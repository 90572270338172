import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  setRightPanel,
  setTaskType,
  setContentType,
  setFolder,
  setOpenTaskId,
  editFolder,
  openFolder,
  deleteFolder,
  deleteFolderFunction,
  resetAllTasks,
  setOpenTaskBranch,
  setParticipants
} from "../../actions/index";
import IsAdmin from "../IsAdmin";
import firebase from "firebase";
import moment from "moment";

//import '../../index.scss';
import {
  Image,
  Button,
  Alert,
  ProgressBar,
  Form,
  ButtonGroup,
  ButtonToolbar,
  Accordion,
  InputGroup,
  FormControl,
  ListGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faInfoCircle,
  faArrowLeft,
  faBookOpen,
  faStar,
  faTimesCircle,
  faPoll,
  faFileAlt,
  faBook,
  faClipboardList,
  faList,
  faTasks,
  faCaretDown,
  faEdit,
  faFolderPlus,
  faFolder,
  faSignOutAlt,
  faTimes,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import {
  faClipboard,
  faCalendarAlt,
} from "@fortawesome/free-regular-svg-icons";

import CreateTaskHeader from "./createTaskHeader2";
import CreateTaskHeader2 from "./CreateTaskHeader_backup";
import RoomDetails from "./RoomDetails";
import RoomDetailsRight from "./RoomDetails_Right";
import RoomAnalytics from "./RoomAnalytics";

//import CreateSchedule from "../Tasks/Schedule/CreateSchedule";
//import CreateReading from "../Tasks/Reading/CreateReading1";
//import CreatePoll from "../Tasks/Poll/CreatePoll";
//import CreateQuiz from "../Tasks/Quiz/CreateQuiz";
//import CreateAssignment from "../Tasks/Assignment/CreateAssignment1";
import Cloud from "../Tasks/Cloud/Cloud";

import AddFolder from "./Folder/AddFolder";
import Folders from "./Folder/Folders";
import TaskManagement from "./TaskManagement";
import ProjectManagement from "./ProjectManagement";
import TaskFolder from "./Folder/TaskFolder";
import TaskTemplate from "./TaskTemplate";

import UpdateFolder from "./Folder/UpdateFolder";
import UpdateAssignment from "../Tasks/Assignment/UpdateAssignment";
import UpdatePoll from "../Tasks/Poll/UpdatePoll";
import UpdateQuiz from "../Tasks/Quiz/UpdateQuiz";
import UpdateReading from "../Tasks/Reading/UpdateReading";
import UpdateSchedule from "../Tasks/Schedule/UpdateSchedule";

import Folder from "./Folder/FolderHomepage";
import PendingInviteAction from "./PendingInviteAction";
import PollHomepage from "../Tasks/Poll/PollHomepage";
import AssignmentHomepage from "../Tasks/Assignment/AssignmentHomepage";
import ReadingHomepage from "../Tasks/Reading/ReadingHomepage";
import ScheduleHomepage from "../Tasks/Schedule/ScheduleHomepage";
import QuizHomepage from "../Tasks/Quiz/QuizHomepage";
import QuizPlusHomepage from "../Tasks/Quiz-Plus/QuizPlusHomepage";
import Prescription from "../Tasks/Prescription/Prescription"

function CreateTaskTemplate({
  type,
  allParticipants,
  contentType,
  setTaskType,
  setContentType,
  resetAllTasks,
  sharing,
}) {
  switch (type) {
    // case "Folder":
    //   return <AddFolder />;

    case "Schedule":
      return (
        <ScheduleHomepage
          allParticipants={allParticipants}
          taskType={type}
          contentType={contentType}
          setContentType={setContentType}
          resetAllTasks={resetAllTasks}
          setTaskType={setTaskType}
        />
      );

    case "Reading":
      return (
        <ReadingHomepage
          allParticipants={allParticipants}
          taskType={"File"}
          contentType={contentType}
          setContentType={setContentType}
          resetAllTasks={resetAllTasks}
          setTaskType={setTaskType}
        />
      );

    case "Poll":
      return (
        <PollHomepage
          allParticipants={allParticipants}
          taskType={type}
          contentType={contentType}
          setContentType={setContentType}
          resetAllTasks={resetAllTasks}
          setTaskType={setTaskType}
        />
      );

    case "Quiz":
      return (
        <QuizHomepage
          allParticipants={allParticipants}
          taskType={type}
          contentType={contentType}
          setContentType={setContentType}
          resetAllTasks={resetAllTasks}
          setTaskType={setTaskType}
          sharing={sharing}
        />
      );
    case "Quiz+":
      return (
        <QuizPlusHomepage
          allParticipants={allParticipants}
          taskType={type}
          contentType={contentType}
          setContentType={setContentType}
          resetAllTasks={resetAllTasks}
          setTaskType={setTaskType}
          sharing={sharing}
        />
      );

    case "Assignment":
      return (
        <AssignmentHomepage
          allParticipants={allParticipants}
          taskType={type}
          contentType={contentType}
          setContentType={setContentType}
          resetAllTasks={resetAllTasks}
          setTaskType={setTaskType}
        />
      );
    case "Cloud":
      return (
        <Cloud
          taskType={type}
          contentType={contentType}
          setContentType={setContentType}
          setTaskType={setTaskType}
          resetAllTasks={resetAllTasks}
        />
      );
      case "Prescription":
        return (
          <Prescription
            taskType={type}
            contentType={contentType}
            setContentType={setContentType}
            setTaskType={setTaskType}
            resetAllTasks={resetAllTasks}
          />
        );

    default:
      return null;
  }
}

function UpdateTaskTemplate({ taskData }) {
  if (
    taskData &&
    taskData.hasOwnProperty("questions") &&
    !taskData.hasOwnProperty("quiz_name")
  ) {
    return <UpdateAssignment data={taskData} />;
  }
  if (taskData && taskData.hasOwnProperty("poll")) {
    return <UpdatePoll data={taskData} />;
  }
  if (taskData && taskData.hasOwnProperty("readings")) {
    return <UpdateReading data={taskData} />;
  }
  if (
    taskData &&
    !taskData.hasOwnProperty("questions") &&
    taskData.hasOwnProperty("quiz_name")
  ) {
    return <UpdateQuiz data={taskData} />;
  }
  if (taskData && taskData.hasOwnProperty("schedule")) {
    return <UpdateSchedule data={taskData} />;
  }
}

function TaskContainer({
  type,
  sharing,
  setTaskType,
  setContentType,
  contentType,
  taskData,
  resetAllTasks,
  allParticipants,
}) {
  return (
    (contentType === "createTask" || contentType === "updateTask") && (
      <div>
        <div>
          {/* <Form> */}
          {/* <div id="display-data-Container"> */}
          {contentType === "createTask" && (
            <CreateTaskTemplate
              allParticipants={allParticipants}
              type={type}
              contentType={contentType}
              setContentType={setContentType}
              resetAllTasks={resetAllTasks}
              setTaskType={setTaskType}
              sharing={sharing}
            />
          )}
          {contentType === "updateTask" && (
            <UpdateTaskTemplate
              allParticipants={allParticipants}
              taskData={taskData}
            />
          )}
          {/* </div> */}
          {/* </Form> */}
        </div>
      </div>
    )
  );
}

function TopSchedule({
  all_tasks,
  contentType,
  setContentType,
  setOpenTaskId,
  setOpenTaskBranch,
}) {
  const month_name = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  // const [showAllSchedules, setShowAllSchedules] = useState(false);
  const [allSchedules, setAllSchedules] = useState([]);

  useEffect(() => {
    all_tasks &&
      setAllSchedules(
        all_tasks
          .sort((a, b) => b.deadline - a.deadline)
          .filter((item) => item.hasOwnProperty("schedule"))
      );
  }, [all_tasks]);

  return (
    <>
      <div className="notifications bg-white">
        <div className="container d-flex p-3 border-bottom border-light">
          <a
            className="d-flex flex-grow-1"
            onClick={() => setContentType("allSchedules")}
          >
            <FontAwesomeIcon icon={faCalendarAlt} style={{ fontSize: 32 }} />
            <div className="ml-3">
              {/* <p>{allSchedules.filter(item => item.deadline > Date.now()).length}</p> */}
              <h5 className="m-0">
                {allSchedules.filter((item) => item.deadline > Date.now())
                  .length > 0
                  ? allSchedules.filter((item) => item.deadline > Date.now())
                      .length + " more"
                  : "No"}{" "}
                upcoming
              </h5>
              <p className="m-0 text-muted">Schedules</p>
            </div>
          </a>
          {contentType === "allSchedules" && (
            <a
              onClick={() => setContentType(null)}
              className="bg-light rounded-circle d-flex justify-content-center align-items-center"
              style={{ height: 30, width: 30 }}
            >
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </a>
          )}
        </div>

        <div className="block pt-0 pb-0">
          {contentType === "allSchedules"
            ? allSchedules.map((item, key) => {
                return (
                  <div
                    onClick={() => {
                      setContentType("Task");
                      setOpenTaskId(item.uid);
                      setOpenTaskBranch("messages");
                    }}
                    className="container border-bottom border-light"
                  >
                    <div className="row mb-2">
                      <div className="col-2 text-center">
                        <h4 className="mb-0 mt-2">
                          {moment(item.deadline).format("DD")}
                        </h4>
                        <span className="small text-secondary">
                          {month_name[moment(item.deadline).format("M") - 1]}
                        </span>
                      </div>
                      <div className="col-10">
                        <p className="mb-1" style={{ marginTop: "10px" }}>
                          {item.schedule}
                        </p>
                        <span className="small text-secondary">
                          {moment(item.deadline).format("hh:mm A")}{" "}
                          {item.duration_hours
                            ? item.duration_minutes
                              ? "(" +
                                item.duration_hours +
                                " hr(s) & " +
                                item.duration_minutes +
                                " mins)"
                              : "(" + item.duration_minutes + " mins)"
                            : item.duration_minutes
                            ? "(" + item.duration_minutes + " mins)"
                            : "onwards"}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })
            : allSchedules
                .filter((item) => item.deadline > Date.now())
                .map((item, key) => {
                  return (
                    key === 0 && (
                      <div
                        onClick={() => {
                          setContentType("Task");
                          setOpenTaskId(item.uid);
                          setOpenTaskBranch("messages");
                        }}
                        className="container border-bottom border-light"
                      >
                        <div className="row mb-2">
                          <div className="col-2 text-center">
                            <h4 className="mb-0 mt-2">
                              {moment(item.deadline).format("DD")}
                            </h4>
                            <span className="small text-secondary">
                              {
                                month_name[
                                  moment(item.deadline).format("M") - 1
                                ]
                              }
                            </span>
                          </div>
                          <div className="col-10">
                            <p className="mb-1" style={{ marginTop: "10px" }}>
                              {item.schedule}
                            </p>
                            <span className="small text-secondary">
                              {moment(item.deadline).format("hh:mm A")}{" "}
                              {item.duration_hours
                                ? item.duration_minutes
                                  ? "(" +
                                    item.duration_hours +
                                    " hr(s) & " +
                                    item.duration_minutes +
                                    " mins)"
                                  : "(" + item.duration_minutes + " mins)"
                                : item.duration_minutes
                                ? "(" + item.duration_minutes + " mins)"
                                : "onwards"}
                            </span>
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
        </div>
      </div>
    </>
  );
}

function RightPanel({
  all_tasks,
  all_saved_tasks,
  contentType,
  setContentType,
  setOpenTaskId,
  setOpenTaskBranch,
  taskType,
  setTaskType,
  resetAllTasks,
  taskData,
  folder_id,
  getTaskData,
  open_task_id,
  currentChannel,
  open_task_branch,
  pendingInvites,
  allParticipants,
  sharing,
  setParticipants
}) {
  switch (contentType) {
    case "allSchedules":
      return (
        <>
          <TopSchedule
            all_tasks={all_tasks}
            contentType={contentType}
            setContentType={setContentType}
            setOpenTaskId={setOpenTaskId}
            setOpenTaskBranch={setOpenTaskBranch}
          />
        </>
      );

    case "roomDetails":
      return <RoomDetails allParticipants={allParticipants} />;

    case "Folder":
      return <Folder allParticipants={allParticipants} />;

    case "createTask":
      return (
        // <IsAdmin>
        <TaskContainer
          type={taskType}
          setTaskType={setTaskType}
          setContentType={setContentType}
          resetAllTasks={resetAllTasks}
          contentType={contentType}
          taskData={taskData}
          sharing={sharing}
          allParticipants={allParticipants}
        />
        //</IsAdmin>
      );

    case "updateTask":
      return (
        // <IsAdmin>
        <TaskContainer
          type={taskType}
          setTaskType={setTaskType}
          setContentType={setContentType}
          resetAllTasks={resetAllTasks}
          contentType={contentType}
          taskData={taskData}
          allParticipants={allParticipants}
        />
        //  </IsAdmin>
      );

    case "Task":
      return (
        <div>
          <div className="mb-1 p-2 bg-card-theme text-theme">
            <FontAwesomeIcon
              onClick={() => {
                setOpenTaskId(null);
                setContentType(null);
              }}
              icon={folder_id ? faTimes : faArrowLeft}
              className="mr-2 pointer"
            />
            <span>{folder_id ? "Close" : "Back"}</span>
          </div>
          <div className="text-theme">
            {/* <Form> Arani 23 Sep*/}
            {/* <div id="display-data-Container"> */}
            <TaskTemplate
              item={getTaskData(open_task_id, currentChannel, open_task_branch)}
            />
            {/* </div> */}
            {/* </Form>  Aran 23 Sep*/}
          </div>
        </div>
      );

    default:
      return (
        <>
          {/* <TopSchedule
            all_tasks={all_tasks}
            contentType={contentType}
            setContentType={setContentType}
            setOpenTaskId={setOpenTaskId}
            setOpenTaskBranch={setOpenTaskBranch} /> */}

          <IsAdmin>
            {pendingInvites && pendingInvites.length > 0 && (
              <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme">
                <div className="">
                  <p className="font-weight-bold">Pending Invites</p>
                  {pendingInvites &&
                    pendingInvites.map((pendingInvite) => (
                      <PendingInviteAction
                        inviteeUserId={pendingInvite.inviteeUserId}
                      />
                    ))}
                </div>
              </div>
            )}
          </IsAdmin>

          <CreateTaskHeader />
          {/* <Folders />
          <TaskManagement all_tasks={all_tasks} all_saved_tasks={all_saved_tasks} />
          <ProjectManagement all_tasks={all_tasks} all_saved_tasks={all_saved_tasks} /> */}

          <RoomDetailsRight allParticipants={allParticipants} />
        </>
      );
  }
}

function Right({
  currentWorkspace,
  currentChannel,
  currentUser,
  displayRightPanel,
  contentType,
  setContentType,
  taskType,
  setTaskType,
  taskId,
  folder_id,
  setFolder,
  open_task_id,
  setOpenTaskId,
  edit_folder,
  editFolder,
  open_folder,
  openFolder,
  deleteFolder,
  deleteFolderFunction,
  delete_folder,
  setRightPanel,
  isPrivateChannel,
  open_task_branch,
  taskBranch,
  resetAllTasks,
  setOpenTaskBranch,
  sharing,
  setParticipants
}) {
  const [all_tasks, set_all_tasks] = useState([]);
  const [all_saved_tasks, set_all_saved_tasks] = useState([]);
  const [pendingInvites, setPendingInvites] = useState([]);

  const [allParticipants, setAllParticipants] = useState([]);

  useEffect(() => {
    if (currentWorkspace && currentChannel) {
      firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
        .doc(`${currentChannel.roomId}`)
        .onSnapshot((doc) => {
          if (doc.exists) {
            // console.log("PENDING INVITES: ", doc.data());
            setPendingInvites(doc.data().pendingInvites);
          }
        });

      firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/participants`
        )
        .onSnapshot((snapshot) => {
          let allParticipants1 = [];

          snapshot.forEach((doc) => {
            allParticipants1.push({ uid: doc.id, ...doc.data() });
          });

          console.log("allParticipants1", allParticipants1);

          setAllParticipants(allParticipants1);

          setParticipants(currentWorkspace.workspaceId,currentChannel.roomId);

        });
    }
  }, [currentWorkspace, currentChannel]);

  useEffect(() => {
    let loadedMessages = [];
    // currentChannel && firebase.database().ref("messages").child(currentChannel.id).orderByChild("timestamp").on("child_added", snap => {

    //   if (!snap.val().hasOwnProperty("content") && !snap.val().hasOwnProperty("image") && !snap.val().hasOwnProperty("file")) {
    //     if (snap.val().everyone)
    //       loadedMessages.push(snap.val())
    //     else if (snap.val().users && Object.keys(snap.val().users).includes(currentUser.uid))
    //       loadedMessages.push(snap.val());
    //   }
    // })
    // set_all_tasks(loadedMessages)

    // let savedTasks = [];
    // currentChannel && firebase.database().ref("saved").child(currentChannel.id).orderByChild("timestamp").on("child_added", snap => {

    //   if (!snap.val().hasOwnProperty("content") && !snap.val().hasOwnProperty("image") && !snap.val().hasOwnProperty("file")) {
    //     if (snap.val().user.id === currentUser.uid)
    //       savedTasks.push(snap.val());
    //   }

    //   // set_all_saved_tasks(savedTasks);
    // })
    // set_all_saved_tasks(savedTasks);

    // console.log("CONSOLE FROM RIGHT:")
    // if (currentWorkspace && currentChannel) {
    //   firebase
    //     .firestore()
    //     .collection(`workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`)
    //     .where("type", "in", [1,2,3,4,5,6,7])
    //     .get()
    //     .then(querySnapshot => {
    //       querySnapshot.forEach(doc => {
    //         if (doc.exists) {
    //           console.log("ALL DATA: ",doc.data())
    //           loadedMessages.push({ [doc.id]: doc.data() })
    //         }
    //       })
    //     }).catch(err => console.error(err))

    //     set_all_tasks(loadedMessages)
    // }
  }, [currentChannel, currentWorkspace]);

  useEffect(() => {
    if (window.innerWidth < 767) {
      setRightPanel(false);
    }
  }, [window.innerWidth]);

  const getTaskData = (key, currentChannel, open_task_branch = "messages") => {
    let taskData = null;
    firebase
      .database()
      .ref(`${open_task_branch}/${currentChannel.id}`)
      .child(key)
      .on("value", (snap) => {
        taskData = snap.val();
      });
    return taskData;
  };

  if (isPrivateChannel) return null;

  return (
    <div id="right" className={displayRightPanel ? "open" : "closed"}>
      {currentChannel && (
        <div className="icon" onClick={() => setRightPanel(!displayRightPanel)}>
          {/* {displayRightPanel ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />} */}
          {displayRightPanel ? <FontAwesomeIcon icon={faTimes} /> : <> </>}
        </div>
      )}

      <div className={`sidebar ${displayRightPanel ? "open" : "closed"}`}>
        <CreateTaskHeader2 />

        <div className="content">
          <RightPanel
            all_tasks={all_tasks}
            all_saved_tasks={all_saved_tasks}
            contentType={contentType}
            setContentType={setContentType}
            setOpenTaskId={setOpenTaskId}
            setOpenTaskBranch={setOpenTaskBranch}
            taskType={taskType}
            setTaskType={setTaskType}
            resetAllTasks={resetAllTasks}
            taskData={taskId && getTaskData(taskId, currentChannel, taskBranch)}
            open_task_id={open_task_id}
            open_task_branch={open_task_branch}
            currentChannel={currentChannel}
            getTaskData={getTaskData}
            pendingInvites={pendingInvites}
            allParticipants={allParticipants}
            sharing={sharing}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,

  displayRightPanel: state.platform.displayRightPanel,
  contentType: state.platform.contentType,
  taskType: state.platform.taskType,
  taskId: state.platform.taskId,
  taskBranch: state.platform.taskBranch,
  showFull: state.platform.showFull,
  folder_id: state.platform.folder_id,
  open_task_id: state.platform.open_task_id,
  open_task_branch: state.platform.open_task_branch,

  edit_folder: state.folder.edit_folder,
  open_folder: state.folder.open_folder,
  delete_folder: state.folder.delete_folder,

  sharing: state.platform.sharing,
});

export default connect(mapStateToProps, {
  setRightPanel,
  setTaskType,
  setContentType,
  setFolder,
  setOpenTaskId,
  editFolder,
  openFolder,
  deleteFolder,
  deleteFolderFunction,
  resetAllTasks,
  setOpenTaskBranch,
  setParticipants
})(Right);
