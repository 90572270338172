import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, InputGroup, FormControl, Spinner, Image, ButtonGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faLock, faCamera, faCheckCircle, faCommentAlt, faStar, faImages, faThumbsUp, faTimes } from '@fortawesome/free-solid-svg-icons'
import html2canvas from 'html2canvas'
import firebase from 'firebase'
import { connect } from 'react-redux'
import moment from 'moment'

const ShowImage = ({ key, blob, removeImage }) => {

  const [imgSrc, setImgSrc] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const reader = new FileReader();
    reader.readAsDataURL(blob)
    reader.onloadend = function () {
      let base64String = reader.result;
      setImgSrc(base64String)
      setLoading(false)
    }
  }, [blob])

  return (
    <div key={key}>
      {loading
        ? <Spinner />
        : <div className="m-1 border rounded rounded-lg"
          style={{ borderColor: "#AEAEAE" }}>
          <Image
            src={imgSrc}
            onClick={() => { if (window.confirm("Want to remove?")) removeImage() }}
            className="img-fluid"
            style={{ height: 60, width: "auto" }} />
        </div>
        // <div
        //   style={{
        //     height: 60,
        //     width: 60,
        //     backgroundImage: `url(${imgSrc})`,
        //     backgroundPosition: 'center',
        //     backgroundSize: 'cover',
        //     backgroundRepeat: 'no-repeat',
        //     border: "solid",
        //     borderWidth: 0.1,
        //     borderColor: "#AEAEAE",
        //     borderRadius: 5
        //   }}
        //   onClick={() => {
        //     if (window.confirm("Want to remove?")) removeImage()
        //   }}
        //   className="m-1"></div>
      }
    </div>
  )
}

function UserFeedback(props) {

  const [feedback, setFeedback] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState([])
  const [show, setShow] = useState(false)
  const [files, setFiles] = useState([])
  const [starValue, setStarValue] = useState(0)
  const [feedbackDone, setFeedbackDone] = useState(false)
  const [imagesLoading, setImagesLoading] = useState(false)


  const handleScreenshot = () => {
    html2canvas(document.body).then(function (canvas) {
      document.body.appendChild(canvas);
      alert("Taken")
    });
  }

  const handleClose = () => {
    setShow(false)
    setFeedbackDone(false)
  };
  const handleShow = () => setShow(true);

  const handleFeedback = () => {
    handleShow()
  }


  const CreateStars = ({ n, setStarValue, starValue }) => {
    let group = []
    for (let i = 1; i <= n; i++) {
      group.push(
        <Button
          id={i}
          key={i}
          variant="transparent"
          value={i}
          onClick={() => setStarValue(i)}
          className="feedbackstar">
          {(starValue >= i) ? <FontAwesomeIcon icon={faStar} color="#f2b600" /> : <FontAwesomeIcon className="text-theme" icon={faStar} />}
        </Button>
      )
    }
    return group
  }

  const fileSelectedHandler = (e) => {
    const loadedFile = e.target.files;
    setFiles(prev => [...prev, ...loadedFile])
  }

  const removeImage = (key) => {
    let temp = [...files]
    temp.splice(key, 1)
    setFiles(temp)
  }

  const uploadImage = (key) => {

    const storageRef = firebase.storage().ref()

    return new Promise(async (resolve, reject) => {

      const { currentUser } = props;

      // const fileData = filesData[key];
      const file = files[key];
      const metadata = { contentType: file.type };
      const filename = file.name.split(".")[0] + "-" + Date.now() + "." + file.name.split(".")[1];
      // const filePath = folder !== ""
      //     ? `cloud/${currentChannel.id}/${folder}/${filename}`
      //     : `cloud/${currentChannel.id}/0/${filename}`;
      // const uploadFolder = folder !== "" ? folder : "0";


      let imageBlob = file;

      var uploadTask = storageRef.child(`feedbacks/${filename}`).put(imageBlob, metadata);

      uploadTask.on(
        "state_changed",
        snap => {
          const percentUploaded = Math.round(
            (snap.bytesTransferred / snap.totalBytes) * 100
          );
          console.log(`File ${key}: `, percentUploaded);
          // this.setState({ percentUploaded });
        },
        err => {
          console.error(err);
          // this.setState({
          //     uploadState: "error",
          //     uploadTask: null
          // });
        },
        () => {
          uploadTask.snapshot.ref
            .getDownloadURL()
            .then(downloadUrl => onData(downloadUrl))
            .catch(err => onError(err));
        }
      )

      // this.setState({
      //   uploadState: "uploading",
      //   imagesLoading: true
      // });
      setImagesLoading(true)

      const onError = err => {
        console.error(err);
        // this.setState({
        //     uploadState: "error",
        //     uploadTask: null
        // });
        reject(err);
      }

      const onData = downloadUrl => {
        console.log("downloadUrl " + key + ": ", downloadUrl)
        let temp_images = [...images];
        temp_images[key] = downloadUrl;
        setImages(temp_images);

        resolve(downloadUrl);
      }
    })
  };

  const handleSubmit = () => {
    const { currentUser } = props;
    const dateNow = Date.now();

    setLoading(true)

    if (feedback.length === 0 || feedback === "") {
      setError("Please add some feedback")
      setLoading(false)
      setImagesLoading(false)
      return;
    }

    let allImagesUploadedPromises = files.map((file, key) => uploadImage(key))

    Promise.all(allImagesUploadedPromises).then((images) => {
      console.log("responses", images)


      let userFeedbackData = {
        feedback,
        user: currentUser.uid,
        images,
        rating: starValue,
        timestamp: dateNow,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        year: moment(dateNow).format("YYYY"),
        month: moment(dateNow).format("MM"),
        date: moment(dateNow).format("DD")
      }

      console.log("userFeedbackData", userFeedbackData)

      firebase
        .firestore()
        .collection(`feedbacks`)
        .add(userFeedbackData)
        .then(docRef => {
          setLoading(false)
          setImagesLoading(false)
          setTimeout(() => setFeedbackDone(true), 1000)

          setTimeout(() => {
            setFeedback("")
            setFiles([])
            setError("")
            setStarValue(0)
          }, 2500)
        })
        .catch(err => console.error(err))
    })
  }

  return (
    <div>
      <Button
        // variant="secondary"
        className="rounded-circle bg-primary feedback-btn"
        onClick={handleFeedback}
        style={{ position: "absolute", zIndex: 1000, bottom: "2rem", left: "2rem", height: "3rem", width: "3rem" }}>
        <div className="">
          <FontAwesomeIcon icon={faThumbsUp} style={{ fontSize: 18 }} className="text-white" />
        </div>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Header className="bg-card-theme text-theme border-0">
          <h3 className="my-2 mb-3">{feedbackDone ? "Thank you" : "Thank you for your feedback"}</h3>
          <div title="Close" className="pointer d-flex justify-content-end mb-0" onClick={() =>  handleClose() }>
            <FontAwesomeIcon className="text-theme" icon={faTimes} />
          </div>
        </Modal.Header>
        <Modal.Body className="bg-card-theme text-theme border-0 ">
          {feedbackDone
            ? <div>
              <FontAwesomeIcon icon={faCheckCircle} className="text-success w-100 mb-3" size="4x" />
              <p className="h4 font-weight-light text-center">Thank you for your valuable feedback!</p>
            </div>
            : <Form>
              <Form.Group>
                <Form.Label className="">What can we do better?</Form.Label>
                <Form.Control
                  as="textarea"
                  aria-label=""
                  placeholder=""
                  rows="3"
                  onChange={e => {
                    setError("")
                    setFeedback(e.target.value)
                  }} />

                {error != "" ? <small className="text-danger">{error}</small> : null}
              </Form.Group>

              <div className="d-flex align-items-center">
                <p className="">How are we doing?</p>
                <div className="mb-3 feedbackdirection flex-grow-1 text-theme">
                  <ButtonGroup aria-label="Feedback" >
                    <CreateStars n={5} setStarValue={setStarValue} starValue={starValue} />
                  </ButtonGroup>
                </div>
              </div>

              <div>
                <p className=" mb-0">Attach screenshots (optional)</p>
                <p className="small text-muted">Click on image to remove</p>
                <div className="row m-1">

                  {files.map((file, key) => (
                    <>
                      <ShowImage key={key} blob={file} removeImage={() => removeImage(key)} />
                      {(!images[key] && imagesLoading) 
                        ? <Spinner animation="border" size="sm" /> 
                        : (images.length > 0 || imagesLoading) && <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 12 }} className="text-success" />}
                    </>
                  ))}

                  <div className="m-1 pointer" style={{ position: "relative", height: 60, width: 60 }}>
                    <div
                      className="bg-light d-flex align-items-center justify-content-center"
                      style={{ borderRadius: 10, borderStyle: "dashed", position: "absolute", cursor: "pointer", height: 60, width: 60, borderWidth: 0.1 }}>
                      <FontAwesomeIcon icon={faImages} className="text-secondary" />
                    </div>
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={e => fileSelectedHandler(e)}
                      style={{ position: "absolute", opacity: 0, height: 60, width: 60 }} />
                  </div>

                </div>
              </div>
            </Form>}
        </Modal.Body>
        <Modal.Footer className="bg-card-theme text-theme border-0 ">
          {feedbackDone
            ? <Button variant="light" onClick={handleClose}>
              <span>Close</span>
            </Button>
            : <Button variant="primary" onClick={handleSubmit} disabled={loading}>
              {loading
                && <Spinner
                  as="span"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                  className="mr-1"
                  size="sm" />}
              <span>Submit Feedback</span>
            </Button>}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => ({
  currentUser: state.user.currentUser
})

export default connect(mapStateToProps)(UserFeedback);