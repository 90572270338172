// Development Environment
// export const API_BASE_URL = "https://dev.igesia.com";

export const API_BBOS_URL = "https://big-blue-ocean.web.app";

//Production Environment
export const API_BASE_URL = "https://api.igesia.com";

//Production Environment => ML
// export const API_BASE_URL = "https://api.uatigesia.com";

//Test Environment
// export const API_BASE_URL = "http://localhost:8000";

// AWS S3 hosted evviroment => ML
// export const API_BASE_URL = "http://3.110.146.213:8000"

//Email configs
export const ACCESS_KEY = "582780d3-d591-4c81-9779-c5dff7715f41";
export const SECRET_KEY = "86B2D2BDF36FE38F28B8C7CC3DAC5";
export const SEND_EMAIL = "/core/email";

export const WORKSPACE_CREATE = "/workspaces/create";

export const ROOM_CREATE = "/rooms/create";
export const ROOM_LEAVE = "/rooms/leave";
export const ROOM_CONFIG = "/rooms/config";
export const ROOM_ADMIN_CONFIG = "/rooms/admin/config";
export const ROOM_DUPLICATE = "/rooms/duplicate";
export const ROOM_CONFIG_SET = "/rooms/admin/config/set";
export const ROOMS_PARTICIPANTS = "/rooms/participants";
export const MEETING_TYPE = "/rooms/meetingtype";
export const ROOM_APP_LIST = "/rooms/applist";
export const ROOM_TRANSFER = "/rooms/transferownership";

export const USER_EXISTS = "/users/exist";
export const USER_ROOM_ARCHIVE = "/users/room/archive";
export const USER_ROOM_UNARCHIVE = "/users/room/unarchive";
export const USER_WORKSPACES = "/users/workspaces";

export const INVITE_LINK_GENERATE = "/invites/link/generate";
export const INVITE_APPROVAL = "/invites/approval/action";
export const INVITE_PROCESS = "/invites/link/process";
export const INVITE_SEND = "/invites/send";
export const INVITE_JWT_PROCESS = "/invites/process";

export const DRIVE_FILE = "/drive/file";
export const DRIVE_RENAME_FILE = "/drive/rename-file";
export const DRIVE_UPLOAD = "/drive/upload";
export const DRIVE_HIERARCHY = "/drive/hierarchy";
export const DRIVE_STORAGE_DETAILS = "/drive/storage-details";
export const DRIVE_FOLDER = "/drive/folder";
export const DRIVE_SOFT_DELETE = "/drive/soft-delete";

export const REPORTS_ROOM_SESSIONS = "/reports/room/sessions";
export const USERS_ROLE_CHANGE = "/users/role/change";

export const APP_CLOSE = "/apps/close";

export const QUIZ_CREATE = "/quiz/create";
export const QUIZ_SHARE = "/quiz/share";
export const QUIZ_UPDATE = "/quiz/update";
export const QUIZ_REMOVE = "/quiz/remove";
export const QUIZ_SUBMIT = "/quiz/submit";
export const QUIZ_EDIT = "/quiz/edit";
export const QUIZ_DOWNLOAD_RESPONSES = "/quiz/download";

export const QUIZPLUS_CREATE = "/special-olympics/create";
export const QUIZPLUS_SHARE = "/special-olympics/share";
export const QUIZPLUS_UPDATE = "/special-olympics/update";
export const QUIZPLUS_REMOVE = "/special-olympics/remove";
export const QUIZPLUS_SUBMIT = "/special-olympics/submit";
export const QUIZPLUS_ANALYTICS = "/special-olympics/analytics";
export const QUIZPLUS_DOWNLOAD_RESPONSES =
  "/special-olympics/download/responses";
export const QUIZPLUS_ADD_QUESTION_CATEGORY =
  "/special-olympics/create/question-category";
export const QUIZPLUS_QUESTION_CATEGORIES =
  "/special-olympics/question-categories";

export const POLL_CREATE = "/poll/create";
export const POLL_SHARE = "/poll/share";
export const POLL_UPDATE = "/poll/update";
export const POLL_REMOVE = "/poll/remove";
export const POLL_SUBMIT = "/poll/submit";
export const POLL_EDIT = "/poll/edit";
export const POLL_ANALYTICS = "/poll/analytics";
export const POLL_DOWNLOAD_RESPONSES = "/poll/download/responses";

export const ASSIGNMENT_CREATE = "/assignment/create";
export const ASSIGNMENT_SHARE = "/assignment/share";
export const ASSIGNMENT_UPDATE = "/assignment/update";
export const ASSIGNMENT_REMOVE = "/assignment/remove";
export const ASSIGNMENT_SUBMIT = "/assignment/submit";
export const ASSIGNMENT_EDIT = "/assignment/edit";

export const ASSIGNMENT_ANALYTICS = "/assignment/analytics";
//export const ASSIGNMENT_DOWNLOAD_RESPONSES = "/assignment/download/responses";

export const READING_CREATE = "/reading/create";
export const READING_SHARE = "/reading/share";
export const READING_UPDATE = "/reading/update";
export const READING_REMOVE = "/reading/remove";
export const READING_EDIT = "/reading/edit";

export const GRADEBOOK_DETAILS = "/gradebook/all";
export const GRADEBOOK_SCORE_PUBLISH = "/gradebook/score/publish";

export const SCHEDULE_CREATE = "/schedule/create";
export const SCHEDULE_SHARE = "/schedule/share";
export const SCHEDULE_UPDATE = "/schedule/update";
export const SCHEDULE_REMOVE = "/schedule/remove";
export const SCHEDULE_SUBMIT = "/schedule/submit";
export const SCHEDULE_EDIT = "/schedule/edit";

export const DOWNLOAD_SESSIONS = "/reports/download/sessions";
export const DOWNLOAD_SESSION = "/reports/download/session";
export const APPS_CLOSE = "/apps/close";

export const CURRENT_VERSION = "0.1.49";
export const IGESIA_VIDEO_SERVER_BASE_URL = "https://opentok-api.igesia.com";

export const SEND_MESSAGE = "/message/send";

// zoom
export const ZOOM_CLIENT_ID = "dzZJw0QiQmOKSkSLDv9BQ";

export const ZOOM_CLIENT_SECRET = "QlXK6O3hF1UqMSKxYwYOtvqAQyOoOxbG";

export const ZOOM_OAUTH = "/zoom/oauth/";

//folder
export const FOLDER_APP_LIST = "/folder/applist";
export const FOLDER_LIST = "/folder/list";

// scrapper
export const META_TAGS = "/meta/getmetadata";

// workspace
export const WORKSPACE_DETAILS_FROM_ID_SLUG =
  "/workspaces/getworkspacedatafromslugorid";
export const WORKSPACE_DETAILS_INVITE_CODE = "/workspaces/getworkspacedata";

//plan
export const PLAN_STATUS = "/users/plan/status";
// export const PLAN_ESTIMATE = "/users/plan/estimate";
// export const PLAN_CREATE = "/users/plan/create";
