import React, { useState, useEffect } from "react";
import firebase from "firebase";
import "./PatientDetails.css";

import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL, DRIVE_UPLOAD } from "../../../../config";
import axios from "axios";
import { Modal, Spinner } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const EMCoptions = [
  { id: "2", isSelected: false, value: "Arthritis" },
  { id: "3", isSelected: false, value: "Asthama" },
  { id: "4", isSelected: false, value: "COPD" },
  { id: "5", isSelected: false, value: "Depression" },
  { id: "6", isSelected: false, value: "Diabetes" },
  { id: "7", isSelected: false, value: "Food Allergies" },
  { id: "8", isSelected: false, value: "Glaucoma" },
  { id: "9", isSelected: false, value: "Heart Disease" },
  { id: "10", isSelected: false, value: "High Cholesterol" },
  { id: "11", isSelected: false, value: "Hypertension (High Blood Pressure)" },
  { id: "12", isSelected: false, value: "Kidney Disease" },
  { id: "13", isSelected: false, value: "Liver Disease" },
  { id: "14", isSelected: false, value: "Seasonal Allergies" },
  { id: "15", isSelected: false, value: "Seizures / Epilepsy" },
  { id: "16", isSelected: false, value: "Skin Disease" },
  { id: "17", isSelected: false, value: "Thyroid Disease" },
  { id: "18", isSelected: false, value: "Ulcer / Acid Reflux/ GERD" },
  { id: "19", isSelected: false, value: "Others" },
];

const PatientDetailsForm = ({
  currentChannel,
  currentWorkspace,
  currentUser,
  right,
}) => {
  // const patientDetails = JSON.parse(localStorage.patientDetails);

  const [existingMedicalConditions, setExistingMedicalConditions] = useState(
    []
  );
  const [EMCValue, setEMCValue] = useState({});
  const [addMoreEMC, setAddMoreEMC] = useState(false);

  const [allergy, setAllergy] = useState([]);
  const [allergyValue, setAllergyValue] = useState({});
  const [addMoreAllergy, setAddMoreAllergy] = useState(false);
  const [appointmentHistory, setAppointmentHistory] = useState({});
  const [formDetails, setFormDetails] = useState({});

  const [patientDetails, setPatientDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [error, setError] = useState("");

  const [IsUploading, setIsUploading] = useState("");
  const [uploads, setUploads] = useState([]);

  const [reportsDetails, setReportDetails] = useState({});

  const [modalShow, setModalShow] = useState(false);
  const [ReportToShow, setReportToShow] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    firebase
      .firestore()
      .collection("caseSheet")
      .doc(currentWorkspace.workspaceId)
      .collection("rooms")
      .doc(currentChannel.roomId)
      .collection("caseSheet")
      .doc("caseSheetId")
      .collection("data")
      .doc("patientData")
      .onSnapshot((doc) => {
        if (doc.exists) {
          setPatientDetails({ ...doc.data() });
        } else {
          setPatientDetails({});
        }
        setIsLoading(false);
      });
  }, []);

  const handleAllergiesSelection = (e) => {
    allergyValue["type"] = e.target.value;
    console.log(e.target.value);
    setAllergyValue({ ...allergyValue });
  };

  const handleFormChange = (e) => {
    const Name = e.target.name;
    const Value = e.target.value;
    formDetails[Name] = Value;
    setFormDetails({ ...formDetails });
  };

  // const handleExistingMedicalConditions = (e) => {
  //   var tempSelection = [];
  //   for (var option of e.target.options) {
  //     if (option.selected) {
  //       if (option.value === "Others") {
  //         setShowOtherEMCinput(true);
  //       } else if (option.value === "Food Allergies") {
  //         setShowOtherFAinput(true);
  //       } else if (option.value === "Seasonal Allergies") {
  //         setShowOtherSAinput(true);
  //       } else {
  //         // tempSelection.push(option.value);
  //         setShowOtherFAinput(false);
  //         setShowOtherSAinput(false);
  //         setShowOtherEMCinput(false);
  //       }
  //       setSelectedExistingMedicalConditions(option.value);
  //     }
  //   }
  // };

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;

  const handleappointmentHistory = (e) => {
    const Name = e.target.name;
    const Value = e.target.value;
    if (Name === "appointmentHistoryFirst" && Value === "Yes") {
      setAppointmentHistory({ appointmentHistoryFirst: "Yes" });
      return;
    }
    appointmentHistory[Name] = Value;
    setAppointmentHistory({ ...appointmentHistory });
  };

  const uploadFile = async (file) => {
    const fileData = new FormData();
    fileData.append("file", file, `${file.lastModified}-${file.name}`);
    fileData.append("user_id", currentUser.uid);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        setIsUploading(
          Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
        );
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        // console.log(res);

        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
        return "";
      }
    } else {
      return "";
    }
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    let fileUrl = await uploadFile(e.target.files[0]);

    reportsDetails["url"] = fileUrl;
    setReportDetails({ ...reportsDetails });
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (!formDetails.fName || !formDetails.lName) {
      setError("Please fill patient name!");
      return;
    }
    if (!formDetails.dateOfBirth) {
      setError("Please fill patient date of birth!");
      return;
    }
    if (!formDetails.gender) {
      setError("Please fill gender of patient!");
      return;
    }
    if (!formDetails.currentHealthProblem) {
      setError("Please fill Current Health Problem");
      return;
    }
    if (!Object.keys(appointmentHistory).length) {
      setError("Please fill appointment history");
      return;
    }
    console.log(appointmentHistory);
    if (
      appointmentHistory.appointmentHistoryFirst === "No" &&
      (!appointmentHistory.appointmentHistoryReview ||
        !appointmentHistory.doctorName ||
        !appointmentHistory.doctorSpeciality ||
        !appointmentHistory.historyDate)
    ) {
      setError("Please fill appointment history");
      return;
    }

    firebase
      .firestore()
      .collection("caseSheet")
      .doc(currentWorkspace.workspaceId)
      .collection("rooms")
      .doc(currentChannel.roomId)
      .collection("caseSheet")
      .doc("caseSheetId")
      .collection("data")
      .doc("patientData")
      .set({
        existingMedicalConditions,
        allergy,
        name: formDetails.fName + " " + formDetails.lName,
        ...formDetails,
        appointmentHistory,
        uploads,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        timestamp: Date.now(),
      })
      .catch((error) => {
        alert("Something went wrong!");
        console.error("error adding document: ", error);
      });
  };

  return (
    <div>
      {isLoading ? (
        <div className="text-center my-4 px-5">
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-2x" />
        </div>
      ) : patientDetails.name ? (
        <div className="text-center font-13 text-dark my-3 px-3">
          Patient Details form has been submitted!
        </div>
      ) : (
        <div className="row mx-0 px-3">
          <div className="col-12">
            <div className="form-group font-13">
              <label>Patient Name</label>
              <div className="row mx-0">
                <div className={!right ? "col-md-6 pl-0 pr-1" : "col-12 px-0"}>
                  <input
                    type="text"
                    name="fName"
                    value={formDetails.fName}
                    onChange={handleFormChange}
                    placeholder="First name"
                    className="form-control form-control-sm "
                  />
                </div>

                <div
                  className={!right ? "col-md-6 pl-0 pr-1" : "col-12 px-0 mt-1"}
                >
                  <input
                    type="text"
                    name="lName"
                    value={formDetails.lName}
                    onChange={handleFormChange}
                    placeholder="Last name"
                    className="form-control form-control-sm "
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={!right ? "col-md-6 px-0 pl-3 pr-1" : "col-12 px-3"}>
            <div className="form-group font-13">
              <label>Date of Birth</label>
              <input
                type="date"
                max={today}
                name="dateOfBirth"
                value={formDetails.dateOfBirth}
                onChange={handleFormChange}
                className="form-control form-control-sm"
              />
            </div>
          </div>

          <div className={!right ? "col-md-6 px-0 pr-3 pl-1" : "col-12 px-3"}>
            <div className="form-group font-13">
              <label>Gender</label>
              <select
                name="gender"
                value={formDetails.gender}
                onChange={handleFormChange}
                className="form-control form-control-sm"
              >
                <option selected disabled>
                  Select
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          {/* ---------------------------------------------- Current Health Problem   -------------------------------------------- */}
          <div className="col-12">
            <div className="form-group">
              <label>Current Health Problem</label>
              <textarea
                className="form-control"
                name="currentHealthProblem"
                placeholder="Write here..."
                onChange={handleFormChange}
                rows="3"
                id="comment"
              />
            </div>
          </div>
          {/* ------------------------------------------------ Existing Medications   -------------------------------------------- */}
          <div className="col-12">
            <div className="form-group">
              <label>Existing Medications</label>
              <textarea
                className="form-control"
                placeholder="Write here..."
                name="existingMedications"
                onChange={handleFormChange}
                rows="3"
                id="comment"
              />
            </div>
          </div>

          {/* ------------------------------ Allergies ---------------------------------- */}

          <div
            className={`shadow rounded my-2  ${
              !right ? "col-6 p-2" : "col-12 my-1 px-2"
            }`}
          >
            <div className="form-group p-2">
              <div className="text-light lead mb-1">
                Allergies
                <span
                  className="btn btn-primary btn-sm float-right mr-2"
                  onClick={() => setAddMoreAllergy(true)}
                  style={{ marginTop: "-5px" }}
                >
                  <small>
                    <FontAwesomeIcon icon={faPlus} /> Add
                  </small>
                </span>
              </div>
              {allergy.length ? (
                <table className="table text-white mb-2">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Allergy</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="font-14">
                    {allergy.map((item, idx) => (
                      <tr key={idx}>
                        <td>
                          <b>{idx + 1}.</b> {item.type}
                        </td>
                        <td>{item.value}</td>
                        <td style={{ width: "85px" }} className="text-center">
                          <span
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              let tempAllergy = allergy.filter(
                                (v, i) => i !== idx
                              );
                              setAllergy([...tempAllergy]);
                            }}
                          >
                            <small>Delete</small>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
              {addMoreAllergy && (
                <div className="rounded border mt-1">
                  <div className="bg-light font-13 p-2 rounded-top text-dark ">
                    Add Allergy
                  </div>
                  <div className="p-2">
                    <label className="text-light mt-1">Select type</label>
                    <select
                      className="form-control form-control-sm"
                      title="None"
                      onChange={handleAllergiesSelection}
                      value={allergyValue.type ? allergyValue.type : "None"}
                    >
                      <option selected disabled value="None">
                        None
                      </option>
                      <option value="Food">Food</option>
                      <option value="Medicine">Medicine</option>
                      <option value="Any other">Any other</option>
                    </select>
                    <div className=" mb-2">
                      <label className="text-light mt-1">Allergy</label>
                      <input
                        type="text"
                        className="form-control form-control-sm "
                        name="value"
                        placeholder="Allergy"
                        onChange={(e) => {
                          allergyValue["value"] = e.target.value;
                          setAllergyValue(allergyValue);
                        }}
                      />
                      <div className="mt-2 ">
                        <span
                          className="btn btn-sm btn-primary "
                          onClick={() => {
                            if (!allergyValue.type || !allergyValue.value) {
                              alert("Please specify allergy!");
                              return;
                            }
                            allergy.push(allergyValue);
                            setAllergy([...allergy]);
                            setAllergyValue({});
                            setAddMoreAllergy(false);
                          }}
                        >
                          Save
                        </span>
                        <span
                          className="btn btn-sm btn-secondary ml-2"
                          onClick={() => {
                            setAllergyValue({});
                            setAddMoreAllergy(false);
                          }}
                        >
                          Discard
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* --------------------------------- Existing Medical Conditions ----------------------------------- */}

          <div
            className={`shadow rounded my-2  ${
              !right ? "col-6 p-2" : "col-12 my-1 px-2"
            }`}
          >
            <div className="form-group p-2">
              <div className="text-light lead mb-1">
                Existing Medical Conditions
                <span
                  className="btn btn-primary btn-sm float-right mr-2"
                  onClick={() => setAddMoreEMC(true)}
                  style={{ marginTop: "-5px" }}
                >
                  <small>
                    <FontAwesomeIcon icon={faPlus} /> Add
                  </small>
                </span>
              </div>
              {existingMedicalConditions.length ? (
                <table className="table text-white mb-2">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Condition</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="font-14">
                    {existingMedicalConditions.map((item, idx) => (
                      <tr key={idx}>
                        <td>
                          <b>{idx + 1}.</b> {item.type}
                        </td>
                        <td>{item.value}</td>
                        <td style={{ width: "85px" }} className="text-center">
                          <span
                            className="btn btn-danger btn-sm "
                            onClick={() => {
                              let tempEMC = existingMedicalConditions.filter(
                                (v, i) => i !== idx
                              );
                              setExistingMedicalConditions([...tempEMC]);
                            }}
                          >
                            <small>Delete</small>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}

              {addMoreEMC && (
                <div className="rounded border mt-1">
                  <div className="bg-light font-13 p-2 rounded-top text-dark ">
                    Add Medical Conditions
                  </div>
                  <div className="p-2  ">
                    <label className="text-light">Select type</label>
                    <select
                      className="form-control form-control-sm"
                      title="None"
                      onChange={(e) => {
                        EMCValue["type"] = e.target.value;
                        setEMCValue({ ...EMCValue });
                      }}
                      value={EMCValue.type ? EMCValue.type : "None"}
                    >
                      <option value="None" selected disabled>
                        None
                      </option>
                      {EMCoptions.map((item) => (
                        <option key={item.id} selected value={item.value}>
                          {item.value}
                        </option>
                      ))}
                    </select>
                    <div className=" mb-2 mt-1">
                      <label className="text-light">Medical condition</label>
                      <input
                        type="text"
                        className="form-control form-control-sm "
                        name="value"
                        placeholder="Medical condition"
                        onChange={(e) => {
                          EMCValue["value"] = e.target.value;
                          setEMCValue({ ...EMCValue });
                        }}
                      />
                      <div className="mt-2 ">
                        <span
                          className="btn btn-sm btn-primary "
                          onClick={() => {
                            if (!EMCValue.type || !EMCValue.value) {
                              alert(
                                "Please specify existing medical condition!"
                              );
                              return;
                            }
                            existingMedicalConditions.push(EMCValue);
                            setExistingMedicalConditions([
                              ...existingMedicalConditions,
                            ]);
                            setEMCValue({});
                            setAddMoreEMC(false);
                          }}
                        >
                          Save
                        </span>
                        <span
                          className="btn btn-sm btn-secondary ml-2"
                          onClick={() => {
                            setEMCValue({});
                            setAddMoreEMC(false);
                          }}
                        >
                          Discard
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <hr />
          {/* -------------------------------------------------------- Appointment history -------------------------------------------- */}
          <div className="my-3">
            <label>Appointment history</label>
            <div className="row mx-0">
              <div className="form-group px-3 mb-1 col-12">
                <label>
                  <b>First: </b>
                </label>
                <input
                  className="ml-3 mx-1"
                  type="radio"
                  name="appointmentHistoryFirst"
                  id="Appointment-history-yes"
                  value="Yes"
                  onChange={handleappointmentHistory}
                />
                <label className="mx-1" for="Appointment-history-yes">
                  Yes
                </label>
                <input
                  className="ml-3 mr-1"
                  type="radio"
                  name="appointmentHistoryFirst"
                  id="Appointment-history-no"
                  value="No"
                  onChange={handleappointmentHistory}
                />
                <label className="mx-1" for="Appointment-history-no">
                  No
                </label>
              </div>

              {appointmentHistory &&
              appointmentHistory.appointmentHistoryFirst === "Yes" ? null : (
                <>
                  <div className="form-group px-3 mb-1 col-12">
                    <label>
                      <b>Review: </b>
                    </label>
                    <input
                      className="ml-3 mx-1"
                      type="radio"
                      name="appointmentHistoryReview"
                      id="Appointment-history-yes"
                      value="Yes"
                      onChange={handleappointmentHistory}
                    />
                    <label className="mx-1" for="Appointment-history-yes">
                      Yes
                    </label>
                    <input
                      className="ml-3 mr-1"
                      type="radio"
                      name="appointmentHistoryReview"
                      id="Appointment-history-no"
                      value="No"
                      onChange={handleappointmentHistory}
                    />
                    <label className="mx-1" for="Appointment-history-no">
                      No
                    </label>
                  </div>

                  <div className="col-12">
                    <label>
                      <b>Doctor Name</b>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Enter Full Name"
                      name="doctorName"
                      onChange={handleappointmentHistory}
                    />
                  </div>
                  <div className="col-12">
                    <label>
                      <b>Speciality</b>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm "
                      placeholder="Enter Speciality of doctor"
                      name="doctorSpeciality"
                      onChange={handleappointmentHistory}
                    />
                  </div>
                  <div className="col-12">
                    <label>
                      <b>Date</b>
                    </label>
                    <input
                      type="date"
                      max={today}
                      className="form-control form-control-sm"
                      placeholder="Enter Full Name"
                      Name="historyDate"
                      onChange={handleappointmentHistory}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          {/* ------------------------------------------- uploads--------------------------------------------- */}
          <div
            className="p-3 mt-2  text-dark mx-2 rounded-lg"
            style={{
              boxSizing: "border-box",
              background: "#fff",
              width: "100%",
            }}
          >
            <h4 className="text-center pb-3 font-weight-bold ">
              Upload your reports
            </h4>
            <div className="row mx-0">
              <div className="col-12">
                {uploads.length ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">No.</th>
                        <th scope="col">Reports</th>
                        <th scope="col">View</th>

                        <th scope="col">Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {uploads.map((item, idx) => (
                        <tr>
                          <th scope="row">{idx + 1}</th>
                          <td>{item.type}</td>
                          <td className="text-center">
                            <span
                              className="btn btn-sm btn-primary "
                              onClick={() => {
                                setModalShow(true);
                                setReportToShow(item);
                              }}
                            >
                              View
                            </span>
                          </td>
                          <td
                            className="text-center text-danger cp"
                            onClick={() => {
                              let tempUploads = uploads.filter(
                                (v, i) => i !== idx
                              );
                              setUploads([...tempUploads]);
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : null}
                <div className="form-group mb-2 px-3">
                  <label>
                    <b>Select report</b>
                  </label>
                  <select
                    className="form-control form-control-sm"
                    value={reportsDetails.type ? reportsDetails.type : "None"}
                    onChange={(e) => {
                      reportsDetails["type"] = e.target.value;
                      setReportDetails({ ...reportsDetails });
                    }}
                  >
                    <option value="None" selected disabled>
                      None
                    </option>
                    <option value="Prescriptions">Prescriptions</option>
                    <option value="Lab Reports">Lab Reports</option>
                    <option value="Radiology Reports">Radiology Reports</option>
                    <option value="Radiology Images">Radiology Images</option>
                    <option value="Picture of problem area">
                      Picture of problem area
                    </option>
                    <option value="Other">Other</option>
                  </select>

                  {reportsDetails.type && (
                    <div className="w-100 mt-2">
                      <input
                        type="file"
                        className="form-control-file form-control-sm "
                        onChange={(e) => handleUpload(e)}
                        accept="image/*"
                      />
                      {console.log(IsUploading)}
                      {IsUploading === 0 || IsUploading ? (
                        <div className="progress my-2">
                          <div
                            className="progress-bar bg-primary progress-bar-striped "
                            role="progressbar"
                            style={{ width: `${IsUploading}%` }}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            {IsUploading}%
                          </div>
                        </div>
                      ) : null}
                      <div className="mt-2 text-center">
                        <span
                          className="btn btn-sm btn-primary px-3"
                          onClick={() => {
                            if (
                              !IsUploading ||
                              IsUploading < 100 ||
                              !reportsDetails.url
                            )
                              return;
                            uploads.push({
                              ...reportsDetails,
                              createdAt: Date.now(),
                            });
                            setUploads([...uploads]);
                            setReportDetails({});
                            setIsUploading("");
                          }}
                        >
                          Add
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Modal
              show={modalShow}
              onHide={() => {
                setModalShow(false);
                setReportToShow(null);
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {!ReportToShow ? (
                    <Spinner animation="border" role="status" variant="dark">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    <div>
                      <div>{ReportToShow.type}</div>
                    </div>
                  )}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {!ReportToShow ? (
                  <div className="text-center mt-5 ">
                    <Spinner animation="border" role="status" variant="dark">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <div className="m-2 border">
                    <img src={ReportToShow.url} alt="report" width="100%" />
                  </div>
                )}
              </Modal.Body>
            </Modal>
          </div>
          <div className="w-100">
            {error ? (
              <div className="mt-2">
                <div className={classes.root}>
                  <Alert onClose={() => setError("")} severity="error">
                    error! {error}
                  </Alert>
                </div>
              </div>
            ) : null}
            <button
              type="submit"
              className="btn btn-primary d-block mx-auto my-3 mb-3"
              onClick={handleSubmitForm}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, {})(PatientDetailsForm);
