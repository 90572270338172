import React from "react";
import {
  Button,
  Form,
  InputGroup,
  FormControl,
  Spinner,
  Badge,
  Card,
  Accordion,
} from "react-bootstrap";
import firebase from "firebase";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Participants from "../../Right/UserNameAvatar";
import { getTimeDifference } from "../Common/TimeDifference";
import AssignedUsers from "../Common/AssignedUsers";

class Reading extends React.Component {
  state = {
    errors: [],
    admin: false,
    responses: [],
    links: [],
    files: [],
    currReading: {},
    isClosed: false,
    assignedShow: false,
    unsubscribeReadingData: null
  };

  getResponses = (responses) => {
    responses &&
      Object.entries(responses).forEach(([key, value]) => {
        this.setState({
          responses: [...this.state.responses, { uid: key, ...value }],
        });
      });
  };

  getName = (key) => {
    let name = "";
    firebase
      .database()
      .ref("users")
      .child(key)
      .on("value", (snap) => {
        if (snap.val()) {
          name = snap.val().name
            ? snap.val().name
            : `${snap.val().firstName} ${snap.val().lastName}`;
        }
      });
    return name;
  };

  timeFromNow = (timestamp) => {
    if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
      return moment(timestamp).fromNow();
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
      return "yesterday";
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
      return moment(timestamp).format("dddd");
    else return moment(timestamp).format("MMM DD YYYY");
  };

  displayResponse = (response) => {
    return (
      <div className="mb-3">
        <h5 className="mt-0">{this.getName(response.uid)}</h5>
        {response.value.map((answer, key) => (
          <div>
            <p className="font-weight-bold mb-0">
              Q: {this.props.item.questions[key]}
            </p>
            <p className="mt-0 mb-1">A: {answer.value}</p>
            <a className="mt-0 mb-0" href={answer.file} target="_blank">
              Click here to see file attached
            </a>
          </div>
        ))}
        <p className="mb-3 mt-0 small">
          Replied <i>{this.timeFromNow(response.responseTime)}</i>
        </p>
        <hr />
      </div>
    );
  };

  async componentDidMount() {
    const { user, item, currentChannel } = this.props;

    try {
      const readingData = firebase
        .firestore()
        .collection("readings")
        .doc(item.contentId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            this.setState({
              currReading: doc.data(),
              read: doc.data().read,
            });

            if(doc.data().previousDeadline || doc.data().deadline < Date.now().valueOf() ) {
              this.setState({isClosed: true})
              //this.props.setIsClosed(true);
            }
          }
        });
      this.setState({ unsubscribeReadingData: readingData })
    } catch (error) {
      console.log(error);
    }
    if (user === item.user.id) {
      this.setState({ admin: true });
      /* firebase
        .database()
        .ref("messages/" + currentChannel.id)
        .child(item.uid)
        .on("value", (snap) => {
          snap.val() && this.getResponses(snap.val().response);
        });*/
      // item.response && this.getResponses(item.response)
    }



  }
  componentWillUnmount() {
    if (this.state.unsubscribeReadingData) {
      this.state.unsubscribeReadingData()
    }
  }
  getResult = (response, user) => {
    console.log(response);
    console.log(user);
    // console.log(Object.keys(response));
    //return response && Object.keys(response).includes(user);
  };

  getValue = (response, user) => {
    return (
      response && (
        <div>
          <Badge variant="secondary">Your answer</Badge>
          <br />
          {response[user].value.map((answer, key) => (
            <div>
              <p className="font-weight-bold mb-0">
                Q: {this.props.item.questions[key]}
              </p>
              <p className="mt-0 mb-1">A: {answer.value}</p>
              <a className="mt-0 mb-0" href={answer.file} target="_blank">
                Click here to see file attached
              </a>
            </div>
          ))}
          <p className="mb-3 mt-0 small">
            Replied <i>{this.timeFromNow(response[user].responseTime)}</i>
          </p>
        </div>
      )
    );
  };
  assignedToShow = () => {
    if(this.state.assignedShow){
      this.setState({assignedShow: false})
    }else{
      this.setState({assignedShow: true})
    }
  }
  render() {
    const {
      uid,
      taskResponse,
      item,
      user,
      open_task_branch,
      open_task_id,
      colorTheme,
    } = this.props;
    const { admin, responses, currReading } = this.state;

    const handleRead = (element, key) => {
      console.log(element, key);
    };
    // console.log(item);
    // console.log(uid);
    return (
      <>
      <>
        <div
          className={
            colorTheme ? `${colorTheme} taskbox` : "taskbox text-theme"
          }
        >
          {Object.keys(currReading).length > 0 ? (
          <>
            <div className="d-flex">
                <h5 className="mt-0 flex-grow-1">
                  {currReading.readingSubject}
                </h5>
                <div>
                  {currReading.deadline < Date.now() ? (
                    <span className="text-secondary small">
                      {currReading.closedBy ? (
                        <>
                          Closed by{" "}
                          <Participants
                            userId={currReading.closedBy}
                            nameOnly={true}
                          />
                        </>
                      ) : (
                        "Closed"
                      )}
                    </span>
                  ) : (
                    <span className="text-success small">Active</span>
                  )}
                </div>
                {open_task_branch === "saved" && open_task_id === item.uid && (
                  <div className="mx-1">
                    <Badge variant="success">Saved</Badge>
                  </div>
                )}
              </div>
              {currReading.closedBy && currReading.previousDeadline && (
                <div className="text-danger small mb-1">
                  <>
                    <span className="text-warning">
                      Reading closed{" "}
                      {getTimeDifference(
                        currReading.previousDeadline,
                        currReading.deadline
                      )}{" "}
                      before actual deadline.
                    </span>
                  </>
                </div>
              )}
              <div className="m-0">
                <p>{currReading.readingDescription}</p>
                {/*console.log(currReading,"my reading")*/}
              </div>
              { currReading.readings.map((reading, key) => (
            <Accordion key={key} eventKey={`${key}`} className="text-dark">
              <Card className="mb-1">
                
                <Accordion.Toggle
                  className="my-0 p-2"
                  as={Card.Header}
                  eventKey="0"
                >
                  {admin ? 
                    
                     (  <div className="mb-2">
                          <p className="mb-1">
                            {reading.link ? (
                              <span>
                                {key + 1}.{" "}
                                <a
                                  href={
                                    reading.link.indexOf("http://") == 0 ||
                                    reading.link.indexOf("https://") == 0
                                      ? reading.link
                                      : `https://${reading.link}`
                                  }
                                  target="_blank"
                                  onClick={() => handleRead("link", key)}
                                >
                                  {reading.title} <FontAwesomeIcon icon={faLink} />
                                </a>
                              </span>
                            ) : (
                              <span>
                                {key + 1}. {reading.title}
                              </span>
                            )}
                          </p>
                        </div>
                  ):( 
                    <div className="mb-2">
                      <p className="mb-1">
                        {reading.link ? (
                          <span className="text-theme">
                            {key + 1}.{" "}
                            <a
                              href={
                                reading.link.indexOf("http://") == 0 ||
                                reading.link.indexOf("https://") == 0
                                  ? reading.link
                                  : `https://${reading.link}`
                              }
                              target="_blank"
                              onClick={() => handleRead("link", key)}
                            >
                              {reading.title}{" "}
                              <FontAwesomeIcon
                                className="text-theme"
                                icon={faLink}
                              />
                            </a>
                          </span>
                        ) : (
                          <span>
                            {key + 1}. {reading.title}
                          </span>
                        )}
                      </p>
                    </div>
                  )}
                
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  {
                    admin ? (
                      <div>
                        <p className="mb-1">
                          {reading.attachment ? (
                            <span className="text-dark dark">
                              <a
                                href={reading.attachment}
                                target="_blank"
                                onClick={() => handleRead("link", key)}
                                className="btn btn-sm btn-outline-dark dark"
                              >
                                <FontAwesomeIcon icon={faPaperclip} /> Attachment
                              </a>
                            </span>
                          ) : (
                            <p className="mx-1 mb-1 text-dark">
                              No Attachment added.
                            </p>)}
                        </p>
                      </div>
                    ):(
                      <div>
                        <p className="mb-1 text-dark">
                          {reading.attachment ? (
                            <span>
                              <a
                                href={reading.attachment}
                                target="_blank"
                                onClick={() => handleRead("link", key)}
                                className={
                                  this.props.darkTheme
                                    ? "btn btn-sm btn-outline-light text-dark"
                                    : "btn btn-sm btn-outline-dark text-dark"
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faPaperclip}
                                  className="text-theme dark"
                                />{" "}
                                Attachment
                              </a>
                            </span>
                          ) : (
                          <p className="mx-1 mb-1 text-dark">
                            No Attachment available.
                          </p>)}
                        </p>
                      </div>
                    )
                  }
                </Accordion.Collapse>
              </Card>
              
            </Accordion>
            
            )) }
            {admin? (
                this.props.view === 2 ? (
                  <>
                    <div className="mt-2 d-flex justify-content-between">
                      <div variant={this.props.darkTheme ? "dark" : "light"} className="mt-3">Assigned To </div>
                      <div className="text-primary mt-3 pointer" onClick={() => this.assignedToShow()}>{this.state.assignedShow ? "Hide(-)" : "Show(+)"}</div>
                    </div>
                    {this.state.assignedShow ? (
                    <>
                    {/*console.log(currAssignment,"respoxx")*/}
                      <AssignedUsers
                        taskData={currReading}
                      />
                    </>
                    ) : null
                    }
                  </>
                ): null
              ): null}
            
          </>
          ) : null}
        </div>
        
      </>
      {/*<>
        <div
          className={
            colorTheme ? `${colorTheme} taskbox` : "taskbox text-theme"
          }
        >
          {Object.keys(currReading).length > 0 ? (
            <Form className="project">
              <div className="d-flex">
                <h5 className="mt-0 flex-grow-1">
                  {currReading.readingSubject}
                </h5>
                <div>
                  {currReading.deadline < Date.now() ? (
                    <span className="text-secondary small">
                      {currReading.closedBy ? (
                        <>
                          Closed by{" "}
                          <Participants
                            userId={currReading.closedBy}
                            nameOnly={true}
                          />
                        </>
                      ) : (
                        "Closed"
                      )}
                    </span>
                  ) : (
                    <span className="text-success small">Active</span>
                  )}
                </div>
                {open_task_branch === "saved" && open_task_id === item.uid && (
                  <div className="mx-1">
                    <Badge variant="success">Saved</Badge>
                  </div>
                )}
              </div>
              {currReading.closedBy && currReading.previousDeadline && (
                <div className="text-danger small mb-1">
                  <>
                    <span className="text-warning">
                      Reading closed{" "}
                      {getTimeDifference(
                        currReading.previousDeadline,
                        currReading.deadline
                      )}{" "}
                      before actual deadline.
                    </span>
                  </>
                </div>
              )}
              <div className="m-0">
                <p>{currReading.readingDescription}</p>
              </div>
              {admin
                ? currReading.readings.map((reading, key) => (
                    <div className="mb-2">
                      <p className="mb-1">
                        {reading.link ? (
                          <span>
                            {key + 1}.{" "}
                            <a
                              href={
                                reading.link.indexOf("http://") == 0 ||
                                reading.link.indexOf("https://") == 0
                                  ? reading.link
                                  : `https://${reading.link}`
                              }
                              target="_blank"
                              onClick={() => handleRead("link", key)}
                            >
                              {reading.title} <FontAwesomeIcon icon={faLink} />
                            </a>
                          </span>
                        ) : (
                          <span>
                            {key + 1}. {reading.title}
                          </span>
                        )}
                      </p>
                      <p className="mb-1">
                        {reading.attachment ? (
                          <span className="text-theme">
                            <a
                              href={reading.attachment}
                              target="_blank"
                              onClick={() => handleRead("link", key)}
                              className="btn btn-sm btn-outline-dark"
                            >
                              <FontAwesomeIcon icon={faPaperclip} /> Attachment
                            </a>
                          </span>
                        ) : null}
                      </p>
                    </div>
                  ))
                : currReading.readings.map((reading, key) => (
                    <div className="mb-2">
                      <p className="mb-1">
                        {reading.link ? (
                          <span className="text-theme">
                            {key + 1}.{" "}
                            <a
                              href={
                                reading.link.indexOf("http://") == 0 ||
                                reading.link.indexOf("https://") == 0
                                  ? reading.link
                                  : `https://${reading.link}`
                              }
                              target="_blank"
                              onClick={() => handleRead("link", key)}
                            >
                              {reading.title}{" "}
                              <FontAwesomeIcon
                                className="text-theme"
                                icon={faLink}
                              />
                            </a>
                          </span>
                        ) : (
                          <span>
                            {key + 1}. {reading.title}
                          </span>
                        )}
                      </p>
                      <p className="mb-1">
                        {reading.attachment ? (
                          <span>
                            <a
                              href={reading.attachment}
                              target="_blank"
                              onClick={() => handleRead("link", key)}
                              className={
                                this.props.darkTheme
                                  ? "btn btn-sm btn-outline-light"
                                  : "btn btn-sm btn-outline-dark"
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPaperclip}
                                className="text-theme"
                              />{" "}
                              Attachment
                            </a>
                          </span>
                        ) : null}
                      </p>
                    </div>
                  ))}
              {/* <Button variant="primary" size="sm" onClick={() => taskResponse(uid)} >Submit</Button> 
            </Form>
          ) : null}
        </div>
      </>*/}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  open_task_branch: state.platform.open_task_branch,
  open_task_id: state.platform.open_task_id,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps)(Reading);
