import React from "react";
//import "../../index.scss"
import windowSize from "react-window-size";
import { connect } from "react-redux";
import {
  setUserPosts,
  setIsVideoVisible,
  setChannelDetails,
  setAllMessages,
  setCurrentChannel,
  setRightPanel,
  setParticipants,
} from "../../actions";
import firebase from "firebase";
import Video from "../Right/Video";

import RoomHeader from "./RoomHeader";
import ActionBox from "./ActionBox";
import Message from "./Message";
import Typing from "./Typing";
import Skeleton from "./Skeleton";
import Welcome from "./Welcome";

import {
  faCaretDown,
  faCaretUp,
  faComment,
  faHandPeace,
  faThumbsUp,
  faSmile,
  faAngleDown,
  faTimes,
  faAngleDoubleDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deviceDetect, deviceType, browserName } from "react-device-detect";

import { StyleRoot } from "radium";
import Whiteboard from "../Whiteboard/index";
import NoteApp from "../HealthApps/NotesApp/NotesApp"
import Gradebook from "./Gradebook2";
import AttendanceLedger from "./newAttendanceLedger";
import { Spinner, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
//import { zIndex } from "html2canvas/dist/types/css/property-descriptors/z-index";
import Chat from "./Chat";
import _ from "lodash";
import CaseSheet from "../Tasks/CaseSheet/CaseSheet";
import PatientRegistrationModal from "../Tasks/CaseSheet/PatientRegistrationModal";

import PrescriptionApp from "../Tasks/Prescription/PrescriptionModal/PrescriptionApp"

const Rnd = require("react-rnd").Rnd;
class Middle extends React.Component {
  state = {
    privateChannel: this.props.isPrivateChannel,
    privateMessagesRef: firebase.database().ref("privateMessages"),
    messagesRef: firebase.database().ref("messages"),
    messages: [],
    messagesLoading: true,
    channel: this.props.currentChannel,
    isLiveSession: 0,
    user: this.props.currentUser,
    usersRef: firebase.database().ref("users"),
    numUniqueUsers: "",
    searchTerm: "",
    searchLoading: false,
    searchResults: [],
    typingRef: firebase.database().ref("typing"),
    typingUsers: [],
    connectedRef: firebase.database().ref(".info/connected"),
    listeners: [],

    limitToLast: 10,
    messagesEnded: false,

    showMessageTopLoader: false,
    currentOffsetY: 0,
    currentOffsetYBottom: 0,

    unsubscribe: null,
    file: null,
  };
  constructor(props) {
    super(props);
    this.main = React.createRef();
  }

  componentDidMount() {
    const { channel, user, listeners } = this.state;
    //console.log(channel, user);

    if (channel && user) {
      this.removeListeners(listeners);
      this.addListeners(channel.roomId);

      const _handleStateChange = this._handleStateChange;

      firebase
        .firestore()
        .collection("liveSessions")
        .doc(`${channel.id}`)
        .onSnapshot(async function (doc) {
          if (doc.exists) {
            // console.log("SESSION DATA:", doc.data().liveSessionId);
            const snapshot = await firebase
              .firestore()
              .collection("attendanceLedger")
              .doc(`${channel.id}`)
              .collection(`sessions`)
              .doc(`${doc.data().liveSessionId}`)
              .collection(`participants`)
              .get();

            if (snapshot.empty) {
              console.log("No matching documents.");
              return;
            }

            let tempLiveUsers = [];
            snapshot.forEach((doc) => {
              //  console.log(doc.id, "=>", doc.data());
              doc.data().leftAtTimestamp === null &&
                tempLiveUsers.push(doc.data().userDetails);
            });
            _handleStateChange("liveUsers", tempLiveUsers);
          } else {
            _handleStateChange("liveUsers", []);
          }
        });

      const {
        currentChannel,
        currentUser,
        setCurrentChannel,
        setRightPanel,
        setParticipants,
        setAllMessages,
      } = this.props;

      firebase
        .firestore()
        .collection(`users/${currentUser.uid}/rooms`)
        .onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (change) {
            if (change.type === "modified") {
              // console.log("Modified city: ", change.doc.data());
              let changedData = change.doc.data();
              let archivedRooms = changedData.archivedRooms
                ? changedData.archivedRooms
                : [];
              let rooms = changedData.rooms ? changedData.rooms : [];

              let allRooms = [...archivedRooms, ...rooms];
              let allRoomsIds = allRooms.map((item) => item.roomId);

              //console.log(allRoomsIds);
              // console.log(currentChannel);
              if (allRoomsIds.includes(currentChannel.roomId)) {
                //alert("Hai " + currentChannel.roomId);
              } else {
                // alert("Nahi "+currentChannel.roomId)
                setCurrentChannel(null);
                setRightPanel(false);
                setParticipants(null);
                setAllMessages(null);
              }
            }
          });
        });
    }
  }

  componentWillUnmount() {
    this.removeListeners(this.state.listeners);
    this.state.connectedRef.off();
  }

  removeListeners = (listeners) => {
    listeners.forEach((listener) => {
      listener.ref.child(listener.id).off(listener.event);
    });
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      setAllMessages,
      currentChannel,
      allChannels,
      currentWorkspace,
    } = this.props;

    if (
      prevProps.currentChannel &&
      prevProps.currentChannel.roomId !== currentChannel.roomId &&
      currentWorkspace
    ) {
      if (this.state.unsubscribe) this.state.unsubscribe();
      setAllMessages([]);
      this.addMessageListener_new();
    }
    // if(prevProps.allChannels && allChannels && prevProps.allChannels.length !== allChannels.length){
    //   this.state.unsubscribe();
    //   setAllMessages([]);
    //   this.addMessageListener_new();
    // }
  }

  addToListeners = (id, ref, event) => {
    const index = this.state.listeners.findIndex((listener) => {
      return (
        listener.id === id && listener.ref === ref && listener.event === event
      );
    });

    if (index === -1) {
      const newListener = { id, ref, event };
      this.setState({ listeners: this.state.listeners.concat(newListener) });
    }
  };

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  addListeners = (roomId) => {
    console.log(this.props, "this props in did mount");
    if (this.props.currentWorkspace) {
      this.addMessageListener_new();
    }
    this.addTypingListeners(roomId);
  };

  addTypingListeners = (channelId) => {
    let typingUsers = [];
    this.state.typingRef.child(channelId).on("child_added", (snap) => {
      if (snap.key !== this.state.user.uid) {
        typingUsers = typingUsers.concat({
          id: snap.key,
          name: snap.val(),
        });
        this.setState({ typingUsers });
      }
    });
    this.addToListeners(channelId, this.state.typingRef, "child_added");

    this.state.typingRef.child(channelId).on("child_removed", (snap) => {
      const index = typingUsers.findIndex((user) => user.id === snap.key);
      if (index !== -1) {
        typingUsers = typingUsers.filter((user) => user.id !== snap.key);
        this.setState({ typingUsers });
      }
    });
    this.addToListeners(channelId, this.state.typingRef, "child_removed");

    this.state.connectedRef.on("value", (snap) => {
      if (snap.val() === true) {
        this.state.typingRef
          .child(channelId)
          .child(this.state.user.uid)
          .onDisconnect()
          .remove((err) => {
            if (err !== null) {
              console.error(err);
            }
          });
      }
    });
  };

  _handleStateChange = (attr, val) => this.setState({ [attr]: val });

  addMessageListener_new = () => {
    this._handleStateChange("messagesLoading", false);
    // const { currentWorkspace, currentChannel, setAllMessages } = this.props;
    // let loadedMessages = [];
    // const _handleStateChange = this._handleStateChange;
    // let unsub;

    // let self = this;

    // //console.log("current channel before unsub", currentChannel);

    // const query = firebase
    //   .firestore()
    //   .collection(
    //     `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
    //   )
    //   .orderBy("timestamp", "desc")
    //   .limit(12);

    // unsub = query.onSnapshot(function (snapshot) {
    //   snapshot.docChanges().forEach((change) => {
    //     //  console.log("currentChannel in unsub", currentChannel);
    //     if (change.type === "added") {
    //       //  console.log("Added: ", change.doc.data());
    //       loadedMessages.length > 0 &&
    //       change.doc.data().timestamp >= loadedMessages[0].timestamp
    //         ? loadedMessages.unshift({
    //             uid: change.doc.ref.id,
    //             ...change.doc.data(),
    //           })
    //         : loadedMessages.push({
    //             uid: change.doc.ref.id,
    //             ...change.doc.data(),
    //           });
    //     }
    //     // if (change.type === "modified") {
    //     //   console.log("Modified: ", change.doc.data());
    //     //   loadedMessages.push({ uid: change.doc.ref.id, ...change.doc.data() })
    //     // }
    //     if (change.type === "removed") {
    //       // console.log("Removed: ", change.doc.data());
    //       loadedMessages.filter((item) => item.uid != change.doc.ref.id);
    //     }
    //   });
    //   // if(currentChannel !== this.props.currentChannel)
    //   //  console.log("props current channel", self.props.currentChannel);
    //   // console.log("loadedMessages", loadedMessages);
    //   loadedMessages = _.cloneDeep(loadedMessages);
    //   setAllMessages(loadedMessages);
    //   _handleStateChange("messages", loadedMessages);
    //   _handleStateChange("messagesLoading", false);
    // });

    // this.setState({ unsubscribe: unsub });

    // // const ref = this.getMessagesRef();
    // // ref.child(roomId).orderByKey().limitToLast(10).on("child_added", snap => {

    // //   if (!snap.val().hasOwnProperty("content") && !snap.val().hasOwnProperty("image")) {
    // //     if (snap.val().everyone)
    // //       // loadedMessages.push(snap.val())
    // //       loadedMessages = [snap.val(), ...loadedMessages]
    // //     else if (snap.val().users && Object.keys(snap.val().users).includes(this.state.user.uid))
    // //       // loadedMessages.push(snap.val())
    // //       loadedMessages = [snap.val(), ...loadedMessages]
    // //   } else loadedMessages = [snap.val(), ...loadedMessages]
    // //   // loadedMessages.push(snap.val())

    // //   this.setState({ messages: loadedMessages });
    // //   this.countUniqueUsers(loadedMessages);
    // //   this.countUserPosts(loadedMessages);
    // //   console.log(loadedMessages)
    // // });

    // // this.setState({ messagesLoading: false })
    // // this.addToListeners(channelId, ref, "child_added");
  };

  addMessageListener = (channelId) => {
    let loadedMessages = [];
    const ref = this.getMessagesRef();
    ref
      .child(channelId)
      .orderByKey()
      .limitToLast(10)
      .on("child_added", (snap) => {
        if (
          !snap.val().hasOwnProperty("content") &&
          !snap.val().hasOwnProperty("image")
        ) {
          if (snap.val().everyone)
            // loadedMessages.push(snap.val())
            loadedMessages = [snap.val(), ...loadedMessages];
          else if (
            snap.val().users &&
            Object.keys(snap.val().users).includes(this.state.user.uid)
          )
            // loadedMessages.push(snap.val())
            loadedMessages = [snap.val(), ...loadedMessages];
        } else loadedMessages = [snap.val(), ...loadedMessages];
        // loadedMessages.push(snap.val())

        this.setState({ messages: loadedMessages });
        this.countUniqueUsers(loadedMessages);
        this.countUserPosts(loadedMessages);
        console.log(loadedMessages);
      });
    // this.setState({ messages: loadedMessages });
    // this.countUniqueUsers(loadedMessages);
    // this.countUserPosts(loadedMessages);
    // console.log(loadedMessages)
    this.setState({ messagesLoading: false });
    this.addToListeners(channelId, ref, "child_added");
  };

  getMessagesRef = () => {
    const { messagesRef, privateMessagesRef, privateChannel } = this.state;
    return privateChannel ? privateMessagesRef : messagesRef;
  };

  handleSearchChange = (event) => {
    this.setState(
      {
        searchTerm: event.target.value,
        searchLoading: true,
      },
      () => this.handleSearchMessages()
    );
  };

  handleSearchMessages = () => {
    const { currentChannelMessages } = this.props;

    // const channelMessages = [...this.state.messages];
    const channelMessages = [...currentChannelMessages];

    const regex = new RegExp(this.state.searchTerm, "gi");
    const searchResults = channelMessages.reduce((acc, message) => {
      if (
        (message.content && message.content.match(regex)) ||
        message.user.name.match(regex)
      ) {
        acc.push(message);
      }
      return acc;
    }, []);
    this.setState({ searchResults });
    setTimeout(() => this.setState({ searchLoading: false }), 1000);
  };

  countUniqueUsers = (messages) => {
    const uniqueUsers = messages.reduce((acc, message) => {
      if (!acc.includes(message.user.name)) {
        acc.push(message.user.name);
      }
      return acc;
    }, []);
    const plural = uniqueUsers.length > 1 || uniqueUsers.length === 0;
    const numUniqueUsers = `${uniqueUsers.length} participant${
      plural ? "s" : ""
    } \n & ${messages.length} total messages`;
    this.setState({ numUniqueUsers });
  };

  countUserPosts = (messages) => {
    let userPosts = messages.reduce((acc, message) => {
      if (message.user.name in acc) {
        acc[message.user.name].count += 1;
      } else {
        acc[message.user.name] = {
          avatar: message.user.avatar,
          count: 1,
        };
      }
      return acc;
    }, {});
    this.props.setUserPosts(userPosts);
  };

  displayMessages = (messages, userId) =>
    messages.length > 0 &&
    messages.map((message) => (
      <>
        {message.type > 0 ? (
          message.all ? (
            <Message
              key={message.timestamp}
              message={message}
              user={this.state.user}
              channel={this.state.channel}
              allMessages={messages}
            />
          ) : message.assignedTo && message.createdBy ? (
            message.assignedTo.includes(userId) ||
            message.createdBy === userId ? (
              <Message
                key={message.timestamp}
                message={message}
                user={this.state.user}
                channel={this.state.channel}
                allMessages={messages}
              />
            ) : null
          ) : (
            <Message
              key={message.timestamp}
              message={message}
              user={this.state.user}
              channel={this.state.channel}
              allMessages={messages}
            />
          )
        ) : (
          <Message
            key={message.timestamp}
            message={message}
            user={this.state.user}
            channel={this.state.channel}
            allMessages={messages}
          />
        )}
      </>
    ));

  displayChannelName = (channel) => {
    return channel
      ? `${this.state.privateChannel ? "" : ""}${channel.name}`
      : "";
  };

  displayChannelAvatar = (channel) => {
    return channel
      ? channel.avatar
        ? channel.avatar
        : channel.createdBy.avatar
      : "";
  };

  displayTypingUsers = (users) =>
    users.length > 0 &&
    users.map((user) => (
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "0.2em" }}
        key={user.id}
      >
        <span className="user__typing">{user.name} is typing</span> <Typing />
      </div>
    ));

  displayMessageSkeleton = (loading) =>
    loading ? (
      // <React.Fragment>
      //   {[...Array(12)].map((_, i) => (
      //     <Skeleton key={i} />
      //   ))}
      // </React.Fragment>
      <Welcome />
    ) : null;

  onScrollMessages_new = (e) => {
    let element = e.target;
    //console.log(element.clientHeight);
    const {
      currentWorkspace,
      currentChannel,
      currentChannelMessages,
      setAllMessages,
    } = this.props;

    /* this.setState({
      currentOffsetY: element.scrollTop,
      currentOffsetYBottom:
        element.scrollHeight - element.clientHeight - element.scrollTop,
    });

    console.log(element.scrollHeight);
    console.log(element.scrollTop);
    console.log(element.clientHeight);*/
    if (browserName === "Edge") {
      this.setState({
        currentOffsetY: element.scrollTop,
        currentOffsetYBottom:
          element.scrollHeight - element.clientHeight - element.scrollTop,
      });
    } else {
      this.setState({
        currentOffsetY: -element.scrollTop,
        currentOffsetYBottom: -element.scrollTop,
      });
    }
    if (browserName === "Edge") {
      if (element.scrollTop === 0 && currentChannelMessages.length > 0) {
        // alert("Hey")
        this.setState({
          limitToLast: this.state.limitToLast + 10,
          showMessageTopLoader: true,
        });
        let loadedMessages = [...currentChannelMessages];
        const _handleStateChange = this._handleStateChange;

        const query = firebase
          .firestore()
          .collection(
            `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
          )
          .orderBy("timestamp", "desc")
          .startAfter(
            currentChannelMessages[currentChannelMessages.length - 1].timestamp
          )
          .limit(12);

        query.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              console.log("Added: ", change.doc.data());
              loadedMessages.length > 0 &&
              change.doc.data().timestamp >= loadedMessages[0].timestamp
                ? loadedMessages.unshift({
                    uid: change.doc.ref.id,
                    ...change.doc.data(),
                  })
                : loadedMessages.push({
                    uid: change.doc.ref.id,
                    ...change.doc.data(),
                  });
            }
          });
          //console.log("loadedMessages on scroll ", loadedMessages);
          setAllMessages(loadedMessages);
          // _handleStateChange("messages", loadedMessages);
          setTimeout(
            () => _handleStateChange("showMessageTopLoader", false),
            800
          );
        });
      }
    } else {
      if (
        element.scrollHeight + element.scrollTop === element.clientHeight &&
        currentChannelMessages.length > 0
      ) {
        // alert("Hey")
        this.setState({
          limitToLast: this.state.limitToLast + 10,
          showMessageTopLoader: true,
        });
        let loadedMessages = [...currentChannelMessages];
        const _handleStateChange = this._handleStateChange;

        const query = firebase
          .firestore()
          .collection(
            `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
          )
          .orderBy("timestamp", "desc")
          .startAfter(
            currentChannelMessages[currentChannelMessages.length - 1].timestamp
          )
          .limit(12);

        query.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              console.log("Added: ", change.doc.data());
              loadedMessages.length > 0 &&
              change.doc.data().timestamp >= loadedMessages[0].timestamp
                ? loadedMessages.unshift({
                    uid: change.doc.ref.id,
                    ...change.doc.data(),
                  })
                : loadedMessages.push({
                    uid: change.doc.ref.id,
                    ...change.doc.data(),
                  });
            }
          });
          // console.log("loadedMessages on scroll ", loadedMessages);
          setAllMessages(loadedMessages);
          // _handleStateChange("messages", loadedMessages);
          setTimeout(
            () => _handleStateChange("showMessageTopLoader", false),
            800
          );
        });
      }
    }

    // const ref = this.getMessagesRef();
    // ref.child(this.props.currentChannel.id).orderByKey().limitToLast(this.state.limitToLast).on("child_added", snap => {

    //   if (!snap.val().hasOwnProperty("content") && !snap.val().hasOwnProperty("image")) {
    //     if (snap.val().everyone)
    //       // loadedMessages.push(snap.val())
    //       loadedMessages = [snap.val(), ...loadedMessages]
    //     else if (snap.val().users && Object.keys(snap.val().users).includes(this.state.user.uid))
    //       // loadedMessages.push(snap.val())
    //       loadedMessages = [snap.val(), ...loadedMessages]
    //   } else loadedMessages = [snap.val(), ...loadedMessages]
    //   // loadedMessages.push(snap.val())

    //   // this.setState({ messages: loadedMessages });
    //   // console.log(loadedMessages)
    // });
    // this.setState({ messages: loadedMessages });
    // setTimeout(() => this.setState({ showMessageTopLoader: false }), 1000)
    // console.log(loadedMessages)
  };

  onScrollMessages = (e) => {
    let element = e.target;

    this.setState({
      currentOffsetY: element.scrollTop,
      currentOffsetYBottom:
        element.scrollHeight - element.clientHeight - element.scrollTop,
    });

    // console.log(element.scrollHeight, element.scrollTop, element.clientHeight)
    // if (element.scrollHeight - element.scrollTop === element.clientHeight) {
    if (element.scrollTop === 0) {
      // alert("Hey")
      this.setState({
        limitToLast: this.state.limitToLast + 10,
        showMessageTopLoader: true,
      });
      let loadedMessages = [];
      const ref = this.getMessagesRef();
      ref
        .child(this.props.currentChannel.id)
        .orderByKey()
        .limitToLast(this.state.limitToLast)
        .on("child_added", (snap) => {
          if (
            !snap.val().hasOwnProperty("content") &&
            !snap.val().hasOwnProperty("image")
          ) {
            if (snap.val().everyone)
              // loadedMessages.push(snap.val())
              loadedMessages = [snap.val(), ...loadedMessages];
            else if (
              snap.val().users &&
              Object.keys(snap.val().users).includes(this.state.user.uid)
            )
              // loadedMessages.push(snap.val())
              loadedMessages = [snap.val(), ...loadedMessages];
          } else loadedMessages = [snap.val(), ...loadedMessages];
          // loadedMessages.push(snap.val())

          // this.setState({ messages: loadedMessages });
          // this.countUniqueUsers(loadedMessages);
          // this.countUserPosts(loadedMessages);
          // console.log(loadedMessages)
        });
      this.setState({ messages: loadedMessages });
      setTimeout(() => this.setState({ showMessageTopLoader: false }), 1000);
      this.countUniqueUsers(loadedMessages);
      this.countUserPosts(loadedMessages);
      //console.log(loadedMessages);
    }
  };

  getAvatar = (key) => {
    let avatar = "";
    firebase
      .database()
      .ref("users")
      .child(key)
      .on("value", (snap) => {
        avatar = snap.val() ? snap.val().avatar : "";
      });
    return avatar;
  };

  render() {
    const {
      messagesRef,
      messages,
      channel,
      user,
      numUniqueUsers,
      searchTerm,
      searchResults,
      searchLoading,
      privateChannel,
      typingUsers,
      messagesLoading,
    } = this.state;

    const {
      isVideoVisible,
      channelDetails,
      currentChannelMessages,
      currentUser,
    } = this.props;

    //console.log(channel);
    //console.log(channelDetails);
    const handleDrop = (ev) => {
      //console.log("File(s) dropped");
      ev.preventDefault();

      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (var i = 0; i < ev.dataTransfer.items.length; i++) {
          if (ev.dataTransfer.items[i].kind === "file") {
            var file = ev.dataTransfer.items[i].getAsFile();
            //  console.log(file);
            // console.log("... file[" + i + "].name = " + file.name);
            this.setState({ file });
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (var i = 0; i < ev.dataTransfer.files.length; i++) {
          console.log(
            "... file[" + i + "].name = " + ev.dataTransfer.files[i].name
          );
        }
      }
    };
    {
      /*window.ondrop = function handleOnDrop(e) {
      e.preventDefault();
      e.stopPropagation();

      console.log(e);
    };*/
    }
    {
      /*this.main
      // crucial for the 'drop' event to fire
      .on("dragover", false)

      .on("drop", function (e) {
        console.log(e);
        return false;
      });*/
    }
    const handleDrag = (ev) => {
      console.log("File(s) in drop zone");

      // Prevent default behavior (Prevent file from being opened)
      ev.preventDefault();
    };
    const removeFile = () => {
      this.setState({ file: null });
    };
    //console.log(this.state.file);
    //console.log(currentChannelMessages);
    return (
      <div
        id="main"
        // ref={this.main}
        onDrop={channel ? (event) => handleDrop(event) : null}
        onDragOver={channel ? (event) => handleDrag(event) : null}
        //style={{ position: "relative" }}
      >
        {/*{this.state.file !== null ? (
          <div
            className="row bg-light"
            style={{
              position: "absolute",
              top: "10%",
              left: "0.2%",
              width: "100%",
              height: "100%",
              zIndex: 30,
            }}
          >
            <div className="col-12 border-1 ">
              <h1 className="text-white">Preview</h1>
              <span>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
          </div>
        ) : null}*/}
        {/*<Dropzone onDrop={handleOnDrop}>
            {({ getRootProps, getInputProps }) => (
              <section
                className=""
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  opacity: 0,
                }}
              >
                <div
                  {...getRootProps()}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    opacity: 0,
                  }}
                >
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                </div>
              </section>
            )}
          </Dropzone>*/}

        {this.displayMessageSkeleton(messagesLoading)}
        {messagesLoading ? null : (
          <>
            <RoomHeader
              numUniqueUsers={numUniqueUsers}
              handleSearchChange={this.handleSearchChange}
              searchLoading={searchLoading}
              isPrivateChannel={privateChannel}
              userId={user.uid}
              channelId={channel}
            />
            <img
              src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-positive-sm.png?alt=media&token=c7b1d5b9-c7b1-4e6d-a771-3d88321d20f6"
              width="80px"
              style={{
                position: "absolute",
                top: 70,
                right: `calc(50% - 40px )`,
                zIndex: 300,
                opacity: 0.35,
              }}
            />
            {isVideoVisible ? (
              <></>
            ) : (
              this.state.liveUsers &&
              this.state.liveUsers.length > 0 && (
                // <div className="bg-card-theme p-2 m-2 shadow-sm text-theme rounded-lg d-flex" style={{position: "absolute"}}>
                <div
                  className="bg-card-theme text-theme d-flex p-1"
                  style={{ position: "absolute", zIndex: 100 }}
                >
                  {/* <p className="mb-0 d-none">{JSON.stringify(this.state.liveUsers)}</p> */}
                  {/* <p>Currently Live</p> */}
                  {this.state.liveUsers.map((user) => (
                    // <div className="mr-3 d-inline-block">
                    //   <img src={this.getAvatar(user.userId)}
                    //   className="rounded-circle d-block mb-1" style={{ height: 30, width: 30 }} />
                    //   <p className="mb-0">{user.userName}</p>
                    // </div>
                    <div className="bg-card-theme rounded-pill p-1 d-flex align-items-center">
                      <img
                        src={this.getAvatar(user.userId)}
                        className="rounded-circle d-block"
                        style={{ height: 30, width: 30 }}
                      />
                      <p className="text-theme mb-0 px-1 small">
                        {user.userName}
                      </p>
                    </div>
                  ))}
                </div>
              )
            )}
          </>
        )}

        {this.props.showPRF && (
          <StyleRoot>
            <PatientRegistrationModal />
          </StyleRoot>
        )}

        {this.props.showWhiteboard && (
          <StyleRoot>
            <Whiteboard />
          </StyleRoot>
        )}
        {this.props.showCaseSheet && (
          <StyleRoot>
            <CaseSheet />
          </StyleRoot>
        )}

{this.props.showNoteApp && 
         <StyleRoot>
         <NoteApp />
       </StyleRoot>}

 {this.props.showPrescription && (
          <StyleRoot>
            <PrescriptionApp />
          </StyleRoot>
        )}
        {/*  Messages  */}

        {!this.props.showGradebook &&
          !this.props.showAttendanceLedger &&
          (messagesLoading ? null : <Chat />)}

        {!this.props.showGradebook &&
          !this.props.showAttendanceLedger &&
          (messagesLoading ? null : (
            <div className="message__form">
              <ActionBox
                isPrivateChannel={privateChannel}
                allMessages={messages}
                file={this.state.file}
                removeFile={removeFile}
              />
            </div>
          ))}

        {this.props.showGradebook && <Gradebook />}

        {this.props.showAttendanceLedger && (
          <AttendanceLedger channelData={channel} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showWhiteboard: state.whiteboard.showWhiteboard,
  showNoteApp: state.noteApp.showNoteApp,
  showGradebook: state.gradebook.showGradebook,
  showAttendanceLedger: state.attendanceLedger.showAttendanceLedger,
  currentChannel: state.channel.currentChannel,
  allChannels: state.channel.allChannels,
  isVideoVisible: state.platform.isVideoVisible,
  channelDetails: state.platform.channelDetails,
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannelMessages: state.channel.currentChannelMessages,
  showCaseSheet: state.caseSheet.showCaseSheet,
  showPRF: state.caseSheet.showPRF,
  showPrescription: state.prescription.showPrescription
});

export default connect(mapStateToProps, {
  setUserPosts,
  setIsVideoVisible,
  setChannelDetails,
  setAllMessages,
  setCurrentChannel,
  setRightPanel,
  setParticipants,
})(windowSize(Middle));
