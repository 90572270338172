import React from 'react';
import { Button, Form, ButtonGroup, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";
import { connect } from 'react-redux';
import {
    setQuizId,
    setQuizTitle,
    setQuizQuestions,
    setQuizOptions,
    setQuizTypes,
    setQuizCorrectAnswers,
    setQuizWeights,
    setQuizTotalWeight,
    setQuizGraded
} from './actions';
import {
    setTaskDeadline,
    setTaskChannelUsers,
    setTaskFolder,
    setTaskEveryone,
    setTaskOldFolder,
    editTask,
    editSaveTask,
    publishSaveTask
} from '../../../actions/index';
import TaskCommon from '../Common/TaskCommon';

class EditQuiz extends React.Component {
    state = {
        addAll: false
    }
    componentDidMount() {
        let temp_questions = [], temp_options = [], temp_types = [], temp_weights = [];
        this.props.data.quiz_questions.map((quiz_question, key) => {
            temp_questions.push(quiz_question.question)
            temp_options.push(quiz_question.options)
            temp_types.push(quiz_question.type)
            temp_weights.push(quiz_question.weight)
        });

        let temp_correctAnswers = [];
        temp_options.map((item, key) => {
            if (temp_types[key] === "single")
                temp_correctAnswers[key] = item.indexOf(this.props.data.correct_answers[key]);
            
            else
                temp_correctAnswers[key] = this.props.data.correct_answers[key].map(i => item.indexOf(i))
        })

        const {
            setQuizId,
            setQuizTitle,
            setQuizQuestions,
            setQuizOptions,
            setQuizTypes,
            setQuizCorrectAnswers,
            setQuizWeights,
            setQuizTotalWeight,
            setQuizGraded,
            
            setTaskDeadline,
            setTaskChannelUsers,
            setTaskFolder,
            setTaskEveryone,
            setTaskOldFolder
        } = this.props;

        setQuizId(this.props.taskId);
        setQuizTitle(this.props.data.quiz_name);
        setQuizQuestions(temp_questions);
        setQuizOptions(temp_options);
        setQuizTypes(temp_types);
        setQuizCorrectAnswers(temp_correctAnswers);
        setQuizWeights(temp_weights);
        setQuizTotalWeight(this.props.data.totalWeight);
        setQuizGraded(this.props.data.totalWeight ? true : false);

        setTaskDeadline(new Date(this.props.data.deadline))
        setTaskChannelUsers(Object.keys(this.props.data.users))
        setTaskFolder(this.props.data.folder)
        setTaskEveryone(this.props.data.everyone)
        setTaskOldFolder(this.props.data.folder)
    }

    handleChange = e => {
        if (e.target.name === "quizTitle") {
            this.props.setQuizTitle(e.target.value)
        }
    }

    // ADDING QUESTION + OPTIONS + CORRECT_ANSWER
    addQuestion = () => {
        let prevQuestion = [...this.props.questions];
        prevQuestion[prevQuestion.length] = "";
        this.props.setQuizQuestions(prevQuestion);

        let prevOptions = [...this.props.options];
        prevOptions[prevOptions.length] = [""];
        this.props.setQuizOptions(prevOptions);

        let prevCorrectAnswer = [...this.props.correctAnswers];
        prevCorrectAnswer[prevCorrectAnswer.length] = 0;
        this.props.setQuizCorrectAnswers(prevCorrectAnswer);

        let prevWeights = [...this.props.weights];
        prevWeights[prevWeights.length] = 0;
        this.props.setQuizWeights(prevWeights);

        let prevTypes = [...this.props.types];
        prevTypes[prevTypes.length] = "single";
        this.props.setQuizTypes(prevTypes);
    }
    addOption = (i) => {
        let prevOptions = this.props.options;
        let thatOption = prevOptions[i];
        prevOptions[i] = [...thatOption, ""];
        this.props.setQuizOptions(prevOptions);
        this.props.setQuizQuestions([...this.props.questions]);
    }

    // REMOVING QUESTION + OPTIONS
    removeQuestion = (key) => {
        let prevQuestions = [...this.props.questions];
        prevQuestions.splice(key,1);
        this.props.setQuizQuestions(prevQuestions);

        let prevOptions = [...this.props.options];
        prevOptions.splice(key,1);
        this.props.setQuizOptions(prevOptions);

        let prevAnswers = [...this.props.correctAnswers];
        prevAnswers.splice(key,1);
        this.props.setQuizCorrectAnswers(prevAnswers);

        let prevWeights = [...this.props.weights];
        prevWeights.splice(key, 1);
        this.props.setQuizWeights(prevWeights);

        let prevTypes = [...this.props.types];
        prevTypes.splice(key, 1);
        this.props.setQuizTypes(prevTypes);
    }
    removeOption = (key,k) => {
        let prevOptions = this.props.options;
        let thatOption = prevOptions[key];
        
        let prevAnswers = [...this.props.correctAnswers];

        if (thatOption[k] == prevAnswers[key]) {
            prevAnswers[key] = thatOption[k+1] ? thatOption[k+1] : 0;
            thatOption.splice(k,1);
            this.props.setQuizCorrectAnswers(prevAnswers);
        } else thatOption.splice(k,1);

        prevOptions[key] = thatOption;
        this.props.setQuizOptions(prevOptions);
    }

    // HANDLES QUESTIONS ADDED
    handleQuestions = (e,key) => {
        let prev = [...this.props.questions];
        prev[key] = e.target.value;
        this.props.setQuizQuestions(prev);
    }
    handleOptions = (e,key,k) => {
        let prevOptions = this.props.options;
        let thatOption = [...prevOptions[key]];
        thatOption[k] = e.target.value;
        prevOptions[key] = thatOption;
        this.props.setQuizOptions(prevOptions);
        this.props.setQuizQuestions([...this.props.questions]);
    }
    handleAnswer = (val,key) => {
        const { types, correctAnswers, setQuizCorrectAnswers } = this.props;
        let prev = [...correctAnswers];

        if (types[key] === "single") {

            prev[key] = val;
        } else {

            let temp = prev[key] ? prev[key] : [];
            prev[key] = temp.includes(val) ? temp.filter(item => item !== val) : [...temp, val];
        }
        setQuizCorrectAnswers(prev);
    }
    handleWeights = (e, key) => {
        let prev = [...this.props.weights];
        prev[key] = parseInt(e.target.value);
        this.props.setQuizWeights(prev);
    }
    handleTypes = (val, key) => {
        let prev = [...this.props.types];
        prev[key] = val ? "multiple" : "single";
        this.props.setQuizTypes(prev);
    }

    render() {
        const { 
            type, 
            quizTitle, 
            questions, 
            options, 
            types,
            correctAnswers, 
            weights, 
            totalWeight, 
            graded, 
            deadline, 
            channelUsers, 
            folder, 
            old_folder, 
            everyone, 
            editTask, 
            uid, 
            currentChannel, 
            currentUser, 
            data, 
            taskBranch } = this.props;

        const validateData = () => {
            if (quizTitle.length == 0) {
                this.setState({error: "Quiz title cannot be empty!\n"});
                return null;
            }

            let filtered_questions = questions.filter(question => question.length > 0);
            if (questions.length != filtered_questions.length) {
                this.setState({error: "Please add some value to question(s).\n"});
                return null;
            }
            if (questions.length == 0) {
                this.setState({error: "Quiz must have at-least one or more question(s).\n"});
                return null;
            }

            if (graded && parseInt(totalWeight) === 0) {
                this.setState({error: "Total weight should be greater than '0'.\n"});
                return null;
            }
            
            let filtered_weights = weights.filter(weight => parseInt(weight) > 0);
            if (graded && weights.length !== filtered_weights.length) {
                this.setState({ error: "Please add some value to weight(s).\n" });
                return null;
            }

            let filtered_options = options.filter(item => item.length < 2);
            if (filtered_options.length > 0) {
                this.setState({error: "Question must have at-least two or more option(s).\n"});
                return null;
            }
            let empty_options = options.flat();
            let filtered_empty_options = empty_options.filter(item => item.length > 0)
            if (filtered_empty_options.length != empty_options.length) {
                this.setState({error: "Please add some value to option(s).\n"});
                return null;
            }

            // let filtered_correctAnswers = correctAnswers.filter(correctAnswer => correctAnswer.length > 0);
            // if (correctAnswers.length != filtered_correctAnswers.length) {
            //     this.setState({error: "Please select a correct option for all question(s).\n"});
            //     return null;
            // }
            if (correctAnswers.length !== questions.length) {
                this.setState({error: "Please select a correct option for all question(s).\n"});
                return null;
            }

            if ( moment(deadline).diff( moment(Date.now()), 'minutes') < 9 ) {
                this.setState({error: "Deadline of task should be atleast 10 minutes.\n"});
                return null;
            }

            if (!everyone && channelUsers.length < 2) {
                this.setState({error: "Please assign task to atleast one user.\n"});
                return null;
            }

            this.setState({error: ""});
            return true;
        }

        const getQuiz = () => {
            
            let quiz_name = quizTitle;
            let quiz_total_questions = questions.length;
            let correct_answers = [];
            correctAnswers.map((item, key) => {
                if (types[key] === "single")
                    correct_answers[key] = options[key][item];

                else 
                    correct_answers[key] = item.map(a => options[key][a])
            });

            let quiz_questions = [];
            questions.map((question, key) => 
                quiz_questions.push({
                    question_number: key + 1,
                    question,
                    type: types[key],
                    options: options[key],
                    correct_answer: correct_answers[key],
                    weight: weights[key]
                })
            );

            let user = data.user;
            let timestamp = data.timestamp;
            let response = data.response ? data.response : null;

            return {type, quiz_name, quiz_total_questions, quiz_questions, totalWeight, deadline, channelUsers, correct_answers, user, timestamp, uid, response, folder, everyone};
        }

        return (
            <React.Fragment>
                <div className="taskBox">
                <h5>Quiz</h5>
                    <Form className="feedback">

                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Quiz Title"
                                aria-label="Quiz Title"
                                aria-describedby="quiz-title"
                                name="quizTitle"
                                value={quizTitle}
                                onChange={this.handleChange}
                            />
                        </InputGroup>

                        {questions.length > 0
                        ? questions.map((question, key) => 
                            (<div>
                                <div className="d-flex">
                                    <p className="mb-0 flex-grow-1">Question {key + 1}.</p>
                                    <div><FontAwesomeIcon icon={faTimes} onClick={() => this.removeQuestion(key)} /></div>
                                </div>
                                <InputGroup className="mb-1">
                                    <FormControl
                                        placeholder="Question"
                                        aria-label="Question"
                                        aria-describedby={"question" + key}
                                        as="textarea"
                                        name="questions[]"
                                        value={questions[key]}
                                        id={key}
                                        onChange={e => this.handleQuestions(e, key)}
                                        required={true}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-2">
                                    <FormControl
                                        placeholder="Weight"
                                        name="weights[]"
                                        value={weights[key]}
                                        id={key}
                                        type="number"
                                        min="0"
                                        onChange={e => this.handleWeights(e, key)}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text> points</InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>

                                <Form.Group className="mb-1">
                                    <Form.Check
                                    required
                                    label="Multiple answers"
                                    value={types[key] === "multiple" ? true : false}
                                    checked={types[key] === "multiple" ? true : false}
                                    onChange={e => this.handleTypes(e.target.value, key)}
                                    />
                                </Form.Group>

                                {options[key].map((option, k) => (
                                    <InputGroup className="mb-1">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text>
                                                {options[key][k] 
                                                    && <Form.Check 
                                                    onChange={() => this.handleAnswer(k,key)} 
                                                    type={types[key] === "single" ? "radio" : "checkbox"} 
                                                    checked={
                                                        types[key] === "single" 
                                                        ?   correctAnswers[key] === k ? true : false
                                                        :   correctAnswers[key] && correctAnswers[key].includes(k) ? true : false} 
                                                    name={"options_" + key} />}
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            placeholder="Option"
                                            name={options[key]}
                                            value={options[key][k]}
                                            id={k}
                                            onChange={e => this.handleOptions(e,key,k)}
                                            required={true}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faTimes} onClick={() => this.removeOption(key,k)} />
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                ))}
                                <InputGroup className="w-100 mb-1">
                                    <Button 
                                    size="sm" 
                                    variant="outline-dark" 
                                    className="w-100"
                                    onClick={() => this.addOption(key)}>+ <small>Add Option</small></Button>
                                </InputGroup>
                            </div>))
                        : null}

                        <InputGroup className="w-100 mb-3">
                            <Button 
                            size="sm" 
                            variant="outline-primary" 
                            className="w-100"
                            onClick={this.addQuestion}>+ <small>Add Question</small></Button>
                        </InputGroup>

                        <p className="mb-1">Gradebook</p>
                        <Form.Group className="mb-1">
                            <Form.Check
                            required
                            label="Add to Gradebook"
                            value="graded"
                            checked={graded}
                            onChange={() => this.props.setQuizGraded(!graded)}
                            />
                        </Form.Group>

                        {graded && <><p className="pb-0 mb-0">Total weight</p>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Weight"
                                name="totalWeight"
                                type="number"
                                min="0"
                                onChange={e => this.props.setQuizTotalWeight(parseInt(e.target.value))}
                                value={totalWeight}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text> points</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup></>}

                        <TaskCommon />
                        
                        <div className="d-flex">
                            {taskBranch === "saved"
                                ? <>
                                    <Button 
                                    onClick={() => validateData() && this.props.publishSaveTask(getQuiz(), uid, old_folder, currentChannel, currentUser)} 
                                    variant="primary" 
                                    style={{width: "100px", marginRight: "5px"}}
                                    size="sm" ><small>Share now</small></Button>

                                    <Button 
                                    onClick={() => validateData() && this.props.editSaveTask(getQuiz(), uid, old_folder, currentChannel, currentUser)} 
                                    variant="success" 
                                    style={{width: "100px", marginLeft: "5px"}}
                                    size="sm" ><small>Save for later</small></Button>
                                </>
                                :
                                <Button 
                                onClick={() => validateData() && editTask(getQuiz(), uid, old_folder, currentChannel, currentUser)} 
                                variant="primary" 
                                style={{width: "100px", marginRight: "5px"}}
                                size="sm" ><small>Share now</small></Button>}                            
                        </div>
                        <p className="my-2 p-0 text-danger">{this.state.error}</p>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentChannel: state.channel.currentChannel,
    currentUser: state.user.currentUser,

    taskId: state.platform.taskId,
    taskBranch: state.platform.taskBranch,

    deadline: state.task.deadline,
    channelUsers: state.task.channelUsers,
    folder: state.task.folder,
    old_folder: state.task.old_folder,
    everyone: state.task.everyone,

    uid: state.quiz.id,
    quizTitle: state.quiz.quizTitle,
    questions: state.quiz.questions,
    options: state.quiz.options,
    types: state.quiz.types,
    correctAnswers: state.quiz.correctAnswers,
    weights: state.quiz.weights,
    totalWeight: state.quiz.totalWeight,
    graded: state.quiz.graded,
    type: state.quiz.type
})

export default connect(
    mapStateToProps, {
        setQuizId,
        setQuizTitle,
        setQuizQuestions,
        setQuizOptions,
        setQuizTypes,
        setQuizCorrectAnswers,
        setQuizWeights,
        setQuizTotalWeight,
        setQuizGraded,
        
        setTaskDeadline,
        setTaskChannelUsers,
        setTaskFolder,
        setTaskEveryone,
        setTaskOldFolder,
        editTask,
        editSaveTask,
        publishSaveTask
    })(EditQuiz);