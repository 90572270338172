import React from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import IsAdmin from "../../IsAdmin";

const AllReadings = ({
  allReadings,
  CustomToggle,
  showSelectedReading,
  shareTaskAgain,
  editPublishedTask,
  closeAppResponses,
}) => {
  return (
    <div>
      <p className="rounded-lg p-2 m-2 border-1 text-theme">Files</p>
      {allReadings.map((reading, index) => (
        <div
          key={index + reading.id}
          className={
            reading.deadline > Date.now()
              ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
              : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
          }
        >
          <div
            className="d-flex justify-content-between mb-1 pointer"
            onClick={() => showSelectedReading(reading.id)}
          >
            <span className="text-theme-lighter smaller">
              {moment(reading.timestamp).format("LLL")}
            </span>
            <span
              className={
                reading.deadline > Date.now()
                  ? "text-success smaller"
                  : "text-secondary smaller"
              }
            >
              {reading.deadline > Date.now() ? "Active" : "Closed"}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <p
              className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
              onClick={() => showSelectedReading(reading.id)}
            >
              {reading.readingSubject}
            </p>
            <IsAdmin>
              <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                <Dropdown.Toggle size="sm" as={CustomToggle} />
                <Dropdown.Menu className="bg-card-theme text-theme">
                  <Dropdown.Item
                    className="small bg-card-theme text-theme"
                    onClick={() => shareTaskAgain(reading)}
                  >
                    Duplicate{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="small bg-card-theme text-theme"
                    onClick={() => editPublishedTask(reading)}
                  >
                    Edit{" "}
                  </Dropdown.Item>
                  {reading.deadline > Date.now() ? (
                    <Dropdown.Item
                      className="small bg-card-theme text-theme"
                      onClick={() =>  closeAppResponses(reading)}
                    >
                      Close {" "}
                    </Dropdown.Item>
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>
            </IsAdmin>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllReadings;
