import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import {
  Modal,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  InputGroup,
  FormControl,
  Tabs,
  Tab,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { postData } from "../../utils/index";
import {
  API_BASE_URL,
  INVITE_LINK_GENERATE,
  ACCESS_KEY,
  SECRET_KEY,
  SEND_EMAIL,
  SCHEDULE_CREATE,
  INVITE_SEND,
} from "../../config/index";
import moment from "moment";
import axios from "axios";
import DateTimePicker from "react-datetime-picker";
import { toast } from "react-toastify";
toast.configure();

function AddMemberInvite({
  currentWorkspace,
  currentChannel,
  workspace,
  currentUser,
  children,
  darkTheme,
  className,
  style,
}) {
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [sharableLink, setSharableLink] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [generateLink, setGenerateLink] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [sentViaEmailModal, setSentViaEmailModal] = useState(false);
  const [emailField, setEmailField] = useState("");
  const [allEmailsArray, setAllEmailsArray] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const [isMeeting, setIsMeeting] = useState(false);
  const [deadline, setDeadline] = useState(new Date(Date.now() + 10 * 60000));
  const [subjectMeeting, setSubjectMeeting] = useState("");
  const [errorObj, setErrorObject] = useState({ subject: "" });

  const textAreaRef = useRef(null);
  const [generateButton, setGenerateButton] = useState(false);
  const [validity, setValidity] = useState(7);

  const [emailArray, setEmailArray] = useState([]);
  const [email, setEmail] = useState("");
  const [mailError, setMailError] = useState("");
  const [sendMailButton, setSendMailButton] = useState(false);

  const [key, setKey] = useState("Get shareable link");


  function copyToClipboard(e) {
    const el = document.createElement("textarea");
    el.value = textAreaRef.current.innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess("Link Copied!");
    document.body.removeChild(el);
    console.log(generateButton);
  }
  async function sentEmailInvitaton(e, props) {
    setIsSending(true);
    setTimeout({}, 1000);
    const el = document.createElement("textarea");
    el.value = textAreaRef.current.innerText;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    e.target.focus();
    //setCopySuccess("Link Copied!");
    document.body.removeChild(el);
    const config = {
      headers: {
        "content-type": "application/json",
        "ACCESS-KEY": `${ACCESS_KEY}`,
        "SECRET-KEY": `${SECRET_KEY}`,
      },
    };
    if (isMeeting) {
      if (subjectMeeting.trim().length < 1) {
        setError("Subject can not be empty.");
        return;
      }
      try {
        let response = await axios.post(`${API_BASE_URL}${SCHEDULE_CREATE}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          user_id: currentUser.uid,
          assigned: allEmailsArray,
          schedule_title: subjectMeeting,
          html_content: htmlContent,
          is_invite: true,
          deadline: deadline,
          all_participants: false,
        });
        console.log(response);
        if (response.status === 200) {
          setAllEmailsArray([]);
          setIsSending(false);
          setSharableLink("");
          setHtmlContent("");
          setSentViaEmailModal(false);
          setShow(false);
          setSentViaEmailModal(!sentViaEmailModal);
          setEmailField("");
          setSubjectMeeting("");
          setError("");
          setIsMeeting(false);
          setDeadline(new Date(Date.now() + 10 * 60000));
          if(darkTheme){
            toast.dark("Invitation sent", {
              position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000
            });
          }else if(!darkTheme){
            toast("Invitation sent", {
              position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000
            });
          }
          
        } else {
          setIsSending(false);
        }
      } catch (error) {
        console.log(error, "error while sending email");
      }
    } else {
      try {
        let response = await axios.post(
          `${API_BASE_URL}${SEND_EMAIL}`,
          {
            to: allEmailsArray,
            subject: "Joining invitation",
            content: htmlContent,
          },
          config
        );
        console.log(response);
        if (response.status === 200) {
          setAllEmailsArray([]);
          setIsSending(false);
          setSharableLink("");
          setHtmlContent("");
          setSentViaEmailModal(false);
          setShow(false);
          if(darkTheme){
            toast.dark("Invitation sent", {
              position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000
            });
          }else if(!darkTheme){
            toast("Invitation sent", {
              position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000
            });
          }
        } else {
          setIsSending(false);
        }
      } catch (error) {
        console.log(error, "error while sending email");
      }
    }
  }
  function sentViaEmail() {
    setSentViaEmailModal(!sentViaEmailModal);
    setEmailField("");
    setError("");
    setAllEmailsArray([]);
    setIsMeeting(false);
    setDeadline(new Date(Date.now() + 10 * 60000));
  }

  const _handleGenerateLink = () => {
    setGenerateButton(true);

    const inviteLinkGenerateData = {
      workspace_id: currentWorkspace.workspaceId,
      room_id: currentChannel.roomId,
      user_id: currentUser.uid,
      expiration_days: validity,
    };

    postData(`${API_BASE_URL}${INVITE_LINK_GENERATE}`, inviteLinkGenerateData)
      .then((response) => {
        const { data, message, status } = response;
        console.log(response, "res from invite");
        if (status === 200) {
          setSharableLink(
            <p>
              Welcome to the Igesia blended learning and collaboration platform!{" "}
              <br></br>
              <br></br>
              <strong>{currentUser.displayName}</strong> has invited you to join
              the meeting room <strong>{currentChannel.roomName}</strong>.
              Please join with the following link: <br></br>
              <span>🔗</span>{" "}
              <a
                href={`${data.invite_link
                  .replace("web", "my")
                  .replace("#/", "")}`}
                target="_blank"
              >
                {data.invite_link.replace("web", "my").replace("#/", "")}
              </a>
              <br></br>
              <br></br>
              This link is valid till -{" "}
              {moment(new Date(data.expiry)).format("ll")}. Please do not share
              the link with others for your own security.<br></br>
              <br></br>
              You can join Igesia from the following devices:<br></br>
              <br></br>
              Laptop/ PC: Please use Chrome, Edge or Firefox browsers<br></br>
              Android Phone: Please download android app from{" "}
              <a href="http://bit.ly/igesia-android" target="_blank">
                http://bit.ly/igesia-android
              </a>
              <br></br>
              iPhone/ iPad: Please download ios app from{" "}
              <a href="http://bit.ly/igesia-ios" target="_blank">
                http://bit.ly/igesia-ios
              </a>
              <br></br>
            </p>
          );
          setHtmlContent(`<p>
          Welcome to the Igesia blended learning and collaboration platform!
          <br></br>
          <br></br>
          <strong>${currentUser.displayName}</strong> has invited you to join
          the meeting room <strong>${currentChannel.roomName}</strong>.
          Please join with the following link: <br></br>
          <span>🔗</span>
          <a
            href=${data.invite_link.replace("web", "my").replace("#/", "")}
            target="_blank"
          >
            ${data.invite_link.replace("web", "my").replace("#/", "")}
          </a>
          <br></br>
          <br></br>
          This link is valid till -
          ${moment(new Date(data.expiry)).format("ll")}. Please do not share
          the link with others for your own security.<br></br>
          <br></br>
          You can join Igesia from the following devices:<br></br>
          <br></br>
          Laptop/ PC: Please use Chrome, Edge or Firefox browsers<br></br>
          Android Phone: Please download android app from
          <a href="http://bit.ly/igesia-android" target="_blank">
            http://bit.ly/igesia-android
          </a>
          <br></br>
          iPhone/ iPad: Please download ios app from
          <a href="http://bit.ly/igesia-ios" target="_blank">
            http://bit.ly/igesia-ios
          </a>
          <br></br>
        </p>`);
        } else {
          console.log(message);
        }
        setGenerateButton(false);
      })
      .catch((error) => {
        console.error(error);
        setGenerateButton(false);
      });
  };

  const handleShow = () => setShow(true);

  const handleHide = () => {
    setShow(false);
    setSharableLink("");
    setGenerateLink(false);
    setCopySuccess("");
    setGenerateButton(false);
    setValidity(7);
  };

  const validateDataOfField = (e) => {
    let errorObj = {};
    if (e.target.name === "subjectMeeting") {
      if (e.target.value.trim().length < 1) {
        errorObj.subject = "Subject cannot be empty.";
      } else {
        errorObj.subject = "";
      }
    }
    setErrorObject(errorObj);
  };
  const handleChange = (e) => {
    if (e.target.name === "subjectMeeting") {
      setSubjectMeeting(e.target.value);
    }
  };

  const onChange = (deadline) => {
    setDeadline(deadline);
  };

  const inviteSend = (e) => {

    //console.log(currentUser.uid, currentChannel.roomId, workspace.workspaceId,)
    axios.post(`${API_BASE_URL}${INVITE_SEND}`, {
      workspace_id: workspace.workspaceId,
      room_id: currentChannel.roomId,
      invitee_emails: emailArray,
      invitor_id: currentUser.uid,
    }).then((res) => {
      if(darkTheme){
        toast.dark("Invitation sent", {
          position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000
        });
      }else if(!darkTheme){
        toast("Invitation sent", {
          position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000
        });
      }
      setEmailArray([]);
    })
  }

  return (
    <>
      <div className={className} style={style} onClick={handleShow}>
        {children}
      </div>
      

      <Modal show={show} onHide={handleHide} animation={false} className=" border-0 p-0">
        <Modal.Header className="border-0 bg-card-theme text-theme">
          <div>
            <h3>Invite</h3>
          </div>
          <div title="Close" className="pointer  mb-0" onClick={() =>  handleHide() }>
            <FontAwesomeIcon className="sm text-theme" icon={faTimes} />
          </div>
        </Modal.Header>
        <Tabs  
          className="bg-card-theme text-theme" 
          activeKey={key} 
          onSelect={(k) => setKey(k)} 
          id="controlled-tab-example"
          transition={false}
        >
            <Tab 
              tabClassName={(key === "Get shareable link") ? "text-theme-important w-50 text-center bg-theme border-0 " : "w-50 text-center bg-card-theme text-theme border-0"} 
              eventKey="Get shareable link" 
              title="Get Shareable Link" 
            >
            <Modal.Body closeButton className="bg-theme text-theme">
              {sharableLink.length != 0 ? (
                <>
                  <div
                    ref={textAreaRef}
                    readOnly={true}
                    // value={sharableLink}
                    className="small border rounded p-2 mb-3"
                  >
                    {sharableLink}
                  </div>
                </>
              ) : (
                <Button size="sm" onClick={_handleGenerateLink}>
                  {generateButton ? (
                    <>
                      <span>Generating Secure Invite Link </span>
                      <Spinner animation="border" role="status" size="sm">
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </>
                  ) : (
                    <span>Generate Secure Invite Link</span>
                  )}
                </Button>
              )}
              <Row className="mt-2">
                <Col className="text-left">
                  {sharableLink.length != 0
                    ? document.queryCommandSupported("copy") && (
                        <div>
                          <Button
                            size="sm"
                            variant="light"
                            className="text-secondary small"
                            onClick={copyToClipboard}
                            data-clipboard-text="yes"
                          >
                            Copy Invitation
                          </Button>
                          <span className="d-block small text-primary mt-1">
                            {copySuccess}
                          </span>
                        </div>
                      )
                    : null}
                </Col>
                <Col className="text-right">
                  {sharableLink.length != 0
                    ? document.queryCommandSupported("copy") && (
                        <div>
                          <Button
                            size="sm"
                            variant="light"
                            className="text-secondary small"
                            onClick={sentViaEmail}
                            data-clipboard-text="yes"
                          >
                            Email Invitation
                          </Button>
                        </div>
                      )
                    : null}
                </Col>
                <Col>
                  <Modal show={sentViaEmailModal} onHide={sentViaEmail} size="md">
                    <Modal.Header className="container bg-card-theme text-theme border-0 p-0 d-flex ">
                    <div className="ml-2 mt-3">
                      <h3>Add Emails to send</h3>
                    </div>
                    <div title="Close" className="pointer mr-2 mt-2 mb-0" onClick={() =>  sentViaEmail() }>
                      <FontAwesomeIcon className="sm text-theme" icon={faTimes} />
                    </div>
                  </Modal.Header>
                    <Modal.Body className="bg-card-theme text-theme border-0 pb-0">
                      <Row className="m-1">
                        <Col>
                          <Form.Group>
                            <Form.Label size="sm">Enter Email</Form.Label>
                            <Form.Control
                              className="form-control"
                              custom
                              size="sm"
                              value={emailField}
                              onChange={(e) => {
                                setEmailField(e.target.value);
                              }}
                            />
                            <Button
                              className="mt-2"
                              size="sm"
                              onClick={() => {
                                if (
                                  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                                    emailField
                                  )
                                ) {
                                  allEmailsArray.push(emailField);
                                  setEmailField("");
                                  setError("");
                                  return;
                                } else {
                                  setError("Invalid email address!");
                                }
                              }}
                            >
                              Add Email
                            </Button>
                            <p className="text-danger">{error}</p>
                          </Form.Group>
                          {allEmailsArray.map((email, key) => (
                            <ul key={key}>
                              <li className="font-weight-bold">
                                {email}{" "}
                                <FontAwesomeIcon
                                  className="ml-3 text-danger"
                                  onClick={() => {
                                    let arr = [...allEmailsArray];
                                    arr.splice(key, 1);
                                    setAllEmailsArray(arr);
                                  }}
                                  icon={faTimes}
                                  style={{ cursor: "pointer" }}
                                />
                              </li>
                            </ul>
                          ))}
                          <Form.Group
                            className="mt-2"
                            controlId="formBasicCheckbox"
                          >
                            <Form.Check
                              type="checkbox"
                              label="Set Meeting"
                              value={isMeeting}
                              checked={isMeeting}
                              onClick={(e) => setIsMeeting(!isMeeting)}
                            />
                          </Form.Group>
                          {isMeeting ? (
                            <>
                              <p className="mb-2 font-weight-bold small">Subject</p>
                              <InputGroup className="mb-3">
                                <FormControl
                                  size="sm"
                                  placeholder="Meeting Subject"
                                  aria-label="Meeting Subject"
                                  aria-describedby="meeting-subject"
                                  name="subjectMeeting"
                                  autoFocus={true}
                                  onKeyUp={(e) => validateDataOfField(e)}
                                  onBlur={(e) => validateDataOfField(e)}
                                  onChange={handleChange}
                                  value={subjectMeeting}
                                />
                              </InputGroup>
                              {errorObj.subject ? (
                                <p className="small text-danger">
                                  {errorObj.subject}
                                </p>
                              ) : (
                                ""
                              )}
                              <div
                                className="mt-3"
                                style={{ position: "relative", zIndex: "10" }}
                              >
                                <p className="my-2 font-weight-bold small">
                                  Set Date And Time
                                </p>
                                <DateTimePicker
                                  className="mb-1 d-block bg-white"
                                  onChange={onChange}
                                  value={deadline}
                                />
                              </div>
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer className=" bg-card-theme text-theme border-0 p-0">
                      {isSending ? (
                        <Button className="mb-3" variant="primary" disabled>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          sending...
                        </Button>
                      ) : (
                        <Button
                        className="mb-3"
                          variant="primary"
                          onClick={sentEmailInvitaton}
                          disabled={allEmailsArray.length < 1}
                        >
                          Send
                        </Button>
                      )}
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form.Group>
                    <Form.Label>Validity of link (number of days)</Form.Label>
                    <Form.Control
                      className="form-control"
                      disabled={generateButton}
                      custom
                      size="sm"
                      value={validity}
                      onChange={(e) => {
                        setValidity(e.target.value);
                        setSharableLink("");
                      }}
                      type="number"
                      min="1"
                      max="90"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            </Tab>
            <Tab 
              tabClassName={(key === "Email Link") ? "text-theme-important w-50 text-center bg-theme border-0 " : "w-50 text-center bg-card-theme text-theme border-0"} 
              eventKey="Email Link"
              title="Email Link" 
            >
              <div className="container bg-theme text-theme">
                <Form 
                  className=""
                  onSubmit={(e) => { 
                    e.preventDefault();
                    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                      let arr = emailArray;
                      arr.push(email);
                      setEmailArray(arr);
                      setEmail("");
                      setMailError("");
                      return;
                    } else {
                      setMailError("Invalid email address!");
                    }
                }}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label className="h5 text-theme mt-3">Add Emails to Send</Form.Label>
                    <Form.Control onChange={(e) => setEmail(e.target.value)} type="email" value={email} placeholder="Enter Email" />
                    {mailError ? <div className="sm text-danger">{mailError}</div> : null}
                  </Form.Group>
                  <Button 
                    variant="primary" 
                    className="btn btn-sm" 
                    type="submit"
                  >
                    Add Email
                  </Button>
                </Form>
                  {emailArray.map((email, key) => (
                    <ul key={key}>
                      <li className="font-weight-bold">
                        {email}{" "}
                          <FontAwesomeIcon
                            className="ml-3 text-danger"
                            onClick={() => {
                              let arr = [...emailArray];
                              arr.splice(key, 1);
                              setEmailArray(arr);
                              }}
                            icon={faTimes}
                            style={{ cursor: "pointer" }}
                          />
                      </li>
                    </ul>
                  ))}
                  <Button 
                    className="mt-2 mb-3 btn-sm"
                    onClick={() =>inviteSend()}
                    disabled={emailArray.length > 0 ? false : true}
                  >
                    Submit
                  </Button>
              </div>
            </Tab>
        </Tabs>
        
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  workspace: state.workspace.currentWorkspace,
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps)(AddMemberInvite);
