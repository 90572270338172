import React from 'react';
import { Dropdown, Button, DropdownButton, Badge, Form, ButtonGroup, ButtonToolbar, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    setPollId,
    setPollTitle,
    setPollOptions,

    setTaskDeadline,
    setTaskChannelUsers,
    setTaskFolder,
    setTaskEveryone,
    setTaskOldFolder,
    editTask,
    editSaveTask,
    publishSaveTask
} from '../../../actions/index';
import TaskCommon from '../Common/TaskCommon';

class EditPoll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addAll: false
        }
    };

    componentDidMount() {
        const {
            setPollId,
            setPollTitle,
            setPollOptions,
        
            setTaskDeadline,
            setTaskChannelUsers,
            setTaskFolder,
            setTaskEveryone,
            setTaskOldFolder
        } = this.props;

        setPollId(this.props.taskId)
        setPollTitle(this.props.data.poll)
        setPollOptions(this.props.data.options)

        setTaskDeadline(new Date(this.props.data.deadline))
        setTaskChannelUsers(Object.keys(this.props.data.users))
        setTaskFolder(this.props.data.folder)
        setTaskEveryone(this.props.data.everyone)
        setTaskOldFolder(this.props.data.folder)
    }

    handleChange = e => {
        if (e.target.name === "poll") {
            this.props.setPollTitle(e.target.value);
        }
    }

    addOption = () => {
        let prev = [...this.props.options];
        prev[prev.length] = "";
        this.props.setPollOptions(prev)
    }
    removeOption = (key) => {
        let prev = [...this.props.options];
        prev.splice(key,1);
        this.props.setPollOptions(prev)
    }
    handleOptions = (e,key) => {
        let prev = [...this.props.options];
        prev[key] = e.target.value;
        this.props.setPollOptions(prev)
    }

    render() {
        const { type, poll, options, channelUsers, deadline, folder, old_folder, everyone, editTask, uid, currentChannel, currentUser, data, taskBranch } = this.props;
        
        const validateData = () => {
            let user = data.user;
            let timestamp = data.timestamp;
            let response = data.response ? data.response : null;

            if (poll.length == 0) {
                this.setState({error: "Poll title cannot be empty!\n"});
                return null;
            }
            
            let filtered_options = options.filter(option => option.length > 0);
            if (options.length != filtered_options.length) {
                this.setState({error: "Please add some value to option(s).\n"});
                return null;
            }
            if (options.length < 2) {
                this.setState({error: "Poll must have at-least two or more options.\n"});
                return null;
            }
            
            if ( moment(deadline).diff( moment(Date.now()), 'minutes') < 9 ) {
                this.setState({error: "Deadline of task should be atleast 10 minutes.\n"});
                return null;
            }

            if (!everyone && channelUsers.length < 2) {
                this.setState({error: "Please assign task to atleast one participant.\n"});
                return null;
            }

            this.setState({error: ""});
            return {type, poll, options, channelUsers, deadline, user, timestamp, uid, response, folder, everyone};
        }

        return (
            <React.Fragment>
                <div className="taskBox">
                <h5>Poll</h5>
                    <Form className="feedback">
                        
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Poll title"
                                aria-label="Poll title"
                                aria-describedby="basic-addon1"
                                name="poll"
                                value={poll}
                                onChange={this.handleChange}
                                required={true}
                            />
                        </InputGroup>
                        {options.length > 0
                        ? options.map((option, key) => 
                            (<div>
                                <div className="d-flex">
                                    <p className="mb-0 flex-grow-1">Option {key + 1}.</p>
                                    <div><FontAwesomeIcon icon={faTimes} onClick={() => this.removeOption(key)} /></div>
                                </div>
                                <InputGroup className="mb-1">
                                    <FormControl
                                        placeholder="Option"
                                        aria-label="option"
                                        aria-describedby={"option" + key}
                                        name="options[]"
                                        value={options[key]}
                                        id={key}
                                        onChange={e => this.handleOptions(e,key)}
                                        required={true}
                                    />
                                </InputGroup>
                            </div>))
                        : null}
                        <hr/>
                        <InputGroup className="w-100 mb-3">
                            <Button 
                            size="sm" 
                            variant="outline-primary" 
                            className="w-100"
                            onClick={this.addOption}>+ <small>Add Option</small></Button>
                        </InputGroup>

                        <TaskCommon />
                        
                        <div className="d-flex">
                            {taskBranch === "saved"
                                ? <>
                                    <Button 
                                    onClick={() => validateData() && this.props.publishSaveTask(validateData(), uid, old_folder, currentChannel, currentUser)} 
                                    variant="primary" 
                                    style={{width: "100px", marginRight: "5px"}}
                                    size="sm" ><small>Share now</small></Button>

                                    <Button 
                                    onClick={() => validateData() && this.props.editSaveTask(validateData(), uid, old_folder, currentChannel, currentUser)} 
                                    variant="success" 
                                    style={{width: "100px", marginLeft: "5px"}}
                                    size="sm" ><small>Save for later</small></Button>
                                </>
                                :
                                <Button 
                                onClick={() => validateData() && editTask(validateData(), uid, old_folder, currentChannel, currentUser)} 
                                variant="primary" 
                                style={{width: "100px", marginRight: "5px"}}
                                size="sm" ><small>Share now</small></Button>}
                        </div>
                        <p className="my-2 p-0 text-danger">{this.state.error}</p>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentChannel: state.channel.currentChannel,
    currentUser: state.user.currentUser,

    taskId: state.platform.taskId,
    taskBranch: state.platform.taskBranch,

    deadline: state.task.deadline,
    channelUsers: state.task.channelUsers,
    folder: state.task.folder,
    old_folder: state.task.old_folder,
    everyone: state.task.everyone,

    uid: state.poll.id,
    poll: state.poll.poll,
    options: state.poll.options,
    type: state.poll.type
})

export default connect(
    mapStateToProps, {
        setPollId,
        setPollTitle,
        setPollOptions,
    
        setTaskDeadline,
        setTaskChannelUsers,
        setTaskFolder,
        setTaskEveryone,
        setTaskOldFolder,
        editTask,
        editSaveTask,
        publishSaveTask
    })(EditPoll);