import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import "./SearchBar.css";

const SearchBar = ({ SearchText, setSearchText }) => {
  const [showInput, setShowInput] = useState(false);

  return (
    <div className="d-inline-block mr-2">
      {showInput ? (
        <span className="position-relative">
          <input
            type="text"
            placeholder="Search a note or notebook..."
            className="SearchBar-input"
            value={SearchText}
            autoFocus
            onChange={(e) => setSearchText(e.target.value)}
          />
          <FontAwesomeIcon
            icon={faTimes}
            className=" SearchBar-input-hide "
            onClick={() => {
              setShowInput(false);
              setSearchText("");
            }}
          />
        </span>
      ) : (
        <span className="cp p-2 text-light " onClick={() => setShowInput(true)}>
          <FontAwesomeIcon icon={faSearch} />
        </span>
      )}
    </div>
  );
};

export default SearchBar;
