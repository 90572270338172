import React, { Component } from 'react';
import firebase from 'firebase';
import moment from 'moment';
import ReadMore from '../../Middle/ReadMore';

import { connect } from 'react-redux';
import { publishMarks, publishRemark } from './actions';

import { Button, Form, InputGroup, ProgressBar, Spinner, Badge, FormControl } from "react-bootstrap";

export class DisplayResponse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remark: {},
            grade: {},
            loading: true,
            content: "",
            marks: 0
        }
    };

    componentDidMount() {
        const { currentChannel, uid, response } = this.props;

        firebase.database().ref(`gradebook/${currentChannel.id}`).child(uid).child(response.uid).on("value", snap => {
            if (snap.val()) {
                this.setState({
                    remark: snap.val().remark,
                    grade: snap.val().grade
                })
            }
            this.setState({ loading: false })
        });
    };

    strip = value => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = value.match(regExp);
        return (match && match[2].length === 11)
            ? <iframe style={{ borderRadius: '4px' }} width="100%" height="170" src={"//www.youtube.com/embed/" + match[2]} frameborder="0" allowfullscreen></iframe>
            : <ReadMore full_text={value} max_length="120" />;
    }

    getName = (key) => {
        let name = "";
        firebase.database().ref("users").child(key).on("value", snap => {
            if (snap.val()) {
                name = snap.val().name ? snap.val().name : `${snap.val().firstName} ${snap.val().lastName}`;
            }
        });
        return name;
    }

    timeFromNow = timestamp => {
        if (moment(Date.now()).diff(moment(timestamp), 'days') < 1) return moment(timestamp).fromNow()
        else if (moment(Date.now()).diff(moment(timestamp), 'days') < 2) return "yesterday"
        else if (moment(Date.now()).diff(moment(timestamp), 'days') < 7) return moment(timestamp).format('dddd')
        else return moment(timestamp).format("DD/MM/YYYY")
    };

    render() {
        const { response, item, uid, currentChannel, currentUser, publishMarks, publishRemark } = this.props;
        const { content, marks, loading, grade, remark } = this.state;

        return (
            <div className="assignment-response pb-1">
                <p className="mt-1 font-weight-bold mb-0">{this.getName(response.uid)}</p>
                {response.value.map((answer, key) => (
                    <div>
                        <p className="font-weight-bold mb-0">Q{key + 1}. {item.questions[key]}</p>
                        {answer.value ? <p className='mt-0 mb-0'><span className="font-weight-bold">A{key + 1}.</span> {this.strip(answer.value)} </p> : ""}
                        {answer.file ? <a className="mt-0 mb-0" href={answer.file} target="_blank">Attachment</a> : ""}
                    </div>

                ))}
                {loading
                    ? <Spinner size="sm" animation="border" />
                    : grade && grade.status
                        ? <p className="mb-1"><span className="font-weight-bold">Points earned:</span> {grade.marks} out of {item.totalWeight}</p>
                        : item.totalWeight ?
                            (<Form className="mb-3">
                                <InputGroup className="w-100 mb-1">
                                    <FormControl
                                        type="number"
                                        min="0"
                                        max={item.totalWeight}
                                        onChange={e => this.setState({ marks: e.target.value })} />

                                    <InputGroup.Append>
                                        <InputGroup.Text><small>/ {item.totalWeight}</small></InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>

                                <Button
                                    onClick={() => publishMarks(uid, response.uid, marks, currentChannel.id, currentUser.uid)}
                                    variant="primary"
                                    style={{ width: "100px", marginRight: "5px" }}
                                    size="sm"><small>Add Grade</small></Button>
                            </Form>)
                            : null
                }
                {loading
                    ? <Spinner size="sm" animation="border" />
                    : remark && remark.status
                        ? <p className="mb-1"><span className="font-weight-bold">Remarks:</span> {remark.content}</p>
                        : <Form>
                            <Form.Group className="mb-1">
                                <Form.Control
                                    as="textarea"
                                    rows="2"
                                    size="sm"
                                    placeholder="Add remark for the student"
                                    onChange={e => this.setState({ content: e.target.value })} />
                            </Form.Group>

                            <Button
                                onClick={() => publishRemark(uid, response.uid, content, currentChannel.id, currentUser.uid)}
                                variant="primary"
                                style={{ width: "100px", marginRight: "5px" }}
                                size="sm"><small>Add Remark</small></Button>

                        </Form>
                }
                <p className="mb-1 mt-0 small"><i>{this.timeFromNow(response.responseTime)}</i></p>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentChannel: state.channel.currentChannel,
    currentUser: state.user.currentUser
})

export default connect(mapStateToProps, { publishMarks, publishRemark })(DisplayResponse);