import React, { Component } from "react";
import firebase from "firebase";
import moment from "moment";

import {
  Form,
  Button,
  Spinner,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

import { connect } from "react-redux";
//import { publishMarks, publishRemark } from "./actions";
import DisplayAnswers from "./DisplayAnswers";

export class DisplayResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remark: {},
      grade: {},
      loading: true,
      content: "",
      marks: 0,
      correctCount: 0,
      falseCount: 0,
      responserName: "",
      responsedAnswers: [],
      showAns: false,
    };
  }

  componentDidMount() {
    const { response } = this.props;

    let docRef = firebase.firestore().collection("users").doc(`${response.id}`);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          //console.log(doc.data());
          //this.getCorrectMatrix(response);
          this.setState({ responserName: data.firstName });
        } else {
          console.log("not exists");
        }
      })
      .catch((error) => console.error(error));

    this.setState({ loading: false });
  }

  getCorrectMatrix = (response) => {
    const { quiz_data } = this.props;

    let responsedAnswers = [];
    Object.keys(quiz_data.specialOlympicQuestions).map((q, k) => {
      // console.log(response);
      let mulAnswers = [];
      if (response.answers[q]) {
        Object.keys(response.answers[q].options).map((opt) => {
          mulAnswers.push(
            quiz_data.specialOlympicQuestions[q].options[opt].optionValue
          );
        });
      }
      let json = {};
      if (mulAnswers.length > 0) {
        json = {
          question: quiz_data.specialOlympicQuestions[q].question,
          options: [...mulAnswers],
        };
      }
      if (mulAnswers.length === 0) {
        json = {
          question: quiz_data.specialOlympicQuestions[q].question,
          options: [],
        };
      }
      responsedAnswers.push(json);
    });
    // console.log(correctAnswersCount);
    // console.log(falseAnswersCount);
    this.setState({
      responsedAnswers,
    });
  };

  render() {
    const {
      quiz_data,
      response,
      currentChannel,
      currentUser,
      //publishMarks,
      publishRemark,
    } = this.props;
    //console.log(response);
    //console.log(quiz_data);

    const {
      correctCount,
      falseCount,
      content,
      marks,
      loading,
      grade,
      remark,
      responserName,
      responsedAnswers,
      showAns,
    } = this.state;
    return (
      <div>
        <hr className="my-2" />
        <p className="mb-0 h6">{responserName}</p>
        <p className="small my-0">
          Responded{" "}
          <i>
            {moment(response.responseTimestamp).format("hh:mm A MMM DD YYYY")}
          </i>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, null)(DisplayResponse);
