import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import Viewer from "react-viewer";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faImage,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faArrowLeft,
  faExclamationCircle,
  faPlusCircle,
  faAngleDown,
  faAngleUp,
  faUpload,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Dropdown,
  Spinner,
  Accordion,
  Card,
  Label,
  useAccordionToggle,
  Form,
  FormControl,
  InputGroup,
  Modal,
  Badge,
} from "react-bootstrap";
//import ImageViewer from "./ImageViewer";
import axios from "axios";
import { ProgressBar } from "react-bootstrap";
import FolderFiles from "./FolderFiles";
import Linkify from "linkifyjs/react";
import * as linkify from 'linkifyjs';
import {
  API_BASE_URL,
  DRIVE_HIERARCHY,
  DRIVE_UPLOAD,
} from "../../../config/index";
import AddFiles from "./AddFiles";
import { toast } from "react-toastify";
toast.configure();
function folderFiles() {}
function AddImage({
  file,
  handleMetaData,
  handleType,
  percentUploaded,
  addFile,
  sendFile,
}) {
  const [show, setShow] = useState(false);
  const [preview, setPreview] = useState(null);
  const [allowedType, setAllowedType] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    metaData && allowedType && handleMetaData(metaData);
    if (metaData && allowedType && image_type.includes(metaData.type))
      handleType("Image");
    if (
      metaData &&
      allowedType &&
      (word_type.includes(metaData.type) ||
        pdf_type.includes(metaData.type) ||
        powerpoint_type.includes(metaData.type) ||
        excel_type.includes(metaData.type))
    )
      handleType("File");
  }, [metaData, allowedType]);

  useEffect(() => {
    sending &&
      percentUploaded === 100 &&
      setTimeout(() => {
        handleClose();
        setPreview(null);
        setAllowedType(false);
        setMetaData(null);
        setSending(false);
        //handleContent("");
      }, 1500);
  }, [sending, percentUploaded]);

  const image_type = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"];

  const pdf_type = ["application/pdf"];

  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFileChange = () => {
    /// const file = event.target.files[0];
    console.log(file);
    const reader = new FileReader();
    if (file.size > 20971520) {
      toast.error(
        "The maximum file size is 20MB. Please choose a different file.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    } else {
      if (file) {
        setMetaData({
          lastModified: file.lastModified,
          lastModifiedDate: JSON.stringify(file.lastModifiedDate),
          name: file.name,
          size: file.size,
          type: file.type,
        });
        setAllowedType(
          image_type.includes(file.type) ||
            word_type.includes(file.type) ||
            pdf_type.includes(file.type) ||
            powerpoint_type.includes(file.type) ||
            excel_type.includes(file.type)
        );
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
          setPreview(reader.result);
        });
      }
    }
  };
  handleFileChange();

  return (
    <>
      <div>
        {preview && (
          <div>
            {/* {metaData && <p>{JSON.stringify(metaData)}</p>} */}
            {!allowedType && (
              <small className="text-danger">
                Sorry! but this file type is not supported yet.
              </small>
            )}
            {allowedType && image_type.includes(metaData.type) && (
              <div
                className="mb-3 mx-auto"
                style={{
                  height: "300px",
                  width: "300px",
                  overflow: "hidden",
                }}
              >
                <img src={preview} alt="icon" className="rounded w-100" />
              </div>
            )}

            <div className="d-flex rounded bg-light mb-0">
              <div className="py-2 px-3" style={{ backgroundColor: "#FEF2E4" }}>
                {!allowedType && (
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    className="text-secondary"
                  />
                )}
                {image_type.includes(metaData.type) && (
                  <FontAwesomeIcon icon={faImage} className="text-secondary" />
                )}
                {word_type.includes(metaData.type) && (
                  <FontAwesomeIcon icon={faFileWord} className="text-primary" />
                )}
                {pdf_type.includes(metaData.type) && (
                  <FontAwesomeIcon icon={faFilePdf} className="text-danger" />
                )}
                {powerpoint_type.includes(metaData.type) && (
                  <FontAwesomeIcon
                    icon={faFilePowerpoint}
                    className="text-danger"
                  />
                )}
                {excel_type.includes(metaData.type) && (
                  <FontAwesomeIcon
                    icon={faFileExcel}
                    className="text-success"
                  />
                )}
              </div>
              <div className="p-2 flex-grow-1">
                <small className="font-weight-bold">{metaData.name}</small>
              </div>
              <div className="py-2 px-3">
                <FontAwesomeIcon icon={faLink} className="text-secondary" />
              </div>
            </div>
            <div className="mb-3">
              {sending && (
                <ProgressBar
                  style={{ height: "0.2rem" }}
                  now={percentUploaded}
                  // label={`${percentUploaded} %`}
                  striped
                  variant="success"
                />
              )}
            </div>
          </div>
        )}
        {/*<InputGroup className="mb-3 text-theme">
            <FormControl
              multiple
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              onChange={(e) => {
                addFile(e);
                handleFileChange(e);
              }}
              type="file"
              name="previewImage"
            />*/}
        {/* <input
              type="file"
              multiple
              onChange={(e) => {
                addFile(e);
                handleFileChange(e);
              }}
              name="previewImage"
              style={{
                position: "absolute",
                opacity: 0,
                height: 60,
                width: 60,
              }}
            />
            </InputGroup>*/}
      </div>
      {/*<Card.Footer>
          <Button
            className="btn btn-sm"
            disabled={!allowedType && true}
            color="red"
            onClick={() => {
              sendFile();
              setSending(true);
            }}
          >
            Add
          </Button>
          <Button color="red" className="btn btn-sm" onClick={handleClose}>
            Cancel
          </Button>
          </Card.Footer>*/}
    </>
  );
}

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      type="button"
      className="btn btn-sm btn-outline-primary"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const UrlMessageView = ({ og, meta, url, createdAt }) => {

  const metaTitle = meta.title, 
        metaDescription = meta.description, 
        ogTitle = og.title, 
        ogDescription = og.description, 
        site_name = og.site_name;

  const title = (metaTitle) 
                ? metaTitle 
                : ogTitle,
  description = (ogDescription) 
                ? ogDescription
                : metaDescription;

  const timeFromNow = (timestamp) => {
    if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
      return moment(timestamp).format("h:mm A");
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
      return `Yesterday, ${moment(timestamp).format("h:mm A")}`;
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
      return `${moment(timestamp).format("dddd")}, ${moment(timestamp).format(
        "h:mm A"
    )}`;
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 365)
      return `${moment(timestamp).format("MMM DD, YYYY")}, ${moment(
        timestamp
      ).format("h:mm A")}`;
    else return moment(timestamp).format("MMM YY");
  };

  return (
    <div className="w-100">
      <div className="small text-theme ">{timeFromNow(createdAt.toDate())}</div>
      <div className="d-flex align-items-top overflow-hidden text-theme-light rounded bg-theme p-1">
        
        {
          og.image && linkify.find(og.image).length > 0 && linkify.find(og.image)[0].type === "url" 
          ? <div className="rounded-left url_message_img" style={{ backgroundImage: `url(${og.image})` }}></div>
          : null
        }
        {console.log(og.image)}
        <div className="flex-grow-1 d-flex flex-column url_message_text">
          {
            title 
            ? <div style={{ textOverflow: 'ellipsis' }} title={title} className="text-theme-light">
                <span className="small text-truncate">{title}</span>
              </div>
            : null
          }
          {
            description
            ? <div style={{ textOverflow: 'ellipsis' }} className="flex-grow-1 text-muted overflow-hidden">
              <span className="small" title={description}>
                {description?.replace(/(\r\n|\n|\r)/gm, "")}
              </span>
            </div>
            : null
          }
          <div style={{ textOverflow: 'ellipsis' }} className="text-theme-light flex-none small">
            {
              site_name 
              ? <span title={site_name}>{site_name}</span>
              : <span title={site_name}>{url}</span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

function ImageViewer(source, content, visible, setVisible, allImages) {
  let index = allImages.findIndex((img) => img.image === source);
  return (
    <>
      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        activeIndex={index}
        images={[
          ...allImages.map((img) => {
            return { src: img.image, alt: img.content ? img.content : "" };
          }),
        ]}
      />
    </>
  );
}

class Cloud extends Component {
  state = {
    selected: "Images",
    allImages: [],
    allFiles: [],
    allLinks: [],
    visible: false,
    imageSelected: {},
    driveFiles: [],
    driveFolders: [],
    isLoading: true,
    selectedFolder: {},
    folderName: "",
    errorObj: { folderName: "" },
    metaData: {},
    type: "",
    percentUploaded: 0,
    files: [],
    fileObj: [],
    isClicked: false,
    authorized: [
      "image/jpeg",
      "image/png",
      "image/svg+xml",
      "image/gif",

      "application/pdf",

      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ],
  };
  constructor(props) {
    super(props);
    this.inputFile = React.createRef();
  }

  componentDidMount() {
    let allImages = [];
    let allFiles = [];
    let allLinks = [];
    let { currentWorkspace, currentChannel, currentUser } = this.props;
    //console.log(currentUser);
    //console.log(folder);
    //let requestData = { user_id: currentUser.uid };
    // if (folder) {
    //   requestData = { user_id: currentUser.uid, folder_id: folder };
    // }
    /*let res = await axios.post(`${API_BASE_URL}${DRIVE_HIERARCHY}`, {
      ...requestData,
    });
    console.log(res);
    this.setState({
      driveFiles: res.data.data["child_files"],
      driveFolders: res.data.data["child_folders"],
      isLoading: false,
    });*/
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      )
      .orderBy("timestamp", "desc");
    query
      .get()
      .then((querySnap) => {
        querySnap.docs.forEach((doc) => {
          //console.log(doc.data());
          if (doc.data() && doc.data().type === 6) {
            allImages.push({ ...doc.data(), id: doc.id });
          } else if (doc.data() && doc.data().type === 7) {
            allFiles.push({ ...doc.data(), id: doc.id });
          }else if (doc.data() && doc.data().type === 12 && !doc.data().isDeleted){
            allLinks.push({...doc.data(), id: doc.id})
          }
        });
      })
      .then(() => {
        console.log(allImages);
        console.log(allFiles);
        this.setState({ allImages, allFiles, allLinks, isLoading: false });
      });
    //console.log(allImages);
    //console.log(allFiles);
    // this.setState({ allImages, allFiles });
  }
  handleSelected = (selected) => {
    this.setState({ selected });
  };
  ImageViewerCaller = (image, content) => {
    this.setState({
      imageSelected: { source: image, content: content },
      visible: true,
    });
  };
  /* handleFolderFiles = (folder) => {
    this.setState({ selectedFolder: folder });
  };
  handleChange = (e) => {
    if (e.target.name === "folderName") {
      this.setState({ folderName: e.target.value });
    }
  };*/
  /*getMyDriveData = async () => {
    let { currentUser, folder } = this.props;

    let requestDataForGetingData = { user_id: currentUser.uid };
    if (folder) {
      requestDataForGetingData = {
        user_id: currentUser.uid,
        folder_id: folder,
      };
    }
    let response = await axios.post("https://dev.igesia.com/drive/hierarchy", {
      ...requestDataForGetingData,
    });
    //console.log(res);
    this.setState({
      driveFiles: response.data.data["child_files"],
      driveFolders: response.data.data["child_folders"],
      isLoading: false,
    });
  };*/
  /*handleSubmitFolder = async () => {
    this.setState({ isLoading: true });
    const { folderName, selectedFolder } = this.state;
    let { currentUser } = this.props;

    let requestData = {
      user_id: currentUser.uid,
      folder_name: folderName,
    };
    if (selectedFolder.folder_id) {
      requestData = {
        user_id: currentUser.uid,
        folder_name: folderName,
        parent_id: selectedFolder.folder_id,
      };
    }
    let res = await axios.post("https://dev.igesia.com/drive/folder", {
      ...requestData,
    });
    // console.log(res);
    if (res.status === 200) {
      this.getMyDriveData();
    } else {
      alert(res.statusText);
    }
  };
  addFile = (event) => {
    let file = event.target.files[0];
    let { files } = this.state;
    if (file) {
      files.push(files);
      this.setState({ files });
    }
  };

  uploadMultipleFiles = (e) => {
    console.log(e.target.files);
    let { fileObj, files } = this.state;
    fileObj = [...e.target.files];

    this.setState({ files, fileObj });
  };*/

  render() {
    const image_type = [
      "image/jpeg",
      "image/png",
      "image/svg+xml",
      "image/gif",
    ];
    const pdf_type = ["application/pdf"];

    const word_type = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    const excel_type = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const powerpoint_type = [
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
    const setVisible = (visible) => {
      this.setState({ visible, imageSelected: {} });
    };
    const {
      allFiles,
      allImages,
      allLinks,
      selected,
      imageSelected,
      visible,
      driveFiles,
      driveFolders,
      isLoading,
      selectedFolder,
      folderName,
      errorObj,
      isClicked,
    } = this.state;
    console.log(allFiles);
    const {
      setContentType,
      setTaskType,
      resetAllTasks,
      taskType,
      contentType,
      currentUser,
    } = this.props;
    // const inputFile = React.createRef();

    if (Object.keys(imageSelected).length > 0) {
      return ImageViewer(
        imageSelected.source,
        imageSelected.content,
        visible,
        setVisible,
        allImages
      );
    } else {
      return (
        <div>
          <div className="m-2 rounded p-2 bg-card-theme text-theme">
            <div className="d-flex">
              <div>
                <FontAwesomeIcon
                  onClick={() => {
                    setContentType(null);
                    setTaskType(null);
                    resetAllTasks();
                  }}
                  icon={faArrowLeft}
                  className="mr-2 pointer"
                />
                <span>
                  {contentType === "createTask"
                    ? taskType
                      ? taskType === "Cloud"
                        ? "Media"
                        : "Create Task"
                      : ""
                    : ""}
                </span>
              </div>
            </div>
          </div>
          <div className="row m-2 border-1 rounded">
            <div
              className={
                selected === "Images"
                  ? "col-4 text-theme bg-card-theme rounded py-1 d-flex justify-content-center small"
                  : "col-4 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Images")}
              style={{ cursor: "pointer" }}
            >
              Images
            </div>
            <div
              className={
                selected === "Files"
                  ? "col-4 text-theme bg-card-theme rounded d-flex py-1 justify-content-center small"
                  : "col-4 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Files")}
              style={{ cursor: "pointer" }}
            >
              Files
            </div>
            <div
              className={
                selected === "Links"
                  ? "col-4 text-theme bg-card-theme rounded py-1 d-flex justify-content-center small"
                  : "col-4 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Links")}
              style={{ cursor: "pointer" }}
            >
              Links
            </div>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="light" />
            </div>
          ) : selected === "Images" ? (
              <div className="row m-0">
              {allImages.length > 0 ? (
                allImages.map((img) => (
                  <div className="col-4 px-1 py-1 " key={img.id}>
                    <div 
                      style={{ backgroundImage: `url(${img.image})`,backgroundSize: "cover",
                      backgroundPosition: "center center",
                      height: "125px",
                      width: "100%",
                      overflow:"hidden",}}
                      onClick={() =>
                        this.ImageViewerCaller(img.image, img.content)
                      }
                    ></div>
                    {/* <img
                    src={img.image}
                    onClick={() =>
                      this.ImageViewerCaller(img.image, img.content)
                    }
                    className="rounded shadow pointer"
                    style={{
                      backgroundImage: "url(" + img.image + ")",
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                      height: "125px",
                      width: "100%",
                      overflow:"hidden",
                    }}
                  /> */}
                    {/*<div
                      className="rounded shadow pointer"
                      onClick={() =>
                        this.ImageViewerCaller(img.image, img.content)
                      }
                      
                    ></div>*/}
                  </div>
                ))
              ) : (
                <div className="col">
                  <p className="text-theme text-center small">
                    No images shared yet
                  </p>
                </div>
              )}
            </div>
          ) : selected === "Files" ? (
            <div className="row m-2">
              {allFiles.length > 0 ? (
                allFiles.map((File) => (
                  <a
                    href={File.file}
                    className="text-theme w-100 text-decoration-none"
                    target="_blank"
                    key={File.id}
                  >
                    {File.metaData ? (
                      <div className="col-12 p-2 rounded shadow border-1 bg-theme pointer">
                        {word_type.includes(File.metaData.type) && (
                          <FontAwesomeIcon
                            icon={faFileWord}
                            className="text-primary mr-1"
                          />
                        )}
                        {pdf_type.includes(File.metaData.type) && (
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            className="text-danger mr-1"
                          />
                        )}
                        {powerpoint_type.includes(File.metaData.type) && (
                          <FontAwesomeIcon
                            icon={faFilePowerpoint}
                            className="text-danger mr-1"
                          />
                        )}
                        {excel_type.includes(File.metaData.type) && (
                          <FontAwesomeIcon
                            icon={faFileExcel}
                            className="text-success mr-1"
                          />
                        )}{" "}
                        {File.metaData.name}
                      </div>
                    ) : (
                      <div className="col-12 p-2 rounded shadow border-1 bg-theme pointer">
                        <FontAwesomeIcon
                          icon={faQuestion}
                          className="text-success mr-1"
                        />{" "}
                        Unknown File
                      </div>
                    )}
                  </a>
                ))
              ) : (
                <div className="col">
                  <p className="text-theme text-center small">
                    No files shared yet
                  </p>
                </div>
              )}
            </div>
          ) : selected === "Links" ? (
            <div>
              {allLinks.length > 0 ? 
                (
                  allLinks.map((message, key) => (
                    <div className="text-theme rounded shadow-sm border-1 bg-theme-lightest pointer m-2">
                      <div  onClick={() => window.open(message.url, '_blank')} className="p-1">
                        <UrlMessageView 
                          og={message.metaTags.og} 
                          meta={message.metaTags.meta} 
                          url={message.url}
                          createdAt={message.createdAt}
                        />
                      </div>
                    </div>
                  ))
                ):(
                  <div className="col">
                    <p className="text-theme text-center small">
                      No Links shared yet
                    </p>
                  </div>
              )}
            </div>
          )
          : (
            ""
          )}
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,

  currentUser: state.user.currentUser,
  folder: state.task.folder,
  replyId: state.platform.replyId,
});

export default connect(mapStateToProps, null)(Cloud);
