import React, { useState, useEffect } from "react";
import "./NoteApp.css";
import GridViewBook from "./GridViewBook/GridViewBook";
import GridViewNote from "./GridViewNote/GridViewNote";
import CreateNote from "./CreateNote/CreateNote";
import SearchBar from "./SearchBar/SearchBar";
import WriteNote from "./WriteNote/WriteNote";
import BookLoader from "./Assets/book-loader.gif";

import ClipLoader from "react-spinners/ClipLoader";

import { setShowNoteApp } from "../../../actions/index.js";

import firebase from "firebase";
import Swal from "sweetalert2/src/sweetalert2.js";
import NoteView from "../NotesApp/NoteView/NoteView";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { connect } from "react-redux";
import {
  faArchive,
  faChevronLeft,
  faDotCircle,
  faEdit,
  faHome,
  faThumbtack,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const NoteApp = ({ currentUser, setShowNoteApp, showNoteApp, ...props }) => {
  const [isLoading, setIsloading] = useState(true);
  const [Saving, setSaving] = useState(false);
  const [GridMode, setGridMode] = useState(true);
  const [ShowCreateNote, setShowCreateNote] = useState(false);
  const [NoteWriteMode, setNoteWriteMode] = useState(false);
  const [AllUsersNotes, setAllUsersNotes] = useState([]);
  const [AllNotes, setAllNotes] = useState([]);
  const [AllPinnedNotes, setAllPinnedNotes] = useState([]);
  const [AllArchivedNotes, setAllArchivedNotes] = useState([]);
  const [AllNotebooks, setAllNotebooks] = useState([]);
  const [AllPinnedNoteBooks, setAllPinnedNoteBooks] = useState([]);
  const [AllArchivedNoteBooks, setAllArchivedNoteBooks] = useState([]);
  const [SelectedNotebook, setSelectedNotebook] = useState(null);
  const [SelectedNotebookNotes, setSelectedNotebookNotes] = useState([]);
  const [SelectedNote, setSelectedNote] = useState(null);
  const [NotebookEdit, setNotebookEdit] = useState(null);

  const [EditorData, setEditorData] = useState("");
  const [NoteTitle, setNoteTitle] = useState("");
  const [error, setError] = useState({});
  const [Active, setActive] = useState("Home");
  const [NoteEdit, setNoteEdit] = useState(null);
  const [SearchText, setSearchText] = useState("");
  const [SearchResultForNotebooks, setSearchResultForNotebooks] = useState([]);
  const [SearchResultForNotes, setSearchResultForNotes] = useState([]);

  // ---------------------------- Create and edit Note function ---------------------------------------------

  const handleSaveNote = () => {
    if (!NoteTitle) {
      error["note"] = "Note title should not be empty!";
      setError({ ...error });
      return;
    }
    setSaving(true);

    if (!NoteEdit) {
      firebase
        .firestore()
        .collection("notesApp")
        .doc(currentUser.uid)
        .collection("notes")
        .add({
          title: NoteTitle,
          lower_case_title: NoteTitle.toLowerCase(),
          content: EditorData,
          is_archived: false,
          is_pinned: false,
          label: "",
          notebook_id: SelectedNotebook ? SelectedNotebook.id : "",
          pinned_at: null,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          timestamp: Date.now(),
          updated_at: null,
        })
        .then(() => {
          setNoteWriteMode(false);
          setNoteTitle("");
          setEditorData("");
          setSaving(false);
          Swal.fire("Success", "Note added successfully!", "success");
          console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);

          Swal.fire("Error", "Something went wrong!", "error");
          setSaving(false);
        });
    } else {
      firebase
        .firestore()
        .collection("notesApp")
        .doc(currentUser.uid)
        .collection("notes")
        .doc(NoteEdit.id)
        .update({
          title: NoteTitle,
          lower_case_title: NoteTitle.toLowerCase(),
          content: EditorData,
          updated_at: Date.now(),
        })
        .then(() => {
          setNoteWriteMode(false);
          setNoteTitle("");
          setEditorData("");
          setSaving(false);
          setNoteEdit(null);
          Swal.fire("Success", "Note updated successfully!", "success");
          console.log("Document updated written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);

          Swal.fire("Error", "Something went wrong!", "error");
          setSaving(false);
        });
    }
  };

  // ------------------------------------ Fetach all notes -------------------------------------

  useEffect(() => {
    if (SearchText) {
      let tempAllNotes = [];
      AllNotes.forEach((item) => {
        if (item.lower_case_title.includes(SearchText.toLowerCase())) {
          tempAllNotes.push(item);
        }
      });
      setSearchResultForNotes(tempAllNotes);

      let tempAllNotebooks = [];
      AllNotebooks.forEach((item) => {
        if (item.lower_case_name.includes(SearchText.toLowerCase())) {
          tempAllNotebooks.push(item);
        }
      });
      setSearchResultForNotebooks(tempAllNotebooks);
    } else {
      setSearchResultForNotebooks([]);
      setSearchResultForNotes([]);
      setIsloading(false);
    }
  }, [SearchText]);

  useEffect(() => {
    if (SelectedNotebook) {
      setIsloading(true);
      setSelectedNotebookNotes([]);

      let tempAllSelectedNotes = [];
      AllUsersNotes.forEach((item) => {
        if (item.notebook_id === SelectedNotebook.id) {
          tempAllSelectedNotes.push(item);
        }
      });
      setSelectedNotebookNotes(tempAllSelectedNotes);
      setIsloading(false);
    }
  }, [SelectedNotebook, AllUsersNotes]);

  useEffect(() => {
    setIsloading(true);
    let unSubscribeForAllNotes = firebase
      .firestore()
      .collection("notesApp")
      .doc(currentUser.uid)
      .collection("notes")
      .orderBy("created_at", "desc")
      .onSnapshot((doc) => {
        let tempAllUserNotes = [];
        let tempAllNotes = [];
        let tempAllArchivedNotes = [];
        let tempAllPinnedNotes = [];
        if (!doc.empty) {
          doc.docs.forEach((item) => {
            let data = item.data();
            tempAllUserNotes.push({ ...data, id: item.id });
            if (data.notebook_id) return;
            if (data.is_archived) {
              tempAllArchivedNotes.push({ ...data, id: item.id });
            } else {
              tempAllNotes.push({ ...data, id: item.id });
              if (data.is_pinned) {
                tempAllPinnedNotes.push({ ...data, id: item.id });
              }
            }
          });
        }
        setAllPinnedNotes(tempAllPinnedNotes);
        setAllArchivedNotes(tempAllArchivedNotes);
        setAllNotes(tempAllNotes);
        setAllUsersNotes(tempAllUserNotes);

        setIsloading(false);
      });

    return () => unSubscribeForAllNotes();
  }, []);

  useEffect(() => {
    setIsloading(true);
    if (SelectedNotebook) return;
    let unSubscribeForAllNotebooks = firebase
      .firestore()
      .collection("notesApp")
      .doc(currentUser.uid)
      .collection("notebooks")
      .orderBy("created_at", "desc")
      .onSnapshot((doc) => {
        let tempAllNotebooks = [];
        let tempAllArchivedNotebooks = [];
        let tempAllPinnedNotebook = [];
        if (!doc.empty) {
          doc.docs.forEach((item) => {
            let data = item.data();
            if (data.is_archived) {
              tempAllArchivedNotebooks.push({ ...data, id: item.id });
            } else {
              tempAllNotebooks.push({ ...data, id: item.id });
              if (data.is_pinned) {
                tempAllPinnedNotebook.push({ ...data, id: item.id });
              }
            }
          });
        }

        setAllArchivedNoteBooks(tempAllArchivedNotebooks);
        setAllPinnedNoteBooks(tempAllPinnedNotebook);
        setAllNotebooks(tempAllNotebooks);
        setIsloading(false);
      });

    return () => unSubscribeForAllNotebooks();
  }, []);

  // ------------------ change view ----------------------

  const handleSelectActive = (view) => {
    setSearchText(null);
    setSelectedNote(null);
    setSelectedNotebook(null);
    setNoteEdit(null);
    setNoteWriteMode(false);
    setActive(view);
  };

  useEffect(() => {
    // if (SelectedNote || SelectedNotebook) {
    //   setActive(null);
    // }
    if (!SelectedNote && !SelectedNotebook && !Active) {
      setActive("Home");
    }
  }, [SelectedNote, SelectedNotebook, Active]);

  // ---------------------------------------- Switch to edit note view -----------------------------------------------

  useEffect(() => {
    if (NotebookEdit) {
      setShowCreateNote(true);
    } else {
      setShowCreateNote(false);
    }
  }, [NotebookEdit]);

  return (
    <div className="NoteApp-modal shadow">
      {isLoading ? (
        <>
          <div className="NoteApp-header">
            <span className="ml-4  text-light">
              <FontAwesomeIcon
                icon={faDotCircle}
                className="text-warning mr-2 font-12"
              />
              Home
            </span>
            <span className=" ml-auto mr-2  ">
              <span
                className="cp text-danger p-2 "
                onClick={() => setShowNoteApp(false)}
              >
                <FontAwesomeIcon icon={faTimes} className="font-18" />
              </span>
            </span>
          </div>
          <div className="NoteApp-loader">
            <img src={BookLoader} alt="BookLoader" height="150px" />
          </div>
        </>
      ) : (
        <>
          {/* --------------------------------------- Header --------------------------------------- */}
          <div className="NoteApp-header">
            {SelectedNote && SelectedNotebook && (
              <span className="NoteBookNameHeader">
                {SelectedNotebook.name}
              </span>
            )}
            {NoteWriteMode ? (
              <span
                className="ml-3 text-light font-14 cp p-2"
                onClick={() => setNoteWriteMode(false)}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="text-warning mr-2 font-12"
                />
                Cancel
              </span>
            ) : SelectedNote ? (
              <span className="ml-4  text-light">
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="text-light cp mr-2 font-12"
                  onClick={() => setSelectedNote(null)}
                />
                {SelectedNote.title}
              </span>
            ) : SelectedNotebook ? (
              <span className="ml-4  text-light">
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="text-light  cp mr-2 font-12"
                  onClick={() => setSelectedNotebook(null)}
                />

                {SelectedNotebook.name}
              </span>
            ) : (
              <span className="ml-4  text-light">
                <FontAwesomeIcon
                  icon={faDotCircle}
                  className="text-warning mr-2 font-12"
                />
                {Active}{" "}
              </span>
            )}

            <span className=" ml-auto mr-2  ">
              {NoteWriteMode ? (
                <span
                  className="btn btn-info btn-sm py-0 px-2 mr-2"
                  onClick={handleSaveNote}
                >
                  Done
                </span>
              ) : (
                !SelectedNote &&
                !SelectedNotebook &&
                Active === "Home" && <SearchBar setSearchText={setSearchText} />
              )}
              {/* <span
              className="cp p-2 text-light mr-3"
              onClick={() => setGridMode(!GridMode)}
            >
              {GridMode ? (
                <i className="fas fa-th-large font-20"></i>
              ) : (
                <i className="fas fa-list font-20"></i>
              )}
            </span> */}

              <span
                className="cp text-danger p-2 "
                onClick={() => setShowNoteApp(false)}
              >
                <FontAwesomeIcon icon={faTimes} className="font-18" />
              </span>
            </span>
          </div>
          {/* <div className="black-strip"></div> */}

          {Saving && (
            <div className="NoteApp-saving">
              <ClipLoader color={"#ff5"} loading={Saving} size={50} />
            </div>
          )}
          {/* --------------------------------------- Body --------------------------------------- */}
          {NoteWriteMode ? (
            <WriteNote
              setNoteWriteMode={setNoteWriteMode}
              EditorData={EditorData}
              setEditorData={setEditorData}
              NoteTitle={NoteTitle}
              setNoteTitle={setNoteTitle}
              error={error}
              setError={setError}
              NoteEdit={NoteEdit}
              setNoteEdit={setNoteEdit}
            />
          ) : SelectedNote ? (
            <NoteView
              SelectedNote={SelectedNote}
              setSelectedNote={setSelectedNote}
              setNoteEdit={setNoteEdit}
              setNoteWriteMode={setNoteWriteMode}
              currentUser={currentUser}
            />
          ) : (
            <div className="NoteApp-body blackScrollBar">
              {/* ------------------------------------ Notebooks perview ----------------------------------- */}

              {!SelectedNotebook && (
                <div>
                  <div className="my-1">
                    <span className="text-light font-15 font-weight-bold ml-2">
                      Notebooks
                    </span>
                  </div>
                  <div className=" notebooks blackScrollBar mb-3">
                    {SearchText && SearchResultForNotebooks.length ? (
                      SearchResultForNotebooks.map((Notebook, idx) => (
                        <GridViewBook
                          Notebook={Notebook}
                          setSelectedNotebook={setSelectedNotebook}
                          setNotebookEdit={setNotebookEdit}
                          currentUser={currentUser}
                          key={idx}
                        />
                      ))
                    ) : SearchText && !SearchResultForNotebooks.length ? (
                      <div className="text-light text-center my-5">
                        No notebook found!
                      </div>
                    ) : Active === "Pins" ? (
                      AllPinnedNoteBooks.length ? (
                        AllPinnedNoteBooks.map((Notebook, idx) => (
                          <GridViewBook
                            Notebook={Notebook}
                            setSelectedNotebook={setSelectedNotebook}
                            setNotebookEdit={setNotebookEdit}
                            currentUser={currentUser}
                            key={idx}
                          />
                        ))
                      ) : (
                        <div className="text-light text-center my-5">
                          No pinned notebooks added!
                        </div>
                      )
                    ) : Active === "Archived" ? (
                      AllArchivedNoteBooks.length ? (
                        AllArchivedNoteBooks.map((Notebook, idx) => (
                          <GridViewBook
                            Notebook={Notebook}
                            setSelectedNotebook={setSelectedNotebook}
                            setNotebookEdit={setNotebookEdit}
                            currentUser={currentUser}
                            key={idx}
                          />
                        ))
                      ) : (
                        <div className="text-light text-center my-5">
                          No archived notebooks added!
                        </div>
                      )
                    ) : AllNotebooks.length ? (
                      AllNotebooks.map((Notebook, idx) => (
                        <GridViewBook
                          Notebook={Notebook}
                          setSelectedNotebook={setSelectedNotebook}
                          setNotebookEdit={setNotebookEdit}
                          currentUser={currentUser}
                          key={idx}
                        />
                      ))
                    ) : (
                      <div className="text-light text-center my-5">
                        No notebooks added!
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* ------------------------------------ Notes perview ---------------------------------- */}

              <div>
                <div className="my-1">
                  {SelectedNotebook && (
                    <div className="my-2 text-center text-light font-13">
                      {SelectedNotebook.created_at?.toDate().toDateString()}
                      <span className="ml-2">
                        {SelectedNotebook.created_at
                          ?.toDate()
                          .toLocaleTimeString()}
                      </span>
                    </div>
                  )}
                  <span className="text-light font-15 font-weight-bold ml-2 mb-2">
                    Notes
                  </span>
                </div>
                {SearchText && SearchResultForNotes.length ? (
                  <div className="GridModeNotesView">
                    {SearchResultForNotes.map((note, idx) => (
                      <GridViewNote
                        Note={note}
                        setSelectedNote={setSelectedNote}
                        setNoteEdit={setNoteEdit}
                        setNoteWriteMode={setNoteWriteMode}
                        currentUser={currentUser}
                        key={idx}
                      />
                    ))}
                  </div>
                ) : SearchText && !SearchResultForNotes.length ? (
                  <div className="text-light text-center my-5">
                    No notes found!
                  </div>
                ) : SelectedNotebook ? (
                  SelectedNotebookNotes.length ? (
                    <div className="GridModeNotesView">
                      {SelectedNotebookNotes.map((note, idx) => (
                        <GridViewNote
                          Note={note}
                          setSelectedNote={setSelectedNote}
                          setNoteEdit={setNoteEdit}
                          setNoteWriteMode={setNoteWriteMode}
                          SelectedNotebook={SelectedNotebook}
                          currentUser={currentUser}
                          key={idx}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="text-light text-center my-5">
                      No notes added!
                    </div>
                  )
                ) : Active === "Pins" ? (
                  AllPinnedNotes.length ? (
                    <div className="GridModeNotesView">
                      {AllPinnedNotes.map((note, idx) => (
                        <GridViewNote
                          Note={note}
                          setSelectedNote={setSelectedNote}
                          setNoteEdit={setNoteEdit}
                          setNoteWriteMode={setNoteWriteMode}
                          SelectedNotebook={SelectedNotebook}
                          currentUser={currentUser}
                          key={idx}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="text-light text-center my-5">
                      No pinned notes added!
                    </div>
                  )
                ) : Active === "Archived" ? (
                  AllArchivedNotes.length ? (
                    <div className="GridModeNotesView">
                      {AllArchivedNotes.map((note, idx) => (
                        <GridViewNote
                          Note={note}
                          setSelectedNote={setSelectedNote}
                          setNoteEdit={setNoteEdit}
                          setNoteWriteMode={setNoteWriteMode}
                          SelectedNotebook={SelectedNotebook}
                          currentUser={currentUser}
                          key={idx}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="text-light text-center my-5">
                      No archived notes added!
                    </div>
                  )
                ) : AllNotes.length ? (
                  <div className="GridModeNotesView">
                    {AllNotes.map((note, idx) => (
                      <GridViewNote
                        Note={note}
                        setSelectedNote={setSelectedNote}
                        setNoteEdit={setNoteEdit}
                        setNoteWriteMode={setNoteWriteMode}
                        SelectedNotebook={SelectedNotebook}
                        currentUser={currentUser}
                        key={idx}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="text-light text-center my-5">
                    No notes added!
                  </div>
                )}
              </div>
            </div>
          )}
          {/* --------------------------------------- Footer --------------------------------------- */}
          {!SelectedNotebook && (
            <div className="NoteApp-footer">
              <span
                className={`cp d-inline-block mx-3 NoteApp-footer-icon ${
                  Active === "Home" ? "bg-light text-dark" : null
                }`}
                onClick={() => handleSelectActive("Home")}
              >
                <FontAwesomeIcon icon={faHome} />
                <br />
                <span className="font-8 NoteApp-footer-text ">Home</span>
              </span>
              <span
                className={`cp d-inline-block mx-3 NoteApp-footer-icon ${
                  Active === "Pins" ? "bg-light text-dark" : null
                }`}
                onClick={() => handleSelectActive("Pins")}
              >
                <FontAwesomeIcon icon={faThumbtack} />
                <br />
                <span className="font-8 NoteApp-footer-text">Pins</span>
              </span>
              {/* <span
                className={`cp d-inline-block mx-3 NoteApp-footer-icon ${
                  Active === "Labels" ? "bg-light text-dark" : null
                }`}
                onClick={() => handleSelectActive("Labels")}
              >
                <i className="fal fa-tag "></i>
                <br />
                <span className="font-8 NoteApp-footer-text">Lables</span>
              </span> */}
              <span
                className={`cp d-inline-block mx-3 NoteApp-footer-icon ${
                  Active === "Archived" ? "bg-light text-dark" : null
                }`}
                onClick={() => handleSelectActive("Archived")}
              >
                <FontAwesomeIcon icon={faArchive} />
                <br />
                <span className="font-8 NoteApp-footer-text">Archived</span>
              </span>
            </div>
          )}

          {!NoteWriteMode && !SelectedNote && (
            <span
              className="writeNoteButton"
              onClick={() => setShowCreateNote(true)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </span>
          )}

          {/* ------------------------------------- Create a note or notebook selector -------------------------------------- */}

          {ShowCreateNote && (
            <CreateNote
              ShowCreateNote={ShowCreateNote}
              setShowCreateNote={setShowCreateNote}
              setNoteWriteMode={setNoteWriteMode}
              setSaving={setSaving}
              SelectedNotebook={SelectedNotebook}
              NotebookEdit={NotebookEdit}
              setNotebookEdit={setNotebookEdit}
              currentUser={currentUser}
            />
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  showNoteApp: state.noteApp.showNoteApp,
});

export default connect(mapStateToProps, {
  setShowNoteApp,
})(NoteApp);
