import * as actionTypes from "./types";

const initialAssignmentState = {
  type: 5,
  id: null,
  questions: [],
  descriptions: [],
  weights: [],
  graded: false,
  totalWeight: 0,
};

const assignment_reducer = (state = initialAssignmentState, action) => {
  switch (action.type) {
    case actionTypes.ADD_ASSIGNMENT_ID:
      return {
        ...state,
        id: action.payload.id,
      };
    case actionTypes.ADD_ASSIGNMENT_QUESTIONS:
      return {
        ...state,
        questions: action.payload.questions,
      };
    case actionTypes.ADD_ASSIGNMENT_DESCRIPTIONS:
      return {
        ...state,
        descriptions: action.payload.descriptions,
      };
    case actionTypes.ADD_ASSIGNMENT_WEIGHTS:
      return {
        ...state,
        weights: action.payload.weights,
      };
    case actionTypes.SET_ASSIGNMENT_GRADED:
      return {
        ...state,
        graded: action.payload.graded,
      };
    case actionTypes.ADD_ASSIGNMENT_TOTAL_WEIGHT:
      return {
        ...state,
        totalWeight: action.payload.totalWeight,
      };
    case actionTypes.RESET_ASSIGNMENT:
      return initialAssignmentState;
    default:
      return state;
  }
};

export default assignment_reducer;
