import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import AddFiles from "../Tasks/Cloud/AddFiles";
import Viewer from "react-viewer";
import {
  Spinner,
  Dropdown,
  ProgressBar,
  ButtonGroup,
  Button,
  InputGroup,
  FormControl,
  Modal,
  Form,
} from "react-bootstrap";
import firebase from "firebase";
import RoundChart from "../Middle/newChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faImage,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faArrowLeft,
  faExclamationCircle,
  faPlusCircle,
  faAngleDown,
  faAngleUp,
  faUpload,
  faBars,
  faThLarge,
  faFolderPlus,
  faFolderOpen,
  faPlus,
  faEllipsisV,
  faTrashAlt,
  faICursor,
  faShareAlt,
  faDownload,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {
  API_BASE_URL,
  DRIVE_HIERARCHY,
  DRIVE_UPLOAD,
  DRIVE_STORAGE_DETAILS,
  DRIVE_RENAME_FILE,
  DRIVE_SOFT_DELETE
} from "../../config/index";
import FolderFiles from "../Tasks/Cloud/FolderFiles";

import { toast } from "react-toastify";
import {
  setShowCreateFolderModal,
  setShowUploadFilesModal,
  setSelectedFolderId,
  resetData,
} from "../../actions/index";
import RoomSelectModalForMessages from "../Middle/RoomSelectModalForMessages";
toast.configure();
function ImageViewer(
  source,
  content,
  visible,
  setVisible,
  allImages,
  setImageSelected
) {
  let index = allImages.findIndex((img) => img.file_url === source);
  console.log(allImages);
  return (
    <>
      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
          setImageSelected({});
        }}
        activeIndex={index}
        images={[
          ...allImages.map((img) => {
            return {
              src: img.file_url,
              alt: img.file_name ? img.file_name : "",
            };
          }),
        ]}
      />
    </>
  );
}

const MyDrive = (props) => {
  const {
    setShowCreateFolderModal,
    setShowUploadFilesModal,
    toggleRooms,
    setLeftActionType,
    setSelectedFolderId,
  } = props;
  const [driveFiles, setDriveFiles] = useState([]);
  const [displayType, setDisplayType] = useState(0);
  const [driveFolders, setdriveFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState({});
  const [selectedFileToSend, setSelectedFileToSend] = useState(null);

  const [showRoomSelectModal, setShowRoomSelectModal] = useState(false);
  const [messageDataObjState, setMessageDataObjState] = useState({});
  const [messageDataState, setMessageDataState] = useState({});
  const [consumedSpace, setConsumedSpace] = useState(null);
  const [availableSpace, setAvailableSpace] = useState(null);
  const [totalSpace, setTotalSpace] = useState(null);
  const [parentFolder, setParentFolder] = useState({});
  const [imageSelected, setImageSelected] = useState({});
  const [visible, setVisible] = useState(false);
  const [selectedFileForRename, setSelectedFileForRename] = useState({});
  const [fileName, setFileName] = useState("");
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [errorInFileName, setErrorInFileName] = useState("");
  const [deleteFile, setDeleteFile] = useState(null);
  const [deleteFileName, setDeleteFileName] = useState("");

  const [
    showRoomSelectModalForMessages,
    setShowRoomSelectModalForMessage,
  ] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const image_type = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"];

  const audio_type = ["audio/mpeg", "audio/ogg", "audio/mp4", "audio/x-mpegurl", "audio/vnd.wav", "audio/wav", "audio/wave"]

  const pdf_type = ["application/pdf"];

  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="rounded-pill small btn btn-light btn-sm"
    >
      <FontAwesomeIcon icon={faPlus} className="text-dark" />
      <span className="text-dark ml-1">Add</span>
    </a>
  ));
  const CustomToggleForFiles = React.forwardRef(
    ({ children, onClick }, ref) => (
      <a
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="small"
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </a>
    )
  );
  const toggleAddFolder = () => {
    // console.log(props.showCreateFolderModal);
    // console.log(props.currentWorkspace);
    setShowCreateFolderModal(!props.showCreateFolderModal);
    setShowUploadFilesModal(false);
    setSelectedFolderId(
      selectedFolder.folder_id ? selectedFolder.folder_id : null
    );
  };
  const toggleUploadFiles = () => {
    // console.log(props.showUploadFilesModal);
    setShowUploadFilesModal(!props.showUploadFilesModal);
    setShowCreateFolderModal(false);
    setSelectedFolderId(
      selectedFolder.folder_id ? selectedFolder.folder_id : null
    );
  };
  const sendSelectedFileOrImage = (file) => {
    //console.log(file, "respoxxx")
    resetData();
    createMessage(file);
  };

  const handleChange = (e) => {
    if (e.target.name === "fileName") {
      setFileName(e.target.value);
    }
  };
  const renameSelectedFileOrImage = (file) => {
    setSelectedFileForRename(file);
    setErrorInFileName("");
    let FileNameWithoutExtention = "";
    if (file) {
      const arrayOfStrOfFileName = file.file_name.split(".");
      FileNameWithoutExtention = arrayOfStrOfFileName.shift();
    }
    setFileName(FileNameWithoutExtention);
  };
  const updateFileName = async () => {
    setIsUpdatingName(true);
    //let regexStr = /^[a-zA-Z0-9\s]+$/;
    let regexStr = /^[\w\s\_\-]*$/g;
    if (fileName.length < 1) {
      setErrorInFileName("File name can't be null.");
      setIsUpdatingName(false);
      return;
    } else if (!regexStr.test(fileName)) {
      setErrorInFileName(
        "Only alphabets,numbers,hyphens,underscores and spaces allowed."
      );
      setIsUpdatingName(false);
      return;
    } else if (fileName.length > 50) {
      setErrorInFileName("Name can have max 50 characters.");
      setIsUpdatingName(false);
      return;
    }
    let file = driveFiles.find(
      (file) => file.file_id === selectedFileForRename.file_id
    );

    let extention = "";
    if (file) {
      const arrayOfStrOfFileName = file.file_name.split(".");
      if (arrayOfStrOfFileName.length > 1) {
        extention = arrayOfStrOfFileName.pop();
      }
    }

    const fileNameWithExtention = extention
      ? fileName + "." + extention
      : fileName;

    let { currentUser } = props;
    let res = await axios.post(`${API_BASE_URL}${DRIVE_RENAME_FILE}`, {
      user_id: currentUser.uid,
      file_id: selectedFileForRename.file_id,
      renew_file_name: fileNameWithExtention,
    });

    if (res.status === 200) {
      let allDriveFiles = [...driveFiles];
      if(props.darkTheme){
        toast.dark("File name changed.", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose: 3000 });
      }else if(!props.darkTheme){
        toast("File name changed.", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose: 3000 });
      }
      
      let index = driveFiles.findIndex(
        (file) => file.file_id === selectedFileForRename.file_id
      );
      let fileWithUpdatedName = { ...selectedFileForRename };
      fileWithUpdatedName.file_name = fileNameWithExtention;
      allDriveFiles.splice(index, 1, fileWithUpdatedName);
      setIsUpdatingName(false);
      setDriveFiles(allDriveFiles);
      setSelectedFileForRename({});
      setErrorInFileName("");
    } else {
      setIsUpdatingName(false);
      setErrorInFileName("");
    }
  };

  const fileDeleteHandler = async (file) => {
      const { file_id } = file;
      const { currentUser } = props;
      let res = await axios.post(`${API_BASE_URL}${DRIVE_SOFT_DELETE}`, {
        user_id: currentUser.uid,
        file_id: file_id,
      });
      if (res.status === 200) {
        console.log("file deleted")
        fetchdata().then(() => {
          if(props.darkTheme){
            toast.dark("File deleted", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose:3000});
          }else if(!props.darkTheme){
            toast("File deleted", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose:3000});
          }
        });
      }else {
        if(props.darkTheme){
          toast.dark("Could not delete the file", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose: 3000});
        }else if(!props.darkTheme){
          toast("Could not delete the file", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose: 3000});
        }
      
    }
  }


  const fileDownloadHandler = (uri, name) => {
    fetch(uri , {mode: 'no-cors'}).then(function async (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", name);
        a.click();
      }
      );
    });
  }

  const createMessage = (file) => {
    resetData();
    const { currentUser } = props;

    const message = {
      timestamp: Date.now(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      user: {
        id: currentUser.uid,
        name: currentUser.displayName,
        avatar: currentUser.photoURL,
      },
    };
    if (file !== null) {
      console.log(file,"respoxxx")
      if (image_type.includes(file.file_content_type)) {
        message["image"] = file.file_url;
        message["type"] = 6;
      }
      else if (audio_type.includes(file.file_content_type)){
        message["audio"] = file.file_url;
        message["type"] = 11;
      }
       else {
        message["file"] = file.file_url;
        message["type"] = 7;
      }
      message["metaData"] = {
        type: file.file_content_type,
        name: file.file_name,
      };
      message["content"] = ""
    }
    message["content"] = "";
    const messageData = { ...message };
    const messageDataObj = { ...messageData };
    console.log(messageData);
    console.log(messageDataObj);
    setMessageDataObjState({ ...messageDataObj });
    setShowRoomSelectModalForMessage(true);
    setMessageDataState({ ...messageData });
  };
  const fetchdata = async () => {
    let { currentUser } = props;
    let requestData = { user_id: currentUser.uid };
    console.log(selectedFolder);
    if (selectedFolder.folder_id) {
      requestData = {
        user_id: currentUser.uid,
        folder_id: selectedFolder.folder_id,
      };
    }
    let res = await axios.post(`${API_BASE_URL}${DRIVE_HIERARCHY}`, {
      ...requestData,
    });

    let response = await axios.post(
      `${API_BASE_URL}${DRIVE_STORAGE_DETAILS}`,
      { user_id: currentUser.uid }
    );
    console.log(res);

    setAvailableSpace(response.data.data["available"] / 1073741824); //converting bytes into Gib
    setTotalSpace(response.data.data["total"] / 1073741824); //converting bytes into Gib
    setConsumedSpace(response.data.data["consumed"] / 1073741824); //converting bytes into Gib

    setDriveFiles(res.data.data["child_files"]);
    setdriveFolders(res.data.data["child_folders"]);
    setIsLoading(false);
    //setParentFolder(res.data.data);
  };
  useEffect(() => {
    fetchdata();
  }, [props.showUploadFilesModal, props.showCreateFolderModal]);
  const handleFolderFiles = (folder) => {
    setSelectedFolder(folder);
    console.log(selectedFolder);
    //fetchdata();
  };
  const handleSelectedImage = (File) => {
    setVisible(true);
    setImageSelected({
      source: File.file_url,
      content: File.file_name,
    });
  };
  let allImages = driveFiles.filter((file) =>
    image_type.includes(file.file_content_type)
  );

  if (Object.keys(selectedFolder).length > 0) {
    return (
      <>
        {isLoading ? (
          <div className="row d-flex justify-content-center align-center">
            <Spinner variant="light" animation="border" />
          </div>
        ) : (
            <>
              <FolderFiles
                folderId={selectedFolder.folder_id}
                currentUser={props.currentUser}
                parentFolderId={
                  selectedFolder.parent_folder_id
                    ? selectedFolder.parent_folder_id
                    : ""
                }
                handleFolderFiles={handleFolderFiles}
                CustomToggle={CustomToggle}
                CustomToggleForFiles={CustomToggleForFiles}
                toggleAddFolder={toggleAddFolder}
                toggleUploadFiles={toggleUploadFiles}
                createMessage={createMessage}
              />
              <RoomSelectModalForMessages
                messageData={messageDataState}
                messageDataObj={messageDataObjState}
                show={showRoomSelectModalForMessages}
                onHide={() => setShowRoomSelectModalForMessage(false)}
              />
            </>
          )}
      </>
    );
  } else if (Object.keys(imageSelected).length > 0) {
    return ImageViewer(
      imageSelected.source,
      imageSelected.content,
      visible,
      setVisible,
      allImages,
      setImageSelected
    );
  } else {
    return (
      <>
        <div className="d-flex bg-card-theme text-theme align-items-center">
          <div className="p-2">
            <FontAwesomeIcon
              onClick={() => {
                toggleRooms();
                setLeftActionType("");
              }}
              icon={faArrowLeft}
              className="mr-2 pointer"
            />
            <span>
              <FontAwesomeIcon icon={faFolderOpen} style={{ fontSize: 18 }} />{" "}
              My drive
            </span>
          </div>
          <Dropdown className="ml-auto mr-2 px-1 py-2 justify-content-right text-right  pointer  text-theme align-middle">
            <Dropdown.Toggle size="sm" as={CustomToggle} />
            <Dropdown.Menu className="bg-card-theme text-theme">
              <Dropdown.Item
                className="small bg-card-theme text-theme"
                onClick={() => toggleAddFolder()}
              >
                <FontAwesomeIcon icon={faFolderPlus} className="mr-2 pointer" />{" "}
                Add folder
              </Dropdown.Item>
              <Dropdown.Item
                className="small bg-card-theme text-theme"
                onClick={() => toggleUploadFiles()}
              >
                <FontAwesomeIcon icon={faUpload} className="mr-2 pointer" />{" "}
                Upload files
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {isLoading ? (
          <div className="row d-flex justify-content-center align-center">
            <Spinner variant="light" animation="border" />
          </div>
        ) : (
            <div className="px-1 text-theme">
              {driveFolders.length === 0 && driveFiles.length === 0 ? (
                <div className="col">
                  <p className="text-theme text-center small">
                    Your drive is empty
                </p>
                </div>
              ) : null}
              <div className="row m-2">
                {/*<RoundChart
                data={[
                  { label: "Available", value: availableSpace },
                  { label: "Consumed", value: consumedSpace },
                ]}
                options={{ legend: false, labels: false }}
                colors={["black", "white"]}
              />
              <div className="row m-1">
                <div className="col-6 border-right">
                  <h3>
                    {consumedSpace.toFixed(2)}
                    {" gib"}
                  </h3>
                  <small>used</small>
                </div>
                <div className="col-6">
                  <h3>
                    {availableSpace.toFixed(2)}
                    {" gib"}
                  </h3>
                  <small>available</small>
                </div>
              </div>
              */}
                <div className="col-12 p-2 rounded shadow border-1 bg-theme pointer justify-content-center">
                  <div className="d-flex justify-content-between">
                    <small className="text-theme">STORAGE</small>
                    <small className="text-theme">{`${consumedSpace
                      .toFixed(2)
                      .replace(".00", "")} GB/${totalSpace
                        .toFixed(2)
                        .replace(".00", "")} GB`}</small>
                  </div>
                  {/* <h4>Storage </h4>
                  <p>
                    {`${consumedSpace.toFixed(2)} GB of ${totalSpace.toFixed(
                      2
                    )} GB`}{" "}
                  used
                </p> */}
                  <div className="mt-1">
                    <ProgressBar
                      style={{ height: "6px" }}
                      max={5}
                      min={0}
                      //animated
                      now={consumedSpace.toFixed(2)}
                    />
                  </div>
                </div>
              </div>
              {driveFolders.length > 0 || driveFiles.length > 0 ? (
                <div className="d-flex justify-content-end m-2">
                  <ButtonGroup size="sm">
                    <Button
                      variant={
                        displayType === 0 ? "secondary" : "outline-secondary"
                      }
                      onClick={() => setDisplayType(0)}
                      title="List view"
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </Button>
                    <Button
                      variant={
                        displayType === 1 ? "secondary" : "outline-secondary"
                      }
                      onClick={() => setDisplayType(1)}
                      title="Thumbnail view"
                    >
                      <FontAwesomeIcon icon={faThLarge} />
                    </Button>
                  </ButtonGroup>
                </div>
              ) : null}
              <div className="row m-2">
                {driveFolders.map((folder) => (
                  <div
                    className={
                      displayType === 0
                        ? "col-12 p-1 rounded border-1 bg-theme pointer d-flex align-items-center"
                        : "col-3 p-1 rounded border-1 bg-theme pointer d-flex flex-column"
                    }
                    key={folder.folder_id}
                    onClick={() => handleFolderFiles(folder)}
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/folder-blue-512.png"
                        className={displayType === 0 ? "" : "w-75"}
                        title={folder.folder_name}
                        style={
                          displayType === 0
                            ? { widht: "20px", height: "20px" }
                            : {}
                        }
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <p
                        title={folder.folder_name}
                        className={
                          displayType === 0
                            ? "text-theme ml-2 mb-0 small"
                            : "text-theme ml-2 mb-0 small text-truncate"
                        }
                      // className="text-theme ml-2 mb-0 small text-truncate"
                      >
                        {folder.folder_name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row m-2 ">
                {driveFiles.map((File) => {
                  if (!File.soft_deleted) {
                    return (
                      <>
                        {displayType === 0 && (
                          <div
                            className="col-12 p-1 rounded shadow border-1 bg-theme align-items-center"
                            title={File.file_name}
                            key={File.file_url}
                          >
                            <div className="row align-items-center">
                              <a
                                href={
                                  !image_type.includes(File.file_content_type) &&
                                  File.file_url
                                }
                                onClick={() =>
                                  image_type.includes(File.file_content_type) &&
                                  handleSelectedImage(File)
                                }
                                className="text-theme text-decoration-none col-11 d-flex align-items-center"
                                target="_blank"
                              >
                                {word_type.includes(File.file_content_type) && (
                                  <FontAwesomeIcon
                                    icon={faFileWord}
                                    className="text-primary mr-1"
                                  />
                                )}
                                {image_type.includes(File.file_content_type) && (
                                  <>
                                    <div>
                                      <img
                                        style={{
                                          resizeMode: "contain",
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        src={File.file_url}
                                      />
                                    </div>
                                    {/*<FontAwesomeIcon
                              icon={faImage}
                              className="text-secondary mr-1"
                            />*/}
                                  </>
                                )}
                                {pdf_type.includes(File.file_content_type) && (
                                  <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="text-danger mr-1"
                                  />
                                )}
                                {powerpoint_type.includes(File.file_content_type) && (
                                  <FontAwesomeIcon
                                    icon={faFilePowerpoint}
                                    className="text-danger mr-1"
                                  />
                                )}
                                {excel_type.includes(File.file_content_type) && (
                                  <FontAwesomeIcon
                                    icon={faFileExcel}
                                    className="text-success mr-1"
                                  />
                                )}{" "}
                                <span className="d-block text-truncate small pointer">
                                  {File.file_name}
                                </span>
                              </a>

                              <div className="col-1 justify-content-center p-0">
                                {props.currentWorkspace ? (
                                  <Dropdown className="pointer text-theme">
                                    <Dropdown.Toggle
                                      size="sm"
                                      as={CustomToggleForFiles}
                                    />
                                    <Dropdown.Menu className="bg-card-theme text-theme">
                                      <Dropdown.Item
                                        className="small bg-card-theme text-theme"
                                        onClick={() => sendSelectedFileOrImage(File)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faShareAlt}
                                          className="mr-2 pointer"
                                        />{" "}
                                  Share to rooms
                                </Dropdown.Item>
                                      <Dropdown.Item
                                        className="small bg-card-theme text-theme"
                                        onClick={() =>
                                          renameSelectedFileOrImage(File)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faICursor}
                                          className="mr-2 pointer"
                                        />{" "}
                                  Rename
                                </Dropdown.Item>
                                      <Dropdown.Item
                                        className="small bg-card-theme text-theme"
                                        onClick={() =>  {
                                          handleShow()
                                          setDeleteFile(File)
                                          //fileDeleteHandler(File)
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrashAlt}
                                          className="mr-2 pointer"
                                        />{" "}
                                  Delete{" "}
                                      </Dropdown.Item>
                                      {/* <Dropdown.Item
                                        className="small"
                                        onClick={() =>
                                          fileDownloadHandler(File.file_url, File.file_name)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faDownload}
                                          className="mr-2 pointer"
                                        />{" "}
                                  Download{" "}
                                      </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                ) : null}
                              </div>
                            </div>
                            {Object.keys(selectedFileForRename).length > 0 &&
                              selectedFileForRename.file_id === File.file_id ? (
                                <>
                                  <InputGroup className="my-3" size="sm">
                                    <FormControl
                                      placeholder="File's name"
                                      aria-label=""
                                      aria-describedby="basic-addon2"
                                      name="fileName"
                                      autoFocus={true}
                                      value={fileName}
                                      onChange={(e) => handleChange(e)}
                                    />

                                    <InputGroup.Append>
                                      {isUpdatingName ? (
                                        <Button variant="primary" disabled>
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                  Updating...
                                        </Button>
                                      ) : (
                                          <Button
                                            variant="outline-success"
                                            size="sm"
                                            onClick={() => updateFileName()}
                                          >
                                            Update
                                          </Button>
                                        )}
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                      <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => {
                                          setSelectedFileForRename({});
                                          setErrorInFileName("");
                                          setFileName("");
                                          setIsUpdatingName(false);
                                        }}
                                      >
                                        Cancel
                              </Button>
                                    </InputGroup.Append>
                                  </InputGroup>
                                  {errorInFileName ? (
                                    <p className="small text-danger">
                                      {errorInFileName}
                                    </p>
                                  ) : null}
                                </>
                              ) : null}
                          </div>
                        )}

                        {displayType === 1 && (
                          <>
                            <div
                              className="col-3 p-2 rounded shadow border-1 bg-theme"
                              title={File.file_name}
                              key={File.file_url}
                            >
                              <div className="d-flex">
                                <a
                                  href={
                                    !image_type.includes(File.file_content_type) &&
                                    File.file_url
                                  }
                                  onClick={() =>
                                    image_type.includes(File.file_content_type) &&
                                    handleSelectedImage(File)
                                  }
                                  className="text-theme text-decoration-none"
                                  target="_blank"
                                >
                                  {word_type.includes(File.file_content_type) && (
                                    <FontAwesomeIcon
                                      icon={faFileWord}
                                      className="w-75 text-primary h1 mr-1"
                                    />
                                  )}
                                  {image_type.includes(File.file_content_type) && (
                                    <>
                                      <div>
                                        <img
                                          className=" text-secondary h1 mr-1"
                                          style={{
                                            resizeMode: "contain",
                                            width: "35px",
                                            height: "35px",
                                          }}
                                          src={File.file_url}
                                        />
                                      </div>
                                      {/*<FontAwesomeIcon
                              icon={faImage}
                              className="w-75 text-secondary h1 mr-1"
                            />*/}
                                    </>
                                  )}
                                  {pdf_type.includes(File.file_content_type) && (
                                    <FontAwesomeIcon
                                      icon={faFilePdf}
                                      className="w-75 text-danger h1 mr-1"
                                    />
                                  )}
                                  {powerpoint_type.includes(
                                    File.file_content_type
                                  ) && (
                                      <FontAwesomeIcon
                                        icon={faFilePowerpoint}
                                        className="w-75 text-danger h1 mr-1"
                                      />
                                    )}
                                  {excel_type.includes(File.file_content_type) && (
                                    <FontAwesomeIcon
                                      icon={faFileExcel}
                                      className="w-75 text-success h1 mr-1"
                                    />
                                  )}
                                </a>
                                <div>
                                  {props.currentWorkspace ? (
                                    <Dropdown className="p-0 col-12 d-flex justify-content-center">
                                      <Dropdown.Toggle
                                        size="sm"
                                        as={CustomToggleForFiles}
                                      />
                                      <Dropdown.Menu className="bg-card-theme text-theme">
                                        <Dropdown.Item
                                          className="small bg-card-theme text-theme"
                                          onClick={() =>
                                            sendSelectedFileOrImage(File)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faShareAlt}
                                            className="mr-2 pointer"
                                          />{" "}
                                    Share to rooms
                                  </Dropdown.Item>
                                        <Dropdown.Item
                                          className="small bg-card-theme text-theme "
                                          onClick={() =>
                                            renameSelectedFileOrImage(File)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faICursor}
                                            className="mr-2 pointer"
                                          />{" "}
                                    Rename{" "}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          className="small bg-card-theme text-theme"
                                          onClick={() =>  {
                                            handleShow()
                                            setDeleteFile(File)
                                            //fileDeleteHandler(File)
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrashAlt}
                                            className="mr-2 pointer"
                                          />{" "}
                                    Delete{" "}
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item
                                          className="small"
                                          onClick={() =>
                                            fileDownloadHandler(File.file_url, File.file_name)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faDownload}
                                            className="mr-2 pointer"
                                          />{" "}
                                  Download{" "}
                                        </Dropdown.Item> */}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : null}
                                </div>
                              </div>

                              <div className="d-flex justify-content-center">
                                <span className="text-truncate  smaller pointer">
                                  <a
                                    href={
                                      !image_type.includes(File.file_content_type) &&
                                      File.file_url
                                    }
                                    onClick={() =>
                                      image_type.includes(File.file_content_type) &&
                                      handleSelectedImage(File)
                                    }
                                    className="text-theme text-decoration-none"
                                    target="_blank"
                                  >
                                    {File.file_name}
                                  </a>
                                </span>
                              </div>
                            </div>
                            {Object.keys(selectedFileForRename).length > 0 &&
                              selectedFileForRename.file_id === File.file_id ? (
                                <>
                                  <InputGroup
                                    className="my-3"
                                    size="sm"
                                    style={{ zIndex: 300 }}
                                  >
                                    <FormControl
                                      placeholder="File's name"
                                      aria-label=""
                                      aria-describedby="basic-addon2"
                                      autoFocus={true}
                                      name="fileName"
                                      value={fileName}
                                      onChange={(e) => handleChange(e)}
                                    />
                                    <InputGroup.Append>
                                      {isUpdatingName ? (
                                        <Button variant="primary" disabled>
                                          <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                          />
                                  Updating...
                                        </Button>
                                      ) : (
                                          <Button
                                            variant="outline-success"
                                            onClick={() => updateFileName()}
                                          >
                                            Update
                                          </Button>
                                        )}
                                    </InputGroup.Append>
                                    <InputGroup.Append>
                                      <Button
                                        variant="outline-danger"
                                        size="sm"
                                        onClick={() => {
                                          setSelectedFileForRename({});
                                          setErrorInFileName("");
                                          setFileName("");
                                        }}
                                      >
                                        Cancel
                              </Button>
                                    </InputGroup.Append>
                                  </InputGroup>
                                  {errorInFileName ? (
                                    <p className="small text-danger mt-2">
                                      {errorInFileName}
                                    </p>
                                  ) : null}
                                </>
                              ) : null}
                          </>
                        )}
                      </>
                    )
                  }
                })}
              </div>
              <>
              <Modal className="border-dark" animation show={show} onHide={handleClose}>
                <div className="bg-theme text-theme">
                  <div className="container bg-theme text-theme">
                    <h5 className="mt-3"><FontAwesomeIcon className="mr-2"  icon={faExclamationTriangle} />Delete this File?</h5>
                    <p className="small">Doing so will permanently delete this file.</p>
                  </div>
                  <div className="container mt-3 bg-theme text-theme">
                    <div className="font-weight-light">File</div>
                    <div className="font-weight-bold">{deleteFile?.file_name}</div>
                  </div>
                </div>
                  
                
                <Modal.Body className="mt-0 bg-theme text-theme">
                  <Form>
                    
                    <Form.Group controlId="Basic">
                      <div className="text-weight-light">Confirm that you want to delete this file by typing its name : </div>
                      <div className="font-weight-bold">{deleteFile?.file_name}</div>
                      <Form.Control 
                        type="text" 
                        placeholder="Enter File Name"
                        onChange ={(e) => setDeleteFileName(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                  <Button className="mr-5" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button 
                    variant="primary" 
                    disabled={deleteFile?.file_name != deleteFileName}
                    onClick ={() =>{
                      fileDeleteHandler(deleteFile)
                      handleClose()
                    }}
                  >
                    Delete
                  </Button>
                </Modal.Body>
                
                  
                
              </Modal>
              </>
            </div>
          )}
        ;
        <RoomSelectModalForMessages
          messageData={messageDataState}
          messageDataObj={messageDataObjState}
          show={showRoomSelectModalForMessages}
          onHide={() => setShowRoomSelectModalForMessage(false)}
        />
      </>
    );
  }
};
const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  folder: state.task.folder,
  showUploadFilesModal: state.uploadFilesModal.showUploadFilesModal,
  showCreateFolderModal: state.createFolderModal.showCreateFolderModal,
  selectedFolderId: state.selectedFolder.id,
  darkTheme: state.platform.darkTheme,
});
export default connect(mapStateToProps, {
  setShowCreateFolderModal,
  setShowUploadFilesModal,
  setSelectedFolderId,
  resetData,
})(MyDrive);
