import React from 'react';
import { ProgressBar, Button, Form, ButtonGroup, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileWord, faFileExcel, faFilePowerpoint, faExclamationCircle, faImage, faCloudUploadAlt, faLink, faTimes } from '@fortawesome/free-solid-svg-icons'
import DateTimePicker from 'react-datetime-picker'
import firebase from "firebase";
import uuidv4 from "uuid/v4";
import mime from "mime-types";
import moment from "moment";
import { connect } from 'react-redux';
import {
    setReadingId,
    setReadingTitles,
    setReadingLinks,
    setReadingFiles,
    setReadingUrls,

    setTaskDeadline,
    setTaskChannelUsers,
    setTaskFolder,
    setTaskEveryone,
    setTaskOldFolder,
    editTask,
    editSaveTask,
    publishSaveTask
} from '../../../actions/index';
import TaskCommon from '../Common/TaskCommon';

class EditReading extends React.Component {
    state = {
        storageRef: firebase.storage().ref(),
        uploadTask: null,
        uploadState: [],
        percentUploaded: [],
        authorized: [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "image/svg+xml",
            "image/gif",

            "application/pdf",

            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ],
        extension: "",

        addAll: false,
        error: ''
    }

    componentDidMount() {
        let temp_titles = [], temp_links = [], temp_urls = [];
        this.props.data.readings.map((reading, key) => {
            temp_titles.push(reading.title)
            temp_links.push(reading.link)
            temp_urls.push(reading.file)
        });
        this.setState({titles: temp_titles, links: temp_links, urls: temp_urls});

        const {
            setReadingId,
            setReadingTitles,
            setReadingLinks,
            setReadingFiles,
            setReadingUrls,

            setTaskDeadline,
            setTaskChannelUsers,
            setTaskFolder,
            setTaskEveryone,
            setTaskOldFolder
        } = this.props;

        setReadingId(this.props.taskId)
        setReadingTitles(temp_titles)
        setReadingLinks(temp_links)
        setReadingUrls(temp_urls)

        setTaskDeadline(new Date(this.props.data.deadline))
        setTaskChannelUsers(Object.keys(this.props.data.users))
        setTaskFolder(this.props.data.folder)
        setTaskEveryone(this.props.data.everyone)
        setTaskOldFolder(this.props.data.folder)
    }

    componentWillUnmount() {
        if (this.state.uploadTask !== null) {
            this.state.uploadTask.cancel();
            this.setState({ uploadTask: null });
        }
    }

    addFile = (event, key) => {
        const { files } = this.props
        let prev = [...files];
        
        if (event.target.files[0]) {
            prev[key] = event.target.files[0];
            this.props.setReadingFiles(prev);
            
            // if (this.isAuthorized(event.target.files[0].name)) {
            //     const metadata = { contentType: mime.lookup(event.target.files[0].name) };
            //     this.uploadFile(event.target.files[0], metadata, key);
            //     this.clearFile();
            // }
        }
    };

    clickToUploadFile = (files, key) => {
        if (this.isAuthorized(files.name)) {
            const metadata = { contentType: mime.lookup(files.name) };
            this.uploadFile(files, metadata, key);
            this.clearFile();
        }
    }

    isAuthorized = filename => {
        return this.state.authorized.includes(mime.lookup(filename));
    }

    uploadFile = (file, metadata, key) => {
        const filePath = `chat/public/${uuidv4()}.${file.name.split(".").reverse()[0]}`;

        let preUploadState = [...this.state.uploadState];
        preUploadState[key] = "uploading";

        this.setState(
            {
                uploadState: preUploadState,
                uploadTask: this.state.storageRef.child(filePath).put(file, metadata)
            },
            () => {
                this.state.uploadTask.on(
                    "state_changed",
                    snap => {
                        let prevPercentUpload = [...this.state.percentUploaded];
                        const percentUpload = Math.round(
                            (snap.bytesTransferred / snap.totalBytes) * 100
                        );
                        prevPercentUpload[key] = percentUpload;
                        this.setState({ percentUploaded: prevPercentUpload });
                    },
                    err => {
                        console.error(err);
                        let preUploadState = [...this.state.uploadState];
                        preUploadState[key] = "error";
                        this.setState({
                            errors: this.state.errors.concat(err),
                            uploadState: preUploadState,
                            uploadTask: null
                        });
                    },
                    () => {
                        this.state.uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then(downloadUrl => {
                                let prev = [...this.props.urls];
                                prev[key] = downloadUrl;
                                this.props.setReadingUrls(prev);
                                console.log(downloadUrl)
                            })
                            .catch(err => {
                                console.error(err);
                                let preUploadState = [...this.state.uploadState];
                                preUploadState[key] = "error";
                                this.setState({
                                    errors: this.state.errors.concat(err),
                                    uploadState: preUploadState,
                                    uploadTask: null
                                });
                            });
                    }
                );
            }
        );
    };

    clearFile = () => this.setState({ file: null });


    // ADDING READING
    addReading = () => {
        let prevTitles = [...this.props.titles];
        prevTitles[prevTitles.length] = "";

        let prevLinks = [...this.props.links];
        prevLinks[prevLinks.length] = "";

        let prevUrls = [...this.props.urls];
        prevUrls[prevUrls.length] = "";

        this.props.setReadingTitles(prevTitles);
        this.props.setReadingLinks(prevLinks);
        this.props.setReadingUrls(prevUrls);
    }

    // REMOVING READING
    removeReading = (key) => {
        let prevTitles = [...this.props.titles];
        prevTitles.splice(key,1);

        let prevLinks = [...this.props.links];
        prevLinks.splice(key,1);

        let prevUrls = [...this.props.urls];
        prevUrls.splice(key,1);
        
        this.props.setReadingTitles(prevTitles);
        this.props.setReadingLinks(prevLinks);
        this.props.setReadingUrls(prevUrls);
    }

    // HANDLES TITLES ADDED
    handleTitles = (e,key) => {
        let prev = [...this.props.titles];
        prev[key] = e.target.value;
        this.props.setReadingTitles(prev);
    }
    // HANDLES LINKS ADDED
    handleLinks = (e,key) => {
        let prev = [...this.props.links];
        prev[key] = e.target.value;
        this.props.setReadingLinks(prev);
    }

    render() {
        const { type, titles, links, files, urls, channelUsers, deadline, folder, old_folder, everyone, editTask, uid, currentChannel, currentUser, data, taskBranch } = this.props;
        const { percentUploaded } = this.state;

        const image_type = [
            "image/jpeg",
            "image/png",
            "image/svg+xml",
            "image/gif"];

        const pdf_type = ["application/pdf"];

        const word_type = ["application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

        const excel_type = ["application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

        const powerpoint_type = ["application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"];

        const validateData = () => {
            let filtered_titles = titles.filter(title => title.length > 0);
            if (titles.length != filtered_titles.length) {
                this.setState({error: "Please add some value to title(s).\n"});
                return null;
            }
            if (titles.length == 0) {
                this.setState({error: "Files must have at-least one or more files(s).\n"});
                return null;
            }

            let filtered_links = links.filter((link, key) => link.length > 0 || urls[key].length > 0);
            if (links.length != filtered_links.length) {
                this.setState({error: "Both link or file cannot be empty.\n"});
                return null;
            }

            if ( moment(deadline).diff( moment(Date.now()), 'minutes') < 9 ) {
                this.setState({error: "Deadline of task should be atleast 10 minutes.\n"});
                return null;
            }

            if (!everyone && channelUsers.length < 2) {
                this.setState({error: "Please assign task to atleast one participant.\n"});
                return null;
            }

            this.setState({error: ""});
            return true;
        }

        const getReadings = () => {
            let user = data.user;
            let timestamp = data.timestamp;
            let response = data.response ? data.response : null;
            let readings = [];

            titles.map((title, key) => readings.push({title: titles[key], link: links[key], file: urls[key]}));
            let newState = {
                type,
                deadline,
                readings,
                channelUsers, 
                user, 
                timestamp, 
                uid,
                response, 
                folder,
                everyone
            };
            
            return newState;
        }

        return (
            <React.Fragment>
                <div className="taskBox">
                <h5>Reading</h5>
                    <Form className="feedback">

                        {titles.length > 0
                        ? titles.map((title, key) => 
                            (<div className="mb-3">
                                <div className="d-flex">
                                    <p className="mb-0 flex-grow-1">Reading {key + 1}.</p>
                                    <div><FontAwesomeIcon icon={faTimes} onClick={() => this.removeReading(key)} /></div>
                                </div>
                                <InputGroup className="mb-1">
                                    <FormControl
                                        placeholder="Reading title"
                                        aria-label="Reading title"
                                        aria-describedby={"title"+key}
                                        name="titles[]"
                                        value={titles[key]}
                                        onChange={e => this.handleTitles(e,key)}
                                        required={true}
                                    />
                                </InputGroup>
                                <InputGroup className="mb-1">
                                    <FormControl
                                        placeholder="Paste link to video or reading"
                                        aria-label="Reading link"
                                        aria-describedby={"link"+key}
                                        as="textarea"
                                        name="links[]"
                                        value={links[key]}
                                        onChange={e => this.handleLinks(e,key)}
                                        required={true}
                                        size="sm"
                                    />
                                </InputGroup>
                                {files[key] &&
                                    <div>
                                        <div className="d-flex rounded bg-light mt-2">
                                            <div className="py-2 px-3" style={{ backgroundColor: "#FEF2E4" }}>
                                                {(!this.state.authorized.includes(files[key].type)) && <FontAwesomeIcon icon={faExclamationCircle} className="text-secondary" />}
                                                {image_type.includes(files[key].type) && <FontAwesomeIcon icon={faImage} className="text-secondary" />}
                                                {word_type.includes(files[key].type) && <FontAwesomeIcon icon={faFileWord} className="text-primary" />}
                                                {pdf_type.includes(files[key].type) && <FontAwesomeIcon icon={faFilePdf} className="text-danger" />}
                                                {powerpoint_type.includes(files[key].type) && <FontAwesomeIcon icon={faFilePowerpoint} className="text-danger" />}
                                                {excel_type.includes(files[key].type) && <FontAwesomeIcon icon={faFileExcel} className="text-success" />}
                                            </div>
                                            <div className="p-2 flex-grow-1">
                                                <small className="font-weight-bold">{files[key].name}</small>
                                            </div>
                                            <div className="py-2 px-3">
                                                {!urls[key] && <FontAwesomeIcon icon={faCloudUploadAlt} className="text-secondary" onClick={() => this.clickToUploadFile(files[key], key)} />}
                                                {urls[key] && <a href={urls[key]} target="_blank"><FontAwesomeIcon icon={faLink} className="text-primary" /></a>}
                                            </div>
                                        </div>
                                        <ProgressBar style={{ height: "0.2rem" }} now={percentUploaded[key]} striped variant="success" />
                                    </div>}
                                {!urls[key] && files[key] && <p className="small">Click on <FontAwesomeIcon icon={faCloudUploadAlt} className="text-secondary" /> to upload, or click on "Attach" to choose another file.</p>}
                                {urls[key] && files[key] && <p className="small">Click on <FontAwesomeIcon icon={faLink} className="text-secondary" /> to see attached file!</p>}

                                <InputGroup className="mb-1">
                                    <FormControl
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        type="file"
                                        name={key}
                                        onChange={e => this.addFile(e, key)}
                                    />
                                </InputGroup>
                                {urls[key] && <a className="mt-0 mb-0" href={urls[key]} target="_blank">Click here to see file attached</a>}
                            </div>))
                        : null}

                        <InputGroup className="w-100 mb-3">
                            <Button 
                            size="sm" 
                            variant="outline-primary" 
                            className="w-100"
                            onClick={this.addReading}>+ <small>Add Reading</small></Button>
                        </InputGroup>

                        <TaskCommon />
                        
                        <div className="d-flex">
                            {taskBranch === "saved"
                                ? <>
                                    <Button 
                                    onClick={() => validateData() && this.props.publishSaveTask(getReadings(), uid, old_folder, currentChannel, currentUser)} 
                                    variant="primary"
                                    style={{width: "100px", marginRight: "5px"}} 
                                    size="sm" ><small>Share now</small></Button>

                                    <Button 
                                    onClick={() => validateData() && this.props.editSaveTask(getReadings(), uid, old_folder, currentChannel, currentUser)} 
                                    variant="success" 
                                    style={{width: "100px", marginLeft: "5px"}}
                                    size="sm" ><small>Save for later</small></Button>
                                </>
                                :
                                <Button 
                                onClick={() => validateData() && editTask(getReadings(), uid, old_folder, currentChannel, currentUser)} 
                                variant="primary" 
                                style={{width: "100px", marginRight: "5px"}}
                                size="sm" ><small>Share now</small></Button>}
                        </div>
                        <p className="my-2 p-0 text-danger">{this.state.error}</p>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentChannel: state.channel.currentChannel,
    currentUser: state.user.currentUser,

    taskId: state.platform.taskId,
    taskBranch: state.platform.taskBranch,

    deadline: state.task.deadline,
    channelUsers: state.task.channelUsers,
    folder: state.task.folder,
    old_folder: state.task.old_folder,
    everyone: state.task.everyone,

    uid: state.reading.id,
    titles: state.reading.titles,
    links: state.reading.links,
    files: state.reading.files,
    urls: state.reading.urls,
    type: state.reading.type
})

export default connect(
    mapStateToProps, {
        setReadingId,
        setReadingTitles,
        setReadingLinks,
        setReadingFiles,
        setReadingUrls,
    
        setTaskDeadline,
        setTaskChannelUsers,
        setTaskFolder,
        setTaskEveryone,
        setTaskOldFolder,
        editTask,
        editSaveTask,
        publishSaveTask
    })(EditReading);