import React from "react";
import {
  Button,
  Form,
  ButtonGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import { connect } from "react-redux";
import firebase from "firebase";

//import { setFolderName, setFolderDescription, addFolder } from '../../../actions/index';
import { toast } from "react-toastify";
toast.configure();

class AddFolder extends React.Component {
  // ALL STATES
  state = {
    error: "",
    folderName: "",
    folderDescription: "",
    errorObj: { folderName: "" },
  };

  // VALIDATION OF DATA
  validate_data = ({ folderName, folderDescription }) => {
    if (folderName.length == 0) {
      this.setState({ error: "Please enter folder name." });
      return false;
    }

    // if (description.length == 0) {
    //     this.setState({error: "Please enter a description."})
    //     return false;
    // }

    this.setState({ error: "" });
    return true;
  };
  validateDataOnFieldLevel = (e) => {
    let errorObj = {};
    if (e.target.name === "folderName") {
      if (e.target.value.trim().length === 0) {
        errorObj.folderName = "Folder name must not be empty.";
      }
    }
    this.setState({ errorObj });
  };
  addFolder = ({
    currentChannel,
    currentUser,
    folderName,
    folderDescription,
  }) => {
    const { currentWorkspace, darkTheme } = this.props;
    //console.log(folderName, folderDescription, currentChannel, currentUser);
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/folders`
      );
    let requestData = {
      timestamp: Date.now(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      user: {
        id: currentUser.uid,
        name: currentUser.displayName,
        avatar: currentUser.photoURL,
      },
      createdBy: currentUser.uid,
      folderName: folderName,
      folderDescription: folderDescription ? folderDescription : "",
    };
    console.log(requestData);

    query
      .add({ ...requestData })
      .then(
        () =>{
          if(darkTheme){
          toast.dark("Folder Created.", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar:true, autoClose: 3000 }) &&
          this.props.addFolderView(0)
          }else{
            toast("Folder Created.", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000}) &&
            this.props.addFolderView(0)
          }
          
        }
          
      )
      .catch((error) => console.log(error));
  };
  // MAIN RENDER FUNCTION
  render() {
    const { currentChannel, currentUser } = this.props;
    const { folderName, folderDescription, error, errorObj } = this.state;

    return (
      <React.Fragment>
        <div>
          <Form>
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme">
              <p className="mb-2 font-weight-bold text-theme small">
                Name (Required)
              </p>
              <InputGroup className="mb-1">
                <FormControl
                  placeholder="Add name"
                  name="folderName"
                  autoFocus
                  onBlur={this.validateDataOnFieldLevel}
                  onKeyPress={(event) => {
                    if (event.key === "Enter"){
                      event.preventDefault();
                      document.getElementById("description").focus();
                    }
                  }}
                  onKeyUp={this.validateDataOnFieldLevel}
                  value={folderName}
                  onChange={(e) =>
                    this.setState({ folderName: e.target.value })
                  }
                  required={true}
                />
              </InputGroup>
              {errorObj.folderName ? (
                <small className="text-danger">{errorObj.folderName}</small>
              ) : null}

              <p className="mb-2 font-weight-bold text-theme small">
                Description
              </p>
              <InputGroup className="mb-3">
                <TextareaAutosize
                  id = "description"
                  className="w-100 p-2 rounded"
                  style={{ borderColor: "#CDCDCD", minWidth: "300px" }}
                  name="folderDescription"
                  value={folderDescription}
                  onChange={(e) =>
                    this.setState({ folderDescription: e.target.value })
                  }
                  minRows={3}
                  maxRows={20}
                  placeholder="Add a brief about folder"
                />
              </InputGroup>
            </div>
            <div className="m-2">
              <p className="my-2 p-0 text-danger">{error}</p>
              <ButtonGroup>
                <Button
                  onClick={() =>
                    this.validate_data({ folderName, folderDescription }) &&
                    this.addFolder({
                      currentChannel,
                      currentUser,
                      folderName,
                      folderDescription,
                    })
                  }
                  variant="outline-primary"
                  size="sm"
                >
                  Create
                </Button>
              </ButtonGroup>
            </div>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, null)(AddFolder);
