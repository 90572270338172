import React, { Component } from "react";
import firebase from "firebase";
import { timeFromNow } from "../../utils/index";
import { connect } from "react-redux";
import {
  setMessageForward,
  setMessagesForwardArray,
  setCurrentChannel,
  setCurrentWorkspace,
  setParticipants,
  resetData,
  setLeftPanel,
} from "../../actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbtack,
  faLock,
  faLockOpen,
  faPoll,
  faFileAlt,
  faImage,
  faFileWord,
  faFilePdf,
  faClipboard,
  faFilePowerpoint,
  faFileExcel,
  faCircle,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const pdf_type = ["application/pdf"];

const word_type = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const excel_type = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const powerpoint_type = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

const complete_month_name = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const short_month_name = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const FileIcon = ({ message }) => {
  if (word_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFileWord} className="text-theme" />;
  if (pdf_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFilePdf} className="text-theme" />;
  if (powerpoint_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFilePowerpoint} className="text-theme" />;
  if (excel_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFileExcel} className="text-theme" />;
  return <div></div>;
};

class RoomListElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomData: {},
      roomLiveStatus: false,
    };
  }

  _handleStateChange = (attr, val) => this.setState({ [attr]: val });

  getRoomData = () => {
    const _handleStateChange = this._handleStateChange;
    const { room, workspace } = this.props;

    const { workspaceId } = workspace;
    const workspaceIdLowerCase = workspaceId.toLowerCase();

    const { roomId } = room;
    const roomIdLowerCase = roomId.toLowerCase();

    firebase
      .firestore()
      .collection(`workspaces/${workspaceId}/rooms`)
      .doc(`${roomId}`)
      .onSnapshot(
        {
          includeMetadataChanges: true,
        },
        function (doc) {
          if (doc.exists) {
            // console.log("Room list element:", doc.data());
            _handleStateChange("roomData", doc.data());
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        }
      );

    firebase
      .firestore()
      .collection(`sessions/${workspaceIdLowerCase}/rooms`)
      .doc(`${roomIdLowerCase}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          // console.log("LIVE Sessions doc: ", doc.data())
          const { currentSession } = doc.data();
          if (currentSession !== "") {
            _handleStateChange("roomLiveStatus", true);
          } else {
            _handleStateChange("roomLiveStatus", false);
          }
        }
      });
  };

  componentDidMount() {
    this.getRoomData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { room } = this.props;

    if (prevProps.room.roomId != room.roomId) {
      this.getRoomData();
    }
  }

  strip = (value) => {
    // if (value.image) {
    //   return value.content
    //     ? <span><FontAwesomeIcon icon={faImage} /> {value.content > 30 ? value.content.slice(0, 30) + '…' : value.content}</span>
    //     : <span><FontAwesomeIcon icon={faImage} /> Photo</span>
    // }
    // else if (value.file) {
    //   return value.content
    //     ? <span><FileIcon message={value} /> {value.content > 30 ? value.content.slice(0, 30) + '…' : value.content}</span>
    //     : <span><FileIcon message={value} /> {value.metaData && value.metaData.name}</span>
    // }
    // else if (value.type === "Quiz") {
    //   return <span><FontAwesomeIcon icon={faTasks} className="text-dark" /> {value.quiz_name}</span>
    // }
    // else if (value.type === "Poll" || value.hasOwnProperty("poll")) {
    //   return <span><FontAwesomeIcon icon={faPoll} className="text-dark" /> {value.poll}</span>
    // }
    // else if (value.type === "Reading" || value.hasOwnProperty("readings")) {
    //   return <span><FontAwesomeIcon icon={faClipboard} className="text-dark" /> {value.readings && value.readings.length} reading(s)</span>
    // }
    // else if (value.type === "Schedule" || value.hasOwnProperty("schedule")) {
    //   return <span><FontAwesomeIcon icon={faCalendarAlt} className="text-dark" /> {short_month_name[(moment(value.deadline).format("M")) - 1]} {moment(value.deadline).format("DD, YYYY")} - {value.schedule}</span>
    // }
    // else if (value.type === "Assignment" || value.hasOwnProperty("questions")) {
    //   return <span><FontAwesomeIcon icon={faFileAlt} className="text-dark" /> {value.questions && value.questions.length} question(s)</span>
    // }
    if (value.type === 0) {
      return value.content.length > 30
        ? value.content.slice(0, 30) + "..."
        : value.content;
    } else if (value.type === 1) {
      return (
        <span>
          <FontAwesomeIcon icon={faCalendarAlt} className="text-theme" />{" "}
          Schedule
        </span>
      );
    } else if (value.type === 2) {
      return (
        <span>
          <FontAwesomeIcon icon={faClipboard} className="text-theme" /> Files
        </span>
      );
    } else if (value.type === 3) {
      return (
        <span>
          <FontAwesomeIcon icon={faPoll} className="text-theme" /> Poll
        </span>
      );
    } else if (value.type === 6) {
      return value.content ? (
        <span>
          <FontAwesomeIcon icon={faImage} />{" "}
          {value.content > 30
            ? value.content.slice(0, 30) + "…"
            : value.content}
        </span>
      ) : (
        <span>
          <FontAwesomeIcon icon={faImage} /> Photo
        </span>
      );
    } else if (value.type === 7) {
      return value.content ? (
        <span>
          <FileIcon message={value} />{" "}
          {value.content > 30
            ? value.content.slice(0, 30) + "…"
            : value.content}
        </span>
      ) : (
        <span>
          <FileIcon message={value} /> {value.metaData && value.metaData.name}
        </span>
      );
    } else {
      return value.type;
    }
  };

  // render() {
  //   const {
  //     room,
  //     key,
  //     workspace,
  //     workspaceData,
  //     setCurrentChannel,
  //     setCurrentWorkspace,
  //     currentChannel,
  //     setParticipants,
  //   } = this.props;
  //   const { roomData } = this.state;
  //   const { isPinned, isVisible, roomId, roomName } = this.props.room;

  render() {
    const {
      room,
      key,
      workspace,
      workspaceData,
      setCurrentChannel,
      setCurrentWorkspace,
      setMessageForward,
      setMessagesForwardArray,
      currentChannel,
      setParticipants,
      resetData,
      messagesForwardArray,
      setLeftPanel,
    } = this.props;
    const { roomData, roomLiveStatus } = this.state;
    const { isPinned, isVisible, roomId, roomName } = this.props.room;

    // if (!isVisible) {
    //     return;
    // }
    return (
      <li
        className={
          currentChannel && room.roomId === currentChannel.roomId
            ? "active rounded-lg py-1 m-1 bg-card-theme shadow-sm text-theme"
            : "rounded-lg py-1 m-1 bg-card-theme shadow-sm text-theme"
        }
        key={key}
        onClick={() => {
          console.log("called")
          this.props.setMessageForward(false);
          console.log(messagesForwardArray, "from room list")
          let myArray = messagesForwardArray;
          myArray.splice(0,myArray.length)
          setMessagesForwardArray([]);
          setCurrentChannel({ ...roomData, roomId });
          setCurrentWorkspace({ ...workspaceData, ...workspace });
          setParticipants(workspace.workspaceId, roomId);
          resetData();
          // setLeftPanel(!this.props.displayLeftPanel);
        }}
        name={room.roomName}
        active={currentChannel && room.roomId === currentChannel.roomId}
      >
        <div className="d-flex align-items-center p-2 w-100">
          <div className={roomLiveStatus ? "liveroom" : ""}>
            <img
              className={
                roomLiveStatus
                  ? "mr-2 rounded-circle profile-image"
                  : "mr-2 rounded-circle"
              }
              src={roomData.roomPic}
              style={{ height: 45, width: 45 }}
            />
          </div>
          <div className="flex-grow-1">
            <div className="d-flex align-items-center">
              {roomData.roomType === 1 && (
                <FontAwesomeIcon
                  icon={faLock}
                  className="text-success mr-2"
                  size="xs"
                />
              )}
              <p className="mb-0 text-theme">{roomData.roomName}</p>
            </div>
            {roomData.lastMessage && (
              <small className="text-theme text-theme-lighter">
                {roomData.lastMessage.type === 0 ? (
                  <span>{`${roomData.lastMessage.user.name}: ${this.strip(
                    roomData.lastMessage
                  )}`}</span>
                ) : (
                  <span>
                    {`${roomData.lastMessage.user.name}: `}
                    {this.strip(roomData.lastMessage)}
                  </span>
                )}
              </small>
            )}
          </div>
          <div className="text-right align-self-start">
            {/* <FontAwesomeIcon
                            icon={faThumbtack}
                            style={isPinned ? { fontSize: 12, transform: "rotate(45deg)" } : { fontSize: 12 }}
                            className={isPinned ? "text-theme" : "text-secondary"} /> */}
            <p
              className="mb-0 text-theme small text-theme-lighter"
              style={{ whiteSpace: "nowrap" }}
            >
              {roomData.lastMessage &&
                timeFromNow(roomData.lastMessage.timestamp)}
            </p>
            {/* Messages Notification */}
            {/* <span className="text-white bg-primary smaller font-weight-bold" style={{ whiteSpace: "nowrap", borderRadius: "2px", padding: "3px 3px 3px 3px" }}>
                            99+</span> */}
          </div>
        </div>
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  messagesForwardArray: state.platform.messageForwardArray,
  currentChannel: state.channel.currentChannel,
  displayLeftPanel: state.platform.displayLeftPanel,
});

export default connect(mapStateToProps, {
  setMessageForward,
  setMessagesForwardArray,
  setCurrentChannel,
  setCurrentWorkspace,
  setParticipants,
  resetData,
  setLeftPanel,
})(RoomListElement);
