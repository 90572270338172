import React, { useState, useEffect } from "react";

import "./CaseSheet.css";

import firebase from "firebase";
import { connect } from "react-redux";
import { setShowCaseSheet, setShowPrescription } from "../../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faTimes } from "@fortawesome/free-solid-svg-icons";

import { Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import { API_BASE_URL, DRIVE_HIERARCHY, DRIVE_UPLOAD } from "../../../config";
import axios from "axios";
import swal from "sweetalert";
import Vitals from "./Vitals";
import NextAppointment from "./NextAppointment";

const CaseSheet = ({
  currentChannel,
  currentWorkspace,
  currentUser,
  darkTheme,
  setShowCaseSheet,setShowPrescription
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState("addedByPatient");

  const [patientDetails, setPatientDetails] = useState({});

  const [modalShow, setModalShow] = useState(false);
  const [ReportToShow, setReportToShow] = useState(null);
  const [allexistingMedications, setAllexistingMedications] = useState([]);
  const [existingMedications, setexistingMedications] = useState(null);

  const [allcurrentHealthProblems, setAllcurrentHealthProblems] = useState([]);
  const [currentHealthProblem, setcurrentHealthProblem] = useState(null);

  const [allHistoryOfPresentIllness, setAllHistoryOfPresentIllness] = useState(
    []
  );
  const [historyOfPresentIllness, setHistoryOfPresentIllness] = useState(null);

  const [allFamilyHistory, setAllFamilyHistory] = useState([]);
  const [familyHistory, setFamilyHistory] = useState("");

  let docRef = firebase
    .firestore()
    .collection("caseSheet")
    .doc(currentWorkspace.workspaceId)
    .collection("rooms")
    .doc(currentChannel.roomId)
    .collection("caseSheet")
    .doc("caseSheetId")
    .collection("data")
    .doc("doctorData");

  useEffect(() => {
    firebase
      .firestore()
      .collection("caseSheet")
      .doc(currentWorkspace.workspaceId)
      .collection("rooms")
      .doc(currentChannel.roomId)
      .collection("caseSheet")
      .doc("caseSheetId")
      .collection("data")
      .doc("patientData")
      .onSnapshot((doc) => {
        if (doc.exists) {
          let data = doc.data();
          console.log(data);
          setPatientDetails({ ...data });
        } else {
          setPatientDetails({});
        }
        setIsLoading(false);
      });

    docRef
      .collection("familyHistory")
      .orderBy("createdAt", "desc")
      .onSnapshot((doc) => {
        let tempAllFamilyHistory = [];
        if (!doc.empty) {
          doc.docs.map((item) => {
            tempAllFamilyHistory.push({ ...item.data(), id: item.id });
          });
        }
        setAllFamilyHistory(tempAllFamilyHistory);
      });

    docRef
      .collection("currentHealthProblems")
      .orderBy("createdAt", "desc")
      .onSnapshot((doc) => {
        let tempCurrentHealthProblems = [];
        if (!doc.empty) {
          doc.docs.map((item) => {
            tempCurrentHealthProblems.push({ ...item.data(), id: item.id });
          });
        }
        setAllcurrentHealthProblems(tempCurrentHealthProblems);
      });

    docRef
      .collection("existingMedications")
      .orderBy("createdAt", "desc")
      .onSnapshot((doc) => {
        let tempExistingMedications = [];
        if (!doc.empty) {
          doc.docs.map((item) => {
            tempExistingMedications.push({ ...item.data(), id: item.id });
          });
        }
        setAllexistingMedications(tempExistingMedications);
      });

    docRef
      .collection("historyOfPresentIllness")
      .orderBy("createdAt", "desc")
      .onSnapshot((doc) => {
        let tempHistoryOfPresentIllness = [];
        if (!doc.empty) {
          doc.docs.map((item) => {
            tempHistoryOfPresentIllness.push({ ...item.data(), id: item.id });
          });
        }
        setAllHistoryOfPresentIllness(tempHistoryOfPresentIllness);
      });

    docRef
      .collection("relevantSocialHistory")
      .orderBy("createdAt", "desc")
      .onSnapshot((doc) => {
        let tempRelevantSocialHistory = [];
        if (!doc.empty) {
          doc.docs.map((item) => {
            tempRelevantSocialHistory.push({ ...item.data(), id: item.id });
          });
        }
        setAllRelevantSocialHistory(tempRelevantSocialHistory);
      });

    docRef
      .collection("differentialDiagnosis")
      .orderBy("createdAt", "desc")
      .onSnapshot((doc) => {
        let tempDifferentialDiagnosis = [];
        if (!doc.empty) {
          doc.docs.map((item) => {
            tempDifferentialDiagnosis.push({ ...item.data(), id: item.id });
          });
        }
        setAllDifferentialDiagnosis(tempDifferentialDiagnosis);
      });

    docRef
      .collection("provisionalDiagnosis")
      .orderBy("createdAt", "desc")
      .onSnapshot((doc) => {
        let tempProvisionalDiagnosis = [];
        if (!doc.empty) {
          doc.docs.map((item) => {
            tempProvisionalDiagnosis.push({ ...item.data(), id: item.id });
          });
        }
        setAllProvisionalDiagnosis(tempProvisionalDiagnosis);
      });

    docRef
      .collection("testAdvised")
      .orderBy("createdAt", "desc")
      .onSnapshot((doc) => {
        let tempTestAdvised = [];
        if (!doc.empty) {
          doc.docs.map((item) => {
            tempTestAdvised.push({ ...item.data(), id: item.id });
          });
        }
        setAllTestAdvised(tempTestAdvised);
      });

    docRef
      .collection("uploads")
      .orderBy("createdAt", "desc")
      .onSnapshot((doc) => {
        let tempUploads = [];
        if (!doc.empty) {
          doc.docs.map((item) => {
            tempUploads.push({ ...item.data(), id: item.id });
          });
        }
        setAllUploadsByDoctor(tempUploads);
      });
  }, []);

  const ageCalculator = (dateOfBirth) => {
    let d1 = new Date(dateOfBirth);
    let d2 = new Date();
    var diff = d2.getTime() - d1.getTime();
    let age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));

    return age;
  };

  const addExistingMedication = () => {
    console.log(existingMedications);
    if (!existingMedications) return;
    let data = {
      description: existingMedications,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      timestamp: Date.now(),
    };

    docRef
      .collection("existingMedications")
      .add({
        ...data,
      })
      .then(() => setexistingMedications(""));
  };

  const addcurrentHealthProblem = () => {
    if (!currentHealthProblem) return;

    let data = {
      description: currentHealthProblem,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      timestamp: Date.now(),
    };

    docRef
      .collection("currentHealthProblems")
      .add({
        ...data,
      })
      .then(() => {
        setcurrentHealthProblem("");
      });
  };

  const [allPresentIllnessChecked, setAllPresentIllnessChecked] = useState([]);

  const handlePresentIllnessCheckbox = (e) => {
    if (e.target.checked) {
      allPresentIllnessChecked.push(e.target.value);
      setAllPresentIllnessChecked([...allPresentIllnessChecked]);
    } else {
      if (allPresentIllnessChecked.length) {
        let temp = allPresentIllnessChecked.filter(
          (item) => item !== e.target.value
        );
        setAllPresentIllnessChecked([...temp]);
      }
    }
    console.log(allPresentIllnessChecked);
  };

  const addHistoryOfPresentIllness = () => {
    if (!historyOfPresentIllness) return;
    let data = {
      values: allPresentIllnessChecked,
      description: historyOfPresentIllness,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      timestamp: Date.now(),
    };

    docRef
      .collection("historyOfPresentIllness")
      .add({
        ...data,
      })
      .then(() => {
        setHistoryOfPresentIllness("");
        setAllPresentIllnessChecked([]);
      });
  };

  const [allFamilyHistoryChecked, setAllFamilyHistoryChecked] = useState([]);

  const handleFamilyHistoryCheckbox = (e) => {
    if (e.target.checked) {
      allFamilyHistoryChecked.push(e.target.value);
      setAllFamilyHistoryChecked([...allFamilyHistoryChecked]);
    } else {
      if (allFamilyHistoryChecked.length) {
        let temp = allFamilyHistoryChecked.filter(
          (item) => item !== e.target.value
        );
        setAllFamilyHistoryChecked([...temp]);
      }
    }
  };

  const addFamilyHistory = () => {
    if (!familyHistory) return;

    let data = {
      values: allFamilyHistoryChecked,
      description: familyHistory,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      timestamp: Date.now(),
    };
    docRef
      .collection("familyHistory")
      .add({
        ...data,
      })
      .then(() => {
        setFamilyHistory("");
        setAllFamilyHistoryChecked([]);
      });
  };

  const [allRelevantSocialHistoryChecked, setAllRelevantSocialHistoryChecked] =
    useState([]);

  const handleRelevantSocialHistoryCheckbox = (e) => {
    if (e.target.checked) {
      allRelevantSocialHistoryChecked.push(e.target.value);
      setAllRelevantSocialHistoryChecked([...allRelevantSocialHistoryChecked]);
    } else {
      if (allRelevantSocialHistoryChecked.length) {
        let temp = allRelevantSocialHistoryChecked.filter(
          (item) => item !== e.target.value
        );
        setAllRelevantSocialHistoryChecked([...temp]);
      }
    }
    console.log(allRelevantSocialHistoryChecked);
  };

  const [allRelevantSocialHistory, setAllRelevantSocialHistory] = useState([]);
  const [relevantSocialHistory, setRelevantSocialHistory] = useState(null);

  const addRelevantSocialHistory = () => {
    if (!relevantSocialHistory) return;

    let data = {
      values: allRelevantSocialHistoryChecked,
      description: relevantSocialHistory,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      timestamp: Date.now(),
    };

    docRef
      .collection("relevantSocialHistory")
      .add({
        ...data,
      })
      .then(() => {
        setRelevantSocialHistory("");
        setAllRelevantSocialHistoryChecked([]);
      });
  };

  const [allDifferentialDiagnosis, setAllDifferentialDiagnosis] = useState([]);
  const [differentialDiagnosis, setDifferentialDiagnosis] = useState(null);

  const addDifferentialDiagnosis = () => {
    if (!differentialDiagnosis) return;
    let data = {
      description: differentialDiagnosis,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      timestamp: Date.now(),
    };
    allDifferentialDiagnosis.push({
      ...data,
    });
    setAllDifferentialDiagnosis(allDifferentialDiagnosis);
    docRef
      .collection("differentialDiagnosis")
      .add({
        ...data,
      })
      .then(() => setDifferentialDiagnosis(""));
  };

  const [allProvisionalDiagnosis, setAllProvisionalDiagnosis] = useState([]);
  const [provisionalDiagnosis, setProvisionalDiagnosis] = useState(null);

  const addProvisionalDiagnosis = () => {
    if (!provisionalDiagnosis) return;
    let data = {
      description: provisionalDiagnosis,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      timestamp: Date.now(),
    };
    docRef
      .collection("provisionalDiagnosis")
      .add({
        ...data,
      })
      .then(() => setProvisionalDiagnosis(""));
  };

  const [allTestAdvisedChecked, setAllTestAdvisedChecked] = useState([]);

  const handleTestAdvisedCheckbox = (e) => {
    if (e.target.checked) {
      allTestAdvisedChecked.push(e.target.value);
      setAllTestAdvisedChecked([...allTestAdvisedChecked]);
    } else {
      if (allTestAdvisedChecked.length) {
        let temp = allRelevantSocialHistoryChecked.filter(
          (item) => item !== e.target.value
        );
        setAllTestAdvisedChecked([...temp]);
      }
    }
    console.log(allTestAdvisedChecked);
  };

  const [allTestAdvised, setAllTestAdvised] = useState([]);
  const [testAdvised, setTestAdvised] = useState(null);

  const addTestAdvised = () => {
    if (!testAdvised) return;
    let data = {
      values: allTestAdvisedChecked,
      description: testAdvised,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      timestamp: Date.now(),
    };

    docRef
      .collection("testAdvised")
      .add({
        ...data,
      })
      .then(() => {
        setTestAdvised("");
        setAllTestAdvisedChecked([]);
      });
  };

  const [isUploading, setIsUploading] = useState("");
  const [allUploadsByDoctor, setAllUploadsByDoctor] = useState([]);
  const [reportUploadedByDoctor, setReportUploadedByDoctor] = useState({});

  const uploadFile = async (file) => {
    const fileData = new FormData();
    fileData.append("file", file, `${file.lastModified}-${file.name}`);
    fileData.append("user_id", currentUser.uid);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        setIsUploading(
          Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total)
        );
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        // console.log(res);

        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
        return "";
      }
    } else {
      return "";
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    let downloadURL = await uploadFile(e.target.files[0]);

    let report = {
      url: downloadURL,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      timestamp: Date.now(),
    };
    setReportUploadedByDoctor({ ...report });
  };

  const [reportDetails, setReportDetails] = useState(null);

  const addReportUpload = (e) => {
    if (!reportDetails) return;
    if (!reportUploadedByDoctor.url) return;
    reportUploadedByDoctor["reportDetails"] = reportDetails;
    setReportUploadedByDoctor({ ...reportUploadedByDoctor });

    docRef
      .collection("uploads")
      .add({
        ...reportUploadedByDoctor,
      })
      .then(() => {
        setReportUploadedByDoctor({});
        setReportDetails("");
        setIsUploading("");
      });
  };

  const handleDeleteByDoctor = (id, type) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        docRef
          .collection(type)
          .doc(id)
          .delete()
          .then(() => {
            swal("Success", "Deleted successfully", "success");
          })
          .catch((err) => {
            console.log(err);
            swal("Error", "Something went wrong!", "error");
          });
      }
    });
  };

  return (
    <div className="casesheet-modal">
      {isLoading ? (
        <div className="pt-5 text-center">
          <FontAwesomeIcon
            icon={faCircleNotch}
            className="fa-spin text-success fa-2x"
          />
        </div>
      ) : (
        <div>
          <div className="casesheet-header">
            <span className="text-light">Case Details</span>
            <span
              onClick={() => setShowCaseSheet(false)}
              className="pointer float-right "
            >
              <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
            </span>
          </div>
          <div className="casesheet-body">
            <div className="row m-0 ">
              <div className="col-7 p-2">
                <div className="row mx-0 mb-2 border shadow rounded">
                  <span
                    className={`col-3 font-13  cp py-2 px-0 rounded-left text-center ${
                      viewMode === "addedByPatient"
                        ? "bg-dark text-light  font-weight-bold"
                        : "bg-light text-dark"
                    }`}
                    onClick={() => setViewMode("addedByPatient")}
                  >
                    Patient Details
                  </span>
                  <span
                    className={`col-3 font-13 cp py-2 px-0 border-left border-right  text-center ${
                      viewMode === "addedByDoctor"
                        ? "bg-dark text-light font-weight-bold"
                        : "bg-light text-dark"
                    }`}
                    onClick={() => setViewMode("addedByDoctor")}
                  >
                    Case sheet
                  </span>
                  <span
                    className={`col-3 font-13 cp py-2 px-0 border-left border-right  text-center ${
                      viewMode === "vitals"
                        ? "bg-dark text-light font-weight-bold"
                        : "bg-light text-dark"
                    }`}
                    onClick={() => setViewMode("vitals")}
                  >
                    Patient Vitals
                  </span>
                  <span
                    className={`col-3 font-13 cp py-2 px-0 rounded-right text-center ${
                      viewMode === "nextAppointment"
                        ? "bg-dark text-light  font-weight-bold"
                        : "bg-light text-dark"
                    }`}
                    onClick={() => setViewMode("nextAppointment")}
                  >
                    Next appointment
                  </span>
                </div>

                {/* ------------------------------------- Added by patient ------------------------------------ */}

                <div style={{ overflowY: "auto", height: "445px", position:"relative" }} >
                  {viewMode === "addedByPatient" && (
                    <div>
                      <div className="rounded-lg border shadow my-2 font14">
                        <div className="bg-primary p-2 text-white  font-14 rounded-top">
                          Patient information
                        </div>
                        <table className="table font-13">
                          <tr>
                            <td>
                              <b>Name: </b>
                            </td>
                            <td>{patientDetails.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Gender: </b>
                            </td>
                            <td>{patientDetails.gender}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Age: </b>
                            </td>
                            <td>{ageCalculator(patientDetails.dateOfBirth)}</td>
                          </tr>
                        </table>
                      </div>
                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom   rounded-top text-white bg-primary font14">
                          Current health problem
                        </div>
                        {patientDetails.currentHealthProblem ? (
                          <div className="p-2 text-justify font-13">
                            {patientDetails.currentHealthProblem}
                          </div>
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom   rounded-top text-white  bg-primary font-14">
                          Existing medications
                        </div>
                        {patientDetails.existingMedications ? (
                          <div className="p-2 text-justify font-13">
                            {patientDetails.existingMedications}
                          </div>
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2 ">
                        <div className=" p-2 border-bottom rounded-top text-white  bg-primary font-14">
                          Allergies
                        </div>
                        {patientDetails.allergy.length ? (
                          <table className="table font-13">
                            <thead>
                              <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Allergy</th>
                                <th scope="col">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {patientDetails.allergy.map((item, idx) => (
                                <tr>
                                  <th scope="row">{idx + 1}</th>
                                  <td>{item.type}</td>
                                  <td>{item.value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2 ">
                        <div className=" p-2 border-bottom rounded-top text-white  bg-primary font-14">
                          Existing medical conditions
                        </div>

                        {patientDetails.existingMedicalConditions.length ? (
                          <table className="table font-13">
                            <thead>
                              <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Condition</th>
                                <th scope="col">Description</th>
                              </tr>
                            </thead>
                            <tbody>
                              {" "}
                              {patientDetails.existingMedicalConditions.map(
                                (item, idx) => (
                                  <tr>
                                    <th scope="row">{idx + 1}</th>
                                    <td>{item.type}</td>
                                    <td>{item.value}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom rounded-top text-white  bg-primary font-14">
                          Appointment history
                        </div>
                        {patientDetails.appointmentHistory ? (
                          <div className=" text-justify font-13">
                            {patientDetails.appointmentHistory
                              .appointmentHistoryFirst === "No" ? (
                              <table className="table">
                                <tr>
                                  <td>
                                    <b>First: </b>
                                  </td>
                                  <td>
                                    {
                                      patientDetails.appointmentHistory
                                        .appointmentHistoryFirst
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Review: </b>
                                  </td>
                                  <td>
                                    {
                                      patientDetails.appointmentHistory
                                        .appointmentHistoryReview
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Doctor Name: </b>
                                  </td>
                                  <td>
                                    {
                                      patientDetails.appointmentHistory
                                        .doctorName
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Speciality: </b>
                                  </td>
                                  <td>
                                    {
                                      patientDetails.appointmentHistory
                                        .doctorSpeciality
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>Date: </b>
                                  </td>
                                  <td>
                                    {
                                      patientDetails.appointmentHistory
                                        .doctorSpeciality
                                    }
                                  </td>
                                </tr>
                              </table>
                            ) : (
                              <div className="text-center my-2">
                                No appointment history!
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom rounded-top text-white  bg-primary font14">
                          Reports
                        </div>
                        {patientDetails.uploads &&
                        patientDetails.uploads.length ? (
                          <div className=" text-justify font-13">
                            <table className="table">
                              {patientDetails.uploads.map((report) => (
                                <tr>
                                  <td>
                                    <b>{report.type}</b>
                                  </td>
                                  <td>
                                    <span
                                      className="btn btn-sm btn-primary float-right"
                                      onClick={() => {
                                        setModalShow(true);
                                        setReportToShow(report);
                                      }}
                                    >
                                      View
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <Modal
                        show={modalShow}
                        onHide={() => {
                          setModalShow(false);
                          setReportToShow(null);
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter">
                            {!ReportToShow ? (
                              <Spinner
                                animation="border"
                                role="status"
                                variant="dark"
                              >
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            ) : (
                              <div>
                                <div>{ReportToShow.type}</div>
                                <div
                                  classname="text-muted"
                                  style={{ fontSize: "13px" }}
                                >
                                  {new Date(
                                    ReportToShow.createdAt
                                  ).toDateString()}
                                </div>
                              </div>
                            )}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {!ReportToShow ? (
                            <div className="text-center mt-5 ">
                              <Spinner
                                animation="border"
                                role="status"
                                variant="dark"
                              >
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            </div>
                          ) : (
                            <div className="m-2 border">
                              <img
                                src={ReportToShow.url}
                                alt="report"
                                width="100%"
                              />
                            </div>
                          )}
                        </Modal.Body>
                      </Modal>
                    </div>
                  )}

                  {/* ----------------------------------------- Added by doctor ------------------------------------------------- */}

                  {viewMode === "addedByDoctor" && (
                    <div>
                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom rounded-top text-white bg-success font-14">
                          Existing medications
                        </div>

                        {allexistingMedications.length ? (
                          allexistingMedications.map((item) => (
                            <div>
                              <div className="bg-light font-13  px-2 py-3  shadow">
                                {new Date(
                                  item.createdAt?.seconds * 1000
                                ).toDateString()}
                                <span
                                  className="btn btn-sm btn-danger float-right"
                                  style={{ marginTop: "-6px" }}
                                  onClick={() =>
                                    handleDeleteByDoctor(
                                      item.id,
                                      "existingMedications"
                                    )
                                  }
                                >
                                  <small>Delete</small>
                                </span>
                              </div>
                              <div className=" text-justify font-13 p-2">
                                {item.description}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom rounded-top text-white bg-success font-14">
                          Current health problem
                        </div>
                        {allcurrentHealthProblems.length ? (
                          allcurrentHealthProblems.map((item) => (
                            <div>
                              <div className="bg-light font-13  px-2 py-3  shadow">
                                {new Date(
                                  item.createdAt?.seconds * 1000
                                ).toDateString()}
                                <span
                                  className="btn btn-sm btn-danger float-right"
                                  style={{ marginTop: "-6px" }}
                                  onClick={() =>
                                    handleDeleteByDoctor(
                                      item.id,
                                      "currentHealthProblems"
                                    )
                                  }
                                >
                                  <small>Delete</small>
                                </span>
                              </div>
                              <div className=" text-justify font-13 p-2">
                                {item.description}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom rounded-top text-white bg-success font-14">
                          History of present illness
                        </div>
                        {allHistoryOfPresentIllness.length ? (
                          allHistoryOfPresentIllness.map((item) => (
                            <div>
                              <div className="bg-light font-13  px-2 py-3  shadow">
                                {new Date(
                                  item.createdAt?.seconds * 1000
                                ).toDateString()}
                                <span
                                  className="btn btn-sm btn-danger float-right"
                                  style={{ marginTop: "-6px" }}
                                  onClick={() =>
                                    handleDeleteByDoctor(
                                      item.id,
                                      "historyOfPresentIllness"
                                    )
                                  }
                                >
                                  <small>Delete</small>
                                </span>
                              </div>
                              {item.values.length && (
                                <div className="font-weight-bold p-2">
                                  {item.values.map((checked, idx) =>
                                    item.values.length - 1 === idx
                                      ? checked
                                      : `${checked}, `
                                  )}
                                </div>
                              )}

                              <div className=" text-justify font-13 p-2">
                                {item.description}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom rounded-top text-white bg-success font-14">
                          Family history
                        </div>
                        {allFamilyHistory.length ? (
                          allFamilyHistory.map((item) => (
                            <div>
                              <div className="bg-light font-13  px-2 py-3  shadow">
                                {new Date(
                                  item.createdAt?.seconds * 1000
                                ).toDateString()}
                                <span
                                  className="btn btn-sm btn-danger float-right"
                                  style={{ marginTop: "-6px" }}
                                  onClick={() =>
                                    handleDeleteByDoctor(
                                      item.id,
                                      "familyHistory"
                                    )
                                  }
                                >
                                  <small>Delete</small>
                                </span>
                              </div>
                              {item.values.length && (
                                <div className="font-weight-bold p-2">
                                  {item.values.map((checked, idx) =>
                                    item.values.length - 1 === idx
                                      ? checked
                                      : `${checked}, `
                                  )}
                                </div>
                              )}

                              <div className=" text-justify font-13 p-2">
                                {item.description}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom rounded-top text-white bg-success font-14">
                          Relevant social history
                        </div>
                        {allRelevantSocialHistory.length ? (
                          allRelevantSocialHistory.map((item) => (
                            <div>
                              <div className="bg-light font-13  px-2 py-3  shadow">
                                {new Date(
                                  item.createdAt?.seconds * 1000
                                ).toDateString()}
                                <span
                                  className="btn btn-sm btn-danger float-right"
                                  style={{ marginTop: "-6px" }}
                                  onClick={() =>
                                    handleDeleteByDoctor(
                                      item.id,
                                      "relevantSocialHistory"
                                    )
                                  }
                                >
                                  <small>Delete</small>
                                </span>
                              </div>
                              {item.values.length && (
                                <div className="font-weight-bold p-2">
                                  {item.values.map((checked, idx) =>
                                    item.values.length - 1 === idx
                                      ? checked
                                      : `${checked}, `
                                  )}
                                </div>
                              )}

                              <div className=" text-justify font-13 p-2">
                                {item.description}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom rounded-top text-white bg-success font-14">
                          Differential diagnosis
                        </div>
                        {allDifferentialDiagnosis.length ? (
                          allDifferentialDiagnosis.map((item) => (
                            <div>
                              <div className="bg-light font-13  px-2 py-3  shadow">
                                {new Date(
                                  item.createdAt?.seconds * 1000
                                ).toDateString()}
                                <span
                                  className="btn btn-sm btn-danger float-right"
                                  style={{ marginTop: "-6px" }}
                                  onClick={() =>
                                    handleDeleteByDoctor(
                                      item.id,
                                      "differentialDiagnosis"
                                    )
                                  }
                                >
                                  <small>Delete</small>
                                </span>
                              </div>
                              <div className=" text-justify font-13 p-2">
                                {item.description}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom rounded-top text-white bg-success font-14">
                          Provisional diagnosis
                        </div>
                        {allProvisionalDiagnosis.length ? (
                          allProvisionalDiagnosis.map((item) => (
                            <div>
                              <div className="bg-light font-13  px-2 py-3  shadow">
                                {new Date(
                                  item.createdAt?.seconds * 1000
                                ).toDateString()}
                                <span
                                  className="btn btn-sm btn-danger float-right"
                                  style={{ marginTop: "-6px" }}
                                  onClick={() =>
                                    handleDeleteByDoctor(
                                      item.id,
                                      "provisionalDiagnosis"
                                    )
                                  }
                                >
                                  <small>Delete</small>
                                </span>
                              </div>
                              <div className=" text-justify font-13 p-2">
                                {item.description}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom rounded-top text-white bg-success font-14">
                          Test advised
                        </div>
                        {allTestAdvised.length ? (
                          allTestAdvised.map((item) => (
                            <div>
                              <div className="bg-light font-13  px-2 py-3  shadow">
                                {new Date(
                                  item.createdAt?.seconds * 1000
                                ).toDateString()}
                                <span
                                  className="btn btn-sm btn-danger float-right"
                                  style={{ marginTop: "-6px" }}
                                  onClick={() =>
                                    handleDeleteByDoctor(item.id, "testAdvised")
                                  }
                                >
                                  <small>Delete</small>
                                </span>
                              </div>
                              {item.values.length && (
                                <div className="font-weight-bold p-2">
                                  {item.values.map((checked, idx) =>
                                    item.values.length - 1 === idx
                                      ? checked
                                      : `${checked}, `
                                  )}
                                </div>
                              )}

                              <div className=" text-justify font-13 p-2">
                                {item.description}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="rounded-lg border shadow my-2">
                        <div className=" p-2 border-bottom rounded-top text-white bg-success font-14">
                          Reports
                        </div>
                        {allUploadsByDoctor && allUploadsByDoctor.length ? (
                          <div
                            className="p-2 text-justify"
                            style={{ fontSize: "14px" }}
                          >
                            <table className="table">
                              {allUploadsByDoctor.map((report) => (
                                <tr>
                                  <td>
                                    <b>{report.reportDetails}</b>
                                  </td>
                                  <td className="text-center">
                                    <span
                                      className="btn btn-sm btn-primary"
                                      onClick={() => {
                                        setModalShow(true);
                                        setReportToShow(report);
                                      }}
                                    >
                                      View
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className="btn btn-sm btn-danger float-right"
                                      onClick={() =>
                                        handleDeleteByDoctor(
                                          report.id,
                                          "uploads"
                                        )
                                      }
                                    >
                                      <small>Delete</small>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        ) : (
                          <div className="text-center my-4">
                            <div>
                              <small>
                                <b>
                                  <i>Not added!</i>
                                </b>
                              </small>
                            </div>
                          </div>
                        )}
                      </div>

                      <Modal
                        show={modalShow}
                        onHide={() => {
                          setModalShow(false);
                          setReportToShow(null);
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title id="contained-modal-title-vcenter">
                            {!ReportToShow ? (
                              <Spinner
                                animation="border"
                                role="status"
                                variant="dark"
                              >
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            ) : (
                              <div>
                                <div>{ReportToShow.reportDetails}</div>
                                <div
                                  classname="text-muted"
                                  style={{ fontSize: "13px" }}
                                >
                                  {new Date(
                                    ReportToShow.createdAt?.seconds * 1000
                                  ).toDateString()}
                                </div>
                              </div>
                            )}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {!ReportToShow ? (
                            <div className="text-center mt-5 ">
                              <Spinner
                                animation="border"
                                role="status"
                                variant="dark"
                              >
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            </div>
                          ) : (
                            <div className="m-2 border">
                              <img
                                src={ReportToShow.url}
                                alt="report"
                                width="100%"
                              />
                            </div>
                          )}
                        </Modal.Body>
                      </Modal>
                    </div>
                  )}
                  {viewMode === "vitals" && <Vitals />}
                  {viewMode === "nextAppointment" && (
                    <NextAppointment patientDetails={patientDetails} />
                  )}
                </div>
              </div>

              {/* -------------------------------------- Case history ----------------------------------- */}

              <div
                className="col-5 p-2"
                style={{ overflowY: "auto", height: "505px" }}
              >
                <div className="btn btn-block btn-warning btn-sm mb-2" onClick={()=>setShowPrescription(true)} >
                  Open prescription
                </div>
                <div className="p-2 border rounded shadow">
                  <div className="bg-info text-white font-weight-bold text-center p-2 rounded font-14">
                    Case History
                  </div>
                  {/* ---------------------------------------------- accordion 1 ---------------------------------------------- */}

                  <div className=" mt-2" id="accordion">
                    <div className="card">
                      <div id="heading1">
                        <button
                          className="btn btn-dark btn-sm font-13 btn-block  text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#ExistingMedications"
                          aria-expanded="false"
                          aria-controls="ExistingMedications"
                        >
                          Existing Medications
                        </button>
                      </div>
                      <div
                        id="ExistingMedications"
                        className="collapse"
                        aria-labelledby="heading1"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <textarea
                            className="form-control"
                            rows="4"
                            placeholder="Write here..."
                            value={existingMedications}
                            onChange={(e) =>
                              setexistingMedications(e.target.value)
                            }
                          />
                          <div
                            className="btn btn-info btn-sm mt-2 d-block"
                            onClick={addExistingMedication}
                          >
                            ADD
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* ----------------------------------------- *** End *** accordion 1 ------------------------------------------- */}

                    {/* ------------------------------------------------ accordion 2 ------------------------------------------------ */}

                    <div className="card mt-2">
                      <div id="headingTwo">
                        <button
                          className="btn btn-dark btn-sm font-13 btn-block  text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#CurrentProblem"
                          aria-expanded="false"
                          aria-controls="CurrentProblem"
                        >
                          Current Problem/Complaint
                        </button>
                      </div>
                      <div
                        id="CurrentProblem"
                        className="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <textarea
                            className="form-control"
                            rows="4"
                            placeholder="Write here..."
                            value={currentHealthProblem}
                            onChange={(e) =>
                              setcurrentHealthProblem(e.target.value)
                            }
                          />
                          <div
                            className="btn btn-info btn-sm mt-2 d-block"
                            onClick={addcurrentHealthProblem}
                          >
                            ADD
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* --------------------------------------- *** End **** accordion 2 -------------------------------------------- */}

                    {/* ------------------------------------------------ accordion 3 ------------------------------------------------ */}

                    <div className="card mt-2">
                      <div id="headingThree">
                        <button
                          className="btn btn-dark btn-sm font-13 btn-block  text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#PresentIllness"
                          aria-expanded="false"
                          aria-controls="PresentIllness"
                        >
                          History of Present Illness
                        </button>
                      </div>
                      <div
                        id="PresentIllness"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Characteristics / Circumstance"
                              value="Characteristics / Circumstance"
                              checked={allPresentIllnessChecked.includes(
                                "Characteristics / Circumstance"
                              )}
                              onChange={handlePresentIllnessCheckbox}
                            />
                            <label
                              className="form-check-label"
                              for="Characteristics / Circumstance"
                            >
                              Characteristics / Circumstance
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Exacerbating / Alleviating factors"
                              value="Exacerbating / Alleviating factors"
                              onChange={handlePresentIllnessCheckbox}
                              checked={allPresentIllnessChecked.includes(
                                "Exacerbating / Alleviating factors"
                              )}
                            />
                            <label
                              className="form-check-label"
                              for="Exacerbating / Alleviating factors"
                            >
                              Exacerbating / Alleviating factors
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Severity / Intensity"
                              value="Severity / Intensity"
                              checked={allPresentIllnessChecked.includes(
                                "Severity / Intensity"
                              )}
                              onChange={handlePresentIllnessCheckbox}
                            />
                            <label
                              className="form-check-label"
                              for="Severity / Intensity"
                            >
                              Severity / Intensity
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Associated Symptoms and Signs"
                              value="Associated Symptoms and Signs"
                              checked={allPresentIllnessChecked.includes(
                                "Associated Symptoms and Signs"
                              )}
                              onChange={handlePresentIllnessCheckbox}
                            />
                            <label
                              className="form-check-label"
                              for="Associated Symptoms and Signs"
                            >
                              Associated Symptoms and Signs
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Timing"
                              value="Timing"
                              checked={allPresentIllnessChecked.includes(
                                "Timing"
                              )}
                              onChange={handlePresentIllnessCheckbox}
                            />
                            <label className="form-check-label" for="Timing">
                              Timing
                            </label>
                          </div>

                          <div className="form-group">
                            <label>
                              <b>Details</b>
                            </label>
                            <textarea
                              className="form-control"
                              rows="3"
                              name="Details"
                              value={historyOfPresentIllness}
                              onChange={(e) =>
                                setHistoryOfPresentIllness(e.target.value)
                              }
                            />
                          </div>

                          <div
                            className="btn btn-info btn-sm mt-2 d-block"
                            onClick={addHistoryOfPresentIllness}
                          >
                            ADD
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* --------------------------------------- *** End **** accordion 3 -------------------------------------------- */}

                    {/* ------------------------------------------------ accordion 4 ------------------------------------------------ */}

                    <div className="card mt-2">
                      <div id="headingFour">
                        <button
                          className="btn btn-dark btn-sm font-13 btn-block  text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#FamilyHistory"
                          aria-expanded="false"
                          aria-controls="FamilyHistory"
                        >
                          Family History
                        </button>
                      </div>
                      <div
                        id="FamilyHistory"
                        className="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Arthritis"
                              value="Arthritis"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "Arthritis"
                              )}
                            />
                            <label className="form-check-label" for="Arthritis">
                              Arthritis
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Asthama"
                              value="Asthama"
                              checked={allFamilyHistoryChecked.includes(
                                "Asthama"
                              )}
                              onChange={handleFamilyHistoryCheckbox}
                            />
                            <label className="form-check-label" for="Asthama">
                              Asthama
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="COPD"
                              value="COPD"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes("COPD")}
                            />
                            <label className="form-check-label" for="COPD">
                              COPD
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Depression"
                              value="Depression"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "Depression"
                              )}
                            />
                            <label
                              className="form-check-label"
                              for="Depression"
                            >
                              Depression
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Diabetes"
                              value="Diabetes"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "Diabetes"
                              )}
                            />
                            <label className="form-check-label" for="Diabetes">
                              Diabetes
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Glaucoma"
                              value="Glaucoma"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "Glaucoma"
                              )}
                            />
                            <label className="form-check-label" for="Glaucoma">
                              Glaucoma
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Heart Disease"
                              value="Heart Disease"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "Heart Disease"
                              )}
                            />
                            <label
                              className="form-check-label"
                              for="Heart Disease"
                            >
                              Heart Disease
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="High Cholesterol"
                              value="High Cholesterol"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "High Cholesterol"
                              )}
                            />
                            <label
                              className="form-check-label"
                              for="High Cholesterol"
                            >
                              High Cholesterol
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Hypertension (High Blood Pressure)"
                              value="Hypertension (High Blood Pressure)"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "Hypertension (High Blood Pressure)"
                              )}
                            />
                            <label
                              className="form-check-label"
                              for="Hypertension (High Blood Pressure)"
                            >
                              Hypertension (High Blood Pressure)
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Kidney Disease"
                              value="Kidney Disease"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "Kidney Disease"
                              )}
                            />
                            <label
                              className="form-check-label"
                              for="Kidney Disease"
                            >
                              Kidney Disease
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Liver Disease"
                              value="Liver Disease"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "Liver Disease"
                              )}
                            />
                            <label
                              className="form-check-label"
                              for="Liver Disease"
                            >
                              Liver Disease
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Seizures / Epilepsy"
                              value="Seizures / Epilepsy"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "Seizures / Epilepsy"
                              )}
                            />
                            <label
                              className="form-check-label"
                              for="Seizures / Epilepsy"
                            >
                              Seizures / Epilepsy
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Thyroid Disease"
                              value="Thyroid Disease"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "Thyroid Disease"
                              )}
                            />
                            <label
                              className="form-check-label"
                              for="Thyroid Disease"
                            >
                              Thyroid Disease
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Cancer"
                              value="Cancer"
                              onChange={handleFamilyHistoryCheckbox}
                              checked={allFamilyHistoryChecked.includes(
                                "Cancer"
                              )}
                            />
                            <label className="form-check-label" for="Cancer">
                              Cancer
                            </label>
                          </div>

                          <div className="form-group">
                            <label>
                              <b>Other</b>
                            </label>
                            <textarea
                              className="form-control"
                              rows="3"
                              name="other"
                              value={familyHistory}
                              onChange={(e) => setFamilyHistory(e.target.value)}
                            />
                          </div>

                          <div
                            className="btn btn-info btn-sm mt-2 d-block"
                            onClick={addFamilyHistory}
                          >
                            ADD
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* --------------------------------------- *** End **** accordion 4 -------------------------------------------- */}

                    {/* ------------------------------------------------ accordion 5 ------------------------------------------------ */}

                    <div className="card mt-2">
                      <div id="headingFive">
                        <button
                          className="btn btn-dark btn-sm font-13 btn-block  text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#SocialHistory"
                          aria-expanded="false"
                          aria-controls="SocialHistory"
                        >
                          Relevant Social History
                        </button>
                      </div>
                      <div
                        id="SocialHistory"
                        className="collapse"
                        aria-labelledby="headingFive"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Alcohol (Frequency, Duration)"
                              value="Alcohol (Frequency, Duration)"
                              checked={allRelevantSocialHistoryChecked.includes(
                                "Alcohol (Frequency, Duration)"
                              )}
                              onChange={handleRelevantSocialHistoryCheckbox}
                            />
                            <label
                              className="form-check-label"
                              for="Alcohol (Frequency, Duration)"
                            >
                              Alcohol (Frequency, Duration)
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Tobacco"
                              value="Tobacco Chewing (Frequency, Duration)"
                              checked={allRelevantSocialHistoryChecked.includes(
                                "Tobacco Chewing (Frequency, Duration)"
                              )}
                              onChange={handleRelevantSocialHistoryCheckbox}
                            />
                            <label className="form-check-label" for="Tobacco">
                              Tobacco Chewing (Frequency, Duration)
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Smoking (Frequency, Duration)"
                              value="Smoking (Frequency, Duration)"
                              checked={allRelevantSocialHistoryChecked.includes(
                                "Smoking (Frequency, Duration)"
                              )}
                              onChange={handleRelevantSocialHistoryCheckbox}
                            />
                            <label
                              className="form-check-label"
                              for="Smoking (Frequency, Duration)"
                            >
                              Smoking (Frequency, Duration)
                            </label>
                          </div>

                          <div className="form-group">
                            <label>
                              <b>Other</b>
                            </label>
                            <textarea
                              className="form-control"
                              rows="3"
                              name="other"
                              value={relevantSocialHistory}
                              onChange={(e) =>
                                setRelevantSocialHistory(e.target.value)
                              }
                            />
                          </div>

                          <div
                            className="btn btn-info btn-sm mt-2 d-block"
                            onClick={addRelevantSocialHistory}
                          >
                            ADD
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* --------------------------------------- *** End **** accordion 5 -------------------------------------------- */}

                    {/* ------------------------------------------------ accordion 6 ------------------------------------------------ */}

                    <div className="card mt-2">
                      <div id="headingSix">
                        <button
                          className="btn btn-dark btn-sm font-13 btn-block  text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#DifferentialDiagnosis"
                          aria-expanded="false"
                          aria-controls="DifferentialDiagnosis"
                        >
                          Differential Diagnosis
                        </button>
                      </div>
                      <div
                        id="DifferentialDiagnosis"
                        className="collapse"
                        aria-labelledby="headingSix"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <textarea
                            className="form-control"
                            rows="4"
                            placeholder="Write here..."
                            value={differentialDiagnosis}
                            onChange={(e) =>
                              setDifferentialDiagnosis(e.target.value)
                            }
                          />
                          <div
                            className="btn btn-info btn-sm mt-2 d-block"
                            onClick={addDifferentialDiagnosis}
                          >
                            ADD
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* --------------------------------------- *** End **** accordion 6 -------------------------------------------- */}

                    {/* ------------------------------------------------ accordion 7 ------------------------------------------------ */}

                    <div className="card mt-2">
                      <div id="headingSeven">
                        <button
                          className="btn btn-dark btn-sm font-13 btn-block  text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#ProvisionalDiagnosis"
                          aria-expanded="false"
                          aria-controls="ProvisionalDiagnosis"
                        >
                          Provisional Diagnosis
                        </button>
                      </div>
                      <div
                        id="ProvisionalDiagnosis"
                        className="collapse"
                        aria-labelledby="headingSeven"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <textarea
                            className="form-control"
                            rows="4"
                            placeholder="Write here..."
                            value={provisionalDiagnosis}
                            onChange={(e) =>
                              setProvisionalDiagnosis(e.target.value)
                            }
                          />
                          <div
                            className="btn btn-info btn-sm mt-2 d-block"
                            onClick={addProvisionalDiagnosis}
                          >
                            ADD
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* --------------------------------------- *** End **** accordion 7 -------------------------------------------- */}

                    {/* ------------------------------------------------ accordion 8 ------------------------------------------------ */}

                    <div className="card mt-2">
                      <div id="headingEight">
                        <button
                          className="btn btn-dark btn-sm font-13 btn-block  text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#TestsAdvised"
                          aria-expanded="false"
                          aria-controls="TestsAdvised"
                        >
                          Tests Advised
                        </button>
                      </div>
                      <div
                        id="TestsAdvised"
                        className="collapse"
                        aria-labelledby="headingEight"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Blood Test"
                              value="Blood Test"
                              checked={allTestAdvisedChecked.includes(
                                "Blood Test"
                              )}
                              onChange={handleTestAdvisedCheckbox}
                            />
                            <label
                              className="form-check-label"
                              for="Blood Test"
                            >
                              Blood Test
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="CT Scan"
                              value="CT Scan"
                              checked={allTestAdvisedChecked.includes(
                                "CT Scan"
                              )}
                              onChange={handleTestAdvisedCheckbox}
                            />
                            <label className="form-check-label" for="CT Scan">
                              CT Scan
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="ECG"
                              value="ECG"
                              checked={allTestAdvisedChecked.includes("ECG")}
                              onChange={handleTestAdvisedCheckbox}
                            />
                            <label className="form-check-label" for="ECG">
                              ECG
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Echo"
                              value="Echo"
                              checked={allTestAdvisedChecked.includes("Echo")}
                              onChange={handleTestAdvisedCheckbox}
                            />
                            <label className="form-check-label" for="Echo">
                              Echo
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="EEG"
                              value="EEG"
                              checked={allTestAdvisedChecked.includes("EEG")}
                              onChange={handleTestAdvisedCheckbox}
                            />
                            <label className="form-check-label" for="EEG">
                              EEG
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="MRI"
                              value="MRI"
                              checked={allTestAdvisedChecked.includes("MRI")}
                              onChange={handleTestAdvisedCheckbox}
                            />
                            <label className="form-check-label" for="MRI">
                              MRI
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Nuclear Medicine"
                              value="Nuclear Medicine"
                              checked={allTestAdvisedChecked.includes(
                                "Nuclear Medicine"
                              )}
                              onChange={handleTestAdvisedCheckbox}
                            />
                            <label
                              className="form-check-label"
                              for="Nuclear Medicine"
                            >
                              Nuclear Medicine
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Urine Tests"
                              value="Urine Tests"
                              checked={allTestAdvisedChecked.includes(
                                "Urine Tests"
                              )}
                              onChange={handleTestAdvisedCheckbox}
                            />
                            <label
                              className="form-check-label"
                              for="Urine Tests"
                            >
                              Urine Tests
                            </label>
                          </div>

                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="Xrays"
                              value="Xrays"
                              checked={allTestAdvisedChecked.includes("Xrays")}
                              onChange={handleTestAdvisedCheckbox}
                            />
                            <label className="form-check-label" for="Xrays">
                              Xrays
                            </label>
                          </div>

                          <label>
                            <b>Other</b>
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            placeholder="Write here..."
                            value={testAdvised}
                            onChange={(e) => setTestAdvised(e.target.value)}
                          />
                          <div
                            className="btn btn-info btn-sm mt-2 d-block"
                            onClick={addTestAdvised}
                          >
                            ADD
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* --------------------------------------- *** End **** accordion 8 -------------------------------------------- */}
                    {/* ------------------------------------------------ accordion 9 ------------------------------------------------ */}
                    <div className="card mt-2">
                      <div id="headingNine">
                        <button
                          className="btn btn-dark btn-sm font-13 btn-block  text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#Uploads"
                          aria-expanded="false"
                          aria-controls="Uploads"
                        >
                          Uploads
                        </button>
                      </div>
                      <div
                        id="Uploads"
                        className="collapse"
                        aria-labelledby="headingNine"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <textarea
                            className="form-control"
                            rows="3"
                            placeholder="Write here..."
                            value={reportDetails}
                            onChange={(e) => setReportDetails(e.target.value)}
                          />
                          <input
                            className="form-control-file my-2"
                            name="report"
                            type="file"
                            onChange={handleUpload}
                            accept="image/*"
                          />
                          {isUploading && (
                            <div className="progress">
                              <div
                                className="progress-bar progress-bar-striped "
                                role="progressbar"
                                style={{
                                  width: `${isUploading}%`,
                                }}
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                {isUploading}%
                              </div>
                            </div>
                          )}

                          <div
                            className="btn btn-info btn-sm mt-2 d-block"
                            onClick={addReportUpload}
                          >
                            ADD
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* --------------------------------------- *** End **** accordion 9 -------------------------------------------- */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, { setShowCaseSheet, setShowPrescription })(CaseSheet);