import React from "react";
import {
  Dropdown,
  Button,
  DropdownButton,
  Badge,
  Form,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImages,
  faTimes,
  faCheckCircle,
  faAngleDown,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { connect } from "react-redux";
import {
  setPollTitle,
  setPollOptions,
  setPollOptionsImages,
  setPollPreviewImages,
  setPollOptionsFiles,
  setPollOptionsFilesData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
  resetData,
} from "../../../actions/index";
import TaskCommon from "../Common/TaskCommon";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import firebase from "firebase";
import { toast } from "react-toastify";
toast.configure();
class AddPoll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
      image_type: [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
        "image/gif",
      ],
      optionsImage:[],
      currentChannel: props.currentChannel,
      currentUser: props.currentUser,
      storageRef: firebase.storage().ref(),
      uploadState: "",
      uploadTask: null,
      percentUploaded: 0,
      imagesLoading: false,
      errorObj: { title: "", options: [] },
      allFolders: [],
      error: "",
    };
  }

  handleChange = (e) => {
    const { setTaskFolder } = this.props;
    if (e.target.name === "title") {
      this.props.setPollTitle(e.target.value);
    }
    if (e.target.name === "folder") {
      console.log(e.target.value);
      setTaskFolder(e.target.value);
    }
  };

  componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;
    let allFolders = [];
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/folders`
      );
    // const query = firebase.firestore().collection("folders");
    query.onSnapshot((snapshot) => {
      let changes = snapshot.docChanges();
      console.log(changes);
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.exists) {
            let data = change.doc.data();
            let json = { ...data, id: change.doc.id };
            allFolders.unshift(json);
          }
        }
      });
      this.setState({ allFolders });
    });
  }
  /* async componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;
    let savedPolls = [];
    let savedPollsData = [];

    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
      )
      .orderBy("timestamp", "desc")
      .limit(5);

    await query.onSnapshot((snapshot) => {
      //console.log(snapshot.docChanges());
      let changes = snapshot.docChanges();
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.data().type === 3) {
            let data = change.doc.data();
            let json = { ...data, id: change.doc.id };
            savedPolls.unshift(json);
          }
        } else if (change.type === "removed") {
          if (change.doc.data().type === 3) {
            savedPolls = savedPolls.filter(
              (savedPoll) => savedPoll.id !== change.doc.id
            );
          }
        } else if (change.type === "modified") {
          if (change.doc.data().type === 3) {
            let index = savedPolls.findIndex(
              (savedPoll) => savedPoll.id === change.doc.id
            );
            if (index !== -1) {
              savedPolls.splice(index, 1, {
                ...change.doc.data(),
                id: change.doc.id,
              });
            }
          }
        }
      });
      handlePollsData(savedPolls);
    });
    const handlePollsData = (savedPolls) => {
      let savedPollsData = [];
      savedPolls.forEach((savedPoll) => {
        firebase
          .firestore()
          .collection(`polls`)
          .doc(savedPoll.contentId)
          .get()
          .then((doc) => {
            // console.log(doc.data());
            let data = doc.data();
            let json = { ...data, id: doc.id };
            savedPollsData.push(json);
          })
          .then(() => {
            savedPollsData.sort((a, b) => b.timestamp - a.timestamp);
            this.setState({ savedPolls, savedPollsData });
          });
      });
      if (savedPolls.length === 0) {
        this.setState({ savedPolls });
      }
    };
  }*/

  addOption = () => {
    if (this.props.options.length < 8) {
      let prev = [...this.props.options];
      let prevImages = [...this.props.images];
      let temp_previewImages = [...this.props.previewImages];
      console.log(prevImages, "prevImages")


      prev[prev.length] = "";
      if (prevImages.length) {
        prevImages[prevImages.length] = "";
      }
      if (temp_previewImages.length) {
        temp_previewImages[temp_previewImages.length] = "";
      }

      this.props.setPollOptions(prev);
      this.props.setPollOptionsImages(prevImages);
      this.props.setPollPreviewImages(temp_previewImages);
    } else {
      console.log("Options can't be more than 8.")
      this.setState({ error: "Options can't be more than 8." })
      return
    }
  };
  removeOption = (key) => {
    let { errorObj } = this.state;
    let prev = [...this.props.options];
    let prevImages = [...this.props.images];
    let temp_previewImages = [...this.props.previewImages];

    prev.splice(key, 1);
    prevImages.splice(key, 1);
    temp_previewImages.splice(key, 1);

    this.props.setPollOptions(prev);
    this.props.setPollOptionsImages(prevImages);
    this.props.setPollPreviewImages(temp_previewImages);
    errorObj.options[key + 1] = "";
    this.setState({ errorObj });
  };
  handleOptions = (e, key) => {
    let prev = [...this.props.options];
    prev[key] = e.target.value;
    this.props.setPollOptions(prev);
  };
  handleOptionsImages = (e,key) => {
    const reader = new FileReader();
    if(e.target.files[0]){
    let prevImages = [...this.props.images]
    prevImages[key] =e.target.files[0];
    this.props.setPollOptionsImages(prevImages);
    
    reader.readAsDataURL(e.target.files[0]);
        reader.addEventListener("load", () => {
          let temp_previewImages = [...this.props.previewImages];
          temp_previewImages[key] =reader.result
          this.props.setPollPreviewImages(temp_previewImages);
        });
      }
  }
  render() {
    const {
      type,
      currentWorkspace,
      currentChannel,
      currentUser,
      title,
      options,
      deadline,
      channelUsers,
      folder,
      everyone,

      files,
      filesData,
      images,
      previewImages,

      allParticipants,
      shareTask,
      shareNow,
      saveForLater,
      saveTaskForLater,
      editedPoll,
      error,
    } = this.props;
    const { errorObj, allFolders, } = this.state;

    /*const notify = (msg) => {
      toast(msg, { position: toast.POSITION.BOTTOM_RIGHT });
    };*/
    const validateDataOfField = (e) => {
      let { errorObj } = this.state;

      if (e.target.name === "options[]") {
        if (e.target.value.trim().length < 1) {
          errorObj.options[+e.target.id + 1] = "Option cannot be empty.";
        } else {
          errorObj.options[+e.target.id + 1] = "";
        }
      }
      if (e.target.name === "title") {
        if (e.target.value.trim().length < 1) {
          errorObj.title = "Title cannot be empty.";
        } else {
          errorObj.title = "";
        }
      }
      this.setState({ errorObj });
    };
    /*const validateData = () => {
      let filtered_previewImages = previewImages.filter(
        (previewImage) => previewImage.length > 0
      );
      let filtered_filesData = filesData.filter((file) => file.length > 0);
      if (filtered_filesData.length != filtered_previewImages.length) {
        this.setState({ error: "Please add images to all option(s).\n" });
        return null;
      }

      if (title.length == 0) {
        this.setState({ error: "Poll title cannot be empty!\n" });
        return null;
      }

      let filtered_options = options.filter((option) => option.length > 0);
      if (options.length != filtered_options.length) {
        this.setState({ error: "Please add some value to option(s).\n" });
        return null;
      }
      if (options.length < 2) {
        this.setState({
          error: "Poll must have at-least two or more options.\n",
        });
        return null;
      }

      if (moment(deadline).diff(moment(Date.now()), "minutes") < 9) {
        this.setState({
          error: "Deadline of task should be atleast 10 minutes.\n",
        });
        return null;
      }

      if (!everyone && channelUsers.length < 1) {
        this.setState({
          error: "Please assign task to atleast one participant.\n",
        });
        return null;
      }

      this.setState({ error: "" });
      return { type, title, options, channelUsers, deadline, folder, everyone };
    };

    const shareTask = () => {
      if (validateData()) {
        let allImagesUploadedPromises = files.map((file, key) =>
          this.uploadImage(key)
        );

        Promise.all(allImagesUploadedPromises).then((images) => {
          addTask({ ...validateData(), images });
        });
      }
    };

    const saveTaskForLater = () => {
      if (validateData()) {
        let allImagesUploadedPromises = files.map((file, key) =>
          this.uploadImage(key)
        );

        Promise.all(allImagesUploadedPromises).then((images) => {
          saveTask({ ...validateData(), images }, currentChannel, currentUser);
        });
      }
    };
    const saveTask = (data) => {
      const {
        resetData,
        setPollOptions,
        setPollTitle,
        setTaskChannelUsers,
        setTaskEveryone,
        setTaskDeadline,
      } = this.props;
      const { editedPoll } = this.state;

      let messageData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        user: {
          id: currentUser.uid,
          name: currentUser.displayName,
          avatar: currentUser.photoURL,
        },
        type: parseInt(data["type"]),
        createdBy: currentUser.uid,
        assignedTo: data["channelUsers"],
        all: data["everyone"],
      };

      let readData = [];
      readData[currentUser.uid] = { read: true, readtime: Date.now() };

      let taskData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: currentUser.uid,
        folderId: data["folder"],
        deadline: moment(data["deadline"]).valueOf(),
        title: data["title"],
        options: data["options"],
        all: data["everyone"],
        read: readData,
        response: [],
        assignedTo: data["channelUsers"],
        images: data["images"],
      };
      console.log(messageData);
      console.log(taskData);

      const task = firebase.firestore().collection(`polls`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      if (Object.keys(editedPoll).length > 0) {
        let savedPollDoc = savedPolls.find(
          (poll) => poll.contentId === editedPoll.id
        );
        task
          .doc(editedPoll.id)
          .update(taskData)
          .then(() => {
            db.doc(savedPollDoc.id)
              .update({ ...messageData, contentId: editedPoll.id })
              .then(() => {
                setPollOptions([]);
                setTaskDeadline(new Date(Date.now() + 10 * 600000));
                setTaskChannelUsers([]);
                setPollTitle("");
                setTaskEveryone(false);
                this.setState(
                  { editedPoll: {} },
                  notify("Poll updated and saved")
                );
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      } else {
        task
          .add(taskData)
          .then((taskRef) => {
            db.add({ ...messageData, contentId: taskRef.id })
              .then(() => {
                setPollOptions([]);
                setTaskDeadline(new Date(Date.now() + 10 * 600000));
                setTaskChannelUsers([]);
                setPollTitle("");
                setTaskEveryone(false);
                this.setState({ editedPoll: {} }, notify("Poll saved"));
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      }
    };

    const addTask = (data) => {
      const { resetData } = this.props;
      const { editedPoll } = this.state;
      console.log(data["channelUsers"]);

      let messageData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        user: {
          id: currentUser.uid,
          name: currentUser.displayName,
          avatar: currentUser.photoURL,
        },
        type: parseInt(data["type"]),
        createdBy: currentUser.uid,
        assignedTo: data["channelUsers"],
        all: data["everyone"],
      };
      console.log(messageData);

      let readData = [];
      readData[currentUser.uid] = { read: true, readtime: Date.now() };

      let taskData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: currentUser.uid,
        folderId: data["folder"],
        deadline: moment(data["deadline"]).valueOf(),
        title: data["title"],
        options: data["options"],
        all: data["everyone"],
        read: readData,
        response: [],
        assignedTo: data["channelUsers"],
        images: data["images"],
      };
      const task = firebase.firestore().collection(`polls`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      const dbSaved = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);
      if (Object.keys(editedPoll).length > 0) {
        let savedPollDoc = savedPolls.find(
          (poll) => poll.contentId === editedPoll.id
        );

        task
          .doc(editedPoll.id)
          .update(taskData)
          .then(() => {
            db.add({ ...messageData, contentId: editedPoll.id })
              .then((docRef) => {
                dbUpdate
                  .doc(`${currentChannel.roomId}`)
                  .update({
                    totalMessages: firebase.firestore.FieldValue.increment(1),
                    lastMessage: {
                      ...messageData,
                      contentId: editedPoll.id,
                      uid: docRef.id,
                    },
                  })
                  .then(() => {
                    resetData();
                    dbSaved.doc(savedPollDoc.id).delete();
                    this.setState({ editedPoll: {} });
                  })
                  .catch((err) => console.error(err));
              })
              .catch((err) => console.error(err));
          })
          .catch((error) => console.error(error));
      } else {
        task
          .add(taskData)
          .then((taskRef) => {
            db.add({ ...messageData, contentId: taskRef.id })
              .then((docRef) => {
                dbUpdate
                  .doc(`${currentChannel.roomId}`)
                  .update({
                    totalMessages: firebase.firestore.FieldValue.increment(1),
                    lastMessage: {
                      ...messageData,
                      contentId: taskRef.id,
                      uid: docRef.id,
                    },
                  })
                  .then(() => {
                    resetData();
                    this.setState({ editedPoll: {} });
                  })
                  .catch((err) => console.error(err));
              })
              .catch((err) => console.error(err));
          })
          .catch((error) => console.error(error));
      }
    };
    const shareSavedTask = (pollId) => {
      const { resetData } = this.props;
      let messageData = savedPolls.find((poll) => poll.contentId === pollId);
      let pollData = savedPollsData.find((poll) => poll.id === pollId);
      if (pollData.deadline < Date.now()) {
        toast.warning("Deadline is over, please update to share.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }
      console.log(messageData);
      console.log(pollData);
      console.log(allParticipants);
      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find((User) => User.uid === user)
          )) ||
        messageData.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants are no longer part of this group, please edit to reassign participants.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      messageData.timestamp = Date.now();

      const dbMsg = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

      dbMsg
        .add({ ...messageData })
        .then((docRef) => {
          dbUpdate
            .doc(`${currentChannel.roomId}`)
            .update({
              totalMessages: firebase.firestore.FieldValue.increment(1),
              lastMessage: {
                ...messageData,
                contentId: messageData.contentId,
                uid: docRef.id,
              },
            })
            .then(() => {
              resetData();
              db.doc(messageData.id).delete();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    };

    const editSavedTask = (pollId) => {
      const { resetData } = this.props;
      let taskData = savedPollsData.find((poll) => poll.id === pollId);
      this.props.setPollOptions(taskData.options);
      this.props.setPollTitle(taskData.title);
      if (
        (taskData.all === false &&
          taskData.assignedTo.every((user) =>
            allParticipants.find((User) => User.uid === user)
          )) ||
        taskData.all === true
      ) {
        this.props.setTaskChannelUsers(taskData.assignedTo);
      } else {
        let assignedTo = taskData.assignedTo.filter((user) =>
          allParticipants.find((User) => User.uid === user)
        );
        //console.log(assignedTo);
        this.props.setTaskChannelUsers(assignedTo);
      }
      this.props.setTaskEveryone(taskData.all);
      this.props.setTaskDeadline(new Date(taskData.deadline));
      this.setState({ editedPoll: taskData });
    };
    const deleteSavedTask = (pollId) => {
      let messageData = savedPolls.find((poll) => poll.contentId === pollId);
      let messageId = messageData.id;
      const dbPolls = firebase.firestore().collection(`polls`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      db.doc(messageId)
        .delete()
        .then(() => {
          dbPolls.doc(pollId).delete();
          notify("Poll deleted");
        })
        .catch((err) => console.log(err));
    };
*/
    // console.log(editedPoll);
    return (
      <React.Fragment>
        <div className="text-theme-lighter">
          <Form>
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
              <p className="mb-2 font-weight-bold text-theme small">
                Poll Title
              </p>
              <InputGroup className="mb-1">
                <FormControl
                  size="sm"
                  placeholder="Enter the question for poll"
                  name="title"
                  autoFocus={true}
                  onChange={this.handleChange}
                  onKeyUp={(e) => validateDataOfField(e)}
                  onBlur={(e) => validateDataOfField(e)}
                  required={true}
                  value={title}
                />
              </InputGroup>
              {errorObj.title ? (
                <p className="small text-danger">{errorObj.title}</p>
              ) : (
                ""
              )}

              {options.length > 0
                ? options.map((option, key) => (
                    <div key={key}>
                      <div className="d-flex">
                        <p className="mb-0 flex-grow-1 small font-weight-bold">
                          Option {key + 1}.
                        </p>
                        <div className="mb-2">
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => this.removeOption(key)}
                          />
                        </div>
                      </div>
                      {this.props.previewImages[key] && (
                        <div className="d-flex">
                          <img
                            className="rounded img-responsive mb-1"
                            style={{
                              background: "white",
                              height: 60,
                              width: "auto",
                            }}
                            src={this.props.previewImages[key]}
                          />
                          {this.props.images[key] && (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-success"
                              style={{ fontSize: 18 }}
                            />
                          )}
                          {!this.props.images[key] &&
                            this.state.imagesLoading && (
                              <Spinner animation="border" size="sm" />
                            )}
                        </div>
                      )}
                      <div className="d-flex justify-content-center content-align-center mb-4">
                        <InputGroup>
                          <FormControl
                            placeholder="Option"
                            aria-label="option"
                            aria-describedby={"option" + key}
                            name="options[]"
                            autoFocus={options.length === key + 1}
                            value={options[key]}
                            id={key}
                            onChange={(e) => this.handleOptions(e, key)}
                            onKeyUp={(e) => validateDataOfField(e)}
                            onBlur={(e) => validateDataOfField(e)}
                            required={true}
                            size="sm"
                          />
                       

                          <div className="ml-1 pointer " style={{ position: "relative", height: 30, width: 30 }}>
                    <div
                      className="bg-light d-flex align-items-center justify-content-center"
                      style={{ borderRadius: 10, position: "absolute", cursor: "pointer", height: 30, width: 30, borderWidth: 0.1 }}>
                      <FontAwesomeIcon icon={faImages} className="text-dark" />
                    </div>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={e => this.handleOptionsImages(e,key)}
                      style={{ position: "absolute", opacity: 0, height: 60, width: 60 }} />
                  </div>
                        </InputGroup>
                      </div>
                      {errorObj.options[key + 1] ? (
                        <p className="small text-danger">
                          {errorObj.options[key + 1]}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  ))
                : null}

              <InputGroup className="w-100 my-2">
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="w-100"
                  onClick={this.addOption}
                >
                  + <small>Add Option</small>
                </Button>
              </InputGroup>
              <div className="text-danger mb-2">
              {this.state.error ? this.state.error : null}
              </div>
              <p className="mb-2 font-weight-bold text-theme small">
                Associate folder
              </p>
              <InputGroup className="mb-3">
                <select
                  name="folder"
                  class="custom-select custom-select-sm"
                  onChange={this.handleChange}
                >
                  <option
                    selected={folder === "" || folder === null ? true : false}
                    value=""
                  >
                    Uncategorized
                  </option>
                  {allFolders.map((item) => (
                    <option
                      value={item.id}
                      selected={folder === item.id ? true : false}
                    >
                      {item.folderName}
                    </option>
                  ))}
                </select>
              </InputGroup>
            </div>

            <TaskCommon
              allParticipants={allParticipants}
              editedTask={Object.keys(editedPoll).length > 0 ? true : false}
            />

            {error ? <p className="small text-danger">{error}</p> : ""}

            <div className="m-2">
              <p className="my-2 p-0 text-danger small d-block">
                {this.state.error}
              </p>
              {
                this.props.editingPublished && !this.props.editedPollHasResponses ?
                  <Button
                    onClick={() => shareTask()}
                    variant="primary"
                    style={{ width: "100px", marginRight: "5px" }}
                    size="sm"
                    disabled={ this.props.pollMessageData?.closedByAdmin}
                  >
                    <small>
                    {
                        this.props.editingPublished && this.props.editedPollHasResponses ||  this.props.pollMessageData?.closedByAdmin ?
                          <FontAwesomeIcon icon={faExclamationTriangle} color="yellow"></FontAwesomeIcon>
                          : null
                      }
                      Save changes</small>
                  </Button>
                  :

                  <Button
                    onClick={() => shareTask()}
                    variant="primary"
                    style={{ width: "100px", marginRight: "5px" }}
                    size="sm"
                    disabled={shareNow || this.props.editingPublished && this.props.editedPollHasResponses}
                  >
                    <small>
                      {
                        this.props.editingPublished && this.props.editedPollHasResponses ?
                          <FontAwesomeIcon icon={faExclamationTriangle} color="yellow"></FontAwesomeIcon>
                          : null
                      }

                      Share now {" "}
                      </small>
                    {shareNow && (
                      <Spinner
                        as="span"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        className="mr-1"
                        size="sm"
                      />
                    )}
                  </Button>
              }


              <Button
                onClick={() => saveTaskForLater()}
                variant="success"
                style={{ width: "120px", marginLeft: "5px" }}
                size="sm"
                disabled={saveForLater}
              >
                <small>Save for later {" "}</small>
                {saveForLater && (
                    <Spinner
                      as="span"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                      className="mr-1"
                      size="sm"
                    />
                  )}
              </Button>

              {
                this.props.editingPublished && this.props.editedPollHasResponses || this.props.pollMessageData?.closedByAdmin ?
                  <div className="text-warning mt-2">It can't be edited as it already has responses. Either close it or delete it.</div>
                  :
                  null
              }
            </div>
          </Form>
          
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,

  type: state.poll.type,
  title: state.poll.title,
  options: state.poll.options,
  images: state.poll.images,
  previewImages: state.poll.previewImages,
  files: state.poll.files,
  filesData: state.poll.filesData,

  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  everyone: state.task.everyone,
});

export default connect(mapStateToProps, {
  setPollTitle,
  setPollOptions,
  setPollOptionsImages,
  setPollPreviewImages,
  setPollOptionsFiles,
  setPollOptionsFilesData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskEveryone,
  resetData,
})(AddPoll);
