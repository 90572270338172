import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Spinner,
  Dropdown,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import 'react-toastify/dist/ReactToastify.css';
import {
  setShowCreateFolderModal,
  setShowUploadFilesModal,
  setSelectedFolderId,
} from "../../actions/index";
import {API_BASE_URL, DRIVE_FOLDER} from "../../config/index";
toast.configure();
const CreateFolderIntoMyDrive = (props) => {
  const {
    setShowCreateFolderModal,
    showCreateFolderModal,
    selectedFolderId,
    setSelectedFolderId,
  } = props;
  const [errorObj, setErrorObj] = useState({});
  //const [selectedFolder, setSelectedFolder] = useState({});
  const [folderName, setFolderName] = useState("");
  //const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const handleChange = (e) => {
    if (e.target.name === "folderName") {
      setFolderName(e.target.value);
    }
  };
  const validateDataOfField = (e) => {
    const errorObj = {};
    if (e.target.name === "folderName") {
      if (e.target.value.trim().length < 1) {
        errorObj.folderName = "Folder Name cannot be empty.";
      } else {
        errorObj.folderName = "";
      }
    }
    setErrorObj(errorObj);
  };
  const handleSubmitFolder = async () => {
    //setIsLoading(true);
    setSubmit(true);
    // const { folderName, selectedFolder } = this.state;
    let { currentUser, darkTheme } = props;

    let requestData = {
      user_id: currentUser.uid,
      folder_name: folderName,
    };
    if (selectedFolderId) {
      requestData = {
        user_id: currentUser.uid,
        folder_name: folderName,
        parent_folder_id: selectedFolderId,
      };
    }
    //console.log(requestData);
    let res = await axios.post(`${API_BASE_URL}${DRIVE_FOLDER}`, {
      ...requestData,
    });
    //console.log(res);
    if (res.status === 200) {
      setSubmit(false);
      handleClose();
      if(darkTheme){
        toast.dark("Folder created.", { position: toast.POSITION.TOP_CENTER, hideProgressBar:true, autoClose:3000});
      }else if(!darkTheme){
        toast("Folder created.", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
      }
      
    } else {
      alert(res.statusText);
      setSubmit(false);
    }
  };
  const handleClose = () => {
    setShowCreateFolderModal(!showCreateFolderModal);
    setSelectedFolderId(null);
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="border-0 pb-0 pt-0"
        centered
        show={showCreateFolderModal}
        onHide={() => handleClose()}
      >
        <Modal.Body className="bg-theme text-theme border-0 pb-0">
        <div title="Close" className="pointer d-flex justify-content-end mb-0" onClick={() =>  handleClose() }>
              <FontAwesomeIcon className="text-theme" icon={faTimes} />
            </div>
              <h3 className="mb-3">Create Folder</h3>
          <Form>
            <p className="mb-2 font-weight-bold  small">Folder name</p>
            <InputGroup className="mb-1">
              <FormControl
                size="sm"
                placeholder="Enter folder name"
                name="folderName"
                autoFocus={true}
                onChange={handleChange}
                onKeyUp={(e) => validateDataOfField(e)}
                onBlur={(e) => validateDataOfField(e)}
                required={true}
                value={folderName}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleSubmitFolder();
                  }}}
              />
            </InputGroup>
            {submit ? (
              <Button variant="primary my-1" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Creating...
              </Button>
            ) : (
              <Button
                onClick={() => handleSubmitFolder()}
                variant="primary my-1"
                //style={{ width: "100px", marginRight: "5px" }}
                size="sm"
                disabled={folderName.length < 1}
              >
                <small>Submit</small>
              </Button>
            )}
            {errorObj.folderName ? (
              <p className="small my-2 text-danger">{errorObj.folderName}</p>
            ) : (
              ""
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer className ="bg-theme text-theme border-0 pb-0">
          <Button className="m-3" onClick={() => handleClose()}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  //folder: state.task.folder,
  showUploadFilesModal: state.uploadFilesModal.showUploadFilesModal,
  showCreateFolderModal: state.createFolderModal.showCreateFolderModal,
  selectedFolderId: state.selectedFolder.id,
  darkTheme:state.platform.darkTheme,
});
export default connect(mapStateToProps, {
  setShowCreateFolderModal,
  setShowUploadFilesModal,
  setSelectedFolderId,
})(CreateFolderIntoMyDrive);
