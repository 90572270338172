import React from 'react';
import { connect } from 'react-redux';
import { Button, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

function RoomAnalytics({ userPosts }) {
    return (
        <Accordion>
            <Accordion.Toggle as={Button} variant="light" eventKey="0" className="rightTabHeader w-100 rounded-0">
                <div className="mt-1 text-left text-dark">
                    <h5 style={{ display: 'inline-block', fontSize: '14px' }}>Engagement</h5>
                    <FontAwesomeIcon icon={faCaretDown} />
                    <span className="small d-block mx-2">Based on class participation, video attendance, task completition</span>
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <div className='block py-0'>
                    <div className='blockcontent'>
                        <ul>
                            {userPosts && Object.entries(userPosts)
                                .sort((a, b) => b[1].count - a[1].count)
                                .map(([key, val], i) => (
                                    <li key={i}>
                                        <span>{key}</span>&nbsp;
                                        <span>{Math.floor(val.count / Object.values(userPosts).sort((a, b) => b.count - a.count)[0].count * 100)}%</span>
                                    </li>
                                ))}
                        </ul>
                        {/* <Button onClick={() => { }} variant="outline-dark" size="sm" className="mr-2 my-2">More</Button> */}
                    </div>
                </div>
            </Accordion.Collapse>

            {/* <Accordion.Toggle as={Button} variant="light" eventKey="1" className="rightTabHeader w-100 rounded-0">
                <div className="mt-1 text-left text-dark">
                    <h5 style={{ display: 'inline-block', fontSize: '14px' }}>Performance</h5>
                    <FontAwesomeIcon icon={faCaretDown} />
                    <span className="small d-block mx-2">Based on grades on asssignments and quiz</span>
                </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
                <div className='block py-0'>
                    <div className='blockcontent'>
                        <ul>
                            {userPosts && Object.entries(userPosts)
                                .sort((a, b) => b[1].count - a[1].count)
                                .map(([key, val], i) => (
                                    <li key={i}>
                                        <span>{key}</span>&nbsp;
                                        <span>{Number.parseFloat(val.count / Object.values(userPosts).sort((a, b) => b.count - a.count)[0].count * 9.5).toFixed(1)}</span>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </Accordion.Collapse> */}
        </Accordion>
    )
}

const mapStateToProps = state => ({
    userPosts: state.channel.userPosts
})

export default connect(mapStateToProps)(RoomAnalytics);