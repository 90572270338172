import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import firebase from "firebase";
import moment from "moment";

import {
  API_BASE_URL,
  GRADEBOOK_DETAILS,
  GRADEBOOK_SCORE_PUBLISH,
} from "../../config/index";
import { setShowGradebook } from "../../actions/index";
import Participants from "../Right/UserNameAvatar";
import Avatar from "react-avatar";
import {
  Spinner,
  Table,
  Card,
  Image,
  Button,
  Modal,
  Badge,
  Alert,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faTimesCircle,
  faCheckCircle,
  faQuestion,
  faPlusCircle,
  faPencilAlt,
  faCheckDouble,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
toast.configure();
class GradePoint extends Component {
  state = {
    isGradePointLoaded: false,
    gradePoint: 0,
    showModal: false,
    grades: [],
    remarks: [],
    showAddGrades: [],
    showAddRemarks: [],
    savedGrades: [],
    savedRemarks: [],
    published: false,
  };

  componentDidMount() {
    const {
      task,
      task_id,
      user_id,
      currentChannel,
      responseByUser,
    } = this.props;

    let temp_showAddGrades = [],
      temp_showAddRemarks = [];
    task.questions.map((question, key) => {
      temp_showAddGrades[key] = false;
      temp_showAddRemarks[key] = false;
    });
    this.setState({
      showAddGrades: temp_showAddGrades,
      showAddRemarks: temp_showAddRemarks,
    });
    if (task.type === "Quiz") {
      if (responseByUser.isScorePublished) {
        let remarks = Object.keys(responseByUser.remarks).map((Key) => {
          return responseByUser.remarks[Key].remark;
        });
        let grades = Object.keys(responseByUser.answers).map((Key) => {
          return responseByUser.answers[Key].score;
        });

        this.setState({
          remarks,
          savedRemarks: remarks,
          grades,
          savedGrades: grades,
          published: responseByUser.isScorePublished,
          gradePoint: responseByUser.totalUserScore,
          isGradePointLoaded: true,
        });
      } else {
        this.setState({ isGradePointLoaded: true });
      }
    }

    if (task.type === "Assignment") {
      if (responseByUser.isScorePublished) {
        let remarks = responseByUser.answers.map((obj) => {
          return obj.remark;
        });
        let grades = responseByUser.answers.map((obj) => {
          return obj.score;
        });

        this.setState({
          remarks,
          savedRemarks: remarks,
          grades,
          savedGrades: grades,
          published: responseByUser.isScorePublished,
          gradePoint: responseByUser.totalUserScore,
          isGradePointLoaded: true,
        });
      } else {
        this.setState({ isGradePointLoaded: true });
      }
    }
  }

  handlePublish = async (task) => {
    const {
      currentChannel,
      currentUser,
      currentWorkspace,
      user_id,
      hasPublished,
      darkTheme
    } = this.props;
    const {
      savedGrades,
      savedRemarks,
      grades,
      gradePoint,
      remarks,
      published,
    } = this.state;
    //console.log(remarks, "remarks");
    //console.log(gradePoint, "gradepoint");
    //console.log(grades, "grades");
    //console.log(published, "pub");
    //console.log(savedRemarks, "savedRemarks");
    //console.log(savedGrades, "savedGrades");
    let temp_showAddGrades = [],
      temp_showAddRemarks = [];
    task.questions.map((question, key) => {
      temp_showAddGrades[key] = false;
      temp_showAddRemarks[key] = false;
    });

    if (task.type === "Quiz") {
      let scores = {};
      let remarks = {};
      task.questions.forEach((que, key) => {
        scores[que.questionId] = {
          score: savedGrades[key] || null,
        };
        remarks[que.questionId] = {
          remark: savedRemarks[key] || null,
        };
      });

      let response = await axios.post(
        `${API_BASE_URL}${GRADEBOOK_SCORE_PUBLISH}`,
        {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          publisher_id: currentUser.uid,
          user_id: user_id,
          graded_content_id: task.quiz_id,
          graded_content_type: 4,
          scores: scores,
          remarks: remarks,
        }
      );
      console.log(response, "response");
      if (response.status === 200) {
        this.setState(
          {
            showModal: false,
            published: true,
            showAddGrades: temp_showAddGrades,
            showAddRemarks: temp_showAddRemarks,
          },
          () => {
            if(darkTheme){
              toast.dark("Quiz score published.", {
                position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar:true , autoClose: 3000
              })
            }else{
              toast("Quiz score published.", {
                position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar:true , autoClose: 3000
              })
            }
          }
        );
        hasPublished();
      }
    } else if (task.type === "Assignment") {
      let scores = {};
      let remarks = {};
      task.questions.forEach((que, key) => {
        scores[que.questionId] = {
          score: savedGrades[key] || null,
        };
        remarks[que.questionId] = {
          remark: savedRemarks[key] || null,
        };
      });

      let response = await axios.post(
        `${API_BASE_URL}${GRADEBOOK_SCORE_PUBLISH}`,
        {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          publisher_id: currentUser.uid,
          user_id: user_id,
          graded_content_id: task.assignment_id,
          graded_content_type: 5,
          scores: scores,
          remarks: remarks,
        }
      );
      console.log(response, "response");
      if (response.status === 200) {
        this.setState(
          {
            showModal: false,
            published: true,
            showAddGrades: temp_showAddGrades,
            showAddRemarks: temp_showAddRemarks,
          },
          () => {
            if(darkTheme){
              toast.dark("Assignment score published.", {
                position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar:true , autoClose: 3000
              })
            }else{
              toast("Assignment score published.", {
                position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar:true , autoClose: 3000
              })
            }
          }
        );
        hasPublished();
      }
    }
  };
  render() {
    const {
      task,
      user_id,
      responseByUser,
      correctMatrix,
      currentChannel,
      currentUser,
    } = this.props;
    const {
      showAddGrades,
      showAddRemarks,
      grades,
      remarks,
      published,
      savedGrades,
      savedRemarks,
      gradePoint,
    } = this.state;
    console.log(responseByUser, "responseByUser");
    console.log(task);
    // console.log(remarks, "remarks");
    //console.log(grades, "grades");
    //console.log(showAddGrades, "showGrades");
    //console.log(showAddRemarks, "showRemarks");
    //console.log(published, "pub");
    //console.log(savedRemarks, "savedRemarks");

    const addAllGrades = (accumulator, currentValue) =>
      parseInt(accumulator) + parseInt(currentValue);
    return (
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 d-flex align-items-center justify-content-center">
          {this.state.isGradePointLoaded ? (
            !published ? (
              <span>
                __ / {task.total_score}{" "}
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => this.setState({ showModal: true })}
                >
                  + Grade
                </Button>
              </span>
            ) : (
              <span>
                {grades.length > 0 ? grades.reduce(addAllGrades) : gradePoint} /{" "}
                {task.total_score}{" "}
                <Button
                  size="sm"
                  variant="success"
                  onClick={() => this.setState({ showModal: true })}
                >
                  Review
                </Button>
              </span>
            )
          ) : (
            <Spinner animation="border" size="sm" />
          )}
        </div>
        {published ? (
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-success"
            size="sm"
          />
        ) : (
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="text-secondary"
            size="sm"
          />
        )}

        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          animation={false}
        >
          <Modal.Body>
            <>
              {/* QUIZ */}
              {task.type === "Quiz" && (
                <div>
                  <h6>
                    <Badge variant="primary" className="mr-2">
                      {task.type}
                    </Badge>

                    {task.deadline > Date.now() ? (
                      <Badge variant="info" className="mr-2">
                        Active
                      </Badge>
                    ) : (
                      <Badge variant="secondary" className="mr-2">
                        Closed
                      </Badge>
                    )}

                    {responseByUser ? (
                      <Badge variant="success" className="mr-2">
                        <FontAwesomeIcon icon={faCheck} /> Answered
                      </Badge>
                    ) : (
                      <Badge variant="danger" className="mr-2">
                        <FontAwesomeIcon icon={faTimes} /> Not Answered
                      </Badge>
                    )}

                    {this.state.gradePoint === "-" ? (
                      <Badge variant="danger" className="mr-2">
                        <FontAwesomeIcon icon={faTimes} /> Not Graded
                      </Badge>
                    ) : (
                      <Badge variant="success" className="mr-2">
                        <FontAwesomeIcon icon={faCheck} /> Graded
                      </Badge>
                    )}
                  </h6>

                  <div className="row mb-3">
                    <small className="col-md-6">
                      <span className="font-weight-bold">Created at</span>:{" "}
                      {moment(task.created_at).format("hh:mm a (DD/MM/YYYY)")}
                    </small>
                    <small className="col-md-6">
                      <span className="font-weight-bold">Points</span>:{" "}
                      {task.total_score}
                    </small>
                    {responseByUser && (
                      <small className="col-md-12">
                        <span className="font-weight-bold">Responded at</span>:{" "}
                        {moment(responseByUser.responseTime).format(
                          "hh:mm a (DD/MM/YYYY)"
                        )}
                      </small>
                    )}
                  </div>

                  <h6>{task.quiz_name}</h6>

                  {task.questions.map((Question, key) => (
                    <Card className="shadow-sm mb-3 p-3">
                      <p className="mb-0">
                        Q{key + 1}. {Question.question}
                      </p>
                      <small className="mb-2">
                        <span className="mr-1">
                          ({Question.weight}{" "}
                          {Question.weight > 1 ? "points" : "point"})
                        </span>
                        <span className="mr-1">
                          (
                          {Question.type === 1
                            ? "Multiple answers"
                            : "Single answer"}
                          )
                        </span>
                      </small>

                      {responseByUser &&
                        Object.keys(Question.options).map((optionKey, k) =>
                          responseByUser.answers[Question.questionId] ? (
                            responseByUser.answers[Question.questionId]
                              .correct ? (
                              Object.keys(
                                responseByUser.answers[Question.questionId]
                                  .options
                              ).includes(optionKey) ? (
                                <Alert
                                  className="mb-1 d-flex align-items-center p-1 border-0"
                                  variant="success"
                                >
                                  <span className="ml-2">
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      color="#515151"
                                      size="sm"
                                    />
                                  </span>
                                  <span className="ml-2">
                                    {Question.options[optionKey].optionValue}
                                  </span>
                                  <span className="ml-auto">
                                    <span className="d-flex align-items-center">
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        className="text-success small"
                                      />
                                      <small className="text-success mx-1">
                                        Correct
                                      </small>
                                    </span>
                                  </span>
                                </Alert>
                              ) : (
                                <Alert
                                  className="mb-1 d-flex align-items-center p-1 border-0"
                                  variant="light"
                                >
                                  <span className="ml-2">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      color="#aeaeae"
                                      size="sm"
                                    />
                                  </span>
                                  <span className="ml-2">
                                    {Question.options[optionKey].optionValue}
                                  </span>
                                </Alert>
                              )
                            ) : responseByUser.answers[Question.questionId]
                                .correct === false &&
                              Object.keys(
                                responseByUser.answers[Question.questionId]
                                  .options
                              ).includes(optionKey) ? (
                              <Alert
                                className="mb-1 d-flex align-items-center p-1 border-0"
                                variant="danger"
                              >
                                <span className="ml-2">
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    color="#515151"
                                    size="sm"
                                  />
                                </span>
                                <span className="ml-2">
                                  {Question.options[optionKey].optionValue}
                                </span>
                                <span className="ml-auto">
                                  <span className="d-flex align-items-center">
                                    <FontAwesomeIcon
                                      icon={faTimes}
                                      className="text-danger"
                                      size="sm"
                                    />
                                    <small className="text-danger mx-1">
                                      Incorrect
                                    </small>
                                  </span>
                                </span>
                              </Alert>
                            ) : (
                              <Alert
                                className="mb-1 d-flex align-items-center p-1 border-0"
                                variant="light"
                              >
                                <span className="ml-2">
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color="#aeaeae"
                                    size="sm"
                                  />
                                </span>
                                <span className="ml-2">
                                  {Question.options[optionKey].optionValue}
                                </span>
                              </Alert>
                            )
                          ) : (
                            <Alert
                              className="mb-1 d-flex align-items-center p-1 border-0"
                              variant="light"
                            >
                              <span className="ml-2">
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  color="#aeaeae"
                                  size="sm"
                                />
                              </span>
                              <span className="ml-2">
                                {Question.options[optionKey].optionValue}
                              </span>
                            </Alert>
                          )
                        )}
                      {responseByUser && (
                        <p className="mt-2 mb-1 small">
                          <span className="mr-1">Points earned:</span>
                          <span>
                            {correctMatrix[key] ? Question.weight : 0}/
                            {Question.weight}
                          </span>
                          {/* <span>{JSON.stringify(responseByUser.value[key])}</span> */}
                        </p>
                      )}

                      {/* <p className="mb-0">Grades: {JSON.stringify(grades)}</p>
                                          <p className="mb-0">Remarks: {JSON.stringify(remarks)}</p> */}

                      {published ? (
                        <p className="my-2">
                          <span className="mr-2">Published</span>
                          <FontAwesomeIcon
                            icon={faCheckDouble}
                            size="sm"
                            className="text-primary"
                          />
                        </p>
                      ) : (
                        (savedGrades[key] >= 0 || savedRemarks[key]) && (
                          <p className="my-2">
                            <span className="mr-2">Saved</span>
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="sm"
                              className="text-info"
                            />
                          </p>
                        )
                      )}

                      {savedGrades[key] >= 0 && (
                        <small className="mb-1">
                          Marks/ Points: {grades[key]}
                        </small>
                      )}
                      {savedRemarks[key] && (
                        <small className="mb-1">Remark: {remarks[key]}</small>
                      )}

                      {!published && (
                        <div className="d-flex justify-content-end">
                          <Button
                            variant={showAddGrades[key] ? "dark" : "light"}
                            size="sm"
                            className="ml-2"
                            onClick={() => {
                              let temp_showAddGrades = [...showAddGrades];
                              temp_showAddGrades[key] = !showAddGrades[key];

                              let temp_grades = [...grades];
                              temp_grades[key] = correctMatrix[key]
                                ? parseInt(Question.weight)
                                : 0;
                              this.setState({
                                showAddGrades: temp_showAddGrades,
                                grades: temp_grades,
                              });
                            }}
                          >
                            <small
                              className={
                                showAddGrades[key]
                                  ? "text-light"
                                  : "text-primary"
                              }
                            >
                              <FontAwesomeIcon
                                icon={
                                  savedGrades[key] >= 0
                                    ? faPencilAlt
                                    : faPlusCircle
                                }
                              />
                              <span className="ml-2">Grade</span>
                            </small>
                          </Button>

                          <Button
                            variant={showAddRemarks[key] ? "dark" : "light"}
                            size="sm"
                            className="ml-2"
                            onClick={() => {
                              let temp_showAddRemarks = [...showAddRemarks];
                              temp_showAddRemarks[key] = !showAddRemarks[key];
                              this.setState({
                                showAddRemarks: temp_showAddRemarks,
                              });
                            }}
                          >
                            <small
                              className={
                                showAddRemarks[key]
                                  ? "text-light"
                                  : "text-primary"
                              }
                            >
                              <FontAwesomeIcon
                                icon={
                                  savedRemarks[key] ? faPencilAlt : faPlusCircle
                                }
                              />
                              <span className="ml-2">Remark</span>
                            </small>
                          </Button>
                        </div>
                      )}

                      <div>
                        {showAddGrades[key] && (
                          <Form>
                            <label className="mb-1 small">Marks/ Points</label>
                            <InputGroup className="mb-3">
                              <FormControl
                                type="number"
                                max={Question.weight}
                                min="0"
                                size="sm"
                                readOnly={true}
                                value={correctMatrix[key] ? Question.weight : 0}
                              />
                              <InputGroup.Append>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    savedGrades[key] = grades[key];
                                    this.setState({ savedGrades });
                                  }}
                                >
                                  Save
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </Form>
                        )}
                        {showAddRemarks[key] && (
                          <Form>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label className="mb-1 small">
                                Remark
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows="3"
                                size="sm"
                                className="mb-1"
                                value={remarks[key]}
                                onChange={(e) => {
                                  let temp_remarks = [...remarks];
                                  temp_remarks[key] = e.target.value;
                                  this.setState({ remarks: temp_remarks });
                                }}
                              />
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() => {
                                  savedRemarks[key] = remarks[key];
                                  this.setState({ savedRemarks });
                                }}
                              >
                                Save
                              </Button>
                            </Form.Group>
                          </Form>
                        )}
                      </div>
                      {/* <p>Q{key+1}. {JSON.stringify(question)}</p> */}
                    </Card>
                  ))}
                </div>
              )}

              {/* ASSIGNMENT */}
              {task.type === "Assignment" && (
                <div>
                  <h6>
                    <Badge variant="primary" className="mr-2">
                      {task.type}
                    </Badge>

                    {task.deadline > Date.now() ? (
                      <Badge variant="info" className="mr-2">
                        Active
                      </Badge>
                    ) : (
                      <Badge variant="secondary" className="mr-2">
                        Closed
                      </Badge>
                    )}

                    {responseByUser ? (
                      <Badge variant="success" className="mr-2">
                        <FontAwesomeIcon icon={faCheck} /> Answered
                      </Badge>
                    ) : (
                      <Badge variant="danger" className="mr-2">
                        <FontAwesomeIcon icon={faTimes} /> Not Answered
                      </Badge>
                    )}

                    {this.state.gradePoint === "-" ? (
                      <Badge variant="danger" className="mr-2">
                        <FontAwesomeIcon icon={faTimes} /> Not Graded
                      </Badge>
                    ) : (
                      <Badge variant="success" className="mr-2">
                        <FontAwesomeIcon icon={faCheck} /> Graded
                      </Badge>
                    )}
                  </h6>

                  <div className="row mb-3">
                    <small className="col-md-6">
                      <span className="font-weight-bold">Created at</span>:{" "}
                      {moment(task.created_at).format("hh:mm a (DD/MM/YYYY)")}
                    </small>
                    <small className="col-md-6">
                      <span className="font-weight-bold">Points</span>:{" "}
                      {task.total_score}
                    </small>
                    {responseByUser && (
                      <small className="col-md-12">
                        <span className="font-weight-bold">Responded at</span>:{" "}
                        {moment(responseByUser.responseTime).format(
                          "hh:mm a (DD/MM/YYYY)"
                        )}
                      </small>
                    )}
                  </div>

                  {task.questions.map((Question, key) => (
                    <Card className="shadow-sm mb-3 p-3">
                      <p className="mb-0">
                        Q{key + 1}. {Question.question}{" "}
                        <small>
                          ({Question.weight}{" "}
                          {Question.weight > 1 ? "points" : "point"})
                        </small>
                      </p>

                      {Question.description.length > 0 && (
                        <small>({Question.description})</small>
                      )}

                      {responseByUser ? (
                        <>
                          {responseByUser.answers[key].answer.length > 0 ? (
                            <p className="small mb-0">
                              <span className="font-weight-bold">Ans.</span>{" "}
                              {responseByUser.answers[key].answer}
                            </p>
                          ) : (
                            <p className="small mb-0">
                              <span className="font-weight-bold">Ans.</span>{" "}
                              <span>-</span>
                            </p>
                          )}

                          {responseByUser.answers[key].attachment.length >
                            0 && (
                            <a
                              href={responseByUser.answers[key].attachment}
                              target="_blank"
                            >
                              <small>Attachment</small>
                            </a>
                          )}
                        </>
                      ) : (
                        <p className="small mb-0">
                          <span className="font-weight-bold">Ans.</span>{" "}
                          <span>Not answered</span>
                        </p>
                      )}

                      {/* <p className="mb-0">Grades: {JSON.stringify(grades)}</p>
                                          <p className="mb-0">Remarks: {JSON.stringify(remarks)}</p> */}

                      {published ? (
                        <p className="my-2">
                          <span className="mr-2">Published</span>
                          <FontAwesomeIcon
                            icon={faCheckDouble}
                            size="sm"
                            className="text-primary"
                          />
                        </p>
                      ) : (
                        (savedGrades[key] >= 0 || savedRemarks[key]) && (
                          <p className="my-2">
                            <span className="mr-2">Saved</span>
                            <FontAwesomeIcon
                              icon={faCheck}
                              size="sm"
                              className="text-info"
                            />
                          </p>
                        )
                      )}

                      {savedGrades[key] >= 0 && (
                        <small className="mb-1">
                          Marks/ Points: {grades[key]}
                        </small>
                      )}
                      {savedRemarks[key] && (
                        <small className="mb-1">Remark: {remarks[key]}</small>
                      )}

                      {!published && (
                        <div className="d-flex justify-content-end">
                          <Button
                            variant={showAddGrades[key] ? "dark" : "light"}
                            size="sm"
                            className="ml-2"
                            onClick={() => {
                              let temp_showAddGrades = [...showAddGrades];
                              temp_showAddGrades[key] = !showAddGrades[key];
                              console.log(showAddGrades);
                              console.log(temp_showAddGrades);
                              this.setState({
                                showAddGrades: temp_showAddGrades,
                              });
                            }}
                          >
                            <small
                              className={
                                showAddGrades[key]
                                  ? "text-light"
                                  : "text-primary"
                              }
                            >
                              <FontAwesomeIcon
                                icon={
                                  savedGrades[key] >= 0
                                    ? faPencilAlt
                                    : faPlusCircle
                                }
                              />
                              <span className="ml-2">Grade</span>
                            </small>
                          </Button>

                          <Button
                            variant={showAddRemarks[key] ? "dark" : "light"}
                            size="sm"
                            className="ml-2"
                            onClick={() => {
                              let temp_showAddRemarks = [...showAddRemarks];
                              temp_showAddRemarks[key] = !showAddRemarks[key];
                              this.setState({
                                showAddRemarks: temp_showAddRemarks,
                              });
                            }}
                          >
                            <small
                              className={
                                showAddRemarks[key]
                                  ? "text-light"
                                  : "text-primary"
                              }
                            >
                              <FontAwesomeIcon
                                icon={
                                  savedRemarks[key] ? faPencilAlt : faPlusCircle
                                }
                              />
                              <span className="ml-2">Remark</span>
                            </small>
                          </Button>
                        </div>
                      )}

                      <div>
                        {showAddGrades[key] && (
                          <Form>
                            <label className="mb-1 small">Marks/ Points</label>
                            <InputGroup className="mb-3">
                              <FormControl
                                type="number"
                                max={Question.weight}
                                min="0"
                                size="sm"
                                value={grades[key]}
                                onChange={(e) => {
                                  let temp_grades = [...grades];
                                  temp_grades[key] =
                                    e.target.value <= Question.weight
                                      ? parseInt(e.target.value)
                                      : 0;
                                  this.setState({ grades: temp_grades });
                                }}
                              />
                              <InputGroup.Append>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  onClick={() => {
                                    savedGrades[key] = grades[key];
                                    this.setState({ savedGrades });
                                  }}
                                >
                                  Save
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </Form>
                        )}
                        {showAddRemarks[key] && (
                          <Form>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                              <Form.Label className="mb-1 small">
                                Remark
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                rows="3"
                                size="sm"
                                className="mb-1"
                                value={remarks[key]}
                                onChange={(e) => {
                                  let temp_remarks = [...remarks];
                                  temp_remarks[key] = e.target.value;

                                  this.setState({ remarks: temp_remarks });
                                }}
                              />
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={() => {
                                  savedRemarks[key] = remarks[key];
                                  this.setState({ savedRemarks });
                                }}
                              >
                                Save
                              </Button>
                            </Form.Group>
                          </Form>
                        )}
                      </div>
                    </Card>
                  ))}
                </div>
              )}
            </>
            {/* <p>{JSON.stringify(task)}</p> */}
          </Modal.Body>
          <Modal.Footer>
            <p className="mr-auto">
              Total points:{" "}
              {grades.length > 0
                ? grades.reduce(addAllGrades)
                : responseByUser.totalUserScore
                ? responseByUser.totalUserScore
                : 0}
            </p>
            {published ? (
              <Button
                variant="light"
                size="sm"
                onClick={() => this.setState({ showModal: false })}
              >
                <p className="mb-0 px-2" style={{ fontSize: 13 }}>
                  CLOSE
                </p>
              </Button>
            ) : (
              <>
                <Button
                  variant="light"
                  size="sm"
                  onClick={() => this.setState({ showModal: false })}
                >
                  <p className="mb-0 px-2" style={{ fontSize: 13 }}>
                    CANCEL
                  </p>
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  disabled={
                    task.type === "Quiz"
                      ? savedGrades.length !== task.questions.length
                      : savedGrades.length !== task.questions.length
                  }
                  onClick={() => this.handlePublish(task)}
                >
                  <p className="mb-0 px-2" style={{ fontSize: 13 }}>
                    PUBLISH
                  </p>
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

class Gradebook extends Component {
  state = {
    allParticipants: [],
    taskList: [],
    loading_taskList: true,
    loading_userList: true,
    selectedTask: null,
  };
  async componentDidMount() {
    const {
      currentChannel,
      currentUser,
      currentWorkspace,
      participants,
    } = this.props;
    //console.log(currentChannel);
    //console.log(participants);
    try {
      let response = await axios.post(`${API_BASE_URL}${GRADEBOOK_DETAILS}`, {
        user_id: currentUser.uid,
        room_id: currentChannel.roomId,
        workspace_id: currentWorkspace.workspaceId,
      });
      console.log(response.data);
      let allQuiz = response.data.data["graded_quiz"];
      let allAssignment = response.data.data["graded_assignment"];
      //console.log(allQuiz);
      //console.log(allAssignment);
      let quizes = allQuiz.map((item) => {
        item.type = "Quiz";
        return item;
      });
      let assignments = allAssignment.map((item) => {
        item.type = "Assignment";
        return item;
      });
      let taskList = [...quizes, ...assignments];

      // console.log(taskList);
      this.setState({
        taskList,
        loading_taskList: false,
        loading_userList: false,
      });
    } catch (error) {
      console.log(error);
    }

    if (currentWorkspace && currentChannel) {
      firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/participants`
        )
        .onSnapshot((snapshot) => {
          let allParticipants1 = [];

          snapshot.forEach((doc) => {
            allParticipants1.push({ uid: doc.id, ...doc.data() });
          });

          // console.log("allParticipants1", allParticipants1);
          allParticipants1.push({ uid: currentChannel.createdBy });

          this.setState({ allParticipants: allParticipants1 });
        });
    }
  }
  toggleGradebook = () => {
    const { showGradebook, setShowGradebook } = this.props;
    setShowGradebook(!showGradebook);
  };

  getCorrectMatrix = (task, response) => {
    let correctMatrix = [];
    console.log(response);
    console.log(task);
    Object.keys(response.answers).map((key, index) =>
      response.answers[key].correct
        ? correctMatrix.push(true)
        : correctMatrix.push(false)
    );
    return correctMatrix;
  };

  taskHeader = (task) => {
    if (task.type == "Quiz") {
      return (
        <>
          <span className="small d-block">Quiz</span>
          <span className="small d-block font-weight-bold">
            {task.quiz_name}
          </span>
        </>
      );
    } else {
      return (
        <>
          <span className="small d-block">Assignment</span>
          <span className="small d-block font-weight-bold">{task.subject}</span>
        </>
      );
    }
  };
  getTaskAvgScore = (task) => {
    let totalMarksObtainedByAll = 0;

    task.responses.forEach((response) => {
      if (response.totalUserScore !== null) {
        totalMarksObtainedByAll =
          response.totalUserScore + totalMarksObtainedByAll;
      }
    });
    // console.log(task.total_score);
    if (task.responses.length > 0) {
      return Math.floor(totalMarksObtainedByAll / task.responses.length);
    } else {
      return 0;
    }
  };
  handleSelectedTask = (task) => {
    const { selectedTask } = this.state;
    if (selectedTask && selectedTask.type === "Quiz" && task.type === "Quiz") {
      if (selectedTask.quiz_id === task.quiz_id) {
        this.setState({ selectedTask: null });
      } else {
        this.setState({ selectedTask: task });
      }
    } else if (
      selectedTask &&
      selectedTask.type === "Assignment" &&
      task.type === "Assignment"
    ) {
      if (selectedTask.assignment_id === task.assignment_id) {
        this.setState({ selectedTask: null });
      } else {
        this.setState({ selectedTask: task });
      }
    } else {
      this.setState({ selectedTask: task });
    }
  };
  hasResponded = (user) => {
    const { allParticipants, selectedTask } = this.state;
    // const { currentUser } = this.props;

    let responseByUser = selectedTask.responses.find(
      (obj) => obj.userId === user.uid
    );
    if (responseByUser) {
      return moment(responseByUser.responseTime).format("hh:mm a (DD/MM/YYYY)");
    } else {
      return selectedTask.created_by === user.uid
        ? "Admin"
        : selectedTask.all || selectedTask.assigned_to.includes(user.uid)
        ? "Not done"
        : "NA";
    }
  };
  userScore = (user) => {
    const { allParticipants, selectedTask } = this.state;
    let responseByUser = selectedTask.responses.find(
      (obj) => obj.userId === user.uid
    );
    if (responseByUser) {
      if (responseByUser.isScorePublished) {
        return `${
          responseByUser.totalUserScore + "/" + selectedTask.total_score
        }`;
      } else {
        return "Not Published Yet";
      }
    } else {
      return selectedTask.created_by === user.uid
        ? "NA"
        : selectedTask.all || selectedTask.assigned_to.includes(user.uid)
        ? "Not Responded yet"
        : "NA";
    }
  };
  /*getUserGrade = (user) => {
    const { allParticipants, selectedTask } = this.state;
    let responseByUser = selectedTask.responses.find(
      (obj) => obj.userId === user.uid
    );
    if (responseByUser) {
      if (responseByUser.isScorePublished) {
        let percentage = selectedTask.total_score
          ? Math.ceil(
              (responseByUser.totalUserScore / selectedTask.total_score) * 100
            )
          : "NA";
        console.log(percentage);
        if (typeof percentage === "number") {
          if (percentage >= 80) {
            return "A+";
          } else if (percentage >= 70) {
            return "A";
          } else if (percentage >= 60) {
            return "B+";
          } else if (percentage >= 50) {
            return "B";
          } else if (percentage >= 40) {
            return "C";
          } else {
            return "Fail";
          }
        }
      } else {
        return "Not Published Yet";
      }
    } else {
      return selectedTask.created_by === user.uid
        ? "NA"
        : selectedTask.all || selectedTask.assigned_to.includes(user.uid)
        ? "Not Responded yet"
        : "NA";
    }

    return "A+";
  };*/
  hasPublished = async () => {
    const {
      currentChannel,
      currentUser,
      currentWorkspace,
      participants,
    } = this.props;
    this.setState({
      loading_taskList: true,
      loading_userList: true,
      selectedTask: null,
    });

    try {
      let response = await axios.post(`${API_BASE_URL}${GRADEBOOK_DETAILS}`, {
        user_id: currentUser.uid,
        room_id: currentChannel.roomId,
        workspace_id: currentWorkspace.workspaceId,
      });
      console.log(response.data);
      let allQuiz = response.data.data["graded_quiz"];
      let allAssignment = response.data.data["graded_assignment"];

      let quizes = allQuiz.map((item) => {
        item.type = "Quiz";
        return item;
      });
      let assignments = allAssignment.map((item) => {
        item.type = "Assignment";
        return item;
      });
      let taskList = [...quizes, ...assignments];

      // console.log(taskList);
      this.setState({
        taskList,
        loading_taskList: false,
        loading_userList: false,
      });
    } catch (error) {
      console.log(error);
    }
  };
  showDetails = () => {
    const { allParticipants, selectedTask } = this.state;
    return (
      <tr>
        <td colSpan="4" className="p-3 p-md-4">
          <Table
            responsive="sm"
            hover
            className="border-bottom border-light mb-0  rounded w-100"
          >
            <thead>
              <tr>
                <th scope="col" className="text-theme">
                  Paticipant
                </th>
                <th scope="col" className="text-theme">
                  Responded
                </th>
                <th scope="col" className="text-theme">
                  Points
                </th>
                {/*<th scope="col" className="text-theme">
                  Grade
    </th>*/}
                <th scope="col" className="text-theme">
                  Publish/Review
                </th>
              </tr>
            </thead>
            <tbody>
              {allParticipants.map((participant, key) => (
                <tr key={key}>
                  <th scope="row" className="small text-theme">
                    <Participants userId={participant.uid} nameOnly={true} />
                  </th>
                  <td className="small text-theme">
                    {this.hasResponded(participant)}
                  </td>
                  <td className="small text-theme">
                    {this.userScore(participant)}
                  </td>
                  {/*<td className="small text-theme">
                    {this.getUserGrade(participant)}
              </td>*/}
                  <td className="smaller text-theme">
                    {selectedTask &&
                      participant &&
                      selectedTask.responses &&
                      selectedTask.responses.find(
                        (obj) => obj.userId === participant.uid
                      ) &&
                      (selectedTask.type === "Quiz" ? (
                        <GradePoint
                          responseByUser={selectedTask.responses.find(
                            (obj) => obj.userId === participant.uid
                          )}
                          correctMatrix={this.getCorrectMatrix(
                            selectedTask,
                            selectedTask.responses.find(
                              (obj) => obj.userId === participant.uid
                            )
                          )}
                          task={selectedTask}
                          task_id={selectedTask.quiz_id}
                          user_id={participant.uid}
                          currentChannel={this.props.currentChannel}
                          currentUser={this.props.currentUser}
                          currentWorkspace={this.props.currentWorkspace}
                          hasPublished={this.hasPublished}
                        />
                      ) : (
                        <GradePoint
                          //responseByUser={task.response[participant]}
                          responseByUser={selectedTask.responses.find(
                            (obj) => obj.userId === participant.uid
                          )}
                          task={selectedTask}
                          task_id={selectedTask.assignment_id}
                          user_id={participant.uid}
                          currentChannel={this.props.currentChannel}
                          currentUser={this.props.currentUser}
                          currentWorkspace={this.props.currentWorkspace}
                          hasPublished={this.hasPublished}
                        />
                      ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </td>
      </tr>
    );
  };
  render() {
    const {
      allParticipants,
      taskList,
      loading_taskList,
      loading_userList,
      selectedTask,
    } = this.state;
    console.log(taskList);
    console.log(selectedTask);
    return (
      <Card className="bg-theme text-theme shadow rounded-0 table-wid">
        <Card.Header className="d-flex rounded-0">
          <div className="flex-grow-1">
            <h4>Gradebook</h4>
            <p className="small mb-0">
              Get insights into performance of all participants. <br /> We are
              actively working on improving this feature.
            </p>
          </div>
          <div>
            <button
              className="btn btn-sm btn-link text-decoration-none"
              onClick={this.toggleGradebook}
            >
              <span className="text-theme-lighter">
                <FontAwesomeIcon icon={faTimes} className="ml-1" /> Close
              </span>
            </button>
          </div>
        </Card.Header>
        <Card.Body className="">
          {loading_userList && loading_taskList ? (
            <Table striped bordered hover size="sm">
              <Spinner animation="border" />
            </Table>
          ) : (
            <>
              <div className="row mb-3 p-2 border-1 mb-2 rounded shadow bg-card-theme">
                <div className="col-12 mb-2 pb-2 border-bottom-1">
                  <strong>Gradebook Summary</strong>
                </div>
                <div className="col-6 border-right-1">
                  <div className="">
                    <h3 className="mb-0">
                      {allParticipants && Object.keys(allParticipants).length}
                    </h3>
                    <span className="small">Participants</span>
                  </div>
                </div>
                <div className="col-6 border-right-1">
                  <div className="">
                    <h3 className="mb-0">{taskList.length}</h3>
                    <span className="small">Assessments</span>
                  </div>
                </div>
              </div>

              <Table
                responsive="sm"
                hover
                className="border-bottom border-light mb-0 bg-card-theme"
              >
                <thead>
                  <tr>
                    <th scope="col" className="text-theme">
                      Date
                    </th>
                    <th scope="col" className="text-theme">
                      Type
                    </th>
                    <th scope="col" className="text-theme">
                      Title
                    </th>
                    <th scope="col" className="text-theme">
                      Total points
                    </th>
                    <th scope="col" className="text-theme">
                      Avg
                    </th>
                    <th scope="col" className="text-theme">
                      Participants
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {taskList.map((task, key) => (
                    <>
                      <tr
                        key={key}
                        onClick={() => this.handleSelectedTask(task)}
                        title={
                          task.type === "Quiz" ? task.quiz_name : task.subject
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <th scope="row" className="small text-theme">
                          {moment(task.created_at).format("ll")}
                        </th>
                        <td className="small text-theme">{task.type}</td>
                        <td className="small text-theme">
                          {task.type === "Quiz" ? task.quiz_name : task.subject}
                        </td>
                        <td className="small text-theme">{task.total_score}</td>
                        <td className="small text-theme">
                          {this.getTaskAvgScore(task)}
                        </td>
                        <td className="small text-theme">
                          {task.all
                            ? Object.keys(allParticipants).length - 1
                            : task.assigned_to.length}
                        </td>
                      </tr>

                      {selectedTask &&
                      selectedTask.type === "Quiz" &&
                      task.type === "Quiz"
                        ? selectedTask.quiz_id === task.quiz_id
                          ? this.showDetails()
                          : null
                        : selectedTask &&
                          selectedTask.type === "Assignment" &&
                          task.type === "Assignment"
                        ? selectedTask.assignment_id === task.assignment_id
                          ? this.showDetails()
                          : null
                        : null}
                    </>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
      /*<Card className="rounded table-card">
          <Card.Header className="bg-white d-flex">
            <div className="flex-grow-1">
              <h4>Gradebook</h4>
            </div>
            <div>
              <button
                className="btn btn-sm btn-light"
                onClick={this.toggleGradebook}
              >
                Close <FontAwesomeIcon icon={faTimes} className="ml-1" />
              </button>
            </div>
          </Card.Header>
          <Card.Body className="table-wid">
            {loading_userList && loading_taskList ? (
              <Table striped bordered hover size="sm">
                <Spinner animation="border" />
              </Table>
            ) : (
              <div className="scrolling-wrapper-flexbox">
                <Card className="scrolling-element shadow-sm">
                  <Table
                    responsive="sm"
                    striped
                    bordered
                    hover
                    size="sm"
                    className="border-0 border-light mb-0"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        {taskList.map((task) =>
                          task.total_score ? (
                            <th className="text-left pt-0">
                              <span className="small d-block text-muted">
                                {moment(Date.now()).format("YYYY") ===
                                moment(task.timestamp).format("YYYY")
                                  ? moment(task.timestamp).format("MMM DD")
                                  : moment(task.timestamp).format(
                                      "MMM DD, YYYY"
                                    )}
                              </span>
                              {this.taskHeader(task)}
                              <span className="small text-muted">
                                out of {task.total_score} points
                              </span>
                            </th>
                          ) : null
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {allParticipants.map((user) => (
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 mx-1">
                                <small>
                                  <Participants
                                    userId={user.uid}
                                    nameOnly={false}
                                  />
                                </small>
                              </div>
                            </div>
                          </td>
                          {taskList.map((task) =>
                            task.total_score ? (
                              <td className="text-center">
                                {task &&
                                  user &&
                                  task.responses &&
                                  (task.responses.find(
                                    (obj) => obj.userId === user.uid
                                  ) ? (
                                    task.type === "Quiz" ? (
                                      <GradePoint
                                        responseByUser={task.responses.find(
                                          (obj) => obj.userId === user.uid
                                        )}
                                        correctMatrix={this.getCorrectMatrix(
                                          task,
                                          task.responses.find(
                                            (obj) => obj.userId === user.uid
                                          )
                                        )}
                                        task={task}
                                        task_id={task.quiz_id}
                                        user_id={user.uid}
                                        currentChannel={
                                          this.props.currentChannel
                                        }
                                        currentUser={this.props.currentUser}
                                        currentWorkspace={
                                          this.props.currentWorkspace
                                        }
                                      />
                                    ) : (
                                      <GradePoint
                                        //responseByUser={task.response[user]}
                                        responseByUser={task.responses.find(
                                          (obj) => obj.userId === user.uid
                                        )}
                                        task={task}
                                        task_id={task.assignment_id}
                                        user_id={user.uid}
                                        currentChannel={
                                          this.props.currentChannel
                                        }
                                        currentUser={this.props.currentUser}
                                        currentWorkspace={
                                          this.props.currentWorkspace
                                        }
                                      />
                                    )
                                  ) : (
                                    <div className="d-flex align-items-center align-self-center">
                                      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
                                        <small className="text-muted">
                                          {task.created_by === user.uid
                                            ? "Admin"
                                            : task.all ||
                                              task.assigned_to.includes(
                                                user.uid
                                              )
                                            ? "Not done"
                                            : "NA"}
                                        </small>
                                      </div>
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className="text-secondary"
                                        size="sm"
                                      />
                                    </div>
                                  ))}
                              </td>
                            ) : null
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card>
              </div>
            )}
          </Card.Body>
        </Card>*/
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  participants: state.channel.participants,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  showGradebook: state.gradebook.showGradebook,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, { setShowGradebook })(Gradebook);
