import React from "react";
import firebase from "firebase";
import { connect } from "react-redux";

class AssignedUsers extends React.Component{
    state={
        assignedToName:[],
    }
    componentDidMount() {
        const {taskData, allParticipants, currentChannel} = this.props;
        //console.log(taskData, currentChannel,"respoxx")
        //console.log(this.props.allParticipants,"All users respoxx")
        if(taskData.all){
            //console.log(allParticipants,"respoxx")
            //console.log(taskData.createdBy, currentChannel.createdBy,"respoxx")
            if(taskData.createdBy !== currentChannel.createdBy){
              //console.log("not created by admin respoxx")
              let docRef = firebase.firestore().collection("users").doc(`${currentChannel.createdBy}`);
              docRef.get()
                .then((doc) => {
                  if(doc.exists){
                    const data = doc.data();
                    let arr = [...this.state.assignedToName];
                    arr.push({displayName :data.displayName, email:data.email, uid:data.uid})
                    this.setState({ assignedToName: arr });
                  }
                })
            }
            allParticipants.map((assignedTo) => {
                let docRef = firebase.firestore().collection("users").doc(`${assignedTo.uid}`);
                docRef.get()
                  .then((doc) => {
                    if (doc.exists) {
                      if(taskData.createdBy !== doc.data().uid){
                        const data = doc.data();
                        let arr = [...this.state.assignedToName];
                        arr.push({displayName :data.displayName, email:data.email, uid:data.uid})
                        this.setState({ assignedToName: arr });
                      }
                    }
                    else {
                      console.log("not exists");
                    }
                  })
                  .catch((error) => console.error(error));

              //this.setState({ loading: false });
              })
            
        }if(taskData.all === false){
            taskData.assignedTo.map((assignedTo) => {
                let docRef = firebase.firestore().collection("users").doc(`${assignedTo}`);
                docRef.get()
                  .then((doc) => {
                    if (doc.exists) {
                      const data = doc.data();
                      //console.log(doc.data().displayName,"respoxx");
                      let arr = [...this.state.assignedToName];
                      arr.push({displayName :data.displayName, email:data.email, uid:data.uid})
                      //console.log(arr,"respoxx")
                      this.setState({ assignedToName: arr });
                      //console.log(this.state.assignedToName,"respoxx")
                    } else {
                      console.log("not exists");
                    }
                  })
                  .catch((error) => console.error(error));

              //this.setState({ loading: false });
              })
        }
    }
    render(){
      const {currentChannel} =this.props;
        return (
            <div className="text-theme">
              {/*console.log(this.state.assignedToName)*/}
                {this.state.assignedToName.map((names, key) => (
                    <>{currentChannel?.createdBy !== names.uid ? (
                      <div>{`${key+1}. ${names.displayName}  (${(names?.email == undefined || names?.email == "") ? "Guest" : names?.email})`}</div>
                    ):(
                      <div  className="text-theme">
                        {`${key+1}. ${names.displayName} `}
                        <small className="text-warning small">
                          Admin
                        </small> 
                        {`(${names.email}) `}
                      </div>
                    )}
                    </>
                ))}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currentChannel: state.channel.currentChannel,
    allParticipants: state.channel.participants,
    darkTheme: state.platform.darkTheme,
  });

export default connect(mapStateToProps)(AssignedUsers);