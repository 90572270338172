/* Quiz Action Types */
export const ADD_QUIZ_ID = "ADD_QUIZ_ID";
export const ADD_QUIZ_PLUS_TITLE = "ADD_QUIZ_PLUS_TITLE";
export const ADD_QUIZ_PLUS_QUESTIONS = "ADD_QUIZ_PLUS_QUESTIONS";
export const ADD_QUIZ_PLUS_QUESTION_TYPES = "ADD_QUIZ_PLUS_QUESTION_TYPES";
export const ADD_QUIZ_PLUS_DESCRIPTIONS = "ADD_QUIZ_PLUS_DESCRIPTIONS";
export const ADD_QUIZ_PLUS_QUESTION_IMAGES = "ADD_QUIZ_PLUS_QUESTION_IMAGES";
export const ADD_QUIZ_PLUS_QUESTION_PREVIEW_IMAGES = "ADD_QUIZ_PLUS_QUESTION_PREVIEW_IMAGES";
export const ADD_QUIZ_PLUS_OPTIONS = "ADD_QUIZ_PLUS_OPTIONS";
export const ADD_QUIZ_PLUS_TYPES = "ADD_QUIZ_PLUS_TYPES";
export const SET_QUIZ_PLUS_RANDOMIZE = "SET_QUIZ_PLUS_RANDOMIZE";
export const RESET_QUIZ_PLUS = "RESET_QUIZ_PLUS";
export const ADD_QUIZ_PLUS_OPTIONS_IMAGES = "ADD_QUIZ_PLUS_OPTIONS_IMAGES";
export const ADD_QUIZ_PLUS_OPTIONS_PREVIEW = "ADD_QUIZ_PLUS_OPTIONS_PREVIEW";
export const SET_QUIZ_PLUS_DESCRIPTION_AFTER_SELECTION = "SET_QUIZ_PLUS_DESCRIPTION_AFTER_SELECTION";
export const SET_QUIZ_PLUS_PERCENTAGE_VISIBLE_TO_PARTICIPANTS = "SET_QUIZ_PLUS_PERCENTAGE_VISIBLE_TO_PARTICIPANTS";
export const SET_QUIZ_PLUS_IS_NOT_EDITABLE = "SET_QUIZ_PLUS_IS_NOT_EDITABLE";

//export const SET_SHARING = "SET_SHARING";
