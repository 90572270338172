import React, { useEffect, useState } from 'react'
import { browserName, CustomView, osName, deviceType, isIOS, isAndroid, browserVersion, fullBrowserVersion } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faWrench, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import firebase from 'firebase';

function BrowserWarning() {

    const [showChromeAndVersion86, setShowChromeAndVersion86] = useState(false)
    const [msgChromeAndVersion86, setMsgChromeAndVersion86] = useState("")

    const [showNonChromeWarnings, setShowNonChromeWarnings] = useState(false)
    const [msgNonChromeWarnings, setMsgNonChromeWarnings] = useState("")
    
    const [showFirefoxWarnings, setShowFirefoxWarnings] = useState(false);
    const [msgFirefoxAndDesktopWarnings, setMsgFirefoxAndDesktopWarnings] = useState("")

    const [showEdgeWarnings, setShowEdgeWarnings] = useState(false)
    const [msgEdgeAndDesktopWarnings, setMsgEdgeAndDesktopWarnings] = useState("")

    const [showChromeWarnings, setShowChromeWarnings] = useState(false)
    const [msgChromeAndDesktopWarnings, setMsgChromeAndDesktopWarnings] = useState("")

    const [showSafariWarnings, setShowSafariWarnings] = useState(false)
    const [msgSafariAndDesktopWarnings, setMsgSafariAndDesktopWarnings] = useState("")

    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection(`versions`)
            .doc(`web-errors`)
            .onSnapshot(doc => {
                if (doc.exists) {
                    const {
                        showChromeAndVersion86,
                        showNonChromeWarnings,
                        msgChromeAndVersion86,
                        msgNonChromeWarnings, 
                        showFirefoxWarnings,
                        msgFirefoxAndDesktopWarnings,
                        showEdgeWarnings,
                        msgEdgeAndDesktopWarnings,
                        showChromeWarnings,
                        msgChromeAndDesktopWarnings,
                        showSafariWarnings,
                        msgSafariAndDesktopWarnings
                    } = doc.data()

                    setShowChromeAndVersion86(showChromeAndVersion86)
                    setShowNonChromeWarnings(showNonChromeWarnings)
                    setMsgChromeAndVersion86(msgChromeAndVersion86)
                    setMsgNonChromeWarnings(msgNonChromeWarnings)
            
                    setShowFirefoxWarnings(showFirefoxWarnings)
                    setMsgFirefoxAndDesktopWarnings(msgFirefoxAndDesktopWarnings)
        
                    setShowEdgeWarnings(showEdgeWarnings)
                    setMsgEdgeAndDesktopWarnings(msgEdgeAndDesktopWarnings)
        
                    setShowChromeWarnings(showChromeWarnings)
                    setMsgChromeAndDesktopWarnings(msgChromeAndDesktopWarnings)
        
                    setShowSafariWarnings(showSafariWarnings)
                    setMsgSafariAndDesktopWarnings(msgSafariAndDesktopWarnings)
                }
            }, err => {
                console.error(err)
            })

        return () => {
            unsubscribe()
        }
    })

    console.log("browserVersion: ", browserVersion)

    let isChromeAndVersion86 = (browserVersion === "86" && browserName === "Chrome")

    return (
        <li className="bg-warning text-dark">
            {isChromeAndVersion86 && showChromeAndVersion86
                && <div className="d-flex align-items-center justify-content-center p-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="m-1 mr-4" />
                    <p className="mb-0">{msgChromeAndVersion86}</p>
                </div>}

            {browserName === "Chrome" && showChromeWarnings
                && <div className="d-flex align-items-center justify-content-center p-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="m-1 mr-4" />
                    <p className="mb-0">{msgChromeAndDesktopWarnings}</p>
                </div>}

            {browserName === "Edge" && showEdgeWarnings
                && <div className="d-flex align-items-center justify-content-center p-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="m-1 mr-4" />
                    <p className="mb-0">{msgEdgeAndDesktopWarnings}</p>
                </div>}

            {browserName === "Safari" && showSafariWarnings
                && <div className="d-flex align-items-center justify-content-center p-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="m-1 mr-4" />
                    <p className="mb-0">{msgSafariAndDesktopWarnings}</p>
                </div>}

            {browserName === "Firefox" && showFirefoxWarnings
                && <div className="d-flex align-items-center justify-content-center p-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="m-1 mr-4" />
                    <p className="mb-0">{msgFirefoxAndDesktopWarnings}</p>
                </div>}

            {showNonChromeWarnings && browserName != "Chrome" && deviceType != "mobile" && deviceType != "tablet"
                && <div className="d-flex align-items-center justify-content-center p-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="m-1 mr-4" />
                    <p className="mb-0">{msgNonChromeWarnings}</p>
                </div>}

            {deviceType === "mobile"
                && <div className="d-flex align-items-start justify-content-center p-3">
                    <FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="m-1 mr-4" />
                    <p className="mb-0"> We recommend downloading Igesia Connect mobile app for best experience. <br />
                        {isIOS && <a href="https://apps.apple.com/us/app/igesia/id1519955986"><img className="mt-3 shadow" src="https://miro.medium.com/max/8982/1*V9-OPWpauGEi-JMp05RC_A.png" width="50%" /></a>}
                        {isAndroid && <a href="https://play.google.com/store/apps/details?id=com.igesia&hl=en_US"><img className="mt-3 shadow" src="https://www.talkhomeapp.com/images/apps/download_app_google_play.svg" width="50%" /></a>}
                    </p>
                </div>}
        </li>
    )
}

export default BrowserWarning;