import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import ReadMore from '../../Middle/ReadMore';
import moment from 'moment';
import TaskListTemplate from '../TaskListTemplate';
import IsAdmin from '../../IsAdmin';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

import { connect } from 'react-redux';
import { 
    openFolder, 
    setFolderId, 
    setFolderName, 
    setFolderDescription, 
    editFolder, 
    deleteFolder, 
    setContentType
} from '../../../actions/index';

function TaskFolder({ 
    id, 
    openFolder, 
    setFolderId, 
    setFolderName, 
    setFolderDescription, 
    editFolder, 
    deleteFolder, 
    setContentType 
}) {
    const [data, setData] = useState(null);

    useEffect(() => {
        firebase.database().ref("folders").child(id).on("value", snap => {
            snap.val() && setData(snap.val());
        });
    }, []);

    const getName = (key) => {
        let name = "";
        firebase.database().ref("users").child(key).on("value", snap => {
            if (snap.val()) {
                name = snap.val().name ? snap.val().name : `${snap.val().firstName} ${snap.val().lastName}`;
            }
        });
        return name;
    }

    const getTaskData = (branch, key) => {
        let taskData = null;
        firebase.database().ref(`${branch}/${data.channel}`).child(key).on("value", snap => {
            taskData = snap.val();
        });
        return taskData;
    }

    return (
        <>
            <div className='shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme'>
            <p className="mb-2 font-weight-bold text-theme">{data && data.folder_name}</p>
                        {data && data.description && 
                            <p className="mb-2 text-theme-lighter small">
                                <ReadMore max_length="145" full_text={data.description} />
                            </p>}
                {data && data.tasks && data.tasks.map(task => getTaskData("messages", task) && 
                <TaskListTemplate item={getTaskData("messages", task)} type="messages" />)}
            </div>
            {data && data.saved && <div className='shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme'>
                <h5>Saved Tasks</h5>
                {data.saved.map(save => getTaskData("saved", save) ? <TaskListTemplate item={getTaskData("saved", save)} type="saved" /> : <Spinner animation="border" />)}
            </div>}

            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme">
            <p className="mb-1 font-weight-bold text-theme small">Folder Details</p>
                <div className="align-self-start">
                    {data && <p className="small">
                        Created by {getName(data.admin)}, {moment(data.timestamp).format("MMM DD YYYY")}</p>}
                </div>
                
                <div className="d-flex align-items-center">
                    <IsAdmin>
                        <div className="row mx-2">
                            <button className="btn btn-sm p-1" onClick={(e) => { e.preventDefault(); openFolder(false); setFolderId(id); editFolder(true); setFolderName(data && data.folder_name); setFolderDescription(data && data.description); setContentType("Folder"); }}>
                                <FontAwesomeIcon className="text-theme" icon={faEdit} />
                            </button>
                            <button className="btn btn-sm p-1" onClick={(e) => { e.preventDefault(); setFolderId(id); deleteFolder(true); }}>
                                <FontAwesomeIcon className="text-theme" icon={faTimesCircle} />
                            </button>
                        </div>
                    </IsAdmin>
                </div>
            </div>
        </>
    )
}

export default connect(
    null, { 
        openFolder, 
        setFolderId, 
        setFolderName, 
        setFolderDescription, 
        editFolder, 
        deleteFolder, 
        setContentType 
    })(TaskFolder);