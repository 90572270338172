import React, { useState, useEffect } from "react";
import moment from "moment";
import firebase from "firebase";

import DisplayResponse from "./DisplayResponse";
import _ from "lodash";
import { Form, Button, Badge, Card } from "react-bootstrap";
import "../Tasks.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCheck,
  faTimes,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

import { connect } from "react-redux";
import { saveRemark, publishRemark } from "./actions";
import axios from "axios";
import { toast } from "react-toastify";
import DisplayAnswers from "./DisplayAnswers";
import Participants from "../../Right/UserNameAvatar";
import { getTimeDifference } from "../Common/TimeDifference";
import AssignedUsers from "../Common/AssignedUsers";
import Viewer from "react-viewer";

import { API_BASE_URL, QUIZ_SUBMIT } from "../../../config";
toast.configure();

const getResult = (response, user_id) => {
  //console.log(response);
  //console.log(user_id);
  //console.log(response.find((user) => user.id === user_id));
  return response && response.find((user) => user.id === user_id)
    ? true
    : false;
};

const getValue = (response, user_id) => {
  return (
    response && (
      <div>
        <strong>Your answer</strong>
        <br />
        <p>{response[user_id] && response[user_id].value}</p>
        <p>
          Responded{" "}
          <i>
            {moment(response[user_id] && response[user_id].responseTime).format(
              "hh:mm A MMM DD YYYY"
            )}
          </i>
        </p>
      </div>
    )
  );
};

const Quiz = (props) => {
  const {
    //quiz_data,
    taskResponse,
    user_id,
    // currentChannel,
    open_task_branch,
    open_task_id,
    //saveRemark,
    //publishRemark,
    colorTheme,
    componentName,
  } = props;
  //console.log(props, "props of quiz");
  const [value, setValue] = useState("");
  const [admin, setAdmin] = useState(false);
  const [responses, setResponses] = useState([]);
  const contentId = props.quiz_data.contentId;
  const [quizData, setQuiz_data] = useState({ quizQuestions: {} });
  const [isClosed, setIsClosed] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState(null);

  const [visible, setVisible] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);



  // getting quiz data from firestore, quiz collection

  useEffect(() => {
    if (contentId) {
      const quizData = firebase
        .firestore()
        .collection("quiz")
        .doc(contentId)
        .onSnapshot
        ((doc) => {
          if (doc.exists) {
            let data = { ...doc.data(), id: doc.id };
            //console.log(data);
            setQuiz_data(data);
          
            if (doc.data().createdBy === user_id) {
              setAdmin(true);
            }
            if (doc.data().previousDeadline || doc.data().deadline < Date.now().valueOf()) {
              setIsClosed(true);
              //props.setIsClosed(true);
            }
          }
        });
        setUnsubscribe(() => quizData);
      /*.then(() => {
          console.log(user_id);
          console.log(quizData);
          if (user_id === quizData.createdBy) {
            console.log("Admin h Bhaiya");
            setAdmin(true);
            getResponses(quizData.response);
          }
          if (quizData && getResult(quizData.response, user_id))
            setAnswers(quizData.response[user_id].value);
        });*/
    }
  }, []);

  function ImageViewer(
    source,
    visible,
    setVisible,
    setImageSelected
  ) {
    return (
      <>
        <Viewer
          visible={visible}
          onClose={() => {
            setVisible(false);
            setImageSelected(null);
          }}
          images={[{
            src: source,
          }
          ]}
        />
      </>
    );
  }

  useEffect(() => {
    return (() => {
      // @ https://stackoverflow.com/questions/55636020/react-hooks-trying-to-access-state-before-unmount-returns-initial-state
      // should update componentwillunmount when the unsub function is updated else it will have the initial value
      console.log("unmount once", unsubscribe)
      if (unsubscribe) {
        unsubscribe()
      }
    })
  }, [unsubscribe])

  useEffect(() => {
    //console.log(quizData.id);
    let response = [...responses];
    const query = firebase
      .firestore()
      .collection(`/quiz/${quizData.id}/responses`);
    let res = query.onSnapshot((snapshot) => {
      let changes = snapshot.docChanges();
      //console.log(changes);
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.exists) {
            let data = change.doc.data();
            let json = { ...data, quiz_id: quizData.id, id: change.doc.id };
            response = [...response, { ...json }];
            setResponses(response);
          }
        }
      });
    });
  }, [Object.keys(quizData).length > 1]);
  useEffect(() => {
    if (quizData && getResult(responses, user_id)) {
      let obj = responses.find((user) => user.id === user_id);
      let responsedAnswers = { ...obj.answers };

      let answers = [];
      Object.keys(quizData.quizQuestions)
        .sort(
          (a, b) =>
            quizData.quizQuestions[a].sequenceNo -
            quizData.quizQuestions[b].sequenceNo
        )
        .map((q, k) => {
          let mulAnswers = [];
          let sinAnswer = "";
          Object.keys(quizData.quizQuestions[q].options)
            .sort(
              (a, b) =>
                quizData.quizQuestions[q].options[a].sequenceNo -
                quizData.quizQuestions[q].options[b].sequenceNo
            )
            .map((o, key) => {
              if (quizData.quizQuestions[q].type === 1) {
                if (
                  responsedAnswers[q] &&
                  Object.keys(responsedAnswers[q].options).includes(o)
                ) {
                  mulAnswers.push(
                    quizData.quizQuestions[q].options[o].optionValue
                  );
                }
              } else {
                if (
                  responsedAnswers[q] &&
                  Object.keys(responsedAnswers[q].options).includes(o)
                ) {
                  sinAnswer = quizData.quizQuestions[q].options[o].optionValue;
                }
              }
            });
          if (mulAnswers.length > 0) {
            answers.push(mulAnswers);
          }
          if (sinAnswer) {
            answers.push(sinAnswer);
          }
          if (sinAnswer === "" && mulAnswers.length === 0) {
            answers.push("");
          }
        });
      setAnswers(answers);
      setSubmit(true);
    }
  }, [responses]);

  const getResponses = (responses) => {
    responses &&
      Object.entries(responses).forEach(([key, value]) => {
        setResponses((prev) => [...prev, { uid: key, ...value }]);
      });
  };

  const timeFromNow = (timestamp) => {
    if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
      return moment(timestamp).fromNow();
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
      return "yesterday";
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
      return moment(timestamp).format("dddd");
    else return moment(timestamp).format("MMM DD YYYY");
  };

  const getName = (key) => {
    let name = "";
    firebase
      .firestore()
      .collection("users")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          name = doc.data().firstName + doc.data().lastName;
        }
      });
    return name;
  };

  // const getPoints = (correctMatrix) => {
  //     let marks = [];
  //     correctMatrix.map((item, key) => (
  //         item && quiz_data.totalWeight && marks.push(quiz_data.quiz_questions[key].weight)
  //     ));
  //     return marks;
  // }

  // const getCorrectMatrix = response => {
  //     let correctMatrix = [];
  //     response.value.map((item, key) => (
  //         (quiz_data.quiz_questions[key].type === "multiple")

  //         ? quiz_data.correct_answers[key].length === Object.values(quiz_data.correct_answers[key]).filter(a => Object.values(item).includes(a)).length ? correctMatrix.push(true) : correctMatrix.push(false)

  //         : item == quiz_data.correct_answers[key] ? correctMatrix.push(true) : correctMatrix.push(false)
  //     ));
  //     return correctMatrix;
  // }

  //const displayResponse = (response) => {
  //     let content = "";
  //     let remarkDone = response.remark && response.remark.status;
  //     firebase.database().ref(`messages/${currentChannel.id}/${quiz_uid}/response/${response.uid}`).on("value", snap => {
  //         remarkDone = snap.val() && snap.val().remark && snap.val().remark.status;
  //         content = snap.val() && snap.val().remark && snap.val().remark.content;
  //     });
  //     let correctMatrix = getCorrectMatrix(response);

  {
    /* return (
      <div>
        <hr className="my-2" />
        <p className="mb-0 h6">{getName(response.uid)}</p>
        <p className="small my-0">
          Responded{" "}
          <i>{moment(response.responseTime).format("hh:mm A MMM DD YYYY")}</i>
        </p>
        <div>
   <strong>Answer</strong>*/
  }
  {
    /*correctMatrix && <p className="mb-1"><span className="font-weight-bold">Score:</span> {correctMatrix.filter(item => item).length / quiz_data.quiz_questions.length * 100}%</p>*/
  }

  {
    /*<div className="row">
                         <div className="col-4 d-flex flex-column">
                             <div className="d-flex align-items-center justify-content-center">
                                 <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
                                 <p className="font-weight-bold px-2 m-0">{correctMatrix.filter(item => item).length} / {quiz_data.quiz_questions.length}</p>
                             </div>
                             <p className="small text-muted text-center m-0">Correct</p>
                         </div>

                         <div className="col-4 border-right border-left border-secondary d-flex flex-column">
                             <div className="d-flex align-items-center justify-content-center">
                                 <FontAwesomeIcon icon={faCircle} className="text-info" />
                                 <p className="font-weight-bold px-2 m-0">{quiz_data.quiz_questions.length - correctMatrix.length} / {quiz_data.quiz_questions.length}</p>
                             </div>
                             <p className="small text-muted text-center m-0">Skipped</p>
                         </div>

                        <div className="col-4 d-flex flex-column">
                             <div className="d-flex align-items-center justify-content-center">
                                 <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                                 <p className="font-weight-bold px-2 m-0">{correctMatrix.filter(item => !item).length} / {quiz_data.quiz_questions.length}</p>
                             </div>
                             <p className="small text-muted text-center m-0">Wrong</p>
                         </div>
                     </div>
        {quiz_data.totalWeight && getPoints(correctMatrix) && <p className="mb-1"><span className="font-weight-bold">Points earned:</span> {getPoints(correctMatrix).reduce((a,b) => a + b, 0)}</p>}*/
  }

  {
    /*{remarkDone
                     ? <p>{content}</p>
                     : <Form>
                         <Form.Group className="mb-1">
                             <Form.Control 
                             as="textarea" 
                             rows="2" 
                             size="sm"
                             placeholder="Add remark for the student"
                             onChange={e => content = e.target.value} />
                         </Form.Group>
                  
                         <div className="d-flex">
                              <Button
                             onClick={() => saveRemark(quiz_uid, response.uid, content, currentChannel.id)}
                             variant="success" 
                             style={{width: "100px", marginRight: "5px"}}
                             size="sm"><small>Save</small></Button> 

                             <Button
                             onClick={() => publishRemark(quiz_uid, response.uid, content, currentChannel.id)}
                             variant="primary" 
                             style={{width: "100px", marginRight: "5px"}}
                             size="sm"><small>Publish</small></Button>
                         </div>
                     </Form>}
                   
        </div>
          </div>
   // );
          //};*/
  }

  const [assignedShow, setAssignedShow] = useState(false)
  const [answers, setAnswers] = useState([]);
  let [showAns, setShowAns] = useState("");
  const [responseAnswers, setResponseAnswers] = useState([]);
  const [submit, setSubmit] = useState(
    quizData && getResult(responses, user_id)
  );
  const [current_question, set_current_question] = useState(
    quizData && getResult(responses, user_id)
      ? Object.keys(quizData.quizQuestions).length - 1
      : 0
  );

  const addAnswer = (q_no, ans, type = 0, question_id, option_id) => {
    console.log(q_no, ans, type, "q_no,ans,type");
    console.log(question_id, option_id, "q_id,o_id");
    if (type === 0) {
      let allAnswers = answers;
      let resAnswers = [...responseAnswers];
      let prev = allAnswers[q_no] ? allAnswers[q_no] : "";
      let prevAns = resAnswers[q_no] ? resAnswers[q_no] : {};
      allAnswers[q_no] = prev === ans ? "" : ans;
      // resAnswers[q_no] = prevAns === ans ? "" : ans;
      /* if (prevAns[question_id] && prevAns["options"] === option_id) {
        resAnswers[q_no] = {};
      }

      //prev.includes(ans)
      //? prev.filter((item) => item !== ans)
      //: [...prev, ans];
      /* let PrevAnswerObj = resAnswers.find(
        (res) => res.question_id === question_id
      );*/
      /* let index = resAnswers.findIndex(
        (res) => res.question_id === question_id && res.options !== option_id
      );*/
      //PrevAnswerObj = PrevAnswerObj ? PrevAnswerObj : {};

      let json = { question_id: question_id, options: option_id };
      //console.log(prevAns, "preA");
      // console.log(json, "json");
      // console.log(resAnswers, "resAnsw");
      if (
        prevAns.question_id === question_id &&
        prevAns.option_id === option_id
      ) {
        resAnswers.push({});
      } else {
        resAnswers.splice(q_no, 1, json);
      }
      //console.log(allAnswers, "allAns");
      //console.log(resAnswers, "resAns");

      //resAnswers.push(json);
      setAnswers(allAnswers);
      setResponseAnswers(resAnswers);
    } else {
      let allAnswers = answers;
      let resAnswers = responseAnswers;
      let prev = allAnswers[q_no] ? allAnswers[q_no] : [];

      allAnswers[q_no] = prev.includes(ans)
        ? prev.filter((item) => item !== ans)
        : [...prev, ans];
      let PrevAnswerObj = resAnswers.find(
        (res) => res.question_id === question_id
      );
      let index = resAnswers.findIndex(
        (res) => res.question_id === question_id
      );
      PrevAnswerObj = PrevAnswerObj ? PrevAnswerObj : {};
      let prevAnswers =
        PrevAnswerObj && PrevAnswerObj.options ? PrevAnswerObj.options : [];
      prevAnswers = prevAnswers.includes(option_id)
        ? prevAnswers.filter((item) => item !== option_id)
        : [...prevAnswers, option_id];
      PrevAnswerObj.question_id = question_id;
      PrevAnswerObj.options = [...prevAnswers];
      if (index === -1) {
        resAnswers.push(PrevAnswerObj);
      } else {
        resAnswers.splice(index, 1, PrevAnswerObj);
      }
      //console.log(allAnswers, "allAns");
      //console.log(resAnswers, "resAnswer");
      setAnswers(allAnswers);
      setResponseAnswers(resAnswers);
    }
  };

  const [all, setAll] = useState([]);
  const handleSubmit = async () => {
    let { currentUser, currentWorkspace, currentChannel, darkTheme} = props;
    let requestData = {
      user_id: currentUser.uid,
      room_id: currentChannel.roomId,
      workspace_id: currentWorkspace.workspaceId,
      quiz_id: quizData.id,
      answers: [...responseAnswers],
    };
    let response = await axios.post(`${API_BASE_URL}${QUIZ_SUBMIT}`, {
      user_id: currentUser.uid,
      room_id: currentChannel.roomId,
      workspace_id: currentWorkspace.workspaceId,
      quiz_id: quizData.id,
      answers: [...responseAnswers],
    });
    //console.log(response);
    if (response.status === 200) {
      // console.log(response);
      if(darkTheme){
        toast.dark(response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
      }else{
        toast(response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
      }
    } else {
      //console.log(response);
    }
    // console.log(responseAnswers);
  };

  const handleShowAns = (response) => {
    // console.log(response);
    let showAnsObj = response.id;

    if (showAnsObj === showAns) {
      showAnsObj = "";
    }

    setShowAns(showAnsObj);
  };
  const assignedToShow = () => {
    if(assignedShow){
      setAssignedShow(false)
    }else{
      setAssignedShow(true)
    }
  }
  //console.log(quizData);
  //console.log(answers);
  //console.log(responses);
  //console.log(responseAnswers);
  return (
    <>
      {Object.keys(quizData.quizQuestions).length > 0 ? (
        <div
          className={
            colorTheme ? `${colorTheme} taskbox` : "taskbox text-theme"
          }
        >
          
          <Form
            className="quiz"
            style={
              componentName === "VideoChat"
                ? { minWidth: "200px" }
                : { minWidth: "300px" }
            }
          >
            <div className="d-flex">
              <h5 className="mt-0 flex-grow-1">{quizData.quizName}</h5>
              <div>
                {quizData.deadline < Date.now() ? (
                  <span className="text-secondary small">
                    {quizData.closedBy ? (
                      <>
                        Closed by{" "}
                        <Participants
                          userId={quizData.closedBy}
                          nameOnly={true}
                        />
                      </>
                    ) : (
                      "Closed"
                    )}
                  </span>
                ) : (
                  <span className="text-success small">Active</span>
                )}
              </div>
              {open_task_branch === "saved" && open_task_id === quizData.uid && (
                <div className="mx-1">
                  <Badge variant="success">Saved</Badge>
                </div>
              )}
            </div>
            {quizData.closedBy && quizData.previousDeadline && (
              <div className="text-danger small mb-1">
                <>
                  <span className="text-warning">
                    Quiz closed{" "}
                    {getTimeDifference(
                      quizData.previousDeadline,
                      quizData.deadline
                    )}{" "}
                    before actual deadline.
                  </span>
                </>
              </div>
            )}
            <span className="small d-block">
              Deadline: {timeFromNow(quizData.deadline)}
            </span>
            <p className="mt-0 mb-1">
              Total questions: {Object.keys(quizData.quizQuestions).length}
            </p>
            {admin &&
              Object.keys(quizData.quizQuestions).length > 0 &&
              Object.keys(quizData.quizQuestions)
                .sort(
                  (a, b) =>
                    quizData.quizQuestions[a].sequenceNo -
                    quizData.quizQuestions[b].sequenceNo
                )
                .map((item, key) => (
                  <>
                    <p className="my-0" key={key}>
                      <span className="font-weight-bold">Q{key + 1}.</span>{" "}
                      {quizData.quizQuestions[item].question}
                    </p>


                    {quizData.quizQuestions[item].questionImage &&
                      <>
                        <img
                          className="img-fluid mb-2"
                          alt=""
                          style={{ height: 100, width: "auto" }}
                          src={quizData.quizQuestions[item].questionImage}
                          onClick={() => {
                            setImageSelected(quizData.quizQuestions[item].questionImage)
                            setVisible(true)
                          }}
                        />
                      </>
                    }


                    <div>
                      <div className="row">
                        {Object.keys(quizData.quizQuestions[item].options)
                          .sort(
                            (a, b) =>
                              quizData.quizQuestions[item].options[a]
                                .sequenceNo -
                              quizData.quizQuestions[item].options[b].sequenceNo
                          )
                          .map((o, k) => (
                            <>
                              <div className="d-flex">
                                <div className="col-md-4">
                                  {quizData.quizQuestions[item].options[o]
                                    .optionImage == null ? null : (
                                    <img
                                      src={
                                        quizData.quizQuestions[item].options[o]
                                          .optionImage
                                      }
                                      style={{ width: "100px" }}
                                    />
                                  )}
                                  <div
                                    className={
                                      quizData.quizQuestions[item].options[o]
                                        .correctAnswer
                                        ? "form-check rounded mb-2 border-dark col"
                                        : "form-check rounded mb-2 col"
                                    }
                                    key={k}
                                  >
                                    <input
                                      className="form-check-input"
                                      type={
                                        quizData.quizQuestions[item].type === 0
                                          ? "radio"
                                          : "checkbox"
                                      }
                                      value={
                                        quizData.quizQuestions[item].options[o]
                                          .optionValue
                                      }
                                      name={
                                        quizData.createdBy +
                                        "_" +
                                        quizData.quizQuestions[item].options[o]
                                          .optionValue +
                                        k +
                                        key
                                      }
                                      id={
                                        quizData.createdBy +
                                        "_" +
                                        k +
                                        "_" +
                                        key +
                                        "_" +
                                        item
                                      }
                                      checked={
                                        quizData.quizQuestions[item].options[o]
                                          .correctAnswer
                                      }
                                      //disabled={true}
                                    />

                                    <label
                                      className="form-check-label"
                                      htmlFor={
                                        quizData.createdBy + "_" + k + "_" + key
                                      }
                                    >
                                      {
                                        quizData.quizQuestions[item].options[o]
                                          .optionValue
                                      }
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                  </>
                ))}

            {/* {submit && 
                 <p className="small pb-0 mb-0">
                     Responded <i>{moment(quiz_data.response && quiz_data.response[user_id].responseTime).format("hh:mm A MMM DD YYYY")}</i>
                 </p>} */}

            {admin ? (
              responses.map((response, key) => (
                <div className="row" key={key}>
                  <div className="col-7">
                    <DisplayResponse response={response} quiz_data={quizData} />
                  </div>
                  <div className="col-5 d-flex align-items-center">
                    <small
                      onClick={() => handleShowAns(response)}
                      className="ml-auto text-primary font-weight-bold"
                      style={{ cursor: "pointer" }}
                    >
                      {showAns === response.id ? "Hide (-)" : "Show (+)"}
                    </small>
                  </div>
                  {showAns === response.id ? (
                    <DisplayAnswers response={response} quiz_data={quizData} />
                  ) : (
                    ""
                  )}
                </div>
              ))
            ) : (
              <>
                {quizData &&
                  Object.keys(quizData.quizQuestions)
                    .sort(
                      (a, b) =>
                        quizData.quizQuestions[a].sequenceNo -
                        quizData.quizQuestions[b].sequenceNo
                    )
                    .map(
                      (q, k) =>
                        current_question === k && (
                          <Card className="task" key={k}>
                            <Card.Header className="bg-white d-flex">
                              <small className="text-dark flex-grow-1">
                                Question {k + 1} <br />
                                {quizData.quizQuestions[q].weight
                                  ? quizData.quizQuestions[q].weight > 1
                                    ? `${quizData.quizQuestions[q].weight} points`
                                    : `${quizData.quizQuestions[q].weight} point`
                                  : ""}
                              </small>
                              <div className="d-flex">
                                {current_question !== 0 && (
                                  <Button
                                    size="sm"
                                    variant="light"
                                    style={{
                                      width: "100px",
                                      marginRight: "5px",
                                    }}
                                    onClick={() =>
                                      set_current_question((prev) => prev - 1)
                                    }
                                  >
                                    <small>Previous</small>
                                  </Button>
                                )}

                                {current_question !==
                                  Object.keys(quizData.quizQuestions).length -
                                    1 && (
                                  <Button
                                    size="sm"
                                    variant="dark"
                                    style={{
                                      width: "100px",
                                      marginLeft: "5px",
                                    }}
                                    onClick={() =>
                                      set_current_question((prev) => prev + 1)
                                    }
                                  >
                                    <small>Next</small>
                                  </Button>
                                )}
                               
                                {current_question ===
                                  Object.keys(quizData.quizQuestions).length -
                                    1 &&
                                  (submit ? (
                                    <Button
                                      size="sm"
                                      variant="primary"
                                      style={{
                                        width: "100px",
                                        marginLeft: "5px",
                                      }}
                                      disabled
                                    >
                                      <small>Completed</small>
                                    </Button>
                                  ) : (
                                    <Button
                                      size="sm"
                                      variant="success"
                                      style={{
                                        width: "100px",
                                        marginLeft: "5px",
                                      }}
                                      onClick={() => handleSubmit()}
                                      disabled={responseAnswers.length === 0}
                                    >
                                      <small>Submit</small>
                                    </Button>
                                  ))}
                              </div>
                            </Card.Header>
                            <Card.Body>
                              <small className="font-weight-bold text-dark">
                                {answers.filter((item) => item !== null).length}{" "}
                                of {Object.keys(quizData.quizQuestions).length}{" "}
                                answered
                              </small>

                              <p className="text-dark">
                                {quizData.quizQuestions[q].question}
                              </p>
                              <div>
                              {quizData.quizQuestions[q].questionImage &&
                                <>
                                  <img
                                    className="img-fluid mb-2"
                                    alt=""
                                    style={{ height: 100, width: "auto" }}
                                    src={quizData.quizQuestions[q].questionImage}
                                    onClick={() => {
                                      setImageSelected(quizData.quizQuestions[q].questionImage)
                                      setVisible(true)
                                    }} />

                                </>
                              }
                              </div>
                              {Object.keys(quizData.quizQuestions[q].options)
                                .sort(
                                  (a, b) =>
                                    quizData.quizQuestions[q].options[a]
                                      .sequenceNo -
                                    quizData.quizQuestions[q].options[b]
                                      .sequenceNo
                                )
                                .map((o, key) => (
                                  <>
                                    {quizData.quizQuestions[q].options[o]
                                      .optionImage == null ? null : (
                                      <img
                                        src={
                                          quizData.quizQuestions[q].options[o]
                                            .optionImage
                                        }
                                        style={{ width: "100px" }}
                                      />
                                    )}
                                    <div
                                      className={
                                        quizData.quizQuestions[q].type === 0
                                          ? quizData.quizQuestions[q].options[o]
                                              .optionValue === answers[k] ||
                                            (responseAnswers[k] &&
                                              q ===
                                                responseAnswers[k][
                                                  "question_id"
                                                ] &&
                                              responseAnswers[k] &&
                                              o ===
                                                responseAnswers[k]["options"])
                                            ? "form-check rounded mb-2 border-dark"
                                            : "form-check rounded mb-2"
                                          : answers[k] &&
                                            answers[k].includes(
                                              quizData.quizQuestions[q].options[
                                                o
                                              ].optionValue
                                            )
                                          ? "form-check rounded mb-2 border-dark"
                                          : "form-check rounded mb-2"
                                      }
                                      key={key}
                                    >
                                      <input
                                        className="form-check-input"
                                        type={
                                          quizData.quizQuestions[q].type === 0
                                            ? "radio"
                                            : "checkbox"
                                        }
                                        value={
                                          quizData.quizQuestions[q].options[o]
                                            .optionValue
                                        }
                                        name={
                                          quizData.createdBy +
                                          "_" +
                                          quizData.quizQuestions[q].options[o]
                                            .optionValue +
                                          k +
                                          key
                                        }
                                        id={
                                          quizData.createdBy +
                                          "_" +
                                          k +
                                          "_" +
                                          key +
                                          "_" +
                                          q
                                        }
                                        onChange={(e) => {
                                          /* console.log(
                                        e.target.name,
                                        e.target.value
                                      );
                                      console.log(
                                        quizData.quizQuestions[q].options[o]
                                          .optionValue
                                      );*/
                                          addAnswer(
                                            k,
                                            e.target.value,
                                            quizData.quizQuestions[q].type,
                                            q,
                                            o
                                          );
                                          setAll(e.target.value);
                                        }}
                                        checked={
                                          quizData.quizQuestions[q].type === 0
                                            ? quizData.quizQuestions[q].options[
                                              o
                                            ].optionValue === answers[k]
                                              ? true
                                              : false
                                            : answers[k] &&
                                            answers[k].includes(
                                              quizData.quizQuestions[q]
                                                .options[o].optionValue
                                            )
                                        }
                                        disabled={submit}
                                      />

                                      <label
                                        className="form-check-label"
                                        htmlFor={
                                          quizData.createdBy +
                                          "_" +
                                          k +
                                          "_" +
                                          key +
                                          "_" +
                                          q
                                        }
                                      >
                                        {
                                          quizData.quizQuestions[q].options[o]
                                            .optionValue
                                        }
                                      </label>
                                    </div>
                                  </>
                                ))}
                            </Card.Body>
                          </Card>
                        )
                    )}
              </>
            )}

{
            ImageViewer(
              imageSelected,
              visible,
              setVisible,
              setImageSelected
              )
            }
          </Form>
          {submit && (
            <div
              className="d-flex mt-2 pt-2 pb-1"
              style={{
                borderTop: 2,
                borderTopStyle: "dashed",
                borderTopColor: "#dbdbdb",
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
              <p
                className="small pb-0 mb-0 flex-grow-1 px-2"
                style={{ color: "#777B8E" }}
              >
                Response recorded{" "}
                {moment(
                  quizData.response &&
                    quizData.response[user_id] &&
                    quizData.response[user_id].responseTime
                ).format("MMM DD, YYYY hh:mm A")}
              </p>
            </div>
          )}
        </div>
      ) : null}{" "}
      {admin? (
                props.view === 2 ? (
                  <>
                    <div className="mt-2 d-flex justify-content-between">
                      <div  className=" mt-3 text-theme">Assigned To </div>
                      <div className="text-primary mt-3 pointer" onClick={() => assignedToShow()}>{assignedShow ? "Hide(-)" : "Show(+)"}</div>
                    </div>
                    {assignedShow ? (
                    <>
                    {/*console.log(currAssignment,"respoxx")*/}
                      <AssignedUsers
                        taskData={quizData}
                      />
                    </>
                    ) : null
                    }
                  </>
                ): null
              ): null}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  open_task_branch: state.platform.open_task_branch,
  open_task_id: state.platform.open_task_id,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, { saveRemark, publishRemark })(Quiz);
