import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Viewer from "react-viewer";
import {
  API_BASE_URL,
  DRIVE_HIERARCHY,
  DRIVE_RENAME_FILE,
  DRIVE_SOFT_DELETE
} from "../../../config/index";

import {
  Spinner,
  Dropdown,
  Button,
  ButtonGroup,
  InputGroup,
  FormControl,
  Modal,
  Form,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  faFilePdf,
  faLink,
  faExclamationTriangle,
  faImage,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faArrowLeft,
  faVideo,
  faUpload,
  faPaperPlane,
  faThLarge,
  faBars,
  faFolderPlus,
  faShareAlt,
  faICursor,
  faTrashAlt,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
toast.configure();
function ImageViewer(
  source,
  content,
  visible,
  setVisible,
  allImages,
  setImageSelected
) {
  let index = allImages.findIndex((img) => img.file_url === source);
  console.log(allImages);
  return (
    <>
      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
          setImageSelected({});
        }}
        activeIndex={index}
        images={[
          ...allImages.map((img) => {
            return {
              src: img.file_url,
              alt: img.file_name ? img.file_name : "",
            };
          }),
        ]}
      />
    </>
  );
}

const FolderFiles = (props) => {
  const image_type = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"];
  const video_type = ["video/mp4", "video/avi"];
  const pdf_type = ["application/pdf"];

  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];
  const {
    CustomToggle,
    toggleAddFolder,
    toggleUploadFiles,
    selectedFolderId,
    handleFolderFiles,
    createMessage,
    CustomToggleForFiles,
  } = props;
  const [loading, setLoading] = useState(true);
  const [displayType, setDisplayType] = useState(0);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoName, setVideoName] = useState("");
  const [files, setFiles] = useState([]);
  const [folders, setFolders] = useState([]);
  const [parentFolder, setParentFolder] = useState([]);
  const [currentFolder, setCurrentFolder] = useState([]);
  const [imageSelected, setImageSelected] = useState({});
  const [visible, setVisible] = useState(false);
  const [selectedFileForRename, setSelectedFileForRename] = useState({});
  const [fileName, setFileName] = useState("");
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [errorInFileName, setErrorInFileName] = useState("");
  const [deleteFile, setDeleteFile] = useState(null);
  const [deleteFileName, setDeleteFileName] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    if (e.target.name === "fileName") {
      setFileName(e.target.value);
    }
  };
  const renameSelectedFileOrImage = (file) => {
    setSelectedFileForRename(file);
    setErrorInFileName("");
    let FileNameWithoutExtention = "";
    if (file) {
      const arrayOfStrOfFileName = file.file_name.split(".");
      FileNameWithoutExtention = arrayOfStrOfFileName.shift();
    }
    setFileName(FileNameWithoutExtention);
  };
  const updateFileName = async () => {
    setIsUpdatingName(true);
    let regexStr = /^[\w\s\_\-]*$/g;
    //let regexStr=new Regex(@"^\w+([\s-_]\w+)*$");
    // let regexStr = /^[A-Za-z_-][A-Za-z0-9_-]*$/;
    if (fileName.length < 1) {
      setErrorInFileName("File name can't be null.");
      setIsUpdatingName(false);
      return;
    } else if (!regexStr.test(fileName)) {
      setErrorInFileName("Only alphabets,numbers and spaces allowed.");
      setIsUpdatingName(false);
      return;
    } else if (fileName.length > 50) {
      setErrorInFileName("Name can have max 50 characters.");
      setIsUpdatingName(false);
      return;
    }
    let file = files.find(
      (file) => file.file_id === selectedFileForRename.file_id
    );

    let extention = "";
    if (file) {
      const arrayOfStrOfFileName = file.file_name.split(".");
      if (arrayOfStrOfFileName.length > 1) {
        extention = arrayOfStrOfFileName.pop();
      }
    }

    const fileNameWithExtention = extention
      ? fileName + "." + extention
      : fileName;
    let { currentUser } = props;
    let res = await axios.post(`${API_BASE_URL}${DRIVE_RENAME_FILE}`, {
      user_id: currentUser.uid,
      file_id: selectedFileForRename.file_id,
      renew_file_name: fileNameWithExtention,
    });
    if (res.status === 200) {
      let allDriveFiles = [...files];
      if(props.darkTheme){
        toast.dark("File name changed.", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose:3000});
      }else{
        toast("File name changed.", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose:3000});
      }
      
      let index = files.findIndex(
        (file) => file.file_id === selectedFileForRename.file_id
      );
      let fileWithUpdatedName = { ...selectedFileForRename };
      fileWithUpdatedName.file_name = fileNameWithExtention;
      allDriveFiles.splice(index, 1, fileWithUpdatedName);
      setIsUpdatingName(false);
      setFiles(allDriveFiles);
      setSelectedFileForRename({});
      setErrorInFileName("");
    } else {
      setIsUpdatingName(false);
      setErrorInFileName("");
    }
  };

  const fileDeleteHandler = async (file) => {
    const { file_id } = file;
    const { currentUser } = props;
    let res = await axios.post(`${API_BASE_URL}${DRIVE_SOFT_DELETE}`, {
      user_id: currentUser.uid,
      file_id: file_id,
    });
    if (res.status === 200) {
      console.log("file deleted")
      if (props.folderId) {
        let requestData = {
          user_id: props.currentUser.uid,
          folder_id: props.folderId,
        };

        axios
          .post(`${API_BASE_URL}${DRIVE_HIERARCHY}`, {
            ...requestData,
          })
          .then((res) => {
            console.log(res);
            setFiles(res.data.data["child_files"]);
            setFolders(res.data.data["child_folders"]);
            setCurrentFolder(res.data.data);
            setLoading(false);
          }).then(() => {
            if(props.darkTheme){
              toast.dark("File deleted", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose:3000});
            }else{
              toast("File deleted", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose:3000});             
            }
          });
      }
    }else {
      if(props.darkTheme){
        toast.dark("Could not delete the file", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose:3000});
      }else{
        toast("Could not delete the file", { position: toast.POSITION.TOP_LEFT, hideProgressBar: true, autoClose:3000});
      }
      
    }
  }

  const fileDownloadHandler = async (uri, name) => {
    fetch(uri, {
      headers: new Headers({
        'Origin': window.location.origin
      }),
      mode: 'cors'
    })
    .then(response => response.blob())
    .then(blob => {
      let blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
      console.log("link", link)
    })

  }

  useEffect(() => {
    console.log(selectedFolderId);
    if (props.folderId) {
      let requestData = {
        user_id: props.currentUser.uid,
        folder_id: props.folderId,
      };

      axios
        .post(`${API_BASE_URL}${DRIVE_HIERARCHY}`, {
          ...requestData,
        })
        .then((res) => {
          console.log(res);
          setFiles(res.data.data["child_files"]);
          setFolders(res.data.data["child_folders"]);
          setCurrentFolder(res.data.data);
          setLoading(false);
        });
    }
  }, [props.folderId, selectedFolderId]);
  useEffect(() => {
    let requestData = {
      user_id: props.currentUser.uid,
    };
    if (currentFolder.parent_folder_id) {
      requestData = {
        user_id: props.currentUser.uid,
        folder_id: currentFolder.parent_folder_id,
      };
      axios
        .post(`${API_BASE_URL}${DRIVE_HIERARCHY}`, {
          ...requestData,
        })
        .then((res) => {
          setParentFolder(res.data.data);
        });
    }
  }, [currentFolder.parent_folder_id]);
  const handleVideo = (File) => {
    setVideoUrl(File.file_url);
    setVideoName(File.file_name);
  };
  const handleSelectedImage = (File) => {
    setVisible(true);
    setImageSelected({
      source: File.file_url,
      content: File.file_name,
    });
  };
  let allImages = files.filter((file) =>
    image_type.includes(file.file_content_type)
  );
  if (videoUrl) {
    return (
      <>
        <div className="m-2 rounded p-2 bg-card-theme text-theme">
          <div className="d-flex">
            <div>
              <FontAwesomeIcon
                onClick={() => setVideoUrl("")}
                icon={faArrowLeft}
                className="mr-2 pointer"
              />
              <span className="small">{videoName ? videoName : "Back"}</span>
            </div>
          </div>
        </div>
        <div className="m-2 rounded p-2 bg-card-theme text-theme">
          <video src={videoUrl} width="280" height="240" autoPlay controls />
        </div>
      </>
    );
  } else if (Object.keys(imageSelected).length > 0) {
    return ImageViewer(
      imageSelected.source,
      imageSelected.content,
      visible,
      setVisible,
      allImages,
      setImageSelected
    );
  } else {
    return (
      <>
        <div className="m-2 rounded p-2 bg-card-theme text-theme">
          <div className="d-flex">
            <div className="text-truncate">
              <FontAwesomeIcon
                onClick={() => {
                  handleFolderFiles(
                    currentFolder.parent_folder_id
                      ? {
                        ...parentFolder,
                      }
                      : {}
                  );
                }}
                icon={faArrowLeft}
                className="mr-2 pointer"
              />
              <span>{currentFolder.folder_name}</span>
            </div>
            <Dropdown className="ml-auto mr-2 px-1 justify-content-right text-right  pointer  text-theme align-middle">
              <Dropdown.Toggle size="sm" as={CustomToggle} />
              <Dropdown.Menu>
                <Dropdown.Item
                  className="small"
                  onClick={() => toggleAddFolder()}
                >
                  <FontAwesomeIcon
                    icon={faFolderPlus}
                    className="mr-2 pointer"
                  />{" "}
                  Add folder
                </Dropdown.Item>
                <Dropdown.Item
                  className="small"
                  onClick={() => toggleUploadFiles()}
                >
                  <FontAwesomeIcon icon={faUpload} className="mr-2 pointer" />{" "}
                  Upload files
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {loading ? (
          <div className="d-flex justify-content-center align-middle">
            <Spinner animation="border" variant="light" />
          </div>
        ) : (
            <>
              {folders.length === 0 && files.length === 0 ? (
                <div className="col">
                  <p className="text-theme text-center small">
                    This Folder is empty.
                </p>
                </div>
              ) : null}
              {folders.length > 0 || files.length > 0 ? (
                <div className="d-flex justify-content-end m-2">
                  <ButtonGroup size="sm">
                    <Button
                      variant={
                        displayType === 0 ? "secondary" : "outline-secondary"
                      }
                      onClick={() => setDisplayType(0)}
                      title="List view"
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </Button>
                    <Button
                      variant={
                        displayType === 1 ? "secondary" : "outline-secondary"
                      }
                      onClick={() => setDisplayType(1)}
                      title="Thumbnail view"
                    >
                      <FontAwesomeIcon icon={faThLarge} />
                    </Button>
                  </ButtonGroup>
                </div>
              ) : null}
              <div className="row m-2 text-theme">
                {folders.map((folder) => (
                  <div
                    className={
                      displayType === 0
                        ? "col-12 p-1 rounded border-1 bg-theme pointer d-flex align-items-center"
                        : "col-3 p-1 rounded border-1 bg-theme pointer d-flex flex-column"
                    }
                    key={folder.folder_id}
                    onClick={() =>
                      handleFolderFiles({
                        ...folder,
                        parent_folder_id: currentFolder.folder_id,
                      })
                    }
                  >
                    <div className="d-flex justify-content-center">
                      <img
                        src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/folder-blue-512.png"
                        className={displayType === 0 ? "" : "w-75"}
                        title={folder.folder_name}
                        style={
                          displayType === 0
                            ? { widht: "20px", height: "20px" }
                            : {}
                        }
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <p
                        title={folder.folder_name}
                        className={
                          displayType === 0
                            ? "text-theme ml-2 mb-0 small"
                            : "text-theme ml-2 mb-0 small text-truncate"
                        }
                      // className="text-theme ml-2 mb-0 small text-truncate"
                      >
                        {folder.folder_name}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="row m-2 text-theme">
                {files.map((File) => {
                  if (!File.soft_deleted) {
                    return (
                      <>
                        <div
                          className={
                            displayType === 0
                              ? "col-12 p-2 rounded shadow border-1 bg-theme d-flex justify-content-center align-items-center"
                              : "col-3 p-2 rounded shadow border-1 bg-theme"
                          }
                          title={File.file_name}
                          key={File.file_url}
                        >
                          <a
                            onClick={
                              () =>
                                (video_type.includes(File.file_content_type) &&
                                  handleVideo(File)) ||
                                (image_type.includes(File.file_content_type) &&
                                  handleSelectedImage(File))
                              // handleVideo(File.file_url)
                            }
                            href={
                              !video_type.includes(File.file_content_type) &&
                              !image_type.includes(File.file_content_type) &&
                              File.file_url
                            }
                            className={
                              displayType === 0
                                ? "text-theme text-decoration-none d-flex"
                                : "text-theme text-decoration-none row d-flex justify-content-center align-items-center"
                            }
                            target="_blank"
                          >
                            {word_type.includes(File.file_content_type) && (
                              <FontAwesomeIcon
                                icon={faFileWord}
                                className={
                                  displayType === 0
                                    ? "text-primary mr-1"
                                    : "w-75 text-primary h1 mr-1"
                                }
                              />
                            )}
                            {video_type.includes(File.file_content_type) && (
                              <FontAwesomeIcon
                                icon={faVideo}
                                className={
                                  displayType === 0
                                    ? "text-secondary mr-1"
                                    : "w-75 text-secondary h1 mr-1"
                                }
                              />
                            )}
                            {image_type.includes(File.file_content_type) && (
                              <FontAwesomeIcon
                                icon={faImage}
                                className={
                                  displayType === 0
                                    ? "text-secondary mr-1"
                                    : "w-75 text-secondary h1 mr-1"
                                }
                              />
                            )}
                            {pdf_type.includes(File.file_content_type) && (
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                className={
                                  displayType === 0
                                    ? "text-danger mr-1"
                                    : "w-75 text-danger h1 mr-1"
                                }
                              />
                            )}
                            {powerpoint_type.includes(File.file_content_type) && (
                              <FontAwesomeIcon
                                icon={faFilePowerpoint}
                                className={
                                  displayType === 0
                                    ? "text-danger mr-1"
                                    : "w-75 text-danger h1 mr-1"
                                }
                              />
                            )}
                            {excel_type.includes(File.file_content_type) && (
                              <FontAwesomeIcon
                                icon={faFileExcel}
                                className={
                                  displayType === 0
                                    ? "text-success mr-1"
                                    : "w-75 text-success h1 mr-1"
                                }
                              />
                            )}

                            <span
                              className={
                                displayType === 0
                                  ? "d-block text-truncate small pointer"
                                  : "w-100 col-12 text-truncate smaller pointer"
                              }
                            >
                              {File.file_name.length > 32
                                ? File.file_name.substring(0, 30)
                                : File.file_name}
                            </span>
                          </a>
                          {props.currentWorkspace ? (
                            <Dropdown
                              className={
                                displayType === 0
                                  ? "ml-auto mr-2 px-1 justify-content-right text-right  pointer  text-theme"
                                  : "col-12 d-flex justify-content-center"
                              }
                            >
                              <Dropdown.Toggle size="sm" as={CustomToggleForFiles} />
                              <Dropdown.Menu className="bg-card-theme text-theme">
                                <Dropdown.Item
                                  className="small bg-card-theme text-theme"
                                  onClick={() => createMessage(File)}
                                >
                                  <FontAwesomeIcon
                                    icon={faShareAlt}
                                    className="mr-2 pointer"
                                  />{" "}
                            Share to rooms
                          </Dropdown.Item>
                                <Dropdown.Item
                                  className="small bg-card-theme text-theme"
                                  onClick={() => renameSelectedFileOrImage(File)}
                                >
                                  <FontAwesomeIcon
                                    icon={faICursor}
                                    className="mr-2 pointer"
                                  />{" "}
                            Rename{" "}
                                  {/*<span className="text-warning">(coming soon)</span>*/}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="small bg-card-theme text-theme"
                                  onClick={() =>  {
                                    handleShow()
                                    setDeleteFile(File)
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrashAlt}
                                    className="mr-2 pointer"
                                  />{" "}
                            Delete{" "}
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                  className="small"
                                  onClick={() =>
                                    fileDownloadHandler(File.file_url, File.file_name)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faDownload}
                                    className="mr-2 pointer"
                                  />{" "}
                                  Download{" "}
                                </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : null}
                          {/* {props.currentWorkspace ? (
                    <FontAwesomeIcon
                      className="ml-auto mr-2 px-1 d-flex justify-content-right text-right  pointer text-theme "
                      icon={faPaperPlane}
                      size="lg"
                      onClick={() => createMessage(File)}
                    />
                  ) : null}
                 */}
                        </div>
                        {Object.keys(selectedFileForRename).length > 0 &&
                          selectedFileForRename.file_id === File.file_id ? (
                            <>
                              <InputGroup
                                className="my-3"
                                size="sm"
                                style={{ zIndex: 300 }}
                              >
                                <FormControl
                                  placeholder="File's name"
                                  aria-label=""
                                  aria-describedby="basic-addon2"
                                  autoFocus={true}
                                  name="fileName"
                                  value={fileName}
                                  onChange={(e) => handleChange(e)}
                                />
                                <InputGroup.Append>
                                  {isUpdatingName ? (
                                    <Button variant="primary" disabled>
                                      <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                              Updating...
                                    </Button>
                                  ) : (
                                      <Button
                                        variant="outline-success"
                                        onClick={() => updateFileName()}
                                      >
                                        Update
                                      </Button>
                                    )}
                                </InputGroup.Append>
                                <InputGroup.Append>
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    onClick={() => {
                                      setSelectedFileForRename({});
                                      setErrorInFileName("");
                                      setFileName("");
                                      setIsUpdatingName(false);
                                    }}
                                  >
                                    cancel
                          </Button>
                                </InputGroup.Append>
                              </InputGroup>
                              {errorInFileName ? (
                                <p className="small text-danger mt-2">
                                  {errorInFileName}
                                </p>
                              ) : null}
                            </>
                          ) : null}
                      </>
                    )
                  }
                })}
              </div>
            </>
          )}
          <>
            <Modal className="border-dark" animation show={show} onHide={handleClose}>
              <div className="bg-theme text-theme">
                <div className="container bg-theme text-theme">
                  <h5 className="mt-3"><FontAwesomeIcon className="mr-2"  icon={faExclamationTriangle} />Delete this File?</h5>
                  <p className="small">Doing so will permanently delete this file.</p>
                </div>
                <div className="container mt-3 bg-theme text-theme">
                  <div className="font-weight-light">File</div>
                  <div className="font-weight-bold">{deleteFile?.file_name}</div>                  
                </div>
              </div>
              <Modal.Body className="mt-0 bg-theme text-theme">
                <Form>
                  <Form.Group controlId="Basic">
                    <div className="text-weight-light">Confirm that you want to delete this file by typing its name : </div>
                    <div className="font-weight-bold">{deleteFile?.file_name}</div>
                    <Form.Control 
                      type="text" 
                      placeholder="Enter File Name"
                      onChange ={(e) => setDeleteFileName(e.target.value)}
                    />
                  </Form.Group>
                </Form>
                <Button className="mr-5" onClick={handleClose}>
                  Cancel
                </Button>
                <Button 
                  variant="primary" 
                  disabled={deleteFile?.file_name != deleteFileName}
                  onClick ={() =>{
                    fileDeleteHandler(deleteFile)                      
                    handleClose()
                  }}
                >
                  Delete
                </Button>
              </Modal.Body>
            </Modal>
          </>
      </>
    );
  }
};
const mapStateToProps = (state) => ({
  selectedFolderId: state.selectedFolder.id,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
});
export default connect(mapStateToProps, null)(FolderFiles);
