import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import { API_BASE_URL, DRIVE_STORAGE_DETAILS } from "../config/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDatabase, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

class StorageNotice extends Component {
  state = {
    availableSpace: 0,
    totalSpace: 0,
    consumedSpace: 0,
    consumedSpacePercentage: 0,
    show: false,
  };
  async componentDidMount() {
    const { currentUser } = this.props;
    let response = await axios.post(`${API_BASE_URL}${DRIVE_STORAGE_DETAILS}`, {
      user_id: currentUser.uid,
    });
    //console.log(response);
    this.setState({
      availableSpace: response.data.data["available"] / 1073741824,
      totalSpace: response.data.data["total"] / 1073741824,
      consumedSpace: response.data.data["consumed"] / 1073741824,
      consumedSpacePercentage:
        100 *
        (response.data.data["consumed"] /
          1073741824 /
          (response.data.data["total"] / 1073741824)),
    });
    /*
    //for testing purpose
    
    let consumedSpace = 4.8;
    let totalSpace = 5;
    let consumedSpacePercentage = (100 * 4.8) / 5;
    let availableSpace = totalSpace - consumedSpace;
    this.setState({
      availableSpace,
      totalSpace,
      consumedSpace,
      consumedSpacePercentage,
    });*/
    if (
      100 *
        (response.data.data["consumed"] /
          1073741824 /
          (response.data.data["total"] / 1073741824)) >=
      90
    ) {
      this.setState({ show: true });
    }
    //if (consumedSpacePercentage >= 90) this.setState({ show: true });
  }
  render() {
    const { show, consumedSpace, totalSpace } = this.state;
    return (
      <Modal
        show={show}
        onHide={() => this.setState({ show: false })}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" />
            <p className="mb-0 ml-2">Space running out</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <div className="d-flex justify-content-between">
              <small className="text-muted">STORAGE</small>
              <small className="text-muted">{`${consumedSpace.toFixed(2).replace(".00", "")} GB/${totalSpace.toFixed(2).replace(".00", "")} GB`}</small>
            </div>

            <div className="mt-1">
              <ProgressBar
                style={{ height: "6px" }}
                max={5}
                min={0}
                animated
                now={consumedSpace.toFixed(2)}
              />
            </div>
          </div>

          <p>You are running out of storage. Please considering upgrading your account to keep enjoying igesia.</p>
          <p>Please contact <a href="mailto:hello@igesia.co">hello@igesia.co</a> to upgrade your account.</p>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, null)(StorageNotice);
