import React, { useEffect, useState, useRef } from 'react';
import { loadState, saveState } from '../../services/index';
import { Card, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import firebase from 'firebase';
import { Link } from 'react-router-dom';
import { postData } from '../../utils/index';
import { API_BASE_URL, USER_EXISTS, USER_WORKSPACES } from '../../config/index'
import * as DeviceDetect from "react-device-detect";
import { connect } from 'react-redux';
import { browserName, CustomView, osName } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import {
    setDarkTheme,
    setCurrentWorkspace,
    setCurrentChannel,
    setAllMessages,
    setParticipants,
    setWorkspaces,
    setUser,
    setChannels,
    setRightPanel,
    setWorkspaceSlug
} from '../../actions/index'
import axios from 'axios';


const UserWorkspaces = (props) => {
    const [workspacesDetails, setWorkspacesDetails] = useState([]);
    const [loading, setLoading] = useState(true)
    const history = useHistory();
    const [unsubscribe, setUnsubscribe] = useState(null);


    // useffect to check whether the user has joined atleast one workspace/room. If not then redirect them

    useEffect(() => {
        if (props.currentUser) {
            firebase.firestore().collection(`users/${props.currentUser.uid}/rooms`).get().then((querySnapshot) => {
                if (querySnapshot.docs.length !== 0) {
                    // user is a part of atleat one workspace
                } else {
                    history.push('/start')
                }
            })
        }
    }, [props.currentUser])



    useEffect(() => {
        if (props.currentUser) {
            props.setWorkspaces(props.currentUser.uid);
        }
    }, [props.currentUser])

    useEffect(() => {
        if (props.currentUser && Array.isArray(props.workspaces)) {
            console.log(props.workspaces)
            setWorkspacesDetails(props.workspaces)
            setLoading(false);
            // axios.post(`${API_BASE_URL}${USER_WORKSPACES}`, {
            //     user_id: props.currentUser.uid
            // }).then((response) => {
            //     if (response.status === 200) {
            //         setWorkspacesDetails(response.data.data.user_workspaces);
            //         setLoading(false);
            //     }
            // }).catch((error) => {
            //     console.log(error.message);
            // })
        }
    }, [props.currentUser, props.workspaces])


    const getWorkspaceHandler = async (workspaceid) => {

        let docRef = firebase.firestore().collection(`workspaces`).doc(`${workspaceid}`)
        await docRef.get()
            .then(function (doc) {
                if (doc.exists) {
                    const workspacedata = doc.data();
                    const unsub = firebase
                        .firestore()
                        .collection(`users/${props.currentUser.uid}/rooms`)
                        .onSnapshot(function (snapshot) {
                            let userWorkspaces = [];
                            snapshot.forEach(function (doc) {
                                userWorkspaces.push({ ...doc.data() })
                            })
                            //  traversing through the workspaces and setting the workspace which has workspaceId === workspaceId from url
                            let workspaceRooms;
                            userWorkspaces.forEach((workspace) => {
                                if (workspace.workspaceId === workspaceid) {
                                    workspaceRooms = workspace;
                                }
                            })

                            saveState("defaultWorkspace", { ...workspacedata, ...workspaceRooms });


                            // setting workspace data in redux
                            props.setCurrentWorkspace({ ...workspacedata, ...workspaceRooms })
                        })
                    setUnsubscribe(() => unsub)

                } else {
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            })
    }

    useEffect(() => {
        return(() => {
            if(unsubscribe) {
                console.log("unsubscribe from userworkspaces")
                unsubscribe()
            }
        })
    },[unsubscribe])


    const handleSignout = async () => {
        const {
            setCurrentWorkspace,
            setCurrentChannel,
            setAllMessages,
            setParticipants,
            setWorkspaces,
            setUser,
            currentUser,
            setChannels,
            setRightPanel,
            setWorkspaceSlug
        } = props;

        firebase
            .auth()
            .signOut()
            .then(() => {

                setRightPanel(false);
                setCurrentWorkspace(null);
                setCurrentChannel(null);
                setAllMessages(null);
                setParticipants(null);
                setWorkspaces(null);
                setUser(null);
                setChannels(null)
                setWorkspaceSlug(null)
                window.localStorage.removeItem("defaultWorkspace");
                history.push('/signin')
                console.log("signed out!");
            });
    };

    const { currentUser } = props;

    return (

        <>
            {
                props.workspaces && props.currentUser ?
                    <div className="container-fluid overflow-auto" style={{ minHeight: "100vh", background: "#18191a" }}>
                        <div className="container" style={{height: '100vh'}}>
                            <div className="row pt-5 justify-content-center">
                                <div className="col-md-4 pt-5 text-center">
                                    <img alt="igesia-logo" src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-reverse.png?alt=media&token=93df9989-4eab-406f-8122-ada051558941"
                                        width="200" />
                                </div>
                            </div>

                            <div className="row pt-5 justify-content-center">
                                <div className="col-lg-5 col-md-6 col-sm-8 col-10 py-4 px-5 shadow-sm text-light rounded" style={{ background: `rgb(35, 38, 38)` }}>
                                    <div className="d-flex justify-content-center">
                                        <div className="align-items-center text-center">
                                            <img alt="" className="rounded-circle" src={currentUser.photoURL} style={{ width: "6rem", height: "6rem" }}></img>
                                            <h6 className="font-weight-light">Welcome back <span className="font-weight-bold">{currentUser.displayName}</span> ({currentUser.email})</h6>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <h5 className="text-theme">Click and select a workspace:</h5>
                                    </div>
                                    <div id="workspace-list" className="overflow-auto" style={{maxHeight: '35vh'}}>
                                        {
                                            loading
                                                ? <div
                                                    className="mt-2"
                                                    style={{ cursor: 'pointer' }}
                                                    title="Loading..."
                                                >
                                                    <Card className="bg-dark w-100">
                                                        <Card.Body className="d-flex p-3">
                                                            <div className="px-2">
                                                                <div
                                                                    className="rounded-circle bg-secondary align-items-center d-flex justify-content-center"
                                                                    style={{ width: "3rem", height: "3rem" }}
                                                                >
                                                                    <Spinner animation="border" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 align-items-center d-flex px-2">
                                                                <div className="mb-0 bg-secondary w-100" style={{ height: "18px" }}></div>
                                                            </div>
                                                            <div className="align-items-center d-flex px-2">
                                                                <FontAwesomeIcon icon={faAngleRight} />
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                                : workspacesDetails.map((workspace) => {
                                                    return (
                                                        <div
                                                            className=""
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                getWorkspaceHandler(workspace.workspaceId).then(() => {
                                                                    setTimeout(() => history.push('/'), 1000)
                                                                })
                                                            }}
                                                        >
                                                            <Card className="bg-dark w-100 mb-1" title={workspace.workspaceName}>
                                                                <Card.Body className="d-flex p-3">
                                                                    <div className="px-2">
                                                                        <img alt="" className="img-fluid rounded-circle bg-light" src={workspace.workspacePic} style={{ width: "3rem", height: "3rem" }}></img>
                                                                    </div>
                                                                    <div className="flex-grow-1 align-items-center d-flex px-2">
                                                                        <h5 className="font-weight-normal mb-0">{workspace.workspaceName}</h5>
                                                                    </div>
                                                                    <div className="align-items-center d-flex px-2">
                                                                        <FontAwesomeIcon icon={faAngleRight} />
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    )
                                                })
                                        }
                                    </div>
                                    <div className="d-flex justify-content-center mt-3">
                                        <div className="align-items-center text-center">
                                            <h6 className="font-weight-light">Not {" "}
                                            <span className="font-weight-bold">{currentUser.displayName} ? </span>
                                            <Link className="ml-1"
                                                    style={{ cursor: "pointer", color: "#6900ff" }}
                                                    onClick={(e) => handleSignout()}
                                                >
                                                    <u> <FontAwesomeIcon icon={faSignOutAlt} className="small" /> Logout</u>
                                                </Link>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row justify-content-center">
                                <div className="text-center mt-3">
                                    <DropdownButton id="dropdown-basic-button" title="Current Workspace">
                                        {
                                            workspacesDetails.map((workspace) => {
                                                return (
                                                    <Dropdown.Item onClick={() => {
                                                        getWorkspaceHandler(workspace.workspaceId).then(() => {
                                                            setTimeout(() => history.push('/'), 1000)
                                                        })
                                                    }}>{workspace.workspaceName}</Dropdown.Item>
                                                )
                                            })
                                        }
                                    </DropdownButton>
                                </div>
                            </div> */}

                        </div >
                    </div >
                    : null
            }
        </>


    );
}

const mapStateToProps = state => ({
    newRoom: state.newRoom.newRoom,
    newRoomError: state.newRoom.newRoomError,
    currentUser: state.user.currentUser,
    workspaces: state.workspace.workspaces,
})

export default connect(mapStateToProps, {
    setCurrentWorkspace,
    setWorkspaces,
    setDarkTheme,
    setCurrentChannel,
    setAllMessages,
    setParticipants,
    setUser,
    setChannels,
    setRightPanel,
    setWorkspaceSlug
})(UserWorkspaces);
