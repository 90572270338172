import * as actionTypes from './types'
import firebase from 'firebase'
import axios from 'axios';
import { API_BASE_URL, USER_WORKSPACES } from '../config/index'

export const setCurrentWorkspace = workspace => {
    return {
        type: actionTypes.SET_CURRENT_WORKSPACE,
        payload: {
            currentWorkspace: workspace
        }
    }
}

export const setRoomsVisible = roomsVisible => {
    return {
        type: actionTypes.SET_ROOMS_VISIBLE,
        payload: {
            roomsVisible
        }
    }
}

export const setWorkspaces = userId => {
    return dispatch => {
        firebase
            .firestore()
            .collection(`users/${userId}/rooms`)
            .onSnapshot(function (snapshot) {
                let userWorkspaces = [];

                // snapshot.forEach(function (doc) {
                //     userWorkspaces.push({ ...doc.data() })
                // })

                axios.post(`${API_BASE_URL}${USER_WORKSPACES}`, {
                    user_id: userId
                }).then((response) => {
                    if(response.status === 200) {
                        userWorkspaces = response.data.data.user_workspaces;
                        console.log("userWorkspaces", userWorkspaces)
                        return dispatch({
                            type: actionTypes.SET_WORKSPACES,
                            payload: {
                                workspaces: userWorkspaces
                            }
                        })
                    }
                }).catch((error) => {
                    console.log(error.message);
                })
            })
    }
}