import React, { useEffect, useState } from "react";
import {
  Form,
  Spinner,
  Badge,
  ButtonToolbar,
  Button,
  Image,
  ProgressBar,
} from "react-bootstrap";
import { connect } from "react-redux";
import firebase from "firebase";
import moment from "moment";
import UserResponse from "../../Right/UserNameAvatar";
import {
  API_BASE_URL,
  DRIVE_FILE,
  DRIVE_UPLOAD,
  POLL_CREATE,
  POLL_REMOVE,
  POLL_UPDATE,
  POLL_SUBMIT,
  POLL_SHARE,
  POLL_ANALYTICS
} from "../../../config/index";
import axios from "axios";
import Participants from "../../Right/UserNameAvatar";
import { getTimeDifference } from "../Common/TimeDifference";
import AssignedUsers from "../Common/AssignedUsers";
import { toast } from "react-toastify";
import _ from 'lodash';
toast.configure();

function PollUser(props) {
  const { pollData, taskResponse, currentUser, darkTheme } = props;

  const [responses, setResponses] = useState([]);
  const [response, setResponse] = useState({});
  const [alreadyResponded, setAlreadyResponsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isClosed, setIsClosed] = useState(false);


  useEffect(() => {
    // console.log("pollData.response", pollData.response)
    if (pollData.response) {
      setResponses(pollData.response);

      let myResponse = pollData.response.filter(
        (response) => response.userId === currentUser.uid
      );
      if (myResponse.length === 1) {
        setResponse(myResponse[0]);
        setAlreadyResponsed(true);
        setLoading(false);
      }
    }

    if (pollData.previousDeadline || pollData.deadline < Date.now().valueOf()) {
      setIsClosed(true);
      //props.setIsClosed(true);
    }



  }, [pollData]);

  return (
    <div className="d-flex">
      {/* <p>{JSON.stringify(response)}</p> */}
      {alreadyResponded ? (
        <div className="w-100">
          {!loading && fooUser(pollData, responses)}

          <Badge variant="secondary">Your answer</Badge>
          <p className="mt-0 mb-0">{response.optionValue}</p>
          <p className="mt-0 mb-0 small">
            {timeFromNow(response.responseTime)}
          </p>
        </div>
      ) : (
          <ButtonToolbar
            aria-label="Toolbar with button groups"
            className="w-100"
          >
            {pollData.options &&
              pollData.options.map((option, key) => (
                <div className="col-12 ">
                  <Button
                    disabled={pollData.deadline < Date.now()}
                    className={darkTheme ? "w-100 mb-2 border-primary text-white rounded" : "w-100 mb-2 border-primary text-dark rounded"}
                    variant=""
                    size="sm"
                    value={option}
                    onClick={() => taskResponse(option)}
                    // onClick={() => {
                    //     setValue(option);
                    //     taskResponse(uid, option);
                    //     closeTask();
                    //     showResult();
                    //     showAnswer(value);
                    //     setIsTaskResponded(true);}}
                    size="sm"
                  >
                    {pollData.images && pollData.images.length > 0 && (
                      <Image
                        src={pollData.images[key]}
                        style={{ height: 100, width: "auto" }}
                      // onClick={() => {
                      //     setImageViewerOpen(prev => prev === item.uid ? null : item.uid)
                      //     setActiveIndex(key) }}
                      />
                    )}{" "}
                    <br />
                    {option}
                  </Button>
                </div>
              ))}
          </ButtonToolbar>
        )}
    </div>
  );
}

function PollAdmin(props) {
  const { pollData } = props;

  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [responseAnalytics, setResponseAnalytics] = useState({});


  useEffect(() => {
    // console.log("pollData.response", pollData.response)
    if (pollData.response) {
      let participantsWithName = [];
      pollData.response.forEach((response, index) => {
        firebase.firestore().collection("users").doc(`${response.userId}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              participantsWithName.push({ displayName: doc.data().displayName, userId: response.userId, ...response });
            } else {
            }
          }).then(() => {
            setResponses(participantsWithName);
          })
      })
    }

    if (pollData.previousDeadline || pollData.deadline < Date.now().valueOf()) {
      setIsClosed(true);
      //props.setIsClosed(true);
    }


    axios.post(`${API_BASE_URL}${POLL_ANALYTICS}`, {
      user_id: props.currentUser.uid,
      workspace_id: props.currentWorkspace.workspaceId,
      room_id: props.currentChannel.roomId,
      published_poll_id: props.contentId
    }).then((res) => {
      if (res.status === 200) {
        setResponseAnalytics(res.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }).catch((err) => {
      setLoading(false);
    })

  }, [pollData]);

  return (
    <div>
      {!loading && foo(pollData, responses, responseAnalytics)}

      <div className="mt-2">
        {!loading &&
          (responses.length > 0 ? (
            <div className="d-flex justify-content-center">
              <Badge variant="secondary"> {responses.length} Response(s)</Badge>
              <small
                onClick={() => setShow((prev) => !prev)}
                className="ml-auto text-primary font-weight-bold"
                style={{ cursor: "pointer" }}
              >
                {show ? "Hide (-)" : "Show (+)"}
              </small>
            </div>
          ) : (
              <p className="my-1">No responses yet</p>
            ))}
      </div>

      {show
        ? responses.map((response) => (
          <UserResponse displayName={response.displayName} userId={response.userId} nameOnly={true}>
            <>
              <p className="mt-0 mb-0">{response.optionValue}</p>
              <p className="mt-0 mb-0 small">
                {timeFromNow(response.responseTime)}
              </p>
            </>
          </UserResponse>
        ))
        : null}
    </div>
  );
}


const fooUser = (item, arr) => {
  var a = [],
    b = [],
    prev = "";

  const arrValues = arr.map((item) => item.optionValue);
  arrValues.sort();

  for (var i = 0; i < arrValues.length; i++) {
    if (arrValues[i] !== prev) {
      a.push(arrValues[i]);
      b.push(1);
    } else {
      b[b.length - 1]++;
    }
    prev = arrValues[i];
  }
  var c = [];
  for (var i = 0; i < a.length; i++) {
    c[i] = (b[i] * 100) / arrValues.length;
  }

  //console.log(item, arr, "in foo")

  return (
    <>
      {item &&
        item["options"].map((option, key) => (
          <div>
            {item.images && item.images.length > 0 && (
              <Image
                src={item.images[key]}
                style={{ height: 100, width: "auto" }}
                // onClick={() => {
                //     setImageViewerOpen(prev => prev === item.uid ? null : item.uid)
                //     setActiveIndex(key)
                // }}
              />
            )}
            <p className="mb-1">{option}</p>
            <ProgressBar
              style={{ height: "1rem" }}
              className="mb-1"
              animated
              now={a.indexOf(option) >= 0 ? c[a.indexOf(option)] : "0"}
              label={`${
                a.indexOf(option) >= 0 ? c[a.indexOf(option)].toFixed(2) : "0"
              } %`}
            />
          </div>
        ))}
      {/* {item.images && <Viewer
            visible={imageViewerOpen === item.uid ? true : false}
            onClose={() => setImageViewerOpen(prev => prev === item.uid ? null : item.uid)}
            images={item.images.map((image, key) => { return {src: image, alt: item.options[key]}})}
            activeIndex={activeIndex}
            downloadable={false}
        />} */}
    </>
  );
};


const foo = (item, arr, responseAnalytics) => {

  return (
    <>
      {item &&
        item["options"].map((option, key) => (
          <div>
            {item.images && item.images.length > 0 && (
              <Image
                src={item.images[key]}
                style={{ height: 100, width: "auto" }}
              // onClick={() => {
              //     setImageViewerOpen(prev => prev === item.uid ? null : item.uid)
              //     setActiveIndex(key)
              // }}
              />
            )}
           
            <div className="d-flex justify-content-between">
              <p className="mb-1">{option}</p>
              <p className="mb-1"></p>
              <p className="mb-1">{
              responseAnalytics && responseAnalytics.optionCount ?
              <div>{`${responseAnalytics?.optionCount[option]}/${responseAnalytics?.optionCount["total"]} `}</div>
              : null
            }</p>
            </div>
            <ProgressBar
              style={{ height: "1rem" }}
              className="mb-1"
              animated
              now={responseAnalytics ? responseAnalytics.percentage ? responseAnalytics.percentage[option] : 0 : 0}
              label={responseAnalytics ? responseAnalytics.percentage ? responseAnalytics?.percentage[option] + "%" : null : null}
            />
            {/*
              responseAnalytics && responseAnalytics.optionCount ?
              <div>{`${responseAnalytics?.optionCount[option]} out of ${responseAnalytics?.optionCount["total"]} participant(s)`}</div>
              : null
            */}
          </div>
        ))}
      {/* {item.images && <Viewer
            visible={imageViewerOpen === item.uid ? true : false}
            onClose={() => setImageViewerOpen(prev => prev === item.uid ? null : item.uid)}
            images={item.images.map((image, key) => { return {src: image, alt: item.options[key]}})}
            activeIndex={activeIndex}
            downloadable={false}
        />} */}
    </>
  );
};

const timeFromNow = (timestamp) => {
  if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
    return moment(timestamp).fromNow();
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
    return "yesterday";
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
    return moment(timestamp).format("dddd");
  else return moment(timestamp).format("MMM DD YYYY");
};

class Poll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pollData: {},
      admin: false,
      assignedShow: false,
      unsubscribePollData: null
    };
  }

  componentDidMount() {
    const { contentId, currentUser } = this.props;

    const subPollData = firebase
      .firestore()
      .collection("polls")
      .doc(`${contentId}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          // console.log("POLL DATA: ", doc.data())
          this.setState(
            {
              pollData: doc.data(),
              loading: false,
            },
            () => {
              this.setState({
                admin: doc.data().createdBy === currentUser.uid,
              });
            }
          );
        }
      });
    this.setState({ unsubscribePollData: subPollData })
  }
  componentWillUnmount() {
    if (this.state.unsubscribePollData) {
      this.state.unsubscribePollData()
    }
  }

  taskResponse = async (option) => {
    const {
      contentId,
      currentUser,
      currentChannel,
      darkTheme,
      currentWorkspace,
    } = this.props;

    let responseData = {
      userId: currentUser.uid,
      responseTime: Date.now(),
      optionValue: option,
    };

    // console.log("POLL RESPONSE: ", responseData)

    /* firebase
      .firestore()
      .collection("polls")
      .doc(`${contentId}`)
      .update({
        response: firebase.firestore.FieldValue.arrayUnion(responseData),
      });*/
    let response = await axios.post(`${API_BASE_URL}${POLL_SUBMIT}`, {
      user_id: currentUser.uid,
      room_id: currentChannel.roomId,
      workspace_id: currentWorkspace.workspaceId,
      poll_id: contentId,
      option_value: option,
    });
    //console.log(response);
    if (response.status === 200) {
      // console.log(response);
      if(darkTheme){
        toast.dark(response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
      }else{
        toast(response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
      }
      
    } else {
      //console.log(response);
    }
  };

  assignedToShow = () => {
    if(this.state.assignedShow){
      this.setState({assignedShow: false})
    }else{
      this.setState({assignedShow: true})
    }
  }

  render() {
    const { loading, pollData, admin } = this.state;

    const {
      currentUser,
      contentId,
      open_task_branch,
      open_task_id,
      item,
      colorTheme,
      componentName,
    } = this.props;
    /* const handleTimeDiff = () => {
      const d = moment.duration(
        moment(new Date(pollData.previousDeadline), "DD/MM/YYYY HH:mm:ss").diff(
          moment(new Date(pollData.deadline), "DD/MM/YYYY HH:mm:ss")
        )
      );
      return (
        d.days() +
        " day : " +
        d.hours() +
        " hours : " +
        d.minutes() +
        " minutes "
      );
    };
*/
    return (
      <div
        className={colorTheme ? `${colorTheme} taskbox` : "taskbox text-theme"}
      >
        <div className="taskBox">
          <div className="poll">
            <div className="d-flex">
              <h5
                className="mt-0 flex-grow-1"
                // style={{ width: "300px" }}
                style={
                  componentName === "VideoChat"
                    ? { minWidth: "200px" }
                    : { minWidth: "300px" }
                }
              >
                {loading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                    pollData.title
                  )}
              </h5>
              <div>
                {pollData.deadline < Date.now() ? (
                  <>
                    <span className="text-secondary small">
                      {pollData.closedBy ? (
                        <>
                          Closed by{" "}
                          <Participants
                            userId={pollData.closedBy}
                            nameOnly={true}
                          />
                        </>
                      ) : (
                          "Closed"
                        )}
                    </span>
                  </>
                ) : (
                    <span className="text-success small">Active</span>
                  )}
              </div>
              {open_task_branch === "saved" && open_task_id === item.uid && (
                <div className="mx-1">
                  <Badge variant="success">Saved</Badge>
                </div>
              )}
            </div>
            {pollData.closedBy && pollData.previousDeadline && (
              <div className="text-danger small mb-1">
                <>
                  <span className="text-warning">
                    Poll closed{" "}
                    {getTimeDifference(
                      pollData.previousDeadline,
                      pollData.deadline
                    )}{" "}
                    before actual deadline.
                  </span>
                </>
              </div>
            )}

            {admin ? (
              <PollAdmin {...this.props} pollData={pollData} />
            ) : (
                <PollUser
                  {...this.props}
                  pollData={pollData}
                  taskResponse={this.taskResponse}
                />
              )}

            {/* {loading
                            ? <Spinner animation="border" size="sm" />
                            : <p>{JSON.stringify(pollData)}</p>} */}
          </div>
        </div>
          
        {admin? (
          this.props.view === 2 ? (
            <>
              <div className="mt-2 d-flex justify-content-between">
                <div variant={this.props.darkTheme ? "dark" : "light"} className="mt-3">Assigned To </div>
                <div className="text-primary mt-3 pointer" onClick={this.assignedToShow}>{this.state.assignedShow ? "Hide(-)" : "Show(+)"}</div>
              </div>
              {this.state.assignedShow ? (
              <>
              {/*console.log(currAssignment,"respoxx")*/}
                <AssignedUsers
                  taskData={pollData}
                />
                {/*this.state.assignedToName.map((name, key) => <div className="text-light">{`${key+1}. ${name}`}</div>)*/}
              </>
              ) : null
              }
            </>
          ): null
        ): null}

        {/* {Object.keys(this.props).map((prop, key) => <p>{prop}: {JSON.stringify(this.props[prop])}</p>)} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,

  darkTheme: state.platform.darkTheme,

  currentUser: state.user.currentUser,
  open_task_branch: state.platform.open_task_branch,
  open_task_id: state.platform.open_task_id,
});

export default connect(mapStateToProps)(Poll);
