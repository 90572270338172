import React, { useEffect, useState, useRef } from 'react';
import { loadState, saveState } from '../../services/index';
import { Form, Button, Spinner, InputGroup, Dropdown, DropdownButton, Card} from 'react-bootstrap';
import firebase from 'firebase';
import { Link } from 'react-router-dom';
import { postData } from '../../utils/index';
import { API_BASE_URL, USER_EXISTS } from '../../config/index'
import * as DeviceDetect from "react-device-detect";
import { connect } from 'react-redux';
import { browserName, CustomView, osName } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import {
    setCurrentWorkspace,
    setWorkspaces
} from '../../actions/workspace'


const Start = (props) => {
    const history = useHistory();
    const [buttonVisible, setButtonVisible] = useState(false);
    
    useEffect(() => {
        if (props.currentUser) {
            firebase.firestore().collection(`users/${props.currentUser.uid}/rooms`).get().then((querySnapshot) => {
                if(querySnapshot.docs.length !== 0) {
                    // user is a part of atleat one workspace
                    setButtonVisible(false);
                    history.push('/');
                }else {
                    setButtonVisible(true);
                }
            })
        }
    },[props.currentUser])

    const continueAsFreeUserHandler = async () => {
        // firebase.firestore().collection("users").doc(props.currentUser.uid).collection('rooms').doc('LgT2sjzoZQwwKMGPnob2').set({
        //     rooms: [],
        //     workspaceId: 'LgT2sjzoZQwwKMGPnob2'
        // })

        // setting igesia as default workspace

        let docRef = firebase.firestore().collection(`workspaces`).doc("LgT2sjzoZQwwKMGPnob2")
        await docRef.get()
            .then(function (doc) {
                if (doc.exists) {
                    const workspacedata = doc.data();
                    firebase
                        .firestore()
                        .collection(`users/${props.currentUser.uid}/rooms`)
                        .onSnapshot(function (snapshot) {
                            let userWorkspaces = [];
                            snapshot.forEach(function (doc) {
                                userWorkspaces.push({ ...doc.data() })
                            })
                            //  traversing through the workspaces and setting the workspace which has workspaceId === workspaceId from url
                            let workspaceRooms;
                            userWorkspaces.forEach((workspace) => {
                                if (workspace.workspaceId === "LgT2sjzoZQwwKMGPnob2") {
                                    workspaceRooms = workspace;
                                }
                            })

                            saveState("defaultWorkspace", { ...workspacedata, ...workspaceRooms });


                            // setting workspace data in redux
                            props.setCurrentWorkspace({ ...workspacedata, ...workspaceRooms })
                        })

                } else {
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            })


    }


    return (

        <>
            {
                props.currentUser ?
                    <div className="container-fluid" style={{ minHeight: "100vh", background: "#18191a" }}>
                        <div className="container">
                            <div className="row pt-5 justify-content-center">
                                <div className="col-md-4 pt-5 text-center">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-reverse.png?alt=media&token=93df9989-4eab-406f-8122-ada051558941"
                                        width="200" />
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="text-center mt-3 row w-100">
                                    <Card className="mx-auto">
                                        <Card.Body>
                                            <h6>Contact sales</h6>
                                        </Card.Body>
                                    </Card>
                                </div>
                                {
                                    buttonVisible ?
                                    // conditional rendering cause if the user who is already in atleast one workspace/room visits this page manually and later network gets disconnected they may not get redirected back to '/' and may click this button which will erase all of their rooms
                                        <div className="text-center mt-3 row w-100" style={{cursor: 'pointer'}} onClick={() => continueAsFreeUserHandler().then(() => {
                                            setTimeout(() => history.push('/'), 1000) 
                                        })}>
                                            <Card className="mx-auto">
                                                <Card.Body>
                                                    <h6>Continue to Igesia as a free user</h6>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                        : null
                                }

                            </div>

                        </div >
                    </div >
                    : null
            }
        </>


    );
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
})

export default connect(mapStateToProps, {
    setCurrentWorkspace
})(Start);
