import React from "react";
import windowSize from "react-window-size";
import Rooms from "./Rooms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { setLeftPanel } from "../../actions/index";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

class Left extends React.Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    this.updateWindowDimensions();
    //    this.updateSidebarState();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.updateWindowDimensions.bind(this)
    );
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  updateSidebarState() {
    if (window.innerWidth < 767) {
      this.props.setLeftPanel(false);
    }
  }

  toggleSidebar = () => {
    this.props.setLeftPanel(!this.props.displayLeftPanel);
  };
  render() {
    const {
      currentUser,
      displayLeftPanel,
      setLeftActionType,
      leftActionType,
    } = this.props;

    // if (isMobile) {
    //   this.props.setLeftPanel(!this.props.displayLeftPanel)
    // }

    return (
      <>
        {/* Browser View */}
        <div id="left" className={displayLeftPanel ? "open" : "closed"}>
          <BrowserView>
            {/* <div className='icon'
          onClick={this.toggleSidebar} >
            <FontAwesomeIcon icon={faBars} />
        </div> */}
          </BrowserView>
          <MobileView>
            <div className="icon" onClick={this.toggleSidebar}>
              <FontAwesomeIcon icon={faChevronLeft} size="lg" />
            </div>
          </MobileView>
          <div className={`sidebar ${displayLeftPanel ? "open" : "closed"}`}>
            <Rooms
              setLeftActionType={setLeftActionType}
              currentUser={currentUser}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  displayLeftPanel: state.platform.displayLeftPanel,
});

export default connect(mapStateToProps, { setLeftPanel })(windowSize(Left));
