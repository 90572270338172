import React, { Component } from 'react'
import firebase from 'firebase'
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

class PendingRoomListElement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roomData: {},
            loadingRoomData: true,
            workspaceData: {},
            loadingWorkspaceData: true
        }
    }

    async componentDidMount() {
        const { roomId, workspaceId } = this.props;

        let workspaceDocRef = await firebase.firestore().collection(`workspaces`).doc(`${workspaceId}`)
        let workspace_data = await workspaceDocRef.get().then(doc => {
            if (doc.exists) {
                const workspaceData = doc.data()
                return workspaceData
            } else {
                return null;
            }
        }).catch(error => console.error(error))

        let roomDocRef = await firebase.firestore().collection(`workspaces/${workspaceId}/rooms`).doc(`${roomId}`)
        let room_data = await roomDocRef.get().then(doc => {
            if (doc.exists) {
                const roomData = doc.data()
                return roomData
            } else {
                return null;
            }
        }).catch(error => console.error(error))

        this.setState({
            roomData: room_data,
            loadingRoomData: false,
            workspaceData: workspace_data,
            loadingWorkspaceData: false
        })
    }


    render() {
        const { roomData, loadingRoomData, workspaceData, loadingWorkspaceData } = this.state;
        const { roomPic, roomName, roomType } = roomData;
        const { workspaceName } = workspaceData;

        return (
            <React.Fragment>
                {(loadingRoomData || loadingWorkspaceData)
                    ? <Spinner animation="border" />
                    : <li onClick={() => alert("Your invitation is pending acceptance by admin.")}
                        className="rounded-lg py-1 m-1 bg-card-theme shadow-sm text-theme">
                        <div className="d-flex align-items-center p-2 w-100">
                            <img
                                className="mr-2 rounded-circle"
                                src={roomPic}
                                style={{ height: 45, width: 45 }} />

                            <div className="flex-grow-1">
                                <p className="text-theme mb-0">
                                    {(roomType === 1) && <FontAwesomeIcon className="text-success mr-2" icon={faLock} style={{ fontSize: 12 }} />}
                                    {roomName}
                                    {/* ({workspaceName}) */}
                                </p>
                                <span className="text-white bg-primary smaller font-weight-bold mr-2" style={{ whiteSpace: "nowrap", borderRadius: "2px", padding: "3px 3px 3px 3px" }}>
                            PENDING ACCEPTANCE</span>
                            </div>
                        </div>
                        <div className="text-right align-self-start">

                        </div>
                    </li>}
            </React.Fragment>
        )
    }
}

export default PendingRoomListElement;