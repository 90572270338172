import React from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import IsAdmin from "../../IsAdmin";

const AllSchedules = ({
  showSelectedSchedule,
  CustomToggle,
  allSchedules,
  shareTaskAgain,
  editPublishedTask,
  closeAppResponses,
}) => {
  return (
    <div>
      <p className="rounded-lg p-2 m-2 border-1 text-theme">Schedules</p>

      {allSchedules.map((Schedule, index) => (
        <div
          key={index + Schedule.id}
          className={
            Schedule.deadline > Date.now()
              ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
              : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
          }
        >
          <div
            className="d-flex justify-content-between mb-1 pointer"
            onClick={() => showSelectedSchedule(Schedule.id)}
          >
            <span className="text-theme-lighter smaller">
              {moment(Schedule.timestamp).format("LLL")}
            </span>
            <span
              className={
                Schedule.deadline > Date.now()
                  ? "text-success smaller"
                  : "text-secondary smaller"
              }
            >
              {Schedule.deadline > Date.now() ? "Active" : "Closed"}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <p
              className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
              onClick={() => showSelectedSchedule(Schedule.id)}
            >
              {Schedule.scheduleTitle}
            </p>
            <IsAdmin>
              <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                <Dropdown.Toggle size="sm" as={CustomToggle} />
                <Dropdown.Menu className="bg-card-theme text-theme">
                  <Dropdown.Item
                    className="small bg-card-theme text-theme"
                    onClick={() => shareTaskAgain(Schedule)}
                  >
                    Duplicate{" "}
                    {/*<small className="text-warning">Coming Soon</small>*/}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="small bg-card-theme text-theme"
                    onClick={() => editPublishedTask(Schedule)}
                  >
                    Edit{" "}
                    {/*<small className="text-warning">Coming Soon</small>*/}
                  </Dropdown.Item>
                  {Schedule.deadline > Date.now() ? (
                    <Dropdown.Item
                      className="small bg-card-theme text-theme"
                      onClick={() => closeAppResponses(Schedule)}
                    >
                      Close {" "}
                    </Dropdown.Item>
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>
            </IsAdmin>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllSchedules;
