import React, { Component } from "react";
import Chart from "chart.js";
class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidUpdate() {
    //this.myChart.data.labels = this.props.data.map((d) => d.time);
    this.myChart.data.labels = this.props.data.map((d) => d.label);
    this.myChart.data.datasets[0].data = this.props.data.map((d) => d.value);
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.chartRef.current, {
      type: "line",
      data: {
        labels: this.props.data.map((d) => d.label),
        datasets: [
          {
            label: this.props.title,
            data: this.props.data.map((d) => d.value),
            fill: "none",
            backgroundColor: this.props.color,
            pointRadius: 5,
            borderColor: "white",
            borderWidth: 2,
            lineTension: 2,
          },
        ],
      },
    });
  }
  render() {
    return <canvas ref={this.chartRef} />;
  }
}
export default LineChart;
