import React from 'react';
import firebase from 'firebase';
import {
    Image,
    Badge,
    InputGroup,
    Form,
    Button,
    OverlayTrigger,
    Popover,
    NavDropdown,
    Dropdown,
    DropdownButton,
    ListGroup,
    Modal
} from "react-bootstrap";
import { connect } from 'react-redux';
import UserProfileModal from './UserProfileModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
    setCurrentChannel,
    setPrivateChannel,
    updateCurrentChannel,
    setLeftPanel,
    setCurrentWorkspace,
    setRoomsVisible,
    setAllMessages,
    setRightPanel,
    setParticipants,
    setUser,
    setChannels,
    setWorkspaces,
    setWorkspaceSlug
} from "../../actions";
import { withRouter } from "react-router-dom";

class GuestUserConversionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            convertEmail: "",
            convertPassword: "",
            currentConversionStep: 0
        };
    }

    convertToPermaAccountHandler = (firstName, lastName, email, password) => {
        var credential = firebase.auth.EmailAuthProvider.credential(email, password);
        firebase.auth().currentUser.linkWithCredential(credential)
            .then((usercred) => {
                var user = usercred.user;
                firebase.firestore().collection('users').doc(this.props.currentUser.uid).update({
                    isAnonymous: false,
                    isConvertedFromGuest: true,
                    conversionTimestamp: new Date(),
                    firstName: firstName,
                    lastName: lastName,
                    email: email
                }).then(() => {
                    this.setState({ showConversionModal: false, convertEmail: "", convertPassword: "" })
                }).then(() => {
                    alert("You have been signed out. Please signin with email and password")
                    this.handleSignout();
                })
                console.log("Anonymous account successfully upgraded", user);
            }).catch((error) => {
                console.log("Error upgrading anonymous account", error);
                alert(error)
            });
    }

    handleSignout = async () => {
        const {
            setCurrentWorkspace,
            setCurrentChannel,
            setAllMessages,
            setParticipants,
            setWorkspaces,
            setUser,
            clearUser,
            currentUser,
            setChannels,
            setRightPanel,
            setWorkspaceSlug
        } = this.props;

        firebase
            .auth()
            .signOut()
            .then(() => {

                // messaging/ notifications
                // const messaging = firebase.messaging();
                // messaging.requestPermission()
                //     .then(() => {
                //         console.log("have permission")
                //         return messaging.getToken();
                //     })
                //     .then((token) => {
                //         firebase.firestore().collection("users")
                //             .doc(currentUser.uid).get().then((doc) => {
                //                 if (doc.exists) {
                //                     const previousDeviceInfo = doc.data().deviceInfo;
                //                     const index = previousDeviceInfo.fcmTokenWeb.indexOf(token);
                //                     previousDeviceInfo.fcmTokenWeb.splice(index, 1);
                //                     firebase.firestore().collection("users")
                //                         .doc(currentUser.uid).update({
                //                             deviceInfo: {
                //                                 ...previousDeviceInfo,
                //                                 fcmTokenWeb: previousDeviceInfo.fcmTokenWeb
                //                             }
                //                         })
                //                 }
                //             })
                //     }).catch((error) => {

                //     })
                setRightPanel(false);
                setCurrentWorkspace(null);
                setCurrentChannel(null);
                setAllMessages(null);
                setParticipants(null);
                setWorkspaces(null);
                setUser(null);
                setChannels(null)
                setWorkspaceSlug(null)
                window.localStorage.removeItem("defaultWorkspace");
                // window.location.replace("/");

                // window.sessionStorage.removeItem("loginToken")
                // window.sessionStorage.removeItem("loginTokenTimestamp")
                // window.sessionStorage.removeItem("referenceLoginToken")
                // window.sessionStorage.removeItem("referenceLoginTokenTimestamp")
                console.log("signed out!");
            });
    };


    render() {
        return (
            <>
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.props.show}
                >
                    <Modal.Header className="bg-theme border-0 d-flex justify-content-between align-items-center pb-0">
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h5 className="text-theme">Enter details</h5>
                        </Modal.Title>
                        <div title="Close" className="pointer" onClick={() => this.setState({ convertEmail: "", convertPassword: "" }, () => this.props.onHide())}>
                            <FontAwesomeIcon className="text-theme" icon={faTimes} />
                        </div>
                    </Modal.Header>
                    <Modal.Body
                        className="overflow-auto list-unstyled bg-theme pt-0"
                    >


                        {
                            !this.state.currentConversionStep ?

                                <>

                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your first name"
                                        value={this.state.firstName}
                                        name="displayName"
                                        onChange={(e) =>
                                            this.setState({ firstName: e.target.value })
                                        }
                                        className="rounded-1 my-2"
                                    />

                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your last name"
                                        value={this.state.lastName}
                                        name="displayName"
                                        onChange={(e) =>
                                            this.setState({ lastName: e.target.value })
                                        }
                                        className="rounded-1 my-2"
                                    />
                                    <p className="text-danger small">{this.state.nameError ? this.state.nameError : null}</p>
                                </>

                                :
                                <>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter your email"
                                        value={this.state.convertEmail}
                                        name="email"
                                        onChange={(e) =>
                                            this.setState({ convertEmail: e.target.value })
                                        }
                                        className="rounded-1 my-2"
                                    />

                                    <Form.Control
                                        type="password"
                                        placeholder="Enter your password"
                                        value={this.state.convertPassword}
                                        name="displayName"
                                        onChange={(e) =>
                                            this.setState({ convertPassword: e.target.value })
                                        }
                                        className="rounded-1 my-2"
                                    />
                                    <p className="text-danger small">{this.state.emailError ? this.state.emailError : null}</p>
                                </>
                        }


                        <div>
                            {
                                !this.state.currentConversionStep ?
                                    <Button
                                        variant="primary"
                                        onClick={() => {
                                            if (this.state.currentConversionStep === 0) {
                                                if (this.state.firstName === "" || this.state.firstName === undefined) {
                                                    this.setState({ nameError: "Enter your first name." })
                                                    return
                                                } else if (this.state.firstName.replace(/\s/g, "").length === 0) {
                                                    this.setState({ nameError: "Enter valid first name." })
                                                    return
                                                } else if (this.state.lastName === "" || this.state.lastName === undefined) {
                                                    this.setState({ nameError: "Enter your last name." })
                                                    return
                                                } else if (this.state.lastName.replace(/\s/g, "").length === 0) {
                                                    this.setState({ nameError: "Enter valid last name." })
                                                    return
                                                } else {
                                                    this.setState({ currentConversionStep: 1 })
                                                    this.setState({ nameError: "" })
                                                }
                                            }
                                        }}
                                        className="btn-sm">Next</Button> :
                                    <div className="d-flex justify-content-between">
                                        <Button variant="primary" onClick={() => this.setState({ currentConversionStep: 0 })} className="btn-sm">Back</Button> :
                  <Button
                                            //this.convertToPermaAccountHandler(this.state.firstName, this.state.lastName, this.state.convertEmail, this.state.convertPassword)
                                            variant="primary"
                                            onClick={() => {
                                                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.convertEmail)) {
                                                    if (this.state.convertPassword.length < 6) {
                                                        this.setState({ emailError: "Password must have at least 6 characters." })
                                                        return;
                                                    } else {
                                                        this.setState({ emailError: "" })
                                                        this.convertToPermaAccountHandler(this.state.firstName, this.state.lastName, this.state.convertEmail, this.state.convertPassword)
                                                    }
                                                } else {
                                                    this.setState({ emailError: "Enter a valid email address." })
                                                }
                                            }}
                                            className="btn-sm">Submit</Button>
                                    </div>
                            }
                        </div>

                    </Modal.Body>
                </Modal>
            </>
        )
    }

}

const mapStateToProps = (state) => ({
    darkTheme: state.platform.darkTheme,
    currentChannel: state.channel.currentChannel,
    displayLeftPanel: state.platform.displayLeftPanel,
    currentUser: state.user.currentUser,
    currentWorkspace: state.workspace.currentWorkspace,
    workspaces: state.workspace.workspaces,
    roomsVisible: state.workspace.roomsVisible,
    showUploadFilesModal: state.uploadFilesModal.showUploadFilesModal,
    showCreateFolderModal: state.createFolderModal.showCreateFolderModal,
  });


export default withRouter(
  connect(mapStateToProps, {
    setCurrentChannel,
    setAllMessages,
    setPrivateChannel,
    updateCurrentChannel,
    setLeftPanel,
    setCurrentWorkspace,
    setRoomsVisible,
    setRightPanel,
    setParticipants,
    setUser,
    setChannels,
    setWorkspaces,
    setWorkspaceSlug
  })(GuestUserConversionModal)
);
