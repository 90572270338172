import React, { useState, useEffect} from "react";
import uuidv4 from "uuid/v4";
import mime from "mime-types";
import firebase from "../../firebase";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt,faTimes, faPaperPlane, faFilePdf, faLink, faFileWord, faFileExcel, faFilePowerpoint, faExclamationCircle, faImage, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { ProgressBar } from "react-bootstrap";
import TextareaAutosize from 'react-textarea-autosize';
import { isMobile } from 'react-device-detect';
import { API_BASE_URL, DRIVE_FILE, DRIVE_UPLOAD, SEND_MESSAGE, META_TAGS } from "../../config/index";
import axios from "axios";
import {
    FormControl,
    Button,
    InputGroup,
    DropdownButton,
    Dropdown,
    Form,
    Modal
} from "react-bootstrap";
import { connect } from "react-redux";
import { setReplyId } from "../../actions/index";
import ReplyTemplate from "./ReplyTemplate";
import IsAdmin from "../IsAdmin";
import * as linkify from 'linkifyjs';
import Linkify from 'linkifyjs/react';
import URLPreviewTemplate from "./URLPreviewTemplate";
import _ from 'lodash';

function AddImage({ content, handleContent, handleMetaData, handleType, percentUploaded, addFile, sendFile }) {
    const [show, setShow] = useState(false);
    const [preview, setPreview] = useState(null);
    const [allowedType, setAllowedType] = useState(false);
    const [metaData, setMetaData] = useState(null);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        metaData && allowedType && handleMetaData(metaData);
        if (metaData && allowedType && image_type.includes(metaData.type)) handleType("Image");
        if (metaData && allowedType && (word_type.includes(metaData.type) || pdf_type.includes(metaData.type) || powerpoint_type.includes(metaData.type) || excel_type.includes(metaData.type))) handleType("File");
    }, [metaData, allowedType]);

    useEffect(() => {
        sending && (percentUploaded === 100) &&
            setTimeout(() => {
                handleClose();
                setPreview(null);
                setAllowedType(false);
                setMetaData(null);
                setSending(false);
                handleContent("");
            }, 1500);
    }, [sending, percentUploaded]);

    const image_type = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/gif"];

    const pdf_type = ["application/pdf"];

    const word_type = ["application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

    const excel_type = ["application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

    const powerpoint_type = ["application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"];

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleFileChange = event => {
        const file = event.target.files[0];
        const reader = new FileReader();

        if (file) {
            setMetaData({ lastModified: file.lastModified, lastModifiedDate: JSON.stringify(file.lastModifiedDate), name: file.name, size: file.size, type: file.type })
            setAllowedType((image_type.includes(file.type) || word_type.includes(file.type) || pdf_type.includes(file.type) || powerpoint_type.includes(file.type) || excel_type.includes(file.type)));
            reader.readAsDataURL(file);
            reader.addEventListener("load", () => {
                setPreview(reader.result);
            });
        }
    };

    return (
        <>
            <FontAwesomeIcon className="pointer" style={{cursor: "pointer"}} onClick={handleShow} icon={faPlusCircle} size="sm" className="actionboxicon mt-2 mr-1" />
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header className="bg-theme text-theme border-0">
                    <h3 className="mb-3">Add File</h3>
                    <div title="Close" className="pointer d-flex justify-content-end mb-0" onClick={() =>  handleClose() }>
                        <FontAwesomeIcon className="text-theme" icon={faTimes} />
                    </div>
                </Modal.Header>
                <Modal.Body className="bg-theme text-theme border-0">
                    {preview &&
                        <div>
                            {/* {metaData && <p>{JSON.stringify(metaData)}</p>} */}
                            {(!allowedType) && <small className="text-danger">Sorry! but this file type is not supported yet.</small>}
                            {allowedType && image_type.includes(metaData.type) &&
                                <div className="mb-3 mx-auto" style={{ height: "300px", width: "300px", overflow: "hidden" }}>
                                    <img src={preview} alt="icon" className="rounded w-100" />
                                </div>}

                            <div className="d-flex rounded bg-light mb-0">
                                <div className="py-2 px-3" style={{ backgroundColor: "#FEF2E4" }}>
                                    {(!allowedType) && <FontAwesomeIcon icon={faExclamationCircle} className="text-secondary" />}
                                    {image_type.includes(metaData.type) && <FontAwesomeIcon icon={faImage} className="text-secondary" />}
                                    {word_type.includes(metaData.type) && <FontAwesomeIcon icon={faFileWord} className="text-primary" />}
                                    {pdf_type.includes(metaData.type) && <FontAwesomeIcon icon={faFilePdf} className="text-danger" />}
                                    {powerpoint_type.includes(metaData.type) && <FontAwesomeIcon icon={faFilePowerpoint} className="text-danger" />}
                                    {excel_type.includes(metaData.type) && <FontAwesomeIcon icon={faFileExcel} className="text-success" />}
                                </div>
                                <div className="p-2 flex-grow-1">
                                    <small className="font-weight-bold text-secondary">{metaData.name}</small>
                                </div>
                                <div className="py-2 px-3">
                                    <FontAwesomeIcon icon={faLink} className="text-secondary" />
                                </div>
                            </div>
                            <div className="mb-3">
                                {sending &&
                                    <ProgressBar
                                        style={{ height: "0.2rem" }}
                                        now={percentUploaded}
                                        // label={`${percentUploaded} %`} 
                                        striped
                                        variant="success" />}
                            </div>

                            <InputGroup className="mb-3">
                                <FormControl
                                    placeholder="Add Content"
                                    aria-label="Add Content"
                                    name="content"
                                    onChange={e => handleContent(e.target.value)}
                                    required={true}
                                    value={content}
                                />
                            </InputGroup>
                        </div>}
                    <InputGroup className="mb-3">
                        <FormControl
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            onChange={e => { addFile(e); handleFileChange(e); }}
                            type="file"
                            name="previewImage"
                        />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer className="bg-theme text-theme border-0">
                    <Button disabled={!allowedType && true} color="red" onClick={() => { sendFile(); setSending(true); }}>Add</Button>
                    <Button color="red" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

class CommentBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            storageRef: firebase.storage().ref(),
            typingRef: firebase.database().ref("typing"),
            uploadTask: null,
            uploadState: "",
            percentUploaded: 0,

            message: "",
            content: "",
            metaData: {},
            type: "",

            channel: props.currentChannel,
            user: props.currentUser,
            loading: false,
            errors: [],
            modal: false,
            emojiPicker: false,
            file: null,
            isAdmin: false,
            authorized: [
                "image/jpeg",
                "image/png",
                "image/svg+xml",
                "image/gif",

                "application/pdf",

                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

                "application/vnd.ms-powerpoint",
                "application/vnd.openxmlformats-officedocument.presentationml.presentation"],
            firstLinkInMessage: null,
            metaTags: null,
            noURLPreview: false
        };

        this.messageInputRef = React.createRef();
    }

    componentDidMount() {
        // document.addEventListener("keydown", this.fancyKeyDown, false);
        this.getIsAdminHandler();
    }

    componentDidUpdate(prevProps, prevState) {

        // calling the cloud function to get preview of any link if it is in the text area
        if (prevState.message !== this.state.message && this.state.message.length !== 0 && linkify.find(this.state.message).length !== 0 && linkify.find(this.state.message)[0].type === "url" && !this.state.firstLinkInMessage && !this.state.noURLPreview) {
            console.log("first case", this.state.message)
            this.setState({ firstLinkInMessage: linkify.find(this.state.message)[0].href }, () => {
                axios.post((`${API_BASE_URL}${META_TAGS}`), {
                    url: this.state.firstLinkInMessage
                }).then((res) => {
                    this.setState({ metaTags: res.data.data.meta_data })
                })
            })
        }
        if (prevState.message !== this.state.message  && this.state.message.length !== 0 && linkify.find(this.state.message).length !== 0 && linkify.find(this.state.message)[0].type === "url" && this.state.firstLinkInMessage && !this.state.noURLPreview) {
            const debouncedFunc = _.debounce(() => {
                axios.post((`${API_BASE_URL}${META_TAGS}`), {
                    url: this.state.firstLinkInMessage
                }).then((res) => {
                    this.setState({ metaTags: res.data.data.meta_data })
                })
            }, 1500)
            this.setState({ firstLinkInMessage: linkify.find(this.state.message)[0].href }, () => {
                debouncedFunc()
            })
        }
        if (this.state.message.length === 0 && this.state.metaTags) {
            this.setState({ metaTags: null, firstLinkInMessage: null, noURLPreview: false })
        }
        if (prevState.message !== this.state.message && linkify.find(this.state.message).length === 0 && this.state.firstLinkInMessage) {
            this.setState({ firstLinkInMessage: null, metaTags: null })
        }
        if (prevState.message !== this.state.message && this.state.message.length === 0) {
            this.setState({ noURLPreview: false })
        }
    }

    componentWillUnmount(prevProps, prevState) {
        if (this.state.uploadTask !== null) {
            this.state.uploadTask.cancel();
            this.setState({ uploadTask: null });
        };
        if (prevProps?.currentChannel?.roomId !== this.props?.currentChannel?.roomId) {
            this.getIsAdminHandler();
        }
        // document.removeEventListener("keydown", this.fancyKeyDown, false);
    }

    // fancyKeyDown = event => {
    //     // if (event.keyCode === 27) {
    //     //     this.messageInputRef.current.blur()
    //     // } else {
    //     //     this.messageInputRef.current.focus()
    //     // };
        
    //     if (event.keyCode >= 48 && event.keyCode <= 90) {
    //         this.messageInputRef.current.focus()
    //     }
    // }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleKeyDown = event => {
        if (event.ctrlKey && event.keyCode === 13) {
            
            this.setState({message: this.state.message + "\n"});
            console.log("CTRL");

        } else if (event.shiftKey && event.keyCode === 13) {
            
            this.setState({message: this.state.message});
            console.log("SHIFT");

        } else if (event.keyCode === 13) {
            
            this.sendMessage();
            this.messageInputRef.current.blur();
            console.log("ENTER");
            
        } else {
        };

        const { message, typingRef, channel, user } = this.state;

        // if (message) {
        //     typingRef
        //         .child(channel.id)
        //         .child(user.uid)
        //         .set(user.displayName);
        // } else {
        //     typingRef
        //         .child(channel.id)
        //         .child(user.uid)
        //         .remove();
        // }
    };

    createMessage = (fileUrl = null) => {
        const { currentUser } = this.props;

        const message = {
            //timestamp: Date.now(),
            //createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            user: {
                id: currentUser.uid,
                name: currentUser.displayName,
                avatar: currentUser.photoURL
            },
        };
        if (this.props.replyId !== "") {
            message["replyId"] = this.props.replyId;
        }
        if (fileUrl !== null) {
            if (this.state.type === "File") {
                message["file"] = fileUrl;
                message["type"] = 7
            }
            if (this.state.type === "Image") {
                message["image"] = fileUrl;
                message["type"] = 6
            }
            // message["type"] = this.state.type;
            message["metaData"] = this.state.metaData;
            message["content"] = this.state.content;
        } else if (!this.state.metaTags) {
            message["content"] = this.state.message;
            message["type"] = 0;
        } else if (this.state.metaTags) {
            message["content"] = this.state.message;
            message["type"] = 12;
            message["metaTags"] = this.state.metaTags;
            message["url"] = this.state.firstLinkInMessage;
        }
        return message;
    };

    sendMessage = (e) => {
        const { currentChannel, currentWorkspace, setReplyId, currentUser } = this.props;
        const { message, channel, user, typingRef } = this.state;

        if (message) {
            this.setState({ loading: true });
            const db = firebase.firestore().collection(`workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`);
            const dbUpdate = firebase.firestore().collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

            const newMessage = this.createMessage();
            // db.add({ ...newMessage })
            // .then((docRef) => {
            //     dbUpdate
            //         .doc(`${currentChannel.roomId}`)
            //         .update({ 
            //             totalMessages: firebase.firestore.FieldValue.increment(1),
            //             lastMessage: { ...newMessage, uid: docRef.id } 
            //         })
            //     setReplyId("");
            //     this.setState({ loading: false, message: "", errors: [] });
            //     this.messageInputRef.current.focus();
            //     typingRef
            //         .child(channel.id)
            //         .child(user.uid)
            //         .remove();
            // })
            // .catch(err => {
            //     console.error(err);
            //     this.setState({
            //         loading: false,
            //         errors: this.state.errors.concat(err)
            //     });
            // });
            console.log(currentUser.uid, currentChannel.roomId, currentWorkspace.workspaceId, "called" )
            axios.post(`${API_BASE_URL}${SEND_MESSAGE}`, {
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                user_id: currentUser.uid,
                message: { ...newMessage }
              }).then(() => {
                setReplyId("");
                this.setState({
                  loading: false,
                  message: "",
                  errors: [],
                });
                this.messageInputRef.current.focus();
                // typingRef.child(channel.id).child(user.uid).remove();
              })


        } else {
            this.setState({
                errors: this.state.errors.concat({ message: "Add a message" })
            });
        }
    };

    getPath = () => {
        const { currentWorkspace, currentChannel } = this.props;

        if (this.props.isPrivateChannel) {
            return `chat/private/${this.state.channel.id}`;
        } else {
            return `chat/${currentWorkspace.workspaceId}/${currentChannel.roomId}`;
        }
    };

    uploadFile = (file, metadata) => {
        const pathToUpload = this.state.channel.id;
        const ref = this.props.currentChannel.roomId;
        const filePath = `${this.getPath()}/${uuidv4()}.${file.name.split(".").reverse()[0]}`;

        this.setState(
            {
                uploadState: "uploading",
                uploadTask: this.state.storageRef.child(filePath).put(file, metadata)
            },
            () => {
                this.state.uploadTask.on(
                    "state_changed",
                    snap => {
                        const percentUploaded = Math.round(
                            (snap.bytesTransferred / snap.totalBytes) * 100
                        );
                        console.log(percentUploaded);
                        this.setState({ percentUploaded });
                    },
                    err => {
                        console.error(err);
                        this.setState({
                            errors: this.state.errors.concat(err),
                            uploadState: "error",
                            uploadTask: null
                        });
                    },
                    () => {
                        this.state.uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then(downloadUrl => {
                                this.sendFileMessage(downloadUrl, ref, pathToUpload);
                            })
                            .catch(err => {
                                console.error(err);
                                this.setState({
                                    errors: this.state.errors.concat(err),
                                    uploadState: "error",
                                    uploadTask: null
                                });
                            });
                    }
                );
            }
        );
    };

    sendFileMessage = (fileUrl, ref, pathToUpload) => {
        // let newMessage = ref.child(pathToUpload).push();
        // let newMessageKey = newMessage.key;

        const { currentWorkspace, currentChannel, currentUser } = this.props;

        const db = firebase.firestore().collection(`workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`);
        const dbUpdate = firebase.firestore().collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

        const newMessage = this.createMessage(fileUrl)

     

        // db.add({ ...newMessage })
        //     .then((docRef) => {
        //         dbUpdate
        //             .doc(`${currentChannel.roomId}`)
        //             .update({ 
        //                 totalMessages: firebase.firestore.FieldValue.increment(1),
        //                 lastMessage: { ...newMessage, uid: docRef.id } 
        //             })
        //         // setReplyId("");
        //         // this.setState({ loading: false, message: "", errors: [] });
        //         // typingRef
        //         //     .child(channel.id)
        //         //     .child(user.uid)
        //         //     .remove();
        //     })
        //     .catch(err => {
        //         console.error(err);
        //         // this.setState({
        //         //     loading: false,
        //         //     errors: this.state.errors.concat(err)
        //         // });
        //     });

        // ref
        //     .child(pathToUpload)
        //     .child(newMessageKey)
        //     .set({ ...this.createMessage(fileUrl), uid: newMessageKey })
        //     .then(() => {
        //         this.setState({ uploadState: "done" });
        //     })
        //     .catch(err => {
        //         console.error(err);
        //         this.setState({
        //             errors: this.state.errors.concat(err)
        //         });
        //     });


        axios.post(`${API_BASE_URL}${SEND_MESSAGE}`, {
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            user_id: currentUser.uid,
            message: { ...newMessage }
        }).then(() => {
            this.setState({ content: "" });
        })
    };

    addFile = event => {
        const file = event.target.files[0];
        if (file) {
            this.setState({ file });
        }
    };

    sendFile = () => {
        const { file } = this.state;
        //        const { uploadFile, closeModal } = this.props;
        console.log('file recieved to send');
        if (file !== null) {
            console.log('file checked');
            if (this.isAuthorized(file.name)) {
                const metadata = { contentType: mime.lookup(file.name) };
                this.uploadFile(file, metadata);
                //                closeModal();
                this.clearFile();
            }
        }
    };

    isAuthorized = filename =>
        this.state.authorized.includes(mime.lookup(filename));

    clearFile = () => this.setState({ file: null });

    getMessageData = id => {
        let messageData = {};
        firebase.database().ref("messages/" + this.state.channel.id).child(id).on("value", snap => {
            messageData = snap.val();
        });
        return messageData;
    }

    getIsAdminHandler = async () => {
        const { currentChannel, currentWorkspace, currentUser } = this.props;
        if (currentChannel && currentWorkspace && currentUser.uid) {
            const query = firebase
                .firestore()
                .collection(
                    `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/participants`
                );
            query.onSnapshot((snapshot) => {
                console.log("admin changed")

                let json = [];
                snapshot.docChanges().map((s) => {
                    json.push({ ...s.doc.data(), id: s.doc.id });
                })

                let coAdmins = json.filter((participant) => participant.isAdmin);

                if (
                    currentChannel.createdBy === currentUser.uid ||
                    coAdmins.find((usr) => usr.id === currentUser.uid)
                ) {
                    this.setState({ isAdmin: true });
                } else {
                    this.setState({ isAdmin: false });

                }
            })


        }
    };

    render() {
        // prettier-ignore
        const { message, loading } = this.state;
        return (
            <>
            {
                this.props.currentChannel.roomConfig?.admin_only_chat && this.state.isAdmin ?
                    (
                        <Container className="p-2">
                            {/* <InputGroup className="mb-3">
                <FormControl
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default"
                onChange={this.addFile}
                type="file"
                label="New Avatar"
                name="previewImage"
                />
            </InputGroup> */}
                            {/* {this.props.replyId && this.getMessageData(this.props.replyId) && <div className="replybox">
                <ReplyTemplate message={this.getMessageData(this.props.replyId)} />
            </div>} */}
                                {this.state.metaTags && Object.keys(this.state.metaTags).length > 0 && (
                                    <div className="replybox text-theme">
                                        <URLPreviewTemplate onClose={() => { this.setState({ firstLinkInMessage: null, metaTags: null, noURLPreview: true }) }} message={{ metaTags: this.state.metaTags }} />                    </div>
                                )}
                            <Form onSubmit={e => this.sendMessage()}>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <AddImage
                                            content={this.state.content}
                                            handleContent={content => this.setState({ content })}
                                            handleMetaData={data => this.setState({ metaData: data })}
                                            handleType={type => this.setState({ type })}
                                            percentUploaded={this.state.percentUploaded}
                                            addFile={this.addFile}
                                            sendFile={this.sendFile}
                                        />
                                    </InputGroup.Prepend>

                                    {/* <FormControl aria-describedby="basic-addon1" aria-label="With textarea"
                        name="message"
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        value={message}
                        ref={node => (this.messageInputRef = node)}
                        id="messageinputbox"
                        placeholder="Type a message"
                    /> */}
                                    <TextareaAutosize
                                        name="message"
                                        className="form-control bg-theme text-theme-important border-0"
                                        onChange={this.handleChange}
                                        onKeyDown={this.handleKeyDown}
                                        value={message}
                                        inputRef={this.messageInputRef}
                                        style={{ fontSize: "14px", padding: "3px" }}
                                        id="messageinputbox"
                                        // placeholder="Type a message"
                                        placeholder={isMobile ? 'Type a message' : 'Type a message'}
                                        minRows={1}
                                        maxRows={2}
                                    />
                                    <InputGroup.Append>
                        <Button type="submit" variant="light" onClick={this.sendMessage} disabled={loading} style={{ background: "none", border: 0 }}>
                            <FontAwesomeIcon color="#0066cc" icon={faPaperPlane} />
                        </Button>
                    </InputGroup.Append>
                                    {/* <InputGroup.Append>
                        <Button variant="light" style={{ background: "none", border: 0 }}>
                            <FontAwesomeIcon color="#0066cc" icon={faExclamationCircle} />
                        </Button>
                    </InputGroup.Append> */}
                                </InputGroup>
                                
                                
                            </Form>
                        </Container>
                    )
                    : 

                    this.props.currentChannel.roomConfig?.admin_only_chat && !this.state.isAdmin ?
                    (<>
                    <div style={{height: "var(--footer-height)"}} className="bg-theme text-theme d-flex align-items-center justify-content-center">
                      <span>
                      Only admins can send messages
                      </span>
                    </div>
                  </>) :
                    (<Container className="p-2">
                        {/* <InputGroup className="mb-3">
            <FormControl
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            onChange={this.addFile}
            type="file"
            label="New Avatar"
            name="previewImage"
            />
        </InputGroup> */}
                        {/* {this.props.replyId && this.getMessageData(this.props.replyId) && <div className="replybox">
            <ReplyTemplate message={this.getMessageData(this.props.replyId)} />
        </div>} */}
                                {this.state.metaTags && Object.keys(this.state.metaTags).length > 0 && (
                                    <div className="replybox text-theme">
                                        <URLPreviewTemplate onClose={() => { this.setState({ firstLinkInMessage: null, metaTags: null, noURLPreview: true }) }} message={{ metaTags: this.state.metaTags }} />                    </div>
                                )}
                        <Form onSubmit={e => {
                            e.preventDefault();
                            this.sendMessage();
                        }}>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <AddImage
                                        content={this.state.content}
                                        handleContent={content => this.setState({ content })}
                                        handleMetaData={data => this.setState({ metaData: data })}
                                        handleType={type => this.setState({ type })}
                                        percentUploaded={this.state.percentUploaded}
                                        addFile={this.addFile}
                                        sendFile={this.sendFile}
                                    />
                                </InputGroup.Prepend>

                                {/* <FormControl aria-describedby="basic-addon1" aria-label="With textarea"
                    name="message"
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    value={message}
                    ref={node => (this.messageInputRef = node)}
                    id="messageinputbox"
                    placeholder="Type a message"
                /> */}
                                <TextareaAutosize
                                    name="message"
                                    className="form-control bg-theme text-theme-important border-0"
                                    onChange={this.handleChange}
                                    onKeyDown={this.handleKeyDown}
                                    value={message}
                                    inputRef={this.messageInputRef}
                                    style={{ fontSize: "14px", padding: "3px" }}
                                    id="messageinputbox"
                                    // placeholder="Type a message"
                                    placeholder={isMobile ? 'Type a message' : 'Type a message'}
                                    minRows={1}
                                    maxRows={2}
                                />
                                <Button className="ml-2" variant="light" style={{ background: "none", border: 0 }} onClick={() => this.sendMessage()}>
                                    <FontAwesomeIcon color="#0066cc" icon={faPaperPlane}/>
                                </Button>
                                {/* <InputGroup.Append>
                    <Button type="submit" variant="light" onClick={this.sendMessage} disabled={loading} style={{ background: "none", border: 0 }}>
                        <FontAwesomeIcon color="#0066cc" icon={faPaperPlane} />
                    </Button>
                </InputGroup.Append> */}
                                {/* <InputGroup.Append>
                    <Button variant="light" style={{ background: "none", border: 0 }}>
                        <FontAwesomeIcon color="#0066cc" icon={faExclamationCircle} />
                    </Button>
                </InputGroup.Append> */}
                                
                            </InputGroup>
                            
                        </Form>
                    </Container>)
            }
            </>

        );
    }
}

const mapStateToProps = state => ({
    replyId: state.platform.replyId,
    currentWorkspace: state.workspace.currentWorkspace,
    currentChannel: state.platform.channelDetails ? state.platform.channelDetails  : {},
    currentUser: state.user.currentUser
})

export default connect(mapStateToProps, { setReplyId })(CommentBox);