import React from "react";
import {
  Button,
  Form,
  InputGroup,
  ProgressBar,
  Spinner,
  Badge,
  FormControl,
} from "react-bootstrap";
import firebase from "firebase";
import uuidv4 from "uuid/v4";
import mime from "mime-types";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import ReadMore from "../../Middle/ReadMore";
import AssignedUsers from "../Common/AssignedUsers";
import DisplayResponse from "./DisplayResponse1";
import { trackPromise } from "react-promise-tracker";
import LoadingIndicator from "./LoadingIndicator";
import { connect } from "react-redux";
import { publishRemark, publishMarks } from "./actions";
import Loader from "react-loader-spinner";
import Participants from "../../Right/UserNameAvatar";
import { getTimeDifference } from "../Common/TimeDifference";

import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faTimes,
  faCloudUploadAlt,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  API_BASE_URL,
  DRIVE_FILE,
  DRIVE_UPLOAD,
  ASSIGNMENT_CREATE,
  ASSIGNMENT_REMOVE,
  ASSIGNMENT_SHARE,
  ASSIGNMENT_SUBMIT,
  ASSIGNMENT_UPDATE,
  ASSIGNMENT_ANALYTICS,
} from "../../../config/index";

class Assignment extends React.Component {
  state = {
    assignedShow: false,
    storageRef: firebase.storage().ref(),
    uploadTask: null,
    uploadState: [],
    percentUploaded: [],
    message: "",
    loading: false,
    errors: [],
    files: [],
    filesWithBase64: [],
    currAssignment: {},
    reqResolved: false,
    authorized: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/svg+xml",
      "image/gif",

      "application/pdf",

      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ],
    value: "",
    extension: "",
    admin: false,
    responses: [],
    answers: [],
    links: [],
    submit: false,
    isClosed: false,
    responded: null,
    unsubscribeAssignmentData: null
  };

  timeFromNow = (timestamp) => {
    if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
      return moment(timestamp).fromNow();
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
      return "yesterday";
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
      return moment(timestamp).format("dddd");
    else return moment(timestamp).format("MMM DD YYYY");
  };

  async componentDidMount() {
    const { user, item, setIsClosed } = this.props;
    try {
      const assignmentData =  firebase
        .firestore()
        .collection("assignments")
        .doc(item.contentId)
        .onSnapshot((doc) => {
          // console.log(doc.data());
          if (doc.data()) {
            let data = { ...doc.data(), id: doc.id };
            //console.log(data, "ass in")
            this.setState({
              currAssignment: data,
              //responses: doc.data() ? [...doc.data().response] : [],
            });
            //console.log(data.assignedTo,"respoxx")
  
          }

          if (doc.data().previousDeadline || doc.data().deadline < Date.now().valueOf()) {
            this.setState({ isClosed: true })
            //setIsClosed(true);
          }
          let response = [...this.state.responses];
         // console.log(doc.id,"ass in the")
          const query = firebase
            .firestore()
            .collection(`/assignments/${doc.id}/responses`);
           query.onSnapshot((snapshot) => {
            //console.log(snapshot.docChanges(),"ass in the");
            let changes = snapshot.docChanges();
           // console.log(changes,"ass in the");
            changes.forEach((change) => {
              
                if (change.doc.exists) {
                  let data = change.doc.data();
                 // console.log(data, "ass in the data")
                  let json = { ...data, assignment_id: doc.id, id: change.doc.id };
                  let response = [...this.state.responses, { ...json }];
                  this.setState({responses:response})
                  //setResponses(response);
  
                  axios.post(`${API_BASE_URL}${ASSIGNMENT_ANALYTICS}`, {
                    user_id: this.props.currentUser.uid,
                    workspace_id: this.props.currentWorkspace.workspaceId,
                    room_id: this.props.currentChannel.roomId,
                    published_assignment_id: doc.id,
                  }).then((res) => {
                    //console.log(res.data.data.userResponded,"respoxx")
                    this.setState({responded: res.data.data.userResponded})
                  })
              }
            });
          });
        });
      this.setState({ unsubscribeAssignmentData: assignmentData });

      }
         catch (error) {
      console.log(error);
    }
    //console.log(user);
    //console.log(item);
    if (user == item.user.id) {
      this.setState({ admin: true });
      // item.response && this.getResponses(item.response)
    }
}

  componentWillUnmount() {
    if (this.state.uploadTask !== null) {
      this.state.uploadTask.cancel();
      this.setState({ uploadTask: null });
    }
    if (this.state.unsubscribeAssignmentData) {
      this.state.unsubscribeAssignmentData()
    }
  }

  strip = (value) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = value.match(regExp);
    return match && match[2].length === 11 ? (
      <iframe
        style={{ borderRadius: "4px" }}
        width="100%"
        height="170"
        src={"//www.youtube.com/embed/" + match[2]}
        frameborder="0"
        allowfullscreen
      ></iframe>
    ) : // : <ReadMore full_text={value} max_length="250" />;
    value.length > 50 ? (
      value.slice(0, 50) + "…"
    ) : (
      value
    );
    // : value.length > 50 ? <ReadMore full_text={value} max_length="250" /> + '…' : value;
  };

  /*getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };*/
  addFile = (event, key) => {
    const { filesWithBase64, files } = this.state;
    let prev = [...files];
    let prevFiles = [...filesWithBase64];
    let file = event.target.files[0];

    if (event.target.files[0]) {
      prev[key] = file;
      this.setState({ files: prev });
      {
        /* let result = this.getBase64(event.target.files[0]);
      result.then((data) => {
        //console.log(data);
        prevFiles[key] = data;
        prev[key] = file;
        prev[key].file_content = data;
        this.setState({ filesWithBase64: prevFiles, files: prev });
      });*/
      }
    }
  };
  removeFile = (key) => {
    const { filesWithBase64, files } = this.state;
    let prev = [...files];
    // let prevFiles = [...filesWithBase64];
    prev.splice(key, 1);
    prev.splice(key, 0, "");
    //prevFiles.splice(key, 1);
    //prevFiles.splice(key, 0, "");
    //this.setState({ filesWithBase64: prevFiles, files: prev });
    this.setState({ files: prev });
  };

  /*uploadFile = async (file, key) => {
    console.log("Key: ", key, "\nFile: ", file);
    const { currentUser } = this.props;
    if (file) {
      try {
        let res = await axios.post(`${API_BASE_URL}${DRIVE_FILE}`, {
          user_id: currentUser.uid,
          file_name: file.name,
          file_content_type: file.type,
          file_content: file.file_content,
        });
        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
      }
    } else return "";
  };
*/
  uploadFile = async (file) => {
    const { currentUser, folder_id } = this.props;
    console.log(this.props);
    let requestData = {};

    const fileData = new FormData();
    fileData.append("file", file, `${file.lastModified}-${file.name}`);
    fileData.append("user_id", currentUser.uid);

    if (folder_id) {
      fileData.append("folder_id", folder_id);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        this.setState({
          percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,
        });
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        // console.log(res);
        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
      }
    } else return "";
  };
  getResult = (response, user) => {
    //console.log(response, user, "respox3")
    let index = response?.findIndex((id) => id.id === user);
    if (index === -1) {
      return false;
    } else {
      return true;
    }
  };

  getValue = (response, user) => {
    //console.log(response,user,"respox2");
    //console.log(this.state.currUserResponse,"ass in the")
    let currUserResponse = response.find((res) => res.id === user);
     //
    return (
      response && (
        <div
          className={
            this.props.colorTheme ? `${this.props.colorTheme}` : "text-theme"
          }
        >
          <Badge variant="secondary">Your answer</Badge>
          {currUserResponse.answers.map((ans, key) => (
            <div>
              <p className="font-weight-bold mb-0 text-theme">
                {this.state.currAssignment.questions[key].question}
              </p>
              {ans.answer ? (
                <p className="my-0"> {this.strip(ans.answer)} </p>
              ) : (
                ""
              )}

              {ans.attachment ? (
                <a className="my mb-0" href={ans.attachment} target="_blank">
                  Attachment
                </a>
              ) : (
                ""
              )}
            </div>
          ))}
          {/* <p className="mt-1 small mb-0"><i>{this.timeFromNow(response[user].responseTime)}</i></p> */}
          <div
            className="d-flex mt-2 pt-2 pb-1"
            style={{
              borderTop: 2,
              borderTopStyle: "dashed",
              borderTopColor: "#dbdbdb",
            }}
          >
            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
            <p
              className="small pb-0 mb-0 flex-grow-1 px-2"
              style={{ color: "#777B8E" }}
            >
              You responded on{" "}
              {moment(currUserResponse.responseTime).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </p>
          </div>
        </div>
      )
    );
  };

  strip = (value) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = value.match(regExp);
    return match && match[2].length === 11 ? (
      <iframe
        style={{ borderRadius: "4px" }}
        width="100%"
        height="170"
        src={"//www.youtube.com/embed/" + match[2]}
        frameborder="0"
        allowfullscreen
      ></iframe>
    ) : (
      <ReadMore full_text={value} max_length="120" />
    );
  };

  render() {
    const {
      uid,
      item,
      user,
      open_task_branch,
      open_task_id,
      currentChannel,
      // currentUser,
      currentWorkspace,
      colorTheme,
      componentName,
    } = this.props;
    const {
      // value,
      // percentUploaded,
      // uploadState,
      admin,
      responses,
      links,
      answers,
      files,
      currAssignment,
    } = this.state;

    //console.log(user, item, uid);
    // HANDLES ANSWERS ADDED
    const handleAnswers = (e, key) => {
      let prev = [...answers];
      prev[key] = e.target.value;
      this.setState({ answers: prev });
    };

    // TO COMBINE ANSWERS & LINKS
    const getAnswers = (answers, links) => {
      let dataSet = [];
      console.log(links);
      currAssignment.questions.map((question, key) =>
        dataSet.push({
          attachment: links[key] ? links[key] : "",
          answer: answers[key] ? answers[key] : "",
          questionId: question.questionId,
        })
      );
      return dataSet;
    };

    const image_type = [
      "image/jpeg",
      "image/png",
      "image/svg+xml",
      "image/gif",
    ];

    const pdf_type = ["application/pdf"];

    const word_type = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    const excel_type = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const powerpoint_type = [
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
    //console.log(item);

    //console.log(currAssignment);
    const taskResponse = (uid) => {
      const { user, item } = this.props;

      let allImagesUploadedPromises = files.map((file, key) =>
        this.uploadFile(file)
      );
      this.setState({ submit: true });

      Promise.all(allImagesUploadedPromises).then(async (values) => {
        console.log("values: ", values);

        let links = [...values];
        let answersAssignment = [];
        console.log(links);
        let condition = links.every((a) => a === "");
        if (condition) {
          this.setState({ reqResolved: true });
        }
        currAssignment.questions.map((question, key) =>
          answersAssignment.push({
            attachment: links[key] ? links[key] : "",
            answer: answers[key] ? answers[key] : "",
            questionId: question.questionId,
          })
        );
        let responseData = {
          userId: user,
          responseTime: Date.now(),
          answers: answersAssignment,
        };

        // console.log("assignment RESPONSE: ", responseData);

        axios
          .post(`${API_BASE_URL}${ASSIGNMENT_SUBMIT}`, {
            user_id: user,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            assignment_id: item.contentId,
            answers: answersAssignment,
          })
          .then(() => {
            this.setState({ reqResolved: true });
          })
          .catch((error) => {
            console.log(error);
          });

        /*firebase
          .firestore()
          .collection("assignments")
          .doc(`${item.contentId}`)
          .update({
            response: firebase.firestore.FieldValue.arrayUnion(responseData),
          })
          .then(() => {
            this.setState({ reqResolved: true });
          })
          .catch((err) => console.log(err));
          */
      });
    };

    const assignedToShow = () => {
      if(this.state.assignedShow){
        this.setState({assignedShow: false})
      }else{
        this.setState({assignedShow: true})
      }
    }

    // console.log(currAssignment);
    return (
      <>
        {Object.keys(currAssignment).length ? (
          <div
            className={
              colorTheme ? `${colorTheme} taskbox` : "taskbox text-theme"
            }
          >
            <Form
              // className="project"
              style={
                componentName === "VideoChat"
                  ? { minWidth: "200px" }
                  : { minWidth: "300px" }
              }
            >
              <div className="d-flex">
                <h5 className="mt-0 flex-grow-1">
                  {currAssignment.assignmentSubject}
                </h5>
                <div>
                  {currAssignment.deadline < Date.now() ? (
                    <span className="text-secondary small">
                      {currAssignment.closedBy ? (
                        <>
                          Closed by{" "}
                          <Participants
                            userId={currAssignment.closedBy}
                            nameOnly={true}
                          />
                        </>
                      ) : (
                        "Closed"
                      )}
                    </span>
                  ) : (
                    <span className="text-success small">Active</span>
                  )}
                </div>
                {open_task_branch === "saved" && open_task_id === item.uid && (
                  <div className="mx-1">
                    <Badge variant="success">Saved</Badge>
                  </div>
                )}
              </div>
              {currAssignment.closedBy && currAssignment.previousDeadline && (
                <div className="text-danger small mb-1">
                  <>
                    <span className="text-warning">
                      Assignment closed{" "}
                      {getTimeDifference(
                        currAssignment.previousDeadline,
                        currAssignment.deadline
                      )}{" "}
                      before actual deadline.
                    </span>
                  </>
                </div>
              )}
              <div className="m-0">
                <p>{currAssignment.assignmentDescription}</p>
              </div>
              <span className="small d-block">
                Deadline: {this.timeFromNow(currAssignment.deadline)}
              </span>
              {admin ? (
                responses.length ? null : (
                  <div>
                    {/*console.log(currAssignment,"ass in the")*/}
                    {currAssignment.questions.map((que, key) => (
                      <>
                        <p className="mb-0">
                          <span className="font-weight-bold">
                            Q{que.questionId}.
                          </span>{" "}
                          {que.question}
                        </p>
                        <p className="mt-0 mb-1 text-secondary">
                          {que.description}
                        </p>
                      </>
                    ))}
                  </div>
                )
              ) : (
                null
              )}
              {/*console.log(admin,"ass in admin")*/}
              {admin ? (
                responses.map((response) => (
                  <DisplayResponse
                    currentChannel={currentChannel}
                    response={response}
                    item={currAssignment}
                    uid={uid}
                  />
                ))
              ) : currAssignment &&
                this.getResult(responses, user) ? (
                <div>{this.getValue(responses, user)}</div>
              ) : this.state.submit ? (
                <div>
                  {/*console.log(this.state.submit, "ass in submit")*/}
                  <Badge variant="secondary">Your answer</Badge>
                  {currAssignment.questions.map((question, key) => (
                    <div>
                      <p className="font-weight-bold mb-0">
                        {currAssignment.questions[key].question}
                      </p>
                      {answers[key] ? (
                        <p className="my-0"> {this.strip(answers[key])} </p>
                      ) : (
                        ""
                      )}

                      {links[key] ? (
                        <a
                          className="my mb-0"
                          href={links[key]}
                          target="_blank"
                        >
                          Attachment
                        </a>
                      ) : this.state.reqResolved ? (
                        ""
                      ) : (
                        <Loader
                          type="ThreeDots"
                          color="#9e2bad"
                          height="60"
                          width="100"
                        />
                      )}
                    </div>
                  ))}
                  {/* <p className="mt-1 small mb-0"><i>{this.timeFromNow(Date.now())}</i></p> */}
                  <div
                    className="d-flex mt-2 pt-2 pb-1"
                    style={{
                      borderTop: 2,
                      borderTopStyle: "dashed",
                      borderTopColor: "#dbdbdb",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-success"
                    />
                    <p
                      className="small pb-0 mb-0 flex-grow-1 px-2"
                      style={{ color: "#777B8E" }}
                    >
                      You responded on{" "}
                      {moment(Date.now()).format("MMM DD, YYYY hh:mm A")}
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  {currAssignment &&
                    currAssignment.questions.map((que, key) => (
                      <div className="mb-3">
                        {/*console.log(que,"ass in the")*/}
                        <p className="font-weight-bold mb-0">
                          Que: {que.question}
                        </p>

                        <p className="mt-0 mb-1">Desc: {que.description}</p>

                        {currAssignment.deadline < Date.now() ? null : (
                          <InputGroup className="mb-1">
                            <TextareaAutosize
                              className="w-100 p-2 rounded"
                              style={{
                                borderColor: "#CDCDCD",
                                minWidth: "300px",
                              }}
                              name="answers[]"
                              value={answers[key]}
                              id={key}
                              onChange={(e) => handleAnswers(e, key)}
                              minRows={3}
                              maxRows={20}
                              placeholder="Your response"
                            />
                          </InputGroup>
                        )}

                        {this.state.files[key] && (
                          <div>
                            <div className="d-flex rounded bg-light mt-2">
                              <div
                                className="py-2 px-3"
                                style={{ backgroundColor: "#FEF2E4" }}
                              >
                                {!this.state.authorized.includes(
                                  this.state.files[key].type
                                ) && (
                                  <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    className="text-danger"
                                  />
                                )}
                                {image_type.includes(
                                  this.state.files[key].type
                                ) && (
                                  <FontAwesomeIcon
                                    icon={faImage}
                                    className="text-secondary"
                                  />
                                )}
                                {word_type.includes(
                                  this.state.files[key].type
                                ) && (
                                  <FontAwesomeIcon
                                    icon={faFileWord}
                                    className="text-primary"
                                  />
                                )}
                                {pdf_type.includes(
                                  this.state.files[key].type
                                ) && (
                                  <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="text-danger"
                                  />
                                )}
                                {powerpoint_type.includes(
                                  this.state.files[key].type
                                ) && (
                                  <FontAwesomeIcon
                                    icon={faFilePowerpoint}
                                    className="text-danger"
                                  />
                                )}
                                {excel_type.includes(
                                  this.state.files[key].type
                                ) && (
                                  <FontAwesomeIcon
                                    icon={faFileExcel}
                                    className="text-success"
                                  />
                                )}
                              </div>
                              <div className="p-2 flex-grow-1">
                                <small className="font-weight-bold text-secondary">
                                  {this.state.files[key].name}
                                </small>
                              </div>
                              <div className="p-2 flex-grow-1 d-flex justify-content-end align-items-center">
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  onClick={() => this.removeFile(key)}
                                  className="text-dark"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {currAssignment.deadline < Date.now() ? null : this
                            .state.files[key] &&
                          this.state.files[key].name ? null : (
                          <InputGroup className="mb-1">
                            <div class="file-input">
                              <input
                                type="file"
                                name={key}
                                onChange={(e) => {
                                  this.addFile(e, key);
                                }}
                              />
                              <span class="btn btn-sm btn-primary">Attach</span>
                              <span
                                class="label ml-2"
                                className="text-dark"
                                data-js-label
                              >
                                {this.state.files[key]
                                  ? " " + this.state.files[key].name
                                  : " No file selected"}
                              </span>
                            </div>
                          </InputGroup>
                        )}
                      </div>
                    ))}
                  <Button
                    disabled={
                      currAssignment.deadline < Date.now() ||
                      !(
                        currAssignment.questions.length ===
                        Object.keys(currAssignment.questions).filter((a) =>
                          Object.keys(answers.filter((answer) => answer !== ""))
                            .concat(
                              Object.keys(links.filter((link) => link !== ""))
                            )
                            .includes(a)
                        ).length
                      )
                    }
                    variant="primary"
                    size="sm"
                    onClick={() => taskResponse(uid)}
                  >
                    Submit
                  </Button>

                  {!(
                    currAssignment.questions.length == answers.length &&
                    currAssignment.questions.length == links.length
                  ) && (
                    <p className="small mt-2 mb-0 pb-0">
                      <span className="font-weight-bold">Note: </span>Please
                      complete your Assignment to unlock submit.
                    </p>
                  )}
                </>
              )}
            </Form>
            {admin ? (
                this.state.responded ? (
                  <Badge variant="secondary">
                    {" "}
                    {this.state.responded} out of {currAssignment.all ? this.props.allParticipants.length :  currAssignment.assignedTo.length} Participant(s) have responded.
                    {/*console.log(currAssignment, this.props.allParticipants, "respoxx")*/}
                  </Badge>
                ) : (
                  <p className="my-1">No responses yet</p>
                )
              ) : null}
          </div>
        ) : null}

        
        {admin? (
          this.props.view === 2 ? (
            <>
              <div className="mt-2 d-flex justify-content-between">
                {console.log(this.props.darkTheme, "darktheme from ass")}
                <div  className="mt-3 text-theme">Assigned To </div>
                <div className="text-primary mt-3 pointer" onClick={() => assignedToShow()}>
                  {this.state.assignedShow ? "Hide(-)" : "Show(+)"}
                </div>
              </div>
              {this.state.assignedShow ? (
              <>
                <AssignedUsers
                  taskData={currAssignment}
                />
              </>
              ) : null
              }
            </>
          ): null
        ): null}
        
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  darkTheme: state.platform.darkTheme,
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  allParticipants: state.channel.participants,
  open_task_branch: state.platform.open_task_branch,
  open_task_id: state.platform.open_task_id,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, { publishRemark, publishMarks })(
  Assignment
);
