import React, { Component } from 'react'
import { Button, Modal, Form, InputGroup, FormControl, Spinner, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faLock } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { API_BASE_URL, INVITE_PROCESS } from '../../config/index'
import { postData } from '../../utils/index'
import firebase from 'firebase'
import GotoRoomOrVideo from './GotoRoomOrVideo';
import _ from 'lodash';
import {
    setCurrentChannel,
    setAllMessages,
    setParticipants,
    resetData,
    setLeftPanel,
    setIsVideoVisible,
    setChannelDetails
} from '../../actions/index'
import {
    setCurrentWorkspace,
} from '../../actions/workspace'

class JoinUsingLink extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showModal2: false,
            showModal3: false,
            showErrorModal: false,
            joiningLink: "",
            loading: false,
            roomId: "",
            workspaceId: "",
            message: "",
            error: "",
            inviteCode: "",
            newRoomExists: false,
            inviteData: null,
            roomData: null,
            loadingNewRoomData: false,
            showGotoRoomOrVideoModal: false,
            workspacedata: {},
            workspaceRooms: {},
            allMessages: [],
            alreadyExists: false,
            openInNewTab: false,
        }
    }
    // showmodal is for entering the link
    // showmodal 2 is for private room (waiting to admit)
    // showmodal 3 is for private room (request already pending)

    componentWillUnmount(){
        console.log("unmounting join using link")
    }

    _handleStateChange = (attr, val) => this.setState({ [attr]: val })

    getWorkspaceData = () => {
        let self = this;

        let docRef = firebase.firestore().collection(`workspaces`).doc(`${this.state.inviteData.workspaceId}`)
        docRef.get()
            .then(function (doc) {
                if (doc.exists) {
                    // console.log("Document data workspace:", doc.data());
                    const workspacedata = doc.data()
                    // console.log(workspacedata, 'workspacedata in firestore')
                    self._handleStateChange("workspacedata", workspacedata);
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            })

    }

    getWorkspaceRooms = () => {
        let self = this;
        firebase
            .firestore()
            .collection(`users/${this.props.currentUser.uid}/rooms`)
            .onSnapshot(function (snapshot) {
                let userWorkspaces = [];
                snapshot.forEach(function (doc) {
                    userWorkspaces.push({ ...doc.data() })
                })
                //   console.log("userWorkspaces", userWorkspaces[0])
                let index;
        
                userWorkspaces.forEach((workspace, key) => {
                  if(workspace.workspaceId === self.state.inviteData?.workspaceId){
                    index = key
                  }
                })


                self._handleStateChange("workspaceRooms", userWorkspaces[index]);
            })

    }

    getLoadedMessages = () => {
        let self = this;
        const query = firebase
            .firestore()
            .collection(
                `/workspaces/${this.state.inviteData.workspaceId}/rooms/${this.state.roomId}/messages`
            )
            .orderBy("timestamp", "desc")
            .limit(12);

        query.get().then((querySnapshot) => {
            const tempMessages = [];
            querySnapshot.forEach((message) => {
                // console.log(message,'message')
                tempMessages.push({ uid: message.id, ...message.data() })
            })
            // console.log(tempMessages,'tempMessages')
            self._handleStateChange("allMessages", tempMessages);
            // setAllMessagesState(tempMessages)
        })

    }



    _handleJoiningLinkChange = e => {
        const joiningLink = e.target.value;
        const pattern = /^[0-9\s\-\(\)]*$/;
        const fullLinkPattern = /^(https|http?):\/\/[^\s$.?#].[^\s]*$/;
        const shortLinkPattern = /[^\s$.?#].[^\s]*$/;

        this._handleStateChange("error", null)
        this._handleStateChange("message", null)
        this._handleStateChange("joiningLink", joiningLink)

        if (!joiningLink.match(fullLinkPattern) && !joiningLink.match(pattern) && !joiningLink.match(shortLinkPattern)) {
            this._handleStateChange("error", "Please enter a valid link.")
        }

        if (joiningLink.indexOf("my.igesia.co/#/") >= 0) {

            if (joiningLink.indexOf("https://my.igesia.co/#/") >= 0) {
                let inviteCode = joiningLink.replace("https://my.igesia.co/#/", "")
                if (inviteCode.match(pattern)) {
                    if (inviteCode.length != 6) {
                        this._handleStateChange("error", "Room code should be 6-digits")
                    }
                    this._handleStateChange("inviteCode", inviteCode)
                } else {
                    this._handleStateChange("error", "Please enter a valid link")
                }
            } else if (joiningLink.indexOf("http://my.igesia.co/#/") >= 0) {
                let inviteCode = joiningLink.replace("http://my.igesia.co/#/", "")
                if (inviteCode.match(pattern)) {
                    if (inviteCode.length != 6) {
                        this._handleStateChange("error", "Room code should be 6-digits")
                    }
                    this._handleStateChange("inviteCode", inviteCode)
                } else {
                    this._handleStateChange("error", "Please enter a valid link")
                }
            } else {
                let inviteCode = joiningLink.replace("my.igesia.co/#/", "")
                if (inviteCode.match(pattern)) {
                    if (inviteCode.length != 6) {
                        this._handleStateChange("error", "Room code should be 6-digits")
                    }
                    this._handleStateChange("inviteCode", inviteCode)
                } else {
                    this._handleStateChange("error", "Please enter a valid link")
                }
            }

        } else if (joiningLink.indexOf("my.igesia.co/") >= 0) {

            if (joiningLink.indexOf("https://my.igesia.co/") >= 0) {
                let inviteCode = joiningLink.replace("https://my.igesia.co/", "")
                if (inviteCode.match(pattern)) {
                    if (inviteCode.length != 6) {
                        this._handleStateChange("error", "Room code should be 6-digits")
                    }
                    this._handleStateChange("inviteCode", inviteCode)
                } else {
                    this._handleStateChange("error", "Please enter a valid link")
                }
            } else if (joiningLink.indexOf("http://my.igesia.co/") >= 0) {
                let inviteCode = joiningLink.replace("http://my.igesia.co/", "")
                if (inviteCode.match(pattern)) {
                    if (inviteCode.length != 6) {
                        this._handleStateChange("error", "Room code should be 6-digits")
                    }
                    this._handleStateChange("inviteCode", inviteCode)
                } else {
                    this._handleStateChange("error", "Please enter a valid link")
                }
            } else {
                let inviteCode = joiningLink.replace("my.igesia.co/", "")
                if (inviteCode.match(pattern)) {
                    if (inviteCode.length != 6) {
                        this._handleStateChange("error", "Room code should be 6-digits")
                    }
                    this._handleStateChange("inviteCode", inviteCode)
                } else {
                    this._handleStateChange("error", "Please enter a valid link")
                }
            }

        } else if (joiningLink.match(pattern)) {
            if (joiningLink.length != 6) {
                this._handleStateChange("error", "Room code should be 6-digits")
            }
            this._handleStateChange("inviteCode", joiningLink)
        } else {
            this._handleStateChange("error", "Please enter a valid link.")
        }


    }

    _handleProceedLink = () => {
        const { inviteCode } = this.state;

        if (inviteCode.length === 0 || inviteCode === "") {
            this._handleStateChange("error", "Please enter a valid link.")
            return;
        }

        this.setState({
            loadingNewRoomData: true,
            newRoomExists: false,
            inviteData: null,
            roomData: null
        })

        firebase
            .firestore()
            .collection("invites")
            .doc(`${inviteCode}`)
            .get()
            .then((response) => {
                if (response.exists) {
                    this._handleStateChange("newRoomExists", true)
                    const inviteData = response.data()
                    this._handleStateChange("inviteData", inviteData)
                    console.log(response.data(), "response from invites")
                    this._handleStateChange("roomId", response.data().roomId)
                    this.getWorkspaceData();
                    this.getWorkspaceRooms();
                    this.getLoadedMessages();
                    firebase
                        .firestore()
                        .collection(`workspaces/${inviteData.workspaceId}/rooms`)
                        .doc(`${inviteData.roomId}`)
                        .get()
                        .then(response => {
                            if (response.exists) {
                                const roomData = response.data()
                                console.log("response.data()", response.data())

                                this._handleStateChange("roomData", roomData)
                                this._handleStateChange("loadingNewRoomData", false)
                                if(roomData.roomType === 0){
                                    this._handleJoinViaLink();
                                }else if(roomData.roomType === 1){
                                    // this._handleStateChange("showModal2", true)
                                    this._handleJoinViaLinkPrivate();
                                }

                            }
                        })
                        .catch(error => {
                            console.error(error)
                        })

                } else {
                    this._handleStateChange("showErrorModal", true);
                    this._handleStateChange("error", "Please enter a valid link.")
                    this._handleStateChange("loadingNewRoomData", false)
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    _handleJoinViaLink = () => {
        const { currentUser } = this.props;
        const { joiningLink, inviteCode } = this.state;

        if (joiningLink == "") {
            this._handleStateChange("error", "Please enter a valid link.")
            return;
        }

        if (currentUser) {

            this._handleStateChange("loading", true)

            const inviteProcessData = {
                invite_code: inviteCode,
                user_id: currentUser.uid
            }

            postData(`${API_BASE_URL}${INVITE_PROCESS}`, inviteProcessData)
                .then(response => {
                    console.log(response)
                    const { status, message, data } = response;

                    this._handleStateChange("message", message)
                    if (status === 200 && !this.state.openInNewTab) {
                        this._handleStateChange("roomId", data.room_id)
                        this._handleStateChange("workspaceId", data.workspace_id)
                        this._handleStateChange("loading", false)
                        setTimeout(() => {this._handleCloseAndReset(); this._handleStateChange("showGotoRoomOrVideoModal", true)}, 1500)
                    } 
                    else if (status === 200 && this.state.openInNewTab) {
                        setTimeout(() => { 
                            window.open(`/w/${data.workspace_id}?r=${data.room_id}`, "igesia-new-tab");
                            this._handleStateChange('roomId', ""); this._handleStateChange('workspaceId', "");
                            this._handleStateChange('roomData', "");
                            this._handleStateChange('showGotoRoomOrVideoModal', false);
                            this._handleStateChange('alreadyExists', false);
                            this._handleStateChange('inviteCode', "");
                            this._handleCloseAndReset();
                        }, 1500)
                    } 
                    else if (status === 400 && (message === "Participant already exists" || message === "Request user is already room owner") && !this.state.openInNewTab){
                        this._handleStateChange("roomId", this.state.roomId)
                        this._handleStateChange("workspaceId", this.state.inviteData.workspaceId)
                        this._handleStateChange("loading", false)
                        this._handleStateChange("alreadyExists", true)
                        setTimeout(() => {this._handleCloseAndReset(); this._handleStateChange("showGotoRoomOrVideoModal", true)}, 1500)
                    }
                    else if (status === 400 && (message === "Participant already exists" || message === "Request user is already room owner") && this.state.openInNewTab){
                        setTimeout(() => { 
                            window.open(`/w/${this.state.inviteData.workspaceId}?r=${this.state.roomId}`, "igesia-new-tab");
                            this._handleStateChange('roomId', ""); this._handleStateChange('workspaceId', "");
                            this._handleStateChange('roomData', "");
                            this._handleStateChange('showGotoRoomOrVideoModal', false);
                            this._handleStateChange('alreadyExists', false);
                            this._handleStateChange('inviteCode', "");
                            this._handleCloseAndReset();
                        }, 1500)
                    }
                    else if (status === 400 && (message === "Invite Code Expired") && !this.state.openInNewTab){
                        if (this.props.currentWorkspace.workspaceId === this.state.inviteData.workspaceId && _.map(this.props.currentWorkspace.rooms, 'roomId').includes(this.state.roomId)) {
                            this._handleStateChange("roomId", this.state.roomId)
                            this._handleStateChange("workspaceId", this.state.inviteData.workspaceId)
                            this._handleStateChange("loading", false)
                            this._handleStateChange("alreadyExists", true)
                            setTimeout(() => { this._handleCloseAndReset(); this._handleStateChange("showGotoRoomOrVideoModal", true) }, 1500)
                        } else {
                            console.log("Something is wrong with the link!");
                            this._handleStateChange("loading", false);
                            this._handleStateChange("showErrorModal", true);
                            setTimeout(() => this._handleCloseAndReset(), 1500)
                        }

                    }
                    else if (status === 400 && (message === "Invite Code Expired") && this.state.openInNewTab){
                        if (this.props.currentWorkspace.workspaceId === this.state.inviteData.workspaceId && _.map(this.props.currentWorkspace.rooms, 'roomId').includes(this.state.roomId)) {
                            setTimeout(() => {
                                window.open(`/w/${this.state.inviteData.workspaceId}?r=${this.state.roomId}`, "igesia-new-tab");
                                this._handleStateChange('roomId', ""); this._handleStateChange('workspaceId', "");
                                this._handleStateChange('roomData', "");
                                this._handleStateChange('showGotoRoomOrVideoModal', false);
                                this._handleStateChange('alreadyExists', false);
                                this._handleStateChange('inviteCode', "");
                                this._handleCloseAndReset();
                            }, 1500)
                        } else {
                            console.log("Something is wrong with the link!");
                            this._handleStateChange("loading", false);
                            this._handleStateChange("showErrorModal", true);
                            setTimeout(() => this._handleCloseAndReset(), 1500)
                        }
                    }
                    else {
                        console.log("Something is wrong with the link!");
                        this._handleStateChange("loading", false);
                        this._handleStateChange("showErrorModal", true);
                        setTimeout(() => this._handleCloseAndReset(), 1500)
                    }
                })
                .catch(error => {
                    console.error(error)
                    this._handleStateChange("loading", false)
                })
        }
    }

    _handleJoinViaLinkPrivate = () => {
        const { currentUser } = this.props;
        const { joiningLink, inviteCode } = this.state;

        if (joiningLink == "") {
            this._handleStateChange("error", "Please enter a valid link.")
            return;
        }

        if (currentUser) {

            this._handleStateChange("loading", true)

            const inviteProcessData = {
                invite_code: inviteCode,
                user_id: currentUser.uid
            }

            postData(`${API_BASE_URL}${INVITE_PROCESS}`, inviteProcessData)
                .then(response => {
                    console.log(response)
                    const { status, message, data } = response;

                    this._handleStateChange("message", message)
                    if (status === 200 && !this.state.openInNewTab) {
                        this._handleStateChange("showModal2", true)
                        this._handleStateChange("roomId", data.room_id)
                        this._handleStateChange("workspaceId", data.workspace_id)
                        this._handleStateChange("loading", false)
                        setTimeout(() => {this._handleCloseAndReset(); this._handleStateChange("showGotoRoomOrVideoModal", true)}, 1500)
                    } 
                    else if (status === 200 && this.state.openInNewTab) {
                        setTimeout(() => { 
                            window.open(`/w/${data.workspace_id}?r=${data.room_id}`, "igesia-new-tab");
                            this._handleStateChange('roomId', ""); this._handleStateChange('workspaceId', "");
                            this._handleStateChange('roomData', "");
                            this._handleStateChange('showGotoRoomOrVideoModal', false);
                            this._handleStateChange('alreadyExists', false);
                            this._handleStateChange('inviteCode', "");
                            this._handleCloseAndReset();
                        }, 1500)
                    }
                    else if (status === 400 && (message === "Participant already exists" || message === "Request user is already room owner") && !this.state.openInNewTab) {
                        this._handleStateChange("roomId", this.state.roomId)
                        this._handleStateChange("workspaceId", this.state.inviteData.workspaceId)
                        this._handleStateChange("loading", false)
                        this._handleStateChange("alreadyExists", true)
                        setTimeout(() => { this._handleCloseAndReset(); this._handleStateChange("showGotoRoomOrVideoModal", true) }, 1500)
                    } 
                    else if (status === 400 && (message === "Participant already exists" || message === "Request user is already room owner") && this.state.openInNewTab) {
                        setTimeout(() => { 
                            window.open(`/w/${this.state.inviteData.workspaceId}?r=${this.state.roomId}`, "igesia-new-tab");
                            this._handleStateChange('roomId', ""); this._handleStateChange('workspaceId', "");
                            this._handleStateChange('roomData', "");
                            this._handleStateChange('showGotoRoomOrVideoModal', false);
                            this._handleStateChange('alreadyExists', false);
                            this._handleStateChange('inviteCode', "");
                            this._handleCloseAndReset();
                        }, 1500)
                    }
                    else if (status === 400 && (message === "Invite Code Expired") && !this.state.openInNewTab){
                        if (this.props.currentWorkspace.workspaceId  === this.state.inviteData.workspaceId && _.map(this.props.currentWorkspace.rooms, 'roomId').includes(this.state.roomId)) {
                            this._handleStateChange("roomId", this.state.roomId)
                            this._handleStateChange("workspaceId", this.state.inviteData.workspaceId)
                            this._handleStateChange("loading", false)
                            this._handleStateChange("alreadyExists", true)
                            setTimeout(() => { this._handleCloseAndReset(); this._handleStateChange("showGotoRoomOrVideoModal", true) }, 1500)
                        } else {
                            console.log("Something is wrong with the link!");
                            this._handleStateChange("loading", false);
                            this._handleStateChange("showErrorModal", true);
                            setTimeout(() => this._handleCloseAndReset(), 1500)
                        }

                    }
                    else if (status === 400 && (message === "Invite Code Expired") && this.state.openInNewTab){
                        if (this.props.currentWorkspace.workspaceId  === this.state.inviteData.workspaceId && _.map(this.props.currentWorkspace.rooms, 'roomId').includes(this.state.roomId)) {
                            setTimeout(() => { 
                                window.open(`/w/${this.state.inviteData.workspaceId}?r=${this.state.roomId}`, "igesia-new-tab");
                                this._handleStateChange('roomId', ""); this._handleStateChange('workspaceId', "");
                                this._handleStateChange('roomData', "");
                                this._handleStateChange('showGotoRoomOrVideoModal', false);
                                this._handleStateChange('alreadyExists', false);
                                this._handleStateChange('inviteCode', "");
                                this._handleCloseAndReset();
                            }, 1500)
                        } else {
                            console.log("Something is wrong with the link!");
                            this._handleStateChange("loading", false);
                            this._handleStateChange("showErrorModal", true);
                            setTimeout(() => this._handleCloseAndReset(), 1500)
                        }

                    }


                    else if (status === 400 && (message === "Invite request is already pending")) {
                        this._handleStateChange("showModal3", true)
                        this._handleStateChange("roomId", this.state.roomId)
                        this._handleStateChange("workspaceId", this.state.inviteData.workspaceId)
                        this._handleStateChange("loading", false)
                        setTimeout(() => {this._handleCloseAndReset(); this._handleStateChange("showGotoRoomOrVideoModal", true)}, 1500)
                    }

                    else {
                        this._handleStateChange("showErrorModal", true);
                        console.log("Something is wrong with the link!")
                        this._handleStateChange("loading", false)
                        setTimeout(() => this._handleCloseAndReset(), 1500)
                    }
                })
                .catch(error => {
                    console.error(error)
                    this._handleStateChange("loading", false)
                })
        }
    }



    _handleCloseAndReset = () => {

        this.setState({
            showModal: false,
            showModal2: false,
            showModal3: false,
            joiningLink: "",
            loading: false,
            // roomId: "",
            // workspaceId: "",
            message: "",
            error: "",
            loadingNewRoomData: false,
            newRoomExists: false,
            inviteData: null,
            openInNewTab: false
            // roomData: null
        })
    }

    render() {
        const {
            showModal, joiningLink, loading, message, error,
            loadingNewRoomData, newRoomExists, roomData, inviteCode, inviteData
        } = this.state;

        // console.log(roomData, "roomData");
        return (

            <div>
                <Button variant="link" className="text-decoration-none" size="sm" onClick={() => this._handleStateChange("showModal", true)}>
                    <small>JOIN VIA LINK</small>
                </Button>

                {
                    // show modal which has input field to take link
                    // after proceed is clicked and room === public => no modal => load the room and show room joined
                    // after proceed if room === private => for now show modal => request to join

                    <Modal
                    show={showModal}
                    onHide={() => this._handleStateChange("showModal", false)}
                    backdrop="static"
                    keyboard={false}

                >
                    <Modal.Body className="bg-theme text-theme rounded shadow-lg">
                        <h4 className="mb-3">Join via link <FontAwesomeIcon icon={faLink} className="ml-2" style={{ fontSize: 16 }} /></h4>
                        {
                        !newRoomExists && <Form className="mr-3" 
                            onSubmit={e => {
                                e.preventDefault()
                                this._handleProceedLink()
                            }}>
                            <label htmlFor="basic-url">Invite ID or Invite Link</label>
                            <InputGroup className="mb-3">
                                
                                <FormControl
                                    autoComplete="false"
                                    id="basic-url"
                                    aria-describedby="basic-addon3"
                                    type="text"
                                    // placeholder="Paste link or ID here" 
                                    placeholder="https://my.igesia.co/xxxxxx"
                                    value={joiningLink}
                                    onChange={this._handleJoiningLinkChange} />
                            </InputGroup>
                            {/* <p>{inviteCode}</p>
                            <p>{this.state.joiningLink}</p> */}

                            <div className="mb-2">
                                {error != "" && <p className="text-danger small mb-0">{error}</p>}
                                {message != "" && <p className="text-theme small mb-0">{message}</p>}
                            </div>
                            <div className="my-2">
                                <Form.Check label="Open in new tab" type="checkbox" id="checkbox-newtab" checked={this.state.openInNewTab} onClick={() => this.setState({openInNewTab: !this.state.openInNewTab})}/>
                            </div>

                            <Button
                                variant="primary"
                                className="mr-3"
                                onClick={() => {this._handleProceedLink(); this._handleStateChange("showModal", false)}}
                                disabled={loadingNewRoomData || (this.state.inviteCode.length === 0) || (this.state.error)}>
                                {loadingNewRoomData
                                    && <Spinner
                                        as="span"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                        className="mr-1"
                                        size="sm" />}
                                <span>Proceed</span>
                            </Button>

                            <Button
                                variant="secondary"
                                onClick={this._handleCloseAndReset}
                                disabled={loading}>Close</Button>
                        </Form>
                        }

                    </Modal.Body>
                </Modal>
                }
                {
                    (inviteData != null)
                    && ((inviteData.expiryTimestamp > Date.now()) && ( roomData &&  roomData.roomType === 1)) ?

                    <Modal
                    show={this.state.showModal2}
                    onHide={() => this._handleStateChange("showModal2", false)}
                    backdrop="static"
                    keyboard={false}

                >
                    <Modal.Body className="bg-theme text-theme rounded shadow-lg">

                    <div className="mb-3">
                            <div className='d-flex align-items-center py-2 my-2 rounded-pill'>
                                <div className="d-inline-block mr-1">
                                    <Image
                                        width="40" height="40"
                                        roundedCircle
                                        className="my-2"
                                        src={roomData.roomPic}
                                        style={{ background: '#FFFFFF' }}
                                    />
                                </div>
                                    &nbsp;
                                    <div className="d-inline-block text-theme small font-weight-bold">
                                    {roomData.roomName}<br />
                                    <span>{roomData.roomType === 1 && <FontAwesomeIcon icon={faLock} className="text-success mr-1" style={{ fontSize: 12 }} />}</span>
                                    <span>{roomData.roomType === 1 ? "Private" : "Public"}</span>
                                    </div>
                            </div>
                            <span>Waiting for admin to admit you into the room</span>
                    </div>



                            <Button
                                variant="secondary"
                                onClick={this._handleCloseAndReset}
                                disabled={loading}>Close</Button>


                    </Modal.Body>
                </Modal>
                    : null
                }
                {
                    (inviteData != null)
                        && ((inviteData.expiryTimestamp > Date.now()) && (roomData && roomData.roomType === 1)) ?

                        <Modal
                            show={this.state.showModal3}
                            onHide={() => this._handleStateChange("showModal3", false)}
                            backdrop="static"
                            keyboard={false}

                        >
                            <Modal.Body className="bg-theme text-theme rounded shadow-lg">

                                <div className="mb-3">
                                    <div className='d-flex align-items-center py-2 my-2 rounded-pill'>
                                        <div className="d-inline-block mr-1">
                                            <Image
                                                width="40" height="40"
                                                roundedCircle
                                                className="my-2"
                                                src={roomData.roomPic}
                                                style={{ background: '#FFFFFF' }}
                                            />
                                        </div>
                                    &nbsp;
                                    <div className="d-inline-block text-theme small font-weight-bold">
                                            {roomData.roomName}<br />
                                            <span>{roomData.roomType === 1 && <FontAwesomeIcon icon={faLock} className="text-success mr-1" style={{ fontSize: 12 }} />}</span>
                                            <span>{roomData.roomType === 1 ? "Private" : "Public"}</span>
                                        </div>
                                    </div>
                                    <span>Invite request is already pending.</span>
                                </div>



                                <Button
                                    variant="secondary"
                                    onClick={this._handleCloseAndReset}
                                    disabled={loading}>Close</Button>


                            </Modal.Body>
                        </Modal>
                        : null
                }


                {/* error modal */}

                {
                    <Modal
                        show={this.state.showErrorModal}
                        onHide={() => {
                            this._handleStateChange("showErrorModal", false);
                            this._handleCloseAndReset();
                        }}
                        backdrop="static"
                        keyboard={false}

                    >
                        <Modal.Body className="bg-theme text-theme rounded shadow-lg">

                            <div className="mb-3">
                                <div className='d-flex align-items-center py-2 my-2 rounded-pill'>
                                    &nbsp;
                                    <div className="d-inline-block text-theme font-weight-bold">
                                        <span>Link expired or invalid!</span>
                                    </div>
                                </div>
                            </div>



                            <Button
                                variant="secondary"
                                onClick={() => {
                                    this._handleStateChange("showErrorModal", false);
                                    this._handleCloseAndReset();
                                }
                                }
                                >Close</Button>
                        </Modal.Body>
                    </Modal>
                }


                
                <GotoRoomOrVideo
                    show={this.state.showGotoRoomOrVideoModal}
                    hide={() => { this._handleStateChange('roomId', ""); this._handleStateChange('workspaceId', ""); this._handleStateChange('roomData', ""); this._handleStateChange('showGotoRoomOrVideoModal', false); this._handleStateChange('alreadyExists', false); }}
                    onShow={() => this._handleStateChange('showGotoRoomOrVideoModal', true)}
                    roomData={this.state.roomData}
                    roomId={this.state.roomId}
                    workspaceId={this.state.workspaceId}
                    alreadyExists={this.state.alreadyExists}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    currentWorkspace: state.workspace.currentWorkspace
})

export default connect(mapStateToProps,{
    setCurrentChannel,
    setCurrentWorkspace,
    setAllMessages,
    setParticipants,
    resetData,
    setLeftPanel,
    setIsVideoVisible,
    setChannelDetails
})(JoinUsingLink);