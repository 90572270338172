import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faShareAlt,
  faStar,
  faSearch,
  faPhoneAlt,
  faVideoSlash,
  faUserPlus,
  faExpand,
  faTimes,
  faChalkboard,
  faBook,
  faUserClock,
  faDotCircle,
  faCircle,
  faCompress,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarReg } from "@fortawesome/free-regular-svg-icons";
import {
  Image,
  Form,
  Popover,
  OverlayTrigger,
  Modal,
  Button,
  Spinner,
  Row,
  Col,
  Tooltip,
  Dropdown,
  Badge,
} from "react-bootstrap";
import firebase from "../../firebase";
import DateTimePicker from "react-datetime-picker";
import IsAdmin from "../IsAdmin";

import {
  isMobile,
  browserName,
  CustomView,
  osName,
  BrowserView,
  MobileView,
  isAndroid,
} from "react-device-detect";

import { connect } from "react-redux";
import {
  setRightPanel,
  setShowWhiteboard,
  saveWhiteboardLayers,
  saveWhiteboardBackground,
  setShowGradebook,
  setShowAttendanceLedger,
  setIsVideoVisible,
  setChannelDetails,
  setConferenceSession,
  setVideoDetails,
} from "../../actions/index";
import AddMemberInvite from "./AddMemberInvite";
import PendingInviteAction from "../Right/PendingInviteAction";
import axios from 'axios';
import https from 'https';
import { ZOOM_CLIENT_ID, API_BASE_URL, MEETING_TYPE } from '../../config/index';

/** Full Screen */

function toggleFullScreen() {
  var doc = window.document;
  var docEl = doc.documentElement;

  var requestFullScreen =
    docEl.requestFullscreen ||
    docEl.mozRequestFullScreen ||
    docEl.webkitRequestFullScreen ||
    docEl.msRequestFullscreen;
  var cancelFullScreen =
    doc.exitFullscreen ||
    doc.mozCancelFullScreen ||
    doc.webkitExitFullscreen ||
    doc.msExitFullscreen;

  if (
    !doc.fullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
}

class MessagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      liveUsers: [],
      pendingInvites: [],
      // roomPic: "",
      // roomName: "",
      roomLiveStatus: false,

      unsubscribe: null,
      unsubLiveRoom: null,
    };
  }

  componentDidMount() {
    const { currentUser, currentChannel, currentWorkspace } = this.props;

    if (currentUser && currentChannel) {
      // this.setState({
      //   roomName: currentChannel.roomName,
      //   roomPic: currentChannel.roomPic
      // })

      const settingState = (data) => {
        this.setState({ liveUsers: data });
      };

      this.getPendingInvites();

      firebase
        .firestore()
        .collection("liveSessions")
        .doc(`${currentChannel.id}`)
        .onSnapshot(async function (doc) {
          if (doc.exists) {
            console.log("SESSION DATA:", doc.data().liveSessionId);
            const snapshot = await firebase
              .firestore()
              .collection("attendanceLedger")
              .doc(`${currentChannel.id}`)
              .collection(`sessions`)
              .doc(`${doc.data().liveSessionId}`)
              .collection(`participants`)
              .get();

            if (snapshot.empty) {
              console.log("No matching documents.");
              return;
            }

            let tempLiveUsers = [];
            snapshot.forEach((doc) => {
              console.log(doc.id, "=>", doc.data());
              doc.data().leftAtTimestamp === null &&
                tempLiveUsers.push(doc.data().userDetails);
            });
            settingState(tempLiveUsers);
          } else {
            settingState([]);
          }
        });
    }

    if (currentWorkspace) {
      let unsubLive;
      unsubLive = firebase
        .firestore()
        .collection(
          `sessions/${currentWorkspace.workspaceId.toLowerCase()}/rooms`
        )
        .doc(`${currentChannel.roomId.toLowerCase()}`)
        .onSnapshot((doc) => {
          if (doc.exists) {
            // console.log("LIVE Sessions doc: ", doc.data())
            const { currentSession } = doc.data();
            if (currentSession !== "") {
              this.setState({
                roomLiveStatus: true,
              });
            } else {
              this.setState({
                roomLiveStatus: false,
              });
            }
          } else {
            this.setState({
              roomLiveStatus: false,
            });
          }
        });

      this.setState({
        unsubLiveRoom: unsubLive,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentUser, currentChannel, currentWorkspace } = this.props;

    if (prevProps.currentChannel.roomId != currentChannel.roomId) {
      if (currentWorkspace) {
        let unsubLive;
        unsubLive = firebase
          .firestore()
          .collection(
            `sessions/${currentWorkspace.workspaceId.toLowerCase()}/rooms`
          )
          .doc(`${currentChannel.roomId.toLowerCase()}`)
          .onSnapshot((doc) => {
            if (doc.exists) {
              const { currentSession } = doc.data();
              if (currentSession !== "") {
                this.setState({
                  roomLiveStatus: true,
                });
              } else {
                this.setState({
                  roomLiveStatus: false,
                });
              }
            }
            else {
              this.setState({
                roomLiveStatus: false,
              });
            }
          });

        this.setState({
          unsubLiveRoom: unsubLive,
        });
      }

      this.state.unsubscribe();
      this.state.unsubLiveRoom();
      this.getPendingInvites();
    }
  }

  getPendingInvites = () => {
    const { currentWorkspace, currentChannel } = this.props;

    let unsub;

    unsub = firebase
      .firestore()
      .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
      .doc(`${currentChannel.roomId}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          // console.log("PENDING INVITES: ", doc.data())
          this.setState({
            pendingInvites: doc.data().pendingInvites,
            // roomName: doc.data().roomName,
            // roomPic: doc.data().roomPic
          });
        }
      });

    this.setState({ unsubscribe: unsub });
  };

  getAvatar = (key) => {
    let avatar = "";
    firebase
      .database()
      .ref("users")
      .child(key)
      .on("value", (snap) => {
        avatar = snap.val() ? snap.val().avatar : "";
      });
    return avatar;
  };

  toggleSidebar = () => {
    this.props.setRightPanel(!this.props.displayRightPanel);
    console.log(this.props.displayRightPanel);
  };

  render() {
    const {
      numUniqueUsers,
      handleSearchChange,
      searchLoading,
      isPrivateChannel,
      handleStar,
      isChannelStarred,
      userId,
      channelId,

      isVideoVisible,
      channelDetails,
      currentChannel,
      setIsVideoVisible,
      setChannelDetails,
      isPublisher,
      conferenceSession,
      setConferenceSession,
      currentWorkspace,
      currentUser,
      setVideoDetails
    } = this.props;

    const { pendingInvites } = this.state;
    const { roomName, roomPic } = this.props.currentChannel;

    const popover = (
      <Popover id="popover-basic">
        <Popover.Content>
          <Form.Control
            type="email"
            onChange={handleSearchChange}
            placeholder="Search Messages"
          />
        </Popover.Content>
      </Popover>
    );

    return (
      <div className="header d-flex bd-highlight ">
        <div className="p-2 w-100 bd-highlight">
          <div
            className="leftelements"
            onClick={this.toggleSidebar}
            style={{ cursor: "pointer" }}
          >
            <div
              className={
                this.state.roomLiveStatus
                  ? "liveroom headerimageicon"
                  : "headerimageicon"
              }
            >
              <img
                className={
                  this.state.roomLiveStatus
                    ? "mr-2 rounded-circle profile-image"
                    : "mr-2 rounded-circle"
                }
                src={roomPic}
                style={{ height: 40, width: 40 }}
              />
            </div>
            {/* <div className={this.state.roomLiveStatus ? "liveroom headerimageicon" : "headerimageicon"}>
            <Image width="40" height="40"
              roundedCircle
              src={roomPic}
              spaced="right"
              style={{ background: '#FFFFFF' }}
              className={this.state.roomLiveStatus ? "rounded-circle" : ""}
            />
          </div> */}
            <div className="row">
              <div className="col-12 d-block">
                <span
                  className="d-block text-theme text-truncate"
                  style={this.props.displayRightPanel ? { maxWidth: "250px" } : { maxWidth: "650px" }}
                  title={roomName}>
                  {roomName}
              &nbsp;
            </span>
                {/* For Starred Rooms - Do it later Call it Pin to Top */}
                {/* {!isPrivateChannel && (
              <FontAwesomeIcon
                onClick={handleStar}
                icon={faStar}
                color={isChannelStarred ? "darkorange" : "black"} />
            )} */}
                {/* <span className="users">
              {!isPrivateChannel && numUniqueUsers}
              </span> */}

                {!this.props.displayRightPanel ? (
                  <span className="users text-secondary">click for room apps</span>
                ) : (
                    <span className="users text-secondary">
                      click to hide room apps
                    </span>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="rightelements p-2 flex-shrink-1 bd-highlight">
          {/* ===Attendance Ledger Start=== */}
          {/* <BrowserView>
            <IsAdmin>
              <div className="mx-2">
                <FontAwesomeIcon
                  icon={faUserClock}
                  onClick={() => this.props.setShowAttendanceLedger(!this.props.showAttendanceLedger)}
                  className={this.props.showAttendanceLedger ? "text-dark" : "text-secondary"}
                />
              </div>
            </IsAdmin>
          </BrowserView> */}
          {/* ===Attendance Ledger End=== */}
          {/* Gradebook */}
          {/* <BrowserView>
          <IsAdmin>
            <div className="mx-2">
              <FontAwesomeIcon
                icon={faBook}
                onClick={() => this.props.setShowGradebook(!this.props.showGradebook)}
                className={this.props.showGradebook ? "text-dark" : "text-secondary"}
              />
            </div>
          </IsAdmin>
          </BrowserView> */}
          {/* Whiteboard */}

          {/* <div className="mx-2">
            <FontAwesomeIcon
              icon={faChalkboard}
              onClick={() => {
                this.props.showWhiteboard && this.props.saveWhiteboardLayers([]);
                !this.props.showWhiteboard
                  && firebase.database().ref("whiteboards").child(this.props.currentChannel.id).on("value", snap => {
                    if (snap.val()) {
                      snap.val().layers && this.props.saveWhiteboardLayers([...snap.val().layers]);
                      snap.val().background && this.props.saveWhiteboardBackground(snap.val().background);
                    }
                  });
                this.props.setShowWhiteboard(!this.props.showWhiteboard);
              }}
              className={this.props.showWhiteboard ? "text-dark" : "text-secondary"} />
          </div> */}

          {/* Search */}
          {/* <BrowserView>
          <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
            <FontAwesomeIcon icon={faSearch} className="headericons" />
          </OverlayTrigger>
          </BrowserView> */}

          <BrowserView>
            {isVideoVisible && channelDetails.id === currentChannel.id ? (
              <Button
                style={{ marginRight: "1.5em" }}
                variant="light"
                size="sm"
                onClick={() => {
                  if (isPublisher) {

                    // At request level
                    const agent = new https.Agent({
                      rejectUnauthorized: false
                    });
                    axios.post(`https://streams.igesia.com/WebRTCAppEE/rest/v2/broadcasts/${currentWorkspace.workspaceId}-${channelDetails.roomId}/stop`, { httpsAgent: agent }).then((res) => {
                      console.log("streaming stopped", res)
                    })
                  }

                  if (conferenceSession) {
                    conferenceSession.off();
                    conferenceSession.disconnect();
                    setConferenceSession(null);


                    firebase
                      .firestore()
                      .collection(
                        `videoConference/${currentWorkspace.workspaceId}/rooms`
                      )
                      .doc(`${currentChannel.roomId}`).get().then((doc) => {
                        if (doc.exists) {
                          const currentPublishers = doc.data().currentPublishers ? doc.data().currentPublishers : [];

                          const tempCurrentPublisher = [];

                          currentPublishers.forEach((p, index) => {
                            if (p.uid !== currentUser.uid) {
                              tempCurrentPublisher.push(p);
                            }
                          })

                          firebase
                            .firestore()
                            .collection(
                              `videoConference/${currentWorkspace.workspaceId}/rooms`
                            )
                            .doc(`${currentChannel.roomId}`)
                            .set({
                              currentPublishers: tempCurrentPublisher,
                            }, { merge: true })

                        }
                      })
                  }

                  setIsVideoVisible(false);
                  setChannelDetails(null);
                }}
              >
                <span className="live text-nowrap small ml-0  py-1  font-weight-bold">
                  END MEETING
                </span>
                {/* <FontAwesomeIcon icon={faVideoSlash} className="headericons live" /> */}
              </Button>
            ) : (
                <Button
                  style={{ marginRight: "1.5em" }}
                  variant="light"
                  size="sm"
                  onClick={() => {
                    if (
                      isVideoVisible &&
                      channelDetails.id !== currentChannel.id
                    ) {
                      if (
                        window.confirm("Do you want to exit the current session?")
                      ) {
                        setIsVideoVisible(false);
                        setTimeout(() => {
                          axios.post(`${API_BASE_URL}${MEETING_TYPE}`, {
                            room_id: this.props.currentChannel.roomId,
                            workspace_id: this.props.currentWorkspace.workspaceId,
                            user_id: this.props.currentUser.uid,
                            email: this.props.currentUser.email
                          }).then((res) => {
                            if (res.status === 200) {
                              setVideoDetails(res.data.data)
                              setChannelDetails(currentChannel);
                              setIsVideoVisible(true);
                            } else {
                              console.log("Could not get info about video meeting:", res.status)
                            }
                          }).catch((error) => {
                            console.log("Could not get info about video meeting:", error)
                          })
                        }, 1000);
                      }
                    } else {
                      axios.post(`${API_BASE_URL}${MEETING_TYPE}`, {
                        room_id: this.props.currentChannel.roomId,
                        workspace_id: this.props.currentWorkspace.workspaceId,
                        user_id: this.props.currentUser.uid,
                        email: this.props.currentUser.email
                      }).then((res) => {
                        if (res.status === 200) {
                          setVideoDetails(res.data.data)
                          setChannelDetails(currentChannel);
                          setIsVideoVisible(true);
                        } else {
                          console.log("Could not get info about video meeting:", res.status)
                        }
                      }).catch((error) => {
                        console.log("Could not get info about video meeting:", error)
                      })
                    }
                  }}
                >
                  <div className="d-flex align-items-center">
                    {this.state.liveUsers && this.state.liveUsers.length > 0 ? (
                      <>
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="headericons liveDot"
                        />
                        <span className="live text-nowrap small ml-0 py-1  font-weight-bold">
                          LIVE
                      </span>
                      </>
                    ) : (
                        <>
                          <span className="live text-nowrap small ml-0 py-1 font-weight-bold">
                            JOIN MEETING
                      </span>
                          <FontAwesomeIcon
                            icon={faVideo}
                            className="headericons live"
                          />
                        </>
                      )}
                    {this.state.liveUsers && this.state.liveUsers.length > 0 && (
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip id="tooltip-bottom">
                            {this.state.liveUsers &&
                              this.state.liveUsers.length > 0 && (
                                <div className="bg-card-theme text-theme p-1">
                                  {this.state.liveUsers.map((user) => (
                                    <div className="p-1 d-flex align-items-center">
                                      <img
                                        src={this.getAvatar(user.userId)}
                                        className="rounded-circle d-block"
                                        style={{ height: 30, width: 30 }}
                                      />
                                      <p className="text-theme mb-0 px-1 small">
                                        {user.userName}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              )}
                          </Tooltip>
                        }
                      >
                        <div className="d-flex align-items-center ml-2">
                          {this.state.liveUsers &&
                            this.state.liveUsers.length > 0 &&
                            this.state.liveUsers.map(
                              (user, key) =>
                                key < 3 && (
                                  <img
                                    src={this.getAvatar(user.userId)}
                                    className="rounded-circle border border-white"
                                    style={{
                                      height: 20,
                                      width: 20,
                                      marginLeft: "-0.3em",
                                    }}
                                  />
                                )
                            )}
                          {this.state.liveUsers &&
                            this.state.liveUsers.length > 3 && (
                              <small className="ml-1 font-weight-bold">
                                + {this.state.liveUsers.length - 3} more
                              </small>
                            )}
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </Button>
              )}
          </BrowserView>
          <MobileView>
            {isVideoVisible ? (
              <Button
                style={{ marginRight: "1.5em" }}
                variant="light"
                size="sm"
                onClick={() => {
                  setIsVideoVisible(false);
                  setChannelDetails(null);
                }}
              >
                <FontAwesomeIcon
                  icon={faVideoSlash}
                  className="headericons live"
                />
              </Button>
            ) : (
                <Button
                  style={{ marginRight: "1.5em" }}
                  variant="light"
                  size="sm"
                  onClick={() => {
                    if (!isMobile) {
                      setChannelDetails(currentChannel);
                      setIsVideoVisible(true);
                    }
                    isMobile &&
                      isAndroid &&
                      window.location.href.indexOf("gu.igesia.co") > 0
                      ? window.open("https://igesia.page.link/app", "_blank")
                      : window.open("https://webigesia.page.link/app", "_blank");
                  }}
                >
                  <FontAwesomeIcon icon={faVideo} className="headericons live" />
                </Button>
              )}
          </MobileView>

          <IsAdmin>
            <AddMemberInvite>
              <Button
                className="mr-3"
                icon={faUserPlus}
                animation={false}
                variant="light"
                size="sm"
              >
                <span className="font-weight-bold text-nowrap small">
                  + INVITE
                </span>
              </Button>
            </AddMemberInvite>

            <div className="">
              {/* {pendingInvites && pendingInvites.length > 0 ? <Badge variant="light">{pendingInvites.length}</Badge> : null} */}
              {
                pendingInvites && pendingInvites.length > 0 ? (
                  <Dropdown
                    alignRight
                    className="mt-4"
                  // className=" mt-4 mr-5"
                  >
                    <Dropdown.Toggle id="dropdown-basic" variant="transparent">
                      <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme">
                        <p className="small mb-0 text-center">
                          <Badge variant="light">{pendingInvites.length}</Badge>{" "}
                          waiting
                        </p>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="bg-theme text-theme">
                      {pendingInvites &&
                        pendingInvites.map((pendingInvite) => (
                          <PendingInviteAction
                            inviteeUserId={pendingInvite.inviteeUserId}
                          />
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                    <></>
                  )
                // <div className='shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme'>
                //   <p className="small mb-0 text-center">No pending invites</p>
                // </div>
              }
            </div>
          </IsAdmin>
          <div
            onClick={() => toggleFullScreen()}
            className="mx-2 text-center pointer fullscreen text-theme"
          >
            <FontAwesomeIcon icon={faExpand} />
          </div>
        </div>

        {/* Channel Search Input  
          <Input
            loading={searchLoading}
            onChange={handleSearchChange}
            size="mini"
            icon="search"
            name="searchTerm"
            placeholder="Search Messages"
          /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  displayRightPanel: state.platform.displayRightPanel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,

  showWhiteboard: state.whiteboard.showWhiteboard,
  layers: state.whiteboard.layers,
  background: state.whiteboard.background,

  showGradebook: state.gradebook.showGradebook,

  showAttendanceLedger: state.attendanceLedger.showAttendanceLedger,

  channelDetails: state.platform.channelDetails,
  isVideoVisible: state.platform.isVideoVisible,
  isPublisher: state.platform.isPublisher,
  conferenceSession: state.platform.conferenceSession
});

export default connect(mapStateToProps, {
  setRightPanel,
  setShowWhiteboard,
  saveWhiteboardLayers,
  saveWhiteboardBackground,
  setShowGradebook,
  setShowAttendanceLedger,

  setIsVideoVisible,
  setChannelDetails,
  setConferenceSession,
  setVideoDetails
})(MessagesHeader);
