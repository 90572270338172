import React from "react";
import {
  Button,
  Form,
  InputGroup,
  ProgressBar,
  Spinner,
  Badge,
  FormControl,
} from "react-bootstrap";
import firebase from "firebase";
import uuidv4 from "uuid/v4";
import mime from "mime-types";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import ReadMore from "../../Middle/ReadMore";
import DisplayResponse from "./DisplayResponse";

import { connect } from "react-redux";
import { publishRemark, publishMarks } from "./actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faCloudUploadAlt,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

class Assignment extends React.Component {
  state = {
    storageRef: firebase.storage().ref(),
    uploadTask: null,
    uploadState: [],
    percentUploaded: [],
    message: "",
    loading: false,
    errors: [],
    files: [],
    authorized: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/svg+xml",
      "image/gif",

      "application/pdf",

      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ],
    value: "",
    extension: "",
    admin: false,
    responses: [],
    answers: [],
    links: [],
    submit: false,
  };

  getResponses = (responses) => {
    let allResponses = [];
    responses &&
      Object.entries(responses).forEach(([key, value]) => {
        allResponses.push({ uid: key, ...value });
      });
    console.log(allResponses);
    this.setState({ responses: allResponses });
  };

  timeFromNow = (timestamp) => {
    if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
      return moment(timestamp).fromNow();
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
      return "yesterday";
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
      return moment(timestamp).format("dddd");
    else return moment(timestamp).format("MMM DD YYYY");
  };

  componentDidMount() {
    const { user, item, currentChannel } = this.props;
    if (user == item.user.id) {
      this.setState({ admin: true });
      firebase
        .database()
        .ref("messages/" + currentChannel.id)
        .child(item.uid)
        .on("value", (snap) => {
          snap.val() && this.getResponses(snap.val().response);
        });
      // item.response && this.getResponses(item.response)
    }
  }

  componentWillUnmount() {
    if (this.state.uploadTask !== null) {
      this.state.uploadTask.cancel();
      this.setState({ uploadTask: null });
    }
  }

  strip = (value) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = value.match(regExp);
    return match && match[2].length === 11 ? (
      <iframe
        style={{ borderRadius: "4px" }}
        width="100%"
        height="170"
        src={"//www.youtube.com/embed/" + match[2]}
        frameborder="0"
        allowfullscreen
      ></iframe>
    ) : // : <ReadMore full_text={value} max_length="250" />;
    value.length > 50 ? (
      value.slice(0, 50) + "…"
    ) : (
      value
    );
    // : value.length > 50 ? <ReadMore full_text={value} max_length="250" /> + '…' : value;
  };

  addFile = (event, key) => {
    const { files, links } = this.state;
    let prev = [...files];
    let prev_links = [...links];

    if (event.target.files[0]) {
      prev[key] = event.target.files[0];
      if (prev_links[key] && prev_links[key] !== "") prev_links[key] = "";
      this.setState({ files: prev, links: prev_links });

      // if (this.isAuthorized(event.target.files[0].name)) {
      //     const metadata = { contentType: mime.lookup(event.target.files[0].name) };
      //     this.uploadFile(event.target.files[0], metadata, key);
      //     this.clearFile();
      // }
    }
  };

  clickToUploadFile = (files, key) => {
    if (this.isAuthorized(files.name)) {
      const metadata = { contentType: mime.lookup(files.name) };
      this.uploadFile(files, metadata, key);
      this.clearFile();
    }
  };

  isAuthorized = (filename) =>
    this.state.authorized.includes(mime.lookup(filename));

  uploadFile = (file, metadata, key) => {
    const filePath = `chat/public/${uuidv4()}.${
      file.name.split(".").reverse()[0]
    }`;

    let preUploadState = [...this.state.uploadState];
    preUploadState[key] = "uploading";

    this.setState(
      {
        uploadState: preUploadState,
        uploadTask: this.state.storageRef.child(filePath).put(file, metadata),
      },
      () => {
        this.state.uploadTask.on(
          "state_changed",
          (snap) => {
            let prevPercentUpload = [...this.state.percentUploaded];
            const percentUpload = Math.round(
              (snap.bytesTransferred / snap.totalBytes) * 100
            );
            prevPercentUpload[key] = percentUpload;
            this.setState({ percentUploaded: prevPercentUpload });
          },
          (err) => {
            console.error(err);
            let preUploadState = [...this.state.uploadState];
            preUploadState[key] = "error";
            this.setState({
              errors: this.state.errors.concat(err),
              uploadState: preUploadState,
              uploadTask: null,
            });
          },
          () => {
            this.state.uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadUrl) => {
                let prev = [...this.state.links];
                prev[key] = downloadUrl;
                this.setState({ links: prev });
                console.log(downloadUrl);
              })
              .catch((err) => {
                console.error(err);
                let preUploadState = [...this.state.uploadState];
                preUploadState[key] = "error";
                this.setState({
                  errors: this.state.errors.concat(err),
                  uploadState: preUploadState,
                  uploadTask: null,
                });
              });
          }
        );
      }
    );
  };

  clearFile = () => this.setState({ file: null });

  getResult = (response, user) => {
    return response && Object.keys(response).includes(user);
  };

  getValue = (response, user) => {
    return (
      response && (
        <div>
          <Badge variant="secondary">Your answer</Badge>
          {response[user].value.map((answer, key) => (
            <div>
              <p className="font-weight-bold mb-0">
                {this.props.item.questions[key]}
              </p>
              {answer.value ? (
                <p className="my-0"> {this.strip(answer.value)} </p>
              ) : (
                ""
              )}
              {answer.file ? (
                <a className="my mb-0" href={answer.file} target="_blank">
                  Attachment
                </a>
              ) : (
                ""
              )}
            </div>
          ))}
          {/* <p className="mt-1 small mb-0"><i>{this.timeFromNow(response[user].responseTime)}</i></p> */}
          <div
            className="d-flex mt-2 pt-2 pb-1"
            style={{
              borderTop: 2,
              borderTopStyle: "dashed",
              borderTopColor: "#dbdbdb",
            }}
          >
            <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
            <p
              className="small pb-0 mb-0 flex-grow-1 px-2"
              style={{ color: "#777B8E" }}
            >
              You responded on{" "}
              {moment(response[user].responseTime).format(
                "MMM DD, YYYY hh:mm A"
              )}
            </p>
          </div>
        </div>
      )
    );
  };

  strip = (value) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = value.match(regExp);
    return match && match[2].length === 11 ? (
      <iframe
        style={{ borderRadius: "4px" }}
        width="100%"
        height="170"
        src={"//www.youtube.com/embed/" + match[2]}
        frameborder="0"
        allowfullscreen
      ></iframe>
    ) : (
      <ReadMore full_text={value} max_length="120" />
    );
  };

  render() {
    const {
      uid,
      taskResponse,
      item,
      user,
      open_task_branch,
      open_task_id,
    } = this.props;
    const {
      value,
      percentUploaded,
      uploadState,
      admin,
      responses,
      links,
      answers,
      files,
    } = this.state;

    // HANDLES ANSWERS ADDED
    const handleAnswers = (e, key) => {
      let prev = [...answers];
      prev[key] = e.target.value;
      this.setState({ answers: prev });
    };

    // TO COMBINE ANSWERS & LINKS
    const getAnswers = (answers, links) => {
      let dataSet = [];
      item.questions.map((question, key) =>
        dataSet.push({
          file: links[key] ? links[key] : "",
          value: answers[key] ? answers[key] : "",
        })
      );
      return dataSet;
    };

    const image_type = [
      "image/jpeg",
      "image/png",
      "image/svg+xml",
      "image/gif",
    ];

    const pdf_type = ["application/pdf"];

    const word_type = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    const excel_type = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const powerpoint_type = [
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];
    //console.log(item);

    return (
      <>
        <div className="taskBox">
          <Form className="project">
            <div className="d-flex">
              <h5 className="mt-0 flex-grow-1">Assignment</h5>
              <div className="mx-1">
                {item.deadline < Date.now() ? (
                  <Badge variant="secondary">Closed</Badge>
                ) : (
                  <Badge variant="primary">Active</Badge>
                )}
              </div>
              {open_task_branch === "saved" && open_task_id === item.uid && (
                <div className="mx-1">
                  <Badge variant="success">Saved</Badge>
                </div>
              )}
            </div>
            <span className="small d-block">
              Deadline: {this.timeFromNow(item.deadline)}
            </span>
            {admin ? (
              responses.length ? null : (
                <div>
                  {item.questions.map((question, key) => (
                    <>
                      <p className="mb-0">
                        <span className="font-weight-bold">Q{key + 1}.</span>{" "}
                        {question}
                      </p>
                      {item.descriptions && item.descriptions[key] ? (
                        <p className="mt-0 mb-1 text-secondary">
                          {item.descriptions[key]}
                        </p>
                      ) : null}
                    </>
                  ))}
                </div>
              )
            ) : null}
            {admin ? (
              responses.length ? (
                <Badge variant="secondary">
                  {" "}
                  {responses.length} Response(s)
                </Badge>
              ) : (
                <p className="my-1">No responses yet</p>
              )
            ) : null}
            {admin ? (
              responses.map((response) => (
                <DisplayResponse response={response} item={item} uid={uid} />
              ))
            ) : item && this.getResult(item.response, user) ? (
              <div>{this.getValue(item.response, user)}</div>
            ) : this.state.submit ? (
              <div>
                <Badge variant="secondary">Your answer</Badge>
                {item.questions.map((question, key) => (
                  <div>
                    <p className="font-weight-bold mb-0">
                      {this.props.item.questions[key]}
                    </p>
                    {answers[key] ? (
                      <p className="my-0"> {this.strip(answers[key])} </p>
                    ) : (
                      ""
                    )}
                    {links[key] ? (
                      <a className="my mb-0" href={links[key]} target="_blank">
                        Attachment
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
                {/* <p className="mt-1 small mb-0"><i>{this.timeFromNow(Date.now())}</i></p> */}
                <div
                  className="d-flex mt-2 pt-2 pb-1"
                  style={{
                    borderTop: 2,
                    borderTopStyle: "dashed",
                    borderTopColor: "#dbdbdb",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  />
                  <p
                    className="small pb-0 mb-0 flex-grow-1 px-2"
                    style={{ color: "#777B8E" }}
                  >
                    You responded on{" "}
                    {moment(Date.now()).format("MMM DD, YYYY hh:mm A")}
                  </p>
                </div>
              </div>
            ) : (
              <>
                {item && item.totalWeight > 0 && (
                  <p>
                    Weight: {item.totalWeight}{" "}
                    {item.totalWeight > 1 ? "points" : "point"}
                  </p>
                )}
                {item.questions.map((question, key) => (
                  <div className="mb-3">
                    <p className="font-weight-bold mb-0">Q: {question}</p>
                    {item.descriptions && item.descriptions[key] ? (
                      <p className="mt-0 mb-1 text-secondary">
                        {item.descriptions[key]}
                      </p>
                    ) : null}
                    {item && item.totalWeight > 0 && item.weights[key] > 0 && (
                      <p>
                        {item.weights[key]}{" "}
                        {item.weights[key] > 1 ? "points" : "point"}
                      </p>
                    )}

                    {item.deadline < Date.now() ? null : (
                      <InputGroup className="mb-1">
                        <TextareaAutosize
                          className="w-100 p-2 rounded"
                          style={{ borderColor: "#CDCDCD", minWidth: "300px" }}
                          name="answers[]"
                          value={answers[key]}
                          id={key}
                          onChange={(e) => handleAnswers(e, key)}
                          minRows={3}
                          maxRows={20}
                          placeholder="Your response"
                        />
                      </InputGroup>
                    )}

                    {this.state.files[key] && (
                      <div>
                        <div className="d-flex rounded bg-light mt-2">
                          <div
                            className="py-2 px-3"
                            style={{ backgroundColor: "#FEF2E4" }}
                          >
                            {!this.state.authorized.includes(
                              this.state.files[key].type
                            ) && (
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                className="text-danger"
                              />
                            )}
                            {image_type.includes(
                              this.state.files[key].type
                            ) && (
                              <FontAwesomeIcon
                                icon={faImage}
                                className="text-secondary"
                              />
                            )}
                            {word_type.includes(this.state.files[key].type) && (
                              <FontAwesomeIcon
                                icon={faFileWord}
                                className="text-primary"
                              />
                            )}
                            {pdf_type.includes(this.state.files[key].type) && (
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                className="text-danger"
                              />
                            )}
                            {powerpoint_type.includes(
                              this.state.files[key].type
                            ) && (
                              <FontAwesomeIcon
                                icon={faFilePowerpoint}
                                className="text-danger"
                              />
                            )}
                            {excel_type.includes(
                              this.state.files[key].type
                            ) && (
                              <FontAwesomeIcon
                                icon={faFileExcel}
                                className="text-success"
                              />
                            )}
                          </div>
                          <div className="p-2 flex-grow-1">
                            <small className="font-weight-bold">
                              {this.state.files[key].name}
                            </small>
                          </div>
                          <div className="py-2 px-3">
                            {!this.state.links[key] && (
                              <FontAwesomeIcon
                                icon={faCloudUploadAlt}
                                className="text-secondary"
                                onClick={() =>
                                  this.clickToUploadFile(
                                    this.state.files[key],
                                    key
                                  )
                                }
                              />
                            )}
                            {this.state.links[key] && (
                              <a href={this.state.links[key]} target="_blank">
                                <FontAwesomeIcon
                                  icon={faLink}
                                  className="text-primary"
                                />
                              </a>
                            )}
                          </div>
                        </div>
                        <ProgressBar
                          style={{ height: "0.2rem" }}
                          now={percentUploaded[key]}
                          striped
                          variant="success"
                        />

                        {!this.state.authorized.includes(
                          this.state.files[key].type
                        ) ? (
                          <small className="text-danger">
                            Sorry! but this file type is not supported yet.
                          </small>
                        ) : (
                          !this.state.links[key] &&
                          this.state.files[key] && (
                            <p className="small">
                              Click on{" "}
                              <FontAwesomeIcon
                                icon={faCloudUploadAlt}
                                className="text-secondary"
                              />{" "}
                              to upload, or click on "Attach" to choose another
                              file.
                            </p>
                          )
                        )}
                      </div>
                    )}
                    {/* {!this.state.links[key] && this.state.files[key] && <p className="small">Click on <FontAwesomeIcon icon={faCloudUploadAlt} className="text-secondary" /> to upload, or click on "Attach" to choose another file.</p>} */}
                    {this.state.links[key] && this.state.files[key] && (
                      <p className="small">
                        Click on{" "}
                        <FontAwesomeIcon
                          icon={faLink}
                          className="text-secondary"
                        />{" "}
                        to see attached file!
                      </p>
                    )}

                    {item.deadline < Date.now() ? null : (
                      <InputGroup className="mb-1">
                        <div class="file-input">
                          <input
                            type="file"
                            name={key}
                            onChange={(e) => {
                              this.addFile(e, key);
                            }}
                          />
                          <span class="button">Attach</span>
                          <span
                            class="label"
                            className="text-bold"
                            data-js-label
                          >
                            {this.state.files[key]
                              ? this.state.files[key].name
                              : "No file selected"}
                          </span>
                        </div>
                      </InputGroup>
                    )}
                  </div>
                ))}
                <Button
                  disabled={
                    item.deadline < Date.now() ||
                    !(
                      item.questions.length ===
                      Object.keys(item.questions).filter((a) =>
                        Object.keys(answers.filter((answer) => answer !== ""))
                          .concat(
                            Object.keys(links.filter((link) => link !== ""))
                          )
                          .includes(a)
                      ).length
                    )
                  }
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    this.setState({ submit: true });
                    taskResponse(uid, getAnswers(answers, links));
                  }}
                >
                  Submit
                </Button>

                {!(
                  item.questions.length == answers.length &&
                  item.questions.length == links.length
                ) && (
                  <p className="small mt-2 mb-0 pb-0">
                    <span className="font-weight-bold">Note: </span>Please
                    complete your Assignment to unlock submit.
                  </p>
                )}
              </>
            )}
          </Form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  open_task_branch: state.platform.open_task_branch,
  open_task_id: state.platform.open_task_id,
});

export default connect(mapStateToProps, { publishRemark, publishMarks })(
  Assignment
);
