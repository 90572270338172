/* Quiz Action Types */
export const ADD_QUIZ_ID = "ADD_QUIZ_ID";
export const ADD_QUIZ_TITLE = "ADD_QUIZ_TITLE";
export const ADD_QUIZ_QUESTIONS = "ADD_QUIZ_QUESTIONS";
export const ADD_QUIZ_OPTIONS = "ADD_QUIZ_OPTIONS";
export const ADD_QUIZ_TYPES = "ADD_QUIZ_TYPES";
export const ADD_QUIZ_CORRECT_ANSWERS = "ADD_QUIZ_CORRECT_ANSWERS";
export const ADD_QUIZ_WEIGHTS = "ADD_QUIZ_WEIGHTS";
export const ADD_QUIZ_TOTAL_WEIGHT = "ADD_QUIZ_TOTAL_WEIGHT";
export const SET_QUIZ_GRADED = "SET_QUIZ_GRADED";
export const SET_QUIZ_RANDOMIZE = "SET_QUIZ_RANDOMIZE";
export const RESET_QUIZ = "RESET_QUIZ";
export const ADD_QUIZ_OPTIONS_IMAGES = "ADD_QUIZ_OPTIONS_IMAGES";
export const ADD_QUIZ_OPTIONS_PREVIEW = "ADD_QUIZ_OPTIONS_PREVIEW";
export const ADD_QUIZ_QUESTION_IMAGES = "ADD_QUIZ_QUESTION_IMAGES";
export const ADD_QUIZ_QUESTION_PREVIEW_IMAGES = "ADD_QUIZ_QUESTION_PREVIEW_IMAGES";
export const SET_QUIZ_RANDOMIZE_QUESTION = "SET_QUIZ_RANDOMIZE_QUESTION";
//export const SET_SHARING = "SET_SHARING";
