import React, { useState, useEffect } from 'react';
import download from './education.svg';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  browserName, CustomView, osName
} from "react-device-detect";
import firebase from "firebase";

function WelcomeScreen() {

    const [showNonChromeWarnings, setShowNonChromeWarnings] = useState(false)
    const [msgNonChromeAndDesktopWarnings, setMsgNonChromeAndDesktopWarnings] = useState("")

    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection(`versions`)
            .doc(`web-errors`)
            .onSnapshot(doc => {
                if (doc.exists) {
                    const { showNonChromeWarnings, msgNonChromeAndDesktopWarnings } = doc.data();

                    setShowNonChromeWarnings(showNonChromeWarnings);
                    setMsgNonChromeAndDesktopWarnings(msgNonChromeAndDesktopWarnings)
                }
            }, err => {
                console.error(err)
            })

        return () => {
            unsubscribe()
        }
    })

    return (
        <React.Fragment>
        <div className="row align-items-center justify-content-md-center welcome bg-theme">
            <div className="col-md-6 text-center p-5" >
                <img src={download} width="300px" class="d-block mb-4" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                <h3 className="text-theme" style={{fontWeight: "300"}}>Welcome the new way of living. <br />Work & learn the way you want.</h3>
                <hr />
                <p style={{fontWeight: "300"}}>
                    <CustomView condition={showNonChromeWarnings && browserName != "Chrome"}>
                        <span className="text-warning">{msgNonChromeAndDesktopWarnings}</span>
                    </CustomView>
                    {/* <CustomView condition={osName === "Mac OS"}>
                        <span className="text-theme">Igesia is available for Mac. <a href="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/Apps%2Figesia-0.1.0.dmg?alt=media&token=90f98a60-2f5e-4499-a037-72562dd4e846">Get it here.</a></span>
                    </CustomView> */}
                    <CustomView condition={osName === "Windows"}>
                        <span className="text-theme">Igesia is available for Windows. <a href="https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/apps%2Fwindows%2Figesia%20Setup%200.1.0.exe?alt=media&token=4df0318b-32e1-4f66-aeb6-1bc47bce2f3c">Get it here.</a></span>
                    </CustomView> 
                </p>
            </div>
        </div>
        {/* <div className="left-bounce" style={{position: "absolute", top: "1rem"}}>
            <p className="small"> &#8592; Click here to create a room</p>
        </div> */}
        <div className="left-bounce" style={{position: "absolute", top: "7.8rem"}}>
            <p className="small text-theme"> &#8592; Click here to create a new room</p>
        </div>
        </React.Fragment>
    );
}



class Welcome extends React.Component {
    render() {
        return (
            <WelcomeScreen />
        )
    }
}

export default Welcome;

