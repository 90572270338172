import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faPlusCircle,
  faQuestionCircle,
  faAngleDown,
  faArrowLeft,
  faSearch,
  faTimes,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faSortAlphaUp,
  faSortAlphaUpAlt,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import axios from "axios";

import {
  Dropdown,
  Button,
  DropdownButton,
  Badge,
  Form,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
//import DisplayName from "./DisplayName";
import moment from "moment";
import {
  setTaskDeadline,
  setRightPanel,
  setContentTypeAndValue,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
  resetData,
  setSharedTask,
  setTaskSharing,
  setTaskEditing,
  setEditedTask,
  setEditedPublishedTask,
  setEditingPublished,
} from "../../../actions/index";
import {
  setAssignmentQuestions,
  setAssignmentDescriptions,
  setAssignmentWeights,
  setAssignmentTotalWeight,
  setAssignmentGraded,
  resetAssignment,
} from "./actions";
import {
  API_BASE_URL,
  ASSIGNMENT_CREATE,
  ASSIGNMENT_REMOVE,
  ASSIGNMENT_SHARE,
  ASSIGNMENT_UPDATE,
  ASSIGNMENT_EDIT,
  APP_CLOSE,
  ROOM_APP_LIST,
} from "../../../config/index";

import { toast } from "react-toastify";
import Assignment from "./Assignment1";
import CreateAssignment from "./CreateAssignment1";
import IsAdmin from "../../IsAdmin";
toast.configure();

class AssignmentHomepage extends Component {
  state = {
    selected: "Published",
    publishedAssignments: [],
    publishedAssignmentsData: [],
    savedAssignments: [],
    savedAssignmentsData: [],
    editedAssignment: {},
    AssignmentSubject: "",
    AssignmentDescription: "",
    selectedAssignment: {},
    sharedAgainAssignment: {},
    view: this.props.sharing ? 1 : 0,
    error: "",
    editedPublishedAssignment: {},
    editedAssignmentHasResponses: false,
    publishedAssignmentId: null,
    assignmentMessageData: null,
    searchTerm: "",
    regex: "",
    sortType: "unreverse",
    reversed: false,
  };

  async componentDidMount() {
    const { currentChannel, currentWorkspace, currentUser } = this.props;
    let savedAssignments = [];
    let publishedAssignments = [];

    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
      )
      .orderBy("timestamp", "desc");

    const queryMsg = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      )
      .orderBy("timestamp", "desc");

    query.where("type", "==", 5).onSnapshot((snapshot) => {
      axios
        .post(`${API_BASE_URL}${ROOM_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          user_id: currentUser.uid,
          message_type: 5,
          app_state: "saved",
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ savedAssignments: res.data.data });
            this.handleAssignmentsData(res.data.data);
            this.setState({ sortType: "unreverse" });
          }
        });
    });

    queryMsg.where("type", "==", 5).onSnapshot((snapshot) => {
      axios
        .post(`${API_BASE_URL}${ROOM_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          user_id: currentUser.uid,
          message_type: 5,
          app_state: "published",
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ publishedAssignments: res.data.data });
            this.handlePublishAssignmentsData(res.data.data);
            this.setState({ sortType: "unreverse" });
          }
        });
    });
  }

  /* componentDidUpdate () {
     //console.log(this.prevProps && this.prevProps.sharing, this.state.view, "respox")
     //this.setState({view: 1});
     if(this.props.sharing && this.state.view === 0) {
       this.setState({view: 1});
  
     }
   }*/
  handleAssignmentsData = async (savedAssignments) => {
    let savedAssignmentsData = [];
    savedAssignmentsData = await Promise.all(
      savedAssignments.map(async (savedAssignment) => {
        const assignmentData = await firebase
          .firestore()
          .collection(`assignments`)
          .doc(savedAssignment.contentId)
          .get();
        return {
          ...assignmentData.data(),
          id: assignmentData.id,
        };
      })
    );
    const assignmentData = _.sortBy(savedAssignmentsData, (assignment) =>
      assignment.assignmentSubject.toLowerCase()
    );
    if (this.state.sortType === "atoz") {
      savedAssignmentsData = assignmentData;
    }
    if (this.state.sortType === "ztoa") {
      savedAssignmentsData = assignmentData.reverse();
    }
    this.setState({ savedAssignments, savedAssignmentsData });
    if (savedAssignments.length === 0) {
      this.setState({ savedAssignments });
    }
  };

  handlePublishAssignmentsData = async (publishedAssignments) => {
    let publishedAssignmentsData = [];
    publishedAssignmentsData = await Promise.all(
      publishedAssignments.map(async (publishedAssignment) => {
        const assignmentData = await firebase
          .firestore()
          .collection(`assignments`)
          .doc(publishedAssignment.contentId)
          .get();
        return {
          ...assignmentData.data(),
          id: assignmentData.id,
          messageId: publishedAssignment.id,
        };
      })
    );
    const sortedAssignmentsData = _.sortBy(
      publishedAssignmentsData,
      (assignment) => assignment.assignmentSubject.toLowerCase()
    );
    if (this.state.sortType === "atoz") {
      publishedAssignmentsData = sortedAssignmentsData;
    }
    if (this.state.sortType === "ztoa") {
      publishedAssignmentsData = sortedAssignmentsData.reverse();
    }
    this.setState({ publishedAssignments, publishedAssignmentsData });
    if (publishedAssignments.length === 0) {
      this.setState({ publishedAssignments });
    }
  };
  addAssignmentView = (view) => {
    this.setState({ view });
  };
  handleSelected = (selected) => {
    this.setState({ selected });
  };
  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));
  showSelectedAssignment = (id) => {
    let selectedAssignment = { id: id };
    this.setState({ selectedAssignment, view: 2 });
  };
  handleSubAndDesc = (e) => {
    // console.log(e.target.value);
    if (e.target.name === "AssignmentSubject") {
      this.setState({ AssignmentSubject: e.target.value });
    } else {
      this.setState({ AssignmentDescription: e.target.value });
    }
  };

  sortAssignmentUp = () => {
    if (this.state.sortType === "reverse") {
      const { savedAssignmentsData, publishedAssignmentsData } = this.state;
      this.setState(
        {
          savedAssignmentsData: savedAssignmentsData.reverse(),
          publishedAssignmentsData: publishedAssignmentsData.reverse(),
        },
        () => {
          this.setState({ reversed: true });
        }
      );
    } else if (this.state.sortType === "unreverse") {
      if (this.state.reversed) {
        const { savedAssignmentsData, publishedAssignmentsData } = this.state;
        this.setState(
          {
            savedAssignmentsData: savedAssignmentsData.reverse(),
            publishedAssignmentsData: publishedAssignmentsData.reverse(),
          },
          () => {
            this.setState({ reversed: true });
          }
        );
      } else {
        const { savedAssignmentsData, publishedAssignmentsData } = this.state;
        this.setState({
          savedAssignmentsData,
          publishedAssignmentsData,
        });
      }
      this.setState({ reversed: false });
    } else if (this.state.sortType === "atoz") {
      const { savedAssignmentsData, publishedAssignmentsData } = this.state;
      this.setState(
        {
          savedAssignmentsData: _.sortBy(savedAssignmentsData, (assignment) =>
            assignment.assignmentSubject.toLowerCase()
          ),
          publishedAssignmentsData: _.sortBy(
            publishedAssignmentsData,
            (assignment) => assignment.assignmentSubject.toLowerCase()
          ),
        },
        () => {
          this.setState({ reversed: false });
        }
      );
    } else if (this.state.sortType === "ztoa") {
      const { savedAssignmentsData, publishedAssignmentsData } = this.state;
      this.setState(
        {
          savedAssignmentsData: _.sortBy(savedAssignmentsData, (assignment) =>
            assignment.assignmentSubject.toLowerCase()
          ).reverse(),
          publishedAssignmentsData: _.sortBy(
            publishedAssignmentsData,
            (assignment) => assignment.assignmentSubject.toLowerCase()
          ).reverse(),
        },
        () => {
          this.setState({ reversed: false });
        }
      );
    }
  };

  render() {
    const {
      type,
      currentWorkspace,
      currentChannel,
      currentUser,
      questions,
      descriptions,
      weights,
      totalWeight,
      graded,
      deadline,
      channelUsers,
      folder,
      everyone,
      allParticipants,
      taskType,
      contentType,
      resetAllTasks,
      setTaskType,
      setContentType,
      resetData,
      setAssignmentDescriptions,
      setAssignmentQuestions,
      setTaskChannelUsers,
      setTaskEveryone,
      setTaskDeadline,
      setSharedTask,
      setTaskSharing,
      sharing,
      sharedTask,
      editing,
      editedTask,
      setTaskEditing,
      setEditedTask,
      editingPublished,
      editedPublishedTask,
      darkTheme,
    } = this.props;
    let {
      savedAssignments,
      savedAssignmentsData,
      editedAssignment,
      publishedAssignments,
      publishedAssignmentsData,
      selected,
      view,
      error,
      selectedAssignment,
      AssignmentDescription,
      AssignmentSubject,
      sharedAgainAssignment,
      editedPublishedAssignment,
    } = this.state;

    let coAdmins = allParticipants.filter((participant) => participant.isAdmin);
    console.log(coAdmins);

    const shareTaskAgain = async (taskData) => {
      let questions = [];
      let descriptions = [];
      const responses = await firebase
        .firestore()
        .collection("assignments")
        .doc(taskData.id)
        .collection("responses")
        .get();
      //console.log(responses.size,"respox")
      for (let i = 0; i < taskData.questions.length; i++) {
        questions.push(taskData.questions[i].question);
        descriptions.push(taskData.questions[i].description);
      }
      //this.props.setTaskChannelUsers(taskData.assignedTo);
      if (
        (taskData.all === false &&
          taskData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        taskData.all === true
      ) {
        this.props.setTaskChannelUsers(taskData.assignedTo);
      } else {
        let assignedTo = taskData.assignedTo.filter((user) =>
          allParticipants.find(
            (User) => User.uid === user || user === currentChannel.createdBy
          )
        );
        //console.log(assignedTo);
        this.props.setTaskChannelUsers(assignedTo);
      }

      if (responses.size > 0 && editingPublished) {
        this.setState({ editedAssignmentHasResponses: true });
      }

      if (editingPublished) {
        this.setState({
          assignmentMessageData: taskData,
          publishedAssignmentId: taskData.id,
        });
      }

      this.props.setTaskEveryone(taskData.all);
      this.props.setTaskDeadline(new Date(taskData.deadline));
      this.props.setAssignmentQuestions(questions);
      this.props.setAssignmentDescriptions(descriptions);
      this.props.setEditedPublishedTask({});

      this.props.setTaskFolder(taskData.folderId);
      //console.log("respoxxxx")
      setSharedTask({});
      // setTaskSharing(false);
      setTaskEditing(false);
      setEditedTask({});
      if (editing) {
        this.setState({
          editedAssignment: taskData,
          AssignmentSubject: taskData.assignmentSubject,
          AssignmentDescription: taskData.assignmentDescription,
          view: 1,
        });
      } else if (editingPublished) {
        this.setState({
          editedPublishedAssignment: taskData,
          AssignmentSubject: taskData.assignmentSubject,
          AssignmentDescription: taskData.assignmentDescription,
          view: 1,
        });
      } else {
        this.setState({
          sharedAgainAssignment: taskData,
          AssignmentSubject: taskData.assignmentSubject,
          AssignmentDescription: taskData.assignmentDescription,
          view: 1,
        });
      }
    };

    if (
      sharing &&
      Object.keys(sharedTask).length > 0 &&
      Object.keys(sharedAgainAssignment).length === 0
    ) {
      shareTaskAgain(sharedTask);
    }
    if (
      editing &&
      Object.keys(editedTask).length > 0 &&
      Object.keys(editedAssignment).length === 0
    ) {
      shareTaskAgain(editedTask);
    }

    if (
      editingPublished &&
      Object.keys(editedPublishedTask).length > 0 &&
      Object.keys(editedAssignment).length === 0
    ) {
      shareTaskAgain(editedPublishedTask);
    }

    const validateData = () => {
      let filtered_questions = questions.filter(
        (question) => question.length > 0
      );
      if (questions.length !== filtered_questions.length) {
        this.setState({ error: "Please add some value to question(s).\n" });
        return null;
      }
      if (questions.length === 0) {
        this.setState({
          error: "Assignment must have at-least one or more question(s).\n",
        });
        return null;
      }

      if (graded && parseInt(totalWeight) === 0) {
        this.setState({ error: "Total weight should be greater than '0'.\n" });
        return null;
      }

      let filtered_weights = weights.filter((weight) => parseInt(weight) > 0);
      if (graded && weights.length !== filtered_weights.length) {
        this.setState({ error: "Please add some value to weight(s).\n" });
        return null;
      }

      if (moment(deadline).diff(moment(Date.now()), "minutes") < 9) {
        this.setState({
          error: "Deadline of task should be atleast 10 minutes.\n",
        });
        return null;
      }

      if (!everyone && channelUsers.length < 1) {
        this.setState({
          error: "Please assign task to atleast one participant.\n",
        });
        return null;
      }
      if (AssignmentSubject.trim().length < 1) {
        this.setState({
          error: "Please add some value to Assignemnt Subject.\n",
        });
        return null;
      }

      this.setState({ error: "" });
      return {
        type,
        questions,
        descriptions,
        weights,
        totalWeight,
        channelUsers,
        deadline,
        folder,
        everyone,
      };
    };

    const saveTask = async (data) => {
      const {
        setAssignmentDescriptions,
        setAssignmentQuestions,
        setTaskDeadline,
        setTaskChannelUsers,
        resetAssignment,
        setTaskEveryone,
        setTaskFolder,
        darkTheme,
      } = this.props;
      const { editedAssignment } = this.state;
      // console.log(editedAssignment);
      let messageData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        user: {
          id: currentUser.uid,
          name: currentUser.displayName,
          avatar: currentUser.photoURL,
        },
        type: parseInt(data["type"]),
        createdBy: currentUser.uid,
        assignedTo: data["channelUsers"],
        all: data["everyone"],
        folderId: data["folder"] ? data["folder"] : "",
      };

      let readData = [];
      readData[currentUser.uid] = { read: true, readtime: Date.now() };

      let questionsArrayWithObjects = [];
      for (let i = 0; i < questions.length; i++) {
        let json = {
          question: questions[i],
          description: descriptions[i],
          questionId: i + 1,
          weight: weights[i],
        };
        questionsArrayWithObjects.push(json);
      }
      let taskData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: currentUser.uid,
        folderId: data["folder"],
        totalWeight: data["totalWeight"],
        assignmentSubject: AssignmentSubject,
        assignmentDescription: AssignmentDescription,
        questions: questionsArrayWithObjects,
        deadline: moment(data["deadline"]).valueOf(),
        all: data["everyone"],
        response: [],
        assignedTo: data["channelUsers"],
      };

      //const task = firebase.firestore().collection(`assigments`);
      /* const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );*/
      //console.log(taskData);
      if (Object.keys(editedAssignment).length > 0) {
        let savedAssignmentDoc = savedAssignments.find(
          (assignment) => assignment.contentId === editedAssignment.id
        );
        let savedAssignmentId = savedAssignmentDoc.id;
        try {
          let res = await axios
            .post(`${API_BASE_URL}${ASSIGNMENT_UPDATE}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              saved_assignment_id: savedAssignmentId,
              deadline: moment(data["deadline"]).valueOf(),
              assignment_subject: AssignmentSubject,
              assignment_description: AssignmentDescription,
              questions: questionsArrayWithObjects,
              all_participants: data["everyone"],
              assigned: data["channelUsers"],
              folderId: data["folder"] ? data["folder"] : "",
              save_for_later: true,
              is_graded: graded || false,
            })
            .then(() => {
              const task = firebase.firestore().collection("assignments");
              task
                .doc(savedAssignmentId)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    let index = savedAssignments.findIndex(
                      (assignment) => assignment.id === doc.id
                    );
                    if (index !== -1) {
                      savedAssignmentsData.splice(index, 1, {
                        ...doc.data(),
                        id: doc.id,
                      });
                      this.setState(savedAssignmentsData);
                    }
                  }
                });
              this.setState({ view: 0, editedAssignment: {} }, () => {
                if (darkTheme) {
                  toast.dark("Assignment updated and saved.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast("Assignment updated and saved.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }
              });
              this.props.resetData();
              this.props.setAssignmentDescriptions([]);
              this.props.setAssignmentQuestions([]);
              this.props.setTaskChannelUsers([]);
              this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
              this.props.setTaskEveryone(false);
              this.props.setTaskFolder("");
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let res = await axios
            .post(`${API_BASE_URL}${ASSIGNMENT_CREATE}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              deadline: moment(data["deadline"]).valueOf(),
              assignment_subject: AssignmentSubject,
              assignment_description: AssignmentDescription,
              questions: questionsArrayWithObjects,
              all_participants: data["everyone"],
              assigned: data["channelUsers"],
              folderId: data["folder"] ? data["folder"] : "",
              save_for_later: true,
              is_graded: graded || false,
            })
            .then(() => {
              this.setState(
                {
                  AssignmentSubject: "",
                  AssignmentDescription: "",
                  view: 0,
                  editedAssignment: {},
                },
                () => {
                  if (darkTheme) {
                    toast.dark("Assignment saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  } else {
                    toast("Assignment saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  }
                }
              );
              this.props.resetData();
              this.props.setAssignmentDescriptions([]);
              this.props.setAssignmentQuestions([]);
              this.props.setTaskChannelUsers([]);
              this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
              this.props.setTaskEveryone(false);
              this.props.setTaskFolder("");
            });
        } catch (error) {
          console.log(error);
        }

        /* try {
          const taskRef = await firebase
            .firestore()
            .collection(`assignments`)
            .doc(editedAssignment.id);
          //  console.log(taskRef);
          taskRef.update({
            ...taskData,
          });
          //console.log(taskRef);
          db.doc(savedAssignmentDoc.id)
            .update({ ...messageData, contentId: taskRef.id })
            .then((docRef) => {
              //resetData();
              setAssignmentDescriptions([]);
              setAssignmentQuestions([]);
              setTaskChannelUsers([]);
              setTaskDeadline(new Date(Date.now() + 10 * 600000));
              setTaskEveryone(false);
              setTaskFolder("");
              this.setState(
                {
                  AssignmentSubject: "",
                  AssignmentDescription: "",
                  view: 0,
                  editedAssignment: {},
                },
                () =>
                  toast("Assignment updated and saved.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  })
              );
              
            })
            .catch((err) => console.error(err));
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const taskRef = await firebase
            .firestore()
            .collection(`assignments`)
            .doc();
          taskRef.set({
            ...taskData,
          });
          //console.log(taskRef);
          db.add({ ...messageData, contentId: taskRef.id })
            .then((docRef) => {
              //resetData();
              setAssignmentDescriptions([]);
              setAssignmentQuestions([]);
              setTaskChannelUsers([]);
              setTaskDeadline(new Date(Date.now() + 10 * 600000));
              setTaskEveryone(false);
              setTaskFolder("");
              this.setState(
                {
                  AssignmentSubject: "",
                  AssignmentDescription: "",
                  view: 0,
                  editedAssignment: {},
                },
                () =>
                  toast("Assignment has saved.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  })
              );
              
            })
            .catch((err) => console.error(err));
        } catch (error) {
          console.log(error);
        }*/
      }
    };

    const addTask = async (data) => {
      // console.log(data);
      if (data) {
        const { editedAssignment } = this.state;
        let messageData = {
          timestamp: Date.now(),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          user: {
            id: currentUser.uid,
            name: currentUser.displayName,
            avatar: currentUser.photoURL,
          },
          type: parseInt(data["type"]),
          createdBy: currentUser.uid,
          assignedTo: data["channelUsers"],
          all: data["everyone"],
          folderId: data["folder"] ? data["folder"] : "",
        };

        let readData = [];
        readData[currentUser.uid] = { read: true, readtime: Date.now() };

        let questionsArrayWithObjects = [];
        for (let i = 0; i < questions.length; i++) {
          let json = {
            question: questions[i],
            description: descriptions[i],
            questionId: i + 1,
            weight: weights[i],
          };
          questionsArrayWithObjects.push(json);
        }

        let taskData = {
          timestamp: Date.now(),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: currentUser.uid,
          folderId: data["folder"],
          totalWeight: data["totalWeight"],
          assignmentSubject: AssignmentSubject,
          assignmentDescription: AssignmentDescription,
          questions: questionsArrayWithObjects,
          deadline: moment(data["deadline"]).valueOf(),
          all: data["everyone"],
          response: [],
          assignedTo: data["channelUsers"],
          is_graded: graded || false,
        };
        console.log(taskData);
        if (Object.keys(editedAssignment).length > 0) {
          let savedAssignment = savedAssignments.find(
            (obj) => obj.contentId === editedAssignment.id
          );
          let savedAssignmentId = savedAssignment.id;

          try {
            let res = await axios
              .post(`${API_BASE_URL}${ASSIGNMENT_UPDATE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                assignment_subject: AssignmentSubject,
                assignment_description: AssignmentDescription,
                questions: questionsArrayWithObjects,
                saved_assignment_id: savedAssignmentId,
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                folderId: data["folder"] ? data["folder"] : "",
                is_graded: graded || false,
              })
              .then(async () => {
                await axios.post(`${API_BASE_URL}${ASSIGNMENT_SHARE}`, {
                  user_id: currentUser.uid,
                  workspace_id: currentWorkspace.workspaceId,
                  room_id: currentChannel.roomId,
                  saved_assignment_id: savedAssignmentId,
                });
              })
              .then(() => {
                if (darkTheme) {
                  toast.dark("Assignment updated and shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast("Assignment updated and shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }

                this.props.resetData();
              });
          } catch (error) {
            console.log(error);
          }
        } else if (Object.keys(editedPublishedAssignment).length > 0) {
          console.log(
            JSON.stringify({
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              deadline: moment(data["deadline"]).valueOf(),
              assignment_subject: AssignmentSubject,
              assignment_description: AssignmentDescription,
              questions: questionsArrayWithObjects,
              published_assignment_id: this.state.publishedAssignmentId,
              all_participants: data["everyone"],
              assigned: data["channelUsers"],
              folderId: data["folder"] ? data["folder"] : "",
              is_graded: graded || false,
            }),
            "Data"
          );
          try {
            let res = await axios
              .post(`${API_BASE_URL}${ASSIGNMENT_EDIT}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                assignment_subject: AssignmentSubject,
                assignment_description: AssignmentDescription,
                questions: questionsArrayWithObjects,
                published_assignment_id: this.state.publishedAssignmentId,
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                folderId: data["folder"] ? data["folder"] : "",
                is_graded: graded || false,
              })
              .then(() => {
                if (darkTheme) {
                  toast.dark("Assignment edited and shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast("Assignment edited and shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }

                this.props.resetData();
              });
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            let res = await axios
              .post(`${API_BASE_URL}${ASSIGNMENT_CREATE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                assignment_subject: AssignmentSubject,
                assignment_description: AssignmentDescription,
                questions: questionsArrayWithObjects,
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                folderId: data["folder"] ? data["folder"] : "",
                save_for_later: false,
                is_graded: graded || false,
              })
              .then(() => {
                if (darkTheme) {
                  toast.dark("Assignment shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast("Assignment shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }

                this.props.resetData();
              });
            console.log(res);
          } catch (error) {
            console.log(error);
          }
        }
      }

      /*const dbMsg = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );

      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);
      if (Object.keys(editedAssignment).length > 0) {
        let savedAssignmentDoc = savedAssignments.find(
          (assignment) => assignment.contentId === editedAssignment.id
        );
        // console.log(savedAssignmentDoc);
        //console.log(editedAssignment);
        //console.log(taskData);
        //console.log(messageData);
        //console.log(currentChannel);

        firebase
          .firestore()
          .collection(`assignments`)
          .doc(editedAssignment.id)
          .update({
            ...taskData,
          })
          .then(() => {
            dbMsg
              .add({ ...messageData, contentId: editedAssignment.id })
              .then((docRef) => {
                dbUpdate
                  .doc(`${currentChannel.roomId}`)
                  .update({
                    totalMessages: firebase.firestore.FieldValue.increment(1),
                    lastMessage: {
                      ...messageData,
                      contentId: editedAssignment.id,
                      uid: docRef.id,
                    },
                  })
                  .then(() => {
                    this.setState(
                      {
                        editedAssignment: {},
                        assignmentSubject: "",
                        assignmentDescription: "",
                      },
                      () =>
                        toast("Assignment shared", {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        })
                    );

                    db.doc(savedAssignmentDoc.id).delete();
                    this.props.resetData();
                  });
              })
              .catch((err) => console.error(err));
          });
      } else {
        try {
          const taskRef = await firebase
            .firestore()
            .collection(`assignments`)
            .doc();
          taskRef.set({
            ...taskData,
          });
          //console.log(taskRef);
          dbMsg
            .add({ ...messageData, contentId: taskRef.id })
            .then((docRef) => {
              dbUpdate
                .doc(`${currentChannel.roomId}`)
                .update({
                  totalMessages: firebase.firestore.FieldValue.increment(1),
                  lastMessage: {
                    ...messageData,
                    contentId: taskRef.id,
                    uid: docRef.id,
                  },
                })
                .then(() => {
                  toast("Assignment shared", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  this.props.resetData();
                });
            })
            .catch((err) => console.error(err));
        } catch (error) {
          console.log(error);
        }
      }*/
    };

    const shareSavedTask = async (assignmentId) => {
      const { resetData } = this.props;
      let messageData = savedAssignments.find(
        (assignment) => assignment.contentId === assignmentId
      );
      let assignmentData = savedAssignmentsData.find(
        (assignment) => assignment.id === assignmentId
      );
      if (!assignmentData.all && assignmentData.assignedTo.length === 0) {
        toast.warning(
          "Share Failed: Assignment is not assigned to any participant.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        return null;
      }

      if (assignmentData.createdBy !== currentUser.uid) {
        toast.warning("Share Failed: Assignment is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }

      console.log(assignmentData);
      console.log(messageData);
      if (assignmentData.deadline < Date.now()) {
        toast.warning(
          "Deadline has passed,please edit Assignment to change deadline.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        messageData.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants have left the group, please edit  to reassign  participants.",

          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      let savedAssignmentId = messageData.id;
      try {
        let res = await axios
          .post(`${API_BASE_URL}${ASSIGNMENT_SHARE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            saved_assignment_id: savedAssignmentId,
          })
          .then(() => {
            if (darkTheme) {
              toast.dark("Assignment shared.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            } else {
              toast("Assignment shared.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            }

            this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }

      /* messageData.timestamp = Date.now();
      const dbMsg = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

      dbMsg
        .add({ ...messageData })
        .then((docRef) => {
          dbUpdate
            .doc(`${currentChannel.roomId}`)
            .update({
              totalMessages: firebase.firestore.FieldValue.increment(1),
              lastMessage: {
                ...messageData,
                contentId: messageData.contentId,
                uid: docRef.id,
              },
            })
            .then(() => {
              toast("Assignment shared", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              resetData();
              db.doc(messageData.id).delete();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));*/
    };

    const editPublishedTask = async (message_id) => {
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      let messageDataObj = await query
        .doc(message_id)
        .get()
        .then((doc) => {
          console.log(doc.data());
          if (doc.exists) {
            return { ...doc.data(), id: doc.id };
          }
        });
      console.log(messageDataObj);
      let messageData = {};
      if (messageDataObj.type === 5) {
        messageData = await firebase
          .firestore()
          .collection("assignments")
          .doc(messageDataObj.contentId)
          .get()
          .then((doc) => {
            return { ...doc.data(), id: doc.id };
          });
      }
      this.props.resetData();
      this.props.setRightPanel(true);
      this.props.setEditingPublished(true);
      setTimeout(
        () => this.props.setContentTypeAndValue("createTask", "Assignment"),
        500
      );
      setTimeout(() => this.props.setEditedPublishedTask(messageData), 700);
    };

    const editSavedTask = (assignmentId) => {
      const { resetData } = this.props;
      let taskData = savedAssignmentsData.find(
        (assignment) => assignment.id === assignmentId
      );
      if (taskData.createdBy !== currentUser.uid) {
        toast.warning("Edit Failed: Assignment is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }
      console.log(taskData);
      let questions = [];
      let descriptions = [];
      for (let i = 0; i < taskData.questions.length; i++) {
        questions.push(taskData.questions[i].question);
        descriptions.push(taskData.questions[i].description);
      }
      //this.props.setTaskChannelUsers(taskData.assignedTo);
      if (
        (taskData.all === false &&
          taskData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        taskData.all === true
      ) {
        this.props.setTaskChannelUsers(taskData.assignedTo);
      } else {
        let assignedTo = taskData.assignedTo.filter((user) =>
          allParticipants.find(
            (User) => User.uid === user || user === currentChannel.createdBy
          )
        );
        console.log(assignedTo);
        this.props.setTaskChannelUsers(assignedTo);
      }
      this.props.setTaskEveryone(taskData.all);
      this.props.setTaskDeadline(new Date(taskData.deadline));
      this.props.setAssignmentQuestions(questions);
      this.props.setAssignmentDescriptions(descriptions);
      this.props.setTaskFolder(taskData.folderId);
      this.setState({
        editedAssignment: taskData,
        AssignmentSubject: taskData.assignmentSubject,
        AssignmentDescription: taskData.assignmentDescription,
        view: 1,
      });
    };

    const closeAppResponses = async (assignment) => {
      const { currentWorkspace, currentChannel, darkTheme } = this.props;
      console.log(assignment, "iddd");
      var message_id = assignment.messageId;
      var user = firebase.auth().currentUser;
      let res = await axios.post(`${API_BASE_URL}${APP_CLOSE}`, {
        workspace_id: currentWorkspace.workspaceId,
        room_id: currentChannel.roomId,
        user_id: user.uid,
        message_id: message_id,
      });
      if (res.status === 200) {
        if (darkTheme) {
          toast.dark("Closed", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast("Closed", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      } else {
        console.log(res);
      }
    };

    const deleteSavedTask = async (assignmentId) => {
      let messageData = savedAssignments.find(
        (assignment) => assignment.contentId === assignmentId
      );
      let savedAssignmentId = messageData.id;
      if (messageData.createdBy !== currentUser.uid) {
        toast.warning("Deletion Failed: Assignment is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }

      try {
        let res = await axios
          .post(`${API_BASE_URL}${ASSIGNMENT_REMOVE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            saved_assignment_id: savedAssignmentId,
          })
          .then(() => {
            if (darkTheme) {
              toast.dark("Assignment deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            } else {
              toast("Assignment deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            }

            //this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }
      /* const dbPolls = firebase.firestore().collection(`assignments`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      db.doc(messageId)
        .delete()
        .then(() => {
          dbPolls.doc(assignmentId).delete();
          this.props.setAssignmentDescriptions([]);
          this.props.setAssignmentQuestions([]);
          this.props.setTaskChannelUsers([]);
          this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
          this.props.setTaskEveryone(false);
          this.props.setTaskFolder("");
          this.setState(
            {
              editedAssignment: {},
              AssignmentSubject: "",
              AssignmentDescription: "",
            },
            () =>
              toast("Assigment deleted", {
                position: toast.POSITION.BOTTOM_RIGHT,
              })
          );
        })
        .catch((err) => console.log(err));*/
    };
    let canSeeTaskCurrentUser = publishedAssignmentsData.filter(
      (assignment) =>
        currentChannel?.createdBy === currentUser.uid ||
        assignment.all === true ||
        coAdmins.find((usr) => usr.uid === currentUser.uid) ||
        (assignment.assignedTo &&
          assignment.assignedTo.includes(currentUser.uid))
    );
    // console.log(canSeeTaskCurrentUser);
    return view === 1 ? (
      <>
        <div
          onClick={() => {
            this.props.setAssignmentDescriptions([]);
            this.props.setAssignmentQuestions([]);
            this.props.setTaskChannelUsers([]);
            this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
            this.props.setTaskEveryone(false);
            this.props.setEditingPublished(false);
            this.props.setTaskFolder("");
            this.setState({
              view: 0,
              editedAssignment: {},
              AssignmentSubject: "",
              AssignmentDescription: "",
            });
          }}
          className="mb-1 p-2 bg-card-theme text-theme"
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
          <span>
            {Object.keys(editedAssignment).length > 0
              ? "Update Assignment"
              : "Create Assignment"}
          </span>
        </div>
        <CreateAssignment
          allParticipants={this.props.allParticipants}
          saveTask={saveTask}
          addTask={addTask}
          editedAssignment={
            Object.keys(editedAssignment).length > 0
              ? editedAssignment
              : sharedAgainAssignment
          }
          AssignmentSubject={AssignmentSubject}
          AssignmentDescription={AssignmentDescription}
          handleSubAndDesc={this.handleSubAndDesc}
          validateData={validateData}
          editedAssignmentHasResponses={this.state.editedAssignmentHasResponses}
          editingPublished={this.props.editingPublished}
          publishedAssignmentId={this.state.publishedAssignmentId}
          error={error}
          assignmentMessageData={this.state.assignmentMessageData}
        />
      </>
    ) : view === 0 ? (
      <>
        <div
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
          className="m-2 rounded p-2 bg-card-theme text-theme"
        >
          <div className="d-flex">
            <div
              onClick={() => {
                setContentType(null);
                setTaskType(null);
                resetAllTasks();
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
              <span>
                {contentType === "createTask"
                  ? taskType
                    ? taskType + "s"
                    : "Create Task"
                  : ""}
              </span>
            </div>
          </div>
          <IsAdmin>
            <div className="mt-2">
              {/*<p className="mb-2 small text-theme-lighter">
                Request opinion from participants in the room and collect
                responses in real time. Users can respond to assignment easily
                from their message timeline.
              </p>*/}
              <div className="d-flex">
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => this.addAssignmentView(1)}
                  className="pt-0 w-50"
                >
                  <small>+ CREATE NEW ASSIGNMENT</small>
                </Button>

                {/* <span className="mt-2 ml-3 text-secondary pointer smaller">
                  <FontAwesomeIcon icon={faQuestionCircle} /> WATCH TUTORIAL
                </span> */}
              </div>
            </div>
          </IsAdmin>
        </div>

        <IsAdmin>
          <div className="row m-2 border-1 rounded">
            <div
              className={
                selected === "Published"
                  ? "col-6 text-theme bg-card-theme rounded py-1 d-flex justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Published")}
              style={{ cursor: "pointer" }}
            >
              Published
            </div>

            <div
              className={
                selected === "Saved"
                  ? "col-6 text-theme bg-card-theme rounded d-flex py-1 justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Saved")}
              style={{ cursor: "pointer" }}
            >
              Saved
            </div>
          </div>
        </IsAdmin>

        {selected === "Published" ? (
          publishedAssignmentsData.length > 0 &&
          canSeeTaskCurrentUser.length > 0 ? (
            <div>
              <div className="d-flex justify-content-between">
                <InputGroup className="my-1 outerPill">
                  <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                    <div className="py-2 pr-2 pl-3">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="sm"
                        color="#aeaeae"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <Form.Control
                        type="text"
                        id="participantsSearch"
                        className="border-0 px-0 bg-theme text-theme rounded"
                        onChange={(e) => {
                          this.setState({ searchTerm: e.target.value }, () => {
                            const reg = _.escapeRegExp(this.state.searchTerm);
                            // console.log(reg,"Reg")
                            const regex = new RegExp(reg, "gi");
                            this.setState({ regex: regex });
                          });
                        }}
                        placeholder={"Search"}
                        value={this.state.searchTerm}
                        style={{ margin: 0 }}
                      />
                    </div>
                    {this.state.searchTerm.length > 0 && (
                      <div
                        className="py-2 pl-2 pr-3"
                        onClick={() => this.setState({ searchTerm: "" })}
                      >
                        <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                      </div>
                    )}
                  </div>
                </InputGroup>
                {this.state.sortType === "unreverse" ? (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "reverse" }, () => {
                        this.sortAssignmentUp();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAmountUpAlt} color="#aeaeae" />
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "unreverse" }, () => {
                        this.sortAssignmentUp();
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSortAmountDownAlt}
                      color="#aeaeae"
                    />
                  </Button>
                )}

                {this.state.sortType === "atoz" ? (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "ztoa" }, () => {
                        this.sortAssignmentUp();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUp} color="#aeaeae" />
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "atoz" }, () => {
                        this.sortAssignmentUp();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUpAlt} color="#aeaeae" />
                  </Button>
                )}
              </div>
              {this.state.searchTerm && this.state.searchTerm.length > 0 ? (
                <>
                  {publishedAssignmentsData
                    .filter((item) => {
                      return item.assignmentSubject?.match(this.state.regex);
                    })
                    .map((assignment, index) =>
                      currentChannel?.createdBy === currentUser.uid ||
                      assignment.all === true ||
                      coAdmins.find((usr) => usr.uid === currentUser.uid) ||
                      (assignment.assignedTo &&
                        assignment.assignedTo.includes(currentUser.uid)) ? (
                        <div
                          key={index + assignment.id}
                          className={
                            assignment.deadline > Date.now()
                              ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
                              : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
                          }
                        >
                          <div
                            className="d-flex justify-content-between mb-1 pointer"
                            onClick={() =>
                              this.showSelectedAssignment(assignment.id)
                            }
                          >
                            <span className="text-theme-lighter smaller">
                              {moment(assignment.timestamp).format("LLL")}
                            </span>
                            <span
                              className={
                                assignment.deadline > Date.now()
                                  ? "text-success smaller"
                                  : "text-secondary smaller"
                              }
                            >
                              {assignment.deadline > Date.now()
                                ? "Active"
                                : "Closed"}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p
                              className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
                              onClick={() =>
                                this.showSelectedAssignment(assignment.id)
                              }
                            >
                              {assignment.assignmentSubject}
                            </p>
                            <IsAdmin>
                              <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                                <Dropdown.Toggle
                                  size="sm"
                                  as={this.CustomToggle}
                                />
                                <Dropdown.Menu className="bg-card-theme text-theme">
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() => shareTaskAgain(assignment)}
                                  >
                                    Duplicate{" "}
                                    {/*<small className="text-warning">Coming Soon</small>*/}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() =>
                                      editPublishedTask(assignment.messageId)
                                    }
                                  >
                                    Edit{" "}
                                    {/*<small className="text-warning">Coming Soon</small>*/}
                                  </Dropdown.Item>
                                  {assignment.deadline > Date.now() ? (
                                    <Dropdown.Item
                                      className="small bg-card-theme text-theme"
                                      onClick={() =>
                                        closeAppResponses(assignment)
                                      }
                                    >
                                      Close{" "}
                                      {/* <small className="text-warning">
                                  Coming Soon
                            </small>*/}
                                    </Dropdown.Item>
                                  ) : null}
                                  {/*<Dropdown.Item
                     className="small"
                     onClick={() => deleteSavedTask(poll.id)}
                   >
                     Delete
                 </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </IsAdmin>
                          </div>
                        </div>
                      ) : null
                    )}
                </>
              ) : (
                <>
                  {publishedAssignmentsData.map((assignment, index) =>
                    currentChannel?.createdBy === currentUser.uid ||
                    assignment.all === true ||
                    coAdmins.find((usr) => usr.uid === currentUser.uid) ||
                    (assignment.assignedTo &&
                      assignment.assignedTo.includes(currentUser.uid)) ? (
                      <div
                        key={index + assignment.id}
                        className={
                          assignment.deadline > Date.now()
                            ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
                            : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
                        }
                      >
                        <div
                          className="d-flex justify-content-between mb-1 pointer"
                          onClick={() =>
                            this.showSelectedAssignment(assignment.id)
                          }
                        >
                          <span className="text-theme-lighter smaller">
                            {moment(assignment.timestamp).format("LLL")}
                          </span>
                          <span
                            className={
                              assignment.deadline > Date.now()
                                ? "text-success smaller"
                                : "text-secondary smaller"
                            }
                          >
                            {assignment.deadline > Date.now()
                              ? "Active"
                              : "Closed"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p
                            className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
                            onClick={() =>
                              this.showSelectedAssignment(assignment.id)
                            }
                          >
                            {assignment.assignmentSubject}
                          </p>
                          <IsAdmin>
                            <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                              <Dropdown.Toggle
                                size="sm"
                                as={this.CustomToggle}
                              />
                              <Dropdown.Menu className="bg-card-theme text-theme">
                                <Dropdown.Item
                                  className="small bg-card-theme text-theme"
                                  onClick={() => shareTaskAgain(assignment)}
                                >
                                  Duplicate{" "}
                                  {/*<small className="text-warning">Coming Soon</small>*/}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="small bg-card-theme text-theme"
                                  onClick={() =>
                                    editPublishedTask(assignment.messageId)
                                  }
                                >
                                  Edit{" "}
                                  {/*<small className="text-warning">Coming Soon</small>*/}
                                </Dropdown.Item>
                                {assignment.deadline > Date.now() ? (
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() =>
                                      closeAppResponses(assignment)
                                    }
                                  >
                                    Close{" "}
                                    {/* <small className="text-warning">
                                  Coming Soon
                            </small>*/}
                                  </Dropdown.Item>
                                ) : null}
                                {/*<Dropdown.Item
                     className="small"
                     onClick={() => deleteSavedTask(poll.id)}
                   >
                     Delete
                 </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </IsAdmin>
                        </div>
                      </div>
                    ) : null
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="col">
              <p className="text-theme text-center small">
                No assignments published yet
              </p>
            </div>
          )
        ) : savedAssignments.length > 0 ? (
          <div>
            <div>
              <div className="d-flex justify-content-between">
                <InputGroup className="my-1 outerPill">
                  <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                    <div className="py-2 pr-2 pl-3">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="sm"
                        color="#aeaeae"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <Form.Control
                        type="text"
                        id="participantsSearch"
                        className="border-0 px-0 bg-theme text-theme rounded"
                        onChange={(e) => {
                          this.setState({ searchTerm: e.target.value }, () => {
                            const reg = _.escapeRegExp(this.state.searchTerm);
                            // console.log(reg,"Reg")
                            const regex = new RegExp(reg, "gi");
                            this.setState({ regex: regex });
                          });
                        }}
                        placeholder={"Search"}
                        value={this.state.searchTerm}
                        style={{ margin: 0 }}
                      />
                    </div>
                    {this.state.searchTerm.length > 0 && (
                      <div
                        className="py-2 pl-2 pr-3"
                        onClick={() => this.setState({ searchTerm: "" })}
                      >
                        <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                      </div>
                    )}
                  </div>
                </InputGroup>
                {this.state.sortType === "unreverse" ? (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "reverse" }, () => {
                        this.sortAssignmentUp();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAmountUpAlt} color="#aeaeae" />
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "unreverse" }, () => {
                        this.sortAssignmentUp();
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSortAmountDownAlt}
                      color="#aeaeae"
                    />
                  </Button>
                )}

                {this.state.sortType === "atoz" ? (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "ztoa" }, () => {
                        this.sortAssignmentUp();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUp} color="#aeaeae" />
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "atoz" }, () => {
                        this.sortAssignmentUp();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUpAlt} color="#aeaeae" />
                  </Button>
                )}
              </div>
            </div>
            {this.state.searchTerm && this.state.searchTerm.length > 0 ? (
              <>
                {savedAssignmentsData
                  .filter((item) => {
                    return item.assignmentSubject?.match(this.state.regex);
                  })
                  .map((Assignment, index) => (
                    <div
                      className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                      key={index}
                    >
                      <div className="d-flex justify-content-center">
                        <div
                          onClick={() => editSavedTask(Assignment.id)}
                          className="pointer"
                        >
                          <small className="text-theme-lighter smaller">
                            {moment(Assignment.timestamp).format("LLL")}
                          </small>
                          <p className="m-0 font-weight-bold text-theme small">
                            {Assignment.assignmentSubject}
                          </p>
                        </div>
                        <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                          <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                          <Dropdown.Menu className="bg-card-theme text-theme">
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => shareSavedTask(Assignment.id)}
                            >
                              Share now
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="small"
                              onClick={() => editSavedTask(Assignment.id)}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="small"
                              onClick={() => deleteSavedTask(Assignment.id)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <>
                {savedAssignmentsData.map((Assignment, index) => (
                  <div
                    className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                    key={index}
                  >
                    <div className="d-flex justify-content-center">
                      <div
                        onClick={() => editSavedTask(Assignment.id)}
                        className="pointer"
                      >
                        <small className="text-theme-lighter smaller">
                          {moment(Assignment.timestamp).format("LLL")}
                        </small>
                        <p className="m-0 font-weight-bold text-theme small">
                          {Assignment.assignmentSubject}
                        </p>
                      </div>
                      <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                        <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                        <Dropdown.Menu className="bg-card-theme text-theme">
                          <Dropdown.Item
                            className="small bg-card-theme text-theme"
                            onClick={() => shareSavedTask(Assignment.id)}
                          >
                            Share now
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="small"
                            onClick={() => editSavedTask(Assignment.id)}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="small"
                            onClick={() => deleteSavedTask(Assignment.id)}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        ) : (
          <div className="col">
            <p className="text-theme text-center small">
              No assignments saved yet
            </p>
          </div>
        )}
      </>
    ) : (
      <div className="container-fluid px-3">
        <div
          onClick={() => {
            this.setState({ view: 0, selectedAssignment: {} });
          }}
          className="mb-1 p-2 bg-card-theme text-theme"
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
          <span>Back</span>
        </div>
        <div className="shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme">
          <Assignment
            view={view}
            user={currentUser.uid}
            item={publishedAssignments.find(
              (assignment) => assignment.contentId === selectedAssignment.id
            )}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,

  type: state.assignment.type,
  questions: state.assignment.questions,
  descriptions: state.assignment.descriptions,
  weights: state.assignment.weights,
  totalWeight: state.assignment.totalWeight,
  graded: state.assignment.graded,

  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  everyone: state.task.everyone,
  darkTheme: state.platform.darkTheme,
  sharing: state.platform.sharing,
  sharedTask: state.platform.sharedTask,
  editing: state.platform.editing,
  editedTask: state.platform.editedTask,
  editingPublished: state.platform.editingPublished,
  editedPublishedTask: state.platform.editedPublishedTask,
});

export default connect(mapStateToProps, {
  setRightPanel,
  setContentTypeAndValue,
  setAssignmentQuestions,
  setAssignmentDescriptions,
  setAssignmentWeights,
  setAssignmentTotalWeight,
  setAssignmentGraded,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskEveryone,
  resetData,
  setSharedTask,
  setTaskSharing,
  setEditedTask,
  setTaskEditing,
  setEditedPublishedTask,
  setEditingPublished,
})(AssignmentHomepage);
