import * as actionTypes from "./types";
import firebase from "firebase";
import moment from "moment";
import { resetAssignment } from "../components/Tasks/Assignment/actions";
import { resetQuiz } from "../components/Tasks/Quiz/actions";
import axios from 'axios';
import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";
import { API_BASE_URL, ROOM_ADMIN_CONFIG } from '../config/index';



/* User Actions */
export const setUser = (user) => {
  return {
    type: actionTypes.SET_USER,
    payload: {
      currentUser: user,
    },
  };
};

export const setUserData = (userData) => {
  return {
    type: actionTypes.SET_USER_DATA,
    payload: {
      currentUserData: userData,
    },
  };
};

export const clearUser = () => {
  return {
    type: actionTypes.CLEAR_USER,
  };
};

export const setSubscription = (subscription) => {
  return {
    type: actionTypes.SET_SUBSCRIPTION,
    payload: {
      currentSubscription: subscription,
    },
  };
};

export const clearSubscription = () => {
  return {
    type: actionTypes.CLEAR_SUBSCRIPTION,
  };
};
/* Channel Actions */
// export const setAllChannels = workspace_id => {
//   return function (dispatch) {
//     let loadedChannels = [];
//     firebase
//       .firestore()
//       .collection(`workspaces/${workspace_id}/rooms`)
//       .get()
//       .then(function(querySnapshot) {
//         querySnapshot.forEach(function(doc) {
//           // doc.data() is never undefined for query doc snapshots
//           loadedChannels.push({ roomId: doc.id, ...doc.data() });
//         });

//         return dispatch({
//           type: actionTypes.SET_CHANNELS,
//           payload: {
//             allChannels: loadedChannels
//           }
//         });
//       });
//   }
// };

export const setAllMessages = (currentChannelMessages) => {
  return {
    type: actionTypes.SET_CURRENT_CHANNEL_MESSAGES,
    payload: {
      currentChannelMessages,
    },
  };
};

export const setParticipants = (workspaceId, roomId) => {
  return (dispatch) => {
    let participants = [];

    firebase
      .firestore()
      .collection(`workspaces/${workspaceId}/rooms/${roomId}/participants`)
      .get()
      .then((snapshotQuery) => {
        snapshotQuery.forEach((doc) => {
          // participants.push({ [doc.id]: doc.data() })
          participants.push({ ...doc.data(), uid: doc.id });
        });
      }).then(() => {
        return dispatch({
          type: actionTypes.SET_PARTICIPANTS,
          payload: {
            participants,
          },
        });
      })
      .catch((error) => console.error(error));

    console.log("Redux participants: ", participants);

  };
};

export const setChannels = (allChannels) => {
  return {
    type: actionTypes.SET_CHANNELS,
    payload: {
      allChannels,
    },
  };
};

// export const setChannels = user_id => {
//   return function (dispatch) {
//     let loadedChannels = [];
//     firebase.database().ref("channels").on("child_added", snap => {
//       if (snap.val().users && Object.keys(snap.val().users).includes(user_id)) loadedChannels.push(snap.val());
//       loadedChannels
//         .map(channel => {
//           firebase.database().ref("messages").child(channel.id).orderByKey().limitToLast(1).on("child_added", snap => {
//             channel.lastMessage = snap.val();
//             channel.lastMessageTimestamp = snap.val().timestamp;
//           })
//         });
//     });
//     return dispatch({
//       type: actionTypes.SET_CHANNELS,
//       payload: {
//         allChannels: loadedChannels
//       }
//     });
//   }
// };

export const updateCurrentChannel = (channel_id) => {
  return function (dispatch) {
    firebase
      .database()
      .ref("channels")
      .child(channel_id)
      .on("value", (snap) => {
        dispatch(setCurrentChannel(snap.val()));
        dispatch(setContentType(null));
        dispatch(setTaskType(null));
        dispatch(setRoomDetails(false));
        dispatch(setFolder(null));
        dispatch(setOpenTaskId(null));
        dispatch(setDeleteTask(null));

        dispatch(setUserPosts({}));

        dispatch(resetAllTasks());
        dispatch(setReplyId(""));
        dispatch(setShowNoteApp(false));

        dispatch(setShowCaseSheet(false));
        dispatch(setShowWhiteboard(false));
        dispatch(setShowPrescription(false));
        dispatch(saveWhiteboardLayers([]));
        dispatch(saveWhiteboardBackground(""));
        dispatch(setShowAttendanceLedger(false));
        dispatch(setShowUploadFilesModal(false));
        dispatch(setShowCreateFolderModal(false));
        dispatch(setSelectedFolderId(null));

        dispatch(setShowGradebook(false));
      });
  };
};

export const resetData = () => {
  return function (dispatch) {
    dispatch(setContentType(null));
    dispatch(setTaskType(null));
    dispatch(setTaskFolder(""));
    dispatch(setRoomDetails(false));
    dispatch(setFolder(null));
    dispatch(setOpenTaskId(null));
    dispatch(setDeleteTask(null));
    dispatch(setSharedTask({}));
    dispatch(setEditedTask({}));
    dispatch(setEditedPublishedTask({}));
    dispatch(setEditingPublished(false));
    dispatch(setSelectedFolderId(null));
    dispatch(setPollPreviewImages([]));
    dispatch(setPollOptionsImages([]));
    dispatch(setUserPosts({}));

    dispatch(resetAllTasks());
    dispatch(setReplyId(""));

    dispatch(resetPoll());
    dispatch(setShowWhiteboard(false));
    dispatch(setShowPrescription(false));
    dispatch(saveWhiteboardLayers([]));
    dispatch(saveWhiteboardBackground(""));
    dispatch(setShowAttendanceLedger(false));
    dispatch(setShowUploadFilesModal(false));
    dispatch(setShowCreateFolderModal(false));
    dispatch(setShowGradebook(false));
    
    dispatch(setShowCaseSheet(false));
    dispatch(setShowNoteApp(false));
    dispatch(setShowPrescription(false));
  };
};

export const setCurrentChannel = (channel) => {

  return (dispatch, getState) => {


    if (channel) {
      axios.post(`${API_BASE_URL}${ROOM_ADMIN_CONFIG}`, {
        workspace_id: getState().workspace.currentWorkspace?.workspaceId,
        room_id: channel?.roomId
      }).then((response) => {
        const roomConfig = response.data.data;

        dispatch({
          type: actionTypes.SET_CURRENT_CHANNEL,
          payload: {
            currentChannel: { ...channel, roomConfig },
          },
        });

      }).catch((error) => {
        console.log(error, "Error")
      })
    } else {
      dispatch({
        type: actionTypes.SET_CURRENT_CHANNEL,
        payload: {
          currentChannel: null,
        },
      });
    }

  }

};

export const setCurrentChannelConfig = (roomConfig) => {
  return {
    type: actionTypes.SET_CURRENT_CHANNEL_CONFIG,
    payload: {
      roomConfig: roomConfig,
    },
  };
};


export const setPrivateChannel = (isPrivateChannel) => {
  return {
    type: actionTypes.SET_PRIVATE_CHANNEL,
    payload: {
      isPrivateChannel,
    },
  };
};

export const setUserPosts = (userPosts) => {
  return {
    type: actionTypes.SET_USER_POSTS,
    payload: {
      userPosts,
    },
  };
};

/* Add New Room Actions */
export const addRoom = (room) => {
  return {
    type: actionTypes.SET_NEW_ROOM,
    payload: {
      newRoom: room,
    },
  };
};

/* Add New Room Actions */
export const addRoomJWT = (roomJWT) => {
  return {
    type: actionTypes.SET_NEW_ROOM_JWT,
    payload: {
      newRoomJWT: roomJWT,
    },
  };
};

export const addRoomError = (error) => {
  return {
    type: actionTypes.SET_NEW_ROOM_ERROR,
    payload: {
      newRoomError: error,
    },
  };
};

export const addRoomJWTError = (errorJWT) => {
  return {
    type: actionTypes.SET_NEW_ROOM_JWT_ERROR,
    payload: {
      newRoomJWTError: errorJWT,
    },
  };
};

export const addRoomWorkspaceDetails = (newRoomDetails) => {
  return {
    type: actionTypes.SET_NEW_ROOM_WORKSPACE_DETAILS,
    payload: {
      newRoomDetails: newRoomDetails,
    },
  };
};

/* Colors Actions */
export const setColors = (primaryColor, secondaryColor) => {
  return {
    type: actionTypes.SET_COLORS,
    payload: {
      primaryColor,
      secondaryColor,
    },
  };
};

/* Platform Actions */

export const setWorkspaceSlug = workspaceSlug => {
  return {
      type: actionTypes.SET_WORKSPACE_SLUG,
      payload: {
          workspaceSlug: workspaceSlug
      }
  }
}

export const setLeftPanel = (display) => {
  return {
    type: actionTypes.SET_LEFT_PANEL,
    payload: {
      displayLeftPanel: display,
    },
  };
};

export const setRightPanel = (display) => {
  return {
    type: actionTypes.SET_RIGHT_PANEL,
    payload: {
      displayRightPanel: display,
    },
  };
};
export const setMessageForward = (display) => {
  console.log(display, "from action")
  return {
    type: actionTypes.SET_MESSAGE_FORWARD,
    payload: {
      displayMessageForward: display,
    },
  };
};

export const setMessagesForwardArray = (value) => {
  //console.log(value,"from action")
    return {
      type: actionTypes.ADD_MESSAGES_FORWARD_ARRAY,
      payload: {
        messageForwardArray: value,
      },
    };
  };

export const setTextUnsendMessage = (value) => {
  console.log(value,"textUnsendMessage")
    return {
      type: actionTypes.SET_TEXT_UNSEND_MESSAGE,
      payload: {
        messageForwardArray: value,
      },
    };
  };

export const setTypedTextMessage = (value) => {
    return {
      type: actionTypes.SET_TYPED_TEXT_MESSAGE,
      payload: {
        typedTextMessage: value,
      },
    };
  };

export const setContentTypeAndValue = (contentType, value) => {
  //console.log(contentType, value, "my share to another room Data")
  switch (contentType) {
    case "createTask":
      return function (dispatch) {
        dispatch(setContentType(contentType));
        dispatch(setTaskType(value));
        dispatch(setRoomDetails(false));
        dispatch(setFolder(null));
        dispatch(setOpenTaskId(null));
        dispatch(setDeleteTask(null));
      };
    case "roomDetails":
      return function (dispatch) {
        dispatch(setContentType(contentType));
        dispatch(setRoomDetails(value));
      };
    case "updateTask":
      return function (dispatch) {
        dispatch(setContentType(contentType));
        dispatch(setTaskType(value));
        dispatch(setRoomDetails(false));
        dispatch(setFolder(null));
        dispatch(setOpenTaskId(null));
        dispatch(setDeleteTask(null));
      };
    case "allSchedules":
      return function (dispatch) {
        dispatch(setContentType(contentType));
      };
    default:
      return function (dispatch) {
        dispatch(setContentType(null));
      };
  }
};

export const setContentType = (value) => {
  return {
    type: actionTypes.SET_CONTENT_TYPE,
    payload: {
      contentType: value,
    },
  };
};

export const setTaskType = (value) => {
  return {
    type: actionTypes.SET_TASK_TYPE,
    payload: {
      taskType: value,
    },
  };
};

export const setTaskId = (value) => {
  return {
    type: actionTypes.SET_TASK_ID,
    payload: {
      taskId: value,
    },
  };
};

export const setTaskBranch = (value) => {
  return {
    type: actionTypes.SET_TASK_BRANCH,
    payload: {
      taskBranch: value,
    },
  };
};

export const setRoomDetails = (value) => {
  return {
    type: actionTypes.SET_ROOM_DETAILS,
    payload: {
      showFull: value,
    },
  };
};

export const setFolder = (value) => {
  return {
    type: actionTypes.SET_FOLDER,
    payload: {
      folder_id: value,
    },
  };
};

export const setOpenTaskId = (value) => {
  return {
    type: actionTypes.SET_OPEN_TASK_ID,
    payload: {
      open_task_id: value,
    },
  };
};

export const setOpenTaskBranch = (value) => {
  return {
    type: actionTypes.SET_OPEN_TASK_BRANCH,
    payload: {
      open_task_branch: value,
    },
  };
};

export const setDeleteTask = (value) => {
  return {
    type: actionTypes.DELETE_TASK,
    payload: {
      delete_id: value,
    },
  };
};

export const setReplyId = (value) => {
  return {
    type: actionTypes.SET_REPLY,
    payload: {
      replyId: value,
    },
  };
};


export const setIsVideoVisible = (value) => {
  return {
    type: actionTypes.SET_IS_VIDEO_VISIBLE,
    payload: {
      isVideoVisible: value,
    },
  };
};

export const setChannelDetails = (value) => {
  return {
    type: actionTypes.SET_CHANNEL_DETAILS,
    payload: {
      channelDetails: value,
    },
  };
};

export const setVideoDetails = (value) => {
  return {
    type: actionTypes.SET_VIDEO_DETAILS,
    payload: {
      videoDetails: value
    }
  }
}

export const setPublisher = (value) => {
  return {
    type: actionTypes.SET_PUBLISHER,
    payload: {
      isPublisher: value,
    },
  };
};

export const setConferenceSession = (value) => {
  return {
    type: actionTypes.SET_CONFERENCE_SESSION,
    payload: {
      conferenceSession: value,
    }
  }
}

export const setDarkTheme = (value) => {
  return {
    type: actionTypes.SET_DARK_THEME,
    payload: {
      darkTheme: value,
    },
  };
};

/* Folder Actions */
export const setFolderName = (value) => {
  return {
    type: actionTypes.ADD_FOLDER_NAME,
    payload: {
      folder_name: value,
    },
  };
};

export const setFolderDescription = (value) => {
  return {
    type: actionTypes.ADD_FOLDER_DESCRIPTION,
    payload: {
      description: value,
    },
  };
};

export const setFolderId = (value) => {
  return {
    type: actionTypes.ADD_FOLDER_ID,
    payload: {
      id: value,
    },
  };
};

export const openFolder = (value) => {
  return {
    type: actionTypes.OPEN_FOLDER,
    payload: {
      open_folder: value,
    },
  };
};

export const editFolder = (value) => {
  return {
    type: actionTypes.EDIT_FOLDER,
    payload: {
      edit_folder: value,
    },
  };
};

export const deleteFolder = (value) => {
  return {
    type: actionTypes.DELETE_FOLDER,
    payload: {
      delete_folder: value,
    },
  };
};

export const addFolder = ({
  currentChannel,
  currentUser,
  folder_name,
  description = "",
}) => {
  return function (dispatch) {
    let folders = null;
    firebase
      .database()
      .ref("channels/" + currentChannel.id)
      .child("folders")
      .on("value", (snap) => {
        folders = snap.val();
      });

    let new_folder = firebase.database().ref("folders").push();
    let new_folder_key = new_folder.key;

    let updates = {};
    updates["folders"] = folders
      ? [...folders, new_folder_key]
      : [new_folder_key];
    let created_at = firebase.database.ServerValue.TIMESTAMP;

    firebase.database().ref("folders").child(new_folder_key).set({
      admin: currentUser.uid,
      channel: currentChannel.id,
      folder_name: folder_name,
      description: description,
      id: new_folder_key,
      timestamp: created_at,
    });
    firebase
      .database()
      .ref("channels/" + currentChannel.id)
      .update(updates);

    dispatch(updateCurrentChannel(currentChannel.id));
    dispatch(setFolderName(null));
    dispatch(setFolderDescription(null));
  };
};

export const updateFolder = ({
  folder_id,
  currentChannel,
  folder_name,
  description,
}) => {
  return function (dispatch) {
    let folder_data = [];
    firebase
      .database()
      .ref("folders")
      .child(folder_id)
      .on("value", (snap) => {
        folder_data = snap.val();
      });

    let updates = {
      ...folder_data,
      folder_name: folder_name,
      description: description,
    };
    firebase.database().ref("folders").child(folder_id).update(updates);

    dispatch(updateCurrentChannel(currentChannel.id));
    dispatch(setFolderName(null));
    dispatch(setFolderDescription(null));
    dispatch(editFolder(false));
  };
};

export const deleteFolderFunction = ({ folder_id, currentChannel }) => {
  return function (dispatch) {
    let folders = null;
    firebase
      .database()
      .ref("channels/" + currentChannel.id)
      .child("folders")
      .on("value", (snap) => {
        folders = snap.val();
      });

    let updates = {};
    updates["folders"] = folders.filter((folder) => folder !== folder_id);

    let folder_data = [];
    firebase
      .database()
      .ref("folders")
      .child(folder_id)
      .on("value", (snap) => {
        folder_data = snap.val();
      });

    folder_data.tasks &&
      folder_data.tasks.map((task) =>
        firebase
          .database()
          .ref("messages/" + folder_data.channel)
          .child(task)
          .remove()
      );
    firebase.database().ref("folders").child(folder_id).remove();
    firebase
      .database()
      .ref("channels/" + currentChannel.id)
      .update(updates);

    dispatch(updateCurrentChannel(currentChannel.id));
    dispatch(deleteFolder(false));
  };
};

/* Poll Actions */
export const setPollId = (value) => {
  return {
    type: actionTypes.ADD_POLL_ID,
    payload: {
      id: value,
    },
  };
};

export const setPollTitle = (value) => {
  return {
    type: actionTypes.ADD_POLL_TITLE,
    payload: {
      title: value,
    },
  };
};

export const setPollOptions = (value) => {
  return {
    type: actionTypes.ADD_POLL_OPTIONS,
    payload: {
      options: value,
    },
  };
};

export const setPollOptionsImages = (value) => {
  return {
    type: actionTypes.ADD_POLL_OPTIONS_IMAGES,
    payload: {
      images: value,
    },
  };
};

export const setPollPreviewImages = (value) => {
  return {
    type: actionTypes.ADD_POLL_PREVIEW_IMAGES,
    payload: {
      previewImages: value,
    },
  };
};

export const setPollOptionsFiles = (value) => {
  return {
    type: actionTypes.ADD_POLL_OPTIONS_FILES,
    payload: {
      files: value,
    },
  };
};

export const setPollOptionsFilesData = (value) => {
  return {
    type: actionTypes.ADD_POLL_OPTIONS_FILES_DATA,
    payload: {
      filesData: value,
    },
  };
};

export const resetPoll = () => {
  return {
    type: actionTypes.RESET_POLL,
    payload: {},
  };
};

/* Schedule Task Actions */
export const setScheduleId = (value) => {
  return {
    type: actionTypes.ADD_SCHEDULE_ID,
    payload: {
      id: value,
    },
  };
};

export const setScheduleTitle = (value) => {
  return {
    type: actionTypes.ADD_SCHEDULE_TITLE,
    payload: {
      scheduleTitle: value,
    },
  };
};

export const setScheduleDurationHours = (value) => {
  return {
    type: actionTypes.ADD_SCHEDULE_DURATION_HOURS,
    payload: {
      durationHours: value,
    },
  };
};

export const setScheduleDurationMinutes = (value) => {
  return {
    type: actionTypes.ADD_SCHEDULE_DURATION_MINUTES,
    payload: {
      durationMinutes: value,
    },
  };
};

export const setSchedulePlace = (value) => {
  return {
    type: actionTypes.ADD_SCHEDULE_PLACE,
    payload: {
      place: value,
    },
  };
};

export const setScheduleAgenda = (value) => {
  return {
    type: actionTypes.ADD_SCHEDULE_AGENDA,
    payload: {
      agenda: value,
    },
  };
};

export const resetSchedule = () => {
  return {
    type: actionTypes.RESET_SCHEDULE,
    payload: {},
  };
};

// /* Quiz Actions */
// export const setQuizId = value => {
//   return {
//     type: actionTypes.ADD_QUIZ_ID,
//     payload: {
//       id: value
//     }
//   }
// }

// export const setQuizTitle = value => {
//   return {
//     type: actionTypes.ADD_QUIZ_TITLE,
//     payload: {
//       quizTitle: value
//     }
//   }
// }

// export const setQuizQuestions = value => {
//   return {
//     type: actionTypes.ADD_QUIZ_QUESTIONS,
//     payload: {
//       questions: value
//     }
//   }
// }

// export const setQuizOptions = value => {
//   return {
//     type: actionTypes.ADD_QUIZ_OPTIONS,
//     payload: {
//       options: value
//     }
//   }
// }

// export const setQuizCorrectAnswers = value => {
//   return {
//     type: actionTypes.ADD_QUIZ_CORRECT_ANSWERS,
//     payload: {
//       correctAnswers: value
//     }
//   }
// }

// export const resetQuiz = () => {
//   return {
//     type: actionTypes.RESET_QUIZ,
//     payload: {}
//   }
// }

/* Reading Actions */
export const setReadingId = (value) => {
  return {
    type: actionTypes.ADD_READING_ID,
    payload: {
      id: value,
    },
  };
};

export const setReadingTitles = (value) => {
  return {
    type: actionTypes.ADD_READING_TITLES,
    payload: {
      titles: value,
    },
  };
};

export const setReadingLinks = (value) => {
  return {
    type: actionTypes.ADD_READING_LINKS,
    payload: {
      links: value,
    },
  };
};

export const setReadingFiles = (value) => {
  return {
    type: actionTypes.ADD_READING_FILES,
    payload: {
      files: value,
    },
  };
};

export const setReadingUrls = (value) => {
  return {
    type: actionTypes.ADD_READING_URLS,
    payload: {
      urls: value,
    },
  };
};

export const resetReading = () => {
  return {
    type: actionTypes.RESET_READING,
    payload: {},
  };
};

/* Common Task Actions */
export const addTask = (data, currentChannel, currentUser) => {
  return function (dispatch) {
    let message = {
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      user: {
        id: currentUser.uid,
        name: currentUser.displayName,
        avatar: currentUser.photoURL,
      },
    };
    // ADDING DATA ACCORDING TO TASK TYPE
    message = { ...message, ...data };

    // CONVERTING DEADLINE TO TIMESTAMP TO STORE ON FIREBASE
    message["deadline"] = moment(data["deadline"]).valueOf();

    // ADDING USERS TO TASK DATA
    let usersData = [];
    usersData[currentUser.uid] = { read: true, readtime: Date.now() };
    message.channelUsers.map((item) => (usersData[item] = { read: false }));
    message["users"] = { ...usersData };

    // DELETING EXTRA ATTRIBUTE FROM DATA
    delete message["channelUsers"];
    // console.log(message)

    let tasks = null;
    firebase
      .database()
      .ref("folders/" + data.folder)
      .child("tasks")
      .on("value", (snap) => {
        tasks = snap.val();
      });

    let newMessage = firebase
      .database()
      .ref("messages")
      .child(currentChannel.id)
      .push();
    let newMessageKey = newMessage.key;

    if (data.folder) {
      let updates = {};
      updates["tasks"] = tasks ? [...tasks, newMessageKey] : [newMessageKey];

      firebase
        .database()
        .ref("folders/" + data.folder)
        .update(updates);
    }

    firebase
      .database()
      .ref("messages")
      .child(currentChannel.id)
      .child(newMessageKey)
      .set({ ...message, uid: newMessageKey });

    dispatch(updateCurrentChannel(currentChannel.id));
  };
};

export const editTask = (
  data,
  uid,
  old_folder,
  currentChannel,
  currentUser
) => {
  return function (dispatch) {
    let message = {};
    // ADDING DATA ACCORDING TO TASK TYPE
    message = { ...data };

    // CONVERTING DEADLINE TO TIMESTAMP TO STORE ON FIREBASE
    message["deadline"] = moment(data["deadline"]).valueOf();

    // ADDING USERS TO TASK DATA
    let usersData = [];
    usersData[currentUser.uid] = { read: true, readtime: Date.now() };
    message.channelUsers.map((item) => (usersData[item] = { read: false }));
    message["users"] = { ...usersData };

    // DELETING EXTRA ATTRIBUTE FROM DATA
    delete message["channelUsers"];

    let tasks = null;
    if (data.folder !== "") {
      firebase
        .database()
        .ref("folders/" + data.folder)
        .child("tasks")
        .on("value", (snap) => {
          tasks = snap.val();
        });
    }

    let old_tasks = null;
    if (old_folder !== "") {
      firebase
        .database()
        .ref("folders/" + old_folder)
        .child("tasks")
        .on("value", (snap) => {
          old_tasks = snap.val();
        });
    }

    let folder_updates = {};
    let old_folder_updates = {};

    if (tasks && !tasks.includes(uid)) {
      folder_updates["tasks"] = [...tasks, uid];
    } else {
      folder_updates["tasks"] = [uid];
    }

    if (old_tasks && old_folder_updates) {
      old_folder_updates["tasks"] = old_tasks.filter((task) => task !== uid);
      firebase
        .database()
        .ref("folders/" + old_folder)
        .update(old_folder_updates);
    }

    data.folder &&
      firebase
        .database()
        .ref("folders/" + data.folder)
        .update(folder_updates);

    let updates = {};
    updates[uid] = message;
    firebase
      .database()
      .ref("messages")
      .child(currentChannel.id)
      .update(updates);

    dispatch(updateCurrentChannel(currentChannel.id));
  };
};

export const saveTask = (data, currentChannel, currentUser) => {
  return function (dispatch) {
    let message = {
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      user: {
        id: currentUser.uid,
        name: currentUser.displayName,
        avatar: currentUser.photoURL,
      },
    };
    // ADDING DATA ACCORDING TO TASK TYPE
    message = { ...message, ...data };

    // CONVERTING DEADLINE TO TIMESTAMP TO STORE ON FIREBASE
    message["deadline"] = moment(data["deadline"]).valueOf();

    // ADDING USERS TO TASK DATA
    let usersData = [];
    usersData[currentUser.uid] = { read: true, readtime: Date.now() };
    message.channelUsers.map((item) => (usersData[item] = { read: false }));
    message["users"] = { ...usersData };

    // DELETING EXTRA ATTRIBUTE FROM DATA
    delete message["channelUsers"];
    // console.log(message)

    let tasks = null;
    firebase
      .database()
      .ref("folders/" + data.folder)
      .child("saved")
      .on("value", (snap) => {
        tasks = snap.val();
      });

    let newMessage = firebase
      .database()
      .ref("saved")
      .child(currentChannel.id)
      .push();
    let newMessageKey = newMessage.key;

    if (data.folder) {
      let updates = {};
      updates["saved"] = tasks ? [...tasks, newMessageKey] : [newMessageKey];

      firebase
        .database()
        .ref("folders/" + data.folder)
        .update(updates);
    }

    firebase
      .database()
      .ref("saved")
      .child(currentChannel.id)
      .child(newMessageKey)
      .set({ ...message, uid: newMessageKey });

    dispatch(updateCurrentChannel(currentChannel.id));
  };
};

export const editSaveTask = (
  data,
  uid,
  old_folder,
  currentChannel,
  currentUser
) => {
  return function (dispatch) {
    let message = {};
    // ADDING DATA ACCORDING TO TASK TYPE
    message = { ...data };

    // CONVERTING DEADLINE TO TIMESTAMP TO STORE ON FIREBASE
    message["deadline"] = moment(data["deadline"]).valueOf();

    // ADDING USERS TO TASK DATA
    let usersData = [];
    usersData[currentUser.uid] = { read: true, readtime: Date.now() };
    message.channelUsers.map((item) => (usersData[item] = { read: false }));
    message["users"] = { ...usersData };

    // DELETING EXTRA ATTRIBUTE FROM DATA
    delete message["channelUsers"];

    let tasks = null;
    if (data.folder !== "") {
      firebase
        .database()
        .ref("folders/" + data.folder)
        .child("saved")
        .on("value", (snap) => {
          tasks = snap.val();
        });
    }

    let old_tasks = null;
    if (old_folder !== "") {
      firebase
        .database()
        .ref("folders/" + old_folder)
        .child("saved")
        .on("value", (snap) => {
          old_tasks = snap.val();
        });
    }

    let folder_updates = {};
    let old_folder_updates = {};

    if (tasks && !tasks.includes(uid)) {
      folder_updates["saved"] = [...tasks, uid];
    } else {
      folder_updates["saved"] = [uid];
    }

    if (old_tasks && old_folder_updates) {
      old_folder_updates["saved"] = old_tasks.filter((task) => task !== uid);
      firebase
        .database()
        .ref("folders/" + old_folder)
        .update(old_folder_updates);
    }

    data.folder &&
      firebase
        .database()
        .ref("folders/" + data.folder)
        .update(folder_updates);

    let updates = {};
    updates[uid] = message;
    firebase.database().ref("saved").child(currentChannel.id).update(updates);

    dispatch(updateCurrentChannel(currentChannel.id));
  };
};

export const publishSaveTask = (
  data,
  uid,
  old_folder,
  currentChannel,
  currentUser
) => {
  return function (dispatch) {
    let message = {};
    // ADDING DATA ACCORDING TO TASK TYPE
    message = { ...data };

    // CONVERTING DEADLINE TO TIMESTAMP TO STORE ON FIREBASE
    message["deadline"] = moment(data["deadline"]).valueOf();

    // ADDING USERS TO TASK DATA
    let usersData = [];
    usersData[currentUser.uid] = { read: true, readtime: Date.now() };
    message.channelUsers.map((item) => (usersData[item] = { read: false }));
    message["users"] = { ...usersData };

    // DELETING EXTRA ATTRIBUTE FROM DATA
    delete message["channelUsers"];

    let tasks = null;
    if (data.folder !== "") {
      firebase
        .database()
        .ref("folders/" + data.folder)
        .child("tasks")
        .on("value", (snap) => {
          tasks = snap.val();
        });
    }

    let old_tasks = null;
    if (old_folder !== "") {
      firebase
        .database()
        .ref("folders/" + old_folder)
        .child("saved")
        .on("value", (snap) => {
          old_tasks = snap.val();
        });
    }

    let newMessage = firebase
      .database()
      .ref("messages")
      .child(currentChannel.id)
      .push();
    let newMessageKey = newMessage.key;
    firebase
      .database()
      .ref("messages")
      .child(currentChannel.id)
      .child(newMessageKey)
      .set({ ...message, uid: newMessageKey });

    let folder_updates = {};
    if (tasks && !tasks.includes(uid)) {
      folder_updates["tasks"] = [...tasks, newMessageKey];
    } else {
      folder_updates["tasks"] = [newMessageKey];
    }
    data.folder &&
      firebase
        .database()
        .ref("folders/" + data.folder)
        .update(folder_updates);

    let old_folder_updates = {};
    if (old_tasks && old_tasks.includes(uid)) {
      old_folder_updates["saved"] = old_tasks.filter((task) => task !== uid);
      firebase
        .database()
        .ref("folders/" + old_folder)
        .update(old_folder_updates);
    }

    dispatch(setContentType(null));
    dispatch(setTaskType(null));

    // Now Deleting Saved Task
    firebase
      .database()
      .ref("saved")
      .child(currentChannel.id)
      .child(uid)
      .remove();

    dispatch(updateCurrentChannel(currentChannel.id));
  };
};

export const setTaskChannelUsers = (value) => {
  return {
    type: actionTypes.ADD_TASK_CHANNEL_USERS,
    payload: {
      channelUsers: value,
    },
  };
};

export const setTaskDeadline = (value) => {
  return {
    type: actionTypes.ADD_TASK_DEADLINE,
    payload: {
      deadline: value,
    },
  };
};

export const setTaskFolder = (value) => {
  return {
    type: actionTypes.ADD_TASK_FOLDER,
    payload: {
      folder: value,
    },
  };
};

export const setTaskOldFolder = (value) => {
  return {
    type: actionTypes.ADD_TASK_OLD_FOLDER,
    payload: {
      old_folder: value,
    },
  };
};

export const setTaskEveryone = (value) => {
  return {
    type: actionTypes.ADD_TASK_EVERYONE,
    payload: {
      everyone: value,
    },
  };
};
export const setTaskSharing = (value) => {
  //console.log(value);
  return {
    type: actionTypes.SET_SHARING,
    payload: {
      sharing: value,
    },
  };
};
export const setEditingPublished = (value) => {
  //console.log(value);
  return {
    type: actionTypes.SET_EDITING_PUBLISHED,
    payload: {
      editingPublished: value,
    },
  };
};
export const setSharedTask = (value) => {
  //console.log(value);
  return {
    type: actionTypes.SET_SHARED_TASK,
    payload: {
      sharedTask: { ...value },
    },
  };
};
export const setTaskEditing = (value) => {
  //console.log(value);
  return {
    type: actionTypes.SET_EDITING,
    payload: {
      editing: value,
    },
  };
};
export const setEditedTask = (value) => {
  //console.log(value);
  return {
    type: actionTypes.SET_EDITED_TASK,
    payload: {
      editedTask: { ...value },
    },
  };
};
export const setEditedPublishedTask = (value) => {
  //console.log(value);
  return {
    type: actionTypes.SET_EDITED_PUBLISHED_TASK,
    payload: {
      editedPublishedTask: { ...value },
    },
  };
};
export const resetTask = () => {
  return {
    type: actionTypes.RESET_TASK,
    payload: {},
  };
};

export const setShowNoteApp = (value) => {
  return {
    type: actionTypes.SET_SHOW_NOTEAPP,
    payload: {
      showNoteApp: value,
    },
  };
};

/* Whiteboard Actions */
export const setShowWhiteboard = (value) => {
  return {
    type: actionTypes.SET_SHOW_WHITEBOARD,
    payload: {
      showWhiteboard: value,
    },
  };
};

export const setShowPrescription = (value) => {
  return {
    type: actionTypes.SET_SHOW_PRESCRIPTION,
    payload: {
      showPrescription: value,
    },
  };
};
export const setPrescriptionEditId = (value) => {
  return {
    type: actionTypes.SET_PRESCRIPTION_EDIT_ID,
    payload: {
      prescriptionEditId: value,
    },
  };
};


export const saveWhiteboardLayers = (value) => {
  return {
    type: actionTypes.SAVE_WHITEBOARD_LAYERS,
    payload: {
      layers: value,
    },
  };
};

export const saveWhiteboardBackground = (value) => {
  return {
    type: actionTypes.SAVE_WHITEBOARD_BACKGROUND,
    payload: {
      background: value,
    },
  };
};

export const setShowCaseSheet = (value) => {
  return {
    type: actionTypes.SET_SHOW_CASESHEET,
    payload: {
      showCaseSheet: value,
    },
  };
};

export const setShowPRF = (value) => {
  return {
    type: actionTypes.SET_SHOW_PRF,
    payload: {
      showPRF: value,
    },
  };
};

/* Gradebook Actions */
export const setShowGradebook = (value) => {
  return {
    type: actionTypes.SET_SHOW_GRADEBOOK,
    payload: {
      showGradebook: value,
    },
  };
};

/* Attendance Ledger Actions */
export const setShowAttendanceLedger = (value) => {
  return {
    type: actionTypes.SET_SHOW_ATTENDANCE_LEDGER,
    payload: {
      showAttendanceLedger: value,
    },
  };
};

/* My drive Actions */
export const setShowUploadFilesModal = (value) => {
  // console.log(value, "inside action");
  return {
    type: actionTypes.SET_SHOW_UPLOAD_FILES_MODAL,
    payload: {
      showUploadFilesModal: value,
    },
  };
};
export const setShowCreateFolderModal = (value) => {
  return {
    type: actionTypes.SET_SHOW_CREATE_FOLDER_MODAL,
    payload: {
      showCreateFolderModal: value,
    },
  };
};
export const setSelectedFolderId = (value) => {
  return {
    type: actionTypes.SET_SELECTED_FOLDER_ID,
    payload: {
      id: value,
    },
  };
};
/* RESET ALL TASKS */
export const resetAllTasks = () => {
  return function (dispatch) {
    dispatch(resetAssignment());
    dispatch(resetPoll());
    dispatch(resetSchedule());
    dispatch(resetQuiz());
    dispatch(resetReading());
    dispatch(resetTask());
  };
};

export * from "./workspace";
