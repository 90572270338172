/* User Action Types */
export const SET_USER = "SET_USER";
export const SET_USER_DATA = "SET_USER_DATA";
export const CLEAR_USER = "CLEAR_USER";

/*subscription*/

export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const CLEAR_SUBSCRIPTION = "CLEAR_SUBSCRIPTION";

/* Channel Action Types */
export const SET_CHANNELS = "SET_CHANNELS";
export const SET_CURRENT_CHANNEL = "SET_CURRENT_CHANNEL";
export const SET_CURRENT_CHANNEL_CONFIG = "SET_CURRENT_CHANNEL_CONFIG";
export const SET_PRIVATE_CHANNEL = "SET_PRIVATE_CHANNEL";
export const SET_USER_POSTS = "SET_USER_POSTS";
export const SET_CURRENT_CHANNEL_MESSAGES = "SET_CURRENT_CHANNEL_MESSAGES";
export const SET_PARTICIPANTS = "SET_PARTICIPANTS";

/* Add New Room Action Types */
export const SET_NEW_ROOM = "SET_NEW_ROOM";
export const SET_NEW_ROOM_ERROR = "SET_NEW_ROOM_ERROR";

/* Add New Room Action JWT Types */
export const SET_NEW_ROOM_JWT = "SET_NEW_ROOM_JWT";
export const SET_NEW_ROOM_JWT_ERROR = "SET_NEW_ROOM_JWT_ERROR";

/* New room's workspace details */
export const SET_NEW_ROOM_WORKSPACE_DETAILS = "SET_NEW_ROOM_WORKSPACE_DETAILS";
/* Colors Action Types */
export const SET_COLORS = "SET_COLORS";

/* Platform Action Types */
export const SET_LEFT_PANEL = "SET_LEFT_PANEL";
export const SET_RIGHT_PANEL = "SET_RIGHT_PANEL";
export const SET_MESSAGE_FORWARD = "SET_MESSAGE_FORWARD";
export const SET_CONTENT_TYPE = "SET_CONTENT_TYPE";
export const SET_TASK_TYPE = "SET_TASK_TYPE";
export const SET_TASK_ID = "SET_TASK_ID";
export const SET_TASK_BRANCH = "SET_TASK_BRANCH";
export const SET_ROOM_DETAILS = "SET_ROOM_DETAILS";
export const SET_FOLDER = "SET_FOLDER";
export const SET_OPEN_TASK_ID = "SET_OPEN_TASK_ID";
export const SET_OPEN_TASK_BRANCH = "SET_OPEN_TASK_BRANCH";
export const SET_REPLY = "SET_REPLY";
export const DELETE_TASK = "DELETE_TASK";
export const SET_IS_VIDEO_VISIBLE = "SET_IS_VIDEO_VISIBLE";
export const SET_CHANNEL_DETAILS = "SET_CHANNEL_DETAILS";
export const SET_VIDEO_DETAILS = "SET_VIDEO_DETAILS";
export const SET_PUBLISHER = "SET_PUBLISHER";
export const SET_CONFERENCE_SESSION = "SET_CONFERENCE_SESSION";
export const SET_DARK_THEME = "SET_DARK_THEME";

/* Folder Action Types */
export const ADD_FOLDER_ID = "ADD_FOLDER_ID";
export const ADD_FOLDER_NAME = "ADD_FOLDER_NAME";
export const ADD_FOLDER_DESCRIPTION = "ADD_FOLDER_DESCRIPTION";
export const OPEN_FOLDER = "OPEN_FOLDER";
export const EDIT_FOLDER = "EDIT_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";

/* Poll Action Types */
export const ADD_POLL_ID = "ADD_POLL_ID";
export const ADD_POLL_TITLE = "ADD_POLL_TITLE";
export const ADD_POLL_OPTIONS = "ADD_POLL_OPTIONS";
export const ADD_POLL_OPTIONS_IMAGES = "ADD_POLL_OPTIONS_IMAGES";
export const ADD_POLL_PREVIEW_IMAGES = "ADD_POLL_PREVIEW_IMAGES";
export const ADD_POLL_OPTIONS_FILES = "ADD_POLL_OPTIONS_FILES";
export const ADD_POLL_OPTIONS_FILES_DATA = "ADD_POLL_OPTIONS_FILES_DATA";
export const RESET_POLL = "RESET_POLL";

/* Schedule Action Types */
export const ADD_SCHEDULE_ID = "ADD_SCHEDULE_ID";
export const ADD_SCHEDULE_TITLE = "ADD_SCHEDULE_TITLE";
export const ADD_SCHEDULE_DURATION_HOURS = "ADD_SCHEDULE_DURATION_HOURS";
export const ADD_SCHEDULE_DURATION_MINUTES = "ADD_SCHEDULE_DURATION_MINUTES";
export const ADD_SCHEDULE_PLACE = "ADD_SCHEDULE_PLACE";
export const ADD_SCHEDULE_AGENDA = "ADD_SCHEDULE_AGENDA";
export const RESET_SCHEDULE = "RESET_SCHEDULE";

/* Reading Action Types */
export const ADD_READING_ID = "ADD_READING_ID";
export const ADD_READING_TITLES = "ADD_READING_TITLES";
export const ADD_READING_LINKS = "ADD_READING_LINKS";
export const ADD_READING_FILES = "ADD_READING_FILES";
export const ADD_READING_URLS = "ADD_READING_URLS";
export const RESET_READING = "RESET_READING";

/* Common Task Actions */
export const ADD_TASK_CHANNEL_USERS = "ADD_TASK_CHANNEL_USERS";
export const ADD_TASK_DEADLINE = "ADD_TASK_DEADLINE";
export const ADD_TASK_FOLDER = "ADD_TASK_FOLDER";
export const ADD_TASK_OLD_FOLDER = "ADD_TASK_OLD_FOLDER";
export const ADD_TASK_EVERYONE = "ADD_TASK_EVERYONE";
export const SET_SHARING = "SET_SHARING";
export const SET_EDITING_PUBLISHED = "SET_EDITING_PUBLISHED";
export const SET_EDITING = "SET_EDITING";
export const SET_SHARED_TASK = "SET_SHARED_TASK";
export const SET_EDITED_TASK = "SET_EDITED_TASK";
export const SET_EDITED_PUBLISHED_TASK = "SET_EDITED_PUBLISHED_TASK";
export const RESET_TASK = "RESET_TASK";

// NoteApp

export const SET_SHOW_NOTEAPP = "SET_SHOW_NOTEAPP";

/* Whiteboard Actions */
export const SET_SHOW_WHITEBOARD = "SET_SHOW_WHITEBOARD";
export const SAVE_WHITEBOARD_LAYERS = "SAVE_WHITEBOARD_LAYERS";
export const SAVE_WHITEBOARD_BACKGROUND = "SAVE_WHITEBOARD_BACKGROUND";

export const SET_SHOW_CASESHEET = "SET_SHOW_CASESHEET";
export const SET_SHOW_PRF="SET_SHOW_PRF"
export const SET_SHOW_PRESCRIPTION = "SET_SHOW_PRESCRIPTION"
export const SET_PRESCRIPTION_EDIT_ID = "SET_PRESCRIPTION_EDIT_ID"

/* Gradebook Actions */
export const SET_SHOW_GRADEBOOK = "SET_SHOW_GRADEBOOK";

/* Attendance Ledger Action Types */
export const SET_SHOW_ATTENDANCE_LEDGER = "SET_SHOW_ATTENDANCE_LEDGER";

/* Workspace Action Types */
export const SET_CURRENT_WORKSPACE = "SET_CURRENT_WORKSPACE";
export const SET_WORKSPACES = "SET_WORKSPACES";
export const SET_ROOMS_VISIBLE = "SET_ROOMS_VISIBLE";
export const ACTIVE = "rooms";
export const ARCHIVE = "archivedRooms";
/* My drive action types*/
export const SET_SHOW_UPLOAD_FILES_MODAL = "SET_SHOW_UPLOAD_FILES_MODAL";
export const SET_SHOW_CREATE_FOLDER_MODAL = "SET_SHOW_CREATE_FOLDER_MODAL";
export const SET_SELECTED_FOLDER_ID = "SET_SELECTED_FOLDER_ID";


export const ADD_MESSAGES_FORWARD_ARRAY = "ADD_MESSAGES_FORWARD_ARRAY";

export const SET_TEXT_UNSEND_MESSAGE = "SET_TEXT_UNSEND_MESSAGE";
export const SET_TYPED_TEXT_MESSAGE = "SET_TYPED_TEXT_MESSAGE";

export const SET_WORKSPACE_SLUG = "SET_WORKSPACE_SLUG";