import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Spinner,
  Dropdown,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Button,
  Alert,
} from "react-bootstrap";
import {
  API_BASE_URL,
  DRIVE_HIERARCHY,
  DRIVE_UPLOAD,
} from "../../config/index";
import AddFiles from "../Tasks/Cloud/AddFiles";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import {
  setShowCreateFolderModal,
  setShowUploadFilesModal,
  setSelectedFolderId,
} from "../../actions/index";
toast.configure();
const UploadFilesIntoMyDrive = (props) => {
  const {
    setShowCreateFolderModal,
    showCreateFolderModal,
    setShowUploadFilesModal,
    showUploadFilesModal,
    setSelectedFolderId,
  } = props;
  //const [errorObj, setErrorObj] = useState({});
  const [selectedFolder, setSelectedFolder] = useState({});
  const [fileObj, setFileObj] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [metaData, setMetaData] = useState(null);
  const [type, setType] = useState("");
  const [fileSizeError, setFileSizeError] = useState([]);
  const addFile = (event) => {
    //console.log(event.target.files);
    let file = event.target.files[0];
    if (file) {
      fileObj.push(file);
      setFileObj(fileObj);
    }
  };
  const handleClose = () => {
    setFileObj([]);
    setSubmit(false);
    setShowUploadFilesModal(!showUploadFilesModal);
    setSelectedFolderId(null);
  };
  const uploadMultipleFiles = (e) => {
    //console.log(e.target.files);

    const fileObj = [...e.target.files];
    setFileObj(fileObj);
    setFileSizeError("");
  };

  useEffect(() => {
    let prevFileSizeError = [...fileSizeError];
    fileObj.forEach((file, index) => {
      if (file.size > 20971520) {
        prevFileSizeError[index] = 1;
      } else {
        prevFileSizeError[index] = 0;
      }
    });
    if (!fileObj.length) {
      prevFileSizeError = [];
    }
    setFileSizeError(prevFileSizeError);
  }, [fileObj, fileObj.length]);

  const uploadFiles = () => {
    setSubmit(true);
    let allImagesUploadedPromises = fileObj.map((file) => uploadFile(file));
    Promise.all(allImagesUploadedPromises).then(async (values) => {
      //console.log("uplaoded files", values);
      if (props.darkTheme) {
        toast.dark("Files Uploaded", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } else if (!props.darkTheme) {
        toast("Files Uploaded", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
      setSubmit(false);
      handleClose();
      setFileObj([]);
    });
  };
  const uploadFile = async (file) => {
    const { currentUser, selectedFolderId } = props;
    //console.log(props);
    const fileData = new FormData();
    fileData.append("file", file, `${file.lastModified}-${file.name}`);
    fileData.append("user_id", currentUser.uid);

    if (selectedFolderId) {
      fileData.append("folder_id", selectedFolderId);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        setPercentUploaded((ProgressEvent.loaded * 100) / ProgressEvent.total);
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        // console.log(res);
        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
      }
    } else return "";
  };
  const handleDrop = (ev) => {
    //console.log("File(s) dropped");
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      let files = [];
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === "file") {
          var file = ev.dataTransfer.items[i].getAsFile();
          //console.log(file);
          //console.log("... file[" + i + "].name = " + file.name);
          // this.setState({ file });
          files.push(file);
        }
      }
      setFileObj(files);
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        // console.log(
        // "... file[" + i + "].name = " + ev.dataTransfer.files[i].name
        //);
        //setFileObj(ev.dataTransfer.files);
      }
    }
  };

  const handleDrag = (ev) => {
    //console.log("File(s) in drop zone");
    ev.preventDefault();
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const fileNameShrinker = (value) => {
    return value.length > 30 ? value.slice(0, 30) + "…" : value;
  };

  const removeFileHandler = (index) => {
    setFileObj((prevFileObj) => {
      return prevFileObj.filter((file, i) => i !== index);
    });
  };

  const uploadFilesErrorCount = Array.isArray(fileSizeError)
    ? fileSizeError.reduce((a, b) => a + b, 0)
    : null;

  return (
    <>
      <div
        onDrop={(event) => handleDrop(event)}
        onDragOver={(event) => handleDrag(event)}
      >
        <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUploadFilesModal}
          onHide={() => handleClose()}
        >
          <Modal.Body className="bg-theme text-theme border-0 pb-0">
            <div
              title="Close"
              className="pointer d-flex justify-content-end mb-0"
              onClick={() => handleClose()}
            >
              <FontAwesomeIcon className="text-theme" icon={faTimes} />
            </div>
            <h3 className="mb-3">Upload Files</h3>
            <Form>
              <div className="form-group">
                {fileObj.length ? (
                  <>
                    <div className="box__input">
                      <input
                        className="box__file"
                        type="file"
                        name="files[]"
                        id="file"
                        data-multiple-caption="{count} files selected"
                        multiple
                        onChange={(e) => {
                          uploadMultipleFiles(e);
                        }}
                      />
                      <label for="file">
                        <strong>Choose a file</strong>
                        <span className=""> or drag it here</span>.
                      </label>
                    </div>
                  </>
                ) : (
                  <div className="box has-advanced-upload text-center">
                    <FontAwesomeIcon
                      size="5x"
                      icon={faCloudUploadAlt}
                      className="box__input text-theme"
                    ></FontAwesomeIcon>
                    <div className="box__input">
                      <input
                        className="box__file"
                        type="file"
                        name="files[]"
                        id="file"
                        data-multiple-caption="{count} files selected"
                        multiple
                        onChange={(e) => {
                          uploadMultipleFiles(e);
                        }}
                      />
                      <label for="file">
                        <strong>Choose a file</strong>
                        <span className=""> or drag it here</span>.
                      </label>
                    </div>
                  </div>
                )}
                <div className="overflow-auto" style={{ maxHeight: "30vh" }}>
                  {fileObj &&
                    fileObj.map((file, index) => {
                      if (fileSizeError[index]) {
                        return (
                          <Alert className="small" key={index} variant="danger">
                            <div className="d-flex justify-content-between align-items-center">
                              {fileNameShrinker(file.name)}
                              <span className="d-flex align-items-center">
                                {formatBytes(file.size)}{" "}
                                <FontAwesomeIcon
                                  onClick={() => removeFileHandler(index)}
                                  className="text-danger ml-2"
                                  style={{ cursor: "pointer" }}
                                  icon={faTimes}
                                />
                              </span>
                            </div>
                          </Alert>
                        );
                      } else {
                        return (
                          <Alert
                            className="small"
                            key={index}
                            variant="success"
                          >
                            <div className="d-flex justify-content-between">
                              {fileNameShrinker(file.name)}
                              <span className="d-flex align-items-center">
                                {formatBytes(file.size)}{" "}
                                <FontAwesomeIcon
                                  onClick={() => removeFileHandler(index)}
                                  className="text-success ml-2"
                                  style={{ cursor: "pointer" }}
                                  icon={faTimes}
                                />
                              </span>
                            </div>
                          </Alert>
                        );
                      }
                    })}
                </div>
              </div>
              {/* {fileSizeError && (
                <p className="text-danger small">{fileSizeError}</p>
              )} */}
              {/* {submit ? (
                <Button variant="primary my-1" disabled>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Uploading...
                </Button>
              ) : (
                <Button
                  variant="primary my-1"
                  size="sm"
                  disabled={fileObj.length < 1}
                >
                  <small>Submit</small>
                </Button>
              )} */}
            </Form>
          </Modal.Body>
          <Modal.Footer className="bg-theme text-theme border-0 pb-0">
            {uploadFilesErrorCount ? (
              <p className="text-danger">
                One or more file(s) exceeds the allowed limit of 20MB.{" "}
              </p>
            ) : null}
            {fileObj.length && !uploadFilesErrorCount ? (
              <Button
                className="mb-3 btn-sm"
                onClick={uploadFiles}
                disabled={submit}
              >
                Upload
                {submit && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
            ) : (
              <Button className="mb-3 btn-sm" onClick={() => handleClose()}>
                Close
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  //folder: state.task.folder,
  showUploadFilesModal: state.uploadFilesModal.showUploadFilesModal,
  showCreateFolderModal: state.createFolderModal.showCreateFolderModal,
  selectedFolderId: state.selectedFolder.id,
  darkTheme: state.platform.darkTheme,
});
export default connect(mapStateToProps, {
  setShowCreateFolderModal,
  setShowUploadFilesModal,
  setSelectedFolderId,
})(UploadFilesIntoMyDrive);
