import React, { useState, useEffect } from "react";
import uuidv4 from "uuid/v4";
import mime from "mime-types";
import firebase from "../../firebase";
import Container from "react-bootstrap/Container";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSmile,
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faPlusCircle,
  faTimes,
  faShare,
  faFileAudio
} from "@fortawesome/free-solid-svg-icons";
import { ProgressBar } from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import { isMobile } from "react-device-detect";
import Picker from 'emoji-picker-react';
import RoomSelectModalForMessages from "./RoomSelectModalForMessages";

import {
  FormControl,
  Button,
  InputGroup,
  DropdownButton,
  Dropdown,
  Form,
  Modal,
  Badge,
} from "react-bootstrap";
import { connect } from "react-redux";
import { setMessageForward, setReplyId, setMessagesForwardArray, setCurrentChannelConfig, setTextUnsendMessage, setTypedTextMessage } from "../../actions/index";
import ReplyTemplate from "./ReplyTemplate";
import { API_BASE_URL, DRIVE_FILE, DRIVE_UPLOAD, SEND_MESSAGE, META_TAGS } from "../../config/index";
import { toast } from "react-toastify";
import * as linkify from 'linkifyjs';
import Linkify from 'linkifyjs/react';
import URLPreviewTemplate from "./URLPreviewTemplate";
import _ from 'lodash';
toast.configure();

function AddImage({
  content,
  handleContent,
  handleMetaData,
  handleType,
  percentUploaded,
  addFile,
  sendFile,
  file,
  removeFile,
  clearFile,
}) {
  const [show, setShow] = useState(false);
  const [preview, setPreview] = useState(null);
  const [allowedType, setAllowedType] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [sending, setSending] = useState(false);
  const [fileSizeError,setFileSizeError] = useState("");

  useEffect(() => {
    metaData && allowedType && handleMetaData(metaData);
    if (metaData && allowedType && image_type.includes(metaData.type))
      handleType("Image");
    if (
      metaData &&
      allowedType &&
      (word_type.includes(metaData.type) ||
        pdf_type.includes(metaData.type) ||
        powerpoint_type.includes(metaData.type) ||
        excel_type.includes(metaData.type))
    )
      handleType("File");
    if(metaData && allowedType && audio_type.includes(metaData.type)) {
      handleType("Audio");
    }
  }, [metaData, allowedType]);

  useEffect(() => {
    sending &&
      percentUploaded === 100 &&
      setTimeout(() => {
        handleClose();
        setPreview(null);
        setAllowedType(false);
        setMetaData(null);
        setSending(false);
        //handleContent("");
      }, 1500);
  }, [sending, percentUploaded]);

  const image_type = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"];

  const audio_type = ["audio/mpeg", "audio/mp4", "audio/x-mpegurl", "audio/ogg", "audio/vnd.wav", "audio/wav", "audio/wave"]

  const pdf_type = ["application/pdf"];

  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const handleClose = () => {
    setShow(false);
    setPreview(null);
    setAllowedType(false);
    setMetaData(null);
    setSending(false);
    removeFile();
    clearFile();
    console.log("called all methods");
  };
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (file) {
      console.log(file);
      setShow(true);
      const reader = new FileReader();
      if (file.size > 20971520) {
        toast.error(
          "The maximum file size is 20MB. Please choose a different file.",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      } else {
        if (file) {
          setMetaData({
            lastModified: file.lastModified,
            lastModifiedDate: JSON.stringify(file.lastModifiedDate),
            name: file.name,
            size: file.size,
            type: file.type,
          });
          setAllowedType(
            image_type.includes(file.type) ||
            word_type.includes(file.type) ||
            pdf_type.includes(file.type) ||
            powerpoint_type.includes(file.type) ||
            excel_type.includes(file.type) ||
            audio_type.includes(file.type)
          );
          reader.readAsDataURL(file);
          reader.addEventListener("load", () => {
            setPreview(reader.result);
          });
        }
      }
    }
  }, [file]);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log(file);
    const reader = new FileReader();
    if (file.size > 20971520) {
      toast.error(
        "The maximum file size is 20MB. Please choose a different file.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    } else {
      if (file) {
        setMetaData({
          lastModified: file.lastModified,
          lastModifiedDate: JSON.stringify(file.lastModifiedDate),
          name: file.name,
          size: file.size,
          type: file.type,
        });
        setAllowedType(
          image_type.includes(file.type) ||
          word_type.includes(file.type) ||
          pdf_type.includes(file.type) ||
          powerpoint_type.includes(file.type) ||
          excel_type.includes(file.type) ||
          audio_type.includes(file.type)
        );
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
          setPreview(reader.result);
        });
      }
    }
  };

  return (
    <>
      <FontAwesomeIcon
        className="pointer"
        style={{ cursor: "pointer" }}
        onClick={handleShow}
        icon={faPlusCircle}
        className="actionboxicon"
      />
      <Modal centered={true} show={show} onHide={handleClose} animation={true}>
        <Modal.Body className="bg-card-theme text-theme" >
        <div title="Close" className="pointer d-flex justify-content-end mb-0" onClick={() =>  handleClose() }>
            <FontAwesomeIcon className="text-theme" icon={faTimes} />
        </div>
        <h3 className="my-2 mb-3">Send File</h3>
          {preview && (
            <div>
              {/* {metaData && <p>{JSON.stringify(metaData)}</p>} */}
              {!allowedType && (
                <small className="text-danger">
                  Sorry! but this file type is not supported yet.
                </small>
              )}
              {allowedType && image_type.includes(metaData.type) && (
                <div
                  className="mb-3 mx-auto"
                  style={{
                    height: "300px",
                    width: "300px",
                    overflow: "hidden",
                  }}
                >
                  <img src={preview} alt="icon" className="rounded w-100" />
                </div>
              )}

              <div className="d-flex rounded bg-light mb-0">
                <div
                  className="py-2 px-3"
                  style={{ backgroundColor: "#FEF2E4" }}
                >
                  {!allowedType && (
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="text-secondary"
                    />
                  )}
                  {image_type.includes(metaData.type) && (
                    <FontAwesomeIcon
                      icon={faImage}
                      className="text-secondary"
                    />
                  )}
                  {word_type.includes(metaData.type) && (
                    <FontAwesomeIcon
                      icon={faFileWord}
                      className="text-primary"
                    />
                  )}
                  {pdf_type.includes(metaData.type) && (
                    <FontAwesomeIcon icon={faFilePdf} className="text-danger" />
                  )}
                  {powerpoint_type.includes(metaData.type) && (
                    <FontAwesomeIcon
                      icon={faFilePowerpoint}
                      className="text-danger"
                    />
                  )}
                  {excel_type.includes(metaData.type) && (
                    <FontAwesomeIcon
                      icon={faFileExcel}
                      className="text-success"
                    />
                  )}
                  {audio_type.includes(metaData.type) && (
                    <FontAwesomeIcon
                      icon={faFileAudio}
                      className="text-success"
                    />
                  )}
                </div>
                <div className="p-2 flex-grow-1">
                  <small className="font-weight-bold">{metaData.name}</small>
                </div>
                <div className="py-2 px-3">
                  <FontAwesomeIcon icon={faLink} className="text-secondary" />
                </div>
              </div>
              <div className="mb-3">
                {sending && (
                  <ProgressBar
                    style={{ height: "0.2rem" }}
                    now={percentUploaded}
                    // label={`${percentUploaded} %`}
                    striped
                    variant="success"
                  />
                )}
              </div>
            </div>
          )}
          <InputGroup className="mb-3">
            <FormControl
              aria-label="Default"
              aria-describedby="inputGroup-sizing-default"
              onChange={(e) => {
                if(e.target.files[0].size > 20971520){
                  setFileSizeError("The maximum file size is 20MB. Please choose a different file.")
                }else{
                  addFile(e);
                  handleFileChange(e);
                }
                
              }}
              type="file"
              name="previewImage"
            />
          </InputGroup>
          {fileSizeError && <p className="text-danger small">{fileSizeError}</p>}
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Add a Caption..."
              aria-label="Add Content"
              name="content"
              onChange={(e) => handleContent(e.target.value)}
              required={true}
              value={content}
            />
          </InputGroup>
          <div className="d-flex justify-content-end">
            <Button
              className="mr-5 fa-2x"
              disabled={!allowedType && true}
              color="red"
              onClick={() => {
                sendFile();
                setSending(true);
              }}
              color="#0000A0"
            >
              Send <FontAwesomeIcon icon={faPaperPlane} />
            </Button>
          </div>
        </Modal.Body>
        
      </Modal>
    </>
  );
}

class CommentBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRoomSelectModalForMessages: false,
       
      storageRef: firebase.storage().ref(),
      typingRef: firebase.database().ref("typing"),
      uploadTask: null,
      uploadState: "",
      percentUploaded: 0,
      repliedMsg: {},

      message: "",
      content: "",
      metaData: {},
      type: "",

      channel: props.currentChannel,
      user: props.currentUser,
      loading: false,
      errors: [],
      modal: false,
      emojiPicker: false,
      file: null,
      authorized: [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/gif",

        "application/pdf",

        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "audio/mpeg", 
        "audio/mp4", 
        "audio/x-mpegurl", 
        "audio/ogg", 
        "audio/vnd.wav", 
        "audio/wav",
        "audio/wave"
      ],
      adminOnlyChat: false,
      unsubscribe: null,
      isAdmin: false,
      firstLinkInMessage: null,
      metaTags: null,
      noURLPreview: false
    };

    this.messageInputRef = React.createRef();
  }

  componentDidMount() {
    // document.addEventListener("keydown", this.fancyKeyDown, false);
    if (this.props.replyId) {
      this.getMessageData(this.props.replyId);
    }
    if (this.props.file) {
      this.setState({ file: this.props.file });
    }
    
    this.messageInputRef && this.messageInputRef.current && this.messageInputRef.current.focus();
    console.log(" did mount")

    this.getIsAdminHandler();


    // listening for onsnapshot (realtime) changes in roomConfig collection

    const unsub = firebase.firestore().collection(`roomConfig/${this.props.currentWorkspace.workspaceId}/rooms`).doc(this.props.currentChannel.roomId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          // doc exists that means the admin only chat feature has been update atleast once
          if (doc.data().adminOnlyChat) {
            this.setState({ adminOnlyChat: true })
            this.props.setCurrentChannelConfig({ workspace_id: this.props.currentWorkspace.workspaceId, 
              room_id: this.props.currentChannel.roomId, 
              admin_only_chat: true, 
              special_olympics_app_allowed: doc.data().special_olympics_app_allowed ? doc.data().special_olympics_app_allowed : false })
          } else {
            this.setState({ adminOnlyChat: false })
            this.props.setCurrentChannelConfig({
              workspace_id: this.props.currentWorkspace.workspaceId,
              room_id: this.props.currentChannel.roomId,
              admin_only_chat: false,
              special_olympics_app_allowed: doc.data().special_olympics_app_allowed ? doc.data().special_olympics_app_allowed : false
            })
          }
        }
        else {
          // doc doesn't exist that means admin only chat feature has never been used
          console.log("doc doesnt exists admin only")
          this.setState({ adminOnlyChat: false })
          this.props.setCurrentChannelConfig({
            workspace_id: this.props.currentWorkspace.workspaceId,
            room_id: this.props.currentChannel.roomId,
            admin_only_chat: true,
            special_olympics_app_allowed: doc.data()?.special_olympics_app_allowed ? doc.data().special_olympics_app_allowed : false
          })
        }
      })

    this.setState({ unsubscribe: unsub })



  }
  componentDidUpdate(prevProps, prevState) {

    //console.log(prevProps.currentChannel.roomId, this.props.currentChannel.roomId,"textUnsendMessage called")
    let message = this.props.textUnsendMessage.find((texts) => texts.roomId === this.props.currentChannel.roomId)
    // console.log(message,"textUnsendMessage from mount");
    if (message) {
      this.props.setTypedTextMessage(message.message);
      this.messageInputRef && this.messageInputRef.current && this.messageInputRef.current.focus();
    } else {
      this.props.setTypedTextMessage("");
    }

    //console.log(prevProps.replyId, this.props.replyId,"action respoxx11")
    if (prevProps.replyId !== this.props.replyId) {

      if (this.props.replyId) {
        this.getMessageData(this.props.replyId);
        //console.log("action respoxx11 called")
      }
    }
    if (prevProps.file !== this.props.file) {
      this.setState({ file: this.props.file });
    }

    //console.log("did update respoxx12")

    this.messageInputRef && this.messageInputRef.current && this.messageInputRef.current.focus();


    // listening for onsnapshot (realtime) changes in roomConfig collection
    if (prevProps.currentChannel.roomId !== this.props.currentChannel.roomId) {
      this.getIsAdminHandler();
      console.log("did update in if", prevProps.currentChannel.roomId , this.props.currentChannel.roomId)
      const unsub = firebase.firestore().collection(`roomConfig/${this.props.currentWorkspace.workspaceId}/rooms`).doc(this.props.currentChannel.roomId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            // doc exists that means the admin only chat feature has been update atleast once
            if (doc.data().adminOnlyChat) {
              this.setState({ adminOnlyChat: true })
              this.props.setCurrentChannelConfig({
                workspace_id: this.props.currentWorkspace.workspaceId,
                room_id: this.props.currentChannel.roomId,
                admin_only_chat: true,
                special_olympics_app_allowed: doc.data().special_olympics_app_allowed ? doc.data().special_olympics_app_allowed : false
              })
            } else {
              this.setState({ adminOnlyChat: false })
              this.props.setCurrentChannelConfig({
                workspace_id: this.props.currentWorkspace.workspaceId,
                room_id: this.props.currentChannel.roomId,
                admin_only_chat: false,
                special_olympics_app_allowed: doc.data().special_olympics_app_allowed ? doc.data().special_olympics_app_allowed : false
              })
            }
          }
          else {
            // doc doesn't exist that means admin only chat feature has never been used
            console.log("doc doesnt exists admin only")
            this.setState({ adminOnlyChat: false })
            this.props.setCurrentChannelConfig({
              workspace_id: this.props.currentWorkspace.workspaceId,
              room_id: this.props.currentChannel.roomId,
              admin_only_chat: false,
              special_olympics_app_allowed: doc.data()?.special_olympics_app_allowed ? doc.data().special_olympics_app_allowed : false
            })
          }
        })

      this.setState({ unsubscribe: unsub })
      this.setState({ metaTags: null, firstLinkInMessage: null, noURLPreview: false })
    }


    // calling the cloud function to get preview of any link if it is in the text area
    if (prevProps.typedTextMessage !== this.props.typedTextMessage && this.props.typedTextMessage.length !== 0 && linkify.find(this.props.typedTextMessage).length !== 0 && linkify.find(this.props.typedTextMessage)[0].type === "url" && !this.state.firstLinkInMessage && !this.state.noURLPreview) {
      console.log("first case", this.props.typedTextMessage)
      this.setState({ firstLinkInMessage: linkify.find(this.props.typedTextMessage)[0].href }, () => {
        axios.post((`${API_BASE_URL}${META_TAGS}`), {
          url: this.state.firstLinkInMessage
        }).then((res) => {
          this.setState({ metaTags: res.data.data.meta_data })
        })
      })
    }
    if (prevProps.typedTextMessage !== this.props.typedTextMessage && this.props.typedTextMessage.length !== 0 && linkify.find(this.props.typedTextMessage).length !== 0 && linkify.find(this.props.typedTextMessage)[0].type === "url" && this.state.firstLinkInMessage && !this.state.noURLPreview) {
      const debouncedFunc = _.debounce(() => {
        axios.post((`${API_BASE_URL}${META_TAGS}`), {
          url: this.state.firstLinkInMessage
        }).then((res) => {
          this.setState({ metaTags: res.data.data.meta_data })
        })
      },1500)
      this.setState({ firstLinkInMessage: linkify.find(this.props.typedTextMessage)[0].href }, () => {
        debouncedFunc()
      })
    }
    if (this.props.typedTextMessage.length === 0 && this.state.metaTags) {
      this.setState({ metaTags: null, firstLinkInMessage: null, noURLPreview: false })
    }
    if (prevProps.typedTextMessage !== this.props.typedTextMessage && linkify.find(this.props.typedTextMessage).length === 0 && this.state.firstLinkInMessage) {
      this.setState({ firstLinkInMessage: null, metaTags: null })
    }
    if (prevProps.typedTextMessage !== this.props.typedTextMessage && this.props.typedTextMessage.length === 0) {
      this.setState({ noURLPreview: false })
    }
  }

  componentWillUnmount() {
    if (this.state.uploadTask !== null) {
      this.state.uploadTask.cancel();
      this.setState({ uploadTask: null });
    }

    if(this.state.unsubscribe) {
      console.log("unsub action box on unmount")
      this.state.unsubscribe()
      // this.setState({adminOnlyChat: false, unsubscribe: null})
    }
    // document.removeEventListener("keydown", this.fancyKeyDown, false);
  }

  // fancyKeyDown = event => {
  //     // if (event.keyCode === 27) {
  //     //     this.messageInputRef.current.blur()
  //     // } else {
  //     //     this.messageInputRef.current.focus()
  //     // };

  //     if (event.keyCode >= 48 && event.keyCode <= 90) {
  //         this.messageInputRef.current.focus()
  //     }
  // }

  handleChange = (event) => {
    //console.log(this.props.textUnsendMessage,"textUnsendMessage 1")
    this.props.setTypedTextMessage(event.target.value);
    //this.setState({ message: event.target.value });
    let arr = this.props.textUnsendMessage;
    let myData = arr.find((array) => array.roomId === this.props.currentChannel.roomId)
    if (myData) {
      myData["message"] = event.target.value;

    } else {
      arr.push({ roomId: this.props.currentChannel.roomId, message: event.target.value })
    }

  };


  onEmojiClick = (event, emoji) => {
    this.setState({ message: this.state.message + emoji.emoji });
    this.messageInputRef.current.focus();
    //setReplyId("");

  }


  handleKeyDown = (event) => {
    if (event.ctrlKey && event.keyCode === 13) {
      this.setState({ message: this.state.message + "\n" });
      console.log("CTRL");
    } else if (event.shiftKey && event.keyCode === 13) {
      this.setState({ message: this.state.message });
      console.log("SHIFT");
    } else if (event.keyCode === 13) {
      this.sendMessage();
      this.messageInputRef.current.blur();
      console.log("ENTER");
    } else {
    }

    const { message, typingRef, channel, user } = this.state;

    // if (message) {
    //     typingRef
    //         .child(channel.id)
    //         .child(user.uid)
    //         .set(user.displayName);
    // } else {
    //     typingRef
    //         .child(channel.id)
    //         .child(user.uid)
    //         .remove();
    // }
  };

  createMessage = (fileUrl = null) => {
    const { currentUser } = this.props;

    const message = {
      // timestamp: Date.now(),
      // createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      user: {
        id: currentUser.uid,
        name: currentUser.displayName,
        avatar: currentUser.photoURL,
      },
    };
    if (this.props.replyId !== "") {
      message["replyId"] = this.props.replyId;
    }
    if (fileUrl !== null) {
      if (this.state.type === "File") {
        message["file"] = fileUrl;
        message["type"] = 7;
      }
      if (this.state.type === "Image") {
        message["image"] = fileUrl;
        message["type"] = 6;
      }
      if (this.state.type === "Audio") {
        message["audio"] = fileUrl;
        message["type"] = 11;
      }
      // message["type"] = this.state.type;
      message["metaData"] = this.state.metaData;
      message["content"] = this.state.content;
    } else if (!this.state.metaTags) {
      message["content"] = this.props.typedTextMessage;
      message["type"] = 0;
    } else if (this.state.metaTags) {
      message["content"] = this.props.typedTextMessage;
      message["type"] = 12;
      message["metaTags"] = this.state.metaTags;
      message["url"] = this.state.firstLinkInMessage;
    }
    return message;
  };

  sendMessage = (e) => {
    const { currentChannel, currentWorkspace, setReplyId, currentUser, setTypedTextMessage, typedTextMessage } = this.props;
    const { message, channel, user, typingRef } = this.state;

    if (typedTextMessage) {
      this.setState({ loading: true });
      this.props.setReplyId("");


      const newMessage = this.createMessage();


      axios.post(`${API_BASE_URL}${SEND_MESSAGE}`, {
        workspace_id: currentWorkspace.workspaceId,
        room_id: currentChannel.roomId,
        user_id: currentUser.uid,
        message: { ...newMessage }
      }).then(() => {

        let message = this.props.textUnsendMessage.find((texts) => texts.roomId === this.props.currentChannel.roomId)
      // console.log(message,"textUnsendMessage from mount");
        if(message){

          let arr = this.props.textUnsendMessage;
          arr.splice(arr.findIndex(v => v.roomId === this.props.currentChannel.roomId), 1);

          console.log(arr,"textUnsendMessage sended")
        }else{
          this.props.setTypedTextMessage("");
        }
        setReplyId("");
        setTypedTextMessage("");
        this.setState({
          loading: false,
          message: "",
          errors: [],
          firstLinkInMessage: null,
          metaTags: null,
          noURLPreview: false
        });
        this.messageInputRef.current.focus();
        // typingRef.child(channel.id).child(user.uid).remove();
      })


    } else {
      this.setState({
        errors: this.state.errors.concat({ message: "Add a message" }),
      });
    }
  };

  getPath = () => {
    const { currentWorkspace, currentChannel } = this.props;

    if (this.props.isPrivateChannel) {
      return `chat/private/${this.state.channel.id}`;
    } else {
      return `chat/${currentWorkspace.workspaceId}/${currentChannel.roomId}`;
    }
  };



  sendFileMessage = (fileUrl, ref, pathToUpload) => {
    // let newMessage = ref.child(pathToUpload).push();
    // let newMessageKey = newMessage.key;

    const { currentWorkspace, currentChannel, currentUser } = this.props;

    const db = firebase
      .firestore()
      .collection(
        `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      );
    const dbUpdate = firebase
      .firestore()
      .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

    const newMessage = this.createMessage(fileUrl);

    console.log("FILE MESSAGE: ", newMessage);

    // db.add({ ...newMessage })
    //   .then((docRef) => {
    //     dbUpdate
    //       .doc(`${currentChannel.roomId}`)
    //       .update({
    //         totalMessages: firebase.firestore.FieldValue.increment(1),
    //         lastMessage: { ...newMessage, uid: docRef.id },
    //       })
    //       .then(() => {
    //         this.setState({ content: "" });
    //       });
    //     // setReplyId("");
    //     // this.setState({ loading: false, message: "", errors: [] });
    //     // typingRef
    //     //     .child(channel.id)
    //     //     .child(user.uid)
    //     //     .remove();
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //     // this.setState({
    //     //     loading: false,
    //     //     errors: this.state.errors.concat(err)
    //     // });
    //   });
    

    axios.post(`${API_BASE_URL}${SEND_MESSAGE}`, {
      workspace_id: currentWorkspace.workspaceId,
      room_id: currentChannel.roomId,
      user_id: currentUser.uid,
      message: { ...newMessage }
    }).then(() => {
      this.setState({ content: "" });
      setReplyId("");
    })


  };
  /* uploadFile = async (file) => {
    console.log("\nFile: ", file);
    const { currentUser } = this.props;
    const config = {
      onUploadProgress: (ProgressEvent) =>
        this.setState({
          percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,
        }),
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_FILE}`,
          {
            user_id: currentUser.uid,
            file_name: file.name,
            file_content_type: file.type,
            file_content: file.file_content,
          },
          config
        );

        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
      }
    } else return "";
  };*/

  /* getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };*/
  addFile = (event) => {
    let file = event.target.files[0];
    /* if (event.target.files[0]) {
      let result = this.getBase64(event.target.files[0]);
      result.then((data) => {
        //console.log(data);
        file.file_content = data;
        this.setState({ file });
      });
    }*/
    if (file) {
      this.setState({ file });
    }
  };
  uploadFile = async (file) => {
    const { currentUser, folder_id } = this.props;
    console.log(this.props);
    let requestData = {};

    const fileData = new FormData();
    fileData.append("file", file, `${file.lastModified}-${file.name}`);
    fileData.append("user_id", currentUser.uid);
console.log(fileData);
    if (folder_id) {
      fileData.append("folder_id", folder_id);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        this.setState({
          percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,
        });
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        // console.log(res);
        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
      }
    } else return "";
  };

  sendFile = () => {
    const { file } = this.state;
    const { setReplyId } = this.props;
    //const { closeModal } = this.props;
    const pathToUpload = this.state.channel.id;
    const ref = this.props.currentChannel.roomId;
    // console.log("file recieved to send");
    console.log(file);
    if (file !== null) {
      console.log(mime.lookup(file.name));
      if (this.isAuthorized(file.name)) {
        const metadata = { contentType: mime.lookup(file.name) };
        //this.uploadFile(file, metadata);

        try {
          let result = this.uploadFile(file);
          console.log(result);
          result.then((values) => {
            console.log(values);
            this.sendFileMessage(values, ref, pathToUpload);
            // closeModal();
            setReplyId("");
            this.clearFile();
            //console.log(this.props.replyId,"respoxxx")
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  isAuthorized = (filename) =>
    this.state.authorized.includes(mime.lookup(filename));

  clearFile = () => this.setState({ file: null });

  getMessageData = async (id) => {
    let messageData = {};
    let { currentChannel, currentWorkspace } = this.props;
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      );
    messageData = (await query.doc(id).get()).data();
    console.log(messageData,"getMessageData");
    this.setState({ repliedMsg: messageData });
  };

  messageForward = () => {
    console.log("called")
    if(this.props.messagesForwardArray.length > 0){
      console.log("called1")
      this.setState({showRoomSelectModalForMessages: true})
      console.log(this.state.showRoomSelectModalForMessages)
    }  
  }

  closeForward = () => {
    this.props.setMessageForward(false)
    
      this.props.setMessagesForwardArray([]);
      //let myArray = this.props.messagesForwardArray;
      //myArray.splice(0,myArray.length)
      //this.props.setMessagesForwardArray(myArray);
  }


  getIsAdminHandler = async () => {
    const {currentChannel, currentWorkspace, currentUser} = this.props;
    //console.log(currentWorkspace.workspaceId, currentChannel,"my id")
    if (currentChannel && currentWorkspace && currentUser.uid) {
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/participants`
        );
       query.onSnapshot((snapshot) => {
        //console.log("admin changed")
          snapshot.forEach((s) => {
            //console.log(s.data(), s.id, currentUser.uid)
            if(s.id === currentUser.uid || currentUser.uid === currentChannel.createdBy){
              console.log(s.data(),s.data().isAdmin,"datata")
              if(s.data().isAdmin || currentUser.uid === currentChannel.createdBy){
                this.setState({isAdmin:true})
              }
              else{
                this.setState({isAdmin:false})
              }
            }
          })
       })
    }
  };
  
       /*  let json = [];
        snapshot.docChanges().forEach((s) => {
          json.push({ ...s.doc.data(), id: s.doc.id });
        })
                  
        let coAdmins = json.filter((participant) => participant.isAdmin);

        if (
          currentChannel.createdBy === currentUser.uid ||
          coAdmins.find((usr) => usr.id === currentUser.uid)
        ) {
          console.log("setted true",coAdmins)
          this.setState({isAdmin:  true});
        } else {
          console.log("setted false",coAdmins, )
          this.setState({isAdmin:  false});

        }
      })*/


    
  


  render() {
    //console.log(this.state.file);
    // prettier-ignore
    const { message, loading, repliedMsg } = this.state;
    //console.log(repliedMsg);
    if (this.props.replyId) {
      this.messageInputRef.current.focus();
    }
    return (

    // conditionally rendering this part so if room is in admin only chat actionbox doesn't get rendered
    <>

    {
      this.state.adminOnlyChat && this.state.isAdmin ?

      <>
      { this.props.forwardCheckbox ? 
          (
            <Container className="p-3">
              <div className="row">
              <FontAwesomeIcon 
                title="cancel"
                className="pointer mx-5"
                style={{ cursor: "pointer" }}
                onClick={() => this.closeForward()}
                className="actionboxicon" 
                icon={faTimes} />
              
              <FontAwesomeIcon 
                title="forwarding"
                className="pointer mx-5"
                style={{ cursor: "pointer" }}
                onClick={() => this.messageForward()}
                className="actionboxicon" 
                icon={faShare} />
              </div>
            </Container>
          ):
          (<Container className="p-3">
            {/* <InputGroup className="mb-3">
                        <FormControl
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        onChange={this.addFile}
                        type="file"
                        label="New Avatar"
                        name="previewImage"
                        />
                    </InputGroup> */}

                  {this.props.replyId && Object.keys(this.state.repliedMsg).length > 0 && (
                    <div className="replybox text-theme">
                      <ReplyTemplate message={this.state.repliedMsg} />
                    </div>
                  )}

                  {this.state.metaTags && Object.keys(this.state.metaTags).length > 0 && (
                    <div className="replybox text-theme">
                        <URLPreviewTemplate onClose={() => {this.setState({ firstLinkInMessage: null, metaTags: null, noURLPreview: true })}} message={{metaTags: this.state.metaTags}} />                    </div>
                  )}
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.sendMessage();
              }}
            >
              <InputGroup>
                <InputGroup.Prepend>
                  <AddImage
                    content={this.state.content}
                    handleContent={(content) => this.setState({ content })}
                    handleMetaData={(data) => this.setState({ metaData: data })}
                    handleType={(type) => this.setState({ type })}
                    percentUploaded={this.state.percentUploaded}
                    addFile={this.addFile}
                    sendFile={this.sendFile}
                    file={this.state.file}
                    removeFile={this.props.removeFile}
                    clearFile={this.clearFile}
                  />
                </InputGroup.Prepend>

                {/* <FormControl aria-describedby="basic-addon1" aria-label="With textarea"
                                name="message"
                                onChange={this.handleChange}
                                onKeyDown={this.handleKeyDown}
                                value={message}
                                ref={node => (this.messageInputRef = node)}
                                id="messageinputbox"
                                placeholder="Type a message"
                            /> */}

                {/* <Dropdown drop="up">
                  <Dropdown.Toggle variant="transparent">
                    <FontAwesomeIcon color="white" icon={faSmile} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu >
                    <Dropdown.Item>
                      <span>
                        <Picker
                          preload
                          disableSearchBar
                          onEmojiClick={(event, emojiObject) => this.onEmojiClick(event, emojiObject)}
                        />
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                <TextareaAutosize
                  name="message"
                  className="form-control rounded-pill"
                  onChange={(event) => {
                    this.handleChange(event)
                  }}
                  onKeyDown={this.handleKeyDown}
                  value={this.props.typedTextMessage}
                  inputRef={this.messageInputRef}
                  id="messageinputbox"
                  // placeholder="Type a message"
                  placeholder={
                    isMobile
                      ? "Type a message"
                      : 'Type a message (Hint: "Shift + Enter" to add a new line)'
                  }
                  minRows={1}
                  maxRows={2}
                />




                <InputGroup.Append>
                  <Button
                    type="submit"
                    variant="light"
                    onClick={this.sendMessage}
                    disabled={loading}
                    style={{ background: "none", border: 0 }}
                  >
                    <FontAwesomeIcon color="#0066cc" icon={faPaperPlane} />
                  </Button>



                </InputGroup.Append>
                {/* <InputGroup.Append>
                                <Button variant="light" style={{ background: "none", border: 0 }}>
                                    <FontAwesomeIcon color="#0066cc" icon={faExclamationCircle} />
                                </Button>
                            </InputGroup.Append> */}
              </InputGroup>
            </Form>
            {console.log(this.state.showRoomSelectModalForMessages,"states12")}
            
          </Container>)
          
      }
        <RoomSelectModalForMessages
          show={this.state.showRoomSelectModalForMessages}
          onHide={() => this.setState({showRoomSelectModalForMessages:false})}
        />
      </>
      :
        this.state.adminOnlyChat && !this.state.isAdmin ?
      <>
      {console.log(this.state.adminOnlyChat, this.state.isAdmin,"is admin")}
        <div style={{height: "var(--footer-height)"}} className="bg-theme text-theme d-flex align-items-center justify-content-center">
          <span>
          Only admins can send messages
          </span>
        </div>
      </>

      : 

      <>
      { this.props.forwardCheckbox ? 
          (
            <Container className="p-3">
              <div className="row">
              <FontAwesomeIcon 
                title="cancel"
                className="pointer mx-5"
                style={{ cursor: "pointer" }}
                onClick={() => this.closeForward()}
                className="actionboxicon" 
                icon={faTimes} />
              
              <FontAwesomeIcon 
                title="forwarding"
                className="pointer mx-5"
                style={{ cursor: "pointer" }}
                onClick={() => this.messageForward()}
                className="actionboxicon" 
                icon={faShare} />
              </div>
            </Container>
          ):
          (<Container className="p-3">
            {/* <InputGroup className="mb-3">
                        <FormControl
                        aria-label="Default"
                        aria-describedby="inputGroup-sizing-default"
                        onChange={this.addFile}
                        type="file"
                        label="New Avatar"
                        name="previewImage"
                        />
                    </InputGroup> */}

            {this.props.replyId && Object.keys(this.state.repliedMsg).length > 0 && (
              <div className="replybox text-theme">
                <ReplyTemplate message={this.state.repliedMsg} />
              </div>
            )}
                    {this.state.metaTags && Object.keys(this.state.metaTags).length > 0 && (
                      <div className="replybox text-theme">
                        <URLPreviewTemplate onClose={() => {this.setState({ firstLinkInMessage: null, metaTags: null, noURLPreview: true })}} message={{metaTags: this.state.metaTags}} />
                      </div>
                    )}
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.sendMessage();
              }}
            >
              <InputGroup>
                <InputGroup.Prepend>
                  <AddImage
                    content={this.state.content}
                    handleContent={(content) => this.setState({ content })}
                    handleMetaData={(data) => this.setState({ metaData: data })}
                    handleType={(type) => this.setState({ type })}
                    percentUploaded={this.state.percentUploaded}
                    addFile={this.addFile}
                    sendFile={this.sendFile}
                    file={this.state.file}
                    removeFile={this.props.removeFile}
                    clearFile={this.clearFile}
                  />
                </InputGroup.Prepend>

                {/* <FormControl aria-describedby="basic-addon1" aria-label="With textarea"
                                name="message"
                                onChange={this.handleChange}
                                onKeyDown={this.handleKeyDown}
                                value={message}
                                ref={node => (this.messageInputRef = node)}
                                id="messageinputbox"
                                placeholder="Type a message"
                            /> */}

                {/* <Dropdown drop="up">
                  <Dropdown.Toggle variant="transparent">
                    <FontAwesomeIcon color="white" icon={faSmile} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu >
                    <Dropdown.Item>
                      <span>
                        <Picker
                          preload
                          disableSearchBar
                          onEmojiClick={(event, emojiObject) => this.onEmojiClick(event, emojiObject)}
                        />
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                
                <TextareaAutosize
                  name="message"
                  className="form-control rounded-pill"
                  onChange={(event) => {
                    this.handleChange(event)
                  }}
                  onKeyDown={this.handleKeyDown}
                  value={this.props.typedTextMessage}
                  inputRef={this.messageInputRef}
                  id="messageinputbox"
                  // placeholder="Type a message"
                  placeholder={
                    isMobile
                      ? "Type a message"
                      : 'Type a message (Hint: "Shift + Enter" to add a new line)'
                  }
                  minRows={1}
                  maxRows={2}
                />




                <InputGroup.Append>
                  <Button
                    type="submit"
                    variant="light"
                    onClick={this.sendMessage}
                    disabled={loading}
                    style={{ background: "none", border: 0 }}
                  >
                    <FontAwesomeIcon color="#0066cc" icon={faPaperPlane} />
                  </Button>



                </InputGroup.Append>
                {/* <InputGroup.Append>
                                <Button variant="light" style={{ background: "none", border: 0 }}>
                                    <FontAwesomeIcon color="#0066cc" icon={faExclamationCircle} />
                                </Button>
                            </InputGroup.Append> */}
              </InputGroup>
            </Form>
            {console.log(this.state.showRoomSelectModalForMessages,"states12")}
            
          </Container>)
          
      }
        <RoomSelectModalForMessages
          show={this.state.showRoomSelectModalForMessages}
          onHide={() => this.setState({showRoomSelectModalForMessages:false})}
        />
      </>
      
    }
    </>
 
    );
  }
}

const mapStateToProps = (state) => ({
  textUnsendMessage: state.platform.textUnsendMessage,
  typedTextMessage: state.platform.typedTextMessage,
  forwardCheckbox:state.platform.displayMessageForward,
  replyId: state.platform.replyId,
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
  folder_id: state.platform.folder_id,
  messagesForwardArray: state.platform.messageForwardArray,
  
});

export default connect(mapStateToProps, {setTypedTextMessage, setTextUnsendMessage, setReplyId, setMessageForward, setMessagesForwardArray, setCurrentChannelConfig })(CommentBox);
