import React from 'react';
import firebase from 'firebase';
import TextareaAutosize from 'react-textarea-autosize';
import AvatarEditor from "react-avatar-editor";
import ReadMore from '../Middle/ReadMore';
import IsAdmin from '../IsAdmin';
import Viewer from 'react-viewer';

import { Image, Button, ListGroup, Form, Alert, Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSignOutAlt, faCamera, faCheck, faPencilAlt, faTimes, faAngleDown, faUserLock } from '@fortawesome/free-solid-svg-icons';
import './Right.css';

import { connect } from 'react-redux';
import {
    setRoomDetails,
    setContentType,
    setContentTypeAndValue,
    setCurrentChannel,
    setRightPanel
} from '../../actions/index';
import CreatedBy from './UserNameAvatar';
import DetailsIcon from "../../assets/exclamation-circle.png"
import CreatedByIcon from "../../assets/user.png"
import ParticipantsIcon from "../../assets/users.png"
import VideoServerIcon from "../../assets/video-eye.png"
import RoomTypeIcon from "../../assets/eye.png"
import ViewMoreIcon from "../../assets/carret-down-circle.png"


const getVideoServer = (key) => {

    switch (key) {
        case "ap-south-1.igesia.com":
            return "South Asia";
        case "us-east-2.igesia.com":
            return "North America";
        case "sa-east-1.igesia.com":
            return "South America";
        case "eu-central-1.igesia.com":
            return "Europe";
        case "sa-east-1.igesia.co":
            return "South America (Legacy)";

        case "ap-south-1":
            return "South Asia";
        case "us-east-2":
            return "North America";
        case "sa-east-1":
            return "South America";
        case "eu-central-1":
            return "Europe";
        case "sa-east-1":
            return "South America (Legacy)";
    }
}

class RoomDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: props.currentChannel && props.currentChannel.description,
            createdBy: props.currentChannel && props.currentChannel.createdBy,
            videoServerId: props.currentChannel && props.currentChannel.videoServerId,
            roomType: props.currentChannel && props.currentChannel.roomType,
            participantsLength: [],
            videoMeetingType: props.currentChannel && props.currentChannel.videoMeetingType ? props.currentChannel.videoMeetingType : 0,
            adminOnlyChat: props.currentChannel && props.currentChannel.roomConfig.admin_only_chat,
            roomTypeInfo: ""
        };
    };

    componentDidMount() {
        const { roomType } = this.state;

        (roomType === 1)
            ? this.setState({ roomTypeInfo: "This is a secure private room. Users need approval of admins before they can join the room." })
            : this.setState({ roomTypeInfo: "Anyone with a room link can join this room. Room admins have limited control over participants" })

        this.setState({
            createdBy: this.props?.currentChannel?.createdBy,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps && this.props && prevProps.currentChannel?.videoMeetingType !== this.props.currentChannel?.videoMeetingType) {
            this.setState({
                videoMeetingType: this.props.currentChannel?.videoMeetingType
            });
        }
        if (prevProps && prevProps.currentChannel && this.props.currentChannel && prevProps.currentChannel?.createdBy !== this.props.currentChannel?.createdBy) {
            console.log("different createdby", prevProps.currentChannel.createdBy, this.props.currentChannel.createdBy)
            this.setState({
                createdBy: this.props.currentChannel.createdBy,
            });
        }
    }

    render() {
        const {
            currentChannel,
            currentUser,
            showFull,
            setRoomDetails,
            setContentType,
            setContentTypeAndValue,
            participantsLoading,
            participants,
            allParticipants
        } = this.props;

        const {
            description,
            createdBy,
            roomTypeInfo,
            videoMeetingType,
            adminOnlyChat
        } = this.state;

        return showFull ? null
            : (

                <div className="row rounded-lg py-2 m-2 bg-card-theme">
                    <div className="col">
                        <div className="row">
                            <div className="col-12">
                                <p className=" font-weight-bold text-theme">Room Transparency &amp; Security</p>
                            </div>
                        </div>

                        {currentChannel?.description &&
                            <div className="row rounded" style={{ margin: "0px -6px" }}>
                                <div className="col-2  text-right float-right ">
                                    <img className="filter-grey p-1" src={DetailsIcon} width="25" />
                                </div>
                                <div className="col-10 ">
                                    <p className="small mt-1 text-theme-lighter">{currentChannel?.description.length > 150 ? currentChannel?.description.slice(0, 150) + '…' : currentChannel?.description}</p>
                                </div>
                            </div>
                        }
                        {currentChannel?.createdBy && 
                            <div className="row rounded" style={{ margin: "0px -6px" }}>
                                <div className="col-2  text-right float-right ">
                                    <img className="filter-grey p-1" src={CreatedByIcon} width="25" />
                                </div>
                                <div className="col-10 ">
                                    
                                    <p className="small mt-1 text-theme-lighter">Created by <strong><CreatedBy nameOnly={true} userId={currentChannel?.createdBy} /></strong></p>
                                </div>
                            </div>
                        }

                        <div className="row rounded" style={{ margin: "0px -6px" }}>
                            <div className="col-2  text-right float-right ">
                                <img className="filter-grey p-1" src={ParticipantsIcon} width="25" />
                            </div>
                            <div className="col-10 ">
                                {(!allParticipants)
                                    ? <Spinner animation="border" size="sm" className="text-theme" />
                                    : <p className="small mt-1 text-theme-lighter">{allParticipants.length} Participants</p>}
                            </div>
                        </div>

                        {/*<div className="row rounded" style={{ margin: "0px -6px" }}>
                            <div className="col-2  text-right float-right ">
                                <img className="filter-grey p-1" src={VideoServerIcon} width="25" />
                            </div>
                            <div className="col-10 ">
                                <p className="small mt-1 text-theme-lighter">Video server located in {getVideoServer(currentChannel?.videoServerId)}</p>
                            </div>
                        </div>*/}

                        <div className="row rounded" style={{ margin: "0px -6px" }}>
                            <div className="col-2  text-right float-right ">
                                <img className="filter-grey p-1" src={VideoServerIcon} width="25" />
                            </div>
                            <div className="col-10 ">
                                <p className="small mt-1 text-theme-lighter">Video meeting type is
                                {this.state.videoMeetingType === 0 ? " Regular" : null}
                                    {this.state.videoMeetingType === 1 ? " Premium" : null}
                                    {this.state.videoMeetingType === 2 ? " Broadcast" : null}
                                    {this.state.videoMeetingType === 4 ? " Custom" : null}
                                </p>
                            </div>
                        </div>

                        {
                            adminOnlyChat ?
                                <div className="row rounded" style={{ margin: "0px -6px" }}>
                                    <div className="col-2  text-right float-right ">
                                        <FontAwesomeIcon className="filter-grey p-1" icon={faUserLock} size="lg" />
                                    </div>
                                    <div className="col-10 ">
                                        <p className="small mt-1 text-theme-lighter">{this.state.adminOnlyChat ? "Admin only chat" : ""}</p>
                                    </div>
                                </div>
                                : null
                        }


                        <div className="row rounded" style={{ margin: "0px -6px" }}>
                            <div className="col-2  text-right float-right ">
                                <img className="filter-grey p-1" src={RoomTypeIcon} width="25" />
                            </div>
                            <div className="col-10 ">
                                <p className="small mt-1 text-theme-lighter">{roomTypeInfo}</p>
                            </div>
                        </div>

                        <div className="row py-2 rounded lightBgonHover" style={{ margin: "0px -6px" }}>
                            <div className="col-2  text-right float-right pointer" onClick={() => setContentTypeAndValue("roomDetails", true)}>
                                <img className="rounded-circle p-1"
                                    style={{ backgroundColor: "rgba(100,100,100,0.25)" }}
                                    src={ViewMoreIcon} width="25" />
                            </div>
                            <div className="col-10 pointer" onClick={() => setContentTypeAndValue("roomDetails", true)} >
                                <p className="mb-0 mt-1 small text-theme-lighter">View More</p>
                            </div>
                        </div>

                    </div>
                </div>

            )
    };
}

const mapStateToProps = state => ({
    currentWorkspace: state.workspace.currentWorkspace,
    currentChannel: state.channel.currentChannel,
    participantsLoading: state.channel.participantsLoading,
    participants: state.channel.participants,
    currentUser: state.user.currentUser,
    showFull: state.platform.showFull
})

export default connect(mapStateToProps, {
    setRoomDetails,
    setContentType,
    setContentTypeAndValue,
    setCurrentChannel,
    setRightPanel
})(RoomDetails);