import React from 'react';
import { Button, Form, ButtonGroup, InputGroup, FormControl } from "react-bootstrap";
import TextareaAutosize from 'react-textarea-autosize';
import { connect } from 'react-redux';
import { setFolderName, setFolderDescription, updateFolder } from '../../../actions/index';

class UpdateFolder extends React.Component {
    // ALL STATES
    state = {
        error: ''
    }

    // VALIDATION OF DATA
    validate_data = ({folder_name, description}) => {

        if (folder_name.length == 0) {
            this.setState({error: "Please enter folder name."})
            return false;
        }

        // if (description.length == 0) {
        //     this.setState({error: "Please enter a description."})
        //     return false;
        // }

        this.setState({error: ""});
        return true;
    }

    // MAIN RENDER FUNCTION
    render() {
        const { 
            currentChannel, folder_id, folder_name, description, 
            setFolderName, setFolderDescription, updateFolder 
        } = this.props;

        return (
            <React.Fragment>
                <div className="taskBox">
                <h5>Folder</h5>
                    <Form className="feedback">

                        <p className="mb-1">Name (Required)</p>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Add name"
                                name="folder_name"
                                value={folder_name}
                                onChange={e => setFolderName(e.target.value)}
                                required={true}
                            />
                        </InputGroup>

                        <p className="mb-1">Description</p>
                        <InputGroup className="mb-3">
                            <TextareaAutosize
                                className="w-100 p-2 rounded"
                                style={{borderColor: '#CDCDCD', minWidth: '300px'}}
                                name="description"
                                value={description}
                                onChange={e => setFolderDescription(e.target.value)}
                                minRows={3}
                                maxRows={20}
                                placeholder="Add a brief about folder"
                            />
                        </InputGroup>

                        <ButtonGroup>
                            <Button onClick={() => this.validate_data({folder_name, description}) && updateFolder({folder_id, currentChannel, folder_name, description})} variant="outline-primary" size="sm" >Save</Button>
                        </ButtonGroup>
                        <p className="my-2 p-0 text-danger">{this.state.error}</p>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => ({
    currentChannel: state.channel.currentChannel,
    currentUser: state.user.currentUser,
    folder_id: state.folder.id,
    folder_name: state.folder.folder_name,
    description: state.folder.description
})

export default connect(mapStateToProps, { setFolderName, setFolderDescription, updateFolder })(UpdateFolder);