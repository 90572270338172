import React, { useEffect, useState, useRef } from 'react';
import { loadState, saveState } from '../../services/index';
import { Form, Button, Spinner, InputGroup, Dropdown, DropdownButton, Card } from 'react-bootstrap';
import firebase from 'firebase';
import { Link, useParams } from 'react-router-dom';
import { postData } from '../../utils/index';
import { API_BASE_URL, USER_EXISTS, USER_WORKSPACES, WORKSPACE_DETAILS_FROM_ID_SLUG } from '../../config/index'
import * as DeviceDetect from "react-device-detect";
import { connect } from 'react-redux';
import { browserName, CustomView, osName } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye, faAngleRight, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import {
    setCurrentWorkspace,
} from '../../actions/workspace';
import {
    setWorkspaceSlug,
    setCurrentChannel,
    setAllMessages,
    setParticipants,
    setWorkspaces,
    setUser,
    setChannels,
    setRightPanel,
} from '../../actions/index';
import App from '../App';
import "./auth.scss";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';

const JoinWorkspace = (props) => {


    const history = useHistory();

    const [workspaceDataFromURL, setWorkspaceDataFromURL] = useState(null);
    const [workspaceAdmin, setWorkspaceAdmin] = useState(null);
    const [workspaceError, setWorkspaceError] = useState(false);
    const [alreadyInWorkspace, setAlreadyInWorkspace] = useState(false);
    let { workspaceId } = useParams();

    const [workspacesAlreadyIn, SetWorkspacesAlreadyIn] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userIsPartOfWorkspace, setUserIsPartOfWorkspace] = useState(false);
    const [loadingUserIsPartOfWorkspace, setLoadingUserIsPartOfWorkspace] = useState(!!props.currentUser);
    // getting all workspaces user is already part of


    const handleSignout = async () => {
        const {
            setCurrentWorkspace,
            setCurrentChannel,
            setAllMessages,
            setParticipants,
            setWorkspaces,
            setUser,
            currentUser,
            setChannels,
            setRightPanel,
            setWorkspaceSlug
        } = props;

        firebase
            .auth()
            .signOut()
            .then(() => {

                setRightPanel(false);
                setCurrentWorkspace(null);
                setCurrentChannel(null);
                setAllMessages(null);
                setParticipants(null);
                setWorkspaces(null);
                setUser(null);
                setChannels(null)
                setWorkspaceSlug(null)
                window.localStorage.removeItem("defaultWorkspace");
                history.push('/signin')
                console.log("signed out!");
            });
    };


    useEffect(() => {
        if (props.currentUser) {
            firebase.firestore().collection(`users/${props.currentUser.uid}/rooms`).get().then((snapshot) => {
                let workspaceAlreadyInData = [];
                snapshot.docs.map((snap) => {
                    firebase.firestore().collection('workspaces').doc(snap.id).get().then((doc) => {
                        workspaceAlreadyInData.push({ ...doc.data(), workspaceId: doc.id })
                    })
                })
                SetWorkspacesAlreadyIn(workspaceAlreadyInData);
                setTimeout(() => setLoading(false), 500)
                // snapshot.docs.id
            })
        }
    }, [props.currentUser])

    // getting workspace rooms and details from firebase 

    const getWorkspaceHandler = async (uid) => {


        console.log(workspaceId, " params?")
        let docRef = firebase.firestore().collection(`workspaces`).doc(`${workspaceId}`)
        await docRef.get()
            .then(async (doc) => {
                if (doc.exists) {
                    const workspacedata = doc.data();
                    await firebase
                        .firestore()
                        .collection(`users/${uid}/rooms`)
                        .get().then(function (snapshot) {
                            let userWorkspaces = [];
                            snapshot.forEach(function (doc) {
                                userWorkspaces.push({ ...doc.data() })
                            })
                            //  traversing through the workspaces and setting the workspace which has workspaceId === workspaceId from url
                            let workspaceRooms;
                            userWorkspaces.forEach((workspace) => {
                                if (workspace.workspaceId === workspaceId) {
                                    workspaceRooms = workspace;
                                }
                            })

                            // setting workspace data in redux
                            saveState("defaultWorkspace", { ...workspacedata, ...workspaceRooms });
                            props.setCurrentWorkspace({ ...workspacedata, ...workspaceRooms })
                        })

                } else {
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            })
    }


    // getting workspacerooms and setting it as current when user clicks 

    const setAlreadyInWorkspaceHandler = async (uid, workspaceId) => {

        let docRef = firebase.firestore().collection(`workspaces`).doc(`${workspaceId}`)
        await docRef.get()
            .then(async (doc) => {
                if (doc.exists) {
                    const workspacedata = doc.data();
                    await firebase
                        .firestore()
                        .collection(`users/${uid}/rooms`)
                        .get().then(function (snapshot) {
                            let userWorkspaces = [];
                            snapshot.forEach(function (doc) {
                                userWorkspaces.push({ ...doc.data() })
                            })
                            //  traversing through the workspaces and setting the workspace which has workspaceId === workspaceId from url
                            let workspaceRooms;
                            userWorkspaces.forEach((workspace) => {
                                if (workspace.workspaceId === workspaceId) {
                                    workspaceRooms = workspace;
                                }
                            })

                            // setting workspace data in redux
                            saveState("defaultWorkspace", { ...workspacedata, ...workspaceRooms });
                            props.setCurrentWorkspace({ ...workspacedata, ...workspaceRooms })
                        })

                } else {
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            })
    }


    // sign in code

    const credentialRef = useRef(null);
    const passwordRef = useRef(null);
    const createPasswordRef = useRef(null);
    const firstNameRef = useRef(null);



    const [fieldName, setFieldName] = useState("");
    const [phoneCode, setPhoneCode] = useState("");
    // const [type, setType] = useState("");
    const type = "email";
    const [credential, setCredential] = useState("");
    const [loadingFindingUser, setLoadingFindingUser] = useState(false);
    const [error, setError] = useState("");

    const [userFound, setUserFound] = useState(false);
    const [takingToPassword, setTakingToPassword] = useState(false);
    const [sendingOtp, setSendingOtp] = useState(false);

    const [formStage, setFormStage] = useState("0");
    const [previousUser, setPreviousUser] = useState(false);
    const [loadingSendingDetails, setLoadingSendingDetails] = useState(false);

    const [forgotPassword, setForgotPassword] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [confirmEmailAddress, setConfirmEmailAddress] = useState("");
    const [loadingResendEmail, setLoadingResendEmail] = useState(false);
    const [resetEmailSent, setResetEmailSent] = useState(false);

    const [loginLoading, setLoginLoading] = useState(false);

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState({number:''});
    const [phone, setPhone] = useState('')

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [otp, setOtp] = useState("");
    const [loadingResendOtp, setLoadingResendOtp] = useState(false);
    const [resendOtp, setResendOtp] = useState(false);

    const [counter, setCounter] = useState(30);
    const [startCounter, setStartCounter] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loadingRegister, setLoadingRegister] = useState(false);
    const [termAndCondition, setTermAndCondition] = useState(false)
    const [errorObj, setErrorObj] = useState({});

    useEffect(() => {
        if (startCounter) {
            const timer =
                counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
            if (counter === 0) setStartCounter(false);
            return () => clearInterval(timer);
        }
    }, [counter, startCounter]);

    useEffect(() => {
        setPhoneCode(loadState("accessCountryPhoneCode"));
    }, [loadState("accessCountryPhoneCode")]);

    const [showNonChromeWarnings, setShowNonChromeWarnings] = useState(false);
    const [msgNonChromeAndDesktopWarnings, setMsgNonChromeAndDesktopWarnings] = useState("")

    const [showFirefoxWarnings, setShowFirefoxWarnings] = useState(false);
    const [msgFirefoxAndDesktopWarnings, setMsgFirefoxAndDesktopWarnings] = useState("")

    const [showEdgeWarnings, setShowEdgeWarnings] = useState(false)
    const [msgEdgeAndDesktopWarnings, setMsgEdgeAndDesktopWarnings] = useState("")

    const [showChromeWarnings, setShowChromeWarnings] = useState(false)
    const [msgChromeAndDesktopWarnings, setMsgChromeAndDesktopWarnings] = useState("")

    const [showSafariWarnings, setShowSafariWarnings] = useState(false)
    const [msgSafariAndDesktopWarnings, setMsgSafariAndDesktopWarnings] = useState("");

    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection(`versions`)
            .doc(`web-errors`)
            .onSnapshot(
                (doc) => {
                    if (doc.exists) {
                        const {
                            showNonChromeWarnings,
                            msgNonChromeAndDesktopWarnings,
                            showFirefoxWarnings,
                            msgFirefoxAndDesktopWarnings,
                            showEdgeWarnings,
                            msgEdgeAndDesktopWarnings,
                            showChromeWarnings,
                            msgChromeAndDesktopWarnings,
                            showSafariWarnings,
                            msgSafariAndDesktopWarnings
                        } = doc.data();

                        setShowNonChromeWarnings(showNonChromeWarnings)
                        setMsgNonChromeAndDesktopWarnings(msgNonChromeAndDesktopWarnings)

                        setShowFirefoxWarnings(showFirefoxWarnings)
                        setMsgFirefoxAndDesktopWarnings(msgFirefoxAndDesktopWarnings)

                        setShowEdgeWarnings(showEdgeWarnings)
                        setMsgEdgeAndDesktopWarnings(msgEdgeAndDesktopWarnings)

                        setShowChromeWarnings(showChromeWarnings)
                        setMsgChromeAndDesktopWarnings(msgChromeAndDesktopWarnings)

                        setShowSafariWarnings(showSafariWarnings)
                        setMsgSafariAndDesktopWarnings(msgSafariAndDesktopWarnings)
                    }
                },
                (err) => {
                    console.error(err);
                }
            );

        return () => {
            unsubscribe();
        };
    });

    const _handleCredentialSubmit = (e) => {
        e.preventDefault();

        setLoadingFindingUser(true);

        if (credential == "" || credential.length == 0) {
            setError("Please enter phone / email.");
            setLoadingFindingUser(false);
            return;
        }

        if (type === "phoneNumber") {
            var pattern = /^[0-9\s\-\(\)]+$/;
            if (!credential.match(pattern)) {
                setError("Invalid phone number!");
                setLoadingFindingUser(false);
                return;
            }
        } else {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(credential)) {
                setError("Invalid email address!");
                setLoadingFindingUser(false);
                return;
            }
        }

        postData(
            `${API_BASE_URL}${USER_EXISTS}`,
            type === "email"
                ? { [type]: credential }
                : { [type]: `+${phoneCode}${credential}` }
        )
            .then((response) => {
                console.log(response);
                const { data, message, status } = response;

                if (status === 200) {
                    if (data.exist) {
                        setUserFound(true);
                        setPreviousUser(true);
                        if (type === "phoneNumber") {
                            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                                "recaptcha-container",
                                {
                                    size: "invisible",
                                }
                            );

                            _handlePhoneAuth(e);
                        } else {
                            setTakingToPassword(true);
                            setTimeout(() => {
                                setFormStage("2");
                                setTakingToPassword(false);
                                passwordRef.current && passwordRef.current.focus();
                            }, 2000);
                        }
                    } else {
                        setFormStage("1");
                        firstNameRef.current && firstNameRef.current.focus();
                    }
                } else {
                    setFormStage("0");
                    setError("Some server error occured!");
                }
            })
            .catch((error) => console.error(error));
    };

    const _handleCredentials = (e) => {
        e.preventDefault();

        var pattern = /^[0-9\s\-\(\)]+$/;
        setError("");

        if (e.target.value.match(pattern)) {
            setFieldName("phone");
            // setType("phoneNumber")
            setCredential(e.target.value);
        } else {
            setFieldName("email");
            // setType("email")
            setCredential(e.target.value);
        }
    };

    const _handleUserDataSubmit = (e) => {
        e.preventDefault();

        setLoadingSendingDetails(true);

        if (isValidString(firstName) && isValidString(lastName)) {
            if (type === "phoneNumber") {
                window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                    "recaptcha-container",
                    {
                        size: "invisible",
                    }
                );

                _handlePhoneAuth(e);
            } else {
                setTakingToPassword(true);
                setTimeout(() => {
                    setFormStage("2");
                    setTakingToPassword(false);
                    setLoadingSendingDetails(false);
                    setError("");
                    createPasswordRef.current && createPasswordRef.current.focus();
                }, 2000);
            }
        } else {
            setLoadingSendingDetails(false);
            setError("Please enter mandatory fields!");
        }
    };

    const isValidString = (str) => {
        return str.length > 0;
    };

    const _handleEmailAndPasswordRegister = (event) => {
        event.preventDefault();
        setLoadingRegister(true);

        if (
            isValidString(credential) &&
            isValidString(password) &&
            isValidString(confirmPassword) &&
            isValidString(firstName) &&
            isValidString(lastName)
        ) {
            if (password.trim().length < 6) {
                setError("Password must have at least 6 characters");
                setLoadingRegister(false);
            } else if (password === confirmPassword) {
                firebase
                    .auth()
                    .createUserWithEmailAndPassword(credential, password)
                    .then((createdUser) => {
                        createdUser.user.sendEmailVerification();
                        setLoadingRegister(false);
                        setTermAndCondition(false)
                        if (createdUser.operationType === "signIn") {
                            _handleUpdateProfile();
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } else {
                setError("Please enter same password in both");
                setLoadingRegister(false);
            }
        } else {
            setError("Please enter same password in both");
        }
    };

    const _handleEmailAndPasswordLogin = (e) => {
        e.preventDefault();
        setLoginLoading(true);
        const workspaceIdFromURL = history.location.pathname.split('/')[2];
        props.setWorkspaceSlug(workspaceIdFromURL);

        if (password === "") {
            setError("Please enter valid password!");
            setLoginLoading(false);
            return;
        }

        if (credential && isValidString(password)) {
            firebase
                .auth()
                .signInWithEmailAndPassword(credential, password)
                .then((response) => {
                    console.log(response);
                    setLoginLoading(false);
                    // setting workspace in redux which was in the URL
                    // after setting redirect to "/" for now (can change if we use workspaces in URL)
                    getWorkspaceHandler(response.user.uid).then(() => {
                        history.push("/");
                    });

                })
                .catch((error) => {
                    console.error(error);
                    setError(error.message);
                    setLoginLoading(false);
                });
        }
    };

    const _handlePhoneAuth = () => {
        setSendingOtp(true);

        const phone = `+${phoneCode}${credential}`;
        const appVerifier = window.recaptchaVerifier;
        firebase
            .auth()
            .signInWithPhoneNumber(phone, appVerifier)
            .then((confirmResult) => {
                window.confirmationResult = confirmResult;

                setLoadingFindingUser(false);
                setLoadingSendingDetails(false);

                setFormStage("2");
                setSendingOtp(false);
                setStartCounter(true);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
            });
    };

    const _handleConfirmOtp = () => {
        setLoginLoading(true);

        if (otp.length != 6) {
            setLoginLoading(false);
            setError("Please enter valid OTP!");
            return;
        }

        window.confirmationResult
            .confirm(otp)
            .then((result) => {
                const { additionalUserInfo } = result;
                if (additionalUserInfo.isNewUser) {
                    _handleUpdateProfile();
                }
                setLoginLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setLoginLoading(false);
            });
    };

    const _handleUpdateProfile = () => {
        const workspaceIdFromURL = history.location.pathname.split('/')[2];
        props.setWorkspaceSlug(workspaceIdFromURL);
        const authUser = firebase.auth().currentUser;

        authUser
            .updateProfile({
                displayName: `${firstName} ${lastName}`,
                photoURL:
                    "https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822",
            })
            .then(() => {
                _handleSaveUser(authUser);
            })
            .catch((error) => console.error(error));
    };

    const params = useParams();

    const _handleSaveUser = (authUser) => {
        console.log(authUser, phoneNumber, "my authUser 2 joinWorkspace")
        return firebase
            .firestore()
            .collection("users")
            .doc(`${authUser.uid}`)
            .set({
                displayName: authUser.displayName,
                avatar: authUser.photoURL,
                firstName: firstName,
                lastName: lastName,
                middleName: middleName,
                phone: phoneNumber,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: JSON.parse(JSON.stringify(authUser.providerData)),
                uid: authUser.uid,
                isAnonymous: authUser.isAnonymous,
                deviceInfo: DeviceDetect.deviceDetect(),
                deviceType: DeviceDetect.deviceType,
                device: DeviceDetect.isMobile
                    ? DeviceDetect.isMobileOnly
                        ? "Mobile"
                        : "Tablet"
                    : "Desktop",
                electron: DeviceDetect.isElectron ? true : false,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(function () {
                // adding the new user to the workspace "igesia"
                firebase.firestore().collection("users").doc(`${authUser.uid}`).collection('rooms').doc('LgT2sjzoZQwwKMGPnob2').set({
                    rooms: [],
                    workspaceId: 'LgT2sjzoZQwwKMGPnob2'
                }).then(() => {
                    console.log("Document successfully written! + added default workspace as igesia");

                })
            })
            .catch(function (error) {
                console.error("Error writing document: ", error);
            });
    };

    const _handleEmailPasswordReset = () => {
        if (emailAddress != confirmEmailAddress) {
            setError("Please enter same email address");
            return;
        }

        setLoadingResendEmail(true);

        firebase
            .auth()
            .sendPasswordResetEmail(emailAddress)
            .then(() => {
                console.log("Reset Email Sent");
                setResetEmailSent(true);
                setTimeout(() => setLoadingResendEmail(false), 500);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const _handleResendOtpSubmit = () => {
        window.recaptchaVerifierResend = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container-resend",
            {
                size: "invisible",
            }
        );

        setResendOtp(true);
        setLoadingResendOtp(true);

        const phone = `+${phoneCode}${credential}`;
        const appVerifier = window.recaptchaVerifierResend;
        firebase
            .auth()
            .signInWithPhoneNumber(phone, appVerifier)
            .then((confirmResult) => {
                window.recaptchaVerifierResend = confirmResult;
                setLoadingResendOtp(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const _handleResendConfirmOtp = () => {
        setLoginLoading(true);

        if (otp.length != 6) {
            setLoginLoading(false);
            setError("Please enter valid OTP!");
            return;
        }

        window.recaptchaVerifierResend
            .confirm(otp)
            .then((result) => {
                const { additionalUserInfo } = result;
                if (additionalUserInfo.isNewUser) {
                    _handleUpdateProfile();
                }
                setLoginLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setError(error.message);
                setLoginLoading(false);
            });
    };

    const _handleUseDifferentNumber = () => {
        setFormStage("0");
    };

    const _handleStartOver = () => {
        setLoadingFindingUser(false);
        setUserFound(false);
        setEmailAddress("");
        setFormStage("0");
        setCredential("");
        setError("");
        setSendingOtp(false);
        // setType("")
        setPassword("");
    };
    const validateDataOfField = (e) => {
        let errorObj = {};
        //console.log(e);
        if (e.target.name === "password") {
            if (e.target.value.trim().length < 6) {
                errorObj.password = "Password must have at least 6 characters";
            } else {
                errorObj.password = "";
            }
        }
        setErrorObj({ ...errorObj });
    };




    // sign in code ends



    useEffect(() => {
        const workspaceIdFromURL = history.location.pathname.split('/')[2]
        console.log(workspaceIdFromURL);

        const getWorkspaceData = async () => {
            // if (props.currentUser) {
            //     firebase.firestore().collection(`workspaces`).doc(`${workspaceIdFromURL}`).get()
            //         .then((doc) => {
            //             if (doc.exists) {
            //                 setWorkspaceDataFromURL(doc.data())
            //                 // firebase.firestore().collection("users").doc(doc.data().workspaceAdmin).get()
            //                 //     .then((doc) => {
            //                 //         if (doc.exists) {
            //                 //             setWorkspaceAdmin(doc.data().displayName)
            //                 //         }
            //                 //     })
            //                 firebase.firestore().collection(`users/${props.currentUser.uid}/rooms`).doc(workspaceIdFromURL).get().then((doc) => {
            //                     if (doc.exists) {
            //                         setAlreadyInWorkspace(true);
            //                     } else {
            //                         setAlreadyInWorkspace(false);
            //                     }
            //                 })
            //                 setTimeout(() => setLoading(false), 500)
            //             } else {
            //                 setWorkspaceError(true);
            //             }
            //         })
            // } 
            axios.post(`${API_BASE_URL}${WORKSPACE_DETAILS_FROM_ID_SLUG}`, {
                workspace_id_or_slug: workspaceIdFromURL
            }).then((response) => {
                if (response.status === 200) {
                    setWorkspaceDataFromURL(response.data.data)
                    setWorkspaceError(false);
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error);
                setWorkspaceError(true);
            })
        }
        getWorkspaceData();
    }, [])

    useEffect(() => {
        if (props.currentUser && workspaceDataFromURL) {
            axios.post(`${API_BASE_URL}${USER_WORKSPACES}`, {
                user_id: props.currentUser.uid
            }).then((res) => {
                if (res.status === 200) {
                    const workspaceIdsArray = res.data.data.user_workspaces.map((workspace) => (workspace.workspaceId))
                    if (workspaceIdsArray.includes(workspaceDataFromURL.workspaceId)) {
                        setUserIsPartOfWorkspace(true);
                        setLoadingUserIsPartOfWorkspace(false);
                        setAlreadyInWorkspaceHandler(props.currentUser.uid, workspaceDataFromURL.workspaceId)
                            .then(() =>
                                setTimeout(() => {
                                    history.push("/")
                                }, 1500)
                            )
                    } else {
                        setLoadingUserIsPartOfWorkspace(false);
                    }
                } else {
                    setUserIsPartOfWorkspace(false);
                    setLoadingUserIsPartOfWorkspace(false);
                }
            }).catch((error) => {
                console.log(error);
                setUserIsPartOfWorkspace(false);
                setLoadingUserIsPartOfWorkspace(false);
            })
        }
    }, [props.currentUser, workspaceDataFromURL])



    return (

        <>
            {
                (!workspaceError && workspaceDataFromURL && !loadingUserIsPartOfWorkspace && props.currentUser && alreadyInWorkspace && !loading) ?

                    <App /> :
                    (!workspaceError && workspaceDataFromURL && !loading) ?
                        <div className="container-fluid overflow-auto" style={{ minHeight: "100vh", background: workspaceDataFromURL?.workspaceCoverPic ? `url(${workspaceDataFromURL.workspaceCoverPic})` : "#18191a", backgroundSize: 'cover' }}>
                            <div className="container" style={{ height: "100vh" }}>
                                <div className="row pt-5 justify-content-center">
                                    <div className="col-md-4 pt-5 text-center">
                                        <img alt="igesia-logo" src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-reverse.png?alt=media&token=93df9989-4eab-406f-8122-ada051558941"
                                            width="200" />
                                    </div>
                                </div>
                                <div className="row py-5 justify-content-center">
                                    <div className="col-lg-5 col-md-6 col-sm-8 col-10 py-4 px-5 shadow-sm text-light rounded" style={{ overflowX: 'hidden', overflowY: "auto", background: `rgb(35, 38, 38)` }}>
                                        <div className="d-flex justify-content-center">
                                            <div className="align-items-center text-center">
                                                <img alt="workspace-bg" className="rounded-circle" src={workspaceDataFromURL.workspacePic} style={{ width: "6rem", height: "6rem" }}></img>
                                                <h4 className="font-weight-light">Logging you into <span className="font-weight-bold">{workspaceDataFromURL.workspaceName}</span> workspace</h4>
                                            </div>
                                        </div>

                                        {
                                            props.currentUser
                                            && (alreadyInWorkspace
                                                ? <div className="p-3 text-center">
                                                    <Button onClick={() => {
                                                        getWorkspaceHandler(props.currentUser.uid).then(() => {
                                                            history.push("/");
                                                        })
                                                    }}>Click here to enter, {workspaceDataFromURL.workspaceName}</Button>
                                                    {/* <h6 className="text-warning">You're already in this workspace</h6> */}
                                                    {/* <Spinner animation="border" size="sm" /> getting your workspace ready */}
                                                </div>
                                                :
                                                <>
                                                    {
                                                        !userIsPartOfWorkspace && !loadingUserIsPartOfWorkspace ?
                                                            <div className="p-3 text-center">
                                                                <h6 className="text-warning">Oops, looks like you're not part of this workspace</h6>
                                                            </div>
                                                            : null
                                                    }

                                                    {workspacesAlreadyIn.length > 0 && !loadingUserIsPartOfWorkspace && !userIsPartOfWorkspace && <div className="mt-3">
                                                        <h5 className="text-theme">Click and select a workspace:</h5>
                                                    </div>}
                                                    <div id="workspace-list" className="overflow-auto" style={{ maxHeight: '35vh' }}>
                                                        {
                                                            loading
                                                                ? <div
                                                                    className="mt-2"
                                                                    style={{ cursor: 'pointer' }}
                                                                    title="Loading..."
                                                                >
                                                                    <Card className="bg-dark w-100">
                                                                        <Card.Body className="d-flex p-3">
                                                                            <div className="px-2">
                                                                                <div
                                                                                    className="rounded-circle bg-secondary align-items-center d-flex justify-content-center"
                                                                                    style={{ width: "3rem", height: "3rem" }}
                                                                                >
                                                                                    <Spinner animation="border" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex-grow-1 align-items-center d-flex px-2">
                                                                                <div className="mb-0 bg-secondary w-100" style={{ height: "18px" }}></div>
                                                                            </div>
                                                                            <div className="align-items-center d-flex px-2">
                                                                                <FontAwesomeIcon icon={faAngleRight} />
                                                                            </div>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                : !userIsPartOfWorkspace && !loadingUserIsPartOfWorkspace && workspacesAlreadyIn.map((workspace) => {
                                                                    return (
                                                                        <div
                                                                            className=""
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={() => {
                                                                                setAlreadyInWorkspaceHandler(props.currentUser.uid, workspace.workspaceId)
                                                                                    .then(() => history.push("/"))
                                                                            }}
                                                                        >
                                                                            <Card className="bg-dark w-100 mb-1" title={workspace.workspaceName}>
                                                                                <Card.Body className="d-flex p-3">
                                                                                    <div className="px-2">
                                                                                        <img alt="" className="img-fluid rounded-circle bg-light" src={workspace.workspacePic} style={{ width: "3rem", height: "3rem" }}></img>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 align-items-center d-flex px-2">
                                                                                        <h5 className="font-weight-normal mb-0">{workspace.workspaceName}</h5>
                                                                                    </div>
                                                                                    <div className="align-items-center d-flex px-2">
                                                                                        <FontAwesomeIcon icon={faAngleRight} />
                                                                                    </div>
                                                                                </Card.Body>
                                                                            </Card>
                                                                        </div>
                                                                    )
                                                                })
                                                        }
                                                    </div>
                                                    <div className="d-flex justify-content-center mt-3">
                                                        <div className="align-items-center text-center">
                                                            <h6 className="font-weight-light">Not {" "}
                                                                <span className="font-weight-bold">{props.currentUser.displayName} ? </span>
                                                                <Link className="ml-1"
                                                                    style={{ cursor: "pointer", color: "#6900ff" }}
                                                                    onClick={(e) => handleSignout()}
                                                                >
                                                                    <u> <FontAwesomeIcon icon={faSignOutAlt} className="small" /> Logout</u>
                                                                </Link>
                                                            </h6>
                                                        </div>
                                                    </div>


                                                </>

                                            )
                                        }
                                        {/* <div className="rounded-lg py-1 m-1 bg-card-theme shadow-sm text-theme">
                                        <div className="d-flex align-items-center p-2 w-100">
                                            <div>
                                                <img className="mr-2 rounded-circle" src={workspaceDataFromURL.workspacePic} style={{ width: 40, height: 40 }}></img>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex align-items-center">
                                                    <p className="mb-0 text-theme">
                                                        {workspaceDataFromURL.workspaceName}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p className="text-theme text-theme-lighter">
                                                        Workspace Admin: {workspaceAdmin}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                        <div>
                                            {
                                                props.currentUser ?
                                                    <>
                                                        {/* <div className="text-center mt-3">
                                                        <Button variant="primary" className="mx-auto">Join</Button>
                                                    </div>
                                                    <div className="text-center mt-3">
                                                        <DropdownButton id="dropdown-basic-button" title="Current Workspace">
                                                            <Dropdown.Item >Users Workspace #1</Dropdown.Item>
                                                            <Dropdown.Item >Users Workspace #2</Dropdown.Item>
                                                            <Dropdown.Item >Users Workspace #3</Dropdown.Item>
                                                        </DropdownButton>

                                                    </div> */}
                                                    </>
                                                    :

                                                    <div className="row justify-content-center">
                                                        {formStage === "0" && (
                                                            <Form
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    _handleCredentialSubmit(e);
                                                                }}
                                                                className="col-md-12 p-3 shadow-sm text-light rounded"
                                                                style={{ background: "#232626" }}
                                                            >
                                                                {/* <h4>Sign up or Login</h4> */}
                                                                {props.newRoom && (
                                                                    <p>
                                                                        Please login to join{" "}
                                                                        <span className="font-weight-bold">
                                                                            my.igesia.co/{props.newRoom}
                                                                        </span>
                                                                    </p>
                                                                )}
                                                                {props.newRoomError && (
                                                                    <p className="text-danger">
                                                                        Invalid link{" "}
                                                                        <span className="font-weight-bold">
                                                                            my.igesia.co/{props.newRoomError}
                                                                        </span>
                                                                    </p>
                                                                )}

                                                                {/* <Form.Label className="mt-md-3">Enter your email or phone number</Form.Label> */}
                                                                <Form.Label className="mt-md-3">Enter your email</Form.Label>
                                                                <InputGroup className="mb-3">
                                                                    {type === "phoneNumber" && (
                                                                        <>
                                                                            <InputGroup.Prepend>
                                                                                <Form.Control
                                                                                    name="phoneCode"
                                                                                    type="text"
                                                                                    style={{ width: "50px" }}
                                                                                    value={phoneCode}
                                                                                    onChange={(e) => {
                                                                                        var pattern = /^\d*$/;
                                                                                        if (e.target.value.match(pattern)) {
                                                                                            setPhoneCode(e.target.value);
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </InputGroup.Prepend>
                                                                        </>
                                                                    )}
                                                                    <Form.Control
                                                                        name={fieldName}
                                                                        type="text"
                                                                        placeholder=""
                                                                        value={credential}
                                                                        ref={credentialRef}
                                                                        onChange={(e) => _handleCredentials(e)}
                                                                    />
                                                                </InputGroup>
                                                                {error !== "" && (
                                                                    <>
                                                                        <small className="d-block text-danger mb-3">{error}</small>
                                                                        <p className="text-muted mb-0">
                                                                            <span>Start over? </span>
                                                                            <a
                                                                                style={{ cursor: "pointer", color: "#6900ff" }}
                                                                                // onClick={() => {
                                                                                //     setLoadingFindingUser(false)
                                                                                //     setUserFound(false)
                                                                                //     setEmailAddress("")
                                                                                //     setFormStage("0")
                                                                                // }}
                                                                                onClick={(e) => _handleStartOver(e)}
                                                                            >
                                                                                <u>click here</u>
                                                                            </a>
                                                                        </p>
                                                                    </>
                                                                )}

                                                                <Button
                                                                    className="w-100"
                                                                    variant="primary"
                                                                    onClick={(e) => _handleCredentialSubmit(e)}
                                                                    disabled={loadingFindingUser}
                                                                >
                                                                    {loadingFindingUser && (
                                                                        <Spinner
                                                                            as="span"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                            animation="border"
                                                                            className="mr-1"
                                                                            size="sm"
                                                                        />
                                                                    )}
                                                                    <span>{userFound ? "User found" : "Next"}</span>
                                                                </Button>

                                                                {sendingOtp ? (
                                                                    <p className="mt-2">
                                                                        Sending OTP on{" "}
                                                                        <span className="h6">
                                                                            +{phoneCode}-{credential}
                                                                        </span>
                                                                    </p>
                                                                ) : null}

                                                                {takingToPassword ? (
                                                                    <p className="mt-2">
                                                                        Forwarding you to securely enter password for{" "}
                                                                        <span className="h6">{credential}</span>
                                                                    </p>
                                                                ) : null}

                                                                {!userFound && (
                                                                    <>{workspaceDataFromURL.workspaceConfig?.guestUsersAllowed !== false ? (
                                                                        <>
                                                                            <p className="text-center w-100 my-3 small">OR</p>
                                                                            <Link to="/guest" className="w-100 btn btn-secondary">
                                                                                Login as Guest
                                                                        </Link>
                                                                        </>
                                                                    ) : (null)
                                                                    }

                                                                    </>
                                                                )}
                                                            </Form>
                                                        )}

                                                        {formStage === "1" && (
                                                            <Form
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    _handleUserDataSubmit(e);
                                                                }}
                                                                className="col-md-12 p-3 shadow-sm text-light rounded"
                                                                style={{ background: "#232626" }}
                                                            >
                                                                <h3 className="mb-0">Looks like you are new here</h3>
                                                                <small className="text-muted">Please enter your name</small>
                                                                <Form.Group className="mt-3 mt-md-4">
                                                                    <Form.Label>
                                                                        First name<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        name="firstName"
                                                                        type="text"
                                                                        placeholder="Enter first name"
                                                                        value={firstName}
                                                                        ref={firstNameRef}
                                                                        onChange={(e) => setFirstName(e.target.value)}
                                                                    />

                                                                    {/* <Form.Label className="mt-3">Middle name</Form.Label>
                                                                      <Form.Control
                                                                          name="middleName"
                                                                          className="mb-0"
                                                                          type="text"
                                                                          placeholder="Enter middle name"
                                                                          value={middleName}
                                                                          onChange={e => setMiddleName(e.target.value)} /> */}

                                                                    <Form.Label className="mt-3">
                                                                        Last name<span className="text-danger">*</span>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        name="lastName"
                                                                        type="text"
                                                                        placeholder="Enter last name"
                                                                        value={lastName}
                                                                        onChange={(e) => setLastName(e.target.value)}
                                                                    />



                                                                    <Form.Label className="mt-3">
                                                                        Phone Number{workspaceDataFromURL.workspaceConfig?.phoneNumberMandatory && <span className="text-danger">*</span>}
                                                                    </Form.Label>
                                                                    
                                                                    <PhoneInput
                                                                        inputClass="text-dark w-100"
                                                                        name="phoneNumber"
                                                                        country={'in'}
                                                                        required={true}
                                                                        placeholder="Enter phone number"
                                                                        value={phone}
                                                                        enableSearch
                                                                        onChange={(phone, countryData) => {
                                                                                if(typeof phone === 'string'){
                                                                                    let number = phone.replace(countryData.dialCode,"").toString();
                                            
                                                                                    let phoneNumberData = { 
                                                                                        countryCode: countryData.countryCode ? countryData.countryCode : "", 
                                                                                        phoneCode: countryData.dialCode ? countryData.dialCode :"", 
                                                                                        number: number

                                                                                    }
                                                                                    setPhoneNumber(phoneNumberData)
                                                                                    setPhone(phone)
                                                                                }
                                                                        }}
                                                                    />



                                                                    {error !== "" && (
                                                                        <small className="text-danger">{error}</small>
                                                                    )}
                                                                </Form.Group>
                                                                {workspaceDataFromURL.workspaceConfig?.phoneNumberMandatory ?
                                                                    (<Button
                                                                        className="w-100"
                                                                        variant="outline-primary"
                                                                        onClick={(e) => _handleUserDataSubmit(e)}
                                                                        disabled={loadingSendingDetails || (phone.length < 11)}
                                                                    >
                                                                        {loadingSendingDetails && (
                                                                            <Spinner
                                                                                as="span"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                                animation="border"
                                                                                className="mr-1"
                                                                                size="sm"
                                                                            />
                                                                        )}
                                                                        <span>{loadingSendingDetails ? "Please wait" : "Next"}</span>
                                                                    </Button>) : (
                                                                        <Button
                                                                            className="w-100"
                                                                            variant="outline-primary"
                                                                            onClick={(e) => _handleUserDataSubmit(e)}
                                                                            disabled={loadingSendingDetails}
                                                                        >
                                                                            {loadingSendingDetails && (
                                                                                <Spinner
                                                                                    as="span"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                    animation="border"
                                                                                    className="mr-1"
                                                                                    size="sm"
                                                                                />
                                                                            )}
                                                                            <span>{loadingSendingDetails ? "Please wait" : "Next"}</span>
                                                                        </Button>
                                                                    )
                                                                }


                                                                <Link
                                                                    onClick={() => {
                                                                        setLoadingFindingUser(false);
                                                                        setUserFound(false);
                                                                        setEmailAddress("");
                                                                        setFormStage("0");
                                                                        setFirstName("");
                                                                        setLastName("");
                                                                    }}
                                                                    className="mt-3 d-block"
                                                                >
                                                                    Back to Login
                                                      </Link>

                                                                {sendingOtp ? (
                                                                    <p className="mt-2">
                                                                        Sending OTP on{" "}
                                                                        <span className="h6">
                                                                            +{phoneCode}-{credential}
                                                                        </span>
                                                                    </p>
                                                                ) : null}

                                                                {takingToPassword ? (
                                                                    <p className="mt-2">
                                                                        Forwarding you to securely create password for{" "}
                                                                        <span className="h6">{credential}</span>
                                                                    </p>
                                                                ) : null}
                                                            </Form>
                                                        )}

                                                        {formStage === "2" && (
                                                            <Form
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    _handleEmailAndPasswordLogin(e);
                                                                }}
                                                                className="col-md-12 p-3 shadow-sm text-light rounded"
                                                                style={{ background: "#232626" }}
                                                            >
                                                                {type === "email" && (
                                                                    <>
                                                                        {previousUser ? (
                                                                            <div>
                                                                                <h3 className="mb-0">Enter password</h3>

                                                                                <p className="text-muted">
                                                                                    Logging into{" "}
                                                                                    <span className="font-weight-bold">{credential}</span>
                                                                                </p>
                                                                                <Form.Group className="mt-3 mt-md-4">
                                                                                    <Form.Label>Enter password</Form.Label>
                                                                                    <div className="d-flex">
                                                                                        <Form.Control
                                                                                            type={showPassword ? "text" : "password"}
                                                                                            placeholder="******"
                                                                                            value={password}
                                                                                            ref={passwordRef}
                                                                                            onChange={(e) => {
                                                                                                setError("");
                                                                                                setPassword(e.target.value);
                                                                                                setShowPassword(false);
                                                                                            }}
                                                                                        />
                                                                                        <div
                                                                                            onClick={() => setShowPassword(!showPassword)}
                                                                                            className="rounded rounded-circle text-theme-inverted p-2 mr-3"
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                                position: "absolute",
                                                                                                right: 0,
                                                                                            }}
                                                                                        >
                                                                                            {showPassword ? (
                                                                                                <FontAwesomeIcon icon={faEye} />
                                                                                            ) : (
                                                                                                <FontAwesomeIcon icon={faEyeSlash} />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    {error !== "" && (
                                                                                        <small className="text-danger">{error}</small>
                                                                                    )}
                                                                                </Form.Group>

                                                                                <Button
                                                                                    className="w-100"
                                                                                    variant="outline-primary"
                                                                                    onClick={(e) => _handleEmailAndPasswordLogin(e)}
                                                                                    disabled={loginLoading}
                                                                                >
                                                                                    {loginLoading && (
                                                                                        <Spinner
                                                                                            as="span"
                                                                                            role="status"
                                                                                            aria-hidden="true"
                                                                                            animation="border"
                                                                                            className="mr-1"
                                                                                            size="sm"
                                                                                        />
                                                                                    )}
                                                                                    <span>Login</span>
                                                                                </Button>
                                                                                <div className="row mt-3">
                                                                                    <div className="col-md-6">
                                                                                        <Link
                                                                                            onClick={() => {
                                                                                                setForgotPassword(true);
                                                                                                setEmailAddress(credential);
                                                                                                setFormStage("3");
                                                                                                setError("");
                                                                                                setPassword("");
                                                                                            }}
                                                                                            className=""
                                                                                        >
                                                                                            Forgot password?
                                                                  </Link>
                                                                                    </div>
                                                                                    <div className="col-md-6 text-right">
                                                                                        <p className="text-muted mb-0">
                                                                                            <span>Start over? </span>
                                                                                            <a
                                                                                                style={{ cursor: "pointer", color: "#6900ff" }}
                                                                                                // onClick={() => {
                                                                                                //     setLoadingFindingUser(false)
                                                                                                //     setUserFound(false)
                                                                                                //     setEmailAddress("")
                                                                                                //     setFormStage("0")
                                                                                                //     setPassword("")
                                                                                                // }}
                                                                                                onClick={(e) => _handleStartOver(e)}
                                                                                            >
                                                                                                <u>click here</u>
                                                                                            </a>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <h3 className="mb-0">Create and confirm password</h3>

                                                                                <p className="text-muted">
                                                                                    Creating password for{" "}
                                                                                    <span className="font-weight-bold">{credential}</span>
                                                                                </p>
                                                                                <Form.Group className="mt-3 mt-md-4">
                                                                                    <Form.Label>Enter password</Form.Label>
                                                                                    <div className="d-flex">
                                                                                        <Form.Control
                                                                                            type={showPassword ? "text" : "password"}
                                                                                            placeholder="******"
                                                                                            value={password}
                                                                                            name="password"
                                                                                            ref={createPasswordRef}
                                                                                            onChange={(e) => {
                                                                                                setError("");
                                                                                                setPassword(e.target.value);
                                                                                                setShowPassword(false);
                                                                                            }}
                                                                                            onKeyUp={(e) => validateDataOfField(e)}
                                                                                            onBlur={(e) => validateDataOfField(e)}
                                                                                        />
                                                                                        <div
                                                                                            onClick={() => setShowPassword(!showPassword)}
                                                                                            className="rounded rounded-circle text-theme-inverted p-2 mr-3"
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                                position: "absolute",
                                                                                                right: 0,
                                                                                            }}
                                                                                        >
                                                                                            {showPassword ? (
                                                                                                <FontAwesomeIcon icon={faEye} />
                                                                                            ) : (
                                                                                                <FontAwesomeIcon icon={faEyeSlash} />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    {errorObj.password ? (
                                                                                        <p className="small text-danger">
                                                                                            {errorObj.password}
                                                                                        </p>
                                                                                    ) : null}
                                                                                    <Form.Label className="mt-3">
                                                                                        Confirm password
                                                                                    </Form.Label>
                                                                                    <div className="d-flex">
                                                                                        <Form.Control
                                                                                            type={showConfirmPassword ? "text" : "password"}
                                                                                            placeholder="******"
                                                                                            value={confirmPassword}
                                                                                            onChange={(e) => {
                                                                                                setError("");
                                                                                                setConfirmPassword(e.target.value);
                                                                                                setShowConfirmPassword(false);
                                                                                            }}
                                                                                        />
                                                                                        <div
                                                                                            onClick={() =>
                                                                                                setShowConfirmPassword(!showConfirmPassword)
                                                                                            }
                                                                                            className="rounded rounded-circle text-theme-inverted p-2 mr-3"
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                                position: "absolute",
                                                                                                right: 0,
                                                                                            }}
                                                                                        >
                                                                                            {showConfirmPassword ? (
                                                                                                <FontAwesomeIcon icon={faEye} />
                                                                                            ) : (
                                                                                                <FontAwesomeIcon icon={faEyeSlash} />
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mt-3">
                                                                                        {workspaceDataFromURL.termAndCondition ? (
                                                                                            <div className="flex-row">
                                                                                                <input
                                                                                                    checked={termAndCondition}
                                                                                                    className="mt-1 mr-1"
                                                                                                    type="checkbox"
                                                                                                    onChange={(event) => {
                                                                                                        if (event.target.checked) {
                                                                                                            setTermAndCondition(true);
                                                                                                        } else {
                                                                                                            setTermAndCondition(false);
                                                                                                        }
                                                                                                    }}
                                                                                                />
                                                                                                By clicking on Register, you agree to all&nbsp; <a href={workspaceDataFromURL.termAndCondition}> terms & conditions</a> and&nbsp; <a href={workspaceDataFromURL.privacyPolicy}>Privacy Policy</a>.
                                                                                            </div>
                                                                                        ) : (null)}
                                                                                    </div>
                                                                                    {error !== "" && (
                                                                                        <small className="text-danger">{error}</small>
                                                                                    )}
                                                                                </Form.Group>
                                                                                <Button
                                                                                    className="w-100"
                                                                                    variant="outline-primary"
                                                                                    onClick={(e) => _handleEmailAndPasswordRegister(e)}
                                                                                    disabled={loadingRegister || !termAndCondition}
                                                                                >
                                                                                    {loadingRegister && (
                                                                                        <Spinner
                                                                                            as="span"
                                                                                            role="status"
                                                                                            aria-hidden="true"
                                                                                            animation="border"
                                                                                            className="mr-1"
                                                                                            size="sm"
                                                                                        />
                                                                                    )}
                                                                                    <span>Register</span>
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}

                                                                {type === "phoneNumber" && (
                                                                    <div>
                                                                        <h3 className="mb-0">Enter OTP to create account</h3>

                                                                        <p className="text-muted">
                                                                            Logging using{" "}
                                                                            <span className="font-weight-bold">
                                                                                +{phoneCode}-{credential}
                                                                            </span>
                                                                        </p>
                                                                        <Form.Group className="mt-3 mt-md-4">
                                                                            <Form.Label>Enter OTP</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                placeholder="Enter OTP"
                                                                                value={otp}
                                                                                onChange={(e) => {
                                                                                    setError("");
                                                                                    setOtp(e.target.value);
                                                                                }}
                                                                            />

                                                                            {error !== "" && (
                                                                                <small className="text-danger">{error}</small>
                                                                            )}
                                                                        </Form.Group>

                                                                        {resendOtp ? (
                                                                            <Button
                                                                                className="w-100"
                                                                                variant="outline-primary"
                                                                                onClick={(e) => _handleResendConfirmOtp(e)}
                                                                                disabled={loginLoading}
                                                                            >
                                                                                {loginLoading && (
                                                                                    <Spinner
                                                                                        as="span"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                        animation="border"
                                                                                        className="mr-1"
                                                                                        size="sm"
                                                                                    />
                                                                                )}
                                                                                <span>Login</span>
                                                                            </Button>
                                                                        ) : (
                                                                            <Button
                                                                                className="w-100"
                                                                                variant="outline-primary"
                                                                                onClick={(e) => _handleConfirmOtp(e)}
                                                                                disabled={loginLoading}
                                                                            >
                                                                                {loginLoading && (
                                                                                    <Spinner
                                                                                        as="span"
                                                                                        role="status"
                                                                                        aria-hidden="true"
                                                                                        animation="border"
                                                                                        className="mr-1"
                                                                                        size="sm"
                                                                                    />
                                                                                )}
                                                                                <span>Login</span>
                                                                            </Button>
                                                                        )}

                                                                        <div className="row mt-3">
                                                                            <div className="col-md-6">
                                                                                <p className="text-muted mb-0">Didn't get OTP?</p>
                                                                                {startCounter ? (
                                                                                    <p className="text-muted mb-0 small">
                                                                                        Resend in{" "}
                                                                                        {parseInt(counter) > 1
                                                                                            ? `${parseInt(counter)} seconds`
                                                                                            : `${parseInt(counter)} second`}
                                                                                    </p>
                                                                                ) : (
                                                                                    <Button
                                                                                        variant="outline-primary"
                                                                                        disabled={resendOtp}
                                                                                        className=""
                                                                                        onClick={(e) => _handleResendOtpSubmit(e)}
                                                                                    >
                                                                                        {loadingResendOtp && (
                                                                                            <Spinner
                                                                                                as="span"
                                                                                                role="status"
                                                                                                aria-hidden="true"
                                                                                                animation="border"
                                                                                                className="mr-1"
                                                                                                size="sm"
                                                                                            />
                                                                                        )}
                                                                                        <span>Resend</span>
                                                                                    </Button>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <p className="text-muted mb-0">
                                                                                    <span>Start over? </span>
                                                                                    <a
                                                                                        style={{ cursor: "pointer", color: "#6900ff" }}
                                                                                        // onClick={() => {
                                                                                        //     setLoadingFindingUser(false)
                                                                                        //     setUserFound(false)
                                                                                        //     setEmailAddress("")
                                                                                        //     setFormStage("0")
                                                                                        // }}
                                                                                        onClick={(e) => _handleStartOver(e)}
                                                                                    >
                                                                                        <u>click here</u>
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Form>
                                                        )}

                                                        {formStage === "3" && forgotPassword && (
                                                            <Form
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    _handleEmailPasswordReset(e);
                                                                }}
                                                                className="col-md-12 p-3 shadow-sm text-light rounded"
                                                                style={{ background: "#232626" }}
                                                            >
                                                                <div>
                                                                    <h3 className="mb-0">Please confirm your email</h3>

                                                                    <Form.Group className="mt-3 mt-md-4">
                                                                        <Form.Label>Email</Form.Label>
                                                                        <Form.Control
                                                                            type="email"
                                                                            placeholder="Email"
                                                                            value={emailAddress}
                                                                            readOnly={true}
                                                                            onChange={(e) => {
                                                                                setError("");
                                                                                setEmailAddress(e.target.value);
                                                                            }}
                                                                        />

                                                                        <Form.Label className="mt-2">Confirm email</Form.Label>
                                                                        <Form.Control
                                                                            type="email"
                                                                            placeholder="Confirm email"
                                                                            value={confirmEmailAddress}
                                                                            onChange={(e) => {
                                                                                setError("");
                                                                                setConfirmEmailAddress(e.target.value);
                                                                            }}
                                                                        />
                                                                    </Form.Group>

                                                                    <Button
                                                                        className="w-100"
                                                                        variant="outline-primary"
                                                                        onClick={(e) => _handleEmailPasswordReset(e)}
                                                                        disabled={loadingResendEmail}
                                                                    >
                                                                        {loadingResendEmail && (
                                                                            <Spinner
                                                                                as="span"
                                                                                role="status"
                                                                                aria-hidden="true"
                                                                                animation="border"
                                                                                className="mr-1"
                                                                                size="sm"
                                                                            />
                                                                        )}
                                                                        <span>Reset password</span>
                                                                    </Button>

                                                                    <Link
                                                                        onClick={() => {
                                                                            setLoadingFindingUser(false);
                                                                            setUserFound(false);
                                                                            setEmailAddress("");
                                                                            setFormStage("0");
                                                                        }}
                                                                        className="mt-3 d-block"
                                                                    >
                                                                        Back to Login
                                                        </Link>

                                                                    {resetEmailSent ? (
                                                                        <p className="mt-2">
                                                                            Reset password email has been sent to{" "}
                                                                            <span className="h6">{emailAddress}</span>
                                                                        </p>
                                                                    ) : null}

                                                                    {error !== "" && (
                                                                        <small className="text-danger">{error}</small>
                                                                    )}
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </div>


                                            }
                                        </div>
                                    </div>
                                </div>
                            </div >
                        </div >
                        : <div className="bg-theme d-flex align-items-center justify-content-center flex-column" style={{ height: '100vh' }}>
                            <div className="mb-5">
                                <img alt="igesia-logo" src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-reverse.png?alt=media&token=93df9989-4eab-406f-8122-ada051558941"
                                    width="200" />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-8 col-10 py-4 px-5 shadow-sm text-light rounded d-flex align-items-center justify-content-center" style={{ background: `rgb(35, 38, 38)`, height: '45vh' }}>
                                <Spinner size="md" animation="border" className="text-secondary" />
                            </div>
                        </div>
            }
        </>


    );
}

const mapStateToProps = state => ({
    newRoom: state.newRoom.newRoom,
    newRoomError: state.newRoom.newRoomError,
    currentUser: state.user.currentUser,
    workspaceSlug: state.platform.workspaceSlug,
})

export default connect(mapStateToProps, {
    setWorkspaceSlug,
    setCurrentWorkspace,
    setCurrentChannel,
    setAllMessages,
    setParticipants,
    setWorkspaces,
    setUser,
    setChannels,
    setRightPanel,
})(JoinWorkspace);
