import React, { useState, useEffect } from "react";
import { Image, Button, Alert, Badge, Form, ButtonGroup, ButtonToolbar, ProgressBar, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faInfoCircle, faArrowLeft, faBookOpen, faStar } from '@fortawesome/free-solid-svg-icons'
import moment from "moment";
import firebase from "firebase";
import { connect } from "react-redux";
import Viewer from 'react-viewer';

function PollView({ item, taskResponse, user, uid, setIsTaskResponded }) {
    const [resultView, setResultView] = useState(false);
    const [value, setValue] = useState('');
    const [taskView, setTaskView] = useState(true);
    const [users, setUsers] = useState([]);
    let displayData = [];

    const showTask = () => setTaskView(true);
    const closeTask = () => setTaskView(false);

    const showResult = () => setResultView(true);
    const closeResult = () => setResultView(false);

    const showAnswer = (value) => {
        return value &&
            <div>
                <Badge variant="secondary">Your answer</Badge>
                <p className="mt-0 mb-0">{value}</p>
            </div>
    }

    const [imageViewerOpen, setImageViewerOpen] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        taskView
            ?
            <div className="d-flex">
                <ButtonToolbar aria-label="Toolbar with button groups">
                    {item && item["options"].map((option, key) => (
                        <div className="col-12">
                            {item.images 
                            && <Image 
                                    src={item.images[key]} 
                                    style={{ height: 100, width: "auto" }}
                                    onClick={() => {
                                        setImageViewerOpen(prev => prev === item.uid ? null : item.uid)
                                        setActiveIndex(key)
                                    }} 
                                />}
                            <Button 
                            disabled={item.deadline < Date.now()}
                            className="w-100 mb-2" 
                            variant="outline-primary"
                            size="sm"
                            value={option} 
                            onClick={() => {    
                                setValue(option); 
                                taskResponse(uid, option); 
                                closeTask(); 
                                showResult(); 
                                showAnswer(value);
                                setIsTaskResponded(true);
                            }} 
                            size="sm">{option}</Button>
                        </div>
                    ))}
                </ButtonToolbar>
                {/* <Button variant="primary" size="sm" onClick={() => { taskResponse(uid, value); closeTask(); showResult(); showAnswer(value) }}>Submit</Button> */}
                
                {item.images && <Viewer
                    visible={imageViewerOpen === item.uid ? true : false}
                    onClose={() => setImageViewerOpen(prev => prev === item.uid ? null : item.uid)}
                    images={item.images.map((image, key) => { return {src: image, alt: item.options[key]}})}
                    activeIndex={activeIndex}
                    downloadable={false}
                />}
            </div>
            : resultView
                ?
                <div>
                    <Badge variant="secondary">Your answer</Badge>
                    <br />
                    <p className="mt-0 mb-0">{value}</p>
                    {/* <p className="mb-0 mt-0 small">Responded <i>{moment(response[user] && response[user].responseTime).format("hh:mm A DD/MM/YYYY")}</i></p> */}
                </div>
                : null
    );
}

const timeFromNow = timestamp => {
    if (moment(Date.now()).diff(moment(timestamp), 'days') < 1) return moment(timestamp).fromNow()
    else if (moment(Date.now()).diff(moment(timestamp), 'days') < 2) return "yesterday"
    else if (moment(Date.now()).diff(moment(timestamp), 'days') < 7) return moment(timestamp).format('dddd')
    else return moment(timestamp).format("MMM DD YYYY")
};

const getResult = (response, user) => {
    return response && Object.keys(response).includes(user)
}

const getValue = (response, user) => {
    return response &&
        <div>
            <Badge variant="secondary">Your answer</Badge>
            <p className="mt-0 mb-1">{response[user] && response[user].value}</p>
            <p className="mb-0 mt-0 small"><i>{timeFromNow(response[user] && response[user].responseTime)}</i></p>
        </div>
}

const toggleTask = (e) => {
    this.setState({
        isResultVisible: false,
        isTaskVisible: true
    });
};

const toggleResult = (e) => {
    this.setState({
        isResultVisible: true,
        isTaskVisible: false
    });
};



const Poll = ({ contentId, uid, taskResponse, item, user, currentChannel, open_task_branch, open_task_id }) => {
    const [value, setValue] = useState('');
    const [admin, setAdmin] = useState(false);
    const [responses, setResponses] = useState([]);
    const [isTaskResponded, setIsTaskResponded] = useState(false);
    const [show, setShow] = useState(false);
    const [imageViewerOpen, setImageViewerOpen] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [pollData, setPollData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user === item.user.id) {
            setAdmin(true)
        }

        firebase
            .firestore()
            .collection("polls")
            .doc(`${contentId}`)
            .onSnapshot({} , doc => {
                if (doc.exists) {
                    console.log("POLL DATA: ", doc.data())
                    setPollData(doc.data());
                    setLoading(false)
                }
            })
        
        firebase.database().ref("messages/"+currentChannel.id).child(item.uid).child("response").on("child_added", snap => {
            // snap.val() && getResponses(snap.val().response)
            snap.val() && setResponses(prev => [...prev, { uid: snap.key, ...snap.val() }])
        })
    }, [])

    const getResponses = (responses) => {
        responses && Object.entries(responses).forEach(([key, value]) => {
            setResponses(prev => [...prev, { uid: key, ...value }])
        })
    }

    const getName = (key) => {
        let name = "";
        firebase.database().ref("users").child(key).on("value", snap => {
            if (snap.val()) {
                name = snap.val().name ? snap.val().name : `${snap.val().firstName} ${snap.val().lastName}`;
            }
        });
        return name;
    }

    let responselist = [];
    const displayStats = (response) => {
        responselist.push(response.value)
    }

    const foo = (arr) => {
        var a = [], b = [], prev;
        arr.sort();
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] !== prev) {
                a.push(arr[i]);
                b.push(1);
            } else {
                b[b.length - 1]++;
            }
            prev = arr[i];
        }
        var c = [];
        for (var i = 0; i < a.length; i++) {
            c[i] = b[i] * 100 / arr.length;
        }
        return (
            // c.map((pvalue, key) =>
            //     //{console.log('Value is ' + pvalue)},
            //     <ProgressBar style={{height: '1rem'}} className="mb-1" animated now={pvalue} label={`${pvalue} % (${a[key]})`} />
            // )
            <>
            {item && item["options"].map((option, key) => (
                <div>
                    {item.images 
                    && <Image 
                        src={item.images[key]} 
                        style={{ height: 100, width: "auto" }} 
                        onClick={() => {
                            setImageViewerOpen(prev => prev === item.uid ? null : item.uid)
                            setActiveIndex(key)
                        }}
                    />}
                    <p className="mb-1">{option}</p>
                    <ProgressBar 
                    style={{height: '1rem'}} 
                    className="mb-1" 
                    animated 
                    now={a.indexOf(option) >= 0 ? c[a.indexOf(option)] : "0"} 
                    label={`${a.indexOf(option) >= 0 ? c[a.indexOf(option)].toFixed(2) : "0"} %`} />
                </div>
            ))}
            {item.images && <Viewer
                visible={imageViewerOpen === item.uid ? true : false}
                onClose={() => setImageViewerOpen(prev => prev === item.uid ? null : item.uid)}
                images={item.images.map((image, key) => { return {src: image, alt: item.options[key]}})}
                activeIndex={activeIndex}
                downloadable={false}
            />}
            </>
        )
    }

    const displayResponse = (response) => {
        return (
            <div className="mt-2">
                <p className="my-0 font-weight-bold">{getName(response.uid)}</p>
                <p className="my-0">{response.value}</p>
                <p className="my-0 small"><i>{timeFromNow(response.responseTime)}</i></p>
            </div>
        )
    }


    return (
        <>
            <div className="taskBox">
                <Form className="poll">
                    <div className="d-flex">
                        <h5 className="mt-0 flex-grow-1"  style={{width: '300px'}}>{loading ? <Spinner size="sm" animation="border" /> : pollData.title}</h5>
                        <div>{pollData.deadline < Date.now() ? <Badge variant="secondary">Closed</Badge> : <Badge variant="primary">Active</Badge>}</div>
                        {(open_task_branch === "saved" && open_task_id === item.uid) && <div className="mx-1"><Badge variant="success">Saved</Badge></div>}
                    </div>

                    {(admin || pollData.deadline < Date.now() || isTaskResponded || item && getResult(item.response, user)) 
                        && <>
                            {responses.map(response => {
                                return displayStats(response)
                            })}
                            {foo(responselist)}
                        </>}
                    
                    {/* {(admin || item.deadline < Date.now())
                        // ?
                        &&
                            <>
                                {responses.map(response => {
                                    return displayStats(response)
                                })}
                                {foo(responselist)}
                            </>
                        // :   null
                    } */}
                    {admin
                        ? responses.length
                            ? <div className="d-flex justify-content-center">
                                <Badge variant="secondary"> {responses.length} Response(s)</Badge>
                                <small onClick={() => setShow(prev => !prev)} className="ml-auto text-primary font-weight-bold" style={{ cursor: "pointer" }}>{show ? "Hide (-)" : "Show (+)"}</small>
                            </div>
                            : <p className="my-1">No responses yet</p>
                        : null
                    }

                    <div>
                        {admin
                            ? show && <>
                                {responses.map(response => {
                                    return displayResponse(response)
                                })}
                            </>
                            : item && getResult(item.response, user)
                                ? <div>{getValue(item.response, user)}</div>
                                : !loading && <PollView item={{...item, ...pollData}} taskResponse={(uid, option) => console.log(uid, option)} user={user} uid={uid} setIsTaskResponded={setIsTaskResponded} />

                        }
                    </div>
                </Form>
            </div>
        </>
    )
};

const mapStateToProps = state => ({
    currentChannel: state.channel.currentChannel,
    open_task_branch: state.platform.open_task_branch,
    open_task_id: state.platform.open_task_id
})

export default connect(mapStateToProps)(Poll);
