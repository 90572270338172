import React, { Component, useState } from 'react';
import radium from 'radium';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import Notification from "./Notification"

import PencilCur from './Icons/Pencil.cur';
import EraserCur from './Icons/eraser.cur';
import BrushCur from './Icons/brush.cur';

const styles = {
	graffitiBoardWrapper: {
		zIndex: 100,
		position: 'relative',
		backgroundColor: 'transparent'
	},
	canvas: {
		top: 0,
		left: 0,
		position: 'absolute'
	}
};

class GraffitiBoard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			graffitiBoard: null,
			show: false,
			draw: false,
			stroke: null,
			initX: null,
			initY: null,
			trace: {
				path: []
			},
			textInput: false
		};
	}

	async componentDidMount() {
		this.initBoard({});
	}

	drawMouseStart = (e) => {
		const { graffitiBoard } = this.state;
		let o = graffitiBoard;
		graffitiBoard.offsetX = graffitiBoard.offsetLeft;
		graffitiBoard.offsetY = graffitiBoard.offsetTop;
		while (o.offsetParent) {
			o = o.offsetParent;
			graffitiBoard.offsetX += o.offsetLeft;
			graffitiBoard.offsetY += o.offsetTop;
		}

		const { selectedTool } = this.props;
		this.setState(
			{
				draw: true,
				stroke: this.state.graffitiBoard.getContext('2d'),
				initX: e.pageX - this.state.graffitiBoard.offsetX,
				initY: e.pageY - this.state.graffitiBoard.offsetY
			},
			() => {
				const { stroke, graffitiBoard, initX, initY } = this.state;
				const { penColor, lineWidth } = this.props;
				stroke.lineWidth = lineWidth;
				stroke.strokeStyle = penColor;

				// console.log(initX);
				// console.log(e.offsetX);

				if (selectedTool === 'PENCILE') {
					stroke.lineCap = 'round';
					stroke.beginPath();
					stroke.moveTo(initX, initY);
				}

				if (selectedTool === 'BRUSH') {
					this.clear();
					const path = {
						x: initX,
						y: initY
					};
					stroke.lineJoin = stroke.lineCap = 'round';
					stroke.shadowBlur = this.props.lineWidth * 2;
					stroke.shadowColor = this.props.penColor;
					stroke.beginPath();
					stroke.moveTo(initX, initY);
					this.setState({
						trace: {
							...this.state.trace,
							path: [...this.state.trace.path, path]
						}
					});
				}

				if (selectedTool === 'ERASER') {
					stroke.beginPath();
					stroke.lineCap = 'round';
					stroke.strokeStyle = '#fff';
					stroke.lineWidth = lineWidth * 2;
					stroke.moveTo(initX, initY);
				}

				if (selectedTool === 'TEXT') {
					if (!initX) return;
					this.setState({ textInput: true }, () => {
						if (this.state.textInput) {
							swal({
								text: 'Input text".',
								content: 'input',
								button: {
									text: 'Add',
									closeModal: false
								}
							})
								.then((text) => {
									if (text) {
										stroke.beginPath();
										stroke.fillStyle = this.props.penColor;
										stroke.font = `${(this.props.lineWidth + 1) * 6}px Arial`;
										stroke.fillText(text, initX, initY);
										stroke.stroke();

										const path = {
											x: Math.abs(initX),
											y: Math.abs(initY),
											text: text,
											fontSize: this.props.lineWidth,
											color: this.props.penColor
										};

										this.setState({
											trace: {
												...this.state.trace,
												path: [path]
											},
											textInput: false
										});
									}
								})
								.then(() => {
									swal.close();
									this.drawEnd(e);
								});
						}
					});
				}

				if (selectedTool === 'LINE') {
					this.clear();

					const path = {
						p: initX,
						y: initY
					};
					stroke.beginPath();
					stroke.moveTo(path.x, path.y);
				}

				if (selectedTool === 'CURVE') {
					stroke.beginPath();
					stroke.moveTo(initX, initY);
					this.clear();
				}
			}
		);
	};

	mouseDrawing = (e) => {
		const { selectedTool } = this.props;
		const { draw, stroke, graffitiBoard, initX, initY } = this.state;
		const curX = e.pageX - graffitiBoard.offsetX;
		const curY = e.pageY - graffitiBoard.offsetY;

		if (draw && selectedTool === 'PENCILE') {
			const path = { x: curX, y: curY };
			stroke.lineCap = 'round';
			stroke.lineTo(curX, curY);
			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [...this.state.trace.path, path]
				}
			}, () => {
				if (this.state.trace.path.length > 400) {
					this.setState({ show: true })
					this.drawEnd(e)
				}
			});
		}

		if (!draw && selectedTool === 'PENCILE') {
			const ctx = this.state.graffitiBoard.getContext('2d');
			this.clear();
			if (e.offsetX > 10 && e.offsetX < 830 && e.offsetY > 10 && e.offsetY < 500) {
				ctx.beginPath();
				ctx.fillStyle = this.props.penColor;
				var penPoint = 2;
				if (this.props.lineWidth > 2) {
					penPoint = this.props.lineWidth * 0.6;
				}
				ctx.arc(e.offsetX - 1, e.offsetY - 1, penPoint, 0, 2 * Math.PI);
				ctx.fill();
			}
		}

		if (draw && selectedTool === 'BRUSH' && initX && initY) {
			const path = {
				x: curX,
				y: curY
			};
			stroke.lineCap = 'round';
			stroke.lineTo(curX, curY);
			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [...this.state.trace.path, path]
				}
			});
		}

		if (!draw && selectedTool === 'ERASER') {
			const ctx = this.state.graffitiBoard.getContext('2d');
			this.clear();
			ctx.shadowColor = 'black';
			ctx.shadowBlur = 1;
			if (e.offsetX > 10 && e.offsetX < 830 && e.offsetY > 10 && e.offsetY < 500) {
				ctx.beginPath();
				ctx.fillStyle = 'white';
				ctx.arc(e.offsetX - 2, e.offsetY - 2, this.props.lineWidth * 1.14, 0, 2 * Math.PI);

				ctx.fill();
			}
		}
		if (draw && selectedTool === 'ERASER') {
			const path = { x: curX, y: curY };
			stroke.lineTo(curX, curY);
			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [...this.state.trace.path, path]
				}
			});
		}

		if (selectedTool === 'TEXT') {
			const ctx = this.state.graffitiBoard.getContext('2d');
			this.clear();
			if (e.offsetX > 10 && e.offsetX < 830 && e.offsetY > 10 && e.offsetY < 500) {
				ctx.beginPath();
				ctx.fillStyle = this.props.penColor;
				ctx.font = `12px Arial`;
				ctx.fillText('Click to add text', e.offsetX + 5, e.offsetY + 5);
				ctx.stroke();
			}
		}

		if (draw && selectedTool === 'ARROW' && initX && initY) {
			const path = {
				p: initX,
				q: initY,
				x: curX,
				y: curY,
				headlen: this.props.lineWidth * 10
			};

			var angle = Math.atan2(path.y - path.q, path.x - path.p);
			this.clear();
			stroke.beginPath();
			stroke.moveTo(path.p, path.q);
			stroke.lineTo(path.x, path.y);
			stroke.moveTo(path.x, path.y);
			stroke.lineTo(
				path.x - path.headlen * Math.cos(angle - Math.PI / 6),
				path.y - path.headlen * Math.sin(angle - Math.PI / 6)
			);
			stroke.moveTo(path.x, path.y);
			stroke.lineTo(
				path.x - path.headlen * Math.cos(angle + Math.PI / 6),
				path.y - path.headlen * Math.sin(angle + Math.PI / 6)
			);
			stroke.lineCap = 'butt';
			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}

		if (draw && selectedTool === 'LINE' && initX && initY) {
			this.clear();
			const path = {
				p: initX,
				q: initY,
				x: curX,
				y: curY
			};
			// stroke.moveTo(initX, initY)
			stroke.beginPath();
			stroke.lineTo(path.p, path.q);
			stroke.lineTo(path.x, path.y);
			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}

		if (draw && selectedTool === 'CURVE') {
			this.clear();
			const path = {
				p: initX,
				q: initY,
				x: curX,
				y: curY
			};
			console.log(path);
			// stroke.moveTo(initX, initY)
			stroke.beginPath();
			stroke.moveTo(path.p, path.q);
			stroke.bezierCurveTo(
				path.p + (path.x - path.p) / 3,
				path.q + (path.x - path.p) / 2.5,
				path.p + (path.x - path.p) * 2 / 3,
				path.q + (path.x - path.p) / 2.5,
				path.x,
				path.y
			);
			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}
		if (draw && selectedTool === 'TRIANGLE') {
			this.clear();
			if (!initX) return;
			const path = {
				x: initX,
				y: initY,
				p: curX,
				q: curY,
				z: (curX + initX) / 2
			};
			stroke.beginPath();
			stroke.moveTo(path.x, path.q);
			stroke.lineTo(path.z, path.y);
			stroke.lineTo(path.p, path.q);
			stroke.closePath();
			stroke.stroke();

			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}

		if (draw && selectedTool === 'RECTANGLE') {
			this.clear();
			const path = {
				x: initX <= curX ? initX : curX,
				y: initY <= curY ? initY : curY,
				w: Math.abs(curX - initX) ? Math.abs(curX - initX) : 0,
				h: Math.abs(curY - initY) ? Math.abs(curY - initY) : 0
			};

			stroke.strokeRect(path.x, path.y, path.w, path.h);

			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}

		if (draw && selectedTool === 'FILLRECTANGLE') {
			this.clear();
			const path = {
				x: initX <= curX ? initX : curX,
				y: initY <= curY ? initY : curY,
				w: Math.abs(curX - initX) ? Math.abs(curX - initX) : 0,
				h: Math.abs(curY - initY) ? Math.abs(curY - initY) : 0,
				color: this.props.penColor
			};
			stroke.fillStyle = path.color;
			stroke.fillRect(path.x, path.y, path.w, path.h);

			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}

		if (draw && selectedTool === 'CIRCLE') {
			this.clear();

			var path = {
				x: initX <= curX ? initX + Math.abs(curY - initY) / 2 : 0,
				y: initY <= curY ? initY + Math.abs(curY - initY) / 2 : 0,
				r:
					initY <= curY || initX <= curX
						? Math.abs(curY - initY) >= Math.abs(curX - initX)
							? Math.abs(curY - initY) / 2
							: Math.abs(curX - initX) / 2
						: 0
			};
			path['x'] = initX + path.r;
			path['y'] = initY + path.r;
			console.log(path);
			if (initY >= curY || initX >= curX) return;
			stroke.beginPath();
			stroke.arc(path.x, path.y, path.r, 0, 2 * Math.PI);
			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}
	};

	drawTouchStart = (e) => {
		// console.log("Touch start", e.touches[0])
		const { graffitiBoard } = this.state;
		let o = graffitiBoard;
		graffitiBoard.offsetX = graffitiBoard.offsetLeft;
		graffitiBoard.offsetY = graffitiBoard.offsetTop;
		while (o.offsetParent) {
			o = o.offsetParent;
			graffitiBoard.offsetX += o.offsetLeft;
			graffitiBoard.offsetY += o.offsetTop;
		}
		const { selectedTool } = this.props;
		this.setState(
			{
				draw: true,
				stroke: this.state.graffitiBoard.getContext('2d'),
				initX: e.touches[0].pageX - this.state.graffitiBoard.offsetX,
				initY: e.touches[0].pageY - this.state.graffitiBoard.offsetY
			},
			() => {
				const { stroke, graffitiBoard, initX, initY } = this.state;
				const { penColor, lineWidth } = this.props;
				stroke.lineWidth = lineWidth;
				stroke.strokeStyle = penColor;

				if (selectedTool === 'PENCILE') {
					const path = {
						x: initX,
						y: initY
					};
					stroke.beginPath();
					stroke.moveTo(initX, initY);
					this.setState({
						trace: {
							...this.state.trace,
							path: [...this.state.trace.path, path]
						}
					});
				}

				if (selectedTool === 'ERASER') {
					const path = {
						x: initX,
						y: initY
					};
					stroke.beginPath();

					stroke.lineWidth = lineWidth * 2;
					stroke.moveTo(initX, initY);
					this.setState({
						trace: {
							...this.state.trace,
							path: [...this.state.trace.path, path]
						}
					});
				}

				if (selectedTool === 'TEXT') {
					if (!initX) return;
					this.setState({ textInput: true }, () => {
						if (this.state.textInput) {
							swal({
								text: 'Input text',
								content: 'input',
								button: {
									text: 'Add',
									closeModal: false
								}
							})
								.then((text) => {
									if (text) {
										stroke.beginPath();
										stroke.fillStyle = this.props.penColor;
										stroke.font = `${(this.props.lineWidth + 1) * 8}px Arial`;
										stroke.fillText(text, initX, initY);
										stroke.stroke();

										const path = {
											x: Math.abs(initX),
											y: Math.abs(initY),
											text: text,
											fontSize: this.props.lineWidth,
											color: this.props.penColor
										};

										this.setState({
											trace: {
												...this.state.trace,
												path: [path]
											},
											textInput: false
										});
									}
								})
								.then(() => {
									swal.close();
									this.drawEnd(e);
								});
						}
					});
				}

				if (selectedTool === 'LINE') {
					this.clear();

					const path = {
						p: initX,
						y: initY
					};
					stroke.beginPath();
					// stroke.moveTo(initX, initY)

					stroke.moveTo(path.x, path.y);
				}
				if (selectedTool === 'CURVE') {
					stroke.beginPath();
					stroke.moveTo(initX, initY);
					this.clear();
				}
			}
		);
	};

	touchDrawing = (e) => {
		// console.log("Drawing:", e)
		const { selectedTool } = this.props;
		const { draw, stroke, graffitiBoard, initX, initY } = this.state;
		const curX = e.touches[0].pageX - graffitiBoard.offsetX;
		const curY = e.touches[0].pageY - graffitiBoard.offsetY;

		if (draw && selectedTool === 'PENCILE') {
			const path = { x: curX, y: curY };
			stroke.lineTo(curX, curY);
			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [...this.state.trace.path, path]
				}
			}, () => {
				if (this.state.trace.path.length > 400) {
					this.drawEnd(e)
				}
			});
		}

		if (draw && selectedTool === 'LINE' && initX && initY) {
			this.clear();
			const path = {
				p: initX,
				q: initY,
				x: curX,
				y: curY
			};
			// stroke.moveTo(initX, initY)
			stroke.beginPath();
			stroke.lineTo(path.p, path.q);
			stroke.lineTo(path.x, path.y);
			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}

		if (draw && selectedTool === 'ARROW' && initX && initY) {
			const path = {
				p: initX,
				q: initY,
				x: curX,
				y: curY,
				headlen: this.props.lineWidth * 5
			};

			var angle = Math.atan2(path.y - path.q, path.x - path.p);
			this.clear();
			stroke.beginPath();
			stroke.moveTo(path.p, path.q);
			stroke.lineTo(path.x, path.y);
			stroke.moveTo(path.x, path.y);
			stroke.lineTo(
				path.x - path.headlen * Math.cos(angle - Math.PI / 6),
				path.y - path.headlen * Math.sin(angle - Math.PI / 6)
			);
			stroke.moveTo(path.x, path.y);
			stroke.lineTo(
				path.x - path.headlen * Math.cos(angle + Math.PI / 6),
				path.y - path.headlen * Math.sin(angle + Math.PI / 6)
			);
			stroke.lineCap = 'round';
			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}

		if (draw && selectedTool === 'CURVE') {
			this.clear();
			const path = {
				p: initX ? initX : curX,
				q: initY ? initY : curY,
				x: curX,
				y: curY
			};
			// stroke.moveTo(initX, initY)
			stroke.beginPath();
			stroke.moveTo(path.p, path.q);
			stroke.quadraticCurveTo(path.p, path.x - path.p, path.x, path.y);
			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}
		if (draw && selectedTool === 'TRIANGLE') {
			this.clear();
			if (!initX) return;
			const path = {
				x: initX,
				y: initY,
				p: curX,
				q: curY,
				z: (curX + initX) / 2
			};
			stroke.beginPath();
			stroke.moveTo(path.x, path.q);
			stroke.lineTo(path.z, path.y);
			stroke.lineTo(path.p, path.q);
			stroke.closePath();
			stroke.stroke();

			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}

		if (draw && selectedTool === 'RECTANGLE') {
			this.clear();
			const path = {
				x: initX <= curX ? initX : curX,
				y: initY <= curY ? initY : curY,
				w: Math.abs(curX - initX) ? Math.abs(curX - initX) : 0,
				h: Math.abs(curY - initY) ? Math.abs(curY - initY) : 0
			};

			stroke.strokeRect(path.x, path.y, path.w, path.h);

			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}

		if (draw && selectedTool === 'FILLRECTANGLE') {
			this.clear();
			const path = {
				x: initX <= curX ? initX : curX,
				y: initY <= curY ? initY : curY,
				w: Math.abs(curX - initX) ? Math.abs(curX - initX) : 0,
				h: Math.abs(curY - initY) ? Math.abs(curY - initY) : 0,
				color: this.props.penColor
			};
			stroke.fillStyle = path.color;
			stroke.fillRect(path.x, path.y, path.w, path.h);

			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}

		if (draw && selectedTool === 'CIRCLE') {
			this.clear();

			const path = {
				x: initX <= curX ? initX + Math.abs(curX - initX) / 2 : curX,
				y: initY <= curY ? initY + Math.abs(curX - initX) / 2 : curY,
				r: Math.abs(curY - initY) ? Math.abs(curY - initY) / 2 : 0
			};
			stroke.beginPath();
			stroke.arc(path.x, path.y, path.r, 0, 2 * Math.PI);

			stroke.stroke();
			this.setState({
				trace: {
					...this.state.trace,
					path: [path]
				}
			});
		}
	};

	drawEnd = (e) => {
		const { trace } = this.state;
		const { selectedTool } = this.props;
		e.preventDefault();
		if (selectedTool === 'RECTANGLE' && trace.path.length) {
			this.props.save({
				type: 'RECTANGLE',
				color: this.props.penColor,
				path: trace.path.length ? trace.path[trace.path.length - 1] : null,
				strokeWidth: parseInt(this.props.lineWidth)
			});
		}

		if (selectedTool === 'TRIANGLE' && trace.path.length) {
			this.props.save({
				type: 'TRIANGLE',
				color: this.props.penColor,
				path: trace.path.length ? trace.path[trace.path.length - 1] : null,
				strokeWidth: parseInt(this.props.lineWidth)
			});
		}

		if (selectedTool === 'FILLRECTANGLE' && trace.path.length) {
			this.props.save({
				type: 'FILLRECTANGLE',
				color: this.props.penColor,
				path: trace.path.length ? trace.path[trace.path.length - 1] : null,
				strokeWidth: parseInt(this.props.lineWidth)
			});
		}
		if (selectedTool === 'LINE' && trace.path.length) {
			this.props.save({
				type: 'LINE',
				color: this.props.penColor,
				path: trace.path[0],
				strokeWidth: parseInt(this.props.lineWidth)
			});
		}
		if (selectedTool === 'ARROW' && trace.path.length) {
			this.props.save({
				type: 'ARROW',
				color: this.props.penColor,
				path: trace.path[0],
				strokeWidth: parseInt(this.props.lineWidth)
			});
		}
		if (selectedTool === 'CURVE' && trace.path.length) {
			this.props.save({
				type: 'CURVE',
				color: this.props.penColor,
				path: trace.path[0],
				strokeWidth: parseInt(this.props.lineWidth)
			});
		}

		if (selectedTool === 'CIRCLE' && trace.path.length) {
			this.props.save({
				type: 'CIRCLE',
				color: this.props.penColor,
				path: trace.path.length ? trace.path[trace.path.length - 1] : null,
				strokeWidth: parseInt(this.props.lineWidth)
			});
		}

		if (selectedTool === 'TEXT' && trace.path.length) {
			this.props.save({
				color: this.props.penColor,
				type: 'TEXT',
				font: this.props.textFont,
				path: trace.path.length ? trace.path[trace.path.length - 1] : null
			});
		}
		if (selectedTool === 'PENCILE' && trace.path.length) {
			this.props.save({
				type: 'PENCILE',
				color: this.props.penColor,
				path: trace.path,
				strokeWidth: parseInt(this.props.lineWidth)
			});
		}

		if (selectedTool === 'BRUSH' && trace.path.length) {
			this.props.save({
				type: 'BRUSH',
				color: this.props.penColor,
				path: trace.path,
				strokeWidth: parseInt(this.props.lineWidth)
			});
		}

		if (selectedTool === 'ERASER' && trace.path.length) {
			this.props.save({
				type: 'PENCILE',
				isEraser: true,
				color: '#fff',
				path: trace.path,
				strokeWidth: parseInt(this.props.lineWidth) * 2
			});
		}

		this.clear();
		this.setState({
			draw: false,
			trace: {
				path: []
			}
		});
	};

	clear = () => {
		const { graffitiBoard } = this.state;
		graffitiBoard.getContext('2d').clearRect(0, 0, graffitiBoard.width, graffitiBoard.height);
		graffitiBoard.getContext('2d').shadowBlur = 0;
	};


	initBoard = () => {
		const { boardWidth, boardHeight } = this.props;
		const graffitiBoard = document.querySelector('#graffitiBoard');
		graffitiBoard.width = boardWidth;
		graffitiBoard.height = boardHeight;
		this.setState(
			{
				graffitiBoard
			},
			() => {
				const eventList = {
					mousedown: this.drawMouseStart,
					mousemove: this.mouseDrawing,
					mouseup: this.drawEnd,
					touchstart: this.drawTouchStart,
					touchmove: this.touchDrawing,
					touchend: this.drawEnd
				};
				Object.keys(eventList).forEach((key) => this.state.graffitiBoard.addEventListener(key, eventList[key]));
			}
		);
	};

	render() {
		const { boardWidth, boardHeight } = this.props;
		const wrapperStyle = {
			...styles.graffitiBoardWrapper,
			cursor: `${this.props.selectedTool === 'TEXT'
				? 'text'
				: this.props.selectedTool === 'PENCILE'
					? `url(${PencilCur}),crosshair `
					: this.props.selectedTool === 'BRUSH'
						? `url(${BrushCur}),crosshair `
						: this.props.selectedTool === 'ERASER' ? `url(${EraserCur}),crosshair ` : 'crosshair'}`,
			width: `${boardWidth}px`,
			height: `${boardHeight}px`
		};
		return (
			<div className="graffitiBoard-wrapper" style={wrapperStyle}>
				<div
					style={{
						position: 'absolute',
						bottom: 0,
						right: 0,
					}}
				>
					<Notification
						header="Alert"
						body="Max length to draw single line exceeded!"
						show={this.state.show}
						onHide={() => this.setState({ show: false })}
					/>
				</div>
				<canvas id="graffitiBoard" style={styles.canvas} />
			</div>
		);
	}
}

GraffitiBoard.propTypes = {
	boardWidth: PropTypes.number,
	boardHeight: PropTypes.number,
	penColor: PropTypes.string,
	lineWidth: PropTypes.number,
	selectedTool: PropTypes.string,
	save: PropTypes.func,
	close: PropTypes.func
};

GraffitiBoard.defaultProps = {
	boardWidth: 300,
	boardHeight: 300,
	penColor: '#000',
	lineWidth: 1,
	selectedTool: 'PENCILE',
	save: (file) => {
		console.log(file);
	},
	close: () => {
		console.log('close click');
	}
};

export default (GraffitiBoard);

