import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import axios from "axios";
import {
  API_BASE_URL,
  DRIVE_FILE,
  DRIVE_UPLOAD,
  POLL_CREATE,
  POLL_REMOVE,
  POLL_UPDATE,
  POLL_SUBMIT,
  POLL_SHARE,
  POLL_EDIT,
  APP_CLOSE,
  ROOM_APP_LIST,
} from "../../../config/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../index2.scss";
import {
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faPlusCircle,
  faAngleDown,
  faArrowLeft,
  faTimes,
  faSearch,
  faFilter,
  faQuestionCircle,
  faExclamationTriangle,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faSortAlphaUp,
  faSortAlphaUpAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Dropdown,
  Button,
  DropdownButton,
  Badge,
  Form,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
  FormControl,
  Spinner,
  NavDropdown,
} from "react-bootstrap";
//import DisplayName from "./DisplayName";
import moment from "moment";
import _ from "lodash";
import {
  setPollTitle,
  setPollOptions,
  setPollOptionsImages,
  setPollPreviewImages,
  setPollOptionsFiles,
  setPollOptionsFilesData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
  setRightPanel,
  setContentTypeAndValue,
  resetData,
  setSharedTask,
  setTaskSharing,
  setTaskEditing,
  setEditedTask,
  setEditedPublishedTask,
  setEditingPublished,
  resetPoll,
} from "../../../actions/index";

import { toast } from "react-toastify";
import CreatePoll from "./CreatePoll1";
import Poll from "./Poll";
import IsAdmin from "../../IsAdmin";

toast.configure();

class PollHomepage extends Component {
  state = {
    searchTerm: "",
    regex: "",
    shareNow: false,
    saveForLater: false,
    selected: "Published",
    publishedPolls: [],
    publishedPollsData: [],
    savedPolls: [],
    savedPollsData: [],
    editedPoll: {},
    selectedPoll: {},
    view: 0,
    error: "",
    sharedAgainPoll: {},
    editedPublishedPoll: {},
    editedPollHasResponses: false,
    publishedPollId: null,
    pollMessageData: null,
    message_Id: null,
    sortType: "unreverse",
    reversed: false,
  };

  async componentDidMount() {
    const { currentChannel, currentWorkspace, currentUser } = this.props;
    let savedPolls = [];
    let publishedPolls = [];

    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/saved`
      )
      .orderBy("timestamp", "desc");

    const queryMsg = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/messages`
      )
      .orderBy("timestamp", "desc");

    query.where("type", "==", 3).onSnapshot((snapshot) => {
      axios
        .post(`${API_BASE_URL}${ROOM_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          user_id: currentUser.uid,
          message_type: 3,
          app_state: "saved",
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ savedPolls: res.data.data });
            this.handlePollsData(res.data.data);
            this.setState({ sortType: "unreverse" });
          }
        });
    });

    queryMsg.where("type", "==", 3).onSnapshot((snapshot) => {
      axios
        .post(`${API_BASE_URL}${ROOM_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          user_id: currentUser.uid,
          message_type: 3,
          app_state: "published",
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ publishedPolls: res.data.data });
            this.handlePublishPollsData(res.data.data);
            this.setState({ sortType: "unreverse" });
          }
        });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //switchSort()
    if (this.props.sharing && this.state.view === 0) {
      this.setState({ view: 1 });
    }
  }
  handlePollsData = async (savedPolls) => {
    let savedPollsData = [];
    savedPollsData = await Promise.all(
      savedPolls.map(async (savedPoll) => {
        const pollData = await firebase
          .firestore()
          .collection(`polls`)
          .doc(savedPoll.contentId)
          .get();
        return {
          ...pollData.data(),
          id: pollData.id,
        };
      })
    );
    if (this.state.sortType === "atoz") {
      const sortedPollsData = _.sortBy(savedPollsData, (poll) =>
        poll.title.toLowerCase()
      );
      savedPollsData = sortedPollsData;
    }
    if (this.state.sortType === "ztoa") {
      const sortedPollsData = _.sortBy(savedPollsData, (poll) =>
        poll.title.toLowerCase()
      );
      savedPollsData = sortedPollsData.reverse();
    }
    this.setState({ savedPolls, savedPollsData });
    if (savedPolls.length === 0) {
      this.setState({ savedPolls });
    }
  };

  handlePublishPollsData = async (publishedPolls) => {
    let publishedPollsData = [];
    publishedPollsData = await Promise.all(
      publishedPolls.map(async (publishedPoll) => {
        const pollData = await firebase
          .firestore()
          .collection(`polls`)
          .doc(publishedPoll.contentId)
          .get();
        return {
          ...pollData.data(),
          id: pollData.id,
          messageId: publishedPoll.id,
        };
      })
    );
    const sortedPollsData = _.sortBy(publishedPollsData, (poll) =>
      poll.title?.toLowerCase()
    );
    if (this.state.sortType === "atoz") {
      publishedPollsData = sortedPollsData;
    }
    if (this.state.sortType === "ztoa") {
      publishedPollsData = sortedPollsData.reverse();
    }
    this.setState({ publishedPolls, publishedPollsData });
    if (publishedPolls.length === 0) {
      this.setState({ publishedPolls });
    }
  };
  addPollView = (view) => {
    this.setState({ view });
  };
  handleSelected = (selected) => {
    this.setState({ selected });
  };
  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));
  showSelectedPoll = (id) => {
    let selectedPoll = { id: id };
    this.setState({ selectedPoll, view: 2 });
  };
  uploadImage = async (file, key) => {
    //console.log("called",file, key)
    const { currentUser } = this.props;
    //console.log(this.props);
    let requestData = {};

    if (file) {
      const fileData = new FormData();
      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append("user_id", currentUser.uid);

      /*if (folder_id) {
        fileData.append("folder_id", foder_idl);
      }*/
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        /*onUploadProgress: (ProgressEvent) => {
          this.setState({percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,);},*/
      };
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        console.log(res);
        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
      }
    } else return "";
  };

  shareTaskAgain = (taskData) => {
    const {
      allParticipants,
      sharing,
      editing,
      currentChannel,
      editingPublished,
    } = this.props;
    //console.log(taskData.images);
    //console.log(taskData);
    this.props.setPollOptions(taskData.options);
    this.props.setPollTitle(taskData.title);
    this.props.setPollOptionsImages(taskData.images);
    this.props.setPollPreviewImages(taskData.images);
    //this.props.setTaskChannelUsers(taskData.assignedTo);
    //console.log(allParticipants);
    console.log(taskData);

    if (taskData.response.length > 0 && editingPublished) {
      this.setState({ editedPollHasResponses: true });
    }

    if (editingPublished) {
      this.setState({
        pollMessageData: taskData,
        publishedPollId: taskData.id,
      });
    }

    if (
      (taskData.all === false &&
        taskData.assignedTo.every((user) =>
          allParticipants.find(
            (User) => User.uid === user || user === currentChannel.createdBy
          )
        )) ||
      taskData.all === true
    ) {
      this.props.setTaskChannelUsers(taskData.assignedTo);
    } else {
      let assignedTo = taskData.assignedTo.filter((user) =>
        allParticipants.find(
          (User) => User.uid === user || user === currentChannel.createdBy
        )
      );
      //console.log(assignedTo);
      this.props.setTaskChannelUsers(assignedTo);
    }
    this.props.setTaskEveryone(taskData.all);
    this.props.setTaskFolder(taskData.folderId);
    this.props.setTaskDeadline(new Date(taskData.deadline));
    this.props.setSharedTask({});
    this.props.setTaskSharing(false);
    this.props.setEditedTask({});
    this.props.setEditedPublishedTask({});
    this.props.setTaskEditing(false);
    if (editing) {
      this.setState({ editedPoll: taskData, view: 1 });
    } else if (editingPublished) {
      this.setState({ editedPublishedPoll: taskData, view: 1 });
    } else {
      this.setState({ sharedAgainPoll: taskData, view: 1 });
    }
  };

  closeAppResponses = async (poll) => {
    const { currentWorkspace, currentChannel, darkTheme } = this.props;
    console.log(message_id, poll, "iddd");
    var message_id = poll.messageId;
    var user = firebase.auth().currentUser;
    let res = await axios.post(`${API_BASE_URL}${APP_CLOSE}`, {
      workspace_id: currentWorkspace.workspaceId,
      room_id: currentChannel.roomId,
      user_id: user.uid,
      message_id: message_id,
    });
    if (res.status === 200) {
      if (darkTheme) {
        toast.dark("Closed", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      } else {
        toast("Closed", {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    } else {
      console.log(res);
    }
  };

  editPublishedTask = async (message_id) => {
    console.log(message_id, "my edit poll");
    const { currentChannel, currentWorkspace } = this.props;
    // let messageData = getMessageData(message_id);
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      );
    let messageDataObj = await query
      .doc(message_id)
      .get()
      .then((doc) => {
        console.log(doc.data(), "my edit poll");
        if (doc.exists) {
          return { ...doc.data(), id: doc.id };
        }
      });
    console.log(messageDataObj);
    let messageData = {};
    if (messageDataObj.type === 3) {
      messageData = await firebase
        .firestore()
        .collection("polls")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          console.log(doc.data(), "my edit poll");
          return { ...doc.data(), id: doc.id };
        });
    }
    //console.log(messageData, "respox");
    this.props.resetData();
    this.props.setRightPanel(true);
    this.props.setEditingPublished(true);
    setTimeout(
      () => this.props.setContentTypeAndValue("createTask", "Poll"),
      500
    );
    setTimeout(() => this.props.setEditedPublishedTask(messageData), 700);
  };

  sortPollData = () => {
    if (this.state.sortType === "reverse") {
      const { savedPollsData, publishedPollsData } = this.state;
      this.setState(
        {
          savedPollsData: savedPollsData.reverse(),
          publishedPollsData: publishedPollsData.reverse(),
        },
        () => {
          this.setState({ reversed: true });
        }
      );
    } else if (this.state.sortType === "unreverse") {
      if (this.state.reversed) {
        const { savedPollsData, publishedPollsData } = this.state;
        this.setState({
          savedPollsData: savedPollsData.reverse(),
          publishedPollsData: publishedPollsData.reverse(),
        });
      } else {
        const { savedPollsData, publishedPollsData } = this.state;
        this.setState({
          savedPollsData,
          publishedPollsData,
        });
      }
      this.setState({ reversed: false });
    } else if (this.state.sortType === "atoz") {
      const { savedPollsData, publishedPollsData } = this.state;
      this.setState(
        {
          savedPollsData: _.sortBy(savedPollsData, (poll) =>
            poll.title.toLowerCase()
          ),
          publishedPollsData: _.sortBy(publishedPollsData, (poll) =>
            poll.title.toLowerCase()
          ),
        },
        () => {
          this.setState({ reversed: false });
        }
      );
    } else if (this.state.sortType === "ztoa") {
      const { savedPollsData, publishedPollsData } = this.state;
      this.setState(
        {
          savedPollsData: _.sortBy(savedPollsData, (poll) =>
            poll.title.toLowerCase()
          ).reverse(),
          publishedPollsData: _.sortBy(publishedPollsData, (poll) =>
            poll.title.toLowerCase()
          ).reverse(),
        },
        () => {
          this.setState({ reversed: false });
        }
      );
    }
  };

  render() {
    const {
      type,
      currentWorkspace,
      currentChannel,
      currentUser,
      title,
      options,
      deadline,
      channelUsers,
      folder,
      everyone,

      files,
      filesData,
      images,
      previewImages,

      allParticipants,
      taskType,
      contentType,
      resetAllTasks,
      setTaskType,
      setContentType,
      resetData,
      setPollOptions,
      setPollTitle,
      setTaskChannelUsers,
      setTaskEveryone,
      setTaskDeadline,
      setTaskSharing,
      setSharedTask,
      sharedTask,
      sharing,
      editingPublished,
      setTaskFolder,
      editedTask,
      editing,
      setTaskEditng,
      setEditedTask,
      setEditedPublishedTask,
      editedPublishedTask,
    } = this.props;
    let {
      shareNow,
      saveForLater,
      savedPolls,
      savedPollsData,
      editedPoll,
      publishedPolls,
      publishedPollsData,
      selected,
      view,
      error,
      selectedPoll,
      sharedAgainPoll,
      editedPublishedPoll,
    } = this.state;

    let coAdmins =
      allParticipants &&
      allParticipants.filter((participant) => participant.isAdmin);
    //console.log(coAdmins);

    const sorticon = (
      <div className="text-center text-theme-lighter">
        <FontAwesomeIcon icon={faFilter} size="sm" className="" />
        <br />
        <small>VIEW</small>
      </div>
    );

    const notify = (msg) => {
      toast(msg, { position: toast.POSITION.BOTTOM_RIGHT });
    };

    if (
      sharing &&
      Object.keys(sharedTask).length > 0 &&
      Object.keys(sharedAgainPoll).length === 0
    ) {
      this.shareTaskAgain(sharedTask);
    }
    if (
      editing &&
      Object.keys(editedTask).length > 0 &&
      Object.keys(editedPoll).length === 0
    ) {
      this.shareTaskAgain(editedTask);
    }

    if (
      editingPublished &&
      Object.keys(editedPublishedTask).length > 0 &&
      Object.keys(editedPublishedPoll).length === 0
    ) {
      this.shareTaskAgain(editedPublishedTask);
    }

    const validateData = () => {
      let filtered_previewImages = previewImages.filter(
        (previewImage) => previewImage.length > 0
      );
      let filtered_filesData = options.filter((file) => file.length > 0);
      /**/

      if (title.length == 0) {
        this.setState({
          error: "Poll title cannot be empty!\n",
          shareNow: false,
          saveForLater: false,
        });
        return null;
      }

      let filtered_options = options.filter((option) => option.length > 0);
      if (options.length != filtered_options.length) {
        this.setState({
          error: "Please add some value to option(s).\n",
          shareNow: false,
          saveForLater: false,
        });
        return null;
      }
      if (options.length < 2) {
        this.setState({
          error: "Poll must have at-least two or more options.\n",
          shareNow: false,
          saveForLater: false,
        });
        return null;
      }

      if (moment(deadline).diff(moment(Date.now()), "minutes") < 9) {
        this.setState({
          error: "Deadline of task should be atleast 10 minutes.\n",
          shareNow: false,
          saveForLater: false,
        });
        return null;
      }

      if (!everyone && channelUsers.length < 1) {
        this.setState({
          error: "Please assign task to atleast one participant.\n",
          shareNow: false,
          saveForLater: false,
        });
        return null;
      }
      if (
        filtered_previewImages.length !== 0 &&
        filtered_filesData.length != filtered_previewImages.length
      ) {
        this.setState({
          error: "Please add images to all option(s).\n",
          shareNow: false,
          saveForLater: false,
        });
        return null;
      }
      if (filtered_previewImages.length === 0) {
        return {
          type,
          title,
          options,
          channelUsers,
          deadline,
          folder,
          everyone,
        };
      }

      this.setState({ error: "" });
      return { type, title, options, channelUsers, deadline, folder, everyone };
    };

    const shareTask = () => {
      if (validateData()) {
        this.setState({ shareNow: true });
        let allImagesUploadedPromises = images.map((image, key) => {
          //console.log(typeof(image))
          if (typeof image === "object") {
            return this.uploadImage(image, key);
          } else return image;
        });
        Promise.all(allImagesUploadedPromises).then(async (images) => {
          addTask({ ...validateData(), images });
        });
      }
    };

    const saveTaskForLater = () => {
      if (validateData()) {
        this.setState({ saveForLater: true });
        let allImagesUploadedPromises = images.map((image, key) => {
          if (typeof image === "object") {
            return this.uploadImage(image, key);
          } else return image;
        });

        Promise.all(allImagesUploadedPromises).then((images) => {
          saveTask(
            { ...validateData(), images },
            currentChannel,
            currentUser
          ).then(() => {
            this.setState({ saveForLater: false });
          });
        });
      }
    };
    const saveTask = async (data) => {
      const {
        resetData,
        setPollOptions,
        setPollTitle,
        setTaskChannelUsers,
        setTaskEveryone,
        setTaskDeadline,
        setTaskFolder,
        setPollPreviewImages,
        setPollOptionsImages,
      } = this.props;
      const { editedPoll } = this.state;

      let messageData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        user: {
          id: currentUser.uid,
          name: currentUser.displayName,
          avatar: currentUser.photoURL,
        },
        type: parseInt(data["type"]),
        createdBy: currentUser.uid,
        assignedTo: data["channelUsers"],
        all: data["everyone"],
        folderId: data["folder"] ? data["folder"] : "",
      };
      console.log(messageData);
      let readData = [];
      readData[currentUser.uid] = { read: true, readtime: Date.now() };

      let taskData = {
        user_id: currentUser.uid,
        workspace_id: currentWorkspace.workspaceId,
        room_id: currentChannel.roomId,
        deadline: moment(data["deadline"]).valueOf(),
        all_participants: data["everyone"],
        assigned: data["channelUsers"],
        title: data["title"],
        options: data["options"],
        images: data["images"],
        folderId: data["folder"] ? data["folder"] : "",
        save_for_later: true,
      };
      console.log(taskData);
      if (Object.keys(editedPoll).length > 0) {
        let savedPollDoc = savedPolls.find(
          (poll) => poll.contentId === editedPoll.id
        );
        let savedPollId = savedPollDoc.id;

        try {
          let res = await axios
            .post(`${API_BASE_URL}${POLL_UPDATE}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              saved_poll_id: savedPollId,
              deadline: moment(data["deadline"]).valueOf(),
              all_participants: data["everyone"],
              assigned: data["channelUsers"],
              title: data["title"],
              options: data["options"],
              images: data["images"],
              folderId: data["folder"] ? data["folder"] : "",
              save_for_later: true,
            })
            .then(() => {
              const task = firebase.firestore().collection("poll");
              task
                .doc(savedPollId)
                .get()
                .then((doc) => {
                  console.log(doc);
                  if (doc.exists) {
                    let index = savedPolls.findIndex(
                      (poll) => poll.id === doc.id
                    );
                    console.log(index);
                    if (index !== -1) {
                      // console.log(doc.data());
                      savedPollsData.splice(index, 1, {
                        ...doc.data(),
                        id: doc.id,
                      });
                      this.setState(savedPollsData);
                    }
                  }
                });
              this.setState({ view: 0, editedPollDoc: {} }, () => {
                if (this.props.darkTheme) {
                  toast.dark("Poll updated and saved.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast("Poll updated and saved.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }
              });
              this.props.resetData();
              this.props.setPollTitle("");
              this.props.setPollOptions([]);
              this.props.setPollOptionsImages([]);
              this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
              this.props.setTaskChannelUsers([]);
              this.props.setTaskEveryone(false);
              this.props.setTaskFolder("");
            });
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let res = await axios
            .post(`${API_BASE_URL}${POLL_CREATE}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              deadline: moment(data["deadline"]).valueOf(),
              all_participants: data["everyone"],
              assigned: data["channelUsers"],
              title: data["title"],
              options: data["options"],
              images: data["images"],
              folderId: data["folder"] ? data["folder"] : "",
              save_for_later: true,
            })
            .then(() => {
              //this.props.resetData();
              this.setState({ view: 0, editedPoll: {} }, () => {
                if (this.props.darkTheme) {
                  toast.dark("Poll saved.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast("Poll saved.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }
              });
              this.props.resetData();
              this.props.setPollTitle("");
              this.props.setPollOptions([]);
              this.props.setPollOptionsImages([]);
              this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
              this.props.setTaskChannelUsers([]);
              this.props.setTaskEveryone(false);
              this.props.setTaskFolder("");
            });
        } catch (error) {
          console.log(error);
        }
      }
    };

    const addTask = async (data) => {
      const { resetData } = this.props;
      const { editedPoll, editedPublishedPoll } = this.state;

      let messageData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        user: {
          id: currentUser.uid,
          name: currentUser.displayName,
          avatar: currentUser.photoURL,
        },
        type: parseInt(data["type"]),
        createdBy: currentUser.uid,
        assignedTo: data["channelUsers"],
        all: data["everyone"],
        folderId: data["folder"] ? data["folder"] : "",
      };

      let readData = [];
      readData[currentUser.uid] = { read: true, readtime: Date.now() };

      let taskData = {
        user_id: currentUser.uid,
        workspace_id: currentWorkspace.workspaceId,
        room_id: currentChannel.roomId,
        deadline: moment(data["deadline"]).valueOf(),
        all_participants: data["everyone"],
        assigned: data["channelUsers"],
        title: data["title"],
        options: data["options"],
        folderId: data["folder"] ? data["folder"] : "",
        images: data["images"],
      };
      // console.log(taskData)
      /*API SHARE*/

      if (Object.keys(editedPoll).length > 0) {
        let savedPollDoc = savedPolls.find(
          (poll) => poll.contentId === editedPoll.id
        );
        let savedPollId = savedPollDoc.id;

        try {
          let res = await axios
            .post(`${API_BASE_URL}${POLL_UPDATE}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              saved_poll_id: savedPollId,
              deadline: moment(data["deadline"]).valueOf(),
              all_participants: data["everyone"],
              assigned: data["channelUsers"],
              title: data["title"],
              options: data["options"],
              folderId: data["folder"] ? data["folder"] : "",
              images: data["images"],
            })
            .then(async () => {
              await await axios.post(`${API_BASE_URL}${POLL_SHARE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                saved_poll_id: savedPollDoc.id,
              });
            })
            .then(() => {
              if (this.props.darkTheme) {
                toast.dark("Poll updated and shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              } else {
                toast("Poll updated and shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              }

              this.props.resetData();
            });
        } catch (error) {
          console.log(error);
        }
      } else if (Object.keys(editedPublishedPoll).length > 0) {
        console.log(data, "data in edit published poll");
        console.log(
          JSON.stringify({
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            published_poll_id: this.state.publishedPollId,
            deadline: moment(data["deadline"]).valueOf(),
            all_participants: data["everyone"],
            assigned: data["channelUsers"],
            title: data["title"],
            options: data["options"],
            folderId: data["folder"] ? data["folder"] : "",
            images: data["images"] === [""] ? [] : data["images"],
          }),
          "json"
        );
        try {
          let res = await axios
            .post(`${API_BASE_URL}${POLL_EDIT}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              published_poll_id: this.state.publishedPollId,
              deadline: moment(data["deadline"]).valueOf(),
              all_participants: data["everyone"],
              assigned: data["channelUsers"],
              title: data["title"],
              options: data["options"],
              folderId: data["folder"] ? data["folder"] : "",
              images: data["images"] === [""] ? [] : data["images"],
            })
            .then(() => {
              if (this.props.darkTheme) {
                toast.dark("Poll edited and shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              } else {
                toast("Poll edited and shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              }

              this.props.resetData();
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let res = await axios
            .post(`${API_BASE_URL}${POLL_CREATE}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              deadline: moment(data["deadline"]).valueOf(),
              all_participants: data["everyone"],
              assigned: data["channelUsers"],
              title: data["title"],
              options: data["options"],
              folderId: data["folder"] ? data["folder"] : "",
              images: data["images"],
            })
            .then(() => {
              if (this.props.darkTheme) {
                toast.dark("Poll shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              } else {
                toast("Poll shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              }
              this.props.resetData();
            });
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      }
    };
    const shareSavedTask = async (pollId) => {
      const { resetData } = this.props;
      let messageData = savedPolls.find((poll) => poll.contentId === pollId);
      let pollData = savedPollsData.find((poll) => poll.id === pollId);
      if (!messageData.all && messageData.assignedTo.length === 0) {
        toast.warning(
          "Share Failed: Poll is not assigned to any participant.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        return null;
      }
      if (messageData.createdBy !== currentUser.uid) {
        toast.warning("Share Failed: Poll is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }

      if (pollData.deadline < Date.now()) {
        toast.warning(
          "Deadline has Passed,please edit Poll to change deadline.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }

      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        messageData.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants have left the group, please edit  to reassign  participants.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }

      let savedPollId = messageData.id;
      try {
        let res = await axios
          .post(`${API_BASE_URL}${POLL_SHARE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            saved_poll_id: savedPollId,
          })
          .then(() => {
            if (this.props.darkTheme) {
              toast.dark("Poll shared.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            } else {
              toast("Poll shared.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            }

            this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }
      /*  messageData.timestamp = Date.now();

      const dbMsg = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

      dbMsg
        .add({ ...messageData })
        .then((docRef) => {
          dbUpdate
            .doc(`${currentChannel.roomId}`)
            .update({
              totalMessages: firebase.firestore.FieldValue.increment(1),
              lastMessage: {
                ...messageData,
                contentId: messageData.contentId,
                uid: docRef.id,
              },
            })
            .then(() => {
              db.doc(messageData.id).delete();
              toast("Poll Shared", toast.POSITION.BOTTOM_RIGHT);
              resetData();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));*/
    };

    const editSavedTask = (pollId) => {
      const { resetData } = this.props;
      let taskData = savedPollsData.find((poll) => poll.id === pollId);
      if (taskData.createdBy !== currentUser.uid) {
        toast.warning("Edit Failed: Poll is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }
      this.props.setPollOptions(taskData.options);
      this.props.setPollTitle(taskData.title);
      //this.props.setTaskChannelUsers(taskData.assignedTo);
      if (
        (taskData.all === false &&
          taskData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        taskData.all === true
      ) {
        this.props.setTaskChannelUsers(taskData.assignedTo);
      } else {
        let assignedTo = taskData.assignedTo.filter((user) =>
          allParticipants.find(
            (User) => User.uid === user || user === currentChannel.createdBy
          )
        );
        //console.log(assignedTo);
        this.props.setTaskChannelUsers(assignedTo);
      }
      this.props.setTaskEveryone(taskData.all);
      this.props.setTaskFolder(taskData.folderId);
      this.props.setTaskDeadline(new Date(taskData.deadline));
      this.props.setPollPreviewImages(taskData.images);
      this.props.setPollOptionsImages(taskData.images);
      this.setState({ editedPoll: taskData, view: 1 });
    };
    const deleteSavedTask = async (pollId) => {
      let messageData = savedPolls.find((poll) => poll.contentId === pollId);
      if (messageData.createdBy !== currentUser.uid) {
        toast.warning("Deletion Failed: Poll is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }
      let savedPollId = messageData.id;
      try {
        let res = await axios
          .post(`${API_BASE_URL}${POLL_REMOVE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            saved_poll_id: savedPollId,
          })
          .then(() => {
            if (this.props.darkTheme) {
              toast.dark("Poll deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            } else {
              toast("Poll deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            }

            //this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }
      /*  const dbPolls = firebase.firestore().collection(`polls`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      db.doc(messageData.id)
        .delete()
        .then(() => {
          dbPolls.doc(pollId).delete();
          this.setState({ editedPoll: {} }, () => {
            notify("Poll deleted");
          });
        })
        .catch((err) => console.log(err));*/
    };
    let canSeeTaskCurrentUser = publishedPollsData.filter(
      (poll) =>
        currentChannel?.createdBy === currentUser.uid ||
        poll.all === true ||
        coAdmins.find((usr) => usr.uid === currentUser.uid) ||
        (poll.assignedTo && poll.assignedTo.includes(currentUser.uid))
    );
    return view === 1 ? (
      <>
        <div
          onClick={() => {
            resetPoll();
            setPollOptions([]);
            setTaskDeadline(new Date(Date.now() + 10 * 600000));
            setTaskChannelUsers([]);
            this.props.setEditingPublished(false);
            this.props.setPollPreviewImages([]);
            this.props.setPollOptionsImages([]);
            setPollTitle("");
            setTaskFolder();
            setTaskEveryone(false);
            setTaskSharing(false);
            this.setState({ view: 0, editedPoll: {} });
          }}
          className="mb-1 p-2 bg-card-theme text-theme"
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
          <span>
            {Object.keys(editedPoll).length > 0 ? "Update Poll" : "Create Poll"}
          </span>
        </div>
        <CreatePoll
          allParticipants={this.props.allParticipants}
          shareTask={shareTask}
          shareNow={shareNow}
          saveForLater={saveForLater}
          saveTaskForLater={saveTaskForLater}
          editedPoll={
            Object.keys(editedPoll).length > 0 ? editedPoll : sharedAgainPoll
          }
          editedPollHasResponses={this.state.editedPollHasResponses}
          editingPublished={this.props.editingPublished}
          publishedPollId={this.state.publishedPollId}
          error={error}
          pollMessageData={this.state.pollMessageData}
        />
      </>
    ) : view === 0 ? (
      <>
        <div
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
          className="m-2 rounded p-2 bg-card-theme text-theme"
        >
          <div className="d-flex-row">
            <div
              onClick={() => {
                resetPoll();
                setPollOptions([]);
                this.props.setPollPreviewImages([]);
                this.props.setPollOptionsImages([]);
                setContentType(null);
                setTaskType(null);
                resetAllTasks();
                this.props.setPollPreviewImages([]);
                this.setState({ view: 0, editedPoll: {} });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
              <span>
                {contentType === "createTask"
                  ? taskType
                    ? taskType + "s"
                    : "Create Task"
                  : ""}
              </span>
            </div>
          </div>

          <IsAdmin>
            <div className="mt-2">
              <p className="mb-2 small text-theme-lighter">
                Request opinion from participants in the room and collect
                responses in real time. Users can respond to poll easily from
                their message timeline.
              </p>
              <div className="d-flex">
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => this.addPollView(1)}
                  className="pt-0 w-50"
                >
                  <small>+ CREATE NEW POLL</small>
                </Button>

                {/* <span className="mt-2 ml-3 text-secondary pointer smaller">
                  <FontAwesomeIcon icon={faQuestionCircle} /> WATCH TUTORIAL
                </span> */}
              </div>
            </div>
          </IsAdmin>
        </div>
        <div></div>
        {/* <div className="row m-2">
          <InputGroup className="w-100">
            <Button
              size="sm"
              variant="primary"
              className="w-100"
              onClick={() => this.addPollView(1)}
            >
              + <small>Create a Poll</small>
            </Button>
          </InputGroup>
        </div> */}

        <IsAdmin>
          <div className="row m-2 border-1 rounded">
            <div
              className={
                selected === "Published"
                  ? "col-6 text-theme bg-card-theme rounded py-1 d-flex justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Published")}
              style={{ cursor: "pointer" }}
            >
              Published
            </div>

            <div
              className={
                selected === "Saved"
                  ? "col-6 text-theme bg-card-theme rounded d-flex py-1 justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Saved")}
              style={{ cursor: "pointer" }}
            >
              Saved
            </div>
          </div>
        </IsAdmin>

        {selected === "Published" ? (
          publishedPollsData.length > 0 && canSeeTaskCurrentUser.length > 0 ? (
            <>
              <div>
                <div className="d-flex justify-content-between">
                  <InputGroup className="my-1 outerPill">
                    <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                      <div className="py-2 pr-2 pl-3">
                        <FontAwesomeIcon
                          icon={faSearch}
                          size="sm"
                          color="#aeaeae"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <Form.Control
                          type="text"
                          id="participantsSearch"
                          className="border-0 px-0 bg-theme text-theme rounded"
                          onChange={(e) => {
                            this.setState(
                              { searchTerm: e.target.value },
                              () => {
                                const reg = _.escapeRegExp(
                                  this.state.searchTerm
                                );
                                // console.log(reg,"Reg")
                                const regex = new RegExp(reg, "gi");
                                this.setState({ regex: regex });
                              }
                            );
                          }}
                          placeholder={"Search"}
                          value={this.state.searchTerm}
                          style={{ margin: 0 }}
                        />
                      </div>
                      {this.state.searchTerm.length > 0 && (
                        <div
                          className="py-2 pl-2 pr-3"
                          onClick={() => this.setState({ searchTerm: "" })}
                        >
                          <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                        </div>
                      )}
                    </div>
                  </InputGroup>
                  {this.state.sortType === "unreverse" ? (
                    <Button
                      size="sm"
                      className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                      variant={this.props.darkTheme ? "dark" : "light"}
                      onClick={() => {
                        this.setState({ sortType: "reverse" }, () => {
                          this.sortPollData();
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSortAmountUpAlt}
                        color="#aeaeae"
                      />
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                      variant={this.props.darkTheme ? "dark" : "light"}
                      onClick={() => {
                        this.setState({ sortType: "unreverse" }, () => {
                          this.sortPollData();
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSortAmountDownAlt}
                        color="#aeaeae"
                      />
                    </Button>
                  )}

                  {this.state.sortType === "atoz" ? (
                    <Button
                      size="sm"
                      className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                      variant={this.props.darkTheme ? "dark" : "light"}
                      onClick={() => {
                        this.setState({ sortType: "ztoa" }, () => {
                          this.sortPollData();
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faSortAlphaUp} color="#aeaeae" />
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                      variant={this.props.darkTheme ? "dark" : "light"}
                      onClick={() => {
                        this.setState({ sortType: "atoz" }, () => {
                          this.sortPollData();
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faSortAlphaUpAlt}
                        color="#aeaeae"
                      />
                    </Button>
                  )}
                </div>
                {this.state.searchTerm && this.state.searchTerm.length > 0 ? (
                  <>
                    {publishedPollsData
                      .filter((item) => {
                        return item.title?.match(this.state.regex);
                      })
                      ?.map((poll, index) =>
                        currentChannel?.createdBy === currentUser.uid ||
                        poll.all === true ||
                        coAdmins.find((usr) => usr.uid === currentUser.uid) ||
                        (poll.assignedTo &&
                          poll.assignedTo.includes(currentUser.uid)) ? (
                          <div
                            key={index + poll.id}
                            className={
                              poll.deadline > Date.now()
                                ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
                                : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
                            }
                          >
                            <div
                              className="d-flex justify-content-between mb-1 pointer"
                              onClick={() => this.showSelectedPoll(poll.id)}
                            >
                              <span className="text-theme-lighter smaller">
                                {moment(poll.timestamp).format("LLL")}
                              </span>
                              <span
                                className={
                                  poll.deadline > Date.now()
                                    ? "text-success smaller"
                                    : "text-secondary smaller"
                                }
                              >
                                {poll.deadline > Date.now()
                                  ? "Active"
                                  : "Closed"}
                              </span>
                            </div>
                            <div className="d-flex justify-content-between">
                              <p
                                className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
                                onClick={() => this.showSelectedPoll(poll.id)}
                              >
                                {poll.title}
                              </p>
                              <IsAdmin>
                                <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                                  <Dropdown.Toggle
                                    size="sm"
                                    as={this.CustomToggle}
                                  />
                                  <Dropdown.Menu className="bg-card-theme text-theme">
                                    <Dropdown.Item
                                      className="small bg-card-theme text-theme"
                                      onClick={() => this.shareTaskAgain(poll)}
                                    >
                                      Duplicate{" "}
                                      {/* <small className="text-warning">Coming Soon</small>*/}
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      className="small bg-card-theme text-theme"
                                      onClick={() =>
                                        this.editPublishedTask(poll.messageId)
                                      }
                                    >
                                      Edit{" "}
                                      {/* <small className="text-warning">Coming Soon</small>*/}
                                    </Dropdown.Item>
                                    {poll.deadline > Date.now() ? (
                                      <Dropdown.Item
                                        className="small bg-card-theme text-theme"
                                        onClick={() =>
                                          this.closeAppResponses(poll)
                                        }
                                      >
                                        Close{" "}
                                        {/*<small className="text-warning">
                                    Coming Soon
                              </small>*/}
                                      </Dropdown.Item>
                                    ) : null}
                                    {/* <Dropdown.Item
                          className="small"
                          onClick={() => deleteSavedTask(poll.id)}
                        >
                          Delete
                      </Dropdown.Item> */}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </IsAdmin>
                            </div>
                          </div>
                        ) : null
                      )}
                  </>
                ) : (
                  <>
                    {publishedPollsData.map((poll, index) =>
                      currentChannel?.createdBy === currentUser.uid ||
                      poll.all === true ||
                      coAdmins.find((usr) => usr.uid === currentUser.uid) ||
                      (poll.assignedTo &&
                        poll.assignedTo.includes(currentUser.uid)) ? (
                        <div
                          key={index + poll.id}
                          className={
                            poll.deadline > Date.now()
                              ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
                              : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
                          }
                        >
                          <div
                            className="d-flex justify-content-between mb-1 pointer"
                            onClick={() => this.showSelectedPoll(poll.id)}
                          >
                            <span className="text-theme-lighter smaller">
                              {moment(poll.timestamp).format("LLL")}
                            </span>
                            <span
                              className={
                                poll.deadline > Date.now()
                                  ? "text-success smaller"
                                  : "text-secondary smaller"
                              }
                            >
                              {poll.deadline > Date.now() ? "Active" : "Closed"}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p
                              className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
                              onClick={() => this.showSelectedPoll(poll.id)}
                            >
                              {poll.title}
                            </p>
                            <IsAdmin>
                              <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                                <Dropdown.Toggle
                                  size="sm"
                                  as={this.CustomToggle}
                                />
                                <Dropdown.Menu className="bg-card-theme text-theme">
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() => this.shareTaskAgain(poll)}
                                  >
                                    Duplicate{" "}
                                    {/* <small className="text-warning">Coming Soon</small>*/}
                                  </Dropdown.Item>
                                  {(this.props.editingPublished &&
                                    this.props.editedPollHasResponses) ||
                                  this.props.pollMessageData
                                    ?.closedByAdmin ? null : (
                                    <Dropdown.Item
                                      className="small bg-card-theme text-theme"
                                      onClick={() =>
                                        this.editPublishedTask(poll.messageId)
                                      }
                                    >
                                      Edit{" "}
                                      {/* <small className="text-warning">Coming Soon</small>*/}
                                    </Dropdown.Item>
                                  )}
                                  {poll.deadline > Date.now() ? (
                                    <Dropdown.Item
                                      className="small bg-card-theme text-theme"
                                      onClick={() =>
                                        this.closeAppResponses(poll)
                                      }
                                    >
                                      Close{" "}
                                      {/*<small className="text-warning">
                                    Coming Soon
                              </small>*/}
                                    </Dropdown.Item>
                                  ) : null}
                                  {/* <Dropdown.Item
                          className="small"
                          onClick={() => deleteSavedTask(poll.id)}
                        >
                          Delete
                      </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </IsAdmin>
                          </div>
                        </div>
                      ) : null
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="col">
              <p className="text-theme text-center small">
                No polls published yet
              </p>
            </div>
          )
        ) : savedPolls.length > 0 ? (
          <div>
            <div className="d-flex justify-content-between">
              <InputGroup className="my-1">
                <div className="d-flex align-items-center rounded flex-grow-1  bg-theme">
                  <div className="py-2 pr-2 pl-3">
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="sm"
                      color="#aeaeae"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <Form.Control
                      type="text"
                      id="participantsSearch"
                      className="border-0 px-0 bg-theme text-theme rounded"
                      onChange={(e) => {
                        this.setState({ searchTerm: e.target.value }, () => {
                          const reg = _.escapeRegExp(this.state.searchTerm);
                          // console.log(reg,"Reg")
                          const regex = new RegExp(reg, "gi");
                          this.setState({ regex: regex });
                        });
                      }}
                      placeholder={"Search"}
                      value={this.state.searchTerm}
                      style={{ margin: 0 }}
                    />
                  </div>
                  {this.state.searchTerm.length > 0 && (
                    <div
                      className="py-2 pl-2 pr-3"
                      onClick={() => this.setState({ searchTerm: "" })}
                    >
                      <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                    </div>
                  )}
                </div>
              </InputGroup>
              {this.state.sortType === "unreverse" ? (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "reverse" }, () => {
                      this.sortPollData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAmountUpAlt} color="#aeaeae" />
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "unreverse" }, () => {
                      this.sortPollData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAmountDownAlt} color="#aeaeae" />
                </Button>
              )}

              {this.state.sortType === "atoz" ? (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "ztoa" }, () => {
                      this.sortPollData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAlphaUp} color="#aeaeae" />
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "atoz" }, () => {
                      this.sortPollData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAlphaUpAlt} color="#aeaeae" />
                </Button>
              )}
            </div>
            {this.state.searchTerm && this.state.searchTerm.length > 0 ? (
              <>
                {savedPollsData
                  .filter((item) => {
                    return item.title?.match(this.state.regex);
                  })
                  .map((poll, index) => (
                    <div
                      className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                      key={index}
                    >
                      <div className="d-flex justify-content-center">
                        <div
                          onClick={() => editSavedTask(poll.id)}
                          className="pointer"
                        >
                          <small className="text-theme-lighter smaller">
                            {moment(poll.timestamp).format("LLL")}
                          </small>
                          <p className="m-0 font-weight-bold text-theme small">
                            {poll.title}
                          </p>
                        </div>
                        <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                          <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                          <Dropdown.Menu className="bg-card-theme text-theme">
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => shareSavedTask(poll.id)}
                            >
                              Share now
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="small"
                              onClick={() => editSavedTask(poll.id)}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="small"
                              onClick={() => deleteSavedTask(poll.id)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <div>
                {savedPollsData.map((poll, index) => (
                  <div
                    className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                    key={index}
                  >
                    <div className="d-flex justify-content-center">
                      <div
                        onClick={() => editSavedTask(poll.id)}
                        className="pointer"
                      >
                        <small className="text-theme-lighter smaller">
                          {moment(poll.timestamp).format("LLL")}
                        </small>
                        <p className="m-0 font-weight-bold text-theme small">
                          {poll.title}
                        </p>
                      </div>
                      <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                        <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                        <Dropdown.Menu className="bg-card-theme text-theme">
                          <Dropdown.Item
                            className="small bg-card-theme text-theme"
                            onClick={() => shareSavedTask(poll.id)}
                          >
                            Share now
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="small"
                            onClick={() => editSavedTask(poll.id)}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="small"
                            onClick={() => deleteSavedTask(poll.id)}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="col">
            <p className="text-theme text-center small">No polls saved yet</p>
          </div>
        )}
      </>
    ) : (
      <>
        <div
          onClick={() => {
            this.setState({ view: 0, selectedPoll: {} });
          }}
          className="mb-1 p-2 bg-card-theme text-theme"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
          <span>Back</span>
        </div>
        <div className="shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme">
          <Poll contentId={selectedPoll.id} view={view} />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,

  type: state.poll.type,
  title: state.poll.title,
  options: state.poll.options,
  images: state.poll.images,
  previewImages: state.poll.previewImages,
  files: state.poll.files,
  filesData: state.poll.filesData,

  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  everyone: state.task.everyone,
  sharing: state.platform.sharing,
  editingPublished: state.platform.editingPublished,
  sharedTask: state.platform.sharedTask,
  editing: state.platform.editing,
  editedTask: state.platform.editedTask,
  editedPublishedTask: state.platform.editedPublishedTask,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, {
  setPollTitle,
  setPollOptions,
  setRightPanel,
  setContentTypeAndValue,
  setPollOptionsImages,
  setPollPreviewImages,
  setPollOptionsFiles,
  setPollOptionsFilesData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskEveryone,
  resetData,
  setTaskSharing,
  setSharedTask,
  setTaskEditing,
  setEditedTask,
  setEditedPublishedTask,
  setEditingPublished,
})(PollHomepage);
