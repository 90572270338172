import React from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import IsAdmin from "../../IsAdmin";

const AllSpecialOlympics = ({
    allSpecialOlympics,
  showSelectedSpecialOlympics, 
  CustomToggle,
  shareTaskAgain,
  closeAppResponses,
}) => {
  return (
    <>
      <div>
        <p className="rounded-lg p-2 m-2 border-1 text-theme">Special Olympics</p>
        {allSpecialOlympics.map((quiz, index) => (
          <div
            key={index + quiz.id}
            className={
              quiz.deadline > Date.now()
                ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
                : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
            }
          >
            <div
              className="d-flex justify-content-between mb-1 pointer"
              onClick={() => showSelectedSpecialOlympics(quiz.id)}
            >
              <span className="text-theme-lighter smaller">
                {moment(quiz.timestamp).format("LLL")}
              </span>
              <span
                className={
                  quiz.deadline > Date.now()
                    ? "text-success smaller"
                    : "text-secondary smaller"
                }
              >
                {quiz.deadline > Date.now() ? "Active" : "Closed"}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <p
                className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
                onClick={() => showSelectedSpecialOlympics(quiz.id)}
              >
                {quiz.specialOlympicName}
              </p>
              <IsAdmin>
                <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                  <Dropdown.Toggle size="sm" as={CustomToggle} />
                  <Dropdown.Menu className="bg-card-theme text-theme">
                    <Dropdown.Item
                      className="small bg-card-theme text-theme"
                      onClick={() => shareTaskAgain(quiz)}
                    >
                      Duplicate{" "}
                    </Dropdown.Item>
                    {quiz.deadline > Date.now() ? (
                      <Dropdown.Item
                        className="small bg-card-theme text-theme"
                       onClick={() => closeAppResponses(quiz)}
                      >
                        Close{" "}
                      </Dropdown.Item>
                    ) : null}
                  </Dropdown.Menu>
                </Dropdown>
              </IsAdmin>
            </div>
          </div>
                    ))}
      </div>
    </>
  );
};

export default AllSpecialOlympics;
