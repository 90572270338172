import React from "react";
import {
  DropdownButton,
  Button,
  Dropdown,
  Badge,
  Form,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
  FormControl,
  Accordion,
  Card,
} from "react-bootstrap";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faAngleDown, faExclamationTriangle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  setAssignmentQuestions,
  setAssignmentDescriptions,
  setAssignmentWeights,
  setAssignmentTotalWeight,
  setAssignmentGraded,
  resetAssignment,
} from "./actions";
import {
  setTaskChannelUsers,
  setTaskDeadline,
  setTaskEveryone,
  setTaskFolder,
  resetData,
} from "../../../actions/index";

import TaskCommon from "../Common/TaskCommon";
import firebase from "firebase";
import { toast } from "react-toastify";
toast.configure();

class AddAssignment extends React.Component {
  // ALL STATES
  constructor(props) {
    super(props);
    this.state = {
      errorObj: { AssignmentSubject: "", questions: [] },
      allFolders: [],
      currentActiveKey: "1",
    };
  }

  componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;
    let allFolders = [];
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/folders`
      );
    // const query = firebase.firestore().collection("folders");
    query.onSnapshot((snapshot) => {
      let changes = snapshot.docChanges();
      console.log(changes);
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.exists) {
            let data = change.doc.data();
            let json = { ...data, id: change.doc.id };
            allFolders.unshift(json);
          }
        }
      });
      this.setState({ allFolders });
    });
  }
  /*async componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;

    let savedAssignments = [];
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
      )
      .orderBy("timestamp", "desc")
      .limit(5);

    await query.onSnapshot((snapshot) => {
      console.log(snapshot.docChanges());

      let changes = snapshot.docChanges();
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.data().type === 5) {
            let data = change.doc.data();
            let json = { ...data, id: change.doc.id };
            savedAssignments.unshift(json);
          }
        } else if (change.type === "removed") {
          if (change.doc.data().type === 5) {
            savedAssignments = savedAssignments.filter(
              (savedAssignment) => savedAssignment.id !== change.doc.id
            );
          }
        } else if (change.type === "modified") {
          if (change.doc.data().type === 5) {
            let index = savedAssignments.findIndex(
              (savedAssignment) => savedAssignment.id === change.doc.id
            );
            if (index !== -1) {
              savedAssignments.splice(index, 1, {
                ...change.doc.data(),
                id: change.doc.id,
              });
            }
          }
        }
      });
      handleAssignmentsData(savedAssignments);
    });
    const handleAssignmentsData = (savedAssignments) => {
      let savedAssignmentsData = [];
      savedAssignments.forEach((savedAssignment) => {
        firebase
          .firestore()
          .collection(`assignments`)
          .doc(savedAssignment.contentId)
          .get()
          .then((doc) => {
            // console.log(doc.data());
            let data = doc.data();
            let json = { ...data, id: doc.id };
            savedAssignmentsData.push(json);
          })
          .then(() => {
            // console.log(savedAssignmentsData);
            savedAssignmentsData.sort((a, b) => b.timestamp - a.timestamp);
            this.setState({ savedAssignments, savedAssignmentsData });
          });
      });
      if (savedAssignments.length === 0) {
        this.setState({ savedAssignments });
      }
    };
  }
  handleSubAndDesc = (e) => {
    // console.log(e.target.value);
    if (e.target.name === "AssignmentSubject") {
      this.setState({ AssignmentSubject: e.target.value });
    } else {
      this.setState({ AssignmentDescription: e.target.value });
    }
  };*/
  // ADDING QUESTION + DESCRIPTION
  addQuestion = () => {
    let prev = [...this.props.questions];
    prev[prev.length] = "";
    this.props.setAssignmentQuestions(prev);
    this.setState({ currentActiveKey: prev.length });

    let prevDescriptions = [...this.props.descriptions];
    prevDescriptions[prevDescriptions.length] = "";
    this.props.setAssignmentDescriptions(prevDescriptions);

    let prevWeights = [...this.props.weights];
    prevWeights[prevWeights.length] = 0;
    this.props.setAssignmentWeights(prevWeights);
  };

  // REMOVING QUESTION
  removeQuestion = (key) => {
    let prev = [...this.props.questions];
    let { errorObj } = this.state;
    prev.splice(key, 1);
    this.props.setAssignmentQuestions(prev);

    let prevDescriptions = [...this.props.descriptions];
    prevDescriptions.splice(key, 1);
    this.props.setAssignmentDescriptions(prevDescriptions);

    let prevWeights = [...this.props.weights];
    prevWeights.splice(key, 1);
    errorObj.questions[key + 1] = "";
    this.props.setAssignmentWeights(prevWeights);
  };

  // HANDLES QUESTIONS ADDED
  handleQuestions = (e, key) => {
    let prev = [...this.props.questions];
    prev[key] = e.target.value;
    this.props.setAssignmentQuestions(prev);
  };

  // HANDLES DESCRIPTION ADDED
  handleDescriptions = (e, key) => {
    let prev = [...this.props.descriptions];
    prev[key] = e.target.value;
    this.props.setAssignmentDescriptions(prev);
  };

  handleWeights = (e, key) => {
    let prev = [...this.props.weights];
    prev[key] = parseInt(e.target.value);
    this.props.setAssignmentWeights(prev);
  };

  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));
  handleChange = (e) => {
    this.props.setTaskFolder(e.target.value);
  };
  // MAIN RENDER FUNCTION
  render() {
    const {
      questions,
      descriptions,

      weights,
      totalWeight,

      graded,
      darkTheme,
      folder,
      currentChannel,
      currentUser,
      allParticipants,
      resetData,
      AssignmentSubject,
      AssignmentDescription,
      validateData,
      addTask,
      saveTask,
      editedAssignment,
      handleSubAndDesc,
      error,
    } = this.props;
    const { errorObj, allFolders } = this.state;

    const validateDataOfField = (e) => {
      let { errorObj } = this.state;

      if (e.target.name === "questions[]") {
        if (e.target.value.trim().length < 1) {
          errorObj.questions[+e.target.id + 1] = "Question cannot be empty.";
        } else {
          errorObj.questions[+e.target.id + 1] = "";
        }
      }
      if (e.target.name === "AssignmentSubject") {
        if (e.target.value.trim().length < 1) {
          errorObj.AssignmentSubject = "Subject cannot be empty.";
        } else {
          errorObj.AssignmentSubject = "";
        }
      }
      this.setState({ errorObj });
    };

    /* const validateData = () => {
      let filtered_questions = questions.filter(
        (question) => question.length > 0
      );
      if (questions.length !== filtered_questions.length) {
        this.setState({ error: "Please add some value to question(s).\n" });
        return null;
      }
      if (questions.length === 0) {
        this.setState({
          error: "Assignment must have at-least one or more question(s).\n",
        });
        return null;
      }

      if (graded && parseInt(totalWeight) === 0) {
        this.setState({ error: "Total weight should be greater than '0'.\n" });
        return null;
      }

      let filtered_weights = weights.filter((weight) => parseInt(weight) > 0);
      if (graded && weights.length !== filtered_weights.length) {
        this.setState({ error: "Please add some value to weight(s).\n" });
        return null;
      }

      if (moment(deadline).diff(moment(Date.now()), "minutes") < 9) {
        this.setState({
          error: "Deadline of task should be atleast 10 minutes.\n",
        });
        return null;
      }

      if (!everyone && channelUsers.length < 1) {
        this.setState({
          error: "Please assign task to atleast one participant.\n",
        });
        return null;
      }
      if (AssignmentSubject.trim().length < 1) {
        this.setState({
          error: "Please add some value to Assignemnt Subject.\n",
        });
        return null;
      }

      this.setState({ error: "" });
      return {
        type,
        questions,
        descriptions,
        weights,
        totalWeight,
        channelUsers,
        deadline,
        folder,
        everyone,
      };
    };*/

    /* const saveTask = async (data) => {
      const {
        setAssignmentDescriptions,
        setAssignmentQuestions,
        setTaskDeadline,
        setTaskChannelUsers,
        resetAssignment,
        setTaskEveryone,
      } = this.props;
      const { editedAssignment } = this.state;
      console.log(editedAssignment);
      let messageData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        user: {
          id: currentUser.uid,
          name: currentUser.displayName,
          avatar: currentUser.photoURL,
        },
        type: parseInt(data["type"]),
        createdBy: currentUser.uid,
        assignedTo: data["channelUsers"],
        all: data["everyone"],
      };

      let readData = [];
      readData[currentUser.uid] = { read: true, readtime: Date.now() };

      let questionsArrayWithObjects = [];
      for (let i = 0; i < questions.length; i++) {
        let json = {
          question: questions[i],
          description: descriptions[i],
          questionId: i + 1,
          //weight: weights[i],
        };
        questionsArrayWithObjects.push(json);
      }
      let taskData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: currentUser.uid,
        folderId: data["folder"],
        totalWeight: data["totalWeight"],
        assignmentSubject: AssignmentSubject,
        assignmentDescription: AssignmentDescription,
        questions: questionsArrayWithObjects,
        deadline: moment(data["deadline"]).valueOf(),
        all: data["everyone"],
        response: [],
        assignedTo: data["channelUsers"],
      };

      //const task = firebase.firestore().collection(`assigments`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      //console.log(taskData);
      if (Object.keys(editedAssignment).length > 0) {
        let savedAssignmentDoc = savedAssignments.find(
          (assignment) => assignment.contentId === editedAssignment.id
        );
        try {
          const taskRef = await firebase
            .firestore()
            .collection(`assignments`)
            .doc(editedAssignment.id);
          console.log(taskRef);
          taskRef.update({
            ...taskData,
          });
          //console.log(taskRef);
          db.doc(savedAssignmentDoc.id)
            .update({ ...messageData, contentId: taskRef.id })
            .then((docRef) => {
              toast("Assignment updated and saved", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              this.props.resetData();
            })
            .catch((err) => console.error(err));
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const taskRef = await firebase
            .firestore()
            .collection(`assignments`)
            .doc();
          taskRef.set({
            ...taskData,
          });
          //console.log(taskRef);
          db.add({ ...messageData, contentId: taskRef.id })
            .then((docRef) => {
              toast("Assignment saved", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              this.props.resetData();
            })
            .catch((err) => console.error(err));
        } catch (error) {
          console.log(error);
        }
      }
    };

    const addTask = async (data) => {
      // console.log(data);
      const { editedAssignment } = this.state;
      let messageData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        user: {
          id: currentUser.uid,
          name: currentUser.displayName,
          avatar: currentUser.photoURL,
        },
        type: parseInt(data["type"]),
        createdBy: currentUser.uid,
        assignedTo: data["channelUsers"],
        all: data["everyone"],
      };

      let readData = [];
      readData[currentUser.uid] = { read: true, readtime: Date.now() };

      let questionsArrayWithObjects = [];
      for (let i = 0; i < questions.length; i++) {
        let json = {
          question: questions[i],
          description: descriptions[i],
          questionId: i + 1,
          //weight: weights[i],
        };
        questionsArrayWithObjects.push(json);
      }

      let taskData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: currentUser.uid,
        folderId: data["folder"],
        totalWeight: data["totalWeight"],
        assignmentSubject: AssignmentSubject,
        assignmentDescription: AssignmentDescription,
        questions: questionsArrayWithObjects,
        deadline: moment(data["deadline"]).valueOf(),
        all: data["everyone"],
        response: [],
        assignedTo: data["channelUsers"],
      };
      const dbMsg = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );

      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);
      if (Object.keys(editedAssignment).length > 0) {
        let savedAssignmentDoc = savedAssignments.find(
          (assignment) => assignment.contentId === editedAssignment.id
        );

        firebase
          .firestore()
          .collection(`assignments`)
          .doc(editedAssignment.id)
          .update({
            ...taskData,
          })
          .then(() => {
            dbMsg
              .add({ ...messageData, contentId: editedAssignment.id })
              .then((docRef) => {
                dbUpdate
                  .doc(`${currentChannel.roomId}`)
                  .update({
                    totalMessages: firebase.firestore.FieldValue.increment(1),
                    lastMessage: {
                      ...messageData,
                      contentId: editedAssignment.id,
                      uid: docRef.id,
                    },
                  })
                  .then(() => {
                    toast("Assignment shared", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    db.doc(savedAssignmentDoc.id).delete();
                    this.props.resetData();
                  });
              })
              .catch((err) => console.error(err));
          });
      } else {
        try {
          const taskRef = await firebase
            .firestore()
            .collection(`assignments`)
            .doc();
          taskRef.set({
            ...taskData,
          });
          //console.log(taskRef);
          dbMsg
            .add({ ...messageData, contentId: taskRef.id })
            .then((docRef) => {
              dbUpdate
                .doc(`${currentChannel.roomId}`)
                .update({
                  totalMessages: firebase.firestore.FieldValue.increment(1),
                  lastMessage: {
                    ...messageData,
                    contentId: taskRef.id,
                    uid: docRef.id,
                  },
                })
                .then(() => {
                  toast("Assignment shared", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  this.props.resetData();
                });
            })
            .catch((err) => console.error(err));
        } catch (error) {
          console.log(error);
        }
      }
    };

    const shareSavedTask = (assignmentId) => {
      const { resetData } = this.props;
      let messageData = savedAssignments.find(
        (assignment) => assignment.contentId === assignmentId
      );
      let assignmentData = savedAssignmentsData.find(
        (assignment) => assignment.id === assignmentId
      );

      if (assignmentData.deadline < Date.now()) {
        toast.warning("Deadline is over, please update to share.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }
      //console.log(messageData);
      //console.log(allParticipants);
      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find((User) => User.uid === user)
          )) ||
        messageData.all === true
      ) {
        console.log(messageData);
      } else {
        toast.warning(
          "Looks like some participants are no longer part of this group, please edit to reassign participants.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }

      messageData.timestamp = Date.now();
      const dbMsg = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

      dbMsg
        .add({ ...messageData })
        .then((docRef) => {
          dbUpdate
            .doc(`${currentChannel.roomId}`)
            .update({
              totalMessages: firebase.firestore.FieldValue.increment(1),
              lastMessage: {
                ...messageData,
                contentId: messageData.contentId,
                uid: docRef.id,
              },
            })
            .then(() => {
              toast("Assignment shared", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              resetData();
              db.doc(messageData.id).delete();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    };

    const editSavedTask = (assignmentId) => {
      const { resetData } = this.props;
      let taskData = savedAssignmentsData.find(
        (assignment) => assignment.id === assignmentId
      );
      // console.log(taskData);
      let questions = [];
      let descriptions = [];
      for (let i = 0; i < taskData.questions.length; i++) {
        questions.push(taskData.questions[i].question);
        descriptions.push(taskData.questions[i].description);
      }

      if (
        (taskData.all === false &&
          taskData.assignedTo.every((user) =>
            allParticipants.find((User) => User.uid === user)
          )) ||
        taskData.all === true
      ) {
        this.props.setTaskChannelUsers(taskData.assignedTo);
      } else {
        let assignedTo = taskData.assignedTo.filter((user) =>
          allParticipants.find((User) => User.uid === user)
        );
        //console.log(assignedTo);
        this.props.setTaskChannelUsers(assignedTo);
      }

      this.props.setTaskEveryone(taskData.all);
      this.props.setTaskDeadline(new Date(taskData.deadline));
      this.props.setAssignmentQuestions(questions);
      this.props.setAssignmentDescriptions(descriptions);
      this.setState({
        editedAssignment: taskData,
        AssignmentSubject: taskData.assignmentSubject,
        AssignmentDescription: taskData.assignmentDescription,
      });
    };
    const deleteSavedTask = (assignmentId) => {
      let messageData = savedAssignments.find(
        (assignment) => assignment.contentId === assignmentId
      );
      let messageId = messageData.id;
      const dbPolls = firebase.firestore().collection(`assignments`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      db.doc(messageId)
        .delete()
        .then(() => {
          dbPolls.doc(assignmentId).delete();
          this.props.setAssignmentDescriptions([]);
          this.props.setAssignmentQuestions([]);
          this.props.setTaskChannelUsers([]);
          this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
          this.props.setTaskEveryone(false);
          this.setState(
            {
              editedAssignment: {},
              AssignmentSubject: "",
              AssignmentDescription: "",
            },
            () =>
              toast("Assigment deleted", {
                position: toast.POSITION.BOTTOM_RIGHT,
              })
          );
        })
        .catch((err) => console.log(err));
    };
    */

    let classesCss =
      "mb-0 flex-grow-1  smaller font-weight-bold text-uppercase";
    return (
      <React.Fragment>
        <div className="taskBox text-theme">
          <Form className="feedback">
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
              <p className="mb-2 font-weight-bold text-theme small">
                Assignment
              </p>
              <div className="mb-3">
                <InputGroup className="mb-1">
                  <FormControl
                    placeholder="Assignment Subject"
                    aria-label="Assignment Subject"
                    aria-describedby={"Subject"}
                    name="AssignmentSubject"
                    autoFocus={true}
                    onKeyUp={(e) => validateDataOfField(e)}
                    onBlur={(e) => validateDataOfField(e)}
                    value={AssignmentSubject}
                    onChange={(e) => handleSubAndDesc(e)}
                    required={true}
                  />
                </InputGroup>

                {errorObj.AssignmentSubject ? (
                  <p className="mb-1 small text-danger">
                    {errorObj.AssignmentSubject}
                  </p>
                ) : (
                  ""
                )}

                <InputGroup className="mb-1">
                  <FormControl
                    placeholder="Assignment Description"
                    aria-label="Assignment Description"
                    aria-describedby={"Description"}
                    name="AssignmentDescription"
                    value={AssignmentDescription}
                    onChange={(e) => handleSubAndDesc(e)}
                    required={true}
                  />
                </InputGroup>
              </div>
              <Accordion activeKey={`${this.state.currentActiveKey}`} defaultActiveKey="1">
                {questions.length > 0
                  ? questions.map((question, key) => (
                    <>
                    <Card className="bg-theme my-1">
                    <div className="d-flex ">
                    <Accordion.Toggle
                      eventKey={`${key + 1}`}
                      as={Card.Header}
                      variant="link"
                      className="flex-grow-1 p-2 pointer border-bottom-0 bg-theme"
                      onClick={() => {
                        if (this.state.currentActiveKey === key + 1)
                          this.setState({ currentActiveKey: null })
                        else
                          this.setState({ currentActiveKey: key + 1 })
                      }}>
                        <p className="mb-0 flex-grow-1 small font-weight-bold">
                          Question {key + 1}
                        </p>
                        <div>
                          {this.props.questions.map((question, index) => (
                              <>
                                {(index === key) ? (
                                <div>
                                  {question}
                                </div>): null}
                              </>
                            ))}
                          </div>
                          {graded ? (
                          <InputGroup className="mb-2">
                            <FormControl
                              placeholder="Weight"
                              name="weights[]"
                              value={weights[key]}
                              id={key}
                              type="number"
                              min="0"
                              onChange={(e) => this.handleWeights(e, key)}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text> points</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        ) : ("")}
                        {errorObj.questions[key + 1] ? (
                          <p className="small text-danger">
                            {errorObj.questions[key + 1]}
                          </p>
                          ) : (
                          ""
                        )}
                      </Accordion.Toggle>
                      <div
                        className="mt-1 mr-1 pointer"
                        //style={{verticalAlign:"top", height: "1.5rem", width: "1.5rem", backgroundColor: "rgba(255,255,255,0.2)" }}
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            onClick={() => this.removeQuestion(key)}
                            title="Delete"
                          />
                      </div>
                    </div>
                    
                    <>
                      <Accordion.Collapse eventKey={`${key + 1}`}>
                        <Card.Body className="pt-1 px-2">
                          <InputGroup className="mb-1">
                            <FormControl
                              placeholder="Question"
                              aria-label="Question"
                              aria-describedby={"question" + key}
                              as="textarea"
                              name="questions[]"
                              autoFocus={questions.length === key + 1}
                              onKeyUp={(e) => validateDataOfField(e)}
                              onBlur={(e) => validateDataOfField(e)}
                              value={questions[key]}
                              id={key}
                              onChange={(e) => this.handleQuestions(e, key)}
                              required={true}
                            />
                          </InputGroup>

                          {errorObj.questions[key + 1] ? (
                            <p className="mb-1 small text-danger">
                              {errorObj.questions[key + 1]}
                            </p>
                          ) : (
                            ""
                          )}
                          <InputGroup className="mb-1">
                            <FormControl
                              placeholder="Description (optional)"
                              aria-label="Description"
                              aria-describedby={"Description" + key}
                              as="textarea"
                              name="descriptions[]"
                              value={descriptions[key]}
                              id={key}
                              onChange={(e) => this.handleDescriptions(e, key)}
                              required={true}
                            />
                          </InputGroup>
                          {graded ? (
                          <InputGroup className="mb-3">
                            <FormControl
                              placeholder="Weight"
                              name="weights[]"
                              value={weights[key]}
                              id={key}
                              type="number"
                              min="0"
                              onChange={(e) => this.handleWeights(e, key)}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text> points</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        ) : (
                          ""
                        )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </>


                        
                        
                      
                    </Card>
                    </>))
                  : null}
              </Accordion>

              <InputGroup className="w-100 mt-3">
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="w-100"
                  onClick={this.addQuestion}
                >
                  + <small>Add Question</small>
                </Button>
              </InputGroup>
              <p className="mb-2 font-weight-bold text-theme small">
                Associate folder
              </p>
              <InputGroup className="mb-3">
                <select
                  name="folder"
                  class="custom-select custom-select-sm"
                  onChange={this.handleChange}
                >
                  <option
                    selected={folder === "" || folder === null ? true : false}
                    value=""
                  >
                    Uncategorized
                  </option>
                  {allFolders.map((item) => (
                    <option
                      value={item.id}
                      selected={folder === item.id ? true : false}
                    >
                      {item.folderName}
                    </option>
                  ))}
                </select>
              </InputGroup>
            </div>

            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
              <p className="mb-2 font-weight-bold text-theme small">
                Gradebook
              </p>
              <Form.Group className="mb-1">
                <Form.Check
                  required
                  label="Add to Gradebook"
                  value="graded"
                  checked={graded}
                  onChange={() => this.props.setAssignmentGraded(!graded)}
                />
              </Form.Group>

              {graded && (
                <>
                  <p className="pb-0 mb-0 small">Total weight</p>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Weight"
                      name="totalWeight"
                      type="number"
                      min="0"
                      onChange={(e) =>
                        this.props.setAssignmentTotalWeight(
                          parseInt(e.target.value)
                        )
                      }
                      value={totalWeight}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>Total points</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </>
              )}
            </div>
            <TaskCommon
              allParticipants={allParticipants}
              editedTask={
                Object.keys(editedAssignment).length > 0 ? true : false
              }
            />

            <div className="my-2">
              {error ? <p className="small text-danger">{error}</p> : ""}
            </div>

            <div className="m-2">
              {
                this.props.editingPublished && !this.props.editedAssignmentHasResponses ?
                  <Button
                    onClick={() =>
                      validateData() &&
                      addTask(validateData(), currentChannel, currentUser)
                    }
                    variant="primary"
                    style={{ width: "100px", marginRight: "5px" }}
                    size="sm"
                    disabled={this.props.assignmentMessageData?.closedByAdmin}
                  >
                    <small>
                    {
                        this.props.assignmentMessageData?.closedByAdmin ?
                         <FontAwesomeIcon icon={faExclamationTriangle} color="yellow"></FontAwesomeIcon>
                        : null
                      }
                      Save changes</small>
                  </Button>
                  :
                  <Button
                    onClick={() =>
                      validateData() &&
                      addTask(validateData(), currentChannel, currentUser)
                    }
                    variant="primary"
                    style={{ width: "100px", marginRight: "5px" }}
                    size="sm"
                    disabled={this.props.editingPublished && this.props.editedAssignmentHasResponses }
                  >
                    <small>
                      {
                        this.props.editingPublished && this.props.editedAssignmentHasResponses  ?
                         <FontAwesomeIcon icon={faExclamationTriangle} color="yellow"></FontAwesomeIcon>
                        : null
                      }
                      Share now

                      </small>
                  </Button>
              }


              <Button
                onClick={() =>
                  validateData() &&
                  saveTask(validateData(), currentChannel, currentUser)
                }
                variant="success"
                style={{ width: "100px", marginLeft: "5px" }}
                size="sm"
              >
                <small>Save for later</small>
              </Button>

              {
                this.props.editingPublished && this.props.editedAssignmentHasResponses  || this.props.assignmentMessageData?.closedByAdmin ?
                  <div className="text-warning mt-2">It can't be edited as it already has responses. Either close it or delete it.</div>
                  :
                  null
              }


            </div>
            
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,

  type: state.assignment.type,
  questions: state.assignment.questions,
  descriptions: state.assignment.descriptions,
  weights: state.assignment.weights,
  totalWeight: state.assignment.totalWeight,
  graded: state.assignment.graded,

  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  everyone: state.task.everyone,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, {
  setAssignmentQuestions,
  setAssignmentDescriptions,
  setAssignmentWeights,
  setAssignmentTotalWeight,
  setAssignmentGraded,

  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskEveryone,

  //addTask,
  //saveTask,
  resetData,
})(AddAssignment);
