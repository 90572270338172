import * as actionTypes from './types';
import firebase from 'firebase';

/* Assignment Actions */
export const setAssignmentId = value => {
  return {
    type: actionTypes.ADD_ASSIGNMENT_ID,
    payload: {
      id: value
    }
  }
}

export const setAssignmentQuestions = value => {
  return {
    type: actionTypes.ADD_ASSIGNMENT_QUESTIONS,
    payload: {
      questions: value
    }
  }
}

export const setAssignmentDescriptions = value => {
  return {
    type: actionTypes.ADD_ASSIGNMENT_DESCRIPTIONS,
    payload: {
      descriptions: value
    }
  }
}

export const setAssignmentWeights = value => {
  return {
    type: actionTypes.ADD_ASSIGNMENT_WEIGHTS,
    payload: {
      weights: value
    }
  }
}

export const setAssignmentTotalWeight = value => {
  return {
    type: actionTypes.ADD_ASSIGNMENT_TOTAL_WEIGHT,
    payload: {
      totalWeight: value
    }
  }
}

export const setAssignmentGraded = value => {
  return {
    type: actionTypes.SET_ASSIGNMENT_GRADED,
    payload: {
      graded: value
    }
  }
}

export const resetAssignment = () => {
  return {
    type: actionTypes.RESET_ASSIGNMENT,
    payload: {}
  }
}

export const publishRemark = (task_id, user_id, content, channel_id, admin_id) => {
  return function (dispatch) {
    let updates = {};
    updates["remark"] = {
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      content,
      status: true,
      admin: admin_id
    }
    // firebase.database().ref(`messages/${channel_id}`).child(`${task_id}/response/${user_id}`).update(updates);
    firebase.database().ref(`gradebook/${channel_id}`).child(`${task_id}/${user_id}`).update(updates);
  }
}

export const publishMarks = (task_id, user_id, marks, channel_id, admin_id) => {
  return function (dispatch) {
    let updates = {};
    updates["grade"] = {
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      marks,
      status: true,
      admin: admin_id
    }
    // firebase.database().ref(`messages/${channel_id}`).child(`${task_id}/response/${user_id}`).update(updates);
    firebase.database().ref(`gradebook/${channel_id}`).child(`${task_id}/${user_id}`).update(updates);
  }
}