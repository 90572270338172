import React from 'react';
import { Dropdown, Button, DropdownButton, Badge, Form, ButtonGroup, ButtonToolbar, InputGroup, FormControl } from "react-bootstrap";
import DateTimePicker from 'react-datetime-picker'
import firebase from "firebase";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import TextareaAutosize from 'react-textarea-autosize';
import { connect } from 'react-redux';
import {
    setScheduleId,
    setScheduleTitle,
    setScheduleDurationHours,
    setScheduleDurationMinutes,
    setSchedulePlace,
    setScheduleAgenda,

    setTaskDeadline,
    setTaskChannelUsers,
    setTaskFolder,
    setTaskEveryone,
    setTaskOldFolder,
    editTask,
    editSaveTask,
    publishSaveTask
} from '../../../actions/index';

class EditSchedule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addAll: false,
            error: ''
        }
    };

    componentDidMount() {
        const {
            setScheduleId,
            setScheduleTitle,
            setScheduleDurationHours,
            setScheduleDurationMinutes,
            setSchedulePlace,
            setScheduleAgenda,

            setTaskDeadline,
            setTaskChannelUsers,
            setTaskFolder,
            setTaskEveryone,
            setTaskOldFolder
        } = this.props;

        setScheduleId(this.props.taskId)
        setScheduleTitle(this.props.data.schedule)
        setScheduleDurationHours(this.props.data.duration_hours)
        setScheduleDurationMinutes(this.props.data.duration_minutes)
        setSchedulePlace(this.props.data.place)
        setScheduleAgenda(this.props.data.agenda)

        setTaskDeadline(new Date(this.props.data.deadline))
        setTaskChannelUsers(Object.keys(this.props.data.users))
        setTaskFolder(this.props.data.folder)
        setTaskEveryone(this.props.data.everyone)
        setTaskOldFolder(this.props.data.folder)
    }

    handleChange = e => {
        const { currentChannel, currentUser } = this.props;

        if (e.target.name === "schedule") {
            this.props.setScheduleTitle(e.target.value)
        }

        if (e.target.name === "folder") {
            this.props.setTaskFolder(e.target.value)
        }

        if (e.target.name === "duration_hours") {
            this.props.setScheduleDurationHours(e.target.value)
        }

        if (e.target.name === "duration_minutes") {
            this.props.setScheduleDurationMinutes(e.target.value)
        }

        if (e.target.name === "place") {
            this.props.setSchedulePlace(e.target.value)
        }

        if (e.target.name === "agenda") {
            this.props.setScheduleAgenda(e.target.value)
        }
        
        if (e.target.name === "channelUsers[]") {
            let prev = this.props.channelUsers.includes(e.target.value) ? this.props.channelUsers.filter(a => a != e.target.value) : [...this.props.channelUsers, e.target.value];
            if (Object.keys(currentChannel.users).filter(item => item != currentUser.uid).length === prev.length) {
                this.setState({ addAll: true })
            } else {
                this.setState({ addAll: false })
            }
            this.props.setTaskChannelUsers(prev);
        }
    }
    onChange = deadline => this.props.setTaskDeadline(deadline)
    
    getName = (key) => {
        let name = "";
        firebase.database().ref("users").child(key).on("value", snap => {
            if (snap.val()) {
                name = snap.val().name ? snap.val().name : `${snap.val().firstName} ${snap.val().lastName}`;
            }
        });
        return name;
    }

    getFolderName = (key) => {
        let folder_name = "";
        firebase.database().ref("folders").child(key).on("value", snap => {
            folder_name = snap.val() && snap.val().folder_name;
        });
        return folder_name;
    }

    selectAll = () => {
        const { currentChannel, currentUser } = this.props;

        if (this.state.addAll) {
            this.setState({addAll: false})

        } else {
            this.setState({addAll: true})
            this.props.setTaskChannelUsers(Object.keys(currentChannel.users).filter(item => item != currentUser.uid))
        }
    }

    render() {
        const { type, schedule, options, duration_hours, duration_minutes, place, agenda, channelUsers, deadline, folder, old_folder, everyone, editTask, uid, currentChannel, currentUser, data, taskBranch } = this.props;
        
        const validateData = () => {
            let user = data.user;
            let timestamp = data.timestamp;
            let response = data.response ? data.response : null;

            if (schedule.length == 0) {
                this.setState({error: "Schedule title cannot be empty!\n"});
                return null;
            }
            
            if ( moment(deadline).diff( moment(Date.now()), 'minutes') < 9 ) {
                this.setState({error: "Deadline of task should be atleast 10 minutes.\n"});
                return null;
            }

            if (!everyone && channelUsers.length < 2) {
                this.setState({error: "Please assign task to atleast one participant.\n"});
                return null;
            }

            this.setState({error: ""});
            return {type, schedule, options, duration_hours, duration_minutes, place, agenda, channelUsers, deadline, user, timestamp, uid, response, folder, everyone};
        }

        return (
            <React.Fragment>
                <h5 className="p-0 m-0">Schedule</h5>
                <div className="taskBox">
                    <Form className="feedback">
                        
                        <p className="mb-1">Schedule title</p>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Add title"
                                name="schedule"
                                onChange={this.handleChange}
                                value={schedule}
                                required={true}
                            />
                        </InputGroup>
                        
                        <p className="mb-1">Date &amp; Time</p>
                        <DateTimePicker
                            className="mb-3 d-block"
                            onChange={this.onChange}
                            value={deadline}
                        />

                        <p className="mb-1">Associate folder</p>
                        <InputGroup className="mb-3">
                            <select name="folder" class="custom-select custom-select-sm" onChange={this.handleChange}>
                            <option selected={(folder === "" || folder === null) ? true : false} value="">Uncategorized</option>
                                {currentChannel && currentChannel.folders && currentChannel.folders.map(item => <option value={item} selected={folder === item ? true : false}>{this.getFolderName(item)}</option>)}
                            </select>
                        </InputGroup>

                        <p className="mb-1">Duration (Optional)</p>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Hours & Minutes</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                type="number" min="0" max="23" step="1"
                                value={duration_hours}
                                name="duration_hours"
                                onChange={this.handleChange}
                                required={true}
                            />
                            <FormControl
                                type="number" min="0" max="60" step="5"
                                value={duration_minutes}
                                name="duration_minutes"
                                onChange={this.handleChange}
                                required={true}
                            />
                        </InputGroup>

                        <p className="mb-1">Place (Optional)</p>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Place (Optional)"
                                name="place"
                                value={place}
                                onChange={this.handleChange}
                                required={true}
                                size="sm"
                            />
                        </InputGroup>

                        <p className="mb-1">Agenda (Optional)</p>
                        <InputGroup className="mb-3">
                            <TextareaAutosize
                                className="w-100 p-2 rounded"
                                style={{borderColor: '#CDCDCD', minWidth: '300px'}}
                                name="agenda"
                                value={agenda}
                                onChange={this.handleChange}
                                minRows={3}
                                maxRows={20}
                                placeholder="Agenda"
                            />
                        </InputGroup>

                        <Form.Group controlId="formBasicCheckbox">
                            <div>
                                <p className="mb-1">Assign users</p>
                                <Form.Check
                                    required
                                    label="Everyone who joins"
                                    value="everyone"
                                    checked={everyone}
                                    onChange={() => this.props.setTaskEveryone(!everyone)}
                                    />
                                {!everyone && <Form.Check style={{ fontSize: '0.6rem' }}
                                    type="switch" 
                                    id="addAll"
                                    value="addAll"
                                    name="addAll"
                                    onChange={this.selectAll}
                                    label="Select all"
                                    checked={this.state.addAll}
                                />}
                            </div>
                            {!everyone && <>{currentChannel.users && Object.keys(currentChannel.users).length > 0
                                ? Object.keys(currentChannel.users)
                                    .map(item => item !== currentUser.uid
                                        && <Form.Check style={{ fontSize: '0.6rem' }}
                                            type="switch" 
                                            id={item} 
                                            value={item}
                                            name="channelUsers[]"
                                            checked={channelUsers.includes(item)}
                                            onChange={this.handleChange}
                                            label={this.getName(item)}
                                        />
                                    )
                                : null}
                            </>}
                        </Form.Group>
                        
                        <div className="d-flex">
                            {taskBranch === "saved"
                                ? <>
                                    <Button 
                                    onClick={() => validateData() && this.props.publishSaveTask(validateData(), uid, old_folder, currentChannel, currentUser)} 
                                    variant="primary" 
                                    style={{width: "100px", marginRight: "5px"}}
                                    size="sm" ><small>Share now</small></Button>

                                    <Button 
                                    onClick={() => validateData() && this.props.editSaveTask(validateData(), uid, old_folder, currentChannel, currentUser)} 
                                    variant="success" 
                                    style={{width: "100px", marginLeft: "5px"}}
                                    size="sm" ><small>Save for later</small></Button>
                                </>
                                :
                                <Button 
                                onClick={() => validateData() && editTask(validateData(), uid, old_folder, currentChannel, currentUser)} 
                                variant="primary" 
                                style={{width: "100px", marginRight: "5px"}}
                                size="sm" ><small>Share now</small></Button>}
                        </div>
                        <p className="my-2 p-0 text-danger">{this.state.error}</p>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentChannel: state.channel.currentChannel,
    currentUser: state.user.currentUser,

    taskId: state.platform.taskId,
    taskBranch: state.platform.taskBranch,

    deadline: state.task.deadline,
    channelUsers: state.task.channelUsers,
    folder: state.task.folder,
    old_folder: state.task.old_folder,
    everyone: state.task.everyone,

    uid: state.schedule.id,
    schedule: state.schedule.schedule,
    options: state.schedule.options,
    duration_hours: state.schedule.duration_hours,
    duration_minutes: state.schedule.duration_minutes,
    place: state.schedule.place,
    agenda: state.schedule.agenda,
    type: state.schedule.type
})

export default connect(
    mapStateToProps, {
        setScheduleId,
        setScheduleTitle,
        setScheduleDurationHours,
        setScheduleDurationMinutes,
        setSchedulePlace,
        setScheduleAgenda,
    
        setTaskDeadline,
        setTaskChannelUsers,
        setTaskFolder,
        setTaskEveryone,
        setTaskOldFolder,
        editTask,
        editSaveTask,
        publishSaveTask
    })(EditSchedule);