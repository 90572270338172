import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { connect } from "react-redux";
import { setShowPRF } from "../../../actions";
import PatientDetailsForm from "./PatientDetails/PatientDetailsForm";

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from "firebase";

const PatientRegistrationModal = ({
  setShowPRF,
  showPRF,
  currentWorkspace,
  currentUser,
  currentChannel,
}) => {
  const [PatientDetails, setPatientDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const ageCalculator = (dateOfBirth) => {
    console.log(dateOfBirth);
    let d1 = new Date(dateOfBirth);
    let d2 = new Date();
    var diff = d2.getTime() - d1.getTime();
    let age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    console.log(age);
    return age;
  };

  const handleClose = () => {
    setShowPRF(false);
  };
  useEffect(() => {
    firebase
      .firestore()
      .collection("caseSheet")
      .doc(currentWorkspace.workspaceId)
      .collection("rooms")
      .doc(currentChannel.roomId)
      .collection("caseSheet")
      .doc("caseSheetId")
      .collection("data")
      .doc("patientData")
      .onSnapshot((doc) => {
        if (doc.exists) {
          setPatientDetails({ ...doc.data() });
        } else {
          setPatientDetails({});
        }
        setIsLoading(false);
      });
  }, []);
  return (
    <Modal
      show={showPRF}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      scrollable
      className="text-theme"
      size="lg"
    >
      <Modal.Header closeButton className="bg-card-theme text-theme">
        <Modal.Title>Patient registraion form</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-theme">
        {isLoading ? (
          <div className="my-5 text-center">
            <FontAwesomeIcon
              icon={faCircleNotch}
              className="fa-spin fa-2x text-success"
            ></FontAwesomeIcon>{" "}
          </div>
        ) : PatientDetails.name ? (
          <div className="p-2 py-4" >
            <div className="my-3 lead text-center" >Patient details already submitted!</div>
            <div className="font-13">
          <div className="text-center my-1">
            <b>Name:</b> {PatientDetails.name}
          </div>{" "}
          <div className="text-center my-1">
            <b>Age:</b> {ageCalculator(PatientDetails.dateOfBirth)}
          </div>
          <div className="text-center my-1">
            <b>Gender:</b> {PatientDetails.gender}
          </div>
          </div>
          </div>
        ) : (
          <PatientDetailsForm />
        )}
      </Modal.Body>
   
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
  showPRF: state.caseSheet.showPRF,
});

export default connect(mapStateToProps, { setShowPRF })(
  PatientRegistrationModal
);
