import * as actionTypes from "./types";

const initialQuizState = {
  type: 4,
  id: null,
  quizTitle: "",
  questions: [],
  quizQuestionImages: [],
  questionPreviewImages: [],
  options: [],
  imageOptions:[],
  previewImageOptions:[],
  types: [],
  correctAnswers: [],
  weights: [],
  graded: false,
  totalWeight: 0,
  randomize: false,
  randomizeQuestion:false,
};

const quiz_reducer = (state = initialQuizState, action) => {
  switch (action.type) {
    case actionTypes.ADD_QUIZ_ID:
      return {
        ...state,
        id: action.payload.id,
      };
    case actionTypes.ADD_QUIZ_TITLE:
      return {
        ...state,
        quizTitle: action.payload.quizTitle,
      };
    case actionTypes.ADD_QUIZ_QUESTIONS:
      return {
        ...state,
        questions: action.payload.questions,
      };
    case actionTypes.ADD_QUIZ_QUESTION_IMAGES:
      return {
        ...state,
        quizQuestionImages: action.payload.quizQuestionImages,
      }
    case actionTypes.ADD_QUIZ_QUESTION_PREVIEW_IMAGES:
      return {
        ...state,
        questionPreviewImages: action.payload.questionPreviewImages,
      }
    case actionTypes.ADD_QUIZ_OPTIONS:
      return {
        ...state,
        options: action.payload.options,
      };
    case actionTypes.ADD_QUIZ_OPTIONS_IMAGES:
      return{
        ...state,
        imageOptions: action.payload.imageOptions
      }
    case actionTypes.ADD_QUIZ_OPTIONS_PREVIEW:
      return{
        ...state,
        previewImageOptions: action.payload.previewImageOptions
      }
    case actionTypes.ADD_QUIZ_TYPES:
      return {
        ...state,
        types: action.payload.types,
      };
    case actionTypes.ADD_QUIZ_CORRECT_ANSWERS:
      return {
        ...state,
        correctAnswers: action.payload.correctAnswers,
      };
    case actionTypes.ADD_QUIZ_WEIGHTS:
      return {
        ...state,
        weights: action.payload.weights,
      };
    case actionTypes.SET_QUIZ_GRADED:
      return {
        ...state,
        graded: action.payload.graded,
      };
    case actionTypes.SET_QUIZ_RANDOMIZE:
      return {
        ...state,
        randomize: action.payload.randomize,
      };
      case actionTypes.SET_QUIZ_RANDOMIZE_QUESTION:
        return {
          ...state,
          randomizeQuestion: action.payload.randomizeQuestion,
        };
    case actionTypes.ADD_QUIZ_TOTAL_WEIGHT:
      return {
        ...state,
        totalWeight: action.payload.totalWeight,
      };
    /*case actionTypes.SET_SHARING:
      return {
        ...state,
        sharing: action.payload.sharing,
      };*/
    case actionTypes.RESET_QUIZ:
      return initialQuizState;
    default:
      return state;
  }
};

export default quiz_reducer;
