import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    setFolder,
    openFolder,
    setContentType
} from "../../../actions/index";
import firebase from "firebase";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-regular-svg-icons';

import ReadMore from '../../Middle/ReadMore';
import IsAdmin from '../../IsAdmin';

class Folder extends Component {
    state = {
        id: this.props.folder,
        data: null,
    }

    componentDidMount() {
        const { id } = this.state;
        firebase
            .database()
            .ref("folders")
            .child(id)
            .on("value", snap =>
                this.setState({ data: snap.val() })
            )
    }

    render() {
        const { data } = this.state;
        return (
            <div className="col-md-3 col-3 text-center mb-3 px-1"
                style={{ cursor: 'pointer' }}
                onClick={() => data && this.props.handleClick_folder(data.id)}>
                <img class="mb-1" src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/folder-blue-512.png" width="35" />
                {data && <p className="m-0 small text-theme-lighter lineHeight-sm">
                    <ReadMore max_length="45" full_text={data.folder_name} />
                </p>}
            </div>
        )
    }
}

class Folders extends Component {
    constructor(props) {
        super(props);

        this.state = {
            channel: props.currentChannel,
            user: props.currentUser,
            uid: props.currentUser.uid,
            folders: props.currentChannel && props.currentChannel.folders ? props.currentChannel.folders : [],
            folder_id: ""
        }
    }

    render() {
        const { folders } = this.state;
        const {
            setFolder,
            openFolder,
            setContentType
        } = this.props;
        return (
            <>
                {folders.length > 0 &&
                    <div className="row pb-2 rounded-lg py-2 m-2 bg-card-theme shadow-sm">

                        <div class="col-md-6 "><p class="font-weight-bold text-theme">Folders</p></div>
                        
                        <div class="col-md-6 text-right">
                        {/* <IsAdmin>    <button class="btn btn-sm btn-primary smaller p-1 mr-0 font-weight-bold">+ NEW FOLDER</button></IsAdmin> */}
                        </div>
                        {folders.map(folder => <Folder folder={folder} handleClick_folder={data => { setFolder(data); openFolder(true); setContentType("Folder"); }} />)}

                    </div>
                }
            </>

        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    currentChannel: state.channel.currentChannel,
    folder_id: state.platform.folder_id
});

export default connect(mapStateToProps, {
    setFolder,
    openFolder,
    setContentType
})(Folders);