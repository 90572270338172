import React, { useState, useRef } from 'react';
import firebase from 'firebase';
import { connect } from 'react-redux';
import { setTaskType, setContentType, setContentTypeAndValue } from '../../actions/index';

import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPoll, faFileAlt, faTasks, faFolderPlus } from '@fortawesome/free-solid-svg-icons';
import { faClipboard, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

import IsAdmin from '../IsAdmin';


function CreateTaskHeader({ setContentType, setContentTypeAndValue, darkTheme }) {

    return (
        <>
            <div className='header'>
                <div className='leftelements d-flex justify-content-between text-theme' >
                    <img 
                        src={darkTheme ? "https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-reverse-sm.png?alt=media&token=7981f7ad-29f8-42f9-988d-c68308ec8888" : "https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-positive-sm.png?alt=media&token=c7b1d5b9-c7b1-4e6d-a771-3d88321d20f6"} 
                        height="20"
                        className="mt-2 ml-2" />
                    <IsAdmin>
                        {/* <Button onClick={() => { setContentType("Folder") }} variant="outline-dark" size="sm" className="mr-2 my-2 folder"><FontAwesomeIcon size="lg" icon={faFolderPlus} /></Button>
                        <Button onClick={() => { setContentTypeAndValue("createTask", "Schedule") }} variant="outline-dark" size="sm" className="mr-2 my-2 schedule"><FontAwesomeIcon size="lg" icon={faCalendarAlt} /></Button>
                        <Button onClick={() => { setContentTypeAndValue("createTask", "Reading") }} variant="outline-dark" size="sm" className="mr-2 my-2 reading"><FontAwesomeIcon size="lg" icon={faClipboard} /> </Button>
                        <Button onClick={() => { setContentTypeAndValue("createTask", "Poll") }} variant="outline-dark" size="sm" className="mr-2 my-2 poll"><FontAwesomeIcon size="lg" icon={faPoll} /> </Button>
                        <Button onClick={() => { setContentTypeAndValue("createTask", "Quiz") }} variant="outline-dark" size="sm" className="mr-2 my-2 quiz"><FontAwesomeIcon size="lg" icon={faTasks} /></Button> 
                        <Button onClick={() => { setContentTypeAndValue("createTask", "Assignment") }} variant="outline-dark" size="sm" className="mr-0 my-2 assignment"><FontAwesomeIcon size="lg" icon={faFileAlt} /> </Button> */}
                        {/* <DropdownButton className="mr-0 my-2 assignment" id="dropdown-basic-button" title="...">
                            <Dropdown.Item onClick={() => { setContentTypeAndValue("createTask", "Quiz") }}>Quiz</Dropdown.Item>
                            <Dropdown.Item onClick={() => { setContentTypeAndValue("createTask", "Assignment") }}>Assignment</Dropdown.Item>
                        </DropdownButton> */}
                    </IsAdmin>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = state => ({
    taskType: state.platform.taskType,
    darkTheme: state.platform.darkTheme
})

export default connect(mapStateToProps, { setTaskType, setContentType, setContentTypeAndValue })(CreateTaskHeader);