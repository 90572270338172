import React, { useEffect, useState } from 'react'
import { CURRENT_VERSION } from '../../config/index'
import firebase from 'firebase'
import * as DeviceDetect from "react-device-detect"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo, faWrench, faAngleRight } from '@fortawesome/free-solid-svg-icons'

const device = DeviceDetect.isElectron ? "electron" : "web"

function VersionUpdates() {
    
    useEffect(() => {

        firebase.firestore().collection("versions").doc(`${device}`).onSnapshot(doc => {
            if (doc.exists) {
                console.log("VERSION DATA: ",doc.data())
                setLatestVersion(doc.data().version)
                if (device === "electron") {
                    setLink(doc.data().link)
                }
            }
        })
    }, [])

    const [latestVersion, setLatestVersion] = useState("")
    const [link, setLink] = useState("")

    if (CURRENT_VERSION === latestVersion) {
        return null;
        
    } else return (
        <li onClick={() => window.location.reload(true)}>
            {device === "electron" 
            && <div className="d-flex align-items-center justify-content-center p-3">
                <FontAwesomeIcon icon={faWrench} className="text-theme m-1 small" />
                <p className="text-theme text-center mb-0">For latest version v{latestVersion}, <a href={link} target="_blank" className="">click here to download</a></p>
            </div>}
            {device === "web" 
            && <div className="d-flex align-items-center justify-content-center p-3">
                <div className="bg-theme-lightest rounded rounded-circle d-flex justify-content-center align-items-center" 
                style={{ height: "2.5rem", width: "2.5rem", borderRadius: "1.25rem"}}>
                    <FontAwesomeIcon icon={faRedo} className="text-theme" />
                </div>
                <div className="ml-3">
                    <p className="text-theme mb-0">Update available</p>
                    <p className="text-theme text-center mb-0 small">Click to update Igesia <FontAwesomeIcon icon={faAngleRight} /></p>
                </div>
            </div>}
        </li>
    )
}

export default VersionUpdates;