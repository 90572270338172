import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import IsAdmin from "../../IsAdmin";
import { Dropdown } from "react-bootstrap";
import moment from "moment";
import Poll from "../../Tasks/Poll/Poll";
import AllPolls from "./allPolls";
import AllAssignments from "./allAssignments";
import Assignment from "../../Tasks/Assignment/Assignment1";
import AllQuizes from "./allQuizes";
import Quiz from "../../Tasks/Quiz/Quiz";
import AllSchedules from "./allSchedules";
import Schedule from "../../Tasks/Schedule/Schedule";
import AllReadings from "./allReadings";
import AllSpecialOlympics from "./allSpecialOlympics";
import Reading from "../../Tasks/Reading/Reading1";
import QuizPlus from "../../Tasks/Quiz-Plus/QuizPlus";

import {
  setSharedTask,
  setContentTypeAndValue,
  resetData,
  setRightPanel,
  setTaskSharing,
  setTaskEditing,
  setEditedTask,
  setEditingPublished,
  setEditedPublishedTask,
} from "../../../actions/index";
import { toast } from "react-toastify";
import { API_BASE_URL, QUIZ_REMOVE, QUIZ_SHARE, FOLDER_APP_LIST, APP_CLOSE, QUIZPLUS_SHARE } from "../../../config/index";
import axios from "axios";
toast.configure();
class FolderContent extends Component {
  state = {
    isLoading: true,
    allTasks: [],
    allTasksMessages: [],
    allTasksSaved: [],
    allPublishedPolls: [],
    allPublishedAssignments: [],
    allPublishedSpecialOlympics : [],
    allPublishedSchedules: [],
    allPublishedReadings: [],
    allPublishedQuizes: [],
    allSavedPolls: [],
    allSavedAssignments: [],
    allSavedSpecialOlympics : [],
    allSavedSchedules: [],
    allSavedReadings: [],
    allSavedQuizes: [],
    selected: "Published",
    selectedPoll: {},
    selectedAssignment: {},
    selectedQuiz: {},
    selectedSchedule: {},
    selectedReading: {},
    selectedSpecialOlympics: {},
  };
  componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;
    let allPublishedPolls = [];
    let allPublishedAssignments = [];
    let allPublishedSchedules = [];
    let allPublishedReadings = [];
    let allPublishedQuizes = [];

    //let allTasks = [];
    let allTasksMessages = [];
    let allTasksSaved = [];
    const queryMsg = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      )
      .orderBy("timestamp", "desc");
    const querySaved = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
      )
      .orderBy("timestamp", "desc");

    querySaved.onSnapshot((snapshot) => {

    
        axios.post(`${API_BASE_URL}${FOLDER_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          folder_id: this.props.folderId,
        }).then((res) => {
          if (res.status === 200) {

            this.handlePublishPollsData(res.data.data.publishedTasks.polls);
            this.handlePublishAssignmentsData(res.data.data.publishedTasks.assignments);
            this.handlePublishReadingsData(res.data.data.publishedTasks.readings);
            this.handlePublishQuizesData(res.data.data.publishedTasks.quizzes);
            this.handlePublishSchedulesData(res.data.data.publishedTasks.schedule);
            this.handlePublishSpecialOlympics(res.data.data.publishedTasks.specialOlympics)
            this.setState({allTasksMessages: [...res.data.data.publishedTasks.polls, 
                                              ...res.data.data.publishedTasks.assignments,
                                              ...res.data.data.publishedTasks.readings, 
                                              ...res.data.data.publishedTasks.quizzes,
                                              ...res.data.data.publishedTasks.schedule,
                                              ...res.data.data.publishedTasks.specialOlympics]})
            
            this.handlePollsData(res.data.data.savedTasks.polls);
            this.handleAssignmentsData(res.data.data.savedTasks.assignments);
            this.handleReadingsData(res.data.data.savedTasks.readings);
            this.handleQuizesData(res.data.data.savedTasks.quizzes);
            this.handleSchedulesData(res.data.data.savedTasks.schedule);
            this.handleSpecialOlympics(res.data.data.savedTasks.specialOlympics);
            this.setState({allTasksSaved: [...res.data.data.savedTasks.polls, 
              ...res.data.data.savedTasks.assignments,
              ...res.data.data.savedTasks.readings, 
              ...res.data.data.savedTasks.quizzes,
              ...res.data.data.savedTasks.schedule,
              ...res.data.data.savedTasks.specialOlympics]})
          }
        })
      })
      
      queryMsg.onSnapshot((snapshot) => {
        axios.post(`${API_BASE_URL}${FOLDER_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          folder_id: this.props.folderId,
        }).then((res) => {
          if (res.status === 200) {

            this.handlePublishPollsData(res.data.data.publishedTasks.polls);
            this.handlePublishAssignmentsData(res.data.data.publishedTasks.assignments);
            this.handlePublishReadingsData(res.data.data.publishedTasks.readings);
            this.handlePublishQuizesData(res.data.data.publishedTasks.quizzes);
            this.handlePublishSchedulesData(res.data.data.publishedTasks.schedule);
            this.handlePublishSpecialOlympics(res.data.data.publishedTasks.specialOlympics)
            this.setState({allTasksMessages: [...res.data.data.publishedTasks.polls, 
              ...res.data.data.publishedTasks.assignments,
              ...res.data.data.publishedTasks.readings, 
              ...res.data.data.publishedTasks.quizzes,
              ...res.data.data.publishedTasks.schedule,
              ...res.data.data.publishedTasks.specialOlympics]})
            
            this.handlePollsData(res.data.data.savedTasks.polls);
            this.handleAssignmentsData(res.data.data.savedTasks.assignments);
            this.handleReadingsData(res.data.data.savedTasks.readings);
            this.handleQuizesData(res.data.data.savedTasks.quizzes);
            this.handleSchedulesData(res.data.data.savedTasks.schedule);
            this.handleSpecialOlympics(res.data.data.savedTasks.specialOlympics);
            this.setState({allTasksSaved: [...res.data.data.savedTasks.polls, 
              ...res.data.data.savedTasks.assignments,
              ...res.data.data.savedTasks.readings, 
              ...res.data.data.savedTasks.quizzes,
              ...res.data.data.savedTasks.schedule,
              ...res.data.data.savedTasks.specialOlympics]
          })
        }
      })
    })
  }  

  handlePublishSpecialOlympics = (publishedSpecialOlympics) => {
    let allPublishedSpecialOlympics = [];
    publishedSpecialOlympics.forEach((publishedSpecialOlympics) => {
      firebase
        .firestore()
        .collection(`specialOlympics`)
        .doc(publishedSpecialOlympics.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id , messageId: publishedSpecialOlympics.id };
          allPublishedSpecialOlympics.push(json);
        })
        .then(() => {
          //allPublishedSchedules.sort((a) => a.deadline > Date.now());
          allPublishedSpecialOlympics.sort((a, b) => b.timestamp - a.timestamp);
          this.setState({ publishedSpecialOlympics, allPublishedSpecialOlympics });
        });
      });
    if (publishedSpecialOlympics.length === 0) {
      this.setState({ allPublishedSpecialOlympics });
    }
  };

  handleSpecialOlympics = (savedSpecialOlympics) => {
    let allSavedSpecialOlympics = [];
    savedSpecialOlympics.forEach((specialOlympics) => {
      firebase
        .firestore()
        .collection(`specialOlympics`)
        .doc(specialOlympics.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id };
          allSavedSpecialOlympics.push(json);
        })
        .then(() => {
          allSavedSpecialOlympics.sort((a, b) => b.timestamp - a.timestamp);
          if (allSavedSpecialOlympics.length > 0) {
            this.setState({ savedSpecialOlympics, allSavedSpecialOlympics });
          } else {
            this.setState({ allSavedSpecialOlympics: [] });
          }
        });
    });
    if (savedSpecialOlympics.length === 0) {
      this.setState({ allSavedSpecialOlympics: [] });
    }
  };

  handleSchedulesData = (savedSchedules) => {
    let allSavedSchedules = [];
    savedSchedules.forEach((savedSchedule) => {
      firebase
        .firestore()
        .collection(`schedule`)
        .doc(savedSchedule.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id };
          allSavedSchedules.push(json);
        })
        .then(() => {
          allSavedSchedules.sort((a, b) => b.timestamp - a.timestamp);
          if (allSavedSchedules.length > 0) {
            this.setState({ savedSchedules, allSavedSchedules });
          } else {
            this.setState({ allSavedSchedules: [] });
          }
        });
    });
    if (savedSchedules.length === 0) {
      this.setState({ allSavedSchedules: [] });
    }
  };

  handlePublishSchedulesData = (publishedSchedules) => {
    let allPublishedSchedules = [];
    publishedSchedules.forEach((publishedSchedule) => {
      firebase
        .firestore()
        .collection(`schedule`)
        .doc(publishedSchedule.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id , messageId: publishedSchedule.id };
          allPublishedSchedules.push(json);
        })
        .then(() => {
          //allPublishedSchedules.sort((a) => a.deadline > Date.now());
          allPublishedSchedules.sort((a, b) => b.timestamp - a.timestamp);
          this.setState({ publishedSchedules, allPublishedSchedules });
        });
    });
    if (publishedSchedules.length === 0) {
      this.setState({ allPublishedSchedules });
    }
  };
  handleReadingsData = (savedReadings) => {
    let allSavedReadings = [];
    //console.log(savedReadings);
    savedReadings.forEach((savedReading) => {
      firebase
        .firestore()
        .collection(`readings`)
        .doc(savedReading.contentId)
        .get()
        .then((doc) => {
          //console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id };
          allSavedReadings.push(json);
        })
        .then(() => {
          allSavedReadings.sort((a, b) => b.timestamp - a.timestamp);
          if (allSavedReadings.length > 0) {
            this.setState({ savedReadings, allSavedReadings });
          } else {
            this.setState({ allSavedReadings: [] });
          }
        });
    });
    //console.log(allSavedReadings);
    if (savedReadings.length === 0) {
      this.setState({ allSavedReadings: [] });
    }
  };

  handlePublishReadingsData = (publishedReadings) => {
    let allPublishedReadings = [];
    publishedReadings.forEach((publishedReading) => {
      firebase
        .firestore()
        .collection(`readings`)
        .doc(publishedReading.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id , messageId:publishedReading.id};
          allPublishedReadings.push(json);
        })
        .then(() => {
          //allPublishedReadings.sort((a) => a.deadline > Date.now());
          allPublishedReadings.sort((a, b) => b.timestamp - a.timestamp);

          this.setState({ publishedReadings, allPublishedReadings });
        });
    });
    if (publishedReadings.length === 0) {
      this.setState({ allPublishedReadings });
    }
  };
  handlePollsData = (savedPolls) => {
    let allSavedPolls = [];
    savedPolls.forEach((savedPoll) => {
      firebase
        .firestore()
        .collection(`polls`)
        .doc(savedPoll.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id };
          allSavedPolls.push(json);
        })
        .then(() => {
          allSavedPolls.sort((a, b) => b.timestamp - a.timestamp);
          this.setState({ savedPolls, allSavedPolls });
        });
    });
    if (savedPolls.length === 0) {
      this.setState({ allSavedPolls: [] });
    }
  };

  handlePublishPollsData = (publishedPolls) => {
    let allPublishedPolls = [];
    publishedPolls.forEach((publishedPoll) => {
      firebase
        .firestore()
        .collection(`polls`)
        .doc(publishedPoll.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id, messageId : publishedPoll.id };
          allPublishedPolls.push(json);
        })
        .then(() => {
          allPublishedPolls.sort((a, b) => b.timestamp - a.timestamp);
          this.setState({ publishedPolls, allPublishedPolls });
        });
    });
    if (publishedPolls.length === 0) {
      this.setState({ allPublishedPolls });
    }
  };

  handleQuizesData = (savedQuizes) => {
    let allSavedQuizes = [];
    savedQuizes.forEach((savedQuiz) => {
      firebase
        .firestore()
        .collection(`quiz`)
        .doc(savedQuiz.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id };
          allSavedQuizes.push(json);
        })
        .then(() => {
          allSavedQuizes.sort((a, b) => b.timestamp - a.timestamp);
          if (allSavedQuizes.length > 0) {
            this.setState({ savedQuizes, allSavedQuizes });
          } else {
            this.setState({ allSavedQuizes: [] });
          }
        });
    });
    if (savedQuizes.length === 0) {
      this.setState({ allSavedQuizes: [] });
    }
  };

  handlePublishQuizesData = (publishedQuizes) => {
    let allPublishedQuizes = [];
    publishedQuizes.forEach((publishedQuiz) => {
      firebase
        .firestore()
        .collection(`quiz`)
        .doc(publishedQuiz.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id, messageId: publishedQuiz.id };
          allPublishedQuizes.push(json);
        })
        .then(() => {
          allPublishedQuizes.sort((a, b) => b.timestamp - a.timestamp);
          this.setState({ publishedQuizes, allPublishedQuizes });
        });
    });
    if (publishedQuizes.length === 0) {
      this.setState({ allPublishedQuizes });
    }
  };

  handleAssignmentsData = (savedAssignments) => {
    let allSavedAssignments = [];
    savedAssignments.forEach((savedAssignment) => {
      firebase
        .firestore()
        .collection(`assignments`)
        .doc(savedAssignment.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id };
          allSavedAssignments.push(json);
        })
        .then(() => {
          allSavedAssignments.sort((a, b) => b.timestamp - a.timestamp);
          if (allSavedAssignments.length > 0) {
            this.setState({ savedAssignments, allSavedAssignments });
          } else {
            this.setState({ allSavedAssignments: [] });
          }
        });
    });
    if (savedAssignments.length === 0) {
      this.setState({ allSavedAssignments: [] });
    }
  };

  handlePublishAssignmentsData = (publishedAssignments) => {
    let allPublishedAssignments = [];
    publishedAssignments.forEach((publishedAssignment) => {
      firebase
        .firestore()
        .collection(`assignments`)
        .doc(publishedAssignment.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id, messageId: publishedAssignment.id };
          allPublishedAssignments.push(json);
        })
        .then(() => {
          allPublishedAssignments.sort((a, b) => b.timestamp - a.timestamp);
          this.setState({ publishedAssignments, allPublishedAssignments });
        });
    });
    if (publishedAssignments.length === 0) {
      this.setState({ allPublishedAssignments });
    }
  };
  handleSelected = (selected) => {
    this.setState({ selected });
  };
  showSelectedPoll = (id) => {
    let selectedPoll = { id: id };
    this.setState({ selectedPoll });
  };
  showSelectedAssignment = (id) => {
    let selectedAssignment = { id: id };
    this.setState({ selectedAssignment });
  };
  showSelectedQuiz = (id) => {
    let selectedQuiz = { id: id };
    this.setState({ selectedQuiz });
  };
  showSelectedSchedule = (id) => {
    let selectedSchedule = { id: id };
    this.setState({ selectedSchedule });
  };
  showSelectedReading = (id) => {
    let selectedReading = { id: id };
    this.setState({ selectedReading });
  };
  showSelectedSpecialOlympics = (id) => {
    let selectedSpecialOlympics = { id: id };
    this.setState({ selectedSpecialOlympics})
  }
  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));
  render() {
    const {
      allTasksMessages,
      allPublishedAssignments,
      allPublishedSpecialOlympics,
      allPublishedPolls,
      allPublishedQuizes,
      allPublishedSchedules,
      allPublishedReadings,
      allSavedPolls,
      allSavedAssignments,
      allSavedQuizes,
      allSavedReadings,
      allSavedSchedules,
      allSavedSpecialOlympics,
      allTasksSaved,
      selected,
      selectedPoll,
      selectedAssignment,
      selectedQuiz,
      selectedSchedule,
      selectedReading,
      selectedSpecialOlympics
    } = this.state;
    const {
      handleFolder,
      addFolderView,
      folderName,
      allParticipants,
      currentChannel,
      currentUser,
      currentWorkspace,
      resetData,
      darkTheme,
    } = this.props;
    //console.log(allTasksMessages);
    //console.log(allPublishedSchedules);
    const shareSavedQuiz = async (savedQuiz, id) => {
      let savedQuizData = allSavedQuizes.find((obj) => obj.id === id);
      if (savedQuizData.deadline < Date.now()) {
        toast.warning(
          "Deadline has passed.please edit quiz to change deadline.",
          { position: toast.POSITION.BOTTOM_CENTER }
        );
        return;
      }
      if (
        (savedQuiz.all === false &&
          savedQuiz.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        savedQuiz.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants have left the group, please edit  to reassign  participants.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }

      let savedQuizId = savedQuiz.id;
      try {
        let res = await axios
          .post(`${API_BASE_URL}${QUIZ_SHARE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            quiz_id: savedQuizId,
          })
          .then(() => {
            if(darkTheme){
              toast.dark("Quiz shared.", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000});
            }else{
              toast("Quiz shared.", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000});
            }
            
            this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }
    };
    const shareSavedPoll = (messageData, pollId) => {
      const { resetData, darkTheme } = this.props;
      let pollData = allSavedPolls.find((poll) => poll.id === pollId);
      if (pollData.deadline < Date.now()) {
        toast.warning(
          "Deadline has Passed,please edit Poll to change deadline.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }

      // console.log(messageData);
      // console.log(allParticipants);
      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        messageData.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants have left the group, please edit  to reassign  participants.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      messageData.timestamp = Date.now();
      messageData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      const dbMsg = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

      dbMsg
        .add({ ...messageData })
        .then((docRef) => {
          dbUpdate
            .doc(`${currentChannel.roomId}`)
            .update({
              totalMessages: firebase.firestore.FieldValue.increment(1),
              lastMessage: {
                ...messageData,
                contentId: messageData.contentId,
                uid: docRef.id,
              },
            })
            .then(() => {
              db.doc(messageData.id).delete();
              if(darkTheme){
                toast.dark("Poll Shared",{position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000});
              }else{
                toast("Poll Shared",{ position:toast.POSITION.BOTTOM_RIGHT, hideProgress:true, autoClose:3000});
              }
              
              resetData();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    };
    const shareSavedAssignment = (messageData, assignmentId) => {
      const { resetData, darkTheme } = this.props;
      let assignmentData = allSavedAssignments.find(
        (assignment) => assignment.id === assignmentId
      );

      if (assignmentData.deadline < Date.now()) {
        toast.warning(
          "Deadline has passed,please edit Assignment to change deadline.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        messageData.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants have left the group, please edit  to reassign  participants.",

          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      messageData.timestamp = Date.now();
      messageData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      const dbMsg = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

      dbMsg
        .add({ ...messageData })
        .then((docRef) => {
          dbUpdate
            .doc(`${currentChannel.roomId}`)
            .update({
              totalMessages: firebase.firestore.FieldValue.increment(1),
              lastMessage: {
                ...messageData,
                contentId: messageData.contentId,
                uid: docRef.id,
              },
            })
            .then(() => {
              if(darkTheme){
                toast.dark("Assignment shared", {
                  position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
                });
              }else{
                toast("Assignment shared", {
                  position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
                });
              }
              
              resetData();
              db.doc(messageData.id).delete();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    };




    const shareSavedSpecialOlympic = async(messageData, specialOlympicId) => {
      const { resetData , darkTheme} = this.props;
      let specialOlympicData = allSavedSpecialOlympics.find(
        (item) => item.id === specialOlympicId
      );

      if (specialOlympicData.deadline < Date.now()) {
        toast.warning(
          "Deadline has passed,please edit Special Olympics to change deadline.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        messageData.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants have left the group, please edit  to reassign  participants.",

          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
       let savedQuizId = messageData.id;
       try {
          let res = await axios
            .post(`${API_BASE_URL}${QUIZPLUS_SHARE}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              saved_special_olympic_id: savedQuizId,
            })
            .then(() => {
              if(darkTheme){
                toast.dark("Special olympic shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
                });
              }else{
                toast("Special olympic shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
                });
              }
              
              this.props.resetData();
            });
        } catch (error) {
          console.log(error);
        }
    };






    const shareSavedSchedule = (messageData, scheduleId) => {
      const { resetData, darkTheme } = this.props;
      let scheduleData = allSavedSchedules.find(
        (schedule) => schedule.id === scheduleId
      );
      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        messageData.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants have left the group, please edit  to reassign  participants.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      if (scheduleData.deadline < Date.now()) {
        toast.warning(
          "Deadline has passed,please edit Schedule to change deadline.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }

      messageData.timestamp = Date.now();
      messageData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      // console.log(poll);
      const dbMsg = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

      dbMsg
        .add({ ...messageData })
        .then((docRef) => {
          dbUpdate
            .doc(`${currentChannel.roomId}`)
            .update({
              totalMessages: firebase.firestore.FieldValue.increment(1),
              lastMessage: {
                ...messageData,
                contentId: messageData.contentId,
                uid: docRef.id,
              },
            })
            .then(() => {
              db.doc(messageData.id).delete();
              if(darkTheme){
                toast.dark("Schedule shared", {
                  position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
                });
              }else{
                toast("Schedule shared", {
                  position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
                });
              }
              
              resetData();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    };

    const closeAppResponses = async (message) => {
      console.log(message);
      var message_id = message.messageId;
      let res = await axios.post(`${API_BASE_URL}${APP_CLOSE}`, {
        workspace_id: currentWorkspace.workspaceId,
        room_id: currentChannel.roomId,
        user_id: currentUser.uid,
        message_id: message_id,
      });
      if (res.status === 200) {
        if(darkTheme){
          toast.dark("Closed", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
        }else{
          toast("Closed", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
        }
        
      } else {
        console.log(res);
      }
    };

    const editPublishedTask = async (message) => {
      const {
        resetData, 
        setRightPanel, 
        setEditingPublished, 
        setContentTypeAndValue, 
        setEditedPublishedTask
      } = this.props;
      let message_id = message.messageId;
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      let messageDataObj = await query
        .doc(message_id)
        .get()
        .then((doc) => {
          console.log(doc.data());
          if (doc.exists) {
            return { ...doc.data(), id: doc.id };
          }
        });
      let messageData = {};
      if (messageDataObj.type === 1) {
        messageData = await firebase
          .firestore()
          .collection("schedule")
          .doc(messageDataObj.contentId)
          .get()
          .then((doc) => {
            return { ...doc.data(), id: doc.id };
          });
      } else if (messageDataObj.type === 2) {
        messageData = await firebase
          .firestore()
          .collection("readings")
          .doc(messageDataObj.contentId)
          .get()
          .then((doc) => {
            return { ...doc.data(), id: doc.id };
          });
      } else if (messageDataObj.type === 3) {
        messageData = await firebase
          .firestore()
          .collection("polls")
          .doc(messageDataObj.contentId)
          .get()
          .then((doc) => {
            return { ...doc.data(), id: doc.id };
          });
      } else if (messageDataObj.type === 4) {
        messageData = await firebase
          .firestore()
          .collection("quiz")
          .doc(messageDataObj.contentId)
          .get()
          .then((doc) => {
            return { ...doc.data(), id: doc.id };
          });
      } else if (messageDataObj.type === 5) {
        messageData = await firebase
          .firestore()
          .collection("assignments")
          .doc(messageDataObj.contentId)
          .get()
          .then((doc) => {
            return { ...doc.data(), id: doc.id };
          });
      } else if (messageDataObj.type === 10) {
        messageData = await firebase
          .firestore()
          .collection("specialOlympics")
          .doc(messageDataObj.contentId)
          .get()
          .then((doc) => {
            return { ...doc.data(), id: doc.id };
          });
      }
  
      //console.log(messageData, "respox");
      resetData();
      setRightPanel(true);
      setEditingPublished(true);
  
      if (messageDataObj.type === 1) {
        setTimeout(() => setContentTypeAndValue("createTask", "Schedule"), 500);
      } else if (messageDataObj.type === 2) {
        setTimeout(() => setContentTypeAndValue("createTask", "Reading"), 500);
      } else if (messageDataObj.type === 3) {
        setTimeout(() => setContentTypeAndValue("createTask", "Poll"), 500);
      } else if (messageDataObj.type === 4) {
        setTimeout(() => setContentTypeAndValue("createTask", "Quiz"), 500);
      } else if (messageDataObj.type === 5) {
        setTimeout(() => setContentTypeAndValue("createTask", "Assignment"), 500);
      }
      console.log(messageData,"respoxx3")
      setTimeout(() => setEditedPublishedTask(messageData), 1500);
    };
  

    const shareSavedReading = (messageData, readingId) => {
      let readingData = allSavedReadings.find(
        (reading) => reading.id === readingId
      );
      const { resetData, darkTheme } = this.props;
      if (readingData.deadline < Date.now()) {
        toast.warning(
          "Deadline has passed,please edit File to change deadline.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        messageData.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants have left the group, please edit  to reassign  participants.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      messageData.timestamp = Date.now();
      messageData.createdAt = firebase.firestore.FieldValue.serverTimestamp();
      const dbMsg = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

      dbMsg
        .add({ ...messageData })
        .then((docRef) => {
          dbUpdate
            .doc(`${currentChannel.roomId}`)
            .update({
              totalMessages: firebase.firestore.FieldValue.increment(1),
              lastMessage: {
                ...messageData,
                contentId: messageData.contentId,
                uid: docRef.id,
              },
            })
            .then(() => {
              resetData();
              db.doc(messageData.id).delete();
              if(darkTheme){
                toast.dark("Files shared", {
                  position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
                });
              }else{
                toast("Files shared", {
                  position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
                });
              }
              
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    };
    const deleteSavedReading = (messageData) => {
      let messageId = messageData.id;
      const dbReadings = firebase.firestore().collection(`readings`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      db.doc(messageId)
        .delete()
        .then(() => {
          dbReadings.doc(messageData.contentId).delete();
          if(darkTheme){
            toast.dark("Files deleted", {
              position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
            });
          }else{
            toast("Files deleted", {
              position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
            });
          }
          
        })
        .catch((err) => console.log(err));
    };
    const deleteSavedSchedule = (messageData) => {
      let messageId = messageData.id;
      const dbSchedules = firebase.firestore().collection(`schedule`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      db.doc(messageId)
        .delete()
        .then(() => {
          dbSchedules.doc(messageData.contentId).delete();
          this.setState(
            {
              editedSchedule: {},
            },
            () =>{
              if(darkTheme){
                toast.dark("Schedule deleted", {
                  position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
                })
              }else{
                toast("Schedule deleted", {
                  position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
                })
              }
            }
          );
        })
        .catch((err) => console.log(err));
    };
    const deleteSavedPoll = (messageData) => {
      const dbPolls = firebase.firestore().collection(`polls`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      db.doc(messageData.id)
        .delete()
        .then(() => {
          dbPolls.doc(messageData.contentId).delete();
          if(darkTheme){
            toast.dark("Polls deleted", {
              position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
            });
          }else{
            toast("Polls deleted", {
              position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
            });
          }
          
        })
        .catch((err) => console.log(err));
    };
    const deleteSavedAssignment = (messageData) => {
      let messageId = messageData.id;
      const dbPolls = firebase.firestore().collection(`assignments`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      db.doc(messageId)
        .delete()
        .then(() => {
          dbPolls.doc(messageData.contentId).delete();
          if(darkTheme){
            toast.dark("Assigment deleted", {
              position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
            });
          }else{
            toast("Assigment deleted", {
              position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000
            });
          }
          
        })
        .catch((err) => console.log(err));
    };
    const deleteSavedSpecialOlympic = (messageData) => {
      let messageId = messageData.id;
      const dbPolls = firebase.firestore().collection(`specialOlympics`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      db.doc(messageId)
        .delete()
        .then(() => {
          dbPolls.doc(messageData.contentId).delete();
          if(darkTheme){
            toast("Special Olympics deleted", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          else{
            toast("Special Olympics deleted", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          
        })
        .catch((err) => console.log(err));
    };
    const deleteSavedQuiz = async (savedQuiz) => {
      let savedQuizId = savedQuiz.id;
      try {
        let res = await axios
          .post(`${API_BASE_URL}${QUIZ_REMOVE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            quiz_id: savedQuizId,
          })
          .then(() => {
            if(darkTheme){
              toast.dark("Quiz deleted.", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose: 3000});
            }else{
              toast("Quiz deleted.", { position: toast.POSITION.BOTTOM_RIGHT, hideProgressBar: true, autoClose:3000});
            }
            
            //this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }
    };
    const deleteSavedTask = (taskId) => {
      // console.log(allTasksSaved);
      let messageData = allTasksSaved.find((msg) => msg.contentId === taskId);
      if (messageData.type === 2) {
        deleteSavedReading(messageData);
      } else if (messageData.type === 1) {
        deleteSavedSchedule(messageData);
      } else if (messageData.type === 3) {
        deleteSavedPoll(messageData);
      } else if (messageData.type === 4) {
        deleteSavedQuiz(messageData);
      } else if (messageData.type === 5) {
        deleteSavedAssignment(messageData);
      }else if(messageData.type === 10){
        deleteSavedSpecialOlympic(messageData);
      }
    };
    const shareSavedTask = (taskId) => {
       console.log(taskId, allTasksSaved );
      let messageData = allTasksSaved.find((msg) => msg.contentId === taskId);
      console.log(messageData);
      if (messageData.type === 2) {
        shareSavedReading(messageData, taskId);
      } else if (messageData.type === 1) {
        shareSavedSchedule(messageData, taskId);
      } else if (messageData.type === 3) {
        shareSavedPoll(messageData, taskId);
      } else if (messageData.type === 4) {
        shareSavedQuiz(messageData, taskId);
      } else if (messageData.type === 5) {
        shareSavedAssignment(messageData, taskId);
      }else if(messageData.type === 10) {
        shareSavedSpecialOlympic(messageData, taskId);
      }
    };

    const shareTaskAgain = (task) => {
      const {
        setSharedTask,
        setContentTypeAndValue,
        resetData,
        setRightPanel,
        setTaskSharing,
      } = this.props;
      let messageData = allTasksMessages.find(
        (msg) => msg.contentId === task.id
      );
      console.log(messageData);
      resetData();
      setRightPanel(true);
      setTaskSharing(true);

      if (messageData.type === 1) {
        setTimeout(() => setContentTypeAndValue("createTask", "Schedule"), 500);
      } else if (messageData.type === 2) {
        setTimeout(() => setContentTypeAndValue("createTask", "Reading"), 500);
      } else if (messageData.type === 3) {
        setTimeout(() => setContentTypeAndValue("createTask", "Poll"), 500);
      } else if (messageData.type === 4) {
        setTimeout(() => setContentTypeAndValue("createTask", "Quiz"), 500);
      } else if(messageData.type === 10) {
        setTimeout(() => setContentTypeAndValue("createTask", "Quiz+"))
      }else if (messageData.type === 5) {
        setTimeout(
          () => setContentTypeAndValue("createTask", "Assignment"),
          500
        );
      }
      setTimeout(() => setSharedTask(task), 700);
    };

    const editSavedTask = (task) => {
      const {
        setEditedTask,
        setTaskEditing,
        setContentTypeAndValue,
        resetData,
        setRightPanel,
      } = this.props;
      let messageData = allTasksSaved.find((msg) => msg.contentId === task.id);
      console.log(messageData);
      resetData();
      setRightPanel(true);
      setTaskEditing(true);

      if (messageData.type === 1) {
        setTimeout(() => setContentTypeAndValue("createTask", "Schedule"), 500);
      } else if (messageData.type === 2) {
        setTimeout(() => setContentTypeAndValue("createTask", "Reading"), 500);
      } else if (messageData.type === 3) {
        setTimeout(() => setContentTypeAndValue("createTask", "Poll"), 500);
      } else if (messageData.type === 4) {
        setTimeout(() => setContentTypeAndValue("createTask", "Quiz"), 500);
      }else if (messageData.type === 10) {
        setTimeout(() => setContentTypeAndValue("createTask", "Quiz+"))
      }else if (messageData.type === 5) {
        setTimeout(
          () => setContentTypeAndValue("createTask", "Assignment"),
          500
        );
      }
      setTimeout(() => setEditedTask(task), 700);
    };

    let coAdmins =
      allParticipants &&
      allParticipants.filter((participant) => participant.isAdmin);
    let canSeePollsCurrentUser = allPublishedPolls.filter(
      (poll) =>
        currentChannel?.createdBy === currentUser.uid ||
        poll.all === true ||
        coAdmins.find((usr) => usr.uid === currentUser.uid) ||
        (poll.assignedTo && poll.assignedTo.includes(currentUser.uid))
    );
    let canSeeAssignmentsCurrentUser = allPublishedAssignments.filter(
      (assignment) =>
        currentChannel?.createdBy === currentUser.uid ||
        assignment.all === true ||
        coAdmins.find((usr) => usr.uid === currentUser.uid) ||
        (assignment.assignedTo &&
          assignment.assignedTo.includes(currentUser.uid))
    );
    let canSeeQuizesCurrentUser = allPublishedQuizes.filter(
      (quiz) =>
        currentChannel?.createdBy === currentUser.uid ||
        quiz.all === true ||
        coAdmins.find((usr) => usr.uid === currentUser.uid) ||
        (quiz.assignedTo && quiz.assignedTo.includes(currentUser.uid))
    );
    let canSeeSchedulesCurrentUser = allPublishedSchedules.filter(
      (Schedule) =>
        currentChannel?.createdBy === currentUser.uid ||
        Schedule.all === true ||
        coAdmins.find((usr) => usr.uid === currentUser.uid) ||
        (Schedule.assignedTo && Schedule.assignedTo.includes(currentUser.uid))
    );
    let canSeeReadingsCurrentUser = allPublishedReadings.filter(
      (reading) =>
        currentChannel?.createdBy === currentUser.uid ||
        reading.all === true ||
        coAdmins.find((usr) => usr.uid === currentUser.uid) ||
        (reading.assignedTo && reading.assignedTo.includes(currentUser.uid))
    );
    let canSeeSpecialOlympicCurrentUser = allPublishedSpecialOlympics.filter(
      (specialOlympic) =>
      currentChannel?.createdBy === currentUser.uid ||
      specialOlympic.all === true ||
      coAdmins.find((usr) => usr.uid === currentUser.uid) ||
      (specialOlympic.assignedTo && specialOlympic.assignedTo.includes(currentUser.uid))
    ) 


    if (Object.keys(selectedPoll).length > 0) {
      return (
        <>
          <div className="m-2 rounded p-2 bg-card-theme text-theme">
            <FontAwesomeIcon
              onClick={() => {
                this.setState({ selectedPoll: {} });
              }}
              icon={faArrowLeft}
              className="mr-2 pointer"
            />
            <span>Back</span>
          </div>
          <div className="shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme">
            <Poll contentId={selectedPoll.id} />
          </div>
        </>
      );
    }  else if(Object.keys(selectedSpecialOlympics).length > 0){
      return (
        <>
          <div className="m-2 rounded p-2 bg-card-theme text-theme">
            <FontAwesomeIcon
              onClick={() => {
                this.setState({ selectedSpecialOlympics: {} });
              }}
              icon={faArrowLeft}
              className="mr-2 pointer"
            />
            <span>Back</span>
          </div>
          <div className="shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme">
            <QuizPlus
              quiz_data={allTasksMessages.find(
                (task) => task.contentId === selectedSpecialOlympics.id
              )}
              user_id={currentUser.uid}
              itemuid={selectedSpecialOlympics}
            />
          </div>
        </>
      )
    } else if (Object.keys(selectedAssignment).length > 0) {
      return (
        <>
          <div className="m-2 rounded p-2 bg-card-theme text-theme">
            <FontAwesomeIcon
              onClick={() => {
                this.setState({ selectedAssignment: {} });
              }}
              icon={faArrowLeft}
              className="mr-2 pointer"
            />
            <span>Back</span>
          </div>
          <div className="shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme">
            <Assignment
              user={currentUser.uid}
              item={allTasksMessages.find(
                (task) => task.contentId === selectedAssignment.id
              )}
            />
          </div>
        </>
      );
    } else if (Object.keys(selectedQuiz).length > 0) {
      return (
        <>
          <div className="m-2 rounded p-2 bg-card-theme text-theme">
            <FontAwesomeIcon
              onClick={() => {
                this.setState({ selectedQuiz: {} });
              }}
              icon={faArrowLeft}
              className="mr-2 pointer"
            />
            <span>Back</span>
          </div>
          <div className="shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme">
            <Quiz
              quiz_data={{ contentId: selectedQuiz.id }}
              user_id={currentUser.uid}
            />
          </div>
        </>
      );
    } else if (Object.keys(selectedSchedule).length > 0) {
      return (
        <>
          <div className="m-2 rounded p-2 bg-card-theme text-theme">
            <FontAwesomeIcon
              onClick={() => {
                this.setState({ selectedSchedule: {} });
              }}
              icon={faArrowLeft}
              className="mr-2 pointer"
            />
            <span>Back</span>
          </div>
          <div className="shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme">
            <Schedule
              user={currentUser.uid}
              contentId={selectedSchedule.id}
              item={allTasksMessages.find(
                (schedule) => schedule.contentId === selectedSchedule.id
              )}
            />
          </div>
        </>
      );
    } else if (Object.keys(selectedReading).length > 0) {
      return (
        <>
          <div className="m-2 rounded p-2 bg-card-theme text-theme">
            <FontAwesomeIcon
              onClick={() => {
                this.setState({ selectedReading: {} });
              }}
              icon={faArrowLeft}
              className="mr-2 pointer"
            />
            <span>Back</span>
          </div>
          <div className="shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme">
            <Reading
              user={currentUser.uid}
              item={allTasksMessages.find(
                (reading) => reading.contentId === selectedReading.id
              )}
            />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="m-2 rounded p-2 bg-card-theme text-theme">
          <div className="d-flex">
            <div>
              <FontAwesomeIcon
                onClick={() => handleFolder(null) && addFolderView(0)}
                icon={faArrowLeft}
                className="mr-2 pointer"
              />
              <span className="d-inline-block">{folderName}</span>
            </div>
          </div>
        </div>
        <IsAdmin>
          <div className="row m-2 border-1 rounded">
            <div
              className={
                selected === "Published"
                  ? "col-6 text-theme bg-card-theme rounded py-1 d-flex justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Published")}
              style={{ cursor: "pointer" }}
            >
              Published
            </div>

            <div
              className={
                selected === "Saved"
                  ? "col-6 text-theme bg-card-theme rounded d-flex py-1 justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Saved")}
              style={{ cursor: "pointer" }}
            >
              Saved
            </div>
          </div>
        </IsAdmin>
        {selected === "Published" ? (
          <>
            {this.state.allTasksMessages.length <= 0 ? (
              <p className="text-theme text-center small mt-5">
              No app published yet.
              </p>
            ): (
            <>
              {canSeePollsCurrentUser.length > 0 ? (
                <AllPolls
                  closeAppResponses={closeAppResponses}
                  editPublishedTask={editPublishedTask}
                  shareTaskAgain={shareTaskAgain}
                  showSelectedPoll={this.showSelectedPoll}
                  CustomToggle={this.CustomToggle}
                  allPolls={canSeePollsCurrentUser}
                />
              ) : (
                <>
                  {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">Polls</p>
                  <div className="col">
                    <p className="text-theme text-center small">
                      No polls published yet
                    </p>
                  </div> */}
                </>
              )}
              {canSeeAssignmentsCurrentUser.length > 0 ? (
                <AllAssignments
                  closeAppResponses={closeAppResponses}
                  editPublishedTask={editPublishedTask}
                  shareTaskAgain={shareTaskAgain}
                  allAssignments={canSeeAssignmentsCurrentUser}
                  CustomToggle={this.CustomToggle}
                  showSelectedAssignment={this.showSelectedAssignment}
                />
              ) : (
                <>
                  {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">
                    Assignments
                  </p>
                  <div className="col">
                    <p className="text-theme text-center small">
                      No assignments published yet
                    </p>
                  </div> */}
                </>
              )}
              {canSeeQuizesCurrentUser.length > 0 ? (
                <AllQuizes
                  closeAppResponses={closeAppResponses}
                  editPublishedTask={editPublishedTask}
                  shareTaskAgain={shareTaskAgain}
                  allQuizes={canSeeQuizesCurrentUser}
                  CustomToggle={this.CustomToggle}
                  showSelectedQuiz={this.showSelectedQuiz}
                />
              ) : (
                <>
                  {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">Quizes</p>
                  <div className="col">
                    <p className="text-theme text-center small">
                      No quizes published yet
                    </p>
                  </div> */}
                </>
              )}
              {canSeeSchedulesCurrentUser.length > 0 ? (
                <AllSchedules
                  closeAppResponses={closeAppResponses}
                  editPublishedTask={editPublishedTask}
                  shareTaskAgain={shareTaskAgain}
                  allSchedules={canSeeSchedulesCurrentUser}
                  showSelectedSchedule={this.showSelectedSchedule}
                  CustomToggle={this.CustomToggle}
                />
              ) : (
                <>
                  {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">
                    Schedules
                  </p>
                  <div className="col">
                    <p className="text-theme text-center small">
                      No schedules published yet
                    </p>
                  </div> */}
                </>
              )}
              {canSeeReadingsCurrentUser.length > 0 ? (
                <AllReadings
                  closeAppResponses={closeAppResponses}
                  editPublishedTask={editPublishedTask}
                  shareTaskAgain={shareTaskAgain}
                  allReadings={canSeeReadingsCurrentUser}
                  showSelectedReading={this.showSelectedReading}
                  CustomToggle={this.CustomToggle}
                />
              ) : (
                <>
                  {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">Files</p>
                  <div className="col">
                    <p className="text-theme text-center small">
                      No files published yet
                    </p>
                  </div> */}
                </>
              )}
              {currentChannel.roomConfig.special_olympics_app_allowed ? (
                <>
                  {canSeeSpecialOlympicCurrentUser.length > 0 ? (
                    <AllSpecialOlympics
                      closeAppResponses={closeAppResponses}
                      editPublishedTask={editPublishedTask}
                      shareTaskAgain={shareTaskAgain}
                      allSpecialOlympics={canSeeSpecialOlympicCurrentUser}
                      showSelectedSpecialOlympics={this.showSelectedSpecialOlympics}
                      CustomToggle={this.CustomToggle}
                    />
                    ) : (
                    <>
                      {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">Special Olympics</p>
                      <div className="col">
                        <p className="text-theme text-center small">
                          No files published yet
                        </p>
                      </div> */}
                    </>
                  )}
                </>
              ): null}
            </>)}
          </>
        ) : (
          <> 
            {this.state.allTasksSaved.length <= 0 ? (
              <p className="text-theme text-center small mt-5">
                No app saved yet.
              </p>
            ):(
              <>
                {allSavedPolls.length > 0 ? (
                  <div>
                    <p className="rounded-lg p-2 m-2 border-1 text-theme">Polls</p>

                    {allSavedPolls.map((poll, index) => (
                      <div
                        className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                        key={index}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            //onClick={() => editSavedTask(poll.id)}
                            className="pointer"
                          >
                            <small className="text-theme-lighter smaller">
                              {moment(poll.timestamp).format("LLL")}
                            </small>
                            <p className="m-0 font-weight-bold text-theme small">
                              {poll.title}
                            </p>
                          </div>
                          <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                            <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                            <Dropdown.Menu className="bg-card-theme text-theme">
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => shareSavedTask(poll.id)}
                              >
                                Share now
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => editSavedTask(poll)}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => deleteSavedTask(poll.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">Polls</p>
                    <div className="col">
                      <p className="text-theme text-center small">
                        No polls saved yet
                      </p>
                    </div> */}
                  </>
                )}
                {allSavedAssignments.length > 0 ? (
                  <div>
                    <p className="rounded-lg p-2 m-2 border-1 text-theme">
                      Assignments
                    </p>

                    {allSavedAssignments.map((Assignment, index) => (
                      <div
                        className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                        key={index}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            // onClick={() => editSavedTask(Assignment.id)}
                            className="pointer"
                          >
                            <small className="text-theme-lighter smaller">
                              {moment(Assignment.timestamp).format("LLL")}
                            </small>
                            <p className="m-0 font-weight-bold text-theme small">
                              {Assignment.assignmentSubject}
                            </p>
                          </div>
                          <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                            <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                            <Dropdown.Menu className="bg-card-theme text-theme">
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => shareSavedTask(Assignment.id)}
                              >
                                Share now
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => editSavedTask(Assignment)}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => deleteSavedTask(Assignment.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">
                      Assignments
                    </p>
                    <div className="col">
                      <p className="text-theme text-center small">
                        No assignments saved yet
                      </p>
                    </div> */}
                  </>
                )}
                {allSavedQuizes.length > 0 ? (
                  <div>
                    <p className="rounded-lg p-2 m-2 border-1 text-theme">Quizes</p>

                    {allSavedQuizes.map((quiz, index) => (
                      <div
                        className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                        key={index}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            //onClick={() => editSavedTask(quiz.id)}
                            className="pointer"
                          >
                            <small className="text-theme-lighter smaller">
                              {moment(quiz.timestamp).format("LLL")}
                            </small>
                            <p className="m-0 font-weight-bold text-theme small">
                              {quiz.quizName}
                            </p>
                          </div>
                          <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                            <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                            <Dropdown.Menu className="bg-card-theme text-theme">
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => shareSavedTask(quiz.id)}
                              >
                                Share now
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => editSavedTask(quiz)}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => deleteSavedTask(quiz.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">Quizes</p>
                    <div className="col">
                      <p className="text-theme text-center small">
                        No quizes saved yet
                      </p>
                    </div> */}
                  </>
                )}
                {allSavedSchedules.length > 0 ? (
                  <div>
                    <p className="rounded-lg p-2 m-2 border-1 text-theme">
                      Schedules
                    </p>
                    {allSavedSchedules.map((Schedule, index) => (
                      <div
                        className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                        key={index}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            // onClick={() => editSavedTask(Schedule.id)}
                            className="pointer"
                          >
                            <small className="text-theme-lighter smaller">
                              {moment(Schedule.timestamp).format("LLL")}
                            </small>
                            <p className="m-0 font-weight-bold text-theme small">
                              {Schedule.scheduleTitle}
                            </p>
                          </div>
                          <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                            <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                            <Dropdown.Menu className="bg-card-theme text-theme">
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => shareSavedTask(Schedule.id)}
                              >
                                Share now
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => editSavedTask(Schedule)}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => deleteSavedTask(Schedule.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">
                      Schedules
                    </p>
                    <div className="col">
                      <p className="text-theme text-center small">
                        No schedules saved yet
                      </p>
                    </div> */}
                  </>
                )}
                {allSavedReadings.length > 0 ? (
                  <div>
                    <p className="rounded-lg p-2 m-2 border-1 text-theme">Files</p>

                    {allSavedReadings.map((Reading, index) => (
                      <div
                        className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                        key={index}
                      >
                        <div className="d-flex justify-content-center">
                          <div
                            // onClick={() => editSavedTask(Reading.id)}
                            className="pointer"
                          >
                            <small className="text-theme-lighter smaller">
                              {moment(Reading.timestamp).format("LLL")}
                            </small>
                            <p className="m-0 font-weight-bold text-theme small">
                              {Reading.readingSubject}
                            </p>
                          </div>
                          <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                            <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                            <Dropdown.Menu className="bg-card-theme text-theme">
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => shareSavedTask(Reading.id)}
                              >
                                Share now
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => editSavedTask(Reading)}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => deleteSavedTask(Reading.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">Files</p>
                    <div className="col">
                      <p className="text-theme text-center small">
                        No files saved yet
                      </p>
                    </div> */}
                  </>
                )}
                {currentChannel.roomConfig.special_olympics_app_allowed ? (
                  <>
                    {allSavedSpecialOlympics.length > 0 ? (
                      <div>
                        <p className="rounded-lg p-2 m-2 border-1 text-theme">Special Olympics</p>
                        {allSavedSpecialOlympics.map((quiz, index) => (
                          <div
                            className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                            key={index}
                          >
                            <div className="d-flex justify-content-center">
                              <div
                                //onClick={() => editSavedTask(quiz.id)}
                                className="pointer"
                              >
                                <small className="text-theme-lighter smaller">
                                  {moment(quiz.timestamp).format("LLL")}
                                </small>
                                <p className="m-0 font-weight-bold text-theme small">
                                  {quiz.specialOlympicName}
                                </p>
                              </div>
                              <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                                <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                                <Dropdown.Menu className="bg-card-theme text-theme">
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() => shareSavedTask(quiz.id)}
                                  >
                                    Share now
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() => editSavedTask(quiz)}
                                  >
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() => deleteSavedTask(quiz.id)}
                                  >
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        {/* <p className="rounded-lg p-2 m-2 border-1 text-theme">Special Olympics</p>
                        <div className="col">
                          <p className="text-theme text-center small">
                            No Special Olympics saved yet
                          </p>
                        </div> */}
                      </>
                    )}
                  </>
                ): null}
                
              </>
            )}
          </>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, {
  setEditingPublished,
  setEditedPublishedTask,
  setSharedTask,
  setContentTypeAndValue,
  resetData,
  setRightPanel,
  setTaskSharing,
  setTaskEditing,
  setEditedTask,
})(FolderContent);
