import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleDown
} from "@fortawesome/free-solid-svg-icons";
import {connect} from 'react-redux';
import { Spinner } from "react-bootstrap";
import firebase from "firebase";
import { deviceDetect, deviceType, browserName } from "react-device-detect";
import {
  setUserPosts,
  setIsVideoVisible,
  setChannelDetails,
  setAllMessages,
  setCurrentChannel,
  setRightPanel,
  setParticipants,
} from "../../actions";
import Typing from "./Typing";
import Message from "./Message";
import InfiniteScroll from './ScrollComponent/InfiniteScroll';
import { queryByTestId } from '@testing-library/react';
import {ReactHeight} from 'react-height';
import _ from 'lodash';


const Chat = (props) => {
  const [messagesEnd, setMessagesEnd] = useState(null);
  const scrollToBottom = () => {
    messagesEnd.scrollIntoView({ behavior: "smooth" });
  };
  const [currentOffsetY, setCurrentOffsetY] = useState(0);
  const [currentOffsetYBottom, setCurrentOffsetYBottom] = useState(0);
  const [limitToLast, setLimitToLast] = useState(10)
  const [showMessageTopLoader, setShowMessageTopLoader] = useState(false);
  const [typingRef, setTypingRef] = useState(firebase.database().ref("typing"));
  const [listeners, setListeners] = useState([]);
  const [connectedRef, setConnectedRef] = useState(firebase.database().ref(".info/connected"));
  const [typingUsers, setTypingUsers] = useState([]);
  const [unsubscribe, setUnsubscribe] = useState(null);
  const [totalMessages, setTotalMessages] = useState(0);

  const [loading, setLoading] = useState(true);

  const [messageContentHeight, setMessageContentHeight] = useState(0);

  const [hasMore, setHasMore] = useState(true);



  function useWindowDimensions() {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);

    const updateWidthAndHeight = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    React.useEffect(() => {
      window.addEventListener("resize", updateWidthAndHeight);
      return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    return {
      width,
      height,
    }
  }

  const { width, height } = useWindowDimensions();


  const addMessageListener_new = () => {

    if (messageContentHeight) {

      const {
        currentWorkspace,
        currentChannel,
        currentChannelMessages,
        setAllMessages,
      } = props;

      let unsub;
      let loadedMessages = [];


      const countOfInitialMessages = +((messageContentHeight / 35) + 1).toFixed(0);

      // console.log(countOfInitialMessages);

      const now = new Date().valueOf();

      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        )
        .orderBy("timestamp", "desc")
        .limit(countOfInitialMessages);

      unsub = query.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            console.log("Added: ", change.doc.data());
            loadedMessages.length > 0 &&
              change.doc.data().timestamp >= loadedMessages[0].timestamp
              ? loadedMessages.unshift({
                uid: change.doc.ref.id,
                ...change.doc.data(),
              })
              : loadedMessages.push({
                uid: change.doc.ref.id,
                ...change.doc.data(),
              });
          }
          if (change.type === "modified") {
            console.log("modified: ", change.doc.data());
            // it's repeating the sent message. 
            // check if isDeleted is true
            // if true then find the index of that message in the loaded messages and replace it with the modified message
            // not replacing it adds another message in DOM which is deleted along with the original message which was supposed to be deleted

            if (change.doc.data().isDeleted) {
              let index = null;
              loadedMessages.forEach((message, i) => {
                if(message.uid === change.doc.ref.id) {
                  index = i;
                }
              })

              if(index !== null) {
                loadedMessages.splice(index, 1, {
                  uid: change.doc.ref.id,
                  isDeleted: true,
                  ...change.doc.data(),
                })
              }
            }

          }
          if (change.type === "removed") {
            console.log("Removed: ", change.doc.data());
            loadedMessages.filter((item) => item.uid != change.doc.ref.id);
          }
        });
        console.log("loadedMessages", loadedMessages);
        let prevCurrentChannel = props.currentChannel;

        //   setCurrentChannel(prevCurrentChannel);
        let prevLoadedMessages = loadedMessages;
        prevLoadedMessages = _.cloneDeep(prevLoadedMessages);
        setAllMessages(prevLoadedMessages);
        setLoading(false);
      });

      console.log("setting unsub")
      setUnsubscribe(() => unsub);
    }

  };


  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      if(messageContentHeight)
      ref.current = value;
    });
    return ref.current;
  }

  const prevChannel = usePrevious(props.currentChannel)

  useEffect(() => {
    console.log("first",prevChannel,  props.currentChannel)
    if(props.currentWorkspace && prevChannel && prevChannel.roomId !== props.currentChannel.roomId) {
      setLoading(true);
      addMessageListener_new();
    }
    if(props.currentWorkspace && !prevChannel) {
      console.log("second")
      setLoading(true);
      addMessageListener_new();
    }
    if(props.currentWorkspace && prevChannel && prevChannel.roomId === props.currentChannel.roomId) {
      console.log("third")
      setLoading(false);
    }

  }, [messageContentHeight, props.currentChannel, props.currentWorkspace])

  useEffect(() => {
    if(prevChannel && prevChannel.roomId !== props.currentChannel.roomId && unsubscribe){
      console.log(prevChannel, props.currentChannel, unsubscribe, "prev and curr channel")
      unsubscribe();
      // func();
    } 
  },[props.currentChannel, props.currentWorkspace])

  useEffect(() => {
    return (() => {
      // @ https://stackoverflow.com/questions/55636020/react-hooks-trying-to-access-state-before-unmount-returns-initial-state
      // should update componentwillunmount when the unsub function is updated else it will have the initial value
      console.log("unmount once", unsubscribe)
      if(unsubscribe) {
        unsubscribe()
      }
    })
  },[unsubscribe])





    const onScrollMessages = (e) => {
      let element = e.target;
      //console.log(element.clientHeight);
      const {
        currentWorkspace,
        currentChannel,
        currentChannelMessages,
        setAllMessages,
      } = props;

      // console.log(element, "element")

      if (browserName === "Edge") {
        setCurrentOffsetY(element.scrollTop);
        setCurrentOffsetYBottom(element.scrollHeight - element.clientHeight - element.scrollTop);
      } else {
        setCurrentOffsetY(-element.scrollTop);
        setCurrentOffsetYBottom(-element.scrollTop);
      }
    }

    const getTotalMessages = async () => {
      const {
        currentWorkspace,
        currentChannel,
        currentChannelMessages,
        setAllMessages,
      } = props;
      const messagesCount = await firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      ).get().then((querySnapshot) => {
        console.log(querySnapshot.size)
        return querySnapshot.size;
      })
      setTotalMessages(messagesCount);
    }

    const fetchMessages = () => {

      if(props.currentChannelMessages.length > 0){

      console.log("fetchmessages")
      const {
        currentWorkspace,
        currentChannel,
        currentChannelMessages,
        setAllMessages,
      } = props;
      setLimitToLast((prev) => prev + 10);
      setShowMessageTopLoader(true);

      let loadedMessages = [...currentChannelMessages];

      getTotalMessages();


      // console.log("all loaded",totalMessages, loadedMessages.length)
      if(totalMessages === loadedMessages.length){
        console.log("all loaded",totalMessages, loadedMessages.length)
        setHasMore(false);
      }

      console.log(currentChannelMessages, "timestamp bug");

      const countOfInitialMessages = +(( messageContentHeight / 35 ) + 1).toFixed(0);

      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        )
        .orderBy("timestamp", "desc")
        .startAfter(
          currentChannelMessages[currentChannelMessages.length - 1].timestamp
        )
        .limit(12);

        query.onSnapshot({
          includeMetadataChanges: true,
        },
          function (snapshot) {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            console.log("Added: ", change.doc.data());
            loadedMessages.length > 0 &&
            change.doc.data().timestamp >= loadedMessages[0].timestamp
              ? loadedMessages.unshift({
                  uid: change.doc.ref.id,
                  ...change.doc.data(),
                })
              : loadedMessages.push({
                  uid: change.doc.ref.id,
                  ...change.doc.data(),
                });
          }
          if (change.type === "modified") {
            console.log("modified: ", change.doc.data());
            // it's repeating the sent message. 
            // check if isDeleted is true
            // if true then find the index of that message in the loaded messages and replace it with the modified message
            // not replacing it adds another message in DOM which is deleted along with the original message which was supposed to be deleted

            if (change.doc.data().isDeleted) {
              let index = null;
              loadedMessages.forEach((message, i) => {
                if(message.uid === change.doc.ref.id) {
                  index = i;
                }
              })

              if(index !== null) {
                loadedMessages.splice(index, 1, {
                  uid: change.doc.ref.id,
                  isDeleted: true,
                  ...change.doc.data(),
                })
              }
            }

          }
        });
        // console.log("loadedMessages on scroll ", loadedMessages);
        loadedMessages = _.cloneDeep(loadedMessages);
        setAllMessages(loadedMessages);
        // _handleStateChange("messages", loadedMessages);
        setTimeout(
          () => setShowMessageTopLoader(false),
          800
        );
      });
    }

  }




    const addToListeners = (id, ref, event) => {
      const index = listeners.findIndex((listener) => {
        return (
          listener.id === id && listener.ref === ref && listener.event === event
        );
      });
  
      if (index === -1) {
        const newListener = { id, ref, event };
        const prevArray = listeners;
        prevArray.concat(newListener);
        setListeners(prevArray)
      }
    };


    const addTypingListeners = (channelId) => {
      let typingUsers = [];
      typingRef.child(channelId).on("child_added", (snap) => {
        if (snap.key !== props.currentUser.uid) {
          typingUsers = typingUsers.concat({
            id: snap.key,
            name: snap.val(),
          });
          setTypingUsers(typingUsers);
        }
      });
      addToListeners(channelId, typingRef, "child_added");
  
      typingRef.child(channelId).on("child_removed", (snap) => {
        const index = typingUsers.findIndex((user) => user.id === snap.key);
        if (index !== -1) {
          typingUsers = typingUsers.filter((user) => user.id !== snap.key);
          setTypingUsers(typingUsers);
        }
      });
      addToListeners(channelId, typingRef, "child_removed");
  
      connectedRef.on("value", (snap) => {
        if (snap.val() === true) {
          typingRef
            .child(channelId)
            .child(props.currentUser.uid)
            .onDisconnect()
            .remove((err) => {
              if (err !== null) {
                console.error(err);
              }
            });
        }
      });
    };

    // useEffect(() => {
    //   if(props.currentChannel && props.currentUser){
    //     addTypingListeners(props.currentChannel.roomId)
    //   }
    // },[props.currentChannel])

    // useEffect(() => {
    //   // if currentChannel changes so we've to reset all the states
    //   console.log("running on change channel")
    //   setHasMore(true);
    //   setTotalMessages(0);

    //   if(unsubscribe) {
    //     console.log("unsub on channel change")
    //     unsubscribe()
    //   }
    // },[props.currentChannel])



    const displayTypingUsers = (users) =>
    users.length > 0 &&
    users.map((user) => (
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "0.2em" }}
        key={user.id}
      >
        <span className="user__typing">{user.name} is typing</span>
        <Typing />
      </div>
    ));


    const displayMessages = (messages, userId) =>
    messages?.length > 0 &&
    messages.map((message) => (
      <>
        {message.type > 0 ? (
          message.all ? (
            <Message
              key={message.timestamp}
              message={message}
              user={props.currentUser}
              channel={props.currentChannel}
            />
          ) : message.assignedTo && message.createdBy ? (
            message.assignedTo.includes(userId) ||
            message.createdBy === userId ? (
              <Message
                key={message.timestamp}
                message={message}
                user={props.currentUser}
                channel={props.currentChannel}
              />
            ) : null
          ) : (
            <Message
              key={message.timestamp}
              message={message}
              user={props.currentUser}
              channel={props.currentChannel}
            />
          )
        ) : (
          <Message
            key={message.timestamp}
            message={message}
            user={props.currentUser}
            channel={props.currentChannel}
          />
        )}
      </>
    ));

    // console.log(props.currentChannelMessages.length, "current messages")

    useEffect(() => {
      console.log(props.currentChannelMessages, "current channel messages changed")
    },[props.currentChannelMessages])

  return (

    <ReactHeight onHeightReady={height => setMessageContentHeight(height)}>


      <div
        id="scrollableDiv"
        className="content-messages"
        style={props.isVideoChat ? { height: '100%' } : null}
        onScroll={onScrollMessages}
      >
        <div ref={(node) => (setMessagesEnd(node))} ></div>
        {
          !loading ?


            <InfiniteScroll
              dataLength={props.currentChannelMessages?.length}
              next={fetchMessages}
              hasMore={props.currentChannelMessages?.length > 0 ? hasMore : false}
              loader={
                <div
                  style={{ padding: 10 }}
                  className="d-flex justify-content-center"
                >
                  <Spinner
                    animation="border"
                    className="bg-theme text-success"
                  />
                </div>
              }
              inverse={true}
              scrollableTarget="scrollableDiv"
              style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
            // endMessage={
            //   <p style={{ textAlign: 'center' }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
            >

              {displayTypingUsers(typingUsers)}
              {
                // props.searchTerm
                //   ? displayMessages(props.searchResults, props.currentUser.uid)
                //   : 
                displayMessages(props.currentChannelMessages, props.currentUser.uid)
              }

              {currentOffsetYBottom > 100 && (
                <div
                  style={{
                    position: "absolute",
                    right: 25,
                    height: 50,
                    width: 50,
                  }}
                  className="bg-white rounded-circle border-white shadow-sm d-flex justify-content-center align-items-center"
                  onClick={() => scrollToBottom()}
                >
                  <FontAwesomeIcon
                    className=" text-muted"
                    icon={faAngleDoubleDown}
                    style={{ fontSize: 20 }}
                  />
                </div>
              )}


            </InfiniteScroll>

            :
            <div
              style={{ padding: 10 }}
              className="d-flex justify-content-center"
            >
              <Spinner
                animation="border"
                className="bg-theme text-success"
              />
            </div>



        }


      </div>
    </ReactHeight>
  )
}


// {showMessageTopLoader && (
//   <div
//     style={{ padding: 10 }}
//     className="d-flex justify-content-center"
//   >
//     <Spinner
//       animation="border"
//       className="bg-theme text-success"
//     />
//   </div>
// )}



const mapStateToProps = (state) => {
    return({
        currentUser: state.user.currentUser,
        currentChannelMessages: state.channel.currentChannelMessages,
        currentWorkspace: state.workspace.currentWorkspace,
        currentChannelMessages: state.channel.currentChannelMessages,
        currentChannel: state.channel.currentChannel
    })
}

export default connect(mapStateToProps,{
  setUserPosts,
  setIsVideoVisible,
  setChannelDetails,
  setAllMessages,
  setCurrentChannel,
  setRightPanel,
  setParticipants,
})(Chat);