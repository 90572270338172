import React, { useEffect, useState, useRef } from "react";

import { Dropdown, Modal, Button, InputGroup, Form, Image } from "react-bootstrap";
import firebase from "firebase";
import RoomListElement from "./RoomListElement";
import { connect } from "react-redux";
import {
  API_BASE_URL,
  PLAN_STATUS,
  ROOM_LEAVE,
  USER_ROOM_ARCHIVE,
  USER_ROOM_UNARCHIVE,
  ROOM_DUPLICATE,
  ROOM_TRANSFER,
  ROOMS_PARTICIPANTS
} from "../../config/index";
import { ARCHIVE } from "../../actions/types";
import {
  setChannels,
  setAllMessages,
  setCurrentChannel,
  setCurrentWorkspace,
  setParticipants,
  resetData,
  setRightPanel,
  setLeftPanel,
  setContentTypeAndValue,
  setWorkspaces
} from "../../actions/index";
import { timeFromNow, postData } from "../../utils/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faThumbtack,
  faLock,
  faLockOpen,
  faPoll,
  faFileAlt,
  faImage,
  faFileWord,
  faFilePdf,
  faClipboard,
  faFilePowerpoint,
  faFileExcel,
  faCircle,
  faCalendarAlt,
  faTasks,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import _ from "lodash";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { platform } from "chart.js";
import "./Left.css"
import RoomItem from './RoomItem';
import axios from 'axios';
import { toast } from "react-toastify";
toast.configure();
const pdf_type = ["application/pdf"];

const word_type = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const excel_type = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const powerpoint_type = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

const FileIcon = ({ message }) => {
  if (word_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFileWord} className="text-theme" />;
  if (pdf_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFilePdf} className="text-theme" />;
  if (powerpoint_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFilePowerpoint} className="text-theme" />;
  if (excel_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFileExcel} className="text-theme" />;
  return <div></div>;
};


const WorkspaceListElement = (props) => {
  const [workspaceData, setWorkspaceData] = useState({});
  const [loadingWorkspaceData, setLoadingWorkspaceData] = useState(true);
  const [roomsDataArray, setRoomsDataArray] = useState([]);
  const [defaultSortDone, setDefaultSortDone] = useState(false);
  const [room, setRoom] = useState({});
  const [show, setShow] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [showUnArchive, setShowUnArchive] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showTransferRoomModal, setShowTransferRoomModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [duplicateRoomName, setDuplicateRoomName] = useState("");


  // room analytics states

  const [allParticipants, setAllParticipants] = useState([]);
  const [savedPolls, setSavedPolls] = useState([]);
  const [publishedPolls, setPublishedPolls] = useState([]);

  const [savedReadings, setSavedReadings] = useState([]);
  const [publishedReadings, setPublishedReadings] = useState([]);

  const [savedQuizes, setSavedQuizes] = useState([]);
  const [publishedQuizes, setPublishedQuizes] = useState([]);

  const [savedAssignments, setSavedAssignmnets] = useState([]);
  const [publishedAssignments, setPublishedAssignments] = useState([]);

  const [savedSchedules, setSavedSchedules] = useState([]);
  const [publishedSchedules, setPublishedSchedules] = useState([]);
  const [chartData, setChartData] = useState([]);

  const [currentPlan, setCurrentPlan] = useState({});


  // modal states


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseArchive = () => setShowArchive(false);
  const handleShowArchive = () => setShowArchive(true);
  const handleCloseUnArchive = () => setShowUnArchive(false);
  const handleShowUnArchive = () => setShowUnArchive(true);

  //console.log(room);

  // Selected Room participants
  const [selectedRoomParticipants, setSelectedRoomParticipants] = useState([]);

  // select user to transfer ownership to
  const [selectedUser, setSelectedUser] = useState(null);

  // select user to transfer ownership to
  const [transferTermsChecked, setTransferTermsChecked] = useState(false);

  const getWorkspaceData = async () => {
    const { workspace } = props;

    let docRef = firebase
      .firestore()
      .collection(`workspaces`)
      .doc(`${workspace.workspaceId}`);
    let workspace_data = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          // console.log("Document data workspace:", doc.data());
          const workspaceData = doc.data();
          return workspaceData;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    return (workspace_data);

  };

  const getAllRoomsData = () => {
    const { workspace, roomsVisible, setChannels } = props;
    let roomsArray = [];
    let roomsDataPromises = [];
    let roomsDataArray = [];

    getWorkspaceData().then((workspace_data) => {
      setWorkspaceData(workspace_data);
      setLoadingWorkspaceData(false);


      roomsArray =
        workspace[roomsVisible] &&
        Object.values(workspace[roomsVisible]).map((room, key) => room.roomId);

      // console.log(`roomsArray ${JSON.stringify(roomsArray)}`)

      if (roomsArray) {
        roomsDataPromises = roomsArray.map((roomId, key) =>
          firebase
            .firestore()
            .collection(`workspaces/${workspace.workspaceId}/rooms`)
            .doc(`${roomId}`)
            .get()
        );
      } else if (!roomsArray) {
        let arr = [];
        roomsDataPromises = arr.map((roomId, key) =>
          firebase
            .firestore()
            .collection(`workspaces/${workspace.workspaceId}/rooms`)
            .doc(`${roomId}`)
            .get()
        );
      }

      Promise.all(roomsDataPromises).then((values) => {
        roomsDataArray = values.map((value) => {
          return { ...value.data(), roomId: value.id };
        });
        console.log("roomsDataArray", roomsDataArray)
        switchSort(roomsDataArray, props.sortType);
        // setChannels(roomsDataArray)
      });
    });

  };

  const {
    workspace,
    _handleAddRoom,
    _handleOpenWorkspaceDetails,
    roomsVisible,
    currentChannel,
    allChannels,
    searchTerm,
    sortType,
    currentUser,
    displayRightPanel,
    setRightPanel,
    setCurrentChannel,
    setCurrentWorkspace,
    setParticipants,
    resetData,
    setContentTypeAndValue,
    currentWorkspace,
  } = props;

  const reg = _.escapeRegExp(searchTerm);
  // console.log(reg,"Reg")
  const regex = new RegExp(reg, "gi");

  // useEffect(() => {
  //   getAllRoomsData();
  // }, []);

  const userCurrentPlan = async () => {
    const res = await axios.post(`${API_BASE_URL}${PLAN_STATUS}`, {
      user_id: currentUser?.uid,
      workspace_id: currentWorkspace.workspaceId,
    });
    setCurrentPlan(res.data.data)
  }

  useEffect(() => {
    userCurrentPlan()
  }, [])

  useEffect(() => {
    getAllRoomsData();
  }, [props.roomsVisible, props.workspace[props.roomsVisible]]);

  useEffect(() => {
    getWorkspaceData();
  }, [props.workspace]);

  useEffect(() => {
    switchSort(allChannels, sortType);
  }, [sortType]);

  useEffect(() => {
    // for default sort (when it loads for first time)
    if (
      allChannels &&
      allChannels.length &&
      allChannels.length > 0 &&
      !defaultSortDone
    ) {
      console.log("default sort");
      switchSort(allChannels, 0);
      setDefaultSortDone(true);
    }
  }, [allChannels]);

  // useEffect(() => {
  //     console.log(allChannels, "allchannels updated in worklistelements")
  // },[allChannels])

  const compareTimestampLodashLatestFirst = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) => {
      if (channel.lastMessage) {
        return channel.lastMessage.timestamp;
      } else {
        return 1000000000000;
      }
    });

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted.reverse());
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareTimestampLodashOldestFirst = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) => {
      if (channel.lastMessage) {
        return channel.lastMessage.timestamp;
      } else {
        return 1000000000000;
      }
    });

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted);
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareAlphabetLodashAtoZ = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) =>
      channel.roomName.toLowerCase()
    );
    // console.log('atoz')

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted);
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareAlphabetLodashZtoA = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) =>
      channel.roomName.toLowerCase()
    );
    // console.log('ztoa')

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted.reverse());
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const switchSort = (allChannels, sortType) => {
    // console.log(allChannels,"allChannels")
    switch (sortType) {
      case 0:
        return compareTimestampLodashLatestFirst(allChannels);

      case 1:
        return compareAlphabetLodashAtoZ(allChannels);

      case 2:
        return compareAlphabetLodashZtoA(allChannels);

      case 3:
        return compareTimestampLodashOldestFirst(allChannels);

      default:
        return compareTimestampLodashLatestFirst(allChannels);
    }
  };

  // console.log(allChannels,"before return")
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));
  const toggleSidebar = () => {
    //props.setLeftPanel(!props.displayLeftPanel);
    props.setRightPanel(true);
    console.log(displayRightPanel);
  };
  const handleAction = (ACTION) => {
    const {
      currentUser,
      setRightPanel,
      setParticipants,
      setAllMessages,
      currentWorkspace,
      setCurrentWorkspace,
      setWorkspaces
    } = props;

    const actionData = {
      workspace_id: workspace.workspaceId,
      room_id: room.room.roomId,
      user_id: currentUser.uid,
    };

    postData(`${API_BASE_URL}${ACTION}`, actionData)
      .then((response) => {
        const { status, message } = response;

        if (status === 200) {
          // console.log("my room called")
          setCurrentChannel(null);
          setRightPanel(false);
          setParticipants(null);
          setAllMessages([]);
          handleClose();
          handleCloseArchive();
          handleCloseUnArchive();

          firebase
            .firestore()
            .collection(`users/${currentUser.uid}/rooms`).get().then((querySnapshot) => {
              let userWorkspaces = [];
              querySnapshot.docs.forEach(function (doc) {
                userWorkspaces.push({ ...doc.data() });
              });

              let index;

              userWorkspaces.forEach((workspace, key) => {
                console.log("inside did update", currentWorkspace)
                if (workspace.workspaceId === currentWorkspace?.workspaceId) {
                  console.log(key, "key in userworkspaces")
                  index = key
                }
              })

              const updatedCurrentWorkspace = { ...currentWorkspace, ...userWorkspaces[index] };
              setCurrentWorkspace(_.cloneDeep(updatedCurrentWorkspace));
              setWorkspaces(currentUser.uid);
            })




        } else {
          console.log(`Error message ${ACTION} room: `, message);
        }
      })
      .catch((err) => console.error(err));
  };

  // console.log(workspace, "workspace rooms map")

  const workspaceRoomId = workspace.rooms.map(({ roomId }) => roomId);
  //console.log(allChannels);
  //console.log(currentWorkspace);
  //console.log(currentChannel);

  // if(searchTerm && searchTerm.length > 0) {
  //   console.log("search term filtered array: ",
  //   allChannels
  //   .filter((item) => {
  //     return (
  //       item.roomName.match(regex) ||
  //       (item.lastMessage &&
  //         item.lastMessage.content &&
  //         item.lastMessage.content.match(regex))
  //     );
  //   })
  //   )

  // }

  const handleRoomName = (e) => {
    setDuplicateRoomName(e.target.value)
  }

  const roomDuplicationHandler = (roomId, newRoomName) => {
    axios.post(`${API_BASE_URL}${ROOM_DUPLICATE}`, {
      workspace_id: currentWorkspace.workspaceId,
      room_id: roomId,
      new_room_name: newRoomName,
      user_id: currentUser.uid
    }).then((res) => {
      // resetting the local states
      setDuplicateRoomName("");
      setSelectedRoom(null);
      setShowDuplicateModal(false);

      // loading the room in middle and showing a toast room duplicated

      console.log(res, "response from api duplication ")
      firebase.firestore().collection(`workspaces/${res.data.data.workspace_id}/rooms`)
        .doc(res.data.data.new_room_id).get().then((doc) => {
          if (doc.exists) {
            const currentChannelDeep = _.cloneDeep({ ...doc.data(), roomId: doc.id });
            setCurrentChannel(currentChannelDeep)
            setParticipants(res.data.data.workspace_id, res.data.data.new_room_id)
            if (props.darkTheme) {
              toast.dark("Room has been duplicated", {
                position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000
              })
            } else if (!props.darkTheme) {
              toast("Room has been duplicated", {
                position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000
              })
            }

          }
        })


    })
  }

  const roomOwnerTransferHandler = (oldAdmin, newAdmin) => {
    axios.post(`${API_BASE_URL}${ROOM_TRANSFER}`, {
      workspace_id: currentWorkspace.workspaceId,
      room_id: selectedRoom.roomId,
      old_super_admin_id: oldAdmin,
      new_super_admin_id: newAdmin
    }).then((res) => {
      console.log(res, "err 1");
      if (res.status === 200) {
        if (props.darkTheme) {
          toast.dark("Room ownership transferred", {
            position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000
          })
        } else if (!props.darkTheme) {
          toast("Room ownership transferred", {
            position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000
          })
        }
        setShowTransferRoomModal(false);
        setSelectedRoomParticipants([]);
        setSelectedUser(null);
        setTransferTermsChecked(false);
      }
    }).catch((error, message) => {
      toast(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000
      })
      setShowTransferRoomModal(false);
      setSelectedRoomParticipants([]);
      setSelectedUser(null);
      setTransferTermsChecked(false);
    })
  }


  // function to fetch room analytics

  const roomAnalyticsHandler = (currentChannel) => {
    if (currentWorkspace && currentChannel) {
      firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/participants`
        )
        .onSnapshot((snapshot) => {
          let allParticipants1 = [];

          snapshot.forEach((doc) => {
            allParticipants1.push({ uid: doc.id, ...doc.data() });
          });

          // console.log("allParticipants1", allParticipants1);

          let savedPolls = [];
          let publishedPolls = [];
          let savedSchedules = [];
          let publishedSchedules = [];
          let savedReadings = [];
          let publishedReadings = [];
          let savedQuizes = [];
          let publishedQuizes = [];
          let savedAssignments = [];
          let publishedAssignments = [];
          const query = firebase
            .firestore()
            .collection(
              `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
            )
            .orderBy("timestamp", "desc");
          const queryMsg = firebase
            .firestore()
            .collection(
              `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
            )
            .orderBy("timestamp", "desc");

          query.onSnapshot((snapshot) => {
            //console.log(snapshot.docChanges());
            let changes = snapshot.docChanges();
            changes.forEach((change) => {
              if (change.type === "added") {
                if (change.doc.data().type === 3) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedPolls.unshift(json);
                } else if (change.doc.data().type === 4) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedQuizes.unshift(json);
                } else if (change.doc.data().type === 5) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedAssignments.unshift(json);
                } else if (change.doc.data().type === 2) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedReadings.unshift(json);
                } else if (change.doc.data().type === 1) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedSchedules.unshift(json);
                }
              } else if (change.type === "removed") {
                if (change.doc.data().type === 3) {
                  savedPolls = savedPolls.filter(
                    (savedPoll) => savedPoll.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 4) {
                  savedQuizes = savedQuizes.filter(
                    (savedQuiz) => savedQuiz.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 5) {
                  savedAssignments = savedAssignments.filter(
                    (savedAssignment) => savedAssignment.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 2) {
                  savedReadings = savedReadings.filter(
                    (savedReading) => savedReading.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 1) {
                  savedSchedules = savedSchedules.filter(
                    (savedSchedule) => savedSchedule.id !== change.doc.id
                  );
                }
              } else if (change.type === "modified") {
                if (change.doc.data().type === 3) {
                  let index = savedPolls.findIndex(
                    (savedPoll) => savedPoll.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedPolls.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 4) {
                  let index = savedQuizes.findIndex(
                    (savedQuiz) => savedQuiz.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedQuizes.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 5) {
                  let index = savedAssignments.findIndex(
                    (savedAssignment) => savedAssignment.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedAssignments.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 2) {
                  let index = savedReadings.findIndex(
                    (savedReading) => savedReading.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedReadings.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 1) {
                  let index = savedSchedules.findIndex(
                    (savedSchedule) => savedSchedule.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedSchedules.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                }
              }
            });
            setSavedPolls(savedPolls);
            setSavedQuizes(savedQuizes);
            setSavedReadings(savedReadings);
            setSavedAssignmnets(savedAssignments);
            setSavedSchedules(savedSchedules);
          });

          queryMsg.onSnapshot((snapshot) => {
            //console.log(snapshot.docChanges());
            let changes = snapshot.docChanges();
            changes.forEach((change) => {
              if (change.type === "added") {
                if (change.doc.data().type === 3) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedPolls.unshift(json);
                } else if (change.doc.data().type === 4) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedQuizes.unshift(json);
                } else if (change.doc.data().type === 5) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedAssignments.unshift(json);
                } else if (change.doc.data().type === 2) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedReadings.unshift(json);
                } else if (change.doc.data().type === 1) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedSchedules.unshift(json);
                }
              } else if (change.type === "removed") {
                if (change.doc.data().type === 3) {
                  publishedPolls = publishedPolls.filter(
                    (publishPoll) => publishPoll.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 4) {
                  publishedQuizes = publishedQuizes.filter(
                    (publishQuiz) => publishQuiz.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 5) {
                  publishedAssignments = publishedAssignments.filter(
                    (publishAssignment) =>
                      publishAssignment.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 2) {
                  publishedReadings = publishedReadings.filter(
                    (publishReading) => publishReading.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 1) {
                  publishedSchedules = publishedSchedules.filter(
                    (publishSchedule) => publishSchedule.id !== change.doc.id
                  );
                }
              } else if (change.type === "modified") {
                if (change.doc.data().type === 3) {
                  let index = publishedPolls.findIndex(
                    (publishPoll) => publishPoll.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedPolls.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 4) {
                  let index = publishedQuizes.findIndex(
                    (publishQuiz) => publishQuiz.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedQuizes.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 5) {
                  let index = publishedAssignments.findIndex(
                    (publishAssignment) =>
                      publishAssignment.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedAssignments.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 2) {
                  let index = publishedReadings.findIndex(
                    (publishReading) => publishReading.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedReadings.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 1) {
                  let index = publishedSchedules.findIndex(
                    (publishSchedule) => publishSchedule.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedSchedules.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                }
              }
            });
            setPublishedPolls(publishedPolls);
            setPublishedQuizes(publishedQuizes);
            setPublishedReadings(publishedReadings);
            setPublishedAssignments(publishedAssignments);
            setPublishedSchedules(publishedSchedules);
            let chartData = [
              {
                label: "Published Assignments",
                value: publishedAssignments.length,
              },
              { label: "Published Polls", value: publishedPolls.length },
              { label: "Published Quizzes", value: publishedQuizes.length },
              { label: "Published Files", value: publishedReadings.length },
            ];
            setChartData(chartData);
          });
          setAllParticipants(allParticipants1);
        });
    }
  }





  return (
    <>
      {searchTerm && searchTerm.length > 0
        ? allChannels &&
        allChannels
          .filter((item) => {
            return (
              item.roomName.match(regex) ||
              (item.lastMessage &&
                item.lastMessage.content &&
                item.lastMessage.content.match(regex))
            );
          })
          .map((room, key) => {
            return (
              <><div className="mb-3">
                <RoomItem
                  roomData={room}
                  key={key}
                  index={key}
                  currentChannel={currentChannel}
                  workspace={workspace}
                  workspaceData={workspaceData}
                  allChannels={allChannels}
                  setChannels={setChannels}
                  sortType={sortType}
                  displayRightPanel={displayRightPanel}
                  setRightPanel={setRightPanel}
                  setCurrentChannel={setCurrentChannel}
                  setCurrentWorkspace={setCurrentWorkspace}
                  setParticipants={setParticipants}
                  setContentTypeAndValue={setContentTypeAndValue}
                  searchTerm={searchTerm}
                // {...props}
                />
                <Dropdown
                  className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer "
                  style={{ width: "20px", marginTop: "-40px", marginRight: "15px" }}
                >
                  <Dropdown.Toggle
                    size="sm"
                    variant="white"
                    as={CustomToggle}
                  />
                  <Dropdown.Menu className="bg-card-theme text-theme">
                    <Dropdown.Item
                      className="small bg-card-theme text-theme"
                      onClick={() => {
                        console.log(room, "my room")
                        var id = room.roomId;
                        toggleSidebar();
                        setCurrentChannel({ ...room, id });
                        setCurrentWorkspace({
                          ...workspaceData,
                          ...workspace,
                        });
                        setParticipants(workspace.workspaceId, id);
                        resetData();
                        setContentTypeAndValue("roomDetails", true);
                        console.log(props.currentChannel, "my room channl")
                      }}
                    >
                      Room Details
                    </Dropdown.Item>
                    {room.createdBy === currentUser.uid ? (
                      <Dropdown.Item
                        className="small bg-card-theme text-theme"
                        onClick={() => {
                          setShowTransferRoomModal(true);
                          setSelectedRoom(room);

                          axios.post(`${API_BASE_URL}${ROOMS_PARTICIPANTS}`, {
                            workspace_id: currentWorkspace.workspaceId,
                            room_id: room?.roomId,
                          }).then((res) => {
                            if (res.status === 200) {
                              setSelectedRoomParticipants(res.data.data);
                            } else {
                              setSelectedRoomParticipants([]);
                            }
                          }).catch(() => {
                            setSelectedRoomParticipants([]);
                          })

                        }}
                      >
                        Transfer Ownership{" "}
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item
                        className="small bg-card-theme text-theme"
                        onClick={() => {
                          handleShow();
                          setRoom({ room });
                        }}
                      >
                        Leave Room
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              </>
            );
          })
        : allChannels &&
        allChannels.map((room, key) => {
          return (
            <><div className="mb-3" >
              <RoomItem

                roomData={room}
                key={key}
                index={key}
                currentChannel={currentChannel}
                workspace={workspace}
                workspaceData={workspaceData}
                allChannels={allChannels}
                setChannels={setChannels}
                sortType={sortType}
                displayRightPanel={displayRightPanel}
                setRightPanel={setRightPanel}
                setCurrentChannel={setCurrentChannel}
                setCurrentWorkspace={setCurrentWorkspace}
                setParticipants={setParticipants}
                setContentTypeAndValue={setContentTypeAndValue}
              // {...props}
              />
              <Dropdown
                className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer "
                style={{ width: "20px", marginTop: "-40px", marginRight: "15px" }}
              >
                <Dropdown.Toggle
                  size="sm"
                  variant="white"
                  as={CustomToggle}
                />
                <Dropdown.Menu className="bg-card-theme text-theme">
                  <Dropdown.Item
                    className="small bg-card-theme text-theme"
                    onClick={() => {
                      //console.log(room, "my room")
                      var id = room.roomId;
                      //console.log(id,"my id")
                      toggleSidebar();
                      setCurrentChannel({ ...room, id });
                      setCurrentWorkspace({ ...workspaceData, ...workspace });
                      setParticipants(workspace.workspaceId, id);
                      //resetData();
                      setContentTypeAndValue("roomDetails", true);
                      //console.log(props,"my room channel from workspace")
                    }}
                  >
                    Room Details
                  </Dropdown.Item>
                  {room.createdBy === currentUser.uid ? (
                    <Dropdown.Item className="small bg-card-theme text-theme"
                      onClick={() => {
                        setShowDuplicateModal(true);
                        setSelectedRoom(room)
                        roomAnalyticsHandler(room)
                      }}
                    >
                      Duplicate Room{" "}
                    </Dropdown.Item>
                  ) : null}

                  {room.createdBy === currentUser.uid ? (
                    <Dropdown.Item
                      className="small bg-card-theme text-theme"
                      onClick={() => {
                        setShowTransferRoomModal(true);
                        setSelectedRoom(room)

                        axios.post(`${API_BASE_URL}${ROOMS_PARTICIPANTS}`, {
                          workspace_id: currentWorkspace.workspaceId,
                          room_id: room?.roomId,
                        }).then((res) => {
                          if (res.status === 200) {
                            setSelectedRoomParticipants(res.data.data);
                          } else {
                            setSelectedRoomParticipants([]);
                          }
                        }).catch(() => {
                          setSelectedRoomParticipants([]);
                        })
                      }}

                    >
                      Transfer Ownership{" "}
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      className="small bg-card-theme text-theme"
                      onClick={() => {
                        handleShow();
                        setRoom({ room });
                      }}
                    >
                      Leave Room
                    </Dropdown.Item>
                  )}
                  <>
                    {workspaceRoomId.includes(room.roomId) ? (
                      <>
                        <Dropdown.Item
                          className="small bg-card-theme text-theme"
                          onClick={() => {
                            handleShowArchive();
                            setRoom({ room });
                          }}
                        >
                          Archive Room
                        </Dropdown.Item>
                      </>
                    ) : (
                      <Dropdown.Item
                        className="small bg-card-theme text-theme"
                        onClick={() => {
                          handleShowUnArchive();
                          setRoom({ room });
                        }}
                      >
                        Unarchive Room
                      </Dropdown.Item>
                    )}
                  </>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            </>
          );
        })}
      <>
        <Modal className="border-0 pb-0" show={show} onHide={handleClose}>
          <Modal.Body className="bg-theme text-theme border-0 pb-0">
            <div title="Close" className="pointer d-flex justify-content-end mb-0" onClick={() => handleClose()}>
              <FontAwesomeIcon className="text-theme" icon={faTimes} />
            </div>
            <h3 className="mb-3">Leave Room</h3>
            Are you Sure want to Leave this Room?
          </Modal.Body>
          <Modal.Footer className="border-0 pb-0 bg-theme text-theme">
            <Button className="mb-3" variant="primary" onClick={() => handleAction(ROOM_LEAVE)}>
              Leave Room
            </Button>
            <Button className="mb-3" variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal className="border-0 pb-0" show={showArchive} onHide={handleCloseArchive}>
          <Modal.Body className="bg-theme text-theme ">
            <div title="Close" className="pointer d-flex justify-content-end mb-0" onClick={() => handleCloseArchive()}>
              <FontAwesomeIcon className="text-theme" icon={faTimes} />
            </div>
            <h3 className="mb-3">Archive Room</h3>
            Are you sure you want to Archive the room?
          </Modal.Body>
          <Modal.Footer className="border-0 pb-0 bg-theme text-theme">
            <Button
              className="mb-3"
              variant="primary"
              onClick={() => handleAction(USER_ROOM_ARCHIVE)}
            >
              Archive Room
            </Button>
            <Button className="mb-3" variant="secondary" onClick={handleCloseArchive}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal className="border-0 pb-0" show={showUnArchive} onHide={handleCloseUnArchive}>
          <Modal.Body className="border-0 pb-0 bg-card-theme text-theme ">
            <div title="Close" className="pointer d-flex justify-content-end mb-0" onClick={() => handleCloseUnArchive()}>
              <FontAwesomeIcon className="text-theme" icon={faTimes} />
            </div>
            <h3 className="mb-3">UnArchive Room</h3>
            Are you sure you want to Un-archive the room?
          </Modal.Body>
          <Modal.Footer className="border-0 pb-0 bg-card-theme text-theme">
            <Button
              className="mb-3"
              variant="primary"
              onClick={() => handleAction(USER_ROOM_UNARCHIVE)}
            >
              Unarchive Room
            </Button>
            <Button className="mb-3" variant="secondary" onClick={handleCloseUnArchive}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal
          size="lg"
          centered
          show={showDuplicateModal}
        >
          <Modal.Header className="bg-theme border-0 d-flex justify-content-between align-items-center pb-0">
            <Modal.Title>
              <h5 className="text-theme">Duplicate {selectedRoom?.roomName} ?</h5>
            </Modal.Title>
            <div title="Close" className="pointer" onClick={() => { setShowDuplicateModal(false); }}>
              <FontAwesomeIcon className="text-theme" icon={faTimes} />
            </div>
          </Modal.Header>
          <Modal.Body
            className="overflow-auto list-unstyled bg-theme p-5"
          >


            {/* Current room's stats */}

            <div className="row mb-3 p-2 border-1 mb-2 rounded shadow bg-card-theme text-theme">
              <div className="col-12 mb-2 pb-2 border-bottom-1">
                <strong>Apps</strong>
              </div>
              <div className="col-3 border-right-1">
                <div className="">
                  <h3 className="mb-0">{publishedPolls.length}</h3>
                  <div className="d-flex justify-content-between">
                    <span className="small">Polls</span>
                    <span className="smaller mt-1 text-theme-lighter">
                      {savedPolls.length} SAVED
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-3 border-right-1">
                <div className="">
                  <h3 className="mb-0">{publishedAssignments.length}</h3>
                  <div className="d-flex justify-content-between">
                    <span className="small d-block">Assignments</span>
                    <span className="smaller mt-1 d-block text-theme-lighter">
                      {savedAssignments.length} SAVED
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-3 border-right-1">
                <div className="">
                  <h3 className="mb-0">{publishedQuizes.length}</h3>
                  <div className="d-flex justify-content-between">
                    <span className="small d-block">Quizzes</span>
                    <span className="smaller mt-1 d-block text-theme-lighter">
                      {savedQuizes.length} SAVED
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="">
                  <h3 className="mb-0">{publishedReadings.length}</h3>
                  <div className="d-flex justify-content-between">
                    <span className="small d-block">Files</span>
                    <span className="smaller mt-1 d-block text-theme-lighter">
                      {savedReadings.length} SAVED
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="col-3">
                  <div className="">
                    <h3 className="mb-0">{publishedSchedules.length}</h3>
                    <span className="small d-block">Schedule</span>
                    <span className="smaller d-block text-theme-lighter">{savedSchedules.length} saved</span>
                  </div>
                </div> */}
            </div>

            <div>
              <span className="text-theme">
                All the apps from previous room will be in saved category and linked to a folder called "{selectedRoom?.roomName} Folder"
              </span>
            </div>
            {/* current room's stats end */}
            <InputGroup className="outerPill p-2">
              <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                <div className="flex-grow-1">
                  <Form.Control
                    type="text"
                    className="border-0 px-0 bg-theme text-theme"
                    onChange={(e) => handleRoomName(e)}
                    placeholder={"Room name"}
                    value={duplicateRoomName}
                    style={{ margin: 0 }}
                  />
                </div>
                {duplicateRoomName.length > 0 && (
                  <div
                    className="py-2 pl-2 pr-3"
                    onClick={() => setDuplicateRoomName('')}
                  >
                    <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                  </div>
                )}
              </div>
            </InputGroup>

            <div className="text-right my-2">
              <Button
                className="btn-sm"
                title="Duplicate"
                disabled={!currentPlan?.premiumWorkspace && currentPlan?.planDetails?.rooms?.available <= 0}
                onClick={() => { roomDuplicationHandler(selectedRoom.roomId, duplicateRoomName) }}
              >
                Duplicate
              </Button>
            </div>
            {!currentPlan?.premiumWorkspace && currentPlan?.planDetails?.rooms?.available <= 0 &&
              <p className="text-white mt-3">
                <b>Note: </b>
                You have already created maximum number of rooms allowed. To
                create more please write to us at{" "}
                <a target="_blank" href="mailto:hello@igesia.co">
                  hello@igesia.co
                </a>
              </p>
            }
          </Modal.Body>
        </Modal>

        {/* modal for transfer room ownership */}

        <Modal
          size="md"
          centered
          show={showTransferRoomModal}
        >
          <Modal.Header className="bg-theme border-0 d-flex justify-content-between align-items-center pb-0">
            <Modal.Title>
              <h5 className="text-theme">Transfer ownership of {selectedRoom?.roomName} ?</h5>
            </Modal.Title>
            <div title="Close" className="pointer" onClick={() => { setShowTransferRoomModal(false); setSelectedUser(null); setTransferTermsChecked(false) }}>
              <FontAwesomeIcon className="text-theme" icon={faTimes} />
            </div>
          </Modal.Header>
          <Modal.Body
            className="list-unstyled bg-theme p-3"
          >
            <div className="overflow-auto" style={{ maxHeight: '30vh' }} >
              {
                selectedRoomParticipants.map((participant, index) => (
                  <div onClick={() => setSelectedUser(participant)} className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    <input className="mr-2" type="checkbox" onClick={() => setSelectedUser(participant)} checked={selectedUser?.uid === participant.uid ? true : false}></input>
                    <div onClick={() => setSelectedUser(participant)} className="d-flex p-2 user-select-none">
                      <Image width="40" height="40"
                        roundedCircle
                        src={participant.avatar}
                        spaced="right"
                        style={{ background: 'white' }} />
                      <div className="flex-grow-1 mx-3 align-self-center">
                        <p className='mb-0 text-theme'>{participant.displayName}</p>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>

            <div className="text-right my-2 d-flex align-items-center">
              <input onClick={() => setTransferTermsChecked((prev) => !prev)} id="transfer-checkbox" className="mr-1" type="checkbox" checked={transferTermsChecked}></input>
              <label htmlFor="transfer-checkbox" className="user-select-none"  >
                I agree to transfer the ownership of {selectedRoom?.roomName} to the selected user.
              </label>
            </div>
            <div className="text-right my-2">
              =
              <Button
                className="btn-sm"
                title="Transfer"
                onClick={() => {
                  if (selectedUser && transferTermsChecked) {
                    roomOwnerTransferHandler(selectedRoom.createdBy, selectedUser.uid);
                  }
                }}>Transfer</Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  displayRightPanel: state.platform.displayRightPanel,
  roomsVisible: state.workspace.roomsVisible,
  currentChannel: state.channel.currentChannel,
  allChannels: state.channel.allChannels,
  platform: state.channel.platform,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, {
  setLeftPanel,
  setAllMessages,
  setContentTypeAndValue,
  setRightPanel,
  setChannels,
  setCurrentChannel,
  setCurrentWorkspace,
  setParticipants,
  resetData,
  setWorkspaces
})(WorkspaceListElement);
