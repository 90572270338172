import * as actionTypes from "./types";

/* Quiz Actions */
export const setQuizPlusId = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_ID,
    payload: {
      id: value,
    },
  };
};

export const setQuizPlusTitle = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_PLUS_TITLE,
    payload: {
      quizPlusTitle: value,
    },
  };
};

export const setQuizPlusQuestions = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_PLUS_QUESTIONS,
    payload: {
      questions: value,
    },
  };
};
export const setQuizPlusQuestionTypes = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_PLUS_QUESTION_TYPES,
    payload: {
      questionTypes: value,
    },
  };
};

export const setQuizPlusTypes = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_PLUS_TYPES,
    payload: {
      types: value,
    },
  };
};


export const setQuizPlusDescriptions = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_PLUS_DESCRIPTIONS,
    payload: {
      descriptions: value,
    },
  };
};

export const setQuizPlusOptions = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_PLUS_OPTIONS,
    payload: {
      options: value,
    },
  };
};
export const setQuizPlusOptionsImages = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_PLUS_OPTIONS_IMAGES,
    payload: {
      imageOptions: value,
    },
  };
};

export const setQuizPlusPreviewImages = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_PLUS_OPTIONS_PREVIEW,
    payload: {
      previewImageOptions: value,
    },
  };
};

export const setQuizPlusQuestionImages = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_PLUS_QUESTION_IMAGES,
    payload: {
      questionImages: value,
    },
  };
};

export const setQuizPlusQuestionPreviewImages = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_PLUS_QUESTION_PREVIEW_IMAGES,
    payload: {
      questionPreviewImages: value,
    },
  };
};

export const setQuizPlusRandomize = (value) => {
  return {
    type: actionTypes.SET_QUIZ_PLUS_RANDOMIZE,
    payload: {
      randomize: value,
    },
  };
};

export const resetQuizPlus = () => {
  return {
    type: actionTypes.RESET_QUIZ_PLUS,
    payload: {},
  };
};

export const setShowDescriptionAfterSelecting = (value) => {
  return {
    type: actionTypes.SET_QUIZ_PLUS_DESCRIPTION_AFTER_SELECTION,
    payload: {
      showDescriptionAfterSelecting: value
    },
  };
};

export const setPercentageVisibleToParticipants = (value) => {
  return {
    type: actionTypes.SET_QUIZ_PLUS_PERCENTAGE_VISIBLE_TO_PARTICIPANTS,
    payload: {
      percentageVisibleToParticipants: value
    },
  };
};

export const setIsNotEditable = (value) => {
  return {
    type: actionTypes.SET_QUIZ_PLUS_IS_NOT_EDITABLE,
    payload: {
      isNotEditable: value
    },
  };
}