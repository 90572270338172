import React from "react";
import { Button, Form, InputGroup, FormControl, Spinner, Badge } from "react-bootstrap";
import firebase from "firebase";
import uuidv4 from "uuid/v4";
import mime from "mime-types";
import moment from "moment";

class Project extends React.Component {
    state = {
        storageRef: firebase.storage().ref(),
        uploadTask: null,
        uploadState: "",
        percentUploaded: 0,
        message: "",
        loading: false,
        errors: [],
        file: null,
        authorized: ["image/jpeg", "image/jpg", "image/png", "image/svg+xml", "image/gif", "application/msword", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
        value: "",
        extension: "",
        admin: false,
        responses: []
    };

    getResponses = (responses) => {
        responses && Object.entries(responses).forEach(([key, value]) => {
            this.setState({ responses: [...this.state.responses, { uid: key, ...value }] })
        })
    }

    getName = (key) => {
        let name = "";
        firebase.database().ref("users").child(key).on("value", snap => {
            if (snap.val()) {
                name = snap.val().name ? snap.val().name : `${snap.val().firstName} ${snap.val().lastName}`;
            }
        });
        return name;
    }

    timeFromNow = timestamp => {
        if (moment(Date.now()).diff(moment(timestamp), 'days') < 1) return moment(timestamp).fromNow()
        else if (moment(Date.now()).diff(moment(timestamp), 'days') < 2) return "yesterday"
        else if (moment(Date.now()).diff(moment(timestamp), 'days') < 7) return moment(timestamp).format('dddd')
        else return moment(timestamp).format("MMM DD YYYY")
    };

    displayResponse = (response) => {
        return (
            <div className="mb-3">
                <h5 className="mt-0">{this.getName(response.uid)}</h5>
                <a className="mt-0 mb-0" href={response.value} target="_blank">Click here to see file attached</a>
                <p className="mb-0 mt-0 small">Replied <i>{this.timeFromNow(response.responseTime)}</i></p>

            </div>
        )
    }

    componentDidMount() {
        const { user, item } = this.props
        if (user == item.user.id) {
            this.setState({ admin: true })
            item.response && this.getResponses(item.response)
        }
    }

    componentWillUnmount() {
        if (this.state.uploadTask !== null) {
            this.state.uploadTask.cancel();
            this.setState({ uploadTask: null });
        }
    }

    addFile = event => {
        const file = event.target.files[0];
        if (file) {
            this.setState({ file });
            if (this.isAuthorized(file.name)) {
                const metadata = { contentType: mime.lookup(file.name) };
                this.uploadFile(file, metadata);
                this.clearFile();
            }
        }
    };

    isAuthorized = filename => {
        if (this.state.authorized.includes(mime.lookup(filename))) {
            console.log(mime.lookup(filename).split("/")[1])
            this.setState({ extension: mime.lookup(filename).split("/")[1] });
        }
        return this.state.authorized.includes(mime.lookup(filename));
    }

    uploadFile = (file, metadata) => {
        const filePath = `chat/public/${uuidv4()}.${this.state.extension}`;

        this.setState(
            {
                uploadState: "uploading",
                uploadTask: this.state.storageRef.child(filePath).put(file, metadata)
            },
            () => {
                this.state.uploadTask.on(
                    "state_changed",
                    snap => {
                        const percentUploaded = Math.round(
                            (snap.bytesTransferred / snap.totalBytes) * 100
                        );
                        this.setState({ percentUploaded });
                    },
                    err => {
                        console.error(err);
                        this.setState({
                            errors: this.state.errors.concat(err),
                            uploadState: "error",
                            uploadTask: null
                        });
                    },
                    () => {
                        this.state.uploadTask.snapshot.ref
                            .getDownloadURL()
                            .then(downloadUrl => {
                                this.setState({ value: downloadUrl });
                                console.log(downloadUrl)
                            })
                            .catch(err => {
                                console.error(err);
                                this.setState({
                                    errors: this.state.errors.concat(err),
                                    uploadState: "error",
                                    uploadTask: null
                                });
                            });
                    }
                );
            }
        );
    };

    clearFile = () => this.setState({ file: null });

    sendFile = () => {
        const { file } = this.state;
        console.log('file recieved to send');
        if (file !== null) {
            console.log('file checked');
            if (this.isAuthorized(file.name)) {
                const metadata = { contentType: mime.lookup(file.name) };
                this.uploadFile(file, metadata);
                this.clearFile();
            }
        }
    };

    getResult = (response, user) => {
        return response && Object.keys(response).includes(user)
    };

    getValue = (response, user) => {
        return response && <h5><p><a href={response[user] && response[user].value} target="_blank">Click here, to see you file!</a></p><p><i>{moment(response[user] && response[user].responseTime).format("hh:mm A DD/MM/YYYY")}</i></p></h5>
    }

    render() {
        const { projectname, uid, taskResponse, item, user } = this.props;
        const { value, percentUploaded, uploadState, admin, responses } = this.state;
        return (
            <>
                <div className="taskBox">
                    <Form className="project">
                        <h5 className="mt-0">{projectname}</h5>
                        {admin
                            ? responses.length
                                ? <Badge variant="secondary">Responses</Badge> : <p className="mt-4 mb-1">No responses yet</p>
                            : null
                        }
                        {admin
                            ? responses.map(response => {
                                return this.displayResponse(response)
                            })
                            : item && this.getResult(item.response, user)
                                ? <div>{this.getValue(item.response, user)}</div>
                                : <>
                                    <InputGroup className="mb-2">
                                        <FormControl
                                            aria-label="Default"
                                            aria-describedby="inputGroup-sizing-default"
                                            type="file"
                                            name="previewImage"
                                            onChange={this.addFile}
                                        />
                                    </InputGroup>
                                    <Button variant="primary" size="sm" disabled={value != "" ? false : true} onClick={() => taskResponse(uid, value)} >
                                        {percentUploaded != 100 && uploadState == "uploading"
                                            ? <>
                                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />Uploading...
                                    </>
                                            : <>Submit</>}
                                    </Button>
                                </>
                        }
                    </Form>
                </div>
            </>
        )
    }
};

export default Project;
