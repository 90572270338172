import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { connect } from "react-redux";
import { Offline, Online } from "react-detect-offline";
import NoInternet from "../../offline/NoInternet.component";
import {
  setCurrentChannel,
  setPrivateChannel,
  updateCurrentChannel,
  setLeftPanel,
  setCurrentWorkspace,
  setRoomsVisible,
  setAllMessages,
  setRightPanel,
  setParticipants,
  setUser,
  setChannels,
  setWorkspaces,
  setWorkspaceSlug,
} from "../../actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  faArrowLeft,
  faPlus,
  faImage,
  faFileExcel,
  faFilePowerpoint,
  faCommentAlt,
  faFilePdf,
  faFileWord,
  faTasks,
  faPoll,
  faCalendarAlt,
  faFileAlt,
  faSearch,
  faTimes,
  faPlusCircle,
  faArchive,
  faHome,
  faSortAmountDown,
  faCheckCircle,
  faSortAlphaDownAlt,
  faSortAlphaDown,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faCircle,
  faFilter,
  faFolderOpen,
  faSignOutAlt,
  faLink,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";

import {
  Image,
  Badge,
  InputGroup,
  Form,
  Button,
  OverlayTrigger,
  Popover,
  NavDropdown,
  Dropdown,
  DropdownButton,
  ListGroup,
  Modal,
} from "react-bootstrap";
import plus from "../../plus.svg";
import moment from "moment";
import UIfx from "uifx";
import bell from "./bell.mp3";
import AddARoom from "./AddARoom";
import UserProfile from "./UserProfile";
import AddWorkspace from "./AddWorkspace";
import WorkspaceListElement from "./WorkspaceListElement";
import OpenWorkspaceDetails from "./OpenWorkspaceDetails";
import JoinUsingLink from "./JoinUsingLink";
import PendingRoomListElement from "./PendingRoomListElement";
import VersionUpdates from "./VersionUpdates";
import BrowserWarning from "./BrowserWarning";
import CreateFolderIntoMyDrive from "./driveCreateFolder";
import UploadFilesIntoMyDrive from "./driveUploadFilesModal";

import "../App.scss";

import { API_BASE_URL, PLAN_STATUS } from "../../config/index";

import { BrowserView, MobileView } from "react-device-detect";
import { ACTIVE, ARCHIVE } from "../../actions/types";
import _ from "lodash";
import MyDrive from "./MyDrive";
import StorageNotice from "../StorageNoticeModal";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { loadState, saveState } from "../../services/index";

const pdf_type = ["application/pdf"];

const word_type = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const excel_type = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const powerpoint_type = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

const FileIcon = ({ message }) => {
  if (word_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFileWord} className="text-primary" />;
  if (pdf_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFilePdf} className="text-danger" />;
  if (powerpoint_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFilePowerpoint} className="text-danger" />;
  if (excel_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFileExcel} className="text-success" />;
  return <div></div>;
};

const complete_month_name = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const short_month_name = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const strip = (value) => {
  if (value.image) {
    return value.content ? (
      <span>
        <FontAwesomeIcon icon={faImage} />{" "}
        {value.content > 30 ? value.content.slice(0, 30) + "…" : value.content}
      </span>
    ) : (
      <span>
        <FontAwesomeIcon icon={faImage} /> Photo
      </span>
    );
  } else if (value.file) {
    return value.content ? (
      <span>
        <FileIcon message={value} />{" "}
        {value.content > 30 ? value.content.slice(0, 30) + "…" : value.content}
      </span>
    ) : (
      <span>
        <FileIcon message={value} /> {value.metaData && value.metaData.name}
      </span>
    );
  } else if (value.type === "Quiz") {
    return (
      <span>
        <FontAwesomeIcon icon={faTasks} className="text-dark" />{" "}
        {value.quiz_name}
      </span>
    );
  } else if (value.type === "Poll" || value.hasOwnProperty("poll")) {
    return (
      <span>
        <FontAwesomeIcon icon={faPoll} className="text-dark" /> {value.poll}
      </span>
    );
  } else if (value.type === "Reading" || value.hasOwnProperty("readings")) {
    return (
      <span>
        <FontAwesomeIcon icon={faClipboard} className="text-dark" />{" "}
        {value.readings && value.readings.length} files(s)
      </span>
    );
  } else if (value.type === "Schedule" || value.hasOwnProperty("schedule")) {
    return (
      <span>
        <FontAwesomeIcon icon={faCalendarAlt} className="text-dark" />{" "}
        {short_month_name[moment(value.deadline).format("M") - 1]}{" "}
        {moment(value.deadline).format("DD, YYYY")} - {value.schedule}
      </span>
    );
  } else if (value.type === "Assignment" || value.hasOwnProperty("questions")) {
    return (
      <span>
        <FontAwesomeIcon icon={faFileAlt} className="text-dark" />{" "}
        {value.questions && value.questions.length} question(s)
      </span>
    );
  } else {
    return value.content.length > 30
      ? value.content.slice(0, 30) + "..."
      : value.content;
  }
  // return (value)
  //   ? value.length > 30 ? value.slice(0, 30) + '…' : value
  //   : '[TASK]';
};

const timeFromNow = (timestamp) => {
  //if (moment(Date.now()).diff(moment(timestamp), 'days') < 1) return moment(timestamp).fromNow()
  if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
    return moment(timestamp).format("h:mm A");
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
    return "yesterday";
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
    return moment(timestamp).format("dddd");
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 365)
    return moment(timestamp).format("DD MMM");
  else return moment(timestamp).format("MMM YY");
};

function RoomLiveStatus(props) {
  const [isLive, setIsLive] = useState(false);

  useEffect(() => {
    firebase
      .firestore()
      .collection("liveSessions")
      .doc(`${props.channelId}`)
      .onSnapshot(function (doc) {
        if (doc.exists) {
          // console.log("SESSION DATA:", doc.data())
          setIsLive(true);
        } else {
          setIsLive(false);
        }
      });
  }, []);

  return (
    <span className="text-danger font-weight-bold">{isLive ? "Live" : ""}</span>
  );
}

class Rooms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameError: "",
      emailError: "",
      activeChannel: "",
      user: this.props.currentUser,
      channel: null,
      channels: [],
      userPic: this.props.currentUser && this.props.currentUser.photoURL,
      userName: this.props.currentUser && this.props.currentUser.displayName,

      channelsRef: firebase.database().ref("channels"),
      messagesRef: firebase.database().ref("messages"),
      typingRef: firebase.database().ref("typing"),
      liveRef: firebase.database().ref("live"),

      notifications: [],

      uploadedCroppedImage: "",
      firstLoad: true,

      isRoomsVisible: true,
      isActionVisible: false,
      actionType: "",

      liveChannels: [],
      users: [],

      searchTerm: "",
      searchLoading: false,
      searchResults: [],

      beep: new UIfx(bell, {
        volume: 1.0, // number between 0.0 ~ 1.0
        throttleMs: 0,
      }),

      workspaces: [],
      rooms: [],
      userWorkspaces: [],
      pendingRooms: [],
      sortType: 0,
      currentWorkspaceIndex: null,
      unsubscribe: null,
      showConversionModal: false,
      firstName: "",
      lastName: "",
      convertEmail: "",
      convertPassword: "",
      currentConversionStep: 0,

      can_create: true,
      roomLoading: true,
    };
  }

  displayData = [];

  _handleStateChange = (attr, val) => this.setState({ [attr]: val });

  async componentDidMount() {
    // const { workspaces } = this.state;
    const {
      currentUser,
      currentChannel,
      setAllMessages,
      setCurrentChannel,
      currentWorkspace,
      workspaces,
      currentSubscription,
    } = this.props;

    this.addListeners();

    const _handleStateChange = this._handleStateChange;

    if (workspaces && workspaces.length > 0) {
      const tempWorkspaces = await Promise.all(
        workspaces.map(async (workspace) => {
          const workspaceData = (
            await firebase
              .firestore()
              .collection("workspaces")
              .doc(workspace.workspaceId)
              .get()
          ).data();
          const workspaceDataTemplate = {
            value: workspace.workspaceId,
            label: (
              <div className="d-flex align-items-center">
                <img
                  alt=""
                  className="mr-2 align-items-center rounded-circle bg-white"
                  style={{ width: "30px" }}
                  src={workspaceData.workspacePic}
                ></img>
                <span>{workspaceData.workspaceName}</span>
              </div>
            ),
          };
          return workspaceDataTemplate;
        })
      );
      _handleStateChange("workspaces", tempWorkspaces);
      // console.log(this.state.workspaces, "workspaces before statechange");
    }

    if (workspaces && workspaces.length > 0 && this.props.currentWorkspace) {
      workspaces.forEach((workspace, index) => {
        // console.log(workspace, "workspace in index did mount");
        if (workspace.workspaceId === this.props.currentWorkspace.workspaceId) {
          _handleStateChange("currentWorkspaceIndex", index);
        }
      });
    }

    firebase
      .firestore()
      .collection(`users/${currentUser.uid}/rooms`)
      .onSnapshot(function (snapshot) {
        let userWorkspaces = [];
        snapshot.forEach(function (doc) {
          userWorkspaces.push({ ...doc.data() });
        });
        // console.log("userWorkspaces", userWorkspaces);
        // if current roomid is not in workspace then remove null the current channel

        let index;

        userWorkspaces.forEach((workspace, key) => {
          // console.log("inside did mount", workspace, key, currentWorkspace);
          if (workspace.workspaceId === currentWorkspace?.workspaceId) {
            index = key;
          }
        });
        let roomIds = _.map(userWorkspaces[index]?.rooms, "roomId");
        const roomIdsArchived = _.map(
          userWorkspaces[index]?.archivedRooms,
          "roomId"
        );
        // console.log(roomIds, "roomIds");
        roomIds = [...roomIds, ...roomIdsArchived];
        //  console.log(currentChannel ? currentChannel.roomId : "no");
        if (currentChannel && !roomIds.includes(currentChannel.roomId)) {
          // console.log("clearing current channel");
          setAllMessages([]);
          setCurrentChannel(null);
        } else {
          console.log("rooms", currentChannel);
        }

        _handleStateChange("userWorkspaces", userWorkspaces);
      });

    firebase
      .firestore()
      .collection("users")
      .doc(`${currentUser.uid}`)
      .onSnapshot(function (snapshot) {
        let pendingRooms = [];

        if (snapshot.exists) {
          // console.log("User details changed: ", snapshot.data())

          _handleStateChange("userPic", snapshot.data().avatar);
          _handleStateChange("userName", snapshot.data().displayName);

          if (snapshot.data().pendingRooms)
            pendingRooms = Object.values(snapshot.data().pendingRooms);
        }
        _handleStateChange("pendingRooms", pendingRooms);
      });
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      currentUser,
      currentChannel,
      setAllMessages,
      setCurrentChannel,
      currentWorkspace,
      workspaces,
      currentSubscription,
    } = this.props;
    if (
      prevProps.currentChannel &&
      currentChannel &&
      prevProps.currentChannel !== currentChannel
    ) {
      // console.log("did update rooms");
      const { workspaces } = this.state;
      const { currentUser, currentChannel, setAllMessages, setCurrentChannel } =
        this.props;
      this.addListeners();

      const _handleStateChange = this._handleStateChange;

      firebase
        .firestore()
        .collection(`users/${currentUser.uid}/rooms`)
        .onSnapshot(function (snapshot) {
          let userWorkspaces = [];
          snapshot.forEach(function (doc) {
            userWorkspaces.push({ ...doc.data() });
          });
          // console.log("userWorkspaces", userWorkspaces);
          // if current roomid is not in workspace then remove null the current channel

          let index;

          userWorkspaces.forEach((workspace, key) => {
            // console.log("inside did update", currentWorkspace);
            if (workspace.workspaceId === currentWorkspace?.workspaceId) {
              // console.log(key, "key in userworkspaces");
              index = key;
            }
          });

          let roomIds = _.map(userWorkspaces[index]?.rooms, "roomId");
          // console.log(roomIds, "roomIds");
          const roomIdsArchived = _.map(
            userWorkspaces[index]?.archivedRooms,
            "roomId"
          );
          //  console.log(roomIds, "roomIds");
          roomIds = [...roomIds, ...roomIdsArchived];
          // console.log(roomIds, "roomIds");

          // console.log(currentChannel ? currentChannel.roomId : "no");
          if (currentChannel && !roomIds.includes(currentChannel.roomId)) {
            console.log("clearing current channel");
            setAllMessages([]);
            setCurrentChannel(null);
          }
          _handleStateChange("userWorkspaces", userWorkspaces);
        });

      firebase
        .firestore()
        .collection("users")
        .doc(`${currentUser.uid}`)
        .onSnapshot(function (snapshot) {
          let pendingRooms = [];

          if (snapshot.exists) {
            // console.log("User details changed: ", snapshot.data())

            _handleStateChange("userPic", snapshot.data().avatar);
            _handleStateChange("userName", snapshot.data().displayName);

            if (snapshot.data().pendingRooms)
              pendingRooms = Object.values(snapshot.data().pendingRooms);
          }
          _handleStateChange("pendingRooms", pendingRooms);
        });
    }

    //console.log("in did update of rooms.js", prevProps, workspaces);
    const tempWorkspaces = [];
    if (
      prevProps.workspaces.length !== workspaces.length &&
      workspaces.length > 0
    ) {
      const tempWorkspaces = await Promise.all(
        workspaces.map(async (workspace) => {
          const workspaceData = (
            await firebase
              .firestore()
              .collection("workspaces")
              .doc(workspace.workspaceId)
              .get()
          ).data();
          const workspaceDataTemplate = {
            value: workspace.workspaceId,
            label: (
              <div className="d-flex align-items-center">
                <img
                  alt=""
                  className="mr-2 align-items-center rounded-circle bg-white"
                  style={{ width: "30px" }}
                  src={workspaceData.workspacePic}
                ></img>
                <span>{workspaceData.workspaceName}</span>
              </div>
            ),
          };
          return workspaceDataTemplate;
        })
      );
      this._handleStateChange("workspaces", tempWorkspaces);
    }

    //console.log(
    // prevProps.workspaces.length,
    // workspaces.length,
    // currentWorkspace,
    // "prevProps.workspaces.length"
    //);
    if (
      workspaces.length > 0 &&
      currentWorkspace &&
      this.state.currentWorkspaceIndex === null
    ) {
      workspaces.forEach((workspace, index) => {
        console.log(workspace, "workspace in index did update");
        if (workspace.workspaceId === currentWorkspace.workspaceId) {
          this._handleStateChange("currentWorkspaceIndex", index);
        }
      });
    }

    if (
      workspaces.length > 0 &&
      currentWorkspace &&
      prevProps.currentWorkspace?.workspaceId !== currentWorkspace.workspaceId
    ) {
      workspaces.forEach((workspace, index) => {
        console.log(workspace, "workspace in index did update");
        if (workspace.workspaceId === currentWorkspace.workspaceId) {
          this._handleStateChange("currentWorkspaceIndex", index);
        }
      });
    }

    if (
      prevProps.currentWorkspace?.workspaceId !==
      currentWorkspace?.workspaceId &&
      this.state.unsubscribe
    ) {
      this.state.unsubscribe();
    }


    if (
      !!currentWorkspace?.workspaceId &&
      currentWorkspace?.workspaceId != prevProps.currentWorkspace?.workspaceId
    ) {
      firebase
        .firestore()
        .collection(`workspaces`)
        .doc(currentWorkspace?.workspaceId)
        .collection("rooms")
        .where("createdBy", "==", currentUser?.uid)
        .onSnapshot(async (roomData) => {
          // console.log("load channels", roomData);
          const res = await axios.post(`${API_BASE_URL}${PLAN_STATUS}`, {
            user_id: currentUser?.uid,
            workspace_id: currentWorkspace?.workspaceId,
          });
          //console.log(res.data.data.planDetails.rooms,"mine data11")
          let totalRooms = res.data.data.planDetails.rooms.used;
          let difference = res.data.data.planDetails.rooms.available;
          let roomLimit = res.data.data.planDetails.rooms.limit;

          //totalRooms = roomData?.docs?.length;
          // console.log(totalRooms, "totalRooms");

          const access_data = currentSubscription?.room_access;

          // console.log(access_data, "access_data");
          // console.log(res.data.data, "my data")
          // console.log(res.data.data.premiumWorkspace, "my data")

          if (res.data.data.premiumWorkspace || res.data.data.planDetails.rooms.available > 0) {
            this.setState({
              can_create: true,
              roomLoading: false,
            });
            return;
          }

          if (access_data?.access_granted_at >= 0) {
            const access_granted_at = moment(
              access_data?.access_granted_at
            ).format("YYYY-MM-DD");
            const today_date = moment(Date.now()).format("YYYY-MM-DD");
            let start = moment(access_granted_at);
            let end = moment(today_date);
            difference = end.diff(start, "days");
          }

          if (access_data?.room_limit >= 0) {
            roomLimit = access_data?.room_limit;
          }

          if (
            totalRooms < roomLimit &&
            difference <= access_data?.number_of_days
          ) {
            // console.log('here 1', difference, access_data?.number_of_days);
            this.setState({
              can_create: true,
              roomLoading: false,
            });
          }
          if (res.data.data.planDetails.rooms.available <= 0 || !res.data.data.premiumWorkspace) {
            this.setState({
              can_create: false,
              roomLoading: false,
            });
            return;
          } else {
            // console.log('here 2', difference, access_data?.number_of_days);
            this.setState({
              can_create: false,
              roomLoading: false,
            });
          }
        });
    }
  }

  componentWillUnmount() {
    this.removeListeners();
  }

  addListeners = () => {
    let loadedChannels = [];
    let liveChannels = [];
    this.state.channelsRef.on("child_added", (snap) => {
      if (
        snap.val().users &&
        Object.keys(snap.val().users).includes(this.state.user.uid)
      ) {
        loadedChannels.push(snap.val());
        loadedChannels
          .map((channel) => {
            this.state.messagesRef
              .child(channel.id)
              .orderByKey()
              .limitToLast(1)
              .on("child_added", (snap) => {
                channel.lastMessage = snap.val();
                channel.lastMessageTimestamp = snap.val().timestamp;
              });
          })
          .slice(0, 1);
        this.setState({ channels: loadedChannels }, () =>
          this.setFirstChannel()
        );
        this.addNotificationListener(snap.key);
      }
    });

    let loadedusers = [];
    firebase
      .database()
      .ref("users")
      .on("value", (snap) => {
        snap.forEach((childSnap) => {
          loadedusers.push({ ...childSnap.val(), uid: childSnap.key });
        });
        this.setState({ users: loadedusers });
      });

    this.state.liveRef.on("value", (snap) => {
      liveChannels = snap.val() && Object.keys(snap.val());
      this.setState({ liveChannels });
    });
  };

  addNotificationListener = (channelId) => {
    this.state.messagesRef.child(channelId).on("value", (snap) => {
      if (this.state.channel) {
        this.handleNotifications(
          channelId,
          this.state.channel.id,
          this.state.notifications,
          snap
        );
      }
    });
  };

  handleNotifications = (channelId, currentChannelId, notifications, snap) => {
    let lastTotal = 0;
    let notificationcount = 0;
    let index = notifications.findIndex(
      (notification) => notification.id === channelId
    );

    if (index !== -1) {
      if (channelId !== currentChannelId) {
        lastTotal = notifications[index].total;

        if (snap.numChildren() - lastTotal > 0) {
          notifications[index].count = snap.numChildren() - lastTotal;
          notificationcount = notificationcount + notifications[index].count;
          // this.state.beep.play(0.4);
        }
      }
      notifications[index].lastKnownTotal = snap.numChildren();
    } else {
      notifications.push({
        id: channelId,
        total: snap.numChildren(),
        lastKnownTotal: snap.numChildren(),
        count: 0,
      });
    }

    this.setState({ notifications });
    this.updateNotificationCounter(notifications);
  };

  updateNotificationCounter = (notifications) => {
    const totalnotifications = notifications.reduce(
      (accumulator, currentValue) => accumulator + currentValue.count,
      0
    );
    if (totalnotifications > 0) {
      document.title = "(" + totalnotifications + ") Igesia";
    } else {
      document.title = "Igesia";
    }
  };

  removeListeners = () => {
    this.state.channelsRef.off();
    this.state.channels.forEach((channel) => {
      this.state.messagesRef.child(channel.id).off();
    });
  };

  setFirstChannel = () => {
    const firstChannel = this.state.channels[0];
    console.log(firstChannel);
    if (this.state.firstLoad && this.state.channels.length > 0) {
      //  this.props.updateCurrentChannel(firstChannel.id);
      //  this.setActiveChannel(firstChannel);
      this.setState({ channel: firstChannel });
    }
  };

  changeChannel = (channel) => {
    this.setActiveChannel(channel);
    this.state.typingRef
      .child(this.state.channel.id)
      .child(this.state.user.uid)
      .remove();
    this.clearNotifications();
    // this.props.setCurrentChannel(channel);
    this.props.updateCurrentChannel(channel.id);
    this.props.setPrivateChannel(false);
    this.setState({ channel });
  };

  clearNotifications = () => {
    let index = this.state.notifications.findIndex(
      (notification) => notification.id === this.state.channel.id
    );

    if (index !== -1) {
      let updatedNotifications = [...this.state.notifications];
      updatedNotifications[index].total =
        this.state.notifications[index].lastKnownTotal;
      updatedNotifications[index].count = 0;
      this.setState({ notifications: updatedNotifications });
      this.updateNotificationCounter(updatedNotifications);
    }
  };

  setActiveChannel = (channel) => {
    this.setState({ activeChannel: channel.id });
  };

  getNotificationCount = (channel) => {
    let count = 0;

    this.state.notifications.forEach((notification) => {
      if (notification.id === channel.id) {
        count = notification.count;
      }
    });

    if (count > 0) return count;
  };

  displayChannels = (channels) =>
    channels.length > 0 &&
    channels
      .sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp)
      .map((channel) => (
        <li
          className={
            channel.id === this.state.activeChannel
              ? "active rounded-lg p-1 m-1 bg-card-theme shadow-sm text-theme"
              : "rounded-lg p-1 m-1 bg-card-theme shadow-sm text-theme"
          }
          key={channel.id}
          onClick={() => this.changeChannel(channel)}
          name={channel.name}
          active={channel.id === this.state.activeChannel}
        >
          {/* New Stuff */}
          <div className="leftelements">
            <div className="headerimageicon">
              <Image
                width="40"
                height="40"
                roundedCircle
                src={channel.avatar ? channel.avatar : channel.createdBy.avatar}
                spaced="right"
                style={{ background: "white", marginRight: "1em" }}
              />
            </div>
            <div className="d-inline-block">
              {channel.name} <RoomLiveStatus channelId={channel.id} /> &nbsp;
              {channel.lastMessage && (
                <span className="users">
                  {" "}
                  {channel.lastMessage.user.name}: {strip(channel.lastMessage)}
                </span>
              )}
            </div>
          </div>
          <div className="rightelements">
            <div className="time">
              {timeFromNow(channel.lastMessageTimestamp)}
            </div>
            <div className="notify">
              <Badge variant="primary">
                {this.getNotificationCount(channel) && (
                  <div>{this.getNotificationCount(channel)}</div>
                )}
              </Badge>
            </div>
          </div>
        </li>
      ));

  displayChannelsMobile = (channels) =>
    channels.length > 0 &&
    channels
      .sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp)
      .map((channel) => (
        <li
          className={
            channel.id === this.state.activeChannel
              ? "active rounded-lg py-2 m-1 bg-card-theme shadow-sm text-theme"
              : "rounded-lg p-2 m-1 bg-card-theme shadow-sm text-theme"
          }
          key={channel.id}
          onClick={() => {
            this.changeChannel(channel);
            this.props.setLeftPanel(!this.props.displayLeftPanel);
          }}
          name={channel.name}
          active={channel.id === this.state.activeChannel}
        >
          {/* New Stuff */}
          <div className="leftelements">
            <div className="headerimageicon">
              <Image
                width="40"
                height="40"
                roundedCircle
                src={channel.avatar ? channel.avatar : channel.createdBy.avatar}
                spaced="right"
                style={{ background: "white", marginRight: "1em" }}
              />
            </div>
            <div className="d-inline-block">
              {channel.name} <RoomLiveStatus channelId={channel.id} /> &nbsp;
              {channel.lastMessage && (
                <span className="users">
                  {" "}
                  {channel.lastMessage.user.name}: {strip(channel.lastMessage)}
                </span>
              )}
            </div>
          </div>
          <div className="rightelements">
            <div className="time">
              {timeFromNow(channel.lastMessageTimestamp)}
            </div>
            <div className="notify">
              <Badge variant="primary">
                {this.getNotificationCount(channel) && (
                  <div>{this.getNotificationCount(channel)}</div>
                )}
              </Badge>
            </div>
          </div>
        </li>
      ));

  toggleRooms = (e) => {
    this.setState({
      isRoomsVisible: true,
      isActionVisible: false,
    });
  };

  toggleAction = (e) => {
    this.setState({
      isRoomsVisible: false,
      isActionVisible: true,
    });
  };

  handleSearchChange = (event) => {
    this.setState(
      {
        searchTerm: event.target.value,
        searchLoading: true,
      },
      () => this.handleSearchRooms()
    );
  };

  handleSearchRooms = () => {
    const channels = [...this.state.channels];
    const reg = _.escapeRegExp(this.state.searchTerm);
    const regex = new RegExp(reg, "gi");
    const searchResults = channels.reduce((acc, channel) => {
      if (channel.name && channel.name.match(regex)) {
        acc.push(channel);
      }
      return acc;
    }, []);
    this.setState({ searchResults });
    setTimeout(() => this.setState({ searchLoading: false }), 1000);
  };

  getName = (key) => {
    let name = "";
    firebase
      .database()
      .ref("users")
      .child(key)
      .on("value", (snap) => {
        name = snap.val().name;
      });
    return name;
  };

  _handleAddRoom = (workspace_data) => {
    const { setCurrentWorkspace, setLeftActionType } = this.props;

    setCurrentWorkspace(workspace_data);

    this.displayData = [];
    this.toggleAction();
    this.setState({ actionType: "AddARoom" });
    setLeftActionType("AddARoom");
  };

  _handleOpenWorkspaceDetails = (workspace_data) => {
    const { setCurrentWorkspace, setLeftActionType } = this.props;

    setCurrentWorkspace(workspace_data);

    this.displayData = [];
    this.toggleAction();
    this.setState({ actionType: "OpenWorkspaceDetails" });
    setLeftActionType("OpenWorkspaceDetails");
  };

  getWorkspaceHandler = async (workspaceid) => {
    const _handleStateChange = this._handleStateChange;
    let self = this;
    let docRef = firebase
      .firestore()
      .collection(`workspaces`)
      .doc(`${workspaceid}`);
    await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          const workspacedata = doc.data();
          const unsub = firebase
            .firestore()
            .collection(`users/${self.props.currentUser.uid}/rooms`)
            .onSnapshot(function (snapshot) {
              let userWorkspaces = [];
              snapshot.forEach(function (doc) {
                userWorkspaces.push({ ...doc.data() });
              });
              //  traversing through the workspaces and setting the workspace which has workspaceId === workspaceId from url
              let workspaceRooms;
              userWorkspaces.forEach((workspace) => {
                if (workspace.workspaceId === workspaceid) {
                  workspaceRooms = workspace;
                }
              });

              saveState("defaultWorkspace", {
                ...workspacedata,
                ...workspaceRooms,
              });

              // setting workspace data in redux
              self.props.setCurrentWorkspace(
                _.cloneDeep({ ...workspacedata, ...workspaceRooms })
              );
            });
          _handleStateChange("unsubscribe", unsub);
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  handleSignout = async () => {
    const {
      setCurrentWorkspace,
      setCurrentChannel,
      setAllMessages,
      setParticipants,
      setWorkspaces,
      setUser,
      clearUser,
      currentUser,
      setChannels,
      setRightPanel,
      setWorkspaceSlug,
    } = this.props;

    firebase
      .auth()
      .signOut()
      .then(() => {
        // messaging/ notifications
        // const messaging = firebase.messaging();
        // messaging.requestPermission()
        //   .then(() => {
        //     console.log("have permission")
        //     return messaging.getToken();
        //   })
        //   .then((token) => {
        //     firebase.firestore().collection("users")
        //       .doc(currentUser.uid).get().then((doc) => {
        //         if (doc.exists) {
        //           const previousDeviceInfo = doc.data().deviceInfo;
        //           const index = previousDeviceInfo.fcmTokenWeb.indexOf(token);
        //           previousDeviceInfo.fcmTokenWeb.splice(index, 1);
        //           firebase.firestore().collection("users")
        //             .doc(currentUser.uid).update({
        //               deviceInfo: {
        //                 ...previousDeviceInfo,
        //                 fcmTokenWeb: previousDeviceInfo.fcmTokenWeb
        //               }
        //             })
        //         }
        //       })
        //   }).catch((error) => {

        //   })
        setRightPanel(false);
        setCurrentWorkspace(null);
        setCurrentChannel(null);
        setAllMessages(null);
        setParticipants(null);
        setWorkspaces(null);
        setUser(null);
        setChannels(null);
        setWorkspaceSlug(null);
        window.localStorage.removeItem("defaultWorkspace");
        // window.location.replace("/");

        // window.sessionStorage.removeItem("loginToken")
        // window.sessionStorage.removeItem("loginTokenTimestamp")
        // window.sessionStorage.removeItem("referenceLoginToken")
        // window.sessionStorage.removeItem("referenceLoginTokenTimestamp")
        console.log("signed out!");
      });
  };

  convertToPermaAccountHandler = (firstName, lastName, email, password) => {
    var credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    firebase
      .auth()
      .currentUser.linkWithCredential(credential)
      .then((usercred) => {
        var user = usercred.user;
        firebase
          .firestore()
          .collection("users")
          .doc(this.props.currentUser.uid)
          .update({
            isAnonymous: false,
            isConvertedFromGuest: true,
            conversionTimestamp: new Date(),
            firstName: firstName,
            lastName: lastName,
            email: email,
          })
          .then(() => {
            this.setState({
              showConversionModal: false,
              convertEmail: "",
              convertPassword: "",
            });
          })
          .then(() => {
            alert(
              "You have been signed out. Please signin with email and password"
            );
            this.handleSignout();
          });
        console.log("Anonymous account successfully upgraded", user);
      })
      .catch((error) => {
        console.log("Error upgrading anonymous account", error);
        alert(error);
      });
  };

  render() {
    const {
      channels,
      isRoomsVisible,
      isActionVisible,
      user,
      userPic,
      userName,
      searchTerm,
      searchResults,
    } = this.state;

    console.log(this.state.workspaces.length);
    console.log(this.state.currentWorkspaceIndex);

    const { currentUser, setLeftActionType, leftActionType } = this.props;
    const sorticon = (
      <div className="text-center text-theme-lighter">
        <FontAwesomeIcon icon={faFilter} size="lg" className="" />
        <br />
        <small>VIEW</small>
      </div>
    );

    const placeholdertext = "Search ";
    //+ channels.length + ' Rooms';
    return (
      <React.Fragment>
        <div className="header">
          <div
            className="leftelements p-2 m-2 rounded-pill bg-card-theme"
            onClick={() => {
              this.displayData = [];
              this.toggleAction();
              this.setState({ actionType: "Profile" });
              setLeftActionType("Profile");
            }}
            style={{ cursor: "pointer" }}
          >
            <div className="d-inline-block mr-1">
              <Image
                width="30"
                height="30"
                roundedCircle
                className="my-2"
                src={userPic}
                style={{ background: "#FFFFFF" }}
              />
            </div>
            &nbsp;
            <div className="d-inline-block text-theme small font-weight-bold">
              {userName}
            </div>
          </div>
          {/* <div
            className="rightelements p-2 m-2 rounded-pill bg-card-theme"
            style={{ cursor: "pointer" }}
          > */}

          {this.state.workspaces.length > 0 &&
            this.state.currentWorkspaceIndex !== null ? (
            <div style={{ zIndex: 20 }} className="w-50 text-theme p-2 ">
              <Select
                options={this.state.workspaces}
                isSearchable={false}
                defaultValue={
                  this.state.workspaces[this.state.currentWorkspaceIndex]
                }
                value={this.state.workspaces[this.state.currentWorkspaceIndex]}
                onChange={(e) =>
                  this.getWorkspaceHandler(e.value).then(() => {
                    this.props.setCurrentChannel(null);
                    setAllMessages([]);
                    this.props.setRightPanel(false);
                    setTimeout(() => this.props.history.push("/"), 1000);
                  })
                }
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: this.props.darkTheme ? "#00366f" : "#c7c7c7",
                    neutral0: `var(--bg-card-theme)`,
                    neutral80: `var(--text-theme)`,
                  },
                })}
                styles={{
                  control: (base) => ({
                    ...base,
                    borderRadius: `50rem !important`,
                  }),
                }}
              />
            </div>
          ) : // this.state.workspaces.map((workspace, index) => {
            //   if(index === 0) {
            //     return (
            //       <Dropdown.Item  variant="transparent" id="dropdown-basic">
            //         <img style={{ width: 25 }} className="mr-1" src={workspace.workspacePic}></img>
            //         <span className="text-theme" style={{ verticalAlign: 'middle' }}>{workspace.workspaceName}</span>
            //       </Dropdown.Item >
            //     )
            //   }else {
            //     return (
            //       <Dropdown.Item variant="transparent" id="dropdown-basic">
            //         <img style={{ width: 25 }} className="mr-1" src={workspace.workspacePic}></img>
            //         <span className="text-theme" style={{ verticalAlign: 'middle' }}>{workspace.workspaceName}</span>
            //       </Dropdown.Item>
            //     )
            //   }
            // })

            null}
          {/* </div> */}
          {/* <div className="rightelements">
            <Button onClick={() => {
              this.displayData = [];
              this.toggleAction();
              this.setState({ actionType: "AddARoom" });
            }}
              variant="light" size="sm">
              <span className="font-weight-bold" style={{ fontSize: "75%" }}>+ NEW ROOM</span>
            </Button>
          </div> */}
        </div>
        <div className="content">
          <ul>
            <div className={isRoomsVisible ? "" : "hidden"}>
              {/* <li className="">
                <Button
                  variant="dark"
                  className="w-100"
                  onClick={() => {
                    this.displayData = [];
                    this.toggleAction();
                    this.setState({ actionType: "AddWorkspace" });
                  }}><span className="h5">+</span> Add Workspace</Button>
              </li> */}
              {/* <Offline>
                <NoInternet />
              </Offline> */}
              <VersionUpdates />
              <div className="rounded-0">
                {currentUser.isAnonymous ? (
                  <li
                    action
                    onClick={() => {
                      this.setState({ showConversionModal: true });
                    }}
                    className="rounded-0"
                  >
                    <div className="d-flex align-items-center justify-content-center p-3">
                      <div
                        className="bg-theme-lightest rounded rounded-circle d-flex justify-content-center align-items-center"
                        style={{
                          height: "2.5rem",
                          width: "2.5rem",
                          borderRadius: "1.25rem",
                        }}
                      >
                        <FontAwesomeIcon icon={faLink} className="text-theme" />
                      </div>
                      <div className="ml-3">
                        <p className="text-theme mb-0">
                          Activate more features
                        </p>
                        <p className="text-theme text-center mb-0 small">
                          Click to link your email now{" "}
                          <FontAwesomeIcon icon={faAngleRight} />
                        </p>
                      </div>
                    </div>
                  </li>
                ) : null}
              </div>
              <BrowserWarning />
              {currentUser != null ? <StorageNotice /> : null}
              <li className="px-3 roomlistheader">
                <InputGroup className="outerPill">
                  <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                    <div className="py-2 pl-3 pr-2">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="sm"
                        color="#aeaeae"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <Form.Control
                        type="text"
                        className="border-0 px-0 bg-theme text-theme-important"
                        onChange={this.handleSearchChange}
                        placeholder={placeholdertext}
                        value={this.state.searchTerm}
                        style={{ margin: 0 }}
                      />
                    </div>
                    {this.state.searchTerm.length > 0 && (
                      <div
                        className="py-2 pl-2 pr-3"
                        onClick={() => this.setState({ searchTerm: "" })}
                      >
                        <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                      </div>
                    )}
                  </div>
                  {/* <InputGroup.Append
                    onClick={() => {
                      (this.props.roomsVisible === ACTIVE)
                        ? this.props.setRoomsVisible(ARCHIVE)
                        : this.props.setRoomsVisible(ACTIVE)
                    }}>
                    <div className=
                      {(this.props.roomsVisible === ACTIVE)
                        ? "headerimageicon archive"
                        : "headerimageicon home"}
                    >
                      {
                        (this.props.roomsVisible === ACTIVE)
                          ? <><FontAwesomeIcon icon={faArchive}
                            className="text-theme-lighter"
                            style={{ width: '33px', height: '20px', marginLeft: '0.8em', padding: '0 0.8em 0.8em 0.8em 0.8em' }} />
                          </>
                          : <FontAwesomeIcon icon={faHome}
                            className="text-theme-lighter"
                            style={{ width: '33px', height: '20px', marginLeft: '0.8em', padding: '0 0.8em 0.8em 0.8em 0.8em' }} />
                      }
                    </div>
                  </InputGroup.Append> */}
                </InputGroup>
                {/*console.log(this.props.darkTheme, "darktheme")*/}
                <NavDropdown
                  className="py-0 mt-1 text-theme  bg-card-theme videowindow  pl-2"
                  title={sorticon}
                  id="basic-nav-dropdown"
                //style={{backgroundColor:"#18191a"}}
                >
                  <NavDropdown.Item className="p-1">
                    <Button
                      variant={this.props.darkTheme ? "dark" : "light"}
                      size="sm"
                      className="w-100"
                      onClick={() => {
                        this.props.roomsVisible === ACTIVE
                          ? this.props.setRoomsVisible(ARCHIVE)
                          : this.props.setRoomsVisible(ACTIVE);
                      }}
                    >
                      <div className="d-flex">
                        {this.props.roomsVisible === ACTIVE ? (
                          <>
                            <FontAwesomeIcon
                              icon={faHome}
                              className="text-theme-lighter"
                            />
                            <span className="small flex-grow-1 text-center">
                              Active Rooms
                            </span>
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon
                              icon={faArchive}
                              className="text-theme-lighter"
                            />
                            <span className="small flex-grow-1 text-center">
                              Archived Rooms
                            </span>
                          </>
                        )}
                        {this.props.roomsVisible === ACTIVE ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="text-theme-inverted"
                          />
                        )}
                      </div>
                    </Button>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="p-1">
                    <Button
                      variant={this.props.darkTheme ? "dark" : "light"}
                      size="sm"
                      className="w-100"
                      onClick={() => this.setState({ sortType: 0 })}
                    >
                      <div className="d-flex">
                        {/* <FontAwesomeIcon icon={faLongArrowAltDown} className="text-theme" /> */}
                        <span className="small flex-grow-1 text-center mr-2">
                          Latest Activity on top
                        </span>
                        {this.state.sortType === 0 ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="text-theme-inverted"
                          />
                        )}
                      </div>
                    </Button>
                  </NavDropdown.Item>
                  <NavDropdown.Item className="p-1">
                    <Button
                      variant={this.props.darkTheme ? "dark" : "light"}
                      size="sm"
                      className="w-100"
                      onClick={() => this.setState({ sortType: 3 })}
                    >
                      <div className="d-flex">
                        {/* <FontAwesomeIcon icon={faLongArrowAltUp} className="text-theme mr-2" /> */}
                        <span className="small flex-grow-1 text-center mr-2">
                          Oldest Activity on top
                        </span>
                        {this.state.sortType === 3 ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="text-theme-inverted"
                          />
                        )}
                      </div>
                    </Button>
                  </NavDropdown.Item>

                  <NavDropdown.Item className="p-1">
                    <Button
                      variant={this.props.darkTheme ? "dark" : "light"}
                      size="sm"
                      className="w-100"
                      onClick={() => this.setState({ sortType: 1 })}
                    >
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon={faSortAlphaDown}
                          className="text-theme mr-2"
                        />
                        <span className="small flex-grow-1 text-center">
                          Room Name
                        </span>
                        {this.state.sortType === 1 ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="text-theme-inverted"
                          />
                        )}
                      </div>
                    </Button>
                  </NavDropdown.Item>

                  <NavDropdown.Item className="p-1">
                    <Button
                      variant={this.props.darkTheme ? "dark" : "light"}
                      size="sm"
                      className="w-100"
                      onClick={() => this.setState({ sortType: 2 })}
                    >
                      <div className="d-flex">
                        <FontAwesomeIcon
                          icon={faSortAlphaDownAlt}
                          className="text-theme"
                        />
                        <span className="small flex-grow-1 text-center">
                          Room Name
                        </span>
                        {this.state.sortType === 2 ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            className="text-success"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCircle}
                            className="text-theme-inverted"
                          />
                        )}
                      </div>
                    </Button>
                  </NavDropdown.Item>
                </NavDropdown>
              </li>
              <div className="d-flex justify-content-between px-1 pt-1">
                <JoinUsingLink />
                {currentUser.isAnonymous ? (
                  <Button
                    variant="link"
                    size="sm"
                    className="text-decoration-none"
                    onClick={() => {
                      alert("Please login");
                    }}
                  >
                    <span className="small">CREATE NEW ROOM</span>
                  </Button>
                ) : (
                  <Button
                    variant="link"
                    size="sm"
                    className="text-decoration-none"
                    onClick={() => {
                      this.displayData = [];
                      this.toggleAction();
                      this.setState({ actionType: "AddARoom" });
                      setLeftActionType("AddARoom");
                    }}
                  >
                    <span className="small">CREATE NEW ROOM</span>
                  </Button>
                )}
              </div>
              {/* {this.state.userWorkspaces.map((workspace, key) => (
                <WorkspaceListElement
                  sortType={this.state.sortType}
                  searchTerm={this.state.searchTerm}
                  workspace={workspace}
                  key={key}
                  _handleAddRoom={(workspace_data) =>
                    this._handleAddRoom(workspace_data)
                  }
                  _handleOpenWorkspaceDetails={(workspace_data) =>
                    this._handleOpenWorkspaceDetails(workspace_data)
                  }
                />
              ))} */}
              {this.props.currentWorkspace &&
                this.props.currentWorkspace.rooms ? (
                <WorkspaceListElement
                  sortType={this.state.sortType}
                  searchTerm={this.state.searchTerm}
                  workspace={this.props.currentWorkspace}
                  // key={key}
                  _handleAddRoom={(workspace_data) =>
                    this._handleAddRoom(workspace_data)
                  }
                  _handleOpenWorkspaceDetails={(workspace_data) =>
                    this._handleOpenWorkspaceDetails(workspace_data)
                  }
                />
              ) : null}

              {/* {searchTerm
                ? this.displayChannels(searchResults)
                : (
                  <>
                    <BrowserView>
                      {this.displayChannels(channels)}
                    </BrowserView>
                    <MobileView>
                      {this.displayChannelsMobile(channels)}
                    </MobileView>
                  </>
                )} */}
              {/* {this.displayChannels(channels)} */}

              <>
                {this.state.pendingRooms.length > 0 ? (
                  <>
                    {this.state.pendingRooms.map((pendingRoom, key) =>
                      pendingRoom.workspaceId ===
                        this.props.currentWorkspace?.workspaceId ? (
                        <>
                          {key === 0 ? (
                            <div className="bg-card-theme p-3">
                              <h6 className="text-theme mb-0">
                                Pending Invitations
                              </h6>
                            </div>
                          ) : null}
                          <PendingRoomListElement
                            key={key}
                            roomId={pendingRoom.roomId}
                            workspaceId={pendingRoom.workspaceId}
                          />
                        </>
                      ) : null
                    )}
                  </>
                ) : null}
              </>
            </div>
            <div
              className={
                isActionVisible ? "notifications" : "notifications hidden"
              }
            >
              {this.state.actionType !== "showMyDrive" ? (
                <Button
                  tabIndex="0"
                  onClick={() => {
                    this.displayData = [];
                    this.toggleRooms();
                    setLeftActionType("");
                  }}
                  variant={this.props.darkTheme ? "dark" : "light"}
                  style={{
                    position: "-webkit-sticky",
                    position: "sticky",
                    zIndex: 10,
                    top: 0,
                  }}
                  className=" mb-1 p-2 bg-card-theme text-theme form-control"
                >
                  <div className="d-flex justify-content-start">
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="mr-2 mt-1 pointer"
                    />
                    <span className="d-inline-block">Back</span>
                  </div>
                </Button>
              ) : null}
              <div className="taskBox px-0">
                <div id="display-data-Container">
                  {this.state.actionType === "AddARoom" && (
                    <AddARoom
                      setLeftActionType={setLeftActionType}
                      toggleRooms={this.toggleRooms}
                      can_create={this.state.can_create}
                      roomLoading={this.state.roomLoading}
                    />
                  )}
                  {this.state.actionType === "showMyDrive" && (
                    <MyDrive
                      toggleRooms={this.toggleRooms}
                      setLeftActionType={setLeftActionType}
                    />
                  )}
                  {this.state.actionType === "Profile" && (
                    <UserProfile
                      displayData={this.displayData}
                      toggleAction={this.toggleAction}
                      setActionType={() =>
                        this.setState({ actionType: "showMyDrive" })
                      }
                      setLeftActionType={setLeftActionType}
                    />
                  )}
                  {this.state.actionType === "AddWorkspace" && <AddWorkspace />}
                  {this.state.actionType === "OpenWorkspaceDetails" && (
                    <OpenWorkspaceDetails />
                  )}
                </div>
              </div>
            </div>
          </ul>
        </div>
        {this.props.showCreateFolderModal && <CreateFolderIntoMyDrive />}
        {this.props.showUploadFilesModal && <UploadFilesIntoMyDrive />}

        {/* modal for details when converting account */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showConversionModal}
        >
          <Modal.Header className="bg-theme border-0 d-flex justify-content-between align-items-center pb-0">
            <Modal.Title id="contained-modal-title-vcenter">
              <h5 className="text-theme">Enter details</h5>
            </Modal.Title>
            <div
              title="Close"
              className="pointer"
              onClick={() =>
                this.setState({
                  showConversionModal: false,
                  convertEmail: "",
                  convertPassword: "",
                })
              }
            >
              <FontAwesomeIcon className="text-theme" icon={faTimes} />
            </div>
          </Modal.Header>
          <Modal.Body className="overflow-auto list-unstyled bg-theme pt-0">
            {!this.state.currentConversionStep ? (
              <>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  value={this.state.firstName}
                  name="displayName"
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                  className="rounded-1 my-2"
                />

                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  value={this.state.lastName}
                  name="displayName"
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                  className="rounded-1 my-2"
                />
                <p className="text-danger small">
                  {this.state.nameError ? this.state.nameError : null}
                </p>
              </>
            ) : (
              <>
                <Form.Control
                  type="text"
                  placeholder="Enter your email"
                  value={this.state.convertEmail}
                  name="email"
                  onChange={(e) =>
                    this.setState({ convertEmail: e.target.value })
                  }
                  className="rounded-1 my-2"
                />

                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  value={this.state.convertPassword}
                  name="displayName"
                  onChange={(e) =>
                    this.setState({ convertPassword: e.target.value })
                  }
                  className="rounded-1 my-2"
                />
                <p className="text-danger small">
                  {this.state.emailError ? this.state.emailError : null}
                </p>
              </>
            )}

            <div>
              {!this.state.currentConversionStep ? (
                <Button
                  variant="primary"
                  onClick={() => {
                    if (this.state.currentConversionStep === 0) {
                      if (
                        this.state.firstName === "" ||
                        this.state.firstName === undefined
                      ) {
                        this.setState({ nameError: "Enter your first name." });
                        return;
                      } else if (
                        this.state.firstName.replace(/\s/g, "").length === 0
                      ) {
                        this.setState({ nameError: "Enter valid first name." });
                        return;
                      } else if (
                        this.state.lastName === "" ||
                        this.state.lastName === undefined
                      ) {
                        this.setState({ nameError: "Enter your last name." });
                        return;
                      } else if (
                        this.state.lastName.replace(/\s/g, "").length === 0
                      ) {
                        this.setState({ nameError: "Enter valid last name." });
                        return;
                      } else {
                        this.setState({ currentConversionStep: 1 });
                        this.setState({ nameError: "" });
                      }
                    }
                  }}
                  className="btn-sm"
                >
                  Next
                </Button>
              ) : (
                <div className="d-flex justify-content-between">
                  <Button
                    variant="primary"
                    onClick={() => this.setState({ currentConversionStep: 0 })}
                    className="btn-sm"
                  >
                    Back
                  </Button>{" "}
                  :
                  <Button
                    //this.convertToPermaAccountHandler(this.state.firstName, this.state.lastName, this.state.convertEmail, this.state.convertPassword)
                    variant="primary"
                    onClick={() => {
                      if (
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                          this.state.convertEmail
                        )
                      ) {
                        if (this.state.convertPassword.length < 6) {
                          this.setState({
                            emailError:
                              "Password must have at least 6 characters.",
                          });
                          return;
                        } else {
                          this.setState({ emailError: "" });
                          this.convertToPermaAccountHandler(
                            this.state.firstName,
                            this.state.lastName,
                            this.state.convertEmail,
                            this.state.convertPassword
                          );
                        }
                      } else {
                        this.setState({
                          emailError: "Enter a valid email address.",
                        });
                      }
                    }}
                    className="btn-sm"
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  darkTheme: state.platform.darkTheme,
  currentChannel: state.channel.currentChannel,
  currentSubscription: state.subscription.currentSubscription,
  displayLeftPanel: state.platform.displayLeftPanel,
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,
  workspaces: state.workspace.workspaces,
  roomsVisible: state.workspace.roomsVisible,
  showUploadFilesModal: state.uploadFilesModal.showUploadFilesModal,
  showCreateFolderModal: state.createFolderModal.showCreateFolderModal,
});

export default withRouter(
  connect(mapStateToProps, {
    setCurrentChannel,
    setAllMessages,
    setPrivateChannel,
    updateCurrentChannel,
    setLeftPanel,
    setCurrentWorkspace,
    setRoomsVisible,
    setRightPanel,
    setParticipants,
    setUser,
    setChannels,
    setWorkspaces,
    setWorkspaceSlug,
  })(Rooms)
);
