import React, { useEffect, useState } from "react";

import BioMarkerImg from "../../../assets/bioMarker.png";
import firebase from "firebase";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faCompress,
  faCompressArrowsAlt,
  faHeartbeat,
  faLungs,
  faRulerVertical,
  faThermometerFull,
  faThermometerHalf,
  faTimes,
  faWeight,
} from "@fortawesome/free-solid-svg-icons";

import BiomarkersLogo from "../../../assets/biomarkersLogo.png"
import chestMeasurementImg from "../../../assets/chestMeasurement.png"


const Vitals = ({ currentChannel, currentWorkspace, currentUser }) => {
  const [BioMarkers, setBioMarkers] = useState({});
  const [editBioMarkers, setEditBioMarkers] = useState(false);
  const [autoCalculatedBioMarkers, setAutoCalculatedBioMarkers] = useState({});
  const [isSaving, setIsSaving] = useState(true);

  const BioMarkersRef = firebase
    .firestore()
    .collection("caseSheet")
    .doc(currentWorkspace.workspaceId)
    .collection("rooms")
    .doc(currentChannel.roomId)
    .collection("caseSheet")
    .doc("caseSheetId")
    .collection("data")
    .doc("bioMarkers");

  useEffect(() => {
    BioMarkersRef.onSnapshot((doc) => {
      if (doc.exists) {
        setBioMarkers({ ...doc.data() });
      }
      setIsSaving(false);
    });
  }, []);

  useEffect(() => {
    console.log(BioMarkers);
    if (!BioMarkers) return;
    if (BioMarkers.height && BioMarkers.weight) {
      console.log("called");
      autoCalculatedBioMarkers["BMI"] = (
        (BioMarkers.weight.value / (BioMarkers.height.value * BioMarkers.height.value)) *
        10000
      ).toFixed(2);
      setAutoCalculatedBioMarkers({ ...autoCalculatedBioMarkers });
    } else {
      autoCalculatedBioMarkers["BMI"] = null;
      setAutoCalculatedBioMarkers({ ...autoCalculatedBioMarkers });
    }
    if (BioMarkers.height && BioMarkers.waistMeasurement) {
      autoCalculatedBioMarkers["WHeightR"] = (
        (BioMarkers.waistMeasurement.value * 2.54) /
        BioMarkers.height.value
      ).toFixed(2);
      setAutoCalculatedBioMarkers({ ...autoCalculatedBioMarkers });
    } else {
      autoCalculatedBioMarkers["WHeightR"] = null;
      setAutoCalculatedBioMarkers({ ...autoCalculatedBioMarkers });
    }
    if (BioMarkers.waistMeasurement && BioMarkers.hipMeasurement) {
      autoCalculatedBioMarkers["WHipR"] = (
        BioMarkers.waistMeasurement.value / BioMarkers.hipMeasurement.value
      ).toFixed(2);
      setAutoCalculatedBioMarkers({ ...autoCalculatedBioMarkers });
    } else {
      autoCalculatedBioMarkers["WHipR"] = null;
      setAutoCalculatedBioMarkers({ ...autoCalculatedBioMarkers });
    }
  }, [BioMarkers]);

  const handleBioMarkerChanges = (e) => {
    const Name = e.target.name;
    const Value = e.target.value;
    const Unit = e.target.id
    
    BioMarkers[Name]={}
    if(Name === "bloodPressure"){
      BioMarkers[Name]["value"] = Value
    } else{
      BioMarkers[Name]["value"] = parseInt(Value);
    }
    
    
    BioMarkers[Name]["unit"] = Unit;
    setBioMarkers({ ...BioMarkers });
  };

  const handleBioMarkerSubmit = () => {
    setIsSaving(true);
    if(BioMarkers.bloodPressure?.value){
    let bloodPressureSplit= BioMarkers.bloodPressure.value.split("/")
    BioMarkers["bloodPressure"]["systolic"] = parseInt(bloodPressureSplit[0]);
    BioMarkers["bloodPressure"]["diastolic"] = parseInt(bloodPressureSplit[1]);
    setBioMarkers({ ...BioMarkers });
    }
    BioMarkersRef.set({ ...BioMarkers,   }).then(() => {
      setIsSaving(false);
      setEditBioMarkers(false);
    });
  };

  return isSaving ? (
    <div className="text-center text-success pt-5">
      <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-2x" />{" "}
    </div>
  ) : (
    <div>
      {!editBioMarkers ? (
        <div>
          {Object.keys(BioMarkers).length !== 0 ? (
            <div className="mt-2 px-2 font-15">
              {autoCalculatedBioMarkers.BMI ||
              autoCalculatedBioMarkers.WHeightR ||
              autoCalculatedBioMarkers.WHipR ? (
                <div className="mb-3">
                  <h6 className="text-primary">Autocalculated Bio Markers</h6>

                  {autoCalculatedBioMarkers.BMI ? (
                    <div className="row m-0 my-2 bg-light rounded-lg">
                      <div className="col-3 p-0 text-center">
                        <div className="text-muted">
                          <img
                            src={BiomarkersLogo}
                            alt="image"
                            height="30px"
                            width="30px"
                            className="mt-2"
                          />
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="">
                          <small>BMI</small>
                        </div>
                        <div
                          className="text-primary"
                          style={{ marginTop: "-7px" }}
                        >
                          <small>{autoCalculatedBioMarkers.BMI}</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="text-success mt-2">
                          <small>
                            {autoCalculatedBioMarkers.BMI > 25 ? (
                              <span className="text-danger">Overweight</span>
                            ) : BioMarkers.BMI < 18.5 ? (
                              <span className="text-danger">Underweight</span>
                            ) : (
                              "Normal"
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {autoCalculatedBioMarkers.WHeightR && (
                    <div className="row m-0 my-2 bg-light rounded-lg">
                      <div className="col-3 p-0 text-center">
                        <div className="text-muted">
                          <img
                            
                            src={BiomarkersLogo}
                            alt="image"
                            height="30px"
                            width="30px"
                            className="mt-2"
                          />
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="">
                          <small>Waist:Height</small>
                        </div>
                        <div
                          className="text-primary"
                          style={{ marginTop: "-7px" }}
                        >
                          <small>{autoCalculatedBioMarkers.WHeightR}</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="text-success mt-2">
                          <small>
                            {autoCalculatedBioMarkers.WHeightR > 0.35 &&
                            autoCalculatedBioMarkers.WHeightR < 0.42 ? (
                              <span className="">Slim</span>
                            ) : autoCalculatedBioMarkers.WHeightR > 0.52 &&
                              autoCalculatedBioMarkers.WHeightR < 0.63 ? (
                              <span className="text-danger">Overweight</span>
                            ) : (
                              "Healthy"
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                  )}

                  {autoCalculatedBioMarkers.WHipR && (
                    <div className="row m-0 my-2 bg-light rounded-lg">
                      <div className="col-3 p-0 text-center">
                        <div className="text-muted">
                          <img
                            
                            src={BiomarkersLogo}
                            alt="image"
                            height="30px"
                            width="30px"
                            className="mt-2"
                          />
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="">
                          <small>Waist:Hip</small>
                        </div>
                        <div
                          className="text-primary"
                          style={{ marginTop: "-7px" }}
                        >
                          <small>{autoCalculatedBioMarkers.WHipR}</small>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="text-success mt-2">
                          <small>
                            {autoCalculatedBioMarkers.WHipR > 0.8 &&
                            autoCalculatedBioMarkers.WHipR < 0.84 ? (
                              <span className="text-warning">Overweight</span>
                            ) : autoCalculatedBioMarkers.WHipR > 0.85 ? (
                              <span className="text-danger">obesity</span>
                            ) : (
                              "Normal"
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
              <h6 className="text-primary">Bio Markers</h6>
              <div className="my-3">
                {BioMarkers.height && BioMarkers.height.value && (
                  <div className="row m-0 my-2 bg-light rounded-lg">
                    <div className="col-3 p-0 text-center">
                      <div className="text-muted">
                        <FontAwesomeIcon
                          icon={faRulerVertical}
                          className=" fa-2x mt-2"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <small>Height</small>
                      </div>
                      <div
                        className="text-primary"
                        style={{ marginTop: "-7px" }}
                      >
                        <small>{BioMarkers.height.value} cms</small>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-danger mt-2">
                        <small
                          className="cursor-pointer"
                          onClick={() => setEditBioMarkers(true)}
                        >
                          Edit
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {BioMarkers.weight && BioMarkers.weight.value &&(
                  <div className="row m-0 my-2 bg-light rounded-lg">
                    <div className="col-3 p-0 text-center">
                      <div className="text-muted">
                        <FontAwesomeIcon
                          icon={faWeight}
                          className=" fa-2x mt-2"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <small>Weight</small>
                      </div>
                      <div
                        className="text-primary"
                        style={{ marginTop: "-7px" }}
                      >
                        <small>{BioMarkers.weight.value} Kgs</small>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-danger mt-2">
                        <small
                          className="cursor-pointer"
                          onClick={() => setEditBioMarkers(true)}
                        >
                          Edit
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {BioMarkers.bloodPressure &&BioMarkers.bloodPressure.value && (
                  <div className="row m-0 my-2 bg-light rounded-lg">
                    <div className="col-3  p-0 text-center">
                      <div className="text-muted">
                        <FontAwesomeIcon
                          icon={faCompressArrowsAlt}
                          className=" fa-2x mt-2"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <small>Blood Pressure</small>
                      </div>
                      <div
                        className="text-primary"
                        style={{ marginTop: "-7px" }}
                      >
                        <small>{BioMarkers.bloodPressure.value} mmHg</small>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-danger mt-2">
                        <small
                          className="cursor-pointer"
                          onClick={() => setEditBioMarkers(true)}
                        >
                          Edit
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {BioMarkers.pulse && BioMarkers.pulse.value && (
                  <div className="row m-0 my-2 bg-light rounded-lg">
                    <div className="col-3 p-0 text-center">
                      <div className="text-muted">
                        {" "}
                        <FontAwesomeIcon
                          icon={faHeartbeat}
                          className=" fa-2x mt-2"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <small>Pulse</small>
                      </div>
                      <div
                        className="text-primary"
                        style={{ marginTop: "-7px" }}
                      >
                        <small>{BioMarkers.pulse.value} bpm</small>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-danger mt-2">
                        <small
                          className="cursor-pointer"
                          onClick={() => setEditBioMarkers(true)}
                        >
                          Edit
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {BioMarkers.temperature && BioMarkers.temperature.value && (
                  <div className="row m-0 my-2 bg-light rounded-lg">
                    <div className="col-3 p-0 text-center">
                      <div className="text-muted">
                        <FontAwesomeIcon
                          icon={faThermometerHalf}
                          className=" fa-2x mt-2"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <small>Temperature</small>
                      </div>
                      <div
                        className="text-primary"
                        style={{ marginTop: "-7px" }}
                      >
                        <small>{BioMarkers.temperature.value} °F</small>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-danger mt-2">
                        <small
                          className="cursor-pointer"
                          onClick={() => setEditBioMarkers(true)}
                        >
                          Edit
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {BioMarkers.respiratoryRate &&BioMarkers.respiratoryRate.value && (
                  <div className="row m-0 my-2 bg-light rounded-lg">
                    <div className="col-3 p-0 text-center">
                      <div className="text-muted">
                        <FontAwesomeIcon
                          icon={faLungs}
                          className=" fa-2x mt-2"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <small>Respiratory Rate</small>
                      </div>
                      <div
                        className="text-primary"
                        style={{ marginTop: "-7px" }}
                      >
                        <small>{BioMarkers.respiratoryRate.value} Breaths/ Min</small>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-danger mt-2">
                        <small
                          className="cursor-pointer"
                          onClick={() => setEditBioMarkers(true)}
                        >
                          Edit
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {BioMarkers.waistMeasurement &&BioMarkers.waistMeasurement.value && (
                  <div className="row m-0 my-2 bg-light rounded-lg">
                    <div className="col-3 p-0 text-center">
                      <div className="">
                        <img
                          src="https://cdn.onlinewebfonts.com/svg/img_22955.png"
                          height="30px"
                          width="30px"
                          className="mt-2"
                          alt="image"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <small>Waist Measurement</small>
                      </div>
                      <div
                        className="text-primary"
                        style={{ marginTop: "-7px" }}
                      >
                        <small>{BioMarkers.waistMeasurement.value} inches</small>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-danger mt-2">
                        <small
                          className="cursor-pointer"
                          onClick={() => setEditBioMarkers(true)}
                        >
                          Edit
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {BioMarkers.hipMeasurement && BioMarkers.hipMeasurement.value && (
                  <div className="row m-0 my-2 bg-light rounded-lg">
                    <div className="col-3 p-0 text-center">
                      <div className="">
                        <img
                          src="https://www.iconfinder.com/data/icons/clothes-sizes/64/239_clothes-size-quide-measurement-hip-circumference-512.png"
                          alt="image"
                          height="35px"
                          width="35px"
                          className="mt-2"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <small>Hip Measurement</small>
                      </div>
                      <div
                        className="text-primary"
                        style={{ marginTop: "-7px" }}
                      >
                        <small>{BioMarkers.hipMeasurement.value} inches</small>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-danger mt-2">
                        <small
                          className="cursor-pointer"
                          onClick={() => setEditBioMarkers(true)}
                        >
                          Edit
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {BioMarkers.chestMeasurement && BioMarkers.chestMeasurement.value && (
                  <div className="row m-0 my-2 bg-light rounded-lg">
                    <div className="col-3 p-0 text-center">
                      <div className="text-muted">
                        <img src={chestMeasurementImg} alt="chestMeasurementImg" height="35px"/>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="">
                        <small>Chest Measurement</small>
                      </div>
                      <div
                        className="text-primary"
                        style={{ marginTop: "-7px" }}
                      >
                        <small>{BioMarkers.chestMeasurement.value} inches</small>
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="text-danger mt-2">
                        <small
                          className="cursor-pointer"
                          onClick={() => setEditBioMarkers(true)}
                        >
                          Edit
                        </small>
                      </div>
                    </div>
                  </div>
                )}

                {(!BioMarkers.height?.value ||
                  !BioMarkers.weight?.value ||
                  !BioMarkers.bloodPressure?.value ||
                  !BioMarkers.pulse?.value ||
                  !BioMarkers.temperature?.value ||
                  !BioMarkers.respiratoryRate?.value ||
                  !BioMarkers.waistMeasurement?.value ||
                  !BioMarkers.hipMeasurement?.value ||
                  !BioMarkers.chestMeasurement?.value) && (
                  <div
                    className="cursor-pointer bg-info text-white mt-3 mb-3 text-center rounded-lg py-1"
                    onClick={() => setEditBioMarkers(true)}
                  >
                    Add more...
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className="text-center" style={{ marginTop: "80px" }}>
                <img src={BioMarkerImg} alt="BioMarkerImg" height="150px" />
                <h5 className="font-thick text-center text-info">
                  Biomarkers not found!
                </h5>
                <div>
                  <small>
                    <i>Biomarkers helps doctor to know users better</i>
                  </small>
                </div>
                <span
                  className="btn btn-sm btn-primary px-5 mt-4"
                  onClick={() => setEditBioMarkers(true)}
                >
                  Add
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="">
          <h4 className="text-center text-info my-4">
            Update your bio markers
          </h4>

          <div className="row m-0 my-2 bg-light rounded-lg">
            <div className="col-3 p-0 text-center">
              <div className="text-muted">
                <FontAwesomeIcon
                  icon={faRulerVertical}
                  className=" fa-2x mt-2"
                />
              </div>
            </div>
            <div className="col-5">
              <div className="">
                <small>Height</small>
              </div>
              <div className="text-primary" style={{ marginTop: "-7px" }}>
                <small>cms</small>
              </div>
            </div>
            <div className="col-4">
              <input
              id="cm"
                name="height"
                type="number"
                onChange={handleBioMarkerChanges}
                value={BioMarkers.height?.value}
                className="form-control form-control-sm mt-2 bg-light border-bottom"
              />
            </div>
          </div>

          <div className="row m-0 my-2 bg-light rounded-lg">
            <div className="col-3 p-0 text-center">
              <div className="text-muted">
                <FontAwesomeIcon icon={faWeight} className=" fa-2x mt-2" />
              </div>
            </div>
            <div className="col-5">
              <div className="">
                <small>Weight</small>
              </div>
              <div className="text-primary" style={{ marginTop: "-7px" }}>
                <small>Kgs</small>
              </div>
            </div>
            <div className="col-4">
              <input
              id="kg"
                type="number"
                name="weight"
                onChange={handleBioMarkerChanges}
                value={BioMarkers.weight?.value}
                className="form-control form-control-sm mt-2 bg-light border-bottom"
              />
            </div>
          </div>

          <div className="row m-0 my-2 bg-light rounded-lg">
            <div className="col-3  p-0 text-center">
              <div className="text-muted">
                <FontAwesomeIcon
                  icon={faCompressArrowsAlt}
                  className=" fa-2x mt-2"
                />
              </div>
            </div>
            <div className="col-5">
              <div className="">
                <small>Blood Pressure</small>
              </div>
              <div className="text-primary" style={{ marginTop: "-7px" }}>
                <small>mmHg</small>
              </div>
            </div>
            <div className="col-4">
              <input
              id="mmHg"
                type="text"
                name="bloodPressure"
                onChange={handleBioMarkerChanges}
                value={BioMarkers.bloodPressure?.value}
                className="form-control form-control-sm mt-2 bg-light border-bottom"
              />
            </div>
          </div>

          <div className="row m-0 my-2 bg-light rounded-lg">
            <div className="col-3 p-0 text-center">
              <div className="text-muted">
                <FontAwesomeIcon icon={faHeartbeat} className=" fa-2x mt-2" />
              </div>
            </div>
            <div className="col-5">
              <div className="">
                <small>Pulse</small>
              </div>
              <div className="text-primary" style={{ marginTop: "-7px" }}>
                <small>bpm</small>
              </div>
            </div>
            <div className="col-4">
              <input
              id="bpm"
                type="number"
                name="pulse"
                onChange={handleBioMarkerChanges}
                value={BioMarkers.pulse?.value}
                className="form-control form-control-sm mt-2 bg-light border-bottom"
              />
            </div>
          </div>

          <div className="row m-0 my-2 bg-light rounded-lg">
            <div className="col-3 p-0 text-center">
              <div className="text-muted">
                <FontAwesomeIcon
                  icon={faThermometerFull}
                  className=" fa-2x mt-2"
                />
              </div>
            </div>
            <div className="col-5">
              <div className="">
                <small>Temperature</small>
              </div>
              <div className="text-primary" style={{ marginTop: "-7px" }}>
                <small>°F</small>
              </div>
            </div>
            <div className="col-4">
              <input
              id="°F"
                type="number"
                name="temperature"
                onChange={handleBioMarkerChanges}
                value={BioMarkers.temperature?.value}
                className="form-control form-control-sm mt-2 bg-light border-bottom"
              />
            </div>
          </div>

          <div className="row m-0 my-2 bg-light rounded-lg">
            <div className="col-3 p-0 text-center">
              <div className="text-muted">
                <FontAwesomeIcon icon={faLungs} className=" fa-2x mt-2" />
              </div>
            </div>
            <div className="col-5">
              <div className="">
                <small>Respiratory Rate</small>
              </div>
              <div className="text-primary" style={{ marginTop: "-7px" }}>
                <small>Breaths/Min</small>
              </div>
            </div>
            <div className="col-4">
              <input
              id="breaths/min"
                type="number"
                name="respiratoryRate"
                onChange={handleBioMarkerChanges}
                value={BioMarkers.respiratoryRate?.value}
                className="form-control form-control-sm mt-2 bg-light border-bottom"
              />
            </div>
          </div>

          <div className="row m-0 my-2 bg-light rounded-lg">
            <div className="col-3 p-0 text-center">
              <div className="">
                <img
                  src="https://cdn.onlinewebfonts.com/svg/img_22955.png"
                  height="30px"
                  width="30px"
                  className="mt-2"
                  alt="image"
                />
              </div>
            </div>
            <div className="col-5">
              <div className="">
                <small>Waist Measurement</small>
              </div>
              <div className="text-primary" style={{ marginTop: "-7px" }}>
                <small>inches</small>
              </div>
            </div>
            <div className="col-4">
              <input
              id="inch"
                type="number"
                name="waistMeasurement"
                onChange={handleBioMarkerChanges}
                value={BioMarkers.waistMeasurement?.value}
                className="form-control form-control-sm mt-2 bg-light border-bottom"
              />
            </div>
          </div>

          <div className="row m-0 my-2 bg-light rounded-lg">
            <div className="col-3 p-0 text-center">
              <div className="">
                <img
                  src="https://www.iconfinder.com/data/icons/clothes-sizes/64/239_clothes-size-quide-measurement-hip-circumference-512.png"
                  alt="image"
                  height="35px"
                  width="35px"
                  className="mt-2"
                />
              </div>
            </div>
            <div className="col-5">
              <div className="">
                <small>Hip Measurement</small>
              </div>
              <div className="text-primary" style={{ marginTop: "-7px" }}>
                <small>inches</small>
              </div>
            </div>
            <div className="col-4">
              <input
              id="inch"
                type="number"
                name="hipMeasurement"
                onChange={handleBioMarkerChanges}
                value={BioMarkers.hipMeasurement?.value}
                className="form-control form-control-sm mt-2 bg-light border-bottom"
              />
            </div>
          </div>

          <div className="row m-0 my-2 bg-light rounded-lg">
            <div className="col-3 p-0 text-center">
              <div className="text-muted">
                
              <img src={chestMeasurementImg} alt="chestMeasurementImg" height="35px"/>
              </div>
            </div>
            <div className="col-5">
              <div className="">
                <small>Chest Measurement</small>
              </div>
              <div className="text-primary" style={{ marginTop: "-7px" }}>
                <small>inches</small>
              </div>
            </div>
            <div className="col-4">
              <input
              id="inch"
                type="number"
                name="chestMeasurement"
                onChange={handleBioMarkerChanges}
                value={BioMarkers.chestMeasurement?.value}
                className="form-control form-control-sm mt-2 bg-light border-bottom"
              />
            </div>
          </div>

          <div
            className="cursor-pointer py-1 mt-3 rounded-lg bg-success text-center text-white font-weight-bolder "
            onClick={handleBioMarkerSubmit}
          >
            Submit
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps)(Vitals);