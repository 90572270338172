import React, { Component } from "react";
import firebase from "firebase";
import moment from "moment";
import ReadMore from "../../Middle/ReadMore";

import { connect } from "react-redux";
import { publishMarks, publishRemark } from "./actions";

import {
  Button,
  Form,
  InputGroup,
  ProgressBar,
  Spinner,
  Badge,
  FormControl,
} from "react-bootstrap";

export class DisplayResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remark: {},
      grade: {},
      loading: true,
      content: "",
      marks: 0,
      responserName: "",
    };
  }

  componentDidMount() {
    const { response } = this.props;
    //console.log(response,"respoxxx")
    let docRef = firebase
      .firestore()
      .collection("users")
      .doc(`${response.id}`);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          //console.log(doc.data());
          this.setState({ responserName: data.firstName });
        } else {
          console.log("not exists");
        }
      })
      .catch((error) => console.error(error));

    this.setState({ loading: false });
  }

  strip = (value) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = value.match(regExp);
    return match && match[2].length === 11 ? (
      <iframe
        style={{ borderRadius: "4px" }}
        width="100%"
        height="170"
        src={"//www.youtube.com/embed/" + match[2]}
        frameborder="0"
        allowfullscreen
      ></iframe>
    ) : (
      <ReadMore full_text={value} max_length="120" />
    );
  };

  timeFromNow = (timestamp) => {
    if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
      return moment(timestamp).fromNow();
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
      return "yesterday";
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
      return moment(timestamp).format("dddd");
    else return moment(timestamp).format("DD/MM/YYYY");
  };

  render() {
    const {
      response,
      item,
      uid,
      currentChannel,
      currentUser,
      publishMarks,
      publishRemark,
    } = this.props;
    const {
      content,
      marks,
      loading,
      grade,
      remark,
      responserName,
    } = this.state;
    return (
      <div className="assignment-response pb-1">
       
        <p className="mt-1 font-weight-bold mb-0">{responserName}</p>
        {response.answers.map((ans, key) => (
          <div>
            
            <p className="font-weight-bold mb-0">
              Q{key + 1}. {item.questions[key].question}
            </p>
            {ans.answer ? (
              <p className="mt-0 mb-0">
                <span className="font-weight-bold">A{key + 1}.</span>{" "}
                {this.strip(ans.answer)}{" "}
              </p>
            ) : (
              ""
            )}
            {ans.attachment ? (
              <a className="mt-0 mb-0" href={ans.attachment} target="_blank">
                Attachment
              </a>
            ) : (
              ""
            )}
          </div>
        ))}
        {loading ? <Spinner size="sm" animation="border" /> : ""}
        {loading ? <Spinner size="sm" animation="border" /> : ""}
        <p className="mb-1 mt-0 small">
          <i>{this.timeFromNow(response.responseTime)}</i>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, { publishMarks, publishRemark })(
  DisplayResponse
);
