import React, { useEffect, useState, useRef } from "react";
import firebase from "firebase";
import {
  faCross,
  faTimes,
  faCalculator,
  faCrosshairs,
  faArrowsAlt,
  faTh,
  faPlus,
  faMinus,
  faExpand,
  faMicrophoneSlash,
  faComment,
  faHandPaper,
  faVideo,
  faVideoSlash,
  faMicrophone,
  faEye,
  faEyeSlash,
  faQuestionCircle,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  setIsVideoVisible,
  setChannelDetails,
  setConferenceSession,
  setVideoDetails,
} from "../../actions/index";
import { Card, Alert, Button, Dropdown, Spinner } from "react-bootstrap";

import ActionBox from "../Middle/ActionBoxForVideo";
import { API_BASE_URL, ROOM_CONFIG, MEETING_TYPE } from "../../config/index";
import { postData } from "../../utils/index";
import {
  browserName,
  CustomView,
  osName,
  deviceType,
  isIOS,
  isAndroid,
} from "react-device-detect";
import Webcam from "react-webcam";
import { loadState, saveState } from "../../services/index";
import Chat from "../Middle/Chat";
import VideoChat from "../Middle/VideoChat";
import VideoQnA from "../Middle/videoQnA";
import Broadcast from "./Broadcast";
import axios from "axios";
import https from "https";
import Subscribe from "./Subscribe";
import IsAdmin from "../IsAdmin";
import VideoConference from "./VideoConference";
import VideoConferenceSubscribe from "./VideoConferenceSubscribe";
import { disconnect } from "process";
import PublishersList from "./PublishersList";

function Video({
  userName,
  userAvatar,
  channelId,
  currentChannel,
  participants,
  currentUser,
  currentWorkspace,
  maximiseVideo,
  minimiseVideo,
  setIsVideoVisible,
  setChannelDetails,
  currentSubscription,
  channelDetails,
  isPublisher,
  conferenceSession,
  setConferenceSession,
  videoDetails,
  setVideoDetails,
}) {
  const styles = {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "middle",
    justifyContent: "center",
    background: "#EFEFEF",
    position: "relative",
  };

  /** Full Screen */
  // console.log(currentSubscription);
  function toggleFullScreen() {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  let unsubLive;

  /* Video Right Panel Visbility */
  const [videoRightPanelVisible, setVideoRightPanelVisible] = useState(false);
  const [videoMessages, setVideoMessages] = useState(false);
  const [videoQnA, setVideoQnA] = useState(false);
  const [publishersList, setPublishersList] = useState(false);
  const [raisedHands, setRaisedHands] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [warning, setWarning] = useState(false);
  const [speaking, setSpeaking] = useState("");
  const [tileView, setTileView] = useState("");
  const [audioMute, setAudioMute] = useState(false);
  const [videoMute, setVideoMute] = useState(false);
  const [loading, setLoading] = useState(true);
  const [avpreferencesnotset, setAvpreferencesnotset] = useState(true);
  const [rememberPreferences, setRememberPreferences] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [tempAPI, setTempAPI] = useState(null);
  const [isLD, setIsLD] = useState(false);
  const [isBroadcastMode, setIsBroadcastMode] = useState(false);
  const [streamState, setStreamState] = useState(null);
  const [isBeingBroadcasted, setIsBeingBroadcasted] = useState(false);

  const [unsubscribeZoomVideo, setUnsubscribeZoomVideo] = useState(null);

  // user is admin
  const [isAdmin, setIsAdmin] = useState(false);

  const [isSubscriberOnly, setIsSubscriberOnly] = useState(false);
  const [isPublisherVideoConference, setIsPublisherVideoConference] =
    useState(false);

  const [showNonChromeWarnings, setShowNonChromeWarnings] = useState(false);
  const [msgNonChromeWarnings, setMsgNonChromeWarnings] = useState("");

  const [showFirefoxWarnings, setShowFirefoxWarnings] = useState(false);
  const [msgFirefoxAndDesktopWarnings, setMsgFirefoxAndDesktopWarnings] =
    useState("");

  const [showEdgeWarnings, setShowEdgeWarnings] = useState(false);
  const [msgEdgeAndDesktopWarnings, setMsgEdgeAndDesktopWarnings] =
    useState("");

  const [showChromeWarnings, setShowChromeWarnings] = useState(false);
  const [msgChromeAndDesktopWarnings, setMsgChromeAndDesktopWarnings] =
    useState("");

  const [showSafariWarnings, setShowSafariWarnings] = useState(false);
  const [msgSafariAndDesktopWarnings, setMsgSafariAndDesktopWarnings] =
    useState("");

  const renderCount = useRef(0);
  const [linkOpened, setLinkOpened] = useState(null);
  const [iframeURL, setIframeURL] = useState("");

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`versions`)
      .doc(`web-errors`)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const {
              showNonChromeWarnings,
              msgNonChromeAndDesktopWarnings,
              showFirefoxWarnings,
              msgFirefoxAndDesktopWarnings,
              showEdgeWarnings,
              msgEdgeAndDesktopWarnings,
              showChromeWarnings,
              msgChromeAndDesktopWarnings,
              showSafariWarnings,
              msgSafariAndDesktopWarnings,
            } = doc.data();

            setShowNonChromeWarnings(showNonChromeWarnings);
            setMsgNonChromeWarnings(msgNonChromeWarnings);

            setShowFirefoxWarnings(showFirefoxWarnings);
            setMsgFirefoxAndDesktopWarnings(msgFirefoxAndDesktopWarnings);

            setShowEdgeWarnings(showEdgeWarnings);
            setMsgEdgeAndDesktopWarnings(msgEdgeAndDesktopWarnings);

            setShowChromeWarnings(showChromeWarnings);
            setMsgChromeAndDesktopWarnings(msgChromeAndDesktopWarnings);

            setShowSafariWarnings(showSafariWarnings);
            setMsgSafariAndDesktopWarnings(msgSafariAndDesktopWarnings);
          }
        },
        (err) => {
          console.error(err);
        }
      );

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    let prefs = loadState("conferencePreference");
    if (prefs) {
      let parsedPrefs = JSON.parse(prefs);
      setRememberPreferences(true);
      setAudioMute(parsedPrefs.audio);
      setVideoMute(parsedPrefs.video);
      // setVideoMute(false);
    }
  }, []);

  useEffect(() => {
    if (
      showNonChromeWarnings &&
      browserName != "Chrome" &&
      deviceType != "mobile" &&
      deviceType != "tablet"
    ) {
      setWarning(true);
    }
  }, [setWarning]);

  const svgstyle = {
    position: "absolute",
    top: "150px",
    height: "200px",
    width: "200px",
    marginLeft: "1.5%",
    transform: "translateY(-50%)",
  };

  const WebcamComponent = () => (
    <Webcam
      audio={true}
      videoConstraints={{ width: 300, height: 160 }}
      className="rounded shadow"
      style={
        videoMute
          ? { filter: "blur(12px)", transform: "scaleX(-1)" }
          : { transform: "scaleX(-1)" }
      }
    />
  );

  const joinSession = () => {
    if (rememberPreferences) {
      let prefs = {
        video: videoMute,
        audio: audioMute,
      };
      saveState("conferencePreference", JSON.stringify(prefs));
    }

    setAvpreferencesnotset(false);
  };

  const joinVideoConference = () => {
    if (rememberPreferences) {
      let prefs = {
        video: videoMute,
        audio: audioMute,
      };
      saveState("conferencePreference", JSON.stringify(prefs));
    }

    setAvpreferencesnotset(false);
    setLoading(false);
    setIsPublisherVideoConference(true);
  };

  const subscriberVideoConference = () => {
    if (rememberPreferences) {
      let prefs = {
        video: videoMute,
        audio: audioMute,
      };
      saveState("conferencePreference", JSON.stringify(prefs));
    }
    setIsSubscriberOnly(true);
    setAvpreferencesnotset(false);
    setLoading(false);
  };

  const startBroadcast = () => {
    setLoading();
    setAvpreferencesnotset(false);
    setIsBroadcastMode(true);
  };

  const zoomMeetingStartHandler = () => {
    renderCount.current = renderCount.current + 1;
    if (videoDetails.zoom_auth_url) {
      if (renderCount.current === 12) {
        window.open(videoDetails.zoom_auth_url, "_blank");
        setIsVideoVisible(false);
        setChannelDetails(null);
      }
      return <h4>Meeting started please switch to zoom tab</h4>;
    } else if (
      videoDetails.request_user_id === videoDetails.user_id &&
      isAdmin &&
      videoDetails.start_url
    ) {
      if (renderCount.current === 12) {
        window.open(videoDetails.start_url, "_blank");
        setIsVideoVisible(false);
        setChannelDetails(null);
      }
    } else if (videoDetails.join_url) {
      if (renderCount.current === 11) {
        window.open(videoDetails.join_url, "_blank");
        setIsVideoVisible(false);
        setChannelDetails(null);
      }
    } else {
      if (renderCount.current === 11) {
        const unsubVideoZoom = firebase
          .firestore()
          .collection(
            `sessions/${currentWorkspace.workspaceId.toLowerCase()}/rooms`
          )
          .doc(`${currentChannel.roomId.toLowerCase()}`)
          .onSnapshot((doc) => {
            if (doc.exists) {
              const { currentSession } = doc.data();
              if (currentSession !== "") {
                axios
                  .post(`${API_BASE_URL}${MEETING_TYPE}`, {
                    room_id: currentChannel.roomId,
                    workspace_id: currentWorkspace.workspaceId,
                    user_id: currentUser.uid,
                    email: currentUser.email,
                  })
                  .then((res) => {
                    if (res.status === 200) {
                      setVideoDetails(res.data.data);
                      window.open(res.data.data.join_url, "_blank");
                      setChannelDetails(currentChannel);
                      setIsVideoVisible(false);
                    } else {
                      console.log(
                        "Could not get info about video meeting:",
                        res.status
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(
                      "Could not get info about video meeting:",
                      error
                    );
                  });
              } else {
                return false;
              }
            } else {
              return false;
            }
          });
        return (
          <p>
            <span className="text-theme font-weight-bold">
              Waiting for admin to create/start the meeting
            </span>
          </p>
        );
        setUnsubscribeZoomVideo(() => unsubVideoZoom);
      }
    }
  };

  const cutsomURLVideoHandler = () => {
    renderCount.current = renderCount.current + 1;
    if (!linkOpened) {
      window.open(videoDetails.video_meeting_url, "_blank");
      setLinkOpened(true);
      // setIsVideoVisible(false);
      // setChannelDetails(null);
    }
    return (
      <>
        <p>
          <span className="text-theme font-weight-bold">
            Meeting started please switch to the meeting tab
          </span>
        </p>
        <p>
          <Button
            onClick={() => {
              window.open(videoDetails.video_meeting_url, "_blank");
            }}
          >
            Go to meeting url
          </Button>
        </p>
      </>
    );
  };

  const AVPreference = () => (
    <div style={styles} className="bg-theme text-center">
      <div>
        {/* <h3 className="text-theme text-center mt-3 mb-4">
          How would you like to join the session?
        </h3> */}
        <div className="my-3">
          {!videoDetails?.video_meeting_type ? (
            // jitsi
            <button className="btn btn-primary" onClick={() => joinSession()}>
              <small className="font-weight-bold">JOIN SESSION</small>
            </button>
          ) : videoDetails?.video_meeting_type === 1 ? (
            // premium
            <button
              className="btn btn-primary"
              onClick={() => joinVideoConference()}
            >
              <small className="font-weight-bold">JOIN VIDEO CONFERENCE</small>
            </button>
          ) : videoDetails?.video_meeting_type === 2 ? (
            // broadcast
            !isBeingBroadcasted && currentSubscription?.broadcasting ? (
              <IsAdmin>
                <button
                  className="btn btn-primary ml-3"
                  onClick={() => startBroadcast()}
                >
                  <small className="font-weight-bold">START BROADCAST</small>
                </button>
              </IsAdmin>
            ) : isBeingBroadcasted && !isPublisher ? (
              <button
                className="btn btn-primary ml-3"
                onClick={() => startBroadcast()}
              >
                <small className="font-weight-bold">JOIN BROADCAST</small>
              </button>
            ) : (
              <p>
                <span className="text-theme font-weight-bold">
                  Broadcast has not started yet
                </span>
              </p>
            )
          ) : videoDetails?.video_meeting_type === 4 ? (
            // zoom
            cutsomURLVideoHandler()
          ) : null}
          {/* {channelDetails.videoMeetingType ? (
            <>
              <button
                className="btn btn-primary"
                onClick={() => joinVideoConference()}
              >
                <small className="font-weight-bold">
                  JOIN VIDEO CONFERENCE
                  </small>
              </button>

              {/* {!isAdmin ? (
                <button
                  className="btn btn-primary"
                  onClick={() => subscriberVideoConference()}
                >
                  <small className="font-weight-bold">
                    JOIN VIDEO CONFERENCE
                  </small>
                </button>
              ) : null} */}
          {/* </>
          ) : (
              <button className="btn btn-primary" onClick={() => joinSession()}>
                <small className="font-weight-bold">JOIN SESSION</small>
              </button>
            )}

          {!isBeingBroadcasted && currentSubscription?.broadcasting ? (
            <IsAdmin>
              <button
                className="btn btn-primary ml-3"
                onClick={() => startBroadcast()}
              >
                <small className="font-weight-bold">START BROADCAST</small>
              </button>
            </IsAdmin>
          ) : null}

          {isBeingBroadcasted && !isPublisher ? (
            <button
              className="btn btn-primary ml-3"
              onClick={() => startBroadcast()}
            >
              <small className="font-weight-bold">JOIN BROADCAST</small>
            </button>
          ) : null}  */}
        </div>
        {videoDetails?.video_meeting_type === 0 ||
        videoDetails?.video_meeting_type === 1 ||
        (videoDetails?.video_meeting_type === 2 &&
          !isBeingBroadcasted &&
          currentSubscription?.broadcasting &&
          isAdmin) ? (
          <>
            <div
              className="mb-3 pointer"
              style={{ position: "relative" }}
              onClick={() => {
                setVideoMute(!videoMute);
                // setVideoMute(false);
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "48%",
                  left: "48%",
                  zIndex: "1000",
                }}
              >
                {videoMute ? (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    size="lg"
                    style={{ opacity: "75%" }}
                  />
                ) : null}
              </div>
              {WebcamComponent()}
            </div>
            <div className="d-flex justify-content-center mb-3">
              <div className="text-center mr-5">
                <button
                  className={
                    videoMute
                      ? "btn btn-light rounded-circle mb-1"
                      : "btn btn-success rounded-circle mb-1"
                  }
                  style={{ width: "45px", height: "45px" }}
                  onClick={() => {
                    setVideoMute(!videoMute);
                  }}
                >
                  {videoMute ? (
                    <FontAwesomeIcon icon={faVideoSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faVideo} />
                  )}
                </button>
                <p className="small text-theme">
                  {videoMute ? <>Video Off</> : <>Video On</>}
                </p>
              </div>
              <div className="text-center">
                <button
                  className={
                    audioMute
                      ? "btn btn-light rounded-circle mb-1"
                      : "btn btn-success rounded-circle mb-1"
                  }
                  style={{ width: "45px", height: "45px" }}
                  onClick={() => {
                    setAudioMute(!audioMute);
                  }}
                >
                  {audioMute ? (
                    <FontAwesomeIcon icon={faMicrophoneSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faMicrophone} />
                  )}
                </button>
                <br />
                <p className="small text-theme">
                  {audioMute ? <>Audio Off</> : <>Audio On</>}
                </p>
              </div>
            </div>
            <div className="d-block mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                value={rememberPreferences}
                checked={rememberPreferences}
                onChange={() => setRememberPreferences(!rememberPreferences)}
                id="defaultCheck1"
              />

              <label className="form-check-label small" for="defaultCheck1">
                Remember my preference and <br /> skip this review from next
                time
              </label>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );

  useEffect(() => {
    const getIsLive = async () => {
      const islive = await firebase
        .firestore()
        .collection(
          `sessions/${currentWorkspace.workspaceId.toLowerCase()}/rooms`
        )
        .doc(`${currentChannel.roomId.toLowerCase()}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("LIVE Sessions doc: ", doc.data());
            const { currentSession } = doc.data();
            if (currentSession !== "") {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
      return islive;
    };
    if (currentWorkspace && currentChannel) {
      getIsLive().then((value) => {
        // console.log(value, "is live");
        setIsLive(value);
      });
    }
  }, [currentWorkspace, currentChannel]);

  useEffect(() => {
    // to find whether a stream is already live or not?
    firebase
      .firestore()
      .collection(`broadcasts/${currentWorkspace.workspaceId}/rooms/`)
      .doc(channelDetails.roomId)
      .onSnapshot((doc) => {
        if (doc.exists) {
          if (doc.data().currentStream) {
            setIsBeingBroadcasted(true);
          } else {
            setIsBeingBroadcasted(false);
          }
        } else {
          setIsBeingBroadcasted(false);
        }
      });
  }, [currentWorkspace, channelDetails]);

  const Loader = () => (
    <div style={styles}>
      <div
        style={{
          fontSize: "14px",
          fontWeight: `'Helvetica Neue', '-apple-system', 'Open Sans', 'sans-serif'`,
          position: "absolute",
          top: "55%",
          textAlign: "center",
        }}
      >
        {isLive && (
          <div className="d-flex align-items-center">
            <Spinner size="sm" animation="border" className="text-secondary" />{" "}
            <p className="m-0">&nbsp;Connecting</p>
          </div>
        )}
        {!waiting && !isLive && (
          <span className="text-theme-inverted">
            Relax... as we secure your video.
          </span>
        )}
        {waiting && !isLive && (
          <p>
            <span className="text-theme-inverted font-weight-bold">
              Waiting for Admin to start meeting.
            </span>
          </p>
        )}
        {iframeURL != "" && (
          <div className="d-flex align-items-center justify-content-center">
            <a
              className="mt-2 text-primary"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => {
                closeVideo();
                window.open(iframeURL, "_blank").focus();
              }}
            >
              Trouble connecting? Open meeting in new tab
            </a>
          </div>
        )}
      </div>
      <div
        style={{
          fontSize: "8px",
          fontWeight: `'Helvetica Neue', '-apple-system', 'Open Sans', 'sans-serif'`,
          position: "absolute",
          bottom: "5%",
        }}
      >
        {/* <span>
              Based on the beautiful artwork by Mike Harris. Visit his page on <a href='https://codepen.io/mikemjharris/pen/pJaaXR' target='_blank'> Codepen</a>
          </span> */}
      </div>
      <svg
        style={svgstyle}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width="2000"
        height="1000"
        version="1.0"
      >
        <g transform="translate(300, 250)">
          <g transform="rotate(0, -200, 130)">
            <g transform="rotate(0, 0, 0)">
              <g transform="rotate( 0,0,-8)">
                <path
                  d="M0 -110 l0 100"
                  fill="none"
                  stroke="black"
                  stroke-width="3"
                >
                  <animate
                    dur="8s"
                    repeatCount="indefinite"
                    attributeName="d"
                    values="
                      M0 -100 c-10 50 10 50 0 100;
                      M0 -100 c0 50 0 50 0 90;
                      M0 -100 c-30 50 -20 50 0 90;
                      M0 -100 c0 50 0 50 0 90;
                      M0 -100 c-20 70 -20 50 0 90;
                      M0 -100 c-10 70 -10 50 0 95;
                      M0 -80 c0 70 0 50 0 95;
                      M0 -80 c0 70 0 50 0 95;
                      M0 -80 c0 70 0 50 0 95;
                      M-60 -75 c40 30 50 50 60 95;
                      M-60 -50 c0 30 0 50 0 100;
                    M-60 -40 c-40 25 -40 25 -80 50;
                      M-60 -50 c0 30 0 50 0 100;
                      M0 -100 c-10 70 -10 50 0 95;
                      M0 -100 c-20 70 -20 50 0 90;
                      M0 -100 c-10 50 10 50 0 100;"
                  />
                </path>
                <path
                  d="M0 -100 l0 -45 l0 -45"
                  fill="none"
                  stroke="black"
                  stroke-width="3"
                >
                  <animate
                    dur="8s"
                    repeatCount="indefinite"
                    attributeName="d"
                    values="
                     M0 -90 l-5 40 l10 40;
                     M0 -100 l0 -45 l0 -45;
                     M0 -100 l45 10 l45 10;
                     M0 -100 l45 0 l45 0;
                     M0 -100 l45 -8 l45 -8;
                     M0 -100 l45 -8 l45 -8;
                     M0 -80 l45 -18 l45 -18;
                     M0 -80 l50 10 l50 10;
                      M0 -80 l-10 50 l50 -50;
                     M-60 -75 l50 10 l50 -15;
                     M-60 -50 l50 -15 l50 -15;
                    M-60 -40 l50 -20 l50 -20;
                     M-60 -50 l50 -15 l70 -15;
                     M0 -100 l45 -8 l70 -8; 
                     M0 -100 l45 -8 l45 -8; 
                     M0 -90 l-5 40 l10 40;"
                  />
                </path>
                <circle
                  cx="0"
                  cy="-140"
                  r="30"
                  stroke="black"
                  stroke-width="3"
                  fill="none"
                >
                  <animate
                    dur="8s"
                    repeatCount="indefinite"
                    attributeName="cy"
                    values="
                    -130; -130; -130; -130; -130; -130; -110; -110; -110;-90; -70; -50; -70; -130; -130; -130"
                  />
                  <animate
                    dur="8s"
                    repeatCount="indefinite"
                    attributeName="cx"
                    values="
                    0; 0; 0; 0; 0; 0; 0; 0; 0;-85; -40; -30; -40; 0; 0; 0"
                  />
                </circle>
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  fill="freeze"
                  values="
                  0,0,-8;
                  0,0,-8;
                  130,0,-8;
                  90, 0, -8;
                  130, 0, -8;
                  130, 0, -8;
                  130, 0, -8;
                  130, 0, -8;
                  130, 0, -8;
                  130, 0, -8;
                  130, 0, -8;
                  130, 0, -8;
                  130, 0, -8;
                  130, 0, -8;
                  130, 0, -8;
                  0,0,-8"
                  dur="8s"
                  repeatCount="indefinite"
                />
              </g>
              <g>
                <path
                  d="M0 -10 l0 45 l0 45"
                  fill="none"
                  stroke="black"
                  stroke-width="3"
                >
                  <animate
                    dur="8s"
                    repeatCount="indefinite"
                    attributeName="d"
                    values="
                   M0 0 l5 65 l-10 65;
                   M0 -10 l0 70 l0 70;
                   M0 -10 l0 70 l0 70;
                   M0 -10 l0 70 l0 70;
                   M0 -10 l0 70 l0 70;
                   M0 -10 l70 40 l-70 40;
                  M0 -10 l0 70 l0 70;
                   M0 -10 l0 70 l0 70;
                   M0 -10 l0 70 l0 70;
                   M0 -10 l0 70 l0 70;
                   M-60 24 l60 10 l0 80;
                  M-136 -13 l70 60 l70 60;
                   M-60 24 l60 10 l0 80;
                   M0 -10 l70 40 l-70 40;
                   M0 -10 l0 70 l0 70;
                   M0 0 l5 65 l-10 65;"
                  />
                </path>
                <animateTransform
                  attributeName="transform"
                  attributeType="XML"
                  type="rotate"
                  fill="freeze"
                  values="
                  0,0,-10;
                  0,0,-10;
                  0,0,-10;
                  0, 0, -10;
                  0, 0, -10;
                  120, 0, -10;
                  130, -5 , -20;
                  130, -5 , -20;
                   130, -5 , -20;
                  130, -5 , -20;
                  130, -5 , -20;
                  130, -5 , -20;
                  130, -5 , -20;
                  120, 0 , -10;
                  0, 0, -10;
                  0, 0, -10"
                  dur="8s"
                  repeatCount="indefinite"
                />
              </g>

              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                fill="freeze"
                values="
                  0,  25, 80;
                  0,  25, 80;
                  0, 25, 80;
                  0, 25, 80;
                  0, 25, 80;
                  0, 25, 80;
                  0, 25, 80;
                -65, 25, 80;
                -65, 25, 80;
                -65, 25, 80;
                -65, 25, 80;
                -65, 25, 80;
                -65, 25, 80;
                0, 25, 80;
                0, 25, 80;
                0, 25, 80;"
                dur="8s"
                repeatCount="indefinite"
              />
            </g>
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              fill="freeze"
              values="
                  0, -200, 130;
                  0, -200, 130
                  0, -200, 130;
                  0, -200, 130;
                  0, -200, 130;
                  0, -200, 130;
                  0, -200, 130;
                  0, -200, 130;
                  15, -200, 130;
                  15, -200, 130;
                  15, -200, 130;
                  15, -200, 130;
                  15, -200, 130;
                  0, -200, 130;
                  0, -200, 130;
                  0, -200, 130;"
              dur="8s"
              repeatCount="indefinite"
            />
          </g>

          <path
            d="M-250 155 l300 0"
            fill="none"
            stroke="#EFEFEF"
            stroke-width="50"
          />
          <path
            d="M-250 130 l300 0"
            fill="none"
            stroke="black"
            stroke-width="3"
          />
        </g>
      </svg>
    </div>
  );

  const VideoMeeting = () => (
    <>
      {!isBroadcastMode && !isPublisherVideoConference && !isSubscriberOnly ? (
        <div id="jitsi-container" style={jitsiContainerStyle} />
      ) : !isSubscriberOnly && isPublisherVideoConference ? (
        <VideoConference isAudioMute={audioMute} isVideoMute={videoMute} />
      ) : isSubscriberOnly && !isPublisherVideoConference ? (
        <VideoConferenceSubscribe />
      ) : !isBeingBroadcasted || isPublisher ? (
        <Broadcast setStreamStateProps={setStreamState} />
      ) : (
        <Subscribe />
      )}
    </>
  );

  const videoStyle = {
    width: "100%",
    overflow: "auto",
    boxSizing: "border-box",
    padding: "0rem",
    height: "100%",
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const jitsiContainerStyle = {
    display: loading ? "none" : "block",
    width: "100%",
    height: "100%",
  };

  function checkUserAvatar(userAvatar) {
    if (
      userAvatar ==
      "https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822"
    )
      return null;
    else return userAvatar;
  }

  const getVideoServer = (videoServer) => {
    if (videoServer) {
      if (videoServer === "goa.igesia.co") {
        return "ap-south-1.igesia.com";

        // } else if (videoServer === "sa-east-1.igesia.co") {
        //   return "sa-east-1.igesia.com";
      } else if (videoServer === "eu-north-1.igesia.co") {
        return "eu-central-1.igesia.com";
      } else return `${videoServer}`;
    } else return "ap-south-1.igesia.com";
  };

  async function startConference() {
    try {
      let roomConfigData = {
        workspace_id: currentWorkspace.workspaceId,
        room_id: currentChannel.roomId,
        user_id: currentUser.uid,
      };

      postData(`${API_BASE_URL}${ROOM_CONFIG}`, roomConfigData)
        .then((response) => {
          const { status, message, data } = response;

          // console.log("status", status, "\nmessage", message, "\ndata", data)
          let checkIfAdmin = currentChannel.createdBy === currentUser.uid;

          let coadmin = false;
          participants.forEach((parti, index) => {
            if (parti.uid === currentUser.uid && parti.isAdmin) {
              coadmin = true;
            }
          });

          let checkIfCoAdmin = !checkIfAdmin && coadmin;

          if (status === 200 && data) {
            const { videoServer } = data;
            const {
              serverURL,
              roomName,
              height,
              parentNodeRef,
              jwt,
              noSsl,
              noticeMessage,
              enableUserRolesBasedOnToken,
              interfaceConfigOverwrite,
              configOverwrite,
              userInfo,
            } = videoServer;

            let domain = serverURL.replace("https://", "");

            let OLD_TOOLBAR_BUTTONS = interfaceConfigOverwrite.TOOLBAR_BUTTONS;

            let NEW_TOOLBAR_BUTTONS = [
              // currentSubscription && currentSubscription.recording
              //   ? "recording"
              //   : null,
              // currentSubscription && currentSubscription.streaming
              //   ? "livestreaming"
              //   : null,
              ...OLD_TOOLBAR_BUTTONS,
            ];

            let newInterfaceConfigOverwrite = {
              ...interfaceConfigOverwrite,
              TOOLBAR_BUTTONS: NEW_TOOLBAR_BUTTONS,
            };

            let options = {
              roomName,
              height,
              parentNode: document.getElementById(`${parentNodeRef}`),
              jwt,
              noSsl,
              noticeMessage,
              enableUserRolesBasedOnToken,
              interfaceConfigOverwrite:
                checkIfAdmin || checkIfCoAdmin
                  ? newInterfaceConfigOverwrite
                  : interfaceConfigOverwrite,
              configOverwrite,
              userInfo,
            };

            if (jwt) {
              setWaiting(false);
              setIframeURL(
                `${serverURL}/${roomName}?jwt=${jwt}#jitsi_meet_external_api_id=0&${Object.keys(
                  configOverwrite
                )
                  ?.map((e) => `config.${e}=${configOverwrite[`${e}`]}`)
                  ?.join("&")}&${Object.keys(interfaceConfigOverwrite)
                  ?.map((e) =>
                    typeof e === "boolean"
                      ? `interfaceConfig.${e}=${
                          interfaceConfigOverwrite[`${e}`]
                        }`
                      : `interfaceConfig.${e}=${encodeURIComponent(
                          JSON.stringify(interfaceConfigOverwrite[`${e}`])
                        )}`
                  )
                  ?.join("&")}&${Object.keys(userInfo)
                  ?.map((e) =>
                    typeof e === "boolean"
                      ? `userInfo.${e}=${userInfo[`${e}`]}`
                      : `userInfo.${e}=${encodeURIComponent(
                          JSON.stringify(userInfo[`${e}`])
                        )}`
                  )
                  ?.join("&")}&appData.localStorageContent=null`
              );
            } else {
              setWaiting(true);
              setIframeURL(
                `${serverURL}/${roomName}#jitsi_meet_external_api_id=0&${Object.keys(
                  configOverwrite
                )
                  ?.map((e) => `config.${e}=${configOverwrite[`${e}`]}`)
                  ?.join("&")}&${Object.keys(interfaceConfigOverwrite)
                  ?.map((e) =>
                    typeof e === "boolean"
                      ? `interfaceConfig.${e}=${
                          interfaceConfigOverwrite[`${e}`]
                        }`
                      : `interfaceConfig.${e}=${encodeURIComponent(
                          JSON.stringify(interfaceConfigOverwrite[`${e}`])
                        )}`
                  )
                  ?.join("&")}&${Object.keys(userInfo)
                  ?.map((e) =>
                    typeof e === "boolean"
                      ? `userInfo.${e}=${userInfo[`${e}`]}`
                      : `userInfo.${e}=${encodeURIComponent(
                          JSON.stringify(userInfo[`${e}`])
                        )}`
                  )
                  ?.join("&")}&appData.localStorageContent=null`
              );
            }

            // console.log("domain", domain, "\noptions", options)

            const api = new window.JitsiMeetExternalAPI(domain, options);
            setTempAPI(api);

            api.executeCommand("subject", channelId.roomName);
            if (audioMute) {
              api.executeCommand("toggleAudio");
            }
            if (videoMute) {
              setTimeout(() => api.executeCommand("toggleVideo"), 500);
            }
            api.executeCommand("toggleTileView");

            if (checkUserAvatar(userAvatar) != null) {
              api.executeCommand("avatarUrl", userAvatar);
            }

            api.addEventListener("videoConferenceJoined", (data) => {
              // console.log("Local User Joined. User Data:", data);
              setLoading(false);

              // unsubLive =
              // setTimeout(
              //   () =>
              //     firebase
              //       .firestore()
              //       .collection(
              //         `sessions/${currentWorkspace.workspaceId.toLowerCase()}/rooms`
              //       )
              //       .doc(`${currentChannel.roomId.toLowerCase()}`)
              //       .get()
              //       .then((doc) => {
              //         if (doc.exists) {
              //           console.log("LIVE Sessions doc: ", doc.data());
              //           const { currentSession } = doc.data();
              //           if (currentSession !== "") {
              //             firebase
              //               .firestore()
              //               .collection(
              //                 `sessions/${currentWorkspace.workspaceId.toLowerCase()}/rooms/${currentChannel.roomId.toLowerCase()}/sessions/${currentSession}/participants`
              //               )
              //               .doc(`${currentUser.uid}`)
              //               .set({
              //                 entryTimestamp: Date.now(),
              //               })
              //               .then(function () {
              //                 console.log("Document successfully updated!");
              //               })
              //               .catch(function (error) {
              //                 // The document probably doesn't exist.
              //                 console.error("Error updating document: ", error);
              //               });
              //           }
              //         }
              //       }),
              //   1000
              // );

              // unsubLive()

              // console.log("data.roomName: ", data.roomName.split("-")[1], " userID: ", currentUser.uid, " timestamp: ", Date.now())

              // Event- Dominant Speaker Changed
              api.on("dominantSpeakerChanged", (id) => {
                //console.log('Currently speaking ', id);
                const displayName = api.getDisplayName(id.id);
                setSpeaking(displayName);
              });

              api.on("tileViewChanged", (status) => {
                //console.log('TileView ', status.enabled);
                setTileView(status.enabled);
              });

              // Event- Suspend Detected
              api.on("suspendDetected", (data) => {
                console.log("Suspend Detected Event:", data);
              });
            });
          }
        })
        .catch((err) => console.error(err));
    } catch (error) {
      console.error("Failed to load Video API", error);
    }
  }

  function endConference() {
    console.log("Ending Live Conference");
  }

  function storePresenceInfo() {
    const uid = firebase.auth().currentUser.uid;
    let userStatusDatabaseRef = firebase.database().ref("/status/" + uid);

    const isOfflineForDatabase = {
      state: "offline",
      last_changed: firebase.database.ServerValue.TIMESTAMP,
      roomId: channelId.roomId,
    };

    const isOnlineForDatabase = {
      state: "online",
      last_changed: firebase.database.ServerValue.TIMESTAMP,
      roomId: channelId.roomId,
    };

    firebase
      .database()
      .ref(".info/connected")
      .on("value", (snapshot) => {
        if (snapshot.val() == false) return;

        userStatusDatabaseRef
          .onDisconnect()
          .set(isOfflineForDatabase)
          .then(() => {
            userStatusDatabaseRef.set(isOnlineForDatabase);
          });
      });
  }

  useEffect(() => {
    // console.log("Executing Initial useEffect process.");
    // storePresenceInfo();
    if (
      window.JitsiMeetExternalAPI &&
      !avpreferencesnotset &&
      !isBroadcastMode
    ) {
      startConference();
    }

    return () => {
      // console.log("Executing useEffect cleanup process.");
      if (unsubscribeZoomVideo) {
        unsubscribeZoomVideo();
      }
      endConference();
      renderCount.current = 0;
      setLinkOpened(false);
    };
  }, [avpreferencesnotset, isBroadcastMode]);

  const stopMediaStreams = () => {
    const localVideo = document.getElementById("localVideo");
    const localTracks = localVideo?.srcObject?.getTracks();

    if (localTracks) {
      localTracks.forEach((track) => {
        // console.log('stopping track', track);
        track.stop();
      });
      localTracks.srcObject = null;
    }
  };

  const closeVideo = () => {
    // At request level
    const agent = new https.Agent({
      rejectUnauthorized: false,
    });

    if (streamState) {
      // not using api for now cause the broadcaster can also call the api and end the stream. Need to limit it to admin and the publisher only

      // axios.post(`https://antmedia.igesia.com/WebRTCApp/rest/v2/broadcasts/${currentWorkspace.workspaceId}-${channelDetails.roomId}/stop`, { httpsAgent: agent }).then((res) => {
      //   console.log("streaming stopped", res)
      // })
      stopMediaStreams();
      streamState.closeStream(
        `${currentWorkspace.workspaceId}-${channelDetails.roomId}`
      );
      streamState.stop(
        `${currentWorkspace.workspaceId}-${channelDetails.roomId}`
      );
    }

    if (conferenceSession) {
      // console.log("disconnect");
      conferenceSession.off();
      conferenceSession.disconnect();
      setConferenceSession(null);

      firebase
        .firestore()
        .collection(`videoConference/${currentWorkspace.workspaceId}/rooms`)
        .doc(`${currentChannel.roomId}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const currentPublishers = doc.data().currentPublishers
              ? doc.data().currentPublishers
              : [];

            const tempCurrentPublisher = [];

            currentPublishers.forEach((p, index) => {
              if (p.uid !== currentUser.uid) {
                tempCurrentPublisher.push(p);
              }
            });

            firebase
              .firestore()
              .collection(
                `videoConference/${currentWorkspace.workspaceId}/rooms`
              )
              .doc(`${currentChannel.roomId}`)
              .set(
                {
                  currentPublishers: tempCurrentPublisher,
                },
                { merge: true }
              );
          }
        });
    }

    setIsVideoVisible(false);
    setChannelDetails(null);
  };

  const toggleVideoRightPanel = (str) => {
    //setVideoRightPanelVisible(!videoRightPanelVisible);
    if (str === "chat") {
      if (videoMessages) {
        setVideoMessages(false);
        setVideoRightPanelVisible(false);
      } else {
        setVideoQnA(false);
        setPublishersList(false);
        setVideoMessages(true);
        setVideoRightPanelVisible(true);
      }
    } else if (str === "publishersList") {
      if (publishersList) {
        setPublishersList(!publishersList);
        setVideoRightPanelVisible(false);
      } else {
        setVideoMessages(false);
        setVideoQnA(false);
        setPublishersList(true);
        setVideoRightPanelVisible(true);
      }
    } else {
      if (videoQnA) {
        setVideoQnA(!videoQnA);
        setVideoRightPanelVisible(false);
      } else {
        setVideoMessages(false);
        setPublishersList(false);
        setVideoRightPanelVisible(true);
        setVideoQnA(true);
      }
    }

    maximiseVideo();
  };

  const toggleVideoQuality = () => {
    const currentVideoQuality = tempAPI.getVideoQuality();
    // console.log("currentVideoQuality", currentVideoQuality);
    if (currentVideoQuality === 180) {
      setIsLD(false);
      tempAPI.executeCommand("setVideoQuality", 360);
    } else {
      setIsLD(true);
      tempAPI.executeCommand("setVideoQuality", 180);
    }
  };

  const getIsAdminHandler = async () => {
    if (channelDetails && currentWorkspace && currentUser.uid) {
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${channelDetails.roomId}/participants`
        );
      let res = (await query.get()).docChanges().map((s) => {
        let json = { ...s.doc.data(), id: s.doc.id };
        return json;
      });

      let coAdmins = res.filter((participant) => participant.isAdmin);

      if (
        channelDetails.createdBy === currentUser.uid ||
        coAdmins.find((usr) => usr.id === currentUser.uid)
      ) {
        setIsAdmin(true);
      }
    }
  };

  useEffect(() => {
    getIsAdminHandler();
  }, [channelDetails, currentWorkspace, currentUser]);

  return (
    <>
      {warning ? (
        <Alert variant="warning" onClose={() => setWarning(false)} dismissible>
          <p className="mb-1 mt-1 small">
            {msgNonChromeWarnings}{" "}
            <a href="https://www.google.com/chrome/" target="_blank">
              Download Chrome
            </a>
          </p>
        </Alert>
      ) : null}

      {browserName === "Edge" && showEdgeWarnings ? (
        <Alert
          variant="warning"
          // onClose={() => setWarning(false)}
          // dismissible
        >
          <p className="mb-1 mt-1 small">{msgEdgeAndDesktopWarnings}</p>
        </Alert>
      ) : null}

      {browserName === "Firefox" && showFirefoxWarnings ? (
        <Alert
          variant="warning"
          // onClose={() => setWarning(false)}
          // dismissible
        >
          <p className="mb-1 mt-1 small">{msgFirefoxAndDesktopWarnings}</p>
        </Alert>
      ) : null}

      {browserName === "Chrome" && showChromeWarnings ? (
        <Alert
          variant="warning"
          // onClose={() => setWarning(false)}
          // dismissible
        >
          <p className="mb-1 mt-1 small">{msgChromeAndDesktopWarnings}</p>
        </Alert>
      ) : null}

      {browserName === "Safari" && showSafariWarnings ? (
        <Alert
          variant="warning"
          // onClose={() => setWarning(false)}
          // dismissible
        >
          <p className="mb-1 mt-1 small">{msgSafariAndDesktopWarnings}</p>
        </Alert>
      ) : null}

      <Card style={videoStyle} className="shadow-lg bg-theme border-1">
        <Card.Header className="px-0 m-0 d-flex">
          <div className="d-flex justify-content-center align-items-center">
            <div
              onClick={closeVideo}
              className="d-flex justify-content-center align-items-center ml-2 p-2 text-center pointer"
              style={{
                height: 12,
                width: 12,
                borderRadius: 12,
                background: "#FE5952",
                cursor: "default",
              }}
              title="Leave"
            >
              <FontAwesomeIcon
                icon={faTimes}
                color="#515151"
                style={{ fontSize: 6 }}
              />
            </div>

            <div
              onClick={minimiseVideo}
              className="d-flex justify-content-center align-items-center ml-2 p-2 text-center pointer"
              style={{
                height: 12,
                width: 12,
                borderRadius: 12,
                background: "#F5CE49",
                cursor: "default",
              }}
              title="Minimise"
            >
              <FontAwesomeIcon
                icon={faMinus}
                color="#515151"
                style={{ fontSize: 6 }}
              />
            </div>

            <div
              onClick={maximiseVideo}
              className="d-flex justify-content-center align-items-center mx-2 text-center p-2 pointer"
              style={{
                height: 12,
                width: 12,
                borderRadius: 12,
                background: "#66CA55",
                cursor: "default",
              }}
              title="Maximise"
            >
              <FontAwesomeIcon
                icon={faPlus}
                color="#515151"
                style={{ fontSize: 6 }}
              />
            </div>
          </div>

          <div style={{ cursor: "grab" }} className="d-flex flex-grow-1 mt-1">
            <small className="mx-auto text-theme">{channelId.roomName}</small>
          </div>
          <div
            style={{ cursor: "grab" }}
            className="d-flex justify-content-center align-items-center"
          >
            <div
              className="d-flex justify-content-center align-items-center mr-4 text-theme-lighter pointer"
              title="Toggle video quality"
              onClick={() => {
                if (tempAPI) {
                  toggleVideoQuality();
                }
              }}
            >
              {console.log(isLD, channelDetails, "my low def")}
              {channelDetails.videoMeetingType == 0 ? (
                <>{isLD ? <span>SD</span> : <span>LD</span>}</>
              ) : null}
            </div>
            <div
              onClick={() => {
                toggleFullScreen();
                maximiseVideo();
              }}
              className="d-flex fullscreen justify-content-center align-items-center mr-4 text-center pointer text-theme-lighter"
              title="Fullscreen"
            >
              <FontAwesomeIcon icon={faExpand} />
            </div>
            {/* Uncomment to enable Room Messages */}
            <div
              className="d-flex justify-content-center align-items-center mr-4 text-theme-lighter pointer"
              title="Open/Close chat"
            >
              <FontAwesomeIcon
                onClick={() => {
                  toggleVideoRightPanel("chat");
                }}
                icon={faComment}
              />
            </div>
            {conferenceSession ? (
              isAdmin ? (
                <div
                  className="d-flex justify-content-center align-items-center mr-4 text-theme-lighter pointer"
                  title="Publishers list"
                >
                  <FontAwesomeIcon
                    onClick={() => {
                      toggleVideoRightPanel("publishersList");
                    }}
                    icon={faClipboardList}
                  />
                </div>
              ) : null
            ) : null}
            {channelDetails.eventId ? (
              <div
                className="d-flex justify-content-center align-items-center mr-4 text-theme-lighter pointer"
                title="Ask questions"
              >
                <FontAwesomeIcon
                  onClick={() => {
                    toggleVideoRightPanel("question");
                  }}
                  icon={faQuestionCircle}
                />
              </div>
            ) : null}
            {/* use faTh for apps  */}
          </div>
        </Card.Header>

        <Card.Body style={containerStyle} className="m-0 p-0">
          <div
            className="d-flex"
            style={{ display: "flex", transition: "all 0.5s", height: "100%" }}
          >
            <div
              className="flex-grow-1"
              style={{ flexGrow: 1, transition: "all 0.5s" }}
            >
              {avpreferencesnotset && AVPreference()}
              {!avpreferencesnotset && loading && Loader()}
              {VideoMeeting()}
              {/* {console.log(channelId.roomId)} */}
            </div>

            {videoRightPanelVisible ? (
              <div
                className="flex-grow-1"
                style={{
                  maxWidth: "30%",
                  minWidth: "200px",
                  transition: "all 0.5s",
                  overflow: "hidden",
                }}
              >
                <div className="d-flex flex-column h-100 flex-grow-1">
                  {/* Apps - Enable when raised hands is separated and implemented */}
                  {/* <div className="py-2 px-2 shadow-sm border-bottom-1  bg-theme-lighter">
                  <div className="d-flex px-3">
                    <div
                      className="d-flex mr-4 text-center pointer text-theme-lighter" >
                      <FontAwesomeIcon onClick={() => { setVideoMessages(true); setRaisedHands(false) }} icon={faComment} />
                    </div>
                    <div className="d-flex mr-4 text-theme-lighter pointer">
                      <FontAwesomeIcon onClick={() => { setRaisedHands(true); setVideoMessages(false) }} icon={faHandPaper} />
                    </div>
                  </div>
                </div> */}

                  {videoMessages ? (
                    <>
                      <div
                        className="flex-grow-1 px-3 pt-2 border-bottom-1 h-100"
                        style={{ overflow: "hidden" }}
                      >
                        <div style={{ height: "100%" }}>
                          <div className="pb-3" style={{ height: "100%" }}>
                            <VideoChat />
                          </div>
                        </div>
                      </div>
                      <div className="message__form">
                        <ActionBox />
                      </div>
                    </>
                  ) : null}
                  {videoQnA ? (
                    <>
                      <div
                        className="flex-grow-1 px-3 pt-2 border-bottom-1 h-100"
                        style={{ overflow: "hidden" }}
                      >
                        <div style={{ height: "100%" }}>
                          <div className="pb-3" style={{ height: "100%" }}>
                            <VideoQnA />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {publishersList ? (
                    <>
                      <div
                        className="flex-grow-1 border-bottom-1 h-100"
                        style={{ overflow: "hidden" }}
                      >
                        <div style={{ height: "100%" }}>
                          <div className="pb-3" style={{ height: "100%" }}>
                            <PublishersList />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {raisedHands ? (
                    <>
                      <div className="flex-grow-1">
                        <h5 className="px-3 pt-2 font-weight-bold">
                          Raised Hands
                        </h5>
                        <ul className="list-group small">
                          <li className="list-group-item d-flex justify-content-between align-items-center border-0 bg-transparent px-3 py-1">
                            John Doe
                            <span className="">
                              <FontAwesomeIcon
                                className="text-primary pointer"
                                icon={faHandPaper}
                              />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </Card.Body>

        {speaking !== "" ? (
          <Card.Footer className="px-4 m-0 d-flex text-theme d-flex justify-content-between">
            <p className="text-theme-lighter small mb-0">
              Current/ Last Speaker{" "}
              <span className="font-weight-bold text-theme">{speaking}</span>
            </p>
            {/* <p className="text-theme-lighter small mb-0">{tileView? <span>Tile View</span> : <span>Not Tile View</span>}</p> */}
          </Card.Footer>
        ) : null}
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  participants: state.channel.participants,
  currentUser: state.user.currentUser,
  currentSubscription: state.subscription.currentSubscription,
  channelDetails: state.platform.channelDetails,
  isPublisher: state.platform.isPublisher,
  conferenceSession: state.platform.conferenceSession,
  videoDetails: state.platform.videoDetails,
  channelName: state.platform.channelDetails.roomName,
  userName: state.user.currentUser.displayName,
  userAvatar: state.user.currentUser.photoURL,
  channelId: state.platform.channelDetails,
});

export default connect(mapStateToProps, {
  setIsVideoVisible,
  setChannelDetails,
  setConferenceSession,
  setVideoDetails,
})(Video);
