import React, { useState, useEffect } from "react";
import { Button, Form, ButtonGroup, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import moment from "moment"
import firebase from "firebase"

const CreateStars = ({ n, setValue }) => {
    let group = []
    for (let i = n; i > 0; i--) {
        group.push(
            <Button id={i} variant="transparent" value={i} onClick={() => setValue(i)} className="feedbackstar"><FontAwesomeIcon icon={faStar} /></Button>
        )
    }
    return group
}

const timeFromNow = timestamp => {
    if (moment(Date.now()).diff(moment(timestamp), 'days') < 1) return moment(timestamp).fromNow()
    else if (moment(Date.now()).diff(moment(timestamp), 'days') < 2) return "yesterday"
    else if (moment(Date.now()).diff(moment(timestamp), 'days') < 7) return moment(timestamp).format('dddd')
    else return moment(timestamp).format("MMM DD YYYY")
};

const ResultStars = ({ n, value }) => {
    let group = []
    for (let j = value; j > 0; j--) {
        group.push(
            <FontAwesomeIcon icon={faStar} color="#f2b600" />
        )
    }
    for (let i = n; i > value; i--) {
        group.push(
            <FontAwesomeIcon icon={faStar} />
        )
    }
    return group
}

const getResult = (response, user) => {
    return response && Object.keys(response).includes(user)
}

const getValue = (response, user) => {
    return response &&
        <div>
            <ResultStars n={5} value={response[user] && response[user].value} />
            <p className="mb-0 mt-2 small">Replied <i>{timeFromNow(response[user] && response[user].responseTime)}</i></p>
        </div>
}

const Feedback = ({ feedbacktitle, uid, taskResponse, item, user }) => {
    const [value, setValue] = useState(0)
    const [admin, setAdmin] = useState(false)
    const [responses, setResponses] = useState([])

    useEffect(() => {
        if (user == item.user.id) {
            setAdmin(true)
            getResponses(item.response && item.response)
        }
    }, [])

    const getResponses = (responses) => {
        responses && Object.entries(responses).forEach(([key, value]) => {
            setResponses(prev => [...prev, { uid: key, ...value }])
        })
    }

    const getName = (key) => {
        let name = "";
        firebase.database().ref("users").child(key).on("value", snap => {
            if (snap.val()) {
                name = snap.val().name ? snap.val().name : `${snap.val().firstName} ${snap.val().lastName}`;
            }
        });
        return name;
    }

    const displayResponse = (response) => {
        return (
            <div className="mb-3">
                <h5 className="mt-0">{getName(response.uid)}</h5>
                <ResultStars n={5} value={response.value} />
                <p className="mb-0 mt-2 small">Replied <i>{timeFromNow(response.responseTime)}</i></p>
            </div>
        )
    }

    return (
        <>
            <div className="taskBox">
                <Form className="feedback">
                    <h5 className="mt-0">{feedbacktitle}</h5>
                    {admin
                        ? responses.length
                            ? <Badge variant="secondary">Responses</Badge> : <p className="mt-4 mb-1">No responses yet</p>
                        : null
                    }
                    {admin
                        ? responses.map(response => {
                            return displayResponse(response)
                        })
                        : item && getResult(item.response, user)
                            ? <div>{getValue(item.response, user)}</div>
                            : <>
                                <div className="mb-3 feedbackdirection">
                                    <ButtonGroup aria-label="Feedback" >
                                        <CreateStars n={5} uid={uid} setValue={setValue} />
                                    </ButtonGroup>
                                </div>
                                <Button variant="primary" size="sm" onClick={() => taskResponse(uid, value)} >Submit</Button>
                            </>
                    }
                </Form>
            </div>
        </>
    )
};

export default Feedback;
