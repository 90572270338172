import * as actionTypes from '../actions/types'

const initialState = {
    currentWorkspace: null,
    workspaces: {},
    loadingWorkspaces: true,
    roomsVisible: actionTypes.ACTIVE
}

const workspace_reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CURRENT_WORKSPACE:
            return {
                ...state,
                currentWorkspace: action.payload.currentWorkspace
            };
        case actionTypes.SET_WORKSPACES:
            return {
                ...state,
                workspaces: action.payload.workspaces,
                loadingWorkspaces: false
            };
        case actionTypes.SET_ROOMS_VISIBLE:
            return {
                ...state,
                roomsVisible: action.payload.roomsVisible
            }
        default:
            return state;
    }
}

export default workspace_reducer