import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Form, FormCheck } from "react-bootstrap";
import firebase from "firebase";
import RoomListElement from "../Left/RoomListElement";
import { connect } from "react-redux";
import {
  setChannels,
  setCurrentChannel,
  setCurrentWorkspace,
  setParticipants,
  resetData,
  setRightPanel,
  setTaskSharing,
  setContentTypeAndValue,
  setSharedTask,
} from "../../actions/index";
import { timeFromNow } from "../../utils/index";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbtack,
  faLock,
  faLockOpen,
  faPoll,
  faFileAlt,
  faImage,
  faFileWord,
  faFilePdf,
  faClipboard,
  faFilePowerpoint,
  faFileExcel,
  faCircle,
  faCalendarAlt,
  faTasks,
  faFileAudio
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import _, { isLength } from "lodash";
import axios from 'axios';
import { API_BASE_URL, ROOM_ADMIN_CONFIG } from "../../config";

const pdf_type = ["application/pdf"];

const word_type = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const excel_type = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const powerpoint_type = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

const FileIcon = ({ message }) => {
  if (word_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFileWord} className="text-theme" />;
  if (pdf_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFilePdf} className="text-theme" />;
  if (powerpoint_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFilePowerpoint} className="text-theme" />;
  if (excel_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFileExcel} className="text-theme" />;
  return <div></div>;
};

export const RoomItem = (props) => {
  const [roomLiveStatus, setRoomLiveStatus] = useState(false);
  // const [roomData, setRoomData] = useState({});

  const compareTimestampLodashLatestFirst = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) => {
      if (channel.lastMessage) {
        return channel.lastMessage.timestamp;
      } else {
        return 1000000000000;
      }
    });

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted.reverse());
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareTimestampLodashOldestFirst = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) => {
      if (channel.lastMessage) {
        return channel.lastMessage.timestamp;
      } else {
        return 1000000000000;
      }
    });

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted);
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareAlphabetLodashAtoZ = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) =>
      channel.roomName.toLowerCase()
    );
    // console.log('atoz')

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted);
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareAlphabetLodashZtoA = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) =>
      channel.roomName.toLowerCase()
    );
    // console.log('ztoa')

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted.reverse());
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const switchSort = (allChannels, sortType) => {
    // console.log(allChannels,"allChannels")
    switch (sortType) {
      case 0:
        return compareTimestampLodashLatestFirst(allChannels);

      case 1:
        return compareAlphabetLodashAtoZ(allChannels);

      case 2:
        return compareAlphabetLodashZtoA(allChannels);

      case 3:
        return compareTimestampLodashOldestFirst(allChannels);

      default:
        return compareTimestampLodashLatestFirst(allChannels);
    }
  };

  const getRoomData = () => {
    const {
      roomData,
      workspace,
      allChannels,
      setChannels,
      index,
      isLoading,
    } = props;

    const { workspaceId } = workspace;
    const workspaceIdLowerCase = workspaceId.toLowerCase();

    const { roomId } = roomData;
    const roomIdLowerCase = roomId.toLowerCase();

    const now = new Date().valueOf();

    isLoading(true);

    firebase
      .firestore()
      .collection(`workspaces/${workspaceId}/rooms/${roomId}/messages`)
      .onSnapshot(
        {
          includeMetadataChanges: true,
        },
        function (doc) {
          doc.docChanges().forEach((change) => {
            if (change.type === "added") {
              if (change.doc.data().timestamp >= now) {
                console.log("message added ", change.doc.data());
                let prevAllChannels = allChannels;
                firebase
                  .firestore()
                  .collection(`workspaces/${workspaceId}/rooms/`)
                  .doc(`${roomId}`)
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      console.log("updated room data", {
                        ...doc.data(),
                        roomId: doc.id,
                      });
                      prevAllChannels.splice(index, 1, {
                        ...doc.data(),
                        roomId: doc.id,
                        lastMessage: change.doc.data(),
                      });
                      console.log(prevAllChannels, "prevallchannels");
                      props.isLoading(false);
                      switchSort(prevAllChannels);
                    }
                  });
              }
            }
            if (change.type === "modified") {
              console.log("message modified: ", change.doc.data());
              let prevAllChannels = allChannels;
              firebase
                .firestore()
                .collection(`workspaces/${workspaceId}/rooms/`)
                .doc(`${roomId}`)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    console.log("updated room data", {
                      ...doc.data(),
                      roomId: doc.id,
                    });
                    prevAllChannels.splice(index, 1, {
                      ...doc.data(),
                      roomId: doc.id,
                    });
                    // console.log(prevAllChannels,"prevallchannels",prevSortType,"prevsorttype", props.sortType,"currentsorttype")
                    props.isLoading(false);
                    switchSort(prevAllChannels, props.sortType);
                  }
                });
            }
            if (change.type === "removed") {
              console.log("message removed: ", change.doc.data());
            }
          });
        }
      );
  };

  useEffect(() => {
    getRoomData();
    props.isLoading(false);
  }, []);

  useEffect(() => {
    getRoomData();
    props.isLoading(false);
  }, [props.sortType]);

  const strip = (value) => {
    const { user, content } = value;
    let name = user.name;
    let firstName = name.trim().split(" ")[0];

    if (value.type === 0) {
      if (firstName.length + content.length > 32) {
        let completeContent = `${firstName}: ${content}`;
        return `${completeContent.slice(0, 32)}...`;
      } else {
        return `${firstName}: ${content}`;
      }
    } else if (value.type === 1) {
      return (
        <span>
          {firstName}:{" "}
          <FontAwesomeIcon icon={faCalendarAlt} className="text-theme" />{" "}
          Schedule
        </span>
      );
    } else if (value.type === 2) {
      return (
        <span>
          {firstName}:{" "}
          <FontAwesomeIcon icon={faClipboard} className="text-theme" />
          Files
        </span>
      );
    } else if (value.type === 3) {
      return (
        <span>
          {firstName}: <FontAwesomeIcon icon={faPoll} className="text-theme" />{" "}
          Poll
        </span>
      );
    } else if (value.type === 4) {
      return (
        <span>
          {firstName}: <FontAwesomeIcon icon={faTasks} className="text-theme" />{" "}
          Quiz
        </span>
      );
    } else if (value.type === 5) {
      return (
        <span>
          {firstName}:{" "}
          <FontAwesomeIcon icon={faFileAlt} className="text-theme" /> Assignment
        </span>
      );
    }  else if (value.type === 6) {
      if (
        (content && firstName.length + content.length > 32) ||
        (firstName.length > 32 && !value.isDeleted)
      ) {
        let len = 32 - firstName.length;

        return (
          <span>
            {firstName}: <FontAwesomeIcon icon={faImage} />{" "}
            {content.length > len ? content.slice(0, len) + "…" : content}
          </span>
        );
      } else if (!value.isDeleted) {
        return (
          <span>
            {firstName}: <FontAwesomeIcon icon={faImage} /> Photo
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 7) {
      if (
        (content && firstName.length + content.length > 32) ||
        firstName.length > 32
      ) {
        let len = 32 - firstName.length;

        return (
          <span>
            {firstName}: <FileIcon message={value} />{" "}
            {content.length > len ? content.slice(0, len) + "…" : content}
          </span>
        );
      } else {
        return (
          <span>
            {firstName}: <FileIcon message={value} />{" "}
            {value.metaData && value.metaData.name}
          </span>
        );
      }
    } else if (value.type === 8) {
      return (
        <span>
          {content.length > 36 ? `${content.slice(0, 36)}...` : content}
        </span>
      );
    } else if (value.type === 9) {
      return (
        <span>
          {content.length > 36 ? `${content.slice(0, 36)}...` : content}
        </span>
      );
    } else if (value.type === 10) {
      if (!value.isDeleted) {
        return (
          <span>
            {firstName}:{" "}
            <FontAwesomeIcon icon={faTasks} className="text-theme" /> Special
            olympic
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 11) {
      if (!value.isDeleted) {
        return (
          <span>
            {firstName}:{" "}
            <FontAwesomeIcon icon={faFileAudio} className="text-theme" /> Audio
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 12) {
      if (
        value.content &&
        firstName.length + content.length > 32 &&
        !value.isDeleted
      ) {
        let completeContent = `${firstName}: ${content}`;
        return `${completeContent.slice(0, 32)}...`;
      } else if (!value.isDeleted && value.content) {
        return `${firstName}: ${content}`;
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    }
    else {
      return value.type;
    }
  };

  const {
    key,
    currentChannel,
    workspaceData,
    workspace,

    setCurrentChannel,
    setCurrentWorkspace,
    setParticipants,
    setRightPanel,
    setTaskSharing,
    setContentTypeAndValue,
    messageDataObj,
    messageData,
    setSharedTask,
    index,
    checkboxclicked,
    selectedRooms,
  } = props;
  const roomData = props.roomData;
  const { roomId } = roomData;

  return (
    <div
      className="d-flex align-items-center"
      style={{ cursor: "pointer" }}
      onClick={() => checkboxclicked(roomId)}
    >
      <Form.Check
        type="checkbox"
        htmlFor={roomData.roomName + index}
        style={{ cursor: "pointer" }}
      >
        <FormCheck.Input
          style={{ cursor: "pointer" }}
          checked={selectedRooms.includes(roomId) ? true : false}
        />
      </Form.Check>
      <li
        className={
          currentChannel && roomData.roomId === currentChannel.roomId
            ? "active rounded-lg py-1 m-1 bg-card-theme shadow-sm text-theme w-100"
            : "rounded-lg py-1 m-1 bg-card-theme shadow-sm text-theme w-100"
        }
        key={key}
        id={roomData.roomName + index}
        // onClick={() => checkboxclicked(roomId)}

        name={roomData.roomName}
        active={currentChannel && roomData.roomId === currentChannel.roomId}
      >
        <div className="d-flex align-items-center p-2 w-100">
          <div className={roomLiveStatus ? "liveroom" : ""}>
            <img
              className={
                roomLiveStatus
                  ? "mr-2 rounded-circle profile-image"
                  : "mr-2 rounded-circle"
              }
              src={roomData.roomPic}
              style={{ height: 45, width: 45 }}
            />
          </div>
          <div className="flex-grow-1">
            <div className="d-flex align-items-center">
              {roomData.roomType === 1 && (
                <FontAwesomeIcon
                  icon={faLock}
                  className="text-success mr-2"
                  size="xs"
                />
              )}
              <p className="mb-0 text-theme">{roomData.roomName}</p>
            </div>
            {roomData.lastMessage && (
              <small className="text-theme text-theme-lighter">
                <span>{strip(roomData.lastMessage)}</span>
              </small>
            )}
          </div>
          <div className="text-right align-self-start">
            {/* <FontAwesomeIcon
                            icon={faThumbtack}
                            style={isPinned ? { fontSize: 12, transform: "rotate(45deg)" } : { fontSize: 12 }}
                            className={isPinned ? "text-theme" : "text-secondary"} /> */}
            <p
              className="mb-0 text-theme small text-theme-lighter"
              style={{ whiteSpace: "nowrap" }}
            >
              {roomData.lastMessage &&
                timeFromNow(roomData.lastMessage.timestamp)}
            </p>
            {/* Messages Notification */}
            {/* <span className="text-white bg-primary smaller font-weight-bold" style={{ whiteSpace: "nowrap", borderRadius: "2px", padding: "3px 3px 3px 3px" }}>
                            99+</span> */}
          </div>
        </div>
      </li>
    </div>
  );
};

const RoomListForModalForMessages = (props) => {
  const [workspaceData, setWorkspaceData] = useState({});
  const [loadingWorkspaceData, setLoadingWorkspaceData] = useState(true);
  const [roomsDataArray, setRoomsDataArray] = useState([]);
  const [defaultSortDone, setDefaultSortDone] = useState(false);
  const [forwardableChannelsState, setForwardableChannelsState] = useState([]);

  const [selectedRooms, setSelectedRooms] = useState([]);

  const roomSelectHandler = (id) => {
    let oldselectedRooms = selectedRooms;
    if (oldselectedRooms.includes(id)) {
      const index = oldselectedRooms.indexOf(id);
      oldselectedRooms.splice(index, 1);
      oldselectedRooms = _.cloneDeep(oldselectedRooms);
      setSelectedRooms(oldselectedRooms);
    } else {
      oldselectedRooms.push(id);
      oldselectedRooms = _.cloneDeep(oldselectedRooms);
      setSelectedRooms(oldselectedRooms);
    }
  };

  useEffect(() => {
    if (selectedRooms.length > 0) {
      props.oneSelected(true);
    } else {
      props.oneSelected(false);
    }
  }, [selectedRooms]);

  useEffect(() => {
    props.selectedChannels(selectedRooms);
  }, [selectedRooms]);

  useEffect(() => {
    // will unmount
    return setSelectedRooms([]);
  }, []);

  const getWorkspaceData = async () => {
    const { workspace } = props;

    let docRef = await firebase
      .firestore()
      .collection(`workspaces`)
      .doc(`${workspace.workspaceId}`);
    let workspace_data = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          // console.log("Document data workspace:", doc.data());
          const workspaceData = doc.data();
          return workspaceData;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });

    setWorkspaceData(workspace_data);
    setLoadingWorkspaceData(false);
  };

  const getAllRoomsData = () => {
    const { workspace, roomsVisible, setChannels } = props;
    let roomsArray = [];
    let roomsDataPromises = [];
    let roomsDataArray = [];

    getWorkspaceData();

    roomsArray =
      workspace[roomsVisible] &&
      Object.values(workspace[roomsVisible]).map((room, key) => room.roomId);

    // console.log(`roomsArray ${JSON.stringify(roomsArray)}`)

    roomsDataPromises = roomsArray.map((roomId, key) =>
      firebase
        .firestore()
        .collection(`workspaces/${workspace.workspaceId}/rooms`)
        .doc(`${roomId}`)
        .get()
    );

    Promise.all(roomsDataPromises).then((values) => {
      roomsDataArray = values.map((value) => {
        return { ...value.data(), roomId: value.id };
      });
      switchSort(roomsDataArray, props.sortType);
      // setChannels(roomsDataArray)
    });
  };

  const {
    workspace,
    _handleAddRoom,
    _handleOpenWorkspaceDetails,
    roomsVisible,
    currentChannel,
    allChannels,
    searchTerm,
    sortType,
    currentUser,
    currentWorkspace,
    isLoading,
  } = props;


  const getAdminChannels = (allChannels) => {
    const ids = allChannels.map(async (channel) => {
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${channel.roomId}/participants`
        );
      let res = (await query.get()).docChanges().map((s) => {
        let json = { ...s.doc.data(), id: s.doc.id };
        return json;
      });
      // console.log(res.find((usr) => usr.uid === uid && usr.IsAdmin));
      let coAdmins = res.filter((participant) => participant.isAdmin);
      // console.log(coAdmins);

      if (channel.createdBy === currentUser.uid || coAdmins.find((usr) => usr.id === currentUser.uid)) {
        //console.log("hmmm");
        // this.setState({ admin: true });
        return (channel);
      }
    })
    return (handlePromiseAllAdminChannels(ids));
  }

  const handlePromiseAllAdminChannels = async (ids) => {
    let adminChannels = [];
    await Promise.all(ids)
      .then((doc) => {
        if (doc) {
          adminChannels.push(doc);
        }
      })
    return (adminChannels);
  }


  getAdminChannels(allChannels)
    .then(doc => {
      const adminChannels = doc[0].filter((channel) => channel !== undefined);
      // console.log(adminChannels);
      const nonAdminChannels = allChannels.filter((channel) => {
        return !adminChannels.some((adminCh) => {
          return adminCh.roomId === channel.roomId
        })
      })

      const nonAdminNoAdminChatChannels = nonAdminChannels.map(async(nonAdminChannel) => {
        // getting roomConfig of all the non adminchannels and if it has adminOnlyChat to false
        // then returning it

        const channelWithoutAdminChat =  await axios.post(`${API_BASE_URL}${ROOM_ADMIN_CONFIG}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: nonAdminChannel.roomId
        }).then((response) => {
          if(!response.data.data.admin_only_chat) {
            return nonAdminChannel;
          }
        }).catch((error) => {
          console.log(error, "Error")
        })
        return (channelWithoutAdminChat);
      })
      return ({nonAdminNoAdminChatChannels, adminChannels});

    }).then(async ({nonAdminNoAdminChatChannels, adminChannels}) => {
      let forwardableChannels = [];
      await Promise.all(nonAdminNoAdminChatChannels)
      .then((doc) => {
        if(doc) {
          forwardableChannels.push(doc);
        }
      })
      forwardableChannels = forwardableChannels[0].filter((channel) => channel !== undefined);
      forwardableChannels = [...forwardableChannels, ...adminChannels];
      // sorting it 
      setForwardableChannelsState(lastestFirstSort(forwardableChannels));
    })


  const lastestFirstSort = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) => {
        if(channel.lastMessage){
            return channel.lastMessage.timestamp
        }else{
            return (1000000000000)
        }
    })

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted.reverse())
    return(sortedClonedDeep)
}




  const reg = _.escapeRegExp(searchTerm);
  // console.log(reg,"Reg")
  const regex = new RegExp(reg, "gi");

  useEffect(() => {
    getAllRoomsData();
  }, []);

  useEffect(() => {
    getAllRoomsData();
  }, [props.roomsVisible, props.workspace[props.roomsVisible]]);

  useEffect(() => {
    getWorkspaceData();
  }, [props.workspace]);

  // useEffect(() => {
  //   console.log("sorttype changed");
  //   switchSort(allChannels, sortType);
  // }, [sortType]);

  // useEffect(() => {
  //   // for default sort (when it loads for first time)
  //   if (
  //     allChannels &&
  //     allChannels.length &&
  //     allChannels.length > 0 &&
  //     !defaultSortDone
  //   ) {
  //     console.log("default sort");
  //     switchSort(allChannels, 0);
  //     setDefaultSortDone(true);
  //   }
  // }, [allChannels]);

  // useEffect(() => {
  //     console.log(allChannels, "allchannels updated in worklistelements")
  // },[allChannels])

  const compareTimestampLodashLatestFirst = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) => {
      if (channel.lastMessage) {
        return channel.lastMessage.timestamp;
      } else {
        return 1000000000000;
      }
    });

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted.reverse());
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareTimestampLodashOldestFirst = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) => {
      if (channel.lastMessage) {
        return channel.lastMessage.timestamp;
      } else {
        return 1000000000000;
      }
    });

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted);
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareAlphabetLodashAtoZ = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) =>
      channel.roomName.toLowerCase()
    );
    // console.log('atoz')

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted);
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareAlphabetLodashZtoA = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) =>
      channel.roomName.toLowerCase()
    );
    // console.log('ztoa')

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted.reverse());
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const switchSort = (allChannels, sortType) => {
    // console.log(allChannels,"allChannels")
    switch (sortType) {
      case 0:
        return compareTimestampLodashLatestFirst(allChannels);

      case 1:
        return compareAlphabetLodashAtoZ(allChannels);

      case 2:
        return compareAlphabetLodashZtoA(allChannels);

      case 3:
        return compareTimestampLodashOldestFirst(allChannels);

      default:
        return compareTimestampLodashLatestFirst(allChannels);
    }
  };

  // console.log(allChannels,"before return")

  return (
    <>
      {searchTerm && searchTerm.length > 0
        ? forwardableChannelsState &&
          forwardableChannelsState
            .filter((item) => {
              return (
                item.roomName.match(regex) ||
                (item.lastMessage &&
                  item.lastMessage.content &&
                  item.lastMessage.content.match(regex))
              );
            })
            .map((room, key) => {
              return (
                <RoomItem
                  roomData={room}
                  key={key}
                  index={key}
                  currentChannel={currentChannel}
                  workspace={workspace}
                  workspaceData={workspaceData}
                  allChannels={allChannels}
                  setChannels={setChannels}
                  sortType={sortType}
                  isLoading={isLoading}
                  checkboxclicked={roomSelectHandler}
                  selectedRooms={selectedRooms}
                  {...props}
                />
              );
            })
        : forwardableChannelsState &&
        forwardableChannelsState.map((room, key) => {
            return (
              <RoomItem
                roomData={room}
                key={key}
                index={key}
                currentChannel={currentChannel}
                workspace={workspace}
                workspaceData={workspaceData}
                allChannels={allChannels}
                setChannels={setChannels}
                sortType={sortType}
                isLoading={isLoading}
                checkboxclicked={roomSelectHandler}
                selectedRooms={selectedRooms}
                {...props}
              />
            );
          })}
    </>
  );
};

const mapStateToProps = (state) => ({
  roomsVisible: state.workspace.roomsVisible,
  currentChannel: state.channel.currentChannel,
  allChannels: state.channel.allChannels,
  platform: state.channel.platform,
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,
});

export default connect(mapStateToProps, {
  setChannels,
  setCurrentChannel,
  setCurrentWorkspace,
  setParticipants,
  resetData,
  setRightPanel,
  setTaskSharing,
  setContentTypeAndValue,
  setSharedTask,
})(RoomListForModalForMessages);
