import React, { useState, useEffect } from "react";
import {
  Image,
  Button,
  Alert,
  Badge,
  Form,
  ButtonGroup,
  ButtonToolbar,
  ProgressBar,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faInfoCircle,
  faArrowLeft,
  faBookOpen,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import firebase from "firebase";
import { connect } from "react-redux";
import UserResponse from "../../Right/UserNameAvatar";
import { API_BASE_URL, SCHEDULE_SUBMIT } from "../../../config/index";
import axios from "axios";
import { toast } from "react-toastify";
import Participants from "../../Right/UserNameAvatar";
import { getTimeDifference } from "../Common/TimeDifference";
import AssignedUsers from "../Common/AssignedUsers";
toast.configure();
// function PollView({ item, user, uid, setIsTaskResponded, option, contentId, currentUser }) {
//     const [resultView, setResultView] = useState(false);
//     const [value, setValue] = useState('');
//     const [taskView, setTaskView] = useState(true);
//     const [users, setUsers] = useState([]);
//     let displayData = [];

//     const showTask = () => setTaskView(true);
//     const closeTask = () => setTaskView(false);

//     const showResult = () => setResultView(true);
//     const closeResult = () => setResultView(false);

//     const showAnswer = (value) => {
//         return value &&
//             <div>
//                 <Badge variant="secondary">Your answer</Badge>
//                 <p className="mt-0 mb-0">{value}</p>
//             </div>
//     }

//     const [activeIndex, setActiveIndex] = useState(0);

//     const taskResponse = (option) => {
//         alert('task')

//         // const { contentId, currentUser } = props;

//         let responseData = {
//             userId: currentUser.uid,
//             responseTime: Date.now(),
//             optionValue: option
//         }

//         // console.log("POLL RESPONSE: ", responseData)

//         firebase
//             .firestore()
//             .collection("schedule")
//             .doc(contentId)
//             .update({
//                 response: firebase.firestore.FieldValue.arrayUnion(responseData)
//             })
//     }

//     return (
//         taskView
//             ?
//             <>
//                 <ButtonToolbar aria-label="Toolbar with button groups">
//                     {item && item["options"].map((option, key) => (
//                         <Button
//                         disabled={item.deadline.toDate() < Date.now()}
//                             className="w-100 mb-2"
//                             variant="outline-primary"
//                             size="sm"
//                             value={option}
//                             onClick={() => {
//                                 setValue(option);
//                                 taskResponse(uid, option);
//                                 closeTask();
//                                 showResult();
//                                 showAnswer(value);
//                                 setIsTaskResponded(true);
//                             }}
//                             size="sm">{option}</Button>
//                     ))}
//                 </ButtonToolbar>
//                 {/* <Button variant="primary" size="sm" onClick={() => { taskResponse(uid, value); closeTask(); showResult(); showAnswer(value) }}>Submit</Button> */}

//             </>
//             : resultView
//                 ?
//                 <div>
//                     <Badge variant="secondary">Your answer</Badge>
//                     <br />
//                     <p className="mt-0 mb-0">{value}</p>
//                     {/* <p className="mb-0 mt-0 small">Responded <i>{moment(response[user] && response[user].responseTime).format("hh:mm A DD/MM/YYYY")}</i></p> */}
//                 </div>
//                 : null
//     );
// }

function PollUser(props) {
  const { pollData, taskResponse, currentUser } = props;

  const [responses, setResponses] = useState([]);
  const [response, setResponse] = useState({});
  const [alreadyResponded, setAlreadyResponsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isClosed, setIsClosed] = useState(false);


  useEffect(() => {
    // console.log("pollData.response", pollData.response)
    if (pollData.response) {
      setResponses(pollData.response);

      let myResponse = pollData.response.filter(
        (response) => response.userId === currentUser.uid
      );
      if (myResponse.length === 1) {
        setResponse(myResponse[0]);
        setAlreadyResponsed(true);
        setLoading(false);
      }
    }

    if (pollData.previousDeadline || pollData.deadline < Date.now().valueOf()) {
      setIsClosed(true);
      //props.setIsClosed(true);
    }


  }, [pollData]);

  return (
    <div className="d-flex">
      {/* <p>{JSON.stringify(response)}</p> */}
      {alreadyResponded ? (
        <div className="w-100">
          {!loading && foo(pollData, responses)}
          <Badge variant="secondary">Your answer</Badge>
          <p className="mt-0 mb-0">{pollData.options[response.optionValue]}</p>
          <p className="mt-0 mb-0 small">
            {timeFromNow(response.responseTime)}
          </p>
        </div>
      ) : (
        <ButtonToolbar
          aria-label="Toolbar with button groups"
          className="w-100"
        >
          {pollData.options &&
            pollData.options.map((option, key) => (
              <div className="col-12">
                <Button
                  disabled={pollData.deadline < Date.now()}
                  className="w-100 mb-2"
                  variant="outline-primary"
                  size="sm"
                  value={option}
                  onClick={() => taskResponse(option, key)}
                  // onClick={() => {
                  //     setValue(option);
                  //     taskResponse(uid, option);
                  //     closeTask();
                  //     showResult();
                  //     showAnswer(value);
                  //     setIsTaskResponded(true);
                  // }}
                  size="sm"
                >
                  {option}
                </Button>
              </div>
            ))}
        </ButtonToolbar>
      )}
    </div>
  );
}

function PollAdmin(props) {
  const { pollData } = props;

  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    // console.log("pollData.response", pollData.response)
    if (pollData.response) {
      let participantsWithName = [];
      pollData.response.forEach((response, index) => {
        firebase.firestore().collection("users").doc(`${response.userId}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              participantsWithName.push({ displayName: doc.data().displayName, userId: response.userId, ...response });
            } else {
            }
          }).then(() => {
            setResponses(participantsWithName);
          })
      })

      // setResponses(pollData.response);
      setLoading(false);
    }

    if(pollData.previousDeadline || pollData.deadline < Date.now().valueOf()) {
      setIsClosed(true);
      //props.setIsClosed(true);
    }


  }, [pollData]);

  return (
    <div>
      {!loading && foo(pollData, responses)}

      <div className="mt-2">
        {!loading &&
          (responses.length > 0 ? (
            <div className="d-flex justify-content-center">
              <Badge variant="secondary"> {responses.length} Response(s)</Badge>
              <small
                onClick={() => setShow((prev) => !prev)}
                className="ml-auto text-primary font-weight-bold"
                style={{ cursor: "pointer" }}
              >
                {show ? "Hide (-)" : "Show (+)"}
              </small>
            </div>
          ) : (
            <p className="my-1">No responses yet</p>
          ))}
      </div>

      {show
        ? responses.map((response) => (
            <UserResponse displayName={response.displayName} userId={response.userId} nameOnly={true}>
              <>
              {pollData.options[response.optionValue] ? 
                ( <p className="mt-0 mb-0">{pollData.options[response.optionValue]}</p>):
                (null)
              }
                <p className="mt-0 mb-0 small">
                  {timeFromNow(response.responseTime)}
                </p>
              </>
            </UserResponse>
          ))
        : null}
    </div>
  );
}

const timeFromNow = (timestamp) => {
  if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
    return moment(timestamp).fromNow();
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
    return "yesterday";
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
    return moment(timestamp).format("dddd");
  else return moment(timestamp).format("MMM DD YYYY");
};

const getResult = (response, user) => {
  console.log(response && Object.keys(response).includes(user), "getresult");
  return response && Object.keys(response).includes(user);
};

const getValue = (response, user) => {
  console.log(response, "response values");
  return (
    response && (
      <div>
        <Badge variant="secondary">Your answer</Badge>
        <p className="mt-0 mb-1">{response[user] && response[user].optionValue}</p>
        <p className="mb-0 mt-0 small">
          <i>{timeFromNow(response[user] && response[user].responseTime)}</i>
        </p>
      </div>
    )
  );
};

const toggleTask = (e) => {
  this.setState({
    isResultVisible: false,
    isTaskVisible: true,
  });
};

const toggleResult = (e) => {
  this.setState({
    isResultVisible: true,
    isTaskVisible: false,
  });
};

const foo = (item, arr) => {
  var a = [],
    b = [],
    prev = "";

  const arrValues = arr.map((item) => item.optionValue);
  arrValues.sort();

  for (var i = 0; i < arrValues.length; i++) {
    if (arrValues[i] !== prev) {
      a.push(arrValues[i]);
      b.push(1);
    } else {
      b[b.length - 1]++;
    }
    prev = arrValues[i];
  }
  var c = [];
  for (var i = 0; i < a.length; i++) {
    c[i] = (b[i] * 100) / arrValues.length;
  }

  return (
    <>
      {item &&
        item["options"].map((option, key) => (
          <div>
            <p className="mb-1">{option}</p>
            <ProgressBar
              style={{ height: "1rem" }}
              className="mb-1"
              animated
              now={a.indexOf(key) >= 0 ? c[a.indexOf(key)] : "0"}
              label={`${
                a.indexOf(key) >= 0 ? c[a.indexOf(key)].toFixed(2) : "0"
              } %`}
            />
          </div>
        ))}
    </>
  );
};

const Schedule = (props) => {
  const [item, setItem] = useState(null);
  const [unsubscribe, setUnsubscribe] = useState(null);
  useEffect(() => {
    if (props.contentId) {
      const scheduleData = firebase
        .firestore()
        .collection("schedule")
        .doc(props.contentId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            //console.log("mineData", doc.data());
            setItem(doc.data());
            setResponses(doc.data().response);
          }
        });
      setUnsubscribe(() => scheduleData)
    }
  }, []);

  useEffect(() => {
    return (() => {
      // @ https://stackoverflow.com/questions/55636020/react-hooks-trying-to-access-state-before-unmount-returns-initial-state
      // should update componentwillunmount when the unsub function is updated else it will have the initial value
      console.log("unmount once", unsubscribe)
      if (unsubscribe) {
        unsubscribe()
      }
    })
  }, [unsubscribe])

  const {
    scheduleTitle,
    uid,
    user,
    currentChannel,
    open_task_branch,
    open_task_id,
    colorTheme,
    darkTheme,
  } = props;
  const [assignedShow, setAssignedShow] = useState(false)
  const [value, setValue] = useState("");
  const [admin, setAdmin] = useState(false);
  const [responses, setResponses] = useState([]);
  const [isTaskResponded, setIsTaskResponded] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [show, setShow] = useState(false);
  const [pollData, setPollData] = useState({});
  useEffect(() => {
    if (item && user == item.createdBy) {
      setAdmin(true);
    }

    // firebase.database().ref("messages/"+currentChannel.id).child(item.uid).on("value", snap => {
    //     // snap.val() && getResponses(snap.val().response)
    //     snap.val() && setResponses(prev => [...prev, { uid: snap.key, ...snap.val() }])
    // });
    // getResponses(item.response && item.response)
  }, [item]);

  const getResponses = (responses) => {
    responses &&
      Object.entries(responses).forEach(([key, value]) => {
        setResponses((prev) => [...prev, { uid: key, ...value }]);
      });
  };

  const getName = (key) => {
    let name = "";
    // firebase.database().ref("users").child(key).on("value", snap => {
    //     if (snap.val()) {
    //         name = snap.val().name ? snap.val().name : `${snap.val().firstName} ${snap.val().lastName}`;
    //     }
    // });
    firebase
      .firestore()
      .collection("users")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          name = doc.data().firstName + " " + doc.data().lastName;
        }
      });
    return name;
  };

  let responselist = [];
  const displayStats = (response) => {
    responselist.push(response.value);
  };

  // const foo = (arr) => {
  //     var a = [], b = [], prev;
  //     arr.sort();
  //     for (var i = 0; i < arr.length; i++) {
  //         if (arr[i] !== prev) {
  //             a.push(arr[i]);
  //             b.push(1);
  //         } else {
  //             b[b.length - 1]++;
  //         }
  //         prev = arr[i];
  //     }
  //     var c = [];
  //     for (var i = 0; i < a.length; i++) {
  //         c[i] = b[i] * 100 / arr.length;
  //     }
  //     return (
  //         // c.map((pvalue, key) =>
  //         //     //{console.log('Value is ' + pvalue)},
  //         //     <ProgressBar style={{height: '1rem'}} className="mb-1" animated now={pvalue} label={`${pvalue} % (${a[key]})`} />
  //         // )
  //         item && item["options"].map(option => (<><p className="mb-1">{option}</p><ProgressBar style={{ height: '1rem' }} className="mb-1" animated now={a.indexOf(option) >= 0 ? c[a.indexOf(option)] : "0"} label={`${a.indexOf(option) >= 0 ? c[a.indexOf(option)] : "0"} %`} /></>))
  //     )
  // }

  const displayResponse = (response) => {
    return (
      <div className="mt-2">
        <p className="my-0 font-weight-bold">test</p>
        <p className="my-0">{response.value}</p>
        <p className="my-0 small">
          <i>{timeFromNow(response.responseTime)}</i>
        </p>
      </div>
    );
  };

  const taskResponse = async (option, key) => {
    const { contentId, currentUser, currentWorkspace, currentChannel } = props;

    let responseData = {
      userId: currentUser.uid,
      responseTime: Date.now(),
      value: key,
    };

    console.log("SCHEDULE RESPONSE: ", key, option, contentId);
    let response = await axios.post(`${API_BASE_URL}${SCHEDULE_SUBMIT}`, {
      user_id: currentUser.uid,
      room_id: currentChannel.roomId,
      workspace_id: currentWorkspace.workspaceId,
      schedule_id: contentId,
      option_value: key,
    });
    if (response.status === 200) {
      // console.log(response);
      if(this.props.darkTheme){
        toast.dark(response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
      }else{
        toast(response.data.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
      }
      
    } else {
      //console.log(response);
    }
    // firebase
    //   .firestore()
    //   .collection("schedule")
    //   .doc(contentId)
    //   .update({
    //     response: firebase.firestore.FieldValue.arrayUnion(responseData),
    //   });
  };
  const assignedToShow = () => {
    if(assignedShow){
      setAssignedShow(false);
    }else{
      setAssignedShow(true);
    }
  }

  const month_name = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  return (
    <>
      {item && (
        <div
          className={
            colorTheme ? `${colorTheme} taskbox` : "taskbox text-theme"
          }
        >
          <Form className="poll">
            <div className="d-flex">
              <p className="mt-0 mb-0 h5 flex-grow-1">{item.scheduleTitle}</p>
              <div>
                {item.deadline < Date.now() ? (
                  <span className="text-secondary small">
                    {item.closedBy ? (
                      <>
                        Closed by{" "}
                        <Participants userId={item.closedBy} nameOnly={true} />
                      </>
                    ) : (
                      "Closed"
                    )}
                  </span>
                ) : (
                  <span className="text-success small">Active</span>
                )}
              </div>
              {open_task_branch === "saved" && open_task_id === item.uid && (
                <div className="mx-1">
                  <Badge variant="success">Saved</Badge>
                </div>
              )}
            </div>
            {item.closedBy && item.previousDeadline && (
              <div className="text-danger small mb-1">
                <>
                  <span className="text-warning">
                    Schedule closed{" "}
                    {getTimeDifference(item.previousDeadline, item.deadline)}{" "}
                    before actual deadline.
                  </span>
                </>
              </div>
            )}
            <p className="mb-2 mt-0 font-weight-bold text-secondary">
              {moment(item.deadline).format("ddd")}{" "}
              {moment(item.deadline).format("DD")}{" "}
              {month_name[moment(item.deadline).format("M") - 1]},{" "}
              {moment(item.deadline).format("hh:mm A")}{" "}
              {item.duration_hours
                ? item.duration_minutes
                  ? "(" +
                    item.duration_hours +
                    " hr(s) & " +
                    item.duration_minutes +
                    " mins)"
                  : "(" + item.duration_minutes + " mins)"
                : item.duration_minutes
                ? "(" + item.duration_minutes + " mins)"
                : "onwards"}
            </p>
            {item.place && (
              <p className="mb-2 text-secondary">
                <span className="font-weight-bold">Location: </span>
                {item.place}
              </p>
            )}
            {item.agenda && (
              <p className="mb-2 text-secondary">
                <span className="font-weight-bold">Agenda:</span>
                <br />
                {item.agenda}
              </p>
            )}

            {/* {(admin || item.deadline < Date.now() || isTaskResponded || item && getResult(item.response, user)) 
                        && <>
                            {responses.map(response => {
                                return displayStats(response)
                            })}
                            {foo(responselist)}
                    </>} */}

            {/* {admin
                        ?
                        <>
                            {responses.map(response => {
                                return displayStats(response)
                            })}
                            {foo(responselist)}
                        </>
                        : null
                    } */}
            {/* {admin
                        ? responses.length
                            ? <div className="d-flex justify-content-center">
                                <Badge variant="secondary"> {responses.length} Response(s)</Badge>
                                <small onClick={() => setShow(prev => !prev)} className="ml-auto text-primary font-weight-bold" style={{ cursor: "pointer" }}>{show ? "Hide (-)" : "Show (+)"}</small>
                            </div>
                            : <p className="my-1">No responses yet</p>
                        : null
                    }
                    <div>
                        {(admin && show)
                            ?
                             <>
                                {responses.map(response => {
                                    return(
                                        <UserResponse userId={response.userId} nameOnly={true}>
                                            <>
                                                <p className="mt-0 mb-0">{response.optionValue}</p>
                                                <p className="mt-0 mb-0 small">{timeFromNow(response.responseTime)}</p>
                                            </>
                                        </UserResponse>
                                    )
                                })}
                            </>
                            : item && getResult(item.response, user)
                                ? <div>{getValue(item.response, user)}</div>
                                : <PollView item={{...item, ...pollData}} contentId={props.contentId} currentUser={props.currentUser} taskResponse={(uid, option) => console.log(uid, option)} user={user} uid={uid} setIsTaskResponded={setIsTaskResponded} />

                        }
                    </div> */}
            {admin ? (
              <PollAdmin {...props} pollData={item} />
            ) : (
              <PollUser
                {...props}
                pollData={item}
                taskResponse={taskResponse}
              />
            )}

        {admin? (
          props.view === 2 ? (
            <>
              <div className="mt-2 d-flex justify-content-between">
                <div variant={darkTheme ? "dark" : "light"} className="mt-3">Assigned To </div>
                <div className="text-primary mt-3 pointer" onClick={() => assignedToShow()}>{assignedShow ? "Hide(-)" : "Show(+)"}</div>
              </div>
              {assignedShow ? (
              <>
              {/*console.log(currAssignment,"respoxx")*/}
                <AssignedUsers
                  taskData={item}
                />
              </>
              ) : null
              }
            </>
          ): null
        ): null}
          </Form>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  darkTheme: state.platform.darkTheme,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,
  open_task_branch: state.platform.open_task_branch,
  open_task_id: state.platform.open_task_id,
});

export default connect(mapStateToProps)(Schedule);
