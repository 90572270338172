import React, { useEffect, useState } from "react";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import firebase from "firebase";
import _ from 'lodash';


const DisplayAnswers = (props) => {
  let [id, setId] = useState(null);

  const [responsedAnswers, setResponsedAnswers] = useState([]);
  const [response, setReponse] = useState({...props.response});

  useEffect(() => {
    firebase.firestore().collection('specialOlympics').doc(props.response.special_olympic_id).collection("responses").doc(props.response.id).onSnapshot((doc) => {
      console.log(doc.data(), " on snapshot data change")
      const prevResponse = response;
      prevResponse['answers'] = doc.data().answers;
      setReponse(_.cloneDeep(prevResponse))
    })
  },[])

  useEffect(() => {
    
    const { quizData } = props;

    if (quizData && response) {

      let responsedAnswers = [];
      Object.keys(quizData.specialOlympicQuestions)
        .sort(
          (a, b) =>
            quizData.specialOlympicQuestions[a].sequenceNo -
            quizData.specialOlympicQuestions[b].sequenceNo
        )
        .map((q, k) => {
          // console.log(response);
          let mulAnswers = [];

          if (response.answers[q]) {
            response.answers[q].options.map((opt) => {
              mulAnswers.push(
                quizData.specialOlympicQuestions[q].options[opt].optionValue
              );
            });
          }

          let json = {};
          if (mulAnswers.length > 0) {
            json = {
              question: quizData.specialOlympicQuestions[q].question,
              options: [...mulAnswers],
            };
          }
          if (mulAnswers.length === 0) {
            json = {
              question: quizData.specialOlympicQuestions[q].question,
              options: [],
            };
          }
          responsedAnswers.push(json);
        });
      setResponsedAnswers(responsedAnswers);
    }
  }, [props.response.id !== id, response]);

  if (props.response.id !== id) {
    setId(props.response.id);
  }

  const { quizData } = props;

  return (
    <div className="container px-3">
      {responsedAnswers.length > 0 && (
        <>
          <div className="row py-2 px-3">
            <h6>Selected Answers</h6>
            <div className="col-12 px-2 py-2">
              {Object.keys(quizData.specialOlympicQuestions)
                .sort(
                  (a, b) =>
                    quizData.specialOlympicQuestions[a].sequenceNo -
                    quizData.specialOlympicQuestions[b].sequenceNo
                )
                .map((item, key) => (
                  <div key={key}>
                    <p className="my-0">
                      <span className="font-weight-bold">Q{key + 1}.</span>{" "}
                      {quizData.specialOlympicQuestions[item].question}
                    </p>
                    <div className="d-flex overflow-auto">
                      {Object.keys(
                        quizData.specialOlympicQuestions[item].options
                      )
                        .sort(
                          (a, b) =>
                            quizData.specialOlympicQuestions[item].options[a]
                              .sequenceNo -
                            quizData.specialOlympicQuestions[item].options[b]
                              .sequenceNo
                        )
                        .map((o, k) => (
                          <div
                            className={
                              response.answers[item] &&
                              Object.keys(
                                response.answers[item].options
                              ).includes(o)
                                ? "form-check rounded mb-2 border-dark col"
                                : "form-check rounded mb-2 col"
                            }
                            key={k}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={
                                quizData.specialOlympicQuestions[item].options[
                                  o
                                ].optionValue
                              }
                              name={
                                quizData.createdBy +
                                "_" +
                                quizData.specialOlympicQuestions[item].options[
                                  o
                                ].optionValue +
                                k
                              }
                              id={
                                quizData.createdBy +
                                "_" +
                                k +
                                "_" +
                                key +
                                "_" +
                                item
                              }
                              checked={
                                response.answers[item] &&
                                response.answers[item].options.includes(o)
                              }
                              disabled={true}
                            />

                            <label
                              className="form-check-label"
                              htmlFor={
                                quizData.createdBy + "_" + k + "_" + key
                              }
                            >
                              {
                                quizData.specialOlympicQuestions[item].options[
                                  o
                                ].optionValue
                              }
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DisplayAnswers;
