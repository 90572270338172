import React, { Component } from "react";
import firebase from "firebase";
import { connect } from "react-redux";
import {
  setDarkTheme,
  setCurrentWorkspace,
  setSubscription,
  setCurrentChannel,
  setAllMessages,
  setParticipants,
  setWorkspaces,
  clearUser,
  setUser,
  setChannels,
  setRightPanel,
  setWorkspaceSlug,
  setShowPrescription,
  setShowNoteApp,
  setContentType,
  resetAllTasks,
  setTaskType,
  setShowCaseSheet,
} from "../../actions/index";

import {
  Form,
  Image,
  InputGroup,
  FormControl,
  Button,
  Spinner,
  ListGroup,
  Modal,
} from "react-bootstrap";
import TextareaAutosize from "react-textarea-autosize";
import AvatarEditor from "react-avatar-editor";

import "./Left.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faCheck,
  faTimes,
  faPencilAlt,
  faSignOutAlt,
  faCheckCircle,
  faTimesCircle,
  faLink,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { saveState, loadState } from "../../services";
import { indexOf } from "lodash";

export class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameError: "",
      emailError: "",
      userRef: firebase.auth().currentUser,
      usersRef: firebase.database().ref("users"),
      error: "",

      previewImage: "",
      avatar: null,
      metadata: {},
      cropImage: false,
      croppedImage: "",
      uploadedAvatar: "",
      changingAvatar: false,
      storageRef: firebase.storage().ref(),

      displayName:
        firebase.auth().currentUser && firebase.auth().currentUser.displayName,
      editDisplayName: false,

      firstName: "",
      firstNameLoaded: "",
      editFirstName: false,
      loadingFirstName: true,

      lastName: "",
      lastNameLoaded: "",
      editLastName: false,
      loadingLastName: true,

      email:
        firebase.auth().currentUser && firebase.auth().currentUser.email
          ? firebase.auth().currentUser.email
          : "",
      emailLoaded: "",
      editEmail: false,
      loadingEmail: true,

      emailVerified: false,
      phoneNumberVerified: false,

      phoneNumber:
        firebase.auth().currentUser && firebase.auth().currentUser.phoneNumber,
      editPhoneNumber: false,
      loadingPhoneNumber: true,
      currentConversionStep: 0,
      showConversionModal: false,
      convertEmail: "",
      convertPassword: "",
    };
  }

  _handleStateChange = (attr, val) => this.setState({ [attr]: val });

  componentDidMount() {
    const { userRef, usersRef } = this.state;

    if (userRef) {
      firebase
        .firestore()
        .collection("users")
        .doc(`${userRef.uid}`)
        .onSnapshot({}, (doc) => {
          if (doc.exists) {
            // console.log("doc.data()", doc.data())
            const {
              firstName,
              middleName,
              lastName,
              email,
              emailVerified,
              phone,
            } = doc.data();

            this._handleStateChange("firstName", firstName);
            this._handleStateChange("loadingFirstName", false);
            this._handleStateChange("middleName", middleName);
            this._handleStateChange("loadingMiddleName", false);
            this._handleStateChange("lastName", lastName);
            this._handleStateChange("loadingLastName", false);
            this._handleStateChange("email", email);
            this._handleStateChange("loadingEmail", false);
            this._handleStateChange("emailVerified", emailVerified);
            this._handleStateChange("phoneNumber", phone);
            this._handleStateChange("loadingPhoneNumber", false);
          }
        });
    }

    // userRef && usersRef.child(userRef.uid).on("value", snap => {

    //     snap.val() && this.setState({

    //         firstName: snap.val().firstName && snap.val().firstName,
    //         firstNameLoaded: snap.val().firstName && snap.val().firstName,
    //         loadingFirstName: false,

    //         lastName: snap.val().lastName && snap.val().lastName,
    //         lastNameLoaded: snap.val().lastName && snap.val().lastName,
    //         loadingLastName: false,

    //         loadingPhoneNumber: false,

    //         email: userRef.email,
    //         emailLoaded: userRef.email,
    //         loadingEmail: false
    //     });
    // });
  }

  uploadCroppedImage = () => {
    const { storageRef, userRef, avatar, metadata } = this.state;

    storageRef
      .child(`avatars/users/${userRef.uid}`)
      .put(avatar, metadata)
      .then((snap) => {
        snap.ref.getDownloadURL().then((downloadURL) => {
          this.setState({ uploadedAvatar: downloadURL }, () =>
            this.changeAvatar()
          );
        });
      });
  };

  changeAvatar = () => {
    this.state.userRef
      .updateProfile({
        photoURL: this.state.uploadedAvatar,
      })
      .then(() => {
        console.log("PhotoURL updated");
      })
      .catch((err) => {
        console.error(err);
      });

    firebase
      .firestore()
      .collection("users")
      .doc(this.props.currentUser.uid)
      .update({ avatar: this.state.uploadedAvatar })
      .then(() => {
        console.log("User avatar updated");
      })
      .catch((err) => {
        console.error(err);
      });

    this.setState({ changingAvatar: false });
  };

  handleChangeFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file) {
      let metadata = {
        contentType: file.type,
      };

      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        this.setState({
          previewImage: reader.result,
          metadata,
          cropImage: true,
        });
      });
    }
  };

  handleCropImage = () => {
    if (this.avatarEditor) {
      this.avatarEditor.getImageScaledToCanvas().toBlob((blob) => {
        let imageUrl = URL.createObjectURL(blob);
        this.setState(
          {
            croppedImage: imageUrl,
            avatar: blob,
            cropImage: false,
            changingAvatar: true,
          },
          () => {
            this.uploadCroppedImage();
          }
        );
      });
    }
  };

  handleSignout = async () => {
    const {
      setCurrentWorkspace,
      setCurrentChannel,
      setAllMessages,
      setParticipants,
      setWorkspaces,
      setUser,
      clearUser,
      currentUser,
      setChannels,
      setRightPanel,
      setWorkspaceSlug,
      setShowPrescription,
      setShowNoteApp,
      setContentType,
      resetAllTasks,
      setTaskType,
      setShowCaseSheet,
      setSubscription
    } = this.props;

    firebase
      .auth()
      .signOut()
      .then(() => {
        // messaging/ notifications
        // const messaging = firebase.messaging();
        // messaging.requestPermission()
        //   .then(() => {
        //     console.log("have permission")
        //     return messaging.getToken();
        //   })
        //   .then((token) => {
        //     firebase.firestore().collection("users")
        //       .doc(currentUser.uid).get().then((doc) => {
        //         if (doc.exists) {
        //           const previousDeviceInfo = doc.data().deviceInfo;
        //           const index = previousDeviceInfo.fcmTokenWeb.indexOf(token);
        //           previousDeviceInfo.fcmTokenWeb.splice(index, 1);
        //           firebase.firestore().collection("users")
        //             .doc(currentUser.uid).update({
        //               deviceInfo: {
        //                 ...previousDeviceInfo,
        //                 fcmTokenWeb: previousDeviceInfo.fcmTokenWeb
        //               }
        //             })
        //         }
        //       })
        //   }).catch((error) => {

        //   })
        setRightPanel(false);
        setCurrentWorkspace(null);
        setSubscription(null);
        setCurrentChannel(null);
        setAllMessages(null);
        setParticipants(null);
        setWorkspaces(null);
        setUser(null);
        setChannels(null);
        setWorkspaceSlug(null);
        setShowPrescription(false);
        setContentType(null);
        setTaskType(null);
        resetAllTasks();
        setShowNoteApp(false);

        setShowCaseSheet(false);
        window.localStorage.removeItem("defaultWorkspace");
        // window.location.replace("/");

        // window.sessionStorage.removeItem("loginToken")
        // window.sessionStorage.removeItem("loginTokenTimestamp")
        // window.sessionStorage.removeItem("referenceLoginToken")
        // window.sessionStorage.removeItem("referenceLoginTokenTimestamp")
        console.log("signed out!");
      });
  };

  convertToPermaAccountHandler = (firstName, lastName, email, password) => {
    var credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    firebase
      .auth()
      .currentUser.linkWithCredential(credential)
      .then((usercred) => {
        var user = usercred.user;
        firebase
          .firestore()
          .collection("users")
          .doc(this.props.currentUser.uid)
          .update({
            isAnonymous: false,
            isConvertedFromGuest: true,
            conversionTimestamp: new Date(),
            firstName: firstName,
            lastName: lastName,
            email: email,
          })
          .then(() => {
            this.setState({
              showConversionModal: false,
              convertEmail: "",
              convertPassword: "",
            });
          })
          .then(() => {
            alert(
              "You have been signed out. Please signin with email and password"
            );
            this.handleSignout();
          });
        console.log("Anonymous account successfully upgraded", user);
      })
      .catch((error) => {
        console.log("Error upgrading anonymous account", error);
        alert(error);
      });
  };

  handleProfileEdit = (type, value) => {
    const { currentUser } = this.props;
    if (type === "displayName") {
      if (value == "") {
        this.setState({ error: "Username can not be empty" });
        return;
      }
    }
    var regex = /^[a-zA-Z0-9]+([_\s\-]?[a-zA-Z0-9])*$/;
    if (!regex.test(value)) {
      this.setState({ error: "Enter valid username." });
      //console.log("Name must contain Alphabets.")
      return;
    }

    function getProfileEditData(type) {
      switch (type) {
        case "displayName":
          return { displayName: value };
        case "firstName":
          return { firstName: value };
        case "lastName":
          return { lastName: value };
        default:
          return false;
      }
    }
    let profileEditData = getProfileEditData(type);

    if (profileEditData) {
      if (type === "displayName") {
        this.state.userRef
          .updateProfile(profileEditData)
          .then(() => {
            console.log("Profile updated");
          })
          .catch((err) => {
            console.error(err);
          });
      }

      firebase
        .firestore()
        .collection("users")
        .doc(this.props.currentUser.uid)
        .update(profileEditData)
        .then(() => {
          console.log("Profile updated");
          this.setState({ error: "" });
          this.handleEditStateChange(type);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  handleEditStateChange = (type) => {
    switch (type) {
      case "displayName":
        this.setState({ editDisplayName: false });
        break;
      case "firstName":
        this.setState({ editFirstName: false });
      case "lastName":
        this.setState({ editLastName: false });
        break;
      default:
        break;
    }
  };

  render() {
    const { currentUser, darkTheme, setDarkTheme } = this.props;
    const { userRef } = this.state;
    return (
      <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme">
        <div>
          <Form>
            {this.state.cropImage ? (
              <div className="d-flex flex-column my-2">
                <p className="small text-muted text-center mb-1">CROP IMAGE</p>
                <div className="d-flex justify-content-center align-items-center">
                  <AvatarEditor
                    ref={(node) => (this.avatarEditor = node)}
                    image={this.state.previewImage}
                    width={150}
                    height={150}
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    variant="light"
                    size="sm"
                    className="d-flex justify-content-center align-items-center text-success"
                    onClick={this.handleCropImage}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faCheck} /> Done
                  </Button>
                  <Button
                    variant="light"
                    size="sm"
                    className="d-flex justify-content-center align-items-center text-secondary"
                    onClick={() => this.setState({ cropImage: false })}
                  >
                    <FontAwesomeIcon className="mr-2" icon={faTimes} /> Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <div className="my-5 mx-auto" id="add-avatar">
                <label for="avatar">
                  <Image
                    id="avatar-image"
                    width="150"
                    height="150"
                    roundedCircle
                    src={
                      this.state.croppedImage
                        ? this.state.croppedImage
                        : this.props.currentUser.photoURL
                    }
                    style={{ background: "#AEAEAE" }}
                  />
                </label>
                <input
                  type="file"
                  id="avatar"
                  accept="image/png, image/jpeg"
                  onChange={this.handleChangeFile}
                />

                {!this.state.changingAvatar && (
                  <label for="avatar" id="overlay">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faCamera} className="text-white" />
                      <small className="text-white text-center px-2">
                        CHANGE PROFILE PICTURE
                      </small>
                    </div>
                  </label>
                )}

                {this.state.changingAvatar && (
                  <label for="avatar" id="overlay" className="label-overlay">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <small className="text-white text-center px-2">
                        LOADING...
                      </small>
                    </div>
                  </label>
                )}
              </div>
            )}

            <div className="px-2">
              <small className="text-muted mb-2">USERNAME</small>
              {this.state.editDisplayName ? (
                <>
                  <div className="d-flex">
                    <Form.Control
                      ref={(input) => (this.displayNameRef = input)}
                      type="text"
                      placeholder="Enter your name"
                      value={this.state.displayName}
                      name="displayName"
                      onChange={(e) =>
                        this.setState({ displayName: e.target.value })
                      }
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          this.handleProfileEdit(
                            "displayName",
                            this.state.displayName
                          );
                        }
                      }}
                      className="border-top-0 border-left-0 border-right-0 rounded-0 px-0 pb-3"
                    />

                    <div className="d-flex">
                      <Button
                        variant="light"
                        onClick={() =>
                          this.handleProfileEdit(
                            "displayName",
                            this.state.displayName
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                        />
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            editDisplayName: false,
                            displayName: currentUser && currentUser.displayName,
                          })
                        }
                        variant="light"
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-secondary"
                        />
                      </Button>
                    </div>
                  </div>
                  <div className="small mt-1 text-danger">
                    {this.state.error ? this.state.error : null}
                  </div>
                </>
              ) : (
                <div className="d-flex">
                  <p className="flex-grow-1">{this.state.displayName}</p>
                  {this.state.editDisplayName ? (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-secondary"
                      onClick={() => this.setState({ editDisplayName: false })}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className="text-secondary"
                      onClick={() => this.setState({ editDisplayName: true })}
                    />
                  )}
                </div>
              )}
              <div className="notice">
                <p className="text-muted">
                  This name will be shown to all your connections on Igesia.
                </p>
              </div>
            </div>
            {/* <Button variant="outline-secondary" size="sm" className="d-block w-100 mb-2">
                            <FontAwesomeIcon size="xs" icon={faPlus} /> Education
                        </Button>
                        <Button variant="outline-secondary" size="sm" className="d-block w-100 mb-2">
                            <FontAwesomeIcon size="xs" icon={faPlus} /> Research</Button>
                        <Button variant="outline-secondary" size="sm" className="d-block w-100 mb-2 m">
                            <FontAwesomeIcon size="xs" icon={faPlus} /> Books</Button> */}
            {/* <Button variant="secondary" size="sm" className="d-block w-100 my-2 text-center" onClick={this.handleSignout}>Sign Out</Button> */}
          </Form>
        </div>

        <div className="px-2">
          <small className="text-muted mb-2">Theme</small>
          <div className="d-flex">
            <p className="mr-auto">
              {darkTheme ? "Dark theme" : "Light theme"}
            </p>
            <Form.Check
              value={darkTheme}
              checked={darkTheme}
              onChange={() => {
                setDarkTheme(!darkTheme);

                if (document.body.getAttribute("data-theme") === "dark") {
                  document.body.setAttribute("data-theme", "light");
                  saveState("theme", "light");
                } else {
                  document.body.setAttribute("data-theme", "dark");
                  saveState("theme", "dark");
                }
              }}
              type="switch"
              id="custom-switch"
              label=""
            />
          </div>
        </div>

        {/* my drive button */}

        <div className="px-2 pb-2">
          <div className="d-flex w-100">
            <Button
              variant="secondary"
              className="w-100"
              onClick={() => {
                this.props.toggleAction();
                this.props.setActionType({ actionType: "showMyDrive" });
                this.props.setLeftActionType("showMyDrive");
              }}
              title="My Drive"
            >
              My Drive
            </Button>
          </div>
        </div>

        <div>
          <ListGroup>
            <ListGroup.Item className="profile-item bg-card-theme text-theme px-2 border-0">
              <div className="d-flex justify-content-between">
                <small className="text-theme-lighter">First name</small>
                <div className="d-flex">
                  {!this.state.editFirstName &&
                    (this.state.loadingFirstName ? (
                      <Spinner animation="border" size="sm" className="mr-2" />
                    ) : (
                      <small className="mr-2">
                        {this.state.firstName ? (
                          this.state.firstName
                        ) : (
                          <span className="text-muted">+ ADD NOW</span>
                        )}
                      </small>
                    ))}
                  {this.state.editFirstName ? (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-secondary"
                      onClick={() =>
                        this.setState({
                          editFirstName: false,
                          firstName: this.state.firstName,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className="text-muted action-buttons"
                      onClick={() => {
                        this.setState({ editFirstName: true });
                      }}
                    />
                  )}
                </div>
              </div>
              {this.state.editFirstName && (
                <div className="d-flex align-items-end">
                  <Form.Control
                    ref={(input) => (this.firstNameRef = input)}
                    type="text"
                    placeholder="Enter your first name"
                    value={this.state.firstName}
                    name="firstName"
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        this.handleProfileEdit(
                          "firstName",
                          this.state.firstName
                        );
                        this.setState({
                          firstNameLoaded: this.state.firstName,
                        });
                      }
                    }}
                    className="border-top-0 border-left-0 border-right-0 rounded-0 p-0"
                  />

                  <div className="py-1">
                    <FontAwesomeIcon
                      onClick={() => {
                        this.handleProfileEdit(
                          "firstName",
                          this.state.firstName
                        );
                        this.setState({
                          firstNameLoaded: this.state.firstName,
                        });
                      }}
                      icon={faCheck}
                      className="text-success"
                    />
                  </div>
                </div>
              )}
            </ListGroup.Item>
            <ListGroup.Item className="profile-item bg-card-theme text-theme px-2 border-0">
              <div className="d-flex justify-content-between">
                <small className="text-muted">Last name</small>
                <div className="d-flex">
                  {!this.state.editLastName &&
                    (this.state.loadingLastName ? (
                      <Spinner animation="border" size="sm" className="mr-2" />
                    ) : (
                      <small className="mr-2">
                        {this.state.lastName ? (
                          this.state.lastName
                        ) : (
                          <span className="text-muted">+ ADD NOW</span>
                        )}
                      </small>
                    ))}
                  {this.state.editLastName ? (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-secondary"
                      onClick={() =>
                        this.setState({
                          editLastName: false,
                          lastName: this.state.lastName,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className="text-muted action-buttons"
                      onClick={() => {
                        this.setState({ editLastName: true });
                      }}
                    />
                  )}
                </div>
              </div>
              {this.state.editLastName && (
                <div className="d-flex align-items-end">
                  <Form.Control
                    ref={(input) => (this.lastNameRef = input)}
                    type="text"
                    placeholder="Enter your last name"
                    value={this.state.lastName}
                    name="lastName"
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        this.handleProfileEdit("lastName", this.state.lastName);
                        this.setState({ lastNameLoaded: this.state.lastName });
                      }
                    }}
                    className="border-top-0 border-left-0 border-right-0 rounded-0 p-0"
                  />

                  <div className="py-1">
                    <FontAwesomeIcon
                      onClick={() => {
                        this.handleProfileEdit("lastName", this.state.lastName);
                        this.setState({ lastNameLoaded: this.state.lastName });
                      }}
                      icon={faCheck}
                      className="text-success"
                    />
                  </div>
                </div>
              )}
            </ListGroup.Item>
            <ListGroup.Item className="profile-item bg-card-theme text-theme px-2 border-0">
              <div className="d-flex justify-content-between">
                <small className="text-muted">Email address</small>
                <div className="d-flex">
                  {!this.state.editEmail &&
                    (this.state.loadingEmail ? (
                      <Spinner animation="border" size="sm" className="mr-2" />
                    ) : (
                      <small className="mr-2">
                        {this.state.email ? (
                          this.state.email
                        ) : (
                          <span className="text-muted">Not linked</span>
                        )}
                      </small>
                    ))}
                  {this.state.emailVerified ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-success"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="text-muted"
                    />
                  )}
                </div>
              </div>
              {this.state.editEmail && (
                <div className="d-flex align-items-end">
                  <Form.Control
                    ref={(input) => (this.emailRef = input)}
                    type="email"
                    placeholder="Enter your email address"
                    value={this.state.email}
                    name="email"
                    onChange={(e) => this.setState({ email: e.target.value })}
                    className="border-top-0 border-left-0 border-right-0 rounded-0 p-0"
                  />

                  <div className="py-1">
                    <FontAwesomeIcon
                      onClick={() =>
                        this.handleProfileEdit("email", this.state.email)
                      }
                      icon={faCheck}
                      className="text-success"
                    />
                  </div>
                </div>
              )}
            </ListGroup.Item>
            <ListGroup.Item className="profile-item bg-card-theme text-theme px-2 border-0">
              <div className="d-flex justify-content-between">
                <small className="text-muted">Phone number</small>
                <div className="d-flex">
                  {!this.state.editPhoneNumber &&
                    (this.state.loadingPhoneNumber ? (
                      <Spinner animation="border" size="sm" className="mr-2" />
                    ) : (
                        <small className="mr-2">
                          {this.state.phoneNumber?.number ? (
                           <>{ `+${this.state.phoneNumber.phoneCode} ${this.state.phoneNumber?.number}`}</>
                          ) : (
                              <span className="text-muted">Not linked</span>
                            )}
                        </small>
                      ))}
                  {this.state.phoneNumberVerified ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-success"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="text-muted"
                    />
                  )}
                </div>
              </div>
              {this.state.editPhoneNumber && (
                <div className="d-flex align-items-end">
                  <Form.Control
                    ref={(input) => (this.phoneNumberRef = input)}
                    type="tel"
                    placeholder="Enter your phone number"
                    value={this.state.phoneNumber}
                    name="phoneNumber"
                    onChange={(e) =>
                      this.setState({ phoneNumber: e.target.value })
                    }
                    className="border-top-0 border-left-0 border-right-0 rounded-0 p-0"
                  />

                  <div className="py-1">
                    <FontAwesomeIcon
                      onClick={() => {
                        this.handleProfileEdit(
                          "phoneNumber",
                          this.state.phoneNumber
                        );
                        this.setState({
                          phoneNumberLoaded: this.state.phoneNumber,
                        });
                      }}
                      icon={faCheck}
                      className="text-success"
                    />
                  </div>
                </div>
              )}
            </ListGroup.Item>
          </ListGroup>

          <div className="rounded-0">
            <ListGroup>
              {currentUser.isAnonymous ? (
                <ListGroup.Item
                  action
                  onClick={() => {
                    if (
                      window.confirm(
                        'Your data will be lost if you logout since you are a guest user. Confirm to "logout" cancel to "stay"'
                      )
                    ) {
                      this.handleSignout();
                    }
                  }}
                  className="text-center text-danger rounded-0 border-0 small"
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="small" />{" "}
                  Logout
                </ListGroup.Item>
              ) : (
                <ListGroup.Item
                  action
                  onClick={this.handleSignout}
                  className="text-center text-danger rounded-0 border-0 small"
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="small" />{" "}
                  Logout
                </ListGroup.Item>
              )}
            </ListGroup>
          </div>

          <div className="rounded-0">
            {currentUser.isAnonymous ? (
              <li
                action
                onClick={() => {
                  this.setState({ showConversionModal: true });
                }}
              >
                <div className="d-flex align-items-center justify-content-center p-3">
                  <div
                    className="bg-theme-lightest rounded rounded-circle d-flex justify-content-center align-items-center"
                    style={{
                      height: "2.5rem",
                      width: "2.5rem",
                      borderRadius: "1.25rem",
                    }}
                  >
                    <FontAwesomeIcon icon={faLink} className="text-theme" />
                  </div>
                  <div className="ml-3">
                    <p className="text-theme mb-0">Activate more features</p>
                    <p className="text-theme text-center mb-0 small">
                      Click to link your email now{" "}
                      <FontAwesomeIcon icon={faAngleRight} />
                    </p>
                  </div>
                </div>
              </li>
            ) : null}
          </div>
        </div>

        {/* modal for details when converting account */}
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.showConversionModal}
        >
          <Modal.Header className="bg-theme border-0 d-flex justify-content-between align-items-center pb-0">
            <Modal.Title id="contained-modal-title-vcenter">
              <h5 className="text-theme">Enter details</h5>
            </Modal.Title>
            <div
              title="Close"
              className="pointer"
              onClick={() =>
                this.setState({
                  showConversionModal: false,
                  convertEmail: "",
                  convertPassword: "",
                })
              }
            >
              <FontAwesomeIcon className="text-theme" icon={faTimes} />
            </div>
          </Modal.Header>
          <Modal.Body className="overflow-auto list-unstyled bg-theme pt-0">
            {!this.state.currentConversionStep ? (
              <>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  value={this.state.firstName}
                  name="displayName"
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                  className="rounded-1 my-2"
                />

                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  value={this.state.lastName}
                  name="displayName"
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                  className="rounded-1 my-2"
                />
                <p className="text-danger small">
                  {this.state.nameError ? this.state.nameError : null}
                </p>
              </>
            ) : (
              <>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={this.state.convertEmail}
                  name="email"
                  onChange={(e) =>
                    this.setState({ convertEmail: e.target.value })
                  }
                  className="rounded-1 my-2"
                />

                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  value={this.state.convertPassword}
                  name="displayName"
                  onChange={(e) =>
                    this.setState({ convertPassword: e.target.value })
                  }
                  className="rounded-1 my-2"
                />
                <p className="text-danger small">
                  {this.state.emailError ? this.state.emailError : null}
                </p>
              </>
            )}

            <div>
              {!this.state.currentConversionStep ? (
                <Button
                  variant="primary"
                  className="btn-sm"
                  onClick={() => {
                    if (this.state.currentConversionStep === 0) {
                      if (
                        this.state.firstName === "" ||
                        this.state.firstName === undefined
                      ) {
                        this.setState({ nameError: "Enter your first name" });
                        return;
                      } else if (
                        this.state.firstName.replace(/\s/g, "").length === 0
                      ) {
                        this.setState({ nameError: "Enter valid first name" });
                        return;
                      } else if (
                        this.state.lastName === "" ||
                        this.state.lastName === undefined
                      ) {
                        this.setState({ nameError: "Enter your last name" });
                        return;
                      } else if (
                        this.state.lastName.replace(/\s/g, "").length === 0
                      ) {
                        this.setState({ nameError: "Enter valid last name" });
                        return;
                      } else {
                        this.setState({ currentConversionStep: 1 });
                      }
                    }
                  }}
                >
                  Next
                </Button>
              ) : (
                <div className="d-flex justify-content-between">
                  <Button
                    variant="primary"
                    onClick={() => this.setState({ currentConversionStep: 0 })}
                    className="btn-sm"
                  >
                    Back
                  </Button>
                  <Button
                    variant="primary"
                    className="btn-sm"
                    onClick={() => {
                      if (
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                          this.state.convertEmail
                        )
                      ) {
                        if (this.state.convertPassword.length < 6) {
                          this.setState({
                            emailError:
                              "Password must have at least 6 characters",
                          });
                          return;
                        } else {
                          this.convertToPermaAccountHandler(
                            this.state.firstName,
                            this.state.lastName,
                            this.state.convertEmail,
                            this.state.convertPassword
                          );
                        }
                      } else {
                        this.setState({
                          emailError: "Enter a valid email address.",
                        });
                      }
                    }}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, {
  setDarkTheme,
  setCurrentWorkspace,
  setSubscription,
  setCurrentChannel,
  setAllMessages,
  setParticipants,
  setWorkspaces,
  setUser,
  clearUser,
  setChannels,
  setRightPanel,
  setWorkspaceSlug,
  setShowPrescription,
  setShowNoteApp,
  setContentType,
  resetAllTasks,
  setTaskType,
  setShowCaseSheet,
})(UserProfile);
