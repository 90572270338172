import React from "react";
import moment from "moment";
import { Button, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faReply,
  faAngleDown,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import Feedback from "../Tasks/Feedback";
import Poll from "../Tasks/Poll/Poll";
import Quiz from "../Tasks/Quiz/Quiz";
import Project from "../Tasks/Project";
import List from "../Tasks/List";
import Assignment from "../Tasks/Assignment/Assignment1";
import Reading from "../Tasks/Reading/Reading1";
import Schedule from "../Tasks/Schedule/Schedule";
import ReadMore from "./ReadMore";

import Linkify from "linkifyjs/react";
import Viewer from "react-viewer";
import firebase from "firebase";

import { connect } from "react-redux";
import { setReplyId } from "../../actions/index";
import QuizPlus from "../Tasks/Quiz-Plus/QuizPlus";

const isImage = (message) => {
  return message.type === 6;
};
const isFile = (message) => {
  return message.type === 7;
};
const isAudio = (message) => {
  return message.type === 11;
}
const isFeedback = (message) => {
  return (
    message.hasOwnProperty("feedback") && !message.hasOwnProperty("content")
  );
};

const isPoll = (message) => {
  return message.type === 3;
};
const isQuiz = (message) => {
  return message.type === 4;
};

const isProject = (message) => {
  return (
    message.hasOwnProperty("project") && !message.hasOwnProperty("content")
  );
};

const isList = (message) => {
  return message.hasOwnProperty("list") && !message.hasOwnProperty("content");
};

const isAssignment = (message) => {
  return message.type === 5;
};

const isReading = (message) => {
  return message.type === 2;
};

const isSchedule = (message) => {
  return message.type === 1;
};

const isSpecialOlympic = (message) => {
  return message.type === 10;
};

const timeFromNow = (timestamp) => {
  //if (moment(Date.now()).diff(moment(timestamp), 'days') < 1) return moment(timestamp).fromNow()
  if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
    return moment(timestamp).format("h:mm A");
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
    return `Yesterday, ${moment(timestamp).format("h:mm A")}`;
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
    return `${moment(timestamp).format("dddd")}, ${moment(timestamp).format(
      "h:mm A"
    )}`;
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 365)
    return `${moment(timestamp).format("MMM DD, YYYY")}, ${moment(
      timestamp
    ).format("h:mm A")}`;
  else return moment(timestamp).format("MMM YY");
};

const pdf_type = ["application/pdf"];

const word_type = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const excel_type = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const powerpoint_type = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

const strip = (value) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = value.match(regExp);
  return match && match[2].length === 11 ? (
    <iframe
      title="youtube"
      style={{ borderRadius: "4px" }}
      width="100%"
      height="170"
      src={"//www.youtube.com/embed/" + match[2]}
      frameborder="0"
      allowfullscreen
    ></iframe>
  ) : // : <ReadMore full_text={value} max_length="250" />;
  value.length > 50 ? (
    value.slice(0, 50) + "…"
  ) : (
    value
  );
  // : value.length > 50 ? <ReadMore full_text={value} max_length="250" /> + '…' : value;
};

const App = (source, content) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <Button
        variant="transparent"
        style={{ padding: 0 }}
        onClick={() => {
          setVisible(true);
        }}
      >
        <div
          style={{ height: "300px", width: "300px", overflow: "hidden" }}
          className="bg-light rounded d-flex align-items-center justify-content-center"
        >
          <img src={source} alt="Logo" className="w-100 rounded" />
        </div>
      </Button>
      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        images={[{ src: source, alt: content ? content : "" }]}
      />
    </>
  );
};

const ReplyMessageTemplate = ({ message, user }) => {
  
  if (isImage(message)) {
    return (
      <div className="text-theme">
        {App(message.image, message.content)}
        {message.content && (
          <div className="mt-2">
            <Linkify
              options={{
                target: { email: null, target: "_blank" },
                format: { url: strip(message.content) },
                events: {
                  mouseover: function (e) {
                    console.log("Link clicked!");
                  },
                },
              }}
            >
              <ReadMore full_text={message.content} max_length="250" />
            </Linkify>
          </div>
        )}
      </div>
    );
  }else if(isAudio(message)){
    return (<div>
      <div className="mt-2">
          <audio controls preload="auto" src={message.audio}></audio>
          {
            message.content && (
              <div className="mt-2">
                <Linkify
                  options={{
                    target: { email: null, target: "_blank" },
                    format: { url: strip(message.content) },
                    events: {
                      mouseover: function (e) {
                        console.log("Link clicked!");
                      },
                    },
                  }}
                >
                  <ReadMore full_text={message.content} max_length="250" />
                </Linkify>
              </div>
            )
          }
        </div>
    </div>)
  }
   else if (isFile(message)) {
    return (
      <div className="text-theme">
        <div className="rounded px-2 py-3 bg-light">
          <a href={message.file} target="_blank">
            <div className="d-flex rounded bg-white shadow-sm">
              <div className="py-2 px-3" style={{ backgroundColor: "#FEF2E4" }}>
                {word_type.includes(
                  message.metaData && message.metaData.type
                ) && (
                  <FontAwesomeIcon icon={faFileWord} className="text-primary" />
                )}
                {pdf_type.includes(
                  message.metaData && message.metaData.type
                ) && (
                  <FontAwesomeIcon icon={faFilePdf} className="text-danger" />
                )}
                {powerpoint_type.includes(
                  message.metaData && message.metaData.type
                ) && (
                  <FontAwesomeIcon
                    icon={faFilePowerpoint}
                    className="text-danger"
                  />
                )}
                {excel_type.includes(
                  message.metaData && message.metaData.type
                ) && (
                  <FontAwesomeIcon
                    icon={faFileExcel}
                    className="text-success"
                  />
                )}
              </div>
              <div className="p-2 flex-grow-1">
                <small className="font-weight-bold text-dark">
                  {message.metaData && message.metaData.name}
                </small>
              </div>
              <div className="py-2 px-3">
                <FontAwesomeIcon icon={faLink} className="text-primary" />
              </div>
            </div>
          </a>
        </div>
        {message.content && (
          <div className="mt-2 text-theme">
            <Linkify
              options={{
                target: { email: null, target: "_blank" },
                format: { url: strip(message.content) },
                events: {
                  mouseover: function (e) {
                    console.log("Link clicked!");
                  },
                },
              }}
            >
              <ReadMore full_text={message.content} max_length="250" />
            </Linkify>
          </div>
        )}
      </div>
    );
  } else if (isFeedback(message)) {
    return (
      <Feedback
        feedbacktitle={message.feedback}
        uid={message.uid}
        item={message}
        user={user.uid}
      />
    );
  } else if (isPoll(message)) {
    return (
      <Poll
        pollname={message.poll}
        uid={message.uid}
        item={message}
        user={user.uid}
        contentId={message.contentId}
      />
    );
  } else if (isQuiz(message)) {
    return <Quiz quiz_data={message} user_id={user.uid} />;
  } else if (isProject(message)) {
    return (
      <Project
        projectname={message.project}
        uid={message.uid}
        item={message}
        user={user.uid}
      />
    );
  } else if (isList(message)) {
    return <List uid={message.uid} item={message} user={user.uid} />;
  } else if (isAssignment(message)) {
    return <Assignment uid={message.uid} item={message} user={user.uid} />;
  } else if (isReading(message)) {
    return <Reading uid={message.uid} item={message} user={user.uid} />;
  } else if (isSchedule(message)) {
    return (
      <Schedule
        schedule={message.schedule}
        uid={message.uid}
        contentId={message.contentId}
        item={message}
        user={user.uid}
      />
    );
  } else if (isSpecialOlympic(message)) {
    return (
      <QuizPlus
        quiz_data={message}
        user_id={user.uid}
        uid={message.uid}
        colorTheme="text-theme-light"
      />
    );
  } else {
    return (
      <div>
        <Linkify
          options={{
            target: { email: null, target: "_blank" },
            format: { url: strip(message.content) },
            events: {
              mouseover: function (e) {
                console.log("Link clicked!");
              },
            },
          }}
        >
          <ReadMore full_text={message.content} max_length="250" />
        </Linkify>
      </div>
    );
  }
};

function ReplyTemplate({ message, currentUser, setReplyId }) {
  console.log(message);
  return (
    <div
      style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
      className="m-0 p-2 rounded-left d-flex text-theme"
    >
      <div className="flex-grow-1">
        <span style={{ fontWeight: "bold" }}>
          {message.user.id === currentUser.uid ? "You" : message.user.name}
          &nbsp;
        </span>
        <span className="timestamp">{timeFromNow(message.timestamp)}</span>
        
        <ReplyMessageTemplate message={message} user={currentUser} />
      </div>
      <div className="px-1 py-0">
        <FontAwesomeIcon
          icon={faTimesCircle}
          size="sm"
          onClick={() => setReplyId("")}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, { setReplyId })(ReplyTemplate);