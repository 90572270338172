import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import {
  setIsVideoVisible,
  setChannelDetails,
  addRoom,
  setWorkspaces,
  setSubscription,
  clearSubscription,
  setCurrentChannel,
  setAllMessages,
  setParticipants,
  resetData,
  setLeftPanel,
  addRoomError,
  setVideoDetails,
} from "../actions";
import { setCurrentWorkspace } from "../actions/workspace";
import {
  Button,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Spinner,
  Image,
  ButtonGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faLock,
  faCamera,
  faCommentAlt,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import firebase from "firebase";
import {
  API_BASE_URL,
  INVITE_PROCESS,
  MEETING_TYPE,
  INVITE_JWT_PROCESS,
} from "../config/index";
import { postData } from "../utils/index";
import html2canvas from "html2canvas";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import axios from "axios";
import Left from "./Left/Left";
import Middle from "./Middle/NewMiddle";
//import Right from "./Right/Right";
import Right from "./Right/newRight";
import Video from "./Right/Video";
import UserFeedback from "./UserFeedback";
import { saveState, loadState } from "../services/localStorage";
import { useHistory } from "react-router-dom";
import NewWindow from "react-new-window";

const Rnd = require("react-rnd").Rnd;

const App = (props) => {
  const history = useHistory();
  const {
    currentUser,
    currentChannel,
    isPrivateChannel,
    userPosts,
    primaryColor,
    allChannels,
    isVideoVisible,
    channelDetails,
    newRoom,
    addRoom,
    setWorkspaces,
    currentSubscription,
    setCurrentChannel,
    setAllMessages,
    setParticipants,
    resetData,
    setLeftPanel,
    setIsVideoVisible,
    setChannelDetails,
    setCurrentWorkspace,
    currentWorkspace,
    workspaces,
    setVideoDetails,
    newRoomJWT,
  } = props;

  useEffect(() => {
    //console.log(currentUser);
    if (currentUser) {
      /* firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .onSnapshot((snapshot) => {
          console.log(snapshot.data());
          if (snapshot.exists && snapshot.data().subscription) {
            console.log(snapshot.data().subscription);
            setSubscription(snapshot.data().subscription);
          }
        });*/
      // setSubscription(currentUser);
    }
  }, [currentUser]);

  console.log(currentSubscription);

  const [videoDimension, setVideoDimension] = useState({
    videoWidth: "50vw",
    videoHeight: "70vh",
  });
  const [coordinates, setCoordinates] = useState({ xAxis: 100, yAxis: 100 });
  const [newRoomModalShow, setNewRoomModalShow] = useState(false);
  const [newRoomModalJWTShow, setNewRoomModalJWTShow] = useState(false);
  const [loadingNewRoomData, setLoadingNewRoomData] = useState(false);
  const [newRoomExists, setNewRoomExists] = useState(true);
  const [inviteData, setInviteData] = useState({});
  const [roomData, setRoomData] = useState({});
  const [newRoomMessage, setNewRoomMessage] = useState("");
  const [joinLoading, setJoinLoading] = useState(false);
  const [adminApprovalModalShow, setAdminApprovalModalShow] = useState(false);
  const [inviteModalShow, setInviteModalShow] = useState(false);
  const [prevPendingInvites, setPrevPendingInvites] = useState([]);
  const [privateRoomLink, setPrivateRoomLink] = useState(false);

  const [usersDocExists, setUsersDocExists] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [modalError, setModalError] = useState("");

  const maximiseVideo = () => {
    setVideoDimension({ videoWidth: "99vw", videoHeight: "90vh", top: "30px" });
    setCoordinates({ xAxis: 8, yAxis: 58 });
  };

  const minimiseVideo = () => {
    setVideoDimension({ videoWidth: 442, videoHeight: 400 });
  };

  const getLive = async (workspaceId, roomId) => {
    const isLive = await firebase
      .firestore()
      .collection(`sessions/${workspaceId.toLowerCase()}/rooms`)
      .doc(`${roomId.toLowerCase()}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log("LIVE Sessions doc: ", doc.data());
          const { currentSession } = doc.data();
          if (currentSession !== "") {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    return isLive;
  };

  const _handleJoinViaLink = (roomData, inviteData) => {
    if (currentUser) {
      setJoinLoading(true);

      const inviteProcessData = {
        invite_code: newRoom,
        user_id: currentUser.uid,
      };

      postData(`${API_BASE_URL}${INVITE_PROCESS}`, inviteProcessData)
        .then((response) => {
          console.log(response, "response from link");
          const { status, message, data } = response;

          setNewRoomMessage(`You've successfully joined ${roomData.roomName}.`);
          if (status === 200) {
            setJoinLoading(false);
            // setTimeout(() => setNewRoomModalShow(false), 1500);

            Promise.all([
              getWorkspaceData(data.workspace_id),
              getWorkspaceRooms(),
              getLoadedMessages(data.workspace_id, data.room_id),
            ])
              .then((values) => {
                console.log(values, "values in Promise all");
                firebase
                  .firestore()
                  .collection(`workspaces/${data.workspace_id}/rooms`)
                  .doc(`${data.room_id}`)
                  .get()
                  .then((response) => {
                    if (response.exists) {
                      const roomData = response.data();
                      console.log("response.data()", response.data());
                      setRoomData(roomData);
                      setLoadingNewRoomData(false);
                      // load room and workspace into middle.js by adding it to redux store
                      const channelData = { ...roomData, roomId: data.room_id };
                      setCurrentChannel(_.cloneDeep(channelData));
                      getLive(data.workspace_id, data.room_id).then(
                        (isLive) => {
                          if (isLive) {
                            axios
                              .post(`${API_BASE_URL}${MEETING_TYPE}`, {
                                room_id: data.room_id,
                                workspace_id: data.workspace_id,
                                user_id: props.currentUser.uid,
                                email: props.currentUser.email,
                              })
                              .then((res) => {
                                if (res.status === 200) {
                                  setVideoDetails(res.data.data);
                                  setChannelDetails({
                                    ...roomData,
                                    roomId: data.room_id,
                                  });
                                  setIsVideoVisible(true);
                                } else {
                                  console.log(
                                    "Could not get info about video meeting:",
                                    res.status
                                  );
                                }
                              })
                              .catch((error) => {
                                console.log(
                                  "Could not get info about video meeting:",
                                  error
                                );
                              });
                          } else {
                            setTimeout(() => {
                              setNewRoomModalShow(true);
                            }, 1000);
                          }
                        }
                      );
                    }
                  });
              })
              .catch((error) => {
                console.log(error, "error in resolving promise.all");
              });

            props.addRoom(null);
          } else if (
            status === 400 &&
            (message === "Participant already exists" ||
              message === "Request user is already room owner")
          ) {
            setJoinLoading(false);
            // setTimeout(() => setNewRoomModalShow(false), 1500);

            console.log(inviteData, "invitedata");
            Promise.all([
              getWorkspaceData(inviteData.workspaceId),
              getWorkspaceRooms(),
              getLoadedMessages(inviteData.workspaceId, inviteData.roomId),
            ])
              .then((values) => {
                console.log(values, "values in Promise all");
                firebase
                  .firestore()
                  .collection(`workspaces/${inviteData.workspaceId}/rooms`)
                  .doc(`${inviteData.roomId}`)
                  .get()
                  .then((response) => {
                    if (response.exists) {
                      const roomData = response.data();
                      console.log("response.data()", response.data());
                      setRoomData(roomData);
                      setLoadingNewRoomData(false);
                      // load room and workspace into middle.js by adding it to redux store
                      const channelData = {
                        ...roomData,
                        roomId: inviteData.roomId,
                      };
                      setCurrentChannel(_.cloneDeep(channelData));
                      getLive(inviteData.workspaceId, inviteData.roomId).then(
                        (isLive) => {
                          if (isLive) {
                            axios
                              .post(`${API_BASE_URL}${MEETING_TYPE}`, {
                                room_id: inviteData.roomId,
                                workspace_id: inviteData.workspaceId,
                                user_id: props.currentUser.uid,
                                email: props.currentUser.email,
                              })
                              .then((res) => {
                                if (res.status === 200) {
                                  setVideoDetails(res.data.data);
                                  setChannelDetails({
                                    ...roomData,
                                    roomId: inviteData.roomId,
                                  });
                                  setIsVideoVisible(true);
                                } else {
                                  console.log(
                                    "Could not get info about video meeting:",
                                    res.status
                                  );
                                }
                              })
                              .catch((error) => {
                                console.log(
                                  "Could not get info about video meeting:",
                                  error
                                );
                              });
                          }
                        }
                      );

                      // setTimeout(() => {
                      //   setNewRoomModalShow(true);
                      // },1000)
                    }
                  });
              })
              .catch((error) => {
                console.log(error, "error in resolving promise.all");
              });

            props.addRoom(null);
          } else {
            console.log("Something is wrong with the link!");
            let defaultWorkspace = loadState("defaultWorkspace");
            getWorkspaceData(defaultWorkspace?.workspaceId, currentUser.uid);
            setNewRoomMessage("");
            // setShowErrorModal(true);
            // setTimeout(() => setNewRoomModalShow(false), 1500);
            setJoinLoading(false);
            props.addRoom(null);
          }
        })
        .catch((error) => {
          console.error(error);
          // setTimeout(() => setNewRoomModalShow(false), 1500);
          setJoinLoading(false);
          props.addRoom(null);
        });
    }
  };

  const _handleJoinViaLinkPrivate = (roomData, inviteData, status) => {
    if (currentUser) {
      setJoinLoading(true);

      setNewRoomMessage(`You've successfully joined ${roomData.roomName}.`);
      setJoinLoading(false);

      Promise.all([
        getWorkspaceData(inviteData.workspaceId),
        getWorkspaceRooms(),
        getLoadedMessages(inviteData.workspaceId, inviteData.roomId),
      ])
        .then((values) => {
          console.log(values, "values in Promise all");
          firebase
            .firestore()
            .collection(`workspaces/${inviteData.workspaceId}/rooms`)
            .doc(`${inviteData.roomId}`)
            .get()
            .then((response) => {
              if (response.exists) {
                const roomData = response.data();
                console.log("response.data()", response.data());

                // load room and workspace into middle.js by adding it to redux store
                const channelData = { ...roomData, roomId: inviteData.roomId };
                setCurrentChannel(_.cloneDeep(channelData));
                setRoomData(roomData);
                setLoadingNewRoomData(false);
                getLive(inviteData.workspaceId, inviteData.roomId).then(
                  (isLive) => {
                    if (isLive) {
                      axios
                        .post(`${API_BASE_URL}${MEETING_TYPE}`, {
                          room_id: inviteData.roomId,
                          workspace_id: inviteData.workspaceId,
                          user_id: props.currentUser.uid,
                          email: props.currentUser.email,
                        })
                        .then((res) => {
                          if (res.status === 200) {
                            setVideoDetails(res.data.data);
                            setChannelDetails({
                              ...roomData,
                              roomId: inviteData.roomId,
                            });
                            setIsVideoVisible(true);
                          } else {
                            console.log(
                              "Could not get info about video meeting:",
                              res.status
                            );
                          }
                        })
                        .catch((error) => {
                          console.log(
                            "Could not get info about video meeting:",
                            error
                          );
                        });
                    } else if (!status && !isLive) {
                      setTimeout(() => {
                        setNewRoomModalShow(true);
                      }, 1000);
                    }
                  }
                );

                props.addRoom(null);
              }
            });
        })
        .catch((error) => {
          console.log(error, "error in resolving promise.all");
        });
    }
  };

  const getWorkspaceData = async (workspaceId) => {
    let docRef = firebase
      .firestore()
      .collection(`workspaces`)
      .doc(`${workspaceId}`);
    await docRef
      .get()
      .then(function (doc) {
        console.log(workspaceId, "w id");
        if (doc.exists) {
          // console.log("Document data workspace:", doc.data());
          const workspacedata = doc.data();
          console.log(workspacedata, "workspacedata in firestore");
          firebase
            .firestore()
            .collection(`users/${props.currentUser.uid}/rooms`)
            .onSnapshot(function (snapshot) {
              let userWorkspaces = [];
              snapshot.forEach(function (doc) {
                userWorkspaces.push({ ...doc.data() });
              });
              console.log("userWorkspaces in firestore", userWorkspaces[0]);

              let index;

              userWorkspaces.forEach((workspace, key) => {
                if (workspace.workspaceId === workspaceId) {
                  index = key;
                }
              });

              setCurrentWorkspace({
                ...userWorkspaces[index],
                ...workspacedata,
              });
              saveState("defaultWorkspace", {
                ...userWorkspaces[index],
                ...workspacedata,
              });
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const getWorkspaceRooms = async () => {
    await firebase
      .firestore()
      .collection(`users/${props.currentUser.uid}/rooms`)
      .onSnapshot(function (snapshot) {
        let userWorkspaces = [];
        snapshot.forEach(function (doc) {
          userWorkspaces.push({ ...doc.data() });
        });
        console.log("userWorkspaces in firestore", userWorkspaces[0]);
      });
  };
  const getLoadedMessages = async (workspaceId, roomId) => {
    const query = firebase
      .firestore()
      .collection(`/workspaces/${workspaceId}/rooms/${roomId}/messages`)
      .orderBy("timestamp", "desc")
      .limit(12);

    await query.get().then((querySnapshot) => {
      const tempMessages = [];
      querySnapshot.forEach((message) => {
        // console.log(message,'message')
        tempMessages.push({ uid: message.id, ...message.data() });
      });
      console.log(tempMessages, "tempMessages");
      setAllMessages(tempMessages);
      setParticipants(workspaceId, roomId);
    });
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevAllChannels = usePrevious(props.allChannels);

  // useeffect to listen to changes after signup happens

  useEffect(() => {
    if (currentUser) {
      firebase
        .firestore()
        .collection("users")
        .doc(`${currentUser.uid}`)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setUsersDocExists(true);
          } else {
            setUsersDocExists(false);
          }
        });
    }
  }, [currentUser]);

  // useEffect to check if there's currentworkspace in localstorage then setting it as current if not then redirecting to switchworkspace route
  // useEffect(() => {
  //   if (!!currentUser && usersDocExists) {
  //     console.log(!!currentUser, usersDocExists, "in useeffect")
  //     const defaultWorkspace = loadState('defaultWorkspace');
  //     if (defaultWorkspace) {

  //       // fetching data from the default workspaceId cause localstorage and database is not in sync otherwise
  //       getWorkspaceData(defaultWorkspace.workspaceId)

  //     } else {
  //       history.push("/switchworkspace");
  //     }
  //   }

  // }, [currentUser, usersDocExists])

  useEffect(() => {
    console.log(currentUser, "current user changed");

    let defaultWorkspace = loadState("defaultWorkspace");

    if (currentUser) {
      setWorkspaces(currentUser.uid);
    }

    if (newRoom && currentUser && usersDocExists) {
      console.log("New Room in useffect", newRoom);
      setLoadingNewRoomData(true);
      // setNewRoomModalShow(true);
      firebase
        .firestore()
        .collection("invites")
        .doc(`${newRoom}`)
        .get()
        .then((response) => {
          if (response.exists) {
            setNewRoomExists(true);
            const inviteData = response.data();
            console.log(inviteData, "invite data from response data");
            setInviteData(inviteData);

            firebase
              .firestore()
              .collection(`workspaces/${inviteData.workspaceId}/rooms`)
              .doc(`${inviteData.roomId}`)
              .get()
              .then((response) => {
                if (response.exists) {
                  const roomData = response.data();
                  console.log("response.data()", response.data());

                  if (roomData.roomType === 0) {
                    console.log(currentWorkspace, "cw in room join");

                    // load room and workspace into middle.js by adding it to redux store
                    const channelData = {
                      ...roomData,
                      roomId: inviteData.roomId,
                    };
                    setCurrentChannel(_.cloneDeep(channelData));
                    setRoomData(roomData);
                    setLoadingNewRoomData(false);
                    _handleJoinViaLink(roomData, inviteData);
                  } else if (roomData.roomType === 1) {
                    // for private rooms
                    setRoomData(roomData);
                    setLoadingNewRoomData(false);
                    const inviteProcessData = {
                      invite_code: newRoom,
                      user_id: currentUser.uid,
                    };
                    postData(
                      `${API_BASE_URL}${INVITE_PROCESS}`,
                      inviteProcessData
                    ).then((response) => {
                      console.log(response, "response from link in pvt ");
                      const { status, message, data } = response;

                      if (status === 200) {
                        setAdminApprovalModalShow(true);
                        setPrivateRoomLink(true);
                        firebase
                          .firestore()
                          .collection(
                            `workspaces/${inviteData.workspaceId}/rooms`
                          )
                          .doc(`${inviteData.roomId}`)
                          .get()
                          .then((doc) => {
                            if (doc.exists && doc.data().pendingInvites) {
                              const { pendingInvites } = doc.data();
                              setPrevPendingInvites(pendingInvites);
                            }
                          });
                      } else if (
                        status === 400 &&
                        (message === "Participant already exists" ||
                          message === "Request user is already room owner")
                      ) {
                        _handleJoinViaLinkPrivate(roomData, inviteData, status);
                      } else if (
                        status === 400 &&
                        message === "Invite request is already pending"
                      ) {
                        setInviteModalShow(true);
                        setPrivateRoomLink(true);
                        firebase
                          .firestore()
                          .collection(
                            `workspaces/${inviteData.workspaceId}/rooms`
                          )
                          .doc(`${inviteData.roomId}`)
                          .get()
                          .then((doc) => {
                            if (doc.exists && doc.data().pendingInvites) {
                              const { pendingInvites } = doc.data();
                              setPrevPendingInvites(pendingInvites);
                            }
                          });
                      } else if (
                        status === 400 &&
                        message === "Invite Code Expired"
                      ) {
                        // console.log("in else")
                        // setShowErrorModal(true);
                        // let defaultWorkspace = loadState('defaultWorkspace');
                        // getWorkspaceData(defaultWorkspace?.workspaceId, currentUser.uid)
                      }
                    });
                  }
                }
              });
          } else {
            // setShowErrorModal(true);
            console.log("wrong link");
            let defaultWorkspace = loadState("defaultWorkspace");
            getWorkspaceData(defaultWorkspace?.workspaceId, currentUser.uid);
            // setNewRoomModalShow(true);
            setTimeout(() => setNewRoomExists(false), 1000);
            setTimeout(() => {
              setNewRoomModalShow(false);
              props.addRoom(null);
              props.addRoomError(null);
            }, 3500);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [newRoom, currentUser, usersDocExists, currentWorkspace, newRoomJWT]);

  useEffect(() => {
    let defaultWorkspace = loadState("defaultWorkspace");
    if (
      newRoomJWT &&
      currentUser &&
      usersDocExists &&
      defaultWorkspace &&
      currentWorkspace
    ) {
      // setNewRoomModalShow(false);
      // localStorage.removeItem('roomID');
      console.log(
        "changes",
        currentUser,
        usersDocExists,
        currentWorkspace,
        newRoomJWT
      );
      console.log(history, "history");
      axios
        .post(`${API_BASE_URL}${INVITE_JWT_PROCESS}`, {
          invite_token: newRoomJWT,
          user_id: currentUser.uid,
        })
        .then((res) => {
          //console.log(res,"my response Data")
          if (
            res.status === 200 &&
            res.data.message === "Invite processed successfully"
          ) {
            firebase
              .firestore()
              .collection(`workspaces/${res.data.data.workspace_id}/rooms`)
              .doc(`${res.data.data.room_id}`)
              .get()
              .then((response) => {
                if (response.exists) {
                  const roomData = response.data();
                  // load room and workspace into middle.js by adding it to redux store
                  console.log("roomData", roomData);
                  const channelData = {
                    ...roomData,
                    roomId: res.data.data.room_id,
                  };
                  setCurrentChannel(_.cloneDeep(channelData));
                  setRoomData(roomData);
                  setLoadingNewRoomData(false);
                  setNewRoomMessage(
                    `You've successfully joined ${roomData.roomName}.`
                  );
                  setTimeout(() => {
                    setNewRoomModalJWTShow(true);
                  }, 1000);
                  // _handleJoinViaLink(roomData, { workspaceId: res.data.data.workspace_id, roomId: res.data.data.room_id, userId: res.data.data.user_id });
                }
              });
          } else if (
            res.status === 200 &&
            res.data.message === "Participant already exists"
          ) {
            firebase
              .firestore()
              .collection(`workspaces/${res.data.data.workspace_id}/rooms`)
              .doc(`${res.data.data.room_id}`)
              .get()
              .then((response) => {
                if (response.exists) {
                  const roomData = response.data();
                  // load room and workspace into middle.js by adding it to redux store
                  console.log("roomData", roomData);
                  const channelData = {
                    ...roomData,
                    roomId: res.data.data.room_id,
                  };
                  setCurrentChannel(_.cloneDeep(channelData));
                  setRoomData(roomData);
                  setLoadingNewRoomData(false);
                }
              });
          } else if (res.status === 400) {
            //console.log("my modal error : ");
            setShowErrorModal(true);
            setModalError(res.data.data);
          }
        })
        .catch((error) => {
          //console.log("my modal error : ",error.response);
          setModalError(error.response.data.message);
          setShowErrorModal(true);
        });
    }
  }, [currentUser, usersDocExists, currentWorkspace?.workspaceId, newRoomJWT]);

  useEffect(() => {
    if (
      prevAllChannels &&
      prevAllChannels.length > 0 &&
      prevAllChannels.length < props.allChannels.length
    ) {
      console.log(prevAllChannels, props.allChannels, "all channels");
      // let currentInvites ;

      // firebase.firestore().collection(`workspaces/${workspaceId}/rooms`).doc(`${roomId}`).get()
      //     .then((doc) => {
      //         if(doc.exists && doc.data().pendingInvites){
      //             const { pendingInvites } = doc.data();
      //             currentInvites = pendingInvites;
      //         }
      // })

      // currentInvites = _.groupBy(currentInvites, "inviteeUserId");

      // console.log(currentInvites,"currentInvites");

      let userIdInviteWasPending = false;
      prevPendingInvites.forEach((user) => {
        if (user.inviteeUserId === props.currentUser.uid) {
          console.log("uid was/is there");
          userIdInviteWasPending = true;
          _handleJoinViaLinkPrivate(roomData, inviteData);
        }
      });
      if (userIdInviteWasPending) {
        firebase
          .firestore()
          .collection(`workspaces/${inviteData.workspaceId}/rooms`)
          .doc(`${inviteData.roomId}`)
          .get()
          .then((doc) => {
            if (doc.exists && doc.data().pendingInvites) {
              const { pendingInvites } = doc.data();
              setPrevPendingInvites(pendingInvites);
            }
          });

        console.log("true, was pending");
      }
      console.log(prevPendingInvites, "prevPending");
    }
    if (
      prevAllChannels &&
      prevAllChannels.length === 0 &&
      props.allChannels.length === 1
    ) {
      console.log(prevAllChannels, props.allChannels, "all channels");
      // let currentInvites ;

      // firebase.firestore().collection(`workspaces/${workspaceId}/rooms`).doc(`${roomId}`).get()
      //     .then((doc) => {
      //         if(doc.exists && doc.data().pendingInvites){
      //             const { pendingInvites } = doc.data();
      //             currentInvites = pendingInvites;
      //         }
      // })

      // currentInvites = _.groupBy(currentInvites, "inviteeUserId");

      // console.log(currentInvites,"currentInvites");

      let userIdInviteWasPending = false;
      prevPendingInvites.forEach((user) => {
        if (user.inviteeUserId === props.currentUser.uid) {
          console.log("uid was/is there");
          userIdInviteWasPending = true;
          _handleJoinViaLinkPrivate(roomData, inviteData);
        }
      });
      if (userIdInviteWasPending) {
        firebase
          .firestore()
          .collection(`workspaces/${inviteData.workspaceId}/rooms`)
          .doc(`${inviteData.roomId}`)
          .get()
          .then((doc) => {
            if (doc.exists && doc.data().pendingInvites) {
              const { pendingInvites } = doc.data();
              setPrevPendingInvites(pendingInvites);
            }
          });

        console.log("true, was pending");
      }
      console.log(prevPendingInvites, "prevPending");
    }
  }, [props.allChannels]);

  useEffect(() => {
    // to load room list when room join link is private room
    if (privateRoomLink && props.workspaces.length > 0) {
      let defaultWorkspace = loadState("defaultWorkspace");
      if (defaultWorkspace?.workspaceId) {
        getWorkspaceData(defaultWorkspace.workspaceId, currentUser.uid);
        props.addRoom(null);
        setPrivateRoomLink(false);
      } else if (inviteData && inviteData.workspaceId) {
        // if user is already a part of that workspace loading that workspace
        props.workspaces.forEach((workspace) => {
          if (workspace.workspaceId === inviteData.workspaceId) {
            getWorkspaceData(workspace.workspaceId, currentUser.uid);
            props.addRoom(null);
            setPrivateRoomLink(false);
          }
        });
      } else {
        getWorkspaceData(props.workspaces[0].workspaceId, currentUser.uid);
        props.addRoom(null);
        setPrivateRoomLink(false);
      }
    }
  }, [workspaces, privateRoomLink]);

  const linkExpiredHandler = () => {
    // props.addRoom(null);
    // props.addRoomError(null);
    return <p>Oops, Link expired!</p>;
  };

  const noRoomExistsHandler = () => {
    // props.addRoom(null);
    // props.addRoomError(null);
    return <p>Oops, looks like no such room exists!</p>;
  };

  const [leftActionType, setLeftActionType] = useState("");

  return (
    <div id="layout">
      <Left
        key={currentUser && currentUser.uid}
        currentUser={currentUser}
        primaryColor={primaryColor}
        allChannels={allChannels}
        setLeftActionType={setLeftActionType}
        leftActionType={leftActionType}
      />
      <Middle
        key={currentChannel && currentChannel.id}
        // currentChannel={currentChannel}
        currentUser={currentUser}
        isPrivateChannel={isPrivateChannel}
      />
      <Right
        // key={Math.random(100)}
        currentChannel={currentChannel}
        userPosts={userPosts}
        isPrivateChannel={isPrivateChannel}
        currentUser={currentUser}
      />

      {leftActionType === "" && <UserFeedback />}

      {isVideoVisible && (
        <div className="videodnd" style={{ zIndex: 1000 }}>
          <Rnd
            size={{
              width: videoDimension.videoWidth,
              height: videoDimension.videoHeight,
            }}
            position={{ x: coordinates.xAxis, y: coordinates.yAxis }}
            onDragStop={(e, d) => {
              setCoordinates({ xAxis: d.x, yAxis: d.y });
            }}
            onResize={(e, direction, ref, delta, position) => {
              setVideoDimension({
                videoWidth: ref.offsetWidth,
                videoHeight: ref.offsetHeight,
              });
            }}
            default={{
              x: coordinates.xAxis,
              y: coordinates.yAxis,
              width: 640,
              height: 360,
            }}
            minWidth={320}
            minHeight={180}
            bounds="window"
          >
            {/* // <NewWindow
            // features={
            //   {menubar: true,toolbar: true,status: true,location: true}
            // }> */}
            <Video
              channelName={channelDetails.roomName}
              userName={currentUser.displayName}
              userAvatar={currentUser.photoURL}
              channelId={channelDetails}
              maximiseVideo={maximiseVideo}
              minimiseVideo={minimiseVideo}
            />
            {/* // </NewWindow> */}
          </Rnd>
        </div>
      )}

      <Modal
        show={newRoomModalShow}
        onHide={() => setNewRoomModalShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="bg-theme text-theme rounded shadow-lg">
          <h4 className="mb-3">
            Join room{" "}
            <FontAwesomeIcon
              icon={faLink}
              className="ml-2"
              style={{ fontSize: 16 }}
            />
          </h4>
          <Form>
            {newRoomExists ? (
              loadingNewRoomData ? (
                <Spinner animation="border" />
              ) : inviteData.expiryTimestamp > Date.now() ? (
                <div>
                  <div className="d-flex align-items-center py-2 my-2 rounded-pill">
                    <div className="d-inline-block mr-1">
                      <Image
                        width="40"
                        height="40"
                        roundedCircle
                        className="my-2"
                        src={roomData.roomPic}
                        style={{ background: "#FFFFFF" }}
                      />
                    </div>
                    &nbsp;
                    <div className="d-inline-block text-theme small font-weight-bold">
                      {roomData.roomName}
                      <br />
                      <span>
                        {roomData.roomType === 1 && (
                          <FontAwesomeIcon
                            icon={faLock}
                            className="text-success mr-1"
                            style={{ fontSize: 12 }}
                          />
                        )}
                      </span>
                      <span>
                        {roomData.roomType === 1 ? "Private" : "Public"}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                linkExpiredHandler()
              )
            ) : (
              noRoomExistsHandler()
            )}
          </Form>
          {newRoomMessage != "" ? <p>{newRoomMessage}</p> : null}
          <div id="action-buttons" className="mt-3">
            {newRoomMessage != "" ? (
              <Button
                className="mr-3"
                variant="primary"
                onClick={() => {
                  setNewRoomModalShow(false);
                  setNewRoomMessage("");
                  axios
                    .post(`${API_BASE_URL}${MEETING_TYPE}`, {
                      room_id: inviteData.roomId,
                      workspace_id: inviteData.workspaceId,
                      user_id: props.currentUser.uid,
                      email: props.currentUser.email,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        setVideoDetails(res.data.data);
                        setChannelDetails({
                          ...roomData,
                          roomId: inviteData.roomId,
                        });
                        setIsVideoVisible(true);
                      } else {
                        console.log(
                          "Could not get info about video meeting:",
                          res.status
                        );
                      }
                    })
                    .catch((error) => {
                      console.log(
                        "Could not get info about video meeting:",
                        error
                      );
                    });
                }}
                disabled={joinLoading}
              >
                Join Meeting
              </Button>
            ) : null}

            <Button
              variant="secondary"
              onClick={() => {
                setNewRoomModalShow(false);
                setNewRoomMessage("");
              }}
              disabled={joinLoading}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal for public rooms when joined using personal link (JWT) */}

      <Modal
        show={newRoomModalJWTShow}
        onHide={() => setNewRoomModalJWTShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="bg-theme text-theme rounded shadow-lg">
          <div>
            <div className="d-flex align-items-center py-2 my-2 rounded-pill">
              <div className="d-inline-block mr-1">
                <Image
                  width="40"
                  height="40"
                  roundedCircle
                  className="my-2"
                  src={roomData.roomPic}
                  style={{ background: "#FFFFFF" }}
                />
              </div>
              &nbsp;
              <div className="d-inline-block text-theme small font-weight-bold">
                {roomData.roomName}
                <br />
                <span>
                  {roomData.roomType === 1 && (
                    <FontAwesomeIcon
                      icon={faLock}
                      className="text-success mr-1"
                      style={{ fontSize: 12 }}
                    />
                  )}
                </span>
                <span>{roomData.roomType === 1 ? "Private" : "Public"}</span>
              </div>
            </div>
          </div>
          {newRoomMessage != "" ? <p>{newRoomMessage}</p> : null}
          <div id="action-buttons" className="mt-3">
            {newRoomMessage != "" ? (
              <Button
                className="mr-3"
                variant="primary"
                onClick={() => {
                  setNewRoomModalJWTShow(false);
                  setNewRoomMessage("");
                  axios
                    .post(`${API_BASE_URL}${MEETING_TYPE}`, {
                      room_id: inviteData.roomId,
                      workspace_id: inviteData.workspaceId,
                      user_id: props.currentUser.uid,
                      email: props.currentUser.email,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        setVideoDetails(res.data.data);
                        setChannelDetails({
                          ...roomData,
                          roomId: inviteData.roomId,
                        });
                        setIsVideoVisible(true);
                      } else {
                        console.log(
                          "Could not get info about video meeting:",
                          res.status
                        );
                      }
                    })
                    .catch((error) => {
                      console.log(
                        "Could not get info about video meeting:",
                        error
                      );
                    });
                }}
                disabled={joinLoading}
              >
                Join Meeting
              </Button>
            ) : null}
            <Button
              variant="secondary"
              onClick={() => {
                setNewRoomModalJWTShow(false);
                setNewRoomMessage("");
              }}
              disabled={joinLoading}
            >
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Modal for waiting for admin approval */}

      <Modal
        show={adminApprovalModalShow}
        onHide={() => setAdminApprovalModalShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="bg-theme text-theme rounded shadow-lg">
          <div className="mb-3">
            <div className="d-flex align-items-center py-2 my-2 rounded-pill">
              <div className="d-inline-block mr-1">
                <Image
                  width="40"
                  height="40"
                  roundedCircle
                  className="my-2"
                  src={roomData.roomPic}
                  style={{ background: "#FFFFFF" }}
                />
              </div>
              &nbsp;
              <div className="d-inline-block text-theme small font-weight-bold">
                {roomData.roomName}
                <br />
                <span>
                  {roomData.roomType === 1 && (
                    <FontAwesomeIcon
                      icon={faLock}
                      className="text-success mr-1"
                      style={{ fontSize: 12 }}
                    />
                  )}
                </span>
                <span>{roomData.roomType === 1 ? "Private" : "Public"}</span>
              </div>
            </div>
            <span>Waiting for admin to admit you into the room</span>
          </div>
          <Button
            variant="secondary"
            onClick={() => setAdminApprovalModalShow(false)}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>

      {/* Modal for waiting for invite request already pending */}

      <Modal
        show={inviteModalShow}
        onHide={() => setInviteModalShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="bg-theme text-theme rounded shadow-lg">
          <div className="mb-3">
            <div className="d-flex align-items-center py-2 my-2 rounded-pill">
              <div className="d-inline-block mr-1">
                <Image
                  width="40"
                  height="40"
                  roundedCircle
                  className="my-2"
                  src={roomData.roomPic}
                  style={{ background: "#FFFFFF" }}
                />
              </div>
              &nbsp;
              <div className="d-inline-block text-theme small font-weight-bold">
                {roomData.roomName}
                <br />
                <span>
                  {roomData.roomType === 1 && (
                    <FontAwesomeIcon
                      icon={faLock}
                      className="text-success mr-1"
                      style={{ fontSize: 12 }}
                    />
                  )}
                </span>
                <span>{roomData.roomType === 1 ? "Private" : "Public"}</span>
              </div>
            </div>
            <span>Invite request is already pending.</span>
          </div>
          <Button variant="secondary" onClick={() => setInviteModalShow(false)}>
            Close
          </Button>
        </Modal.Body>
      </Modal>

      {/* error modal */}
      <Modal
        show={showErrorModal}
        onHide={() => {
          setShowErrorModal(false);
        }}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body className="bg-theme text-theme rounded shadow-lg">
          <div className="mb-3">
            <div className="d-flex align-items-center py-2 my-2 rounded-pill">
              &nbsp;
              <div className="d-inline-block text-theme font-weight-bold">
                <p>{modalError.substring(0, 30)}</p>
                <p className="text-warning">{modalError.substring(31)}</p>
              </div>
            </div>
          </div>

          <Button
            variant="secondary"
            onClick={() => {
              setShowErrorModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  currentSubscription: state.subscription.currentSubscription,
  currentChannel: state.channel.currentChannel,
  allChannels: state.channel.allChannels,
  isPrivateChannel: state.channel.isPrivateChannel,
  userPosts: state.channel.userPosts,
  primaryColor: state.colors.primaryColor,
  secondaryColor: state.colors.secondaryColor,

  isVideoVisible: state.platform.isVideoVisible,
  channelDetails: state.platform.channelDetails,
  newRoom: state.newRoom.newRoom,
  newRoomJWT: state.newRoom.newRoomJWT,
  currentWorkspace: state.workspace.currentWorkspace,
  workspaces: state.workspace.workspaces,
});

export default connect(mapStateToProps, {
  setIsVideoVisible,
  setVideoDetails,
  setChannelDetails,
  addRoom,
  setWorkspaces,
  setSubscription,
  clearSubscription,
  setCurrentChannel,
  setAllMessages,
  setParticipants,
  resetData,
  setLeftPanel,
  setCurrentWorkspace,
  addRoomError,
})(App);
