import * as actionTypes from "./types";

const initialQuizPlusState = {
  type: 10,
  id: null,
  quizPlusTitle: "",
  questions: [],
  descriptions: [],
  showDescriptionAfterSelecting: [],
  percentageVisibleToParticipants: [],
  questionImages: [],
  questionPreviewImages: [],
  options: [],
  imageOptions: [],
  previewImageOptions: [],
  questionTypes: [],
  randomize: false,
  types: [],
  isNotEditable: false
};

const quiz_plus_reducer = (state = initialQuizPlusState, action) => {
  switch (action.type) {
    case actionTypes.ADD_QUIZ_ID:
      return {
        ...state,
        id: action.payload.id,
      };
    case actionTypes.ADD_QUIZ_PLUS_TITLE:
      return {
        ...state,
        quizPlusTitle: action.payload.quizPlusTitle,
      };
    case actionTypes.ADD_QUIZ_PLUS_QUESTIONS:
      return {
        ...state,
        questions: action.payload.questions,
      };
    case actionTypes.ADD_QUIZ_PLUS_QUESTION_TYPES:
      return {
        ...state,
        questionTypes: action.payload.questionTypes,
      };
    case actionTypes.ADD_QUIZ_PLUS_TYPES:
      return {
        ...state,
        types: action.payload.types,
      };
    case actionTypes.ADD_QUIZ_PLUS_DESCRIPTIONS:
      return {
        ...state,
        descriptions: action.payload.descriptions,
      };
    case actionTypes.ADD_QUIZ_PLUS_OPTIONS:
      return {
        ...state,
        options: action.payload.options,
      };
    case actionTypes.ADD_QUIZ_PLUS_OPTIONS_IMAGES:
      return {
        ...state,
        imageOptions: action.payload.imageOptions,
      };
    case actionTypes.ADD_QUIZ_PLUS_OPTIONS_PREVIEW:
      return {
        ...state,
        previewImageOptions: action.payload.previewImageOptions,
      };
    case actionTypes.ADD_QUIZ_PLUS_QUESTION_IMAGES:
      return {
        ...state,
        questionImages: action.payload.questionImages,
      };
    case actionTypes.ADD_QUIZ_PLUS_QUESTION_PREVIEW_IMAGES:
      return {
        ...state,
        questionPreviewImages: action.payload.questionPreviewImages,
      };
    case actionTypes.SET_QUIZ_PLUS_RANDOMIZE:
      return {
        ...state,
        randomize: action.payload.randomize,
      };
    case actionTypes.SET_QUIZ_PLUS_IS_NOT_EDITABLE:
      return {
        ...state,
        isNotEditable: action.payload.isNotEditable,
      };
    case actionTypes.SET_QUIZ_PLUS_DESCRIPTION_AFTER_SELECTION:
      return {
        ...state,
        showDescriptionAfterSelecting: action.payload.showDescriptionAfterSelecting,
      };
    case actionTypes.SET_QUIZ_PLUS_PERCENTAGE_VISIBLE_TO_PARTICIPANTS:
      return {
        ...state,
        percentageVisibleToParticipants: action.payload.percentageVisibleToParticipants,
      };
    case actionTypes.RESET_QUIZ_PLUS:
      return initialQuizPlusState;
    default:
      return state;
  }
};

export default quiz_plus_reducer;
