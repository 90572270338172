import { combineReducers } from "redux";
import * as actionTypes from "../actions/types";

import assignment_reducer from "../components/Tasks/Assignment/reducers";
import quiz_reducer from "../components/Tasks/Quiz/reducers";
import quiz_plus_reducer from "../components/Tasks/Quiz-Plus/reducers";

import workspace_reducer from "./workspace";

const initialUserState = {
  currentUser: null,
  currentUserData: null,
  isLoading: true,
};

const user_reducer = (state = initialUserState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        currentUser: action.payload.currentUser,
        isLoading: false,
      };
    case actionTypes.SET_USER_DATA:
      return {
        ...state,
        currentUserData: action.payload.currentUserData,
      };
    case actionTypes.CLEAR_USER:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
const initialSubscriptionState = {
  currentSubscription: null,
  isLoading: true,
};

const subscription_reducer = (state = initialSubscriptionState, action) => {
  // console.log(action);
  switch (action.type) {
    case actionTypes.SET_SUBSCRIPTION:
      return {
        currentSubscription: action.payload.currentSubscription,
        isLoading: false,
      };
    case actionTypes.CLEAR_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialChannelState = {
  allChannels: null,
  currentChannel: null,
  isPrivateChannel: false,
  userPosts: null,
  currentChannelMessages: [],
  participantsLoading: true,
  participants: [],
};

const channel_reducer = (state = initialChannelState, action) => {
  switch (action.type) {
    case actionTypes.SET_CHANNELS:
      return {
        ...state,
        allChannels: action.payload.allChannels,
      };
    case actionTypes.SET_CURRENT_CHANNEL:
      return {
        ...state,
        currentChannel: action.payload.currentChannel,
      };
    case actionTypes.SET_CURRENT_CHANNEL_CONFIG:
      return {
        ...state,
        roomConfig: action.payload.roomConfig,
      };
    case actionTypes.SET_PRIVATE_CHANNEL:
      return {
        ...state,
        isPrivateChannel: action.payload.isPrivateChannel,
      };
    case actionTypes.SET_USER_POSTS:
      return {
        ...state,
        userPosts: action.payload.userPosts,
      };
    case actionTypes.SET_CURRENT_CHANNEL_MESSAGES:
      return {
        ...state,
        currentChannelMessages: action.payload.currentChannelMessages,
      };
    case actionTypes.SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.payload.participants,
        participantsLoading: false,
      };
    default:
      return state;
  }
};

const initialNewRoomState = {
  newRoom: null,
  newRoomError: null,
  newRoomJWT: null,
  newRoomJWTError: null,
  newRoomDetails: null
};

const newRoom_reducer = (state = initialNewRoomState, action) => {
  switch (action.type) {
    case actionTypes.SET_NEW_ROOM:
      return {
        ...state,
        newRoom: action.payload.newRoom,
      };
    case actionTypes.SET_NEW_ROOM_ERROR:
      return {
        ...state,
        newRoomError: action.payload.newRoomError,
      };
    case actionTypes.SET_NEW_ROOM_JWT:
      return {
        ...state,
        newRoomJWT: action.payload.newRoomJWT,
      };
    case actionTypes.SET_NEW_ROOM_JWT_ERROR:
      return {
        ...state,
        newRoomJWTError: action.payload.newRoomJWTError,
      };
    case actionTypes.SET_NEW_ROOM_WORKSPACE_DETAILS:
      return {
        ...state,
        newRoomDetails: action.payload.newRoomDetails,
      };
    default:
      return state;
  }
};

const initialColorsState = {
  primaryColor: "#1c1c1c",
  secondaryColor: "#eee",
};

const colors_reducer = (state = initialColorsState, action) => {
  switch (action.type) {
    case actionTypes.SET_COLORS:
      return {
        primaryColor: action.payload.primaryColor,
        secondaryColor: action.payload.secondaryColor,
      };
    default:
      return state;
  }
};

const initialPlatformState = {
  workspaceSlug: null,
  displayLeftPanel: true,
  displayRightPanel: false,
  displayMessageForward: false,
  contentType: null,
  taskType: null,
  taskId: null,
  taskBranch: null,
  showFull: false,
  folder_id: null,
  open_task_id: null,
  open_task_branch: null,
  delete_id: null,
  replyId: "",
  isVideoVisible: false,
  channelDetails: null,
  videoDetails: null,
  isPublisher: false,
  conferenceSession: null,
  darkTheme: false,
  sharing: false,
  editingPublished: false,
  sharedTask: {},
  editing: false,
  editedTask: {},
  editedPublishedTask: {},
  messageForwardArray:[],
  textUnsendMessage: [],
  typedTextMessage: "",
};

const platform_reducer = (state = initialPlatformState, action) => {
  console.log(action.type, action.payload,"workspace Slug")
  switch (action.type) {
    case actionTypes.SET_WORKSPACE_SLUG:
      return {
        ...state,
        workspaceSlug: action.payload.workspaceSlug,
      }
    case actionTypes.SET_SHARING:
      return {
        ...state,
        sharing: action.payload.sharing,
      };
    case actionTypes.SET_EDITING_PUBLISHED:
      return {
        ...state,
        editingPublished: action.payload.editingPublished,
      };
    case actionTypes.SET_SHARED_TASK:
      return {
        ...state,
        sharedTask: { ...action.payload.sharedTask },
      };
    case actionTypes.SET_EDITING:
      return {
        ...state,
        editing: action.payload.editing,
      };
    case actionTypes.SET_EDITED_TASK:
      return {
        ...state,
        editedTask: { ...action.payload.editedTask },
      };
    case actionTypes.SET_EDITED_PUBLISHED_TASK:
      return {
        ...state,
        editedPublishedTask: { ...action.payload.editedPublishedTask },
      };
    case actionTypes.SET_LEFT_PANEL:
      return {
        ...state,
        displayLeftPanel: action.payload.displayLeftPanel,
      };
    case actionTypes.SET_RIGHT_PANEL:
      return {
        ...state,
        displayRightPanel: action.payload.displayRightPanel,
      };
      case actionTypes.SET_MESSAGE_FORWARD:
        return {
          ...state,
          displayMessageForward: action.payload.displayMessageForward,
        };
      case actionTypes.ADD_MESSAGES_FORWARD_ARRAY:
        return {
          ...state,
          messageForwardArray: action.payload.messageForwardArray,
        };
      case actionTypes.SET_TEXT_UNSEND_MESSAGE:
        return {
          ...state,
          textUnsendMessage: action.payload.textUnsendMessage,
        };
      case actionTypes.SET_TYPED_TEXT_MESSAGE:
        return {
          ...state,
          typedTextMessage: action.payload.typedTextMessage,
         };
    case actionTypes.SET_CONTENT_TYPE:
      return {
        ...state,
        contentType: action.payload.contentType,
      };
    case actionTypes.SET_TASK_TYPE:
      return {
        ...state,
        taskType: action.payload.taskType,
      };
    case actionTypes.SET_TASK_ID:
      return {
        ...state,
        taskId: action.payload.taskId,
      };
    case actionTypes.SET_TASK_BRANCH:
      return {
        ...state,
        taskBranch: action.payload.taskBranch,
      };
    case actionTypes.SET_ROOM_DETAILS:
      return {
        ...state,
        showFull: action.payload.showFull,
      };
    case actionTypes.SET_FOLDER:
      return {
        ...state,
        folder_id: action.payload.folder_id,
      };
    case actionTypes.SET_OPEN_TASK_ID:
      return {
        ...state,
        open_task_id: action.payload.open_task_id,
      };
    case actionTypes.SET_OPEN_TASK_BRANCH:
      return {
        ...state,
        open_task_branch: action.payload.open_task_branch,
      };
    case actionTypes.DELETE_TASK:
      return {
        ...state,
        delete_id: action.payload.delete_id,
      };
    case actionTypes.SET_REPLY:
      return {
        ...state,
        replyId: action.payload.replyId,
      };
    case actionTypes.SET_IS_VIDEO_VISIBLE:
      return {
        ...state,
        isVideoVisible: action.payload.isVideoVisible,
      };
    case actionTypes.SET_CHANNEL_DETAILS:
      return {
        ...state,
        channelDetails: action.payload.channelDetails,
      };
    case actionTypes.SET_VIDEO_DETAILS:
      return {
        ...state,
        videoDetails: action.payload.videoDetails,
      }
    case actionTypes.SET_PUBLISHER:
      return {
        ...state,
        isPublisher: action.payload.isPublisher,
      };
    case actionTypes.SET_CONFERENCE_SESSION:
      return {
        ...state,
        conferenceSession: action.payload.conferenceSession,
      };
    case actionTypes.SET_DARK_THEME:
      return {
        ...state,
        darkTheme: action.payload.darkTheme,
      };
    default:
      return state;
  }
};

const initialFolderState = {
  id: null,
  folder_name: null,
  description: null,
  open_folder: false,
  edit_folder: false,
  delete_folder: false,
};

const folder_reducer = (state = initialFolderState, action) => {
  switch (action.type) {
    case actionTypes.ADD_FOLDER_ID:
      return {
        ...state,
        id: action.payload.id,
      };
    case actionTypes.ADD_FOLDER_NAME:
      return {
        ...state,
        folder_name: action.payload.folder_name,
      };
    case actionTypes.ADD_FOLDER_DESCRIPTION:
      return {
        ...state,
        description: action.payload.description,
      };
    case actionTypes.OPEN_FOLDER:
      return {
        ...state,
        open_folder: action.payload.open_folder,
      };
    case actionTypes.EDIT_FOLDER:
      return {
        ...state,
        edit_folder: action.payload.edit_folder,
      };
    case actionTypes.DELETE_FOLDER:
      return {
        ...state,
        delete_folder: action.payload.delete_folder,
      };
    default:
      return state;
  }
};

// const initialAssignmentState = {
//   type: "Assignment",
//   id: null,
//   questions: [],
//   descriptions: [],
//   totalWeight: 0
// }

// const assignment_reducer = (state = initialAssignmentState, action) => {
//   switch (action.type) {
//     case actionTypes.ADD_ASSIGNMENT_ID:
//       return {
//         ...state,
//         id: action.payload.id
//       }
//     case actionTypes.ADD_ASSIGNMENT_QUESTIONS:
//       return {
//         ...state,
//         questions: action.payload.questions
//       }
//     case actionTypes.ADD_ASSIGNMENT_DESCRIPTIONS:
//       return {
//         ...state,
//         descriptions: action.payload.descriptions
//       }
//     case actionTypes.ADD_ASSIGNMENT_TOTAL_WEIGHT:
//       return {
//         ...state,
//         totalWeight: action.payload.totalWeight
//       }
//     case actionTypes.RESET_ASSIGNMENT:
//       return initialAssignmentState;
//     default:
//       return state;
//   }
// }

const initialPollState = {
  // type: "Poll",
  type: 3,
  id: null,
  title: "",
  options: [],
  images: [],
  previewImages: [],
  files: [],
  filesData: [],
};

const poll_reducer = (state = initialPollState, action) => {
  switch (action.type) {
    case actionTypes.ADD_POLL_ID:
      return {
        ...state,
        id: action.payload.id,
      };
    case actionTypes.ADD_POLL_TITLE:
      return {
        ...state,
        title: action.payload.title,
      };
    case actionTypes.ADD_POLL_OPTIONS:
      return {
        ...state,
        options: action.payload.options,
      };
    case actionTypes.ADD_POLL_OPTIONS_IMAGES:
      return {
        ...state,
        images: action.payload.images,
      };
    case actionTypes.ADD_POLL_PREVIEW_IMAGES:
      return {
        ...state,
        previewImages: action.payload.previewImages,
      };
    case actionTypes.ADD_POLL_OPTIONS_FILES:
      return {
        ...state,
        files: action.payload.files,
      };
    case actionTypes.ADD_POLL_OPTIONS_FILES_DATA:
      return {
        ...state,
        filesData: action.payload.filesData,
      };
    case actionTypes.RESET_POLL:
      return initialPollState;
    default:
      return state;
  }
};

const initialScheduleState = {
  // type: "Schedule",
  type: 1,
  id: null,
  scheduleTitle: "",
  options: ["Yes", "No", "Maybe"],
  durationHours: 0,
  durationMinutes: 30,
  place: "",
  agenda: "",
};

const schedule_reducer = (state = initialScheduleState, action) => {
  switch (action.type) {
    case actionTypes.ADD_SCHEDULE_ID:
      return {
        ...state,
        id: action.payload.id,
      };
    case actionTypes.ADD_SCHEDULE_TITLE:
      return {
        ...state,
        scheduleTitle: action.payload.scheduleTitle,
      };
    case actionTypes.ADD_SCHEDULE_DURATION_HOURS:
      return {
        ...state,
        durationHours: action.payload.durationHours,
      };
    case actionTypes.ADD_SCHEDULE_DURATION_MINUTES:
      return {
        ...state,
        durationMinutes: action.payload.durationMinutes,
      };
    case actionTypes.ADD_SCHEDULE_PLACE:
      return {
        ...state,
        place: action.payload.place,
      };
    case actionTypes.ADD_SCHEDULE_AGENDA:
      return {
        ...state,
        agenda: action.payload.agenda,
      };
    case actionTypes.RESET_SCHEDULE:
      return initialScheduleState;
    default:
      return state;
  }
};

// const initialQuizState = {
//   type: "Quiz",
//   id: null,
//   quizTitle: "",
//   questions: [],
//   options: [],
//   correctAnswers: [],
//   weights: [],
//   graded: false,
//   totalWeight: 0
// }

// const quiz_reducer = (state = initialQuizState, action) => {
//   switch (action.type) {
//     case actionTypes.ADD_QUIZ_ID:
//       return {
//         ...state,
//         id: action.payload.id
//       }
//     case actionTypes.ADD_QUIZ_TITLE:
//       return {
//         ...state,
//         quizTitle: action.payload.quizTitle
//       }
//     case actionTypes.ADD_QUIZ_QUESTIONS:
//       return {
//         ...state,
//         questions: action.payload.questions
//       }
//     case actionTypes.ADD_QUIZ_OPTIONS:
//       return {
//         ...state,
//         options: action.payload.options
//       }
//     case actionTypes.ADD_QUIZ_CORRECT_ANSWERS:
//       return {
//         ...state,
//         correctAnswers: action.payload.correctAnswers
//       }
//     case actionTypes.ADD_QUIZ_WEIGHTS:
//       return {
//         ...state,
//         weights: action.payload.weights
//       }
//     case actionTypes.SET_QUIZ_GRADED:
//       return {
//         ...state,
//         graded: action.payload.graded
//       }
//     case actionTypes.ADD_QUIZ_TOTAL_WEIGHT:
//       return {
//         ...state,
//         totalWeight: action.payload.totalWeight
//       }
//     case actionTypes.RESET_QUIZ:
//       return initialQuizState;
//     default:
//       return state;
//   }
// }

const initialReadingState = {
  type: 2,
  id: null,
  titles: [],
  links: [],
  files: [],
  urls: [],
};

const reading_reducer = (state = initialReadingState, action) => {
  switch (action.type) {
    case actionTypes.ADD_READING_ID:
      return {
        ...state,
        id: action.payload.id,
      };
    case actionTypes.ADD_READING_TITLES:
      return {
        ...state,
        titles: action.payload.titles,
      };
    case actionTypes.ADD_READING_LINKS:
      return {
        ...state,
        links: action.payload.links,
      };
    case actionTypes.ADD_READING_FILES:
      return {
        ...state,
        files: action.payload.files,
      };
    case actionTypes.ADD_READING_URLS:
      return {
        ...state,
        urls: action.payload.urls,
      };
    case actionTypes.RESET_READING:
      return initialReadingState;
    default:
      return state;
  }
};

const initialTaskState = {
  deadline: new Date(Date.now() + 10 * 60000),
  folder: "",
  old_folder: "",
  everyone: false,
  channelUsers: [],
  //sharing: false,
};

const task_reducer = (state = initialTaskState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TASK_CHANNEL_USERS:
      return {
        ...state,
        channelUsers: action.payload.channelUsers,
      };
    case actionTypes.ADD_TASK_DEADLINE:
      return {
        ...state,
        deadline: action.payload.deadline,
      };
    case actionTypes.ADD_TASK_FOLDER:
      return {
        ...state,
        folder: action.payload.folder,
      };
    case actionTypes.ADD_TASK_OLD_FOLDER:
      return {
        ...state,
        old_folder: action.payload.old_folder,
      };
    case actionTypes.ADD_TASK_EVERYONE:
      return {
        ...state,
        everyone: action.payload.everyone,
      };

    case actionTypes.RESET_TASK:
      return initialTaskState;
    default:
      return state;
  }
};

const initialNoteAppState = {
  showNoteApp : false
}

const note_app_reducer = (state = initialNoteAppState, action) => {
    switch (action.type) {
      case actionTypes.SET_SHOW_NOTEAPP:
      return {
        ...state,
        showNoteApp: action.payload.showNoteApp,
      };
      default:
        return state;
    }
  }


const initialWhiteboardState = {
  showWhiteboard: false,
  layers: [],
  background: "",
};



const whiteboard_reducer = (state = initialWhiteboardState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHOW_WHITEBOARD:
      return {
        ...state,
        showWhiteboard: action.payload.showWhiteboard,
      };
    case actionTypes.SAVE_WHITEBOARD_LAYERS:
      return {
        ...state,
        layers: action.payload.layers,
      };
    case actionTypes.SAVE_WHITEBOARD_BACKGROUND:
      return {
        ...state,
        background: action.payload.background,
      };
    default:
      return state;
  }
};

const initialCaseSheetState = {
  showCaseSheet: false,
  showPRF: false,
};
// showPRF means show patient registraion form


const caseSheet_reducer = (state = initialCaseSheetState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHOW_CASESHEET:
      return {
        ...state,
        showCaseSheet: action.payload.showCaseSheet,
      };
      case actionTypes.SET_SHOW_PRF:
      return {
        ...state,
        showPRF: action.payload.showPRF,
      };
      default:
        return state;
    }
  };
        
const initialPrescriptionState = {
  showPrescription: false,
  prescriptionEditId: null
};

const prescription_reducer = (state = initialPrescriptionState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHOW_PRESCRIPTION:
      return {
        ...state,
        showPrescription: action.payload.showPrescription,
      };
    case actionTypes.SET_PRESCRIPTION_EDIT_ID:
      return {
        ...state,
        prescriptionEditId: action.payload.prescriptionEditId,
      };
    default:
      return state;
  }
};

const initialGradebookState = {
  showGradebook: false,
};

const gradebook_reducer = (state = initialGradebookState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHOW_GRADEBOOK:
      return {
        ...state,
        showGradebook: action.payload.showGradebook,
      };
    default:
      return state;
  }
};

const initialAttendanceLedgerState = {
  showAttendanceLedger: false,
};

const attendanceLedger_reducer = (
  state = initialAttendanceLedgerState,
  action
) => {
  switch (action.type) {
    case actionTypes.SET_SHOW_ATTENDANCE_LEDGER:
      return {
        ...state,
        showAttendanceLedger: action.payload.showAttendanceLedger,
      };
    default:
      return state;
  }
};
const initialUploadFilesState = {
  showUploadFilesModal: false,
};

const uploadFiles_reducer = (state = initialUploadFilesState, action) => {
  //console.log(action, "action inside reducer");
  switch (action.type) {
    case actionTypes.SET_SHOW_UPLOAD_FILES_MODAL:
      return {
        ...state,
        showUploadFilesModal: action.payload.showUploadFilesModal,
      };
    default:
      return state;
  }
};
const initialCreateFolderState = {
  showCreateFolderModal: false,
};

const createFolder_reducer = (state = initialCreateFolderState, action) => {
  switch (action.type) {
    case actionTypes.SET_SHOW_CREATE_FOLDER_MODAL:
      return {
        ...state,
        showCreateFolderModal: action.payload.showCreateFolderModal,
      };
    default:
      return state;
  }
};
const initialSelectedFolderState = {
  id: null,
};

const selectedFolder_reducer = (state = initialSelectedFolderState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_FOLDER_ID:
      return {
        ...state,
        id: action.payload.id,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: user_reducer,
  subscription: subscription_reducer,
  newRoom: newRoom_reducer,
  channel: channel_reducer,
  colors: colors_reducer,

  platform: platform_reducer,

  folder: folder_reducer,
  assignment: assignment_reducer,
  poll: poll_reducer,
  schedule: schedule_reducer,
  quiz: quiz_reducer,
  quizPlus: quiz_plus_reducer,
  reading: reading_reducer,
  task: task_reducer,

  whiteboard: whiteboard_reducer,
  caseSheet: caseSheet_reducer,
  noteApp: note_app_reducer,
  prescription: prescription_reducer,

  gradebook: gradebook_reducer,

  attendanceLedger: attendanceLedger_reducer,
  uploadFilesModal: uploadFiles_reducer,
  createFolderModal: createFolder_reducer,
  selectedFolder: selectedFolder_reducer,

  workspace: workspace_reducer,
});

export default rootReducer;
