import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { connect } from "react-redux";
import moment from "moment";

import {
  Card,
  Spinner,
  Table,
  Accordion,
  Tabs,
  Tab,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { setShowAttendanceLedger } from "../../actions/index";
import axios from "axios";
import LineChart from "./lineChart";
import SessionsChart from "./timeChart";
import _ from "lodash";
import Participant from "../Right/UserNameAvatar";
import {
  API_BASE_URL,
  REPORTS_ROOM_SESSIONS,
  DOWNLOAD_SESSION,
  DOWNLOAD_SESSIONS,
} from "../../config/index";
import momentDurationFormatSetup from 'moment-duration-format';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

momentDurationFormatSetup(moment);


const getUserRole = (channelUsers, userId) => {
  if (channelUsers[userId]["admin"]) {
    return "Moderator";
  } else {
    return "Participant";
  }
};

const getParticipationPercent = (
  userJoinedAtTimestamp,
  userLeftAtTimestamp,
  sessionStartTimestamp,
  sessionEndTimestamp
) => {
  return Math.round(
    ((userLeftAtTimestamp - userJoinedAtTimestamp) /
      (sessionEndTimestamp - sessionStartTimestamp)) *
      100
  );
};

function ParticipantsList(props) {
  const [loadingList, setLoadingList] = useState(true);
  const [participantList, setparticipantList] = useState([]);

  useEffect(() => {
    const sessionRef = firebase
      .firestore()
      .collection(
        `attendanceLedger/${props.channelId}/sessions/${props.sessionId}/participants`
      );
    sessionRef.get().then((sessionQS) => {
      let temp_participantList = [];
      sessionQS.forEach((participantDoc) => {
        temp_participantList.push(participantDoc.data());
      });
      setparticipantList(temp_participantList);
      setLoadingList(false);
    });
  }, []);

  return (
    <Table hover size="sm" className="mb-0">
      {/* <p>{JSON.stringify(participantList)}</p> */}
      <thead>
        <th>Participant</th>
        <th>Role</th>
        <th>Duration</th>
        <th>Start</th>
        <th>End</th>
        <th>Participation</th>
      </thead>
      <tbody>
        {loadingList ? (
          <Spinner size="sm" variant="secondary" animation="border" />
        ) : (
          participantList.map((item) => (
            <tr>
              <td>{getSessionAdmin(item.userDetails)}</td>
              <td>
                {getUserRole(props.channelUsers, item.userDetails.userId)}
              </td>
              <td>
                {getSessionDuration(
                  item.joinedAtTimestamp,
                  item.leftAtTimestamp
                )}
              </td>
              <td>{getSessionTime(item.joinedAtTimestamp)}</td>
              <td>{getSessionTime(item.leftAtTimestamp)}</td>
              <td>
                {getParticipationPercent(
                  item.joinedAtTimestamp,
                  item.leftAtTimestamp,
                  props.sessionStartTimestamp,
                  props.sessionEndTimestamp
                )}
                %
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
}

const getSessionTime = (timestamp) => {
  if (timestamp) return moment(timestamp).format("hh:mm A");
  else return "-";
};

const getSessionDate = (timestamp) => {
  if (timestamp)
    if (
      moment(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).format(
        "MMM DD, YYYY"
      )
    )
      return moment(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
      ).format("MMM DD, YYYY");
    else return "-";
};

const getSessionDuration = (totalDuration) => {
  //console.log(totalDuration, "totalDuration");
  if (totalDuration) {
    /* const startTimestampMilliseconds =
      startTimestamp.seconds * 1000 + startTimestamp.nanoseconds / 1000000;
    const endTimestampMilliseconds =
      endTimestamp.seconds * 1000 + endTimestamp.nanoseconds / 1000000;
    const totalDuration = endTimestampMilliseconds - startTimestampMilliseconds;*/
    if (moment.utc(totalDuration).format("HH") > 0) {
      return (
        moment.utc(totalDuration).format("HH") +
        " hour " +
        moment.utc(totalDuration).format("mm") +
        " minutes"
        // + moment.utc(totalDuration).format("ss") +
        // ""
      );
    } else {
      if (moment.utc(totalDuration).format("mm") > 0) {
        return (
          moment.utc(totalDuration).format("mm") + " minutes"
          // + moment.utc(totalDuration).format("ss") +
          // ""
        );
      } else {
        return moment.utc(totalDuration).format("ss") + " seconds";
      }
    }
  } else {
    return "Currently Live/ Calculating...";
  }
};

const getSessionAdmin = (userData) => {
  return userData.userName;
};

function UserAvatar({ userId }) {
  const [avatar, setAvatar] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData(firebase.database().ref("users").child(userId))
      .then((value) => {
        setAvatar(value.avatar);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("User not found, ", error);
        setAvatar(null);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Spinner size="sm" animation="border" />;
  }

  if (avatar) {
    return (
      <img
        className="rounded-circle"
        src={avatar}
        style={{ height: 50, width: 50 }}
      />
    );
  }

  return (
    <img
      className="rounded-circle"
      src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822"
      style={{ height: 50, width: 50 }}
    />
  );
}

const getData = (ref) => {
  return new Promise((resolve, reject) => {
    const onError = (error) => reject(error);
    const onData = (snap) => resolve(snap.val());

    ref.on("value", onData, onError);
  });
};

function UserName({ userId }) {
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData(firebase.database().ref("users").child(userId))
      .then((value) => {
        setName(value.name);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("User not found:", error);
        setName(null);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Spinner size="sm" animation="border" />;
  }

  if (name) {
    return name;
  }
}

function DayAnalyticAccordion(props) {
  const [loadingData, setLoadingData] = useState(true);
  const [sessionParticipants, setSessionParticipants] = useState({});

  const getSessionParticipantsCount = (sessionId) => {
    if (sessionParticipants[sessionId])
      return sessionParticipants[sessionId].length;
  };

  const getSessionParticipationPercent = (sessionData) => {
    if (sessionParticipants[sessionData["id"]]) {
      let totalParticipationPercent = 0;
      sessionParticipants[sessionData["id"]].forEach((participant) => {
        if (
          participant["joinedAtTimestamp"] &&
          participant["leftAtTimestamp"]
        ) {
          totalParticipationPercent += getParticipationPercent(
            participant["joinedAtTimestamp"],
            participant["leftAtTimestamp"],
            sessionData["startTimestamp"],
            sessionData["endTimestamp"]
          );
        }
      });
      return Math.round(
        totalParticipationPercent /
          sessionParticipants[sessionData["id"]].length
      );
    }
  };

  useEffect(() => {
    async function getParticipantData() {
      await Object.entries(props.dayData).forEach(async (dayEntry, index) => {
        await dayEntry.forEach(async (session, i) => {
          if (i == 1) {
            const sessionRef = firebase
              .firestore()
              .collection(
                `attendanceLedger/${props.channelData.id}/sessions/${session.id}/participants`
              );
            await sessionRef.get().then(async (sessionQS) => {
              await sessionQS.forEach((participantDoc) => {
                let tempParticipantData = [];
                sessionQS.forEach((participantDoc) => {
                  tempParticipantData.push(participantDoc.data());
                });
                const sessionId = session.id;
                setSessionParticipants((prevState) => ({
                  ...prevState,
                  [sessionId]: tempParticipantData,
                }));
              });
            });
          }
        });
      });
      setLoadingData(false);
    }

    getParticipantData();
  }, []);

  const createTable = () => {
    let table = [];

    Object.entries(props.dayData).forEach((session) => {
      let children = [];
      children.push(<td>{session[1]["startedBy"]["userName"]}</td>);
      children.push(
        <td>
          {getSessionDuration(
            session[1]["startTimestamp"],
            session[1]["endTimestamp"]
          )}
        </td>
      );
      children.push(<td>{getSessionTime(session[1]["startTimestamp"])}</td>);
      children.push(<td>{getSessionTime(session[1]["endTimestamp"])}</td>);
      children.push(<td>{getSessionParticipationPercent(session[1])}%</td>);
      children.push(<td>{getSessionParticipantsCount(session[1]["id"])}</td>);

      table.push(<tr>{children}</tr>);
    });

    return table;
  };

  return (
    <Table hover size="sm" className="mb-0">
      <thead>
        <th>Started By</th>
        <th>Duration</th>
        <th>Start</th>
        <th>End</th>
        <th>Participation</th>
        <th>Participants</th>
      </thead>

      <tbody>
        {loadingData ? (
          <Spinner size="sm" variant="secondary" animation="border" />
        ) : (
          createTable()
        )}
      </tbody>
    </Table>
  );
}

function DayAnalytic(props) {
  const [dayData, setDayData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [participantsData, setParticipantsData] = useState({});

  const getAverageSessionDuration = (sessions) => {
    let totalMilliseconds = 0;
    sessions.forEach((item) => {
      if (item.session_duration) {
        totalMilliseconds += item.session_duration;
      }
    });
    const avgMilliseconds = totalMilliseconds / sessions.length;
    if (moment.utc(avgMilliseconds).format("HH") > 0) {
      return (
        moment.utc(avgMilliseconds).format("HH") +
        " hrs " +
        moment.utc(avgMilliseconds).format("mm") +
        " mins " +
        moment.utc(avgMilliseconds).format("ss") +
        " sec"
      );
    } else {
      return (
        moment.utc(avgMilliseconds).format("mm") +
        " mins " +
        moment.utc(avgMilliseconds).format("ss") +
        " sec"
      );
    }
  };

  const getTotalSessionDuration = (sessions) => {
    let totalMilliseconds = 0;
    sessions.forEach((item) => {
      if (item.session_duration) {
        totalMilliseconds += item.session_duration;
      }
    });
    if (moment.utc(totalMilliseconds).format("HH") > 0) {
      return (
        moment.utc(totalMilliseconds).format("HH") +
        " hrs " +
        moment.utc(totalMilliseconds).format("mm") +
        " mins " +
        moment.utc(totalMilliseconds).format("ss") +
        " sec"
      );
    } else {
      return (
        moment.utc(totalMilliseconds).format("mm") +
        " mins " +
        moment.utc(totalMilliseconds).format("ss") +
        " sec"
      );
    }
  };

  const getAverageParticipationPercent = (sessions) => {
    let totalParticipationPercent = 0;
    sessions.forEach((item) => {
      if (participantsData[item.id]) {
        let totalSessionParticipationPercent = 0;
        participantsData[item.id].forEach((participant) => {
          if (participant) {
            if (participant.joinedAtTimestamp && participant.leftAtTimestamp) {
              totalSessionParticipationPercent += getParticipationPercent(
                participant.joinedAtTimestamp,
                participant.leftAtTimestamp,
                item.startTimestamp,
                item.endTimestamp
              );
            }
          }
        });
        totalParticipationPercent +=
          totalSessionParticipationPercent / participantsData[item.id].length;
      }
    });
    return Math.round(totalParticipationPercent / sessions.length);
  };

  useEffect(() => {
    let flags = {};
    let perDaySessionData = {};
    let newSessionData = props.sessionData.map((session) => {
      session["localDateString"] = new Date(
        session["startTimestamp"]["seconds"] * 1000 +
          session["startTimestamp"]["nanoseconds"] / 1000000
      ).toLocaleDateString();
      return session;
    });
    newSessionData.forEach((session) => {
      if (!flags[session["localDateString"]]) {
        flags[session["localDateString"]] = true;
        perDaySessionData[session["localDateString"]] = [session];
      } else {
        perDaySessionData[session["localDateString"]].push(session);
      }
    });
    let orderedPerDaySessionData = {};
    Object.keys(perDaySessionData)
      .sort(function (a, b) {
        return (
          moment(b, "DD/MM/YYYY").toDate() - moment(a, "DD/MM/YYYY").toDate()
        );
      })
      .forEach(function (key) {
        orderedPerDaySessionData[key] = perDaySessionData[key];
      });

    Object.entries(orderedPerDaySessionData).forEach((dayEntry, index) => {
      dayEntry[1].forEach((session) => {
        const sessionRef = firebase
          .firestore()
          .collection(
            `attendanceLedger/${props.channelData.id}/sessions/${session.id}/participants`
          );
        sessionRef.get().then((sessionQS) => {
          sessionQS.forEach((participantDoc) => {
            let tempParticipantData = [];
            sessionQS.forEach((participantDoc) => {
              tempParticipantData.push(participantDoc.data());
            });
            const sessionId = session.id;
            setParticipantsData((prevState) => ({
              ...prevState,
              [sessionId]: tempParticipantData,
            }));
          });
        });
      });
    });

    setDayData(orderedPerDaySessionData);
    setIsLoading(false);
  }, []);

  return (
    <>
      <Table hover size="sm" className="mb-0 mt-3">
        <thead>
          <th>Date</th>
          <th>Sessions</th>
          <th>Avg. Duration</th>
          <th>Avg. Participation</th>
        </thead>
      </Table>
      {!isLoading && (
        <div style={{ overflowY: "scroll", display: "block" }}>
          {Object.entries(dayData).map((dayEntry, index) => (
            <Accordion className="border-0">
              <Card className="border-0 shadow-sm mb-3 mx-1">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <Table hover size="sm" className="mb-0">
                    <tbody>
                      <tr>
                        <td>
                          {getSessionDate(dayEntry[1][0]["startTimestamp"])}
                        </td>
                        <td>{dayEntry[1].length}</td>
                        <td>{getAverageSessionDuration(dayEntry[1])}</td>
                        <td>{getAverageParticipationPercent(dayEntry[1])}%</td>
                      </tr>
                    </tbody>
                  </Table>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0">
                  <DayAnalyticAccordion
                    sessionData={props.sessionData}
                    channelData={props.channelData}
                    dayData={dayEntry[1]}
                  />
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ))}
        </div>
      )}
    </>
  );
}

function ParticipantAnalyticAccordion(props) {
  const [loadingData, setLoadingData] = useState(true);
  const [participantSessionData, setParticipantSessionData] = useState({});

  useEffect(() => {
    props.sessionData.forEach((sessionEntry) => {
      const sessionRef = firebase
        .firestore()
        .collection(
          `attendanceLedger/${props.channelData.id}/sessions/${sessionEntry.id}/participants`
        );
      sessionRef.get().then((sessionQS) => {
        sessionQS.forEach((participantDoc) => {
          if (participantDoc.data().userDetails.userId === props.userData[0]) {
            setParticipantSessionData((prevState) => ({
              ...prevState,
              [sessionEntry.id]: {
                sessionAdmin: sessionEntry["startedBy"]["userName"],
                sessionDuration: getSessionDuration(
                  sessionEntry["startTimestamp"],
                  sessionEntry["endTimestamp"]
                ),
                participationDuration: getSessionDuration(
                  participantDoc.data().joinedAtTimestamp,
                  participantDoc.data().leftAtTimestamp
                ),
                sessionStart: getSessionTime(sessionEntry["startTimestamp"]),
                sessionEnd: getSessionTime(sessionEntry["endTimestamp"]),
                participationPercent: Math.round(
                  ((participantDoc.data().leftAtTimestamp -
                    participantDoc.data().joinedAtTimestamp) /
                    (sessionEntry["endTimestamp"] -
                      sessionEntry["startTimestamp"])) *
                    100
                ),
              },
            }));
          }
        });
      });
    });
    setLoadingData(false);
  }, []);

  return (
    <Table hover size="sm" className="mb-0">
      <thead>
        <th>Started By</th>
        <th>Session Duration</th>
        <th>Participation Duration</th>
        <th>Start</th>
        <th>End</th>
        <th>Participation</th>
      </thead>

      <tbody>
        {loadingData ? (
          <Spinner size="sm" variant="secondary" animation="border" />
        ) : (
          Object.entries(participantSessionData).map(
            (participantSession, index) => (
              <tr>
                <td>{participantSession[1]["sessionAdmin"]}</td>
                <td>{participantSession[1]["sessionDuration"]}</td>
                <td>{participantSession[1]["participationDuration"]}</td>
                <td>{participantSession[1]["sessionStart"]}</td>
                <td>{participantSession[1]["sessionEnd"]}</td>
                <td>{participantSession[1]["participationPercent"]}%</td>
              </tr>
            )
          )
        )}
      </tbody>
    </Table>
  );
}

function ParticipantAnalytic(props) {
  const [loadingData, setLoadingData] = useState(true);
  const [participantData, setParticipantData] = useState({});

  const getUserSessions = (userId) => {
    let sessionCount = 0;
    Object.entries(participantData).forEach((data) => {
      data[1].forEach((userData) => {
        if (userData.userDetails["userId"] === userId) sessionCount += 1;
      });
    });
    return sessionCount;
  };

  const getAvgUserSessionStats = (userId, sessionData) => {
    let totalMilliseconds = 0;
    let sessionCount = 0;
    let totalSessionMilliseconds = 0;
    Object.entries(participantData).forEach((data) => {
      data[1].forEach((userData) => {
        if (userData.userDetails["userId"] === userId) {
          sessionCount += 1;
          if (userData["joinedAtTimestamp"] && userData["leftAtTimestamp"]) {
            const joinedAtTimestampMilliseconds =
              userData["joinedAtTimestamp"].seconds * 1000 +
              userData["joinedAtTimestamp"].nanoseconds / 1000000;
            const leftAtTimestampMilliseconds =
              userData["leftAtTimestamp"].seconds * 1000 +
              userData["leftAtTimestamp"].nanoseconds / 1000000;
            totalMilliseconds +=
              leftAtTimestampMilliseconds - joinedAtTimestampMilliseconds;
          }
          sessionData.forEach((session) => {
            if (session["id"] === data[0]) {
              if (session["startTimestamp"] && session["endTimestamp"]) {
                const startTimestampMilliseconds =
                  session["startTimestamp"].seconds * 1000 +
                  session["startTimestamp"].nanoseconds / 1000000;
                const endTimestampMilliseconds =
                  session["endTimestamp"].seconds * 1000 +
                  session["endTimestamp"].nanoseconds / 1000000;
                totalSessionMilliseconds +=
                  endTimestampMilliseconds - startTimestampMilliseconds;
              }
            }
          });
        }
      });
    });
    const avgMilliseconds = totalMilliseconds / sessionCount;
    const avgSessionMilliseconds = totalSessionMilliseconds / sessionCount;

    if (sessionCount === 0) {
      return ["00:00:00", "0"];
    }

    if (moment.utc(avgMilliseconds).format("HH") > 0) {
      return [
        moment.utc(avgMilliseconds).format("HH") +
          " hrs " +
          moment.utc(avgMilliseconds).format("mm") +
          " mins " +
          moment.utc(avgMilliseconds).format("ss") +
          " sec",
        Math.round((avgMilliseconds / avgSessionMilliseconds) * 100),
      ];
    } else {
      return [
        moment.utc(avgMilliseconds).format("mm") +
          " mins " +
          moment.utc(avgMilliseconds).format("ss") +
          " sec",
        Math.round((avgMilliseconds / avgSessionMilliseconds) * 100),
      ];
    }
  };

  async function loadParticipantData() {
    await props.sessionData.forEach(async (sessionEntry) => {
      const sessionRef = firebase
        .firestore()
        .collection(
          `attendanceLedger/${props.channelData.id}/sessions/${sessionEntry.id}/participants`
        );
      await sessionRef.get().then((sessionQS) => {
        let tempParticipantData = [];
        sessionQS.forEach((participantDoc) => {
          tempParticipantData.push(participantDoc.data());
        });
        const sessionId = sessionEntry.id;
        setParticipantData((prevState) => ({
          ...prevState,
          [sessionId]: tempParticipantData,
        }));
      });
    });
    setLoadingData(false);
  }

  useEffect(() => {
    loadParticipantData();
  }, []);

  return (
    <>
      <Table hover size="sm" className="mb-0 mt-3">
        <thead>
          <th>Participant</th>
          <th>Role</th>
          <th>Sessions attended</th>
          <th>Avg. Duration of Sessions attended</th>
          <th>Avg. Duration of Participation</th>
          <th>Avg. Participation</th>
        </thead>
      </Table>
      {!loadingData && (
        <div style={{ overflowY: "scroll", display: "block" }}>
          {props.channelData.users &&
            Object.entries(props.channelData.users).map((userEntry, index) => (
              <Accordion className="border-0">
                <Card className="border-0 shadow-sm mb-3 mx-1">
                  <Accordion.Toggle as={Card.Header} eventKey="0">
                    <Table hover size="sm" className="mb-0">
                      <tbody>
                        <tr>
                          <UserName userId={userEntry[0]} />
                          <td>
                            {getUserRole(props.channelData.users, userEntry[0])}
                          </td>
                          <td>{getUserSessions(userEntry[0])}</td>
                          <td>
                            {
                              getAvgUserSessionStats(
                                userEntry[0],
                                props.sessionData
                              )[0]
                            }
                          </td>
                          <td>
                            {
                              getAvgUserSessionStats(
                                userEntry[0],
                                props.sessionData
                              )[0]
                            }
                          </td>
                          <td>
                            {
                              getAvgUserSessionStats(
                                userEntry[0],
                                props.sessionData
                              )[1]
                            }
                            %
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="0">
                    <ParticipantAnalyticAccordion
                      sessionData={props.sessionData}
                      userData={userEntry}
                      channelData={props.channelData}
                    />
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            ))}
        </div>
      )}
    </>
  );
}

const timeFromNow = (timestamp) => {
  if (moment(Date.now()).format("l") === moment(timestamp).format("l"))
    return `Today, ${moment(timestamp).format("h:mm A")}`;
  // else if (moment(Date.now()).diff(moment(timestamp), 'days') < 2) return `Yesterday, ${moment(timestamp).format("h:mm A")}`
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
    return moment(timestamp).format("dddd, h:mm A");
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 365)
    return moment(timestamp).format("DD MMM, h:mm A");
  else return moment(timestamp).format("MMM YY");
};
function ExportAllSessionsDataToExcel({ userId, roomId, workspaceId }) {
  const [loading, setLoading] = useState(false);
  const exportFile = async () => {
    setLoading(true);

    const config = {
      /*headers: {
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "Content-disposition": "attachment; filename=sessions.xlsx",
      },*/
      responseType: "arraybuffer",
    };

    axios
      .post(
        `${API_BASE_URL}${DOWNLOAD_SESSIONS}`,
        {
          user_id: userId,
          room_id: roomId,
          workspace_id: workspaceId,
        },
        config
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "sessions.xlsx");
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <Button variant="success" disabled size="sm">
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Exporting...
        </Button>
      ) : (
        <Button variant="warning" size="sm" onClick={() => exportFile()}>
          Export All
        </Button>
      )}
    </>
  );
}
function ExportDataToExcel({ userId, roomId, workspaceId, sessionId }) {
  console.log(sessionId);
  const [loading, setLoading] = useState(false);

  const exportFile = async () => {
    setLoading(true);

    const config = {
      /*headers: {
       "Content-Type":
         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
       "Content-disposition": "attachment; filename=sessions.xlsx",
     },*/
      responseType: "arraybuffer",
    };

    axios
      .post(
        `${API_BASE_URL}${DOWNLOAD_SESSION}`,
        {
          user_id: userId,
          room_id: roomId,
          workspace_id: workspaceId,
          session_id: sessionId,
        },
        config
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "session.xlsx");
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? (
        <Button variant="success" disabled size="sm">
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          Exporting...
        </Button>
      ) : (
        <Button variant="warning" size="sm" onClick={() => exportFile()}>
          Export
        </Button>
      )}
    </>
  );
}

function ListOfParticipants({ workspaceId, roomId, sessionId, item }) {
  let allParticipants = Object.keys(item.session_participants).map(
    (key) => item.session_participants[key]
  );
  //console.log(allParticipants);
  console.log(item);
  let allParticipantsByGrp = _.groupBy(allParticipants, "userId");
  let allParticipantsByMap = _.map(allParticipants, "userId");
  console.log(allParticipantsByGrp);

  return (
    <>
      {allParticipants.length > 0 && (
        <p className="text-theme my-1 small">
          Participants ({Object.keys(allParticipantsByGrp).length})
        </p>
      )}
      {Object.keys(allParticipantsByGrp).map((key) => (
        <div className="d-flex">
          <Participant
            userId={allParticipantsByGrp[key][0].userId}
            showYou={true}
            nameOnly={true}
          />
          {allParticipantsByGrp[key].length > 1
            ? `(${allParticipantsByGrp[key].length})`
            : null}
          <small className="ml-auto">
            {timeFromNow(
              allParticipantsByGrp[key].sort(
                (a, b) => a.joinedTimestamp - b.joinedTimestamp
              )[0].joinedTimestamp
            )}
          </small>
        </div>
      ))}
    </>
  );
}

function AttendanceLedger({
  channelData,
  currentUser,
  currentChannel,
  currentWorkspace,

  showAttendanceLedger,
  setShowAttendanceLedger,
}) {
  const [attendanceList, setAttendanceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [completeAttendanceList, setCompleteAttendanceList] = useState([]);

  const [allParticipants, setAllParticipants] = useState([]);
  const [savedPolls, setSavedPolls] = useState([]);
  const [publishedPolls, setPublishedPolls] = useState([]);

  const [savedReadings, setSavedReadings] = useState([]);
  const [publishedReadings, setPublishedReadings] = useState([]);

  const [savedQuizes, setSavedQuizes] = useState([]);
  const [publishedQuizes, setPublishedQuizes] = useState([]);

  const [savedAssignments, setSavedAssignmnets] = useState([]);
  const [publishedAssignments, setPublishedAssignments] = useState([]);

  const [savedSchedules, setSavedSchedules] = useState([]);
  const [publishedSchedules, setPublishedSchedules] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [sessionsChartData, setSessionsChartData] = useState([]);
  const [showAllDetails, setShowAllDetails] = useState(false);

  useEffect(() => {
    if (currentWorkspace && currentChannel) {
      firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/participants`
        )
        .onSnapshot((snapshot) => {
          let allParticipants1 = [];

          snapshot.forEach((doc) => {
            allParticipants1.push({ uid: doc.id, ...doc.data() });
          });

          // console.log("allParticipants1", allParticipants1);

          let savedPolls = [];
          let publishedPolls = [];
          let savedSchedules = [];
          let publishedSchedules = [];
          let savedReadings = [];
          let publishedReadings = [];
          let savedQuizes = [];
          let publishedQuizes = [];
          let savedAssignments = [];
          let publishedAssignments = [];
          const query = firebase
            .firestore()
            .collection(
              `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
            )
            .orderBy("timestamp", "desc");
          const queryMsg = firebase
            .firestore()
            .collection(
              `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
            )
            .orderBy("timestamp", "desc");

          query.onSnapshot((snapshot) => {
            //console.log(snapshot.docChanges());
            let changes = snapshot.docChanges();
            changes.forEach((change) => {
              if (change.type === "added") {
                if (change.doc.data().type === 3) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedPolls.unshift(json);
                } else if (change.doc.data().type === 4) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedQuizes.unshift(json);
                } else if (change.doc.data().type === 5) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedAssignments.unshift(json);
                } else if (change.doc.data().type === 2) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedReadings.unshift(json);
                } else if (change.doc.data().type === 1) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedSchedules.unshift(json);
                }
              } else if (change.type === "removed") {
                if (change.doc.data().type === 3) {
                  savedPolls = savedPolls.filter(
                    (savedPoll) => savedPoll.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 4) {
                  savedQuizes = savedQuizes.filter(
                    (savedQuiz) => savedQuiz.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 5) {
                  savedAssignments = savedAssignments.filter(
                    (savedAssignment) => savedAssignment.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 2) {
                  savedReadings = savedReadings.filter(
                    (savedReading) => savedReading.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 1) {
                  savedSchedules = savedSchedules.filter(
                    (savedSchedule) => savedSchedule.id !== change.doc.id
                  );
                }
              } else if (change.type === "modified") {
                if (change.doc.data().type === 3) {
                  let index = savedPolls.findIndex(
                    (savedPoll) => savedPoll.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedPolls.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 4) {
                  let index = savedQuizes.findIndex(
                    (savedQuiz) => savedQuiz.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedQuizes.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 5) {
                  let index = savedAssignments.findIndex(
                    (savedAssignment) => savedAssignment.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedAssignments.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 2) {
                  let index = savedReadings.findIndex(
                    (savedReading) => savedReading.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedReadings.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 1) {
                  let index = savedSchedules.findIndex(
                    (savedSchedule) => savedSchedule.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedSchedules.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                }
              }
            });
            setSavedPolls(savedPolls);
            setSavedQuizes(savedQuizes);
            setSavedReadings(savedReadings);
            setSavedAssignmnets(savedAssignments);
            setSavedSchedules(savedSchedules);
          });

          queryMsg.onSnapshot((snapshot) => {
            //console.log(snapshot.docChanges());
            let changes = snapshot.docChanges();
            changes.forEach((change) => {
              if (change.type === "added") {
                if (change.doc.data().type === 3) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedPolls.unshift(json);
                } else if (change.doc.data().type === 4) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedQuizes.unshift(json);
                } else if (change.doc.data().type === 5) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedAssignments.unshift(json);
                } else if (change.doc.data().type === 2) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedReadings.unshift(json);
                } else if (change.doc.data().type === 1) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedSchedules.unshift(json);
                }
              } else if (change.type === "removed") {
                if (change.doc.data().type === 3) {
                  publishedPolls = publishedPolls.filter(
                    (publishPoll) => publishPoll.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 4) {
                  publishedQuizes = publishedQuizes.filter(
                    (publishQuiz) => publishQuiz.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 5) {
                  publishedAssignments = publishedAssignments.filter(
                    (publishAssignment) =>
                      publishAssignment.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 2) {
                  publishedReadings = publishedReadings.filter(
                    (publishReading) => publishReading.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 1) {
                  publishedSchedules = publishedSchedules.filter(
                    (publishSchedule) => publishSchedule.id !== change.doc.id
                  );
                }
              } else if (change.type === "modified") {
                if (change.doc.data().type === 3) {
                  let index = publishedPolls.findIndex(
                    (publishPoll) => publishPoll.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedPolls.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 4) {
                  let index = publishedQuizes.findIndex(
                    (publishQuiz) => publishQuiz.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedQuizes.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 5) {
                  let index = publishedAssignments.findIndex(
                    (publishAssignment) =>
                      publishAssignment.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedAssignments.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 2) {
                  let index = publishedReadings.findIndex(
                    (publishReading) => publishReading.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedReadings.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 1) {
                  let index = publishedSchedules.findIndex(
                    (publishSchedule) => publishSchedule.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedSchedules.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                }
              }
            });
            setPublishedPolls(publishedPolls);
            setPublishedQuizes(publishedQuizes);
            setPublishedReadings(publishedReadings);
            setPublishedAssignments(publishedAssignments);
            setPublishedSchedules(publishedSchedules);
            let chartData = [
              {
                label: "Published Assignments",
                value: publishedAssignments.length,
              },
              { label: "Published Polls", value: publishedPolls.length },
              { label: "Published Quizzes", value: publishedQuizes.length },
              { label: "Published Files", value: publishedReadings.length },
            ];
            setChartData(chartData);
          });
          setAllParticipants(allParticipants1);
        });
    }
  }, [currentWorkspace, currentChannel] || []);

  const getAverageSessionDuration = (sessions) => {
    let totalMilliseconds = 0;
    let avgMilliseconds = 0;
    sessions.forEach((item) => {
      if (item.session_duration) {
        totalMilliseconds += item.session_duration;
      }
    });
    if (totalMilliseconds) {
      avgMilliseconds = totalMilliseconds / sessions.length;
    }

    return (moment.duration(avgMilliseconds, "milliseconds").format("hh:mm:ss"));

  };

  const getTotalSessionDuration = (sessions) => {
    let totalMilliseconds = 0;
    sessions.forEach((item) => {
      if (item.session_duration) {
        totalMilliseconds += item.session_duration;
      }
    });

    return (moment.duration(totalMilliseconds, "milliseconds").format("hh:mm:ss"));
  };

  async function loadAttendanceLedgerData() {
    const sessionsData = await axios.post(
      `${API_BASE_URL}${REPORTS_ROOM_SESSIONS}`,
      {
        workspace_id: currentWorkspace.workspaceId,
        room_id: currentChannel.roomId,
        user_id: currentUser.uid,
      }
    );
    console.log("sessionsData: ", sessionsData.data.data["sessions"]);
    setCompleteAttendanceList(sessionsData.data.data["sessions"]);

    let sessions = sessionsData.data.data["sessions"].sort(
      (a, b) => b.session_start_timestamp - a.session_start_timestamp
    );

    //console.log(sessions);
    /* let allSessions = [...sessions];
    let newSessions = [];

    for (let i = 0; i < allSessions.length; i++) {
      let totalDuration = 0;
      let firstSessionOfDateObj = allSessions[i];
      for (let j = 0; j < allSessions.length; j++) {
        if (
          firstSessionOfDateObj.session_date === allSessions[j].session_date
        ) {
          totalDuration = totalDuration + +allSessions[j].session_duration;
        }
      }
      firstSessionOfDateObj.session_duration = totalDuration;
      console.log(firstSessionOfDateObj);
      allSessions = allSessions.filter(
        (s) => firstSessionOfDateObj.session_date !== s.session_date
      );
      newSessions.push(firstSessionOfDateObj);
    }*/
    let newSessions = [...sessions];

    newSessions.forEach((session) => {
      let totalduration = 0;
      let firstSessionOfDateObj = session;
      //console.log(firstSessionOfDateObj);
      /* sessions.forEach((s) => {
        if (s.session_date === firstSessionOfDateObj.session_date) {
          console.log("inside if");
          totalduration = totalduration + s.session_duration;
          console.log(totalduration);
        }
      });*/

      console.log(firstSessionOfDateObj);
      /*  let allS = newSessions.filter(
        (s) => firstSessionOfDateObj.session_date === s.session_date
      );
      let totalD = allS.reduce(
        (acc, curr) => (acc = acc + curr.session_duration),
        0
      );*/
      newSessions = newSessions.filter(
        (s) => firstSessionOfDateObj.session_date !== s.session_date
      );
      //console.log(totalduration);
      newSessions.push(firstSessionOfDateObj);
    });
    console.log(newSessions, "filtered Sessions");
    //  let sessionsCopy = [...sessions];

    // let newSessions =
    //   sessionsCopy.length > 10 ? sessionsCopy.splice(0, 10) : [...sessionsCopy];
    // console.log(newSessions, "filtered Sessions");

    let sessionsChartData = sessions.map((session) => {
      return {
        label: session.session_date,
        value: Math.round(session.session_duration / 60000),
      };
    });

    setAttendanceList(newSessions);
    setSessionsChartData(sessionsChartData);
    setLoading(false);
  }
  /*const getSessionDurationInNumber=(totalDuration)=>
  {

    if (totalDuration) {
      if (moment.utc(totalDuration).format("HH") > 0) {
        return (
          moment.utc(totalDuration).format("HH") +
          " hour" +
          moment.utc(totalDuration).format("mm") +
          " seconds"
          // + moment.utc(totalDuration).format("ss") +
          // ""
        );
      } else {
        if (moment.utc(totalDuration).format("mm") > 0) {
          return (
            moment.utc(totalDuration).format("mm") + " minutes"
            // + moment.utc(totalDuration).format("ss") +
            // ""
          );
        } else {
          return moment.utc(totalDuration).format("ss") + " seconds";
        }
      }
    } else {
      return "Currently Live/ Calculating...";
    }

  }
*/

  useEffect(() => {
    console.log("Loading Attendance Data");

    loadAttendanceLedgerData();
  }, []);

  const [key, setKey] = useState("session");

  const toggleAnalytics = () => {
    setShowAttendanceLedger(!showAttendanceLedger);
  };
  // console.log(props);
  //console.log(allParticipants);
  //console.log(channelData);
  //console.log(currentChannel);
  //console.log(completeAttendanceList);
  //console.log(attendanceList);
  return (
    <Card className="bg-theme text-theme shadow rounded-0 table-wid">
      <Card.Header className="d-flex rounded-0">
        <div className="flex-grow-1">
          <h4>Analytics</h4>
          <p className="small mb-0">
            Get insights into your sessions and participants. <br /> We are
            actively working on improving this feature.
          </p>
        </div>
        <div>
          <button
            className="btn btn-sm btn-link text-decoration-none"
            onClick={toggleAnalytics}
          >
            <span className="text-theme-lighter">
              <FontAwesomeIcon icon={faTimes} className="ml-1" /> Close
            </span>
          </button>
        </div>
      </Card.Header>
      <Card.Body className="">
        {loading ? (
          <div className="w-100 d-flex align-items-center justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <div className="row mb-3 p-2 border-1 mb-2 rounded shadow bg-card-theme">
              <div className="col-12 mb-2 pb-2 border-bottom-1">
                <strong>Room Summary</strong>
              </div>
              <div className="col-3 border-right-1">
                <div className="">
                  <h3 className="mb-0">
                    {allParticipants && Object.keys(allParticipants).length}
                  </h3>
                  <span className="small">Users</span>
                </div>
              </div>
              <div className="col-3 border-right-1">
                <div className="">
                  <h3 className="mb-0">{completeAttendanceList.length}</h3>
                  <span className="small">Sessions</span>
                </div>
              </div>
              <div className="col-3 border-right-1">
                <div className="">
                  <h3 className="mb-0">
                    {getAverageSessionDuration(completeAttendanceList)}
                  </h3>
                  <span className="small text-nowrap">
                    Avg. Session Duration
                  </span>
                </div>
              </div>
              <div className="col-3">
                <div className="">
                  <h3 className="mb-0">
                    {getTotalSessionDuration(completeAttendanceList)}
                  </h3>
                  <span className="small text-nowrap">
                    Total Session Duration
                  </span>
                </div>
              </div>
            </div>
            <div className="row mb-3 p-2 border-1 mb-2 rounded shadow bg-card-theme">
              <div className="col-12 mb-2 pb-2 border-bottom-1">
                <strong>Apps</strong>
              </div>
              <div className="col-3 border-right-1">
                <div className="">
                  <h3 className="mb-0">{publishedPolls.length}</h3>
                  <div className="d-flex justify-content-between">
                    <span className="small">Polls</span>
                    <span className="smaller mt-1 text-theme-lighter">
                      {savedPolls.length} SAVED
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-3 border-right-1">
                <div className="">
                  <h3 className="mb-0">{publishedAssignments.length}</h3>
                  <div className="d-flex justify-content-between">
                    <span className="small d-block">Assignments</span>
                    <span className="smaller mt-1 d-block text-theme-lighter">
                      {savedAssignments.length} SAVED
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-3 border-right-1">
                <div className="">
                  <h3 className="mb-0">{publishedQuizes.length}</h3>
                  <div className="d-flex justify-content-between">
                    <span className="small d-block">Quizzes</span>
                    <span className="smaller mt-1 d-block text-theme-lighter">
                      {savedQuizes.length} SAVED
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="">
                  <h3 className="mb-0">{publishedReadings.length}</h3>
                  <div className="d-flex justify-content-between">
                    <span className="small d-block">Files</span>
                    <span className="smaller mt-1 d-block text-theme-lighter">
                      {savedReadings.length} SAVED
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="col-3">
                  <div className="">
                    <h3 className="mb-0">{publishedSchedules.length}</h3>
                    <span className="small d-block">Schedule</span>
                    <span className="smaller d-block text-theme-lighter">{savedSchedules.length} saved</span>
                  </div>
                </div> */}
            </div>
            {/* <div className="row my-2">
                <LineChart data={chartData} title="Apps" color="aqua" />
              </div> */}

            <div className="row mb-2 mb-3 p-2 border-1 mb-2 rounded shadow bg-card-theme">
              <div className="col-12 mb-2 pb-2">
                <div className="row">
                  <div className="col-6">
                    <strong>Session Details</strong>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <small
                      className="pointer "
                      onClick={() => setShowAllDetails(!showAllDetails)}
                    >
                      {showAllDetails ? "Hide" : "Show"}
                    </small>
                  </div>
                </div>
              </div>
              {showAllDetails ? (
                <div className="col-md-12">
                  <div className="row d-flex justify-content-end mb-1 shadow-sm">
                    <ExportAllSessionsDataToExcel
                      completeAttendanceList={completeAttendanceList}
                      attendanceList={attendanceList}
                      workspaceId={currentWorkspace.workspaceId}
                      roomId={currentChannel.roomId}
                      userId={currentUser.uid}
                    />
                  </div>
                  {attendanceList.map((attendanceEntry) => (
                    <div
                      className="row p-2 border-1 mb-2 rounded shadow hover-light pointer"
                      key={attendanceEntry.session_duration}
                    >
                      <div className="col-6">
                        <span className="d-block font-weight-bold">
                          {attendanceEntry.session_date}
                        </span>
                        {/* <span className="small">
                          {getSessionDuration(attendanceEntry.session_duration)}
                        </span> */}
                      </div>
                      <div className="col-6 text-right">
                        {/*<span className="d-block small text-theme-lighter">
                          {getSessionDuration(attendanceEntry.session_duration)}
                        </span>
                        {/* <span className="small text-theme-lighter">
                          {getSessionTime(attendanceEntry.session_end_timestamp)}
                        </span> */}
                      </div>

                      <div className="col-md-12 mt-2">
                        {completeAttendanceList.map(
                          (item) =>
                            item.session_date ===
                              attendanceEntry.session_date && (
                              <div className="p-2 border-1 mb-2 rounded pointer ">
                                <div
                                  className="d-flex"
                                  key={item.session_duration}
                                >
                                  <span className="d-block font-weight-bold flex-grow-1">
                                    {timeFromNow(item.session_start_timestamp)}
                                  </span>
                                  <span className="small">
                                    {getSessionDuration(item.session_duration)}{" "}
                                    <ExportDataToExcel
                                      workspaceId={currentWorkspace.workspaceId}
                                      roomId={currentChannel.roomId}
                                      userId={currentUser.uid}
                                      sessionId={item.session_id}
                                    />
                                  </span>
                                </div>

                                <ListOfParticipants
                                  workspaceId={currentWorkspace.workspaceId}
                                  roomId={currentChannel.roomId}
                                  sessionId={item.session_id}
                                  item={item}
                                />
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            {/* <div className="row my-4">
                <SessionsChart
                  data={sessionsChartData}
                  //title="Sessions"
                  color="aqua"
                />
              </div> */}
          </>
        )}
      </Card.Body>
    </Card>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  showAttendanceLedger: state.attendanceLedger.showAttendanceLedger,
});

export default connect(mapStateToProps, { setShowAttendanceLedger })(
  AttendanceLedger
);
