import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import Loader from "./Loader";
import * as serviceWorker from "./serviceWorker";
import firebase from "./firebase";
import "./index2.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap"

import { setAccessCountryCode } from "./services/index";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import SignIn from "./components/Auth/SignIn";
import SignInAsGuest from "./components/Auth/SignInAsGuest";

import { createStore, applyMiddleware } from "redux";
import { Provider, connect } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import {
  setUser,
  setUserData,
  clearUser,
  setSubscription,
  clearSubscription,
  addRoom,
  addRoomError,
  setDarkTheme,
  setCurrentWorkspace,
  setWorkspaces,
  setCurrentChannel,
  setChannelDetails,
  setIsVideoVisible,
  setVideoDetails,
  addRoomJWT,
  addRoomJWTError,
  addRoomWorkspaceDetails
} from "./actions";
import thunk from "redux-thunk";
import { loadState, saveState } from "./services/index";

import TagManager from 'react-gtm-module'
import JoinWorkspace from "./components/Auth/JoinWorkspace";
import UserWorkspaces from "./components/Auth/UserWorkspaces";
import Start from "./components/Auth/Start";
import _ from 'lodash';
import axios from "axios";
import base64 from 'base-64';
import {API_BASE_URL, ZOOM_CLIENT_ID, ZOOM_CLIENT_SECRET, ZOOM_OAUTH, MEETING_TYPE, WORKSPACE_DETAILS_INVITE_CODE, WORKSPACE_DETAILS_FROM_ID_SLUG, USER_WORKSPACES} from './config/index';
// import { browserName, CustomView, osName, deviceType, isIOS, isAndroid, } from "react-device-detect";

import $ from "jquery";
import Popper from "popper.js";
import "bootstrap/dist/js/bootstrap.bundle.min";

const tagManagerArgs = {
  gtmId: "GTM-TN5NMJX",
};

TagManager.initialize(tagManagerArgs);


const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

//Determining vh
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty("--vh", `${vh}px`);

let vw = window.innerWidth * 0.01;
document.documentElement.style.setProperty("--vw", `${vw}px`);

window.addEventListener("resize", () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  let vw = window.innerWidth * 0.01;
  document.documentElement.style.setProperty("--vw", `${vw}px`);
});

//End

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        firebase.auth().currentUser ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: location },
              }}
            />
          )
      }
    />
  );
}

class Root extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      roominvite: null,
      roominviteJWT: null,
      unsubscribe: null,
      unsubscribeUser: null
    }
  }

  getWorkspaceData = async (workspaceId, uid) => {

    let self = this;
    let docRef = firebase.firestore().collection(`workspaces`).doc(`${workspaceId}`)
    await docRef.get()
      .then(function (doc) {
        console.log(workspaceId, "w id")
        if (doc.exists) {
          // console.log("Document data workspace:", doc.data());
          const workspacedata = doc.data();
          console.log(workspacedata, 'workspacedata in firestore')
          const unsub = firebase
            .firestore()
            .collection(`users/${uid}/rooms`)
            .onSnapshot(function (snapshot) {
              let userWorkspaces = [];
              snapshot.forEach(function (doc) {
                userWorkspaces.push({ ...doc.data() })
              })
              console.log("userWorkspaces in firestore", userWorkspaces[0])

              let index;

              userWorkspaces.forEach((workspace, key) => {
                if (workspace.workspaceId === workspaceId) {
                  index = key
                }
              })

              self.props.setCurrentWorkspace({ ...userWorkspaces[index], ...workspacedata })
            })
          self.setState({ unsubscribe: unsub });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      })

  }

  getLive = async (workspaceId, roomId) => {
    const isLive = await firebase
      .firestore()
      .collection(`sessions/${workspaceId.toLowerCase()}/rooms`)
      .doc(`${roomId.toLowerCase()}`)
      .get()
      .then(doc => {
        if (doc.exists) {
          console.log("LIVE Sessions doc: ", doc.data())
          const { currentSession } = doc.data()
          if (currentSession !== "") {
            return (true);
          } else {
            return (false);
          }
        } else {
          return (false);
        }
      });
    return isLive;
  }

  // setNewUser = async (user) => {
  //   let savedInState;
  //   const userIsInWorkspace = await firebase.firestore().collection("users").doc(user.uid).collection('rooms').doc('LgT2sjzoZQwwKMGPnob2').set({
  //     rooms: [],
  //     workspaceId: 'LgT2sjzoZQwwKMGPnob2'
  //   }).then(async () => {

  //     let docRef = firebase.firestore().collection(`workspaces`).doc(`LgT2sjzoZQwwKMGPnob2`)
  //     savedInState = await docRef.get()
  //       .then(function (doc) {
  //         if (doc.exists) {
  //           // console.log("Document data workspace:", doc.data());
  //           const workspacedata = doc.data();
  //           saveState("defaultWorkspace", { ...workspacedata, workspaceId: "LgT2sjzoZQwwKMGPnob2",  rooms: [] });
  //           return true;
  //         } else {
  //           // doc.data() will be undefined in this case
  //           console.log("No such document!");
  //           return false;
  //         }
  //       })
  //       .catch(function (error) {
  //         console.log("Error getting document:", error);
  //         return false;
  //       })
  //   })
  //   return(savedInState);
  // }

  getUserIsNewUser = async (user) => {
    // checking if the user is brand new (aka is not in any workspace/room)
    const isNew = await firebase.firestore().collection(`users/${user.uid}/rooms`).get().then((querySnapshot) => {
      console.log(querySnapshot, "qsnapshot")
      if (querySnapshot.docs.length !== 0) {
        // user is a part of atleat one workspace
        return false;
      } else {
        return true;
      }
    })
    return (isNew);
  }

  setUserDataHandler = async (user) => {

    firebase
      .firestore()
      .collection("users")
      .doc(user.uid).get().then((doc) => {
        if (doc.exists) {
          this.props.setUserData(doc.data())
        }
      })
  }

  setFcmTokenHandler = (user) => {
    // messaging/ notifications
    const messaging = firebase.messaging();
    messaging.requestPermission()
      .then(() => {
        console.log("have permission")
        return messaging.getToken();
      })
      .then((token) => {
        firebase.firestore().collection("users")
          .doc(user.uid).get()
          .then((doc) => {
            if (doc.exists) {
              const previousDeviceInfo = doc.data().deviceInfo;
              if (previousDeviceInfo.fcmTokenWeb && previousDeviceInfo.fcmTokenWeb.indexOf(token) === -1) {
                firebase.firestore().collection("users")
                  .doc(user.uid).update({
                    deviceInfo: {
                      ...previousDeviceInfo,
                      fcmTokenWeb: [...previousDeviceInfo.fcmTokenWeb, token]
                    }
                  })
              } else if (!previousDeviceInfo.fcmTokenWeb) {
                firebase.firestore().collection("users")
                  .doc(user.uid).update({
                    deviceInfo: {
                      ...previousDeviceInfo,
                      fcmTokenWeb: [token]
                    }
                  })
              } else {
                // same token already exists do nothing
              }
            }
          })
      })
      .catch((error) => {
        console.log("Error detected", error)
      })
    messaging.onMessage((payload) => {
      console.log("onMessage: ", payload);
    })
  }

  componentDidMount() {
    setAccessCountryCode();

    const { setDarkTheme } = this.props;

    console.log(this.props, "props", window.location, "pathname")

    if (loadState("theme")) {
      document.body.setAttribute("data-theme", loadState("theme"));
      setDarkTheme(loadState("theme") === "dark");
    } else {
      document.body.setAttribute("data-theme", "dark");
      saveState("theme", "dark");
      setDarkTheme(true);
    }

    const {
      addRoom,
      addRoomError,
      setUser,
      setSubscription,
      history,
      clearUser,
      clearSubscription,
      newRoom,
      setUserData,
      addRoomJWT,
      addRoomJWTError,
      addRoomWorkspaceDetails
    } = this.props;

    const { pathname, hash } = this.props.location;
    const cleanpathname = pathname.replace("/", "");
    const cleanhash = hash.replace("#/", "");

    console.log(`Pathname is ${cleanpathname}`, `\nHash is ${cleanhash}`);

    firebase.auth().onAuthStateChanged((user) => {
      const pattern = /^[0-9\s\-\(\)]+$/;

      if (cleanpathname.match(pattern) && cleanpathname.length === 6) {
        axios.post(`${API_BASE_URL}${WORKSPACE_DETAILS_INVITE_CODE}`, {
          invite_code: cleanpathname
        }).then((response) => {
          if(response.status === 200) {
            addRoomWorkspaceDetails(response.data.data)
          }
        })
        addRoom(cleanpathname);
        this.setState({ roominvite: cleanpathname })
        addRoomError(null);
      } else if (cleanpathname.match(pattern)) {
        // Show error for wrong links
        addRoom(null);
        this.setState({ roominvite: null })
        addRoomError(cleanpathname);
      } else if (cleanhash.match(pattern) && cleanhash.length === 6) {
        addRoom(cleanhash);
        this.setState({ roominvite: cleanhash })
        addRoomError(null);
      } else if (cleanhash.match(pattern)) {
        addRoom(null);
        this.setState({ roominvite: null })
        addRoomError(cleanhash);
      } else {
        //Do nothing
      }

      if (pathname.split('/')[1] === "join" && pathname.split('/')[2].trim() !== 0) {
        const JWT = pathname.split('/')[2];
        addRoomJWT(JWT);
        addRoomJWTError(null);
      } else {
        //Do nothing
      }

      if (user) {
        setUser(user);
        // this.setUserDataHandler(user);
        // this.setFcmTokenHandler(user);
        
        const unsub = firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .onSnapshot(async (snapshot) => {

            if (snapshot.exists) {
              let defaultWorkspace = loadState('defaultWorkspace');
              const userIsInMoreThanOneWorkspace = await firebase.firestore().collection(`users/${user.uid}/rooms`).get().then((querySnapshot) => {
                if (querySnapshot.docs.length > 1) {
                  // user is a part of atleat one workspace
                  return true;
                } else {
                  return false;
                }
              })


              if (snapshot.data().subscription) {
                // console.log(snapshot.data().subscription);
                setSubscription(snapshot.data().subscription);
              }

              if (history.location.pathname.split('/')[1] === "w" && history.location.pathname.split('/')[2] !== "" && history.location.search === "") {
                firebase.firestore().collection(`users/${user.uid}/rooms`).doc(history.location.pathname.split('/')[2]).get().then((doc) => {
                  if (doc.exists) {
                    this.props.setWorkspaces(user.uid);
                    this.getWorkspaceData(history.location.pathname.split('/')[2], user.uid)
                  } else {
                    history.push(history.location.pathname)
                  }
                })
              }
              else if (history.location.pathname.split('/')[1] === "w" && history.location.pathname.split('/')[2] !== "" && history.location.search !== "") {
                const workspaceId = history.location.pathname.split('/')[2];
                const roomId = history.location.search.split('=')[1];
                firebase.firestore().collection(`users/${user.uid}/rooms`).doc(workspaceId).get().then((doc) => {
                  if (doc.exists) {
                    this.props.setWorkspaces(user.uid);
                    this.getWorkspaceData(workspaceId, user.uid)
                  } else {
                    history.push(history.location.pathname)
                  }

                  let roomData;
                  console.log(workspaceId, roomId, "wid rid")
                  firebase.firestore().collection(`workspaces/${workspaceId}/rooms`).doc(`${roomId}`).get().then((doc) => {
                    console.log(doc.data(), "doc of room data")
                    if (doc.exists) {
                      roomData = doc.data()
                    }
                    const channelData = { ...roomData, roomId: roomId };
                    this.props.setCurrentChannel(_.cloneDeep(channelData));
                    this.getLive(workspaceId, roomId).then((isLive) => {
                      if (isLive) {
                        axios.post(`${API_BASE_URL}${MEETING_TYPE}`, {
                          room_id: roomId,
                          workspace_id: workspaceId,
                          user_id: user.uid,
                          email: user.email
                        }).then((res) => {
                          if (res.status === 200) {
                            this.props.setVideoDetails(res.data.data)
                            this.props.setChannelDetails({ ...roomData, roomId: roomId })
                            this.props.setIsVideoVisible(true);
                          } else {
                            console.log("Could not get info about video meeting:", res.status)
                          }
                        }).catch((error) => {
                          console.log("Could not get info about video meeting:", error)
                        })
                      } else {
                        // setTimeout(() => {
                        //   setNewRoomModalShow(true);
                        // }, 1000)
                      }
                    })
                  })

                })
              }
              else if (history.location.search.includes("?code=")) {
                const base64EncodedHeader = base64.encode(`${ZOOM_CLIENT_ID}:${ZOOM_CLIENT_SECRET}`);
                axios.post(`${API_BASE_URL}${ZOOM_OAUTH}`, {
                  authorization_code: history.location.search.split('=')[1].split('&')[0],
                  base64_auth_header: base64EncodedHeader,
                  email: 'hello@igesia.co',
                  room_id: history.location.search.split('=')[2].split('%2F')[0],
                  workspace_id: history.location.search.split('=')[2].split('%2F')[1],
                  user_id: history.location.search.split('=')[2].split('%2F')[2]
                }).then((res) => {
                  console.log(res, "res")
                })
                // axios.post(`https://zoom.us/oauth/token?grant_type=authorization_code&code=${history.location.search.split('=')[1]}&redirect_uri=http://localhost:3000/`, {

                // }, {
                //   headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': `Basic ${base64EncodedHeader}`
                //   }
                // }).then((res) => {
                //   console.log(res, "res")
                // })
                // axios.post(`https://api.zoom.us/v2/users/${user.email}/meetings`)
              }
              else if (this.state.roominvite) {
                history.push("/");
              }
              else if (defaultWorkspace) {
                // fetching data from the default workspaceId cause localstorage and database is not in sync otherwise
                this.getWorkspaceData(defaultWorkspace.workspaceId, user.uid)
              }
              
              else if (!userIsInMoreThanOneWorkspace) {
                saveState("defaultWorkspace", { workspaceId: "LgT2sjzoZQwwKMGPnob2", rooms: [] });
                this.getWorkspaceData("LgT2sjzoZQwwKMGPnob2", user.uid)
                history.push("/");
              }
              
              else if (!this.state.roominvite && !defaultWorkspace && !this.props.workspaceSlug) {
                history.push("/switchworkspace");
              }
              else if (this.props.workspaceSlug) {
                history.push(`/w/${this.props.workspaceSlug}`);
              }
              else {
                history.push("/switchworkspace");
              }


            }
          });
        // history.push("/");
        this.setState({ unsubscribeUser: unsub });
        console.log(history, "history")
        if (history.location.pathname.split('/')[1] === "w" || history.location.pathname.split('/')[1] === "switchworkspace" || history.location.pathname.split('/')[1] === "start") {

        } else if (history.location.search.includes("?code=")) {
          const base64EncodedHeader = base64.encode(`${ZOOM_CLIENT_ID}:${ZOOM_CLIENT_SECRET}`);
          axios.post(`${API_BASE_URL}${ZOOM_OAUTH}`, {
            authorization_code: history.location.search.split('=')[1].split('&')[0],
            base64_auth_header: base64EncodedHeader,
            email: 'veboke6010@eamarian.com',
            room_id: history.location.search.split('=')[2].split('%2F')[0],
            workspace_id: history.location.search.split('=')[2].split('%2F')[1],
            user_id: history.location.search.split('=')[2].split('%2F')[2]
          }).then((res) => {
            if (res.status === 200) {
              window.location.replace(res.data.data.start_url) 
            }
          })
        }
        else{
          history.push("/");
        }
        return;
      } else {
        if(this.state.unsubscribeUser)
        this.state.unsubscribeUser()
        // history.push("/signin");
        clearUser();
        clearSubscription();
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps && prevProps.currentWorkspace && this.props.currentWorkspace && prevProps.currentWorkspace.workspaceId !== this.props.currentWorkspace.workspaceId) {
      if (this.state.unsubscribe) {
        console.log("unsubscribe from index")
        this.state.unsubscribe();
      }
    }
  }

  render() {
    return this.props.isLoading ? (
      <Loader />
    ) : (
        <Switch>
          <Route exact path="/signin">
            <SignIn />
          </Route>

          <Route path="/guest">
            <SignInAsGuest />
          </Route>

          <PrivateRoute exact path="/">
            <App />
          </PrivateRoute>

          <PrivateRoute exact path="/switchworkspace">
            <UserWorkspaces />
          </PrivateRoute>

          {/* <PrivateRoute exact path="/start">
            <Start />
          </PrivateRoute> */}


          <Route exact path="/w/:workspaceId">
            <JoinWorkspace />
          </Route>

          <PrivateRoute exact path="/">
            <App />
          </PrivateRoute>

          <PrivateRoute path="/">
            <App />
          </PrivateRoute>
        </Switch>
      );
  }
}

const mapStateFromProps = (state) => ({
  isLoading: state.user.isLoading,
  newRoom: state.newRoom.newRoom,
  currentWorkspace: state.workspace.currentWorkspace,
  workspaceSlug: state.platform.workspaceSlug,
});

const RootWithAuth = withRouter(
  connect(mapStateFromProps, {
    setUser,
    setUserData,
    clearUser,
    setSubscription,
    clearSubscription,
    addRoom,
    addRoomError,
    setDarkTheme,
    setCurrentWorkspace,
    setWorkspaces,
    setCurrentChannel,
    setChannelDetails,
    setIsVideoVisible,
    setVideoDetails,
    addRoomJWT,
    addRoomJWTError,
    addRoomWorkspaceDetails
  })(Root)
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <RootWithAuth />
    </Router>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
