import React, { useState, useEffect } from 'react';
import firebase from 'firebase';
import moment from 'moment';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';


import TaskListTemplate from './TaskListTemplate';
import TaskTemplate from './TaskTemplate';


function TaskContainer({ item, set_display_task }) {
    return item && (
        <div className="notifications" >
            <div className="rightTabHeader">
                <FontAwesomeIcon onClick={() => set_display_task("")} icon={faArrowLeft} className="rightheadericon" />
                <h5>Back</h5>
            </div>
            <div className="taskBox">
                <Form className="feedback">
                    <div id="display-data-Container">
                        <TaskTemplate item={item} />
                    </div>
                </Form>
            </div>
        </div>
    )
}

function TaskManagement({ all_tasks, all_saved_tasks }) {
    const [task_visible, set_task_visible] = useState("ToDo");
    const [display_task, set_display_task] = useState("");
    // const [all_tasks, set_all_tasks] = useState([]);

    // useEffect(() => {
    //     let loadedMessages = [];
    //     currentChannel && firebase.database().ref("messages").child(currentChannel.id).orderByChild("timestamp").on("child_added", snap => {

    //         if (!snap.val().hasOwnProperty("content") && !snap.val().hasOwnProperty("image")) {
    //             if (snap.val().everyone)
    //                 loadedMessages.push(snap.val())
    //             else if (snap.val().users && Object.keys(snap.val().users).includes(currentUser.uid))
    //                 loadedMessages.push(snap.val());
    //         }

    //         set_all_tasks(loadedMessages)
    //     })
    // }, [currentChannel]);

    return (
        <>
            {/* <ButtonGroup size="sm" className="w-100 pt-2 pb-2 px-2">
                <Button variant={task_visible !== "ToDo" ? "outline-secondary" : "secondary"} className={task_visible !== "ToDo" ? "bg-light" : "bg-secondary"} onClick={() => set_task_visible('ToDo')}>To Do</Button>
                <Button variant={task_visible !== "InProgress" ? "outline-secondary" : "secondary"} className={task_visible !== "InProgress" ? "bg-light" : "bg-secondary"} onClick={() => set_task_visible('InProgress')}>In Progress</Button> 
                <Button variant={task_visible !== "Completed" ? "outline-secondary" : "secondary"} className={task_visible !== "Completed" ? "bg-light" : "bg-secondary"} onClick={() => set_task_visible('Completed')}>Completed</Button>
            </ButtonGroup> */}

            {/* <ul> */}
            <div className="row rounded-lg py-2 m-2 bg-card-theme shadow-sm">
                <div className="col">
                    <div class="row">
                        <div class="col-8">
                            <p class=" font-weight-bold text-theme">
                                {task_visible !== "Completed"
                                    ? <span>Your Schedule</span>
                                    : <span>
                                        <FontAwesomeIcon
                                            onClick={() => set_task_visible('ToDo')}
                                            icon={faArrowLeft} className="pointer" /> Archive
                                    </span>
                                }
                            </p>
                        </div>
                        <div class="col-4 text-right text-muted pointer">
                            {task_visible !== "ToDo"
                                ? <p class="mt-1 small" onClick={() => set_task_visible('ToDo')}></p>
                                : <p class="mt-1 small" onClick={() => set_task_visible('Completed')}>Archive</p>}
                        </div>
                    </div>
                    {all_tasks && all_tasks.sort((a, b) => b.timestamp - a.timestamp).map((item, key) => {
                        return <TaskListTemplate item={item} key={key} type="messages" task_visible={task_visible} />
                        // if (key >= 4 && task_visible == "Completed") return <TaskListTemplate item={item} key={key} type="messages" />
                        // if (key >= 2 && key < 4 && task_visible == "InProgress") return <TaskListTemplate item={item} key={key} type="messages" />
                        // if (key >= 0 && key < 2 && task_visible == "ToDo") return <TaskListTemplate item={item} key={key} type="messages" />
                    })}
                    {/* <div className="row py-2 rounded pointer lightBgonHover"
                        style={{margin: "0px -6px"}} >
                        <div className="col-2  text-right float-right ">
                            <img className="rounded-circle p-1" src="https://static.xx.fbcdn.net/rsrc.php/v3/y4/r/lTjoHuuMCNu.png"
                                width="25"
                                style={{ backgroundColor: "rgba(100, 100, 100, 0.25)" }} />
                        </div>
                        <div class="col-10 ">
                            <p className="mb-0 mt-1 small">Load More</p>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* </ul> */}

            {/* {all_saved_tasks.length > 0 && 
            <div className="row rounded-lg py-2 m-2 bg-white">
                <div className="col">
                    <div class="row">
                        <div class="col-12">
                            <p class=" font-weight-bold">Saved</p>
                        </div>
                    </div>
                    {all_saved_tasks
                        && all_saved_tasks
                            .sort((a, b) => b.timestamp - a.timestamp)
                            .map((item, key) => <TaskListTemplate item={item} key={key} type="saved" />)}
                </div>
            </div>
            } */}

            <TaskContainer item={display_task} set_display_task={set_display_task} />
        </>
    )
};

export default TaskManagement;