import React, { useState, useEffect } from "react";
import moment from "moment";
import firebase from "firebase";

import DisplayResponse from "./DisplayResponse";

import {
  Form,
  Button,
  Badge,
  Card,
  Accordion,
  Alert,
  ProgressBar
} from "react-bootstrap";
import "../Tasks.css";
import Participants from "../../Right/UserNameAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faCheck,
  faTimes,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import Viewer from "react-viewer";

import { connect } from "react-redux";
//import { saveRemark, publishRemark } from "./actions";
import axios from "axios";
import { toast } from "react-toastify";
import DisplayAnswers from "./DisplayAnswers";
import { API_BASE_URL, QUIZPLUS_SUBMIT, QUIZPLUS_ANALYTICS, ROOM_ADMIN_CONFIG, QUIZPLUS_QUESTION_CATEGORIES } from "../../../config";
import NewWindow from 'react-new-window';
import { getTimeDifference } from "../Common/TimeDifference";
import AssignedUsers from "../Common/AssignedUsers";
import _ from 'lodash';

toast.configure();

const getResult = (response, user_id) => {
  //console.log(response);
  //console.log(user_id);
  //console.log(response.find((user) => user.id === user_id));
  return response && response.find((user) => user.id === user_id)
    ? true
    : false;
};

const getValue = (response, user_id) => {
  return (
    response && (
      <div>
        <strong>Your answer</strong>
        <br />
        <p>{response[user_id] && response[user_id].value}</p>
        <p>
          Responded{" "}
          <i>
            {moment(response[user_id] && response[user_id].responseTime).format(
              "hh:mm A MMM DD YYYY"
            )}
          </i>
        </p>
      </div>
    )
  );
};

const Quiz = (props) => {
  const {
    //quiz_data,
    taskResponse,
    user_id,
    // currentChannel,
    open_task_branch,
    open_task_id,
    //saveRemark,
    //publishRemark,
    colorTheme,
    componentName,
  } = props;
  //console.log(props, "props of quiz");
  const [value, setValue] = useState("");
  const [admin, setAdmin] = useState(false);
  const [responses, setResponses] = useState([]);
  const contentId = props.quiz_data.contentId;
  const [quizData, setQuizData] = useState({ specialOlympicQuestions: {} });
  const [sortedQuestions, setSortedQuestion] = useState([]);
  const [quizAnalytics, setQuizAnalytics] = useState(null);
  const [isClosed, setIsClosed] = useState(false);
  const [loadingAnalytics, setLoadingAnalytics] = useState(true);
  const [currentUserResponseExists, setCurrentUsersResponseExists] = useState(false);
  const [allQuestionTypes, setAllQuestionTypes] = useState([
    // { hexCode: "#959a9c", name: "Information", type: 1 },
    // { hexCode: "#a4dbaa", name: "Caries Risk", type: 2 },
    // { hexCode: "#ff810a", name: "Periodontal Risk", type: 3 },
    // { hexCode: "#7088b5", name: "Health (apnea) Risk", type: 4 },
    // { hexCode: "#8a679c", name: "Trauma Risk", type: 5 },
    // { hexCode: "#dbbb1a", name: "Urgency", type: 6 },
    // { hexCode: "#a82d14", name: "Stop & Review", type: 7 },
  ]);
  const QuestionType = id => {
    const found = allQuestionTypes[id];
    return found ? found : null
  }

  // image viewer states
  const [visible, setVisible] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [noOptionSelectedError, setNoOptionSelectedError] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState(null);


  function ImageViewer(
    source,
    visible,
    setVisible,
    setImageSelected
  ) {
    return (
      <>
        <Viewer
          visible={visible}
          onClose={() => {
            setVisible(false);
            setImageSelected(null);
          }}
          images={[{
            src: source,
          }
          ]}
        />
      </>
    );
  }

  // getting quiz data from firestore, quiz collection

  useEffect(() => {
    if (contentId) {
      const quizPlusData = firebase
        .firestore()
        .collection("specialOlympics")
        .doc(contentId)
        .onSnapshot((doc) => {
          if (doc.exists) {
            let data = { ...doc.data(), id: doc.id };
            //console.log(data);
            setQuizData(data);

            let sorted = _.map(data.specialOlympicQuestions, function (value, key) {
              return _.defaults({ order: value.sequenceNo, key: key }, value);
            });

            sorted = _.sortBy(sorted, 'order');
            setSortedQuestion(sorted);

            if (doc.data().createdBy === user_id) {
              setAdmin(true);
            } if (doc.data().previousDeadline || doc.data().deadline < Date.now().valueOf()) {
              setIsClosed(true);
              //props.setIsClosed(true);
            }
          }
        })

        setUnsubscribe(() => quizPlusData)

      /*.then(() => {
          console.log(user_id);
          console.log(quizData);
          if (user_id === quizData.createdBy) {
            console.log("Admin h Bhaiya");
            setAdmin(true);
            getResponses(quizData.response);
          }
          if (quizData && getResult(quizData.response, user_id))
            setAnswers(quizData.response[user_id].value);
        });*/
    }
  }, []);

  useEffect(() => {
    return (() => {
      // @ https://stackoverflow.com/questions/55636020/react-hooks-trying-to-access-state-before-unmount-returns-initial-state
      // should update componentwillunmount when the unsub function is updated else it will have the initial value
      console.log("unmount once", unsubscribe)
      if (unsubscribe) {
        unsubscribe()
      }
    })
  }, [unsubscribe])

  useEffect(() => {
    axios.get(`${API_BASE_URL}${QUIZPLUS_QUESTION_CATEGORIES}`).then((res) => {
      if (res.status === 200) {
        if (res.data.data.question_categories) {
          let allQuestionTypes = {};
          res.data.data.question_categories.forEach((questionType) => {
            allQuestionTypes[questionType.id] = {
              hexCode: questionType.properties?.hexCode,
              name: questionType.name,
              fontCode: questionType.properties ? questionType.properties.fontCode ? questionType.properties.fontCode : null : null
            }
          })
          setAllQuestionTypes(allQuestionTypes)
        }
      }
    })
  }, [])



  useEffect(() => {
    //console.log(quizData.id);
    let response = [...responses];
    const query = firebase
      .firestore()
      .collection(`/specialOlympics/${quizData.id}/responses`);
    query.onSnapshot((snapshot) => {
      let changes = snapshot.docChanges();
      //console.log(changes);
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.exists) {
            let data = change.doc.data();
            let json = {
              ...data,
              special_olympic_id: quizData.id,
              id: change.doc.id,
            };
            response = [...response, { ...json }];
            setResponses(response);

            axios.post(`${API_BASE_URL}${QUIZPLUS_ANALYTICS}`, {
              user_id: props.currentUser.uid,
              workspace_id: props.currentWorkspace.workspaceId,
              room_id: props.currentChannel.roomId,
              publish_special_olympic_id: quizData.id
            }).then((res) => {
              setQuizAnalytics(res.data.data)
              setLoadingAnalytics(false);
            })
          }
          if (change.doc.exists && change.doc.id === props.currentUser.uid) {
            setCurrentUsersResponseExists(true);
          }
        }
        if (change.type === "modified") {
          if (change.doc.exists && change.doc.id === props.currentUser.uid) {
            setCurrentUsersResponseExists(true);
          }
        }
      });
    });
  }, [Object.keys(quizData).length > 1]);

  const getResponsesOfCurrentUserAfterNext = () => {
    if (currentUserResponseExists) {
      let response = [...responses];
      let index = null
      response.forEach((res, i) => {
        if (res.id === props.currentUser.uid) {
          index = i;
        }
      })
      console.log(index, "index i")
      const query = firebase
        .firestore()
        .collection(`/specialOlympics/${quizData.id}/responses`)
        .doc(props.currentUser.uid);
      query.get().then((snapshot) => {
        if (snapshot.exists) {
          let data = snapshot.data();
          let json = {
            ...data,
            special_olympic_id: quizData.id,
            id: snapshot.id,
          };
          response.splice(index, 1, { ...json });
          // response = [...response, { ...json }];
          setResponses(response);
          axios.post(`${API_BASE_URL}${QUIZPLUS_ANALYTICS}`, {
            user_id: props.currentUser.uid,
            workspace_id: props.currentWorkspace.workspaceId,
            room_id: props.currentChannel.roomId,
            publish_special_olympic_id: quizData.id
          }).then((res) => {
            setQuizAnalytics(res.data.data)
            setLoadingAnalytics(false);
          })
        }
      });
    }
  }

  useEffect(() => {
    if (quizData && getResult(responses, user_id)) {
      let obj = responses.find((user) => user.id === user_id);
      let responsedAnswers = { ...obj.answers };

      let answers = [];
      Object.keys(quizData.specialOlympicQuestions)
        .sort(
          (a, b) =>
            quizData.specialOlympicQuestions[a].sequenceNo -
            quizData.specialOlympicQuestions[b].sequenceNo
        )
        .map((q, k) => {
          let mulAnswers = [];

          Object.keys(quizData.specialOlympicQuestions[q].options)
            .sort(
              (a, b) =>
                quizData.specialOlympicQuestions[q].options[a].sequenceNo -
                quizData.specialOlympicQuestions[q].options[b].sequenceNo
            )
            .map((o, key) => {
              if (
                responsedAnswers[q] &&
                responsedAnswers[q].options.includes(o)
              ) {
                mulAnswers.push(
                  quizData.specialOlympicQuestions[q].options[o].optionValue
                );
              }
            });
          if (mulAnswers.length > 0) {
            answers.push(mulAnswers);
          }
          if (mulAnswers.length === 0) {
            // answers.push("");
          }
        });
      console.log(quizData);
      console.log(responsedAnswers);
      console.log(answers);
      setAnswers(answers);
      setSubmittedAnswers(responsedAnswers);
      setSubmit(true);
    }
  }, [responses]);

  const getResponses = (responses) => {
    responses &&
      Object.entries(responses).forEach(([key, value]) => {
        setResponses((prev) => [...prev, { uid: key, ...value }]);
      });
  };

  const timeFromNow = (timestamp) => {
    if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
      return moment(timestamp).fromNow();
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
      return "yesterday";
    else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
      return moment(timestamp).format("dddd");
    else return moment(timestamp).format("MMM DD YYYY");
  };

  const getName = (key) => {
    let name = "";
    firebase
      .firestore()
      .collection("users")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          name = doc.data().firstName + doc.data().lastName;
        }
      });
    return name;
  };


  const [assignedShow, setAssignedShow] = useState(false)
  const [answers, setAnswers] = useState([]);
  const [submittedAnswers, setSubmittedAnswers] = useState([]);
  let [showAns, setShowAns] = useState("");
  const [responseAnswers, setResponseAnswers] = useState([]);
  const [submit, setSubmit] = useState(
    quizData && getResult(responses, user_id)
  );
  const [selectedAnswer, setSelectedAnswer] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(
    quizData && getResult(responses, user_id)
      ? Object.keys(quizData.specialOlympicQuestions).length - 1
      : 0
  );


  const addAnswer = (q_no, ans, type = 0, question_id, option_id) => {
    console.log(q_no, ans, question_id, option_id, "q_no, ans, question_id, option_id")
    let allAnswers = answers;
    let resAnswers = responseAnswers;
    let prev = allAnswers[q_no] ? allAnswers[q_no] : [];
    let selectedAns = {};

    if (type === 0) {
      if (prev.includes(ans)) {
        if (!selectedAns[q_no]) {
          selectedAns[q_no] = {}
        }
      }
      else {
        selectedAns[q_no] = {}
        selectedAns[q_no][option_id] = { ...selectedAns[q_no][option_id], ...ans }
      }

      allAnswers[q_no] = prev.includes(ans)
        ? [...allAnswers[q_no]]
        : [ans];
      let PrevAnswerObj = resAnswers.find(
        (res) => res.question_id === question_id
      );
      let index = resAnswers.findIndex((res) => res.question_id === question_id);
      PrevAnswerObj = PrevAnswerObj ? PrevAnswerObj : {};
      let prevAnswers =
        PrevAnswerObj && PrevAnswerObj.options ? PrevAnswerObj.options : [];
      prevAnswers = prevAnswers.includes(option_id)
        ? [...prevAnswers]
        : [option_id];
      PrevAnswerObj.question_id = question_id;
      PrevAnswerObj.options = [...prevAnswers];
      if (index === -1) {
        resAnswers.push(PrevAnswerObj);
      } else {
        resAnswers.splice(index, 1, PrevAnswerObj);
      }
      setAnswers(allAnswers);
      setResponseAnswers(resAnswers);
      setSelectedAnswer(selectedAns);

    } else {
      if (prev.includes(ans)) {
        if (!selectedAns[q_no]) {
          selectedAns[q_no] = {}
        }
        selectedAns[q_no][option_id] = null
      } else {
        selectedAns[q_no] = {}
        selectedAns[q_no][option_id] = { ...selectedAns[q_no][option_id], ...ans }
      }

      allAnswers[q_no] = prev.includes(ans)
        ? prev.filter((item) => item !== ans)
        : [...prev, ans];
      
      let PrevAnswerObj = resAnswers.find(
        (res) => res.question_id === question_id
      );

      let index = resAnswers.findIndex((res) => res.question_id === question_id);
      PrevAnswerObj = PrevAnswerObj ? PrevAnswerObj : {};

      let prevAnswers =
        PrevAnswerObj && PrevAnswerObj.options ? PrevAnswerObj.options : [];

      prevAnswers = prevAnswers.includes(option_id)
        ? prevAnswers.filter((item) => item !== option_id)
        : [...prevAnswers, option_id];

      PrevAnswerObj.question_id = question_id;
      PrevAnswerObj.options = [...prevAnswers];

      if(PrevAnswerObj.options.length === 0) {
        resAnswers = [];
      }

      else if (index === -1) {
        resAnswers.push(PrevAnswerObj);
      } else {
        resAnswers.splice(index, 1, PrevAnswerObj);
      }

      setAnswers(allAnswers);
      setResponseAnswers(resAnswers);
      setSelectedAnswer(selectedAns);
    }

  };

  const [all, setAll] = useState([]);
  const handleSubmit = async () => {
    let { currentUser, currentWorkspace, currentChannel,darkTheme } = props;
    let requestData = {
      user_id: currentUser.uid,
      room_id: currentChannel.roomId,
      workspace_id: currentWorkspace.workspaceId,
      quiz_id: quizData.id,
      answers: [...responseAnswers],
    };
    // console.log(requestData);
    const result = await axios.post(
      `${API_BASE_URL}${QUIZPLUS_SUBMIT}`,
      {
        user_id: currentUser.uid,
        room_id: currentChannel.roomId,
        workspace_id: currentWorkspace.workspaceId,
        special_olympic_id: quizData.id,
        answers: [...responseAnswers],
      }
    ).then((response) => {
      // console.log(response)
      if (response.status === 200) {
        setResponseAnswers([]);
        if(darkTheme){
          toast.dark("Response submitted", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
        }else{
          toast("Response submitted", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
        }
        
        return (true);
      } else {
        console.log(response, "response else")
        return (false);
      }
    })
      .catch((err) => {
        console.log(err, "err")
        return (false);
      });
    return(result);
  };

  const handleShowAns = (response) => {
    // console.log(response);
    let showAnsObj = response.id;

    if (showAnsObj === showAns) {
      showAnsObj = "";
    }

    setShowAns(showAnsObj);
  };
  const assignedToShow = () => {
    if (assignedShow) {
      setAssignedShow(false)
    } else {
      setAssignedShow(true)
    }
  }

  return (
    <>
      {Object.keys(quizData.specialOlympicQuestions).length > 0 ? (
        <div
          className={
            colorTheme ? `${colorTheme} taskbox` : "taskbox text-theme"
          }
        >
          {/*console.log(quizData, "my olympics")*/}
          <Form
            className="specialOlympics w-100"
            style={
              componentName === "VideoChat"
                ? { minWidth: "200px" }
                : { minWidth: "300px" }
            }
          >
            <div className="d-flex">
              <h5 className="mt-0 flex-grow-1">
                {quizData.specialOlympicName}
              </h5>
              <div>
                {quizData.deadline < Date.now() ? (
                  <span className="text-secondary small">
                    {quizData.closedBy ? (
                      <>
                        Closed by{" "}
                        <Participants
                          userId={quizData.closedBy}
                          nameOnly={true}
                        />
                      </>
                    ) : (
                        "Closed"
                      )}
                  </span>
                ) : (
                    <span className="text-success small">Active</span>
                  )}
              </div>
              {open_task_branch === "saved" && open_task_id === quizData.uid && (
                <div className="mx-1">
                  <Badge variant="success">Saved</Badge>
                </div>
              )}
            </div>
            {quizData.closedBy && quizData.previousDeadline && (
              <div className="text-danger small mb-1">
                <>
                  <span className="text-warning">
                    Quiz closed{" "}
                    {getTimeDifference(
                      quizData.previousDeadline,
                      quizData.deadline
                    )}{" "}
                    before actual deadline.
                  </span>
                </>
              </div>
            )}
            <span className="small d-block">
              Deadline: {timeFromNow(quizData.deadline)}
            </span>
            <p className="mt-0 mb-1">
              Total questions:{" "}
              {Object.keys(quizData.specialOlympicQuestions).length}
            </p>

            {admin &&
              Object.keys(quizData.specialOlympicQuestions).length > 0 &&
              Object.keys(quizData.specialOlympicQuestions)
                .sort(
                  (a, b) =>
                    quizData.specialOlympicQuestions[a].sequenceNo -
                    quizData.specialOlympicQuestions[b].sequenceNo
                )
                .map((item, key) => (
                  <>

                    <Accordion key={key} eventKey={`${key}`} className="text-dark">
                      <Card className="mb-1">
                        <Accordion.Toggle
                          className="my-0 p-2"
                          as={Card.Header}
                          eventKey="0"
                        >
                          <div className="">
                            {
                              quizData.specialOlympicQuestions[item].questionCode ?
                                <>
                                  <div className="d-flex align-items-center">
                                    <div className="rounded m-1" style={{ height: 20, width: 20, backgroundColor: QuestionType(quizData.specialOlympicQuestions[item].questionCode)?.hexCode }}></div>
                                    <small className="font-weight-bold">{QuestionType(quizData.specialOlympicQuestions[item].questionCode)?.name}</small>
                                  </div>
                                </>
                                : null
                            }
                            <div className="" style={{ whiteSpace: 'break-spaces' }}>

                              <span className="font-weight-bold">Q{key + 1}.</span>{" "}
                              {quizData.specialOlympicQuestions[item].question}
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className="p-2">
                            <div >
                              {quizData.specialOlympicQuestions[item].questionImage &&
                                <>
                                  <img
                                    className="img-fluid mb-2"
                                    alt=""
                                    style={{ height: 100, width: "auto" }}
                                    src={quizData.specialOlympicQuestions[item].questionImage}
                                    onClick={() => {
                                      setImageSelected(quizData.specialOlympicQuestions[item].questionImage)
                                      setVisible(true)
                                    }} />

                                </>
                              }
                              {quizData.specialOlympicQuestions[item].description &&
                                <Alert variant="info">{quizData.specialOlympicQuestions[item].description}</Alert>}
                            </div>

                            <ol className="mb-1 px-3 py-2">
                              {Object.keys(
                                quizData.specialOlympicQuestions[item].options
                              )
                                .sort(
                                  (a, b) =>
                                    quizData.specialOlympicQuestions[item].options[a]
                                      .sequenceNo -
                                    quizData.specialOlympicQuestions[item].options[b]
                                      .sequenceNo
                                )
                                .map((o, k) => (
                                  <li key={k} className="mb-1">
                                    <div className="d-flex">
                                      {quizData.specialOlympicQuestions[item].options[o].optionImage &&

                                        <div
                                          className="rounded"
                                          style={{
                                            height: 50,
                                            width: 50,
                                            backgroundImage: `url(${quizData.specialOlympicQuestions[item].options[o].optionImage})`,
                                            backgroundRepeat: "none",
                                            backgroundSize: "contain"
                                          }}
                                          onClick={() => {
                                            setImageSelected(quizData.specialOlympicQuestions[item].options[o].optionImage);
                                            setVisible(true)
                                          }
                                          }
                                        />


                                      }
                                      <span className="flex-grow-1 ml-1">{quizData.specialOlympicQuestions[item].options[o].optionValue}</span>
                                    </div>
                                    {
                                      !loadingAnalytics ?
                                        <ProgressBar
                                          style={{ height: "1rem" }}
                                          className="mb-1"
                                          animated
                                          now={quizAnalytics.percentage[item] ? quizAnalytics.percentage[item][o] ? quizAnalytics.percentage[item][o] : 0 : 0}
                                          label={quizAnalytics.percentage[item] ? quizAnalytics.percentage[item][o] ? quizAnalytics.percentage[item][o] + "%" : 0 + "%" : 0 + "%"}
                                        />
                                        : null
                                    }
                                    {
                                      !loadingAnalytics && quizAnalytics.optionCount[item] ?
                                        quizAnalytics.optionCount[item][o] ? quizAnalytics.optionCount[item][o] + " out of " + quizAnalytics.optionCount[item]["total"] + " participants selected this" : null : null
                                    }
                                  </li>
                                ))}
                            </ol>

                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>

                  </>
                ))}

            {
              ImageViewer(
                imageSelected,
                visible,
                setVisible,
                setImageSelected
              )
            }

            {/* {submit && 
                 <p className="small pb-0 mb-0">
                     Responded <i>{moment(quiz_data.response && quiz_data.response[user_id].responseTime).format("hh:mm A MMM DD YYYY")}</i>
                 </p>} */}

            {admin ? (
              responses.map((response, key) => (
                // <NewWindow>
                <div className="row" key={key}>
                  <div className="col-7">
                    <DisplayResponse response={response} quizData={quizData} />
                  </div>
                  <div className="col-5 d-flex align-items-center">
                    <small
                      onClick={() => handleShowAns(response)}
                      className="ml-auto text-primary font-weight-bold"
                      style={{ cursor: "pointer" }}
                    >
                      {showAns === response.id ? "Hide (-)" : "Show (+)"}
                    </small>
                  </div>
                  {showAns === response.id ? (

                    <DisplayAnswers response={response} quizData={quizData} />
                  )
                    : (
                      ""
                    )}
                </div>
                // </NewWindow>
              ))
            ) : (
                <>
                  {quizData &&
                    Object.keys(quizData.specialOlympicQuestions)
                      .sort(
                        (a, b) =>
                          quizData.specialOlympicQuestions[a].sequenceNo -
                          quizData.specialOlympicQuestions[b].sequenceNo
                      )
                      .map(
                        (q, k) =>
                          currentQuestion === k && (
                            <Card className="task" key={k}>
                              <Card.Header className="bg-white d-flex">
                                <small className="text-dark p-2 mr-auto">
                                  Question {k + 1}
                                </small>
                                {
                                  quizData.specialOlympicQuestions[q].questionCode ?
                                    <div style={{
                                      position: 'absolute',
                                      left: 0,
                                      top: '10px',
                                      marginLeft: '-2px',
                                      transform: 'scale(0.5)'
                                    }}
                                    >
                                      <span class="ribbon3" style={{ backgroundColor: `${QuestionType(quizData.specialOlympicQuestions[q].questionCode)?.hexCode}` }}><span class="ribbon3-after" style={{ borderLeft: `15px solid  ${QuestionType(quizData.specialOlympicQuestions[q].questionCode)?.hexCode}` }}></span></span>

                                    </div>
                                    : null
                                }

                              </Card.Header>
                              <Card.Body className="my-3">
                                <small className="font-weight-bold text-dark">
                                  {answers.filter((item) => item !== null).length}{" "}
                                of{" "}
                                  {
                                    Object.keys(quizData.specialOlympicQuestions)
                                      .length
                                  }{" "}
                                answered
                              </small>

                                <p className="text-dark mb-1" style={{ whiteSpace: 'break-spaces' }}>
                                  {quizData.specialOlympicQuestions[q].question}
                                </p>



                                <div>
                                  {quizData.specialOlympicQuestions[q].questionImage &&
                                    <>
                                      <img
                                        alt=""
                                        className="img-fluid mb-2"
                                        style={{ height: 100, width: "auto" }}
                                        src={quizData.specialOlympicQuestions[q].questionImage}
                                        onClick={() => {
                                          setImageSelected(quizData.specialOlympicQuestions[q].questionImage)
                                          setVisible(true)
                                        }}
                                      />
                                    </>
                                  }
                                </div>

                                {/* {quizData.specialOlympicQuestions[q].questionImage && <div style={{ height: "3rem" }}>
                                <img src={quizData.specialOlympicQuestions[q].questionImage} className="img-fluid" />
                              </div>}
                              <p className="text-theme">{JSON.stringify(quizData.specialOlympicQuestions[q])}</p> */}
                                {Object.keys(
                                  quizData.specialOlympicQuestions[q].options
                                )
                                  .sort(
                                    (a, b) =>
                                      quizData.specialOlympicQuestions[q].options[
                                        a
                                      ].sequenceNo -
                                      quizData.specialOlympicQuestions[q].options[
                                        b
                                      ].sequenceNo
                                  )
                                  .map((o, key) => (
                                    <>
                                      {quizData.specialOlympicQuestions[q]
                                        .options[o].optionImage == null ? null : (
                                          <img
                                            src={
                                              quizData.specialOlympicQuestions[q]
                                                .options[o].optionImage
                                            }
                                            alt=""
                                            style={{ width: "100px" }}
                                            onClick={() => {
                                              setImageSelected(quizData.specialOlympicQuestions[q]
                                                .options[o].optionImage)
                                              setVisible(true)
                                            }}
                                          />
                                        )}
                                      <div
                                        className={
                                          answers[k] &&
                                            answers[k].includes(
                                              quizData.specialOlympicQuestions[q]
                                                .options[o].optionValue
                                            )
                                            ? "form-check rounded mb-2 border-dark"
                                            : "form-check rounded mb-2"
                                        }
                                        key={key}
                                      >
                                        <input
                                          className="form-check-input"
                                          type={
                                            quizData.specialOlympicQuestions[q].type === 0
                                              ? "radio"
                                              : "checkbox"
                                          }
                                          value={
                                            quizData.specialOlympicQuestions[q]
                                              .options[o].optionValue
                                          }
                                          name={
                                            quizData.createdBy +
                                            "_" +
                                            quizData.specialOlympicQuestions[q]
                                              .options[o].optionValue +
                                            k +
                                            key
                                          }
                                          id={
                                            quizData.createdBy +
                                            "_" +
                                            k +
                                            "_" +
                                            key +
                                            "_" +
                                            q
                                          }
                                          onChange={(e) => {
                                            addAnswer(k, e.target.value, quizData.specialOlympicQuestions[q].type, q, o);
                                            setAll(e.target.value);
                                            setNoOptionSelectedError(false);
                                          }}
                                          checked={
                                            answers[k] &&
                                            answers[k].includes(
                                              quizData.specialOlympicQuestions[q]
                                                .options[o].optionValue
                                            )
                                          }
                                          disabled={
                                            submittedAnswers[q]
                                          }
                                        />

                                        <label
                                          className="form-check-label"
                                          htmlFor={
                                            quizData.createdBy +
                                            "_" +
                                            k +
                                            "_" +
                                            key +
                                            "_" +
                                            q
                                          }
                                        >
                                          {
                                            quizData.specialOlympicQuestions[q]
                                              .options[o].optionValue
                                          }
                                        </label>
                                      </div>

                                      {
                                        quizData.specialOlympicQuestions[q].showPercentageToParticipant && !loadingAnalytics && quizAnalytics.percentage[q] && quizAnalytics.percentage[q][o] && answers[k] ?
                                          <ProgressBar
                                            style={{ height: "1rem" }}
                                            className="mb-1"
                                            animated
                                            now={quizAnalytics.percentage[q] ? quizAnalytics.percentage[q][o] ? quizAnalytics.percentage[q][o] : 0 : 0}
                                            label={quizAnalytics.percentage[q] ? quizAnalytics.percentage[q][o] ? quizAnalytics.percentage[q][o] + "%" : 0 + "%" : 0 + "%"}
                                          />
                                          : null
                                      }
                                      <div className="text-dark">
                                        {
                                          quizData.specialOlympicQuestions[q].showPercentageToParticipant && !loadingAnalytics && quizAnalytics.optionCount[q] && answers[k] ?
                                            quizAnalytics.optionCount[q][o] ? quizAnalytics.optionCount[q][o] + " out of " + quizAnalytics.optionCount[q]["total"] + " participants selected this" : null : null
                                        }
                                      </div>

                                    </>
                                  ))}
                                {
                                  quizData.specialOlympicQuestions[q].showDescriptionAfter &&
                                    answers[k]
                                    ?
                                    quizData.specialOlympicQuestions[q].description ?
                                      <p className="mb-1 p-2" style={{ backgroundColor: QuestionType(quizData.specialOlympicQuestions[q].questionCode)?.hexCode, borderRadius: '10px', color: QuestionType(quizData.specialOlympicQuestions[q].questionCode) ? QuestionType(quizData.specialOlympicQuestions[q].questionCode)?.fontCode ?  QuestionType(quizData.specialOlympicQuestions[q].questionCode)?.fontCode : '#ffffff' : '#ffffff'}} >
                                        {quizData.specialOlympicQuestions[q].description}
                                      </p>
                                      : null
                                    : null
                                }
                                {/* description shown right when quiz loads */}
                                {
                                  !quizData.specialOlympicQuestions[q].showDescriptionAfter ?
                                    quizData.specialOlympicQuestions[q].description ?
                                      <p className="text-white mb-1 p-2" style={{ backgroundColor: QuestionType(quizData.specialOlympicQuestions[q].questionCode)?.hexCode, borderRadius: '10px' }} >
                                        {quizData.specialOlympicQuestions[q].description}
                                      </p>
                                      : null
                                    : null
                                }
                              </Card.Body>
                              <div className="d-flex ml-auto p-3">
                                {currentQuestion !== 0 && (
                                  <Button
                                    size="sm"
                                    variant="light"
                                    style={{
                                      width: "100px",
                                      marginRight: "5px",
                                    }}
                                    onClick={() => {
                                      setCurrentQuestion((prev) => prev - 1)
                                      setNoOptionSelectedError(false);
                                    }
                                    }
                                  >
                                    <small>Previous</small>
                                  </Button>
                                )}

                                {currentQuestion !==
                                  Object.keys(quizData.specialOlympicQuestions)
                                    .length -
                                  1 && (
                                    <Button
                                      size="sm"
                                      variant="dark"
                                      style={{
                                        width: "100px",
                                        marginLeft: "5px",
                                      }}
                                      onClick={() => {
                                        if (submittedAnswers[q]) {
                                          setCurrentQuestion((prev) => prev + 1)
                                        } else {
                                          handleSubmit().then((response) => {
                                            if (response) {
                                              getResponsesOfCurrentUserAfterNext();
                                              setCurrentQuestion((prev) => prev + 1)
                                              setNoOptionSelectedError(false);
                                            } else if(!response) {
                                              setNoOptionSelectedError(true);
                                            }
                                          })
                                        }
                                      }
                                      }
                                    >
                                      <small>Next</small>
                                    </Button>
                                  )}


                                {currentQuestion ===
                                  Object.keys(quizData.specialOlympicQuestions)
                                    .length -
                                  1 &&
                                  (
                                    submittedAnswers[sortedQuestions[sortedQuestions.length - 1]?.key]
                                      ? (
                                        <Button
                                          size="sm"
                                          variant="primary"
                                          style={{
                                            width: "100px",
                                            marginLeft: "5px",
                                          }}
                                          disabled
                                        >
                                          <small>Completed</small>
                                        </Button>
                                      ) : (
                                        <Button
                                          size="sm"
                                          variant="success"
                                          style={{
                                            width: "100px",
                                            marginLeft: "5px",
                                          }}
                                          onClick={() => {
                                            handleSubmit().then(() => getResponsesOfCurrentUserAfterNext())
                                          }
                                          }
                                          disabled={responseAnswers.length === 0}
                                        >
                                          <small>Submit</small>
                                        </Button>
                                      ))}
                              </div>
                              <div className="text-right px-3">
                                {
                                  noOptionSelectedError ?
                                    <p className="text-danger">Note: Please select an option</p>
                                    : null
                                }
                              </div>
                            </Card>
                          )
                      )}
                </>
              )}
          </Form>
          {submit && (
            <div
              className="d-flex mt-2 pt-2 pb-1"
              style={{
                borderTop: 2,
                borderTopStyle: "dashed",
                borderTopColor: "#dbdbdb",
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
              <p
                className="small pb-0 mb-0 flex-grow-1 px-2"
                style={{ color: "#777B8E" }}
              >
                Response recorded{" "}
                {moment(
                  quizData.response &&
                  quizData.response[user_id] &&
                  quizData.response[user_id].responseTime
                ).format("MMM DD, YYYY hh:mm A")}
              </p>
            </div>
          )}
        </div>
      ) : null}{" "}

      {admin ? (
        props.view === 2 ? (
          <>
            <div className="mt-2 d-flex justify-content-between">
              <div className="text-theme mt-3">Assigned To </div>
              <div className="text-primary mt-3 pointer" onClick={() => assignedToShow()}>{assignedShow ? "Hide(-)" : "Show(+)"}</div>
            </div>
            {assignedShow ? (
              <>
                {/*console.log(currAssignment,"respoxx")*/}
                <AssignedUsers
                  taskData={quizData}
                />
              </>
            ) : null
            }
          </>
        ) : null
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  open_task_branch: state.platform.open_task_branch,
  open_task_id: state.platform.open_task_id,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, null)(Quiz);
