import React from "react";
import {
  ProgressBar,
  Button,
  Form,
  ButtonGroup,
  InputGroup,
  Dropdown,
  DropdownButton,
  FormControl,
  Accordion,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faCloudUploadAlt,
  faLink,
  faAngleDown,
  faTimes,
  faExclamationTriangle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import DateTimePicker from "react-datetime-picker";
import firebase from "firebase";
import uuidv4 from "uuid/v4";
import mime from "mime-types";
import moment from "moment";
import { connect } from "react-redux";
import {
  setReadingTitles,
  setReadingLinks,
  setReadingFiles,
  setReadingUrls,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskEveryone,
  resetData,
  //addTask,
  //saveTask,
} from "../../../actions/index";
import TaskCommon from "../Common/TaskCommon";
//import axios from "axios";
import { postData } from "../../../utils/index";
import { toast } from "react-toastify";
import { API_BASE_URL, DRIVE_FILE } from "../../../config";
toast.configure();

class AddReading extends React.Component {
  state = {
    storageRef: firebase.storage().ref(),
    uploadTask: null,
    uploadState: [],
    percentUploaded: [],
    fileSizeError:"",
    authorized: [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/svg+xml",
      "image/gif",

      "application/pdf",

      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ],
    extension: "",

    addAll: false,
    filesWithBase64: [],
    allFolders: [],

    errorObj: { ReadingSubject: "", titles: [] },
    currentActiveKey: "1",
  };
  componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;
    let allFolders = [];
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/folders`
      );
    // const query = firebase.firestore().collection("folders");
    query.onSnapshot((snapshot) => {
      let changes = snapshot.docChanges();
      console.log(changes);
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.exists) {
            let data = change.doc.data();
            let json = { ...data, id: change.doc.id };
            allFolders.unshift(json);
          }
        }
      });
      this.setState({ allFolders });
    });
  }
  componentWillUnmount() {
    if (this.state.uploadTask !== null) {
      this.state.uploadTask.cancel();
      this.setState({ uploadTask: null });
    }
  }
  removeFile = (key) => {
    const { files } = this.props;
    const { filesWithBase64 } = this.state;
    let prev = [...files];
    let prevFiles = [...filesWithBase64];
    prev.splice(key, 1);
    prev.splice(key, 0, "");
    prevFiles.splice(key, 1);
    prevFiles.splice(key, 0, "");
    this.props.setReadingFiles(prev);
    this.setState({ filesWithBase64: prevFiles });
  };
  /*getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };*/
  addFile = (event, key) => {
    const { files } = this.props;
    // const { filesWithBase64 } = this.state;
    let prev = [...files];
    //let prevFiles = [...filesWithBase64];
    let file = event.target.files[0];

    if (event.target.files[0]) {
      prev[key] = file;
      this.props.setReadingFiles(prev);
      /*let result = this.getBase64(event.target.files[0]);
      result.then((data) => {
        //console.log(data);
        prevFiles[key] = data;
        prev[key] = file;
        prev[key].file_content = data;
        this.props.setReadingFiles(prev);
        this.setState({ filesWithBase64: prevFiles });
      });*/
    }
    this.setState({fileSizeError: ""})
  };
  removeUrl = (key) => {
    const { urls } = this.props;

    let prev = [...urls];
    prev.splice(key, 1);
    prev.splice(key, 0, "");
    this.props.setReadingUrls(prev);
  };
  // ADDING READING
  addReading = () => {
    let prevTitles = [...this.props.titles];
    prevTitles[prevTitles.length] = "";
    this.setState({ currentActiveKey: prevTitles.length });

    let prevLinks = [...this.props.links];
    prevLinks[prevLinks.length] = "";
    

    let prevUrls = [...this.props.urls];
    prevUrls[prevUrls.length] = "";

    this.props.setReadingTitles(prevTitles);
    this.props.setReadingLinks(prevLinks);
    this.props.setReadingUrls(prevUrls);
  };

  // REMOVING READING
  removeReading = (key) => {
    let { filesWithBase64, errorObj } = this.state;
    let prevTitles = [...this.props.titles];
    prevTitles.splice(key, 1);

    let prevLinks = [...this.props.links];
    prevLinks.splice(key, 1);

    let prevUrls = [...this.props.urls];
    prevUrls.splice(key, 1);

    let prevFiles = [...this.props.files];
    prevFiles.splice(key, 1);

    //filesWithBase64.splice(key, 1);
    this.props.setReadingFiles(prevFiles);
    this.props.setReadingTitles(prevTitles);
    this.props.setReadingLinks(prevLinks);
    this.props.setReadingUrls(prevUrls);
    errorObj.titles[key + 1] = "";
    this.setState({ errorObj });
  };

  // HANDLES TITLES ADDED
  handleTitles = (e, key) => {
    let prev = [...this.props.titles];
    prev[key] = e.target.value;
    this.props.setReadingTitles(prev);
  };
  // HANDLES LINKS ADDED
  handleLinks = (e, key) => {
    let prev = [...this.props.links];
    prev[key] = e.target.value;
    this.props.setReadingLinks(prev);
  };
  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));
  /*uploadFile = async (file, key) => {
    console.log("Key: ", key, "\nFile: ", file);
    const { currentUser } = this.props;
    if (file) {
      try {
        let res = await axios.post(`${API_BASE_URL}${DRIVE_FILE}`, {
          user_id: currentUser.uid,
          file_name: file.name,
          file_content_type: file.type,
          file_content: file.file_content,
        });

        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
      }
    } else return "";
  };*/
  handleChange = (e) => {
    if (e.target.name === "folder") {
      this.props.setTaskFolder(e.target.value);
    }
  };
  render() {
    const {
      type,
      titles,
      links,
      files,
      urls,
      channelUsers,
      deadline,
      folder,
      everyone,
      currentChannel,
      currentWorkspace,
      currentUser,
      allParticipants,
      ReadingDescription,
      ReadingSubject,
      handleSubAndDesc,
      saveTaskForLater,
      editedReading,
      shareTask,
      validateData,
      error,
    } = this.props;
    const { filesWithBase64, errorObj, allFolders } = this.state;
    const image_type = [
      "image/jpeg",
      "image/png",
      "image/svg+xml",
      "image/gif",
    ];

    const pdf_type = ["application/pdf"];

    const word_type = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    const excel_type = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const powerpoint_type = [
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];

    const validateDataOfField = (e) => {
      let { errorObj } = this.state;

      if (e.target.name === "titles[]") {
        if (e.target.value.trim().length < 1) {
          errorObj.titles[+e.target.id + 1] = "Title cannot be empty.";
        } else {
          errorObj.titles[+e.target.id + 1] = "";
        }
      }
      if (e.target.name === "ReadingSubject") {
        if (e.target.value.trim().length < 1) {
          errorObj.ReadingSubject = "Subject cannot be empty.";
        } else {
          errorObj.ReadingSubject = "";
        }
      }
      this.setState({ errorObj });
    };
    return (
      <React.Fragment>
        <div className="text-theme">
          <Form>
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
              <p className="mb-2 font-weight-bold text-theme small">File</p>
              <div className="mb-3">
                <InputGroup className="mb-1">
                  <FormControl
                    placeholder="Subject"
                    aria-label="Subject"
                    aria-describedby={"Subject"}
                    name="ReadingSubject"
                    value={ReadingSubject}
                    onKeyUp={(e) => validateDataOfField(e)}
                    onBlur={(e) => validateDataOfField(e)}
                    autoFocus={true}
                    onChange={(e) => handleSubAndDesc(e)}
                    required={true}
                  />
                </InputGroup>
                {errorObj.ReadingSubject ? (
                  <p className="mb-1 small text-danger">
                    {errorObj.ReadingSubject}
                  </p>
                ) : (
                  ""
                )}
                <InputGroup className="mb-1">
                  <FormControl
                    placeholder="Description"
                    aria-label="Description"
                    aria-describedby={"Description"}
                    name="ReadingDescription"
                    value={ReadingDescription}
                    onChange={(e) => handleSubAndDesc(e)}
                    required={true}
                  />
                </InputGroup>
              </div>

              <Accordion activeKey={`${this.state.currentActiveKey}`} defaultActiveKey="1">
              {titles.length > 0
                ? titles.map((title, key) => (
                  <>
                    <Card className="bg-theme my-1">
                      
                        <div className="d-flex">
                        <Accordion.Toggle
                          eventKey={`${key + 1}`}
                          as={Card.Header}
                          variant="link"
                          className="flex-grow-1 p-2 pointer border-bottom-0 bg-theme"
                          onClick={() => {
                            if (this.state.currentActiveKey === key + 1)
                              this.setState({ currentActiveKey: null })
                            else
                              this.setState({ currentActiveKey: key + 1 })
                          }}>
                          <p className="mb-0 flex-grow-1 text-theme">
                            File {key + 1}.
                          </p>
                          <div>
                            {this.props.titles.map((title, index) => (
                              <>
                                {(index === key) ? (
                                <div>
                                  {title}
                                </div>): null}
                              </>
                            ))}
                          </div>
                          <div>
                            {errorObj.titles[key + 1] ? (
                              <p className="mb-1 small text-danger">
                                {errorObj.titles[key + 1]}
                              </p>
                              ) : (
                                ""
                            )}
                          </div>
                        </Accordion.Toggle>
                        <div
                          className="mt-1 mr-1 pointer"
                          //style={{verticalAlign:"top", height: "1.5rem", width: "1.5rem", backgroundColor: "rgba(255,255,255,0.2)" }}
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            onClick={() => this.removeReading(key)}
                            title="Delete"
                          />
                        </div>
                        </div>
                        <>
                          <Accordion.Collapse eventKey={`${key + 1}`}>
                            <Card.Body className="pt-1 px-2">
                              <InputGroup className="mb-1">
                                <FormControl
                                  placeholder="File title"
                                  aria-label="File title"
                                  aria-describedby={"title" + key}
                                  name="titles[]"
                                  id={key}
                                  autoFocus={titles.length === key + 1}
                                  value={titles[key]}
                                  onKeyUp={(e) => validateDataOfField(e)}
                                  onBlur={(e) => validateDataOfField(e)}
                                  onChange={(e) => this.handleTitles(e, key)}
                                  required={true}
                                />
                              </InputGroup>
                              {errorObj.titles[key + 1] ? (
                                <p className="mb-1 small text-danger">
                                  {errorObj.titles[key + 1]}
                                </p>
                              ) : (
                                ""
                              )}
                              <InputGroup className="mb-1">
                                <FormControl
                                  placeholder="Paste link to video or file"
                                  aria-label="File link"
                                  aria-describedby={"link" + key}
                                  as="textarea"
                                  name="links[]"
                                  value={links[key]}
                                  onChange={(e) => this.handleLinks(e, key)}
                                  required={true}
                                  size="sm"
                                />
                              </InputGroup>
                              {files[key] || urls[key] ? (
                        <div>
                          {files[key] ? (
                            <div className="d-flex rounded bg-light mt-2">
                              <div
                                className="py-2 px-3"
                                style={{ backgroundColor: "#FEF2E4" }}
                              >
                                {!this.state.authorized.includes(
                                  files[key].type
                                ) && (
                                  <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    className="text-secondary"
                                  />
                                )}
                                {image_type.includes(files[key].type) && (
                                  <FontAwesomeIcon
                                    icon={faImage}
                                    className="text-secondary"
                                  />
                                )}
                                {word_type.includes(files[key].type) && (
                                  <FontAwesomeIcon
                                    icon={faFileWord}
                                    className="text-primary"
                                  />
                                )}
                                {pdf_type.includes(files[key].type) && (
                                  <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="text-danger"
                                  />
                                )}
                                {powerpoint_type.includes(files[key].type) && (
                                  <FontAwesomeIcon
                                    icon={faFilePowerpoint}
                                    className="text-danger"
                                  />
                                )}
                                {excel_type.includes(files[key].type) && (
                                  <FontAwesomeIcon
                                    icon={faFileExcel}
                                    className="text-success"
                                  />
                                )}
                              </div>
                              <div className="p-2 flex-grow-1">
                                <small className="font-weight-bold text-dark">
                                  {files[key].name}
                                </small>
                              </div>
                              <div className="p-2 flex-grow-1 d-flex justify-content-end align-items-center">
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  onClick={() => this.removeFile(key)}
                                  className="text-dark"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          ) : (
                            urls[key] && (
                              <div className="d-flex rounded bg-light mt-2 align-items-center">
                                {urls[key] && (
                                  <>
                                    <a
                                      href={urls[key]}
                                      target="_blank"
                                      className="px-2"
                                    >
                                      <p className="text-secondary align-items-center mb-0">
                                        Attachment Link{" "}
                                        <FontAwesomeIcon
                                          icon={faLink}
                                          className="text-primary"
                                        />
                                      </p>
                                    </a>
                                  </>
                                )}
                                <div className="p-2 flex-grow-1 d-flex justify-content-end align-items-center">
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    onClick={() => this.removeUrl(key)}
                                    className="text-dark"
                                    style={{ cursor: "pointer" }}
                                            />
                                </div>
                                </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  ""
                              )}
                              <InputGroup className="mb-1">
                                {!files[key] && !urls[key] ? (
                                  <FormControl
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="file"
                                    name={key}
                                    onChange={(e) => {
                                      if(e.target.files[0].size < 20971520){
                                        this.addFile(e, key)
                                      }else {
                                        this.setState({fileSizeError: "The maximum file size is 20MB. Please choose a different file."})
                                      }
                                    }}
                                  />
                                ) : null}
                              </InputGroup>
                              {this.state.fileSizeError && 
                              <p className="text-danger small">{this.state.fileSizeError}</p>}
                            </Card.Body>
                          </Accordion.Collapse>
                        </>
                      
                    </Card>
                  </>
                ))
                : null}
              </Accordion>
    
              <InputGroup className="w-100">
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="w-100 mb-2"
                  onClick={this.addReading}
                >
                  + <small>Add File</small>
                </Button>
              </InputGroup>
              <p className="mb-2 font-weight-bold text-theme small">
                Associate folder
              </p>
              <InputGroup className="mb-3">
                <select
                  name="folder"
                  class="custom-select custom-select-sm"
                  onChange={this.handleChange}
                >
                  <option
                    selected={folder === "" || folder === null ? true : false}
                    value=""
                  >
                    Uncategorized
                  </option>
                  {allFolders.map((item) => (
                    <option
                      value={item.id}
                      selected={folder === item.id ? true : false}
                    >
                      {item.folderName}
                    </option>
                  ))}
                </select>
              </InputGroup>
            </div>
            <TaskCommon
              allParticipants={allParticipants}
              editedTask={Object.keys(editedReading).length > 0 ? true : false}
            />
            {error ? <p className="mb-1 small text-danger">{error}</p> : ""}
            <div className="m-2">
              <p className="my-2 p-0 text-danger small d-block">
                {this.state.error}
              </p>
              {
                this.props.editingPublished ?
                  <Button
                    onClick={() => validateData() && shareTask()}
                    variant="primary"
                    style={{ width: "100px", marginRight: "5px" }}
                    size="sm"
                    disabled ={this.props.readingMessageData?.closedByAdmin}
                  >
                    <small>
                    {
                        this.props.readingMessageData?.closedByAdmin ?
                          <FontAwesomeIcon icon={faExclamationTriangle} color="yellow"></FontAwesomeIcon>
                          : null
                      }Save changes</small>
                  </Button> :
                  <Button
                    onClick={() => validateData() && shareTask()}
                    variant="primary"
                    style={{ width: "100px", marginRight: "5px" }}
                    size="sm"
                    //disabled={editedReading?.closedByAdmin}
                  >
                    <small>
                      Share now</small>
                  </Button>
              }


              <Button
                onClick={() => validateData() && saveTaskForLater()}
                variant="success"
                style={{ width: "100px", marginLeft: "5px" }}
                size="sm"
              >
                <small>Save for later</small>
              </Button>
            </div>
          </Form>
          
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,
  type: state.reading.type,
  titles: state.reading.titles,
  links: state.reading.links,
  files: state.reading.files,
  urls: state.reading.urls,

  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  everyone: state.task.everyone,
});

export default connect(mapStateToProps, {
  setReadingTitles,
  setReadingLinks,
  setReadingFiles,
  setReadingUrls,

  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskEveryone,

  //addTask,
  //saveTask,
  resetData,
})(AddReading);
