import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faPlusCircle,
  faAngleDown,
  faArrowLeft,
  faTimes,
  faSearch,
  faFilter,
  faQuestionCircle,
  faExclamationTriangle,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faEllipsisV,
  faTrash,
  faEdit,
  faCircle,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";

import {
  Dropdown,
  Button,
  DropdownButton,
  Badge,
  Form,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
  FormControl,
  Spinner,
  NavDropdown,
} from "react-bootstrap";

import { connect } from "react-redux";
import firebase from "firebase";

import {
  setRightPanel,
  setShowPrescription,
  setPrescriptionEditId,
} from "../../../actions/index";

const Prescription = ({
  setContentType,
  setTaskType,
  resetAllTasks,
  currentWorkspace,
  currentChannel,
  currentUser,
  darkTheme,
  workspaceId,
  setShowPrescription,
  showPrescription,
  setPrescriptionEditId,
}) => {
  const [allPrescriptions, setAllPrescriptions] = useState([]);
  const [isLoading, setIsLoading] =useState(true)

  const togglePrescription = () => {
    console.log(showPrescription);
    setShowPrescription(!showPrescription);
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("prescriptions")
      .where("room_id", "==", currentChannel.roomId)
      .where("workspace_id", "==", workspaceId)
      .orderBy("created_at", "desc")
      .onSnapshot((doc) => {
        let tempPrescriptions = [];
        if (!doc.empty) {
          doc.docs.forEach((item) => {
            console.log(item.data());
            tempPrescriptions.push({ ...item.data(), id: item.id });
          });
          setAllPrescriptions(tempPrescriptions);
          setIsLoading(false)
        } else {
          setAllPrescriptions([]);
          setIsLoading(false)
        }
      });
  }, []);

  const deletePrescription = (item) => {
    firebase
      .firestore()
      .collection("prescriptions")
      .doc(item.id)
      .delete()
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div
        style={{
          position: "-webkit-sticky",
          position: "sticky",
          zIndex: 10,
          top: 0,
          cursor: "pointer",
        }}
        className="m-2 rounded p-2 bg-card-theme text-theme"
      >
        <div className="d-flex-row">
          <div
            onClick={() => {
              setContentType(null);
              setTaskType(null);
              resetAllTasks();
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
            <span>Prescription</span>
          </div>
          <div className="d-flex mt-2">
            <Button
              size="sm"
              variant="primary"
              className="pt-0 w-50"
              onClick={togglePrescription}
            >
              <small>+ ADD NEW PRESCRIPTION</small>
            </Button>
          </div>
        </div>
      </div>

      { isLoading? <div className="text-center text-success mt-3" ><FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-2x" /> </div> :allPrescriptions.length ? (
        allPrescriptions.map((item) => (
          <div
            style={{
              position: "relative",
            }}
            className="m-2 rounded p-2 bg-card-theme text-theme"
          >
            <div className="d-flex-row px-2">
              {console.log(item)}
              <div className="text-secondary font-11">
                <span>
                  {item.created_at?.toDate().toDateString()}
                  <span className="ml-2">
                    {item.created_at?.toDate().toLocaleTimeString()}
                  </span>
                </span>
              </div>
              <div className="text-white font-14 font-weight-bold">
                {item.patient_name
                  ? item.patient_name.length > 20
                    ? item.patient_name.slice(0, 20) + "..."
                    : item.patient_name
                  : null}
              </div>

              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
                className=" dropleft"
              >
                <span
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="p-2"
                >
                  <FontAwesomeIcon icon={faEllipsisV} className="font-13" />
                </span>
                <div
                  className="dropdown-menu px-2"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    className="font-13 cp my-1 py-1"
                    onClick={() => {
                      setShowPrescription(true);
                      setPrescriptionEditId(item.id);
                    }}
                  >
                    <b>
                      <FontAwesomeIcon icon={faEdit} className="mx-2" />
                      <span>Edit</span>
                    </b>
                  </div>
                  <div
                    className="font-13 cp my-1 py-1"
                    onClick={() => deletePrescription(item)}
                  >
                    <b>
                      <FontAwesomeIcon icon={faTrash} className="mx-2" />
                      <span>Delete</span>
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center text-white font-14 mt-2">
          No prescriptions found!
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
  showPrescription: state.prescription.showPrescription,
  workspaceId: state.workspace.currentWorkspace.workspaceId,
});

export default connect(mapStateToProps, {
  setRightPanel,
  setShowPrescription,
  setPrescriptionEditId,
})(Prescription);
