import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Spinner, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSignOutAlt,
  faCamera,
  faCheck,
  faPencilAlt,
  faTimes,
  faAngleDown,
  faEdit,
  faArchive,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import Participant from "./UserNameAvatar";
import { Button, InputGroup, Form } from "react-bootstrap";
import { postData } from "../../utils/index";
import { API_BASE_URL, ROOM_LEAVE, USERS_ROLE_CHANGE, ROOMS_PARTICIPANTS} from "../../config/index";
import axios from "axios";
import { toast } from "react-toastify";
import _ from 'lodash';
import firebase from 'firebase';

toast.configure();

function Participants(props) {
  const {
    participants,
    participantsLoading,
    currentWorkspace,
    currentChannel,
    allParticipants,
    currentUser,
    darkTheme,
    createrId,
  } = props;

  useEffect(() => {
    // firebase
    //     .firestore()
    //     .collection(`workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/participants`)
    //     .onSnapshot(snapshot => {
    //         let allParticipants1 = [];

    //         snapshot.forEach(doc => {
    //             allParticipants1.push({uid: doc.id, ...doc.data()})
    //         })

    //         console.log("allParticipants1", allParticipants1)

    //         setAllParticipants(allParticipants1)
    //         setLoading(participantsLoading)
    //     })

    setTimeout(() => {
      //     setAllParticipants(participants)
      setLoading(participantsLoading);
    }, 300);
  }, [currentWorkspace, currentChannel, participantsLoading]);

  // const [allParticipants, setAllParticipants] = useState([])
  const [loading, setLoading] = useState(true);
  const [allParticipantsSorted, setAllParticipantsSorted] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [regex, setRegex] = useState("");

  const handleAction = (ACTION, user_id) => {
    const actionData = {
      workspace_id: currentWorkspace.workspaceId,
      room_id: currentChannel.roomId,
      user_id,
    };

    postData(`${API_BASE_URL}${ACTION}`, actionData)
      .then((response) => {
        const { status, message } = response;

        if (status === 200) {
        } else {
          console.log(`Error message ${ACTION} room: `, message);
        }
      })
      .catch((err) => console.error(err));
  };
  const makeAdmin = async (participantId) => {
    let requestData = {
      workspace_id: currentWorkspace.workspaceId,
      room_id: currentChannel.roomId,
      participant_id: participantId,
      admin_id: currentUser.uid,
      role_type: 1,
    };
    //console.log(requestData);
    let response = await axios.post(
      `${API_BASE_URL}${USERS_ROLE_CHANGE}`,
      {
        ...requestData,
      }
    );
    if(darkTheme){
      toast.dark(response.data["message"], {
        position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000
      });
    }else{
      toast(response.data["message"], {
        position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000
      });
    }
    
  };
  const removeAdmin = async (participantId) => {
    let requestData = {
      workspace_id: currentWorkspace.workspaceId,
      room_id: currentChannel.roomId,
      participant_id: participantId,
      admin_id: currentUser.uid,
      role_type: 2,
    };
    console.log(requestData);
    let response = await axios.post(
      `${API_BASE_URL}${USERS_ROLE_CHANGE}`,
      {
        ...requestData,
      }
    );
    if(darkTheme){
      toast.dark(response.data["message"], {
        position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000
      });
    }else{
      toast(response.data["message"], {
        position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000
      });
    }
    
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));
  //console.log(allParticipants);
  //console.log(currentUser);
  let coAdmins = allParticipants ? allParticipants.filter((participant) => participant.isAdmin) : [];


  useEffect(() => {
    if (allParticipants.length > 0) {
      let participantsWithName = [];
      axios.post(`${API_BASE_URL}${ROOMS_PARTICIPANTS}`, {
        workspace_id: currentWorkspace.workspaceId,
        room_id:  currentChannel?.roomId,
      }).then((res) => {
        if(res.status === 200) {
          //console.log(res.data.data,"yzz my participation")
          setAllParticipantsSorted(res.data.data);
        }
      })

     /* allParticipants.forEach((part, index) => {
        firebase.firestore().collection("users").doc(`${part.uid}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              participantsWithName.push({ ...part, displayName: doc.data().displayName, avatar: doc.data().avatar });
            } else {
            }
          }).then(() => {
            const sortedByName = _.sortBy(participantsWithName, (p) => p.displayName.toLowerCase())
            const sortedByRoleAndName = _.orderBy(sortedByName, 'isAdmin' ,['desc']);
            console.log(sortedByRoleAndName,"yzz")
            //setAllParticipantsSorted(sortedByRoleAndName);
          })
          .catch(error => console.error(error));

      })*/
    }
  }, [allParticipants])



  return (
    <div>
      {loading ? (
        <Spinner animation="border" />
      ) : (
      <>
          <InputGroup className="my-1">
                <div className="d-flex align-items-center rounded flex-grow-1  bg-theme">
                  <div className="py-2 pr-2 pl-3">
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="sm"
                      color="#aeaeae"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <Form.Control
                      type="text"
                      id="participantsSearch"
                      className="border-0 px-0 bg-theme text-theme rounded"
                      onChange={(e) => {
                          let value = e.target.value;
                          setSearchTerm( e.target.value);
                          const reg = _.escapeRegExp(searchTerm);
                          // console.log(reg,"Reg")
                          const regex = new RegExp(value, "gi");
                          setRegex(regex)
                          //this.setState({ regex: regex })
                        
                      }}
                      placeholder={"Search"}
                      value={searchTerm}
                      style={{ margin: 0 }}
                    />
                  </div>
                  {searchTerm.length > 0 && (
                    <div
                      className="py-2 pl-2 pr-3"
                      onClick={() => setSearchTerm("")}
                    >
                      <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                    </div>
                  )}
                </div>
              </InputGroup>
           {
            searchTerm && searchTerm.length > 0 ? (
            <>
              {allParticipantsSorted.filter((item) => {
               // console.log(item.displayName,"item ")
                return item.displayName?.match(regex)
              }).map((participant) => (
                <>
                  <div>
                  <Participant userId={participant.uid} avatar={participant.avatar} displayName={participant.displayName} showYou={true}>
                    <div className="d-flex">
                      <p className="small text-warning mb-0">
                        {participant.isAdmin ? "Co-admin" : ""}
                      </p>
                      {currentChannel.createdBy === currentUser.uid ||
                      (coAdmins &&
                        coAdmins.find((par) => par.uid === currentUser.uid)) ? (
                        <Dropdown
                          className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer"
                          style={{ marginTop: "-24px" }}
                        >
                          <Dropdown.Toggle
                            size="sm"
                            variant="white"
                            as={CustomToggle}
                          />

                          <Dropdown.Menu className="bg-card-theme text-theme">
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => handleAction(ROOM_LEAVE, participant.uid)}
                            >
                              Remove
                            </Dropdown.Item>

                            {participant.isAdmin ? (
                              <Dropdown.Item
                                className="small text-warning bg-card-theme "
                                onClick={() => removeAdmin(participant.uid)}
                              >
                                Remove co-admin
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                className="small text-success bg-card-theme"
                                onClick={() => makeAdmin(participant.uid)}
                              >
                                Make co-admin
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : null}
                    </div>
                  </Participant>
                </div>
                </>
              ))}
            </>) : (
              <>
              {allParticipantsSorted.map((participant) => (
                <>
                <div>
                <Participant userId={participant.uid} avatar={participant.avatar} displayName={participant.displayName} showYou={true}>
                  <div className="d-flex">
                    <p className="small text-warning mb-0">
                      {participant.isAdmin ? "Co-admin" : ""}
                    </p>
                    {currentChannel?.createdBy === currentUser.uid ||
                    (coAdmins &&
                      coAdmins.find((par) => par.uid === currentUser.uid)) ? (
                      <Dropdown
                        className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer"
                        style={{ marginTop: "-24px" }}
                      >
                        <Dropdown.Toggle
                          size="sm"
                          variant="white"
                          as={CustomToggle}
                        />

                        <Dropdown.Menu className="bg-card-theme text-theme">
                          <Dropdown.Item
                            className=" small bg-card-theme text-theme"
                            onClick={() => handleAction(ROOM_LEAVE, participant.uid)}
                          >
                            Remove
                          </Dropdown.Item>

                          {participant.isAdmin ? (
                            <Dropdown.Item
                              className="small text-warning bg-card-theme"
                              onClick={() => removeAdmin(participant.uid)}
                            >
                              Remove co-admin
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item
                              className="small text-success bg-card-theme"
                              onClick={() => makeAdmin(participant.uid)}
                            >
                              Make co-admin
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : null}
                  </div>
                </Participant>
              </div>
              </>
              ))}
              </>
            )}
      </>)}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  participantsLoading: state.channel.participantsLoading,
  allParticipants: state.channel.participants,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps)(Participants);