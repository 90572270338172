import React from "react";
import {
  Button,
  Form,
  ButtonGroup,
  InputGroup,
  FormControl,
  Dropdown,
  Spinner,
  Accordion,
  Card,
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faAngleDown,
  faImages,
  faCheckCircle,
  faExclamationTriangle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { connect } from "react-redux";
import firebase from "firebase";
import {
  setQuizTitle,
  setQuizQuestions,
  setQuizOptionsImages,
  setQuizQuestionImages,
  setQuizQuestionPreviewImages,
  setQuizPreviewImages,
  setQuizOptions,
  setQuizTypes,
  setQuizCorrectAnswers,
  setQuizWeights,
  setQuizTotalWeight,
  setQuizGraded,
  setQuizRandomize,
  setQuizRandomizeQuestion,
} from "./actions";
import {
  resetData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
} from "../../../actions/index";
import TaskCommon from "../Common/TaskCommon";
//import firebase from "firebase";
import axios from "axios";
import {
  API_BASE_URL,
  QUIZ_CREATE,
  QUIZ_SHARE,
  QUIZ_REMOVE,
  QUIZ_SUBMIT,
  QUIZ_UPDATE,
} from "../../../config/index";
import { toast } from "react-toastify";
toast.configure();

class AddQuiz extends React.Component {
  state = {
    errorObj: { title: "", questions: [] },
    allFolders: [],
    saveForLaterLoading:false,
    shareNowLoading:false,
    error:"",
    key:"",
    currentActiveKey: "1",
  };

  componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;
    let allFolders = [];
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/folders`
      );
    // const query = firebase.firestore().collection("folders");
    query.onSnapshot((snapshot) => {
      let changes = snapshot.docChanges();
      console.log(changes);
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.exists) {
            let data = change.doc.data();
            let json = { ...data, id: change.doc.id };
            allFolders.unshift(json);
          }
        }
      });
      this.setState({ allFolders });
    });
  }
  /*async componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;
    let savedQuizes = [];
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
      )
      .orderBy("timestamp", "desc");

    await query.onSnapshot((snapshot) => {
      // console.log(snapshot.docChanges());

      let changes = snapshot.docChanges();
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.data().type === 4) {
            let data = change.doc.data();
            let json = { ...data, id: change.doc.id };
            savedQuizes.unshift(json);
          }
        } else if (change.type === "removed") {
          if (change.doc.data().type === 4) {
            savedQuizes = savedQuizes.filter(
              (savedQuiz) => savedQuiz.id !== change.doc.id
            );
          }
        }
      });
      this.handlePollsData(savedQuizes);
    });
  }
  handlePollsData = (savedQuizes) => {
    let savedQuizesData = [];
    savedQuizes.forEach((savedQuiz) => {
      firebase
        .firestore()
        .collection(`quiz`)
        .doc(savedQuiz.contentId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          let data = doc.data();
          let json = { ...data, id: doc.id };
          savedQuizesData.push(json);
        })
        .then(() => {
          savedQuizesData.sort((a, b) => b.timestamp - a.timestamp);
          this.setState({ savedQuizes, savedQuizesData });
        });
    });
    if (savedQuizes.length === 0) {
      this.setState({ savedQuizes });
    }
  };*/
  handleChange = (e) => {
    if (e.target.name === "quizTitle") {
      this.props.setQuizTitle(e.target.value);
    }
    if (e.target.name === "folder") {
      this.props.setTaskFolder(e.target.value);
    }
  };
  handleChangeRandomize = (e) => {
    //console.log(e.target.checked);
    if (e.target.name === "randomize") {
      this.props.setQuizRandomize(e.target.checked);
    }
  };

  handleChangeRandomizeQuestion = (e) => {
    //console.log(e.target.checked);
    if (e.target.name === "randomize") {
      this.props.setQuizRandomizeQuestion(e.target.checked);
    }
  };

  // ADDING QUESTION + OPTIONS + CORRECT_ANSWER
  addQuestion = () => {
    let prevQuestion = [...this.props.questions];
    prevQuestion[prevQuestion.length] = "";
    this.props.setQuizQuestions(prevQuestion);
    this.setState({ currentActiveKey: prevQuestion.length });

    let prevOptions = [...this.props.options];
    prevOptions[prevOptions.length] = [""];
    this.props.setQuizOptions(prevOptions);

    let prevImageOption = [...this.props.imageOptions];
    prevImageOption[prevImageOption.length] = [""];
    this.props.setQuizOptionsImages(prevImageOption);
    let prevPreviewImageOption = [...this.props.previewImageOptions];
    prevPreviewImageOption[prevPreviewImageOption.length] = [""];
    this.props.setQuizPreviewImages(prevPreviewImageOption);

    let prevCorrectAnswer = [...this.props.correctAnswers];
    prevCorrectAnswer[prevCorrectAnswer.length] = 0;
    this.props.setQuizCorrectAnswers(prevCorrectAnswer);

    let prevWeights = [...this.props.weights];
    prevWeights[prevWeights.length] = 0;
    this.props.setQuizWeights(prevWeights);

    let prevTypes = [...this.props.types];
    prevTypes[prevTypes.length] = "single";
    this.props.setQuizTypes(prevTypes);
  };
  addOption = (i) => {
    //console.log("option",this.props.options[i].length)
    if(this.props.options[i].length < 8){
    let prevOptions = this.props.options;
    let thatOption = prevOptions[i];
    prevOptions[i] = [...thatOption, ""];
    this.props.setQuizOptions(prevOptions);
    this.props.setQuizQuestions([...this.props.questions]);
    }else{
      this.setState({error: "Options can't be more than 8.", key:i})
      return
    }
  };

  // REMOVING QUESTION + OPTIONS
  removeQuestion = (key) => {
    let prevQuestions = [...this.props.questions];
    prevQuestions.splice(key, 1);
    this.props.setQuizQuestions(prevQuestions);

    let prevOptions = [...this.props.options];
    prevOptions.splice(key, 1);
    this.props.setQuizOptions(prevOptions);

    let prevImageOption = [...this.props.imageOptions];
    prevImageOption.splice(key, 1);
    this.props.setQuizOptionsImages(prevImageOption);

    let prevPreviewImageOptions = [...this.props.previewImageOptions];
    prevPreviewImageOptions.splice(key, 1);
    this.props.setQuizPreviewImages(prevPreviewImageOptions);

    let prevAnswers = [...this.props.correctAnswers];
    prevAnswers.splice(key, 1);
    this.props.setQuizCorrectAnswers(prevAnswers);

    let prevWeights = [...this.props.weights];
    prevWeights.splice(key, 1);
    this.props.setQuizWeights(prevWeights);

    let prevTypes = [...this.props.types];
    prevTypes.splice(key, 1);
    this.props.setQuizTypes(prevTypes);
  };
  removeOption = (key, k) => {

    let prevOptions = this.props.options;
    let thatOption = prevOptions[key];

    let prevImage = this.props.previewImageOptions;
    let thatImg = prevImage[key];

    let imgOption = this.props.imageOptions;
    let thatImages = imgOption[key];

    let prevAnswers = [...this.props.correctAnswers];

    if (thatOption[k] == prevAnswers[key]) {
      prevAnswers[key] = thatOption[k + 1] ? thatOption[k + 1] : 0;
      thatOption.splice(k, 1);
      thatImg.splice(k, 1);
      // thatImages.splice(k, 1);
      this.props.setQuizCorrectAnswers(prevAnswers);
    } else {
      thatOption.splice(k, 1);
      thatImg.splice(k, 1);
      // thatImages.splice(k, 1);  
    }

    prevOptions[key] = thatOption;
    prevImage[key] = thatImg;
    imgOption[key] = thatImages;
    this.props.setQuizPreviewImages(prevImage);
    this.props.setQuizOptionsImages(imgOption);
    this.props.setQuizOptions(prevOptions);
    this.props.setQuizQuestions([...this.props.questions]);

  };

  // HANDLES QUESTIONS ADDED
  handleQuestions = (e, key) => {
    let prev = [...this.props.questions];
    prev[key] = e.target.value;
    this.props.setQuizQuestions(prev);
  };
  handleQuestionImages = (e, key) => {
    if(e === null){
      let prevQuestionImages = this.props.quizQuestionImages;
      prevQuestionImages[key] = null;
      this.props.setQuizQuestionImages(prevQuestionImages);
      this.props.setQuizQuestions([...this.props.questions]);

      
        let prevImg = this.props.questionPreviewImages;
        prevImg[key] = null;
        this.props.setQuizQuestionPreviewImages(prevImg);
        this.props.setQuizQuestions([...this.props.questions]);
      
    }else{
      const reader = new FileReader();

      let prevQuestionImages = this.props.quizQuestionImages;
      let thatImageOption = e.target.files[0];
      prevQuestionImages[key] = thatImageOption;
      this.props.setQuizQuestionImages(prevQuestionImages);
      this.props.setQuizQuestions([...this.props.questions]);

      reader.readAsDataURL(e.target.files[0]);

      reader.addEventListener("load", () => {
        let prevImg = this.props.questionPreviewImages;
        prevImg[key] = reader.result;
        this.props.setQuizQuestionPreviewImages(prevImg);
        this.props.setQuizQuestions([...this.props.questions]);
      });
    }
    
  };
  handleOptions = (e, key, k) => {
    let prevOptions = this.props.options;
    let thatOption = [...prevOptions[key]];
    // console.log(prevOptions);
    //console.log(thatOption);
    thatOption[k] = e.target.value;
    prevOptions[key] = thatOption;
    // console.log(prevOptions);
    this.props.setQuizOptions(prevOptions);
    this.props.setQuizQuestions([...this.props.questions]);
    //console.log(this.props.options);
  };
  /*handleAnswer = (val, key,optionValue) => {
    const { types, correctAnswers, setQuizCorrectAnswers } = this.props;
    let prev = [...correctAnswers];
    if (types[key] === "single") {
      prev[key] = val;
    } else {
      let temp = prev[key] ? prev[key] : [];
      prev[key] = temp.includes(val)
        ? temp.filter((item) => item !== val)
        : [...temp, val];
    }
    setQuizCorrectAnswers(prev);
  };*/
  handleOptionsImages = (e, key, k) => {
    const reader = new FileReader();

    let prevImageOptions = this.props.imageOptions;
    let thatImageOptions = [...prevImageOptions[key]];

    thatImageOptions[k] = e.target.files[0];

    //console.log(thatImageOptions, "imageOptions");
    prevImageOptions[key] = thatImageOptions;
    this.props.setQuizOptionsImages(prevImageOptions);
    this.props.setQuizQuestions([...this.props.questions]);
    //console.log("prevImageOption", prevImageOptions);
    //console.log("this.props.imageOptions", this.props.imageOptions);

    reader.readAsDataURL(e.target.files[0]);

    reader.addEventListener("load", () => {
      let prevImg = this.props.previewImageOptions;
      let thatImgOption = [...prevImg[key]];
      thatImgOption[k] = reader.result;
      //console.log(thatImgOption, "prev");
      prevImg[key] = thatImgOption;
      this.props.setQuizPreviewImages(prevImg);
      this.props.setQuizQuestions([...this.props.questions]);
      //console.log("prevImage", typeof prevImg);
      // console.log("preview Image Options",this.props.previewImageOptions)
    });
  };
  handleAnswer = (val, key, optionValue) => {
    const {
      types,
      correctAnswers,
      setQuizCorrectAnswers,
      options,
    } = this.props;
    let prev = [...correctAnswers];

    if (types[key] === "single") {
      prev[key] = optionValue ? optionValue : options[key][val];
    } else {
      let temp = prev[key] ? prev[key] : [];
      prev[key] = temp.includes(optionValue)
        ? temp.filter((item) => item !== optionValue)
        : [...temp, optionValue];
    }
    //console.log(prev);
    setQuizCorrectAnswers(prev);
  };
  handleWeights = (e, key) => {
    let prev = [...this.props.weights];
    prev[key] = parseInt(e.target.value);
    const sum = prev.reduce((partial_sum, a) => partial_sum + a,0);

    //console.log(sum);
    this.props.setQuizTotalWeight(parseInt(sum))
    this.props.setQuizWeights(prev);
  };
  handleTypes = (val, key) => {
    let prev = [...this.props.types];
    prev[key] = val ? "multiple" : "single";
    this.props.setQuizTypes(prev);
  };

  render() {
    const {
      quizTitle,
      questions,
      options,
      imageOptions,
      types,
      correctAnswers,
      weights,
      totalWeight,
      graded,
      allParticipants,
      editedQuiz,
      getQuiz,
      saveForLaterTask,
      shareTask,
      validateData,
      addTask,
      saveTask,
      error,
      folder,
    } = this.props;
     //console.log(this.props.randomize, "respoxx1");
    //console.log(questions, "respoxx2");
    //console.log(options , "respoxx3");
    //console.log(types, "respoxx4");
    //console.log(correctAnswers , "respoxx4");
    //console.log(editedQuiz, "respoxx5");

    const validateDataOfField = (e) => {
      let { errorObj } = this.state;
      if (e.target.name === "questions[]") {
        if (e.target.value.trim().length < 1) {
          errorObj.questions[+e.target.id + 1] = "Question cannot be empty.";
        } else {
          errorObj.questions[+e.target.id + 1] = "";
        }
      }
      if (e.target.name === "quizTitle") {
        if (e.target.value.trim().length < 1) {
          errorObj.title = "Title cannot be empty.";
        } else {
          errorObj.title = "";
        }
      }
      this.setState({ errorObj });
    };

    let { errorObj, allFolders } = this.state;
    return (
      <React.Fragment>
        
        <div className="taskBox text-theme">
          <Form>
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
              <p className="mb-2 font-weight-bold text-theme small">
                Quiz Title
              </p>
              <InputGroup className="mb-3">
                <FormControl
                  size="sm"
                  placeholder="Quiz Title"
                  aria-label="Quiz Title"
                  aria-describedby="quiz-title"
                  name="quizTitle"
                  autoFocus={true}
                  onKeyUp={(e) => validateDataOfField(e)}
                  onBlur={(e) => validateDataOfField(e)}
                  onChange={this.handleChange}
                  value={quizTitle}
                />
              </InputGroup>
              {errorObj.title ? (
                <p className="small text-danger">{errorObj.title}</p>
              ) : (
                ""
              )}

<Accordion activeKey={`${this.state.currentActiveKey}`} defaultActiveKey="1">
            {questions.length > 0 &&
                questions.map((question, key) => (<>
                <Card className="bg-theme my-1">

                {/*console.log(questions, options, "respoxx")*/}
                  <div className="d-flex">
                    <Accordion.Toggle
                      eventKey={`${key + 1}`}
                      as={Card.Header}
                      variant="link"
                      className="flex-grow-1 p-2 pointer border-bottom-0 bg-theme"
                      onClick={() => {
                        if (this.state.currentActiveKey === key + 1)
                          this.setState({ currentActiveKey: null })
                        else
                          this.setState({ currentActiveKey: key + 1 })
                      }}>
                        <p className="mb-0 flex-grow-1 small font-weight-bold">
                          Question {key + 1}
                        </p>
                        <div>
                          {this.props.questions.map((question, index) => (
                            <>
                              {(index === key) ? (
                              <div>
                                {question}
                              </div>): null}
                            </>
                          ))}
                          <div>
                            {(this.props.options[key]) ? 
                            (<div>
                              {`(${this.props.options[key].length} options`}{(this.props.imageOptions[key][1]) ?  "+ images.)" : ")"}
                            </div>)
                              : null
                          }
                          
                          </div>
                          
                        </div>
                        {/*question[key] &&
                          <div className="d-flex align-items-center">
                            <div className="rounded m-1" ></div>
                              <p className="mb-1">{question[key]}</p>
                        </div>*/}
                          {graded ? (
                          <InputGroup className="mb-2">
                            <FormControl
                              placeholder="Weight"
                              name="weights[]"
                              value={weights[key]}
                              id={key}
                              type="number"
                              min="0"
                              onChange={(e) => this.handleWeights(e, key)}
                            />
                            <InputGroup.Append>
                              <InputGroup.Text> points</InputGroup.Text>
                            </InputGroup.Append>
                          </InputGroup>
                        ) : ("")}
                        {errorObj.questions[key + 1] ? (
                          <p className="small text-danger">
                            {errorObj.questions[key + 1]}
                          </p>
                          ) : (
                          ""
                        )}
                    </Accordion.Toggle>
                    
                    <div
                      className="pointer  mt-1 mr-1  "
                      //style={{ height: "1.5rem", width: "1.5rem", backgroundColor: "rgba(255,255,255,0.2)" }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          onClick={() => this.removeQuestion(key)}
                          title="Delete"
                        />
                    </div>
                  </div>
                  <>
                    <Accordion.Collapse eventKey={`${key + 1}`}>
                      <Card.Body className="pt-1 px-2">
                        <InputGroup className="mb-1">
                          <FormControl
                            placeholder="Question"
                            aria-label="Question"
                            aria-describedby={"question" + key}
                            as="textarea"
                            name="questions[]"
                            value={questions[key]}
                            id={key}
                            autoFocus={key === questions.length - 1}
                            onKeyUp={(e) => validateDataOfField(e)}
                            onBlur={(e) => validateDataOfField(e)}
                            onChange={(e) => this.handleQuestions(e, key)}
                            required={true}
                          />
                        </InputGroup>
                        {errorObj.questions[key + 1] ? (
                          <p className="small text-danger">
                            {errorObj.questions[key + 1]}
                          </p>
                          ) : (
                          ""
                        )}
                        
                        <Form.Group className="mb-1">
                          <Form.Check
                            required
                            label="Multiple answers"
                            value={types[key] === "multiple" ? true : false}
                            checked={types[key] === "multiple" ? true : false}
                            onChange={(e) =>
                              this.handleTypes(
                                types[key] === "multiple" ? false : true,
                                key
                              )
                            }
                          />
                        </Form.Group>
                        {/*console.log(options, "respoxx")*/}






                        <p className="mb-0 mt-1 font-weight-bold text-theme small">
                            Question image
                          </p>
                          <div className="d-flex mb-2">
                            {this.props?.questionPreviewImages[key] && (<div className="flex-grow-1">
                              <img
                                alt=""
                                className="rounded img-responsive"
                                style={{
                                  background: "white",
                                  height: 60,
                                  width: "auto",
                                }}
                                src={this.props.questionPreviewImages[key]}
                              />
                              </div>)}
                            <div
                              className="m-1 pointer"
                              style={{
                                position: "relative",
                                height: 30,
                                width: 30,
                              }}
                            >
                              <div
                                className="bg-light d-flex align-items-center justify-content-center"
                                style={{
                                  borderRadius: 10,
                                  position: "absolute",
                                  cursor: "pointer",
                                  height: 30,
                                  width: 30,
                                  borderWidth: 0.1,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faImages}
                                  className="text-dark"
                                />
                              </div>
                              <input
                                id={"que" + key}
                                type="file"
                                accept="image/*"
                                onChange={(e) => this.handleQuestionImages(e, key)}
                                style={{
                                  position: "absolute",
                                  opacity: 0,
                                  height: 30,
                                  width: 30,
                                }}
                                //disabled={this.props.isNonEditable}
                              />
                            </div>
                            {this.props?.questionPreviewImages[key] ? 
                              (<FontAwesomeIcon
                                icon={faTimes}
                                className="text-dark"
                                onClick={(e) => this.handleQuestionImages(null, key)}
                              />
                              ):(null)}
                          </div>










                        {options[key].map((option, k) => (
                          <>
                            {this.props.previewImageOptions[key][k] && (
                              <div>
                                <img
                                  className="rounded img-responsive mb-1"
                                  style={{
                                    background: "white",
                                    height: 60,
                                    width: "auto",
                                  }}
                                  src={this.props.previewImageOptions[key][k]}
                                />
                              </div>
                            )}
                            <InputGroup className="mb-1" key={k}>
                              <InputGroup.Prepend>
                                <InputGroup.Text>
                                  {options[key][k] && (
                                    <Form.Check
                                      onChange={() =>
                                        this.handleAnswer(k, key, option)
                                      }
                                      type={
                                        types[key] === "single"
                                          ? "radio"
                                          : "checkbox"
                                      }
                                      checked={
                                        types[key] === "single"
                                          ? correctAnswers[key] === option
                                            ? true
                                            : false
                                          : correctAnswers[key] &&
                                            (correctAnswers[key].includes(option) ||
                                              correctAnswers[key].includes(k))
                                          ? true
                                          : false
                                      }
                                      name={"options_" + key}
                                    />
                                  )}
                                </InputGroup.Text>
                              </InputGroup.Prepend>

                              <FormControl
                                placeholder="Option"
                                name={options[key]}
                                value={options[key][k]}
                                id={k}
                                onChange={(e) => this.handleOptions(e, key, k)}
                                required={true}
                              />
                              <InputGroup.Append>
                                <InputGroup.Text>
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    onClick={() => this.removeOption(key, k)}
                                  />
                                </InputGroup.Text>
                              </InputGroup.Append>
                              <div
                                className="m-1 pointer"
                                style={{
                                  position: "relative",
                                  height: 30,
                                  width: 30,
                                }}
                              >
                                <div
                                  className="bg-light d-flex align-items-center justify-content-center"
                                  style={{
                                    borderRadius: 10,
                                    position: "absolute",
                                    cursor: "pointer",
                                    height: 30,
                                    width: 30,
                                    borderWidth: 0.1,
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faImages}
                                    className="text-dark"
                                  />
                                </div>
                                <input
                                  id={k}
                                  type="file"
                                  accept="image/*"
                                  //name={options[key]}
                                  onChange={(e) =>
                                    this.handleOptionsImages(e, key, k)
                                  }
                                  style={{
                                    position: "absolute",
                                    opacity: 0,
                                    height: 30,
                                    width: 10,
                                  }}
                                />
                              </div>
                            </InputGroup>
                          </>
                        ))}
                        <InputGroup className="w-100 mb-3">
                          <Button
                            size="sm"
                            className="w-100"
                            onClick={() => this.addOption(key)}
                          >
                            + <small>Add Option</small>
                          </Button>
                        </InputGroup>
                        <div className="text-danger mb-2">
                          {this.state.error && (key === this.state.key) ? this.state.error :null}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </>
                  
                </Card>
                </>))}
            </Accordion>

              <InputGroup className="w-100 mb-2">
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="w-100"
                  onClick={this.addQuestion}
                >
                  + <small>Add Question</small>
                </Button>
              </InputGroup>
              <p className="mb-2 font-weight-bold text-theme small">
                Associate folder
              </p>
              <InputGroup className="mb-3">
                <select
                  name="folder"
                  class="custom-select custom-select-sm"
                  onChange={this.handleChange}
                >
                  <option
                    selected={folder === "" || folder === null ? true : false}
                    value=""
                  >
                    Uncategorized
                  </option>
                  {allFolders.map((item) => (
                    <option
                      value={item.id}
                      selected={folder === item.id ? true : false}
                    >
                      {item.folderName}
                    </option>
                  ))}
                </select>
              </InputGroup>
            </div>

            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
              <p className="mb-2 font-weight-bold text-theme small">
                Gradebook
              </p>
              <Form.Group className="mb-1">
                <Form.Check
                  required
                  label="Add to Gradebook"
                  value="graded"
                  checked={graded}
                  onChange={() => this.props.setQuizGraded(!graded)}
                />
              </Form.Group>

              {graded && (
                <>
                  <p className="pb-0 mb-0">Total weight</p>
                  <InputGroup className="mb-3">
                    <FormControl
                      placeholder="Weight"
                      name="totalWeight"
                      type="number"
                      min="0"
                      onChange={(e) =>
                        this.props.setQuizTotalWeight(parseInt(e.target.value))
                      }
                      value={totalWeight}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text> points</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </>
              )}
            </div>
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
              <Form.Group className="mb-1">
                <Form.Check
                  required
                  name="randomize"
                  label="Randomize options order"
                  // value={this.props.randomize == "true" ? true : false}
                  checked={this.props.randomize == true}
                  onChange={(e) => this.handleChangeRandomize(e)}
                />
              </Form.Group>
            </div>
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
              <Form.Group className="mb-1">
                <Form.Check
                  required
                  name="randomize"
                  label="Randomize Questions order"
                  // value={this.props.randomize == "true" ? true : false}
                  checked={this.props.randomizeQuestion === true}
                  onChange={(e) => this.handleChangeRandomizeQuestion(e)}
                />
              </Form.Group>
            </div>
            <TaskCommon
              allParticipants={allParticipants}
              editedTask={Object.keys(editedQuiz).length > 0 ? true : false}
            />
               {error ? <p className="small text-danger">{error}</p> : ""}
            <div className="m-2">
              <p className="my-2 p-0 text-danger small d-block">
                {this.state.error}
              </p>
              {
                this.props.editingPublished && !this.props.editedQuizHasResponses ?
                  <Button
                    onClick={() =>
                      validateData() &&
                      addTask(shareTask()) &&
                      this.setState({ shareNowLoading: true })
                    }
                    variant="primary"
                    style={{ width: "100px", marginRight: "5px" }}
                    size="sm"
                    disabled={this.state.shareNowLoading || this.props.quizMessageData?.closedByAdmin}
                  >
                    {
                        this.props.quizMessageData?.closedByAdmin ?
                          <FontAwesomeIcon icon={faExclamationTriangle} color="yellow"></FontAwesomeIcon>
                          : null
                      }
                    <small>Save changes</small>
                    {this.state.shareNowLoading && (
                      <Spinner
                        as="span"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        className="mr-1"
                        size="sm"
                      />
                    )}
                  </Button>
                  :
                  <Button
                    onClick={() =>
                      validateData() &&
                      addTask(shareTask()) &&
                      this.setState({ shareNowLoading: true })
                    }
                    variant="primary"
                    style={{ width: "100px", marginRight: "5px" }}
                    size="sm"
                    disabled={this.state.shareNowLoading || (this.props.editingPublished && this.props.editedQuizHasResponses)  || this.props.quizMessageData?.closedByAdmin}
                  >
                    <small>
                      {
                        this.props.editingPublished && this.props.editedQuizHasResponses  || this.props.quizMessageData?.closedByAdmin ?
                          <FontAwesomeIcon icon={faExclamationTriangle} color="yellow"></FontAwesomeIcon>
                          : null
                      }
                      Share now </small>
                    {this.state.shareNowLoading && (
                      <Spinner
                        as="span"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        className="mr-1"
                        size="sm"
                      />
                    )}
                  </Button>
              }

              {/*console.log(editedQuiz.closedByAdmin, this.props.quizMessageData.closedByAdmin," closed quizz")*/}
              <Button
                onClick={() =>
                  validateData() &&
                  saveTask(saveForLaterTask()) &&
                  this.setState({ saveForLaterLoading: true })
                }
                variant="success"
                style={{ width: "120px", marginLeft: "5px" }}
                size="sm"
                disabled={this.state.saveForLaterLoading}
              >
                <small>Save for later </small>
                {this.state.saveForLaterLoading && (
                  <Spinner
                    as="span"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                    className="mr-1"
                    size="sm"
                  />
                )}
              </Button>
                  

              {
                this.props.editingPublished && this.props.editedQuizHasResponses || this.props.quizMessageData?.closedByAdmin ?
                  <div className="text-warning mt-2">It can't be Edited/Share Again as it already has responses. Either close it or delete it.</div>
                  :
                  null
              }


            </div>
          </Form>
          {/* {savedQuizes.length > 0 ? (
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
              {savedQuizesData.map((Quiz, index) => (
                <div key={index} className="d-flex justify-content-center">
                  <div>
                    <small className="text-theme">
                      {moment(Quiz.timestamp).format("LLL")}
                    </small>
                    <p className="mb-2 font-weight-bold text-warning small">
                      {Quiz.quizName}
                    </p>
                  </div>
                  <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right">
                    <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="small"
                        onClick={() => shareSavedTask(Quiz.id)}
                      >
                        Share now
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="small"
                        onClick={() => editSavedTask(Quiz.id)}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="small"
                        onClick={() => deleteSavedTask(Quiz.id)}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}*/}
         
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,

  type: state.quiz.type,
  quizTitle: state.quiz.quizTitle,
  questions: state.quiz.questions,
  questionPreviewImages: state.quiz.questionPreviewImages,
  quizQuestionImages: state.quiz.quizQuestionImages,

  previewImageOptions: state.quiz.previewImageOptions,
  imageOptions: state.quiz.imageOptions,
  options: state.quiz.options,
  types: state.quiz.types,
  correctAnswers: state.quiz.correctAnswers,
  weights: state.quiz.weights,
  totalWeight: state.quiz.totalWeight,
  graded: state.quiz.graded,
  randomize: state.quiz.randomize,
  randomizeQuestion: state.quiz.randomizeQuestion,

  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  everyone: state.task.everyone,
});

export default connect(mapStateToProps, {
  setQuizTitle,
  setQuizRandomize,
  setQuizRandomizeQuestion,
  setQuizQuestionImages,
  setQuizQuestionPreviewImages,
  setQuizQuestions,
  setQuizPreviewImages,
  setQuizOptions,
  setQuizOptionsImages,
  setQuizTypes,
  setQuizCorrectAnswers,
  setQuizWeights,
  setQuizTotalWeight,
  setQuizGraded,
  resetData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,

  // addTask,
  // saveTask,
})(AddQuiz);
