import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { Card, Spinner } from "react-bootstrap";
import firebase from "firebase";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactHeight } from "react-height";
import Avatar from "react-avatar";
import moment from "moment";
import { deviceDetect, deviceType, browserName } from "react-device-detect";

const VideoQnA = ({ currentChannel, currentUser, currentWorkspace }) => {
  const [messagesEnd, setMessagesEnd] = useState(null);
  const scrollToBottom = () => {
    messagesEnd.scrollIntoView({ behavior: "smooth" });
  };
  const [totalQuestions, setTotalQuestions] = useState(0);

  const [hasMore, setHasMore] = useState(true);

  const [messageContentHeight, setMessageContentHeight] = useState(0);
  const [currentChannelQuestions, setCurrentChannelQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typingUsers, setTypingUsers] = useState([]);
  const [limitToLast, setLimitToLast] = useState(10);
  const [showMessageTopLoader, setShowMessageTopLoader] = useState(false);

  const [currentOffsetYBottom, setCurrentOffsetYBottom] = useState(0);
  const [currentOffsetY, setCurrentOffsetY] = useState(0);

  const add_questions_listner = () => {
    if (messageContentHeight) {
      let loadedQuestions = [];
      let unsub;

      const countOfInitialMessages = +(messageContentHeight / 67 + 1).toFixed(
        0
      );
      const query = firebase
        .firestore()
        .collection(`/${currentChannel.eventId}/questionsAsked`)
        .orderBy("timestamp", "desc")
        .limit(countOfInitialMessages);

      const now = new Date().valueOf();

      unsub = query.onSnapshot(function (snapshot) {
        console.log(snapshot);
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            console.log("Added: ", change.doc.data());
            loadedQuestions.length > 0 &&
            change.doc.data().timestamp >= loadedQuestions[0].timestamp
              ? loadedQuestions.unshift({
                  uid: change.doc.ref.id,
                  ...change.doc.data(),
                })
              : loadedQuestions.push({
                  uid: change.doc.ref.id,
                  ...change.doc.data(),
                });
          }
        });
        console.log("loadedQuestions", loadedQuestions);
        let prevLoadedQuestions = loadedQuestions;
        prevLoadedQuestions = _.cloneDeep(prevLoadedQuestions);
        setCurrentChannelQuestions(prevLoadedQuestions);
        setLoading(false);
      });
    }
  };
  useEffect(() => {
    add_questions_listner();
  }, [messageContentHeight, currentChannel]);
  const onScrollQuestions = (e) => {
    let element = e.target;
    //console.log(element.clientHeight);

    // console.log(element, "element")

    if (browserName === "Edge") {
      setCurrentOffsetY(element.scrollTop);
      setCurrentOffsetYBottom(
        element.scrollHeight - element.clientHeight - element.scrollTop
      );
    } else {
      setCurrentOffsetY(-element.scrollTop);
      setCurrentOffsetYBottom(-element.scrollTop);
    }
  };

  const getTotalQuestions = async () => {
    const QuestionsCount = await firebase
      .firestore()
      .collection(`/${currentChannel.eventId}/questionsAsked`)
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot.size);
        return querySnapshot.size;
      });
    setTotalQuestions(QuestionsCount);
  };

  const fetchQuestions = () => {
    if (currentChannelQuestions.length > 0) {
      console.log("fetchQuestions");

      setLimitToLast((prev) => prev + 10);
      setShowMessageTopLoader(true);

      let loadedQuestions = [...currentChannelQuestions];

      getTotalQuestions();

      // console.log("all loaded",totalMessages, loadedQuestions.length)
      if (totalQuestions === loadedQuestions.length) {
        console.log("all loaded", totalQuestions, loadedQuestions.length);
        setHasMore(false);
      }

      console.log(currentChannelQuestions, "timestamp bug");

      const query = firebase
        .firestore()
        .collection(`/${currentChannel.eventId}/questionsAsked`)
        .orderBy("timestamp", "desc")
        .startAfter(
          currentChannelQuestions[currentChannelQuestions.length - 1].timestamp
        )
        .limit(12);

      query.onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            console.log("Added: ", change.doc.data());
            loadedQuestions.length > 0 &&
            change.doc.data().timestamp >= loadedQuestions[0].timestamp
              ? loadedQuestions.unshift({
                  uid: change.doc.ref.id,
                  ...change.doc.data(),
                })
              : loadedQuestions.push({
                  uid: change.doc.ref.id,
                  ...change.doc.data(),
                });
          }
        });
        // console.log("loadedQuestions on scroll ", loadedQuestions);
        loadedQuestions = _.cloneDeep(loadedQuestions);
        setCurrentChannelQuestions(loadedQuestions);
        // _handleStateChange("messages", loadedMessages);
        setTimeout(() => setShowMessageTopLoader(false), 800);
      });
    }
  };
  console.log(totalQuestions);
  console.log(currentChannelQuestions);

  return (
    <ReactHeight
      onHeightReady={(height) => setMessageContentHeight(height)}
      style={{ height: "100%" }}
    >
      <div style={{ height: "100%" }}>
        <div
          id="scrollableDivVideoQnA"
          className="content-messages"
          style={{ height: "100%" }}
          onScroll={onScrollQuestions}
        >
          <div ref={(node) => setMessagesEnd(node)}></div>
          {!loading && currentChannelQuestions.length > 0 ? (
            <InfiniteScroll
              dataLength={currentChannelQuestions.length}
              next={fetchQuestions}
              hasMore={currentChannelQuestions.length > 0 ? hasMore : false}
              loader={
                <div
                  style={{ padding: 10 }}
                  className="d-flex justify-content-center"
                >
                  <Spinner
                    animation="border"
                    className="bg-theme text-success"
                  />
                </div>
              }
              inverse={true}
              scrollableTarget="scrollableDivVideoQnA"
              style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
            >
              {currentChannelQuestions.map((item) => (
                <div className="row w-100 mb-2 text-theme">
                  <div className="col-2">
                    <Avatar
                      size="30"
                      textSizeRatio={1.75}
                      round={true}
                      name={item.name}
                    />
                  </div>
                  <div className="col-10 border border-1 rounded p-1">
                    <small>{item.name}</small>
                    <p className="mb-0 small">{item.question}</p>
                    <div className="d-flex flex-row-reverse">
                      <small className="text-muted">
                        {moment(item.timestamp).format("LLL")}
                      </small>
                    </div>
                  </div>
                </div>
              ))}

              {currentOffsetYBottom > 100 && (
                <div
                  style={{
                    position: "absolute",
                    right: 25,
                    height: 50,
                    width: 50,
                  }}
                  className="bg-white rounded-circle border-white shadow-sm d-flex justify-content-center align-items-center"
                  onClick={() => scrollToBottom()}
                >
                  <FontAwesomeIcon
                    className=" text-muted"
                    icon={faAngleDoubleDown}
                    style={{ fontSize: 20 }}
                  />
                </div>
              )}
            </InfiniteScroll>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </ReactHeight>
  );
};
const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
    currentWorkspace: state.workspace.currentWorkspace,
    currentChannel: state.platform.channelDetails
      ? state.platform.channelDetails
      : {},
  };
};

export default connect(mapStateToProps, null)(VideoQnA);
