import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Button,Form, Dropdown, Badge, Modal, Spinner, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faReply,
  faAngleDown,
  faTimes,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import Feedback from "../Tasks/Feedback";
import Poll from "../Tasks/Poll/Poll";
// import Poll from "../Tasks/Poll/PollMessage";
import Quiz from "../Tasks/Quiz/Quiz";
import Project from "../Tasks/Project";
import List from "../Tasks/List";
import Assignment from "../Tasks/Assignment/Assignment1";
//import Cloud from "../Tasks/Cloud/Cloud";
import Reading from "../Tasks/Reading/Reading1";
import Schedule from "../Tasks/Schedule/Schedule";
import ReadMore from "./ReadMore";
import IsAdmin from "../IsAdmin";

import Linkify from "linkifyjs/react";
import Viewer from "react-viewer";
import firebase from "firebase";

import { connect } from "react-redux";
import {
  setReplyId,
  setContentTypeAndValue,
  setRightPanel,
  resetAllTasks,
  resetTask,
  resetData,
  setPollTitle,
  setPollOptions,
  setScheduleTitle,
  setScheduleDurationHours,
  setScheduleDurationMinutes,
  setSchedulePlace,
  setScheduleAgenda,
  setReadingTitles,
  setReadingLinks,
  setReadingFiles,
  setReadingUrls,
  setTaskSharing,
  setTaskDeadline,
  setTaskEveryone,
  setTaskChannelUsers,
  setSharedTask,
  setCurrentChannel,
  setAllMessages,
  setCurrentWorkspace
} from "../../actions/index";

import {
  setQuizTitle,
  setQuizQuestions,
  setQuizOptions,
  setQuizTypes,
  setQuizCorrectAnswers,
  setQuizWeights,
  setQuizTotalWeight,
  setQuizGraded,
  //setQuizSharing,
} from "../Tasks/Quiz/actions";

import {
  setAssignmentQuestions,
  setAssignmentDescriptions,
  setAssignmentWeights,
  setAssignmentTotalWeight,
  setAssignmentGraded,
} from "../Tasks/Assignment/actions";
import _ from "lodash";
import Select from 'react-select'
import RoomListForModal from "./RoomListForModal";

const RoomSelectModal = (props) => {
  const [userWorkspaces, setUserWorkspaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentWorkspaceIndex, setCurrentWorkspaceIndex] = useState(0);
  const [userWorkspacesDropdown, setUserWorkspacesDropdown] = useState([]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
  }

  const {
    currentUser,
    currentChannel,
    setCurrentChannel,
    setAllMessages,
    messageDataObj,
    messageData,
    currentWorkspace
  } = props;

  useEffect(() => {
    firebase
      .firestore()
      .collection(`users/${currentUser.uid}/rooms`)
      .onSnapshot(function (snapshot) {
        // console.log(snapshot, "snapshot")
        let userWorkspaces = [];
        snapshot.forEach(function (doc) {
          userWorkspaces.push({ ...doc.data() });
        });

        let index;

        userWorkspaces.forEach((workspace, key) => {
          // console.log("inside did mount", workspace, key)
          if (workspace.workspaceId === props.currentWorkspace?.workspaceId) {
            index = key
          }
        })

        setCurrentWorkspaceIndex(index);

        let roomIds = _.map(userWorkspaces[index]?.rooms, "roomId");
        const roomIdsArchived = _.map(
          userWorkspaces[index]?.archivedRooms,
          "roomId"
        );
        // console.log(roomIds, "roomIds");
        roomIds = [...roomIds, ...roomIdsArchived];
        if (currentChannel && !roomIds.includes(currentChannel.roomId)) {
          setAllMessages([]);
          setCurrentChannel(null);
        }
        // console.log(userWorkspaces, "userworkspaces")
        setUserWorkspaces(userWorkspaces);
      });
  }, []);


  const getWorkspaceDataHandler = async (workspaceId) => {
    const data = await firebase.firestore().collection("workspaces").doc(workspaceId).get().then((doc) => {
      if (doc.exists) {
        const workspaceData = {
          value: doc.id,
          label: <div className="d-flex">
            <img className="mr-2 align-items-center rounded-circle" style={{ width: '30px' }} src={doc.data().workspacePic}></img>
            <span>{doc.data().workspaceName}</span>
          </div>
        }
        return (workspaceData);
      }
    })
    // console.log(data, "Data")
    return (data);
  }


  useEffect(() => {
    if (userWorkspaces && userWorkspaces.length > 0) {

      const workspacePromise = userWorkspaces.map((workspace, key) => {
        return (
          getWorkspaceDataHandler(workspace.workspaceId)
        );
      })

      Promise.all(workspacePromise).then((values) => {
        setUserWorkspacesDropdown(values);
      })

    }
  }, [userWorkspaces])

  const changeWorkspaceIndexHandler = (workspaceid) => {

    let docRef = firebase.firestore().collection(`workspaces`).doc(`${workspaceid}`)
    docRef.get()
      .then(function (doc) {
        if (doc.exists) {
          const workspacedata = doc.data();
          firebase
            .firestore()
            .collection(`users/${props.currentUser.uid}/rooms`)
            .onSnapshot(function (snapshot) {
              let userWorkspaces = [];
              snapshot.forEach(function (doc) {
                userWorkspaces.push({ ...doc.data() })
              })
              //  traversing through the workspaces and setting the workspace which has workspaceId === workspaceId from url
              let workspaceRooms;
              userWorkspaces.forEach((workspace) => {
                if (workspace.workspaceId === workspaceid) {
                  workspaceRooms = workspace;
                }
              })


              // setting workspace data in redux
              props.setCurrentWorkspace({ ...workspacedata, ...workspaceRooms })
            })

        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      })


    userWorkspaces.forEach((workspace, index) => {
      if (workspace.workspaceId === workspaceid) {
        setCurrentWorkspaceIndex(index);
      }
    })
  }

  

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-theme border-0 d-flex justify-content-between align-items-center pb-0">
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="text-theme">Select Room</h5>
        </Modal.Title>

        {/* {
          (userWorkspacesDropdown.length > 0) ?
            <div className="w-50 text-theme p-2">
              <Select
                options={userWorkspacesDropdown}
                isSearchable={false}
                defaultValue={userWorkspacesDropdown[currentWorkspaceIndex]}
                value={userWorkspacesDropdown[currentWorkspaceIndex]}
                onChange={(e) => changeWorkspaceIndexHandler(e.value)}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    // primary25: '#f8f9fa',
                    neutral0: `var(--bg-card-theme)`,
                    neutral80: `var(--text-theme)`,
                  },
                })}
                styles={{
                  control: base => ({
                    ...base,
                    borderRadius: `50rem !important`,
                  }),
                }}
              />
            </div>
            : null
        } */}

        <div title="Close" className="pointer" onClick={() => props.onHide()}>
          <FontAwesomeIcon className="text-theme" icon={faTimes} />
        </div>
      </Modal.Header>
      <Modal.Body
        className="overflow-auto list-unstyled bg-theme pt-0"
        style={{ minHeight: "40vh", maxHeight: "60vh", position: "relative" }}
      >
        {isLoading ? <Spinner animation="border" /> : null}

        <InputGroup className="outerPill p-2">
                  <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                    <div className="py-2 pr-2">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="sm"
                        color="#aeaeae"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <Form.Control
                        type="text"
                        className="border-0 px-0 bg-theme text-theme"
                        onChange={(e) => handleSearch(e)}
                        placeholder={"Search"}
                        value={searchTerm}
                        style={{ margin: 0 }}
                      />
                    </div>
                    {searchTerm.length > 0 && (
                      <div
                        className="py-2 pl-2 pr-3"
                        onClick={() => setSearchTerm('')}
                      >
                        <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                      </div>
                    )}
                  </div>
          </InputGroup>

        <RoomListForModal
          sortType={0}
          workspace={userWorkspaces[currentWorkspaceIndex]}
          messageDataObj={messageDataObj}
          messageData={messageData}
          isLoading={setIsLoading}
          searchTerm={searchTerm}
        />

      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
});

export default connect(mapStateToProps, {
  setCurrentChannel,
  setAllMessages,
  setCurrentWorkspace
})(RoomSelectModal);
