import React, { Component } from "react";
import UserNameAvatar from "./UserNameAvatar";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { API_BASE_URL, INVITE_APPROVAL } from "../../config/index";
import { postData } from "../../utils/index";
import firebase from "firebase";

class PendingInviteAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approval_action_type: "",
      invitee_user_details: {}
    };
  }

  _handleActionInvite = (approval_action_type) => {
    const {
      inviteeUserId,
      currentUser,
      currentChannel,
      currentWorkspace,
    } = this.props;

    let actionInviteData = {
      approval_action_type,
      room_id: currentChannel.roomId,
      workspace_id: currentWorkspace.workspaceId,
      approver_user_id: currentUser.uid,
      invitee_user_id: inviteeUserId,
    };

    // console.log("actionInviteData", actionInviteData)

    postData(`${API_BASE_URL}${INVITE_APPROVAL}`, actionInviteData)
      .then((response) => {
        console.log(response);

        // this.setState({ approval_action_type })
      })
      .catch((error) => console.error(error));
  };

  componentDidMount() {
    firebase.firestore().collection("users").doc(`${this.props.inviteeUserId}`)
      .get()
      .then(doc => {
        if (doc.exists) {
          this.setState({invitee_user_details : { uid:this.props.inviteeUserId, displayName: doc.data().displayName, avatar: doc.data().avatar }});
        } else {
        }
      }).then(() => {
      })
  }  

  render() {
    const { inviteeUserId } = this.props;
    const { approval_action_type } = this.state;

    return (
      <div className="bg-card-theme text-theme">
        <UserNameAvatar
          userId={this.state.invitee_user_details?.inviteeUserId}
          avatar={this.state.invitee_user_details?.avatar}
          displayName={this.state.invitee_user_details?.displayName}
          children={
            approval_action_type === "" ? (
              <div className="d-flex ml-2 mt-2">
                <Button
                  onClick={() => this._handleActionInvite("0")}
                  variant="primary"
                  size="sm"
                  className="small mr-2"
                >
                  Accept
                </Button>
                <Button
                  onClick={() => this._handleActionInvite("1")}
                  variant="secondary"
                  size="sm"
                  className="small"
                >
                  Reject
                </Button>
              </div>
            ) : (
              <div className="">
                {approval_action_type === "0" ? (
                  <p className="small mb-0 text-theme">Accepted Invitation</p>
                ) : (
                  <p className="small mb-0 text-theme">Rejected Invitation</p>
                )}
              </div>
            )
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(PendingInviteAction);
