import React, { useEffect, useState, useRef } from "react";
import { loadState, saveState } from "../../services/index";
import { Form, Button, Spinner, InputGroup } from "react-bootstrap";
import firebase from "firebase";
import { Link, StaticRouter } from "react-router-dom";
import { postData } from "../../utils/index";
import { API_BASE_URL, USER_EXISTS } from "../../config/index";
import * as DeviceDetect from "react-device-detect";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { browserName, CustomView, osName } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye, faLink } from "@fortawesome/free-solid-svg-icons";

function SignIn(props) {
  const credentialRef = useRef(null);
  const passwordRef = useRef(null);
  const createPasswordRef = useRef(null);
  const firstNameRef = useRef(null);

  useEffect(() => {
    if (firebase.auth().currentUser) {
      window.location.replace("/");
    }

    credentialRef.current && credentialRef.current.focus();
  }, []);

  const [fieldName, setFieldName] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  // const [type, setType] = useState("");
  const type = "email";
  const [credential, setCredential] = useState("");
  const [loadingFindingUser, setLoadingFindingUser] = useState(false);
  const [error, setError] = useState("");

  const [userFound, setUserFound] = useState(false);
  const [takingToPassword, setTakingToPassword] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);

  const [formStage, setFormStage] = useState("0");
  const [previousUser, setPreviousUser] = useState(false);
  const [loadingSendingDetails, setLoadingSendingDetails] = useState(false);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [confirmEmailAddress, setConfirmEmailAddress] = useState("");
  const [loadingResendEmail, setLoadingResendEmail] = useState(false);
  const [resetEmailSent, setResetEmailSent] = useState(false);

  const [loginLoading, setLoginLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [phone, setPhone] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [loadingResendOtp, setLoadingResendOtp] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);

  const [counter, setCounter] = useState(30);
  const [startCounter, setStartCounter] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [errorObj, setErrorObj] = useState({});

  // workspace and room states
  const [workspaceDataFromURL, setWorkspaceDataFromURL] = useState(null);
  const [workspaceAdmin, setWorkspaceAdmin] = useState(null);
  const [workspaceError, setWorkspaceError] = useState(false);
  const [inviteData, setInviteData] = useState(null);
  const [roomData, setRoomData] = useState(null);
  const [roomDataLoading, setRoomDataLoading] = useState(true);

  // useEffect(() => {

  //   const getWorkspaceDetails = async (workspaceId) => {
  //     let details = firebase.functions().httpsCallable("workspaceDetails");
  //     let result = await details({ workspaceId });
  //     console.log(result, "result from https func");
  //     setWorkspaceDataFromURL(result.data.workspaceDetails);
  //   }

  //   if(props.newRoom){
  //     firebase
  //       .firestore()
  //       .collection("invites")
  //       .doc(`${props.newRoom}`)
  //       .get()
  //       .then((response) => {
  //         if (response.exists) {
  //           const inviteData = response.data();
  //           console.log(inviteData, "invite data from response data")
  //           setInviteData(inviteData);
  //           getWorkspaceDetails(inviteData?.workspaceId).then(() => {
  //             firebase.firestore().collection(`workspaces/${inviteData?.workspaceId}/rooms`).doc(`${inviteData?.roomId}`).get().then((doc) => {
  //               if (doc.exists) {
  //                 setRoomData(doc.data());
  //                 setRoomDataLoading(false);
  //               }
  //             })
  //           })
  //         } else {
  //           setInviteData(null);
  //           setRoomDataLoading(false);
  //         }
  //       })

  //   }
  // },[props.newRoom])

  useEffect(() => {
    if (startCounter) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      if (counter === 0) setStartCounter(false);
      return () => clearInterval(timer);
    }
  }, [counter, startCounter]);

  useEffect(() => {
    setPhoneCode(loadState("accessCountryPhoneCode"));
  }, [loadState("accessCountryPhoneCode")]);

  const [showNonChromeWarnings, setShowNonChromeWarnings] = useState(false);
  const [msgNonChromeAndDesktopWarnings, setMsgNonChromeAndDesktopWarnings] =
    useState("");

  const [showFirefoxWarnings, setShowFirefoxWarnings] = useState(false);
  const [msgFirefoxAndDesktopWarnings, setMsgFirefoxAndDesktopWarnings] =
    useState("");

  const [showEdgeWarnings, setShowEdgeWarnings] = useState(false);
  const [msgEdgeAndDesktopWarnings, setMsgEdgeAndDesktopWarnings] =
    useState("");

  const [showChromeWarnings, setShowChromeWarnings] = useState(false);
  const [msgChromeAndDesktopWarnings, setMsgChromeAndDesktopWarnings] =
    useState("");

  const [showSafariWarnings, setShowSafariWarnings] = useState(false);
  const [msgSafariAndDesktopWarnings, setMsgSafariAndDesktopWarnings] =
    useState("");

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`versions`)
      .doc(`web-errors`)
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const {
              showNonChromeWarnings,
              msgNonChromeAndDesktopWarnings,
              showFirefoxWarnings,
              msgFirefoxAndDesktopWarnings,
              showEdgeWarnings,
              msgEdgeAndDesktopWarnings,
              showChromeWarnings,
              msgChromeAndDesktopWarnings,
              showSafariWarnings,
              msgSafariAndDesktopWarnings,
            } = doc.data();

            setShowNonChromeWarnings(showNonChromeWarnings);
            setMsgNonChromeAndDesktopWarnings(msgNonChromeAndDesktopWarnings);

            setShowFirefoxWarnings(showFirefoxWarnings);
            setMsgFirefoxAndDesktopWarnings(msgFirefoxAndDesktopWarnings);

            setShowEdgeWarnings(showEdgeWarnings);
            setMsgEdgeAndDesktopWarnings(msgEdgeAndDesktopWarnings);

            setShowChromeWarnings(showChromeWarnings);
            setMsgChromeAndDesktopWarnings(msgChromeAndDesktopWarnings);

            setShowSafariWarnings(showSafariWarnings);
            setMsgSafariAndDesktopWarnings(msgSafariAndDesktopWarnings);
          }
        },
        (err) => {
          console.error(err);
        }
      );

    return () => {
      unsubscribe();
    };
  });

  const _handleCredentialSubmit = (e) => {
    e.preventDefault();

    setLoadingFindingUser(true);

    if (credential == "" || credential.length == 0) {
      setError("Please enter email.");
      setLoadingFindingUser(false);
      return;
    }

    if (type === "phoneNumber") {
      var pattern = /^[0-9\s\-\(\)]+$/;
      if (!credential.match(pattern)) {
        setError("Invalid phone number!");
        setLoadingFindingUser(false);
        return;
      }
    } else {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(credential)) {
        setError("Invalid email address!");
        setLoadingFindingUser(false);
        return;
      }
    }

    postData(
      `${API_BASE_URL}${USER_EXISTS}`,
      type === "email"
        ? { [type]: credential }
        : { [type]: `+${phoneCode}${credential}` }
    )
      .then((response) => {
        console.log(response);
        const { data, message, status } = response;

        if (status === 200) {
          if (data.exist) {
            setUserFound(true);
            setPreviousUser(true);
            if (type === "phoneNumber") {
              window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
                "recaptcha-container",
                {
                  size: "invisible",
                }
              );

              _handlePhoneAuth(e);
            } else {
              setTakingToPassword(true);
              setTimeout(() => {
                setFormStage("2");
                setTakingToPassword(false);
                passwordRef.current && passwordRef.current.focus();
              }, 2000);
            }
          } else {
            setFormStage("1");
            firstNameRef.current && firstNameRef.current.focus();
          }
        } else {
          setFormStage("0");
          setError("Some server error occured!");
        }
      })
      .catch((error) => console.error(error));
  };

  const _handleCredentials = (e) => {
    e.preventDefault();

    var pattern = /^[0-9\s\-\(\)]+$/;
    setError("");

    if (e.target.value.match(pattern)) {
      setFieldName("phone");
      // setType("phoneNumber")
      setCredential(e.target.value);
    } else {
      setFieldName("email");
      // setType("email")
      setCredential(e.target.value);
    }
  };

  const _handleUserDataSubmit = (e) => {
    e.preventDefault();

    setLoadingSendingDetails(true);

    if (isValidString(firstName) && isValidString(lastName)) {
      if (type === "phoneNumber") {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
          }
        );

        _handlePhoneAuth(e);
      } else {
        setTakingToPassword(true);
        setTimeout(() => {
          setFormStage("2");
          setTakingToPassword(false);
          setLoadingSendingDetails(false);
          setError("");
          createPasswordRef.current && createPasswordRef.current.focus();
        }, 2000);
      }
    } else {
      setLoadingSendingDetails(false);
      setError("Please enter mandatory fields!");
    }
  };

  const isValidString = (str) => {
    return str.length > 0;
  };

  const _handleEmailAndPasswordRegister = (event) => {
    event.preventDefault();
    setLoadingRegister(true);

    if (
      isValidString(credential) &&
      isValidString(password) &&
      isValidString(confirmPassword) &&
      isValidString(firstName) &&
      isValidString(lastName)
    ) {
      if (password.trim().length < 6) {
        setError("Password must have at least 6 characters");
        setLoadingRegister(false);
      } else if (password === confirmPassword) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(credential, password)
          .then((createdUser) => {
            createdUser.user.sendEmailVerification();
            setLoadingRegister(false);

            if (createdUser.operationType === "signIn") {
              _handleUpdateProfile();
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        setError("Please enter same password in both");
        setLoadingRegister(false);
      }
    } else {
      setError("Please enter same password in both");
    }
  };

  const _handleEmailAndPasswordLogin = (e) => {
    e.preventDefault();
    setLoginLoading(true);

    if (password === "") {
      setError("Please enter valid password!");
      setLoginLoading(false);
      return;
    }

    if (credential && isValidString(password)) {
      firebase
        .auth()
        .signInWithEmailAndPassword(credential, password)
        .then((response) => {
          console.log(response);
          setLoginLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setError(error.message);
          setLoginLoading(false);
        });
    }
  };

  const _handlePhoneAuth = () => {
    setSendingOtp(true);

    const phone = `+${phoneCode}${credential}`;
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then((confirmResult) => {
        window.confirmationResult = confirmResult;

        setLoadingFindingUser(false);
        setLoadingSendingDetails(false);

        setFormStage("2");
        setSendingOtp(false);
        setStartCounter(true);
      })
      .catch((error) => {
        console.error(error);
        setError(error.message);
      });
  };

  const _handleConfirmOtp = () => {
    setLoginLoading(true);

    if (otp.length != 6) {
      setLoginLoading(false);
      setError("Please enter valid OTP!");
      return;
    }

    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        const { additionalUserInfo } = result;
        if (additionalUserInfo.isNewUser) {
          _handleUpdateProfile();
        }
        setLoginLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error.message);
        setLoginLoading(false);
      });
  };

  const _handleUpdateProfile = () => {
    const authUser = firebase.auth().currentUser;
    console.log(firstName, lastName, phoneNumber, "my data");
    authUser
      .updateProfile({
        displayName: `${firstName} ${lastName}`,
        phoneNumber: phoneNumber,
        photoURL:
          "https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822",
      })
      .then((usr) => {
        console.log(usr, "user data updated");
        _handleSaveUser(authUser);
      })
      .catch((error) => console.error(error));
  };

  const _handleSaveUser = (authUser) => {
    console.log(authUser, phoneNumber, "my authUser");
    return firebase
      .firestore()
      .collection("users")
      .doc(`${authUser.uid}`)
      .set({
        displayName: authUser.displayName,
        avatar: authUser.photoURL,
        firstName: firstName,
        lastName: lastName,
        middleName: middleName,
        email: authUser.email,
        emailVerified: authUser.emailVerified,
        phone: phoneNumber,
        providerData: JSON.parse(JSON.stringify(authUser.providerData)),
        uid: authUser.uid,
        isAnonymous: authUser.isAnonymous,
        deviceInfo: DeviceDetect.deviceDetect(),
        deviceType: DeviceDetect.deviceType,
        device: DeviceDetect.isMobile
          ? DeviceDetect.isMobileOnly
            ? "Mobile"
            : "Tablet"
          : "Desktop",
        electron: DeviceDetect.isElectron ? true : false,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        subscription: {
          room_access: {
            access_granted_at: Date.now(),
            number_of_days: 30,
            room_limit: 1,
          },
        },
      })
      .then(function (usr) {
        // adding the new user to the workspace "igesia"
        console.log(usr);
        firebase
          .firestore()
          .collection("users")
          .doc(`${authUser.uid}`)
          .collection("rooms")
          .doc("LgT2sjzoZQwwKMGPnob2")
          .set({
            rooms: [],
            workspaceId: "LgT2sjzoZQwwKMGPnob2",
          })
          .then(() => {
            console.log(
              "Document successfully written! + added default workspace as igesia"
            );
          });
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  };

  const _handleEmailPasswordReset = () => {
    if (emailAddress != confirmEmailAddress) {
      setError("Please enter same email address");
      return;
    }

    setLoadingResendEmail(true);

    firebase
      .auth()
      .sendPasswordResetEmail(emailAddress)
      .then(() => {
        console.log("Reset Email Sent");
        setResetEmailSent(true);
        setTimeout(() => setLoadingResendEmail(false), 500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const _handleResendOtpSubmit = () => {
    window.recaptchaVerifierResend = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container-resend",
      {
        size: "invisible",
      }
    );

    setResendOtp(true);
    setLoadingResendOtp(true);

    const phone = `+${phoneCode}${credential}`;
    const appVerifier = window.recaptchaVerifierResend;
    firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then((confirmResult) => {
        window.recaptchaVerifierResend = confirmResult;
        setLoadingResendOtp(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const _handleResendConfirmOtp = () => {
    setLoginLoading(true);

    if (otp.length != 6) {
      setLoginLoading(false);
      setError("Please enter valid OTP!");
      return;
    }

    window.recaptchaVerifierResend
      .confirm(otp)
      .then((result) => {
        const { additionalUserInfo } = result;
        if (additionalUserInfo.isNewUser) {
          _handleUpdateProfile();
        }
        setLoginLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error.message);
        setLoginLoading(false);
      });
  };

  const _handleUseDifferentNumber = () => {
    setFormStage("0");
  };

  const _handleStartOver = () => {
    setLoadingFindingUser(false);
    setUserFound(false);
    setEmailAddress("");
    setFormStage("0");
    setCredential("");
    setError("");
    setSendingOtp(false);
    // setType("")
    setPassword("");
  };
  const validateDataOfField = (e) => {
    let errorObj = {};
    //console.log(e);
    if (e.target.name === "password") {
      if (e.target.value.trim().length < 6) {
        errorObj.password = "Password must have at least 6 characters";
      } else {
        errorObj.password = "";
      }
    }
    setErrorObj({ ...errorObj });
  };

  return (
    <div
      className="container-fluid"
      style={{
        minHeight: "100vh",
        background: workspaceDataFromURL?.workspaceCoverPic
          ? `url(${workspaceDataFromURL.workspaceCoverPic}) 0% 0% /cover`
          : props.newRoomDetails?.workspaceData?.workspaceCoverPic
          ? `url(${props.newRoomDetails.workspaceData.workspaceCoverPic}) 0% 0% /cover`
          : "#18191a",
      }}
    >
      <div className="container">
        <div className="row pt-5 justify-content-center">
          <div className="col-md-4 pt-5 text-center">
            <img
              alt="igesia-logo"
              src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-reverse.png?alt=media&token=93df9989-4eab-406f-8122-ada051558941"
              width="200"
            />
          </div>
        </div>
        {false ? null : ( // (browserName != "Chrome" && browserName != "Firefox")
          <div className="row pt-5 justify-content-center">
            {formStage === "0" && (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  _handleCredentialSubmit(e);
                }}
                className="col-md-4 p-3 shadow-sm text-light rounded"
                style={{ background: "#232626" }}
              >
                <h4>Sign up or Login</h4>
                {props.newRoom && (
                  <p className="mb-1">
                    Please login to join{" "}
                    <div className="mt-2">
                      {/* {
                        inviteData && roomDataLoading ?
                        <Spinner size="sm" animation="border" className="text-secondary" /> : null
                      } */}
                      <p>
                        <FontAwesomeIcon icon={faLink} />{" "}
                        https://console.igesia.co/<b>{props.newRoom}</b>
                      </p>
                      {props.newRoomDetails?.roomData ? (
                        <div className="d-flex mt-2 align-items-center">
                          <img
                            className="img-fluid rounded-circle"
                            src={props.newRoomDetails.roomData.roomPic}
                            alt={props.newRoomDetails.roomData.roomName}
                            title={props.newRoomDetails.roomData.roomName}
                            style={{ width: "75px" }}
                          ></img>
                          <div className="ml-3 align-items-center">
                            <p
                              className="mb-0"
                              title={props.newRoomDetails.roomData.roomName}
                            >
                              {props.newRoomDetails.roomData.roomName}
                            </p>
                            <p
                              className="mb-1 text-secondary"
                              title={
                                props.newRoomDetails.workspaceData.workspaceName
                              }
                            >
                              {props.newRoomDetails.workspaceData.workspaceName}{" "}
                              workspace
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </p>
                )}
                {props.newRoomError && (
                  <p className="text-danger">
                    Invalid link{" "}
                    <span className="font-weight-bold">
                      my.igesia.co/{props.newRoomError}
                    </span>
                  </p>
                )}

                {/* <Form.Label className="mt-md-3">Enter your email or phone number</Form.Label> */}
                <Form.Label className="mt-md-3">Enter your email</Form.Label>
                <InputGroup className="mb-3">
                  {type === "phoneNumber" && (
                    <>
                      <InputGroup.Prepend>
                        <Form.Control
                          name="phoneCode"
                          type="text"
                          style={{ width: "50px" }}
                          value={phoneCode}
                          onChange={(e) => {
                            var pattern = /^\d*$/;
                            if (e.target.value.match(pattern)) {
                              setPhoneCode(e.target.value);
                            }
                          }}
                        />
                      </InputGroup.Prepend>
                    </>
                  )}
                  <Form.Control
                    name={fieldName}
                    type="text"
                    placeholder=""
                    value={credential}
                    ref={credentialRef}
                    onChange={(e) => _handleCredentials(e)}
                    disabled={loadingFindingUser}
                  />
                </InputGroup>
                {error !== "" && (
                  <>
                    <small className="d-block text-danger mb-3">{error}</small>
                    <p className="text-muted mb-0 d-flex">
                      <div>Start over? </div>
                      <Link
                        className="ml-1"
                        style={{ cursor: "pointer", color: "#6900ff" }}
                        // onClick={() => {
                        //     setLoadingFindingUser(false)
                        //     setUserFound(false)
                        //     setEmailAddress("")
                        //     setFormStage("0")
                        // }}
                        onClick={(e) => _handleStartOver(e)}
                      >
                        <u>Click here</u>
                      </Link>
                    </p>
                  </>
                )}

                <Button
                  className="w-100"
                  variant="primary"
                  onClick={(e) => _handleCredentialSubmit(e)}
                  disabled={loadingFindingUser}
                >
                  {loadingFindingUser && (
                    <Spinner
                      as="span"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                      className="mr-1"
                      size="sm"
                    />
                  )}
                  <span>{userFound ? "User found" : "Next"}</span>
                </Button>

                {sendingOtp ? (
                  <p className="mt-2">
                    Sending OTP on{" "}
                    <span className="h6">
                      +{phoneCode}-{credential}
                    </span>
                  </p>
                ) : null}

                {takingToPassword ? (
                  <p className="mt-2">
                    Forwarding you to securely enter password for{" "}
                    <span className="h6">{credential}</span>
                  </p>
                ) : null}

                {!userFound && (
                  <>
                    <p className="text-center w-100 my-3 small">OR</p>
                    <Link to="/guest" className="w-100 btn btn-secondary">
                      Login as Guest
                    </Link>
                  </>
                )}
              </Form>
            )}

            {formStage === "1" && (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  _handleUserDataSubmit(e);
                }}
                className="col-md-4 p-3 shadow-sm text-light rounded"
                style={{ background: "#232626" }}
              >
                <h3 className="mb-0">Looks like you are new here</h3>
                <small className="text-muted">Please enter your name</small>
                <Form.Group className="mt-3 mt-md-4">
                  <Form.Label>
                    First name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="firstName"
                    type="text"
                    placeholder="Enter first name"
                    value={firstName}
                    ref={firstNameRef}
                    onChange={(e) => setFirstName(e.target.value)}
                  />

                  {/* <Form.Label className="mt-3">Middle name</Form.Label>
                                <Form.Control
                                    name="middleName"
                                    className="mb-0"
                                    type="text"
                                    placeholder="Enter middle name"
                                    value={middleName}
                                    onChange={e => setMiddleName(e.target.value)} /> */}

                  <Form.Label className="mt-3">
                    Last name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    name="lastName"
                    type="text"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <Form.Label className="mt-3 col-md-12">
                    Phone Number<span className="text-danger"></span>
                  </Form.Label>
                  <PhoneInput
                    inputClass="text-dark w-100"
                    name="phoneNumber"
                    country={"in"}
                    //required={ true}
                    placeholder="Enter Phone Number"
                    value={phone}
                    onChange={(phone, countryData) => {
                      if (typeof phone === "string") {
                        let number = phone
                          .replace(countryData.dialCode, "")
                          .toString();

                        let phoneNumberData = {
                          countryCode: countryData.countryCode
                            ? countryData.countryCode
                            : "",
                          phoneCode: countryData.dialCode
                            ? countryData.dialCode
                            : "",
                          number: number,
                        };
                        setPhoneNumber(phoneNumberData);
                        setPhone(phone);
                      }
                    }}
                  />
                  {error !== "" && (
                    <small className="text-danger">{error}</small>
                  )}
                </Form.Group>

                <Button
                  className="w-100"
                  variant="outline-primary"
                  onClick={(e) => _handleUserDataSubmit(e)}
                  disabled={loadingSendingDetails}
                >
                  {loadingSendingDetails && (
                    <Spinner
                      as="span"
                      role="status"
                      aria-hidden="true"
                      animation="border"
                      className="mr-1"
                      size="sm"
                    />
                  )}
                  <span>{loadingSendingDetails ? "Please wait" : "Next"}</span>
                </Button>

                <Link
                  onClick={() => {
                    setLoadingFindingUser(false);
                    setUserFound(false);
                    setEmailAddress("");
                    setFormStage("0");
                    setFirstName("");
                    setLastName("");
                  }}
                  className="mt-3"
                >
                  Back to Login
                </Link>

                {sendingOtp ? (
                  <p className="mt-2">
                    Sending OTP on{" "}
                    <span className="h6">
                      +{phoneCode}-{credential}
                    </span>
                  </p>
                ) : null}

                {takingToPassword ? (
                  <p className="mt-2">
                    Forwarding you to securely create password for{" "}
                    <span className="h6">{credential}</span>
                  </p>
                ) : null}
              </Form>
            )}

            {formStage === "2" && (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  _handleEmailAndPasswordLogin(e);
                }}
                className="col-md-4 p-3 shadow-sm text-light rounded"
                style={{ background: "#232626" }}
              >
                {type === "email" && (
                  <>
                    {previousUser ? (
                      <div>
                        <h3 className="mb-0">Enter password</h3>

                        <p className="text-muted">
                          Logging into{" "}
                          <span className="font-weight-bold">{credential}</span>
                        </p>
                        <Form.Group className="mt-3 mt-md-4">
                          <Form.Label>Enter password</Form.Label>
                          <div className="d-flex">
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              placeholder="******"
                              value={password}
                              ref={passwordRef}
                              onChange={(e) => {
                                setError("");
                                setPassword(e.target.value);
                                setShowPassword(false);
                              }}
                            />
                            <div
                              onClick={() => setShowPassword(!showPassword)}
                              className="rounded rounded-circle text-theme-inverted p-2 mr-3"
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: 0,
                              }}
                            >
                              {showPassword ? (
                                <FontAwesomeIcon icon={faEye} />
                              ) : (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              )}
                            </div>
                          </div>
                          {error !== "" && (
                            <small className="text-danger">{error}</small>
                          )}
                        </Form.Group>

                        <Button
                          className="w-100"
                          variant="outline-primary"
                          onClick={(e) => _handleEmailAndPasswordLogin(e)}
                          disabled={loginLoading}
                        >
                          {loginLoading && (
                            <Spinner
                              as="span"
                              role="status"
                              aria-hidden="true"
                              animation="border"
                              className="mr-1"
                              size="sm"
                            />
                          )}
                          <span>Login</span>
                        </Button>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <Link
                              onClick={() => {
                                setForgotPassword(true);
                                setEmailAddress(credential);
                                setFormStage("3");
                                setError("");
                                setPassword("");
                              }}
                              className=""
                            >
                              Forgot password?
                            </Link>
                          </div>
                          <div className="col-md-6 text-right">
                            <p className="text-muted mb-0 d-flex">
                              <div>Start over? </div>
                              <Link
                                className="ml-1"
                                style={{ cursor: "pointer", color: "#6900ff" }}
                                // onClick={() => {
                                //     setLoadingFindingUser(false)
                                //     setUserFound(false)
                                //     setEmailAddress("")
                                //     setFormStage("0")
                                // }}
                                onClick={(e) => _handleStartOver(e)}
                              >
                                <u>Click here</u>
                              </Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h3 className="mb-0">Create and confirm password</h3>

                        <p className="text-muted">
                          Creating password for{" "}
                          <span className="font-weight-bold">{credential}</span>
                        </p>
                        <Form.Group className="mt-3 mt-md-4">
                          <Form.Label>Enter password</Form.Label>
                          <div className="d-flex">
                            <Form.Control
                              type={showPassword ? "text" : "password"}
                              placeholder="******"
                              value={password}
                              name="password"
                              ref={createPasswordRef}
                              onChange={(e) => {
                                setError("");
                                setPassword(e.target.value);
                                setShowPassword(false);
                              }}
                              onKeyUp={(e) => validateDataOfField(e)}
                              onBlur={(e) => validateDataOfField(e)}
                            />
                            <div
                              onClick={() => setShowPassword(!showPassword)}
                              className="rounded rounded-circle text-theme-inverted p-2 mr-3"
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: 0,
                              }}
                            >
                              {showPassword ? (
                                <FontAwesomeIcon icon={faEye} />
                              ) : (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              )}
                            </div>
                          </div>
                          {errorObj.password ? (
                            <p className="small text-danger">
                              {errorObj.password}
                            </p>
                          ) : null}

                          <Form.Label className="mt-3">
                            Confirm password
                          </Form.Label>
                          <div className="d-flex">
                            <Form.Control
                              type={showConfirmPassword ? "text" : "password"}
                              placeholder="******"
                              value={confirmPassword}
                              onChange={(e) => {
                                setError("");
                                setConfirmPassword(e.target.value);
                                setShowConfirmPassword(false);
                              }}
                            />
                            <div
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              className="rounded rounded-circle text-theme-inverted p-2 mr-3"
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                right: 0,
                              }}
                            >
                              {showConfirmPassword ? (
                                <FontAwesomeIcon icon={faEye} />
                              ) : (
                                <FontAwesomeIcon icon={faEyeSlash} />
                              )}
                            </div>
                          </div>
                          {error !== "" && (
                            <small className="text-danger">{error}</small>
                          )}
                        </Form.Group>

                        <Button
                          className="w-100"
                          variant="outline-primary"
                          onClick={(e) => _handleEmailAndPasswordRegister(e)}
                          disabled={loadingRegister}
                        >
                          {loadingRegister && (
                            <Spinner
                              as="span"
                              role="status"
                              aria-hidden="true"
                              animation="border"
                              className="mr-1"
                              size="sm"
                            />
                          )}
                          <span>Register</span>
                        </Button>
                      </div>
                    )}
                  </>
                )}

                {type === "phoneNumber" && (
                  <div>
                    <h3 className="mb-0">Enter OTP to create account</h3>

                    <p className="text-muted">
                      Logging using{" "}
                      <span className="font-weight-bold">
                        +{phoneCode}-{credential}
                      </span>
                    </p>
                    <Form.Group className="mt-3 mt-md-4">
                      <Form.Label>Enter OTP</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={(e) => {
                          setError("");
                          setOtp(e.target.value);
                        }}
                      />

                      {error !== "" && (
                        <small className="text-danger">{error}</small>
                      )}
                    </Form.Group>

                    {resendOtp ? (
                      <Button
                        className="w-100"
                        variant="outline-primary"
                        onClick={(e) => _handleResendConfirmOtp(e)}
                        disabled={loginLoading}
                      >
                        {loginLoading && (
                          <Spinner
                            as="span"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            className="mr-1"
                            size="sm"
                          />
                        )}
                        <span>Login</span>
                      </Button>
                    ) : (
                      <Button
                        className="w-100"
                        variant="outline-primary"
                        onClick={(e) => _handleConfirmOtp(e)}
                        disabled={loginLoading}
                      >
                        {loginLoading && (
                          <Spinner
                            as="span"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                            className="mr-1"
                            size="sm"
                          />
                        )}
                        <span>Login</span>
                      </Button>
                    )}

                    <div className="row mt-3">
                      <div className="col-md-6">
                        <p className="text-muted mb-0">Didn't get OTP?</p>
                        {startCounter ? (
                          <p className="text-muted mb-0 small">
                            Resend in{" "}
                            {parseInt(counter) > 1
                              ? `${parseInt(counter)} seconds`
                              : `${parseInt(counter)} second`}
                          </p>
                        ) : (
                          <Button
                            variant="outline-primary"
                            disabled={resendOtp}
                            className=""
                            onClick={(e) => _handleResendOtpSubmit(e)}
                          >
                            {loadingResendOtp && (
                              <Spinner
                                as="span"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                                className="mr-1"
                                size="sm"
                              />
                            )}
                            <span>Resend</span>
                          </Button>
                        )}
                      </div>
                      <div className="col-md-6">
                        <p className="text-muted mb-0 d-flex">
                          <div>Start over? </div>
                          <Link
                            className="ml-1"
                            style={{ cursor: "pointer", color: "#6900ff" }}
                            // onClick={() => {
                            //     setLoadingFindingUser(false)
                            //     setUserFound(false)
                            //     setEmailAddress("")
                            //     setFormStage("0")
                            // }}
                            onClick={(e) => _handleStartOver(e)}
                          >
                            <u>Click here</u>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            )}

            {formStage === "3" && forgotPassword && (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  _handleEmailPasswordReset(e);
                }}
                className="col-md-4 p-3 shadow-sm text-light rounded"
                style={{ background: "#232626" }}
              >
                <div>
                  <h3 className="mb-0">Please confirm your email</h3>

                  <Form.Group className="mt-3 mt-md-4">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={emailAddress}
                      readOnly={true}
                      onChange={(e) => {
                        setError("");
                        setEmailAddress(e.target.value);
                      }}
                    />

                    <Form.Label className="mt-2">Confirm email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Confirm email"
                      value={confirmEmailAddress}
                      onChange={(e) => {
                        setError("");
                        setConfirmEmailAddress(e.target.value);
                      }}
                    />
                  </Form.Group>

                  <Button
                    className="w-100"
                    variant="outline-primary"
                    onClick={(e) => _handleEmailPasswordReset(e)}
                    disabled={loadingResendEmail}
                  >
                    {loadingResendEmail && (
                      <Spinner
                        as="span"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        className="mr-1"
                        size="sm"
                      />
                    )}
                    <span>Reset password</span>
                  </Button>

                  <Link
                    onClick={() => {
                      setLoadingFindingUser(false);
                      setUserFound(false);
                      setEmailAddress("");
                      setFormStage("0");
                    }}
                    className="mt-3"
                  >
                    Back to Login
                  </Link>

                  {resetEmailSent ? (
                    <p className="mt-2">
                      Reset password email has been sent to{" "}
                      <span className="h6">{emailAddress}</span>
                    </p>
                  ) : null}

                  {error !== "" && (
                    <small className="text-danger">{error}</small>
                  )}
                </div>
              </Form>
            )}

            {/* <input id="recaptcha-container" type="hidden" />

                    <input id="recaptcha-container-resend" type="hidden" /> */}
          </div>
        )}

        {browserName === "Firefox" && (
          <div className="row pt-3 justify-content-center">
            <div className="col-md-4">
              <p className="text-center">
                {/* Browser and Firefox */}
                <CustomView
                  condition={
                    showFirefoxWarnings &&
                    DeviceDetect.deviceType != "mobile" &&
                    DeviceDetect.deviceType != "tablet"
                  }
                >
                  <div className="text-warning mb-5">
                    {msgFirefoxAndDesktopWarnings}{" "}
                  </div>
                </CustomView>

                {/* Mobile and Firefox */}

                <CustomView
                  condition={
                    DeviceDetect.deviceType === "mobile" ||
                    DeviceDetect.deviceType === "tablet"
                  }
                >
                  <div className="text-warning mb-5">
                    We recommend downloading Igesia Connect mobile app for best
                    experience. <br />
                    <CustomView condition={DeviceDetect.isIOS}>
                      <a href="https://apps.apple.com/us/app/igesia/id1519955986">
                        <img
                          className="mt-3 shadow"
                          src="https://miro.medium.com/max/8982/1*V9-OPWpauGEi-JMp05RC_A.png"
                          width="250"
                        />
                      </a>
                    </CustomView>
                    <CustomView condition={DeviceDetect.isAndroid}>
                      <a href="https://play.google.com/store/apps/details?id=com.igesia&hl=en_US">
                        <img
                          className="mt-3 shadow"
                          src="https://www.talkhomeapp.com/images/apps/download_app_google_play.svg"
                          width="250"
                        />
                      </a>
                    </CustomView>
                  </div>
                </CustomView>
              </p>
            </div>
          </div>
        )}

        {browserName === "Edge" && (
          <div className="row pt-3 justify-content-center">
            <div className="col-md-4">
              <p className="text-center">
                {/* Browser and Edge */}
                <CustomView
                  condition={
                    showEdgeWarnings &&
                    DeviceDetect.deviceType != "mobile" &&
                    DeviceDetect.deviceType != "tablet"
                  }
                >
                  <div className="text-warning mb-5">
                    {msgEdgeAndDesktopWarnings}{" "}
                  </div>
                </CustomView>

                {/* Mobile and Edge */}

                <CustomView
                  condition={
                    DeviceDetect.deviceType === "mobile" ||
                    DeviceDetect.deviceType === "tablet"
                  }
                >
                  <div className="text-warning mb-5">
                    We recommend downloading Igesia Connect mobile app for best
                    experience. <br />
                    <CustomView condition={DeviceDetect.isIOS}>
                      <a href="https://apps.apple.com/us/app/igesia/id1519955986">
                        <img
                          className="mt-3 shadow"
                          src="https://miro.medium.com/max/8982/1*V9-OPWpauGEi-JMp05RC_A.png"
                          width="250"
                        />
                      </a>
                    </CustomView>
                    <CustomView condition={DeviceDetect.isAndroid}>
                      <a href="https://play.google.com/store/apps/details?id=com.igesia&hl=en_US">
                        <img
                          className="mt-3 shadow"
                          src="https://www.talkhomeapp.com/images/apps/download_app_google_play.svg"
                          width="250"
                        />
                      </a>
                    </CustomView>
                  </div>
                </CustomView>
              </p>
            </div>
          </div>
        )}

        {browserName === "Chrome" && (
          <div className="row pt-3 justify-content-center">
            <div className="col-md-4">
              <p className="text-center">
                {/* Browser and Chrome */}
                <CustomView
                  condition={
                    showChromeWarnings &&
                    DeviceDetect.deviceType != "mobile" &&
                    DeviceDetect.deviceType != "tablet"
                  }
                >
                  <div className="text-warning mb-5">
                    {msgChromeAndDesktopWarnings}{" "}
                  </div>
                </CustomView>

                {/* Mobile and Chrome */}

                <CustomView
                  condition={
                    DeviceDetect.deviceType === "mobile" ||
                    DeviceDetect.deviceType === "tablet"
                  }
                >
                  <div className="text-warning mb-5">
                    We recommend downloading Igesia Connect mobile app for best
                    experience. <br />
                    <CustomView condition={DeviceDetect.isIOS}>
                      <a href="https://apps.apple.com/us/app/igesia/id1519955986">
                        <img
                          className="mt-3 shadow"
                          src="https://miro.medium.com/max/8982/1*V9-OPWpauGEi-JMp05RC_A.png"
                          width="250"
                        />
                      </a>
                    </CustomView>
                    <CustomView condition={DeviceDetect.isAndroid}>
                      <a href="https://play.google.com/store/apps/details?id=com.igesia&hl=en_US">
                        <img
                          className="mt-3 shadow"
                          src="https://www.talkhomeapp.com/images/apps/download_app_google_play.svg"
                          width="250"
                        />
                      </a>
                    </CustomView>
                  </div>
                </CustomView>
              </p>
            </div>
          </div>
        )}

        {browserName === "Safari" && (
          <div className="row pt-3 justify-content-center">
            <div className="col-md-4">
              <p className="text-center">
                {/* Browser and Safari */}
                <CustomView
                  condition={
                    showSafariWarnings &&
                    DeviceDetect.deviceType != "mobile" &&
                    DeviceDetect.deviceType != "tablet"
                  }
                >
                  <div className="text-warning mb-5">
                    {msgSafariAndDesktopWarnings}{" "}
                  </div>
                </CustomView>

                {/* Mobile and Safari */}

                <CustomView
                  condition={
                    DeviceDetect.deviceType === "mobile" ||
                    DeviceDetect.deviceType === "tablet"
                  }
                >
                  <div className="text-warning mb-5">
                    We recommend downloading Igesia Connect mobile app for best
                    experience. <br />
                    <CustomView condition={DeviceDetect.isIOS}>
                      <a href="https://apps.apple.com/us/app/igesia/id1519955986">
                        <img
                          className="mt-3 shadow"
                          src="https://miro.medium.com/max/8982/1*V9-OPWpauGEi-JMp05RC_A.png"
                          width="250"
                        />
                      </a>
                    </CustomView>
                    <CustomView condition={DeviceDetect.isAndroid}>
                      <a href="https://play.google.com/store/apps/details?id=com.igesia&hl=en_US">
                        <img
                          className="mt-3 shadow"
                          src="https://www.talkhomeapp.com/images/apps/download_app_google_play.svg"
                          width="250"
                        />
                      </a>
                    </CustomView>
                  </div>
                </CustomView>
              </p>
            </div>
          </div>
        )}

        <div
          className={
            browserName != "Chrome"
              ? "row pt-5 justify-content-center"
              : "row pt-2 justify-content-center"
          }
        >
          <div className="col-md-4">
            <p className="text-center">
              {/* Browser and Not Chrome */}
              <CustomView
                condition={
                  showNonChromeWarnings &&
                  browserName != "Chrome" &&
                  DeviceDetect.deviceType != "mobile" &&
                  DeviceDetect.deviceType != "tablet"
                }
              >
                <div className="text-warning mb-5">
                  {msgNonChromeAndDesktopWarnings}{" "}
                </div>
              </CustomView>

              {/* Mobile and Not Chrome */}

              <CustomView
                condition={
                  DeviceDetect.deviceType === "mobile" ||
                  DeviceDetect.deviceType === "tablet"
                }
              >
                <div className="text-warning mb-5">
                  We recommend downloading Igesia Connect mobile app for best
                  experience. <br />
                  <CustomView condition={DeviceDetect.isIOS}>
                    <a href="https://apps.apple.com/us/app/igesia/id1519955986">
                      <img
                        className="mt-3 shadow"
                        src="https://miro.medium.com/max/8982/1*V9-OPWpauGEi-JMp05RC_A.png"
                        width="250"
                      />
                    </a>
                  </CustomView>
                  <CustomView condition={DeviceDetect.isAndroid}>
                    <a href="https://play.google.com/store/apps/details?id=com.igesia&hl=en_US">
                      <img
                        className="mt-3 shadow"
                        src="https://www.talkhomeapp.com/images/apps/download_app_google_play.svg"
                        width="250"
                      />
                    </a>
                  </CustomView>
                </div>
              </CustomView>

              {/* <CustomView condition={osName === "Mac OS"}>
                                <div className="text-light">Igesia is available for Mac. <a href="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/Apps%2Figesia-0.1.0.dmg?alt=media&token=90f98a60-2f5e-4499-a037-72562dd4e846">Get it here.</a></div>
                            </CustomView> */}
              <CustomView condition={osName === "Windows"}>
                <div className="text-light">
                  Igesia is available for Windows.{" "}
                  <a href="https://firebasestorage.googleapis.com/v0/b/igesia-94de2.appspot.com/o/apps%2Fwindows%2Figesia%20Setup%200.1.0.exe?alt=media&token=4df0318b-32e1-4f66-aeb6-1bc47bce2f3c">
                    Get it here.
                  </a>
                </div>
              </CustomView>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  newRoom: state.newRoom.newRoom,
  newRoomError: state.newRoom.newRoomError,
  newRoomDetails: state.newRoom.newRoomDetails,
});

export default connect(mapStateToProps)(SignIn);
