import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import { Image, Spinner, Modal, Button } from "react-bootstrap";
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faComment,
    faTimes,
    faPlusCircle
} from "@fortawesome/free-solid-svg-icons";

const UserProfileModal = (props) => {
    // destructuring props
    const { show, userData } = props;

    return (
        <>
            {
                userData ?
                    <Modal
                        show={show}
                        size="sm"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={props.onHide}
                    >
                        <Modal.Header className="bg-theme border-0 d-flex justify-content-end align-items-center p-0">
                            <div className="mx-auto" style={{ height: '100%', width: '100%', zIndex: '100' }}>
                                <Image width="100%" height="100%"
                                    className="user-select-none"
                                    src={userData.avatar}
                                    style={{ background: '#CCCCCC' }} />
                            </div>
                            <div className="d-flex position-absolute w-100 justify-content-between p-2"
                                style={{
                                    zIndex: '200',
                                    top: 0,
                                    background: '#00000069'
                                    // background: 'linear-gradient(rgba(81, 81, 81, 0.9), rgba(101, 101, 101, 0.1), rgba(250, 250, 250, 0.1))' 
                                }}
                            >
                                <div title="Username" className="text-theme" style={{ cursor: 'default', top: 0 }}>
                                    {userData.displayName}
                                </div>
                                <div title="Close" style={{ right: '20px', top: '20px' }} className="pointer" onClick={() => props.onHide()}>
                                    <FontAwesomeIcon className="text-theme" icon={faTimes} />
                                </div>
                            </div>
                        </Modal.Header>
                        <Modal.Body
                            className="overflow-auto bg-theme text-theme text-left"
                        >
                            {
                                !userData.isAnonymous ?
                                    <div className="d-flex justify-content-start my-2">
                                        <div title="First name" className="mr-2" style={{ cursor: 'default' }}>
                                            <p className="mb-0">
                                                {userData.firstName}
                                            </p>
                                        </div>
                                        <div title="Last name" style={{ cursor: 'default' }}>
                                            <p className="mb-0">
                                                {userData.lastName}
                                            </p>
                                        </div>
                                    </div>
                                    :
                                    <div className="d-flex justify-content-start my-2">
                                        <div title="Unregistered user" className="mr-2" style={{ cursor: 'default' }}>
                                            <p className="mb-0">
                                                Unregistered user
                                </p>
                                        </div>
                                    </div>
                            }


                            <div className="d-flex justify-content-start my-2">
                                <div title="Email" className="mr-2" style={{ cursor: 'default' }}>
                                    <p>{userData.email}</p>
                                </div>
                            </div>

                            {
                                !userData.isAnonymous ?
                                    <div>
                                        {/* <Button variant="primary" className="btn-sm mr-2">
                                Message {" "}
                                <FontAwesomeIcon className="text-white" icon={faComment} />
                            </Button>
                            <Button variant="secondary" className="btn-sm">
                                Follow {" "}
                                <FontAwesomeIcon className="text-white" icon={faPlusCircle} />
                            </Button> */}
                                    </div>
                                    : null
                            }


                        </Modal.Body>
                    </Modal>
                    : null
            }

        </>
    )
}


export default UserProfileModal;