import React, { useState, useEffect, useRef } from 'react'
import { Button, Modal, Form, InputGroup, FormControl, Spinner, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faLock } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import firebase from 'firebase'
import {
    setCurrentChannel,
    setAllMessages,
    setParticipants,
    resetData,
    setLeftPanel,
    setIsVideoVisible,
    setChannelDetails,
    setVideoDetails
} from '../../actions/index'
import {
    setCurrentWorkspace,
} from '../../actions/workspace'
import { invoke } from 'lodash'
import _ from 'lodash';
import axios from 'axios';
import { API_BASE_URL, MEETING_TYPE } from '../../config/index';
const GotoRoomOrVideo = (props) => {

    const [workspaceData, setWorkspaceData] = useState({});
    const [workspaceRooms, setWorkspaceRooms] = useState({});
    const [allMessages, setAllMessagesState] = useState([]);
    const [prevPendingInvites, setPrevPendingInvites] = useState([]);
    const [roomLiveStatus, setRoomLiveStatus] = useState(false);
    const [unsubscribeFromRoomLiveStatus, setUnsubscribeFromRoomLiveStatus] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [joinWithVideo, setJoinWithVideo] = useState(false);


    // dispatch to props
    const {
        setCurrentChannel,
        setCurrentWorkspace,
        setParticipants,
        resetData,
        setLeftPanel,
        setChannelDetails,
        setIsVideoVisible,
        setAllMessages,
        setVideoDetails
    } = props;

    // actual props from JoinUsingLink.js 
    const {
        roomData,
        workspaceId,
        roomId
    } = props



    useEffect(() => {
        if (workspaceId && roomId) {
            getLoadedMessages()
            getWorkspaceData()
            getWorkspaceRooms()
        }
    }, [props])

    useEffect(() => {
        return(
            setDataLoaded(false)
        )
    },[])


    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevAllChannels = usePrevious(props.allChannels)

    useEffect(() => {
        if (prevAllChannels && prevAllChannels.length < props.allChannels.length) {

            // let currentInvites ;

            // firebase.firestore().collection(`workspaces/${workspaceId}/rooms`).doc(`${roomId}`).get()
            //     .then((doc) => {
            //         if(doc.exists && doc.data().pendingInvites){
            //             const { pendingInvites } = doc.data();
            //             currentInvites = pendingInvites;
            //         }
            // })

            // currentInvites = _.groupBy(currentInvites, "inviteeUserId");

            // console.log(currentInvites,"currentInvites");


            let userIdInviteWasPending = false;
            prevPendingInvites.forEach((user) => {
                if (user.inviteeUserId === props.currentUser.uid) {
                    console.log("uid was/is there")
                    userIdInviteWasPending = true;
                    directJoinRoom();
                    props.onShow()
                }
            })
            if (userIdInviteWasPending) {

                firebase.firestore().collection(`workspaces/${workspaceId}/rooms`).doc(`${roomId}`).get()
                    .then((doc) => {
                        if (doc.exists && doc.data().pendingInvites) {
                            const { pendingInvites } = doc.data();
                            setPrevPendingInvites(pendingInvites)
                        }
                    })

                console.log("true, was pending")
            }
            console.log("added channel", roomId)
            console.log(prevPendingInvites, "prevPending")
        }
    }, [props.allChannels])



    useEffect(() => {
        if (roomId && workspaceId) {
            firebase.firestore().collection(`workspaces/${workspaceId}/rooms`).doc(`${roomId}`).get()
                .then((doc) => {
                    if (doc.exists && doc.data().pendingInvites) {
                        const { pendingInvites } = doc.data();
                        setPrevPendingInvites(pendingInvites)
                    }
                })
        }
    }, [roomId, workspaceId])

    useEffect(() => {
        if (roomId && workspaceId) {
            // let unsubLive;
            firebase
                .firestore()
                .collection(`sessions/${workspaceId.toLowerCase()}/rooms`)
                .doc(`${roomId.toLowerCase()}`)
                .onSnapshot(doc => {
                    if (doc.exists) {
                        console.log("LIVE Sessions doc: ", doc.data())
                        const { currentSession } = doc.data()
                        if (currentSession !== "") {
                            setRoomLiveStatus(true);
                        } else {
                            setRoomLiveStatus(false);
                        }
                    }
                })
            // setUnsubscribeFromRoomLiveStatus(unsubLive);
        }
    }, [props])



    const getWorkspaceData = () => {

        let docRef = firebase.firestore().collection(`workspaces`).doc(`${workspaceId}`)
        let workspace_data = docRef.get()
            .then(function (doc) {
                if (doc.exists) {
                    // console.log("Document data workspace:", doc.data());
                    const workspacedata = doc.data()
                    // console.log(workspacedata, 'workspacedata in firestore')
                    setWorkspaceData(workspacedata);
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            })
            .catch(function (error) {
                console.log("Error getting document:", error);
            })

    }

    const getWorkspaceRooms = () => {

        firebase
            .firestore()
            .collection(`users/${props.currentUser.uid}/rooms`)
            .onSnapshot(function (snapshot) {
                let userWorkspaces = [];
                snapshot.forEach(function (doc) {
                    userWorkspaces.push({ ...doc.data() })
                })
                //   console.log("userWorkspaces", userWorkspaces[0])
                let index;
        
                userWorkspaces.forEach((workspace, key) => {
                  if(workspace.workspaceId === workspaceId){
                    index = key
                  }
                })

                setWorkspaceRooms(userWorkspaces[index])
            })

    }

    const getLoadedMessages = () => {
        const query = firebase
            .firestore()
            .collection(
                `/workspaces/${workspaceId}/rooms/${roomId}/messages`
            )
            .orderBy("timestamp", "desc")
            .limit(12);

        query.get().then((querySnapshot) => {
            const tempMessages = [];
            querySnapshot.docs.forEach((message) => {
                // console.log(message,'message')
                tempMessages.push({ uid: message.doc, ...message.data() })
            })
            // console.log(tempMessages,'tempMessages')
            setAllMessagesState(tempMessages)
        })

    }




    const gotoRoomHandler = () => {
        setJoinWithVideo(false);
        console.log('without video',workspaceData)
        setCurrentWorkspace({ ...workspaceData, ...workspaceRooms})
        setParticipants(workspaceId, roomId)
        // setting channel after setting workspace else in NewMiddle.js the workspace remains null when component did mount happens.
        const currentChannelDeep = _.cloneDeep({ ...roomData, roomId });
        setCurrentChannel(currentChannelDeep)
        const messagesDeep = _.cloneDeep(allMessages);
        setAllMessages(messagesDeep);
        resetData()
        // props.hide()
        setDataLoaded(true);
        return(true);
    }

    const gotoRoomWithVideoHandler = () => {
        setJoinWithVideo(true);
        console.log('with video',workspaceData)
        setChannelDetails({ ...roomData, roomId })
        setIsVideoVisible(true);
        setCurrentWorkspace({ ...workspaceData, ...workspaceRooms})
        setParticipants(workspaceId, roomId)
        // setting channel after setting workspace else in NewMiddle.js the workspace remains null when component did mount happens.
        const currentChannelDeep = _.cloneDeep({ ...roomData, roomId });
        setCurrentChannel(currentChannelDeep)
        const messagesDeep = _.cloneDeep(allMessages);
        setAllMessages(messagesDeep);
        resetData()
        // props.hide()
        setDataLoaded(true);

        setTimeout(() => {props.hide(); setDataLoaded(false)}, 2000) 

        return(true);
    }

    const getLive = async () => {
        const isLive = await firebase
        .firestore()
        .collection(`sessions/${workspaceId.toLowerCase()}/rooms`)
        .doc(`${roomId.toLowerCase()}`)
        .get()
        .then(doc => {
            if (doc.exists) {
                console.log("LIVE Sessions doc: ", doc.data())
                const { currentSession } = doc.data()
                if (currentSession !== "") {
                    return(true);
                } else {
                    return(false);
                }
            }else{
                return(false);
            }
        });
        return isLive;
    }

    const directJoinRoom = () => {
        if(!_.isEmpty(workspaceData) && !_.isEmpty(workspaceRooms) && workspaceId && !dataLoaded){
            console.log(getLive())
            getLive().then((isLive) => {
                console.log(isLive)
                // setting a spinner / notification that room is loading
                if(isLive){
                    axios.post(`${API_BASE_URL}${MEETING_TYPE}`, {
                        room_id: roomId,
                        workspace_id: workspaceId,
                        user_id: props.currentUser.uid,
                        email: props.currentUser.email
                    }).then((res) => {
                        if (res.status === 200) {
                            setVideoDetails(res.data.data)
                        } else {
                            console.log("Could not get info about video meeting:", res.status)
                        }
                    }).catch((error) => {
                        console.log("Could not get info about video meeting:", error)
                    })
                    gotoRoomWithVideoHandler();
                    if(props.alreadyExists){
                        props.hide()
                        setDataLoaded(false) 
                    }
                }else if(!isLive){
                    gotoRoomHandler();
                    if(props.alreadyExists){
                        props.hide()
                        setDataLoaded(false) 
                    }
                }
            })
        }
    }

    useEffect(() => {
        if(roomData && roomData.roomType === 0 || (roomData && roomData.roomType === 1 && props.alreadyExists)){
            // if public room
            directJoinRoom();
        }
    },[workspaceData, workspaceRooms, roomData, roomLiveStatus])



    return (
        <>
            {
                roomData && dataLoaded && !props.alreadyExists && !joinWithVideo ?

                    <Modal show={props.show} onHide={props.hide} backdrop="static" keyboard={false}>
                        <Modal.Body className="bg-theme text-theme rounded shadow-lg">
                            <>
                                <div className='d-flex align-items-center py-2 my-2 rounded-pill'>
                                    <div className={roomLiveStatus ? "liveroom headerimageicon" : "headerimageicon"}>
                                        <img
                                            className={roomLiveStatus ? "mr-2 rounded-circle profile-image" : "mr-2 rounded-circle"}
                                            src={roomData.roomPic}
                                            style={{ height: 40, width: 40 }} />
                                    </div>
                                            &nbsp;
                                            <div className="d-inline-block text-theme small font-weight-bold">
                                        {roomData.roomName}<br />
                                        <span>{roomData.roomType === 1 && <FontAwesomeIcon icon={faLock} className="text-success mr-1" style={{ fontSize: 12 }} />}</span>
                                        <span>{roomData.roomType === 1 ? "Private" : "Public"}</span>
                                    </div>
                                </div>
                                <p>
                                    You've successfully joined {roomData.roomName}. 
                    </p>
                                <div>
                                    {/* <Button
                                        variant="primary"
                                        className="mr-3"
                                        onClick={gotoRoomHandler}
                                    >Select the Room</Button>
                                    <Button
                                        variant="primary"
                                        className="mr-3"
                                        onClick={gotoRoomWithVideoHandler}
                                    >Join Meeting</Button> */}


                                    <Button
                                        className="mr-3"
                                        variant="primary"
                                        onClick={() => {
                                            setTimeout(() => {props.hide(); setDataLoaded(false)}, 1000);
                                            axios.post(`${API_BASE_URL}${MEETING_TYPE}`, {
                                                room_id: roomId,
                                                workspace_id: workspaceId,
                                                user_id: props.currentUser.uid,
                                                email: props.currentUser.email
                                            }).then((res) => {
                                                if (res.status === 200) {
                                                    setVideoDetails(res.data.data)
                                                    setChannelDetails({ ...roomData, roomId })
                                                    setIsVideoVisible(true);
                                                } else {
                                                    console.log("Could not get info about video meeting:", res.status)
                                                }
                                            }).catch((error) => {
                                                console.log("Could not get info about video meeting:", error)
                                            })

                                        }}
                                    >
                                        Join Meeting
                                    </Button>



                                    <Button
                                        variant="secondary"
                                        className="mr-3"
                                        onClick={() => { setTimeout(() => {props.hide(); setDataLoaded(false)}, 1000) }}
                                    >Close</Button>
                                </div>
                            </>
                        </Modal.Body>
                    </Modal>
                    : 
                 null

            }
        </>
    )
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    currentChannelMessages: state.channel.currentChannelMessages,
    currentChannel: state.channel.currentChannel,
    isVideoVisible: state.platform.isVideoVisible,
    allChannels: state.channel.allChannels,
    currentWorkspace: state.workspace.currentWorkspace
})

export default connect(mapStateToProps,
    {
        setCurrentChannel,
        setCurrentWorkspace,
        setAllMessages,
        setParticipants,
        resetData,
        setLeftPanel,
        setIsVideoVisible,
        setChannelDetails,
        setVideoDetails
    })(GotoRoomOrVideo);