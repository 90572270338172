import React from "react";
import {
  Button,
  Form,
  InputGroup,
  FormControl,
  Spinner,
  Accordion,
  Card,
  Modal
} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faAngleDown,
  faImages,
  faCheckCircle,
  faCheck,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import firebase from "firebase";
import {
  setQuizPlusTitle,
  setQuizPlusQuestions,
  setQuizPlusOptionsImages,
  setQuizPlusPreviewImages,
  setQuizPlusOptions,
  setQuizPlusRandomize,
  setQuizPlusDescriptions,
  setQuizPlusQuestionImages,
  setQuizPlusQuestionPreviewImages,
  setQuizPlusQuestionTypes,
  setQuizPlusTypes,
  setIsNotEditable,
  setShowDescriptionAfterSelecting,
  setPercentageVisibleToParticipants
} from "./actions";
import {
  resetData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
} from "../../../actions/index";
import TaskCommon from "../Common/TaskCommon";
import { ChromePicker } from 'react-color'
import { toast } from "react-toastify";
import { API_BASE_URL, ROOM_ADMIN_CONFIG, ROOM_CONFIG_SET, QUIZPLUS_ADD_QUESTION_CATEGORY, QUIZPLUS_QUESTION_CATEGORIES } from "../../../config";
import Axios from 'axios';
import OutsideAlerter from "./OutsideAlerter";
import Select from 'react-select';
toast.configure();

class AddQuiz extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  state = {
    errorObj: { title: "", questions: [], newQuestionTypeTitle: "" },
    allFolders: [],
    saveForLaterLoading: false,
    shareNowLoading: false,
    error: "",
    key: "",
    allQuestionTypes: [
      // { hexCode: "#959a9c", name: "Information", type: 1 },
      // { hexCode: "#a4dbaa", name: "Caries Risk", type: 2 },
      // { hexCode: "#ff810a", name: "Periodontal Risk", type: 3 },
      // { hexCode: "#7088b5", name: "Health (apnea) Risk", type: 4 },
      // { hexCode: "#8a679c", name: "Trauma Risk", type: 5 },
      // { hexCode: "#dbbb1a", name: "Urgency", type: 6 },
      // { hexCode: "#a82d14", name: "Stop & Review", type: 7 },
    ],
    currentActiveKey: "1",
    addQuestionType: false,
    newType: { hexCode: "#efefef", title: "" },
    showColorPicker: false,
    showFontPicker: false,
  };

  componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;
    let allFolders = [];
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/folders`
      );

    query.onSnapshot((snapshot) => {
      let changes = snapshot.docChanges();
      console.log(changes);
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.exists) {
            let data = change.doc.data();
            let json = { ...data, id: change.doc.id };
            allFolders.unshift(json);
          }
        }
      });
      this.setState({ allFolders });
    });

    Axios.get(`${API_BASE_URL}${QUIZPLUS_QUESTION_CATEGORIES}`).then((res) => {
      if (res.status === 200) {
        if (res.data.data.question_categories) {
          const allQuestionTypes = res.data.data.question_categories.map((questionType) => {
            return (
              {
                value: questionType,
                label: (
                  <div className="d-flex align-items-center">
                    <div className="rounded m-1" style={{ height: 20, width: 20, backgroundColor: questionType.properties?.hexCode }}></div>
                    <p className="mb-0 m-1 text-theme">{questionType.name}</p>
                  </div>
                ),
                name: questionType.name,
                id: questionType.id
              }
            )
          })
          this.setState({ allQuestionTypes })
        }
      }
    })
      .catch((error) => {
        console.log(error, "error")
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.addQuestionType !== this.state.addQuestionType) {
      Axios.get(`${API_BASE_URL}${QUIZPLUS_QUESTION_CATEGORIES}`).then((res) => {
        if (res.status === 200) {
          if (res.data.data.question_categories) {
            const allQuestionTypes = res.data.data.question_categories.map((questionType) => {
              return (
                {
                  value: questionType,
                  label: (
                    <div className="d-flex align-items-center">
                      <div className="rounded m-1" style={{ height: 20, width: 20, backgroundColor: questionType.properties?.hexCode }}></div>
                      <p className="mb-0 m-1 text-theme">{questionType.name}</p>
                    </div>
                  ),
                  name: questionType.name,
                  id: questionType.id
                }
              )
            })
            this.setState({ allQuestionTypes })
          }
        }
      })
        .catch((error) => {
          console.log(error, "error")
        })
    }
  }

  handleChange = (e) => {
    if (e.target.name === "quizPlusTitle") {
      this.props.setQuizPlusTitle(e.target.value);
    }
    if (e.target.name === "folder") {
      this.props.setTaskFolder(e.target.value);
    }
    if (e.target.name === "newQuestionTypeTitle") {
      this.setState({ newType: { ...this.state.newType, title: e.target.value } });
    }
  };
  handleChangeRandomize = (e) => {
    //console.log(e.target.checked);
    if (e.target.name === "randomize") {
      this.props.setQuizPlusRandomize(e.target.checked);
    }
  };

  // ADDING QUESTION + OPTIONS + CORRECT_ANSWER
  addQuestion = () => {
    //colors
    //gray=#959a9c
    //green=#9db09f
    //orange=#f59f1d
    //blue=#7088b5
    //purple=#8a679c
    //yellow=#dbbb1a
    //Red=#a82d14
    let prevQuestion = [...this.props.questions];
    prevQuestion[prevQuestion.length] = "";
    this.props.setQuizPlusQuestions(prevQuestion);

    this.setState({ currentActiveKey: prevQuestion.length })

    let prevDescriptions = [...this.props.descriptions];
    prevDescriptions[prevDescriptions.length] = "";
    this.props.setQuizPlusDescriptions(prevDescriptions);

    let prevQuestionTypes = [...this.props.questionTypes];
    prevQuestionTypes[prevQuestionTypes.length] = null;
    this.props.setQuizPlusQuestionTypes(prevQuestionTypes);

    let prevOptions = [...this.props.options];
    prevOptions[prevOptions.length] = [""];
    this.props.setQuizPlusOptions(prevOptions);

    let prevImageOption = [...this.props.imageOptions];
    prevImageOption[prevImageOption.length] = [""];
    this.props.setQuizPlusOptionsImages(prevImageOption);
    let prevPreviewImageOption = [...this.props.previewImageOptions];
    prevPreviewImageOption[prevPreviewImageOption.length] = [""];
    this.props.setQuizPlusPreviewImages(prevPreviewImageOption);

    let prevQuestionImages = [...this.props.questionImages];
    prevQuestionImages[prevQuestionImages.length] = "";
    this.props.setQuizPlusQuestionImages(prevQuestionImages);

    let prevQuestionPreviewImages = [...this.props.questionPreviewImages];
    prevQuestionPreviewImages[prevQuestionPreviewImages.length] = "";
    this.props.setQuizPlusQuestionPreviewImages(prevQuestionPreviewImages);

    let prevTypes = [...this.props.types];
    prevTypes[prevTypes.length] = "single";
    this.props.setQuizPlusTypes(prevTypes);
  };
  addOption = (i) => {
    //console.log("option",this.props.options[i].length)
    if (this.props.options[i].length < 8) {
      let prevOptions = this.props.options;
      let thatOption = prevOptions[i];
      prevOptions[i] = [...thatOption, ""];
      this.props.setQuizPlusOptions(prevOptions);
      this.props.setQuizPlusQuestions([...this.props.questions]);
    } else {
      this.setState({ error: "Options can't be more than 8.", key: i });
      return;
    }
  };

  // REMOVING QUESTION + OPTIONS
  removeQuestion = (key) => {
    let prevQuestions = [...this.props.questions];
    prevQuestions.splice(key, 1);
    this.props.setQuizPlusQuestions(prevQuestions);
    let prevDescriptions = [...this.props.descriptions];
    prevDescriptions.splice(key, 1);
    this.props.setQuizPlusDescriptions(prevDescriptions);

    let prevQuestionTypes = [...this.props.questionTypes];
    prevQuestionTypes.splice(key, 1);
    this.props.setQuizPlusQuestionTypes(prevQuestionTypes);

    let prevOptions = [...this.props.options];
    prevOptions.splice(key, 1);
    this.props.setQuizPlusOptions(prevOptions);

    let prevImageOption = [...this.props.imageOptions];
    prevImageOption.splice(key, 1);
    this.props.setQuizPlusOptionsImages(prevImageOption);

    let prevPreviewImageOptions = [...this.props.previewImageOptions];
    prevPreviewImageOptions.splice(key, 1);
    this.props.setQuizPlusPreviewImages(prevPreviewImageOptions);

    let prevQuestionImages = [...this.props.questionImages];
    prevQuestionImages.splice(key, 1);
    this.props.setQuizPlusQuestionImages(prevQuestionImages);

    let prevQuestionPreviewImages = [...this.props.questionPreviewImages];
    prevQuestionPreviewImages.splice(key, 1);
    this.props.setQuizPlusQuestionPreviewImages(prevQuestionPreviewImages);

    let prevTypes = [...this.props.types];
    prevTypes.splice(key, 1);
    this.props.setQuizPlusTypes(prevTypes);
  };
  removeOption = (key, k) => {
    let prevOptions = this.props.options;
    let thatOption = prevOptions[key];

    let prevImage = this.props.previewImageOptions;
    let thatImg = prevImage[key];

    let imgOption = this.props.imageOptions;
    let thatImages = imgOption[key];

    thatOption.splice(k, 1);
    thatImg.splice(k, 1);
    thatImages.splice(k, 1);

    prevOptions[key] = thatOption;
    prevImage[key] = thatImg;
    this.props.setQuizPlusPreviewImages(prevImage);
    this.props.setQuizPlusOptionsImages(imgOption);
    this.props.setQuizPlusOptions(prevOptions);
    this.props.setQuizPlusQuestions([...this.props.questions]);
  };

  // HANDLES QUESTIONS ADDED
  handleQuestions = (e, key) => {
    let prev = [...this.props.questions];
    prev[key] = e.target.value;
    this.props.setQuizPlusQuestions(prev);
  };
  handleQuestionsTypes = (e, key) => {
    let prev = [...this.props.questionTypes];
    prev[key] = e.target.value;
    this.props.setQuizPlusQuestionTypes(prev);
  };
  handleQuestionsTypesButton = (val, key) => {
    let prev = [...this.props.questionTypes];
    prev[key] = val;
    this.props.setQuizPlusQuestionTypes(prev);
  };

  handleDescriptions = (e, key) => {
    let prev = [...this.props.descriptions];
    prev[key] = e.target.value;
    this.props.setQuizPlusDescriptions(prev);
  };
  handleOptions = (e, key, k) => {
    let prevOptions = this.props.options;
    let thatOption = [...prevOptions[key]];

    thatOption[k] = e.target.value;
    prevOptions[key] = thatOption;

    this.props.setQuizPlusOptions(prevOptions);
    this.props.setQuizPlusQuestions([...this.props.questions]);
  };

  handleOptionsImages = (e, key, k) => {
    const reader = new FileReader();

    let prevImageOptions = this.props.imageOptions;
    let thatImageOptions = [...prevImageOptions[key]];

    thatImageOptions[k] = e.target.files[0];

    //console.log(thatImageOptions, "imageOptions");
    prevImageOptions[key] = thatImageOptions;
    this.props.setQuizPlusOptionsImages(prevImageOptions);
    this.props.setQuizPlusQuestions([...this.props.questions]);

    reader.readAsDataURL(e.target.files[0]);

    reader.addEventListener("load", () => {
      let prevImg = this.props.previewImageOptions;
      let thatImgOption = [...prevImg[key]];
      thatImgOption[k] = reader.result;
      //console.log(thatImgOption, "prev");
      prevImg[key] = thatImgOption;
      this.props.setQuizPlusPreviewImages(prevImg);
      this.props.setQuizPlusQuestions([...this.props.questions]);
    });
  };

  handleQuestionImages = (e, key) => {
    const reader = new FileReader();

    let prevQuestionImages = this.props.questionImages;
    let thatImageOption = e.target.files[0];
    prevQuestionImages[key] = thatImageOption;
    this.props.setQuizPlusQuestionImages(prevQuestionImages);
    this.props.setQuizPlusQuestions([...this.props.questions]);

    reader.readAsDataURL(e.target.files[0]);

    reader.addEventListener("load", () => {
      let prevImg = this.props.questionPreviewImages;
      prevImg[key] = reader.result;
      this.props.setQuizPlusQuestionPreviewImages(prevImg);
      this.props.setQuizPlusQuestions([...this.props.questions]);
    });
  };

  executeScroll = () => this.myRef?.current?.scrollIntoView()

  handleTypes = (val, key) => {
    let prev = [...this.props.types];
    prev[key] = val ? "multiple" : "single";
    this.props.setQuizPlusTypes(prev);
  };

  handleShowDescriptions = (val, key) => {
    let prev = [...this.props.showDescriptionAfterSelecting];
    prev[key] = val;
    this.props.setShowDescriptionAfterSelecting(prev);
  }

  handlePercentageVisibleToParticipant = (val, key) => {
    let prev = [...this.props.percentageVisibleToParticipants];
    prev[key] = val;
    this.props.setPercentageVisibleToParticipants(prev);
  }

  randomIdGenerator = (arrayOfIds) => {
    const id = Math.floor(100000 + Math.random() * 900000);
    if (!arrayOfIds.includes(id)) {
      return id;
    } else {
      return (this.randomIdGenerator(arrayOfIds));
    }
  }

  addQuestionTypeHandler = () => {

    if (this.state.newType.title.trim() !== "") {
      Axios.post(`${API_BASE_URL}${QUIZPLUS_ADD_QUESTION_CATEGORY}`, {
        workspace_id: this.props.currentWorkspace.workspaceId,
        room_id: this.props.currentChannel.roomId,
        user_id: this.props.currentUser.uid,
        category_name: this.state.newType.title,
        properties: {
          hexCode: this.state.newType.hexCode
        }
      }).then((res) => {
        if (res.status === 201) {
          this.setState({ addQuestionType: false, newType: { hexCode: "#efefef", title: "" } })
        }
      })
        .catch((error) => {
          console.log(error, "error")
        })
    }
  }

  render() {
    const {
      quizPlusTitle,
      questions,
      options,
      imageOptions,
      allParticipants,
      editedQuiz,
      getQuiz,
      saveForLaterTask,
      shareTask,
      validateData,
      addTask,
      saveTask,
      error,
      folder,
      descriptions,
      questionTypes,
      questionImages,
      questionPreviewImages,
      types
    } = this.props;
    const { allQuestionTypes, currentActiveKey } = this.state;


    const validateDataOfField = (e) => {
      let { errorObj } = this.state;
      if (e.target.name === "questions[]") {
        if (e.target.value.trim().length < 1) {
          errorObj.questions[+e.target.id + 1] = "Question cannot be empty.";
        } else {
          errorObj.questions[+e.target.id + 1] = "";
        }
      }
      if (e.target.name === "quizPlusTitle") {
        if (e.target.value.trim().length < 1) {
          errorObj.title = "Title cannot be empty.";
        } else {
          errorObj.title = "";
        }
      }
      if (e.target.name === "newQuestionTypeTitle") {
        if (e.target.value.trim().length < 1) {
          errorObj.newQuestionTypeTitle = "Title cannot be empty.";
        } else {
          errorObj.newQuestionTypeTitle = "";
        }
      }
      this.setState({ errorObj });
    };

    let { errorObj, allFolders } = this.state;
    return (
      <React.Fragment>
        <div className="taskBox text-theme">
          <Form>
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
              <p className="mb-1 font-weight-bold text-theme small">
                Title
              </p>
              <InputGroup className={errorObj.title ? "mb-1" : "mb-3"}>
                <FormControl
                  size="sm"
                  placeholder="Add title"
                  aria-label="Add title"
                  aria-describedby="add-title"
                  name="quizPlusTitle"
                  autoFocus={true}
                  onKeyUp={(e) => validateDataOfField(e)}
                  onBlur={(e) => validateDataOfField(e)}
                  onChange={this.handleChange}
                  value={quizPlusTitle}
                  disabled={this.props.isNonEditable}
                />
              </InputGroup>
              {errorObj.title
                ? <p className="small text-danger">{errorObj.title}</p>
                : null}

              <Accordion activeKey={`${this.state.currentActiveKey}`} defaultActiveKey="1">
                {questions.length > 0 &&
                  questions.map((question, key) => (
                    <Card className="bg-theme my-1">

                      <div className="d-flex ">
                        <Accordion.Toggle
                          eventKey={`${key + 1}`}
                          as={Card.Header}
                          variant="link"
                          className="flex-grow-1 p-2 pointer border-bottom-0 bg-theme"
                          onClick={() => {
                            if (this.state.currentActiveKey === key + 1)
                              this.setState({ currentActiveKey: null })
                            else
                              this.setState({ currentActiveKey: key + 1 })
                          }}
                        >
                          <p className="mb-0 small font-weight-bold">
                            Question {key + 1}
                          </p>
                          {questions[key] &&
                            <div className="row">

                              <div className="rounded m-1" style={{ height: 20, width: 20, backgroundColor: questionTypes[key] }}></div>

                              <p className="mb-1 px-3" style={{ whiteSpace: 'break-spaces' }}>{questions[key]}</p>
                            </div>}
                          {errorObj.questions[key + 1] ? (
                            <p className="small text-danger mb-1">
                              {errorObj.questions[key + 1]}
                            </p>
                          ) : (
                              ""
                            )}
                          <div>
                            {(this.props.options[key] && this.props.options[key][1]) ?
                              (<div className="ml-2">
                                {`(${this.props.options[key].length} options`}{(this.props.imageOptions[key][1]) ? "+ images.)" : ")"}
                              </div>)
                              : null
                            }

                          </div>
                        </Accordion.Toggle>
                        <div
                          className="pointer mt-1 mr-1"
                        //style={{ height: "1.5rem", width: "1.5rem", backgroundColor: "rgba(255,255,255,0.2)" }}
                        >
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            onClick={() => {
                              if (!this.props.isNonEditable)
                                this.removeQuestion(key)
                            }
                            }
                            title="Delete"
                          />
                        </div>
                      </div>

                      <Accordion.Collapse eventKey={`${key + 1}`}>
                        <Card.Body className="pt-1 px-2">
                          <InputGroup className="mb-1">
                            <FormControl
                              placeholder="Question"
                              aria-label="Question"
                              aria-describedby={"question" + key}
                              as="textarea"
                              name="questions[]"
                              value={questions[key]}
                              id={key}
                              autoFocus={key === questions.length - 1}
                              onKeyUp={(e) => validateDataOfField(e)}
                              onBlur={(e) => validateDataOfField(e)}
                              onChange={(e) => this.handleQuestions(e, key)}
                              required={true}
                              ref={this.myRef}
                              disabled={this.props.isNonEditable}
                            />
                          </InputGroup>
                          {errorObj.questions[key + 1] ? (
                            <p className="small text-danger mb-1">
                              {errorObj.questions[key + 1]}
                            </p>
                          ) : (
                              ""
                            )}
                          <p className="mb-0 mt-1 flex-grow-1 small font-weight-bold">
                            Description {key + 1}
                          </p>
                          <InputGroup className="mb-1">
                            <FormControl
                              placeholder="Description"
                              aria-label="Description"
                              aria-describedby={"description" + key}
                              as="textarea"
                              name="descriptions[]"
                              value={descriptions[key]}
                              id={key}
                              onChange={(e) => this.handleDescriptions(e, key)}
                              disabled={this.props.isNonEditable}
                            />
                          </InputGroup>

                          <Form.Group className="mb-1">
                            <Form.Check
                              required
                              label="Multiple answers"
                              value={types[key] === "multiple" ? true : false}
                              checked={types[key] === "multiple" ? true : false}
                              onChange={(e) =>
                                this.handleTypes(
                                  types[key] === "multiple" ? false : true,
                                  key
                                )
                              }
                              disabled={this.props.isNonEditable}
                            />
                          </Form.Group>

                          <Form.Group className="mb-1">
                            <Form.Check
                              required
                              label="Description is shown after selecting"
                              value={this.props.showDescriptionAfterSelecting[key] ? true : false}
                              checked={this.props.showDescriptionAfterSelecting[key] ? true : false}
                              onChange={(e) =>
                                this.handleShowDescriptions(
                                  this.props.showDescriptionAfterSelecting[key] ? false : true,
                                  key
                                )
                              }
                              disabled={this.props.isNonEditable}
                            />
                          </Form.Group>

                          <Form.Group className="mb-1">
                            <Form.Check
                              required
                              label="Show percentage to user after selecting"
                              value={this.props.percentageVisibleToParticipants[key] ? true : false}
                              checked={this.props.percentageVisibleToParticipants[key] ? true : false}
                              onChange={(e) =>
                                this.handlePercentageVisibleToParticipant(
                                  this.props.percentageVisibleToParticipants[key] ? false : true,
                                  key
                                )
                              }
                              disabled={this.props.isNonEditable}
                            />
                          </Form.Group>

                          <p className="mb-0 mt-1 font-weight-bold text-theme small">
                            Question type
                          </p>
                          <div className="mb-2">

                            <Select
                              options={this.state.allQuestionTypes}
                              isSearchable={true}
                              value={this.state.allQuestionTypes.filter((questionType) => {
                                if (questionType.id === this.props.questionTypes[key]) {
                                  return (questionType)
                                }
                              })}
                              onChange={(e) => {
                                if (e) {
                                  let prev = [...this.props.questionTypes];
                                  prev[key] = e.id;
                                  this.props.setQuizPlusQuestionTypes(prev)
                                } else {
                                  let prev = [...this.props.questionTypes];
                                  prev[key] = null;
                                  this.props.setQuizPlusQuestionTypes(prev)
                                }
                              }
                              }
                              filterOption={(option, searchText) => {
                                console.log(option, "option")
                                if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
                                  return true;
                                } else {
                                  return false
                                }
                              }}
                              isClearable={true}
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#007bff',
                                  neutral0: `var(--bg-card-theme)`,
                                  neutral80: `var(--text-theme)`,
                                },
                              })}
                              styles={{
                                // control: (base) => ({
                                //   ...base,
                                //   borderRadius: `50rem !important`,
                                // }),
                                menu: (base) => ({
                                  // height: '100%'
                                })
                              }}
                              isDisabled={this.props.isNonEditable}
                            />


                            <button disabled={this.props.isNonEditable} className="my-3 btn btn-primary btn-sm" onClick={() => { this.setState({ addQuestionType: true }) }}>
                              Add question type
                              </button>
                          </div>

                          <p className="mb-0 mt-1 font-weight-bold text-theme small">
                            Question image
                          </p>
                          <div className="d-flex mb-2">
                            {this.props.questionPreviewImages[key] && (<div className="flex-grow-1">
                              <img
                                alt=""
                                className="rounded img-responsive"
                                style={{
                                  background: "white",
                                  height: 60,
                                  width: "auto",
                                }}
                                src={this.props.questionPreviewImages[key]}
                              />
                            </div>)}
                            <div
                              className="m-1 pointer"
                              style={{
                                position: "relative",
                                height: 30,
                                width: 30,
                              }}
                            >
                              <div
                                className="bg-light d-flex align-items-center justify-content-center"
                                style={{
                                  borderRadius: 10,
                                  position: "absolute",
                                  cursor: "pointer",
                                  height: 30,
                                  width: 30,
                                  borderWidth: 0.1,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faImages}
                                  className="text-dark"
                                />
                              </div>
                              <input
                                id={"que" + key}
                                type="file"
                                accept="image/*"
                                //name={options[key]}
                                onChange={(e) => this.handleQuestionImages(e, key)}
                                style={{
                                  position: "absolute",
                                  opacity: 0,
                                  height: 30,
                                  width: 30,
                                }}
                                disabled={this.props.isNonEditable}
                              />
                            </div>
                          </div>

                          {options[key] && options[key].map((option, k) => (
                            <>
                              {this.props.previewImageOptions[key][k] && (
                                <div className="px-2 py-2">
                                  <img
                                    alt=""
                                    className="rounded img-responsive mb-1"
                                    style={{
                                      background: "white",
                                      height: 60,
                                      width: "auto",
                                    }}
                                    src={this.props.previewImageOptions[key][k]}
                                  />
                                </div>
                              )}
                              <InputGroup className="mb-1" key={k}>
                                <FormControl
                                  placeholder="Option"
                                  name={options[key]}
                                  value={options[key][k]}
                                  id={k}
                                  onChange={(e) => this.handleOptions(e, key, k)}
                                  required={true}
                                  disabled={this.props.isNonEditable}
                                />
                                <InputGroup.Append>
                                  <InputGroup.Text>
                                    <FontAwesomeIcon
                                      icon={faTimes}
                                      onClick={() => {
                                        if (!this.props.isNonEditable)
                                          this.removeOption(key, k)
                                      }}
                                      disabled={this.props.isNonEditable}
                                    />
                                  </InputGroup.Text>
                                </InputGroup.Append>
                                <div
                                  className="m-1 pointer"
                                  style={{
                                    position: "relative",
                                    height: 30,
                                    width: 30,
                                  }}
                                >
                                  <div
                                    className="bg-light d-flex align-items-center justify-content-center"
                                    style={{
                                      borderRadius: 10,
                                      position: "absolute",
                                      cursor: "pointer",
                                      height: 30,
                                      width: 30,
                                      borderWidth: 0.1,
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faImages}
                                      className="text-dark"
                                    />
                                  </div>
                                  <input
                                    id={k}
                                    type="file"
                                    accept="image/*"
                                    //name={options[key]}
                                    onChange={(e) =>
                                      this.handleOptionsImages(e, key, k)
                                    }
                                    style={{
                                      position: "absolute",
                                      opacity: 0,
                                      height: 30,
                                      width: 10,
                                    }}
                                    disabled={this.props.isNonEditable}
                                  />
                                </div>
                              </InputGroup>
                            </>
                          ))}
                          <InputGroup className="w-100">
                            <Button
                              size="sm"
                              className="w-100"
                              onClick={() => this.addOption(key)}
                              disabled={this.props.isNonEditable}
                            >
                              + <small>Add Option</small>
                            </Button>
                          </InputGroup>
                          <div className="text-danger">
                            {this.state.error && key === this.state.key
                              ? this.state.error
                              : null}
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
              </Accordion>

              <Modal show={this.state.addQuestionType}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header className="bg-theme border-0 d-flex justify-content-between align-items-center pb-0">
                  <Modal.Title id="contained-modal-title-vcenter">
                    <h5 className="text-theme">Add question type</h5>
                  </Modal.Title>
                  <div title="Close" className="pointer" onClick={() => {
                    if (!this.props.isNonEditable)
                      this.setState({ addQuestionType: false })
                  }}>
                    <FontAwesomeIcon className="text-theme" icon={faTimes} />
                  </div>
                </Modal.Header>

                <Modal.Body
                  className="overflow-auto list-unstyled bg-theme pt-0 d-flex justify-content-between align-items-center"
                // style={{ minHeight: "35vh", maxHeight: "60vh", position: "relative" }}
                >

                      <div style={{
                        position: "relative",
                        zIndex: 500
                      }}
                      >
                        <button
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Color picker"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              showColorPicker: true,
                            });
                          }}
                          style={{
                            height: 32,
                            width: 32,
                            backgroundColor: `${this.state.newType.hexCode}`,
                            zIndex: 1000,
                          }}
                        >
                          {this.state.showColorPicker && (
                            <OutsideAlerter
                              onClickOutside={() => {
                                this.setState({
                                  showColorPicker: false,
                                });
                              }}
                            >
                              <ChromePicker
                                color={this.state.newType?.hexCode}
                                onChangeComplete={(color) => {
                                  this.setState({
                                    newType: {
                                      ...this.state.newType,
                                      hexCode: color.hex,
                                    }
                                  });
                                }}
                                className="mx-auto"
                              />
                            </OutsideAlerter>
                          )}
                        </button>
                      </div>



                      <InputGroup style={{ width: '50%' }} className={errorObj.newQuestionTypeTitle ? "mb-1" : "mb-3"}>
                        <FormControl
                          size="sm"
                          placeholder="Add title"
                          aria-label="Add title"
                          aria-describedby="add-title"
                          name="newQuestionTypeTitle"
                          onKeyUp={(e) => validateDataOfField(e)}
                          onBlur={(e) => validateDataOfField(e)}
                          onChange={this.handleChange}
                          value={this.state.newType?.title}
                          className="my-3"
                          disabled={this.props.isNonEditable}
                        />
                      </InputGroup>

                    {/* font color */}
                    {/* <div className="row">

                      <div style={{
                        position: "relative",
                        zIndex: 500
                      }}
                      className="col-4"
                      >
                        <button
                          data-toggle="tooltip"
                          data-placement="bottom"
                          title="Color picker"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              showFontPicker: true,
                            });
                          }}
                          style={{
                            height: 32,
                            width: 32,
                            backgroundColor: `${this.state.newType.fontCode}`,
                            zIndex: 1000,
                          }}
                        >
                          {this.state.showFontPicker && (
                            <OutsideAlerter
                              onClickOutside={() => {
                                this.setState({
                                  showFontPicker: false,
                                });
                              }}
                            >
                              <ChromePicker
                                color={this.state.newType ? this.state.newType.fontCode ? this.state.newType.fontCode : '#ffffff' : '#ffffff'}
                                onChangeComplete={(color) => {
                                  this.setState({
                                    newType: {
                                      ...this.state.newType,
                                      fontCode: color.hex,
                                    }
                                  });
                                }}
                                className="mx-auto"
                              />
                            </OutsideAlerter>
                          )}
                        </button>
                      </div>
                    </div>



                    <InputGroup style={{ width: '50%' }} className={errorObj.newQuestionTypeTitle ? "mb-1" : "mb-3"}>
                      <FormControl
                        size="sm"
                        placeholder="Add title"
                        aria-label="Add title"
                        aria-describedby="add-title"
                        name="newQuestionTypeTitle"
                        onKeyUp={(e) => validateDataOfField(e)}
                        onBlur={(e) => validateDataOfField(e)}
                        onChange={this.handleChange}
                        value={this.state.newType?.title}
                        className="my-3 col-8"
                        disabled={this.props.isNonEditable}
                      />
                    </InputGroup> */}

                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        variant="light"
                        size="sm"
                        className="d-flex justify-content-center align-items-center text-success"
                        onClick={this.addQuestionTypeHandler}
                        disabled={this.props.isNonEditable}
                      >
                        <FontAwesomeIcon className="mr-2" icon={faCheck} /> Add
                    </Button>
                    </div>

                </Modal.Body>
              </Modal>

              <InputGroup className="w-100 my-2">
                <Button
                  size="sm"
                  variant="outline-primary"
                  className="w-100"
                  onClick={() => { this.addQuestion(); this.executeScroll() }}
                  disabled={this.props.isNonEditable}
                >
                  + <small>Add Question</small>
                </Button>
              </InputGroup>

              <p className="mb-2 font-weight-bold text-theme small">
                Associate folder
              </p>
              <InputGroup className="mb-3">
                <select
                  name="folder"
                  class="custom-select custom-select-sm"
                  onChange={this.handleChange}
                >
                  <option
                    selected={folder === "" || folder === null ? true : false}
                    value=""
                  >
                    Uncategorized
                  </option>
                  {allFolders.map((item) => (
                    <option
                      value={item.id}
                      selected={folder === item.id ? true : false}
                    >
                      {item.folderName}
                    </option>
                  ))}
                </select>
              </InputGroup>

              <Form.Group className="mb-1">
                <Form.Check
                  required
                  label="Can not be edited when shared"
                  value={this.props.isNotEditable ? true : false}
                  checked={this.props.isNotEditable ? true : false}
                  onChange={(e) => {
                    if (e.target.value === "false") {
                      this.props.setIsNotEditable(true)
                    } else {
                      this.props.setIsNotEditable(false)
                    }
                  }
                  }
                />
              </Form.Group>


            </div>
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
              <Form.Group className="mb-1">
                <Form.Check
                  required
                  name="randomize"
                  label="Randomize options order"
                  onChange={(e) => this.handleChangeRandomize(e)}
                />
              </Form.Group>
            </div>
            <TaskCommon
              allParticipants={allParticipants}
              editedTask={Object.keys(editedQuiz).length > 0 ? true : false}
            />
            {error ? <p className="small text-danger">{error}</p> : ""}
            <div className="m-2">
              <p className="my-2 p-0 text-danger small d-block">
                {this.state.error}
              </p>
              <Button
                onClick={
                  () => validateData() && addTask(shareTask())
                  //this.setState({ shareNowLoading: true })
                }
                variant="primary"
                style={{ width: "100px", marginRight: "5px" }}
                size="sm"
                disabled={this.state.shareNowLoading}
              >
                <small>Share now </small>
                {this.state.shareNowLoading && (
                  <Spinner
                    as="span"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                    className="mr-1"
                    size="sm"
                  />
                )}
              </Button>

              <Button
                onClick={() =>
                  validateData() &&
                  saveTask(saveForLaterTask()) &&
                  this.setState({ saveForLaterLoading: true })
                }
                variant="success"
                style={{ width: "120px", marginLeft: "5px" }}
                size="sm"
                disabled={this.state.saveForLaterLoading}
              >
                <small>Save for later </small>
                {this.state.saveForLaterLoading && (
                  <Spinner
                    as="span"
                    role="status"
                    aria-hidden="true"
                    animation="border"
                    className="mr-1"
                    size="sm"
                  />
                )}
              </Button>
            </div>
          </Form>

        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,

  previewImageOptions: state.quizPlus.previewImageOptions,
  imageOptions: state.quizPlus.imageOptions,
  questionPreviewImages: state.quizPlus.questionPreviewImages,
  questionImages: state.quizPlus.questionImages,
  questionTypes: state.quizPlus.questionTypes,
  descriptions: state.quizPlus.descriptions,
  type: state.quizPlus.type,

  types: state.quizPlus.types,
  isNotEditable: state.quizPlus.isNotEditable,
  showDescriptionAfterSelecting: state.quizPlus.showDescriptionAfterSelecting,
  percentageVisibleToParticipants: state.quizPlus.percentageVisibleToParticipants,


  quizPlusTitle: state.quizPlus.quizPlusTitle,
  questions: state.quizPlus.questions,

  options: state.quizPlus.options,
  randomize: state.quizPlus.randomize,

  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  everyone: state.task.everyone,
});

export default connect(mapStateToProps, {
  setQuizPlusTitle,
  setQuizPlusRandomize,
  setQuizPlusQuestions,
  setQuizPlusPreviewImages,
  setQuizPlusOptions,
  setQuizPlusOptionsImages,
  setQuizPlusDescriptions,
  setQuizPlusQuestionImages,
  setQuizPlusQuestionPreviewImages,
  setQuizPlusQuestionTypes,
  resetData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
  setQuizPlusTypes,
  setIsNotEditable,
  setShowDescriptionAfterSelecting,
  setPercentageVisibleToParticipants
})(AddQuiz);
