import React from 'react';
import { connect } from 'react-redux';
import {
    updateCurrentChannel,
    setDeleteTask,
    setOpenTaskId,
    setContentTypeAndValue,
    setTaskId,
    setTaskBranch
} from '../../actions/index';
import { ProgressBar, Alert, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import firebase from 'firebase';

import Feedback from '../Tasks/Feedback';
import Poll from '../Tasks/Poll/PollMessage';
import Assignment from '../Tasks/Assignment/Assignment';
import Quiz from '../Tasks/Quiz/Quiz';
import Project from '../Tasks/Project';
import List from '../Tasks/List';
import Reading from '../Tasks/Reading/Reading';
import Schedule from '../Tasks/Schedule/Schedule';

import IsAdmin from '../IsAdmin';


const timeFromNow = timestamp => {
    if (moment(Date.now()).diff(moment(timestamp), 'days') < 1) return moment(timestamp).fromNow()
    else if (moment(Date.now()).diff(moment(timestamp), 'days') < 2) return "yesterday"
    else if (moment(Date.now()).diff(moment(timestamp), 'days') < 7) return moment(timestamp).format('dddd')
    else return moment(timestamp).format("MMM DD YYYY")
};

const deleteTask = (uid, currentChannel, setDeleteTask, open_task_branch = "messages") => {
    let folder = "";
    firebase.database().ref(`${open_task_branch}/${currentChannel.id}`).child(uid).on("value", snap => {
        folder = snap.val() && snap.val().folder
    });

    if (folder && folder !== "") {
        let tasks = null;
        let folderBranch = (open_task_branch === "messages") ? "tasks" : "saved"
        firebase.database().ref("folders/" + folder).child(folderBranch).on("value", snap => { tasks = snap.val() });

        let updates = {};
        updates[folderBranch] = tasks.filter(task => task !== uid);
        firebase.database().ref("folders/" + folder).update(updates);
    }

    firebase.database().ref(`${open_task_branch}/${currentChannel.id}`).child(uid).remove();

    setDeleteTask(null);
}

const TaskTemplate = ({
    item,
    currentChannel,
    currentUser,
    setDeleteTask,
    delete_id,
    updateCurrentChannel,
    setOpenTaskId,
    setContentTypeAndValue,
    setTaskId,
    setTaskBranch,
    open_task_branch
}) => {
    const progress = item && item.response
        ? item.everyone
            ? (Object.keys(item.response).length / Object.keys(currentChannel.users).filter(item => item !== currentUser.uid).length) * 100
            : (Object.keys(item.response).length / (Object.keys(item.users).filter(item => item !== currentUser.uid).length)) * 100
        : 0;


    const taskResponse = (uid, response) => {
        let allData = {};
        let prevResponse = {};
        firebase.database().ref("messages").child(currentChannel.id).child(uid).on("value", snap => {
            // console.log(snap.val())
            allData = snap.val();
            prevResponse = allData.response ? allData.response : {};
            prevResponse[currentUser.uid] = { value: response, responseTime: Date.now() }
            allData["response"] = prevResponse
        })
        // console.log(uid, response, channel.id, user.uid)
        firebase.database().ref("messages").child(currentChannel.id).child(uid).update(allData);
        // console.log(allData)
    }

    return (
        <>
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme">
                {item.feedback && <Feedback feedbacktitle={item.feedback} uid={item.uid} item={item} user={currentUser.uid} taskResponse={taskResponse} />}
                {item.poll && <Poll pollname={item.poll} uid={item.uid} item={item} user={currentUser.uid} taskResponse={taskResponse} />}
                {item.questions && <Assignment uid={item.uid} item={item} user={currentUser.uid} taskResponse={taskResponse} />}
                {item.quiz_name && <Quiz quiz_data={item} user_id={currentUser.uid} taskResponse={taskResponse} />}
                {item.project && <Project projectname={item.project} uid={item.uid} item={item} user={currentUser.uid} taskResponse={taskResponse} />}
                {item.list && <List uid={item.uid} item={item} user={currentUser.uid} taskResponse={taskResponse} />}
                {item.readings && <Reading uid={item.uid} item={item} user={currentUser.uid} taskResponse={taskResponse} />}
                {item.schedule && <Schedule schedule={item.schedule} uid={item.uid} item={item} user={currentUser.uid} taskResponse={taskResponse} />}
            </div>
            <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme">
                <div>
                    {delete_id === item.uid && <Alert variant="danger" onClose={() => setDeleteTask(null)}>
                        <Alert.Heading>Confirm task deletion!</Alert.Heading>
                        <p>
                            Are you sure you want to delete this task?<br />
                Once deleted you cannot undo this process.
                </p>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <Button onClick={() => setDeleteTask(null)} size="sm" variant="outline-dark">
                                No, cancel it!
                    </Button>
                            <Button onClick={() => { setOpenTaskId(null); deleteTask(delete_id, currentChannel, setDeleteTask, open_task_branch); updateCurrentChannel(currentChannel.id); }} size="sm" variant="outline-danger">
                                Yes, delete it!
                    </Button>
                        </div>
                    </Alert>}
                </div>
                <div>
                    <p className="small">Created by <span className="font-weight-bold"> {item.user && item.user.name} </span> on <span className="timestamp"> {timeFromNow(item.timestamp)}</span></p>

                    <IsAdmin>
                        <p>
                            <button className="btn btn-sm btn-outline-primary p-1 mr-2" onClick={(e) => { e.preventDefault(); setContentTypeAndValue("updateTask"); setTaskBranch(open_task_branch); item.uid && setTaskId(item.uid); }}>
                                Edit
                            </button>
                            <button className="btn btn-sm btn-outline-danger p-1" onClick={(e) => { e.preventDefault(); item.uid && setDeleteTask(item.uid) }}>
                                Delete
                            </button>
                        </p>
                    </IsAdmin>

                </div>

                {item.response && <>
                    {/* <ProgressBar label={`${Math.round(progress)}% complete`} animated style={{ fontSize: "0.7rem" }} now={progress} variant={progress > 50 ? "success" : "warning"} /> */}
                    <div className="">
                        <p className="small">
                            Assigned to {item.everyone
                                ? Object.keys(currentChannel.users).filter(item => item !== currentUser.uid).length
                                : Object.keys(item.users).filter(item => item !== currentUser.uid).length}</p>
                        <p className="small">
                            Responded by {Object.keys(item.response).length}
                        </p>
                    </div>
                </>}
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    currentChannel: state.channel.currentChannel,
    currentUser: state.user.currentUser,
    delete_id: state.platform.delete_id,
    open_task_branch: state.platform.open_task_branch
})

export default connect(
    mapStateToProps, {
    updateCurrentChannel,
    setDeleteTask,
    setOpenTaskId,
    setContentTypeAndValue,
    setTaskId,
    setTaskBranch
})(TaskTemplate);