import React, { useState, useEffect, useReducer } from "react";
import moment from "moment";
import { Button, Dropdown, Badge, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faReply,
  faAngleDown,
  faVideo,
  faBan,
  faTimes,

} from "@fortawesome/free-solid-svg-icons";
import Feedback from "../Tasks/Feedback";
import Poll from "../Tasks/Poll/Poll";
// import Poll from "../Tasks/Poll/PollMessage";
import Quiz from "../Tasks/Quiz/Quiz";
import QuizPlus from "../Tasks/Quiz-Plus/QuizPlus";
import Project from "../Tasks/Project";
import List from "../Tasks/List";
import Assignment from "../Tasks/Assignment/Assignment1";
import PatientDetailsForm from "../Tasks/CaseSheet/PatientDetails/PatientDetailsForm"
//import Cloud from "../Tasks/Cloud/Cloud";
import Reading from "../Tasks/Reading/Reading1";
import Schedule from "../Tasks/Schedule/Schedule";
import ReadMore from "./ReadMore";
import IsAdmin from "../IsAdmin";

import Linkify from "linkifyjs/react";
import Viewer from "react-viewer";
import firebase from "firebase";
import _ from 'lodash';

import { connect } from "react-redux";
import {
  setReplyId,
  setMessageForward,
  setMessagesForwardArray,
  setContentTypeAndValue,
  setRightPanel,
  resetAllTasks,
  resetTask,
  resetData,
  setPollTitle,
  setPollOptions,
  setScheduleTitle,
  setScheduleDurationHours,
  setScheduleDurationMinutes,
  setSchedulePlace,
  setScheduleAgenda,
  setReadingTitles,
  setReadingLinks,
  setReadingFiles,
  setReadingUrls,
  setTaskSharing,
  setTaskDeadline,
  setTaskEveryone,
  setTaskChannelUsers,
  setSharedTask,
  setEditingPublished,
  setEditedPublishedTask
} from "../../actions/index";

import {
  setQuizTitle,
  setQuizQuestions,
  setQuizOptions,
  setQuizTypes,
  setQuizCorrectAnswers,
  setQuizWeights,
  setQuizTotalWeight,
  setQuizGraded,
  //setQuizSharing,
} from "../Tasks/Quiz/actions";

import {
  setAssignmentQuestions,
  setAssignmentDescriptions,
  setAssignmentWeights,
  setAssignmentTotalWeight,
  setAssignmentGraded,
} from "../Tasks/Assignment/actions";
import { API_BASE_URL, QUIZPLUS_DOWNLOAD_RESPONSES, APP_CLOSE, POLL_DOWNLOAD_RESPONSES, QUIZ_DOWNLOAD_RESPONSES } from "../../config";
import RoomSelectModal from "./RoomSelectModal";
import RoomSelectModalForMessages from "./RoomSelectModalForMessages";
import ReactPlayer from "react-player";
import VideoThumbnail from "react-video-thumbnail";
import axios from "axios";
import * as linkify from 'linkifyjs';
import { toast } from "react-toastify";
import OpenPRFButton from "../Tasks/CaseSheet/OpenPRFButton";
toast.configure();

function App(source, content, componentName = "Chat") {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <Button
        variant="transparent"
        style={{ padding: 0 }}
        onClick={() => {
          setVisible(true);
        }}
      >
        <div
          style={
            componentName === "Chat"
              ? {
                  height: "auto",
                  maxHeight: "300px",
                  width: "301px",
                  overflow: "hidden",
                }
              : {
                  height: "auto",
                  maxHeight: "200px",
                  width: "201px",
                  overflow: "hidden",
                }
          }
          className="bg-light rounded d-flex align-items-center justify-content-center"
        >
          <img src={source} alt="Logo" className="w-100 rounded" />
        </div>
      </Button>
      <Viewer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        images={[{ src: source, alt: content ? content : "" }]}
      />
    </>
  );
}
const isOwnMessage = (message, user) => {
  return message.user.id === user.uid ? "message__self" : "message__others";
};

const strip = (value) => {
  if(value) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = value.match(regExp);
    return match && match[2].length === 11 ? (
      <iframe
        style={{ borderRadius: "4px" }}
        width="100%"
        height="170"
        src={"//www.youtube.com/embed/" + match[2]}
        frameborder="0"
        allowfullscreen
      ></iframe>
    ) : // : <ReadMore full_text={value} max_length="250" />;
    value.length > 50 ? (
      value.slice(0, 50) + "…"
    ) : (
      value
    );
  }
  // : value.length > 50 ? <ReadMore full_text={value} max_length="250" /> + '…' : value;
};
const isImage = (message) => {
  return message.hasOwnProperty("image") && message.type === 6;
};
const isFile = (message) => {
  return message.hasOwnProperty("file") && message.type === 7;
};
const isFeedback = (message) => {
  return (
    message.hasOwnProperty("feedback") && !message.hasOwnProperty("content")
  );
};

const isPoll = (message) => {
  return message.type === 3;
};
const isQuiz = (message) => {
  return message.type === 4;
};
const isAssignment = (message) => {
  return message.type === 5;
};
const isReading = (message) => {
  return message.type === 2;
};

const isSchedule = (message) => {
  return message.type === 1;
  // return message.hasOwnProperty("schedule") && !message.hasOwnProperty("content");
};
const isRoomCreatedNotice = (message) => {
  return message.type === 8;
};

const isParticipantAddedNotice = (message) => {
  // console.log(message);
  return message.type === 9;
};

const isSpecialOlympic = (message) => {
  return message.type === 10;
};

const isAudio = (message) => {
  return message.type === 11;
};

const isURLMessage = (message) => {
  return message.type === 12;
};

const isPatientDetailsForm = (message) => {
  return message.type === 13;
};

const isProject = (message) => {
  return (
    message.hasOwnProperty("project") && !message.hasOwnProperty("content")
  );
};

const isList = (message) => {
  return message.hasOwnProperty("list") && !message.hasOwnProperty("content");
};

const timeFromNow = (timestamp) => {
  //if (moment(Date.now()).diff(moment(timestamp), 'days') < 1) return moment(timestamp).fromNow()
  if (moment(Date.now()).diff(moment(timestamp), "days") < 1)
    return moment(timestamp).format("h:mm A");
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 2)
    return `Yesterday, ${moment(timestamp).format("h:mm A")}`;
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 7)
    return `${moment(timestamp).format("dddd")}, ${moment(timestamp).format(
      "h:mm A"
    )}`;
  else if (moment(Date.now()).diff(moment(timestamp), "days") < 365)
    return `${moment(timestamp).format("MMM DD, YYYY")}, ${moment(
      timestamp
    ).format("h:mm A")}`;
  else return moment(timestamp).format("MMM YY");
};

const Message = ({
  message,
  allMessages,
  currentWorkspace,
  messagesForwardArray,
  currentChannel,
  user,
  channel,
  replyId,
  currentUser,
  darkTheme,
  
  setReplyId,
  setMessageForward,
  setContentTypeAndValue,
  setRightPanel,
  resetAllTasks,
  resetTask,
  resetData,
  setTaskSharing,
  setSharedTask,

  setPollTitle,
  setPollOptions,

  setQuizTitle,
  setQuizQuestions,
  setQuizOptions,
  setQuizTypes,
  setQuizCorrectAnswers,
  setQuizWeights,
  setQuizTotalWeight,
  setQuizGraded,

  setScheduleTitle,
  setScheduleDurationHours,
  setScheduleDurationMinutes,
  setSchedulePlace,
  setScheduleAgenda,

  setReadingTitles,
  setReadingLinks,
  setReadingFiles,
  setReadingUrls,

  setAssignmentQuestions,
  setAssignmentDescriptions,
  setAssignmentWeights,
  setAssignmentTotalWeight,
  setAssignmentGraded,
  forwardCheckbox,
  componentName,
  setEditingPublished,
  setEditedPublishedTask
}) => {
  const [repliedMsg, setRepliedMsg] = useState({});
  const [allParticipants, setAllParticipants] = useState([]);


  const [showRoomSelectModal, setShowRoomSelectModal] = useState(false);
  const [messageDataObjState, setMessageDataObjState] = useState({});
  const [messageDataState, setMessageDataState] = useState({});

  const [selectedMessage, setSelectedMessage] = useState(null);
  const [selectedMessageIsApp, setSelectedMessageIsApp] = useState(false);
  const [userDeleteMessageShow, setUserDeleteMessageShow] = useState(false);
  const [adminDeleteMessageShow, setAdminDeleteMessageShow] = useState(false);

  const [isClosed, setIsClosed] = useState(false);

  const [
    showRoomSelectModalForMessages,
    setShowRoomSelectModalForMessage,
  ] = useState(false);

  const returnTrimmedURL = (url) => {
    var pathArray = url.split('/');
    var host = pathArray[2];
    return (host);
  }

  useEffect(() => {
    if (currentWorkspace && currentChannel) {
      firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/participants`
        )
        .onSnapshot((snapshot) => {
          let allParticipants1 = [];

          snapshot.forEach((doc) => {
            allParticipants1.push({ uid: doc.id, ...doc.data() });
          });

          // console.log("allParticipants1", allParticipants1);

          setAllParticipants(allParticipants1);
        });
    }
  }, [currentWorkspace, currentChannel]);

  useEffect(() => {
    let messageData = {};
    //console.log(message.replyId);
    if (message.replyId !== undefined) {
      //getMessageData(message.replyId);
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      query
        .doc(message.replyId)
        .get()
        .then((doc) => {
          // console.log(doc.data());
          if (doc.exists) {
            setRepliedMsg(doc.data());
          }
        });
      //console.log(messageData);
    }
  }, []);
  const taskResponse = (uid, response) => {
    let allData = {};
    let prevResponse = {};
    firebase
      .database()
      .ref("messages")
      .child(channel.id)
      .child(uid)
      .on("value", (snap) => {
        // console.log(snap.val())
        allData = snap.val();
        if (allData) {
          prevResponse = allData.response ? allData.response : {};
          prevResponse[user.uid] = {
            value: response,
            responseTime: Date.now(),
          };
          allData["response"] = prevResponse;
        }
      });

    // console.log(uid, response, channel.id, user.uid)
    firebase
      .database()
      .ref("messages")
      .child(channel.id)
      .child(uid)
      .update(allData);
    // console.log(allData)
  };

  const image_type = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"];

  const pdf_type = ["application/pdf"];

  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  const video_type = ["video/mp4"];

  // special olympics / advanced poll
  const exportResponsesHandler = (contentId, type) => {
    //console.log(contentId, type, "Response");
    if (type === 10) {
      firebase.firestore().collection("specialOlympics").doc(contentId).get()
        .then((doc) => {
          if (doc.exists) {
            axios.post(`${API_BASE_URL}${QUIZPLUS_DOWNLOAD_RESPONSES}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              publish_special_olympic_id: contentId
            }, {
              responseType: "arraybuffer"
            }).then((response) => {
              console.log(response, "Response")
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `ADVPOLL-IG-${doc.data().specialOlympicName}-${moment(Date.now()).format('YYYYMMDD')}.xlsx`);
              document.body.appendChild(link);
              link.click();
            })
              .catch((err) => {
                console.log(err);
              });
          }
        }).catch((error) => {
          console.log("Can not download")
        })
    }
    if (type === 3) {
      firebase.firestore().collection("polls").doc(contentId).get()
        .then((doc) => {
          if (doc.exists) {
            axios.post(`${API_BASE_URL}${POLL_DOWNLOAD_RESPONSES}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              published_poll_id: contentId
            }, {
              responseType: "arraybuffer"
            }).then((response) => {
              console.log(response, "Response")
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `POLL-IG-${doc.data().title.replace(/ +/g, '-')}-${moment(Date.now()).format('YYYYMMDD')}.xlsx`);
              document.body.appendChild(link);
              link.click();
            })
              .catch((err) => {
                console.log(err);
              });
          }
        }).catch((error) => {
          console.log("Can not download")
        })
      }
        if (type === 4) {
          firebase.firestore().collection("quiz").doc(contentId).get()
            .then((doc) => {
              if (doc.exists) {
                axios.post(`${API_BASE_URL}${QUIZ_DOWNLOAD_RESPONSES}`, {
                  user_id: currentUser.uid,
                  workspace_id: currentWorkspace.workspaceId,
                  room_id: currentChannel.roomId,
                  quiz_id: contentId
                }, {
                  responseType: "arraybuffer"
                }).then((response) => {
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", `QUIZ-IG-${doc.data().quizName}-${moment(Date.now()).format('YYYYMMDD')}.xlsx`);
                  document.body.appendChild(link);
                  link.click();
                })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }).catch((error) => {
              console.log("Can not download")
            })
        }
    
      
  }

  const UrlMessageView = ({ og, meta, url }) => {

    const metaTitle = meta.title, 
          metaDescription = meta.description, 
          ogTitle = og.title, 
          ogDescription = og.description, 
          site_name = og.site_name;

    const title = (metaTitle) 
                  ? metaTitle 
                  : ogTitle,
    description = (ogDescription) 
                  ? ogDescription
                  : metaDescription;

    return (
      <div className="d-flex align-items-top overflow-hidden text-theme-light rounded-left">
        {
          og.image && linkify.find(og.image).length > 0 && linkify.find(og.image)[0].type === "url" 
          ? <div className="url_message_img" style={{ backgroundImage: `url(${og.image})` }}></div>
          : null
        }
        <div className="flex-grow-1 d-flex flex-column url_message_text">
          {
            title 
            ? <div style={{ textOverflow: 'ellipsis' }} title={title} className="text-theme-light">
                <span>{title}</span>
              </div>
            : null
          }
          {
            description
            ? <div style={{ textOverflow: 'ellipsis' }} className="flex-grow-1 text-muted overflow-hidden">
              <span className="small" title={description}>
                {description?.replace(/(\r\n|\n|\r)/gm, "")}
              </span>
            </div>
            : null
          }
          <div style={{ textOverflow: 'ellipsis' }} className="text-theme-light flex-none">
            {
              site_name 
              ? <span title={site_name}>{site_name}</span>
              : <span title={site_name}>{url}</span>
            }
          </div>
        </div>
      </div>
    )
  }

  const MessageTemplate = ({
    message,
    taskResponse,
    currentChannel,
    currentWorkspace,
    messagesForwardArray,
    repliedMsg,
    forwardCheckbox,
    messageForwardArray,
    componentName,
  }) => {
    // console.log(message);

    if (isImage(message)) {
      return (
        <div>
          {App(message.image, message.content, componentName)}
          {message.content && (
            <div className="mt-2">
              <Linkify
                options={{
                  target: { email: null, target: "_blank" },
                  format: { url: strip(message.content) },
                  events: {
                    mouseover: function (e) {
                      console.log("Link clicked!");
                    },
                  },
                }}
              >
                <ReadMore full_text={message.content} max_length="250" />
              </Linkify>
            </div>
          )}
        </div>
      );
    } else if (isFile(message)) {
      // console.log("FILE MESSAGE: ", message);

      if (video_type.includes(message.metaData && message.metaData.type)) {
        // if isFile a video (.mp4)
        return (
          <div className="">
            <ReactPlayer
              url={message.file}
              controls
              height="180px"
              width="360px"
              config={{
                attributes: {
                  controlsList: "nodownload noremoteplayback",
                  oncontextmenu: "return false",
                },
              }}
            />
          </div>
        );
      } else {
        return (
          <div>
            <div className="rounded px-2 py-3 bg-light">
              <a href={message.file} target="_blank">
                <div className="d-flex rounded bg-white shadow-sm">
                  <div
                    className="py-2 px-3"
                    style={{ backgroundColor: "#FEF2E4" }}
                  >
                    {word_type.includes(
                      message.metaData && message.metaData.type
                    ) && (
                      <FontAwesomeIcon
                        icon={faFileWord}
                        className="text-primary"
                      />
                    )}
                    {pdf_type.includes(
                      message.metaData && message.metaData.type
                    ) && (
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className="text-danger"
                      />
                    )}
                    {powerpoint_type.includes(
                      message.metaData && message.metaData.type
                    ) && (
                      <FontAwesomeIcon
                        icon={faFilePowerpoint}
                        className="text-danger"
                      />
                    )}
                    {excel_type.includes(
                      message.metaData && message.metaData.type
                    ) && (
                      <FontAwesomeIcon
                        icon={faFileExcel}
                        className="text-success"
                      />
                    )}
                    {video_type.includes(
                      message.metaData && message.metaData.type
                    ) && (
                      <FontAwesomeIcon
                        icon={faVideo}
                        className="text-success"
                      />
                    )}
                  </div>
                  <div className="p-2 flex-grow-1">
                    <small className="font-weight-bold text-dark">
                      {message.metaData && message.metaData.name}
                    </small>
                  </div>
                  <div className="py-2 px-3">
                    <FontAwesomeIcon icon={faLink} className="text-primary" />
                  </div>
                </div>
              </a>
            </div>
            {message.content && (
              <div className="mt-2">
                <Linkify
                  options={{
                    target: { email: null, target: "_blank" },
                    format: { url: strip(message.content) },
                    events: {
                      mouseover: function (e) {
                        console.log("Link clicked!");
                      },
                    },
                  }}
                >
                  <ReadMore full_text={message.content} max_length="250" />
                </Linkify>
              </div>
            )}
          </div>
        );
      }
    } else if (isAudio(message)) {
      return (
        <div className="mt-2">
          <audio controls preload="auto" src={message.audio}></audio>
          {
            message.content && (
              <div className="mt-2">
                <Linkify
                  options={{
                    target: { email: null, target: "_blank" },
                    format: { url: strip(message.content) },
                    events: {
                      mouseover: function (e) {
                        console.log("Link clicked!");
                      },
                    },
                  }}
                >
                  <ReadMore full_text={message.content} max_length="250" />
                </Linkify>
              </div>
            )
          }
        </div>
      )
    } else if (isURLMessage(message)) {
      return (
        <>
          <div
            style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
            className="m-0 p-1 rounded-left pointer"
            onClick={() => window.open(message.url, '_blank')}
          >
            <UrlMessageView 
              og={message.metaTags.og} 
              meta={message.metaTags.meta} 
              url={message.url}
             />
          </div>
          <div className="mt-2">
            {
              message.content && (
                <div className="mt-2">
                  <Linkify
                    options={{
                      target: { email: null, target: "_blank" },
                      format: { url: strip(message.content) },
                      events: {
                        mouseover: function (e) {
                          console.log("Link clicked!");
                        },
                      },
                    }}
                  >
                    <ReadMore full_text={message.content} max_length="250" />
                  </Linkify>
                </div>
              )
            }
          </div>
        </>
      )
    } else if (isFeedback(message)) {
      return (
        <Feedback
          feedbacktitle={message.feedback}
          uid={message.uid}
          item={message}
          user={user.uid}
          taskResponse={taskResponse}
        />
      );
    } else if (isPoll(message)) {
      return (
        <Poll
          contentId={message.contentId}
          type={message.type}
          uid={message.uid}
          item={message}
          colorTheme="text-theme-light"
          componentName={componentName}
          setIsClosed={setIsClosed}
        />
      );
    } else if (isQuiz(message)) {
      return (
        <>
        <Quiz
          quiz_data={message}
          user_id={user.uid}
          uid={message.uid}
          //type={message.type}
          taskResponse={taskResponse}
          colorTheme="text-theme-light"
          componentName={componentName}
          setIsClosed={setIsClosed}
        />
        </>
      );
    } else if (isProject(message)) {
      return (
        <Project
          projectname={message.project}
          uid={message.uid}
          item={message}
          user={user.uid}
          taskResponse={taskResponse}
          colorTheme="text-theme-light"
        />
      );
    } else if (isSpecialOlympic(message)) {
      return (
        <QuizPlus
          quiz_data={message}
          user_id={user.uid}
          uid={message.uid}
          //type={message.type}
          taskResponse={taskResponse}
          colorTheme="text-theme-light"
          componentName={componentName}
          setIsClosed={setIsClosed}
        />
      );
    } else if (isList(message)) {
      return (
        <List
          uid={message.uid}
          item={message}
          user={user.uid}
          taskResponse={taskResponse}
          colorTheme="text-theme-light"
        />
      );
    } else if (isAssignment(message)) {
      return (
        <Assignment
          uid={message.uid}
          item={message}
          user={user.uid}
          taskResponse={taskResponse}
          colorTheme="text-theme-light"
          componentName={componentName}
          setIsClosed={setIsClosed}
        />
      );
    }  else if (isPatientDetailsForm(message)) {
      return (
        // <PatientDetailsForm
        //   uid={message.uid}
        //   item={message}
        //   user={user.uid}
        //   taskResponse={taskResponse}
        //   colorTheme="text-theme-light"
        //   componentName={componentName}
        //   setIsClosed={setIsClosed}
        // />
        <OpenPRFButton 
              uid={message.uid}
          item={message}
          user={user.uid}
        />
      );
    } else if (isReading(message)) {
      return (
        <Reading
          uid={message.uid}
          item={message}
          user={user.uid}
          taskResponse={taskResponse}
          colorTheme="text-theme-light"
          setIsClosed={setIsClosed}
        />
      );
    } else if (isSchedule(message)) {
      return (
        <Schedule
          contentId={message.contentId}
          type={message.type}
          uid={message.uid}
          item={message}
          user={user.uid}
          colorTheme="text-theme-light"
          setIsClosed={setIsClosed}
        />
      );
    } else {
      return (
        <div>
          <Linkify
            options={{
              target: { email: null, target: "_blank" },
              format: { url: strip(message.content) },
              events: {
                mouseover: function (e) {
                  console.log("Link clicked!");
                },
              },
            }}
          >
            {
              message.content ? 
              <ReadMore full_text={message.content} max_length="250" />
              : null
            }
          </Linkify>
        </div>
      );
    }
  };
  // const [repliedMsg, setRepliedMsg] = useState({});
  const getMessageData = async (id) => {
    let messageData = {};

    // messageData = allMessages.find((message) => message.uid === id);
    /*firebase
      .database()
      .ref("messages/" + channel.id)
      .child(id)
      .on("value", (snap) => {
        messageData = snap.val();
      });*/
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      );
    messageData = (await query.doc(id).get()).data();
    //console.log(messageData);
    setRepliedMsg(messageData);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));
  const closeAppResponses = async (message) => {
    console.log(message);
    var message_id = message.uid;
    let res = await axios.post(`${API_BASE_URL}${APP_CLOSE}`, {
      workspace_id: currentWorkspace.workspaceId,
      room_id: currentChannel.roomId,
      user_id: user.uid,
      message_id: message_id,
    });
    if (res.status === 200) {
      if(darkTheme){
        toast.dark("Closed", { position: toast.POSITION.TOP_CENTER, hideProgressBar:true , autoClose:3000});
      }else{
        toast("Closed", { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose:3000});
      }
      
    } else {
      console.log(res);
    }
  };

  const shareAgain = async (message_id) => {
    //console.log(message_id);

    // let messageData = getMessageData(message_id);
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      );
    let messageDataObj = await query
      .doc(message_id)
      .get()
      .then((doc) => {
        console.log(doc.data());
        if (doc.exists) {
          return { ...doc.data(), id: doc.id };
        }
      });
    console.log(messageDataObj);
    let messageData = {};
    if (messageDataObj.type === 1) {
      messageData = await firebase
        .firestore()
        .collection("schedule")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 2) {
      messageData = await firebase
        .firestore()
        .collection("readings")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 3) {
      messageData = await firebase
        .firestore()
        .collection("polls")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 4) {
      messageData = await firebase
        .firestore()
        .collection("quiz")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 5) {
      messageData = await firebase
        .firestore()
        .collection("assignments")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 10) {
      messageData = await firebase
        .firestore()
        .collection("specialOlympics")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    }

    console.log(messageData);
    resetData();
    setRightPanel(true);
    setTaskSharing(true);

    if (messageDataObj.type === 1) {
      setTimeout(() => setContentTypeAndValue("createTask", "Schedule"), 500);
    } else if (messageDataObj.type === 2) {
      setTimeout(() => setContentTypeAndValue("createTask", "Reading"), 500);
    } else if (messageDataObj.type === 3) {
      setTimeout(() => setContentTypeAndValue("createTask", "Poll"), 500);
    } else if (messageDataObj.type === 4) {
      setTimeout(() => setContentTypeAndValue("createTask", "Quiz"), 500);
    } else if (messageDataObj.type === 5) {
      setTimeout(() => setContentTypeAndValue("createTask", "Assignment"), 500);
    } else if (messageDataObj.type === 10) {
      setTimeout(() => setContentTypeAndValue("createTask", "Quiz+"), 500);
    }
    setTimeout(() => setSharedTask(messageData), 700);
  };

  const editPublishedTask = async (message_id) => {
    //console.log(message_id);

    // let messageData = getMessageData(message_id);
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      );
    let messageDataObj = await query
      .doc(message_id)
      .get()
      .then((doc) => {
        console.log(doc.data());
        if (doc.exists) {
          return { ...doc.data(), id: doc.id };
        }
      });
    console.log(messageDataObj);
    let messageData = {};
    if (messageDataObj.type === 1) {
      messageData = await firebase
        .firestore()
        .collection("schedule")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 2) {
      messageData = await firebase
        .firestore()
        .collection("readings")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 3) {
      messageData = await firebase
        .firestore()
        .collection("polls")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 4) {
      messageData = await firebase
        .firestore()
        .collection("quiz")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 5) {
      messageData = await firebase
        .firestore()
        .collection("assignments")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 10) {
      messageData = await firebase
        .firestore()
        .collection("specialOlympics")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    }

    //console.log(messageData, "respox");
    resetData();
    setRightPanel(true);
    setEditingPublished(true);

    if (messageDataObj.type === 1) {
      setTimeout(() => setContentTypeAndValue("createTask", "Schedule"), 500);
    } else if (messageDataObj.type === 2) {
      setTimeout(() => setContentTypeAndValue("createTask", "Reading"), 500);
    } else if (messageDataObj.type === 3) {
      setTimeout(() => setContentTypeAndValue("createTask", "Poll"), 500);
    } else if (messageDataObj.type === 4) {
      setTimeout(() => setContentTypeAndValue("createTask", "Quiz"), 500);
    } else if (messageDataObj.type === 5) {
      setTimeout(() => setContentTypeAndValue("createTask", "Assignment"), 500);
    }
    setTimeout(() => setEditedPublishedTask(messageData), 700);
  };


  if (isRoomCreatedNotice(message)) {
    return (
      <div className="d-flex flex-column mb-2 mb-md-3">
        <div className="d-flex justify-content-center m-1">
          <Badge className="bg-theme text-theme">
            <p className="m-0 p-1 font-weight-light">
              {timeFromNow(message.createdAt.toDate())}
            </p>
          </Badge>
        </div>
        <div className="d-flex justify-content-center m-1">
          <Badge className="bg-theme text-theme">
            <p className="m-0 p-1 font-weight-light">
              {message.content.length > 60
                ? message.content.substring(0, 59) + `....."`
                : message.content}
            </p>
          </Badge>
        </div>
      </div>
    );
  }
  //str.length > 10 ? str.substring(0, 7) + "..." : str;
  if (isParticipantAddedNotice(message)) {
    return (
      <div className="d-flex flex-column mb-2 mb-md-3">
        <div className="d-flex justify-content-center m-1">
          <Badge className="bg-theme text-theme">
            <p className="m-0 p-1 font-weight-light">
              {timeFromNow(message.createdAt.toDate())}
            </p>
          </Badge>
        </div>
        <div className="d-flex justify-content-center m-1">
          <Badge className="bg-theme text-theme">
            <p className="m-0 p-1 font-weight-light">{message.content}</p>
          </Badge>
        </div>
      </div>
    );
  }

  const shareToAnotherRoom = async (message_id) => {
    resetData();
    // logic
    /*
      // current room logic
      get message data against the message_id from firebase
      store it in this function scope
      open modal and send all data to that modal component

      // modal logic 
      ask user to select any other room they're are admin/co-admin of
      load the room in redux current channel
      open right panel in that room

      // shared to room logic
      open the app type
      and initialise it with the message data which is stored in this function scope
    */
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      );
    let messageDataObj = await query
      .doc(message_id)
      .get()
      .then((doc) => {
        console.log(doc.data());
        if (doc.exists) {
          return { ...doc.data(), id: doc.id };
        }
      });

    let messageData = {};
    if (messageDataObj.type === 1) {
      messageData = await firebase
        .firestore()
        .collection("schedule")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 2) {
      messageData = await firebase
        .firestore()
        .collection("readings")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 3) {
      messageData = await firebase
        .firestore()
        .collection("polls")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 4) {
      messageData = await firebase
        .firestore()
        .collection("quiz")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    } else if (messageDataObj.type === 5) {
      messageData = await firebase
        .firestore()
        .collection("assignments")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          return { ...doc.data(), id: doc.id };
        });
    }else if (messageDataObj.type === 10) {
      messageData = await firebase
        .firestore()
        .collection("specialOlympics")
        .doc(messageDataObj.contentId)
        .get()
        .then((doc) => {
          //console.log(doc.data(),"my special olympics")
          return { ...doc.data(), id: doc.id };
        });
    }
    // console.log(messageData);

    // loading the channel/room where it has to be shared

    // setCurrentChannel
    setMessageDataObjState(messageDataObj);
    setShowRoomSelectModal(true);
    setMessageDataState(messageData);
    // resetData();
    // setRightPanel(true);
    // setTaskSharing(true);
  };


  const messageForward = (message, type) => {
    let messageData = message;
    console.log(message)
   // console.log(messagesForwardArray)
    //var currentUser = firebase.auth().currentUser;
    if(type === "add"){
      let myArray = messagesForwardArray;
      let start = messagesForwardArray.length;
      let deleteCount = 0
      myArray.splice(start, deleteCount, messageData) ;
      setMessagesForwardArray(myArray);
      console.log(myArray)
     // console.log(myArray, "myArray")
     // console.log(messagesForwardArray,"message.js")
    }
   /* if(type === "add"){
      if(message.type === 0){
        let messageData = {
          content: message.content,
          uid:message.uid,
          timestamp: Date.now(),
          type: 0,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          user: {
            id: currentUser.uid,
            name: currentUser.displayName,
            avatar: currentUser.photoURL,
          },
        }
        let myArray = messagesForwardArray;
        myArray.push(messageData) ;
        setMessagesForwardArray(myArray);
      }
      if(message.type === 6){
        let messageData = {
          content: "",
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          image: message.image,
          metaData:message.metaData,
          timestamp: Date.now(),
          type: 6,
          uid:message.uid,
          user: {
            id: currentUser.uid,
            name: currentUser.displayName,
            avatar: currentUser.photoURL,
          },
        }
        let myArray = messagesForwardArray;
        myArray.push(messageData) ;
        setMessagesForwardArray(myArray);
      }if(message.type === 7){
        let messageData = {
          content: "",
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          file: message.file,
          metaData:message.metaData,
          timestamp: Date.now(),
          type: 7,
          uid:message.uid,
          user: {
            id: currentUser.uid,
            name: currentUser.displayName,
            avatar: currentUser.photoURL,
          },
        }
        let myArray = messagesForwardArray;
        myArray.push(messageData) ;
        setMessagesForwardArray(myArray);
      }
    }*/else if(type === "remove"){
      var id = message.uid;
      let index = null;
      messagesForwardArray.forEach((item, i) => {
        if(item.uid=== message.uid){
          index= i;
        }
      })
      let myArray = messagesForwardArray;
      myArray.splice(index, 1)
      setMessagesForwardArray(myArray)
      //console.log(myArray,"remove1")
      //console.log(messagesForwardArray,"remove2")
    }
  }

  const shareToOtherRooms = async (message_id) => {
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      );
    let messageDataObj = await query
      .doc(message_id)
      .get()
      .then((doc) => {
        console.log(doc.data());
        if (doc.exists) {
          return { ...doc.data(), id: doc.id };
        }
      });
    let messageData = {};
    if (!messageDataObj.type) {
      // normal text message
      messageData = messageDataObj;
    } else if (messageDataObj.type === 6 || messageDataObj.type === 7) {
      // image or any other acceptable file format
      messageData = messageDataObj;
    }
    setMessageDataObjState(messageDataObj);
    setShowRoomSelectModalForMessage(true);
    setMessageDataState(messageData);
  };


  // delete message 

  const deleteMessageHandler = (message_id) => {
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      );
    query
      .doc(message_id)
      .update({
        isDeleted: true,
        content: null
      })

    // if the deleted message happens to be the last message in the room
    // adding isDeleted in that

    const queryRoom = firebase
    .firestore()
    .collection(
      `/workspaces/${currentWorkspace.workspaceId}/rooms/`
    ).doc(currentChannel.roomId);

    queryRoom.get().then((doc) => {
      if(doc.exists) {
        if(doc.data().lastMessage) {
          if(doc.data().lastMessage?.uid === message_id) {
            // updating the last message with isDeleted = true
            const updatedLastMessage = {...doc.data().lastMessage, isDeleted: true};
            queryRoom.update({
              lastMessage: updatedLastMessage
            })
          }
        }
      }
    })
    setUserDeleteMessageShow(false);
    setSelectedMessage(null);
  }

    // delete message app
    const deleteMessageAppHandler = (message_id) => {
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      query
        .doc(message_id)
        .update({
          isDeleted: true,
        })
  
      // if the deleted message happens to be the last message in the room
      // adding isDeleted in that
  
      const queryRoom = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/`
      ).doc(currentChannel.roomId);
  
      queryRoom.get().then((doc) => {
        if(doc.exists) {
          if(doc.data().lastMessage) {
            if(doc.data().lastMessage?.uid === message_id) {
              // updating the last message with isDeleted = true
              const updatedLastMessage = {...doc.data().lastMessage, isDeleted: true};
              queryRoom.update({
                lastMessage: updatedLastMessage
              })
            }
          }
        }
      })
      setUserDeleteMessageShow(false);
      setSelectedMessageIsApp(false);
      setSelectedMessage(null);
    }


  // delete message and app as an admin

  const deleteMessageAsAdminHandler = (message_id) => {
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
      );
    query
      .doc(message_id)
      .update({
        isDeleted: true,
        isDeletedByAdmin: true,
        deletedBy: user.uid
      })

    // if the deleted message happens to be the last message in the room
    // adding isDeleted in that

    const queryRoom = firebase
    .firestore()
    .collection(
      `/workspaces/${currentWorkspace.workspaceId}/rooms/`
    ).doc(currentChannel.roomId);

    queryRoom.get().then((doc) => {
      if(doc.exists) {
        if(doc.data().lastMessage) {
          if(doc.data().lastMessage?.uid === message_id) {
            // updating the last message with isDeleted = true
            const updatedLastMessage = {...doc.data().lastMessage, isDeleted: true};
            queryRoom.update({
              lastMessage: updatedLastMessage
            })
          }
        }
      }
    })
    setAdminDeleteMessageShow(false);
    setSelectedMessage(null);
  }



  return (
    <>
    <div className="message m-0 p-0">
      {/*console.log("isi",messagesForwardArray)*/}
        <div className="d-flex-left" style={{bottom:"00%"}}>
          {forwardCheckbox ? (
            message.type === 0 || message.type === 6 || message.type === 7 || message.type === 11 || message.type === 12 ? 
            (<input type="checkbox"  
              defaultChecked ={messagesForwardArray.includes(message)}
            //onClick={() => dispatch({ type: "add", value: message })}
            onClick={(event) => {
              if(event.target.checked){
                messageForward(message, "add")
              }else{
                messageForward(message, "remove")
              }
              
            }}/>): null)
            :null}  
        </div>   
      <div
        className={`${isOwnMessage(message, user)} p-2 `}
        style={{ marginBottom: 10 }}
      >
        {
          !message.isDeleted ?
          <div className="d-flex">
          {message.replyId && message.type !== 13 && (
            <small className="text-muted mr-2">
              Reply <FontAwesomeIcon icon={faReply} />
            </small>
          )}
          <span style={{ fontWeight: "bold" }} className="author" as="a">
            {message.user.name}&nbsp;
          </span>
          <span className="small text-theme-light timestamp">
            {timeFromNow(message?.createdAt?.toDate())}
          </span>
          {componentName !== "VideoChat" && !forwardCheckbox &&(

            currentChannel.roomConfig.admin_only_chat ?

            <IsAdmin>
            <Dropdown className="ml-auto px-1 py-0 pointer">
              <Dropdown.Toggle size="sm" variant="white" as={CustomToggle} />

              <Dropdown.Menu className="bg-card-theme text-theme">
                {(message.type === 0 || message.type === 6 || message.type === 7 || message.type === 11 || message.type === 12) ? (
                <Dropdown.Item
                className="small bg-card-theme text-theme"
                onClick= {() => {
                  setMessageForward(true);
                  messageForward(message, "add")
                  }}
                >
                  Forward
                </Dropdown.Item>): null
                }
                {message.type !== 13 &&
                <Dropdown.Item
                className="small bg-card-theme text-theme"
                onClick={() => setReplyId(message.uid)}
              >
                Reply
              </Dropdown.Item>
                }
                

                    {/* delete for user who sent the message */}

                    {
                      message.user.id === user.uid && (message.type === 0) ?
                        <Dropdown.Item
                          className="small bg-card-theme text-theme"
                          onClick={() => {
                            setSelectedMessage(message.uid)
                            setUserDeleteMessageShow(true);
                          }}
                        >
                          Delete
                    </Dropdown.Item>
                        : null
                    }

                    {/* delete app for user who created the app */}

                    {
                      message.user.id === user.uid  && (message.type !== 0) ?
                        <Dropdown.Item
                          className="small bg-card-theme text-theme"
                          onClick={() => {
                            setSelectedMessage(message.uid)
                            setSelectedMessageIsApp(true);
                            setUserDeleteMessageShow(true);
                          }}
                        >
                          Delete
                    </Dropdown.Item>
                        : null
                    }


                    {/* delete for superadmin */}

                    {
                      message.user.id !== user.uid && user.uid === currentChannel.createdBy ?
                        <Dropdown.Item
                          className="small bg-card-theme text-theme"
                          onClick={() => {
                            setSelectedMessage(message.uid);
                            setAdminDeleteMessageShow(true);
                          }}
                        >
                          Delete (Admin)
                    </Dropdown.Item>
                        : null
                    }


                {message.type
                  ? message.type !== 7 &&
                    message.type !== 6 && message.type !== 11 && message.type !== 12 && message.type !== 13 &&(
                      <IsAdmin>
                        <Dropdown.Item
                          className="small bg-card-theme text-theme"
                          onClick={() => shareAgain(message.uid)}
                        >
                          Duplicate
                        </Dropdown.Item>
                      </IsAdmin>
                    )
                  : ""}

                  
                {/* edit apps */}

                {message.type
                  ? message.type !== 7 &&
                    message.type !== 6 && message.type !== 10 && message.type !== 11 && message.type !== 12 && message.type !== 13 && (
                      <IsAdmin>
                        <Dropdown.Item
                          className="small bg-card-theme text-theme"
                          onClick={() => editPublishedTask(message.uid)}
                        >
                          Edit
                        </Dropdown.Item>
                      </IsAdmin>
                    )
                  : ""}

                {/*console.log(message,"my message")*/}
                {message.type
                  ?  message.type >= 1 &&
                    message.type <= 5 || message.type ==10 && !isClosed && (
                      <IsAdmin>
                        <Dropdown.Item
                          className="small bg-card-theme text-theme"
                          onClick={() => closeAppResponses(message.uid)}
                        >
                          Close
                        </Dropdown.Item>
                      </IsAdmin>
                    )
                  : ""}

                { message.type === 10 || message.type === 3 ? (
                      <IsAdmin>
                        <Dropdown.Item
                          className="small bg-card-theme text-theme"
                          onClick={() => exportResponsesHandler(message.contentId, message.type)}
                        >
                          Export responses
                        </Dropdown.Item>
                      </IsAdmin>
                    )
                  : ""}



                {/* <IsAdmin>
                <IsAdmin>
                  <Dropdown.Item className="small text-muted">
                    Delete (coming soon)
                  </Dropdown.Item>
                </IsAdmin> */}
                {message.type
                  ? message.type !== 7 &&
                    message.type !== 6 && message.type !== 11 && message.type !== 12 && message.type !== 13 &&  (
                      <IsAdmin>
                        <Dropdown.Item
                          className="small bg-card-theme text-theme"
                          onClick={() => shareToAnotherRoom(message.uid)}
                        >
                          Share to another room
                        </Dropdown.Item>
                      </IsAdmin>
                    )
                  : null}
              </Dropdown.Menu>
            </Dropdown>
            </IsAdmin>
            : 
            <Dropdown className="ml-auto px-1 py-0 pointer">
            <Dropdown.Toggle size="sm" variant="white" as={CustomToggle} />

            <Dropdown.Menu className="bg-card-theme text-theme">
              {(message.type === 0 || message.type === 6 || message.type === 7 || message.type === 11 || message.type === 12) ? (
              <Dropdown.Item
              className="small bg-card-theme text-theme"
              onClick= {() => {
                setMessageForward(true);
                messageForward(message, "add")
                }}
              >
                Forward
              </Dropdown.Item>): null
              }
              {message.type !== 13 &&
              <Dropdown.Item
              className="small bg-card-theme text-theme"
              onClick={() => setReplyId(message.uid)}
            >
              Reply
            </Dropdown.Item>
              }
              

                  {/* delete for user who sent the message */}

                  {
                    message.user.id === user.uid && (message.type === 0) ?
                      <Dropdown.Item
                        className="small bg-card-theme text-theme"
                        onClick={() => {
                          setSelectedMessage(message.uid)
                          setUserDeleteMessageShow(true);
                        }}
                      >
                        Delete
                  </Dropdown.Item>
                      : null
                  }

                  {/* delete app for user who created the app */}

                  {
                    message.user.id === user.uid  && (message.type !== 0) ?
                      <Dropdown.Item
                        className="small bg-card-theme text-theme"
                        onClick={() => {
                          setSelectedMessage(message.uid)
                          setSelectedMessageIsApp(true);
                          setUserDeleteMessageShow(true);
                        }}
                      >
                        Delete
                  </Dropdown.Item>
                      : null
                  }


                  {/* delete for superadmin */}

                  {
                    message.user.id !== user.uid && user.uid === currentChannel.createdBy ?
                      <Dropdown.Item
                        className="small bg-card-theme text-theme"
                        onClick={() => {
                          setSelectedMessage(message.uid);
                          setAdminDeleteMessageShow(true);
                        }}
                      >
                        Delete (Admin)
                  </Dropdown.Item>
                      : null
                  }


              {message.type
                ? message.type !== 7 &&
                  message.type !== 6 &&  message.type !==11 && message.type !== 12 && message.type !== 13 &&(
                    <IsAdmin>
                      <Dropdown.Item
                        className="small bg-card-theme text-theme"
                        onClick={() => shareAgain(message.uid)}
                      >
                        Duplicate
                      </Dropdown.Item>
                    </IsAdmin>
                  )
                : ""}

                        {/* edit apps */}

                        {message.type
                          ? message.type !== 7 &&
                          message.type !== 6 && message.type !== 10 && message.type !== 11  && message.type !== 12 && message.type !== 13 && (
                            <IsAdmin>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => editPublishedTask(message.uid)}
                              >
                                Edit
                        </Dropdown.Item>
                            </IsAdmin>
                          )
                          : ""}


                        {message.type
                          ? ((message.type >= 1 &&
                          message.type <= 5) || (message.type ==10))  && !isClosed && (
                            <IsAdmin>
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => closeAppResponses(message)}
                              >
                                Close
                        </Dropdown.Item>
                            </IsAdmin>
                          )
                          : ""}

                        {message.type === 10 || message.type ===4? (
                          <IsAdmin>
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => exportResponsesHandler(message.contentId, message.type)}
                              >
                              Export responses
                        </Dropdown.Item>
                          </IsAdmin>
                        )
                          : ""}


              {message.type
                ? message.type !== 7 &&
                  message.type !== 6 && message.type !== 11 && message.type !== 12 && message.type !== 13 &&(
                    <IsAdmin>
                      <Dropdown.Item
                        className="small bg-card-theme text-theme"
                        onClick={() => shareToAnotherRoom(message.uid)}
                      >
                        Share to another room
                      </Dropdown.Item>
                    </IsAdmin>
                  )
                : null}
            </Dropdown.Menu>
          </Dropdown>

          )}
        </div>
            : 
              message.isDeleted && !message.isDeletedByAdmin ?
            <>
              <div className="d-flex">
                <span style={{ fontWeight: "bold" }} className="author" as="a">
                  {message.user.name}&nbsp;
              </span>
                <span className="small text-theme-light timestamp">
                  {timeFromNow(message?.createdAt?.toDate())}
                </span>
              </div>
              <div className="mt-1">
                <span className="py-1 px-1">
                  <FontAwesomeIcon icon={faBan} className="text-theme-light" />
                </span>
                <span className="font-italic px-1">
                  {
                    message.user.id === user.uid ?
                      "You deleted this message" : "This message was deleted"
                  }
                </span>
              </div>
            </>
            : 
              message.isDeleted && message.isDeletedByAdmin ?
              <>
              <div className="d-flex">
                <span style={{ fontWeight: "bold" }} className="author" as="a">
                  {message.user.name}&nbsp;
              </span>
                <span className="small text-theme-light timestamp">
                  {timeFromNow(message?.createdAt?.toDate())}
                </span>
              </div>
                  <div className="mt-1">
                    {
                      message.user.id === user.uid ?
                        // "This message was deleted by the admin"
                        <div>
                          <span className="py-1 px-1">
                            <FontAwesomeIcon icon={faBan} className="text-theme-light" />
                          </span>
                          <span
                            title={"You"}
                            className="font-italic px-1"
                          >
                            {"This message was deleted by the admin"}
                          </span>

                          <div
                            style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
                            className="m-0 p-2 rounded-left"
                          >
                            <MessageTemplate
                              message={message}
                              taskResponse={taskResponse}
                              currentChannel={currentChannel}
                              currentWorkspace={currentWorkspace}
                              componentName={componentName}
                            />
                          </div>
                        </div>

                        : 
                        
                        <div className="mt-1">
                          <span className="py-1 px-1">
                            <FontAwesomeIcon icon={faBan} className="text-theme-light" />
                          </span>
                          <span className="font-italic px-1">
                            This message was deleted
                            
                        </span>
                        </div>
                    }

                  </div>
            </>
            : null

        }

        {message.replyId && !message.isDeleted && (
          <div
            style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
            className="m-0 p-2 rounded-left"
          >
            {Object.keys(repliedMsg).length > 0 && (
              <div>
                <span
                  title={
                    repliedMsg.user.id === user.uid
                      ? "You"
                      : repliedMsg.user.name
                  }
                  style={{ fontWeight: "bold" }}
                >
                  {repliedMsg.user.id === user.uid
                    ? "You"
                    : repliedMsg.user.name}
                  &nbsp;
                </span>
                <span
                  title={timeFromNow(repliedMsg.createdAt.toDate())}
                  className="small text-theme-light timestamp mt-0"
                >
                  {timeFromNow(repliedMsg.createdAt.toDate())}
                </span>

                {
                  !repliedMsg.isDeleted ?
                  <MessageTemplate
                  message={repliedMsg}
                  taskResponse={taskResponse}
                  currentChannel={currentChannel}
                  currentWorkspace={currentWorkspace}
                  componentName={componentName}
                /> : 
                <div className="mt-1">
                <span className="py-1 px-1">
                  <FontAwesomeIcon icon={faBan} className="text-theme-light" />
                </span>
                <span className="font-italic px-1">
                  This message was deleted
                  
              </span>
              </div>
                }

              </div>
            )}
          </div>
        )}
        {
          !message.isDeleted ?

            <div className="mt-1">
              <MessageTemplate
                message={message}
                taskResponse={taskResponse}
                currentChannel={currentChannel}
                currentWorkspace={currentWorkspace}
                componentName={componentName}
              />
            </div>
            : null

        }

        <RoomSelectModal
          messageData={messageDataState}
          messageDataObj={messageDataObjState}
          show={showRoomSelectModal}
          onHide={() => setShowRoomSelectModal(false)}
        />
        <RoomSelectModalForMessages
          messageData={messageDataState}
          messageDataObj={messageDataObjState}
          show={showRoomSelectModalForMessages}
          onHide={() => setShowRoomSelectModalForMessage(false)}
        />

        {/* message delete modals */}

        <Modal
          size="sm"
          centered
          show={userDeleteMessageShow}
        >
          <Modal.Header className="bg-theme border-0 d-flex justify-content-between align-items-center pb-0">
            <Modal.Title>
              <h5 className="text-theme">Delete message?</h5>
            </Modal.Title>
            <div title="Close" className="pointer" onClick={() => { setUserDeleteMessageShow(false); setSelectedMessage(null); }}>
              <FontAwesomeIcon className="text-theme" icon={faTimes} />
            </div>
          </Modal.Header>
          <Modal.Body
            className="overflow-auto list-unstyled bg-theme pt-0"
          >
            <Button onClick={() => {
              if(selectedMessageIsApp) {
                deleteMessageAppHandler(selectedMessage)
              } else if(!selectedMessageIsApp) {
                deleteMessageHandler(selectedMessage)
              }
            }
            }>Delete</Button>
          </Modal.Body>
        </Modal>


        {/* message delete modals for admin*/}

        <Modal
          size="sm"
          centered
          show={adminDeleteMessageShow}
        >
        <Modal.Header className="bg-theme border-0 d-flex justify-content-between align-items-center pb-0">
          <Modal.Title>
          <h5 className="text-theme">Delete message?</h5>
        </Modal.Title>
        <div title="Close" className="pointer" onClick={() => {setAdminDeleteMessageShow(false); setSelectedMessage(null);}}>
          <FontAwesomeIcon className="text-theme" icon={faTimes} />
        </div>
          </Modal.Header>
          <Modal.Body
            className="overflow-auto list-unstyled bg-theme pt-0"
          >
            <Button onClick={() => deleteMessageAsAdminHandler(selectedMessage)}>Delete</Button>
          </Modal.Body>
        </Modal>

      </div>
    </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  messagesForwardArray: state.platform.messageForwardArray,
  forwardCheckbox:state.platform.displayMessageForward,
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  replyId: state.platform.replyId,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, {
  setMessageForward,
  setReplyId,
  setContentTypeAndValue,
  setRightPanel,

  setPollTitle,
  setPollOptions,

  setQuizTitle,
  setQuizQuestions,
  setQuizOptions,
  setQuizTypes,
  setQuizCorrectAnswers,
  setQuizWeights,
  setQuizTotalWeight,
  setQuizGraded,
  //setQuizSharing,

  setScheduleTitle,
  setScheduleDurationHours,
  setScheduleDurationMinutes,
  setSchedulePlace,
  setScheduleAgenda,
  setMessageForward,
  setReadingTitles,
  setReadingLinks,
  setReadingFiles,
  setReadingUrls,

  setAssignmentQuestions,
  setAssignmentDescriptions,
  setAssignmentWeights,
  setAssignmentTotalWeight,
  setAssignmentGraded,
  //setTask
  setMessagesForwardArray,
  setTaskChannelUsers,
  setTaskDeadline,
  setTaskEveryone,
  setTaskSharing,
  setSharedTask,
  resetAllTasks,
  resetData,
  resetTask,
  setEditingPublished,
  setEditedPublishedTask
})(Message);