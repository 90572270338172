import React, { useEffect, useState, useRef } from 'react';
import { loadState } from '../../services/index';
import { Form, Button, Spinner } from 'react-bootstrap';
import firebase from 'firebase';
import { Link } from 'react-router-dom';
import * as DeviceDetect from "react-device-detect";
import { connect } from 'react-redux';
import { addRoom } from '../../actions/index';

function SignInAsGuest(props) {

    const { newRoom, addRoom } = props;
    const fullNameRef = useRef(null);

    useEffect(() => {
        if (firebase.auth().currentUser) {
            window.location.replace("/")
        }

        if (newRoom) {
            setRoomLink(`https://my.igesia.co/${newRoom}`)
        }

        fullNameRef.current && fullNameRef.current.focus();
    }, []);

    const [fullName, setFullName] = useState("");
    const [roomLink, setRoomLink] = useState("");
    const [error, setError] = useState("");
    const [loginLoading, setLoginLoading] = useState(false);

    const _handleLoginAsGuest = e => {
        e.preventDefault();
        const pattern = /^[0-9\s\-\(\)]+$/;

        setLoginLoading(true)

        if (fullName.length > 0) {

            if (roomLink.length > 0) {
                if (roomLink.indexOf("https://my.igesia.co/#/") >= 0) {
                    let roomCode = roomLink.replace("https://my.igesia.co/#/", "")

                    if (roomCode.match(pattern) && (roomCode.length === 6)) {
                        addRoom(roomCode)
                    } else {
                        setError("Something is wrong with the link!")
                        setLoginLoading(false)
                        return;
                    }

                } else if (roomLink.indexOf("my.igesia.co/#/") >= 0) {
                    let roomCode = roomLink.replace("my.igesia.co/#/", "")

                    if (roomCode.match(pattern) && (roomCode.length === 6)) {
                        addRoom(roomCode)
                    } else {
                        setError("Something is wrong with the link!")
                        setLoginLoading(false)
                        return;
                    }

                } else if (roomLink.indexOf("https://my.igesia.co/") >= 0) {
                    let roomCode = roomLink.replace("https://my.igesia.co/", "")

                    if (roomCode.match(pattern) && (roomCode.length === 6)) {
                        addRoom(roomCode)
                    } else {
                        setError("Something is wrong with the link!")
                        setLoginLoading(false)
                        return;
                    }

                } else if (roomLink.indexOf("my.igesia.co/") >= 0) {
                    let roomCode = roomLink.replace("my.igesia.co/", "")

                    if (roomCode.match(pattern) && (roomCode.length === 6)) {
                        addRoom(roomCode)
                    } else {
                        setError("Something is wrong with the link!")
                        setLoginLoading(false)
                        return;
                    }

                } else if (roomLink.match(pattern)) {
                    let roomCode = roomLink

                    if (roomCode.length === 6) {
                        addRoom(roomCode)
                    } else {
                        setError("Something is wrong with the link!")
                        setLoginLoading(false)
                        return;
                    }

                } else {
                    setError("Something is wrong with the link!")
                    setLoginLoading(false)
                    return;
                }
            }

            firebase
                .auth()
                .signInAnonymously()
                .then(response => {
                    // console.log(response)
                    if (response.additionalUserInfo.isNewUser) {
                        firebase
                            .auth()
                            .currentUser
                            .updateProfile({
                                displayName: fullName,
                                photoURL: 'https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822'
                            })
                            .then(() => {
                                firebase
                                    .firestore()
                                    .collection("users")
                                    .doc(`${firebase.auth().currentUser.uid}`)
                                    .set({
                                        displayName: firebase.auth().currentUser.displayName,
                                        avatar: firebase.auth().currentUser.photoURL,
                                        providerData: firebase.auth().currentUser.providerData,
                                        uid: firebase.auth().currentUser.uid,
                                        creationTime: firebase.auth().currentUser.metadata.creationTime,
                                        lastSignInTime: firebase.auth().currentUser.metadata.lastSignInTime,
                                        isAnonymous: firebase.auth().currentUser.isAnonymous,
                                        deviceInfo: DeviceDetect.deviceDetect(),
                                        deviceType: DeviceDetect.deviceType,
                                        device: DeviceDetect.isMobile ? DeviceDetect.isMobileOnly ? "Mobile" : "Tablet" : "Desktop",
                                        electron: DeviceDetect.isElectron ? true : false,
                                        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                                    })
                                    .then(function () {
                                        console.log("Document successfully written!");
                                    })
                                    .catch(function (error) {
                                        console.error("Error writing document: ", error);
                                    });
                            })
                            .catch(error => console.error(error))
                        setLoginLoading(false)
                    }
                })
                .catch(function (error) {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    setError(error.message)
                    setLoginLoading(false)
                });

        } else {
            setError("Please enter your name")
            setLoginLoading(false)
        }

    }

    const _handleRoomLinkChange = e => {
        e.preventDefault();

        const roomLink = e.target.value;

        setError("")
        setRoomLink(roomLink)
    }

    return (
        <div className="container-fluid" style={{ minHeight: "100vh", background: "#18191a" }}>
            <div className="container">
                <div className="row pt-5 justify-content-center">
                    <div className="col-md-4 pt-5 text-center">
                        <img src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-reverse.png?alt=media&token=93df9989-4eab-406f-8122-ada051558941"
                            width="200" />
                    </div>
                </div>
                <div className="row pt-5 justify-content-center">
                    <Form className="col-md-4 p-3 shadow-sm text-light rounded"
                        style={{ background: "#232626" }}>
                        <h3>Guest Login</h3>
                        <Form.Group className="mt-3 mt-md-4">
                            <Form.Label>Your full name<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder=""
                                value={fullName}
                                ref={fullNameRef}
                                onChange={e => {
                                    setError("")
                                    setFullName(e.target.value)
                                }} />

                            <Form.Label className="mt-2">Room link or Room code <span className="small">(optional)</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="my.igesia.co/xxxxxx"
                                value={roomLink}
                                onChange={e => _handleRoomLinkChange(e)} />
                        </Form.Group>

                        <Button
                            className="w-100"
                            variant="outline-primary"
                            onClick={e => _handleLoginAsGuest(e)}
                            disabled={loginLoading}>
                                {loginLoading
                                    && <Spinner
                                        as="span"
                                        role="status"
                                        aria-hidden="true"
                                        animation="border"
                                        className="mr-1"
                                        size="sm" />}
                                <span>Login as Guest</span>
                            </Button>

                        {error !== "" && <small className="text-danger">{error}</small>}

                        <div className="mt-2 text-center">
                            <p className="mt-3 small mb-0">Want unrestricted access to Igesia?</p>
                            <Link className="text-decoration-none" to="/signin">Sign up or Log in</Link>
                        </div>
                    </Form>
                </div>
            </div >
        </div >
    );
}

const mapStateToProps = state => ({
    newRoom: state.newRoom.newRoom
})

export default connect(mapStateToProps, { addRoom })(SignInAsGuest);
