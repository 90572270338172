import React, { useState, useRef, useEffect } from "react";
import firebase from "firebase";
import { connect } from "react-redux";
import {
  setTaskType,
  setContentType,
  setContentTypeAndValue,
  setShowWhiteboard,
  setShowGradebook,
  setShowAttendanceLedger,
  setShowNoteApp,
  setShowCaseSheet,
} from "../../actions/index";


import {
  Button,
  DropdownButton,
  Dropdown,
  Modal,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPoll,
  faFileAlt,
  faTasks,
  faFolderPlus,
  faClock,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
  API_BASE_URL,
  ROOMS_PARTICIPANTS,
  API_BBOS_URL,
} from "../../config/index";
import {
  faClipboard,
  faCalendarAlt,
} from "@fortawesome/free-regular-svg-icons";
import RoomDetails from "./RoomDetails";

import IsAdmin from "../IsAdmin";
import AddMemberInvite from "../Middle/AddMemberInvite";

import WhiteboardIcon from "../../assets/whiteboard.png";
import InviteIcon from "../../assets/invite.png";
import PollIcon from "../../assets/poll.png";
import ScheduleIcon from "../../assets/schedule.png";
import AssignmentIcon from "../../assets/assignment.png";
import FilesIcon from "../../assets/files.png";
import QuizIcon from "../../assets/quiz.png";
import AnalyticsIcon from "../../assets/analytics.png";
import CloudDriveIcon from "../../assets/cloud-drive.png";
import FolderIcon from "../../assets/folder.png";
import PrescriptionLogo from "../../assets/prescription.png";
import GradebookIcon from "../../assets/gradebook.png";
import NotesAppLogo from "../../assets/notes-app.png";
import CaseSheetLogo from "../../assets/caseSheetLogo.png";


import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import PatientDetails from "../Tasks/CaseSheet/PatientDetails/PatientDetails";

function CreateTaskHeader({
  setContentType,
  setContentTypeAndValue,

  currentSubscription,

  showWhiteboard,
  setShowWhiteboard,

  showGradebook,
  setShowGradebook,

  showAttendanceLedger,
  setShowAttendanceLedger,

  setShowNoteApp,
  showNoteApp,

  currentUser,
  currentChannel,
  currentWorkspace,
  
  setShowCaseSheet,
  showCaseSheet,
}) {

  
  const [openCaseSheetDrawer, setOpenCaseSheetDrawer] = useState(false);

  const toggleWhiteboard = () => {
    setShowWhiteboard(!showWhiteboard);
  };


  const toggleGradebook = () => {
    setShowAttendanceLedger(false);
    setShowGradebook(!showGradebook);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenCaseSheetDrawer(open);
  };

  const toggleCaseSheet = () => {
    setShowCaseSheet(!showCaseSheet);
  };
  const toggleNotesApp = () => {
    console.log(showNoteApp);
    setShowNoteApp(!showNoteApp);
  };

  const toggleAnalytics = () => {
    setShowGradebook(false);
    setShowAttendanceLedger(!showAttendanceLedger);
  };

  const [showModal, setShowModal] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false);

  const [loadingAdminOrNot, setLoadingAdminOrNot] = useState(true);

  const getIsAdminHandler = async () => {
    if (currentChannel && currentWorkspace && currentUser.uid) {
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/participants`
        );
      query.onSnapshot((snapshot) => {
        axios
          .post(`${API_BASE_URL}${ROOMS_PARTICIPANTS}`, {
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
          })
          .then((res) => {
            if (res.status === 200) {
              let data = res.data.data;
              let coAdmins = data.filter((participant) => participant.isAdmin);
              if (
                coAdmins.find((usr) => usr.uid === currentUser.uid) ||
                currentUser.uid === currentChannel.createdBy
              ) {
                setIsAdmin(true);
                setLoadingAdminOrNot(false);
              } else {
                setIsAdmin(false);
                setLoadingAdminOrNot(false);
              }
            }
          });
        if (currentUser.uid === currentChannel.createdBy) {
          setIsAdmin(true);
          setLoadingAdminOrNot(false);
        }
      });
    }
  };

  useEffect(() => {
    getIsAdminHandler();
  }, [currentWorkspace, currentChannel]);

  return (
    <>
      <div className="row text-center  pb-2 rounded-lg py-2 m-2 bg-card-theme shadow-sm text-theme">
        <div class="col-md-12 ">
          <p class="font-weight-bold text-left">Igesia Apps</p>
        </div>
        {!loadingAdminOrNot && isAdmin ? (
          <>
            {/* Essentials */}
            <div className="col-md-3 col-sm-4 col-3 pointer">
              <RoomDetails />
              <p className="smaller ">Room Details</p>
            </div>

            <div
              className="col-md-3 col-sm-4  col-3 pointer"
              style={{ cursor: "pointer" }}
              onClick={toggleWhiteboard}
            >
              <img src={WhiteboardIcon} width="50" />
              <p className="smaller">WhiteBoard</p>
            </div>

            <AddMemberInvite
              className="col-md-3 col-sm-4  col-3"
              style={{ cursor: "pointer" }}
            >
              <img src={InviteIcon} width="50" />
              <p className="smaller text-theme">Invite</p>
            </AddMemberInvite>

            <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              onClick={() => setContentTypeAndValue("createTask", "Poll")}
            >
              <img src={PollIcon} width="50" />
              <p className="smaller">Poll</p>
            </div>

            {/* Admin Only Essentials */}

            <div
              className="col-md-3 col-sm-4  col-3 pointer"
              style={{ cursor: "pointer" }}
              // onClick={() => setShowModal(true)}
              onClick={() => setContentTypeAndValue("createTask", "Schedule")}
            >
              <img src={ScheduleIcon} width="50" />
              <p className="smaller">Schedule</p>
              {/* <p className="smaller">[Beta]</p> */}
            </div>

            <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              // onClick={() => setShowModal(true)}
              onClick={() => setContentTypeAndValue("createTask", "Assignment")}
            >
              <img src={AssignmentIcon} width="50" />
              <p className="smaller">Assignment</p>
              {/* <p className="smaller">[Beta]</p> */}
            </div>

            <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              // onClick={() => setShowModal(true)}
              onClick={() => setContentTypeAndValue("createTask", "Reading")}
            >
              <img src={FilesIcon} width="50" />
              <p className="smaller">Files</p>
              {/* <p className="smaller">[Beta]</p> */}
            </div>

            <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              // onClick={() => setShowModal(true)}
              onClick={() => setContentTypeAndValue("createTask", "Quiz")}
            >
              <img src={QuizIcon} width="50" />
              <p className="smaller">Quiz</p>
              {/* <p className="smaller text-warning">Coming Soon</p>*/}
            </div>
            <div
              className="col-md-3 col-sm-4  col-3 pointer"
              //onClick={() => setShowModal(true)}
              onClick={toggleAnalytics}
            >
              <img src={AnalyticsIcon} width="50" />
              <p className="smaller">Analytics</p>
              {/* <p className="smaller text-warning">Coming Soon</p> */}
            </div>
            <div
              className="col-md-3 col-sm-4  col-3 pointer"
              // onClick={() => setShowModal(true)}
              onClick={() => setContentTypeAndValue("createTask", "Cloud")}
            >
              <img src={CloudDriveIcon} width="50" />
              <p className="smaller">Media</p>
              {/* <p className="smaller text-warning">Coming Soon</p> */}
            </div>

            <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              //onClick={() => setShowModal(true)}
              onClick={() => setContentType("Folder")}
            >
              <img src={FolderIcon} width="50" />
              <p className="smaller mb-0">Folder</p>
              {/* <p className="smaller text-warning">Coming Soon</p> */}
            </div>

            {/* <div className="col-md-3 col-sm-4 pointer  col-3">
                                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/7_gcmlwrelX.png" width="50" />
                                <p className="smaller text-white">Igesia Apps</p>
                            </div> */}
            {/* <div className="col-md-3 col-sm-4  col-3 pointer">
                        <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/tSXYIzZlfrS.png" width="50" />
                        <p className="smaller ">People</p>
                    </div>                 */}
            {/* Learning Management */}

            <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              //onClick={() => setShowModal(true)}
              onClick={toggleGradebook}
            >
              <img
                src="https://static.xx.fbcdn.net/rsrc.php/v3/yc/r/Tqo1st5w-sM.png"
                width="50"
              />
              <p className="smaller mb-0">Gradebook</p>
              {/* <p className="smaller text-warning">Coming Soon</p> */}
            </div>

            {currentSubscription?.notesApp ? (
              <div
                className="col-md-3 col-sm-4 pointer  col-3"
                style={{ cursor: "pointer" }}
                onClick={toggleNotesApp}
              >
                <img src={NotesAppLogo} width="50" />
                <p className="smaller mb-0">My Notes</p>
                {/* <p className="smaller text-warning">Coming Soon</p> */}
              </div>
            ) : null}

            {currentSubscription?.prescription && (
              <div
                className="col-md-3 col-sm-4  col-3 pointer"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  setContentTypeAndValue("createTask", "Prescription")
                }
              >
                <img src={PrescriptionLogo} width="50" />
                <p className="smaller">Prescription</p>
              </div>
            )}

{currentSubscription?.caseSheet && (
              <div
                className="col-md-3 col-sm-4  col-3 pointer"
                style={{ cursor: "pointer" }}
                onClick={toggleDrawer("right", true)}
              >
                <img src={CaseSheetLogo} width="50" className="p-1" />
                <p className="smaller">Case Sheet</p>
              </div>
            )}
            <Drawer
              anchor={"right"}
              open={openCaseSheetDrawer}
              onClose={toggleDrawer("right", false)}
            >
              <PatientDetails setOpenCaseSheetDrawer={setOpenCaseSheetDrawer} />
            </Drawer>

            {currentChannel?.roomConfig.special_olympics_app_allowed ? (
              <div
                className="col-md-3 col-sm-4 pointer  col-3"
                style={{ cursor: "pointer" }}
                // onClick={() => setShowModal(true)}
                onClick={() => setContentTypeAndValue("createTask", "Quiz+")}
              >
                <img src={QuizIcon} width="50" />
                <p className="smaller">Special Olympics</p>
                {/* <p className="smaller text-warning">Coming Soon</p>*/}
              </div>
            ) : null}

            {/* <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  `${API_BBOS_URL}/${currentWorkspace.workspaceId}/${currentChannel.roomId}`,
                  "_blank"
                )
              }
            >
              <img src={PollIcon} width="50" style={{ filter: "invert(1)" }} />

              <p className="smaller">BBOS</p>
            </div> */}

            {/* </IsAdmin>

        {/* <div className="col-md-3 col-sm-4 pointer  col-3">
                                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yP/r/FMv4tTIpfwB.png" width="50" />
                                <p className="smaller text-white">Health Record</p>
                            </div>
                            <div className="col-md-3 col-sm-4 pointer  col-3">
                                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yA/r/KlDlsO3UxDM.png" width="50" />
                                <p className="smaller text-white">Presription</p>
                            </div>
                            <div className="col-md-3 col-sm- pointer4  col-3">
                                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yg/r/JQCVtO0LVjk.png" width="50" />
                                <p className="smaller text-white">Power Thoughts</p>
                            </div>
                            <div className="col-md-3 col-sm-4 pointer  col-3">
                                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yI/r/QucXUhmnkru.png" width="50" />
                                <p className="smaller text-white">Payments</p>
                            </div> */}
          </>
        ) : !loadingAdminOrNot && !isAdmin ? (
          <>
            {/* Essentials */}
            <div className="col-md-3 col-sm-4 col-3 pointer">
              <RoomDetails />
              <p className="smaller ">Room Details</p>
            </div>

            <div
              className="col-md-3 col-sm-4  col-3 pointer"
              style={{ cursor: "pointer" }}
              onClick={toggleWhiteboard}
            >
              <img src={WhiteboardIcon} width="50" />
              <p className="smaller">WhiteBoard</p>
            </div>

            <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              onClick={() => setContentTypeAndValue("createTask", "Poll")}
            >
              <img src={PollIcon} width="50" />
              <p className="smaller">Poll</p>
            </div>

            {/* Admin Only Essentials */}

            <div
              className="col-md-3 col-sm-4  col-3 pointer"
              style={{ cursor: "pointer" }}
              // onClick={() => setShowModal(true)}
              onClick={() => setContentTypeAndValue("createTask", "Schedule")}
            >
              <img src={ScheduleIcon} width="50" />
              <p className="smaller">Schedule</p>
              {/* <p className="smaller">[Beta]</p> */}
            </div>

            <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              // onClick={() => setShowModal(true)}
              onClick={() => setContentTypeAndValue("createTask", "Assignment")}
            >
              <img src={AssignmentIcon} width="50" />
              <p className="smaller">Assignment</p>
              {/* <p className="smaller">[Beta]</p> */}
            </div>

            <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              // onClick={() => setShowModal(true)}
              onClick={() => setContentTypeAndValue("createTask", "Reading")}
            >
              <img src={FilesIcon} width="50" />
              <p className="smaller">Files</p>
              {/* <p className="smaller">[Beta]</p> */}
            </div>

            <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              // onClick={() => setShowModal(true)}
              onClick={() => setContentTypeAndValue("createTask", "Quiz")}
            >
              <img src={QuizIcon} width="50" />
              <p className="smaller">Quiz</p>
              {/* <p className="smaller text-warning">Coming Soon</p>*/}
            </div>

            <div
              className="col-md-3 col-sm-4  col-3 pointer"
              // onClick={() => setShowModal(true)}
              onClick={() => setContentTypeAndValue("createTask", "Cloud")}
            >
              <img src={CloudDriveIcon} width="50" />
              <p className="smaller">Media</p>
              {/* <p className="smaller text-warning">Coming Soon</p> */}
            </div>

            <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              //onClick={() => setShowModal(true)}
              onClick={() => setContentType("Folder")}
            >
              <img src={FolderIcon} width="50" />
              <p className="smaller mb-0">Folder</p>
              {/*<p className="smaller text-warning">Coming Soon</p>*/}
            </div>

            {currentSubscription?.notesApp ? (
              <div
                className="col-md-3 col-sm-4 pointer  col-3"
                style={{ cursor: "pointer" }}
                onClick={toggleNotesApp}
              >
                <img src={NotesAppLogo} width="50" />
                <p className="smaller mb-0">My Notes</p>
                {/* <p className="smaller text-warning">Coming Soon</p> */}
              </div>
            ) : null}
            {/*                 
                <div
                className="col-md-3 col-sm-4  col-3 pointer"
                style={{ cursor: "pointer" }}
                onClick={() => setContentTypeAndValue("createTask", "Prescription")}
              >
                <img src={PrescriptionLogo} width="50" />
                <p className="smaller">Presription</p>
              </div> */}

            {currentChannel?.roomConfig.special_olympics_app_allowed ? (
              <div
                className="col-md-3 col-sm-4 pointer  col-3"
                style={{ cursor: "pointer" }}
                // onClick={() => setShowModal(true)}
                onClick={() => setContentTypeAndValue("createTask", "Quiz+")}
              >
                <img src={QuizIcon} width="50" />
                <p className="smaller">Special Olympics</p>
                {/* <p className="smaller text-warning">Coming Soon</p>*/}
              </div>
            ) : null}

            {/* <div
              className="col-md-3 col-sm-4 pointer  col-3"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  `${API_BBOS_URL}/${currentWorkspace.workspaceId}/${currentChannel.roomId}/user`,
                  "_blank"
                )
              }
            >
              <img src={PollIcon} width="50" style={{ filter: "invert(1)" }} />
              <p className="smaller">BBOS</p>
            </div> */}

            {/* <div className="col-md-3 col-sm-4 pointer  col-3">
                                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/y9/r/7_gcmlwrelX.png" width="50" />
                                <p className="smaller text-white">Igesia Apps</p>
                            </div> */}
            {/* <div className="col-md-3 col-sm-4  col-3 pointer">
                        <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yj/r/tSXYIzZlfrS.png" width="50" />
                        <p className="smaller ">People</p>
                    </div>                 */}
            {/* Learning Management */}

            {/* </IsAdmin>

                {/* <div className="col-md-3 col-sm-4 pointer  col-3">
                                  <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yP/r/FMv4tTIpfwB.png" width="50" />
                                <p className="smaller text-white">Health Record</p>
                            </div>
                            <div className="col-md-3 col-sm-4 pointer  col-3">
                                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yA/r/KlDlsO3UxDM.png" width="50" />
                                <p className="smaller text-white">Presription</p>
                            </div>
                            <div className="col-md-3 col-sm- pointer4  col-3">
                                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yg/r/JQCVtO0LVjk.png" width="50" />
                                <p className="smaller text-white">Power Thoughts</p>
                            </div>
                            <div className="col-md-3 col-sm-4 pointer  col-3">
                                <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yI/r/QucXUhmnkru.png" width="50" />
                                <p className="smaller text-white">Payments</p>
                            </div> */}
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center w-100">
            <Spinner animation="border" className="bg-theme text-success" />
          </div>
        )}
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        keyboard={true}
      >
        <Modal.Body className="bg-theme text-theme rounded shadow-lg">
          <h4 className="mb-3">
            <FontAwesomeIcon
              icon={faClock}
              className="mr-2"
              style={{ fontSize: 16 }}
            />
            <span>Coming soon</span>
          </h4>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  taskType: state.platform.taskType,
  showWhiteboard: state.whiteboard.showWhiteboard,
  showGradebook: state.gradebook.showGradebook,
  showAttendanceLedger: state.attendanceLedger.showAttendanceLedger,
  showNoteApp: state.noteApp.showNoteApp,
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  showCaseSheet: state.caseSheet.showCaseSheet,
  currentSubscription: state.subscription.currentSubscription,
});

export default connect(mapStateToProps, {
  setTaskType,
  setContentType,
  setContentTypeAndValue,
  setShowWhiteboard,
  setShowGradebook,
  setShowAttendanceLedger,
  setShowNoteApp,
  setShowCaseSheet,
})(CreateTaskHeader);