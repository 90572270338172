import moment from "moment";
export const getTimeDifference = (timestamp1, timestamp2) => {
  const d = moment.duration(
    moment(new Date(timestamp1), "DD/MM/YYYY HH:mm:ss").diff(
      moment(new Date(timestamp2), "DD/MM/YYYY HH:mm:ss")
    )
  );
  let days = d.days();
  let hours = d.hours();
  let minutes = d.minutes();
  let str = "";

  if (minutes) {
    if (minutes > 55) {
      minutes = 0;
      hours++;
    }
  }
  if (hours) {
    if (hours > 23) {
      hours = 0;
      days++;
    }
  }
  if (days) {
    if (days > 1) {
      str = days + " days, ";
    } else {
      str = days + " day, ";
    }
  }
  if (hours) {
    if (hours > 1) {
      str = str + hours + " hours ";
    } else {
      str = str + hours + " hour ";
    }
  }
  if (minutes) {
    if (minutes > 1) {
      str = str + minutes + " minutes ";
    } else {
      str = str + minutes + " minute ";
    }
  }

  return str;
};
