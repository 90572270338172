import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button,Form, Dropdown, Badge, Modal, Spinner, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faReply,
  faAngleDown,
  faTimes,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import Feedback from "../Tasks/Feedback";
import Poll from "../Tasks/Poll/Poll";
// import Poll from "../Tasks/Poll/PollMessage";
import Quiz from "../Tasks/Quiz/Quiz";
import Project from "../Tasks/Project";
import List from "../Tasks/List";
import Assignment from "../Tasks/Assignment/Assignment1";
//import Cloud from "../Tasks/Cloud/Cloud";
import Reading from "../Tasks/Reading/Reading1";
import Schedule from "../Tasks/Schedule/Schedule";
import ReadMore from "./ReadMore";
import IsAdmin from "../IsAdmin";

import Linkify from "linkifyjs/react";
import Viewer from "react-viewer";
import firebase from "firebase";

import { connect } from "react-redux";
import {
  setReplyId,
  setMessageForward,
  
  setContentTypeAndValue,
  setRightPanel,
  resetAllTasks,
  resetTask,
  resetData,
  setPollTitle,
  setPollOptions,
  setScheduleTitle,
  setScheduleDurationHours,
  setScheduleDurationMinutes,
  setSchedulePlace,
  setScheduleAgenda,
  setReadingTitles,
  setReadingLinks,
  setReadingFiles,
  setReadingUrls,
  setTaskSharing,
  setTaskDeadline,
  setTaskEveryone,
  setTaskChannelUsers,
  setSharedTask,
  setCurrentChannel,
  setAllMessages,
  setCurrentWorkspace,
  setMessagesForwardArray
} from "../../actions/index";

import {
  setQuizTitle,
  setQuizQuestions,
  setQuizOptions,
  setQuizTypes,
  setQuizCorrectAnswers,
  setQuizWeights,
  setQuizTotalWeight,
  setQuizGraded,
  //setQuizSharing,
} from "../Tasks/Quiz/actions";

import {
  setAssignmentQuestions,
  setAssignmentDescriptions,
  setAssignmentWeights,
  setAssignmentTotalWeight,
  setAssignmentGraded,
} from "../Tasks/Assignment/actions";
import _ from "lodash";
import Select from 'react-select';


import RoomListForModalForMessages from "./RoomListForModalForMessages";
import { toast } from "react-toastify";

const RoomSelectModalForMessages = (props) => {
  const [userWorkspaces, setUserWorkspaces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [atleastOneSelected, setAtleastOneSelected] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentWorkspaceIndex, setCurrentWorkspaceIndex] = useState(0);
  const [userWorkspacesDropdown, setUserWorkspacesDropdown] = useState([]);

  const [selectedRooms, setSelectedRooms] = useState([]);

  const {
    currentUser,
    messagesForwardArray,
    currentChannel,
    setCurrentChannel,
    setAllMessages,
    messageDataObj,
    messageData,
    currentWorkspace,
    forwardCheckbox,
    setMessageForward
  } = props;

  const [messageContent, setMessageContent] = useState(null);

  const [fileUrl, setFileUrl] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [type, setType] = useState(null);


  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
  }



  // setting messageContent
  useEffect(() => {
    if (!!messageData && messageData.type === 0) {
      setMessageContent(messageData.content);
    } else if (
      !!messageData &&
      (messageData.type === 6 || messageData.type === 7)
    ) {
      if (messageData.type === 6) {
        setFileUrl(messageData.image);
      } else {
        setFileUrl(messageData.file);
      }
      setMetaData(messageData.metaData);
      setType(messageData.type);
    }
    // console.log(messageData, "useeeffect message data")
  }, [messageData]);

  useEffect(() => {
    firebase
      .firestore()
      .collection(`users/${currentUser.uid}/rooms`)
      .onSnapshot(function (snapshot) {
        // console.log(snapshot, "snapshot")
        let userWorkspaces = [];
        snapshot.forEach(function (doc) {
          userWorkspaces.push({ ...doc.data() });
        });

        let index;

        userWorkspaces.forEach((workspace, key) => {
          // console.log("inside did mount", workspace, key)
          if (workspace.workspaceId === props.currentWorkspace?.workspaceId) {
            index = key
          }
        })

        setCurrentWorkspaceIndex(index);

        let roomIds = _.map(userWorkspaces[index]?.rooms, "roomId");
        const roomIdsArchived = _.map(
          userWorkspaces[index]?.archivedRooms,
          "roomId"
        );
        // console.log(roomIds, "roomIds");
        roomIds = [...roomIds, ...roomIdsArchived];
        if (currentChannel && !roomIds.includes(currentChannel.roomId)) {
          setAllMessages([]);
          setCurrentChannel(null);
        }
        // console.log(userWorkspaces, "userworkspaces")
        setUserWorkspaces(userWorkspaces);
      });
  }, []);



  const getWorkspaceDataHandler = async (workspaceId) => {
    const data = await firebase.firestore().collection("workspaces").doc(workspaceId).get().then((doc) => {
      if (doc.exists) {
        const workspaceData = {
          value: doc.id,
          label: <div className="d-flex">
            <img className="mr-2 align-items-center rounded-circle" style={{ width: '30px' }} src={doc.data().workspacePic}></img>
            <span>{doc.data().workspaceName}</span>
          </div>
        }
        return (workspaceData);
      }
    })
    // console.log(data, "Data")
    return (data);
  }


  useEffect(() => {
    if (userWorkspaces && userWorkspaces.length > 0) {

      const workspacePromise = userWorkspaces.map((workspace, key) => {
        return (
          getWorkspaceDataHandler(workspace.workspaceId)
        );
      })

      Promise.all(workspacePromise).then((values) => {
        setUserWorkspacesDropdown(values);
      })

    }
  }, [userWorkspaces])

  const changeWorkspaceIndexHandler = (workspaceid) => {

    let docRef = firebase.firestore().collection(`workspaces`).doc(`${workspaceid}`)
    docRef.get()
      .then(function (doc) {
        if (doc.exists) {
          const workspacedata = doc.data();
          firebase
            .firestore()
            .collection(`users/${props.currentUser.uid}/rooms`)
            .onSnapshot(function (snapshot) {
              let userWorkspaces = [];
              snapshot.forEach(function (doc) {
                userWorkspaces.push({ ...doc.data() })
              })
              //  traversing through the workspaces and setting the workspace which has workspaceId === workspaceId from url
              let workspaceRooms;
              userWorkspaces.forEach((workspace) => {
                if (workspace.workspaceId === workspaceid) {
                  workspaceRooms = workspace;
                }
              })


              // setting workspace data in redux
              props.setCurrentWorkspace({ ...workspacedata, ...workspaceRooms })
            })

        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      })


    userWorkspaces.forEach((workspace, index) => {
      if (workspace.workspaceId === workspaceid) {
        setCurrentWorkspaceIndex(index);
        // setSelectedRooms([]);
        // setAtleastOneSelected(false);
      }
    })
  }




  const createMessage = (messages) => {
    const { currentUser } = props;
    console.log(messages,"messages before update")
    const message = {
      timestamp: Date.now(),
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      user: {
        id: currentUser.uid,
        name: currentUser.displayName,
        avatar: currentUser.photoURL,
      },
    };
    //console.log(messages.image,"file")
    if (messages.metaData) {
      if(messages.type === 11) {
        message["audio"] = messages.audio;
        message["type"] = 11;
      }
      if (messages.type === 7) {
        message["file"] = messages.file;
        message["type"] = 7;
      }
      if (messages.type === 6) {
        message["image"] = messages.image;
        message["type"] = 6;
      }

      message["metaData"] = messages.metaData;
      message["content"] = messages.content;
    } else if (messages.metaTags) {
      message["content"] = messages.content
      message["type"] = 12;
      message["metaTags"] = messages.metaTags
      message["url"] = messages.url
    }
    else {
      message["content"] = messages.content;
      message["type"] = 0;
    }
    return message;
  };

  const sendMessage = (currentChannel) => {
    const { currentWorkspace } = props;
    //console.log(messagesForwardArray,"ROom Select Modal")
    console.log(messageData,"respoxx")
    if(messagesForwardArray){
      messagesForwardArray.forEach((message) => {
        const db = firebase
          .firestore()
          .collection(
            `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel}/messages`
          );
          const dbUpdate = firebase
          .firestore()
          .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);
          const newMessage = createMessage(message);
          ///console.log(newMessage,"newMessage")
          db.add({...newMessage})
          .then((docRef) => {
            dbUpdate.doc(`${currentChannel}`).update({
            totalMessages: firebase.firestore.FieldValue.increment(1),
            lastMessage: { ...newMessage, uid: docRef.id },
            })
            .catch((err) => {
              console.log(err)
            })
          })
      })
    }
    if(messageData){
      let message = [messageData]
      message.forEach((message) => {
        const db = firebase
          .firestore()
          .collection(
            `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel}/messages`
          );
          const dbUpdate = firebase
          .firestore()
          .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

          const newMessage = createMessage(message);
          ///console.log(newMessage,"newMessage")
          db.add({...newMessage})
          .then((docRef) => {
            dbUpdate.doc(`${currentChannel}`).update({
            totalMessages: firebase.firestore.FieldValue.increment(1),
            lastMessage: { ...newMessage, uid: docRef.id },
            })
            .catch((err) => {
              console.log(err)
            })
          })
      })
    }
  /*  if (messageContent || fileUrl) {
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel}/messages`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

      const newMessage = createMessage();

      db.add({ ...newMessage })
        .then((docRef) => {
          dbUpdate.doc(`${currentChannel}`).update({
            totalMessages: firebase.firestore.FieldValue.increment(1),
            lastMessage: { ...newMessage, uid: docRef.id },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
    }*/
  };

  const shareMessageToOtherRoomsHandler = () => {
    //messagesForwardArray.map((arr) => console.log(arr,"fom modal befor sort")) 
    let arr = messagesForwardArray.sort((a,b)=> a.timestamp-b.timestamp)
    //arr.map((arr) => console.log(arr, "from modal sorted"))
    setMessagesForwardArray(arr);
    //console.log(messageData,"respoxx")
    //console.log(selectedRooms);
    //console.log(props.messageData);
    
    // sending the same message to all the rooms in selectedRooms
    selectedRooms.forEach((room) => {
      sendMessage(room);
    });
    
    //toast("sent.", { position: toast.POSITION.TOP_CENTER });
    props.setMessageForward(false)
    props.setMessagesForwardArray([]);
    props.onHide();
    //console.log(messagesForwardArray,"atlast2");
    //console.log(forwardCheckbox,"atlast1");
    // loading that last room to which the message was forwarded to, in the middle


  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="bg-theme border-0 d-flex justify-content-between align-items-center pb-0">
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="text-theme">Select Room</h5>
        </Modal.Title>

        {/* {
          (userWorkspacesDropdown.length > 0) ?
            <div className="w-50 text-theme p-2">
              <Select
                options={userWorkspacesDropdown}
                isSearchable={false}
                defaultValue={userWorkspacesDropdown[currentWorkspaceIndex]}
                value={userWorkspacesDropdown[currentWorkspaceIndex]}
                onChange={(e) => changeWorkspaceIndexHandler(e.value)}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    // primary25: '#f8f9fa',
                    neutral0: `var(--bg-card-theme)`,
                    neutral80: `var(--text-theme)`,
                  },
                })}
                styles={{
                  control: base => ({
                    ...base,
                    borderRadius: `50rem !important`,
                  }),
                }}
              />
            </div>
            : null
        } */}


        <div title="Close" className="pointer" onClick={() => props.onHide()}>
          <FontAwesomeIcon className="text-theme" icon={faTimes} />
        </div>
      </Modal.Header>
      <Modal.Body
        className="overflow-auto list-unstyled bg-theme pt-0"
        style={{ minHeight: "40vh", maxHeight: "60vh", position: "relative" }}
      >
        {isLoading ? <Spinner animation="border" /> : null}

        <InputGroup className="outerPill p-2">
                  <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                    <div className="py-2 pr-2">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="sm"
                        color="#aeaeae"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <Form.Control
                        type="text"
                        className="border-0 px-0 bg-theme text-theme"
                        onChange={(e) => handleSearch(e)}
                        placeholder={"Search"}
                        value={searchTerm}
                        style={{ margin: 0 }}
                      />
                    </div>
                    {searchTerm.length > 0 && (
                      <div
                        className="py-2 pl-2 pr-3"
                        onClick={() => setSearchTerm('')}
                      >
                        <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                      </div>
                    )}
                  </div>
          </InputGroup>

          <RoomListForModalForMessages
            sortType={0}
            workspace={userWorkspaces[currentWorkspaceIndex]}
            messageDataObj={messageDataObj}
            messageData={messageData}
            isLoading={setIsLoading}
            oneSelected={setAtleastOneSelected}
            selectedChannels={setSelectedRooms}
            searchTerm={searchTerm}
          />

        {!isLoading && atleastOneSelected ? (
          <Button
            variant="primary"
            style={{ position: "sticky", left: "100%", bottom: "0" }}
            onClick={shareMessageToOtherRoomsHandler}
          >
            <FontAwesomeIcon icon={faPaperPlane} />
          </Button>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  forwardCheckbox:state.platform.displayMessageForward,
  currentUser: state.user.currentUser,
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  messagesForwardArray: state.platform.messageForwardArray,
});

export default connect(mapStateToProps, {
  setCurrentChannel,
  setAllMessages,
  setReplyId,
  setCurrentWorkspace,
  setMessageForward,
  setMessagesForwardArray,
})(RoomSelectModalForMessages);