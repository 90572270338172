import {
  faCircleNotch,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import firebase from "firebase";
import { connect } from "react-redux";

import CalenderLogo from "../../../assets/calenderLogo.png";
import IgesiaLogo from "../../../assets/igesia-logo.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  API_BASE_URL,
  DRIVE_UPLOAD,
  SEND_MESSAGE,
} from "../../../config/index";
import axios from "axios";

import * as htmlToImage from "html-to-image";
import PreviousAppointments from "./PreviousAppointments";

const NextAppointment = ({
  currentChannel,
  currentWorkspace,
  currentUser,
  patientDetails,
  roomId,
  workspaceId,
  userId,
}) => {
  const [nextAppointmentDetails, setNextAppointmentDetails] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [error, setError] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const Ref = firebase
    .firestore()
    .collection("caseSheet")
    .doc(currentWorkspace.workspaceId)
    .collection("rooms")
    .doc(currentChannel.roomId)
    .collection("caseSheet")
    .doc("caseSheetId")
    .collection("data")
    .doc("doctorData");

  useEffect(() => {
    Ref.get()
      .then((doc) => {
        console.log(doc);
        if (!doc.data().nextAppointment) {
          setShowEdit(true);
          nextAppointmentDetails["doctorName"] = currentUser.displayName;
          setNextAppointmentDetails({ ...nextAppointmentDetails });
        } else {
          setNextAppointmentDetails({ ...doc.data().nextAppointment });
        }
      })
      .then(() => setIsLoading(false))
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    nextAppointmentDetails[e.target.name] = e.target.value;
    setNextAppointmentDetails({ ...nextAppointmentDetails });
    error[e.target.name] = false;
    setError({ ...error });
  };

  const handleSubmit = () => {
    if (!nextAppointmentDetails.date) {
      error["date"] = true;
      setError({ ...error });
      return;
    }
    if (!nextAppointmentDetails.time) {
      error["time"] = true;
      setError({ ...error });
      return;
    }
    if (!nextAppointmentDetails.doctorName) {
      error["doctorName"] = true;
      setError({ ...error });
      return;
    }
    if (!nextAppointmentDetails.description) {
      error["description"] = true;
      setError({ ...error });
      return;
    }
    setIsLoading(true);
    Ref.update({
      nextAppointment: nextAppointmentDetails,
    })
      .then(() => {
        setIsLoading(false);

        setShowEdit(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleDelete = () => {
    setIsLoading(true);
    Ref.update({
      nextAppointment: null,
    })
      .then(() => {
        setNextAppointmentDetails({});
        setIsLoading(false);
        setShowEdit(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const Format24to12 = (time) => {
    if (!time) return "Invalid";
    let timeSplit = time.split(":");
    console.log(timeSplit);
    let format;
    let min = timeSplit[1];
    let hours;
    if (timeSplit[0] > 12) {
      hours = timeSplit[0] - 12;
      if (hours < 10) {
        hours = "0" + hours;
      }
      format = "PM";
    } else {
      hours = timeSplit[0];
      format = "AM";
    }
    let time12 = hours + ":" + min + " " + format;
    return time12;
  };

  const handleMarkAsDone = () => {
    setIsLoading(true);
    Ref.collection("previousAppointments")
      .add({
        ...nextAppointmentDetails,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        timestamp: Date.now(),
      })
      .then(() => {
        handleDelete();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const handleSendCard = () => {
    if (isSending) return;
    setIsSending(true);

      htmlToImage
        .toJpeg(document.getElementById("appointment-card"), {
          quality: 1,
        })
        .then(function (dataUrl) {
          var ImageURL = dataUrl;
          // Split the base64 string in data and contentType
          var block = ImageURL.split(";");
          // Get the content type of the image
          var contentType = block[0].split(":")[1]; // In this case "image/gif"
          // get the real base64 content of the file
          var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

          // Convert it to a blob to upload
          var file = b64toBlob(realData, contentType);
          console.log(file);
          let fileUrl;
          let Path = userId + roomId + Date.now();
          var storageRef = firebase.storage().ref("casesheet/" + Path);
          var uploadTask = storageRef.put(file);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + Math.round(progress) + "% done");
            },
            function (error) {
              console.log(error);
            },
            () => {
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                  console.log("File available at", downloadURL);
                  fileUrl = downloadURL;
                  const newMessage = {
                    user: {
                      id: currentUser.uid,
                      name: currentUser.displayName,
                      avatar: currentUser.photoURL,
                    },
                    type: 6,
                    image: fileUrl,
                    content: "",
                    metaData: {
                      lastModified: Date.now(),
                      lastModifiedDate: new Date(),
                      name: "prescription.jpg",
                      size: file.size,
                      type: file.type,
                    },
                  };
                  console.log("FILE MESSAGE: ", newMessage);
                  const Message = {
                    workspace_id: workspaceId,
                    room_id: roomId,
                    user_id: userId,
                    message: { ...newMessage },
                  };
                  console.log("Message", Message);
                  axios
                    .post(`${API_BASE_URL}${SEND_MESSAGE}`, { ...Message })
                    .then(() => {
                      console.log("message sent!");
                      setIsSending(false);
                    })
                    .then(() =>
                      toast.success("Successfully sent to chat!", {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      })
                    )
                    .catch((err) => {
                      setIsSending(false);
                      alert("Something went wrong!");
                      console.log(err);
                    });
                });
            }
          );
        });
  };

  return (
    <div
      className="bg-white rounded-lg shadow p-2"
      style={{
        minHeight: "100%",
      }}
    >
      {isLoading ? (
        <div className="text-center text-success pt-5">
          <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-2x" />{" "}
        </div>
      ) : !showEdit ? (
        <>
          <div className="text-primary text-center m-2 lead ">
            Scheduled next appointment
          </div>
          <div className="text-right mb-2 mt-1">
            <span
              className="btn btn-sm btn-info px-3 ml-2"
              onClick={handleSendCard}
            >
              <small>
                {isSending ? (
                  <>
                    Sending{" "}
                    <FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
                  </>
                ) : (
                  "Send in chat"
                )}
              </small>
            </span>
            <span
              className="btn btn-sm btn-primary px-3 ml-2"
              onClick={() => setShowEdit(true)}
            >
              <small>Edit</small>
            </span>
            <span
              className="btn btn-sm btn-danger px-3 ml-2"
              onClick={handleDelete}
            >
              <small>Delete</small>
            </span>
            <span
              className="btn btn-sm btn-success px-3 ml-2"
              onClick={handleMarkAsDone}
            >
              <small>Mark as done</small>
            </span>
          </div>
          <table className="table font-13">
            <tbody>
              <tr>
                <th scope="row" style={{ width: "120px" }}>
                  Date
                </th>
                <td>{nextAppointmentDetails.date}</td>
              </tr>
              <tr>
                <th scope="row">Time</th>
                <td>{Format24to12(nextAppointmentDetails.time)}</td>
              </tr>
              <tr>
                <th>Doctor name</th>
                <td>{nextAppointmentDetails.doctorName}</td>
              </tr>
              <tr>
                <th scope="row">Description</th>
                <td>{nextAppointmentDetails.description}</td>
              </tr>
            </tbody>
          </table>

          {/* ----------------- previous Appointments ------------------------------ */}

          <div className="accordion mt-2" id="accordion1">
            <div className="card">
              <div id="headingThree">
                <button
                  className="btn btn-primary  font-13 btn-block  text-left  "
                  type="button"
                  data-toggle="collapse"
                  data-target="#previousAppointments"
                  aria-expanded="false"
                  aria-controls="previousAppointments"
                >
                  Previous appointments
                </button>
              </div>
              <div
                id="previousAppointments"
                className="collapse show"
                aria-labelledby="headingThree"
                data-parent="#previousAppointments"
              >
                <div className="card-body">
                  <PreviousAppointments
                    workspaceId={currentWorkspace.workspaceId}
                    roomId={currentChannel.roomId}
                    Format24to12={Format24to12}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* ----------------------------- Next appointment card --------------------------- */}

          <div
            className="bg-white rounded-lg border pb-4 "
            style={{ position: "absolute", top: "0", zIndex: "-1", left: "0" }}
            id="appointment-card"
          >
            <div className="py-3">
              <img
                src={IgesiaLogo}
                alt="IgesiaLogo"
                height="25px"
                className="float-right mr-3"
              />
            </div>
            <div className="text-center mt-2">
              <div className=" my-3">
                <img src={CalenderLogo} alt="CalenderLogo" height="80px" />
              </div>
              <div className="px-3 text-info">
                {patientDetails.fName}, your next
                <br />
                appointment has been scheduled.
              </div>
              <div className="mt-3 lead font-weight-bold">
                {Format24to12(nextAppointmentDetails.time)} | Dr.{" "}
                {nextAppointmentDetails.doctorName}
              </div>
              <div className="text-secondary my-2">
                {new Date(nextAppointmentDetails.date).toDateString()}
              </div>
              <hr />
              <div className="font-13 text-justify mb-2 px-3">
                <div className="font-weight-bold">Description</div>
                {nextAppointmentDetails.description}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="">
            <div className="text-primary m-t lead text-center">
            Schedule next appointment<br />
            <span className="text-muted font-13" >Choose date and time</span>
            </div>

            <div className="row mx-0 mt-2">
              <div className="col-6">
                <label className="font-weight-bold">Date</label>
                <input
                  type="date"
                  className="form-control font-13"
                  onChange={handleChange}
                  value={nextAppointmentDetails.date}
                  name="date"
                />
                {error.date && (
                  <div className="font-13 text-danger text-left my-1">
                    <FontAwesomeIcon icon={faExclamationCircle} /> Please select
                    a date!
                  </div>
                )}
              </div>
              <div className="col-6">
                <label className="font-weight-bold">Time</label>
                <input
                  type="time"
                  className="form-control  font-13"
                  step="1800"
                  name="time"
                  value={nextAppointmentDetails.time}
                  onChange={handleChange}
                />
                {error.time && (
                  <div className="font-13 text-danger text-left my-1">
                    <FontAwesomeIcon icon={faExclamationCircle} /> Please select
                    time!
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group  px-3 mt-2">
            <label className="font-weight-bold">Doctor name</label>
            <input
              placeholder="Enter doctor name"
              name="doctorName"
              className="form-control text-dark  font-13"
              value={nextAppointmentDetails.doctorName}
              onChange={handleChange}
            ></input>
            {error.doctorName && (
              <div className="font-13 text-danger text-left my-1">
                <FontAwesomeIcon icon={faExclamationCircle} /> Please enter
                doctor name!
              </div>
            )}
          </div>
          <div className="form-group  px-3 ">
            <label className="font-weight-bold">Description</label>
            <textarea
              rows="4"
              placeholder="Write here..."
              name="description"
              className="form-control text-dark  font-13"
              value={nextAppointmentDetails.description}
              onChange={handleChange}
            ></textarea>
            {error.description && (
              <div className="font-13 text-danger text-left my-1">
                <FontAwesomeIcon icon={faExclamationCircle} /> Please enter
                description!
              </div>
            )}
          </div>

          <div className="my-2 text-right">
            <span
              className="btn btn-primary btn-sm px-2"
              onClick={handleSubmit}
            >
              Submit
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
  roomId: state.channel.currentChannel.roomId,
  userId: state.user.currentUser.uid,
  workspaceId: state.workspace.currentWorkspace.workspaceId,
});

export default connect(mapStateToProps)(NextAppointment);