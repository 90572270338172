import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import moment from "moment";

const List = ({ uid, taskResponse, item, user }) => {
    const [resp, setResp] = useState([])
    
    useEffect(() => {
        item.response && setResp(item.response[user] ? getValue(item.response[user]) : [])
    }, []);

    const getValue = obj => {
        let prev = [...resp]

        obj.value.length > 0 
        ? Object.entries(obj.value).map(([key, value]) => {
            prev[key] = value
        }) 
        : item && item["listItems"].map((listItem, key) => {
            prev[key] = {value: false}
        })
        console.log(prev)
        return prev
    }
    
    const handleCheck = e => {
        let prev = [...resp]
        let key = e.target.name
        if (prev[key]) {
            prev[key] = {responseTime: Date.now(), value: getChecked(prev[key]) ? false : true}
        } else {
            prev[key] = {responseTime: Date.now(), value: true}
        }
        setResp(prev)
    }

    const getChecked = obj => {
        return obj && obj.value
    }

    return (
        <>
            <div className="taskBox">
                <Form className="quiz">
                    <h5>{ item.list }</h5>
                    <p>{ item.description }</p>
                    <Form.Group id="formGridCheckbox">
                    {item && item["listItems"].map((listItem, key) => (
                        <div key={key} className="mb-3">
                            <Form.Check type="checkbox" id={key}>
                                <Form.Check.Input for={key} id={key} type="checkbox" checked={resp && getChecked(resp[key])} name={key} onChange={handleCheck} />
                                <Form.Check.Label>{listItem.title} <a href={listItem.link} target="_blank"> <FontAwesomeIcon icon={faLink} color="primary" /></a></Form.Check.Label>
                                <p>{moment(listItem.deadline/100).format("hh:mm A MMM DD YYYY")}</p>
                            </Form.Check>
                        </div>
                    ))}
                    </Form.Group>
                    <Button variant="primary" size="sm" onClick={() => console.log(uid, resp)}>Submit</Button>
                </Form>
            </div>
        </>
    )
};

export default List;
