import React, { useState, useEffect } from "react";

import firebase from "firebase";

import "./GridViewNote.css";
import ReactHtmlParser from "react-html-parser";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faEdit,
  faEllipsisH,
  faPalette,
  faThumbtack,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const GridViewNote = ({
  Note,
  setSelectedNote,
  setNoteEdit,
  setNoteWriteMode,
  SelectedNotebook,
  currentUser,
}) => {
  const [NoteColor, setNoteColor] = useState(null);

  useEffect(() => {
    if (NoteColor) {
      firebase
        .firestore()
        .collection("notesApp")
        .doc(currentUser.uid)
        .collection("notes")
        .doc(Note.id)
        .update({
          bg_color: NoteColor,
          updated_at: Date.now(),
        });
    }
  }, [NoteColor]);

  const clearNoteColor = () => {
    firebase
      .firestore()
      .collection("notesApp")
      .doc(currentUser.uid)
      .collection("notes")
      .doc(Note.id)
      .update({
        bg_color: null,
        updated_at: Date.now(),
      })
      .then(() => setNoteColor(null));
  };

  const DeleteNote = (id) => {
    Swal.fire({
      title: "Delete Note?",
      showDenyButton: true,
      confirmButtonText: `Delete`,
    }).then((result) => {
      if (result.isConfirmed) {
        firebase
          .firestore()
          .collection("notesApp")
          .doc(currentUser.uid)
          .collection("notes")
          .doc(id)
          .delete();
      } else if (result.isDenied) {
      }
    });
  };

  const HandleArchiveNote = (ArchiveNote) => {
    firebase
      .firestore()
      .collection("notesApp")
      .doc(currentUser.uid)
      .collection("notes")
      .doc(ArchiveNote.id)
      .update({
        is_archived: !ArchiveNote.is_archived,
      });
  };

  const HandlePinNote = (PinNote) => {
    firebase
      .firestore()
      .collection("notesApp")
      .doc(currentUser.uid)
      .collection("notes")
      .doc(PinNote.id)
      .update({
        is_pinned: !PinNote.is_pinned,
      });
  };

  return (
    <div
      className="GridViewNote"
      style={{ backgroundColor: Note.bg_color ? Note.bg_color : "#181819" }}
    >
      <span
        className={`GridViewNote-pin ${
          Note.is_pinned ? "text-yellow" : "text-white"
        }`}
        onClick={() => HandlePinNote(Note)}
      >
        <FontAwesomeIcon icon={faThumbtack} />
      </span>
      <span
        className="GridViewNote-action px-1"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <FontAwesomeIcon icon={faEllipsisH} className="font-14 mt-2" />
      </span>
      <div
        className="dropdown-menu  dropdown-menu-right"
        style={{ filter: "invert(0.85)" }}
        aria-labelledby="dropdownMenuButton"
      >
        <span
          className="dropdown-item cp font-13"
          onClick={() => {
            setNoteEdit(Note);
            setNoteWriteMode(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit} className="mr-2" /> Edit
        </span>
        {!SelectedNotebook && (
          <span
            className="dropdown-item cp font-13"
            onClick={() => HandleArchiveNote(Note)}
          >
            <FontAwesomeIcon icon={faArchive} className="mr-2" />
            {Note.is_archived ? "Unarchive" : "Archive"}
          </span>
        )}

        <span
          className="dropdown-item cp font-13"
          onClick={() => DeleteNote(Note.id)}
        >
          <FontAwesomeIcon icon={faTrash} className="mr-2" /> Delete
        </span>
        <hr className="my-0" />
        <span className="dropdown-item font-12">
          <FontAwesomeIcon icon={faPalette} className="mr-2" />
          <b>Change color</b>{" "}
          {Note.bg_color && (
            <span
              className="ml-2 font-10 cp text-primary"
              onClick={clearNoteColor}
            >
              clear
            </span>
          )}
        </span>
        <div className="text-center" style={{ filter: "invert(1)" }}>
          <span
            className={`note-color-selector first ${
              Note.bg_color === "#ff4c50" ? "Note-selected-color" : null
            }`}
            onClick={() => setNoteColor("#ff4c50")}
          ></span>
          <span
            className={`note-color-selector second ${
              Note.bg_color === "#4c70ff" ? "Note-selected-color" : null
            }`}
            onClick={() => setNoteColor("#4c70ff")}
          ></span>
          <span
            className={`note-color-selector third ${
              Note.bg_color === "#ffc34c" ? "Note-selected-color" : null
            }`}
            onClick={() => setNoteColor("#ffc34c")}
          ></span>
          <span
            className={`note-color-selector fourth ${
              Note.bg_color === "#2fcda9" ? "Note-selected-color" : null
            }`}
            onClick={() => setNoteColor("#2fcda9")}
          ></span>
        </div>
      </div>

      <div
        className="cp h-100 unselectable"
        onClick={() => setSelectedNote(Note)}
      >
        <div className="GridViewNote-title">{Note.title}</div>
        {Note.content ? (
          <div className="GridViewNote-text">
            {Note.content.length > 220 ? (
              <span>{ReactHtmlParser(Note.content.slice(0, 220))}</span>
            ) : (
              ReactHtmlParser(Note.content)
            )}
          </div>
        ) : (
          <div className="GridViewNote-empty">empty!</div>
        )}
      </div>
      {/* <div className="GridViewNote-label">Chapter 1</div> */}
    </div>
  );
};
export default GridViewNote;
