import React, { useState, useEffect } from "react";
import "./CreateNote.css";

import BookLogo from "../BookLogo/BookLogo";
import NoteLogo from "../Assets/NoteLogo.png";
import firebase from "firebase";
import Swal from "sweetalert2/src/sweetalert2.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

const CreateNote = ({
  ShowCreateNote,
  setShowCreateNote,
  setNoteWriteMode,
  setSaving,
  SelectedNotebook,
  NotebookEdit,
  setNotebookEdit,
  currentUser,
}) => {
  const [addNotebook, setAddNotebook] = useState(false);

  const [NotebookName, setNotebookName] = useState("");
  const [NotebookColor, setNotebookColor] = useState("#ff4c50");

  const CloseNoteModal = () => {
    let createNote = document.getElementById("createNote");
    createNote.classList.add("create-note-hide");
    setNotebookEdit(null);
    setTimeout(() => {
      createNote.classList.remove("create-note-hide");
      setShowCreateNote(false);
    }, 500);
  };
  useEffect(() => {
    if (NotebookEdit) {
      setAddNotebook(true);
      setNotebookName(NotebookEdit.name);
      setNotebookColor(NotebookEdit.bg_color);
    }
  }, [NotebookEdit]);

  const handleSaveNoteBook = () => {
    if (!NotebookName) return;
    setSaving(true);
    if (!NotebookEdit) {
      firebase
        .firestore()
        .collection("notesApp")
        .doc(currentUser.uid)
        .collection("notebooks")
        .add({
          name: NotebookName,
          lower_case_name: NotebookName.toLowerCase(),
          bg_color: NotebookColor,
          is_archived: false,
          is_pinned: false,
          pinned_at: null,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          timestamp: Date.now(), 
        })
        .then(() => {
          setSaving(false);
          CloseNoteModal();
          Swal.fire("Success", "Notebook added successfully!", "success");
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
          Swal.fire("Error", "Something went wrong!", "error");
        });
    } else {
      firebase
        .firestore()
        .collection("notesApp")
        .doc(currentUser.uid)
        .collection("notebooks")
        .doc(NotebookEdit.id)
        .update({
          name: NotebookName,
          lower_case_name: NotebookName.toLowerCase(),
          bg_color: NotebookColor,
          updated_at: Date.now(),
        })
        .then(() => {
          setSaving(false);
          CloseNoteModal();
          Swal.fire("Success", "Notebook updated successfully!", "success");
        })
        .catch((err) => {
          console.log(err);
          setSaving(false);
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  return (
    <div
      id="createNote"
      className={`create-note ${ShowCreateNote ? "create-note-show" : null}`}
    >
      {addNotebook ? (
        <div className="add-notebook-name">
          <div>
            <span
              className="add-notebook-name-cancel"
              onClick={() => {
                if (NotebookEdit) {
                  CloseNoteModal();
                } else {
                  setAddNotebook(false);
                }
              }}
            >
              Cancel
            </span>
            <span
              className="add-notebook-name-save"
              onClick={handleSaveNoteBook}
            >
              Save
            </span>
          </div>
          <div className="lead mx-3 mt-2 mb-2">Notebook</div>
          <div className="text-center">
            <input
              type="text"
              placeholder="Enter notebook name..."
              className="add-notebook-name-input"
              autoFocus
              value={NotebookName}
              onChange={(e) => setNotebookName(e.target.value)}
            />
          </div>
          <div className="mt-2 ml-3 pl-2">
            <span>Color </span>

            <span className="ml-5">
              <span
                className={`color-selector first ${
                  NotebookColor === "#ff4c50" ? "Notebook-selected-color" : null
                }`}
                onClick={() => setNotebookColor("#ff4c50")}
              ></span>
              <span
                className={`color-selector second ${
                  NotebookColor === "#4c70ff" ? "Notebook-selected-color" : null
                }`}
                onClick={() => setNotebookColor("#4c70ff")}
              ></span>
              <span
                className={`color-selector third ${
                  NotebookColor === "#ffc34c" ? "Notebook-selected-color" : null
                }`}
                onClick={() => setNotebookColor("#ffc34c")}
              ></span>
              <span
                className={`color-selector fourth ${
                  NotebookColor === "#2fcda9" ? "Notebook-selected-color" : null
                }`}
                onClick={() => setNotebookColor("#2fcda9")}
              ></span>
            </span>
          </div>
        </div>
      ) : (
        <div className="create-note-selector">
          {!SelectedNotebook && (
            <span
              className="create-note-logo"
              onClick={() => setAddNotebook(true)}
            >
              <BookLogo />
              <span className="">Add Notebook</span>
            </span>
          )}

          <span
            className="create-note-logo"
            onClick={() => {
              setNoteWriteMode(true);
              CloseNoteModal();
            }}
          >
            <img src={NoteLogo} alt="NoteLogo" width="55px" height="75px" />
            <span className=" mt-1 pt-1">Add Note</span>
          </span>
        </div>
      )}

      <span className="create-note-hide-button" onClick={CloseNoteModal}>
        <FontAwesomeIcon icon={faSortDown} />
      </span>
    </div>
  );
};

export default CreateNote;
