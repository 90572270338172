import React, { Component, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faImage,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faArrowLeft,
  faExclamationCircle,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Spinner,
  Accordion,
  Card,
  useAccordionToggle,
  Form,
  FormControl,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
toast.configure();
export default function AddFiles({
  file,
  handleMetaData,
  handleType,
  // percentUploaded,
  // addFile,
  //sendFile,
}) {
  //const [show, setShow] = useState(false);
  const [preview, setPreview] = useState(null);
  const [allowedType, setAllowedType] = useState(false);
  const [metaData, setMetaData] = useState(null);
  //const [sending, setSending] = useState(false);

  useEffect(() => {
    console.log(file);
    const reader = new FileReader();
    if (file.size > 20971520) {
      toast.error(
        "The maximum file size is 20MB. Please choose a different file.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    } else {
      if (file) {
        setMetaData({
          lastModified: file.lastModified,
          lastModifiedDate: JSON.stringify(file.lastModifiedDate),
          name: file.name,
          size: file.size,
          type: file.type,
        });
        setAllowedType(
          image_type.includes(file.type) ||
            word_type.includes(file.type) ||
            pdf_type.includes(file.type) ||
            powerpoint_type.includes(file.type) ||
            excel_type.includes(file.type)
        );
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
          setPreview(reader.result);
        });
      }
    }
  }, []);
  useEffect(() => {
    metaData && allowedType && handleMetaData(metaData);
    if (metaData && allowedType && image_type.includes(metaData.type))
      handleType("Image");
    if (
      metaData &&
      allowedType &&
      (word_type.includes(metaData.type) ||
        pdf_type.includes(metaData.type) ||
        powerpoint_type.includes(metaData.type) ||
        excel_type.includes(metaData.type))
    )
      handleType("File");
  }, [metaData, allowedType]);

  const image_type = ["image/jpeg", "image/png", "image/svg+xml", "image/gif"];

  const pdf_type = ["application/pdf"];

  const word_type = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const excel_type = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const powerpoint_type = [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ];

  return (
    <>
      <div className="text-theme row d-flex justify-content-center my-2">
        {preview && (
          <>
            {/* {metaData && <p>{JSON.stringify(metaData)}</p>} */}
            {!allowedType && (
              <small className="text-danger">
                Sorry! but this file type is not supported yet.
              </small>
            )}
            {/* {allowedType && image_type.includes(metaData.type) && (
              <div
                className="col-12 mb-3 mx-auto d-flex justify-content-center"
                style={{
                  height: "200px",
                  width: "200px",
                  overflow: "hidden",
                }}
              >
                <img src={preview} alt="icon" className="rounded w-100" />
              </div>
            )} */}

            {image_type.includes(metaData.type) ? null : (
              <div className="col-12 d-flex justify-content-center rounded bg-light mb-0">
                <div
                  className="py-2 px-3"
                  style={{ backgroundColor: "#FEF2E4" }}
                >
                  {!allowedType && (
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="text-secondary"
                    />
                  )}
                  {image_type.includes(metaData.type) && (
                    <FontAwesomeIcon
                      icon={faImage}
                      className="text-secondary"
                    />
                  )}
                  {word_type.includes(metaData.type) && (
                    <FontAwesomeIcon
                      icon={faFileWord}
                      className="text-primary"
                    />
                  )}
                  {pdf_type.includes(metaData.type) && (
                    <FontAwesomeIcon icon={faFilePdf} className="text-danger" />
                  )}
                  {powerpoint_type.includes(metaData.type) && (
                    <FontAwesomeIcon
                      icon={faFilePowerpoint}
                      className="text-danger"
                    />
                  )}
                  {excel_type.includes(metaData.type) && (
                    <FontAwesomeIcon
                      icon={faFileExcel}
                      className="text-success"
                    />
                  )}
                </div>
                <div className="p-2 flex-grow-1">
                  <small className="font-weight-bold text-dark">
                    {metaData.name}
                  </small>
                </div>
                <div className="py-2 px-3">
                  <FontAwesomeIcon icon={faLink} className="text-secondary" />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
