import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/firebase-messaging';

// var webconfig = {
//     apiKey: "AIzaSyAyox7br-qET_sNRtJSB8BUV2W6yuSbPxs",
//     authDomain: "igesia-web.firebaseapp.com",
//     databaseURL: "https://igesia-web.firebaseio.com",
//     projectId: "igesia-web",
//     storageBucket: "igesia-web.appspot.com",
//     messagingSenderId: "167545419151",
//     appId: "1:167545419151:web:7d51b3b30c023cfc1147fa",
//     measurementId: "G-ZXFRJBSRLT"
//   };

//   var guconfig = {
//     apiKey: "AIzaSyAhil9-iZHA2jHUJU2owqpmu63sn07lvrk", 
//     authDomain: "gu-igesia.firebaseapp.com", 
//     databaseURL: "https://gu-igesia.firebaseio.com", 
//     projectId: "gu-igesia", 
//     storageBucket: "gu-igesia.appspot.com", 
//     messagingSenderId: "80096395676", 
//     appId: "1:80096395676:web:ccf19037d81d5bf3ea4859", 
//     //measurementId: "G-ZXFRJBSRLT"
//   };

//   const firebaseConfig = {
//     apiKey: "AIzaSyCs7Q7b4gb27vrCFw-ZHziu5s2t1xuIpKo",
//     authDomain: "igesia-v1.firebaseapp.com",
//     databaseURL: "https://igesia-v1.firebaseio.com",
//     projectId: "igesia-v1",
//     storageBucket: "igesia-v1.appspot.com",
//     messagingSenderId: "971790760240",
//     appId: "1:971790760240:web:038d94c462e78096c1b0f5"
// };

const firebaseConfig = {

  apiKey: "AIzaSyDmaKKLmqVg8uyjUx_OfaOttZwDRU6XBl0",
  authDomain: "igesia-94de2.firebaseapp.com",
  databaseURL: "https://igesia-94de2.firebaseio.com",
  projectId: "igesia-94de2",
  storageBucket: "igesia-94de2.appspot.com",
  messagingSenderId: "415922997581",
  appId: "1:415922997581:web:47bdf5fd3fa266f5134f04",
  measurementId: "G-DW1ZCJN8HQ"
};

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  // firebase.initializeApp(webconfig);
  // firebase.initializeApp(guconfig);
  //firebase.analytics();
  //igesia-v1 or beta-igesia-v1
  //npm run build;  firebase deploy --project igesia-v1

  export default firebase;


  