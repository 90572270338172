import React, { useEffect, useState, useRef } from "react";

import { Dropdown, Modal, Button } from "react-bootstrap";
import firebase from "firebase";
import RoomListElement from "./RoomListElement";
import { connect } from "react-redux";
import {
  API_BASE_URL,
  ROOM_LEAVE,
  USER_ROOM_ARCHIVE,
  USER_ROOM_UNARCHIVE,
} from "../../config/index";
import { ARCHIVE } from "../../actions/types";
import {
  setChannels,
  setAllMessages,
  setCurrentChannel,
  setMessageForward,
  setMessagesForwardArray,
  setCurrentWorkspace,
  setParticipants,
  resetData,
  setRightPanel,
  setLeftPanel,
  setContentTypeAndValue,
  setWorkspaces,
  setTypedTextMessage,
} from "../../actions/index";
import { timeFromNow, postData } from "../../utils/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faThumbtack,
  faLock,
  faLockOpen,
  faPoll,
  faFileAlt,
  faImage,
  faFileWord,
  faFilePdf,
  faClipboard,
  faFilePowerpoint,
  faFileExcel,
  faCircle,
  faCalendarAlt,
  faTasks,
  faFileAudio,
  faBriefcaseMedical
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import _ from "lodash";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { platform } from "chart.js";
import "./Left.css";
const pdf_type = ["application/pdf"];

const word_type = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const excel_type = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const powerpoint_type = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];

const audio_type = ["audio/mpeg", "audio/mp4", "audio/x-mpegurl", "audio/ogg", "audio/vnd.wav", "audio/wav", "audio/wave"]

const FileIcon = ({ message }) => {
  if (word_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFileWord} className="text-theme" />;
  if (pdf_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFilePdf} className="text-theme" />;
  if (powerpoint_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFilePowerpoint} className="text-theme" />;
  if (excel_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFileExcel} className="text-theme" />;
  if (audio_type.includes(message.metaData && message.metaData.type))
    return <FontAwesomeIcon icon={faFileAudio} className="text-theme" />;
  return <div></div>;
};

const RoomItem = (props) => {
  // console.log(props, "props")
  const [roomLiveStatus, setRoomLiveStatus] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState(null);
  // const [roomData, setRoomData] = useState({});

  const compareTimestampLodashLatestFirst = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) => {
      if (channel.lastMessage) {
        return channel.lastMessage.timestamp;
      } else {
        return 1000000000000;
      }
    });

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted.reverse());
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareTimestampLodashOldestFirst = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) => {
      if (channel.lastMessage) {
        return channel.lastMessage.timestamp;
      } else {
        return 1000000000000;
      }
    });

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted);
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareAlphabetLodashAtoZ = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) =>
      channel.roomName.toLowerCase()
    );
    // console.log('atoz')

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted);
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const compareAlphabetLodashZtoA = (allChannels) => {
    const sorted = _.sortBy(allChannels, (channel) =>
      channel.roomName.toLowerCase()
    );
    // console.log('ztoa')

    // using cloneDeep cause the channels state in redux is an array of objects and sorting/reordering of array elements won't be noticed by react/javascript's shallow comparison
    const sortedClonedDeep = _.cloneDeep(sorted.reverse());
    props.setChannels(sortedClonedDeep);
    return 1;
  };

  const switchSort = (allChannels, sortType) => {
    // console.log(allChannels,"allChannels")
    switch (sortType) {
      case 0:
        return compareTimestampLodashLatestFirst(allChannels);

      case 1:
        return compareAlphabetLodashAtoZ(allChannels);

      case 2:
        return compareAlphabetLodashZtoA(allChannels);

      case 3:
        return compareTimestampLodashOldestFirst(allChannels);

      default:
        return compareTimestampLodashLatestFirst(allChannels);
    }
  };

  const getRoomData = () => {
    const {
      roomData,
      workspace,
      allChannels,
      setChannels,
      index,
      currentUser,
      currentWorkspace,
    } = props;

    const { workspaceId } = workspace;
    const workspaceIdLowerCase = workspaceId.toLowerCase();

    const { roomId } = roomData;
    const roomIdLowerCase = roomId.toLowerCase();

    const now = new Date().valueOf();
    const query = firebase
      .firestore()
      .collection(
        `workspaces/${currentWorkspace.workspaceId}/rooms/${roomId}/messages`
      );
    let unsub;

    unsub = query.onSnapshot(
      {
        includeMetadataChanges: false,
      },
      function (doc) {
        doc.docChanges().forEach((change) => {
          if (change.type === "added") {
            if (change.doc.data().timestamp >= now) {
              console.log("message added ", change.doc.data());
              let prevAllChannels = allChannels;
              firebase
                .firestore()
                .collection(`workspaces/${currentWorkspace.workspaceId}/rooms/`)
                .doc(`${roomId}`)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    console.log("updated room data", {
                      ...doc.data().lastMessage
                    }, index, prevAllChannels[index].roomName);
                    prevAllChannels.splice(index, 1, {
                      ...doc.data(),
                      roomId: doc.id,
                      lastMessage: { ...change.doc.data(), uid: change.doc.id }
                    });
                    console.log(prevAllChannels, "prevallchannels");
                    switchSort(prevAllChannels);
                  }
                });
            }
          }
          if (change.type === "modified") {
            console.log("message modified: ", change.doc.data(), change.doc.id);

            // if change.doc.id !== doc.data().lastMessage.uid (the one from get() of room)
            // then don't modify

            let prevAllChannels = allChannels;
            firebase
              .firestore()
              .collection(`workspaces/${workspaceId}/rooms/`)
              .doc(`${roomId}`)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  console.log("updated room data", {
                    ...doc.data(),
                    roomId: doc.id,
                  });

                  if (doc.data().lastMessage.uid === change.doc.id) {
                    prevAllChannels.splice(index, 1, {
                      ...doc.data(),
                      roomId: doc.id,
                      lastMessage: change.doc.data(),
                    });
                  }
                  // console.log(prevAllChannels,"prevallchannels",prevSortType,"prevsorttype", props.sortType,"currentsorttype")
                  switchSort(prevAllChannels, props.sortType);
                }
              });
          }
          if (change.type === "removed") {
            console.log("message removed: ", change.doc.data());
          }
        });
      }
    );

    setUnsubscribe(() => unsub);

    // firebase
    //     .firestore()
    //     .collection(`sessions/${workspaceIdLowerCase}/rooms`)
    //     .doc(`${roomIdLowerCase}`)
    //     .onSnapshot(doc => {
    //         if (doc.exists) {
    //             // console.log("LIVE Sessions doc: ", doc.data())
    //             const { currentSession } = doc.data()
    //             if (currentSession !== "") {
    //                 setRoomLiveStatus(true)
    //                 // console.log(roomId,"room id of live in snapshot")
    //             } else {
    //                 setRoomLiveStatus(false)
    //             }
    //         }
    // })
  };

  useEffect(() => {
    if (props.currentWorkspace.workspaceId) {
      getRoomData();
    }
  }, [props.sortType, props.currentWorkspace.workspaceId]);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevCurrentWorkspace = usePrevious(props.currentWorkspace);

  useEffect(() => {
    if (unsubscribe) {
      console.log("unsub")
      unsubscribe();
      // setUnsubscribe(null);
    }

    // console.log(prevCurrentWorkspace, "prevWorkspace", workspace, "workspace", unsubscribe," unsubscribe")
    // if(prevCurrentWorkspace?.workspaceId !== props.currentWorkspace?.workspaceId && unsubscribe){
    //     console.log(unsubscribe, "unsubscribing")
    //   unsubscribe();
    // }else if (props.currentWorkspace?.workspaceId && unsubscribe) {
    //     console.log(unsubscribe, "unsubscribing 2")
    //     unsubscribe();
    // }
  }, [props.currentWorkspace.workspaceId]);

  // useEffect(() => {
  //   if (unsubscribe && props.searchTerm) {
  //     console.log(unsubscribe, "unsub", props.searchTerm)
  //     unsubscribe();
  //   }
  // }, [props.searchTerm, unsubscribe]);

  useEffect(() => {
    return () => {
      if (unsubscribe) {
        console.log("unsub")
        unsubscribe();
      }

      // setUnsubscribe(null);
    };
  }, []);

  const strip = (value) => {
    //  console.log(value);
    const { user, content } = value;
    let name = user.name;
    let firstName = name.trim().split(" ")[0];
    //  console.log(firstName);

    if (value.type === 0) {
      if (
        value.content &&
        firstName.length + content.length > 32 &&
        !value.isDeleted
      ) {
        let completeContent = `${firstName}: ${content}`;
        return `${completeContent.slice(0, 32)}...`;
      } else if (!value.isDeleted && value.content) {
        return `${firstName}: ${content}`;
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 1) {
      if (!value.isDeleted) {
        return (
          <span>
            {firstName}:{" "}
            <FontAwesomeIcon icon={faCalendarAlt} className="text-theme" />{" "}
            Schedule
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 2) {
      if (!value.isDeleted) {
        return (
          <span>
            {firstName}:{" "}
            <FontAwesomeIcon icon={faClipboard} className="text-theme" />
            Files
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 3) {
      if (!value.isDeleted) {
        return (
          <span>
            {firstName}:{" "}
            <FontAwesomeIcon icon={faPoll} className="text-theme" /> Poll
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 4) {
      if (!value.isDeleted) {
        return (
          <span>
            {firstName}:{" "}
            <FontAwesomeIcon icon={faTasks} className="text-theme" /> Quiz
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 5) {
      if (!value.isDeleted) {
        return (
          <span>
            {firstName}:{" "}
            <FontAwesomeIcon icon={faFileAlt} className="text-theme" />{" "}
            Assignment
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 6) {
      if (
        (content && firstName.length + content.length > 32) ||
        (firstName.length > 32 && !value.isDeleted)
      ) {
        let len = 32 - firstName.length;

        return (
          <span>
            {firstName}: <FontAwesomeIcon icon={faImage} />{" "}
            {content.length > len ? content.slice(0, len) + "…" : content}
          </span>
        );
      } else if (!value.isDeleted) {
        return (
          <span>
            {firstName}: <FontAwesomeIcon icon={faImage} /> Photo
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 7) {
      if (
        (content && firstName.length + content.length > 32) ||
        (firstName.length > 32 && !value.isDeleted)
      ) {
        let len = 32 - firstName.length;

        return (
          <span>
            {firstName}: <FileIcon message={value} />{" "}
            {content.length > len ? content.slice(0, len) + "…" : content}
          </span>
        );
      } else if (!value.isDeleted) {
        return (
          <span>
            {firstName}: <FileIcon message={value} />{" "}
            {value.metaData && value.metaData.name}
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 8) {
      return (
        <span>
          {content.length > 36 ? `${content.slice(0, 36)}...` : content}
        </span>
      );
    } else if (value.type === 9) {
      return (
        <span>
          {content.length > 36 ? `${content.slice(0, 36)}...` : content}
        </span>
      );
    } else if (value.type === 10) {
      if (!value.isDeleted) {
        return (
          <span>
            {firstName}:{" "}
            <FontAwesomeIcon icon={faTasks} className="text-theme" /> Special
            olympic
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 11) {
      if (!value.isDeleted) {
        return (
          <span>
            {firstName}:{" "}
            <FontAwesomeIcon icon={faFileAudio} className="text-theme" /> Audio
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    } else if (value.type === 12) {
      if (
        value.content &&
        firstName.length + content.length > 32 &&
        !value.isDeleted
      ) {
        let completeContent = `${firstName}: ${content}`;
        return `${completeContent.slice(0, 32)}...`;
      } else if (!value.isDeleted && value.content) {
        return `${firstName}: ${content}`;
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    }else if (value.type === 13) {
      if (!value.isDeleted) {
        return (
          <span>
            {firstName}:{" "}
            <FontAwesomeIcon icon={faBriefcaseMedical} className="text-theme" /> Patient details form
          </span>
        );
      } else if (value.isDeleted) {
        return `${firstName}: This message was deleted`;
      }
    }
    else {
      return value.type;
    }
  };

  const {
    key,
    setMessageForward,
    setMessagesForwardArray,
    currentChannel,
    workspaceData,
    workspace,
    setCurrentChannel,
    setCurrentWorkspace,
    setParticipants,
    resetData,
    currentUser,
    setTypedTextMessage,
  } = props;
  const roomData = props.roomData;
  const { roomId } = roomData;

  // console.log(roomId,"room id of live before return")
  return (
    <li
      className={
        currentChannel && roomData.roomId === currentChannel.roomId
          ? "active rounded-lg py-1 m-1 bg-card-theme shadow-sm text-theme"
          : "rounded-lg py-1 m-1 bg-card-theme shadow-sm text-theme"
      }
      key={key}
      onClick={() => {
        // console.log(
        //   "this is the value of the roomData and id",
        //   roomData,
        //   roomId
        // );
        setTypedTextMessage("")
        setMessagesForwardArray([]);
        setMessageForward(false);
        setCurrentChannel({ ...roomData, roomId });
        setCurrentWorkspace({ ...workspaceData, ...workspace });
        setParticipants(workspace.workspaceId, roomId);
        resetData();
        // setLeftPanel(!this.props.displayLeftPanel);
      }}
      name={roomData.roomName}
      active={currentChannel && roomData.roomId === currentChannel.roomId}
    >
      <div className="d-flex align-items-center p-2 w-100">
        <div className={roomLiveStatus ? "liveroom" : ""}>
          <img
            className={
              roomLiveStatus
                ? "mr-2 rounded-circle profile-image"
                : "mr-2 rounded-circle"
            }
            src={roomData.roomPic}
            style={{ height: 45, width: 45 }}
          />
        </div>
        <div className="flex-grow-1">
          <div className="d-flex align-items-center">
            {roomData.roomType === 1 && (
              <FontAwesomeIcon
                icon={faLock}
                className="text-success mr-2"
                size="xs"
              />
            )}
            <p
              data-toggle="tooltip"
              data-placement="bottom"
              title={roomData.roomName}
              className="mb-0 text-theme"
            >
              {roomData.roomName.length > 20
                ? roomData.roomName.substring(0, 20) + "..."
                : roomData.roomName}
            </p>
          </div>
          {roomData.lastMessage && (
            <small className="text-theme text-theme-lighter">
              <span
                data-toggle="tooltip"
                data-placement="bottom"
                title={roomData.lastMessage.content}
              >
                {/* {console.log(
                  "i got that is this is a last message",
                  roomData.lastMessage.content
                )} */}
                {strip(roomData.lastMessage)}
              </span>
            </small>
          )}
        </div>
        <div className="text-right align-self-start">
          {/* <FontAwesomeIcon
                            icon={faThumbtack}
                            style={isPinned ? { fontSize: 12, transform: "rotate(45deg)" } : { fontSize: 12 }}
                            className={isPinned ? "text-theme" : "text-secondary"} /> */}
          <p
            className="mb-0 text-theme small text-theme-lighter"
            style={{ whiteSpace: "nowrap" }}
          >
            {roomData.lastMessage &&
              timeFromNow(roomData.lastMessage?.createdAt?.toDate())}
          </p>
          {/* Messages Notification */}
          {/* <span className="text-white bg-primary smaller font-weight-bold" style={{ whiteSpace: "nowrap", borderRadius: "2px", padding: "3px 3px 3px 3px" }}>
            99+</span> */}
        </div>
      </div>
    </li>
  );
};

const mapStateToProps = (state) => ({
    currentWorkspace: state.workspace.currentWorkspace,
    messagesForwardArray: state.platform.messageForwardArray,
    // workspace: state.workspace.currentWorkspace
  });

export default connect(mapStateToProps, {
    setLeftPanel,
    setMessageForward,
    setMessagesForwardArray,
    setAllMessages,
    setContentTypeAndValue,
    setRightPanel,
    setChannels,
    setCurrentChannel,
    setCurrentWorkspace,
    setParticipants,
    resetData,
    setWorkspaces,
    setTypedTextMessage,
  })(RoomItem);
  
