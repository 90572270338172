import React from "react";
import {
  Dropdown,
  Button,
  DropdownButton,
  Badge,
  Form,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import firebase from "firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faAngleDown, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { connect } from "react-redux";
import {
  setScheduleTitle,
  setScheduleDurationHours,
  setScheduleDurationMinutes,
  setSchedulePlace,
  setScheduleAgenda,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskEveryone,
  resetData,
} from "../../../actions/index";
import TaskCommon from "./TaskCommonForSchedule";
import { toast } from "react-toastify";
//import { object } from "prop-types";
toast.configure();

class AddSchedule extends React.Component {
  constructor() {
    super();
    this.state = {
      deadline: new Date(Date.now() + 10 * 60000),
      addAll: false,
      errorObj: { title: "" },
      allFolders: [],
    };
  }

  componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;
    let allFolders = [];
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/folders`
      );
    // const query = firebase.firestore().collection("folders");
    query.onSnapshot((snapshot) => {
      let changes = snapshot.docChanges();
      console.log(changes);
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.exists) {
            let data = change.doc.data();
            let json = { ...data, id: change.doc.id };
            allFolders.unshift(json);
          }
        }
      });
      this.setState({ allFolders });
    });
  }
  // loading saved data from firestore

  /*async componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;
    let savedSchedules = [];
    let savedSchedulesData = [];

    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
      )
      .orderBy("timestamp", "desc")
      .limit(5);

    await query.onSnapshot((snapshot) => {
      let changes = snapshot.docChanges();

      changes.forEach((change) => {
        if (change.type === "added") {
          let data = change.doc.data();
          let json = {};
          if (data.type === 1) {
            //   saved is of the type schedule (1)
            json = { ...data, id: change.doc.id };
          }
          if (Object.keys(json).length !== 0) {
            //   if json is empty then don't push otherwise push
            savedSchedules.unshift(json);
          }
        } else if (change.type === "removed") {
          console.log("removed", change.doc.data());
          console.log("removed", change.doc.id);
          console.log("removed", savedSchedules);
          savedSchedules = savedSchedules.filter(
            (savedSchedule) => savedSchedule.id !== change.doc.id
          );
        } else if (change.type === "modified") {
          if (change.doc.data().type === 1) {
            let index = savedSchedules.findIndex(
              (savedSchedule) => savedSchedule.id === change.doc.id
            );
            if (index !== -1) {
              savedSchedules.splice(index, 1, {
                ...change.doc.data(),
                id: change.doc.id,
              });
            }
          }
        }
      });
      console.log(savedSchedules, "saved schedules");
      handleSchedulesData(savedSchedules);
    });
    const handleSchedulesData = (savedSchedules) => {
      let savedSchedulesData = [];
      savedSchedules.forEach((savedSchedule) => {
        firebase
          .firestore()
          .collection(`schedule`)
          .doc(savedSchedule.contentId)
          .get()
          .then((doc) => {
            console.log(doc.data());
            let data = doc.data();
            let json = { ...data, id: doc.id };
            savedSchedulesData.push(json);
          })
          .then(() => {
            // console.log(savedPollsData);
            this.setState({ savedSchedules, savedSchedulesData });
          });
      });
      if (savedSchedules.length === 0) {
        this.setState({ savedSchedules });
      }
    };
  }*/

  handleChange = (e) => {
    const { currentChannel, currentUser } = this.props;

    if (e.target.name === "scheduleTitle") {
      this.props.setScheduleTitle(e.target.value);
    }

    if (e.target.name === "folder") {
      this.props.setTaskFolder(e.target.value);
    }

    if (e.target.name === "durationHours") {
      this.props.setScheduleDurationHours(e.target.value);
    }

    if (e.target.name === "durationMinutes") {
      this.props.setScheduleDurationMinutes(e.target.value);
    }

    if (e.target.name === "place") {
      this.props.setSchedulePlace(e.target.value);
    }

    if (e.target.name === "agenda") {
      this.props.setScheduleAgenda(e.target.value);
    }

    if (e.target.name === "channelUsers[]") {
      let prev = this.props.channelUsers.includes(e.target.value)
        ? this.props.channelUsers.filter((a) => a != e.target.value)
        : [...this.props.channelUsers, e.target.value];
      if (
        Object.keys(currentChannel.users).filter(
          (item) => item != currentUser.uid
        ).length === prev.length
      ) {
        this.setState({ addAll: true });
      } else {
        this.setState({ addAll: false });
      }
      this.props.setTaskChannelUsers(prev);
    }
  };
  onChange = (deadline) => this.props.setTaskDeadline(deadline);

  getName = (key) => {
    let name = "";
    firebase
      .database()
      .ref("users")
      .child(key)
      .on("value", (snap) => {
        if (snap.val()) {
          name = snap.val().name
            ? snap.val().name
            : `${snap.val().firstName} ${snap.val().lastName}`;
        }
      });
    return name;
  };

  getFolderName = (key) => {
    let folder_name = "";
    firebase
      .database()
      .ref("folders")
      .child(key)
      .on("value", (snap) => {
        folder_name = snap.val() && snap.val().folder_name;
      });
    return folder_name;
  };

  selectAll = () => {
    const { currentChannel, currentUser } = this.props;

    if (this.state.addAll) {
      this.setState({ addAll: false });
    } else {
      this.setState({ addAll: true });
      this.props.setTaskChannelUsers(
        Object.keys(currentChannel.users).filter(
          (item) => item != currentUser.uid
        )
      );
    }
  };

  render() {
    const {
      type,
      scheduleTitle,
      options,
      durationHours,
      durationMinutes,
      place,
      agenda,
      channelUsers,
      deadline,
      folder,
      everyone,
      currentChannel,
      currentUser,
      currentWorkspace,
      allParticipants,
      editedSchedule,
      validateData,
      saveTask,
      addTask,
      error,
    } = this.props;
    const { errorObj, allFolders } = this.state;
    const validateDataOfField = (e) => {
      let { errorObj } = this.state;
      if (e.target.name === "scheduleTitle") {
        if (e.target.value.trim().length < 1) {
          errorObj.title = "Title cannot be empty.";
        } else {
          errorObj.title = "";
        }
      }
      this.setState({ errorObj });
    };
    //console.log(currentChannel, "currentChannel");
    // const {errorObj  } = this.state;
    /* const validateData = () => {
      if (scheduleTitle.length == 0) {
        this.setState({ error: "Schedule title cannot be empty!\n" });
        return null;
      }

      if (moment(deadline).diff(moment(Date.now()), "minutes") < 9) {
        this.setState({
          error: "Deadline of task should be atleast 10 minutes.\n",
        });
        return null;
      }

      // if (folder.length == 0) {
      //     this.setState({error: "Please associate a folder to task.\n"});
      //     return null;
      // }

      if (!everyone && channelUsers.length < 1) {
        this.setState({
          error: "Please assign task to atleast one participant.\n",
        });
        return null;
      }

      this.setState({ error: "" });
      return {
        type,
        scheduleTitle,
        options,
        durationHours,
        durationMinutes,
        place,
        agenda,
        channelUsers,
        deadline,
        folder,
        everyone,
      };
    };

    const addTask = (data) => {
      const { resetData } = this.props;
      const { editedSchedule } = this.state;
      console.log(data, "data of schedule");
      let messageData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        user: {
          id: currentUser.uid,
          name: currentUser.displayName,
          avatar: currentUser.photoURL,
        },
        type: parseInt(data["type"]),
        createdBy: currentUser.uid,
        assignedTo: data["channelUsers"],
        all: data["everyone"],
      };

      let readData = {};
      readData[currentUser.uid] = { read: true, readtime: Date.now() };
      data["channelUsers"].forEach((user) => {
        readData[user] = { read: false };
      });

      let taskData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: currentUser.uid,
        // folderId: data["folder"],
        place: data["place"],
        scheduleTitle: data["scheduleTitle"],
        durationHours: data["durationHours"],
        durationMinutes: data["durationMinutes"],
        deadline: moment(data["deadline"]).valueOf(),
        agenda: data["agenda"],
        all: data["everyone"],
        read: readData,
        options: ["Yes", "No", "Maybe"],
        response: [],
        assignedTo: data["channelUsers"],
      };
      const task = firebase.firestore().collection(`schedule`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

      const dbSaved = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );

      if (Object.keys(editedSchedule).length > 0) {
        let savedScheduleDoc = savedSchedules.find(
          (schedule) => schedule.contentId === editedSchedule.id
        );
        // console.log(savedPollDoc.id);
        task
          .doc(editedSchedule.id)
          .update(taskData)
          .then(() => {
            db.add({ ...messageData, contentId: editedSchedule.id })
              .then((docRef) => {
                dbUpdate
                  .doc(`${currentChannel.roomId}`)
                  .update({
                    totalMessages: firebase.firestore.FieldValue.increment(1),
                    lastMessage: {
                      ...messageData,
                      contentId: editedSchedule.id,
                      uid: docRef.id,
                    },
                  })
                  .then(() => {
                    dbSaved.doc(savedScheduleDoc.id).delete();
                    toast("Schedule shared", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    resetData();

                    //this.setState({ editedPoll: {} });
                  })
                  .catch((err) => console.error(err));
              })
              .catch((err) => console.error(err));
          })
          .catch((error) => console.error(error));
      } else {
        task
          .add(taskData)
          .then((taskRef) => {
            db.add({ ...messageData, contentId: taskRef.id })
              .then((docRef) => {
                dbUpdate
                  .doc(`${currentChannel.roomId}`)
                  .update({
                    totalMessages: firebase.firestore.FieldValue.increment(1),
                    lastMessage: {
                      ...messageData,
                      contentId: taskRef.id,
                      uid: docRef.id,
                    },
                  })
                  .then(() => {
                    toast("Schedule shared", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    resetData();
                    //this.setState({ editedPoll: {} });
                  })
                  .catch((err) => console.error(err));
              })
              .catch((err) => console.error(err));
          })
          .catch((error) => console.error(error));
      }
    };

    // save task
    const saveTask = (data) => {
      const {
        resetData,
        setScheduleTitle,
        setTaskChannelUsers,
        setTaskEveryone,
        setTaskDeadline,
      } = this.props;

      const { editedSchedule } = this.state;
      // console.log(editedPoll);
      // console.log(Object.keys(editedPoll).length);

      let messageData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        user: {
          id: currentUser.uid,
          name: currentUser.displayName,
          avatar: currentUser.photoURL,
        },
        type: parseInt(data["type"]),
        createdBy: currentUser.uid,
        assignedTo: data["channelUsers"],
        all: data["everyone"],
      };

      let readData = {};
      readData[currentUser.uid] = { read: true, readtime: Date.now() };
      data["channelUsers"].forEach((user) => {
        readData[user] = { read: false };
      });

      let taskData = {
        timestamp: Date.now(),
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: currentUser.uid,
        // folderId: data["folder"],
        place: data["place"],
        scheduleTitle: data["scheduleTitle"],
        durationHours: data["durationHours"],
        durationMinutes: data["durationMinutes"],
        deadline: moment(data["deadline"]).valueOf(),
        agenda: data["agenda"],
        all: data["everyone"],
        read: readData,
        options: ["Yes", "No", "Maybe"],
        response: [],
        assignedTo: data["channelUsers"],
      };

      const task = firebase.firestore().collection(`schedule`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      if (Object.keys(editedSchedule).length > 0) {
        let savedScheduleDoc = savedSchedules.find(
          (schedule) => schedule.contentId === editedSchedule.id
        );
        console.log(savedScheduleDoc, "savededited");
        task
          .doc(editedSchedule.id)
          .update(taskData)
          .then(() => {
            db.doc(savedScheduleDoc.id)
              .update({ ...messageData, contentId: editedSchedule.id })
              .then(() => {
                //   setPollOptions(['Yes','No','Maybe']);
                //setTaskDeadline(new Date(Date.now() + 10 * 600000));
                //setTaskChannelUsers([]);
                //setScheduleTitle("");
                //setTaskEveryone(false);
                this.setState({ editedSchedule: {} }, () =>
                  toast("Schedule updated and saved", {
                    position: toast.POSITION.BOTTOM_CENTER,
                  })
                );
                resetData();
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      } else {
        task
          .add(taskData)
          .then((taskRef) => {
            db.add({ ...messageData, contentId: taskRef.id })
              .then(() => {
                // alert('Schedule Saved')

                // setPollOptions(['Yes','No','Maybe']);
                //  setTaskDeadline(new Date(Date.now() + 10 * 600000));
                //  setTaskChannelUsers([]);
                // setScheduleTitle("");
                // setTaskEveryone(false);
                //this.setState({ editedSchedule: {} });
                toast("Schedule saved", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
                resetData();
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      }
    };

    //   crud for saved schedule

    const shareSavedTask = (scheduleId) => {
      // console.log(savedPolls);
      // console.log(pollId);
      const { resetData } = this.props;
      let messageData = savedSchedules.find(
        (schedule) => schedule.contentId === scheduleId
      );
      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find((User) => User.uid === user)
          )) ||
        messageData.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants are no longer part of this group, please edit to reassign participants.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }

      const task = firebase.firestore().collection(`schedule`);
      task
        .doc(messageData.contentId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            if (doc.data().deadline < Date.now()) {
              toast.warning("Deadline is over, please update to share.", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
              editSavedTask(scheduleId);
            } else {
              messageData.timestamp = Date.now();
              // console.log(poll);
              const dbMsg = firebase
                .firestore()
                .collection(
                  `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
                );
              const db = firebase
                .firestore()
                .collection(
                  `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
                );
              const dbUpdate = firebase
                .firestore()
                .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

              dbMsg
                .add({ ...messageData })
                .then((docRef) => {
                  dbUpdate
                    .doc(`${currentChannel.roomId}`)
                    .update({
                      totalMessages: firebase.firestore.FieldValue.increment(1),
                      lastMessage: {
                        ...messageData,
                        contentId: messageData.contentId,
                        uid: docRef.id,
                      },
                    })
                    .then(() => {
                      db.doc(messageData.id).delete();
                      toast("Schedule shared", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      });
                      resetData();
                    })
                    .catch((err) => console.error(err));
                })
                .catch((err) => console.error(err));
            }
          }
        });
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
        className="text-muted"
      >
        <FontAwesomeIcon icon={faAngleDown} />
      </a>
    ));

    const editSavedTask = (scheduleId) => {
      const { resetData } = this.props;
      let taskData = savedSchedulesData.find(
        (schedule) => schedule.id === scheduleId
      );
      console.log(taskData, "taskdata edit");
      //console.log(savedPolls);
      console.log(taskData.deadline);
      //   setPollOptions(['Yes','No','Maybe']);
      this.props.setScheduleTitle(taskData.scheduleTitle);

      if (
        (taskData.all === false &&
          taskData.assignedTo.every((user) =>
            allParticipants.find((User) => User.uid === user)
          )) ||
        taskData.all === true
      ) {
        this.props.setTaskChannelUsers(taskData.assignedTo);
      } else {
        let assignedTo = taskData.assignedTo.filter((user) =>
          allParticipants.find((User) => User.uid === user)
        );
        console.log(assignedTo);
        this.props.setTaskChannelUsers(assignedTo);
      }

      this.props.setTaskEveryone(taskData.all);
      this.props.setTaskDeadline(new Date(taskData.deadline));
      this.setState({ editedSchedule: taskData });
    };
    const deleteSavedTask = (scheduleId) => {
      let messageData = savedSchedules.find(
        (schedule) => schedule.contentId === scheduleId
      );
      let messageId = messageData.id;
      const dbSchedules = firebase.firestore().collection(`schedule`);
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      db.doc(messageId)
        .delete()
        .then(() => {
          dbSchedules.doc(scheduleId).delete();
        })
        .catch((err) => console.log(err));
    };
*/
    return (
      <React.Fragment>
        <div className="pb-2 rounded-lg p-2 m-2 bg-card-theme text-theme shadow-sm">
          <Form>
            <p className="mb-1">Schedule title</p>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Add title"
                name="scheduleTitle"
                onChange={this.handleChange}
                required={true}
                autoFocus={true}
                onKeyUp={(e) => validateDataOfField(e)}
                onBlur={(e) => validateDataOfField(e)}
                value={scheduleTitle}
              />
            </InputGroup>
            {errorObj.title ? (
              <p className=" mb-1 small text-danger">{errorObj.title}</p>
            ) : (
              ""
            )}

            {/* <p className="mb-1">Date & Time</p>
                        <DateTimePicker
                            className="mb-3 d-block bg-white"
                            onChange={this.onChange}
                            value={deadline}
                        />

            */}

            <p className="mb-1">Duration (Optional)</p>
            <InputGroup className="mb-3">
              <InputGroup.Prepend>
                <InputGroup.Text>Hours & Minutes</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                type="number"
                min="0"
                max="23"
                step="1"
                value={durationHours}
                name="durationHours"
                onChange={this.handleChange}
                required={true}
              />
              <FormControl
                type="number"
                min="0"
                max="60"
                step="5"
                value={durationMinutes}
                name="durationMinutes"
                onChange={this.handleChange}
                required={true}
              />
            </InputGroup>

            <p className="mb-1">Place (Optional)</p>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Place (Optional)"
                name="place"
                onChange={this.handleChange}
                required={true}
                value={place}
                size="sm"
              />
            </InputGroup>

            <p className="mb-1">Agenda (Optional)</p>
            <InputGroup className="mb-3">
              <TextareaAutosize
                className="w-100 p-2 rounded"
                style={{ borderColor: "#CDCDCD", minWidth: "300px" }}
                name="agenda"
                value={agenda}
                onChange={this.handleChange}
                minRows={3}
                maxRows={20}
                placeholder="Agenda"
              />
            </InputGroup>
            <p className="mb-1">Associate folder</p>
            <InputGroup className="mb-3">
              <select
                name="folder"
                class="custom-select custom-select-sm"
                onChange={this.handleChange}
              >
                <option
                  selected={folder === "" || folder === null ? true : false}
                  value=""
                >
                  Uncategorized
                </option>
                {allFolders.map((item) => (
                  <option
                    value={item.id}
                    selected={folder === item.id ? true : false}
                  >
                    {item.folderName}
                  </option>
                ))}
              </select>
            </InputGroup>

            {/* <Form.Group controlId="formBasicCheckbox">
                            <div>
                                <p className="mb-1">Assign users</p>
                                <Form.Group>
                                    <Form.Check
                                    required
                                    label="Everyone who joins"
                                    value="everyone"
                                    checked={everyone}
                                    onChange={() => this.props.setTaskEveryone(!everyone)}
                                    />
                                </Form.Group>
                                {!everyone && <Form.Check style={{ fontSize: '0.6rem' }}
                                    type="switch" 
                                    id="addAll"
                                    value="addAll"
                                    name="addAll"
                                    onChange={this.selectAll}
                                    label="Select all"
                                    checked={this.state.addAll}
                                />}
                            </div>
                            {!everyone && <>{currentChannel.users && Object.keys(currentChannel.users).length > 0
                                ? Object.keys(currentChannel.users)
                                    .map(item => item !== currentUser.uid
                                        && <Form.Check style={{ fontSize: '0.6rem' }}
                                            type="switch" 
                                            id={item} 
                                            value={item}
                                            name="channelUsers[]"
                                            checked={channelUsers.includes(item)}
                                            onChange={this.handleChange}
                                            label={this.getName(item)}
                                        />
                                    )
                                : null}
                            </>}
                        </Form.Group> */}
            <TaskCommon
              allParticipants={allParticipants}
              editedTask={Object.keys(editedSchedule).length > 0 ? true : false}
            />


              {error ? <p className="small text-danger">{error}</p> : ""}

            <div className="d-flex">
              {
                this.props.editingPublished && !this.props.editedScheduleHasResponses ?
                  <Button
                    onClick={() =>
                      validateData() &&
                      addTask(validateData(), currentChannel, currentUser)
                    }
                    variant="primary"
                    style={{ width: "100px", marginRight: "5px" }}
                    size="sm"
                    disabled={this.props.scheduleMessageData?.closedByAdmin}
                  >
                    <small>
                    {
                        this.props.scheduleMessageData?.closedByAdmin ?
                          <FontAwesomeIcon icon={faExclamationTriangle} color="yellow"></FontAwesomeIcon>
                          : null
                      }Save changes</small>
                  </Button>
                  :
                  <Button
                    onClick={() =>
                      validateData() &&
                      addTask(validateData(), currentChannel, currentUser)
                    }
                    variant="primary"
                    style={{ width: "100px", marginRight: "5px" }}
                    size="sm"
                    disabled={this.props.editingPublished && this.props.editedScheduleHasResponses}
                  >
                    <small>
                    {
                        this.props.editingPublished && this.props.editedScheduleHasResponses  ?
                          <FontAwesomeIcon icon={faExclamationTriangle} color="yellow"></FontAwesomeIcon>
                          : null
                      }
                      Share now</small>
                  </Button>
              }


              <Button
                onClick={() =>
                  validateData() &&
                  saveTask(validateData(), currentChannel, currentUser)
                }
                variant="success"
                style={{ width: "100px", marginLeft: "5px" }}
                size="sm"
              >
                <small>Save for later</small>
              </Button>

            </div>
            {
              this.props.editingPublished && this.props.editedScheduleHasResponses || this.props.scheduleMessageData?.closedByAdmin ?
                <div className="text-warning mt-2">It can't be edited as it already has responses. Either close it or delete it.</div>
                :
                null
            }

            <p className="my-2 p-0 text-danger">{this.state.error}</p>
          </Form>
          
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,

  type: state.schedule.type,
  scheduleTitle: state.schedule.scheduleTitle,
  options: state.schedule.options,
  durationHours: state.schedule.durationHours,
  durationMinutes: state.schedule.durationMinutes,
  place: state.schedule.place,
  agenda: state.schedule.agenda,

  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  everyone: state.task.everyone,
});

export default connect(mapStateToProps, {
  setScheduleTitle,
  setScheduleDurationHours,
  setScheduleDurationMinutes,
  setSchedulePlace,
  setScheduleAgenda,

  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskEveryone,

  // addTask,
  // saveTask,
  resetData,
})(AddSchedule);
