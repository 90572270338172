import React from "react";
import firebase, { firestore } from "firebase";
import TextareaAutosize from "react-textarea-autosize";
import AvatarEditor from "react-avatar-editor";
import ReadMore from "../Middle/ReadMore";
import IsAdmin from "../IsAdmin";
import Viewer from "react-viewer";

import {
  Image,
  Button,
  ListGroup,
  Form,
  Alert,
  Modal,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faSignOutAlt,
  faCamera,
  faCheck,
  faPencilAlt,
  faTimes,
  faAngleDown,
  faEdit,
  faArchive,
  faClone,
} from "@fortawesome/free-solid-svg-icons";
import "./Right.css";

import { connect } from "react-redux";
import {
  setRoomDetails,
  setContentType,
  setContentTypeAndValue,
  setCurrentChannel,
  setRightPanel,
  setParticipants,
  setAllMessages,
  setChannels,
  setWorkspaces,
} from "../../actions/index";
import { setCurrentWorkspace } from "../../actions/workspace";
import CreatedBy from "./UserNameAvatar";
import Participants from "./Participants";
import { postData } from "../../utils/index";
import {
  API_BASE_URL,
  ROOM_LEAVE,
  USER_ROOM_ARCHIVE,
  USER_ROOM_UNARCHIVE,
  ROOM_CONFIG_SET,
  ROOM_DUPLICATE,
} from "../../config/index";
import { ARCHIVE, ACTIVE } from "../../actions/types";
import _ from "lodash";
import RoomDetailsIcon from "../../assets/room-details.png";
import axios from "axios";
import { toast } from "react-toastify";
toast.configure();

const getName = (key) => {
  let name = "";
  firebase
    .database()
    .ref("users")
    .child(key)
    .on("value", (snap) => {
      if (snap.val()) {
        name = snap.val().name
          ? snap.val().name
          : `${snap.val().firstName} ${snap.val().lastName}`;
      }
    });
  return name;
};

const getAvatar = (key) => {
  let avatar = "";
  firebase
    .database()
    .ref("users")
    .child(key)
    .on("value", (snap) => {
      avatar = snap.val() ? snap.val().avatar : "";
    });
  return avatar;
};

const getVideoServer = (key) => {
  switch (key) {
    case "ap-south-1.igesia.com":
      return "South Asia";
    case "us-east-2.igesia.com":
      return "North America";
    case "sa-east-1.igesia.com":
      return "South America";
    case "eu-central-1.igesia.com":
      return "Europe";
    case "sa-east-1.igesia.co":
      return "South America (Legacy)";

    case "ap-south-1":
      return "South Asia";
    case "us-east-2":
      return "North America";
    case "sa-east-1":
      return "South America";
    case "eu-central-1":
      return "Europe";
    case "sa-east-1":
      return "South America (Legacy)";
  }
};

class RoomDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImageRight: "",
      avatarRight: null,
      metadataRight: {},
      cropImageRight: false,
      croppedImageRight: "",
      uploadedAvatarRight: "",
      changingAvatarRight: false,
      storageRef: firebase.storage().ref(),
      channelRef: firebase.database().ref("channels"),

      roomName: "",
      editRoomName: false,
      roomNameError: "",

      roomDescription: "",
      editRoomDescription: false,
      roomDescriptionError: "",

      visibleRoomIcon: false,
      video_server_location: "",
      editVideoServer: false,
      editVideoMeetingType: false,
      addCustomVideoUrl: false,
      videoMeetingType: 0,
      videoMeetingURL: null,
      videoMeetingURLError: "",

      editAdminOnlyChat: false,
      editSpecialOlympics: false,
      adminOnlyChat: false,
      specialOlympics: false,

      createdBy: "",
      loading: true,

      showDuplicateModal: false,
      selectedRoom: null,
      duplicateRoomName: "",

      savedPolls: [],
      publishedPolls: [],

      savedQuizes: [],
      publishedQuizes: [],

      savedReadings: [],
      publishedReadings: [],

      savedAssignments: [],
      publishedAssignments: [],

      savedSchedules: [],
      publishedSchedules: [],

      chartData: [],
      allParticipants: [],

      createdByUserDetails: null,

      videoModal: false,
    };
  }

  componentDidMount() {
    //console.log(this.props,"my room did mount");
    const { currentChannel } = this.props;
    if (currentChannel) {
      const {
        roomName,
        description,
        roomPic,
        roomType,
        videoServerId,
        createdBy,
        roomId,
        videoMeetingType,
        videoMeetingURL,
      } = currentChannel;

      this.setState({
        roomPic,
        roomName,
        roomDescription: description,
        video_server_location: videoServerId,
        videoMeetingType: videoMeetingType ? videoMeetingType : 0,
        videoMeetingURL: videoMeetingURL ? videoMeetingURL : null,
        adminOnlyChat: this.props.currentChannel?.roomConfig?.admin_only_chat,
        specialOlympics: this.props.currentChannel?.roomConfig
          ?.special_olympics_app_allowed,
        createdBy,
        loading: false,
      });

      firebase
        .firestore()
        .collection("users")
        .doc(`${createdBy}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.setState({
              createdByUserDetails: {
                displayName: doc.data().displayName,
                avatar: doc.data().avatar,
              },
            });
          } else {
          }
        })
        .catch((error) => console.error(error));
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { currentChannel } = this.props;
    if (prevProps.currentChannel?.id !== currentChannel.id) {
      const {
        roomName,
        description,
        roomPic,
        roomType,
        videoServerId,
        createdBy,
        roomId,
        videoMeetingType,
      } = currentChannel;

      //console.log(prevProps.currentChannel,"my room update")
      //console.log(this.props.currentChannel,"my room udate")
      this.setState({
        roomPic,
        roomName,
        roomDescription: description,
        video_server_location: videoServerId,
        videoMeetingType: videoMeetingType ? videoMeetingType : 0,
        adminOnlyChat: this.props.currentChannel?.roomConfig?.admin_only_chat,
        specialOlympics: this.props.currentChannel?.roomConfig
          ?.special_olympics_app_allowed,
        createdBy,
        loading: false,
      });
      firebase
        .firestore()
        .collection("users")
        .doc(`${createdBy}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.setState({
              createdByUserDetails: {
                displayName: doc.data().displayName,
                avatar: doc.data().avatar,
              },
            });
          } else {
          }
        })
        .catch((error) => console.error(error));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentChannel } = this.props;
    if (
      currentChannel &&
      prevProps?.currentChannel?.id !== currentChannel?.id
    ) {
      const {
        roomName,
        description,
        roomPic,
        roomType,
        videoServerId,
        createdBy,
        roomId,
        videoMeetingType,
      } = currentChannel;

      //console.log(prevProps.currentChannel,"my room update")
      //console.log(this.props.currentChannel,"my room udate")
      this.setState({
        roomPic,
        roomName,
        roomDescription: description,
        video_server_location: videoServerId,
        videoMeetingType: videoMeetingType ? videoMeetingType : 0,
        adminOnlyChat: this.props.currentChannel?.roomConfig?.admin_only_chat,
        specialOlympics: this.props.currentChannel?.roomConfig
          ?.special_olympics_app_allowed,
        createdBy,
        loading: false,
      });

      firebase
        .firestore()
        .collection("users")
        .doc(`${createdBy}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.setState({
              createdByUserDetails: {
                displayName: doc.data().displayName,
                avatar: doc.data().avatar,
              },
            });
          } else {
          }
        })
        .catch((error) => console.error(error));
    }
  }

  uploadCroppedImage = () => {
    const { storageRef, avatarRight, metadataRight } = this.state;

    const {
      currentChannel,
      currentWorkspace,
      setCurrentChannel,
      allChannels,
      setChannels,
    } = this.props;

    console.log(avatarRight);

    let prevAllChannels = allChannels;

    storageRef
      .child(
        `avatars/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}`
      )
      .put(avatarRight, metadataRight)
      .then((snap) => {
        snap.ref.getDownloadURL().then((downloadURL) => {
          this.setState(
            {
              uploadedAvatarRight: downloadURL,
            },
            () => {
              firebase
                .firestore()
                .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
                .doc(`${currentChannel.roomId}`)
                .update({ roomPic: this.state.uploadedAvatarRight })
                .then(() => {
                  console.log("Room avatar updated");

                  // logic to put the updates in redux store so that every panel remains in sync

                  firebase
                    .firestore()
                    .collection(
                      `workspaces/${currentWorkspace.workspaceId}/rooms`
                    )
                    .doc(`${currentChannel.roomId}`)
                    .get()
                    .then((doc) => {
                      if (doc.exists) {
                        console.log("here");
                        setCurrentChannel({ roomId: doc.id, ...doc.data() });
                        const indexOfCurrentRoom = _.findIndex(
                          prevAllChannels,
                          { roomId: currentChannel.roomId }
                        );
                        prevAllChannels.splice(indexOfCurrentRoom, 1, {
                          roomId: doc.id,
                          ...doc.data(),
                        });
                        prevAllChannels = _.cloneDeep(prevAllChannels);
                        setChannels(prevAllChannels);
                      }
                    });

                  this.setState({ changingAvatarRight: false });
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          );
        });
      });
  };

  handleChangeFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (file) {
      let metadataRight = {
        contentType: file.type,
      };

      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        this.setState({
          previewImageRight: reader.result,
          metadataRight,
          cropImageRight: true,
        });
      });
    }
  };

  handleCropImage = () => {
    if (this.avatarEditorRight) {
      this.avatarEditorRight.getImageScaledToCanvas().toBlob((blob) => {
        let imageUrl = URL.createObjectURL(blob);
        this.setState(
          {
            croppedImageRight: imageUrl,
            avatarRight: blob,
            cropImageRight: false,
          },
          () => {
            this.uploadCroppedImage();
          }
        );
      });
    }
  };

  handleRoomNameEdit = () => {
    const {
      currentChannel,
      currentWorkspace,
      setCurrentChannel,
      allChannels,
      setChannels,
    } = this.props;
    const { roomName } = this.state;
    //console.log(roomName.replace(/\s/g, "").length,"resssssss")
    if (roomName === " " || roomName.length === 0) {
      this.setState({
        roomNameError: "Please enter room name.",
      });
      return;
    }
    if (roomName.replace(/\s/g, "").length === 0) {
      this.setState({
        roomNameError: "Please enter valid room name.",
      });
      return;
    }

    let prevAllChannels = allChannels;

    firebase
      .firestore()
      .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
      .doc(`${currentChannel.roomId}`)
      .update({ roomName: this.state.roomName })
      .then(() => {
        console.log("Room name updated");
        firebase
          .firestore()
          .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
          .doc(`${currentChannel.roomId}`)
          .get()
          .then((doc) => {
            if (doc.exists) {
              // console.log({roomId: doc.id, ...doc.data()},"updated room details")
              setCurrentChannel({ roomId: doc.id, ...doc.data() });
              const indexOfCurrentRoom = _.findIndex(prevAllChannels, {
                roomId: currentChannel.roomId,
              });
              prevAllChannels.splice(indexOfCurrentRoom, 1, {
                roomId: doc.id,
                ...doc.data(),
              });
              prevAllChannels = _.cloneDeep(prevAllChannels);
              setChannels(prevAllChannels);
            }
          });

        this.setState({ editRoomName: false });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleRoomDescriptionEdit = () => {
    const { currentChannel, currentWorkspace } = this.props;
    const { roomDescription } = this.state;

    if (roomDescription === "" || roomDescription.length === 0) {
      this.setState({
        roomDescriptionError: "Please enter room description.",
      });
      return;
    }

    firebase
      .firestore()
      .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
      .doc(`${currentChannel.roomId}`)
      .update({ description: this.state.roomDescription })
      .then(() => {
        console.log("Room description updated");

        this.setState({ editRoomDescription: false });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleVideoServerEdit = () => {
    const {
      currentChannel,
      currentWorkspace,
      setCurrentChannel,
      allChannels,
    } = this.props;
    console.log("in redux before", currentChannel.videoServerId);
    console.log("in state", this.state.video_server_location);

    let prevAllChannels = allChannels;
    firebase
      .firestore()
      .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
      .doc(`${currentChannel.roomId}`)
      .update({ videoServerId: this.state.video_server_location })
      .then(() => {
        console.log("Room video server updated");
        firebase
          .firestore()
          .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
          .doc(`${currentChannel.roomId}`)
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log(
                { roomId: doc.id, ...doc.data() },
                "updated room details"
              );
              setCurrentChannel({ roomId: doc.id, ...doc.data() });
              const indexOfCurrentRoom = _.findIndex(prevAllChannels, {
                roomId: currentChannel.roomId,
              });
              prevAllChannels.splice(indexOfCurrentRoom, 1, {
                roomId: doc.id,
                ...doc.data(),
              });
              prevAllChannels = _.cloneDeep(prevAllChannels);
              setChannels(prevAllChannels);
            }
          });
        this.setState({ editVideoServer: false });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleVideoMeetingTypeEdit = () => {
    const {
      currentChannel,
      currentWorkspace,
      setCurrentChannel,
      allChannels,
    } = this.props;
    console.log("meeting type in state", this.state.videoMeetingType);
    let prevAllChannels = allChannels;
    firebase
      .firestore()
      .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
      .doc(`${currentChannel.roomId}`)
      .update({ videoMeetingType: this.state.videoMeetingType })
      .then(() => {
        console.log("Video meeting type updated");
        firebase
          .firestore()
          .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
          .doc(`${currentChannel.roomId}`)
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log(
                { roomId: doc.id, ...doc.data() },
                "updated room details"
              );
              setCurrentChannel({ roomId: doc.id, ...doc.data() });
              const indexOfCurrentRoom = _.findIndex(prevAllChannels, {
                roomId: currentChannel.roomId,
              });
              prevAllChannels.splice(indexOfCurrentRoom, 1, {
                roomId: doc.id,
                ...doc.data(),
              });
              prevAllChannels = _.cloneDeep(prevAllChannels);
              setChannels(prevAllChannels);
            }
          });
        this.setState({ editVideoMeetingType: false, addCustomVideoUrl: true });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleCustomVideoURL = () => {
    const {
      currentChannel,
      currentWorkspace,
      setCurrentChannel,
      allChannels,
    } = this.props;
    let prevAllChannels = allChannels;
    const { videoMeetingURL, videoMeetingURLError } = this.state;
    if (
      videoMeetingURL.indexOf("http://") == 0 ||
      videoMeetingURL.indexOf("https://") == 0
    ) {
      firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
        .doc(`${currentChannel.roomId}`)
        .update({ videoMeetingURL: this.state.videoMeetingURL })
        .then(() => {
          firebase
            .firestore()
            .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
            .doc(`${currentChannel.roomId}`)
            .get()
            .then((doc) => {
              if (doc.exists) {
                setCurrentChannel({ roomId: doc.id, ...doc.data() });
                const indexOfCurrentRoom = _.findIndex(prevAllChannels, {
                  roomId: currentChannel.roomId,
                });
                prevAllChannels.splice(indexOfCurrentRoom, 1, {
                  roomId: doc.id,
                  ...doc.data(),
                });
                prevAllChannels = _.cloneDeep(prevAllChannels);
                setChannels(prevAllChannels);
              }
            });
          this.setState({ addCustomVideoUrl: false, videoMeetingURLError: "" });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      this.setState({
        videoMeetingURLError:
          "Add a link which starts with http:// or https://",
      });
      return;
    }
  };

  handleAdminOnlyChatEdit = () => {
    const {
      currentChannel,
      currentWorkspace,
      setCurrentChannel,
      allChannels,
    } = this.props;
    let prevAllChannels = allChannels;

    axios
      .post(`${API_BASE_URL}${ROOM_CONFIG_SET}`, {
        workspace_id: currentWorkspace?.workspaceId,
        room_id: currentChannel?.roomId,
        user_id: this.props.currentUser.uid,
        admin_only_chat: this.state.adminOnlyChat,
      })
      .then(() => {
        this.setState({ editAdminOnlyChat: false });
        firebase
          .firestore()
          .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
          .doc(`${currentChannel.roomId}`)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setCurrentChannel({ roomId: doc.id, ...doc.data() });
            }
          });
      });
  };

  handleSpecialOlympics = () => {
    const {
      currentChannel,
      currentWorkspace,
      setCurrentChannel,
      allChannels,
    } = this.props;
    let prevAllChannels = allChannels;

    axios
      .post(`${API_BASE_URL}${ROOM_CONFIG_SET}`, {
        workspace_id: currentWorkspace?.workspaceId,
        room_id: currentChannel?.roomId,
        user_id: this.props.currentUser.uid,
        special_olympics_app_allowed: this.state.specialOlympics,
      })
      .then(() => {
        this.setState({ editSpecialOlympics: false });
        firebase
          .firestore()
          .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`)
          .doc(`${currentChannel.roomId}`)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setCurrentChannel({ roomId: doc.id, ...doc.data() });
            }
          });
      });
  };

  handleRemoveParticipant = (participant_id) => {
    const { currentChannel } = this.props;
    console.log(participant_id, currentChannel, "my id");
    this.state.channelRef
      .child(currentChannel.id)
      .child(`users/${participant_id}`)
      .remove();
  };

  handleRoomAdmin = (participant_id, value) => {
    const { currentChannel } = this.props;
    let updates = {};
    updates["admin"] = value;

    this.state.channelRef
      .child(currentChannel.id)
      .child(`users/${participant_id}`)
      .update(updates);
  };

  handleAction = (ACTION) => {
    const {
      currentUser,
      currentChannel,
      currentWorkspace,
      setCurrentChannel,
      setRightPanel,
      setParticipants,
      setAllMessages,
      setCurrentWorkspace,
      setWorkspaces,
      darkTheme,
    } = this.props;

    const actionData = {
      workspace_id: currentWorkspace.workspaceId,
      room_id: currentChannel.roomId,
      user_id: currentUser.uid,
    };

    postData(`${API_BASE_URL}${ACTION}`, actionData)
      .then((response) => {
        const { status, message } = response;
        if (status === 200) {
          setCurrentChannel(null);
          setRightPanel(false);
          setParticipants(null);
          setAllMessages([]);

          firebase
            .firestore()
            .collection(`users/${currentUser.uid}/rooms`)
            .get()
            .then((querySnapshot) => {
              let userWorkspaces = [];
              querySnapshot.docs.forEach(function (doc) {
                userWorkspaces.push({ ...doc.data() });
              });

              let index;

              userWorkspaces.forEach((workspace, key) => {
                console.log("inside did update", currentWorkspace);
                if (workspace.workspaceId === currentWorkspace?.workspaceId) {
                  console.log(key, "key in userworkspaces");
                  index = key;
                }
              });

              const updatedCurrentWorkspace = {
                ...currentWorkspace,
                ...userWorkspaces[index],
              };
              setCurrentWorkspace(_.cloneDeep(updatedCurrentWorkspace));
              setWorkspaces(currentUser.uid);
            });
        } else {
          console.log(`Error message ${ACTION} room: `, message);
        }
      })
      .catch((err) => console.error(err));
  };

  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));

  handleRoomName = (e) => {
    this.setState({ duplicateRoomName: e.target.value });
    //setDuplicateRoomName(e.target.value)
  };

  roomDuplicationHandler = (roomId, newRoomName) => {
    axios
      .post(`${API_BASE_URL}${ROOM_DUPLICATE}`, {
        workspace_id: this.props.currentWorkspace.workspaceId,
        room_id: roomId,
        new_room_name: newRoomName,
        user_id: this.props.currentUser.uid,
      })
      .then((res) => {
        // resetting the local states
        this.setState({
          duplicateRoomName: "",
          showDuplicateModal: false,
          selectedRoom: null,
        });

        //setDuplicateRoomName("");
        //setSelectedRoom(null);
        //setShowDuplicateModal(false);

        // loading the room in middle and showing a toast room duplicated

        console.log(res, "response from api duplication ");
        firebase
          .firestore()
          .collection(`workspaces/${res.data.data.workspace_id}/rooms`)
          .doc(res.data.data.new_room_id)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const currentChannelDeep = _.cloneDeep({
                ...doc.data(),
                roomId: doc.id,
              });
              this.props.setCurrentChannel(currentChannelDeep);
              this.props.setParticipants(
                res.data.data.workspace_id,
                res.data.data.new_room_id
              );
              if (this.props.darkTheme) {
                toast.dark("Room has been duplicated", {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              } else {
                toast("Room has been duplicated", {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              }
            }
          });
      });
  };

  roomAnalyticsHandler = (currentChannel) => {
    if (this.props.currentWorkspace && currentChannel) {
      firebase
        .firestore()
        .collection(
          `workspaces/${this.props.currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/participants`
        )
        .onSnapshot((snapshot) => {
          let allParticipants1 = [];

          snapshot.forEach((doc) => {
            allParticipants1.push({ uid: doc.id, ...doc.data() });
          });

          // console.log("allParticipants1", allParticipants1);

          let savedPolls = [];
          let publishedPolls = [];
          let savedSchedules = [];
          let publishedSchedules = [];
          let savedReadings = [];
          let publishedReadings = [];
          let savedQuizes = [];
          let publishedQuizes = [];
          let savedAssignments = [];
          let publishedAssignments = [];
          const query = firebase
            .firestore()
            .collection(
              `/workspaces/${this.props.currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
            )
            .orderBy("timestamp", "desc");
          const queryMsg = firebase
            .firestore()
            .collection(
              `/workspaces/${this.props.currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
            )
            .orderBy("timestamp", "desc");

          query.onSnapshot((snapshot) => {
            //console.log(snapshot.docChanges());
            let changes = snapshot.docChanges();
            changes.forEach((change) => {
              if (change.type === "added") {
                if (change.doc.data().type === 3) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedPolls.unshift(json);
                } else if (change.doc.data().type === 4) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedQuizes.unshift(json);
                } else if (change.doc.data().type === 5) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedAssignments.unshift(json);
                } else if (change.doc.data().type === 2) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedReadings.unshift(json);
                } else if (change.doc.data().type === 1) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  savedSchedules.unshift(json);
                }
              } else if (change.type === "removed") {
                if (change.doc.data().type === 3) {
                  savedPolls = savedPolls.filter(
                    (savedPoll) => savedPoll.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 4) {
                  savedQuizes = savedQuizes.filter(
                    (savedQuiz) => savedQuiz.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 5) {
                  savedAssignments = savedAssignments.filter(
                    (savedAssignment) => savedAssignment.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 2) {
                  savedReadings = savedReadings.filter(
                    (savedReading) => savedReading.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 1) {
                  savedSchedules = savedSchedules.filter(
                    (savedSchedule) => savedSchedule.id !== change.doc.id
                  );
                }
              } else if (change.type === "modified") {
                if (change.doc.data().type === 3) {
                  let index = savedPolls.findIndex(
                    (savedPoll) => savedPoll.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedPolls.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 4) {
                  let index = savedQuizes.findIndex(
                    (savedQuiz) => savedQuiz.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedQuizes.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 5) {
                  let index = savedAssignments.findIndex(
                    (savedAssignment) => savedAssignment.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedAssignments.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 2) {
                  let index = savedReadings.findIndex(
                    (savedReading) => savedReading.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedReadings.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 1) {
                  let index = savedSchedules.findIndex(
                    (savedSchedule) => savedSchedule.id === change.doc.id
                  );
                  if (index !== -1) {
                    savedSchedules.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                }
              }
            });
            this.setState({ savedPolls: savedPolls });
            this.setState({ savedQuizes: savedQuizes });
            this.setState({ savedReadings: savedReadings });
            this.setState({ savedAssignments: savedAssignments });
            this.setState({ savedSchedules: savedSchedules });

            //setSavedPolls(savedPolls);
            //setSavedQuizes(savedQuizes);
            //setSavedReadings(savedReadings);
            //setSavedAssignmnets(savedAssignments);
            //setSavedSchedules(savedSchedules);
          });

          queryMsg.onSnapshot((snapshot) => {
            //console.log(snapshot.docChanges());
            let changes = snapshot.docChanges();
            changes.forEach((change) => {
              if (change.type === "added") {
                if (change.doc.data().type === 3) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedPolls.unshift(json);
                } else if (change.doc.data().type === 4) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedQuizes.unshift(json);
                } else if (change.doc.data().type === 5) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedAssignments.unshift(json);
                } else if (change.doc.data().type === 2) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedReadings.unshift(json);
                } else if (change.doc.data().type === 1) {
                  let data = change.doc.data();
                  let json = { ...data, id: change.doc.id };
                  publishedSchedules.unshift(json);
                }
              } else if (change.type === "removed") {
                if (change.doc.data().type === 3) {
                  publishedPolls = publishedPolls.filter(
                    (publishPoll) => publishPoll.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 4) {
                  publishedQuizes = publishedQuizes.filter(
                    (publishQuiz) => publishQuiz.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 5) {
                  publishedAssignments = publishedAssignments.filter(
                    (publishAssignment) =>
                      publishAssignment.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 2) {
                  publishedReadings = publishedReadings.filter(
                    (publishReading) => publishReading.id !== change.doc.id
                  );
                } else if (change.doc.data().type === 1) {
                  publishedSchedules = publishedSchedules.filter(
                    (publishSchedule) => publishSchedule.id !== change.doc.id
                  );
                }
              } else if (change.type === "modified") {
                if (change.doc.data().type === 3) {
                  let index = publishedPolls.findIndex(
                    (publishPoll) => publishPoll.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedPolls.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 4) {
                  let index = publishedQuizes.findIndex(
                    (publishQuiz) => publishQuiz.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedQuizes.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 5) {
                  let index = publishedAssignments.findIndex(
                    (publishAssignment) =>
                      publishAssignment.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedAssignments.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 2) {
                  let index = publishedReadings.findIndex(
                    (publishReading) => publishReading.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedReadings.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                } else if (change.doc.data().type === 1) {
                  let index = publishedSchedules.findIndex(
                    (publishSchedule) => publishSchedule.id === change.doc.id
                  );
                  if (index !== -1) {
                    publishedSchedules.splice(index, 1, {
                      ...change.doc.data(),
                      id: change.doc.id,
                    });
                  }
                }
              }
            });
            this.setState({ publishedPolls: publishedPolls });
            this.setState({ publishedQuizes: publishedQuizes });
            this.setState({ publishedReadings: publishedReadings });
            this.setState({ publishedAssignments: publishedAssignments });
            this.setState({ publishedSchedules: publishedSchedules });

            //setPublishedPolls(publishedPolls);
            //setPublishedQuizes(publishedQuizes);
            //setPublishedReadings(publishedReadings);
            //setPublishedAssignments(publishedAssignments);
            //setPublishedSchedules(publishedSchedules);
            let chartData = [
              {
                label: "Published Assignments",
                value: publishedAssignments.length,
              },
              { label: "Published Polls", value: publishedPolls.length },
              { label: "Published Quizzes", value: publishedQuizes.length },
              { label: "Published Files", value: publishedReadings.length },
            ];
            this.setState({ chartData: chartData });
            //setChartData(chartData);
          });
          this.setState({ allParticipants: allParticipants1 });
          //setAllParticipants(allParticipants1);
        });
    }
  };

  render() {
    const {
      currentChannel,
      currentUser,
      showFull,
      setRoomDetails,
      setContentType,
      setContentTypeAndValue,
      roomsVisible,
      allParticipants,
      currentSubscription,
    } = this.props;
    const {
      video_server_location,
      videoMeetingType,
      adminOnlyChat,
      specialOlympics,
      videoMeetingURL,
    } = this.state;

    return (
      <>
        {showFull ? (
          <div>
            <div
              onClick={() => {
                setRoomDetails(false);
                setContentType(null);
              }}
              style={{
                position: "-webkit-sticky",
                position: "sticky",
                zIndex: 10,
                top: 0,
                cursor: "pointer",
              }}
              className="mb-1 p-2 bg-card-theme text-theme"
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
              <span className="d-inline-block">Room Details</span>
            </div>

            <div className="shadow-sm rounded-lg py-2 m-2 bg-card-theme text-theme">
              <Form>
                {this.state.cropImageRight ? (
                  <div className="d-flex flex-column my-2">
                    <p className="small text-muted text-center mb-1">
                      CROP IMAGE
                    </p>
                    <div className="d-flex justify-content-center align-items-center">
                      <AvatarEditor
                        ref={(node) => (this.avatarEditorRight = node)}
                        image={this.state.previewImageRight}
                        width={150}
                        height={150}
                      />
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <Button
                        variant="light"
                        size="sm"
                        className="d-flex justify-content-center align-items-center text-success"
                        onClick={this.handleCropImage}
                      >
                        <FontAwesomeIcon className="mr-2" icon={faCheck} /> Done
                      </Button>
                      <Button
                        variant="light"
                        size="sm"
                        className="d-flex justify-content-center align-items-center text-secondary"
                        onClick={() => this.setState({ cropImageRight: false })}
                      >
                        <FontAwesomeIcon className="mr-2" icon={faTimes} />{" "}
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="my-5 mx-auto" id="add-avatar-right">
                    {this.state.loading ? null : (
                      <label for="avatar-right">
                        <Image
                          id="avatar-image-right"
                          width="150"
                          height="150"
                          roundedCircle
                          src={
                            this.state.croppedImageRight
                              ? this.state.croppedImageRight
                              : this.state.roomPic
                          }
                          style={{ background: "white" }}
                          onClick={() =>
                            this.setState({ visibleRoomIcon: true })
                          }
                        />
                        <Viewer
                          visible={this.state.visibleRoomIcon}
                          onClose={() => {
                            this.setState({ visibleRoomIcon: false });
                          }}
                          images={[
                            {
                              src: this.state.roomPic,
                              alt: this.state.roomName,
                            },
                          ]}
                        />
                      </label>
                    )}
                    <IsAdmin>
                      <input
                        type="file"
                        id="avatar-right"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={this.handleChangeFile}
                      />

                      {!this.state.changingAvatarRight && (
                        <label for="avatar-right" id="overlay-right">
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <FontAwesomeIcon
                              icon={faCamera}
                              className="text-white"
                            />
                            <small className="text-white text-center px-2">
                              CHANGE ROOM ICON
                            </small>
                          </div>
                        </label>
                      )}

                      {this.state.changingAvatarRight && (
                        <label
                          for="avatar-right"
                          id="overlay-right"
                          className="label-overlay-right"
                        >
                          <div className="d-flex flex-column justify-content-center align-items-center">
                            <small className="text-white text-center px-2">
                              LOADING...
                            </small>
                          </div>
                        </label>
                      )}
                    </IsAdmin>
                  </div>
                )}

                {this.state.editRoomName ? (
                  <>
                    <div className="d-flex justify-content-end text-secondary mr-4 mb-1 small ">
                      {150 - this.state.roomName.length}
                    </div>
                    <div className="d-flex px-4">
                      <Form.Control
                        maxLength="150"
                        ref={(input) => (this.roomNameRef = input)}
                        type="text"
                        placeholder="Enter room name"
                        value={this.state.roomName}
                        name="roomName"
                        onChange={(e) =>
                          this.setState({ roomName: e.target.value })
                        }
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            this.handleRoomNameEdit(event);
                          }
                        }}
                        className="border-top-0 border-left-0 border-right-0 rounded-0 px-0 pb-3 mt-2"
                      />

                      <div className="d-flex">
                        <Button
                          variant="light"
                          onClick={this.handleRoomNameEdit}
                        >
                          <FontAwesomeIcon
                            //onClick={this.handleRoomNameEdit}
                            icon={faCheck}
                            className="text-success"
                          />
                        </Button>
                        <Button
                          onClick={() =>
                            this.setState({
                              editRoomName: false,
                              roomName:
                                currentChannel && currentChannel.roomName,
                            })
                          }
                          variant="light"
                        >
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="text-secondary"
                          />
                        </Button>
                      </div>
                    </div>
                    {this.state.roomNameError != "" ? (
                      <p className="small text-danger my-1 px-4">
                        {this.state.roomNameError}
                      </p>
                    ) : null}
                  </>
                ) : (
                  <div className="d-flex px-4">
                    <p className="flex-grow-1">{this.state.roomName}</p>
                    <IsAdmin>
                      {this.state.editRoomName ? (
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-secondary mt-2 pointer"
                          onClick={() => this.setState({ editRoomName: false })}
                        />
                      ) : (
                        <FontAwesomeIcon
                          size="sm"
                          icon={faEdit}
                          className="text-secondary mt-2 pointer"
                          onClick={() => this.setState({ editRoomName: true })}
                        />
                      )}
                    </IsAdmin>
                  </div>
                )}
              </Form>
            </div>

            <div className="shadow-sm rounded-lg py-2 px-4 m-2  bg-card-theme text-theme">
              <div className="d-flex">
                <p className="flex-grow-1 font-weight-bold">Room Description</p>
                <IsAdmin>
                  {this.state.editRoomDescription ? (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className="text-secondary"
                      onClick={() =>
                        this.setState({
                          editRoomDescription: false,
                          roomDescription:
                            currentChannel && currentChannel.description,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      size="sm"
                      icon={faEdit}
                      className="text-secondary mt-2"
                      onClick={() =>
                        this.setState({ editRoomDescription: true })
                      }
                    />
                  )}
                </IsAdmin>
              </div>
              <div className="text-theme-lighter small">
                {this.state.editRoomDescription ? (
                  <IsAdmin>
                    <div className="d-flex justify-content-end text-secondary mr-4 mb-1 small ">
                      {2500 - this.state.roomDescription.length}
                    </div>
                    <div className="d-flex flex-column">
                      <TextareaAutosize
                        maxLength="2500"
                        placeholder="Enter room description"
                        name="roomDescription"
                        value={this.state.roomDescription}
                        onChange={(e) =>
                          this.setState({ roomDescription: e.target.value })
                        }
                        className="form-control border-top-0 border-left-0 border-right-0 rounded-0 px-0 pb-3"
                      />
                      <Button
                        variant="light"
                        onClick={this.handleRoomDescriptionEdit}
                      >
                        SAVE
                      </Button>

                      {this.state.roomDescriptionError != "" ? (
                        <p className="text-danger my-1">
                          {this.state.roomDescriptionError}
                        </p>
                      ) : null}
                    </div>
                  </IsAdmin>
                ) : this.state.roomDescription.length > 0 ? (
                  <ReadMore
                    full_text={this.state.roomDescription}
                    max_length="250"
                  />
                ) : (
                  <p className="small mb-0 text-theme">No description added</p>
                )}
              </div>
            </div>

            <div className="mt-2 shadow-sm  rounded-lg py-2 px-4 m-2  bg-card-theme text-theme">
              {/*console.log(this.state.createdByUserDetails,"my details")*/}
              <p className="font-weight-bold">Created by</p>
              {this.state.loading ? (
                <Spinner animation="border" size="sm" />
              ) : this.state.createdByUserDetails ? (
                <CreatedBy
                  avatar={this.state.createdByUserDetails.avatar}
                  displayName={this.state.createdByUserDetails.displayName}
                  userId={this.state.createdBy}
                  showYou={true}
                >
                  <p className="small text-success mb-0">Admin</p>
                </CreatedBy>
              ) : (
                <Spinner animation="border" size="sm" />
              )}
            </div>

            <div className="shadow-sm rounded-lg p-2 px-4 m-2 bg-card-theme text-theme">
              <div className="">
                <p className="font-weight-bold">Participants</p>
                <Participants
                  allParticipants={allParticipants}
                  createrId={this.state.createdBy}
                />
              </div>
            </div>

            {/* <div className='block shadow-sm rounded-lg py-2 m-2 bg-card-theme text-theme'>
                    <p className="px-4 font-weight-bold">{currentChannel && Object.keys(currentChannel.users).length} Participants</p>
                    <div>
                        {currentChannel && Object.keys(currentChannel.users).map(user => (
                            <div className="d-flex px-4 py-2" style={(getAvatar(user) !== "" && getName(user) !== "") ? {} : { background: "rgba(25, 25, 25, 0.1)" }}>
                                <Image width="40" height="40"
                                    roundedCircle
                                    src={getAvatar(user) !== "" ? getAvatar(user) : 'https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/avatars%2Fusers%2Fsolo-icon.svg?alt=media&token=0279ff83-ebf4-420f-bc28-e5f6b10a7822'}
                                    spaced="right"
                                    style={{ background: 'white' }} />
                                <div className='flex-grow-1 mx-3'>
                                    <div className="d-flex">
                                        <p className={getName(user) !== "" ? "flex-grow-1 mb-0" : "flex-grow-1 mb-0 text-muted"}>{currentUser.uid === user ? "You" : getName(user) !== "" ? getName(user) : "User deactivated"}</p>
                                        <IsAdmin>
                                            {currentUser.uid !== user
                                                && <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right">
                                                    <Dropdown.Toggle size="sm" variant="white" as={this.CustomToggle} />

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item className="small" onClick={() => this.handleRemoveParticipant(user)}>Remove</Dropdown.Item>

                                                        {currentChannel.users[user].admin
                                                            ? <Dropdown.Item className="small" onClick={() => this.handleRoomAdmin(user, false)}>Dismiss as admin</Dropdown.Item>
                                                            : <Dropdown.Item className="small" onClick={() => this.handleRoomAdmin(user, true)}>Make room admin</Dropdown.Item>}
                                                    </Dropdown.Menu>
                                                </Dropdown>}
                                        </IsAdmin>
                                    </div>
                                    {currentChannel.users[user].admin && <small className="text-success">Admin</small>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}

            <div className="shadow-sm  rounded-lg py-2  m-2 shadow-sm  bg-card-theme text-theme">
              <p className="px-4 font-weight-bold">Room Settings</p>

              {currentUser.uid === currentChannel?.createdBy ? (
                <div className="px-4 py-2">
                  <p className="small mb-2 text-muted">Duplicate Room</p>
                  <ListGroup>
                    <ListGroup.Item
                      action
                      onClick={() => {
                        //setShowDuplicateModal(true);
                        this.setState({
                          showDuplicateModal: true,
                          selectedRoom: currentChannel,
                        });
                        this.roomAnalyticsHandler(currentChannel);
                      }}
                      className="text-center"
                    >
                      Duplicate Room <FontAwesomeIcon icon={faClone} />
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              ) : null}

              {/*<div className="px-4 py-2">
                <p className="small mb-0 text-muted">Video server location</p>
                {this.state.editVideoServer ? (
                  <div className="d-flex mb-2">
                    <Form.Group className="flex-grow-1 mb-0 pb-0">
                      <Form.Control
                        as="select"
                        onChange={(e) =>
                          this.setState({ video_server_location: e.target.value })
                        }
                      >
                        <option
                          value="ap-south-1.igesia.com"
                          selected={
                            video_server_location === "ap-south-1.igesia.com"
                              ? true
                              : false
                          }
                        >
                          South Asia
                    </option>
                        <option
                          value="us-east-2.igesia.com"
                          selected={
                            video_server_location === "us-east-2.igesia.com"
                              ? true
                              : false
                          }
                        >
                          North America
                    </option>
                        <option
                          value="sa-east-1.igesia.com"
                          selected={
                            video_server_location === "sa-east-1.igesia.com"
                              ? true
                              : false
                          }
                        >
                          South America
                    </option>
                        <option
                          value="eu-central-1.igesia.com"
                          selected={
                            video_server_location === "eu-central-1.igesia.com"
                              ? true
                              : false
                          }
                        >
                          Europe
                    </option>
                        {/* <option
                      value="sa-east-1.igesia.co"
                      selected={
                        video_server_location === "sa-east-1.igesia.co"
                          ? true
                          : false
                      }
                    >
                      South America (Legacy)
                    </option> }
                      </Form.Control>
                    </Form.Group>

                    <div className="d-flex mb-0 pb-0">
                      <Button variant="light"
                        onClick={this.handleVideoServerEdit}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                        />
                      </Button>
                      <Button 
                        onClick={() =>
                          this.setState({
                            editVideoServer: false,
                            video_server_location:
                              currentChannel && currentChannel.videoServerId,
                          })}
                        variant="light"
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-secondary"
                        />
                      </Button>
                    </div>
                  </div>
                ) : (
                    <div className="d-flex">
                      <p className="flex-grow-1 p-1">
                        {getVideoServer(video_server_location)}
                      </p>
                      <IsAdmin>
                        {this.state.editVideoServer ? (
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="text-secondary"
                            onClick={() => this.setState({ editVideoServer: false })}
                          />
                        ) : (
                            <FontAwesomeIcon
                              icon={faEdit}
                              size="sm"
                              className="text-secondary mt-2"
                              onClick={() => this.setState({ editVideoServer: true })}
                            />
                          )}
                      </IsAdmin>
                    </div>
                  )}
                </div>*/}

              {/* video meeting type */}
              <div className="px-4 py-2">
                <p className="small mb-0 text-muted">Video meeting type</p>
                {this.state.editVideoMeetingType ? (
                  <div className="d-flex mb-2">
                    <Form.Group className="flex-grow-1 mb-0 pb-0">
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          if (+e.target.value === 1 || +e.target.value === 2) {
                            if (
                              (currentSubscription &&
                                !currentSubscription.premiumVideo) ||
                              currentSubscription === null
                            ) {
                              // console.log("Open MODAL for the non subscription yzzzz")
                              this.setState({ videoModal: true });
                            } else {
                              // console.log("having a subscription yzzzz")
                              this.setState({
                                videoMeetingType: +e.target.value,
                              });
                            }
                          } else {
                            //console.log("Regulaer or ustom selected yzzzz")
                            this.setState({
                              videoMeetingType: +e.target.value,
                            });
                          }

                          //
                        }}
                      >
                        <option
                          value={0}
                          selected={videoMeetingType === 0 ? true : false}
                        >
                          Regular
                        </option>

                        <option
                          value={1}
                          selected={videoMeetingType === 1 ? true : false}
                        >
                          Premium
                        </option>

                        <option
                          value={2}
                          selected={videoMeetingType === 2 ? true : false}
                        >
                          Broadcast
                        </option>

                        {this.props?.currentSubscription?.customVideo ? (
                          <option
                            value={4}
                            selected={videoMeetingType === 4 ? true : false}
                          >
                            Custom
                          </option>
                        ) : null}
                      </Form.Control>
                    </Form.Group>

                    <div className="d-flex mb-0 pb-0">
                      <Button
                        variant="light"
                        onClick={this.handleVideoMeetingTypeEdit}
                      >
                        <FontAwesomeIcon
                          //onClick={this.handleVideoMeetingTypeEdit}
                          icon={faCheck}
                          className="text-success"
                        />
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            editVideoMeetingType: false,
                            videoMeetingType:
                              currentChannel && currentChannel.videoMeetingType
                                ? videoMeetingType
                                : 0,
                          })
                        }
                        variant="light"
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-secondary"
                        />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex">
                    <p className="flex-grow-1 p-1">
                      {videoMeetingType === 0 ? "Regular" : null}
                      {videoMeetingType === 1 ? "Premium" : null}
                      {videoMeetingType === 2 ? "Broadcast" : null}
                      {videoMeetingType === 4 ? "Custom" : null}
                    </p>
                    <IsAdmin>
                      {currentUser.uid === currentChannel?.createdBy ? (
                        this.state.editVideoMeetingType ? (
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="text-secondary"
                            onClick={() =>
                              this.setState({ editVideoMeetingType: false })
                            }
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEdit}
                            size="sm"
                            className="text-secondary mt-2"
                            onClick={() =>
                              this.setState({ editVideoMeetingType: true })
                            }
                          />
                        )
                      ) : null}
                    </IsAdmin>
                  </div>
                )}
              </div>

              {/* custom url input */}
              {videoMeetingType === 4 ? (
                <div className="px-4 py-2">
                  <p className="small mb-0 text-muted">Video URL</p>
                  <small className="text-muted">
                    (Make sure link starts with either http:// or https://)
                  </small>
                  {this.state.addCustomVideoUrl ? (
                    <>
                      <div className="d-flex mb-2">
                        <Form.Group className="flex-grow-1 mb-0 pb-0">
                          <Form.Control
                            value={this.state.videoMeetingURL}
                            onChange={(e) => {
                              this.setState({
                                videoMeetingURL: e.target.value,
                              });
                            }}
                          ></Form.Control>
                        </Form.Group>

                        <div className="d-flex mb-0 pb-0">
                          <Button
                            variant="light"
                            onClick={this.handleCustomVideoURL}
                          >
                            <FontAwesomeIcon
                              //onClick={this.handleVideoMeetingTypeEdit}
                              icon={faCheck}
                              className="text-success"
                            />
                          </Button>
                          <Button
                            onClick={() =>
                              this.setState({
                                addCustomVideoUrl: false,
                                videoMeetingURL: this.state.videoMeetingURL
                                  ? this.state.videoMeetingURL
                                  : "",
                              })
                            }
                            variant="light"
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="text-secondary"
                            />
                          </Button>
                        </div>
                      </div>
                      <p className="text-danger small">
                        {this.state.videoMeetingURLError}
                      </p>
                    </>
                  ) : (
                    <div className="d-flex">
                      <p className="flex-grow-1 p-1 text-truncate">
                        {videoMeetingURL?.length ? videoMeetingURL : null}
                      </p>
                      <IsAdmin>
                        {currentUser.uid === currentChannel?.createdBy ? (
                          this.state.addCustomVideoUrl ? (
                            <FontAwesomeIcon
                              icon={faTimes}
                              className="text-secondary"
                              onClick={() =>
                                this.setState({ addCustomVideoUrl: false })
                              }
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faEdit}
                              size="sm"
                              className="text-secondary mt-2"
                              onClick={() =>
                                this.setState({ addCustomVideoUrl: true })
                              }
                            />
                          )
                        ) : null}
                      </IsAdmin>
                    </div>
                  )}
                </div>
              ) : null}

              {/* admin only chat */}
              <div className="px-4 py-2">
                <p className="small mb-0 text-muted">Admin only chat</p>
                {this.state.editAdminOnlyChat ? (
                  <div className="d-flex mb-2">
                    <Form.Group className="flex-grow-1 mb-0 pb-0">
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          //console.log(e.target.value, "value on change")
                          this.setState({
                            adminOnlyChat:
                              e.target.value === "true" ? true : false,
                          });
                        }}
                      >
                        <option
                          value={true}
                          selected={adminOnlyChat === true ? true : false}
                        >
                          On
                        </option>
                        <option
                          value={false}
                          selected={adminOnlyChat === false ? true : false}
                        >
                          Off
                        </option>
                      </Form.Control>
                    </Form.Group>

                    <div className="d-flex mb-0 pb-0">
                      <Button
                        variant="light"
                        onClick={this.handleAdminOnlyChatEdit}
                      >
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                        />
                      </Button>
                      <Button
                        onClick={this.handleAdminOnlyChatEdit}
                        variant="light"
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-secondary"
                        />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex">
                    <p className="flex-grow-1 p-1">
                      {adminOnlyChat === true ? "On" : "Off"}
                    </p>
                    <IsAdmin>
                      {currentUser.uid === currentChannel?.createdBy ? (
                        this.state.editAdminOnlyChat ? (
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="text-secondary"
                            onClick={() =>
                              this.setState({ editAdminOnlyChat: false })
                            }
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEdit}
                            size="sm"
                            className="text-secondary mt-2"
                            onClick={() =>
                              this.setState({ editAdminOnlyChat: true })
                            }
                          />
                        )
                      ) : null}
                    </IsAdmin>
                  </div>
                )}
              </div>

              {/* special olympics */}
              <div className="px-4 py-2">
                <p className="small mb-0 text-muted">Special olympics app</p>
                {this.state.editSpecialOlympics ? (
                  <div className="d-flex mb-2">
                    <Form.Group className="flex-grow-1 mb-0 pb-0">
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          //console.log(e.target.value, "value on change")
                          this.setState({
                            specialOlympics:
                              e.target.value === "true" ? true : false,
                          });
                        }}
                      >
                        <option
                          value={true}
                          selected={specialOlympics === true ? true : false}
                        >
                          On
                        </option>
                        <option
                          value={false}
                          selected={specialOlympics === false ? true : false}
                        >
                          Off
                        </option>
                      </Form.Control>
                    </Form.Group>

                    <div className="d-flex mb-0 pb-0">
                      <Button
                        variant="light"
                        onClick={this.handleSpecialOlympics}
                      >
                        <FontAwesomeIcon
                          //onClick={this.handleSpecialOlympics}
                          icon={faCheck}
                          className="text-success"
                        />
                      </Button>
                      <Button
                        variant="light"
                        onClick={() =>
                          this.setState({
                            editSpecialOlympics: false,
                            specialOlympics:
                              currentChannel &&
                              currentChannel.roomConfig
                                .special_olympics_app_allowed,
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-secondary"
                        />
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex">
                    <p className="flex-grow-1 p-1">
                      {specialOlympics === true ? "On" : "Off"}
                    </p>
                    <IsAdmin>
                      {currentUser.uid === currentChannel?.createdBy ? (
                        this.state.editSpecialOlympics ? (
                          <FontAwesomeIcon
                            icon={faTimes}
                            className="text-secondary"
                            onClick={() =>
                              this.setState({ editSpecialOlympics: false })
                            }
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEdit}
                            size="sm"
                            className="text-secondary mt-2"
                            onClick={() =>
                              this.setState({ editSpecialOlympics: true })
                            }
                          />
                        )
                      ) : null}
                    </IsAdmin>
                  </div>
                )}
              </div>
            </div>
            <div className="m-2">
              {this.state.leaveRoom && (
                <Alert
                  className="shadow-sm"
                  variant="danger"
                  onClose={() => this.setState({ leaveRoom: false })}
                >
                  <p className="font-weight-bold">
                    Are you sure you want to leave this room?
                  </p>
                  <p className="small">
                    Once you leave this room, you will not be able to access it
                    unless re-invited.{" "}
                  </p>
                  <p className="smaller">
                    Note: Currently, you can't leave a room if you are the room
                    creator. We are working on a solution and it will be
                    available soon. Thank you for being with us as we develop
                    the platform.
                  </p>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <Button
                      onClick={() => this.setState({ leaveRoom: false })}
                      size="sm"
                      variant="outline-dark"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => this.handleAction(ROOM_LEAVE)}
                      size="sm"
                      variant="outline-danger"
                    >
                      Leave
                    </Button>
                  </div>
                </Alert>
              )}
              <ListGroup>
                <ListGroup.Item
                  action
                  onClick={() => this.setState({ leaveRoom: true })}
                  className="text-center text-danger"
                >
                  Leave Room <FontAwesomeIcon icon={faSignOutAlt} />
                </ListGroup.Item>
              </ListGroup>
            </div>
            {roomsVisible === ACTIVE ? (
              <div className="m-2">
                {this.state.archiveRoom && (
                  <Alert
                    className="shadow-sm"
                    variant="info"
                    onClose={() => this.setState({ archiveRoom: false })}
                  >
                    <p className="font-weight-bold">
                      Are you sure you want to archive this room?
                    </p>
                    <p className="small">
                      Once you archive this room, you will no longer see it on
                      your active rooms' list. You can access the room by
                      clicking on the archive button next to search bar in your
                      left bar.
                    </p>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <Button
                        onClick={() => this.setState({ archiveRoom: false })}
                        size="sm"
                        variant="outline-dark"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => this.handleAction(USER_ROOM_ARCHIVE)}
                        size="sm"
                        variant="outline-info"
                      >
                        Archive
                      </Button>
                    </div>
                  </Alert>
                )}
                <ListGroup>
                  <ListGroup.Item
                    action
                    onClick={() => this.setState({ archiveRoom: true })}
                    className="text-center text-muted small"
                  >
                    Archive Room <FontAwesomeIcon icon={faArchive} />
                  </ListGroup.Item>
                </ListGroup>
              </div>
            ) : (
              <div className="m-2">
                {this.state.archiveRoom && (
                  <Alert
                    className="shadow-sm"
                    variant="info"
                    onClose={() => this.setState({ archiveRoom: false })}
                  >
                    <p className="font-weight-bold">
                      Are you sure you want to un-archive this room?
                    </p>
                    <p className="small">
                      Once you un-archive this room, you will be able to see it
                      in active rooms' list in your left bar.
                    </p>
                    <hr />
                    <div className="d-flex justify-content-between">
                      <Button
                        onClick={() => this.setState({ archiveRoom: false })}
                        size="sm"
                        variant="outline-dark"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => this.handleAction(USER_ROOM_UNARCHIVE)}
                        size="sm"
                        variant="outline-info"
                      >
                        Un-Archive
                      </Button>
                    </div>
                  </Alert>
                )}
                <ListGroup>
                  <ListGroup.Item
                    action
                    onClick={() => this.setState({ archiveRoom: true })}
                    className="text-center text-muted small"
                  >
                    Un-Archive Room <FontAwesomeIcon icon={faArchive} />
                  </ListGroup.Item>
                </ListGroup>
              </div>
            )}
          </div>
        ) : (
          <img
            onClick={() => setContentTypeAndValue("roomDetails", true)}
            src={RoomDetailsIcon}
            width="50"
          />
          // <p className="mb-0">{currentChannel && (currentChannel. description.length > 150 ? currentChannel. description.slice(0, 150) + '…' : currentChannel. description)}</p>
        )}

        <>
          <Modal size="lg" centered show={this.state.showDuplicateModal}>
            <Modal.Header className="bg-theme border-0 d-flex justify-content-between align-items-center pb-0">
              <Modal.Title>
                <h5 className="text-theme">
                  Duplicate {this.state.selectedRoom?.roomName} ?
                </h5>
              </Modal.Title>
              <div
                title="Close"
                className="pointer"
                onClick={() => {
                  this.setState({ showDuplicateModal: false });
                }}
              >
                <FontAwesomeIcon className="text-theme" icon={faTimes} />
              </div>
            </Modal.Header>
            <Modal.Body className="overflow-auto list-unstyled bg-theme p-5">
              {/* Current room's stats */}

              <div className="row mb-3 p-2 border-1 mb-2 rounded shadow bg-card-theme text-theme">
                <div className="col-12 mb-2 pb-2 border-bottom-1">
                  <strong>Apps</strong>
                </div>
                <div className="col-3 border-right-1">
                  <div className="">
                    <h3 className="mb-0">{this.state.publishedPolls.length}</h3>
                    <div className="d-flex justify-content-between">
                      <span className="small">Polls</span>
                      <span className="smaller mt-1 text-theme-lighter">
                        {this.state.savedPolls.length} SAVED
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-3 border-right-1">
                  <div className="">
                    <h3 className="mb-0">
                      {this.state.publishedAssignments.length}
                    </h3>
                    <div className="d-flex justify-content-between">
                      <span className="small d-block">Assignments</span>
                      <span className="smaller mt-1 d-block text-theme-lighter">
                        {this.state.savedAssignments.length} SAVED
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-3 border-right-1">
                  <div className="">
                    <h3 className="mb-0">
                      {this.state.publishedQuizes.length}
                    </h3>
                    <div className="d-flex justify-content-between">
                      <span className="small d-block">Quizzes</span>
                      <span className="smaller mt-1 d-block text-theme-lighter">
                        {this.state.savedQuizes.length} SAVED
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className="">
                    <h3 className="mb-0">
                      {this.state.publishedReadings.length}
                    </h3>
                    <div className="d-flex justify-content-between">
                      <span className="small d-block">Files</span>
                      <span className="smaller mt-1 d-block text-theme-lighter">
                        {this.state.savedReadings.length} SAVED
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="col-3">
                  <div className="">
                    <h3 className="mb-0">{publishedSchedules.length}</h3>
                    <span className="small d-block">Schedule</span>
                    <span className="smaller d-block text-theme-lighter">{savedSchedules.length} saved</span>
                  </div>
                </div> */}
              </div>

              <div>
                <span className="text-theme">
                  All the apps from previous room will be in saved category and
                  linked to a folder called "{this.state.selectedRoom?.roomName}{" "}
                  Folder"
                </span>
              </div>

              {/* current room's stats end */}
              <InputGroup className="outerPill p-2">
                <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                  <div className="flex-grow-1">
                    <Form.Control
                      type="text"
                      className="border-0 px-0 bg-theme text-theme"
                      onChange={(e) => this.handleRoomName(e)}
                      placeholder={"Room name"}
                      value={this.state.duplicateRoomName}
                      style={{ margin: 0 }}
                    />
                  </div>
                  {this.state.duplicateRoomName.length > 0 && (
                    <div
                      className="py-2 pl-2 pr-3"
                      onClick={() => this.setState({ duplicateRoomName: "" })}
                    >
                      <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                    </div>
                  )}
                </div>
              </InputGroup>

              <div className="text-right my-2">
                <Button
                  className="btn-sm"
                  title="Duplicate"
                  onClick={() => {
                    this.roomDuplicationHandler(
                      this.state.selectedRoom.roomId,
                      this.state.duplicateRoomName
                    );
                  }}
                >
                  Duplicate
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
        <>
          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={this.state.videoModal}
            onHide={() => {
              this.setState({ videoModal: false });
              this.handleVideoMeetingTypeEdit();
            }}
          >
            <div
              title="Close"
              className="bg-theme pointer d-flex justify-content-end"
            >
              <FontAwesomeIcon
                className="text-theme mt-2 mr-2"
                icon={faTimes}
                onClick={() => {
                  this.setState({ videoModal: false });
                  this.handleVideoMeetingTypeEdit();
                }}
              />
            </div>

            <Modal.Body className="bg-theme text-theme  " closeButton>
              <div>
                It's a premium feature please contact{" "}
                <a href="mailto: hello@igesia.co" target="_blank">
                  hello@igesia.co
                </a>
                .
              </div>
              <div className="d-flex justify-content-end mr-5">
                <Button
                  className="mt-3 "
                  variant="primary"
                  onClick={() => {
                    window.location.assign("mailto: hello@igesia.co");
                    this.setState({ videoModal: false });
                    this.handleVideoMeetingTypeEdit();
                  }}
                >
                  Contact
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
  showFull: state.platform.showFull,
  roomsVisible: state.workspace.roomsVisible,
  allChannels: state.channel.allChannels,
  currentSubscription: state.subscription.currentSubscription,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, {
  setRoomDetails,
  setContentType,
  setContentTypeAndValue,
  setCurrentChannel,
  setRightPanel,
  setParticipants,
  setAllMessages,
  setChannels,
  setCurrentWorkspace,
  setWorkspaces,
})(RoomDetails);
