import React, { Component } from "react";
import Chart from "chart.js";
class SessionsChart extends React.Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
  }

  componentDidUpdate() {
    //this.myChart.data.labels = this.props.data.map((d) => d.time);
    this.myChart.data.labels = this.props.data.map((d) => d.label);
    this.myChart.data.datasets[0].data = this.props.data.map((d) => d.value);
    this.myChart.update();
  }

  componentDidMount() {
    this.myChart = new Chart(this.chartRef.current, {
      type: "line",
      data: {
        labels: this.props.data.map((d) => d.label),
        datasets: [
          {
            label: "",
            data: this.props.data.map((d) => d.value),
            fill: "none",
            backgroundColor: this.props.color,
            pointRadius: 0,
            pointRotation: 3,
            borderColor: "white",
            borderWidth: 2,
            lineTension: 0,
          },
        ],
      },
      options: {
        scales: {
          xAxes: [
            {
              time: {
                unit: "day",
              },
              display: false,
            },
          ],
          yAxes: [
            {
              ticks: {
                callback: function (value, index, values) {
                  return value + "min";
                },
                min: 0,
                display: false,
              },
            },
          ],
        },
        legend: false,
      },
    });
  }
  render() {
    //console.log(this.props.data);
    return <canvas ref={this.chartRef} />;
  }
}
export default SessionsChart;
