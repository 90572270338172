import React, { useState, useEffect } from "react";

import "./PatientDetails.css";
import { faArrowLeft, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PatientDetailsForm from "./PatientDetailsForm";


import { setShowCaseSheet } from "../../../../actions/index";

import { connect } from "react-redux";
import firebase from "firebase";

import {
  API_BASE_URL,
  DRIVE_FILE,
  DRIVE_UPLOAD,
  SEND_MESSAGE,
  META_TAGS,
} from "../../../../config/index";
import axios from "axios";

const PatientDetails = ({
  setOpenCaseSheetDrawer,
  currentChannel,
  currentWorkspace,
  setShowCaseSheet,
  currentUser,
  participants,
}) => {
  const [showPatientDetailsForm, SetShowPatientDetailsForm] = useState(false);
  const [PatientDetails, setPatientDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const ageCalculator = (dateOfBirth) => {
    console.log(dateOfBirth);
    let d1 = new Date(dateOfBirth);
    let d2 = new Date();
    var diff = d2.getTime() - d1.getTime();
    let age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
    console.log(age);
    return age;
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("caseSheet")
      .doc(currentWorkspace.workspaceId)
      .collection("rooms")
      .doc(currentChannel.roomId)
      .collection("caseSheet")
      .doc("caseSheetId")
      .collection("data")
      .doc("patientData")
      .onSnapshot((doc) => {
        if (doc.exists) {
          setPatientDetails({ ...doc.data() });
        } else {
          setPatientDetails({});
        }
        setIsLoading(false);
      });
  }, []);

  const sendForm = () => {
    let message = {
      type: 13,
      deadline: new Date(Date.now() + 10 * 600000),
      channelUsers: participants,
      user: {
        id: currentUser.uid,
        name: currentUser.displayName,
        avatar: currentUser.photoURL,
      },
    };
    axios.post(`${API_BASE_URL}${SEND_MESSAGE}`, {
      workspace_id: currentWorkspace.workspaceId,
      room_id: currentChannel.roomId,
      user_id: currentUser.uid,
      message: { ...message },
    });
  };

  return (
    <div className="PatientDetailsDrawer">
      <div
        style={{
          position: "-webkit-sticky",
          position: "sticky",
          zIndex: 10,
          top: 0,
          cursor: "pointer",
        }}
        className="m-2 rounded p-2 bg-card-theme text-theme"
      >
        {showPatientDetailsForm ? (
          <div
            className="d-flex-row"
            onClick={() => SetShowPatientDetailsForm(false)}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
            <span>Enter patient details</span>
          </div>
        ) : (
          <div
            className="d-flex-row"
            onClick={() => setOpenCaseSheetDrawer(false)}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
            <span>Case sheet</span>
          </div>
        )}
      </div>
      {isLoading ? (
        <div className="mt-5 text-center">
          <FontAwesomeIcon
            icon={faCircleNotch}
            className="fa-spin fa-2x text-success"
          ></FontAwesomeIcon>{" "}
        </div>
      ) : PatientDetails.name ? (
        <div className="mt-5 pt-5  d-block text-light font-14 mx-4 text-white">
          <div className="border rounded">
            <div className="bg-primary p-2 text-white  font-14 rounded-top">
              Patient information
            </div>
            <table className="table font-13 text-white mb-0">
              <tr>
                <td>
                  <b>Name: </b>
                </td>
                <td>{PatientDetails.name}</td>
              </tr>
              <tr>
                <td>
                  <b>Gender: </b>
                </td>
                <td>{PatientDetails.gender}</td>
              </tr>
              <tr>
                <td>
                  <b>Age: </b>
                </td>
                <td>{ageCalculator(PatientDetails.dateOfBirth)}</td>
              </tr>
            </table>
           <div className="m-2">
           <div className=" btn btn-primary btn-block btn-sm "
                onClick={() => {
                  setShowCaseSheet(true);
                  setOpenCaseSheetDrawer(false);
                }}
              >
                Open Case Sheet
              </div>
           </div>
          </div>
        </div>
      ) : showPatientDetailsForm ? (
        <PatientDetailsForm right />
      ) : (
        <div className="text-center mt-5 pt-5">
          <div className="text-secondary mb-5=4">
            <small>
              <i>No patient details found!</i>
            </small>
          </div>
          <div
            className="pointer bg-primary py-2 my-3 mx-3 rounded-pill font-13 font-weight-bold text-white"
            onClick={() => SetShowPatientDetailsForm(true)}
          >
            Enter patient details
          </div>
          <div>
            <small className="text-secondary ">or</small>
          </div>
          <div
            className="pointer bg-light py-2 my-3 mx-3 rounded-pill font-13 font-weight-bold "
            onClick={sendForm}
          >
            Send form in chat
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
  participants: state.channel.participants,
});

export default connect(mapStateToProps, { setShowCaseSheet })(PatientDetails);
