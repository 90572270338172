import React, { Component } from "react";
import firebase from "firebase";
import { timeFromNow } from "../../utils/index";
import { connect } from "react-redux";
import {
  setCurrentChannel,
  setCurrentWorkspace,
  setAllMessages,
} from "../../actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbtack,
  faLock,
  faLockOpen,
} from "@fortawesome/free-solid-svg-icons";

class RoomDetailsElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roomData: {},
    };
  }

  _handleStateChange = (attr, val) => this.setState({ [attr]: val });

  componentDidMount() {
    const _handleStateChange = this._handleStateChange;
    const { room, workspace } = this.props;

    firebase
      .firestore()
      .collection(`workspaces/${workspace.workspaceId}/rooms`)
      .doc(`${room.roomId}`)
      .onSnapshot(
        {
          includeMetadataChanges: true,
        },
        function (doc) {
          if (doc.exists) {
            console.log("Document data roomlistelement:", doc.data());
            _handleStateChange("roomData", doc.data());
          } else {
            // doc.data() will be undefined in this case
            // console.log("No such document!");
          }
        }
      );
  }

  render() {
    const { roomData } = this.state;
    const { isPinned, isVisible, roomId, roomName } = this.props.room;

    if (!isVisible) {
      return;
    }
    return (
      <div className="d-flex align-items-center p-2 w-100">
        <div>
          <img
            className="mr-2 bg-light rounded-circle"
            src={roomData.roomPic}
            style={{ height: 30, width: 30 }}
          />
        </div>
        <div className="flex-grow-1">
          <div className="d-flex align-items-center">
            {roomData.roomType ? (
              <FontAwesomeIcon
                icon={faLock}
                className="text-success mr-1"
                style={{ fontSize: 12 }}
              />
            ) : null}
            <p className="mb-0 text-theme">{roomData.roomName}</p>
          </div>
          <div>
            <p className="mb-0 small text-theme">
              Total messages:{" "}
              {roomData.totalMessages ? roomData.totalMessages : 0}
            </p>
            {roomData.pendingInvites && (
              <p className="mb-0 small text-theme">
                Pending invites: {Object.values(roomData.pendingInvites).length}
              </p>
            )}
          </div>
        </div>
        <div className="text-right">
          <FontAwesomeIcon
            icon={faThumbtack}
            style={
              isPinned
                ? { fontSize: 12, transform: "rotate(45deg)" }
                : { fontSize: 12 }
            }
            className={isPinned ? "text-theme" : "text-secondary"}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
});

export default connect(mapStateToProps, {
  setCurrentChannel,
  setCurrentWorkspace,
  setAllMessages,
})(RoomDetailsElement);
