import React from "react";

import { connect } from "react-redux";
import { setShowPRF } from "../../../actions";

const OpenPRFButton = ({setShowPRF}) => {
  return (
    <div className="p-3 px-5">
      <div className="text-center font-weight-bold">Patient details form</div>
      <div className="text-center my-2">
        <span className="px-4 btn btn-sm btn-primary rounded-pill" onClick={()=> setShowPRF(true)} >Open</span>
      </div>
      <div className="text-center text-muted">
        <small>
          <i>
            Doctor has shared patient details form.
            <br />
            Please fill this form...
          </i>
        </small>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, { setShowPRF })(OpenPRFButton);
