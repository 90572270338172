import React from "react";
import { Button, Form, InputGroup, FormControl, Spinner, Badge } from "react-bootstrap";
import firebase from "firebase";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

class Reading extends React.Component {
    state = {
        errors: [],
        admin: false,
        responses: [],
        links: [],
        files: []
    };

    getResponses = (responses) => {
        responses && Object.entries(responses).forEach(([key, value]) => {
            this.setState({ responses: [...this.state.responses, { uid: key, ...value }] })
        })
    }

    getName = (key) => {
        let name = "";
        firebase.database().ref("users").child(key).on("value", snap => {
            if (snap.val()) {
                name = snap.val().name ? snap.val().name : `${snap.val().firstName} ${snap.val().lastName}`;
            }
        });
        return name;
    }

    timeFromNow = timestamp => {
        if (moment(Date.now()).diff(moment(timestamp), 'days') < 1) return moment(timestamp).fromNow()
        else if (moment(Date.now()).diff(moment(timestamp), 'days') < 2) return "yesterday"
        else if (moment(Date.now()).diff(moment(timestamp), 'days') < 7) return moment(timestamp).format('dddd')
        else return moment(timestamp).format("MMM DD YYYY")
    };

    displayResponse = (response) => {
        return (
            <div className="mb-3">
                <h5 className="mt-0">{this.getName(response.uid)}</h5>
                {response.value.map((answer, key) => (
                    <div>
                        <p className="font-weight-bold mb-0">Q: {this.props.item.questions[key]}</p>
                        <p className="mt-0 mb-1">A: {answer.value}</p>
                        <a className="mt-0 mb-0" href={answer.file} target="_blank">Click here to see file attached</a>
                    </div>
                ))}
                <p className="mb-3 mt-0 small">Replied <i>{this.timeFromNow(response.responseTime)}</i></p>
                <hr/>
            </div>
        )
    }

    componentDidMount() {
        const { user, item, currentChannel } = this.props
        if (user == item.user.id) {
            this.setState({ admin: true });
            firebase.database().ref("messages/"+currentChannel.id).child(item.uid).on("value", snap => {
                snap.val() && this.getResponses(snap.val().response)
            });
            // item.response && this.getResponses(item.response)
        }
    }

    getResult = (response, user) => {
        return response && Object.keys(response).includes(user)
    };

    getValue = (response, user) => {
        return response && 
        <div>
            <Badge variant="secondary">Your answer</Badge>
            <br />
            {response[user].value.map((answer, key) => (
                <div>
                    <p className="font-weight-bold mb-0">Q: {this.props.item.questions[key]}</p>
                    <p className="mt-0 mb-1">A: {answer.value}</p>
                    <a className="mt-0 mb-0" href={answer.file} target="_blank">Click here to see file attached</a>
                </div>
            ))}
            <p className="mb-3 mt-0 small">Replied <i>{this.timeFromNow(response[user].responseTime)}</i></p>
        </div>
    }

    render() {
        const { uid, taskResponse, item, user, open_task_branch, open_task_id } = this.props;
        const { admin, responses } = this.state;

        const handleRead = (element, key) => {
            console.log(element, key)
        }

        return (
            <>
                <div className="taskBox">
                    <Form className="project">
                        <div className="d-flex">
                            <h5 className="mt-0 flex-grow-1">Reading</h5>
                            <div>{item.deadline < Date.now() ? <Badge variant="secondary">Closed</Badge> : <Badge variant="primary">Active</Badge>}</div>
                            {(open_task_branch === "saved" && open_task_id === item.uid) && <div className="mx-1"><Badge variant="success">Saved</Badge></div>}
                        </div>
                        {admin
                        ? item.readings.map((reading, key) => (
                            <div className="mb-2">
                                <p className="mb-1">
                                    {reading.link 
                                        ? <span>{key + 1}. <a href={(reading.link.indexOf("http://") == 0 || reading.link.indexOf("https://") == 0) ? reading.link : `https://${reading.link}`} target="_blank" onClick={() => handleRead("link", key)}>{reading.title} <FontAwesomeIcon icon={faLink} /></a></span> 
                                        : <span>{key + 1}. {reading.title}</span>
                                }</p>
                                <p className="mb-1">
                                    {reading.file
                                        ? <span><a href={reading.file} target="_blank" onClick={() => handleRead("link", key)} className="btn btn-sm btn-outline-dark"><FontAwesomeIcon icon={faPaperclip} /> Attachment</a></span>
                                        : null
                                }</p>
                            </div>)
                        )
                        : null}
                        {admin
                            ? responses.length
                                ? <Badge variant="secondary">Responses</Badge> : <p className="mt-4 mb-1">No responses yet</p>
                            : null
                        }
                        {admin
                            ? responses.map(response => {
                                return this.displayResponse(response)
                            })
                            : item && this.getResult(item.response, user)
                                ? <div>{this.getValue(item.response, user)}</div>
                                : <>
                                    {item.readings.map((reading, key) => (
                                        <div className="mb-2">
                                            <p className="mb-1">
                                                {reading.link 
                                                    ? <span>{key + 1}. <a href={(reading.link.indexOf("http://") == 0 || reading.link.indexOf("https://") == 0) ? reading.link : `https://${reading.link}`} target="_blank" onClick={() => handleRead("link", key)}>{reading.title} <FontAwesomeIcon icon={faLink} /></a></span> 
                                                    : <span>{key + 1}. {reading.title}</span>
                                            }</p>
                                            <p className="mb-1">
                                                {reading.file
                                                    ? <span><a href={reading.file} target="_blank" onClick={() => handleRead("link", key)} className="btn btn-sm btn-outline-dark"><FontAwesomeIcon icon={faPaperclip} /> Attachment</a></span>
                                                    : null
                                            }</p>
                                        </div>)
                                    )}
                                    {/* <Button variant="primary" size="sm" onClick={() => taskResponse(uid)} >Submit</Button> */}
                                </>
                        }
                    </Form>
                </div>
            </>
        )
    }
};

const mapStateToProps = state => ({
    currentChannel: state.channel.currentChannel,
    open_task_branch: state.platform.open_task_branch,
    open_task_id: state.platform.open_task_id
})

export default connect(mapStateToProps)(Reading);