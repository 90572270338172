import React, { Component } from "react";
import firebase from "firebase";
import { connect } from "react-redux";
import IsAdmin from "../../IsAdmin";
import AddFolder from "./AddFolderNew";
import FolderContent from "./FolderContent";
import {API_BASE_URL, FOLDER_LIST} from "../../../config/index";
import axios from "axios";
import { Spinner, Button, ButtonGroup, InputGroup, Form } from "react-bootstrap";
import { setContentType, resetData } from "../../../actions/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBars,
  faThLarge,
  faSearch,
  faTimes,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faSortAlphaUpAlt,
  faSortAlphaDownAlt,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

class Folder extends Component {
  state = {
    allFolders: [],
    isLoading: true,
    view: 0,
    folder: null,
    displayType: 0,
    searchTerm: "" ,
    regex: "",
    sortType: "",
    sortByAlpha: "desc",
  };
  componentDidMount() {
    const { currentChannel, currentWorkspace } = this.props;
    let allFolders = [];
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/folders`
      ).orderBy("timestamp", "desc");
    // const query = firebase.firestore().collection("folders");
    query.onSnapshot((snapshot) => {

      axios.post(`${API_BASE_URL}${FOLDER_LIST}`, {
        workspace_id: currentWorkspace.workspaceId,
        room_id: currentChannel.roomId,
      }).then((res) => {
          if(res.status === 200){
            this.setState({ allFolders: res.data.data.foldersList, isLoading: false, sortType: res.data.data.sortType});
          }
      })
      /*let changes = snapshot.docChanges();
      console.log(changes);
      changes.forEach((change) => {
        if (change.type === "added") {
          if (change.doc.exists) {
            let data = change.doc.data();
            let json = { ...data, id: change.doc.id };
            allFolders.unshift(json);
          }
        }
      });
      this.setState({ allFolders, isLoading: false });*/
    });
  }

  sortFolder = () => {
    const { currentChannel, currentWorkspace } = this.props;
    let allFolders = [];
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/folders`
      ).orderBy("timestamp", "desc");
    // const query = firebase.firestore().collection("folders");
    query.onSnapshot((snapshot) => {
      if(this.state.sortType === "asc"){
        axios.post(`${API_BASE_URL}${FOLDER_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          sort_by: "timestamp",
          sort_type: "asc"
        }).then((res) => {
            if(res.status === 200){
              this.setState({ allFolders: res.data.data.foldersList, isLoading: false});
            }
        })
      }else if(this.state.sortType === "desc"){
        axios.post(`${API_BASE_URL}${FOLDER_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
        }).then((res) => {
            if(res.status === 200){
              this.setState({ allFolders: res.data.data.foldersList, isLoading: false});
            }
        })
      }
    })
  }

  sortByAlphabet = () => {
    const { currentChannel, currentWorkspace } = this.props;
    let allFolders = [];
    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/folders`
      ).orderBy("timestamp", "desc");
    // const query = firebase.firestore().collection("folders");
    query.onSnapshot((snapshot) => {
      if(this.state.sortByAlpha === "desc"){
        axios.post(`${API_BASE_URL}${FOLDER_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          sort_by: "folderName",
          sort_type: "desc"
        }).then((res) => {
            if(res.status === 200){
              this.setState({ allFolders: res.data.data.foldersList, isLoading: false});
            }
        })
      }else if(this.state.sortByAlpha === "asc"){
        axios.post(`${API_BASE_URL}${FOLDER_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          sort_by: "folderName",
          sort_type: "asc"
        }).then((res) => {
          if(res.status === 200){
              this.setState({ allFolders: res.data.data.foldersList, isLoading: false});
            }
        })
      }
    })
  }

  addFolderView = (view) => {
    this.setState({ view });
  };
  handleFolder = (folder) => {
    this.setState({ folder });
  };
  render() {
    const { allFolders, isLoading, view, folder, displayType } = this.state;
    const { setContentType, allParticipants } = this.props;

    if (view === 1) {
      return (
        <>
          <div className="m-2 rounded p-2 bg-card-theme text-theme">
            <div className="d-flex">
              <div>
                <FontAwesomeIcon
                  onClick={() => this.setState({ view: 0 })}
                  icon={faArrowLeft}
                  className="mr-2 pointer"
                />
                <span className="d-inline-block"> Create Folder</span>
              </div>
            </div>
          </div>
          <AddFolder addFolderView={this.addFolderView} />
        </>
      );
    } else if (view === 0) {
      if (folder) {
        return (
          <FolderContent
            folderName={folder.folderName}
            folderId={folder.id}
            addFolderView={this.addFolderView}
            handleFolder={this.handleFolder}
            allParticipants={allParticipants}
          />
        );
      } else {
        return (
          <>
            <div className="m-2 rounded p-2 bg-card-theme text-theme">
              <div className="d-flex">
                <div>
                  <FontAwesomeIcon
                    onClick={() => setContentType(null)}
                    icon={faArrowLeft}
                    className="mr-2 pointer"
                  />
                  <span className="d-inline-block"> Folders</span>
                </div>
              </div>

              <IsAdmin>
                <div className="mt-2">
                  <div className="d-flex">
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={() => this.addFolderView(1)}
                      className="pt-0 w-50"
                    >
                      <small>+ CREATE NEW FOLDER</small>
                    </Button>

                    {/* <span className="mt-2 ml-3 text-secondary pointer smaller">
                  <FontAwesomeIcon icon={faQuestionCircle} /> WATCH TUTORIAL
                </span> */}
                  </div>
                </div>
              </IsAdmin>
            </div>
            <div>
              {allFolders.length > 0 ? (
                <div className="d-flex justify-content-end m-2">
                  <ButtonGroup size="sm">
                    <Button
                      variant={
                        displayType === 0 ? "secondary" : "outline-secondary"
                      }
                      onClick={() => this.setState({ displayType: 0 })}
                      title="List view"
                    >
                      <FontAwesomeIcon icon={faBars} />
                    </Button>
                    <Button
                      variant={
                        displayType === 1 ? "secondary" : "outline-secondary"
                      }
                      onClick={() => this.setState({ displayType: 1 })}
                      title="Thumbnail view"
                    >
                      <FontAwesomeIcon icon={faThLarge} />
                    </Button>
                  </ButtonGroup>
                </div>
              ) : null}
              {isLoading ? (
                <>
                  <div className="shadow d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="light" />
                  </div>
                </>
              ) : (
                <div className="">
                  <div className="d-flex justify-content-between ">
                  <InputGroup className="my-1 outerPill">
                    <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                      <div className="py-2 pr-2 pl-3">
                        <FontAwesomeIcon
                          icon={faSearch}
                          size="sm"
                          color="#aeaeae"
                        />
                      </div>
                      <div className="flex-grow-1">
                        <Form.Control
                          type="text"
                          id="participantsSearch"
                          className="border-0 px-0 bg-theme text-theme rounded"
                          onChange={(e) => {
                            this.setState({ searchTerm: e.target.value }, () => {
                              const reg = _.escapeRegExp(this.state.searchTerm);
                              // console.log(reg,"Reg")
                              const regex = new RegExp(reg, "gi");
                              this.setState({ regex: regex })
                            })
                          }}
                          placeholder={"Search"}
                          value={this.state.searchTerm}
                          style={{ margin: 0 }}
                        />
                      </div>
                      {this.state.searchTerm.length > 0 && (
                        <div
                          className="py-2 pl-2 pr-3"
                          onClick={() => this.setState({ searchTerm: "" })}
                        >
                          <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                        </div>
                      )}
                    </div>
                  </InputGroup>
                    {this.state.sortType =="desc" ? (
                      <Button 
                        size="sm"
                        className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                        variant={this.props.darkTheme ? "dark" : "light"}
                        onClick={() => {
                          this.sortFolder();
                          this.setState({sortType: "asc"})
                        }}
                      >
                      <FontAwesomeIcon icon={faSortAmountUpAlt} color="#aeaeae" />
                    </Button>
                    ): null}
                    {this.state.sortType === "asc" ? (
                      <Button 
                        size="sm"
                        className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                        variant={this.props.darkTheme ? "dark" : "light"}
                        onClick={() => {
                          this.sortFolder();
                          this.setState({sortType: "desc"})
                        }}
                    >
                      <FontAwesomeIcon icon={faSortAmountDownAlt} color="#aeaeae" />
                    </Button>
                    ): null}

                    {this.state.sortByAlpha === "desc" ? (
                      <Button 
                        size="sm"
                        className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                        variant={this.props.darkTheme ? "dark" : "light"}
                        onClick={() => {
                          this.sortByAlphabet();
                          this.setState({sortByAlpha: "asc"})
                        }}
                    >
                      <FontAwesomeIcon icon={faSortAlphaDownAlt} color="#aeaeae" />
                    </Button>
                        ):(null)}

                  {this.state.sortByAlpha === "asc" ? (
                    <Button 
                      size="sm"
                      className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                      variant={this.props.darkTheme ? "dark" : "light"}
                      onClick={() => {
                        this.sortByAlphabet();
                        this.setState({sortByAlpha: "desc"})
                      }}
                    >
                      <FontAwesomeIcon icon={faSortAlphaUpAlt} color="#aeaeae" />
                    </Button>
                  ):(null)}         

                  </div>
                  {this.state.searchTerm && this.state.searchTerm.length > 0  ? (
                      <>
                        {allFolders.length > 0 ? (
                          allFolders.filter((item) => {
                            return item.folderName?.match(this.state.regex)
                          }).map((folder) => (
                            <div
                              // className="col-3 p-1 rounded border-1 bg-theme pointer d-flex flex-column"
                              className={
                                displayType === 0
                                  ? "col-12 p-1 rounded border-1 bg-theme pointer d-flex align-items-center"
                                  : "col-3 p-1 rounded border-1 bg-theme pointer d-flex flex-column"
                              }
                              key={folder.id}
                              onClick={() => this.handleFolder(folder)}
                            >
                              <div className="d-flex justify-content-center">
                                <img
                                  src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/folder-blue-512.png"
                                  className={displayType === 0 ? "" : "w-75"}
                                  title={folder.folderName}
                                  style={
                                    displayType === 0
                                      ? { widht: "20px", height: "20px" }
                                      : {}
                                  }
                                />
                              </div>
                              <div className="d-flex justify-content-center">
                                <p
                                  title={folder.folderName}
                                  className={
                                    displayType === 0
                                      ? "text-theme ml-2 mb-0 small"
                                      : "text-theme ml-2 mb-0 small text-truncate"
                                  }
                                  // className="text-theme ml-2 mb-0 small text-truncate"
                                >
                                  {folder.folderName}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col">
                            <p className="text-theme text-center small">
                              No folder created yet
                            </p>
                          </div>
                        )}
                      </>
                    ):(
                      <>
                        {allFolders.length > 0 ? (
                          allFolders.map((folder) => (
                            <div
                              // className="col-3 p-1 rounded border-1 bg-theme pointer d-flex flex-column"
                              className={
                                displayType === 0
                                  ? "col-12 p-1 rounded border-1 bg-theme pointer d-flex align-items-center"
                                  : "col-3 p-1 rounded border-1 bg-theme pointer d-flex flex-column"
                              }
                              key={folder.id}
                              onClick={() => this.handleFolder(folder)}
                            >
                              <div className="d-flex justify-content-center">
                                <img
                                  src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/folder-blue-512.png"
                                  className={displayType === 0 ? "" : "w-75"}
                                  title={folder.folderName}
                                  style={
                                    displayType === 0
                                      ? { widht: "20px", height: "20px" }
                                      : {}
                                  }
                                />
                              </div>
                              <div className="d-flex justify-content-center">
                                <p
                                  title={folder.folderName}
                                  className={
                                    displayType === 0
                                      ? "text-theme ml-2 mb-0 small"
                                      : "text-theme ml-2 mb-0 small text-truncate"
                                  }
                                  // className="text-theme ml-2 mb-0 small text-truncate"
                                >
                                  {folder.folderName}
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="col">
                            <p className="text-theme text-center small">
                              No folder created yet
                            </p>
                          </div>
                        )}
                      </>
                    )}
                </div>
              )}
            </div>
          </>
        );
      }
    }
  }
}
const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
  currentUser: state.user.currentUser,
  darkTheme: state.platform.darkTheme,
});
export default connect(mapStateToProps, {
  setContentType,
  resetData,
})(Folder);
