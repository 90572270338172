import React, { Component } from "react";
import firebase from "firebase";
import moment from "moment";

import {
  Form,
  Button,
  Spinner,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

import { connect } from "react-redux";
import { publishMarks, publishRemark } from "./actions";
import DisplayAnswers from "./DisplayAnswers";

export class DisplayResponse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remark: {},
      grade: {},
      loading: true,
      content: "",
      marks: 0,
      correctCount: 0,
      falseCount: 0,
      responserName: "",
      responsedAnswers: [],
      showAns: false,
    };
  }

  componentDidMount() {
    const { response } = this.props;

    let docRef = firebase.firestore().collection("users").doc(`${response.id}`);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          //console.log(doc.data());
          this.getCorrectMatrix(response);
          this.setState({ responserName: data.firstName });
        } else {
          console.log("not exists");
        }
      })
      .catch((error) => console.error(error));

    this.setState({ loading: false });
  }

  /*getPoints = (correctCount) => {
        const { quiz_data } = this.props;

        let marks = [];
        correctCount.map((item, key) => (
            item && quiz_data.totalWeight && marks.push(quiz_data.quizQuestions[key].weight)
        ));
        return marks;
    }*/

  getCorrectMatrix = (response) => {
    const { quiz_data } = this.props;
    {
      /* 
        response.value.map((item, key) => (
            (quiz_data.quizQuestions[key].type === 1)

            ? quiz_data.correct_answers[key].length === Object.values(quiz_data.correct_answers[key]).filter(a => Object.values(item).includes(a)).length ? correctCount.push(true) : correctCount.push(false)

            : item == quiz_data.correct_answers[key] ? correctCount.push(true) : correctCount.push(false)
        ));*/
    }
    // console.log(quiz_data);
    let correctAnswersCount = 0;
    let falseAnswersCount = 0;
    let responsedAnswers = [];
    // console.log(response);
    Object.keys(quiz_data.quizQuestions).map((q, k) => {
      // console.log(response);
      let mulAnswers = [];
      let sinAnswer = "";
      if (response.answers[q] && response.answers[q].correct === true) {
        correctAnswersCount++;
        if (quiz_data.quizQuestions[q].type === 0) {
          sinAnswer =
            quiz_data.quizQuestions[q].options[
              Object.keys(response.answers[q].options)
            ].optionValue;
        } else {
          Object.keys(response.answers[q].options).map((opt) => {
            //console.log(opt);
            mulAnswers.push(
              quiz_data.quizQuestions[q].options[opt].optionValue
            );
          });
        }
      } else if (response.answers[q] && response.answers[q].correct === false) {
        falseAnswersCount++;
        if (quiz_data.quizQuestions[q].type === 0) {
          sinAnswer =
            quiz_data.quizQuestions[q].options[
              Object.keys(response.answers[q].options)
            ].optionValue;
        } else {
          Object.keys(response.answers[q].options).map((opt) => {
            // console.log(opt);
            mulAnswers.push(
              quiz_data.quizQuestions[q].options[opt].optionValue
            );
          });
        }
      }
      //console.log(mulAnswers);
      //console.log(sinAnswer);
      let json = {};
      if (sinAnswer) {
        json = {
          question: quiz_data.quizQuestions[q].question,
          options: sinAnswer,
        };
      }
      if (mulAnswers.length > 0) {
        json = {
          question: quiz_data.quizQuestions[q].question,
          options: [...mulAnswers],
        };
      }
      if (sinAnswer === "" && mulAnswers.length === 0) {
        json = { question: quiz_data.quizQuestions[q].question, options: "" };
      }
      responsedAnswers.push(json);
    });
    // console.log(correctAnswersCount);
    // console.log(falseAnswersCount);
    this.setState({
      correctCount: correctAnswersCount,
      falseCount: falseAnswersCount,
      responsedAnswers,
    });
  };

  render() {
    const {
      quiz_data,
      response,
      currentChannel,
      currentUser,
      publishMarks,
      publishRemark,
    } = this.props;
    //console.log(response);
    //console.log(quiz_data);
    const {
      correctCount,
      falseCount,
      content,
      marks,
      loading,
      grade,
      remark,
      responserName,
      responsedAnswers,
      showAns,
    } = this.state;
    // console.log(quiz_data);
    //console.log(response);
    //console.log(responsedAnswers);
    return (
      <div>
        <hr className="my-2" />
        <p className="mb-0 h6">{responserName}</p>
        <p className="small my-0">
          Responded{" "}
          <i>
            {moment(response.responseTimestamp).format("hh:mm A MMM DD YYYY")}
          </i>
        </p>
        <div>
          {/*<strong>Answer</strong>
          {correctCount ? (
            <p className="mb-1">
              <span className="font-weight-bold">Score:</span>{" "}
              {(correctCount / Object.keys(quiz_data.quizQuestions).length) *
                100}
              %
            </p>
          ) : (
            <p className="mb-1">
              <span className="font-weight-bold">Score:</span>
              {" 0%"}
            </p>
          )}*/}

          {/*<div className="row">
            <div className="col-4 d-flex flex-column">
              <div className="d-flex align-items-center justify-content-center">
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="text-success"
                />
                <p className="font-weight-bold px-2 m-0">
                  {correctCount}
                  {" / "} {Object.keys(quiz_data.quizQuestions).length}
                </p>
              </div>
              <p className="small text-muted text-center m-0">Correct</p>
            </div>

            <div className="col-4 border-right border-left border-secondary d-flex flex-column">
              <div className="d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faCircle} className="text-info" />
                <p className="font-weight-bold px-2 m-0">
                  {Object.keys(quiz_data.quizQuestions).length -
                    (correctCount + falseCount)}{" "}
                  / {Object.keys(quiz_data.quizQuestions).length}
                </p>
              </div>
              <p className="small text-muted text-center m-0">Skipped</p>
            </div>

            <div className="col-4 d-flex flex-column">
              <div className="d-flex align-items-center justify-content-center">
                <FontAwesomeIcon icon={faTimesCircle} className="text-danger" />
                <p className="font-weight-bold px-2 m-0">
                  {falseCount} {" / "}{" "}
                  {Object.keys(quiz_data.quizQuestions).length}
                </p>
              </div>
              <p className="small text-muted text-center m-0">Wrong</p>
            </div>
          </div>*/}

          {/* {responsedAnswers.length > 0 && showAns ? (*/}
          {/* <div className="row py-2 px-3">*/}
          {/*  <h6>Selected Answers</h6>*/}
          {/*<DisplayAnswers response={response} quiz_data={quiz_data} />*/}
          {/*responsedAnswers.map((ans) => {
                return (
                  <div className="col-12 text-center py-2 px-2">
                    <div className="row py-2 px-2 mt-2">
                      Question:- {ans.question}
                    </div>
                    <div className="row py-2 px-2 mt-2">
                      {Array.isArray(ans.options)
                        ? ans.options.join(",")
                        : ans.options}
                    </div>
                  </div>
                );
              })*/}
          {/*<div className="col-12 px-2 py-2">
                {Object.keys(quiz_data.quizQuestions).map((item, key) => (
                  <div className="row px-2" key={key}>
                    <p className="my-0">
                      <span className="font-weight-bold">Q{key + 1}.</span>{" "}
                      {quiz_data.quizQuestions[item].question}
                    </p>
                    {Object.keys(quiz_data.quizQuestions[item].options).map(
                      (o, k) => (
                        <div
                          class={
                            response.answers[item] &&
                            Object.keys(
                              response.answers[item].options
                            ).includes(o)
                              ? "form-check rounded mb-2 border-dark"
                              : "form-check rounded mb-2"
                          }
                          key={k}
                        >
                          <input
                            class="form-check-input"
                            type={
                              quiz_data.quizQuestions[item].type === 0
                                ? "radio"
                                : "checkbox"
                            }
                            value={
                              quiz_data.quizQuestions[item].options[o]
                                .optionValue
                            }
                            name={
                              quiz_data.createdBy +
                              "_" +
                              quiz_data.quizQuestions[item].options[o]
                                .optionValue +
                              k
                            }
                            id={
                              quiz_data.createdBy +
                              "_" +
                              k +
                              "_" +
                              key +
                              "_" +
                              item
                            }
                            checked={
                              response.answers[item] &&
                              Object.keys(
                                response.answers[item].options
                              ).includes(o)
                            }
                            disabled={true}
                          />

                          <label
                            class="form-check-label"
                            htmlFor={quiz_data.createdBy + "_" + k + "_" + key}
                          >
                            {
                              quiz_data.quizQuestions[item].options[o]
                                .optionValue
                            }
                          </label>
                        </div>
                      )
                    )}
                  </div>
                ))}
                          </div>*/}
          {/* </div>
         // ) : null}*/}
          {quiz_data.totalWeight && this.getPoints(correctCount) && (
            <p className="mb-1">
              <span className="font-weight-bold">Quiz points:</span>{" "}
              {this.getPoints(correctCount).reduce((a, b) => a + b, 0)}
            </p>
          )}

          {quiz_data.totalWeight &&
            (loading ? (
              <Spinner size="sm" animation="border" />
            ) : grade && grade.status ? (
              <p className="mb-1">
                <span className="font-weight-bold">Points earned:</span>{" "}
                {grade.marks} out of {quiz_data.totalWeight}
              </p>
            ) : (
              <Form className="mb-3">
                <InputGroup className="w-100 mb-1">
                  <FormControl
                    type="number"
                    min="0"
                    value={this.getPoints(correctCount).reduce(
                      (a, b) => a + b,
                      0
                    )}
                    max={quiz_data.totalWeight}
                    onChange={(e) => this.setState({ marks: e.target.value })}
                  />

                  <InputGroup.Append>
                    <InputGroup.Text>
                      <small>/ {quiz_data.totalWeight}</small>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>

                <Button
                  onClick={() =>
                    publishMarks(
                      quiz_data.uid,
                      response.uid,
                      marks,
                      currentChannel.id,
                      currentUser.uid
                    )
                  }
                  variant="primary"
                  style={{ width: "100px", marginRight: "5px" }}
                  size="sm"
                >
                  <small>Confirm</small>
                </Button>
              </Form>
            ))}
          {quiz_data.totalWeight &&
            (loading ? (
              <Spinner size="sm" animation="border" />
            ) : remark && remark.status ? (
              <p className="mb-1">
                <span className="font-weight-bold">Remarks:</span>{" "}
                {remark.content}
              </p>
            ) : (
              <Form>
                <Form.Group className="mb-1">
                  <Form.Control
                    as="textarea"
                    rows="2"
                    size="sm"
                    placeholder="Add remark for the student"
                    onChange={(e) => this.setState({ content: e.target.value })}
                  />
                </Form.Group>

                <div className="d-flex">
                  {/* <Button
                                onClick={() => saveRemark(quiz_uid, response.uid, content, currentChannel.id)}
                                variant="success" 
                                style={{width: "100px", marginRight: "5px"}}
                                size="sm"><small>Save</small></Button> */}

                  {/*<Button
                    onClick={() =>
                      publishRemark(
                        quiz_data.uid,
                        response.uid,
                        content,
                        currentChannel.id,
                        currentUser.uid
                      )
                    }
                    variant="primary"
                    style={{ width: "100px", marginRight: "5px" }}
                    size="sm"
                  >
                    <small>Publish</small>
                  </Button>*/}
                </div>
              </Form>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps, { publishMarks, publishRemark })(
  DisplayResponse
);
