import React, { useEffect, useState } from "react";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
const DisplayAnswers = (props) => {
  //console.log(props.response);
  let [id, setId] = useState(null);
  const [correctCount, setCorrectCount] = useState(0);
  const [falseCount, setFalseCount] = useState(0);
  const [responsedAnswers, setResponsedAnswers] = useState([]);
  useEffect(() => {
    const { quiz_data, response } = props;

    if (quiz_data && response) {
      let correctAnswersCount = 0;
      let falseAnswersCount = 0;
      let responsedAnswers = [];
      Object.keys(quiz_data.quizQuestions)
        .sort(
          (a, b) =>
            quiz_data.quizQuestions[a].sequenceNo -
            quiz_data.quizQuestions[b].sequenceNo
        )
        .map((q, k) => {
          // console.log(response);
          let mulAnswers = [];
          let sinAnswer = "";
          if (response.answers[q] && response.answers[q].correct === true) {
            correctAnswersCount++;
            if (quiz_data.quizQuestions[q].type === 0) {
              sinAnswer =
                quiz_data.quizQuestions[q].options[
                  Object.keys(response.answers[q].options)
                ].optionValue;
            } else {
              Object.keys(response.answers[q].options).map((opt) => {
                //console.log(opt);
                mulAnswers.push(
                  quiz_data.quizQuestions[q].options[opt].optionValue
                );
              });
            }
          } else if (
            response.answers[q] &&
            response.answers[q].correct === false
          ) {
            falseAnswersCount++;
            if (quiz_data.quizQuestions[q].type === 0) {
              sinAnswer =
                quiz_data.quizQuestions[q].options[
                  Object.keys(response.answers[q].options)
                ].optionValue;
            } else {
              Object.keys(response.answers[q].options).map((opt) => {
                // console.log(opt);
                mulAnswers.push(
                  quiz_data.quizQuestions[q].options[opt].optionValue
                );
              });
            }
          }
          //console.log(mulAnswers);
          //console.log(sinAnswer);
          let json = {};
          if (sinAnswer) {
            json = {
              question: quiz_data.quizQuestions[q].question,
              options: sinAnswer,
            };
          }
          if (mulAnswers.length > 0) {
            json = {
              question: quiz_data.quizQuestions[q].question,
              options: [...mulAnswers],
            };
          }
          if (sinAnswer === "" && mulAnswers.length === 0) {
            json = {
              question: quiz_data.quizQuestions[q].question,
              options: "",
            };
          }
          responsedAnswers.push(json);
        });
      // console.log(responsedAnswers);
      setCorrectCount(correctAnswersCount);
      setFalseCount(falseAnswersCount);
      setResponsedAnswers(responsedAnswers);
    }
  }, [props.response.id !== id]);
  if (props.response.id !== id) {
    setId(props.response.id);
  }
  //console.log(id);
  const { quiz_data, response } = props;
  //console.log(response.answers);
  //console.log(responsedAnswers);

  return (
    <div className="container px-3">
      {responsedAnswers.length > 0 && (
        <>
          <div>
            {/* <strong>Answer</strong> */}
            {correctCount ? (
              <p className="mb-1">
                <span className="font-weight-bold">Score:</span>{" "}
                {(correctCount / Object.keys(quiz_data.quizQuestions).length) *
                  100}
                %
              </p>
            ) : (
              <p className="mb-1">
                <span className="font-weight-bold">Score:</span>
                {" 0%"}
              </p>
            )}

            <div className="row">
              <div className="col-4 d-flex flex-column">
                <div className="d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  />
                  <p className="font-weight-bold px-2 m-0">
                    {correctCount}
                    {" / "} {Object.keys(quiz_data.quizQuestions).length}
                  </p>
                </div>
                <p className="small text-muted text-center m-0">Correct</p>
              </div>

              <div className="col-4 border-right border-left border-secondary d-flex flex-column">
                <div className="d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faCircle} className="text-info" />
                  <p className="font-weight-bold px-2 m-0">
                    {Object.keys(quiz_data.quizQuestions).length -
                      (correctCount + falseCount)}{" "}
                    / {Object.keys(quiz_data.quizQuestions).length}
                  </p>
                </div>
                <p className="small text-muted text-center m-0">Skipped</p>
              </div>

              <div className="col-4 d-flex flex-column">
                <div className="d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-danger"
                  />
                  <p className="font-weight-bold px-2 m-0">
                    {falseCount} {" / "}{" "}
                    {Object.keys(quiz_data.quizQuestions).length}
                  </p>
                </div>
                <p className="small text-muted text-center m-0">Wrong</p>
              </div>
            </div>
          </div>
          <div className="row py-2 px-3">
            <h6>Selected Answers</h6>
            <div className="col-12 px-2 py-2">
              {Object.keys(quiz_data.quizQuestions)
                .sort(
                  (a, b) =>
                    quiz_data.quizQuestions[a].sequenceNo -
                    quiz_data.quizQuestions[b].sequenceNo
                )
                .map((item, key) => (
                  <div key={key}>
                    <p className="my-0">
                      <span className="font-weight-bold">Q{key + 1}.</span>{" "}
                      {quiz_data.quizQuestions[item].question}
                    </p>
                    <div className="d-flex">
                      {Object.keys(quiz_data.quizQuestions[item].options)
                        .sort(
                          (a, b) =>
                            quiz_data.quizQuestions[item].options[a]
                              .sequenceNo -
                            quiz_data.quizQuestions[item].options[b].sequenceNo
                        )
                        .map((o, k) => (
                          <div
                            className={
                              response.answers[item] &&
                              Object.keys(
                                response.answers[item].options
                              ).includes(o)
                                ? "form-check rounded mb-2 border-dark col"
                                : "form-check rounded mb-2 col"
                            }
                            key={k}
                          >
                            <input
                              className="form-check-input"
                              type={
                                quiz_data.quizQuestions[item].type === 0
                                  ? "radio"
                                  : "checkbox"
                              }
                              value={
                                quiz_data.quizQuestions[item].options[o]
                                  .optionValue
                              }
                              name={
                                quiz_data.createdBy +
                                "_" +
                                quiz_data.quizQuestions[item].options[o]
                                  .optionValue +
                                k
                              }
                              id={
                                quiz_data.createdBy +
                                "_" +
                                k +
                                "_" +
                                key +
                                "_" +
                                item
                              }
                              checked={
                                response.answers[item] && 
                                Object.keys(
                                  response.answers[item].options
                                ).includes(o) ||
                                (quiz_data.quizQuestions[item].question ===
                                  responsedAnswers[key]["question"] &&
                                  quiz_data.quizQuestions[item].options[o]
                                    .optionValue ===
                                    responsedAnswers[key]["options"])
                              }
                              //disabled={true}
                            />

                            <label
                              className="form-check-label"
                              htmlFor={
                                quiz_data.createdBy + "_" + k + "_" + key
                              }
                            >
                              {
                                quiz_data.quizQuestions[item].options[o]
                                  .optionValue
                              }
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DisplayAnswers;
