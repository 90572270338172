import React, { useEffect, useState, useRef } from "react";
import firebase from "firebase";
import {
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
  faMicrophone,
  faDesktop,
  faVolumeMute
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { setIsVideoVisible, setChannelDetails, setConferenceSession } from "../../actions/index";


import IsAdmin from "../IsAdmin";
import moment from 'moment'
import { ButtonGroup, Button } from "react-bootstrap";

function PublishersList({
  userName,
  userAvatar,
  channelId,
  currentChannel,
  participants,
  currentUser,
  currentWorkspace,
  maximiseVideo,
  minimiseVideo,
  setIsVideoVisible,
  setChannelDetails,
  currentSubscription,
  channelDetails,
  isPublisher,
  setConferenceSession,
  
}) {


    const [publishersWaiting, setPublishersWaiting] = useState([]);
    const [currentPublishers, setCurrentPublishers] = useState([]);
    const [active, setActive] = useState(1);



    const waitingToPublish = () => {
        firebase
            .firestore()
            .collection(
                `videoConference/${currentWorkspace.workspaceId}/rooms`
            )
            .doc(`${channelDetails.roomId}`)
            .onSnapshot(doc => {
                if (doc.exists) {
                    setPublishersWaiting(doc.data().waitingForPublishing)
                    setCurrentPublishers(doc.data().currentPublishers)
                }
            })
    }

    useEffect(() => {
        waitingToPublish()
    }, [])

    const publishRequestHandler = (type, index) => {

        firebase
            .firestore()
            .collection(
                `videoConference/${currentWorkspace.workspaceId}/rooms`
            )
            .doc(`${channelDetails.roomId}`).get().then((doc) => {
                if (doc.exists) {
                    const currentPublishers = doc.data().currentPublishers ? doc.data().currentPublishers : [];
                    const rejectedPublishers = doc.data().rejectedPublishers ? doc.data().rejectedPublishers : [];

                    const waitingForPublishing = doc.data().waitingForPublishing;

                    waitingForPublishing[index].requestCompletedAt = moment().valueOf();
                    waitingForPublishing[index].requestCompletedType = type;

                    if (type === "admit") {
                        currentPublishers.push(waitingForPublishing[index]);
                    }
                    else if (type === "reject") {
                        rejectedPublishers.push(waitingForPublishing[index]);
                    }
                    waitingForPublishing.splice(index, 1);

                    const query = firebase
                        .firestore()
                        .collection(
                            `videoConference/${currentWorkspace.workspaceId}/rooms`
                        )
                        .doc(`${channelDetails.roomId}`)
                        .set({
                            currentPublishers: currentPublishers,
                            waitingForPublishing: waitingForPublishing,
                            rejectedPublishers: rejectedPublishers
                        }, { merge: true })

                }
            })

    }

    const removePublisherHandler = (index) => {

        firebase
            .firestore()
            .collection(
                `videoConference/${currentWorkspace.workspaceId}/rooms`
            )
            .doc(`${channelDetails.roomId}`).get().then((doc) => {
                if (doc.exists) {
                    const currentPublishers = doc.data().currentPublishers ? doc.data().currentPublishers : [];

                    currentPublishers[index].requestCompletedAt = moment().valueOf();
                    currentPublishers[index].requestCompletedType = "removed";


                    const query = firebase
                        .firestore()
                        .collection(
                            `videoConference/${currentWorkspace.workspaceId}/rooms`
                        )
                        .doc(`${channelDetails.roomId}`)
                        .set({
                            currentPublishers: currentPublishers,
                        }, { merge: true })

                }
            })

    }
    

    return (
        <>


            {/* table for admin to allow subs to become publishers */}


                <ButtonGroup className="w-100">
                    <Button variant="dark" className={active ? "btn-sm active" : "btn-sm"} onClick={() => setActive(1)}>Participants</Button>
                    <Button variant="dark" className={!active ? "btn-sm active" : "btn-sm"}  onClick={() => setActive(0)}>
                        Waiting
                        {
                            publishersWaiting && publishersWaiting.length > 0 ?
                                <span class="badge badge-light ml-2">{publishersWaiting.length}</span>
                                : null
                        }
                    </Button>
                </ButtonGroup>
                {
                    !active ?
                        <div className="row m-2" style={{overflowY: "auto", overflowX: 'hidden', maxHeight: '100%'}}>
                            {/* <h6 className="text-theme small">Waiting to publish</h6> */}
                            {
                                publishersWaiting && publishersWaiting.map((publisher, index) => {
                                    if (!publisher.requestCompletedType)
                                        return (
                                            <div className="col-12 rounded-lg py-1 m-1 bg-card-theme shadow-sm text-theme">
                                                <div className="d-flex align-items-center p-2 w-100">
                                                    <img className="mr-2 rounded-circle" src={publisher.photoUrl} style={{ width: '50px', height: '50px' }}></img>
                                                    <div className="d-flex flex-column">
                                                        <p className="mb-0 small">{publisher.displayName}</p>
                                                        <p className="mb-0 small">{moment(publisher.requestedAt).format('LLL')}</p>
                                                    </div>
                                                    <button className="btn btn-sm btn-primary mx-2" onClick={() => publishRequestHandler("admit", index)}>Admit</button>
                                                    <button className="btn btn-sm btn-primary mx-2" onClick={() => publishRequestHandler("reject", index)}>Reject</button>
                                                </div>
                                            </div>
                                        )
                                })
                            }
                        </div>
                        :
                        <div className="row m-2" style={{overflowY: "auto", overflowX: 'hidden', maxHeight: '100%'}}>
                            {/* <h6 className="text-theme small">Currently publishing</h6> */}
                            {
                                currentPublishers && currentPublishers.map((publisher, index) => {
                                    return (
                                        <div className="col-12 rounded-lg py-1 m-1 bg-card-theme shadow-sm text-theme">
                                            <div className="d-flex align-items-center p-2 w-100">
                                                <img className="mr-2 rounded-circle" src={publisher.photoUrl} style={{ width: '50px', height: '50px' }}></img>
                                                <div className="d-flex flex-column">
                                                    <p className="mb-0 small">{publisher.displayName}</p>
                                                    <p className="mb-0 small">{moment(publisher.requestedAt).format('LLL')}</p>
                                                </div>
                                                <button className="btn btn-sm btn-primary mx-2" onClick={() => removePublisherHandler(index)}>Remove</button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                }


        </>
    );
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  participants: state.channel.participants,
  currentUser: state.user.currentUser,
  currentSubscription: state.subscription.currentSubscription,
  channelDetails: state.platform.channelDetails,
  isPublisher: state.platform.isPublisher
});

export default connect(mapStateToProps, {
  setIsVideoVisible,
  setChannelDetails,
  setConferenceSession
})(PublishersList);