import React, { useEffect, useState } from "react";

import {
  faCircleNotch,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import firebase from "firebase";

function PreviousAppointments({ workspaceId, roomId, Format24to12 }) {
  const [previousAppointments, setPreviousAppointments] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const Ref = firebase
    .firestore()
    .collection("caseSheet")
    .doc(workspaceId)
    .collection("rooms")
    .doc(roomId)
    .collection("caseSheet")
    .doc("caseSheetId")
    .collection("data")
    .doc("doctorData");

  useEffect(() => {
    Ref.collection("previousAppointments").onSnapshot((doc) => {
      let tempPreviousAppointments = [];
      if (!doc.empty) {
        doc.docs.map((item) => {
          tempPreviousAppointments.push({ ...item.data(), id: item.id });
        });
      }
      setPreviousAppointments(tempPreviousAppointments);
      setIsLoading(false);
    });
  }, []);

  const handleDelete = (id) => {
    Ref.collection("previousAppointments").doc(id).delete();
  };

  return isLoading ? (
    <div className="text-center text-success pt-5">
      <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-2x" />
    </div>
  ) : (
    <div>
      {previousAppointments.length ? (
        previousAppointments.map((item, idx) => (
          <div
            className="row m-0 my-2 bg-light rounded-lg font-13 text-dark"
            key={idx}
          >
            <div className="col-1 p-2 text-center">
              <b>{idx + 1}.</b>
            </div>
            <div className="col-4 p-2">
              {new Date(item.date).toDateString()}
            </div>
            <div className="col-4 p-2">{Format24to12(item.time)}</div>
            <div className="col-3 p-2">
              <span
                className="btn btn-sm btn-danger"
                onClick={() => handleDelete(item.id)}
              >
                <small>Remove</small>
              </span>
            </div>
          </div>
        ))
      ) : (
        <div className="text-center text-danger font-13 py-5">
          No previous appointments found!
        </div>
      )}
    </div>
  );
}

export default PreviousAppointments;
