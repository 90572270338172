import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowLeft,
  faTimes,
  faSearch,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faSortAlphaUp,
  faSortAlphaUpAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown, Button, Form, InputGroup } from "react-bootstrap";
import moment from "moment";
import {
  setQuizPlusTitle,
  setQuizPlusQuestions,
  setQuizPlusOptions,
  setQuizPlusPreviewImages,
  setQuizPlusOptionsImages,
  setQuizPlusRandomize,
  setQuizPlusDescriptions,
  setQuizPlusQuestionImages,
  setQuizPlusQuestionPreviewImages,
  setQuizPlusQuestionTypes,
  setQuizPlusTypes,
  setIsNotEditable,
  setShowDescriptionAfterSelecting,
  setPercentageVisibleToParticipants,
} from "./actions";
import {
  resetData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
  setTaskSharing,
  setSharedTask,
  setEditedTask,
  setTaskEditing,
} from "../../../actions/index";
import CreateQuiz from "./CreateQuizPlus";
import Quiz from "./QuizPlus";
import axios from "axios";
import IsAdmin from "../../IsAdmin";

import { toast } from "react-toastify";
import {
  API_BASE_URL,
  QUIZPLUS_CREATE,
  QUIZPLUS_SHARE,
  QUIZPLUS_REMOVE,
  QUIZPLUS_UPDATE,
  DRIVE_UPLOAD,
  APP_CLOSE,
  ROOM_APP_LIST,
} from "../../../config";
import _ from "lodash";
toast.configure();

class QuizPlusHomepage extends Component {
  state = {
    selected: "Published",
    publishedQuizes: [],
    publishedQuizesData: [],
    savedQuizes: [],
    savedQuizesData: [],
    editedQuiz: {},
    selectedQuiz: {},
    view: 0,
    error: "",
    sharedAgainQuiz: {},
    isNonEditable: false,
    searchTerm: "",
    regex: "",
    sortType: "unreverse",
    reversed: false,
  };

  async componentDidMount() {
    const { currentChannel, currentWorkspace, currentUser } = this.props;
    let savesQuizes = [];
    let publishedQuizes = [];

    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/saved`
      )
      .orderBy("timestamp", "desc");

    const queryMsg = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/messages`
      )
      .orderBy("timestamp", "desc");

    query.where("type", "==", 10).onSnapshot((snapshot) => {
      axios
        .post(`${API_BASE_URL}${ROOM_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          user_id: currentUser.uid,
          message_type: 10,
          app_state: "saved",
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ savedQuiz: res.data.data });
            this.handleQuizesData(res.data.data);
            this.setState({ sortType: "unreverse" });
          }
        });
    });

    queryMsg.where("type", "==", 10).onSnapshot((snapshot) => {
      axios
        .post(`${API_BASE_URL}${ROOM_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          user_id: currentUser.uid,
          message_type: 10,
          app_state: "published",
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ publishedQuiz: res.data.data });
            this.handlePublishQuizesData(res.data.data);
            this.setState({ sortType: "unreverse" });
          }
        });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.sharing && this.state.view === 0) {
      this.setState({ view: 1 });
      this.setState({ isNonEditable: prevProps.isNotEditable });
      setIsNotEditable(prevProps.isNotEditable);
    }
  }
  handleQuizesData = async (savedQuizes) => {
    let savedQuizesData = [];
    savedQuizesData = await Promise.all(
      savedQuizes.map(async (savedQuiz) => {
        const quizData = await firebase
          .firestore()
          .collection(`specialOlympics`)
          .doc(savedQuiz.contentId)
          .get();
        return {
          ...quizData.data(),
          id: quizData.id,
        };
      })
    );

    const sortedQuizData = _.sortBy(savedQuizesData, (quiz) =>
      quiz.specialOlympicName.toLowerCase()
    );

    if (this.state.sortType === "atoz") {
      savedQuizesData = sortedQuizData;
    }
    if (this.state.sortType === "ztoa") {
      savedQuizesData = sortedQuizData.reverse();
    }

    this.setState({ savedQuizes, savedQuizesData });

    if (savedQuizes.length === 0) {
      this.setState({ savedQuizes });
    }
  };

  handlePublishQuizesData = async (publishedQuizes) => {
    let publishedQuizesData = [];
    publishedQuizesData = await Promise.all(
      publishedQuizes.map(async (publishedQuiz) => {
        const quizData = await firebase
          .firestore()
          .collection(`specialOlympics`)
          .doc(publishedQuiz.contentId)
          .get();
        return {
          ...quizData.data(),
          id: quizData.id,
          messageId: publishedQuiz.id,
        };
      })
    );

    const sortedQuizData = _.sortBy(publishedQuizesData, (quiz) =>
      quiz.specialOlympicName.toLowerCase()
    );

    if (this.state.sortType === "atoz") {
      publishedQuizesData = sortedQuizData;
    }
    if (this.state.sortType === "ztoa") {
      publishedQuizesData = sortedQuizData.reverse();
    }

    this.setState({ publishedQuizes, publishedQuizesData });

    if (publishedQuizes.length === 0) {
      this.setState({ publishedQuizes });
    }
  };
  addQuizView = (view) => {
    this.setState({ view });
  };

  handleSelected = (selected) => {
    this.setState({ selected });
  };

  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));

  showSelectedQuiz = (id) => {
    let selectedQuiz = { id: id };
    this.setState({ selectedQuiz, view: 2 });
  };

  uploadImage = async (file, key) => {
    const { currentUser } = this.props;
    if (file) {
      const fileData = new FormData();
      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append("user_id", currentUser.uid);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );

        return res.data.data["file_url"];
      } catch (error) {}
    } else return "";
  };

  sortQuizPlusData = () => {
    const { sortType } = this.state;

    // Sorting: Timestamp ascending
    if (sortType === "reverse") {
      const { savedQuizesData, publishedQuizesData } = this.state;

      this.setState(
        {
          savedQuizesData: savedQuizesData.reverse(),
          publishedQuizesData: publishedQuizesData.reverse(),
        },
        () => {
          this.setState({ reversed: true });
        }
      );
    }

    // Sorting: Timestamp descending
    else if (sortType === "unreverse") {
      if (this.state.reversed) {
        const { savedQuizesData, publishedQuizesData } = this.state;

        this.setState({
          savedQuizesData: savedQuizesData.reverse(),
          publishedQuizesData: publishedQuizesData.reverse(),
        });
      } else {
        const { savedQuizesData, publishedQuizesData } = this.state;

        this.setState({
          savedQuizesData,
          publishedQuizesData,
        });
      }
      this.setState({ reversed: false });
    }

    // Sorting: A to Z
    else if (sortType === "atoz") {
      const { savedQuizesData, publishedQuizesData } = this.state;

      this.setState(
        {
          savedQuizesData: _.sortBy(savedQuizesData, (quiz) =>
            quiz.specialOlympicName.toLowerCase()
          ),
          publishedQuizesData: _.sortBy(publishedQuizesData, (quiz) =>
            quiz.specialOlympicName.toLowerCase()
          ),
        },
        () => {
          this.setState({ reversed: false });
        }
      );
    }

    // Sorting: Z to A
    else if (sortType === "ztoa") {
      const { savedQuizesData, publishedQuizesData } = this.state;

      this.setState(
        {
          savedQuizesData: _.sortBy(savedQuizesData, (quiz) =>
            quiz.specialOlympicName.toLowerCase()
          ).reverse(),
          publishedQuizesData: _.sortBy(publishedQuizesData, (quiz) =>
            quiz.specialOlympicName.toLowerCase()
          ).reverse(),
        },
        () => {
          this.setState({ reversed: false });
        }
      );
    }
  };

  render() {
    const {
      type,
      currentWorkspace,
      currentChannel,
      currentUser,
      quizPlusTitle,
      questions,
      options,
      deadline,
      channelUsers,
      folder,
      imageOptions,
      everyone,
      allParticipants,
      taskType,
      contentType,
      resetAllTasks,
      setTaskType,
      setContentType,
      resetData,
      setQuizPlusTitle,
      setQuizPlusQuestions,
      setQuizPlusOptions,
      setQuizPlusTypes,
      setQuizPlusDescriptions,
      setQuizPlusQuestionImages,
      setQuizPlusQuestionPreviewImages,
      setQuizPlusQuestionTypes,
      types,

      setTaskChannelUsers,
      setTaskEveryone,
      setTaskDeadline,
      sharing,
      sharedTask,
      setSharedTask,
      setTaskSharing,
      randomize,
      setTaskFolder,
      editedTask,
      editing,
      setEditedTask,
      setQuizPlusOptionsImages,
      setQuizPlusPreviewImages,
      setTaskEditing,
      questionImages,
      questionPreviewImages,
      questionTypes,
      descriptions,
      showDescriptionAfterSelecting,
      percentageVisibleToParticipants,
      isNotEditable,
      setIsNotEditable,
      setShowDescriptionAfterSelecting,
      setPercentageVisibleToParticipants,
    } = this.props;

    let {
      savedQuizes,
      savedQuizesData,
      editedQuiz,
      publishedQuizes,
      publishedQuizesData,
      selected,
      view,
      error,
      selectedQuiz,
      sharedAgainQuiz,
    } = this.state;
    console.log(savedQuizesData);
    console.log(publishedQuizesData);
    let coAdmins = allParticipants.filter((participant) => participant.isAdmin);

    const closeAppResponses = async (quiz) => {
      const { currentWorkspace, currentChannel, darkTheme } = this.props;
      console.log(quiz, "iddd");
      var message_id = quiz.messageId;
      var user = firebase.auth().currentUser;
      let res = await axios.post(`${API_BASE_URL}${APP_CLOSE}`, {
        workspace_id: currentWorkspace.workspaceId,
        room_id: currentChannel.roomId,
        user_id: user.uid,
        message_id: message_id,
      });
      if (res.status === 200) {
        if (darkTheme) {
          toast.dark("Closed", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast("Closed", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      } else {
        console.log(res);
      }
    };
    const shareTaskAgain = (messageData) => {
      console.log("my Data", messageData);
      let questions = [];
      let imgOption = [];
      let options = [];
      let types = [];
      let questionImages = [];
      // let questionPreviewImages=[];
      let questionTypes = [];
      let questionDescriptions = [];
      let showDescriptionAfter = [];
      let showPercentageToParticipant = [];

      let sorted = _.map(
        messageData.specialOlympicQuestions,
        function (value, key) {
          return _.defaults({ order: value.sequenceNo }, value);
        }
      );

      sorted = _.sortBy(sorted, "order");

      sorted.map((q, key) => {
        let queImgOptions = [];
        let queOptions = [];

        if (q.type) {
          types.push("multiple");
        } else {
          types.push("single");
        }

        questions.push(q.question);
        questionDescriptions.push(q.description ? q.description : "");
        questionImages.push(q.questionImage ? q.questionImage : "");
        questionTypes.push(q.questionCode);

        let sortedOptions = _.map(q.options, function (value, key) {
          return _.defaults({ order: value.sequenceNo }, value);
        });

        sortedOptions = _.sortBy(sortedOptions, "order");

        sortedOptions.map((o, k) => {
          //console.log(messageData.specialOlympicQuestions[q].options[o].optionValue);
          //console.log(messageData.specialOlympicQuestions[q].options[o].optionImage);
          queOptions.push(o.optionValue);
          queImgOptions.push(o.optionImage ? o.optionImage : null);
        });
        options.push(queOptions);
        imgOption.push(queImgOptions);
        showDescriptionAfter.push(q.showDescriptionAfter);
        showPercentageToParticipant.push(q.showPercentageToParticipant);
      });

      console.log(questions, "questions spec olympics");
      setQuizPlusTitle(messageData.specialOlympicName);
      setQuizPlusQuestions(questions);
      setQuizPlusOptions(options);
      setQuizPlusOptionsImages(imgOption);
      setQuizPlusPreviewImages(imgOption);
      setQuizPlusTypes(types);

      this.setState({ isNonEditable: messageData.isNotEditable });

      setIsNotEditable(messageData.isNotEditable);
      setQuizPlusQuestionImages(questionImages);
      setQuizPlusQuestionPreviewImages(questionImages);
      setQuizPlusQuestionTypes(questionTypes);
      setQuizPlusDescriptions(questionDescriptions);
      setShowDescriptionAfterSelecting(showDescriptionAfter);
      setPercentageVisibleToParticipants(showDescriptionAfter);

      setTaskDeadline(new Date(messageData.deadline));
      setTaskEveryone(messageData.all);
      setTaskFolder(messageData.folderId);

      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        messageData.all === true
      ) {
        setTaskChannelUsers(messageData.assignedTo);
      } else {
        let assignedTo = messageData.assignedTo.filter((user) =>
          allParticipants.find(
            (User) => User.uid === user || user === currentChannel.createdBy
          )
        );
        //console.log(assignedTo);
        setTaskChannelUsers(assignedTo);
      }
      setSharedTask({});
      setTaskSharing(false);
      setEditedTask({});
      setTaskEditing(false);
      if (editing) {
        this.setState({ editedQuiz: messageData, view: 1 });
      } else {
        this.setState({ sharedAgainQuiz: messageData, view: 1 });
      }
    };

    if (
      sharing &&
      Object.keys(sharedTask).length > 0 &&
      Object.keys(sharedAgainQuiz).length === 0
    ) {
      console.log(sharedTask);
      shareTaskAgain(sharedTask);
    }
    if (
      editing &&
      Object.keys(editedTask).length > 0 &&
      Object.keys(editedQuiz).length === 0
    ) {
      shareTaskAgain(editedTask);
    }

    const validateData = () => {
      if (quizPlusTitle.length == 0) {
        this.setState({ error: "Title cannot be empty!\n" });
        return null;
      }

      let filtered_questions = questions.filter(
        (question) => question.length > 0
      );
      if (questions.length != filtered_questions.length) {
        this.setState({ error: "Please add some value to question(s).\n" });
        return null;
      }

      /* let existFlag = false;
      options.forEach((item, key) => {
        let imageOptionsForCurrentQue = imageOptions[key];
        let filteredImages = imageOptionsForCurrentQue.filter(
          (img) => img !== ""
        );
        //console.log("item length", item.length);
        //console.log("filtered images", filteredImages.length);
        if (
          filteredImages.length > 0 &&
          filteredImages.length !== item.length
        ) {
          existFlag = true;
        }
      });
      if (existFlag) {
        this.setState({ error: "Please add images to all option(s).\n" });
        return null;
      }*/

      if (questions.length == 0) {
        this.setState({
          error: "Quiz must have at-least one or more question(s).\n",
        });
        return null;
      }

      let filtered_options = options.filter((item) => item.length < 2);
      if (filtered_options.length > 0) {
        this.setState({
          error: "Question must have at-least two or more option(s).\n",
        });
        return null;
      }

      let empty_options = options.flat();
      let filtered_empty_options = empty_options.filter(
        (item) => item.length > 0
      );
      if (filtered_empty_options.length != empty_options.length) {
        this.setState({ error: "Please add some value to option(s).\n" });
        return null;
      }

      if (!everyone && channelUsers.length < 1) {
        this.setState({ error: "Please assign task to atleast one user.\n" });
        return null;
      }

      this.setState({ error: "" });
      return true;
    };

    const getQuiz = (allQuestionImages, images) => {
      //console.log("my file", images);
      let quiz_name = quizPlusTitle;
      let quiz_total_questions = questions.length;

      let newOptions = [];

      if (randomize) {
        newOptions = options.map((option, key) =>
          option.map((opt, k) => {
            return {
              option_value: opt,
              option_image: images[key][k] ? images[key][k] : null,
              //sequence_no: k + 1,
            };
          })
        );
      } else {
        newOptions = options.map((option, key) =>
          option.map((opt, k) => {
            return {
              option_value: opt,
              option_image: images[key][k] ? images[key][k] : null,
              sequence_no: k + 1,
            };
          })
        );
      }

      let quiz_questions = [];
      questions.map((question, key) =>
        quiz_questions.push({
          sequence_no: key + 1,
          question,
          questionImage: allQuestionImages[key],
          description: descriptions[key],
          questionCode: questionTypes[key],
          options: newOptions[key],
          show_description_after: showDescriptionAfterSelecting[key],
          show_percentage_to_participant: percentageVisibleToParticipants[key],
          type: types[key] === "multiple" ? 1 : 0,
        })
      );
      //console.log(newOptions);
      console.log(quiz_questions, "quiz_questions");

      return {
        type,
        quiz_name,
        quiz_total_questions,
        quiz_questions,
        deadline,
        channelUsers,
        folder,
        everyone,
        isNotEditable,
      };
    };

    const shareTask = () => {
      if (validateData()) {
        let allImagesUploadedPromises = imageOptions.map((subArr, key) => {
          return subArr.map((image, key) => {
            if (typeof image === "object") {
              return this.uploadImage(image, key);
            } else return image;
          });
        });

        let allPromises = [];
        for (let i = 0; i < allImagesUploadedPromises.length; i++) {
          let arrayOfPromisesForQue = allImagesUploadedPromises[i];
          for (let j = 0; j < arrayOfPromisesForQue.length; j++) {
            allPromises.push(arrayOfPromisesForQue[j]);
          }
        }
        Promise.all(allPromises).then(async (images) => {
          let optionImages = [];
          for (let i = 0; i < allImagesUploadedPromises.length; i++) {
            let arrayOfPromisesForQue = allImagesUploadedPromises[i];
            let newImages = images.splice(0, arrayOfPromisesForQue.length);
            optionImages.push(newImages);
          }
          let allQuestionImagesPromises = questionImages.map((img, index) => {
            if (typeof img === "object") {
              return this.uploadImage(img, index);
            } else return img;
          });
          Promise.all(allQuestionImagesPromises).then(
            async (allQuestionImages) => {
              console.log(
                allQuestionImages,
                "resolved question image promises"
              );
              addTask(getQuiz(allQuestionImages, optionImages));
            }
          );
        });
      }
    };

    const saveForLaterTask = () => {
      if (validateData()) {
        //console.log("imageOptions", imageOptions);
        let allImagesUploadedPromises = imageOptions.map((subArr, key) => {
          //console.log("Sub Array",subArr)
          return subArr.map((image, key) => {
            if (typeof image === "object") {
              return this.uploadImage(image, key);
            } else return image;
          });
        });
        // console.log(allImagesUploadedPromises, "promisses");

        let allPromises = [];
        for (let i = 0; i < allImagesUploadedPromises.length; i++) {
          let arrayOfPromisesForQue = allImagesUploadedPromises[i];
          for (let j = 0; j < arrayOfPromisesForQue.length; j++) {
            allPromises.push(arrayOfPromisesForQue[j]);
          }
        }
        Promise.all(allPromises).then(async (images) => {
          //console.log(images, "allImages");
          let optionImages = [];
          for (let i = 0; i < allImagesUploadedPromises.length; i++) {
            let arrayOfPromisesForQue = allImagesUploadedPromises[i];
            let newImages = images.splice(0, arrayOfPromisesForQue.length);
            optionImages.push(newImages);
          }

          let allQuestionImagesPromises = questionImages.map((img, index) => {
            if (typeof img === "object") {
              return this.uploadImage(img, index);
            } else return img;
          });
          Promise.all(allQuestionImagesPromises).then(
            async (allQuestionImages) => {
              console.log(
                allQuestionImages,
                "resolved question image promises"
              );
              saveTask(getQuiz(allQuestionImages, optionImages));
            }
          );
        });
      }
    };

    const addTask = async (data) => {
      console.log(data);

      if (data) {
        let taskData = {
          user_id: currentUser.uid,
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          deadline: moment(data["deadline"]).valueOf(),
          special_olympic_name: data["quiz_name"],
          questions: data["quiz_questions"],
          all_participants: data["everyone"],
          assigned: data["channelUsers"],
          folderId: data["folder"] ? data["folder"] : "",
          save_for_later: false,
          is_not_editable: data["isNotEditable"],
        };
        if (Object.keys(editedQuiz).length > 0) {
          let savedQuiz = savedQuizes.find(
            (obj) => obj.contentId === editedQuiz.id
          );
          let savedQuizId = savedQuiz.id;

          try {
            let res = await axios
              .post(`${API_BASE_URL}${QUIZPLUS_UPDATE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                special_olympic_name: data["quiz_name"],
                saved_special_olympic_id: savedQuizId,
                questions: data["quiz_questions"],
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                folderId: data["folder"] ? data["folder"] : "",
                is_not_editable: data["isNotEditable"],
              })
              .then(async () => {
                await axios.post(`${API_BASE_URL}${QUIZPLUS_SHARE}`, {
                  user_id: currentUser.uid,
                  workspace_id: currentWorkspace.workspaceId,
                  room_id: currentChannel.roomId,
                  saved_special_olympic_id: savedQuizId,
                });
              })
              .then(() => {
                if (this.props.darkTheme) {
                  toast.dark("Special olympic updated and shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast("Special olympic updated and shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }

                this.props.resetData();
                setQuizPlusTitle("");
                setQuizPlusOptions([]);
                setQuizPlusQuestions([]);
                setQuizPlusQuestionImages([]);
                setQuizPlusQuestionPreviewImages([]);
                setQuizPlusQuestionTypes([]);
                setQuizPlusDescriptions([]);

                setTaskDeadline(new Date(Date.now() + 10 * 600000));
                setTaskChannelUsers([]);
                setTaskEveryone(false);
                setTaskFolder("");
              });
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            let res = await axios
              .post(`${API_BASE_URL}${QUIZPLUS_CREATE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                special_olympic_name: data["quiz_name"],
                questions: data["quiz_questions"],
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                folderId: data["folder"] ? data["folder"] : "",
                save_for_later: false,
                is_not_editable: data["isNotEditable"],
              })
              .then(() => {
                if (this.props.darkTheme) {
                  toast.dark("Special olympic shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast("Special olympic shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }

                this.props.resetData();
                setQuizPlusTitle("");
                setQuizPlusOptions([]);
                setQuizPlusQuestions([]);
                setQuizPlusQuestionImages([]);
                setQuizPlusQuestionPreviewImages([]);
                setQuizPlusQuestionTypes([]);
                setQuizPlusDescriptions([]);

                setTaskDeadline(new Date(Date.now() + 10 * 600000));
                setTaskChannelUsers([]);
                setTaskEveryone(false);
                setTaskFolder("");
              });
            console.log(res);
          } catch (error) {
            console.log(error);
          }
        }
      }
    };
    const saveTask = async (data) => {
      console.log(data, "save data");
      if (data) {
        let taskData = {
          user_id: currentUser.uid,
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          deadline: moment(data["deadline"]).valueOf(),
          special_olympic_name: data["quiz_name"],
          questions: data["quiz_questions"],
          all_participants: data["everyone"],
          assigned: data["channelUsers"],
          save_for_later: true,
          folderId: data["folder"] ? data["folder"] : "",
          is_not_editable: data["isNotEditable"],
        };
        // console.log(taskData);
        if (Object.keys(editedQuiz).length > 0) {
          let savedQuiz = savedQuizes.find(
            (obj) => obj.contentId === editedQuiz.id
          );
          let savedQuizId = savedQuiz.id;

          try {
            let res = await axios
              .post(`${API_BASE_URL}${QUIZPLUS_UPDATE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                special_olympic_name: data["quiz_name"],
                saved_special_olympic_id: savedQuizId,
                questions: data["quiz_questions"],
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                save_for_later: true,
                folderId: data["folder"] ? data["folder"] : "",
                is_not_editable: data["isNotEditable"],
              })
              .then(() => {
                const task = firebase.firestore().collection("specialOlympics");
                task
                  .doc(editedQuiz.id)
                  .get()
                  .then((doc) => {
                    console.log(doc);
                    if (doc.exists) {
                      let index = savedQuizesData.findIndex(
                        (savedQuiz) => savedQuiz.id === doc.id
                      );
                      console.log(index);
                      if (index !== -1) {
                        // console.log(doc.data());
                        savedQuizesData.splice(index, 1, {
                          ...doc.data(),
                          id: doc.id,
                        });
                        this.setState(savedQuizesData);
                      }
                    }
                  });
                this.setState({ view: 0, editedQuiz: {} }, () => {
                  if (this.props.darkTheme) {
                    toast.dark("Special olympic updated and saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  } else {
                    toast("Special olympic updated and saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  }
                });
                this.props.resetData();
                this.props.setQuizPlusTitle("");
                this.props.setQuizPlusOptions([]);
                this.props.setQuizPlusQuestions([]);
                this.props.setQuizPlusQuestionImages([]);
                this.props.setQuizPlusQuestionPreviewImages([]);
                this.props.setQuizPlusQuestionTypes([]);
                this.props.setQuizPlusDescriptions([]);

                this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
                this.props.setTaskChannelUsers([]);
                this.props.setTaskEveryone(false);
                this.props.setTaskFolder("");
              });
            console.log(res);
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            let res = await axios
              .post(`${API_BASE_URL}${QUIZPLUS_CREATE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                special_olympic_name: data["quiz_name"],
                questions: data["quiz_questions"],
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                save_for_later: true,
                folderId: data["folder"] ? data["folder"] : "",
                is_not_editable: data["isNotEditable"],
              })
              .then(() => {
                //this.props.resetData();
                this.setState({ view: 0, editedQuiz: {} }, () => {
                  if (this.props.darkTheme) {
                    toast.dark("Special olympic saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  } else {
                    toast("Special olympic saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  }
                });
                this.props.setQuizPlusTitle("");
                this.props.setQuizPlusOptions([]);
                this.props.setQuizPlusPreviewImages([]);
                this.props.setQuizPlusOptionsImages([]);
                this.props.setQuizPlusQuestions([]);
                this.props.setQuizPlusQuestionImages([]);
                this.props.setQuizPlusQuestionPreviewImages([]);
                this.props.setQuizPlusQuestionTypes([]);
                this.props.setQuizPlusDescriptions([]);

                this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
                this.props.setTaskChannelUsers([]);
                this.props.setTaskEveryone(false);
                this.props.setTaskFolder("");
              });
          } catch (error) {
            console.log(error);
          }
        }
      }
    };

    const shareSavedTask = async (id) => {
      let savedQuiz = savedQuizes.find((obj) => obj.contentId === id);
      let savedQuizData = savedQuizesData.find((obj) => obj.id === id);
      if (savedQuizData?.createdBy !== currentUser.uid) {
        toast.warning("Share Failed: Special olympic is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }
      if (savedQuizData.deadline < Date.now()) {
        toast.warning(
          "Deadline has passed.please edit special olympic to change deadline.",
          { position: toast.POSITION.BOTTOM_CENTER }
        );
        return;
      }
      if (
        (savedQuiz.all === false &&
          savedQuiz.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        savedQuiz.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants have left the group, please edit  to reassign  participants.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }

      let savedQuizId = savedQuiz.id;
      try {
        let res = await axios
          .post(`${API_BASE_URL}${QUIZPLUS_SHARE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            saved_special_olympic_id: savedQuizId,
          })
          .then(() => {
            if (this.props.darkTheme) {
              toast.dark("Special olympic shared.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            } else {
              toast("Special olympic shared.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            }
            this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }
    };
    const editSavedTask = (id) => {
      let savedQuiz = savedQuizesData.find((obj) => obj.id === id);
      if (savedQuiz.createdBy !== currentUser.uid) {
        toast.warning("Edit Failed: Special olympic is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }

      let questions = [];
      let options = [];
      let imageOptions = [];
      let types = [];
      let questionImages = [];
      // let questionPreviewImages=[];
      let questionTypes = [];
      let questionDescriptions = [];

      let sorted = _.map(
        savedQuiz.specialOlympicQuestions,
        function (value, key) {
          return _.defaults({ order: value.sequenceNo }, value);
        }
      );

      sorted = _.sortBy(sorted, "order");

      sorted.map((q, key) => {
        let queImgOptions = [];
        let queOptions = [];

        if (q.type) {
          types.push("multiple");
        } else {
          types.push("single");
        }

        questions.push(q.question);
        questionDescriptions.push(q.description ? q.description : "");
        questionImages.push(q.questionImage ? q.questionImage : "");
        questionTypes.push(q.questionCode);

        let sortedOptions = _.map(q.options, function (value, key) {
          return _.defaults({ order: value.sequenceNo }, value);
        });

        sortedOptions = _.sortBy(sortedOptions, "order");

        sortedOptions.map((o, k) => {
          //console.log(messageData.specialOlympicQuestions[q].options[o].optionValue);
          //console.log(messageData.specialOlympicQuestions[q].options[o].optionImage);
          queOptions.push(o.optionValue);
          queImgOptions.push(o.optionImage ? o.optionImage : null);
        });
        options.push(queOptions);
        imageOptions.push(queImgOptions);
      });

      setQuizPlusTitle(savedQuiz.specialOlympicName);
      setQuizPlusQuestions(questions);
      setQuizPlusOptions(options);
      setQuizPlusOptionsImages(imageOptions);
      setQuizPlusPreviewImages(imageOptions);
      setQuizPlusTypes(types);
      setQuizPlusQuestionImages(questionImages);
      setQuizPlusQuestionPreviewImages(questionImages);
      setQuizPlusQuestionTypes(questionTypes);
      setQuizPlusDescriptions(questionDescriptions);

      setTaskDeadline(new Date(savedQuiz.deadline));
      setTaskFolder(savedQuiz.folderId);
      //setTaskChannelUsers(savedQuiz.all ? [] : savedQuiz.assignedTo);
      if (
        (savedQuiz.all === false &&
          savedQuiz.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        savedQuiz.all === true
      ) {
        this.props.setTaskChannelUsers(savedQuiz.assignedTo);
      } else {
        let assignedTo = savedQuiz.assignedTo.filter((user) =>
          allParticipants.find(
            (User) => User.uid === user || user === currentChannel.createdBy
          )
        );
        console.log(assignedTo);
        this.props.setTaskChannelUsers(assignedTo);
      }
      setTaskEveryone(savedQuiz.all);
      this.setState({ editedQuiz: savedQuiz, view: 1 });
    };
    const deleteSavedTask = async (id) => {
      let savedQuiz = savedQuizes.find((obj) => obj.contentId === id);
      if (savedQuiz?.createdBy !== currentUser.uid) {
        toast.warning(
          "Deletion Failed: Special olympic is not created by you.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        return null;
      }
      console.log(savedQuiz);
      let savedQuizId = savedQuiz.id;
      try {
        let res = await axios
          .post(`${API_BASE_URL}${QUIZPLUS_REMOVE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            saved_special_olympic_id: savedQuizId,
          })
          .then(() => {
            if (this.props.darkTheme) {
              toast.dark("Special olympic deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            } else {
              toast("Special olympic deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            }

            //this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }
    };

    let canSeeTaskCurrentUser = publishedQuizesData.filter(
      (quiz) =>
        currentChannel?.createdBy === currentUser.uid ||
        quiz.all === true ||
        coAdmins.find((usr) => usr.uid === currentUser.uid) ||
        (quiz.assignedTo && quiz.assignedTo.includes(currentUser.uid))
    );
    return view === 1 ? (
      <>
        <div
          onClick={() => {
            setQuizPlusTitle("");
            setQuizPlusOptions([]);
            setQuizPlusOptionsImages([]);
            setQuizPlusPreviewImages([]);
            setQuizPlusQuestions([]);
            setQuizPlusQuestionImages([]);
            setQuizPlusQuestionPreviewImages([]);
            setQuizPlusQuestionTypes([]);
            setQuizPlusDescriptions([]);
            setTaskDeadline(new Date(Date.now() + 10 * 600000));
            setTaskChannelUsers([]);
            setTaskEveryone(false);
            setTaskFolder("");
            this.setState({ view: 0, editedQuiz: {} });
            this.props.setTaskSharing(false);
            this.setState({ isNonEditable: false });
          }}
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
          className="mb-1 p-2 bg-card-theme text-theme"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
          <span>
            {Object.keys(editedQuiz).length > 0 ? "Update" : "Create"}
          </span>
        </div>
        <CreateQuiz
          allParticipants={this.props.allParticipants}
          saveForLaterTask={saveForLaterTask}
          shareTask={shareTask}
          validateData={validateData}
          addTask={addTask}
          saveTask={saveTask}
          editedQuiz={
            Object.keys(editedQuiz).length > 0 ? editedQuiz : sharedAgainQuiz
          }
          error={error}
          isNonEditable={this.state.isNonEditable}
        />
      </>
    ) : view === 0 ? (
      <>
        <div
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
          className="m-2 rounded p-2 bg-card-theme text-theme"
        >
          <div className="d-flex">
            <div
              onClick={() => {
                setContentType(null);
                setTaskType(null);
                resetAllTasks();
                this.setState({ isNonEditable: false });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
              <span>
                {contentType === "createTask"
                  ? taskType
                    ? taskType === "Quiz+"
                      ? "Special Olympics"
                      : taskType + "s"
                    : "Create Task"
                  : ""}
              </span>
            </div>
          </div>
          <IsAdmin>
            <div className="mt-2">
              <div className="d-flex">
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => this.addQuizView(1)}
                  className="pt-0 w-50"
                >
                  <small>+ CREATE NEW</small>
                </Button>
              </div>
            </div>
          </IsAdmin>
        </div>

        <IsAdmin>
          <div className="row m-2 border-1 rounded">
            <div
              className={
                selected === "Published"
                  ? "col-6 text-theme bg-card-theme rounded py-1 d-flex justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Published")}
              style={{ cursor: "pointer" }}
            >
              Published
            </div>

            <div
              className={
                selected === "Saved"
                  ? "col-6 text-theme bg-card-theme rounded d-flex py-1 justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Saved")}
              style={{ cursor: "pointer" }}
            >
              Saved
            </div>
          </div>
        </IsAdmin>

        {selected === "Published" ? (
          canSeeTaskCurrentUser.length > 0 ? (
            <div>
              <div className="d-flex justify-content-between">
                <InputGroup className="my-1 outerPill">
                  <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                    <div className="py-2 pr-2 pl-3">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="sm"
                        color="#aeaeae"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <Form.Control
                        type="text"
                        id="participantsSearch"
                        className="border-0 px-0 bg-theme text-theme rounded"
                        onChange={(e) => {
                          this.setState({ searchTerm: e.target.value }, () => {
                            const reg = _.escapeRegExp(this.state.searchTerm);
                            // console.log(reg,"Reg")
                            const regex = new RegExp(reg, "gi");
                            this.setState({ regex: regex });
                          });
                        }}
                        placeholder={"Search"}
                        value={this.state.searchTerm}
                        style={{ margin: 0 }}
                      />
                    </div>
                    {this.state.searchTerm.length > 0 && (
                      <div
                        className="py-2 pl-2 pr-3"
                        onClick={() => this.setState({ searchTerm: "" })}
                      >
                        <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                      </div>
                    )}
                  </div>
                </InputGroup>
                {this.state.sortType === "unreverse" ? (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "reverse" }, () => {
                        this.sortQuizPlusData();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAmountUpAlt} color="#aeaeae" />
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "unreverse" }, () => {
                        this.sortQuizPlusData();
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSortAmountDownAlt}
                      color="#aeaeae"
                    />
                  </Button>
                )}
                {this.state.sortType === "atoz" ? (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "ztoa" }, () => {
                        this.sortQuizPlusData();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUp} color="#aeaeae" />
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "atoz" }, () => {
                        this.sortQuizPlusData();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUpAlt} color="#aeaeae" />
                  </Button>
                )}
              </div>
              {this.state.searchTerm && this.state.searchTerm.length > 0 ? (
                <>
                  {canSeeTaskCurrentUser
                    .filter((item) => {
                      return item.specialOlympicName?.match(
                        this.state.searchTerm
                      );
                    })
                    .map((quiz, index) => (
                      <div
                        key={index + quiz.id}
                        className={
                          quiz.deadline > Date.now()
                            ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
                            : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
                        }
                      >
                        <div
                          className="d-flex justify-content-between mb-1 pointer"
                          onClick={() => this.showSelectedQuiz(quiz.id)}
                        >
                          <span className="text-theme-lighter smaller">
                            {moment(quiz.timestamp).format("LLL")}
                          </span>
                          <span
                            className={
                              quiz.deadline > Date.now()
                                ? "text-success smaller"
                                : "text-secondary smaller"
                            }
                          >
                            {quiz.deadline > Date.now() ? "Active" : "Closed"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p
                            className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
                            onClick={() => this.showSelectedQuiz(quiz.id)}
                          >
                            {quiz.specialOlympicName}
                          </p>
                          <IsAdmin>
                            <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                              <Dropdown.Toggle
                                size="sm"
                                as={this.CustomToggle}
                              />
                              <Dropdown.Menu className="bg-card-theme text-theme">
                                <Dropdown.Item
                                  className="small bg-card-theme text-theme"
                                  onClick={() => shareTaskAgain(quiz)}
                                >
                                  Duplicate
                                </Dropdown.Item>
                                {quiz.deadline > Date.now() ? (
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() => closeAppResponses(quiz)}
                                  >
                                    Close{" "}
                                  </Dropdown.Item>
                                ) : null}
                                {/*  <Dropdown.Item
                            className="small"
                            onClick={() => deleteSavedTask(poll.id)}
                          >
                            Delete
                        </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </IsAdmin>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <>
                  {canSeeTaskCurrentUser.map((quiz, index) => (
                    <div
                      key={index + quiz.id}
                      className={
                        quiz.deadline > Date.now()
                          ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
                          : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
                      }
                    >
                      <div
                        className="d-flex justify-content-between mb-1 pointer"
                        onClick={() => this.showSelectedQuiz(quiz.id)}
                      >
                        <span className="text-theme-lighter smaller">
                          {moment(quiz.timestamp).format("LLL")}
                        </span>
                        <span
                          className={
                            quiz.deadline > Date.now()
                              ? "text-success smaller"
                              : "text-secondary smaller"
                          }
                        >
                          {quiz.deadline > Date.now() ? "Active" : "Closed"}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p
                          className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
                          onClick={() => this.showSelectedQuiz(quiz.id)}
                        >
                          {quiz.specialOlympicName}
                        </p>
                        <IsAdmin>
                          <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                            <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                            <Dropdown.Menu className="bg-card-theme text-theme">
                              <Dropdown.Item
                                className="small bg-card-theme text-theme"
                                onClick={() => shareTaskAgain(quiz)}
                              >
                                Duplicate
                              </Dropdown.Item>
                              {quiz.deadline > Date.now() ? (
                                <Dropdown.Item
                                  className="small bg-card-theme text-theme"
                                  onClick={() => closeAppResponses(quiz)}
                                >
                                  Close{" "}
                                </Dropdown.Item>
                              ) : null}
                              {/*  <Dropdown.Item
                            className="small"
                            onClick={() => deleteSavedTask(poll.id)}
                          >
                            Delete
                        </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </IsAdmin>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : (
            <div className="col">
              <p className="text-theme text-center small">
                No special olympics published yet
              </p>
            </div>
          )
        ) : savedQuizes.length > 0 ? (
          <div>
            <div className="d-flex justify-content-between">
              <InputGroup className="my-1 outerPill">
                <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                  <div className="py-2 pr-2 pl-3">
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="sm"
                      color="#aeaeae"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <Form.Control
                      type="text"
                      id="participantsSearch"
                      className="border-0 px-0 bg-theme text-theme rounded"
                      onChange={(e) => {
                        this.setState({ searchTerm: e.target.value }, () => {
                          const reg = _.escapeRegExp(this.state.searchTerm);
                          // console.log(reg,"Reg")
                          const regex = new RegExp(reg, "gi");
                          this.setState({ regex: regex });
                        });
                      }}
                      placeholder={"Search"}
                      value={this.state.searchTerm}
                      style={{ margin: 0 }}
                    />
                  </div>
                  {this.state.searchTerm.length > 0 && (
                    <div
                      className="py-2 pl-2 pr-3"
                      onClick={() => this.setState({ searchTerm: "" })}
                    >
                      <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                    </div>
                  )}
                </div>
              </InputGroup>
              {this.state.sortType === "unreverse" ? (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "reverse" }, () => {
                      this.sortQuizPlusData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAmountUpAlt} color="#aeaeae" />
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "unreverse" }, () => {
                      this.sortQuizPlusData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAmountDownAlt} color="#aeaeae" />
                </Button>
              )}
              {this.state.sortType === "atoz" ? (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "ztoa" }, () => {
                      this.sortQuizPlusData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAlphaUp} color="#aeaeae" />
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "atoz" }, () => {
                      this.sortQuizPlusData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAlphaUpAlt} color="#aeaeae" />
                </Button>
              )}
            </div>
            {this.state.searchTerm && this.state.searchTerm.length > 0 ? (
              <>
                {savedQuizesData
                  .filter((item) => {
                    return item.specialOlympicName?.match(this.state.regex);
                  })
                  .map((quiz, index) => (
                    <div className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1">
                      <div
                        key={index}
                        className="d-flex justify-content-center"
                      >
                        <div
                          onClick={() => editSavedTask(quiz.id)}
                          className="pointer"
                        >
                          <small className="text-theme-lighter smaller">
                            {moment(quiz.timestamp).format("LLL")}
                          </small>
                          <p className="m-0 font-weight-bold text-theme small">
                            {quiz.specialOlympicName}
                          </p>
                        </div>
                        <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                          <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                          <Dropdown.Menu className="bg-card-theme text-theme">
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => shareSavedTask(quiz.id)}
                            >
                              Share now
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => editSavedTask(quiz.id)}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => deleteSavedTask(quiz.id)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <>
                {savedQuizesData.map((quiz, index) => (
                  <div className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1">
                    <div key={index} className="d-flex justify-content-center">
                      <div
                        onClick={() => editSavedTask(quiz.id)}
                        className="pointer"
                      >
                        <small className="text-theme-lighter smaller">
                          {moment(quiz.timestamp).format("LLL")}
                        </small>
                        <p className="m-0 font-weight-bold text-theme small">
                          {quiz.specialOlympicName}
                        </p>
                      </div>
                      <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                        <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                        <Dropdown.Menu className="bg-card-theme text-theme">
                          <Dropdown.Item
                            className="small bg-card-theme text-theme"
                            onClick={() => shareSavedTask(quiz.id)}
                          >
                            Share now
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="small bg-card-theme text-theme"
                            onClick={() => editSavedTask(quiz.id)}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="small bg-card-theme text-theme"
                            onClick={() => deleteSavedTask(quiz.id)}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        ) : (
          <div className="col">
            <p className="text-theme text-center small">
              No special olympics saved yet
            </p>
          </div>
        )}
      </>
    ) : (
      <div>
        <div
          onClick={() => {
            this.setState({ view: 0, selectedQuiz: {} });
            this.props.setTaskSharing(false);
            this.setState({ isNonEditable: false });
          }}
          className="mb-1 p-2 bg-card-theme text-theme"
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
          <span>Back</span>
        </div>
        <div className="shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme">
          <Quiz
            view={view}
            quiz_data={{ contentId: selectedQuiz.id }}
            user_id={currentUser.uid}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,

  previewImageOptions: state.quizPlus.previewImageOptions,
  imageOptions: state.quizPlus.imageOptions,
  type: state.quizPlus.type,
  quizPlusTitle: state.quizPlus.quizPlusTitle,
  questions: state.quizPlus.questions,
  options: state.quizPlus.options,
  types: state.quizPlus.types,
  randomize: false,
  questionPreviewImages: state.quizPlus.questionPreviewImages,
  questionImages: state.quizPlus.questionImages,
  questionTypes: state.quizPlus.questionTypes,
  descriptions: state.quizPlus.descriptions,

  showDescriptionAfterSelecting: state.quizPlus.showDescriptionAfterSelecting,
  percentageVisibleToParticipants:
    state.quizPlus.percentageVisibleToParticipants,
  isNotEditable: state.quizPlus.isNotEditable,

  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  everyone: state.task.everyone,
  sharing: state.platform.sharing,
  sharedTask: state.platform.sharedTask,
  editing: state.platform.editing,
  editedTask: state.platform.editedTask,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, {
  setQuizPlusOptionsImages,
  setQuizPlusPreviewImages,
  setQuizPlusTitle,
  setTaskSharing,
  setQuizPlusQuestions,
  setQuizPlusOptions,
  setQuizPlusDescriptions,
  setQuizPlusQuestionImages,
  setQuizPlusQuestionPreviewImages,
  setQuizPlusQuestionTypes,
  setQuizPlusTypes,
  setIsNotEditable,
  setShowDescriptionAfterSelecting,
  setPercentageVisibleToParticipants,

  resetData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
  setSharedTask,
  setTaskSharing,
  setEditedTask,
  setTaskEditing,
  resetData,
})(QuizPlusHomepage);
