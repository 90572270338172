import React, { useEffect, useState, useRef } from "react";
import firebase from "firebase";
import {
  faMicrophoneSlash,
  faVideo,
  faVideoSlash,
  faMicrophone,
  faDesktop,
  faVolumeMute,
  faHandPaper,
  faStarHalfAlt,
  faStopCircle,
  faThLarge,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import {
  setIsVideoVisible,
  setChannelDetails,
  setConferenceSession,
} from "../../actions/index";
import { Card, Alert, Button, Dropdown, Spinner } from "react-bootstrap";

import ActionBox from "../Middle/ActionBoxForVideo";
import {
  API_BASE_URL,
  ROOM_CONFIG,
  IGESIA_VIDEO_SERVER_BASE_URL,
} from "../../config/index";
import { postData } from "../../utils/index";
import {
  browserName,
  CustomView,
  osName,
  deviceType,
  isIOS,
  isAndroid,
} from "react-device-detect";
import Webcam from "react-webcam";
import { loadState, saveState } from "../../services/index";
import Chat from "../Middle/Chat";
import VideoChat from "../Middle/VideoChat";
import VideoQnA from "../Middle/videoQnA";
import Broadcast from "./Broadcast";
import axios from "axios";
import https from "https";
import Subscribe from "./Subscribe";
import IsAdmin from "../IsAdmin";
import moment from "moment";
import "./opentok-video.css";
// import swal from "sweetalert";
import jwt from "jsonwebtoken";
import classNames from "classnames";
import "opentok-solutions-css";
import "./opentok.css";
import swal from "sweetalert";

function VideoConference({
  userName,
  userAvatar,
  channelId,
  currentChannel,
  participants,
  currentUser,
  currentWorkspace,
  maximiseVideo,
  minimiseVideo,
  setIsVideoVisible,
  setChannelDetails,
  currentSubscription,
  channelDetails,
  isPublisher,
  setConferenceSession,
  conferenceSession,
  isAudioMute,
  isVideoMute,
}) {
  const [session, setSession] = useState(null);
  const [isSubOnly, setIsSubOnly] = useState(false);
  const [publishersWaiting, setPublishersWaiting] = useState(null);
  const [publisher, setPublisher] = useState(null);
  const [screenPublisher, setScreenPublisher] = useState(null);
  const [currentPublishers, setCurrentPublishers] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [subscriberScreen, setSubscriberScreen] = useState(null);
  const [hasScreenSubscriber, setHasScreenSubscriber] = useState(false);
  const [hasCameraSubscriber, setHasCameraSubscriber] = useState(false);
  const [isPublishingScreen, setIsPublishingScreen] = useState(false);
  const [publishersCount, setPublishersCount] = useState(0);

  const [isMicMuted, setIsMicMuted] = useState(isAudioMute);
  const [isCameraOff, setIsCameraOff] = useState(isVideoMute);
  const [screenShareOn, setScreenShareOn] = useState(false);
  const [isBeingRecorded, setIsBeingRecorded] = useState(false);
  const [onVideoNotification, setOnVideoNotification] = useState(null);
  const [active, setActive] = useState(false);
  const [meta, setMeta] = useState({
    publisher: 0,
    subscriber: 0,
    camera: 0,
    screen: 0,
    isFullscreen: false,
  });
  const [HandRaise, setHandRaise] = useState(false);
  const [MuteAll, setMuteAll] = useState(false);
  const [isFullscreenMode, setIsFullscreenMode] = useState(false);
  const [Classes, setClasses] = useState({});

  const cameraSubsRef = useRef(null);

  // const getCurrentPublishersAPI = async () => {

  //   await axios
  //   .get(
  //     IGESIA_VIDEO_SERVER_BASE_URL +
  //       `/room/:${currentWorkspace.workspaceId}-${channelDetails.roomId}`
  //   )
  //   .then((response) => {
  //     console.log(response, "response from publish video");
  //     const token = jwt.sign({
  //       "iss": response.data.apiKey,
  //       "ist": "project",
  //       "iat": Math.floor(new Date().getTime()/1000),
  //       "exp": Math.floor((new Date().getTime() + 500 * 1000)/1000),
  //     }, "2269643cd91abd249491736c28d1f23be9aa0a56")

  //     axios.get(`https://api.opentok.com/v2/project/${response.data.apiKey}/session/${response.data.sessionId}/stream/`, {
  //       'X-OPENTOK-AUTH': token,
  //       'Content-Type': 'application/json'
  //     }).then((res) => {
  //       console.log(res, "res from rest api")
  //     })

  //   })

  // }

  // Handling all of our errors here by alerting them
  function handleError(error) {
    if (error) {
      console.log(error.message);
    }
  }

  const containerClasses = ({
    active,
    meta,
    isMicMuted,
    isCameraOff,
    isPublishingScreen,
  }) => {
    // const { active, meta, localAudioEnabled, localVideoEnabled } = state;
    // console.log("isActive", active);
    const sharingScreen = isPublishingScreen;
    const viewingSharedScreen = meta ? meta.screen : false;
    const activeCameraSubscribers = meta ? meta.camera : 0;
    const activeCameraSubscribersGt2 = activeCameraSubscribers > 2;
    const activeCameraSubscribersOdd = activeCameraSubscribers % 2;
    const screenshareActive = viewingSharedScreen || sharingScreen;
    return {
      controlClass: classNames("App-control-container", {
        hidden: !active,
      }),
      localAudioClass: classNames("ots-video-control circle audio", {
        hidden: !active,
        muted: isMicMuted,
      }),
      localVideoClass: classNames("ots-video-control circle video", {
        hidden: !active,
        muted: isCameraOff,
      }),
      localCallClass: classNames("ots-video-control circle end-call", {
        hidden: !active,
      }),
      cameraPublisherClass: classNames("video-container", {
        hidden: !active,
        small: !!activeCameraSubscribers || screenshareActive,
        left: screenshareActive,
      }),
      screenPublisherClass: classNames("video-container", {
        hidden: !active || !sharingScreen,
      }),
      cameraSubscriberClass: classNames(
        "video-container",
        { hidden: !active || !activeCameraSubscribers },
        { "active-gt2": activeCameraSubscribersGt2 && !screenshareActive },
        { "active-odd": activeCameraSubscribersOdd && !screenshareActive },
        { small: screenshareActive }
      ),
      screenSubscriberClass: classNames("video-container", {
        hidden: !viewingSharedScreen || !active,
      }),
    };
  };

  useEffect(() => {
    let Class = containerClasses({
      active,
      meta,
      isMicMuted,
      isCameraOff,
      isPublishingScreen,
    });
    setClasses(Class);
  }, [active, meta, isMicMuted, isCameraOff, isPublishingScreen]);

  const subscribeOnly = () => {
    axios
      .get(
        IGESIA_VIDEO_SERVER_BASE_URL +
          `/room/:${currentWorkspace.workspaceId}-${channelDetails.roomId}`
      )
      .then((response) => {
        if (conferenceSession) {
          conferenceSession.off();
          conferenceSession.disconnect();
        }
        console.log(response, "response from publish video");
        var sessionlocal = window.OT.initSession(
          response.data.apiKey,
          response.data.sessionId
        );
        setSession(sessionlocal);
        setConferenceSession(sessionlocal);

        sessionlocal.connect(response.data.token, function (error) {
          console.log("error: ", error);
          // swal("Error", "Something went wrong!", "error");
        });

        setActive(true);

        sessionlocal.on("streamCreated", function (event) {
          setPublishersCount((prev) => prev + 1);
          console.log("stream created", event);
          if (event.stream.videoType === "screen") {
            console.log("screen meta", meta);
            meta["screen"] = meta.screen + 1;
            setMeta({ ...meta });
            setHasScreenSubscriber(true);
            sessionlocal.subscribe(
              event.stream,
              "screenSubscriberContainer",
              {
                insertMode: "append",
                width: "100%",
                height: "100%",
              },
              handleError
            );
          }
          if (event.stream.videoType === "camera") {
            console.log("camera meta", meta);
            meta["camera"] = meta.camera + 1;
            setMeta({ ...meta });
            setHasCameraSubscriber(true);
            var subscriber = sessionlocal.subscribe(
              event.stream,
              "cameraSubscriberContainer",
              {
                insertMode: "append",
                width: "100%",
                height: "100%",
                // insertDefaultUI: false,
              },
              handleError
            );
            const prevSubs = subscribers;
            prevSubs.push(subscriber);
            setSubscribers(prevSubs);
          }
        });

        sessionlocal.on("streamDestroyed", function (event) {
          console.log("stream destroyed", event.stream.videoType);
          if (event.stream.videoType === "screen") {
            meta["screen"] = meta.screen - 1;
            setMeta({ ...meta });
            console.log("media stopped");
            setHasScreenSubscriber(false);
          }
          if (event.stream.videoType === "camera") {
            meta["camera"] = meta.camera + 1;
            setMeta({ ...meta });

            setPublishersCount((prev) => prev - 1);
            console.log("media stopped");
            // setHasCameraSubscriber(false);
          }
          console.log("meta", meta);
        });

        sessionlocal.on("archiveStarted", function (event) {
          setCurrentArchive(event);
          setIsBeingRecorded(true);
          console.log("ARCHIVE STARTED");
        });

        sessionlocal.on("archiveStopped", function (event) {
          setCurrentArchive(event);
          setIsBeingRecorded(false);
          console.log("ARCHIVE STOPED");
        });

        setIsSubOnly(true);
      });
  };

  const publishVideo = () => {
    axios
      .get(
        IGESIA_VIDEO_SERVER_BASE_URL +
          `/room/:${currentWorkspace.workspaceId}-${channelDetails.roomId}`
      )
      .then((response) => {
        console.log(response, "response from publish video");
        var sessionlocal = window.OT.initSession(
          response.data.apiKey,
          response.data.sessionId
        );
        setSession(sessionlocal);

        setConferenceSession(sessionlocal);
        var publisher = window.OT.initPublisher(
          "cameraPublisherContainer",
          {
            insertMode: "append",
            width: "100%",
            height: "100%",
            // resolution: '1280x720',
            name: currentUser.displayName,
            style: { nameDisplayMode: "on" },
            publishAudio: !isMicMuted,
            publishVideo: !isCameraOff,
          },
          handleError
        );
        publisher.setStyle("backgroundImageURI", currentUser.photoURL);
        setPublisher(publisher);
        console.log(sessionlocal);
        setActive(true);
        sessionlocal.connect(response.data.token, function (error) {
          // If the connection is successful, publish to the session
          if (error) {
            console.log("error: ", error);
            // handleError(error);
          } else {
            sessionlocal.publish(publisher, handleError);
            setActive(true);
            // session publisher
            // if currenet uid !== channel.createdby then they're coadmins

            firebase
              .firestore()
              .collection(
                `videoConference/${currentWorkspace.workspaceId}/rooms`
              )
              .doc(`${currentChannel.roomId}`)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  let oldCurrentPublisher = [];
                  if (doc.data().currentPublishers) {
                    oldCurrentPublisher = doc.data().currentPublishers;
                  }
                  if (oldCurrentPublisher.length !== 0) {
                    let userAlreadyExists = false;
                    const modifiedCurrentPublishers = oldCurrentPublisher.map(
                      (user, index) => {
                        if (user.uid === currentUser.uid) {
                          userAlreadyExists = true;
                          user.requestedAt = moment().valueOf();
                          return user;
                        } else {
                          return user;
                        }
                      }
                    );
                    console.log(
                      "modifiedCurrentPublishers",
                      modifiedCurrentPublishers
                    );

                    if (userAlreadyExists) {
                      firebase
                        .firestore()
                        .collection(
                          `videoConference/${currentWorkspace.workspaceId}/rooms`
                        )
                        .doc(`${currentChannel.roomId}`)
                        .set(
                          {
                            currentPublishers: modifiedCurrentPublishers,
                          },
                          { merge: true }
                        );
                    } else if (!userAlreadyExists) {
                      firebase
                        .firestore()
                        .collection(
                          `videoConference/${currentWorkspace.workspaceId}/rooms`
                        )
                        .doc(`${currentChannel.roomId}`)
                        .set(
                          {
                            currentPublishers: firebase.firestore.FieldValue.arrayUnion(
                              {
                                uid: currentUser.uid,
                                displayName: currentUser.displayName,
                                requestedAt: moment().valueOf(),
                                photoUrl: currentUser.photoURL,
                              }
                            ),
                          },
                          { merge: true }
                        );
                    }
                  } else {
                    firebase
                      .firestore()
                      .collection(
                        `videoConference/${currentWorkspace.workspaceId}/rooms`
                      )
                      .doc(`${currentChannel.roomId}`)
                      .set(
                        {
                          currentPublishers: firebase.firestore.FieldValue.arrayUnion(
                            {
                              uid: currentUser.uid,
                              displayName: currentUser.displayName,
                              requestedAt: moment().valueOf(),
                              photoUrl: currentUser.photoURL,
                            }
                          ),
                        },
                        { merge: true }
                      );
                  }
                } else {
                  firebase
                    .firestore()
                    .collection(
                      `videoConference/${currentWorkspace.workspaceId}/rooms`
                    )
                    .doc(`${currentChannel.roomId}`)
                    .set(
                      {
                        currentPublishers: firebase.firestore.FieldValue.arrayUnion(
                          {
                            uid: currentUser.uid,
                            displayName: currentUser.displayName,
                            requestedAt: moment().valueOf(),
                            photoUrl: currentUser.photoURL,
                          }
                        ),
                      },
                      { merge: true }
                    );
                }
              });
          }
        });

        sessionlocal.on("streamCreated", function (event) {
          console.log(event);
          console.log(event.stream.hasAudio)
          setPublishersCount((prev) => prev + 1);
          console.log("stream created", event);
          if (!isSubscribed) {
            if (event.stream.videoType === "screen") {
              if (meta.isFullscreen && meta.screen) {
                meta["isFullscreen"] = false;
                meta["screen"] = 0;
                setMeta({ ...meta });
                let allChild = document.getElementById(
                  "screenSubscriberContainer"
                );
                while (allChild.firstChild) {
                  allChild.firstChild.remove();
                }
              }

              console.log("meta screen in publish", meta);
              meta["screen"] = meta.screen + 1;
              setMeta({ ...meta });
              console.log("meta", meta);
              setHasScreenSubscriber(true);
              var subscriberScreen = sessionlocal.subscribe(
                event.stream,
                "screenSubscriberContainer",
                {
                  insertMode: "append",
                  width: "100%",
                  height: "100%",
                },
                handleError
              );
              setSubscriberScreen(subscriberScreen);
            } else {
              console.log("meta camera in publish", meta);
              meta["camera"] = meta.camera + 1;
              setMeta({ ...meta });
              console.log("meta", meta);
              setHasCameraSubscriber(true);
              console.log(event);

              var subscriber = sessionlocal.subscribe(
                event.stream,
                "cameraSubscriberContainer",
                {
                  insertMode: "append",
                  width: "100%",
                  height: "100%",
                },
                function (error) {
                  if (error) {
                    console.log(error);
                  }
                }
              );

              subscriber.on("videoElementCreated", function (e) {
                
                console.log(e);
                let element = document
                  .getElementById(`${e.target.id}`);
                  console.log(element)
                  element.classList.add("rounded")
                  element.classList.add("shadow-sm")

                  element.addEventListener("click", function () {
console.log(event.stream)
                    console.log(meta.screen, meta.isFullscreen);
                    if (meta.screen && !meta.isFullscreen) return;
                    meta["screen"] = 1;
                    meta["isFullscreen"] = event.target.id;
                    setMeta({ ...meta });
                    console.log(meta.screen, meta.isFullscreen);
                    let allChild = document.getElementById(
                      "screenSubscriberContainer"
                    );
                    while (allChild.firstChild) {
                      allChild.firstChild.remove();
                    }
                   let sub = sessionlocal.subscribe(
                      event.stream,
                      "screenSubscriberContainer",
                      {
                        insertMode: "append",
                        width: "100%",
                        height: "100%",
                      },
                      handleError
                    );
                    sub.setAudioVolume(0); 
                  });
              });
              const prevSubs = subscribers;
              prevSubs.push(subscriber);
              setSubscribers(prevSubs);
            }
            setIsSubscribed(true);
            console.log("subscribed", isSubscribed);
          }
        });

        sessionlocal.on("streamDestroyed", function (event) {
          console.log("stream destroyed", event.stream.videoType);
          if (event.stream.videoType === "screen") {
            meta["screen"] = meta.screen - 1;
            setMeta({ ...meta });
            console.log("meta", meta);
            console.log("media stopped");
            setHasScreenSubscriber(false);
          }
          if (event.stream.videoType === "camera") {
            console.log(event);
            if (meta.isFullscreen && event.target.id === meta.isFullscreen) {
              if (meta.camera <= 1) {
                meta["screen"] = 0;
                meta["isFullscreen"] = false;
                setMeta({ ...meta });
              } else {
                let fristChild = document.getElementById(
                  "cameraSubscriberContainer"
                ).firstChild;
                console.log("fristChild", fristChild);
                if (fristChild) {
                  fristChild.click();
                }
              }
            }
            meta["camera"] = meta.camera - 1;
            setMeta({ ...meta });
            console.log("meta", meta);
            setPublishersCount((prev) => prev - 1);
            console.log("media stopped");
            // setHasCameraSubscriber(false);
          }
        });

        sessionlocal.on("signal", function (event) {
          console.log("Signal sent from connection " + event.from.id);
          console.log(event);
          if (
            event.type === "signal:muteAll" &&
            event.from.id !== sessionlocal.connection.id
          ) {
            setOnVideoNotification("Admin has muted you!");
            console.log("Admin has muted you!");
            publisher.publishAudio(false);
            setIsMicMuted(true);
          }

          if (
            event.type === "signal:raiseHand" &&
            event.from.id !== sessionlocal.connection.id
          ) {
            setOnVideoNotification(event.data + " would like to speak!");
            console.log(event.data + " would like to speak!");
          }
          // Process the event.data property, if there is any data.
        });
        sessionlocal.on("archiveStarted", function (event) {
          setCurrentArchive(event);
          setIsBeingRecorded(true);
          console.log("ARCHIVE STARTED");
        });

        sessionlocal.on("archiveStopped", function (event) {
          setCurrentArchive(event);
          setIsBeingRecorded(false);
          console.log("ARCHIVE STOPED");
        });
      });
  };

  const FullscreenModeHandler = () => {
    if (meta.isFullscreen) {
      meta["screen"] = 0;
      meta["isFullscreen"] = false;
      setMeta({ ...meta });
    }
  };

  useEffect(() => {
    if (onVideoNotification) {
      console.log(onVideoNotification);
      setTimeout(() => setOnVideoNotification(null), 5000);
    }
  }, [onVideoNotification]);

  useEffect(() => {
    publishVideo();

    // recording attendance

    firebase
      .firestore()
      .collection(`videoConference/${currentWorkspace.workspaceId}/rooms`)
      .doc(`${currentChannel.roomId}`)
      .set(
        {
          attendance: firebase.firestore.FieldValue.arrayUnion({
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            joinedAt: moment().valueOf(),
          }),
        },
        { merge: true }
      );
  }, []);

  // screenshare func
  const screenShareHandler = () => {
    let err = false;
    var publisher = window.OT.initPublisher(
      "screenPublisherContainer",
      {
        videoSource: "screen",
        insertMode: "append",
        height: "100%",
        width: "100%",
      },
      function (error) {
        if (error) {
          // Look at error.message to see what went wrong.
          console.log(error, "error screensharing");

          setIsPublishingScreen(false);
          setScreenShareOn(false);
          err = true;
          setScreenPublisher(null);
        } else {
          session.publish(publisher, function (error) {
            if (error) {
              // Look error.message to see what went wrong.
              console.log(error, "error screensharing publishing");
            }
          });
        }
      }
    );

    publisher.on("mediaStopped", function (event) {
      // The user clicked stop.
      console.log("screenshare stopped");
      setScreenPublisher(null);
      setIsPublishingScreen(false);
      setScreenShareOn(false);
    });
    if (!err) {
      setScreenPublisher(publisher);
    } else if (err) {
      console.log("err");
      setScreenPublisher(null);
    }
  };

  const muteAll = () => {
    session.signal(
      {
        type: "muteAll",
        data: `${currentUser.displayName}`,
      },
      function (error) {
        if (error) {
          console.log("signal error (" + error.name + "): " + error.message);
        } else {
          setMuteAll(true);
          console.log("signal sent.");
        }
      }
    );
  };

  useEffect(() => {
    if (!MuteAll) return;
    setTimeout(() => setMuteAll(false), 5000);
  }, [MuteAll]);

  // switch related
  const requestToPublish = () => {
    firebase
      .firestore()
      .collection(`videoConference/${currentWorkspace.workspaceId}/rooms`)
      .doc(`${currentChannel.roomId}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          let oldWaitingForPublishing = [];
          if (doc.data().waitingForPublishing) {
            oldWaitingForPublishing = doc.data().waitingForPublishing;
          }
          if (oldWaitingForPublishing.length !== 0) {
            let userAlreadyExists = false;
            const modifiedWaitingForPublishing = oldWaitingForPublishing.map(
              (user, index) => {
                if (user.uid === currentUser.uid) {
                  userAlreadyExists = true;
                  user.requestedAt = moment().valueOf();
                  return user;
                } else {
                  return user;
                }
              }
            );
            console.log(
              "modifiedWaitingForPublishing",
              modifiedWaitingForPublishing
            );

            if (userAlreadyExists) {
              firebase
                .firestore()
                .collection(
                  `videoConference/${currentWorkspace.workspaceId}/rooms`
                )
                .doc(`${currentChannel.roomId}`)
                .set(
                  {
                    waitingForPublishing: modifiedWaitingForPublishing,
                  },
                  { merge: true }
                );
            } else if (!userAlreadyExists) {
              firebase
                .firestore()
                .collection(
                  `videoConference/${currentWorkspace.workspaceId}/rooms`
                )
                .doc(`${currentChannel.roomId}`)
                .set(
                  {
                    waitingForPublishing: firebase.firestore.FieldValue.arrayUnion(
                      {
                        uid: currentUser.uid,
                        displayName: currentUser.displayName,
                        requestedAt: moment().valueOf(),
                        photoUrl: currentUser.photoURL,
                      }
                    ),
                  },
                  { merge: true }
                );
            }
          } else {
            firebase
              .firestore()
              .collection(
                `videoConference/${currentWorkspace.workspaceId}/rooms`
              )
              .doc(`${currentChannel.roomId}`)
              .set(
                {
                  waitingForPublishing: firebase.firestore.FieldValue.arrayUnion(
                    {
                      uid: currentUser.uid,
                      displayName: currentUser.displayName,
                      requestedAt: moment().valueOf(),
                      photoUrl: currentUser.photoURL,
                    }
                  ),
                },
                { merge: true }
              );
          }
        } else {
          firebase
            .firestore()
            .collection(`videoConference/${currentWorkspace.workspaceId}/rooms`)
            .doc(`${currentChannel.roomId}`)
            .set(
              {
                waitingForPublishing: firebase.firestore.FieldValue.arrayUnion({
                  uid: currentUser.uid,
                  displayName: currentUser.displayName,
                  requestedAt: moment().valueOf(),
                  photoUrl: currentUser.photoURL,
                }),
              },
              { merge: true }
            );
        }
      });
  };

  const waitingToPublish = () => {
    firebase
      .firestore()
      .collection(`videoConference/${currentWorkspace.workspaceId}/rooms`)
      .doc(`${currentChannel.roomId}`)
      .onSnapshot((doc) => {
        if (doc.exists) {
          setPublishersWaiting(doc.data().waitingForPublishing);
          setCurrentPublishers(doc.data().currentPublishers);
        }
      });
  };

  useEffect(() => {
    waitingToPublish();
  }, []);

  useEffect(() => {
    if (
      currentPublishers &&
      session &&
      currentUser.uid !== channelDetails.createdBy
    ) {
      currentPublishers.forEach((pub, index) => {
        if (
          isSubOnly &&
          pub.uid === currentUser.uid &&
          pub.requestCompletedType === "admit"
        ) {
          console.log("is publishing");
          setIsSubOnly(false);

          setTimeout(() => {
            var publisherProperties = {
              insertMode: "append",
              width: "100%",
              height: "100%",
              name: currentUser.displayName,
            };
            var publisherLocal = window.OT.initPublisher(
              "cameraPublisherContainer",
              publisherProperties
            );
            console.log(session, "session");
            session.publish(publisherLocal);
            setPublisher(publisherLocal);
          }, 1200);
        } else if (
          pub.uid === currentUser.uid &&
          pub.requestCompletedType === "reject"
        ) {
          console.log("you've been rejected");
        } else if (
          !isSubOnly &&
          session &&
          pub.uid === currentUser.uid &&
          pub.requestCompletedType === "removed"
        ) {
          // publisher.destroy()

          const tempCurrentPublishers = currentPublishers;
          tempCurrentPublishers.splice(index, 1);

          firebase
            .firestore()
            .collection(`videoConference/${currentWorkspace.workspaceId}/rooms`)
            .doc(`${currentChannel.roomId}`)
            .set(
              {
                currentPublishers: tempCurrentPublishers,
              },
              { merge: true }
            )
            .then(() => {
              setIsMicMuted(false);
              setIsCameraOff(false);
              setScreenShareOn(false);
              console.log("disconnect");
              if (publisher) {
                session.unpublish(publisher);
                publisher.destroy();
              }
              conferenceSession.off();
              conferenceSession.disconnect();
              setIsSubOnly(true);
              if (subscribers.length > 0) {
                subscribers.forEach((sub) => {
                  session.unsubscribe(sub);
                  setPublishersCount((prev) => prev - 1);
                });
              }
              if (subscriberScreen) {
                session.unsubscribe(subscriberScreen);
              }
              setMeta({
                publisher: 0,
                subscriber: 0,
                camera: 0,
                screen: 0,
              });
              session.off();
              session.disconnect();
              // turn into a subscriber
              subscribeOnly();
            });
        }
      });
    }
  }, [currentPublishers, publisher, session, isSubOnly]);

  useEffect(() => {
    return () => {
      console.log("unmount");

      setSession(null);
      setIsSubOnly(false);
      setPublishersWaiting(null);
      setPublisher(null);
      setScreenPublisher(null);
      setCurrentPublishers(null);
    };
  }, []);

  const RaiseHand = () => {
    if (HandRaise) return;
    session.signal(
      {
        type: "raiseHand",
        data: `${currentUser.displayName}`,
      },
      function (error) {
        if (error) {
          console.log("signal error (" + error.name + "): " + error.message);
        } else {
          setHandRaise(true);
          console.log("signal sent.");
        }
      }
    );
  };

  useEffect(() => {
    if (!HandRaise) return;
    setTimeout(() => setHandRaise(false), 10000);
  }, [HandRaise]);

  const classTileView = (count) => {
    /*
      1-4 col-6
      4-9 col-4
      9-16 col-3
      16-36 col-2
      no less than col-2
    */
    if (count >= 1 && count <= 4) {
      return "grid-layout-4";
    } else if (count > 4 && count <= 9) {
      return "grid-layout-9";
    } else if (count > 9 && count <= 16) {
      return "grid-layout-16";
    } else if (count > 16) {
      return "grid-layout-16";
    }
  };

  const [CurrentArchive, setCurrentArchive] = useState(null);

  const startRecording = () => {
    session.archives.forEach((item) => {
      console.log(item);
    });
    if (isBeingRecorded) {
      stopRecording();
    } else {
      axios
        .post(IGESIA_VIDEO_SERVER_BASE_URL + "/archive/start", {
          sessionId: session.id,
        })
        .then((archive) => {
          setCurrentArchive(archive.data);
          console.log(archive.data);
          setIsBeingRecorded(true);
        })
        .catch((error) => {
          console.log(error);
          swal("Error", "Unable to start recording!", "error");
        });
    }
  };

  const stopRecording = () => {
    axios
      .post(IGESIA_VIDEO_SERVER_BASE_URL + "/archive/stop", {
        archiveId: CurrentArchive.id,
      })
      .then(function (data) {
        setIsBeingRecorded(false);
        console.log(data);
      })
      .catch(function (error) {
        console.log(error);

        swal("Error", "Unable to stop recording!", "error");
      });
  };

  // useEffect(() => {
  //   if (publishersCount > 0 && hasCameraSubscriber && subscriber) {
  //     console.log(cameraSubsRef.current.childNodes, "camera subs");
  //     const nodeListOfVids = cameraSubsRef.current.childNodes;

  //     nodeListOfVids.forEach((videoEl, index) => {
  //       console.log(videoEl, "videoEl", index)
  //       videoEl.className = classTileView(publishersCount)
  //     })
  //   }
  // },[publishersCount, hasCameraSubscriber, subscriber, cameraSubsRef])

  return (
    <>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/igesia-web-a5934.appspot.com/o/images%2Fpublic%2Figesia-logo-positive-sm.png?alt=media&token=c7b1d5b9-c7b1-4e6d-a771-3d88321d20f6"
        alt="igesia-logo"
        height="35px"
        className="igesia-logo-video"
      />
      {/* {!isSubOnly ? <div id="cameraPublisherContainer"></div> : null} */}
      <div className={`${!active ? "d-none" : "App-main"}`}>
        <div className="App-video-container">
          {!isSubOnly ? (
            <div
              id="cameraPublisherContainer"
              className={Classes.cameraPublisherClass}
            />
          ) : null}
          <div
            id="screenPublisherContainer"
            className={Classes.screenPublisherClass}
          />
          <div
            id="cameraSubscriberContainer"
            className={`${Classes.cameraSubscriberClass} ${meta.camera > 16?"five-tile": meta.camera > 9?"four-tile": meta.camera > 4?"three-tile": null}`}

          />
          <div
            id="screenSubscriberContainer"
            className={Classes.screenSubscriberClass}
          />
        </div>

        {/* buttons */}

        {!isSubOnly ? (
          <>
            <div className="Controls btn-group">
              {/* micbutton */}
              <button
                title={isMicMuted ? "Unmute" : "Mute"}
                className={
                  isMicMuted
                    ? "btn btn-dark btn-sm py-1 px-3"
                    : "btn btn-success btn-sm py-1 px-3"
                }
                onClick={() => {
                  if (isMicMuted) {
                    publisher.publishAudio(true);
                    setIsMicMuted(false);
                  } else if (!isMicMuted) {
                    publisher.publishAudio(false);
                    setIsMicMuted(true);
                  }
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <FontAwesomeIcon
                    icon={isMicMuted ? faMicrophoneSlash : faMicrophone}
                  />
                  <small>Turn Mic {isMicMuted ? "On" : "Off"}</small>
                </div>
              </button>

              {/* video button */}
              <button
                title={isCameraOff ? "Video on" : "Video off"}
                className={
                  isCameraOff
                    ? "btn btn-dark btn-sm py-1 px-3"
                    : "btn btn-success btn-sm py-1 px-3"
                }
                onClick={() => {
                  if (isCameraOff) {
                    publisher.publishVideo(true);
                    setIsCameraOff(false);
                  } else if (!isCameraOff) {
                    publisher.publishVideo(false);
                    setIsCameraOff(true);
                  }
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <FontAwesomeIcon
                    icon={isCameraOff ? faVideoSlash : faVideo}
                  />
                  <small>Turn Video {isCameraOff ? "On" : "Off"}</small>
                </div>
              </button>

              {/* share screen button */}

              <button
                title="Share screen"
                className={
                  !screenPublisher
                    ? "btn btn-dark btn-sm py-1 px-3"
                    : "btn btn-success btn-sm py-1 px-3"
                }
                onClick={() => {
                  if (!screenPublisher) {
                    setIsPublishingScreen(true);
                    setScreenShareOn(true);
                    // setTimeout(() => {
                    screenShareHandler();
                    // }, 1200);
                  } else if (screenPublisher) {
                    setIsPublishingScreen(false);
                    setScreenShareOn(false);
                    screenPublisher.publishVideo(false);
                    session.unpublish(screenPublisher);
                    setScreenPublisher(null);
                  }
                }}
              >
                <div className="d-flex flex-column align-items-center">
                  <FontAwesomeIcon icon={faDesktop} />
                  <small>Share screen</small>
                </div>
              </button>

              <button
                title="Mute all"
                className="btn btn-dark btn-sm py-1 px-3"
                onClick={() => {
                  muteAll();
                }}
              >
                <div
                  className={`d-flex flex-column align-items-center ${
                    MuteAll ? "text-primary" : "text-white"
                  }`}
                >
                  <FontAwesomeIcon icon={faVolumeMute} />
                  <small>Click to Mute All</small>
                </div>
              </button>
              {/* <button
                title="Start recording"
                className={`btn  btn-sm py-1 px-3 ${
                  isBeingRecorded ? "btn-success" : "btn-dark"
                }`}
              >
                <div
                  className="d-flex flex-column align-items-center t"
                  onClick={startRecording}
                >
                  <FontAwesomeIcon icon={faStopCircle} />
                  <small>
                    {isBeingRecorded ? "Stop recording" : "Start recording"}
                  </small>
                </div>
              </button> */}
            </div>
            <div className="left-controls bg-dark p-2 rounded">
              <span
                className={`cursor-pointer d-flex flex-column align-items-center ${
                  HandRaise ? "text-primary" : "text-white"
                }`}
                onClick={RaiseHand}
              >
                <FontAwesomeIcon icon={faHandPaper} size="lg" />
                <small>Raise hand</small>
              </span>
            </div>
            {!isPublishingScreen && meta.isFullscreen && (
              <div className="right-controls bg-dark p-2 rounded">
                <span
                  className="cursor-pointer d-flex flex-column align-items-center text-white"
                  onClick={FullscreenModeHandler}
                >
                  {/* {isFullscreenMode ? ( */}
                  <>
                    <FontAwesomeIcon icon={faThLarge} size="lg" />
                    <small>Tile Mode</small>
                  </>
                  {/* ) : (
                    <>
                      <FontAwesomeIcon icon={faSquare} size="lg" />
                      <small>Fullscreen Mode</small>
                    </> */}
                  {/* )} */}
                </span>
              </div>
            )}
            {onVideoNotification ? (
              <div className="on-video-notification">{onVideoNotification}</div>
            ) : null}
          </>
        ) : (
          <div className="Controls btn-group">
            <button className="btn btn-light mx-2" onClick={requestToPublish}>
              <div className="d-flex flex-column align-items-center">
                <FontAwesomeIcon icon={faVideo} size="lg" />
                <p className="mb-0">Tap to Join Video</p>
              </div>
            </button>
          </div>
        )}
      </div>

      {/* {isSubOnly ? (
        <button
          className="btn btn-dark btn-sm mx-2"
          onClick={requestToPublish}
        >
          Tap to join video
        </button>
      ) : null} */}
    </>
  );
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  participants: state.channel.participants,
  currentUser: state.user.currentUser,
  currentSubscription: state.subscription.currentSubscription,
  channelDetails: state.platform.channelDetails,
  isPublisher: state.platform.isPublisher,
  conferenceSession: state.platform.conferenceSession,
});

export default connect(mapStateToProps, {
  setIsVideoVisible,
  setChannelDetails,
  setConferenceSession,
})(VideoConference);