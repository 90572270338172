import React from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import firebase from "firebase";
import { connect } from "react-redux";
import {
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
} from "../../../actions/index";
import Participants from "../../Right/UserNameAvatar";
import moment from "moment";
import _ from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes, faUndo } from "@fortawesome/free-solid-svg-icons";
import "./Common.css";
import {ROOMS_PARTICIPANTS, API_BASE_URL} from "../../../config/index";
import axios from "axios";

class TaskCommon extends React.Component {
  // ALL STATES
  state = {
    addAll: false,
    removeAll: false,
    setDeadline: false,
    allParticipantsWithName: [],
    searchTerm: "",
    regex: ""
  };
  componentDidMount() {
    this.props.setTaskDeadline(
      new Date(Date.now() + 2 * 365 * 24 * 60 * 60000)
    );

    if (this.props.allParticipants.length > 0) {
      let participantsWithName = [];

      //console.log(this.props.currentChannel.roomId,"yzz")
      //console.log(this.props.workSpace.workspaceId,"yzz")

      axios.post(`${API_BASE_URL}${ROOMS_PARTICIPANTS}`, {
        workspace_id: this.props.workSpace.workspaceId,
        room_id: this.props.currentChannel.roomId,
      }).then((res) => {
        if(res.status === 200) {
          //console.log(res.data.data,"yzz my participation")
          this.setState({ allParticipantsWithName: res.data.data });
        }
      })


      /*this.props.allParticipants.forEach((part, index) => {
        firebase.firestore().collection("users").doc(`${part.uid}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              participantsWithName.push({ ...part, displayName: doc.data().displayName, avatar: doc.data().avatar });
            } else {
            }
          }).then(() => {
            const sortedByName = _.sortBy(participantsWithName, (p) => p.displayName.toLowerCase())
            const sortedByRoleAndName = _.orderBy(sortedByName, 'isAdmin', ['desc']);
            console.log(sortedByRoleAndName,"yzz my participation")
            this.setState({ allParticipantsWithName: sortedByRoleAndName });
          })
          .catch(error => console.error(error));
      })*/
    }


  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.editedTask !== this.props.editedTask) {
      if (this.props.editedTask) {
        this.setState({ setDeadline: true });
      }
    }
  }
  // HANDLES USERS ADDED
  handleChange = (e) => {
    const {
      currentChannel,
      currentUser,
      // participants,
      channelUsers,
      allParticipants,
    } = this.props;
    const participantsIds = allParticipants.map((item) => item.uid);

    if (e.target.name === "folder") {
      this.props.setTaskFolder(e.target.value);
    }

    if (e.target.name === "channelUsers[]") {
      let prev = channelUsers.includes(e.target.value)
        ? channelUsers.filter((a) => a != e.target.value)
        : [...channelUsers, e.target.value];
      if (
        participantsIds.filter((item) => item != currentUser.uid).length ===
        prev.length
      ) {
        this.setState({ addAll: true });
      } else {
        this.setState({ addAll: false });
      }
      this.props.setTaskChannelUsers(prev);
    }
  };

  // HANDLES DEADLINE
  onChange = (deadline) => {
    //console.log(deadline);
    this.props.setTaskDeadline(deadline);
  };

  // GET NAMES OF USERS FROM FIREBASE
  getName = (key) => {
    let name = "";
    firebase
      .database()
      .ref("users")
      .child(key)
      .on("value", (snap) => {
        if (snap.val()) {
          name = snap.val().name
            ? snap.val().name
            : `${snap.val().firstName} ${snap.val().lastName}`;
        }
      });
    return name;
  };

  getFolderName = (key) => {
    let folder_name = "";
    firebase
      .database()
      .ref("folders")
      .child(key)
      .on("value", (snap) => {
        folder_name = snap.val() && snap.val().folder_name;
      });
    return folder_name;
  };

  selectAll = () => {
    const {
      currentChannel,
      currentUser,
      participants,
      allParticipants,
    } = this.props;
    var newData = [
      ...allParticipants,
      {
        uid: currentChannel?.createdBy,
        joinedAt: currentChannel.createdAt,
        isAdmin: true,
      },
    ];
    const participantsIds = newData.map((item) => item.uid);
    if (this.state.addAll) {
      this.setState({ addAll: false });
    } else {
      this.setState({ addAll: true });
      console.log("in a select all method", this.props.setTaskChannelUsers);
      this.props.setTaskChannelUsers(
        participantsIds.filter((item) => item != currentUser.uid)
      );
    }
  };

  unselectAll = () => {
    this.setState({ removeAll: true })
    this.setState({ addAll: false })
    this.props.setTaskChannelUsers([]);
  };



  // MAIN RENDER FUNCTION
  render() {
    const {
      channelUsers,
      deadline,
      folder,
      everyone,
      currentChannel,
      currentUser,
      participants,
      allParticipants,
      editedTask,
    } = this.props;
    //console.log(everyone);
    var newData = [
      ...this.state.allParticipantsWithName,
      {
        uid: currentChannel?.createdBy,
        joinedAt: currentChannel?.createdAt,
        isAdmin: true,
      },
    ];
    const { setDeadline } = this.state;
    return (
      <div className="shadow-sm rounded-lg p-2 m-2 bg-card-theme">
        {/* <div className="mb-3">
                    <p className="mb-2 font-weight-bold text-theme small">Associate folder</p>
                    <InputGroup className="mb-3">
                        <select name="folder" className="custom-select custom-select-sm" onChange={this.handleChange}>
                            <option selected={(folder === "" || folder === null) ? true : false} value="">Uncategorized</option>
                            {currentChannel && currentChannel.folders && currentChannel.folders.map(item => <option value={item} selected={folder === item ? true : false}>{this.getFolderName(item)}</option>)}
                        </select>
                    </InputGroup>
                </div> */}
        <div className="mb-3">
          <Form.Group>
            <Form.Check
              type="checkbox"
              label="Set Deadline (Optional)"
              checked={setDeadline}
              onChange={() => {
                let prevState = this.state.setDeadline;
                this.setState(
                  {
                    setDeadline: !prevState,
                  },
                  // () => {
                  //   prevState
                  //     ? this.props.setTaskDeadline(
                  //         new Date(Date.now() + 2 * 365 * 24 * 60 * 60000)
                  //       )
                  //     : this.props.setTaskDeadline(
                  //         new Date(Date.now() + 60 * 60000)
                  //       );
                  // }
                );
              }}
            />
          </Form.Group>
        </div>
        {setDeadline && (
          <div className="mb-3" style={{ position: "relative", zIndex: "10" }}>
            <p className="mb-2 font-weight-bold text-theme small">Deadline</p>
            <DateTimePicker
              className="mb-1 d-block bg-white"
              onChange={this.onChange}
              value={deadline}
            />
          </div>
        )}

        <Form.Group
          controlId="formBasicCheckbox"
        // style={{ maxHeight: "300px", overflow: "scroll" }}
        >
          <div>
            <p className="mb-2 font-weight-bold text-theme small">
              Assign users
            </p>
            <Form.Group>
              <Form.Check
                required
                type="switch"
                name="everyone"
                id="everyone"
                label="Everyone who joins"
                value="everyone"
                checked={everyone}
                onChange={() => this.props.setTaskEveryone(!everyone)}
              />
            </Form.Group>
            <div className="d-flex align-items-center justify-content-between">
              {!everyone && (
                <div className="pointer">
                  <Form.Check
                    type="switch"
                    id="addAll"
                    value="addAll"
                    name="addAll"
                    onChange={this.selectAll}
                    label="All participants"
                    title="Click to select all participants"
                    checked={this.state.addAll}
                  />
                </div>
              )}
              {!everyone && (
                <Button
                  variant="light"
                  title="Click to clear all"
                  onClick={this.unselectAll}
                >
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faUndo} size="sm" />
                    <span className="flex-grow-1 ml-1">Clear all</span>
                  </div>
                </Button>
              )}
            </div>
          </div>

          {
            !everyone &&
              this.state.allParticipantsWithName ?
              <InputGroup className="my-1">
                <div className="d-flex align-items-center rounded flex-grow-1  bg-theme">
                  <div className="py-2 pr-2 pl-3">
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="sm"
                      color="#aeaeae"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <Form.Control
                      type="text"
                      id="participantsSearch"
                      className="border-0 px-0 bg-theme text-theme rounded"
                      onChange={(e) => {
                        this.setState({ searchTerm: e.target.value }, () => {
                          const reg = _.escapeRegExp(this.state.searchTerm);
                          // console.log(reg,"Reg")
                          const regex = new RegExp(reg, "gi");
                          this.setState({ regex: regex })
                        })
                      }}
                      placeholder={"Search"}
                      value={this.state.searchTerm}
                      style={{ margin: 0 }}
                    />
                  </div>
                  {this.state.searchTerm.length > 0 && (
                    <div
                      className="py-2 pl-2 pr-3"
                      onClick={() => this.setState({ searchTerm: "" })}
                    >
                      <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                    </div>
                  )}
                </div>
              </InputGroup>
              : null
          }

          {
            this.state.searchTerm && this.state.searchTerm.length > 0 ?
              !everyone &&
              this.state.allParticipantsWithName &&
              newData.filter(item => {
                console.log(item, "item")
                return item.displayName?.match(this.state.regex)
              }).map((participant) => (
                <div>
                  {participant.uid === currentUser.uid ? null : (
                    <Form.Check
                      key={participant.uid}
                      type="switch"
                      id={participant.uid}
                      value={participant.uid}
                      name="channelUsers[]"
                      checked={channelUsers.includes(participant.uid)}
                      onChange={this.handleChange}
                      label={
                        participant.displayName
                      }
                    />
                  )}
                </div>
              ))
              : !everyone &&
              this.state.allParticipantsWithName &&
              newData.map((participant) => (
                <div>
                  {participant.uid === currentUser.uid ? null : (
                    <Form.Check
                      key={participant.uid}
                      type="switch"
                      id={participant.uid}
                      value={participant.uid}
                      name="channelUsers[]"
                      checked={channelUsers.includes(participant.uid)}
                      onChange={this.handleChange}
                      label={
                        participant.displayName
                      }
                    />
                  )}
                </div>
              ))
          }



        </Form.Group>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  allParticipants: state.channel.participants,
  currentUser: state.user.currentUser,
  workSpace: state.workspace.currentWorkspace,
  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  old_folder: state.task.old_folder,
  everyone: state.task.everyone,
  sharing: state.platform.sharing,
});

export default connect(mapStateToProps, {
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
})(TaskCommon);
