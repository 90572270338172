import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import {
  faFileAlt,
  faPaperPlane,
  faFilePdf,
  faLink,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faExclamationCircle,
  faImage,
  faPlusCircle,
  faQuestionCircle,
  faAngleDown,
  faArrowLeft,
  faSearch,
  faTimes,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faSortAlphaUp,
  faSortAlphaUpAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Dropdown,
  Button,
  DropdownButton,
  Badge,
  Form,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
//import DisplayName from "./DisplayName";
import IsAdmin from "../../IsAdmin";

import moment from "moment";
import {
  setRightPanel,
  setContentTypeAndValue,
  setReadingTitles,
  setReadingLinks,
  setReadingFiles,
  setReadingUrls,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
  resetData,
  setTaskSharing,
  setSharedTask,
  setEditedTask,
  setEditedPublishedTask,
  setEditingPublished,
  setTaskEditing,
} from "../../../actions/index";

import { toast } from "react-toastify";
import axios from "axios";
import CreateReading from "./CreateReading1";
import Reading from "./Reading1";
import {
  API_BASE_URL,
  DRIVE_FILE,
  DRIVE_UPLOAD,
  READING_CREATE,
  READING_SHARE,
  READING_UPDATE,
  READING_REMOVE,
  READING_EDIT,
  APP_CLOSE,
  ROOM_APP_LIST,
} from "../../../config";

toast.configure();

class ReadingHomepage extends Component {
  state = {
    selected: "Published",
    publishedReadings: [],
    publishedReadingsData: [],
    ReadingSubject: "",
    ReadingDescription: "",
    savedReadings: [],
    savedReadingsData: [],
    editedReading: {},
    selectedReading: {},
    view: 0,
    error: "",
    sharedAgainReading: {},
    editedPublishedReading: {},
    publishedReadingId: null,
    readingMessageData: null,
    searchTerm: "",
    regex: "",
    sortType: "unreverse",
    reversed: false,
  };

  async componentDidMount() {
    const { currentChannel, currentWorkspace, currentUser } = this.props;

    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/saved`
      )
      .orderBy("timestamp", "desc");

    const queryMsg = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/messages`
      )
      .orderBy("timestamp", "desc");

    query.where("type", "==", 2).onSnapshot((snapshot) => {
      axios
        .post(`${API_BASE_URL}${ROOM_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          user_id: currentUser.uid,
          message_type: 2,
          app_state: "saved",
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ savedReadings: res.data.data });
            this.handleReadingsData(res.data.data);
            this.setState({ sortType: "unreverse" });
          }
        });
    });

    queryMsg.where("type", "==", 2).onSnapshot((snapshot) => {
      axios
        .post(`${API_BASE_URL}${ROOM_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          user_id: currentUser.uid,
          message_type: 2,
          app_state: "published",
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ publishedReadings: res.data.data });
            this.handlePublishReadingsData(res.data.data);
            this.setState({ sortType: "unreverse" });
          }
        });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.sharing && this.state.view === 0) {
      this.setState({ view: 1 });
    }
  }
  handleReadingsData = async (savedReadings) => {
    let savedReadingsData = [];
    savedReadingsData = await Promise.all(
      savedReadings.map(async (savedReading) => {
        const readingData = await firebase
          .firestore()
          .collection(`readings`)
          .doc(savedReading.contentId)
          .get();
        return {
          ...readingData.data(),
          id: readingData.id,
        };
      })
    );
    if (this.state.sortType === "atoz") {
      const sortedReadingData = _.sortBy(savedReadingsData, (reading) =>
        reading.readingSubject.toLowerCase()
      );
      savedReadingsData = sortedReadingData;
    }
    if (this.state.sortType === "ztoa") {
      const sortedReadingData = _.sortBy(savedReadingsData, (reading) =>
        reading.readingSubject.toLowerCase()
      );
      savedReadingsData = sortedReadingData.reverse();
    }
    this.setState({ savedReadings, savedReadingsData });
    if (savedReadings.length === 0) {
      this.setState({ savedReadings });
    }
  };

  handlePublishReadingsData = async (publishedReadings) => {
    let publishedReadingsData = [];
    publishedReadingsData = await Promise.all(
      publishedReadings.map(async (publishedReading) => {
        const readingData = await firebase
          .firestore()
          .collection(`readings`)
          .doc(publishedReading.contentId)
          .get();
        return {
          ...readingData.data(),
          id: readingData.id,
          messageId: publishedReading.id,
        };
      })
    );
    const sortedReadingData = _.sortBy(publishedReadingsData, (reading) =>
      reading.readingSubject.toLowerCase()
    );
    if (this.state.sortType === "atoz") {
      publishedReadingsData = sortedReadingData;
    }
    if (this.state.sortType === "ztoa") {
      publishedReadingsData = sortedReadingData.reverse();
    }
    this.setState({ publishedReadings, publishedReadingsData });
    if (publishedReadings.length === 0) {
      this.setState({ publishedReadings });
    }
  };
  addReadingView = (view) => {
    this.setState({ view });
  };
  handleSelected = (selected) => {
    this.setState({ selected });
  };
  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));
  showSelectedReading = (id) => {
    let selectedReading = { id: id };
    this.setState({ selectedReading, view: 2 });
  };
  handleSubAndDesc = (e) => {
    // console.log(e.target.value);
    if (e.target.name === "ReadingSubject") {
      this.setState({ ReadingSubject: e.target.value });
    } else {
      this.setState({ ReadingDescription: e.target.value });
    }
  };

  /*uploadFile = async (file, key) => {
    console.log("Key: ", key, "\nFile: ", file);
    const { currentUser } = this.props;
    if (file) {
      try {
        let res = await axios.post(`${API_BASE_URL}${DRIVE_FILE}`, {
          user_id: currentUser.uid,
          file_name: file.name,
          file_content_type: file.type,
          file_content: file.file_content,
        });

        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
      }
    } else return "";
  };*/
  uploadFile = async (file) => {
    const { currentUser, folder_id } = this.props;
    console.log(this.props);
    let requestData = {};

    const fileData = new FormData();
    fileData.append("file", file, `${file.lastModified}-${file.name}`);
    fileData.append("user_id", currentUser.uid);

    if (folder_id) {
      fileData.append("folder_id", folder_id);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (ProgressEvent) => {
        this.setState({
          percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,
        });
      },
    };
    if (file) {
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        // console.log(res);
        return res.data.data["file_url"];
      } catch (error) {
        console.log(error);
      }
    } else return "";
  };

  sortReadingData = () => {
    if (this.state.sortType === "reverse") {
      const { savedReadingsData, publishedReadingsData } = this.state;
      this.setState(
        {
          savedReadingsData: savedReadingsData.reverse(),
          publishedReadingsData: publishedReadingsData.reverse(),
        },
        () => {
          this.setState({ reversed: true });
        }
      );
    } else if (this.state.sortType === "unreverse") {
      if (this.state.reversed) {
        const { savedReadingsData, publishedReadingsData } = this.state;
        this.setState({
          savedReadingsData: savedReadingsData.reverse(),
          publishedReadingsData: publishedReadingsData.reverse(),
        });
      } else {
        const { savedReadingsData, publishedReadingsData } = this.state;
        this.setState({
          savedReadingsData,
          publishedReadingsData,
        });
      }
      this.setState({ reversed: false });
    } else if (this.state.sortType === "atoz") {
      const { savedReadingsData, publishedReadingsData } = this.state;
      this.setState(
        {
          savedReadingsData: _.sortBy(savedReadingsData, (reading) =>
            reading.readingSubject.toLowerCase()
          ),
          publishedReadingsData: _.sortBy(publishedReadingsData, (reading) =>
            reading.readingSubject.toLowerCase()
          ),
        },
        () => {
          this.setState({ reversed: true });
        }
      );
    } else if (this.state.sortType === "ztoa") {
      const { savedReadingsData, publishedReadingsData } = this.state;
      this.setState(
        {
          savedReadingsData: _.sortBy(savedReadingsData, (reading) =>
            reading.readingSubject.toLowerCase()
          ).reverse(),
          publishedReadingsData: _.sortBy(publishedReadingsData, (reading) =>
            reading.readingSubject.toLowerCase()
          ).reverse(),
        },
        () => {
          this.setState({ reversed: true });
        }
      );
    }
  };

  render() {
    const {
      type,
      titles,
      links,
      files,
      urls,
      currentWorkspace,
      currentChannel,
      currentUser,
      deadline,
      channelUsers,
      folder,
      everyone,

      allParticipants,
      taskType,
      contentType,
      resetAllTasks,
      setTaskType,
      setContentType,
      setReadingFiles,
      setReadingLinks,
      setReadingUrls,
      setReadingTitles,
      resetData,
      setTaskChannelUsers,
      setTaskEveryone,
      setTaskDeadline,
      setTaskSharing,
      setSharedTask,
      sharedTask,
      sharing,
      editing,
      editedTask,
      setTaskEditing,
      setEditedTask,
      editingPublished,
      editedPublishedTask,
    } = this.props;
    const {
      editedReading,
      ReadingDescription,
      ReadingSubject,
      selected,
      selectedReading,
      error,
      view,
      savedReadings,
      savedReadingsData,
      publishedReadings,
      publishedReadingsData,
      sharedAgainReading,
      editedPublishedReading,
    } = this.state;

    let coAdmins = allParticipants.filter((participant) => participant.isAdmin);
    //console.log(coAdmins);

    const shareTaskAgain = (taskData) => {
      //console.log(taskData);
      let titles = [];
      let links = [];
      let attachments = [];
      taskData.readings.forEach((reading) => {
        titles.push(reading.title);
        links.push(reading.link);
        attachments.push(reading.attachment);
      });
      this.props.setReadingTitles(titles);
      this.props.setReadingLinks(links);
      this.props.setReadingUrls(attachments);
      this.props.setTaskFolder(taskData.folderId);
      //this.props.setTaskChannelUsers(taskData.assignedTo);
      if (
        (taskData.all === false &&
          taskData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        taskData.all === true
      ) {
        this.props.setTaskChannelUsers(taskData.assignedTo);
      } else {
        let assignedTo = taskData.assignedTo.filter((user) =>
          allParticipants.find(
            (User) => User.uid === user || user === currentChannel.createdBy
          )
        );
        //console.log(assignedTo);
        this.props.setTaskChannelUsers(assignedTo);
      }

      if (editingPublished) {
        this.setState({
          readingMessageData: taskData,
          publishedReadingId: taskData.id,
        });
      }

      this.props.setTaskEveryone(taskData.all);
      this.props.setTaskDeadline(new Date(taskData.deadline));
      setTaskSharing(false);
      setSharedTask({});
      setEditedTask({});
      this.props.setEditedPublishedTask({});

      setTaskEditing(false);
      if (editing) {
        this.setState({
          editedReading: taskData,
          ReadingSubject: taskData.readingSubject,
          ReadingDescription: taskData.readingDescription,
          view: 1,
        });
      } else if (editingPublished) {
        this.setState({
          editedPublishedReading: taskData,
          ReadingSubject: taskData.readingSubject,
          ReadingDescription: taskData.readingDescription,
          view: 1,
        });
      } else {
        this.setState({
          sharedAgainReading: taskData,
          ReadingSubject: taskData.readingSubject,
          ReadingDescription: taskData.readingDescription,
          view: 1,
        });
      }
    };

    if (
      sharing &&
      Object.keys(sharedTask).length > 0 &&
      Object.keys(sharedAgainReading).length === 0
    ) {
      shareTaskAgain(sharedTask);
    }
    if (
      editing &&
      Object.keys(editedTask).length > 0 &&
      Object.keys(editedReading).length === 0
    ) {
      shareTaskAgain(editedTask);
    }

    if (
      editingPublished &&
      Object.keys(editedPublishedTask).length > 0 &&
      Object.keys(editedPublishedReading).length === 0
    ) {
      shareTaskAgain(editedPublishedTask);
    }

    const image_type = [
      "image/jpeg",
      "image/png",
      "image/svg+xml",
      "image/gif",
    ];

    const pdf_type = ["application/pdf"];

    const word_type = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    const excel_type = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    const powerpoint_type = [
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];

    const shareTask = () => {
      let allImagesUploadedPromises = files.map((file, key) =>
        this.uploadFile(file, key)
      );

      Promise.all(allImagesUploadedPromises).then(async (values) => {
        //console.log("values: ", values);
        //console.log(values);
        //console.log(urls);
        values = values.map((value, key) => {
          if (urls[key]) {
            return urls[key];
          } else {
            return value;
          }
        });
        if (values.length > 0) {
          this.props.setReadingUrls(values);
        } else {
          this.props.setReadingUrls(urls);
        }
        //this.props.setReadingUrls(values);
        console.log("urls: ", this.props.urls);

        // let readings = [];
        // console.log(links);
        // console.log(urls);
        let readings = await titles.map((title, key) => ({
          title: this.props.titles[key],
          link: this.props.links[key],
          attachment: this.props.urls[key] ? this.props.urls[key] : "",
        }));

        console.log("Final readings: ", readings);

        addTask(readings);
      });
    };

    const saveTaskForLater = () => {
      let allImagesUploadedPromises = files.map((file, key) =>
        this.uploadFile(file, key)
      );

      Promise.all(allImagesUploadedPromises).then(async (values) => {
        //console.log("values: ", values);
        //console.log(urls);
        let prevUrls = [...urls];
        console.log(values);
        values = values.map((value, key) => {
          if (urls[key]) {
            return urls[key];
          } else {
            return value;
          }
        });
        // console.log(values);
        // console.log(prevUrls);
        if (values.length > 0) {
          this.props.setReadingUrls(values);
        } else {
          this.props.setReadingUrls(urls);
        }

        //console.log("urls: ", this.props.urls);

        // let readings = [];
        // console.log(links);
        // console.log(urls);
        let readings = await titles.map((title, key) => ({
          title: this.props.titles[key],
          link: this.props.links[key],
          attachment: this.props.urls[key] ? this.props.urls[key] : "",
        }));

        // console.log("Final readings: ", readings);

        saveTask(readings);
      });
    };

    const validateData = () => {
      //console.log(files);
      //console.log(filesWithBase64);
      let filtered_titles = titles.filter((title) => title.length > 0);
      if (titles.length != filtered_titles.length) {
        this.setState({ error: "Please add some value to title(s).\n" });
        return null;
      }
      if (this.state.ReadingSubject.trim().length < 1) {
        this.setState({ error: "Please add some value to File Subject.\n" });
        return null;
      }
      if (titles.length == 0) {
        this.setState({
          error: "Files must have at-least one or more file(s).\n",
        });
        return null;
      }

      let filtered_links = links.filter(
        (link, key) =>
          link.length > 0 || urls[key].length > 0 || files.length > 0
      );
      if (links.length != filtered_links.length) {
        this.setState({ error: "Both link or file cannot be empty.\n" });
        return null;
      }

      if (moment(deadline).diff(moment(Date.now()), "minutes") < 9) {
        this.setState({
          error: "Deadline of task should be atleast 10 minutes.\n",
        });
        return null;
      }

      // if (folder.length == 0) {
      //     this.setState({error: "Please associate a folder to task.\n"});
      //     return null;
      // }

      if (!everyone && channelUsers.length < 1) {
        this.setState({
          error: "Please assign task to atleast one participant.\n",
        });
        return null;
      }

      this.setState({ error: "" });
      return true;
    };

    const saveTask = async (data) => {
      const {
        setReadingUrls,
        setReadingLinks,
        setReadingTitles,
        setReadingFiles,
        setTaskChannelUsers,
        setTaskDeadline,
        setTaskEveryone,
        resetData,
      } = this.props;
      const { savedReadings, savedReadingsData } = this.state;
      // const { editedReading,ReadingDescription,ReadingSubject,selected,selectedReading,error,view ,savedReadings,savedReadingsData,publishedReadings,publishedReadingsData} = this.state;
      // console.log(editedReading);
      //console.log(Object.keys(editedReading).length);
      /* let messageData = {
         timestamp: Date.now(),
         createdAt: firebase.firestore.FieldValue.serverTimestamp(),
         user: {
           id: currentUser.uid,
           name: currentUser.displayName,
           avatar: currentUser.photoURL,
         },
         type: type,
         createdBy: currentUser.uid,
         assignedTo: channelUsers,
         all: everyone,
         folderId: folder ? folder : "",
       };*/

      let readData = [];
      readData[currentUser.uid] = { read: true, readtime: Date.now() };

      /* let taskData = {
         timestamp: Date.now(),
         createdAt: firebase.firestore.FieldValue.serverTimestamp(),
         createdBy: currentUser.uid,
         folderId: folder ? folder : "",
         readingSubject: ReadingSubject,
         readingDescription: ReadingDescription,
         readings: data,
         deadline: moment(deadline).valueOf(),
         all: everyone,
         read: [],
         assignedTo: channelUsers,
       };*/

      if (Object.keys(editedReading).length > 0) {
        let savedReadingDoc = savedReadings.find(
          (Reading) => Reading.contentId === editedReading.id
        );
        let savedReadingId = savedReadingDoc.id;

        try {
          let res = await axios
            .post(`${API_BASE_URL}${READING_UPDATE}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              saved_reading_id: savedReadingId,
              deadline: moment(deadline).valueOf(),
              all_participants: everyone,
              assigned: channelUsers,
              reading_subject: ReadingSubject,
              reading_description: ReadingDescription,
              readings: data,
              folderId: folder ? folder : "",
              save_for_later: true,
            })
            .then(() => {
              const task = firebase.firestore().collection("reading");
              task
                .doc(savedReadingId)
                .get()
                .then((doc) => {
                  console.log(doc);
                  if (doc.exists) {
                    let index = savedReadings.findIndex(
                      (reading) => reading.id === doc.id
                    );
                    console.log(index);
                    if (index !== -1) {
                      // console.log(doc.data());
                      savedReadingsData.splice(index, 1, {
                        ...doc.data(),
                        id: doc.id,
                      });
                      this.setState(savedReadingsData);
                    }
                  }
                });
              this.setState(
                {
                  view: 0,
                  editedReadingDoc: {},
                  ReadingSubject: "",
                  ReadingDescription: "",
                },
                () => {
                  if (this.props.darkTheme) {
                    toast.dark("Files updated and saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  } else {
                    toast("Files updated and saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  }
                }
              );
              this.props.resetData();
              this.props.setReadingTitles([]);
              this.props.setReadingLinks([]);
              this.props.setReadingUrls([]);
              this.props.setReadingFiles([]);
              this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
              this.props.setTaskChannelUsers([]);
              this.props.setTaskEveryone(false);
              this.props.setTaskFolder("");
            });
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let res = await axios
            .post(`${API_BASE_URL}${READING_CREATE}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              //saved_reading_id: savedReadingId,
              deadline: moment(deadline).valueOf(),
              all_participants: everyone,
              assigned: channelUsers,
              reading_subject: ReadingSubject,
              reading_description: ReadingDescription,
              readings: data,
              folderId: folder ? folder : "",
              save_for_later: true,
            })
            .then(() => {
              //this.props.resetData();
              this.setState(
                {
                  view: 0,
                  editedReading: {},
                  ReadingSubject: "",
                  ReadingDescription: "",
                },
                () => {
                  if (this.props.darkTheme) {
                    toast.dark("File saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  } else {
                    toast("File saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  }
                }
              );
              this.props.resetData();
              this.props.setReadingTitles([]);
              this.props.setReadingLinks([]);
              this.props.setReadingUrls([]);
              this.props.setReadingFiles([]);
              this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
              this.props.setTaskChannelUsers([]);
              this.props.setTaskEveryone(false);
              this.props.setTaskFolder("");
            });
        } catch (error) {
          console.log(error);
        }
      }

      /*  const task = firebase.firestore().collection(`readings`);
        const db = firebase
          .firestore()
          .collection(
            `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
          );
        if (Object.keys(editedReading).length > 0) {
          let savedReadingDoc = savedReadings.find(
            (Reading) => Reading.contentId === editedReading.id
          );
          task
            .doc(editedReading.id)
            .update(taskData)
            .then(() => {
              db.doc(savedReadingDoc.id)
                .update({ ...messageData, contentId: editedReading.id })
                .then(() => {
                  setReadingTitles([]);
                  setReadingLinks([]);
                  setReadingUrls([]);
                  setReadingFiles([]);
                  setTaskDeadline(new Date(Date.now() + 10 * 600000));
                  setTaskChannelUsers([]);
                  setTaskEveryone(false);
                  setTaskFolder("");
                  this.setState(
                    {
                      editedReading: {},
                      ReadingSubject: "",
                      ReadingDescription: "",
                      view: 0,
                    },
                    () =>
                      toast("Files updated and saved.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      })
                  );
                })
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        } else {
          task
            .add(taskData)
            .then((taskRef) => {
              db.add({ ...messageData, contentId: taskRef.id })
                .then(() => {
                  //resetData();
                  setReadingTitles([]);
                  setReadingLinks([]);
                  setReadingUrls([]);
                  setReadingFiles([]);
                  setTaskDeadline(new Date(Date.now() + 10 * 600000));
                  setTaskChannelUsers([]);
                  setTaskEveryone(false);
                  setTaskFolder("");
                  this.setState(
                    {
                      editedReading: {},
                      ReadingSubject: "",
                      ReadingDescription: "",
                      view: 0,
                    },
                    () =>
                      toast("Files saved.", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                      })
                  );
                  /*
                  toast("Files saved", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                })
                .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
        }*/
    };

    const shareSavedTask = async (readingId) => {
      const { resetData } = this.props;
      let messageData = savedReadings.find(
        (reading) => reading.contentId === readingId
      );
      console.log(messageData);
      if (!messageData.all && messageData.assignedTo.length === 0) {
        toast.warning(
          "Share Failed: Files is not assigned to any participant.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        return null;
      }
      if (messageData.createdBy !== currentUser.uid) {
        toast.warning("Share Failed: Files is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }

      let readingData = savedReadingsData.find(
        (reading) => reading.id === readingId
      );

      if (readingData.deadline < Date.now()) {
        toast.warning(
          "Deadline has passed,please edit File to change deadline.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        messageData.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants have left the group, please edit  to reassign  participants.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }
      messageData.timestamp = Date.now();
      let savedReadingId = messageData.id;
      try {
        let res = await axios
          .post(`${API_BASE_URL}${READING_SHARE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            saved_reading_id: savedReadingId,
          })
          .then(() => {
            if (this.props.darkTheme) {
              toast.dark("File shared.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            } else {
              toast("File shared.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            }
            this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }
      /*const dbMsg = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      const db = firebase
        .firestore()
        .collection(
          `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
        );
      const dbUpdate = firebase
        .firestore()
        .collection(`workspaces/${currentWorkspace.workspaceId}/rooms`);

      dbMsg
        .add({ ...messageData })
        .then((docRef) => {
          dbUpdate
            .doc(`${currentChannel.roomId}`)
            .update({
              totalMessages: firebase.firestore.FieldValue.increment(1),
              lastMessage: {
                ...messageData,
                contentId: messageData.contentId,
                uid: docRef.id,
              },
            })
            .then(() => {
              resetData();
              db.doc(messageData.id).delete();
              toast("Files shared", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));*/
    };

    const editSavedTask = (readingId) => {
      const { resetData } = this.props;
      let taskData = savedReadingsData.find(
        (reading) => reading.id === readingId
      );
      if (taskData.createdBy !== currentUser.uid) {
        toast.warning("Edit Failed: Files is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }
      //console.log(new Date(taskData.deadline));
      let titles = [];
      let links = [];
      let attachments = [];
      taskData.readings.forEach((reading) => {
        titles.push(reading.title);
        links.push(reading.link);
        attachments.push(reading.attachment);
      });
      this.props.setReadingTitles(titles);
      this.props.setReadingLinks(links);
      this.props.setReadingUrls(attachments);
      this.props.setTaskFolder(taskData.folderId);
      // this.props.setTaskChannelUsers(taskData.assignedTo);
      if (
        (taskData.all === false &&
          taskData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        taskData.all === true
      ) {
        this.props.setTaskChannelUsers(taskData.assignedTo);
      } else {
        let assignedTo = taskData.assignedTo.filter((user) =>
          allParticipants.find(
            (User) => User.uid === user || user === currentChannel.createdBy
          )
        );
        console.log(assignedTo);
        this.props.setTaskChannelUsers(assignedTo);
      }
      this.props.setTaskEveryone(taskData.all);
      this.props.setTaskDeadline(new Date(taskData.deadline));
      this.setState({
        editedReading: taskData,
        ReadingSubject: taskData.readingSubject,
        ReadingDescription: taskData.readingDescription,
        view: 1,
      });
      window.scrollTo(0, 0);
    };
    const deleteSavedTask = async (readingId) => {
      let messageData = savedReadings.find(
        (reading) => reading.contentId === readingId
      );
      if (messageData.createdBy !== currentUser.uid) {
        toast.warning("Deletion Failed: Files is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }
      let messageId = messageData.id;
      try {
        let res = await axios
          .post(`${API_BASE_URL}${READING_REMOVE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            saved_reading_id: messageId,
          })
          .then(() => {
            if (this.props.darkTheme) {
              toast.dark("File deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            } else {
              toast("File deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            }
            //this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }
      /*  const dbReadings = firebase.firestore().collection(`readings`);
        const db = firebase
          .firestore()
          .collection(
            `workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/saved`
          );
        db.doc(messageId)
          .delete()
          .then(() => {
            dbReadings.doc(readingId).delete();
            toast("Files deleted", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          })
          .catch((err) => console.log(err));*/
    };
    const closeAppResponses = async (reading) => {
      const { currentWorkspace, currentChannel } = this.props;
      console.log(reading, "iddd");
      var message_id = reading.messageId;
      var user = firebase.auth().currentUser;
      let res = await axios.post(`${API_BASE_URL}${APP_CLOSE}`, {
        workspace_id: currentWorkspace.workspaceId,
        room_id: currentChannel.roomId,
        user_id: user.uid,
        message_id: message_id,
      });
      if (res.status === 200) {
        if (this.props.darkTheme) {
          toast.dark("Closed", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast("Closed", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      } else {
        console.log(res);
      }
    };

    const editPublishedTask = async (message_id) => {
      //console.log(message_id);

      // let messageData = getMessageData(message_id);
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      let messageDataObj = await query
        .doc(message_id)
        .get()
        .then((doc) => {
          console.log(doc.data());
          if (doc.exists) {
            return { ...doc.data(), id: doc.id };
          }
        });
      console.log(messageDataObj);
      let messageData = {};
      if (messageDataObj.type === 2) {
        messageData = await firebase
          .firestore()
          .collection("readings")
          .doc(messageDataObj.contentId)
          .get()
          .then((doc) => {
            return { ...doc.data(), id: doc.id };
          });
      }

      //console.log(messageData, "respox");
      this.props.resetData();
      this.props.setRightPanel(true);
      this.props.setEditingPublished(true);
      setTimeout(
        () => this.props.setContentTypeAndValue("createTask", "Reading"),
        500
      );
      setTimeout(() => this.props.setEditedPublishedTask(messageData), 700);
    };

    const addTask = async (data) => {
      console.log(data);
      const {
        resetData,
        setReadingFiles,
        setReadingLinks,
        setReadingUrls,
        setReadingTitles,
        setTaskChannelUsers,
        setTaskDeadline,
        setTaskEveryone,
      } = this.props;

      console.log(data);
      /* let messageData = {
         timestamp: Date.now(),
         createdAt: firebase.firestore.FieldValue.serverTimestamp(),
         user: {
           id: currentUser.uid,
           name: currentUser.displayName,
           avatar: currentUser.photoURL,
         },
         type: type,
         createdBy: currentUser.uid,
         assignedTo: channelUsers,
         all: everyone,
         folderId: folder ? folder : "",
       };*/

      let readData = [];
      readData[currentUser.uid] = { read: true, readtime: Date.now() };
      let { ReadingDescription, ReadingSubject } = this.state;
      /* let taskData = {
         timestamp: Date.now(),
         createdAt: firebase.firestore.FieldValue.serverTimestamp(),
         createdBy: currentUser.uid,
         folderId: folder ? folder : "",
         readingSubject: ReadingSubject,
         readingDescription: ReadingDescription,
         readings: data,
         deadline: moment(deadline).valueOf(),
         all: everyone,
         read: [],
         assignedTo: channelUsers,
       };*/

      //API_SHARE
      console.log("API SHARE");
      console.log(data);
      if (Object.keys(editedReading).length > 0) {
        let savedDoc = savedReadings.find(
          (savedReading) => savedReading.contentId === editedReading.id
        );
        let savedReadingId = savedDoc.id;
        console.log(data);
        try {
          let res = await axios
            .post(`${API_BASE_URL}${READING_UPDATE}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              saved_reading_id: savedReadingId,
              deadline: moment(deadline).valueOf(),
              all_participants: everyone,
              assigned: channelUsers,
              reading_subject: ReadingSubject,
              reading_description: ReadingDescription,
              readings: data,
              folderId: folder ? folder : "",
            })
            .then(async () => {
              await await axios.post(`${API_BASE_URL}${READING_SHARE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                saved_reading_id: savedReadingId,
              });
            })
            .then(() => {
              if (this.props.darkTheme) {
                toast.dark("File updated and shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              } else {
                toast("File updated and shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              }
              this.props.resetData();
            });
        } catch (error) {
          console.log(error);
        }
      } else if (Object.keys(editedPublishedReading).length > 0) {
        console.log(data);
        try {
          let res = await axios
            .post(`${API_BASE_URL}${READING_EDIT}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              published_reading_id: this.state.publishedReadingId,
              deadline: moment(deadline).valueOf(),
              all_participants: everyone,
              assigned: channelUsers,
              reading_subject: ReadingSubject,
              reading_description: ReadingDescription,
              readings: data,
              folderId: folder ? folder : "",
            })
            .then(() => {
              if (this.props.darkTheme) {
                toast.dark("File edited and shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              } else {
                toast("File edited and shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              }
              this.props.resetData();
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          let res = await axios
            .post(`${API_BASE_URL}${READING_CREATE}`, {
              user_id: currentUser.uid,
              workspace_id: currentWorkspace.workspaceId,
              room_id: currentChannel.roomId,
              //saved_reading_id: savedReadingId,
              deadline: moment(deadline).valueOf(),
              all_participants: everyone,
              assigned: channelUsers,
              reading_subject: ReadingSubject,
              reading_description: ReadingDescription,
              readings: data,
              folderId: folder ? folder : "",
            })
            .then(() => {
              if (this.props.darkTheme) {
                toast.dark("File shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              } else {
                toast("File shared.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              }

              this.props.resetData();
            });
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      }
    };
    let canSeeTaskCurrentUser = publishedReadingsData.filter(
      (reading) =>
        currentChannel?.createdBy === currentUser.uid ||
        reading.all === true ||
        coAdmins.find((usr) => usr.uid === currentUser.uid) ||
        (reading.assignedTo && reading.assignedTo.includes(currentUser.uid))
    );
    return view === 1 ? (
      <>
        <div
          onClick={() => {
            setReadingTitles([]);
            setReadingLinks([]);
            setReadingUrls([]);
            setReadingFiles([]);
            setTaskDeadline(new Date(Date.now() + 10 * 600000));
            setTaskChannelUsers([]);
            setTaskEveryone(false);
            this.props.setEditingPublished(false);
            setTaskFolder("");
            this.setState({
              view: 0,
              editedReading: {},
              ReadingSubject: "",
              ReadingDescription: "",
            });
          }}
          className="mb-1 p-2 bg-card-theme text-theme"
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
          <span>
            {Object.keys(editedReading).length > 0
              ? "Update File"
              : "Create File"}
          </span>
        </div>
        <CreateReading
          allParticipants={this.props.allParticipants}
          saveTaskForLater={saveTaskForLater}
          shareTask={shareTask}
          editedReading={
            Object.keys(editedReading).length > 0
              ? editedReading
              : sharedAgainReading
          }
          ReadingSubject={ReadingSubject}
          ReadingDescription={ReadingDescription}
          handleSubAndDesc={this.handleSubAndDesc}
          validateData={validateData}
          editingPublished={this.props.editingPublished}
          publishedReadingId={this.state.publishedReadingId}
          error={error}
          readingMessageData={this.state.readingMessageData}
        />
      </>
    ) : view === 0 ? (
      <>
        <div
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
          className="m-2 rounded p-2 bg-card-theme text-theme"
        >
          <div className="d-flex">
            <div
              onClick={() => {
                setContentType(null);
                setTaskType(null);
                resetAllTasks();
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
              <span>
                {contentType === "createTask"
                  ? taskType
                    ? taskType + "s"
                    : "Create Task"
                  : ""}
              </span>
            </div>
          </div>
          <IsAdmin>
            <div className="mt-2">
              {/*<p className="mb-2 small text-theme-lighter">
                Provide files to participants in the room.Users can find links
                and attachments easily from their message timeline.
              </p>*/}
              <div className="d-flex">
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => this.addReadingView(1)}
                  className="pt-0 w-50"
                >
                  <small>+ CREATE NEW FILE</small>
                </Button>

                {/* <span className="mt-2 ml-3 text-secondary pointer smaller">
                  <FontAwesomeIcon icon={faQuestionCircle} /> WATCH TUTORIAL
                </span> */}
              </div>
            </div>
          </IsAdmin>
        </div>

        <IsAdmin>
          <div className="row m-2 border-1 rounded">
            <div
              className={
                selected === "Published"
                  ? "col-6 text-theme bg-card-theme rounded py-1 d-flex justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Published")}
              style={{ cursor: "pointer" }}
            >
              Published
            </div>

            <div
              className={
                selected === "Saved"
                  ? "col-6 text-theme bg-card-theme rounded d-flex py-1 justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Saved")}
              style={{ cursor: "pointer" }}
            >
              Saved
            </div>
          </div>
        </IsAdmin>

        {selected === "Published" ? (
          publishedReadingsData.length > 0 &&
          canSeeTaskCurrentUser.length > 0 ? (
            <div>
              <div className="d-flex justify-content-between">
                <InputGroup className="my-1 outerPill">
                  <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                    <div className="py-2 pr-2 pl-3">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="sm"
                        color="#aeaeae"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <Form.Control
                        type="text"
                        id="participantsSearch"
                        className="border-0 px-0 bg-theme text-theme rounded"
                        onChange={(e) => {
                          this.setState({ searchTerm: e.target.value }, () => {
                            const reg = _.escapeRegExp(this.state.searchTerm);
                            // console.log(reg,"Reg")
                            const regex = new RegExp(reg, "gi");
                            this.setState({ regex: regex });
                          });
                        }}
                        placeholder={"Search"}
                        value={this.state.searchTerm}
                        style={{ margin: 0 }}
                      />
                    </div>
                    {this.state.searchTerm.length > 0 && (
                      <div
                        className="py-2 pl-2 pr-3"
                        onClick={() => this.setState({ searchTerm: "" })}
                      >
                        <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                      </div>
                    )}
                  </div>
                </InputGroup>
                {this.state.sortType === "unreverse" ? (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "reverse" }, () => {
                        this.sortReadingData();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAmountUpAlt} color="#aeaeae" />
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "unreverse" }, () => {
                        this.sortReadingData();
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSortAmountDownAlt}
                      color="#aeaeae"
                    />
                  </Button>
                )}

                {this.state.sortType === "atoz" ? (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "ztoa" }, () => {
                        this.sortReadingData();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUp} color="#aeaeae" />
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "atoz" }, () => {
                        this.sortReadingData();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUpAlt} color="#aeaeae" />
                  </Button>
                )}
              </div>
              {this.state.searchTerm && this.state.searchTerm.length > 0 ? (
                <>
                  {publishedReadingsData
                    .filter((item) => {
                      return item.readingSubject?.match(this.state.regex);
                    })
                    .map((reading, index) =>
                      currentChannel?.createdBy === currentUser.uid ||
                      reading.all === true ||
                      coAdmins.find((usr) => usr.uid === currentUser.uid) ||
                      (reading.assignedTo &&
                        reading.assignedTo.includes(currentUser.uid)) ? (
                        <div
                          key={index + reading.id}
                          className={
                            reading.deadline > Date.now()
                              ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
                              : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
                          }
                        >
                          <div
                            className="d-flex justify-content-between mb-1 pointer"
                            onClick={() => this.showSelectedReading(reading.id)}
                          >
                            <span className="text-theme-lighter smaller">
                              {moment(reading.timestamp).format("LLL")}
                            </span>
                            <span
                              className={
                                reading.deadline > Date.now()
                                  ? "text-success smaller"
                                  : "text-secondary smaller"
                              }
                            >
                              {reading.deadline > Date.now()
                                ? "Active"
                                : "Closed"}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p
                              className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
                              onClick={() =>
                                this.showSelectedReading(reading.id)
                              }
                            >
                              {reading.readingSubject}
                            </p>
                            <IsAdmin>
                              <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                                <Dropdown.Toggle
                                  size="sm"
                                  as={this.CustomToggle}
                                />
                                <Dropdown.Menu className="bg-card-theme text-theme">
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() => shareTaskAgain(reading)}
                                  >
                                    Duplicate{" "}
                                    {/*<small className="text-warning">Coming Soon</small>*/}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() =>
                                      editPublishedTask(reading.messageId)
                                    }
                                  >
                                    Edit{" "}
                                    {/*<small className="text-warning">Coming Soon</small>*/}
                                  </Dropdown.Item>
                                  {reading.deadline > Date.now() ? (
                                    <Dropdown.Item
                                      className="small bg-card-theme text-theme"
                                      onClick={() => closeAppResponses(reading)}
                                    >
                                      Close{" "}
                                      {/*<small className="text-warning">
                                          Coming Soon
                                    </small>*/}
                                    </Dropdown.Item>
                                  ) : null}
                                  {/* <Dropdown.Item
                          className="small"
                          onClick={() => deleteSavedTask(poll.id)}
                        >
                          Delete
                      </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </IsAdmin>
                          </div>
                        </div>
                      ) : null
                    )}
                </>
              ) : (
                <>
                  {publishedReadingsData.map((reading, index) =>
                    currentChannel?.createdBy === currentUser.uid ||
                    reading.all === true ||
                    coAdmins.find((usr) => usr.uid === currentUser.uid) ||
                    (reading.assignedTo &&
                      reading.assignedTo.includes(currentUser.uid)) ? (
                      <div
                        key={index + reading.id}
                        className={
                          reading.deadline > Date.now()
                            ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
                            : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
                        }
                      >
                        <div
                          className="d-flex justify-content-between mb-1 pointer"
                          onClick={() => this.showSelectedReading(reading.id)}
                        >
                          <span className="text-theme-lighter smaller">
                            {moment(reading.timestamp).format("LLL")}
                          </span>
                          <span
                            className={
                              reading.deadline > Date.now()
                                ? "text-success smaller"
                                : "text-secondary smaller"
                            }
                          >
                            {reading.deadline > Date.now()
                              ? "Active"
                              : "Closed"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p
                            className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
                            onClick={() => this.showSelectedReading(reading.id)}
                          >
                            {reading.readingSubject}
                          </p>
                          <IsAdmin>
                            <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                              <Dropdown.Toggle
                                size="sm"
                                as={this.CustomToggle}
                              />
                              <Dropdown.Menu className="bg-card-theme text-theme">
                                <Dropdown.Item
                                  className="small bg-card-theme text-theme"
                                  onClick={() => shareTaskAgain(reading)}
                                >
                                  Duplicate{" "}
                                  {/*<small className="text-warning">Coming Soon</small>*/}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="small bg-card-theme text-theme"
                                  onClick={() =>
                                    editPublishedTask(reading.messageId)
                                  }
                                >
                                  Edit{" "}
                                  {/*<small className="text-warning">Coming Soon</small>*/}
                                </Dropdown.Item>
                                {reading.deadline > Date.now() ? (
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() => closeAppResponses(reading)}
                                  >
                                    Close{" "}
                                    {/*<small className="text-warning">
                                          Coming Soon
                                    </small>*/}
                                  </Dropdown.Item>
                                ) : null}
                                {/* <Dropdown.Item
                          className="small"
                          onClick={() => deleteSavedTask(poll.id)}
                        >
                          Delete
                      </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </IsAdmin>
                        </div>
                      </div>
                    ) : null
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="col">
              <p className="text-theme text-center small">
                No files published yet
              </p>
            </div>
          )
        ) : savedReadings.length > 0 ? (
          <div>
            <div className="d-flex justify-content-between">
              <InputGroup className="my-1 outerPill">
                <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                  <div className="py-2 pr-2 pl-3">
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="sm"
                      color="#aeaeae"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <Form.Control
                      type="text"
                      id="participantsSearch"
                      className="border-0 px-0 bg-theme text-theme rounded"
                      onChange={(e) => {
                        this.setState({ searchTerm: e.target.value }, () => {
                          const reg = _.escapeRegExp(this.state.searchTerm);
                          // console.log(reg,"Reg")
                          const regex = new RegExp(reg, "gi");
                          this.setState({ regex: regex });
                        });
                      }}
                      placeholder={"Search"}
                      value={this.state.searchTerm}
                      style={{ margin: 0 }}
                    />
                  </div>
                  {this.state.searchTerm.length > 0 && (
                    <div
                      className="py-2 pl-2 pr-3"
                      onClick={() => this.setState({ searchTerm: "" })}
                    >
                      <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                    </div>
                  )}
                </div>
              </InputGroup>
              {this.state.sortType === "unreverse" ? (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "reverse" }, () => {
                      this.sortReadingData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAmountUpAlt} color="#aeaeae" />
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "unreverse" }, () => {
                      this.sortReadingData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAmountDownAlt} color="#aeaeae" />
                </Button>
              )}

              {this.state.sortType === "atoz" ? (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "ztoa" }, () => {
                      this.sortReadingData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAlphaUp} color="#aeaeae" />
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "atoz" }, () => {
                      this.sortReadingData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAlphaUpAlt} color="#aeaeae" />
                </Button>
              )}
            </div>
            {this.state.searchTerm && this.state.searchTerm.length > 0 ? (
              <>
                {savedReadingsData
                  .filter((data) => {
                    return data.readingSubject?.match(this.state.regex);
                  })
                  .map((Reading, index) => (
                    <div
                      className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                      key={index}
                    >
                      <div className="d-flex justify-content-center">
                        <div
                          onClick={() => editSavedTask(Reading.id)}
                          className="pointer"
                        >
                          <small className="text-theme-lighter smaller">
                            {moment(Reading.timestamp).format("LLL")}
                          </small>
                          <p className="m-0 font-weight-bold text-theme small">
                            {Reading.readingSubject}
                          </p>
                        </div>
                        <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                          <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                          <Dropdown.Menu className="bg-card-theme text-theme">
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => shareSavedTask(Reading.id)}
                            >
                              Share now
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => editSavedTask(Reading.id)}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => deleteSavedTask(Reading.id)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <>
                {savedReadingsData.map((Reading, index) => (
                  <div
                    className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1"
                    key={index}
                  >
                    <div className="d-flex justify-content-center">
                      <div
                        onClick={() => editSavedTask(Reading.id)}
                        className="pointer"
                      >
                        <small className="text-theme-lighter smaller">
                          {moment(Reading.timestamp).format("LLL")}
                        </small>
                        <p className="m-0 font-weight-bold text-theme small">
                          {Reading.readingSubject}
                        </p>
                      </div>
                      <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                        <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                        <Dropdown.Menu className="bg-card-theme text-theme">
                          <Dropdown.Item
                            className="small bg-card-theme text-theme"
                            onClick={() => shareSavedTask(Reading.id)}
                          >
                            Share now
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="small bg-card-theme text-theme"
                            onClick={() => editSavedTask(Reading.id)}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="small bg-card-theme text-theme"
                            onClick={() => deleteSavedTask(Reading.id)}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        ) : (
          <div className="col">
            <p className="text-theme text-center small">No files saved yet</p>
          </div>
        )}
      </>
    ) : (
      <>
        <div
          onClick={() => {
            this.setState({ view: 0, selectedReading: {} });
          }}
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
          className="mb-1 p-2 bg-card-theme text-theme"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
          <span>Back</span>
        </div>
        <div className="shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme">
          <Reading
            view={view}
            user={currentUser.uid}
            item={_.find(
              publishedReadings,
              (reading) => reading.contentId === selectedReading.id
            )}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,
  currentWorkspace: state.workspace.currentWorkspace,
  type: state.reading.type,
  titles: state.reading.titles,
  links: state.reading.links,
  files: state.reading.files,
  urls: state.reading.urls,
  darkTheme: state.platform.darkTheme,

  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  everyone: state.task.everyone,
  sharing: state.platform.sharing,
  sharedTask: state.platform.sharedTask,
  editing: state.platform.editing,
  editedTask: state.platform.editedTask,
  editingPublished: state.platform.editingPublished,
  editedPublishedTask: state.platform.editedPublishedTask,
});

export default connect(mapStateToProps, {
  setReadingTitles,
  setReadingLinks,
  setReadingFiles,
  setReadingUrls,
  setRightPanel,
  setContentTypeAndValue,

  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskEveryone,
  resetData,
  setSharedTask,
  setTaskSharing,
  setTaskEditing,
  setEditedTask,
  setEditedPublishedTask,
  setEditingPublished,
})(ReadingHomepage);
