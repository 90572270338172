import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowLeft,
  faQuestionCircle,
  faSearch,
  faTimes,
  faSortAmountUpAlt,
  faSortAmountDownAlt,
  faSortAlphaUp,
  faSortAlphaUpAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  Dropdown,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Form,
} from "react-bootstrap";
import moment from "moment";
import {
  setQuizTitle,
  setQuizQuestions,
  setQuizOptions,
  setQuizTypes,
  setQuizCorrectAnswers,
  setQuizWeights,
  setQuizTotalWeight,
  setQuizGraded,
  setQuizPreviewImages,
  setQuizOptionsImages,
  setQuizQuestionImages,
  setQuizQuestionPreviewImages,
  setQuizRandomize,
  setQuizRandomizeQuestion,
  // setQuizSharing,
} from "./actions";
import {
  resetData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
  setTaskSharing,
  setSharedTask,
  setEditedTask,
  setTaskEditing,
  setEditedPublishedTask,
  setEditingPublished,
  setContentTypeAndValue,
  setRightPanel,
} from "../../../actions/index";
import CreateQuiz from "./CreateQuiz";
import Quiz from "./Quiz";
import axios from "axios";
import IsAdmin from "../../IsAdmin";

import { toast } from "react-toastify";
import {
  API_BASE_URL,
  QUIZ_CREATE,
  QUIZ_UPDATE,
  QUIZ_REMOVE,
  QUIZ_SHARE,
  QUIZ_SUBMIT,
  DRIVE_UPLOAD,
  QUIZ_EDIT,
  APP_CLOSE,
  ROOM_APP_LIST,
} from "../../../config";
toast.configure();

class QuizHomepage extends Component {
  state = {
    selected: "Published",
    publishedQuizes: [],
    publishedQuizesData: [],
    savedQuizes: [],
    savedQuizesData: [],
    editedQuiz: {},
    selectedQuiz: {},
    view: 0,
    error: "",
    sharedAgainQuiz: {},
    editedPublishedQuiz: {},
    editedQuizHasResponses: false,
    publishedQuizId: null,
    quizMessageData: null,
    searchTerm: "",
    regex: "",
    sortType: "unreverse",
    reversed: false,
  };

  async componentDidMount() {
    const {
      currentChannel,
      currentWorkspace,
      sharing,
      sharedTask,
      currentUser,
    } = this.props;
    let savesQuizes = [];
    let publishedQuizes = [];

    /*if (sharing) {
      this.setState({ view: 1 });
      return;
    }*/

    const query = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/saved`
      )
      .orderBy("timestamp", "desc");

    const queryMsg = firebase
      .firestore()
      .collection(
        `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/messages`
      )
      .orderBy("timestamp", "desc");

    query.where("type", "==", 4).onSnapshot((snapshot) => {
      axios
        .post(`${API_BASE_URL}${ROOM_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          user_id: currentUser.uid,
          message_type: 4,
          app_state: "saved",
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ savedQuizes: res.data.data });
            this.handleQuizesData(res.data.data);
            this.setState({ sortType: "unreverse" });
          }
        });
    });

    queryMsg.where("type", "==", 4).onSnapshot((snapshot) => {
      axios
        .post(`${API_BASE_URL}${ROOM_APP_LIST}`, {
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          user_id: currentUser.uid,
          message_type: 4,
          app_state: "published",
        })
        .then((res) => {
          if (res.status === 200) {
            this.setState({ publishedQuizes: res.data.data });
            this.handlePublishQuizesData(res.data.data);
            this.setState({ sortType: "unreverse" });
          }
        });
    });
  }
  handleQuizesData = async (savedQuizes) => {
    let savedQuizesData = [];
    savedQuizesData = await Promise.all(
      savedQuizes.map(async (savedQuiz) => {
        const quizData = await firebase
          .firestore()
          .collection(`quiz`)
          .doc(savedQuiz.contentId)
          .get();
        return {
          ...quizData.data(),
          id: quizData.id,
        };
      })
    );
    if (this.state.sortType === "atoz") {
      const sortedQuizData = _.sortBy(savedQuizesData, (quiz) =>
        quiz.quizName.toLowerCase()
      );
      savedQuizesData = sortedQuizData;
    }
    if (this.state.sortType === "ztoa") {
      const sortedQuizData = _.sortBy(savedQuizesData, (quiz) =>
        quiz.quizName.toLowerCase()
      );
      savedQuizesData = sortedQuizData.reverse();
    }
    this.setState({ savedQuizes, savedQuizesData });

    if (savedQuizes.length === 0) {
      this.setState({ savedQuizes });
    }
  };

  handlePublishQuizesData = async (publishedQuizes) => {
    let publishedQuizesData = [];
    publishedQuizesData = await Promise.all(
      publishedQuizes.map(async (publishedQuiz) => {
        const quizData = await firebase
          .firestore()
          .collection(`quiz`)
          .doc(publishedQuiz.contentId)
          .get();
        return {
          ...quizData.data(),
          id: quizData.id,
          messageId: publishedQuiz.id,
        };
      })
    );
    const sortedQuizData = _.sortBy(publishedQuizesData, (quiz) =>
      quiz.quizName.toLowerCase()
    );

    if (this.state.sortType === "atoz") {
      publishedQuizesData = sortedQuizData;
    }
    if (this.state.sortType === "ztoa") {
      publishedQuizesData = sortedQuizData.reverse();
    }
    this.setState({ publishedQuizes, publishedQuizesData });
    if (publishedQuizes.length === 0) {
      this.setState({ publishedQuizes });
    }
  };
  addQuizView = (view) => {
    this.setState({ view });
    //console.log("respoxx3")
  };
  handleSelected = (selected) => {
    this.setState({ selected });
  };
  CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="text-muted"
    >
      <FontAwesomeIcon icon={faAngleDown} />
    </a>
  ));
  showSelectedQuiz = (id) => {
    let selectedQuiz = { id: id };
    this.setState({ selectedQuiz, view: 2 });
    //console.log("respoxxx view")
  };
  uploadImage = async (file, key) => {
    //console.log("called",file, key)
    const { currentUser } = this.props;
    //console.log(this.props);
    let requestData = {};

    if (file) {
      const fileData = new FormData();
      fileData.append("file", file, `${file.lastModified}-${file.name}`);
      fileData.append("user_id", currentUser.uid);

      /*if (folder_id) {
        fileData.append("folder_id", foder_idl);
      }*/
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
        /*onUploadProgress: (ProgressEvent) => {
          this.setState({percentUploaded: (ProgressEvent.loaded * 100) / ProgressEvent.total,);},*/
      };
      try {
        let res = await axios.post(
          `${API_BASE_URL}${DRIVE_UPLOAD}`,
          fileData,
          config
        );
        //console.log(res);
        return res.data.data["file_url"];
      } catch (error) {
        //console.log(error);
      }
    } else return "";
  };

  sortQuizData = async () => {
    const { sortType } = this.state;

    // Sorting: Timestamp ascending
    if (sortType === "reverse") {
      const { savedQuizesData, publishedQuizesData } = this.state;

      this.setState(
        {
          savedQuizesData: savedQuizesData.reverse(),
          publishedQuizesData: publishedQuizesData.reverse(),
        },
        () => {
          this.setState({ reversed: true });
        }
      );
    }

    // Sorting: Timestamp descending
    else if (sortType === "unreverse") {
      if (this.state.reversed) {
        const { savedQuizesData, publishedQuizesData } = this.state;

        this.setState({
          savedQuizesData: savedQuizesData.reverse(),
          publishedQuizesData: publishedQuizesData.reverse(),
        });
      } else {
        const { savedQuizesData, publishedQuizesData } = this.state;

        this.setState({
          savedQuizesData,
          publishedQuizesData,
        });
      }
      this.setState({ reversed: false });
    }

    // Sorting: A to Z
    else if (sortType === "atoz") {
      const { savedQuizesData, publishedQuizesData } = this.state;

      this.setState(
        {
          savedQuizesData: _.sortBy(savedQuizesData, (quiz) =>
            quiz.quizName.toLowerCase()
          ),
          publishedQuizesData: _.sortBy(publishedQuizesData, (quiz) =>
            quiz.quizName.toLowerCase()
          ),
        },
        () => {
          this.setState({ reversed: false });
        }
      );
    }

    // Sorting: Z to A
    else if (sortType === "ztoa") {
      const { savedQuizesData, publishedQuizesData } = this.state;

      this.setState(
        {
          savedQuizesData: _.sortBy(savedQuizesData, (quiz) =>
            quiz.quizName.toLowerCase()
          ).reverse(),
          publishedQuizesData: _.sortBy(publishedQuizesData, (quiz) =>
            quiz.quizName.toLowerCase()
          ).reverse(),
        },
        () => {
          this.setState({ reversed: false });
        }
      );
    }
  };

  render() {
    const {
      type,
      currentWorkspace,
      currentChannel,
      currentUser,
      quizTitle,
      questions,
      options,
      types,
      correctAnswers,
      weights,
      totalWeight,
      graded,
      deadline,
      channelUsers,
      folder,
      imageOptions,
      everyone,
      allParticipants,
      taskType,
      contentType,
      resetAllTasks,
      setTaskType,
      setContentType,
      resetData,
      setQuizTitle,
      setQuizQuestions,
      setQuizOptions,
      setQuizTypes,

      setQuizCorrectAnswers,
      setTaskChannelUsers,
      setTaskEveryone,
      setTaskDeadline,
      sharing,
      sharedTask,
      setSharedTask,
      setTaskSharing,
      randomize,
      randomizeQuestion,
      setTaskFolder,
      editedTask,
      editing,
      setEditedTask,
      setQuizOptionsImages,
      setQuizPreviewImages,
      setTaskEditing,
      editingPublished,
      editedPublishedTask,
      setQuizQuestionImages,
      setQuizQuestionPreviewImages,
      setQuizRandomize,
      setQuizRandomizeQuestion,
    } = this.props;
    // console.log(sharing);

    let {
      savedQuizes,
      savedQuizesData,
      editedQuiz,
      publishedQuizes,
      publishedQuizesData,
      selected,
      view,
      error,
      selectedQuiz,
      sharedAgainQuiz,
      editedPublishedQuiz,
    } = this.state;
    let coAdmins = allParticipants.filter((participant) => participant.isAdmin);
    //console.log(coAdmins);

    const closeAppResponses = async (quiz) => {
      const { currentWorkspace, currentChannel } = this.props;
      console.log(quiz, "iddd");
      var message_id = quiz.messageId;
      var user = firebase.auth().currentUser;
      let res = await axios.post(`${API_BASE_URL}${APP_CLOSE}`, {
        workspace_id: currentWorkspace.workspaceId,
        room_id: currentChannel.roomId,
        user_id: user.uid,
        message_id: message_id,
      });
      if (res.status === 200) {
        if (this.props.darkTheme) {
          toast.dark("Closed", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast("Closed", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      } else {
        console.log(res);
      }
    };

    const shareTaskAgain = async (messageData) => {
      console.log("quiz Data", messageData);
      let questions = [];
      let questionImages = [];
      let imgOption = [];
      let options = [];
      let types = [];
      let correctAnswers = [];
      // let correct_answers = [];

      const responses = await firebase
        .firestore()
        .collection("quiz")
        .doc(messageData.id)
        .collection("responses")
        .get();
      console.log(responses.size, "size");
      if (responses.size > 0 && editingPublished) {
        this.setState({ editedQuizHasResponses: true });
      }

      if (editingPublished) {
        //console.log(messageData,"editted quiz")
        this.setState({ quizMessageData: messageData });
        this.setState({ publishedQuizId: messageData.id });
      }

      
      let sorted = _.map(messageData.quizQuestions, function (value, key) {
        return _.defaults({ order: value.sequenceNo }, value);
      });

      sorted= _.sortBy(sorted,'order');

      //console.log(sorted)
      //Object.keys(messageData.quizQuestions)
      sorted.map((q, key) => {
        let multipleAnswers = [];
       // let questionSequence = null;
        let queImgOptions = [];
        let queOptions = [];
        let singleAnswer = "";
        //console.log(q)
        questions.push(q.question);
        questionImages.push(q.questionImage);
        if(q.sequenceNo === null){
          setQuizRandomizeQuestion(true)
        }
        //questionSequence.push()
        if (q.type === 1) {
          types.push("multiple");

          let sortedOptions = _.map(q.options, function (value, key) {
            return _.defaults({ order: value.sequenceNo }, value);
          });
  
          sortedOptions = _.sortBy(sortedOptions, 'order');

          sortedOptions.map((o, k) => {
            //console.log(messageData.quizQuestions[q].options[o].optionValue);
            //console.log(messageData.quizQuestions[q].options[o].optionImage);
            queOptions.push(
              o.optionValue
            );
            queImgOptions.push(
              o.optionImage
                ? o.optionImage
                : null
            );
            if(o.sequenceNo === null){
              setQuizRandomize(true)
            }
            if (o.correctAnswer) {
              multipleAnswers.push(
                o.optionValue
              );
            }
          });
        } else {
          types.push("single");
          let sortedOptions = _.map(q.options, function (value, key) {
            return _.defaults({ order: value.sequenceNo }, value);
          });
  
          sortedOptions = _.sortBy(sortedOptions, 'order');
          
          sortedOptions.map((o, k) => {
            //console.log(messageData.quizQuestions[q].options[o].optionValue);
            //console.log(messageData.quizQuestions[q].options[o].optionImage);
            queOptions.push(
              o.optionValue
            );
            queImgOptions.push(
              o.optionImage
                ? o.optionImage
                : null
            );
            if(o.sequenceNo === null){
              setQuizRandomize(true)
            }
            if (o.correctAnswer) {
              singleAnswer =
                o.optionValue;
            }
          });
        }

        if (multipleAnswers.length > 0) {
          correctAnswers.push(multipleAnswers);
        }
        if (singleAnswer) {
          correctAnswers.push(singleAnswer);
        }

        options.push(queOptions);
        imgOption.push(queImgOptions);
      });
      setQuizTitle(messageData.quizName);
      setQuizTypes(types);
      setQuizQuestions(questions);
      setQuizOptions(options);
      setQuizOptionsImages(imgOption);
      setQuizPreviewImages(imgOption);
      setQuizCorrectAnswers(correctAnswers);
      setTaskDeadline(new Date(messageData.deadline));
      setTaskEveryone(messageData.all);
      setTaskFolder(messageData.folderId);
      setQuizQuestionImages(questionImages);
      setQuizQuestionPreviewImages(questionImages);

      if (
        (messageData.all === false &&
          messageData.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        messageData.all === true
      ) {
        setTaskChannelUsers(messageData.assignedTo);
      } else {
        let assignedTo = messageData.assignedTo.filter((user) =>
          allParticipants.find(
            (User) => User.uid === user || user === currentChannel.createdBy
          )
        );
        //console.log(assignedTo);
        setTaskChannelUsers(assignedTo);
      }
      //console.log("respoxxxxxxxxxxxx")
      //setSharedTask({});
      //setTaskSharing(false);
      //setEditedTask({});
      //setTaskEditing(false);
      this.props.setEditedPublishedTask({});
      if (editing) {
        this.setState({ editedQuiz: messageData, view: 1 });
        // console.log("respoxx5")
      } else if (editingPublished) {
        this.setState({ editedPublishedQuiz: messageData, view: 1 });
        //console.log("respoxx4")
      } else {
        this.setState({ sharedAgainQuiz: messageData, view: 1 });
        //console.log(messageData,"respoxx6")
      }
    };

    if (
      sharing &&
      Object.keys(sharedTask).length > 0 &&
      Object.keys(sharedAgainQuiz).length === 0
    ) {
      shareTaskAgain(sharedTask);
    }
    if (
      editing &&
      Object.keys(editedTask).length > 0 &&
      Object.keys(editedQuiz).length === 0
    ) {
      shareTaskAgain(editedTask);
    }

    if (
      editingPublished &&
      Object.keys(editedPublishedTask).length > 0 &&
      Object.keys(editedPublishedQuiz).length === 0
    ) {
      shareTaskAgain(editedPublishedTask);
    }

    const validateData = () => {
      if (quizTitle.length == 0) {
        this.setState({ error: "Quiz title cannot be empty!\n" });
        return null;
      }

      let filtered_questions = questions.filter(
        (question) => question.length > 0
      );
      if (questions.length != filtered_questions.length) {
        this.setState({ error: "Please add some value to question(s).\n" });
        return null;
      }

      let existFlag = false;
      options.forEach((item, key) => {
        let imageOptionsForCurrentQue = imageOptions[key];
        let filteredImages = imageOptionsForCurrentQue.filter(
          (img) => img !== "" && img !== null
        );
       // console.log(imageOptionsForCurrentQue, "img option")
       // console.log("item length", item.length);
        //console.log("filtered images", filteredImages.length, filteredImages);
        if (
          filteredImages.length > 0 &&
          filteredImages.length !== item.length
        ) {
          existFlag = true;
        }
      });
      if (existFlag) {
        this.setState({ error: "Please add images to all option(s).\n" });
        return null;
      }

      if (questions.length == 0) {
        this.setState({
          error: "Quiz must have at-least one or more question(s).\n",
        });
        return null;
      }

      if (graded && parseInt(totalWeight) === 0) {
        this.setState({ error: "Total weight should be greater than '0'.\n" });
        return null;
      }

      let filtered_weights = weights.filter((weight) => parseInt(weight) > 0);
      if (graded && weights.length !== filtered_weights.length) {
        this.setState({ error: "Please add some value to weight(s).\n" });
        return null;
      }

      let filtered_options = options.filter((item) => item.length < 2);
      if (filtered_options.length > 0) {
        this.setState({
          error: "Question must have at-least two or more option(s).\n",
        });
        return null;
      }

      let empty_options = options.flat();
      let filtered_empty_options = empty_options.filter(
        (item) => item.length > 0
      );
      if (filtered_empty_options.length != empty_options.length) {
        this.setState({ error: "Please add some value to option(s).\n" });
        return null;
      }

      if (
        correctAnswers.length !== questions.length ||
        correctAnswers.some((val) => {
          if (typeof val === "number" && val === 0) {
            return true;
          } else if (Array.isArray(val) && val.length === 0) {
            return true;
          } else {
            return false;
          }
        })
      ) {
        this.setState({
          error: "Please select a correct option for all question(s).\n",
        });
        return null;
      }

      /* if (moment(deadline).diff(moment(Date.now()), "minutes") < 9) {
          this.setState({
            error: "Deadline of task should be atleast 10 minutes.\n",
          });
          return null;
        }*/

      if (!everyone && channelUsers.length < 1) {
        this.setState({ error: "Please assign task to atleast one user.\n" });
        return null;
      }

      this.setState({ error: "" });
      return true;
    };

    const getQuiz = (questionImage, images) => {
      //console.log("my file", images);
      let quiz_name = quizTitle;
      let quiz_total_questions = questions.length;
      let correct_answers = [];
      correctAnswers.map((item, key) => {
        if (Array.isArray(item)) correct_answers[key] = item.map((a) => a);
        else correct_answers[key] = item;
      });

      //let keys = [];
      let newOptions = [];

      if (randomize) {
        newOptions = options.map((option, key) =>
          option.map((opt, k) => {
            if (
              correct_answers[key] === opt ||
              correct_answers[key].includes(opt)
            ) {
              return {
                option_value: opt,
                correct_answer: true,
                option_image: images[key][k] ? images[key][k] : null,
                //sequence_no: k + 1,
              };
            } else {
              return {
                option_value: opt,
                correct_answer: false,
                option_image: images[key][k] ? images[key][k] : null,
                //sequence_no: k + 1,
              };
            }
          })
        );
      } else {
        newOptions = options.map((option, key) =>
          option.map((opt, k) => {
            if (
              correct_answers[key] === opt ||
              correct_answers[key].includes(opt)
            ) {
              return {
                option_value: opt,
                correct_answer: true,
                option_image: images[key][k] ? images[key][k] : null,
                sequence_no: k + 1,
              };
            } else {
              return {
                option_value: opt,
                correct_answer: false,
                option_image: images[key][k] ? images[key][k] : null,
                sequence_no: k + 1,
              };
            }
          })
        );
      }
      if(randomizeQuestion){

        let quiz_questions = [];
        questions.map((question, key) => 
          quiz_questions.push({
          //sequence_no: key + 1,
            question,
            questionImage: questionImage[key],
            type: types[key] === "multiple" ? 1 : 0,
            options: newOptions[key],
            //correctAnswer: correct_answers[key],
            weight: weights[key],
          })
        );


        return {
          type,
          quiz_name,
          quiz_total_questions,
          quiz_questions,
          totalWeight,
          deadline,
          channelUsers,
          correct_answers,
          folder,
          everyone,
        };
      }else{
      let quiz_questions = [];
      questions.map((question, key) => 

        quiz_questions.push({
          sequence_no: key + 1,
          question,
          questionImage: questionImage[key],
          type: types[key] === "multiple" ? 1 : 0,
          options: newOptions[key],
          //correctAnswer: correct_answers[key],
          weight: weights[key],
        })
      );
      //console.log(newOptions);
      //console.log(quiz_questions);

      return {
        type,
        quiz_name,
        quiz_total_questions,
        quiz_questions,
        totalWeight,
        deadline,
        channelUsers,
        correct_answers,
        folder,
        everyone,
      };
      }
    };

    const shareTask = () => {
      if (validateData()) {
        //console.log("imageOptions", imageOptions, this.props.quizQuestionImages);
        let allImagesUploadedPromises = imageOptions.map((subArr, key) => {
          //console.log("Sub Array",subArr)
          return subArr.map((image, key) => {
            if (typeof image === "object") {
              return this.uploadImage(image, key);
            } else return image;
          });
        });
        // console.log(allImagesUploadedPromises, "promisses");

        let allPromises = [];
        for (let i = 0; i < allImagesUploadedPromises.length; i++) {
          let arrayOfPromisesForQue = allImagesUploadedPromises[i];
          for (let j = 0; j < arrayOfPromisesForQue.length; j++) {
            allPromises.push(arrayOfPromisesForQue[j]);
          }
        }
        Promise.all(allPromises).then(async (images) => {
          //console.log(images, "allImages");
          let optionImages = [];
          for (let i = 0; i < allImagesUploadedPromises.length; i++) {
            let arrayOfPromisesForQue = allImagesUploadedPromises[i];
            let newImages = images.splice(0, arrayOfPromisesForQue.length);
            optionImages.push(newImages);
          }
          let allQuestionImagesPromises = this.props.quizQuestionImages.map((img, index) => {
            if (typeof img === "object") {
              return this.uploadImage(img, index);
            } else return img;
          });
          Promise.all(allQuestionImagesPromises).then(
            async (allQuestionImages) => {
              console.log(
                allQuestionImages,
                "resolved question image promises"
              );
              addTask(getQuiz(allQuestionImages, optionImages));
            }
          );

          //console.log(optionImages, "resolved promises");
          //addTask(getQuiz(optionImages));
        });
      }
    };

    const saveForLaterTask = () => {
      if (validateData()) {
        //console.log("imageOptions", imageOptions);
        let allImagesUploadedPromises = imageOptions.map((subArr, key) => {
          //console.log("Sub Array",subArr)
          return subArr.map((image, key) => {
            if (typeof image === "object") {
              return this.uploadImage(image, key);
            } else return image;
          });
        });
        // console.log(allImagesUploadedPromises, "promisses");

        let allPromises = [];
        for (let i = 0; i < allImagesUploadedPromises.length; i++) {
          let arrayOfPromisesForQue = allImagesUploadedPromises[i];
          for (let j = 0; j < arrayOfPromisesForQue.length; j++) {
            allPromises.push(arrayOfPromisesForQue[j]);
          }
        }
        Promise.all(allPromises).then(async (images) => {
          //console.log(images, "allImages");
          let optionImages = [];
          for (let i = 0; i < allImagesUploadedPromises.length; i++) {
            let arrayOfPromisesForQue = allImagesUploadedPromises[i];
            let newImages = images.splice(0, arrayOfPromisesForQue.length);
            optionImages.push(newImages);
          }
          let allQuestionImagesPromises = this.props.quizQuestionImages.map((img, index) => {
            if (typeof img === "object") {
              return this.uploadImage(img, index);
            } else return img;
          });
          Promise.all(allQuestionImagesPromises).then(
            async (allQuestionImages) => {
              console.log(
                allQuestionImages,
                "resolved question image promises"
              );
              saveTask(getQuiz(allQuestionImages, optionImages));
            }
          );
          //console.log(optionImages, "resolved promises");
          //saveTask(getQuiz(optionImages));
        });
      }
    };

    const addTask = async (data) => {
      //console.log(data);
      if (data) {
        let taskData = {
          user_id: currentUser.uid,
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          deadline: moment(data["deadline"]).valueOf(),
          quiz_name: data["quiz_name"],
          questions: data["quiz_questions"],
          all_participants: data["everyone"],
          assigned: data["channelUsers"],
          save_for_later: false,
          folderId: data["folder"] ? data["folder"] : "",
        };
        if (Object.keys(editedQuiz).length > 0) {
          let savedQuiz = savedQuizes.find(
            (obj) => obj.contentId === editedQuiz.id
          );
          let savedQuizId = savedQuiz.id;

          try {
            let res = await axios
              .post(`${API_BASE_URL}${QUIZ_UPDATE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                quiz_name: data["quiz_name"],
                quiz_id: savedQuizId,
                questions: data["quiz_questions"],
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                folderId: data["folder"] ? data["folder"] : "",
                is_graded: graded,
              })
              .then(async () => {
                await await axios.post(`${API_BASE_URL}${QUIZ_SHARE}`, {
                  user_id: currentUser.uid,
                  workspace_id: currentWorkspace.workspaceId,
                  room_id: currentChannel.roomId,
                  quiz_id: savedQuizId,
                });
              })
              .then(() => {
                if (this.props.darkTheme) {
                  toast.dark("Quiz updated and shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast("Quiz updated and shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }

                this.props.resetData();
              });
          } catch (error) {
            console.log(error);
          }
        } else if (Object.keys(editedPublishedQuiz).length > 0) {
          try {
            let res = await axios
              .post(`${API_BASE_URL}${QUIZ_EDIT}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                quiz_name: data["quiz_name"],
                published_quiz_id: this.state.publishedQuizId,
                questions: data["quiz_questions"],
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                folderId: data["folder"] ? data["folder"] : "",
                is_graded: graded,
              })
              .then(() => {
                if (this.props.darkTheme) {
                  toast.dark("Quiz edited and shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast("Quiz edited and shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }

                this.props.resetData();
              });
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            let res = await axios
              .post(`${API_BASE_URL}${QUIZ_CREATE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                quiz_name: data["quiz_name"],
                questions: data["quiz_questions"],
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                folderId: data["folder"] ? data["folder"] : "",
                save_for_later: false,
                is_graded: graded,
              })
              .then(() => {
                if (this.props.darkTheme) {
                  toast.dark("Quiz shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                } else {
                  toast("Quiz shared.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                }

                this.props.resetData();
              });
            console.log(res);
          } catch (error) {
            console.log(error);
          }
        }
      }
    };
    const saveTask = async (data) => {
      // console.log(data);
      if (data) {
        let taskData = {
          user_id: currentUser.uid,
          workspace_id: currentWorkspace.workspaceId,
          room_id: currentChannel.roomId,
          deadline: moment(data["deadline"]).valueOf(),
          quiz_name: data["quiz_name"],
          questions: data["quiz_questions"],
          all_participants: data["everyone"],
          assigned: data["channelUsers"],
          save_for_later: true,
          folderId: data["folder"] ? data["folder"] : "",
        };
        // console.log(taskData);
        if (Object.keys(editedQuiz).length > 0) {
          let savedQuiz = savedQuizes.find(
            (obj) => obj.contentId === editedQuiz.id
          );
          let savedQuizId = savedQuiz.id;

          try {
            let res = await axios
              .post(`${API_BASE_URL}${QUIZ_UPDATE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                quiz_name: data["quiz_name"],
                quiz_id: savedQuizId,
                questions: data["quiz_questions"],
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                save_for_later: true,
                folderId: data["folder"] ? data["folder"] : "",
                is_graded: graded,
              })
              .then(() => {
                const task = firebase.firestore().collection("quiz");
                task
                  .doc(editedQuiz.id)
                  .get()
                  .then((doc) => {
                    console.log(doc);
                    if (doc.exists) {
                      let index = savedQuizesData.findIndex(
                        (savedQuiz) => savedQuiz.id === doc.id
                      );
                      console.log(index);
                      if (index !== -1) {
                        // console.log(doc.data());
                        savedQuizesData.splice(index, 1, {
                          ...doc.data(),
                          id: doc.id,
                        });
                        this.setState(savedQuizesData);
                      }
                    }
                  });
                this.setState({ view: 0, editedQuiz: {} }, () => {
                  if (this.props.darkTheme) {
                    toast.dark("Quiz updated and saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  } else {
                    toast("Quiz updated and saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  }
                });
                // console.log("respoxx 9")
                this.props.resetData();
                this.props.setQuizTitle("");
                this.props.setQuizCorrectAnswers([]);
                this.props.setQuizOptions([]);
                this.props.setQuizTypes([]);
                this.props.setQuizQuestions([]);
                this.props.setQuizQuestionImages([]);
                this.props.setQuizQuestionPreviewImages([]);
                this.props.setQuizWeights([]);
                this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
                this.props.setTaskChannelUsers([]);
                this.props.setTaskEveryone(false);
                this.props.setTaskFolder("");
              });
            console.log(res);
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            let res = await axios
              .post(`${API_BASE_URL}${QUIZ_CREATE}`, {
                user_id: currentUser.uid,
                workspace_id: currentWorkspace.workspaceId,
                room_id: currentChannel.roomId,
                deadline: moment(data["deadline"]).valueOf(),
                quiz_name: data["quiz_name"],
                questions: data["quiz_questions"],
                all_participants: data["everyone"],
                assigned: data["channelUsers"],
                save_for_later: true,
                folderId: data["folder"] ? data["folder"] : "",
                is_graded: graded,
              })
              .then(() => {
                //this.props.resetData();
                this.setState({ view: 0, editedQuiz: {} }, () => {
                  if (this.props.darkTheme) {
                    toast.dark("Quiz saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  } else {
                    toast("Quiz saved.", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                      hideProgressBar: true,
                      autoClose: 3000,
                    });
                  }
                });
                //console.log("respoxx10")
                this.props.resetData();
                this.props.setQuizTitle("");
                this.props.setQuizCorrectAnswers([]);
                this.props.setQuizOptions([]);
                this.props.setQuizOptionsImages([]);
                this.props.setQuizTypes([]);
                this.props.setQuizQuestions([]);
                this.props.setQuizQuestionImages([]);
                this.props.setQuizQuestionPreviewImages([]);
                this.props.setQuizWeights([]);
                this.props.setTaskDeadline(new Date(Date.now() + 10 * 600000));
                this.props.setTaskChannelUsers([]);
                this.props.setTaskEveryone(false);
                this.props.setTaskFolder("");
              });
          } catch (error) {
            console.log(error);
          }
        }
      }
    };

    const shareSavedTask = async (id) => {
      let savedQuiz = savedQuizes.find((obj) => obj.contentId === id);
      let savedQuizData = savedQuizesData.find((obj) => obj.id === id);
      //console.log(savedQuiz)
      if (!savedQuiz.all && savedQuiz.assignedTo.length === 0) {
        toast.warning(
          "Share Failed: Quiz is not assigned to any participant.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        return null;
      }
      if (savedQuizData.createdBy !== currentUser.uid) {
        toast.warning("Share Failed: Quiz is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }
      if (savedQuizData.deadline < Date.now()) {
        toast.warning(
          "Deadline has passed.please edit quiz to change deadline.",
          { position: toast.POSITION.BOTTOM_CENTER }
        );
        return;
      }
      if (
        (savedQuiz.all === false &&
          savedQuiz.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        savedQuiz.all === true
      ) {
      } else {
        toast.warning(
          "Looks like some participants have left the group, please edit  to reassign  participants.",
          { position: toast.POSITION.BOTTOM_RIGHT }
        );
        return;
      }

      let savedQuizId = savedQuiz.id;
      try {
        let res = await axios
          .post(`${API_BASE_URL}${QUIZ_SHARE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            quiz_id: savedQuizId,
          })
          .then(() => {
            if (this.props.darkTheme) {
              toast.dark("Quiz shared.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            } else {
              toast("Quiz shared.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            }
            this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }
    };

    const editPublishedTask = async (message_id) => {
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${currentChannel.roomId}/messages`
        );
      let messageDataObj = await query
        .doc(message_id)
        .get()
        .then((doc) => {
          console.log(doc.data());
          if (doc.exists) {
            return { ...doc.data(), id: doc.id };
          }
        });
      console.log(messageDataObj);
      let messageData = {};
      if (messageDataObj.type === 4) {
        messageData = await firebase
          .firestore()
          .collection("quiz")
          .doc(messageDataObj.contentId)
          .get()
          .then((doc) => {
            return { ...doc.data(), id: doc.id };
          });
      }

      //console.log(messageData, "respox");
      this.props.resetData();
      this.props.setRightPanel(true);
      this.props.setEditingPublished(true);
      setTimeout(
        () => this.props.setContentTypeAndValue("createTask", "Quiz"),
        500
      );
      setTimeout(() => this.props.setEditedPublishedTask(messageData), 700);
    };

    const editSavedTask = (id) => {
      let savedQuiz = savedQuizesData.find((obj) => obj.id === id);
      if (savedQuiz.createdBy !== currentUser.uid) {
        toast.warning("Edit Failed: Quiz is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }
      let questions = [];
      let questionImages = [];
      let options = [];
      let imageOptions = [];
      let types = [];
      let correctAnswers = [];
      // let correct_answers = [];
      console.log(savedQuiz)

      let sorted = _.map(savedQuiz.quizQuestions, function (value, key) {
        return _.defaults({ order: value.sequenceNo }, value);
      });

      sorted= _.sortBy(sorted,'order');

      sorted.map((q, key) => {
        let multipleAnswers = [];

        let queImgOptions = [];
        let queOptions = [];
        let singleAnswer = "";
        questions.push(q.question);
        questionImages.push(q.questionImage);
        if(q.sequenceNo === null){
          setQuizRandomizeQuestion(true)
        }
        if (q.type === 1) {
          types.push("multiple");

          let sortedOptions = _.map(q.options, function (value, key) {
            return _.defaults({ order: value.sequenceNo }, value);
          });
  
          sortedOptions = _.sortBy(sortedOptions, 'order');

          sortedOptions.map((o, k) => {
            queOptions.push(o.optionValue);
            queImgOptions.push(
              o.optionImage
                ? o.optionImage
                : null
            );
            if(o.sequenceNo === null){
              setQuizRandomize(true)
            }
            if (o.correctAnswer) {
              multipleAnswers.push(
                o.optionValue
              );
            }
          });
        } else {
          types.push("single");

          let sortedOptions = _.map(q.options, function (value, key) {
            return _.defaults({ order: value.sequenceNo }, value);
          });
  
          sortedOptions = _.sortBy(sortedOptions, 'order');

          sortedOptions.map((o, k) => {
            queOptions.push(o.optionValue);
            queImgOptions.push(
              o.optionImage
                ? o.optionImage
                : null
            );
            if(o.sequenceNo === null){
              setQuizRandomize(true)
            }
            if (o.correctAnswer) {
              singleAnswer = o.optionValue;
            }
          });
        }

        if (multipleAnswers.length > 0) {
          correctAnswers.push(multipleAnswers);
        }
        if (singleAnswer) {
          correctAnswers.push(singleAnswer);
        }
        options.push(queOptions);
        imageOptions.push(queImgOptions);
      });

      setQuizTitle(savedQuiz.quizName);
      setQuizQuestions(questions);
      setQuizQuestionImages(questionImages);
      setQuizQuestionPreviewImages(questionImages);
      setQuizOptions(options);
      setQuizTypes(types);
      setQuizOptionsImages(imageOptions);
      setQuizPreviewImages(imageOptions);
      setQuizCorrectAnswers(correctAnswers);
      setTaskDeadline(new Date(savedQuiz.deadline));
      setTaskFolder(savedQuiz.folderId);
      //setTaskChannelUsers(savedQuiz.all ? [] : savedQuiz.assignedTo);
      if (
        (savedQuiz.all === false &&
          savedQuiz.assignedTo.every((user) =>
            allParticipants.find(
              (User) => User.uid === user || user === currentChannel.createdBy
            )
          )) ||
        savedQuiz.all === true
      ) {
        this.props.setTaskChannelUsers(savedQuiz.assignedTo);
      } else {
        let assignedTo = savedQuiz.assignedTo.filter((user) =>
          allParticipants.find(
            (User) => User.uid === user || user === currentChannel.createdBy
          )
        );
        console.log(assignedTo);
        this.props.setTaskChannelUsers(assignedTo);
      }
      setTaskEveryone(savedQuiz.all);
      this.setState({ editedQuiz: savedQuiz, view: 1 });
      //console.log("respoxx11")
    };
    const deleteSavedTask = async (id) => {
      let savedQuiz = savedQuizes.find((obj) => obj.contentId === id);
      if (savedQuiz.createdBy !== currentUser.uid) {
        toast.warning("Deletion Failed: Quiz is not created by you.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return null;
      }
      let savedQuizId = savedQuiz.id;
      try {
        let res = await axios
          .post(`${API_BASE_URL}${QUIZ_REMOVE}`, {
            user_id: currentUser.uid,
            workspace_id: currentWorkspace.workspaceId,
            room_id: currentChannel.roomId,
            quiz_id: savedQuizId,
          })
          .then(() => {
            if (this.props.darkTheme) {
              toast.dark("Quiz deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            } else {
              toast("Quiz deleted.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                hideProgressBar: true,
                autoClose: 3000,
              });
            }

            //this.props.resetData();
          });
      } catch (error) {
        console.log(error);
      }
    };
    //console.log(publishedQuizesData);
    //console.log(savedQuizesData);
    let canSeeTaskCurrentUser = publishedQuizesData.filter(
      (quiz) =>
        currentChannel?.createdBy === currentUser.uid ||
        quiz.all === true ||
        coAdmins.find((usr) => usr.uid === currentUser.uid) ||
        (quiz.assignedTo && quiz.assignedTo.includes(currentUser.uid))
    );
    return view === 1 ? (
      <>
        <div
          onClick={() => {
            setQuizTitle("");
            setQuizCorrectAnswers([]);
            setQuizOptions([]);
            setQuizTypes([]);
            setQuizQuestionImages([]);
            setQuizQuestionPreviewImages([]);
            setQuizOptionsImages([]);
            setQuizPreviewImages([]);
            setQuizQuestions([]);
            setTaskDeadline(new Date(Date.now() + 10 * 600000));
            setTaskChannelUsers([]);
            this.props.setEditingPublished(false);
            setTaskEveryone(false);
            setTaskFolder("");
            this.setState({ view: 0, editedQuiz: {} });
          }}
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
          className="mb-1 p-2 bg-card-theme text-theme"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
          <span>
            {Object.keys(editedQuiz).length > 0 ? "Update Quiz" : "Create Quiz"}
          </span>
        </div>
        <CreateQuiz
          allParticipants={this.props.allParticipants}
          saveForLaterTask={saveForLaterTask}
          shareTask={shareTask}
          validateData={validateData}
          addTask={addTask}
          saveTask={saveTask}
          editedQuiz={
            Object.keys(editedQuiz).length > 0 ? editedQuiz : sharedAgainQuiz
          }
          editedQuizHasResponses={this.state.editedQuizHasResponses}
          editingPublished={this.props.editingPublished}
          publishedQuizId={this.state.publishedQuizId}
          quizMessageData={this.state.quizMessageData}
          error={error}
        />
      </>
    ) : view === 0 ? (
      <>
        <div
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
          className="m-2 rounded p-2 bg-card-theme text-theme"
        >
          <div className="d-flex">
            <div
              onClick={() => {
                setContentType(null);
                setTaskType(null);
                resetAllTasks();
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
              <span>
                {contentType === "createTask"
                  ? taskType
                    ? taskType === "Quiz"
                      ? taskType + "es"
                      : taskType + "s"
                    : "Create Task"
                  : ""}
              </span>
            </div>
          </div>
          <IsAdmin>
            <div className="mt-2">
              {/*<p className="mb-2 small text-theme-lighter">
                Request opinion from participants in the room and collect
                responses in real time. Users can respond to Quiz easily from
                their message timeline.
              </p>*/}
              <div className="d-flex">
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => this.addQuizView(1)}
                  className="pt-0 w-50"
                >
                  <small>+ CREATE NEW QUIZ</small>
                </Button>

                {/* <span className="mt-2 ml-3 text-secondary pointer smaller">
                  <FontAwesomeIcon icon={faQuestionCircle} /> WATCH TUTORIAL
                </span> */}
              </div>
            </div>
          </IsAdmin>
        </div>

        <IsAdmin>
          <div className="row m-2 border-1 rounded">
            <div
              className={
                selected === "Published"
                  ? "col-6 text-theme bg-card-theme rounded py-1 d-flex justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Published")}
              style={{ cursor: "pointer" }}
            >
              Published
            </div>

            <div
              className={
                selected === "Saved"
                  ? "col-6 text-theme bg-card-theme rounded d-flex py-1 justify-content-center small"
                  : "col-6 text-theme d-flex justify-content-center py-1 small"
              }
              onClick={() => this.handleSelected("Saved")}
              style={{ cursor: "pointer" }}
            >
              Saved
            </div>
          </div>
        </IsAdmin>

        {selected === "Published" ? (
          publishedQuizesData.length > 0 && canSeeTaskCurrentUser.length > 0 ? (
            <div>
              <div className="d-flex justify-content-between">
                <InputGroup className="my-1 outerPill">
                  <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                    <div className="py-2 pr-2 pl-3">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="sm"
                        color="#aeaeae"
                      />
                    </div>
                    <div className="flex-grow-1">
                      <Form.Control
                        type="text"
                        id="participantsSearch"
                        className="border-0 px-0 bg-theme text-theme rounded"
                        onChange={(e) => {
                          this.setState({ searchTerm: e.target.value }, () => {
                            const reg = _.escapeRegExp(this.state.searchTerm);
                            // console.log(reg,"Reg")
                            const regex = new RegExp(reg, "gi");
                            this.setState({ regex: regex });
                          });
                        }}
                        placeholder={"Search"}
                        value={this.state.searchTerm}
                        style={{ margin: 0 }}
                      />
                    </div>
                    {this.state.searchTerm.length > 0 && (
                      <div
                        className="py-2 pl-2 pr-3"
                        onClick={() => this.setState({ searchTerm: "" })}
                      >
                        <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                      </div>
                    )}
                  </div>
                </InputGroup>
                {this.state.sortType === "unreverse" ? (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "reverse" }, () => {
                        this.sortQuizData();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAmountUpAlt} color="#aeaeae" />
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "unreverse" }, () => {
                        this.sortQuizData();
                      });
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSortAmountDownAlt}
                      color="#aeaeae"
                    />
                  </Button>
                )}

                {this.state.sortType === "atoz" ? (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "ztoa" }, () => {
                        this.sortQuizData();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUp} color="#aeaeae" />
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                    variant={this.props.darkTheme ? "dark" : "light"}
                    onClick={() => {
                      this.setState({ sortType: "atoz" }, () => {
                        this.sortQuizData();
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSortAlphaUpAlt} color="#aeaeae" />
                  </Button>
                )}
              </div>
              {this.state.searchTerm && this.state.searchTerm.length > 0 ? (
                <>
                  {publishedQuizesData
                    .filter((item) => {
                      return item.quizName?.match(this.state.regex);
                    })
                    .map((quiz, index) =>
                      currentChannel?.createdBy === currentUser.uid ||
                      quiz.all === true ||
                      coAdmins.find((usr) => usr.uid === currentUser.uid) ||
                      (quiz.assignedTo &&
                        quiz.assignedTo.includes(currentUser.uid)) ? (
                        <div
                          key={index + quiz.id}
                          className={
                            quiz.deadline > Date.now()
                              ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
                              : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
                          }
                        >
                          <div
                            className="d-flex justify-content-between mb-1 pointer"
                            onClick={() => this.showSelectedQuiz(quiz.id)}
                          >
                            <span className="text-theme-lighter smaller">
                              {moment(quiz.timestamp).format("LLL")}
                            </span>
                            <span
                              className={
                                quiz.deadline > Date.now()
                                  ? "text-success smaller"
                                  : "text-secondary smaller"
                              }
                            >
                              {quiz.deadline > Date.now() ? "Active" : "Closed"}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p
                              className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
                              onClick={() => this.showSelectedQuiz(quiz.id)}
                            >
                              {quiz.quizName}
                            </p>
                            <IsAdmin>
                              <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                                <Dropdown.Toggle
                                  size="sm"
                                  as={this.CustomToggle}
                                />
                                <Dropdown.Menu className="bg-card-theme text-theme">
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() => shareTaskAgain(quiz)}
                                  >
                                    Duplicate{" "}
                                    {/*<small className="text-warning">Coming Soon</small>*/}
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() =>
                                      editPublishedTask(quiz.messageId)
                                    }
                                  >
                                    Edit{" "}
                                    {/*<small className="text-warning">Coming Soon</small>*/}
                                  </Dropdown.Item>
                                  {quiz.deadline > Date.now() ? (
                                    <Dropdown.Item
                                      className="small bg-card-theme text-theme"
                                      onClick={() => closeAppResponses(quiz)}
                                    >
                                      Close{" "}
                                      {/*<small className="text-warning">
                                  Coming Soon
                                </small>*/}
                                    </Dropdown.Item>
                                  ) : null}
                                  {/*  <Dropdown.Item
                      className="small"
                      onClick={() => deleteSavedTask(poll.id)}
                    >
                      Delete
                  </Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </IsAdmin>
                          </div>
                        </div>
                      ) : null
                    )}
                </>
              ) : (
                <>
                  {publishedQuizesData.map((quiz, index) =>
                    currentChannel?.createdBy === currentUser.uid ||
                    quiz.all === true ||
                    coAdmins.find((usr) => usr.uid === currentUser.uid) ||
                    (quiz.assignedTo &&
                      quiz.assignedTo.includes(currentUser.uid)) ? (
                      <div
                        key={index + quiz.id}
                        className={
                          quiz.deadline > Date.now()
                            ? "shadow-sm rounded-lg p-2 m-2 bg-card-theme border-1"
                            : "shadow-sm rounded-lg p-2 m-2 bg-card-theme "
                        }
                      >
                        <div
                          className="d-flex justify-content-between mb-1 pointer"
                          onClick={() => this.showSelectedQuiz(quiz.id)}
                        >
                          <span className="text-theme-lighter smaller">
                            {moment(quiz.timestamp).format("LLL")}
                          </span>
                          <span
                            className={
                              quiz.deadline > Date.now()
                                ? "text-success smaller"
                                : "text-secondary smaller"
                            }
                          >
                            {quiz.deadline > Date.now() ? "Active" : "Closed"}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p
                            className="m-0 font-weight-bold text-theme small flex-grow-1 pointer"
                            onClick={() => this.showSelectedQuiz(quiz.id)}
                          >
                            {quiz.quizName}
                          </p>
                          <IsAdmin>
                            <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer bg-card-theme text-theme">
                              <Dropdown.Toggle
                                size="sm"
                                as={this.CustomToggle}
                              />
                              <Dropdown.Menu className="bg-card-theme text-theme">
                                <Dropdown.Item
                                  className="small bg-card-theme text-theme"
                                  onClick={() => shareTaskAgain(quiz)}
                                >
                                  Duplicate{" "}
                                  {/*<small className="text-warning">Coming Soon</small>*/}
                                </Dropdown.Item>
                                <Dropdown.Item
                                  className="small bg-card-theme text-theme"
                                  onClick={() =>
                                    editPublishedTask(quiz.messageId)
                                  }
                                >
                                  Edit{" "}
                                  {/*<small className="text-warning">Coming Soon</small>*/}
                                </Dropdown.Item>
                                {quiz.deadline > Date.now() ? (
                                  <Dropdown.Item
                                    className="small bg-card-theme text-theme"
                                    onClick={() => closeAppResponses(quiz)}
                                  >
                                    Close{" "}
                                    {/*<small className="text-warning">
                                  Coming Soon
                                </small>*/}
                                  </Dropdown.Item>
                                ) : null}
                                {/*  <Dropdown.Item
                      className="small"
                      onClick={() => deleteSavedTask(poll.id)}
                    >
                      Delete
                  </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </IsAdmin>
                        </div>
                      </div>
                    ) : null
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="col">
              <p className="text-theme text-center small">
                No quizes published yet
              </p>
            </div>
          )
        ) : /*<div className="shadow-sm rounded-lg p-2 m-2">
            {publishedQuizesData.map((quiz, index) =>
              currentChannel.createdBy === currentUser.uid ||
              quiz.all === true ||
              (quiz.assignedTo && quiz.assignedTo.includes(currentUser.uid)) ? (
                <div
                  key={index + quiz.id}
                  className="row d-flex shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.showSelectedQuiz(quiz.id)}
                >
                  <div className="col-6 justify-content-start">
                    <small className="text-theme">
                      {moment(quiz.timestamp).format("LLL")}
                    </small>
                    <p className="mb-2 font-weight-bold text-warning small">
                      {quiz.quizName}
                    </p>
                  </div>
                  <div className="col-6 justify-content-end">
                    <span
                      class={
                        quiz.deadline > Date.now()
                          ? "badge badge-pill badge-success"
                          : "badge badge-pill badge-secondary"
                      }
                    >
                      {quiz.deadline > Date.now() ? "Active" : "Closed"}
                    </span>
                  </div>
                </div>
              ) : null
            )}
       </div>*/

        savedQuizes.length > 0 ? (
          <div>
            <div className="d-flex justify-content-between">
              <InputGroup className="my-1 outerPill">
                <div className="d-flex align-items-center rounded-pill flex-grow-1  bg-theme">
                  <div className="py-2 pr-2 pl-3">
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="sm"
                      color="#aeaeae"
                    />
                  </div>
                  <div className="flex-grow-1">
                    <Form.Control
                      type="text"
                      id="participantsSearch"
                      className="border-0 px-0 bg-theme text-theme rounded"
                      onChange={(e) => {
                        this.setState({ searchTerm: e.target.value }, () => {
                          const reg = _.escapeRegExp(this.state.searchTerm);
                          // console.log(reg,"Reg")
                          const regex = new RegExp(reg, "gi");
                          this.setState({ regex: regex });
                        });
                      }}
                      placeholder={"Search"}
                      value={this.state.searchTerm}
                      style={{ margin: 0 }}
                    />
                  </div>
                  {this.state.searchTerm.length > 0 && (
                    <div
                      className="py-2 pl-2 pr-3"
                      onClick={() => this.setState({ searchTerm: "" })}
                    >
                      <FontAwesomeIcon icon={faTimes} color="#aeaeae" />
                    </div>
                  )}
                </div>
              </InputGroup>
              {this.state.sortType === "unreverse" ? (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "reverse" }, () => {
                      this.sortQuizData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAmountUpAlt} color="#aeaeae" />
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "unreverse" }, () => {
                      this.sortQuizData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAmountDownAlt} color="#aeaeae" />
                </Button>
              )}

              {this.state.sortType === "atoz" ? (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "ztoa" }, () => {
                      this.sortQuizData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAlphaUp} color="#aeaeae" />
                </Button>
              ) : (
                <Button
                  size="sm"
                  className="py-0 mr-2 text-theme  bg-card-theme videowindow  pl-2"
                  variant={this.props.darkTheme ? "dark" : "light"}
                  onClick={() => {
                    this.setState({ sortType: "atoz" }, () => {
                      this.sortQuizData();
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faSortAlphaUpAlt} color="#aeaeae" />
                </Button>
              )}
            </div>
            {this.state.searchTerm && this.state.searchTerm.length > 0 ? (
              <>
                {savedQuizesData
                  .filter((item) => {
                    return item.quizName?.match(this.state.regex);
                  })
                  .map((quiz, index) => (
                    <div className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1">
                      <div
                        key={index}
                        className="d-flex justify-content-center"
                      >
                        <div
                          onClick={() => editSavedTask(quiz.id)}
                          className="pointer"
                        >
                          <small className="text-theme-lighter smaller">
                            {moment(quiz.timestamp).format("LLL")}
                          </small>
                          <p className="m-0 font-weight-bold text-theme small">
                            {quiz.quizName}
                          </p>
                        </div>
                        <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                          <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                          <Dropdown.Menu className="bg-card-theme text-theme">
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => shareSavedTask(quiz.id)}
                            >
                              Share now
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => editSavedTask(quiz.id)}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="small bg-card-theme text-theme"
                              onClick={() => deleteSavedTask(quiz.id)}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <>
                {savedQuizesData.map((quiz, index) => (
                  <div className="shadow-sm rounded-lg px-2 pb-2 m-2 bg-card-theme border-1">
                    <div key={index} className="d-flex justify-content-center">
                      <div
                        onClick={() => editSavedTask(quiz.id)}
                        className="pointer"
                      >
                        <small className="text-theme-lighter smaller">
                          {moment(quiz.timestamp).format("LLL")}
                        </small>
                        <p className="m-0 font-weight-bold text-theme small">
                          {quiz.quizName}
                        </p>
                      </div>
                      <Dropdown className="ml-auto px-1 py-0 justify-content-right text-right align-items-right pointer  bg-card-theme text-theme">
                        <Dropdown.Toggle size="sm" as={this.CustomToggle} />
                        <Dropdown.Menu className="bg-card-theme text-theme">
                          <Dropdown.Item
                            className="small bg-card-theme text-theme"
                            onClick={() => shareSavedTask(quiz.id)}
                          >
                            Share now
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="small bg-card-theme text-theme"
                            onClick={() => editSavedTask(quiz.id)}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="small bg-card-theme text-theme"
                            onClick={() => deleteSavedTask(quiz.id)}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        ) : (
          <div className="col">
            <p className="text-theme text-center small">No quizes saved yet</p>
          </div>
        )}
      </>
    ) : (
      <div className="container-fluid px-3">
        <div
          style={{
            position: "-webkit-sticky",
            position: "sticky",
            zIndex: 10,
            top: 0,
            cursor: "pointer",
          }}
          onClick={() => {
            this.setState({ view: 0, selectedQuiz: {} });
          }}
          className="mb-1 p-2 bg-card-theme text-theme"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2 pointer" />
          <span>Back</span>
        </div>
        <div className="shadow-sm rounded-lg p-2 m-2 my-3 bg-card-theme">
          <Quiz
            view={view}
            quiz_data={{ contentId: selectedQuiz.id }}
            user_id={currentUser.uid}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  currentUser: state.user.currentUser,

  previewImageOptions: state.quiz.previewImageOptions,
  imageOptions: state.quiz.imageOptions,
  type: state.quiz.type,
  quizTitle: state.quiz.quizTitle,
  questions: state.quiz.questions,
  options: state.quiz.options,
  types: state.quiz.types,
  correctAnswers: state.quiz.correctAnswers,
  weights: state.quiz.weights,
  totalWeight: state.quiz.totalWeight,
  graded: state.quiz.graded,
  randomize: state.quiz.randomize,
  randomizeQuestion: state.quiz.randomizeQuestion,
  quizQuestionImages: state.quiz.quizQuestionImages,
  //sharing: state.task.sharing,

  deadline: state.task.deadline,
  channelUsers: state.task.channelUsers,
  folder: state.task.folder,
  everyone: state.task.everyone,
  sharing: state.platform.sharing,
  sharedTask: state.platform.sharedTask,
  editing: state.platform.editing,
  editedTask: state.platform.editedTask,
  editedPublishedTask: state.platform.editedPublishedTask,
  editingPublished: state.platform.editingPublished,
  darkTheme: state.platform.darkTheme,
});

export default connect(mapStateToProps, {
  setRightPanel,
  setQuizRandomize,
  setQuizRandomizeQuestion,
  setContentTypeAndValue,
  setQuizQuestionImages,
  setQuizQuestionPreviewImages,
  setQuizOptionsImages,
  setQuizPreviewImages,
  setQuizTitle,
  setTaskSharing,
  setQuizQuestions,
  setQuizOptions,
  setQuizTypes,
  setQuizCorrectAnswers,
  setQuizWeights,
  setQuizTotalWeight,
  setQuizGraded,
  resetData,
  setTaskDeadline,
  setTaskChannelUsers,
  setTaskFolder,
  setTaskOldFolder,
  setTaskEveryone,
  setSharedTask,
  setEditedTask,
  setTaskEditing,
  resetData,
  setEditedPublishedTask,
  setEditingPublished,
})(QuizHomepage);
