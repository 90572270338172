import React, { Component } from 'react'
import { connect } from 'react-redux'
import firebase from 'firebase'
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import RoomDetailsElement from './RoomDetailsElement';

const IMG_SIZE = 150;

class OpenWorkspaceDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            loadingUserData: true,
        }
    }

    _handleStateChange = (attr, val) => this.setState({ [attr]: val })

    getAdminDetails = async () => {
        const { workspaceAdmin } = this.props.currentWorkspace;

        let docRef = await firebase.firestore().collection("users").doc(`${workspaceAdmin}`)
        let user_data = await docRef
            .get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    console.log("workspaceAdmin: ", workspaceAdmin, "doc.data(): ", doc.data())
                    return data;
                } else {
                    console.log("User data not found!")
                    return null;
                }
            })
            .catch(error => console.error(error));

        this._handleStateChange("userData", user_data);
        this._handleStateChange("loadingUserData", false);
    }

    getRoomsDetails = async () => {
        const {} = this.props.currentWorkspace;
    }

    componentDidMount() {
        this.getAdminDetails();
    }
    
    componentDidUpdate(prevProps, prevStates) {
        if (prevProps.currentWorkspace.workspaceAdmin != this.props.currentWorkspace.workspaceAdmin) {
            this.getAdminDetails();
        }
    }


    render() {
        const { currentWorkspace, currentUser } = this.props;
        const { createdAt, subscription, workspaceAdmin, workspaceName, workspacePic, rooms } = currentWorkspace;
        const { loadingUserData, userData } = this.state;

        return (
            <div className="bg-theme">
                <div className='shadow-sm rounded-lg py-2 m-2 bg-card-theme text-theme'>
                    <div className="my-5 mx-auto" id="add-avatar-right">
                        <img
                            src={workspacePic}
                            style={{ height: IMG_SIZE, width: IMG_SIZE }}
                            className="rounded-circle" />
                    </div>
                </div>

                <div className='shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme'>
                    <div className="">
                        <p className="font-weight-bold">Workspace</p>
                        <p className="text-theme mb-0">{workspaceName}</p>
                        <small className="text-theme">{moment(createdAt).format("LLLL")}</small>
                    </div>
                </div>

                <div className='shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme'>
                    <div className="">
                        <p className="font-weight-bold">Admin details</p>
                        {loadingUserData
                        ? <Spinner animation="border" />
                        : <div className="d-flex align-items-center">
                            <img
                                src={userData.avatar}
                                style={{ height: 45, width: 45 }}
                                className="rounded-circle mr-2" />
                            <div className="flex-grow-1">
                                <p className="text-theme mb-0">{userData.displayName} {workspaceAdmin === currentUser.uid ? "(You)" : ""}</p>
                                {userData.isGuest && <small className="text-theme">Guest</small>}
                            </div>
                        </div>}
                    </div>
                </div>

                <div className='shadow-sm rounded-lg p-2 m-2 bg-card-theme text-theme'>
                    <div className="">
                        <p className="font-weight-bold">Rooms</p>
                        {rooms.map((room, key) => (
                            <RoomDetailsElement room={room} key={key} workspace={currentWorkspace} />
                        ))}
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    currentWorkspace: state.workspace.currentWorkspace,
    currentUser: state.user.currentUser
})

export default connect(mapStateToProps)(OpenWorkspaceDetails);