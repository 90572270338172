import React from 'react';
import firebase from 'firebase';
import { Image, Spinner, Modal, Button } from "react-bootstrap";
import { connect } from 'react-redux';
import UserProfileModal from './UserProfileModal';
import GuestUserConversionModal from './GuestUserConversionModal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faLink,
    faAngleRight
} from "@fortawesome/free-solid-svg-icons";

class UserNameAvatar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            loadingUserData: true,
            showUserProfileModal: false,
            showGuestWarningModal: false,
            showGuestConversionModal: false
        }
    }

    getAdminDetails = async () => {
        const { userId } = this.props;

        let docRef = firebase.firestore().collection("users").doc(`${userId}`)
        let user_data = await docRef
            .get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    return data;
                } else {
                    // console.log("User data not found!")
                    return null;
                }
            })
            .catch(error => console.error(error));

        this.setState({ userData: user_data, loadingUserData: false });
    }

    componentDidMount() {
        this.getAdminDetails()
    }

    componentDidUpdate(prevProps, prevState) {
        const { userId } = this.props;

        if (prevProps.userId !== userId) {
            this.getAdminDetails()
        }
    }


    render() {
        const { nameOnly, showYou, children, currentUser, userId, displayName, avatar } = this.props;
        const { loadingUserData, userData } = this.state;

        return (
            <>
                <React.Fragment>
                    {nameOnly
                        ? <>
                            {(currentUser.uid === userId)
                                ? <span className='text-theme-light'>{userData.displayName} <small className="text-warning">You</small></span>
                                : <span className='text-theme-light'>{userData.displayName}</span>}
                            {children}
                        </>
                        : <div className="d-flex p-2">
                            <Image onClick={() => {
                                if (!currentUser.isAnonymous) {
                                    this.setState({ showUserProfileModal: true })
                                } else {
                                    this.setState({ showGuestWarningModal: true })
                                }
                            }} width="40" height="40"
                                roundedCircle
                                src={avatar}
                                spaced="right"
                                style={{ background: 'white' }} />
                            <div className="flex-grow-1 mx-3">
                                {(showYou && currentUser.uid === userId)
                                    ? <p onClick={() => {
                                        if (!currentUser.isAnonymous) {
                                            this.setState({ showUserProfileModal: true })
                                        } else {
                                            this.setState({ showGuestWarningModal: true })
                                        }
                                    }} className='mb-0 text-theme-light'>{displayName} <small className="text-warning">You</small></p>
                                    : <p onClick={() => {
                                        if (!currentUser.isAnonymous) {
                                            this.setState({ showUserProfileModal: true })
                                        } else {
                                            this.setState({ showGuestWarningModal: true })
                                        }
                                    }} className='mb-0 text-theme-light'>{displayName}</p>}
                                {children}
                            </div>
                        </div>}
                </React.Fragment>
                <UserProfileModal show={this.state.showUserProfileModal} onHide={() => this.setState({ showUserProfileModal: false })} userData={this.state.userData} />

                <Modal
                    show={this.state.showGuestWarningModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => this.setState({ showGuestWarningModal: false })}
                >
                    <Modal.Header className="bg-theme border-0 d-flex justify-content-end align-items-center p-2">
                        <div title="Close" className="pointer" onClick={() => this.setState({ showGuestWarningModal: false })}>
                            <FontAwesomeIcon className="text-theme" icon={faTimes} />
                        </div>

                    </Modal.Header>
                    <Modal.Body
                        className="overflow-auto bg-theme text-theme text-center"
                    >
                        <p>
                            You're logged in as a guest. Please link your email.
                        </p>
                        {currentUser.isAnonymous ? (
                            <Button
                                onClick={() => {
                                    this.setState({ showGuestConversionModal: true, showGuestWarningModal:false })
                                }}
                                className="rounded-0 bg-theme"
                            >
                                <div className="d-flex align-items-center justify-content-center p-3">
                                    <div className="bg-theme-lightest rounded rounded-circle d-flex justify-content-center align-items-center"
                                        style={{ height: "2.5rem", width: "2.5rem", borderRadius: "1.25rem" }}>
                                        <FontAwesomeIcon icon={faLink} className="text-theme" />
                                    </div>
                                    <div className="ml-3">
                                        <p className="text-theme mb-0">Activate more features</p>
                                        <p className="text-theme text-center mb-0 small">Click to link your email now <FontAwesomeIcon icon={faAngleRight} /></p>
                                    </div>
                                </div>
                            </Button>
                        ) : null}
                    </Modal.Body>
                </Modal>

                <GuestUserConversionModal show={this.state.showGuestConversionModal} onHide={() => this.setState({ showGuestConversionModal: false })} />

            </>
        )
    }
}

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
})

export default connect(mapStateToProps)(UserNameAvatar);