import React from 'react';
import { DropdownButton, Button, Dropdown, Badge, Form, ButtonGroup, ButtonToolbar, InputGroup, FormControl } from "react-bootstrap";
import DateTimePicker from 'react-datetime-picker'
import moment from "moment";
import firebase from "firebase";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import {
    setAssignmentId,
    setAssignmentQuestions,
    setAssignmentDescriptions, 
    setAssignmentWeights,
    setAssignmentTotalWeight,
    setAssignmentGraded
} from './actions';
import { 
    setTaskDeadline,
    setTaskChannelUsers,
    setTaskFolder,
    setTaskEveryone,
    setTaskOldFolder,
    editTask,
    editSaveTask,
    publishSaveTask
} from '../../../actions/index';
import TaskCommon from '../Common/TaskCommon';

class EditAssignment extends React.Component {
    // ALL STATES
    state = {
        addAll: false,
        error: ''
    }

    componentDidMount() {
        const { 
            setAssignmentId, 
            setAssignmentQuestions, 
            setAssignmentDescriptions, 
            setAssignmentWeights,
            setAssignmentTotalWeight,
            setAssignmentGraded,

            setTaskDeadline,
            setTaskChannelUsers, 
            setTaskFolder, 
            setTaskEveryone, 
            setTaskOldFolder
        } = this.props;
        
        setAssignmentId(this.props.taskId)
        setAssignmentQuestions(this.props.data.questions)
        setAssignmentDescriptions(this.props.data.descriptions)
        setAssignmentWeights(this.props.data.weights)
        setAssignmentTotalWeight(this.props.data.totalWeight);
        setAssignmentGraded(this.props.data.totalWeight ? true : false)

        setTaskDeadline(new Date(this.props.data.deadline))
        setTaskChannelUsers(Object.keys(this.props.data.users))
        setTaskFolder(this.props.data.folder)
        setTaskEveryone(this.props.data.everyone)
        setTaskOldFolder(this.props.data.folder)
    }

    // ADDING QUESTION + DESCRIPTION
    addQuestion = () => {
        let prev = [...this.props.questions];
        prev[prev.length] = "";
        this.props.setAssignmentQuestions(prev);

        let prevDescriptions = [...this.props.descriptions];
        prevDescriptions[prevDescriptions.length] = "";
        this.props.setAssignmentDescriptions(prevDescriptions);

        let prevWeights = [...this.props.weights];
        prevWeights[prevWeights.length] = 0;
        this.props.setAssignmentWeights(prevWeights);
    }

    // REMOVING QUESTION
    removeQuestion = (key) => {
        let prev = [...this.props.questions];
        prev.splice(key, 1);
        this.props.setAssignmentQuestions(prev);

        let prevDescriptions = [...this.props.descriptions];
        prevDescriptions.splice(key, 1);
        this.props.setAssignmentDescriptions(prevDescriptions);

        let prevWeights = [...this.props.weights];
        prevWeights.splice(key, 1);
        this.props.setAssignmentWeights(prevWeights);
    }

    // HANDLES QUESTIONS ADDED
    handleQuestions = (e, key) => {
        let prev = [...this.props.questions];
        prev[key] = e.target.value;
        this.props.setAssignmentQuestions(prev);
    }

    // HANDLES DESCRIPTION ADDED
    handleDescriptions = (e, key) => {
        let prev = [...this.props.descriptions];
        prev[key] = e.target.value;
        this.props.setAssignmentDescriptions(prev);
    }

    handleWeights = (e, key) => {
        let prev = [...this.props.weights];
        prev[key] = parseInt(e.target.value);
        this.props.setAssignmentWeights(prev);
    }

    // MAIN RENDER FUNCTION
    render() {
        const { 
            type, 
            questions, 
            descriptions,  
            weights, 
            totalWeight, 
            graded, 
            channelUsers, 
            deadline, 
            folder, 
            old_folder, 
            everyone, 
            editTask, 
            uid, 
            currentChannel, 
            currentUser, 
            data, 
            taskBranch } = this.props;

        const validateData = () => {
            let user = data.user;
            let timestamp = data.timestamp;
            let response = data.response ? data.response : null;

            let filtered_questions = questions.filter(question => question.length > 0);
            if (questions.length != filtered_questions.length) {
                this.setState({ error: "Please add some value to question(s).\n" });
                return null;
            }
            if (questions.length == 0) {
                this.setState({ error: "Assignment must have at-least one or more question(s).\n" });
                return null;
            }

            if (graded && parseInt(totalWeight) === 0) {
                this.setState({error: "Total weight should be greater than '0'.\n"});
                return null;
            }
            
            let filtered_weights = weights.filter(weight => parseInt(weight) > 0);
            if (graded && weights.length !== filtered_weights.length) {
                this.setState({ error: "Please add some value to weight(s).\n" });
                return null;
            }

            if (moment(deadline).diff(moment(Date.now()), 'minutes') < 9) {
                this.setState({ error: "Deadline of task should be atleast 10 minutes.\n" });
                return null;
            }

            if (!everyone && channelUsers.length < 2) {
                this.setState({ error: "Please assign task to atleast one participant.\n" });
                return null;
            }

            this.setState({ error: "" });
            return { type, questions, descriptions, weights, totalWeight, channelUsers, deadline, user, timestamp, uid, response, folder, everyone };
        }

        return (
            <React.Fragment>
                <div className="taskBox">
                    <h5>Assignment</h5>
                    <Form className="feedback">

                        {questions.length > 0
                            ? questions.map((question, key) =>
                                (<div>
                                    <div className="d-flex">
                                        <p className="mb-0 flex-grow-1">Question {key + 1}.</p>
                                        <div><FontAwesomeIcon icon={faTimes} onClick={() => this.removeQuestion(key)} /></div>
                                    </div>
                                    <InputGroup className="mb-1">
                                        <FormControl
                                            placeholder="Question"
                                            aria-label="Question"
                                            aria-describedby={"question" + key}
                                            as="textarea"
                                            name="questions[]"
                                            value={questions[key]}
                                            id={key}
                                            onChange={e => this.handleQuestions(e, key)}
                                            required={true}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            placeholder="Description (optional)"
                                            aria-label="Description"
                                            aria-describedby={"Description" + key}
                                            as="textarea"
                                            name="descriptions[]"
                                            value={descriptions[key]}
                                            id={key}
                                            onChange={e => this.handleDescriptions(e, key)}
                                            required={true}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            placeholder="Weight"
                                            name="weights[]"
                                            value={weights && weights[key]}
                                            id={key}
                                            type="number"
                                            min="0"
                                            onChange={e => this.handleWeights(e, key)}
                                        />
                                        <InputGroup.Append>
                                            <InputGroup.Text> points</InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>))
                            : null}

                        <InputGroup className="w-100 my-3">
                            <Button 
                            size="sm" 
                            variant="outline-primary" 
                            className="w-100"
                            onClick={this.addQuestion}>+ <small>Add Question</small></Button>
                        </InputGroup>

                        <p className="mb-1">Gradebook</p>
                        <Form.Group className="mb-1">
                            <Form.Check
                            required
                            label="Add to Gradebook"
                            value="graded"
                            checked={graded}
                            onChange={() => this.props.setAssignmentGraded(!graded)}
                            />
                        </Form.Group>

                        {graded && <><p className="pb-0 mb-0">Total weight</p>
                        <InputGroup className="mb-3">
                            <FormControl
                                placeholder="Weight"
                                name="totalWeight"
                                type="number"
                                min="0"
                                onChange={e => this.props.setAssignmentTotalWeight(parseInt(e.target.value))}
                                value={totalWeight}
                            />
                            <InputGroup.Append>
                                <InputGroup.Text> points</InputGroup.Text>
                            </InputGroup.Append>
                        </InputGroup></>}

                        <TaskCommon />
                        
                        <div className="d-flex">
                            {taskBranch === "saved"
                                ? <>
                                    <Button 
                                    onClick={() => validateData() && this.props.publishSaveTask(validateData(), uid, old_folder, currentChannel, currentUser)} 
                                    variant="primary" 
                                    style={{width: "100px", marginRight: "5px"}}
                                    size="sm" ><small>Share now</small></Button>

                                    <Button 
                                    onClick={() => validateData() && this.props.editSaveTask(validateData(), uid, old_folder, currentChannel, currentUser)} 
                                    variant="success" 
                                    style={{width: "100px", marginLeft: "5px"}}
                                    size="sm" ><small>Save for later</small></Button>
                                </>
                                :
                                <Button 
                                onClick={() => validateData() && editTask(validateData(), uid, old_folder, currentChannel, currentUser)} 
                                variant="primary" 
                                style={{width: "100px", marginRight: "5px"}}
                                size="sm" ><small>Share now</small></Button>}
                        </div>
                        <p className="my-2 p-0 text-danger">{this.state.error}</p>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    currentChannel: state.channel.currentChannel,
    currentUser: state.user.currentUser,

    taskId: state.platform.taskId,
    taskBranch: state.platform.taskBranch,

    deadline: state.task.deadline,
    channelUsers: state.task.channelUsers,
    folder: state.task.folder,
    old_folder: state.task.old_folder,
    everyone: state.task.everyone,

    questions: state.assignment.questions,
    descriptions: state.assignment.descriptions,
    weights: state.assignment.weights,
    totalWeight: state.assignment.totalWeight,
    graded: state.assignment.graded,
    uid: state.assignment.id,
    type: state.assignment.type
})

export default connect(
    mapStateToProps, {
        setAssignmentId,
        setAssignmentQuestions,
        setAssignmentDescriptions, 
        setAssignmentWeights,
        setAssignmentTotalWeight,
        setAssignmentGraded,
        
        setTaskDeadline,
        setTaskChannelUsers,
        setTaskFolder,
        setTaskEveryone,
        setTaskOldFolder,
        editTask,
        editSaveTask,
        publishSaveTask
    })(EditAssignment);