import React, { useEffect, useState, useRef } from "react";
import firebase from "firebase";
import {
    faVideo,
    faVideoSlash,
    faMicrophone,
    faMicrophoneSlash,
    faDesktop,
    faStop,
    faDotCircle,
    faPlayCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { setIsVideoVisible, setChannelDetails, setPublisher } from "../../actions/index";
import { Card, Alert, Button, Dropdown, Spinner } from "react-bootstrap";

import ActionBox from "../Middle/ActionBoxForVideo";
import { API_BASE_URL, ROOM_CONFIG } from "../../config/index";
import { postData } from "../../utils/index";
import {
  browserName,
  CustomView,
  osName,
  deviceType,
  isIOS,
  isAndroid,
} from "react-device-detect";
import Webcam from "react-webcam";
import { loadState, saveState } from "../../services/index";
import Chat from "../Middle/Chat";
import VideoChat from "../Middle/VideoChat";
import VideoQnA from "../Middle/videoQnA";
import {WebRTCAdaptor} from '../../utils/webrtc_adaptor';
import axios from 'axios';
import IsAdmin from "../IsAdmin";

function Broadcast({
    userName,
    userAvatar,
    channelId,
    currentChannel,
    participants,
    currentUser,
    currentWorkspace,
    maximiseVideo,
    minimiseVideo,
    setIsVideoVisible,
    setChannelDetails,
    currentSubscription,
    channelDetails,
    setStreamStateProps,
    setPublisher
}) {

    const [streamState, setStreamState] = useState(null);
    const [isPublishing, setIsPublishing] = useState(false);
    const [isMicMuted, setIsMicMuted] = useState(false);
    const [isCameraOff, setIsCameraOff] = useState(false);
    const [screenShareOn, setScreenShareOn] = useState(false);
    const [isBeingRecorded, setIsBeingRecorded] = useState(false);
    const [isBeingBroadcasted, setIsBeingBroadcasted] = useState(false);
    const [initialised, setInitialised] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const getIsAdminHandler = async () => {
        if (channelDetails && currentWorkspace && currentUser.uid) {
          const query = firebase
            .firestore()
            .collection(
              `/workspaces/${currentWorkspace.workspaceId}/rooms/${channelDetails.roomId}/participants`
            );
          let res = (await query.get()).docChanges().map((s) => {
            let json = { ...s.doc.data(), id: s.doc.id };
            return json;
          });
    
          let coAdmins = res.filter((participant) => participant.isAdmin);
    
          if (
            channelDetails.createdBy === currentUser.uid ||
            coAdmins.find((usr) => usr.id === currentUser.uid)
          ) {
            setIsAdmin(true);
          }
        }
      };

    const startBroadcasting = () => {

        var pc_config = {
			'iceServers' : [ {
				'urls' : 'stun:stun1.l.google.com:19302'
			} ]
		};

        var sdpConstraints = {
            OfferToReceiveAudio: false,
            OfferToReceiveVideo: false

        };
        var mediaConstraints = {
            audio: true,
            video: {width: 1280, height: 720}
        };

        var stream = new WebRTCAdaptor({
            websocket_url: "wss://streams.igesia.com/WebRTCAppEE/websocket" ,
            mediaConstraints: mediaConstraints,
            peerconnection_config: pc_config,
            sdp_constraints: sdpConstraints,
            localVideoId: "localVideo",
            callback: function (info) {
                if (info == "initialized") {
                    console.log("initialized");
                    setInitialised(true);
                }
                else if (info == "publish_started") {
                    //stream is being published 
                    console.log("publish started");
                }
                else if (info == "publish_finished") {
                    //stream is finished
                    console.log("publish finished");
                }
                else if (info == "screen_share_extension_available") {
                    //screen share extension is avaiable
                    console.log("screen share extension available");
                }
                else if (info == "screen_share_stopped") {
                    //"Stop Sharing" is clicked in chrome screen share dialog
                    console.log("screen share stopped");
                    setScreenShareOn(false);
                    setIsCameraOff(false);
                }

            },
            callbackError: function (error) {
                //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

                console.log("error callback: " + error);
                // alert(error);

                if(error === "ScreenSharePermissionDenied") {
                    setScreenShareOn(false);
                    setIsCameraOff(false);
                }
            }
        });

        setStreamState(stream)
        setStreamStateProps(stream)
    }

    useEffect(() => {
        if (isAdmin) {
            startBroadcasting()
        }
    }, [isAdmin])

    useEffect(() => {
        getIsAdminHandler();
    }, [])

    useEffect(() => {
        // to automatically start streaming when it's mounted
        if (streamState && initialised) {
            streamState.publish(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`);
            setIsPublishing(true);
            setPublisher(true);
        }
    }, [streamState, initialised])




    useEffect(() => {
        // to find whether a stream is already live or not?
        firebase.firestore().collection(`broadcasts/${currentWorkspace.workspaceId}/rooms/`).doc(channelDetails.roomId)
            .onSnapshot((doc) => {
                if (doc.exists && !isPublishing) {
                    if (doc.data().currentStream) {
                        setIsBeingBroadcasted(true)
                    } else {
                        setIsBeingBroadcasted(false);
                    }
                } else {
                    setIsBeingBroadcasted(false);
                }
            })
    }, [isPublishing])

    useEffect(() => {
        return () => {
            if(streamState) {
                console.log("closeStream and stop")
                streamState.closeStream(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`)
                streamState.stop(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`)
                setPublisher(false);
            }
        }
    },[streamState])


    const stopMediaStreams = () => {
        const localVideo = document.getElementById('localVideo');
        const localTracks = localVideo?.srcObject?.getTracks();
    
        if (localTracks) {
          localTracks.forEach((track) => {
            console.log('stopping track', track);
            track.stop();
          })
          localTracks.srcObject = null;
        }
    }

    return (
        <>
            <div className="row w-100 mx-auto mb-2" style={{height: 'calc( 100% - 60px)'}}>
                <video className="w-100 h-100" id="localVideo" autoPlay muted playsInline></video>
            </div>

            <div className="row w-100 mx-auto d-flex justify-content-center">
                <div className="btn-group">
                {
                    isPublishing && isAdmin ? 
                    <button 
                        title={(isPublishing && streamState) ? "Stop publishing" : "Start publishing"} 
                        className={
                            (isPublishing && streamState)
                              ? "btn btn-dark btn-sm py-1 px-3"
                              : "btn btn-success btn-sm py-1 px-3"
                          } 
                        onClick={() => {

                        streamState.publish(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`);
                        setIsPublishing(true);
                        setPublisher(true);
    
                        if (isPublishing && streamState) {
                            console.log("closeStream and stop")
                            stopMediaStreams();
                            streamState.closeStream(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`)
                            streamState.stop(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`)
                            // setIsPublishing(false);
                            setIsVideoVisible(false);
                            setChannelDetails(null);
                        }
    
    
                    }}>{isPublishing && isAdmin ? 
                            <div className="d-flex flex-column align-items-center">
                                <FontAwesomeIcon
                                    icon={faStop}
                                    color="#fff"
                                    size="lg"
                                />
                                <span>Stop publishing</span>
                            </div>
                     :  !isPublishing && isAdmin ?
                            <div className="d-flex flex-column align-items-center ">
                                <FontAwesomeIcon
                                    icon={faPlayCircle}
                                    color="#fff"
                                    size="lg"
                                />
                                <span>Start publishing</span>
                            </div>
                        :  null
     
                     }
                    </button>
                    : !isPublishing && isAdmin ?
                    <button 
                        title={(isPublishing && streamState) ? "Stop publishing" : "Start publishing"} 
                        className={(isPublishing && streamState) ? "btn btn-dark btn-sm py-1 px-3" : "btn btn-success btn-sm py-1 px-3"} 
                        onClick={() => {

                        streamState.publish(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`);
                        setIsPublishing(true);
                        setPublisher(true);
    
                        if (isPublishing && streamState) {
                            console.log("closeStream and stop")
                            stopMediaStreams();
                            streamState.closeStream(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`)
                            streamState.stop(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`)
                            // setIsPublishing(false);
                            setIsVideoVisible(false);
                            setChannelDetails(null);
                        }
    
    
                    }}>{isPublishing && isAdmin ? 
                            <div className="d-flex flex-column align-items-center ">
                                <FontAwesomeIcon
                                    icon={faStop}
                                    color="#fff"
                                    size="lg"
                                />
                                <span>Stop publishing</span>
                            </div>
                     :  !isPublishing && isAdmin ?
                            <div className="d-flex flex-column align-items-center ">
                                <FontAwesomeIcon
                                    icon={faPlayCircle}
                                    color="#fff"
                                    size="lg"
                                />
                                <span>Start publishing</span>
                            </div>
                        :  null
     
                     }
                    </button>
                    :                       
                    <p>
                        <span className="text-theme font-weight-bold">
                        Broadcast has not started yet
                        </span>
                    </p>
                }

                {
                    isPublishing ?
                        <button 
                            title={isCameraOff ? "Video on" : "Video off"} 
                            className={isCameraOff ? "btn btn-dark btn-sm "  : "btn btn-success btn-sm py-1 px-3"}
                            onClick={() => {
                            if (isCameraOff && screenShareOn) {
                                streamState.turnOnLocalCamera()
                                streamState.switchDesktopCaptureWithCamera(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`)
                                setIsCameraOff(false);
                            } else if (isCameraOff && !screenShareOn) {
                                streamState.turnOnLocalCamera()
                                setIsCameraOff(false);
                            } else if (!isCameraOff && screenShareOn) {
                                streamState.switchDesktopCapture(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`);
                                streamState.turnOffLocalCamera()
                                setIsCameraOff(true);
                            } else if (!isCameraOff && !screenShareOn) {
                                streamState.turnOffLocalCamera()
                                setIsCameraOff(true);
                            }
                        }}>{isCameraOff ? 
                                <div className="d-flex flex-column align-items-center">
                                    <FontAwesomeIcon
                                        icon={faVideoSlash}
                                        color="#fff"
                                        size="lg"
                                    />
                                    <span>
                                        Turn Video on
                                    </span>
                                </div>
                                 : 
                                <div className="d-flex flex-column align-items-center">
                                    <FontAwesomeIcon
                                        icon={faVideo}
                                        color="#fff"
                                        size="lg"
                                    />
                                    <span>Turn Video off</span>
                                </div>
                                 }</button>
                        : null
                }
                {
                    isPublishing ?
                        <button 
                            title={isMicMuted ? "Unmute" : "Mute"} 
                            className={isMicMuted ? "btn btn-dark btn-sm py-1 px-3" : "btn btn-success btn-sm py-1 px-3"}
                            onClick={() => {
                            if (isMicMuted) {
                                streamState.unmuteLocalMic()
                                setIsMicMuted(false);
                            } else if (!isMicMuted) {
                                streamState.muteLocalMic()
                                setIsMicMuted(true);
                            }
                        }}>{isMicMuted ? 
                                <div className="d-flex flex-column align-items-center">
                                    <FontAwesomeIcon
                                        icon={faMicrophoneSlash}
                                        color="#fff"
                                        size="lg"
                                    />
                                    <span>Unmute</span>
                                </div>
                                :
                                <div className="d-flex flex-column align-items-center">
                                    <FontAwesomeIcon
                                        icon={faMicrophone}
                                        color="#fff"
                                        size="lg"
                                    />
                                    <span>Mute</span>
                                </div>
                         }</button>
                        : null
                }
                {
                    isPublishing && !screenShareOn ?
                        <button 
                            title="Share screen" 
                            className={(isPublishing && !screenShareOn) ? "btn btn-dark btn-sm " : "btn btn-success btn-sm py-1 px-3"}
                            onClick={() => {
                            if (isPublishing) {
                                streamState.switchDesktopCaptureWithCamera(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`)
                                setScreenShareOn(true);
                                setIsCameraOff(false);
                            }
                        }}>
                            <div className="d-flex flex-column align-items-center">
                                <FontAwesomeIcon
                                    icon={faDesktop}
                                    color="#fff"
                                    size="lg"
                                />
                                <span>Share screen</span>
                            </div>
                        </button>
                        : null
                }
                {/*
                    isPublishing ?
                        <button 
                            title={isBeingRecorded ? "Stop recording" : "Start recording"} 
                            className={isBeingRecorded ? "btn btn-dark btn-sm"  : "btn btn-success btn-sm py-1 px-3"}
                            onClick={() => {
                            if (!isBeingRecorded) {
                                axios.put(`https://streams.igesia.com/WebRTCAppEE/rest/v2/broadcasts/${currentWorkspace.workspaceId}-${channelDetails.roomId}/recording/true`)
                                setIsBeingRecorded(true);
                            } else if (isBeingRecorded) {
                                axios.put(`https://streams.igesia.com/WebRTCAppEE/rest/v2/broadcasts/${currentWorkspace.workspaceId}-${channelDetails.roomId}/recording/false`)
                                setIsBeingRecorded(false);
                            }
                        }}>{isBeingRecorded ? 
                                <div className="d-flex flex-column align-items-center">
                                    <FontAwesomeIcon
                                        icon={faDotCircle}
                                        color="#fff"
                                        size="lg"
                                    />
                                    <span>Stop recording</span>
                                </div>
                                :
                                <div className="d-flex flex-column align-items-center">
                                    <FontAwesomeIcon
                                        icon={faDotCircle}
                                        color="#fff"
                                        size="lg"
                                    />
                                    <span>Start recording</span>
                                </div>
                          }</button>
                        : null
                        */}
                </div>
            </div>
        </>

    )
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  participants: state.channel.participants,
  currentUser: state.user.currentUser,
  currentSubscription: state.subscription.currentSubscription,
  channelDetails: state.platform.channelDetails
});

export default connect(mapStateToProps, {
  setIsVideoVisible,
  setChannelDetails,
  setPublisher
})(Broadcast);
