import React, { useEffect, useState, useRef } from "react";
import firebase from "firebase";
import {
  faCross,
  faTimes,
  faCalculator,
  faCrosshairs,
  faArrowsAlt,
  faTh,
  faPlus,
  faMinus,
  faExpand,
  faMicrophoneSlash,
  faComment,
  faHandPaper,
  faVideo,
  faVideoSlash,
  faMicrophone,
  faEye,
  faEyeSlash,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { setIsVideoVisible, setChannelDetails, setPublisher } from "../../actions/index";
import { Card, Alert, Button, Dropdown, Spinner } from "react-bootstrap";

import ActionBox from "../Middle/ActionBoxForVideo";
import { API_BASE_URL, ROOM_CONFIG } from "../../config/index";
import { postData } from "../../utils/index";
import {
  browserName,
  CustomView,
  osName,
  deviceType,
  isIOS,
  isAndroid,
} from "react-device-detect";
import Webcam from "react-webcam";
import { loadState, saveState } from "../../services/index";
import Chat from "../Middle/Chat";
import VideoChat from "../Middle/VideoChat";
import VideoQnA from "../Middle/videoQnA";
import {WebRTCAdaptor} from '../../utils/webrtc_adaptor';
import axios from 'axios';
import IsAdmin from "../IsAdmin";

function Subscribe({
    userName,
    userAvatar,
    channelId,
    currentChannel,
    participants,
    currentUser,
    currentWorkspace,
    maximiseVideo,
    minimiseVideo,
    setIsVideoVisible,
    setChannelDetails,
    currentSubscription,
    channelDetails,
    setStreamStateProps,
    setPublisher
}) {

    const [subscriberState, setSubscriberState] = useState(null);
    const [isPublishing, setIsPublishing] = useState(false);
    const [isBeingBroadcasted, setIsBeingBroadcasted] = useState(false);
    const [joinedBroadcast, setJoinedBroadcast] = useState(false);
	



    const joinBroadcast = () => {


        var pc_config = {
            'iceServers': [{
                'urls': 'stun:stun1.l.google.com:19302'
            }]
        };

        var sdpConstraints = {
            OfferToReceiveAudio: true,
            OfferToReceiveVideo: true
        };
        var mediaConstraints = {
            video: false,
            audio: false
        };

        var stream = new WebRTCAdaptor({
            websocket_url: "wss://streams.igesia.com/WebRTCAppEE/websocket",
            mediaConstraints: mediaConstraints,
            peerconnection_config: pc_config,
            sdp_constraints: sdpConstraints,
            localVideoId: "localVideoSubscriber",
            isPlayMode: true,
            debug: true,
            candidateTypes: ["tcp", "udp"],
            callback: function (info, obj) {
                if (info == "initialized") {
                    console.log("initialized");

                } else if (info == "play_started") {
                    //joined the stream
                    console.log("play started");

                } else if (info == "play_finished") {
                    //leaved the stream
                    console.log("play finished");

                } else if (info == "closed") {
                    //console.log("Connection closed");
                    if (typeof obj != "undefined") {
                        console.log("Connecton closed: "
                            + JSON.stringify(obj));
                    }
                } else if (info == "streamInformation") {


                }
                else if (info == "ice_connection_state_changed") {
                    console.log("iceConnectionState Changed: ", JSON.stringify(obj));
                }
                else if (info == "updated_stats") {
                    //obj is the PeerStats which has fields
                    //averageIncomingBitrate - kbits/sec
                    //currentIncomingBitrate - kbits/sec
                    //packetsLost - total number of packet lost
                    //fractionLost - fraction of packet lost





                    console.debug("Average incoming kbits/sec: " + obj.averageIncomingBitrate
                        + " Current incoming kbits/sec: " + obj.currentIncomingBitrate
                        + " video packetLost: " + obj.videoPacketsLost
                        + " audio packetLost: " + obj.audioPacketsLost
                        + " frame width: " + obj.frameWidth
                        + " frame height: " + obj.frameHeight
                        + " frame received: " + obj.framesReceived
                        + " frame decoded: " + obj.framesDecoded
                        + " frame dropped: " + obj.framesDropped
                        + " video jitter average delay: " + obj.videoJitterAverageDelay
                        + " audio jitter average delay: " + obj.audioJitterAverageDelay
                        + " audio level: " + obj.audioLevel);

                }
                else if (info == "data_received") {

                }
                else if (info == "bitrateMeasurement") {


                }
                else {
                    console.log(info + " notification received");
                }
            },
            callbackError: function (error) {
                //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

                console.log("error callback: " + JSON.stringify(error));
            }
        });

        setSubscriberState(stream)


    }

    useEffect(() => {
        joinBroadcast()
    },[])

    useEffect(() => {
        setJoinedBroadcast(true)
        
    },[])

    useEffect(() => {
        // to find whether a stream is already live or not?
        firebase.firestore().collection(`broadcasts/${currentWorkspace.workspaceId}/rooms/`).doc(channelDetails.roomId)
            .onSnapshot((doc) => {
                if (doc.exists && !isPublishing) {
                    if (doc.data().currentStream) {
                        setIsBeingBroadcasted(true)
                    } else {
                        setIsBeingBroadcasted(false);
                    }
                } else {
                    setIsBeingBroadcasted(false);
                }
            })
    }, [isPublishing])


    return (
        <>
            <div className="row w-100 h-100 mx-auto">
                {/* <video id="localVideoSubscriber" autoPlay playsInline controls></video> */}
                <iframe className="w-100 h-100" style={{ display: joinedBroadcast ? 'block' : 'none' }} src={`https://streams.igesia.com/WebRTCAppEE/play.html?name=${currentWorkspace.workspaceId}-${channelDetails.roomId}`} frameBorder="0" allowFullScreen autoPlay ></iframe>
            </div>
            {/* <div className="row w-100 mx-auto">
                <button className="btn btn-primary" onClick={() => {
                    setJoinedBroadcast(true)
                    // subscriberState.join(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`)
                    // subscriberState.play(`${currentWorkspace.workspaceId}-${channelDetails.roomId}`)

                    if(joinedBroadcast) {
                        setIsVideoVisible(false);
                        setChannelDetails(null);
                    }

                }}>{joinedBroadcast ? <span>Leave broadcast</span> : <span>Join broadcast</span>}</button>
            </div> */}
        </>
    )
}

const mapStateToProps = (state) => ({
  currentWorkspace: state.workspace.currentWorkspace,
  currentChannel: state.channel.currentChannel,
  participants: state.channel.participants,
  currentUser: state.user.currentUser,
  currentSubscription: state.subscription.currentSubscription,
  channelDetails: state.platform.channelDetails
});

export default connect(mapStateToProps, {
  setIsVideoVisible,
  setChannelDetails,
})(Subscribe);
