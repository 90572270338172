import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import * as linkify from 'linkifyjs';




function URLPreviewTemplate(props) {
    const {message} = props;

    const { metaTags, url } = message;
    const { meta, og } = metaTags;
    
    const metaTitle = meta.title, 
          metaDescription = meta.description, 
          ogTitle = og.title, 
          ogDescription = og.description, 
          site_name = og.site_name;

    const title = (metaTitle) 
                  ? metaTitle 
                  : ogTitle,
    description = (ogDescription) 
                  ? ogDescription
                  : metaDescription;

    return (
        <>
            <div
                style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
                className="m-0 p-1 rounded-left d-flex"
            >
                <div className="d-flex align-items-top overflow-hidden text-theme-light rounded-left">
                    {
                        og.image && linkify.find(og.image).length > 0 && linkify.find(og.image)[0].type === "url" 
                        ? <div className="url_message_img" style={{ backgroundImage: `url(${og.image})` }}></div>
                        : null
                    }
                    <div className="flex-grow-1 d-flex flex-column url_message_text">
                        {
                            title
                                ? <div style={{ textOverflow: 'ellipsis' }} title={title} className="text-theme-light">
                                    <span>{title}</span>
                                </div>
                                : null
                        }
                        {
                            description
                                ? <div style={{ textOverflow: 'ellipsis' }} className="flex-grow-1 text-muted overflow-hidden">
                                    <span className="small" title={description}>
                                        {description?.replace(/(\r\n|\n|\r)/gm, "")}
                                    </span>
                                </div>
                                : null
                        }
                        <div style={{ textOverflow: 'ellipsis' }} className="text-theme-light flex-none small">
                            {
                                site_name 
                                    ? <span title={site_name}>{site_name}</span>
                                    : <span title={site_name}>{url}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className="p-2 ml-auto">
                    <FontAwesomeIcon
                        icon={faTimesCircle}
                        size="sm"
                        onClick={props.onClose}
                    />
                </div>
            </div>
        </>
    )
}



export default (URLPreviewTemplate);
