import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    setOpenTaskId,
    setOpenTaskBranch,
    setContentType
} from '../../actions/index';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';
import firebase from 'firebase';

const timeFromNow = timestamp => {
    if (moment(Date.now()).diff(moment(timestamp), 'days') < 1) return moment(timestamp).fromNow()
    else if (moment(Date.now()).diff(moment(timestamp), 'days') < 2) return "yesterday"
    else if (moment(Date.now()).diff(moment(timestamp), 'days') < 7) return moment(timestamp).format('dddd')
    else return moment(timestamp).format("MMM DD YYYY")
};
const month_name = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

const TaskListTemplate = ({
    item,
    currentChannel,
    currentUser,
    setOpenTaskId,
    type,
    setOpenTaskBranch,
    task_visible,
    setContentType
}) => {
    const [responses, setResponses] = useState({});
    const [respondedBy, setRespondedBy] = useState([]);

    useEffect(() => {
        firebase.database().ref("messages/" + currentChannel.id).child(item.uid).on("value", snap => {
            snap.val() && setResponses(snap.val().response);
            setRespondedBy(snap.val() && snap.val().response ? Object.keys(snap.val().response) : []);
        });
    }, [])
    
     const progress = responses && responses
         ? item.everyone
             ? (Object.keys(responses).length / Object.keys(currentChannel.users).filter(item => item !== currentUser.uid).length) * 100
             : (Object.keys(responses).length / (Object.keys(item.users).filter(item => item !== currentUser.uid).length)) * 100
         : 0; 

    
    
    const getClassName = () => {

        if (item.user.id === currentUser.uid) {

            if (progress === 100 || (moment(Date.now()) > item.deadline)) {

                if (task_visible === "ToDo" || task_visible === "InProgress") return "completed d-none";
                else return "completed";

            } else {

                if (task_visible === "ToDo" || task_visible === "Completed") return "inprogress d-none";
                else return "inprogress";

            }

        } else {
            if (progress === 0) {

                if (task_visible === "Completed" || task_visible === "InProgress") return "todo d-none";
                else return "todo";

            }

            if (progress > 0 && progress < 100) {

                if (respondedBy.includes(currentUser.uid)) {

                    if (task_visible === "ToDo" || task_visible === "Completed") return "inprogress d-none";
                    else return "inprogress";

                } else {

                    if (task_visible === "InProgress" || task_visible === "Completed") return "todo d-none";
                    else return "todo";

                }

            }

            if (progress === 100) {

                if (task_visible === "ToDo" || task_visible === "InProgress") return "completed d-none";
                else return "completed";

            }
        }
    };
    

    if (item.list || item.feedback || item.poll || item.quiz_name || item.project || item.questions || item.readings || item.schedule)
        return (
            <div className={getClassName()}>
                <div className="row py-2 rounded  pointer lightBgonHover"
                    style={{margin: "0px -6px"}} 
                    onClick={() => {
                        setOpenTaskId(item.uid);
                        setOpenTaskBranch(type);
                        setContentType("Task");
                    }}>
                    <div className="col-2 text-right float-right">
                        {item.poll ? <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yM/r/C949oxkze-S.png" width="25" />
                            : item.feedback ? <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yM/r/C949oxkze-S.png" width="25" />
                                : item.quiz_name ? <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yz/r/Zy368sjJ0p_.png" width="25" />
                                    : item.project ? <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yM/r/C949oxkze-S.png" width="25" />
                                        : item.list ? <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yM/r/C949oxkze-S.png" width="25" />
                                            : item.questions ? <img src="https://static.xx.fbcdn.net/rsrc.php/v3/y-/r/Uy-TOlM5VXG.png" width="25" />
                                                : item.readings ? <img src="https://static.xx.fbcdn.net/rsrc.php/v3/yu/r/DxwxddVlL2T.png" width="25" />
                                                    : item.schedule ? <span className="text-center align-items-center text-theme">
                                                        <h4 class="my-0">{moment(item.deadline).format("DD")} </h4>
                                                        <p class="my-0 smaller">{month_name[moment(item.deadline).format("M") - 1]}</p>
                                                    </span>
                                                        : null}

                    </div>
                    <div class="col-10 ">
                        <div class="row">
                            <div class="col-4">
                                <p class="smaller mb-0 font-weight-bold text-uppercase text-theme-lighter">
                                    {item.poll ? 'Poll'
                                        : item.feedback ? 'Feedback'
                                            : item.quiz_name ? 'Quiz'
                                                : item.project ? 'Project'
                                                    : item.list ? 'List'
                                                        : item.questions ? 'Assignment'
                                                            : item.readings ? 'Files'
                                                                : item.schedule ? 'Schedule'
                                                                    : null}
                                </p>
                            </div>
                            <div class="col-8">
                                <p class="smaller mb-0 font-weight-bold text-warning text-uppercase text-right">DUE {timeFromNow(item.deadline)}</p>
                            </div>
                        </div>
                        <p class="small mb-0 text-theme">

                            {item.poll ? item.poll
                                : item.feedback ? item.feedback
                                    : item.quiz_name ? item.quiz_name
                                        : item.project ? item.project
                                            : item.list ? item.list
                                                : item.questions ? item.questions[0] + " + " + (item.questions.length - 1) + " more"
                                                    : item.readings ? Object(item.readings)[0].title + " + " + (item.readings.length - 1) + " more"
                                                        : item.schedule ? <span>
                                                            {item.schedule}
                                                            <p class="small mb-0" style={{ color: "rgb(69, 137, 247)" }}>
                                                                {moment(item.deadline).format("ddd")} {moment(item.deadline).format("DD")} {month_name[moment(item.deadline).format("M") - 1]}, {moment(item.deadline).format("hh:mm A")} {item.duration_hours ? item.duration_minutes ? "(" + item.duration_hours + " hr(s) & " + item.duration_minutes + " mins)" : "(" + item.duration_minutes + " mins)" : item.duration_minutes ? "(" + item.duration_minutes + " mins)" : "onwards"}
                                                            </p>

                                                        </span>
                                                            : null}

                        </p>
                        {/* <p className="small mb-0">
                            Replies
                            {responses ? Object.keys(responses).length : 0}
                            /
                            {item.everyone ? Object.keys(currentChannel.users).filter(item => item !== currentUser.uid).length : Object.keys(item.users).filter(item => item !== currentUser.uid).length}
                        </p>
                        <p className="small mb-0">
                            {timeFromNow(item.timestamp)}
                        </p>
                        <ProgressBar animated style={{ height: "0.2rem", marginTop: "0px", marginLeft: "8px", marginRight: "8px" }} now={progress} variant={progress > 50 ? "success" : "warning"} /> */}
                    </div>

                </div>
            </div>
        )

};

const mapStateToProps = state => ({
    currentChannel: state.channel.currentChannel,
    currentUser: state.user.currentUser
})

export default connect(mapStateToProps, {
    setOpenTaskId,
    setOpenTaskBranch,
    setContentType
})(TaskListTemplate);