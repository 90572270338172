import * as actionTypes from "./types";
import firebase from "firebase";

/* Quiz Actions */
export const setQuizId = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_ID,
    payload: {
      id: value,
    },
  };
};
/*export const setQuizSharing = (value) => {
  console.log(value);
  return {
    type: actionTypes.SET_SHARING,
    payload: {
      sharing: value,
    },
  };
};
*/
export const setQuizTitle = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_TITLE,
    payload: {
      quizTitle: value,
    },
  };
};

export const setQuizQuestions = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_QUESTIONS,
    payload: {
      questions: value,
    },
  };
};

export const setQuizQuestionImages = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_QUESTION_IMAGES,
    payload: {
      quizQuestionImages: value,
    },
  };
};

export const setQuizQuestionPreviewImages = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_QUESTION_PREVIEW_IMAGES,
    payload: {
      questionPreviewImages: value,
    },
  };
};

export const setQuizOptions = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_OPTIONS,
    payload: {
      options: value,
    },
  };
};
export const setQuizOptionsImages = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_OPTIONS_IMAGES,
    payload:{
      imageOptions:value,
    },
  };
};

export const setQuizPreviewImages = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_OPTIONS_PREVIEW,
    payload:{
      previewImageOptions:value,
    },
  };
};

export const setQuizTypes = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_TYPES,
    payload: {
      types: value,
    },
  };
};

export const setQuizCorrectAnswers = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_CORRECT_ANSWERS,
    payload: {
      correctAnswers: value,
    },
  };
};

export const setQuizWeights = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_WEIGHTS,
    payload: {
      weights: value,
    },
  };
};

export const setQuizTotalWeight = (value) => {
  return {
    type: actionTypes.ADD_QUIZ_TOTAL_WEIGHT,
    payload: {
      totalWeight: value,
    },
  };
};

export const setQuizGraded = (value) => {
  return {
    type: actionTypes.SET_QUIZ_GRADED,
    payload: {
      graded: value,
    },
  };
};
export const setQuizRandomize = (value) => {
  return {
    type: actionTypes.SET_QUIZ_RANDOMIZE,
    payload: {
      randomize: value,
    },
  };
};

export const setQuizRandomizeQuestion = (value) => {
  return {
    type: actionTypes.SET_QUIZ_RANDOMIZE_QUESTION,
    payload: {
      randomizeQuestion: value,
    },
  };
};

export const resetQuiz = () => {
  return {
    type: actionTypes.RESET_QUIZ,
    payload: {},
  };
};

export const saveRemark = (task_id, user_id, content, channel_id) => {
  return function (dispatch) {
    let updates = {};
    updates["remark"] = {
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      content,
    };
    firebase
      .database()
      .ref(`messages/${channel_id}`)
      .child(`${task_id}/response/${user_id}`)
      .update(updates);
  };
};

export const publishRemark = (
  task_id,
  user_id,
  content,
  channel_id,
  admin_id
) => {
  return function (dispatch) {
    let updates = {};
    updates["remark"] = {
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      content,
      status: true,
      admin: admin_id,
    };
    // firebase.database().ref(`messages/${channel_id}`).child(`${task_id}/response/${user_id}`).update(updates);
    firebase
      .database()
      .ref(`gradebook/${channel_id}`)
      .child(`${task_id}/${user_id}`)
      .update(updates);
  };
};

export const publishMarks = (task_id, user_id, marks, channel_id, admin_id) => {
  return function (dispatch) {
    let updates = {};
    updates["grade"] = {
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      marks,
      status: true,
      admin: admin_id,
    };
    // firebase.database().ref(`messages/${channel_id}`).child(`${task_id}/response/${user_id}`).update(updates);
    firebase
      .database()
      .ref(`gradebook/${channel_id}`)
      .child(`${task_id}/${user_id}`)
      .update(updates);
  };
};
