import React, { Component } from "react";
import { connect } from "react-redux";
import firebase from "firebase";

class IsAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      admin: false,
      channel: props.currentChannel,
      user: props.currentUser,
      uid: props.currentUser.uid,
      currentWorkspace: props.currentWorkspace,
    };
  }

  async componentDidMount() {
    const { channel, uid, currentWorkspace } = this.state;

    if (channel && currentWorkspace && uid) {
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace.workspaceId}/rooms/${channel.roomId}/participants`
        );
      let res = (await query.get()).docChanges().map((s) => {
        let json = { ...s.doc.data(), id: s.doc.id };
        return json;
      });
      // console.log(res.find((usr) => usr.uid === uid && usr.IsAdmin));
      let coAdmins = res.filter((participant) => participant.isAdmin);
      // console.log(coAdmins);

      if (channel.createdBy === uid || coAdmins.find((usr) => usr.id === uid)) {
        //console.log("hmmm");
        this.setState({ admin: true });
      }
    }

    // channel && this.setState({ admin: channel.users && channel.users[uid].admin })
    //if (channel && channel.createdBy === uid) this.setState({ admin: true });
  }

  componentDidUpdate = async (prevProps, prevStates) => {
    const { currentChannel, currentUser } = this.props;
    const { currentWorkspace } = this.state;

    if (prevProps.currentChannel != currentChannel) {
      /*if (currentChannel.createdBy === currentUser.uid) {






        this.setState({ admin: true });
      } */
      const query = firebase
        .firestore()
        .collection(
          `/workspaces/${currentWorkspace?.workspaceId}/rooms/${currentChannel?.roomId}/participants`
        );
      let res = (await query.get()).docChanges().map((s) => {
        let json = { ...s.doc.data(), id: s.doc.id };
        return json;
      });
      // console.log(res.find((usr) => usr.uid === uid && usr.IsAdmin));
      let coAdmins = res.filter((participant) => participant.isAdmin);
      //console.log(coAdmins);

      if (currentChannel &&
        currentChannel.createdBy === currentUser.uid ||
        coAdmins.find((usr) => usr.id === currentUser.uid)
      ) {
        // console.log("hmmm");
        this.setState({ admin: true });
      } else {
        this.setState({ admin: false });
      }
      // alert(`Changes ${prevProps.currentChannel.roomId} ${currentChannel.roomId}`)
    }
  };

  render() {
    const { admin } = this.state;
    if (admin) return <React.Fragment>{this.props.children}</React.Fragment>;
    else return null;
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  currentChannel: state.channel.currentChannel,
  currentWorkspace: state.workspace.currentWorkspace,
});

export default connect(mapStateToProps)(IsAdmin);
